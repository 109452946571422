import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoLegendPanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";
import {AgroAhoEyeSvg} from "../icons/AgroAhoEyeSvg";
import {AgroAhoEye2Svg} from "../icons/AgroAhoEye2Svg";
import {WindowTriState} from "../../../../app/helper/structs/WindowTriState";


@observer
export class AgroAhoLegendPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    isMinimized: boolean = false;

    onMinClick(){
        this.isMinimized = !this.isMinimized;
    }

    onHideIndcClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.onHideIndcClick();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let sel_indc = cstore.selectedIndicator;
        // console.log('selected indicator', store_.cardStore.selectedIndicator);

        let list = sel_indc?.visibility?.scales?.agrosoft;
        if (cstore.indcFormat == AhoIndicatorFormat.Agroplem)
            list = sel_indc?.visibility?.scales?.agroplem;
        if (cstore.indcFormat == AhoIndicatorFormat.FosAgro)
            list = sel_indc?.visibility?.scales?.fosagro;

        let st: React.CSSProperties = {right: "5px"};
        if (store.indexByPointer.showPanel == WindowTriState.show){
            st.right = "440px";
        }
        else if (store.map.layersMenuOpened) {
            st.right = "355px"
        }


        // let noAgrosoft = !sel_indc?.visibility?.scales?.agrosoft?.length;
        // let list = noAgrosoft ? sel_indc.visibility.scales.agroplem : sel_indc.visibility.scales.agrosoft;

        // let list = store_.cardStore.selectedIndicator?.info?.visibility;
        if (!list) return <div> </div>;
        list = list.slice().reverse();
        // console.log('data:', JSON.stringify(list));

        let arr: any[] = [];
        for (let i=0; i<list.length; i++) {
            let item = list[i];
            let val = item.max;
            let class_ = 'AgroAhoLegendPanelComp-ballon';
            if (i === 0) {
                val = '';
                class_ += ' AgroAhoLegendPanelComp-ballonTop';
            }
            if (i === list.length - 1) {
                class_ += ' AgroAhoLegendPanelComp-ballonBottom';
            }
            arr.push(<tr key={i}>
                <td>{val}</td>
                <td>
                    {/*{i == 0 && <div className={'AgroAhoLegendPanelComp-topLine'}> </div>}*/}
                    <div className={class_} style={{backgroundColor: item.color}}> </div>
                    {i != 0 && <div className='AgroAhoLegendPanelComp-bottomLine'> </div>}
                    <div className='AgroAhoLegendPanelComp-centerLine'> </div>
                </td>
                <td>
                    <div className='AgroAhoLegendPanelComp-name'>{item.name}</div>
                </td>
            </tr>);
        }

        let bottomCaption = '';
        if (cstore.indcFormat == AhoIndicatorFormat.AgroSoft) bottomCaption = store_.trans["Agro-Soft"];
        if (cstore.indcFormat == AhoIndicatorFormat.Agroplem) bottomCaption = store_.trans["Agroplem"];
        if (cstore.indcFormat == AhoIndicatorFormat.FosAgro) bottomCaption = store_.trans["FosAgro"];
        if (bottomCaption) bottomCaption = `${store_.trans['gradations']} ${bottomCaption}`;
        // className={classNames("tswitcher-item tswitcher-item-right",{
        //     "tswitcher-disabledItem": check1 && this.props.disabledUnchecked,
        //         "tswitcher-checked": !check1})}

            // arr.push(<ShowRoom2FiltersListItem item={a} onCheck={this.props.onCheck} onUncheck={this.props.onUncheck} store={this.props.store} key={a.id} />);

        return <div className={classNames("AgroAhoLegendPanelComp-main qtransit",{
            "AgroAhoLegendPanelComp-minimized": this.isMinimized})} style={st}>
            <div className="AgroAhoLegendPanelComp-title">
                <div className="AgroAhoLegendPanelComp-hideIndcCover" onClick={this.onHideIndcClick}>
                    {!store_.cardStore.hideIndc && <AgroAhoEye2Svg className="AgroAhoLegendPanelComp-hideIndc"/>}
                    {!!store_.cardStore.hideIndc && <div className="AgroAhoLegendPanelComp-hideIndcClosed"/>}
                </div>
                <div>{store_.cardStore.selectedIndicator.indc_name}, {store_.cardStore.selectedIndicator.unit}</div>
            </div>
            <table className="AgroAhoLegendPanelComp-table">
                <tbody>
                    {arr}
                </tbody>
            </table>
            {/*<div className="AgroAhoLegendPanelComp-unit">{store_.cardStore.selectedIndicator.unit}</div>*/}
            <div className="AgroAhoLegendPanelComp-unit">{bottomCaption}</div>
            <svg className="AgroAhoLegendPanelComp-min" onClick={this.onMinClick} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.99998 8V7H6.29298L3.64648 4.3535L4.35348 3.6465L6.99998 6.293V4H7.99998L7.99998 7.5C7.99998 7.776 7.77598 8 7.49998 8H3.99998Z" fill="#C5C5C5"/>
                <path d="M8 0L0.5 0C0.224 0 0 0.224 0 0.5L0 8H1L1 1L8 1L8 0Z" fill="#C5C5C5"/>
            </svg>
            <div className="AgroAhoLegendPanelComp-minCaption">{this.props.store.trans.Legend}</div>
        </div>
    }
}
