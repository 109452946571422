import React, {RefObject} from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {Utils} from "../../../../helper/utils/Utils";
import {EChartOption} from "echarts";
import {isArray} from "lodash-es";
import {IndexByPointGraphStore} from "../../../../store/indeByPoint/IndexByPointGraphStore";
import {MapComp} from "../../Map/MapComp";
import {action, observable, runInAction, when} from "mobx";
import autoBindReact from "auto-bind/react";
import MyChart from "./MyChart";
import center from "@turf/center";
import {MBUtils} from "../../../../helper/utils/MBUtils";
import DomToImage from 'dom-to-image';
import {
    getShortNameOfIndexDataType,
    IndexDataGroup,
    IndexDataType,
    TemperatureCategory
} from "../../../../store/indeByPoint/IndexByPointerStore";
import {IChartEvent} from "./IMyTestCompProps";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import booleanIntersects from "@turf/boolean-intersects";
import {ColorHelper} from "../../../../helper/utils/ColorHelper";
import {LeftPanelMode} from "../../../../store/SearchStore";
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../../Common/ContextMenuCommonComp";
import {IndexByPointPopupMonthComp} from "./Popups/IndexByPointPopupMonthComp";
import {IndexByPointPopupSettingsComp} from "./Popups/IndexByPointPopupSettingsComp";
import {IndexByPointLinesComp} from "./IndexByPointLinesComp";
import {IndexAreaType} from "../../../../store/indeByPoint/IndexByPointGraphPointStore";
import {ContextItems} from "../../../Common/ContextItems";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {DateUtils} from "../../../../helper/utils/DateUtils";
import {WindowTriState} from "../../../../helper/structs/WindowTriState";
import {ra} from "../../../../helper/utils/mobxUtils";
import mapboxgl from "maplibre-gl";
import {Satellite} from "../../../../helper/utils/satellliteDic";
import SeriesLine = echarts.EChartOption.SeriesLine;
import { Lang } from '../../../../../pluginApi/store/Lang';
import "./IndexByPointChart2Comp.scss"
import { thumbnail } from 'exifr';

//import {SeriesOption} from "echarts/lib/util/types";

const IdChart = "MyChart";
const Postfix_AreaMin = "_min";
const Postfix_AreaValue = "_value";
const Postfix_AreaMax = "_max";

export interface IIndexByPointChart2Comp extends IStoreProps{
    calendarButtonVisible?: boolean;//default true
    settingsButtonVisible?: boolean;//default true
    //legend: any;
}
interface IMinMax{min: number, max: number};
enum LineType{
    simple,
    areaMin,
    areaValue,
    areaMax
}
interface ISeriesValue{
    dayOfYear: number;
    virtualValue: number,
    realValue: number,
    sceneID?: string,
}
@observer
export default class IndexByPointChart2Comp extends React.Component<IIndexByPointChart2Comp, undefined> {
    constructor(props: IIndexByPointChart2Comp) {
        super(props);
        autoBindReact(this);
        this.graphRef = React.createRef();
        this.myChartRef = React.createRef()
    }
    myChartRef: RefObject<MyChart>;
    graphRef: RefObject<any>;
    base: number = 0;

    static readonly KOEF_MAP_CLICK = 0.5;
    @observable splitNumber:number = null
    @observable newSplitNumber:number = null
    componentDidMount() {
    this.updateSplitNumber();
    document.body.addEventListener('keydown', this.onKeyPress);
    }
    componentWillUnmount() {
        document.body.removeEventListener('keydown', this.onKeyPress);
    }
    updateSplitNumber() {
        if (this.myChartRef.current) {
            const splitNumber = this.myChartRef.current.getSplitNumber();
            if (splitNumber) {
                this.splitNumber = splitNumber;
                if (this.props.store.indexByPointer.maxPanel){
                    this.newSplitNumber = this.splitNumber * 2 
                }
            }
        }
    }

    @action
    onKeyPress(event: any){
        let store = this.props.store;
        if (store.indexByPointer.showPanel != WindowTriState.show) return;
        if (event.code == 'ArrowLeft' || event.code == 'ArrowRight'){
            let gArr = store.indexByPointer.graphs.filter(a => a.id == store.indexByPointer.currentGraphId);
            if (gArr.length == 0) return;
            let g = gArr[0];
            let data = g.graphData.data;
            let idx = data.findIndex(a => a.sceneID == store.searchPanel.currentSceneid);
            if (idx < 0) return;

            let next = -1;
            let max = 0;
            if (event.code == 'ArrowLeft') {
            }
            if (event.code == 'ArrowRight') {
                max = data.length - 1;
                next = 1;
            }
            while (true){
                if (idx == max) return;
                idx = idx + next;
                if (data[idx].sceneID != null) break;
            }
            store.searchPanel.currentSceneid = data[idx].sceneID;
            let elem = document.getElementById(store.searchPanel.currentSceneid);
            if (elem != null)
                Utils.scrollIntoView(elem);
        }
    }


    getOptions(): EChartOption{//EChartsFullOption
        let store = this.props.store;
        let st = store.indexByPointer;
        let r: EChartOption = {};//EChartsFullOption
        let title:string = "";
        let graphTypes: Set<IndexDataType> = new Set<IndexDataType>();
        let graphGroups: Set<IndexDataGroup> = new Set<IndexDataGroup>();
       
        st.graphs.forEach(a =>{
            graphTypes.add(a.dataType);
            graphGroups.add(a.dataGroup);
        });
        let graphGroup: IndexDataGroup = null;
        if (graphGroups.size == 1) {
            graphGroup = graphGroups.values().next().value;
        }else if (graphGroups.size > 1) {
            store.addError("Multi groups!");
            return {};
        }

        this.base = 0;
        if (graphGroup == IndexDataGroup.temperature) this.base = 10000;

        if (graphGroup != null){
            if (graphGroup == IndexDataGroup.radar_iw) {
                title = `dB, Backscattering coefficient ${st.polarIW}`;
            }
            if (graphGroup == IndexDataGroup.radar_ew) {
                title = `dB, Backscattering coefficient ${st.polarEW}`;
            }
            if (graphGroup == IndexDataGroup.temperature) {
                title = store.trans["Temperature °C"] + ", " + TranslateUtils.getTemperatureCategoryTitle(st.temperatureCategory, store)
            }
            if (graphGroup == IndexDataGroup.precipitation) {
                title = store.trans["Precipitation mm"]+", "+ TranslateUtils.getPrecipitationCategoryTitle(st.precipitationCategory, store);
            }
            if (graphGroup == IndexDataGroup.relativeHumidity) title = store.trans["Relative humidity, %"];
            if (graphGroup == IndexDataGroup.soilMoisture) title = store.trans["Volume moisture fraction"];
            if (graphGroup == IndexDataGroup.solarRadiation){
                title = store.trans["Surface solar radiation, MJ/m²"] +", "+ TranslateUtils.getSolarRadCategoryTitle(st.solarRadCategory, store);
            }
            if (graphGroup == IndexDataGroup.spectral) title = st.productCode??"";
            if (graphGroup == IndexDataGroup.modis){
                if (graphTypes.has(IndexDataType.modis_TERRA_RED) || graphTypes.has(IndexDataType.modis_TERRA_NIR)){
                    title = "SR, Spectral reflectance";
                }else {
                    title = "NDVI";
                }
            }
        }

        

        if (this.props.store)
        r.title = {text: title,
            show: true,
            left: 28,
            top: 7,
            textStyle:{
                fontSize: 10,
                color: "#8C9BAB",
            }
        };
        r.tooltip = {trigger: 'axis',//вертикальная линия которая бегает за курсором
            padding: 0,
            borderWidth: 1,
            borderColor: '#363F48',
            axisPointer:{
                lineStyle:{
                    type: "dashed",//type: "dashed",
                    color: "#4c4f54",
                },
            },
            formatter: (params) => {
                let html = "";
                if (isArray(params) && params.length > 0){
                    html += `<div>`;
                    let dRel = (params[0]["value"] as any[])[0];
                    let d = Utils.getDateByRelativeByGlobalAllLeapYears(dRel, 2020);
                    let month = d.toLocaleString('default', { month: 'long' });
                    html += `<div class="IndexByPointChart2Comp-tooltip-date">${d.getDate()} ${Utils.stringToHtmlEncode(month)}</div>`;
                    params.forEach(p =>{
                        let v = (p["value"] as any[])[4];
                        let ln_name = "";
                        if (p.seriesName.endsWith(Postfix_AreaMin)) ln_name = "Min: ";
                        if (p.seriesName.endsWith(Postfix_AreaMax)) ln_name = "Max: ";
                        let color = (p["value"] as any[])[3];
                        html += `<div class="IndexByPointChart2Comp-tooltip-value flex-columns flex-align-items-center">`;
                        html += `<span class="IndexByPointChart2Comp-tooltip-circle" style="background-color: ${color}"></span>`;
                        html += `<div class="flex-stretch-item">${ln_name}${v}</div>`;
                        html += `</div>`;
                    });
                    html += `</div>`;
                }
                return html;
            },
        };
        r.toolbox = {
            show: false
        };
        //зумирование колесиком
        r.dataZoom = [{
            type: 'inside',              
        },];
        
       
        //рамка вокруг графика
        r.grid = {
            show: false,
            backgroundColor: "#262B32",
            top: 30,//отступы графика от краёв
            bottom: 20,
            right: 15,
            left: 35,
            //containLabel: true,
        };
        let d1 = Utils.getDayOfYearRelativeByGlobalAllLeapYears(store.indexByPointer.getBeginDate(2020), 2020);
        let d2 = Utils.getDayOfYearRelativeByGlobalAllLeapYears(store.indexByPointer.getEndDate(2020, false), 2020);
        let months = store.indexByPointer.monthEnd - store.indexByPointer.monthBegin + 1;
        if (months < 0) months += 12;
        let intervalMonth = (d1 - d2) / months;
        const periodLengthInDays = d2 - d1;
        let interval;
        if (periodLengthInDays <= 31) {
            interval = this.props.store.indexByPointer.maxPanel? 1 : 4 ; 
        } else if (periodLengthInDays > 31 && periodLengthInDays <= 92) { 
            interval = 15;
        } else {
            interval = intervalMonth; 
        }   
        const isLongPeriod = periodLengthInDays >= 31;
        r.xAxis = {
            type: "time",
            min: (d1),
            max: (d2),

            minInterval:  interval ,//366*24*60*60*1000/12,
            interval:  interval,//366*24*60*60*1000/12,

            axisLine: {
                show: true,
            },

            axisTick:{show: true},
            boundaryGap: false,
            axisLabel:{
                show: true,
                "showMinLabel":true,
                "showMaxLabel": true,
                formatter: (value: number) => {
                    // if (isLongPeriod) {
                    //     return ;
                    // } else {
                    //     const dRel = value;
                    //     const d = Utils.getDateByRelativeByGlobalAllLeapYears(dRel, 2020); 
                    //     const month = String(d.getMonth() + 1).padStart(2, '0');
                    //     return `${d.getDate()}`;
                    // }
                    const dRel = value;
            const d = Utils.getDateByRelativeByGlobalAllLeapYears(dRel, 2020);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            if (periodLengthInDays <= 31) {
                return `${day}`; 
            } else if (periodLengthInDays > 31 && periodLengthInDays <= 92) {
                return `${day}.${month}`;
            } else {
                return ;
            }

                },
                color:  "#C5C5C5", 
                fontSize: 10, 
                fontWeight: "bold",
            },
            splitLine: {show: false},
        };
        let minMax = this.getMinMaxValue();
        let axisTick = false;
        if (graphGroup == IndexDataGroup.spectral){
            let newMinMax: IMinMax = {min: 0, max: 1};
            if (st.graphs.length > 0){
                let sg = st.graphs[0];
                if (["BSI"].some(v => v == sg.productCode)){
                    newMinMax = {min: 0, max: 0.5};
                }

                if (["NDWI", "NDWI2", "NDMI"].some(v => v == sg.productCode)){
                    newMinMax = {min: -1, max: 1};
                }else{
                    if (minMax.min < newMinMax.min) newMinMax.min = Math.floor((minMax.min) * 10) / 10;
                    if (minMax.max > newMinMax.max) newMinMax.max = Math.ceil((minMax.max) * 10) / 10;
                }
            }
            minMax = newMinMax;
            axisTick = true;
        }
//        if (graphGroup == null || graphGroup == IndexDataGroup.spectral ||
//            graphGroup == IndexDataGroup.radar_iw || graphGroup == IndexDataGroup.radar_ew) {
        r.yAxis = {
            type: "value",
            axisTick: {show: axisTick},
            min: minMax.min + this.base,
            max: minMax.max + this.base,     
            splitNumber: this.newSplitNumber? this.newSplitNumber : this.splitNumber,  
            splitLine: {//линии сетки горизонтальные
                show: true,
                lineStyle: {color: "#323941", type: "dashed"}
            },

            boundaryGap: false,
            axisLabel: {
                fontSize: 9,
                "showMinLabel": axisTick,
                "showMaxLabel": axisTick,
                color: "#C5C5C5",
            },
        };
        if (graphGroup == IndexDataGroup.temperature){
            r.yAxis.axisLabel.formatter = (value: any)=>{ 
                return Math.round((value - this.base) * 1000) / 1000;
            };
        }
        r.animationDuration = 200;
        r.series = this.getSeries(!(periodLengthInDays > 31 && periodLengthInDays <= 92) && isLongPeriod);
        return r;
    }

    getGraphBySeries(id: string): IndexByPointGraphStore{
        let a: IndexByPointGraphStore = null;
        let i = 0;
        this.props.store.indexByPointer.graphs.forEach((q, idx)=>{
            if (ColorHelper.numToCssHexRgb(q.color) == id){
                a = q;
            }
        });
        return a;
    }
    getGraphStores(): IndexByPointGraphStore[]{
        let graphs: IndexByPointGraphStore[] = [];
        let store = this.props.store;
        store.indexByPointer.points.forEach(a => a.yearPoints.forEach(b => b.graphs.forEach(c => {
            if (!c.isClimate) graphs.push(c);
        })));
        store.indexByPointer.points.forEach(a => a.yearPoints.forEach(b => b.graphs.forEach(c => {
            if (c.isClimate) graphs.push(c);
        })));
        return  graphs;
    }
    getSeries(isLongPeriod:boolean): SeriesLine[]{//LineSeriesOption
        let ser: SeriesLine[] = [];//LineSeriesOption
        if(isLongPeriod){
            ser.push(this.getMonthLine());
        }
        let store = this.props.store;
        let data2: any[] = [];//список "текущих" точек
        let hasClimate = store.indexByPointer.graphs.find(a => a.isClimate && a.visible) != null;

        let graphs: IndexByPointGraphStore[] = this.getGraphStores();
        graphs.forEach(q => {
            if (q.isClimate && (q.dataType == IndexDataType.vegIndex || q.dataType == IndexDataType.ndvi250)) return;
            if (q.visible) {
                let color: string;
                if (store.indexByPointer.compareSources){
                    color = ColorHelper.numToCssHexRgb(q.colorByDataType);
                }else{
                    color = ColorHelper.numToCssHexRgb(q.color);
                }


                let gd = q.graphData;
                if (gd.hasMinMax){
                    let minData: ISeriesValue[] = [];
                    gd.data.forEach(a => {
                        minData.push({virtualValue: a.min + this.base, realValue: a.min, dayOfYear: a.dayOfYear});
                    });
                    let lineInfo = this.getGraphLine(color, q.isClimate, q.id, minData, LineType.areaMin);
                    ser.push(lineInfo.line);
                    let valueData: ISeriesValue[] = [];
                    gd.data.forEach(a => {
                        valueData.push({virtualValue: a.value - a.min, realValue: a.value, dayOfYear: a.dayOfYear});
                    });
                    lineInfo = this.getGraphLine(color, q.isClimate, q.id, valueData, LineType.areaValue);
                    ser.push(lineInfo.line);

                    let maxData: ISeriesValue[] = [];
                    gd.data.forEach(a => {
                        maxData.push({virtualValue: a.max - a.value, realValue: a.max, dayOfYear: a.dayOfYear});
                    });
                    lineInfo = this.getGraphLine(color, q.isClimate, q.id, maxData, LineType.areaMax);
                    ser.push(lineInfo.line);

                }else{
                    let serValues: ISeriesValue[] = [];
                    gd.data.forEach(a => serValues.push({virtualValue: a.value + this.base, realValue: a.value, dayOfYear: a.dayOfYear, sceneID: a.sceneID}));
                    let lineInfo = this.getGraphLine(color, q.isClimate, q.id, serValues, LineType.simple);
                    data2.push(...lineInfo.curItems);
                    ser.push(lineInfo.line);
                }
            }
        });

        ser.push({//тень текущей точки (текущий снимок, она большой двойной круг)
            type: "line",
            id: "overlay",
            silent: true,
            //tooltip:{show: false},
            itemStyle:{
                shadowColor: 'rgba(0,0,0,0.5)',
                shadowBlur: 3,
                shadowOffsetY: 3
            },
            lineStyle:{width: 0
            },
            data: data2,
            animationDuration: 200,
            z: 999,
            zlevel: 999,
        });

        return ser;
    }
       
    getGraphLine(color: string, isClimate: boolean, id: string,
                 dataGraph: ISeriesValue[], lineType: LineType): {line: SeriesLine, curItems: any[]}{
        let data: any[] = [];
        let data2: any[] = [];
        let store = this.props.store;
        let idLine = id;
        if (lineType == LineType.areaMin) idLine += Postfix_AreaMin;
        if (lineType == LineType.areaValue) idLine += Postfix_AreaValue;
        if (lineType == LineType.areaMax) idLine += Postfix_AreaMax;

        let line: SeriesLine = {//LineSeriesOption
            type: "line",
            symbolSize: (value: any)=>{
                return (value[2] == null)? 1 : 7;//Есть ли sceneId
            },
            //clip: false,
            //areaStyle: {},
            hoverAnimation: false,
            symbol: this.props.store.indexByPointer.showVertex? 'circle':'none',
            itemStyle:{
                color: color,
            },
            lineStyle: {
                width: (isClimate) ? 2 : 1,
                color: color,
                //type: (q.isClimate) ? "dashed": "solid",
                type: "solid",
                opacity: (!isClimate) ? 0.65 : 1,
                shadowColor: 'rgba(0,0,0,0.4)',
                shadowBlur: 3,
                shadowOffsetY: 3
            },
            smooth: true,
            dimensions: ["date", "value"],
            id: idLine,
            name: color,
        };
        if (lineType == LineType.areaMin || lineType == LineType.areaValue || lineType == LineType.areaMax){
            line.stack = id;
        }
        if (lineType == LineType.areaMin) {
            line.symbol = "none";
            if (line.lineStyle == null) line.lineStyle = {};
            line.lineStyle.opacity = 0;
        }
        if (lineType == LineType.areaValue) {
            if (line.areaStyle == null) line.areaStyle = {};
            line.areaStyle.opacity = 0.2;
        }
        if (lineType == LineType.areaMax) {
            line.symbol = "none";
            if (line.lineStyle == null) line.lineStyle = {};
            line.lineStyle.opacity = 0;
            if (line.areaStyle == null) line.areaStyle = {};
            line.areaStyle.opacity = 0.2;
        }
        if (store.indexByPointer.topGraphId == id){
            line.z = 80;
            line.zlevel = 80;
        }

        let indexes = dataGraph;
        indexes.forEach((a, idx) => {
            let d = a.dayOfYear;
            if (a.virtualValue == null) return;
            let value: any[] = [
                d,
                a.virtualValue,
                a.sceneID,
                color,
                a.realValue
            ];

            if (store.searchPanel.currentSceneid == a.sceneID && a.sceneID != null){
                let svg = `<svg width="140" height="140" xmlns="http://www.w3.org/2000/svg"><ellipse stroke="${color}" ry="60" rx="60" cy="70" cx="70" stroke-width="20" fill="#262B32"/><ellipse ry="35" rx="35" cy="70" cx="70" fill="${color}"/></svg>`;
                let baseSvg = 'image://'+'data:image/svg+xml;base64,'+btoa(svg);
                let item2 = {
                    value: value,
                    symbol: baseSvg,
                    symbolSize: 14
                };
                let item = {
                    value: value,
                    symbolSize: (a.sceneID == null)? 0 : 7
                };

                data.push(item);
                data2.push(item2);
            }else
            if (store.searchPanel.hoverSceneid == a.sceneID && a.sceneID != null){
                let item = {
                    value: value,
                    symbolSize: 10
                };
                data.push(item);
            }else data.push(value);
        });
        line.data = data;
        return {line: line, curItems: data2};
    }

    getMinMaxValue(): IMinMax{
        let graphs = this.getGraphStores();
        let min: number = null;
        let max: number = null;
        let check = (v: number) => {
            if (min == null || v < min) min = v;
            if (max == null || v > max) max = v;
        }
        graphs.forEach(q => {
            if (q.isClimate && (q.dataType == IndexDataType.vegIndex || q.dataType == IndexDataType.ndvi250)) return;
            if (q.visible) {
                let gd = q.graphData;
                gd.data.forEach(a =>{
                    check(a.value);
                });
                if (gd.hasMinMax){
                    gd.data.forEach(a =>{
                        if (a.min != null) check(a.min);
                        if (a.max != null) check(a.max);
                    });
                }
            }
        });
        if (min == null) return {min: 0, max: 1};
        let d = max - min;
        if (d == 0) {
            return {min: min, max: min + 1};
        }
        if (d < 0.0001){
            return {min, max};
        }
        min = min - d / 8.0;
        max = max + d / 8.0;
        return {min, max};
    }
    getMonthLine(): SeriesLine{
        let arr: any[] = [];
        let store = this.props.store;
        const monthNames = store.getLang() === Lang.ru ? 
        ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек", "Янв"] :
        ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan"];
        
        let m1 = Utils.getGlobalMonthNumber(store.indexByPointer.getBeginDate(2020));
        let m2 = Utils.getGlobalMonthNumber(store.indexByPointer.getEndDate(2020))+1;

        for(let i = m1; i <= m2; i++){
            let d1 = Utils.getDateByGlobalMonth(i);
            let d2 = Utils.getDayOfYearRelativeByGlobalAllLeapYears(d1, 2020);
            let monthNum = d1.getMonth();
            let t = {
                    name:'aaa',
                    xAxis: d2,
                    label:{
                        position: 'start',
                        fontSize: 10,
                        distance: 6,
                        formatter: monthNames[monthNum],
                    }
                };
            arr.push(t);
        }

        let s: SeriesLine = {
            data: [],
            type: 'line',
            markLine: {
                symbolSize: 0,
                silent: true,
                lineStyle:{
                    type: "solid",
                    color: "#C5C5C5",
                    width: 1,
                    opacity: 0.1,
                },
                data: arr as any,
            },
            animation: false
        };
        return  s;
    }  
    //Возвращает true, если что-то поменялось
    setSatellitesSearchFilterByIndexByPointSettings(): boolean{
        let store = this.props.store;
        let ok = false;
        //if (store.searchPanel.filterSatellites
        if (store.indexByPointer.satellites.find(a => a == Satellite["Sentinel-2"]) != null && !store.searchPanel.filterSatellites.S2){
            store.searchPanel.filterSatellites.S2 = true;
            ok = true;
        }
        if (store.indexByPointer.satellites.find(a => a == Satellite["Landsat-8"]) != null && !store.searchPanel.filterSatellites.L8){
            store.searchPanel.filterSatellites.L8 = true;
            ok = true;
        }
        if (store.indexByPointer.satellites.find(a => a == Satellite["Landsat-7"]) != null && !store.searchPanel.filterSatellites.L7E){
            store.searchPanel.filterSatellites.L7E = true;
            ok = true;
        }
        if ((store.indexByPointer.satellites.find(a => a == Satellite["Landsat-4"]) != null ||
            store.indexByPointer.satellites.find(a => a == Satellite["Landsat-5"]) != null
            ) && !store.searchPanel.filterSatellites.L45){
            store.searchPanel.filterSatellites.L45 = true;
            ok = true;
        }
        return ok;
    }
    @action
    async onClick(arg: IChartEvent){
        let store = this.props.store;
        let sceneId = arg.value[2];

        if (!sceneId) return;
       
        let needWait = false;
        if (store.searchPanel.leftPanelMode != LeftPanelMode.search){
            store.searchPanel.switchPanel(LeftPanelMode.search);
            needWait = true;
        }
        if (this.setSatellitesSearchFilterByIndexByPointSettings()){
            await store.searchPanel.refreshImmediately();
        }
        await when(() => {
            let ok = store.searchPanel.searchResult.searchState != LoadStatus.loading;
            if (!ok) needWait = true;
            return ok;
        });
        if (needWait) await Utils.pauseAsync(500);


        //let sceneId = this.graphLines[obj.seriesIndex].data[obj.dataPointIndex].sceneID;
        if (store.searchPanel.currentSceneid == sceneId){
            store.searchPanel.currentSceneid = null;
        }else {
            store.searchPanel.currentSceneid = sceneId;
            let elem = document.getElementById(sceneId);
            if (elem != null) {
                Utils.scrollIntoView(elem);
            }
        }
        let q = this.getGraphBySeries(arg.seriesName);
        if (!q) return;
        store.indexByPointer.currentGraphId = q.id;
        //насильно включаем видимость снимка
        store.searchPanel.currentSceneVisible = true;
        if (store.map.zoom < 11){
            store.map.mapbox.setZoom(11);
        }

        //let smallBboxMap = Utils.getInnerBounds(store.map.bbox.getLatLon(), IndexByPointChart2Comp.KOEF_MAP_CLICK);

        //центровка
        let newCenterCoord: mapboxgl.LngLat = null;
        if (q.gPoint.areaType == IndexAreaType.point) {

            //if (!Utils.pointInBbox(smallBboxMap, q.gPoint.point.getMbPoint())) {
                this.toCenter(q.gPoint.point.getMbPoint(), q);
            //}
            if (!this.pointInSpatialFilter(q)) {
                store.addInfo(store.trans["The point is not included in the spatial filter of image search"]);
            }
        }
        // if (!q.yearPeriod.isClimate){
        //     const date = Utils.getDateByRelativeByGlobalAllLeapYears(arg.value[0],q.year)
        //     console.log(date)
        //     const beginDate = new Date(date);
        //     beginDate.setMonth(beginDate.getMonth() - 1);         
        //     const endDate = new Date(date);
        //     endDate.setMonth(endDate.getMonth() + 1);  
            
        //     if (beginDate.getFullYear() < q.year || endDate.getFullYear() > q.year) {
        //         store.searchPanel.filterDate.begin = new Date(q.year, 9, 1);
        //         store.searchPanel.filterDate.end = new Date(q.year, 11, 31); 
        //     } else {
        //         store.searchPanel.filterDate.begin = beginDate;
        //         store.searchPanel.filterDate.end = endDate;
        //     }       
        // }
        if (q.gPoint.areaType == IndexAreaType.field) {
            if (q.gPoint.fieldGeometry == null) {
                store.agro2.getField(q.gPoint.field_id).then((f) => {
                    runInAction(() => {
                        let c = center(f as any);
                        q.gPoint.fieldGeometry = f.geometry;
                        newCenterCoord = MBUtils.positionToLL(c.geometry.coordinates);
                        //if (!Utils.pointInBbox(smallBboxMap, newCenterCoord)) {
                            q.gPoint.point.set(newCenterCoord.lat, newCenterCoord.lng);
                            this.toCenter(newCenterCoord, q);
                        //}
                        if (!this.pointInSpatialFilter(q)) {
                            store.addInfo(store.trans["Polygon is not included in the spatial filter of images search"]);
                        }
                    });
                }).catch(err => {
                    store.addError(err);
                });
            }else{
                this.toCenter(q.gPoint.point.getMbPoint(), q);
                if (!this.pointInSpatialFilter(q)){
                    store.addInfo(store.trans["Polygon is not included in the spatial filter of images search"]);
                }
            }
        }
    }

    //проверяет попадает ли точка(или поле) в пространственный фильтр поиска снимков (экран или полигон)
    pointInSpatialFilter(q: IndexByPointGraphStore):boolean{
        let polygon = this.props.store.map.searchObject.searchGeometry;
        if (q.gPoint.areaType == IndexAreaType.field && q.gPoint.fieldGeometry != null) {
            return booleanIntersects(q.gPoint.fieldGeometry as any, polygon as any);
        }
        if (q.gPoint.areaType == IndexAreaType.point && !q.gPoint.point.isNull()) {
            let p = q.gPoint.point.getMbPoint();
            return booleanPointInPolygon(MBUtils.llToPosition(p), polygon as any);
        }

        return false;
    }

    toCenter(newCenterCoord: mapboxgl.LngLat, q: IndexByPointGraphStore){
      
        let store = this.props.store;
        let scrP = store.map.mapbox.project(newCenterCoord);
        let needCenter = true;
        if (scrP.x != Number.MAX_VALUE) {
            let w = store.map.mapbox.getCanvas().width / window.devicePixelRatio;
            let h = store.map.mapbox.getCanvas().height / window.devicePixelRatio;
            if ((scrP.x > w / 6 && scrP.x < w / 6 * 5) && (scrP.y > h / 6 && scrP.y < h / 6 * 5)) {
                needCenter = false;
            }
        }

        store.searchPanel.filterCloud = 100;
        if (!q.yearPeriod.isClimate){
            store.searchPanel.filterDate.begin = q.parent.getBeginDate(q.year)
            store.searchPanel.filterDate.end = q.parent.getEndDate(q.year, false);
        }

        //store.searchPanel.filterSatellites.L8 = true;
        //store.searchPanel.filterSatellites.S2 = true;
        if (needCenter) {
            let newZoom = Math.max(store.map.mapbox.getZoom(), MapComp.MIN_SEARCH_ZOOM);
            store.map.mapbox.flyTo({zoom: newZoom, center: newCenterCoord});
        }
    }

    @action
    onClickVertex(){
        let store = this.props.store;
        store.indexByPointer.showVertex = !store.indexByPointer.showVertex;
    }

    @observable
    showMonths: boolean = false;
    @observable
    showDowloadPopup: boolean = false;
    @action
    onCloseMonthsPopup(){
        this.showMonths = false;
    }
    @action
    onShowMonthsPopup(){
        this.showMonths = true;
    }

    @observable
    settingsDropDown: boolean = false;
    @action
    onClickSettings(){
        this.settingsDropDown = !this.settingsDropDown;
    }
    @action
    onDowloadPopup(){
        this.showDowloadPopup = !this.showDowloadPopup;
    }
    @action
    onDowloadPopupClose(){
        this.showDowloadPopup = false;
    }
    @action
    onExportImage(){
        let elem = this.graphRef.current;// document.getElementById(IdChart);
        //let elem = document.getElementsByClassName("IndexByPointTableComp-cont");
        let ths = this;
        // if (elem.length > 0) {
        //     DomToImage.toBlob(elem[0] as Node)
        if (elem) {
            DomToImage.toBlob(elem)
                .then(function (blob) {
                    Utils.downloadBlob(blob, 'image.png');
 
                    ra(()=> {
                        ths.showDowloadPopup = false;
                    });
                }).catch(err =>{
                    ra(()=>{
                        ths.props.store.addError(err);
                        ths.showDowloadPopup = false;
                    });
            });
        }
    }


    onExportCsv(){
        let data = this.createCsvData();
        let txt = this.creeateCsvFile(data.columns, data.cells);
        Utils.downloadText("export.csv", txt);
    }

    @action
    async onExportExcel(){
        try {
            this.showDowloadPopup = false;
            let data = this.createCsvData();
            let txt = this.creeateCsvFile(data.columns, data.cells);
            let blob = await this.props.store.api.downloadExcel(txt, ";");
            Utils.downloadBlob(blob, "export.ods");
        }catch (err){
            this.props.store.addError(err);
        }
    }

    createCsvData(): {columns: string[], cells: Map<number, Map<string, string>>}{
        let store = this.props.store;
        let columns: string[] = [];
        let cells = new Map<number, Map<string, string>>();// номер дня года
        let addCell = (day: number, key: string, value: string) =>{
            if (!cells.has(day)) cells.set(day, new Map<string, string>());
            let m = cells.get(day);
            m.set(key, value.toString());
        };
        //заголовки
        store.indexByPointer.graphs.forEach(q => {
            if (q.isClimate && (q.dataType == IndexDataType.vegIndex || q.dataType == IndexDataType.ndvi250)) return;
            if (q.visible) {
                let src = getShortNameOfIndexDataType(q.dataType, store, false);
                if ([IndexDataGroup.temperature, IndexDataGroup.precipitation, 
                    IndexDataGroup.relativeHumidity, IndexDataGroup.solarRadiation,
                    IndexDataGroup.soilMoisture].indexOf(q.dataGroup) >= 0)
                    src = TranslateUtils.getTitleIndexDataGroup(q.dataGroup, store, false);

                let period: string = "";
                if (q.yearPeriod.isClimate) period = store.trans.Climate;
                else{
                    if (q.parent.isCrossYear){
                        period = q.yearPeriod.year + " - "+(q.yearPeriod.year + 1);
                    }else period = q.yearPeriod.year.toString();
                }
                let numPointText = "";

                if (q.gPoint.areaType == IndexAreaType.point) numPointText = q.gPoint.pointName;
                if (q.gPoint.areaType == IndexAreaType.field) numPointText = "Field: "+q.gPoint.field_name;

                let colName = `Point_${numPointText}_${src}_${period}`;

                columns.push(colName);
                let indexes = q.graphData.data;
                let colName2 = "SceneId";
                if (q.dataGroup == IndexDataGroup.spectral){
                    columns.push(colName2);
                }
                indexes.forEach(a => {
                    if (q.dataGroup == IndexDataGroup.spectral) {
                        if (a.sceneID != null) {
                            addCell(a.dayOfYear, colName2, a.sceneID);
                            addCell(a.dayOfYear, colName, a.value.toString());
                        }else{

                        }
                    } else{
                        addCell(a.dayOfYear, colName, a.value.toString());
                    }
                });
            }
        });
        return {columns, cells};
    }
    creeateCsvFile(columns: string[], cells: Map<number, Map<string, string>>): string{
        let store = this.props.store;
        let del = ";";
        let lt = "\n";
        let txt = `DOY${del}date${del}`;
        columns.forEach(a => txt += a+del);
        txt += lt;
        cells.forEach((value, key) => {
            //let date = Utils.getDateByGlobalAllLeapYears(key);
            let d = Utils.getDateByRelativeByGlobalAllLeapYears(key, 2020);
            let month = DateUtils.getMonthName(d.getMonth(), store.trans);
            txt += `${key + 1}${del}${d.getDate()} ${month}${del}`;
            columns.forEach(a =>{
                if (!value.has(a)) txt += del;
                else{
                    let v = value.get(a);
                    txt += v + del;
                }
            });
            txt += lt;
        });
        return txt;
    }
    @action
    onClickMinMax(){
        let store = this.props.store;
        if (store.indexByPointer.temperatureCategory == TemperatureCategory.averageMinMaxDaily){
            store.indexByPointer.temperatureCategory = TemperatureCategory.averageDaily;
        }else{
            store.indexByPointer.temperatureCategory = TemperatureCategory.averageMinMaxDaily;
        }
        this.props.store.indexByPointer.graphs.forEach((a) => {
            runInAction(() => {
                a.resetStatus();
            });
        });

    }

    render() {
        let opt = this.getOptions();
        let store = this.props.store;
        let singleDataType: IndexDataType = null;
        if (store.indexByPointer.typesData.length == 1) singleDataType = store.indexByPointer.typesData[0];
        return <div className={this.props.className}>
            <div className="indexByPoint-chartDivChart" ref={this.graphRef}>
                <MyChart options={opt} class="IndexByPointChart2Comp" id={IdChart} ref={this.myChartRef}
                         onClick={this.onClick}/>
            </div>
            <div className="indexByPoint-chartDivBottom">
                <div className="flex-columns-centerByVertical width100" style={{paddingTop: "15px",gap:"4px"}}>
                    <div className="flex-stretch-item flex-columns IndexByPointChart2Comp-source-compare-div">
                        {store.indexByPointer.dataGroup == IndexDataGroup.spectral &&
                            <CheckboxMiniComp state={store.indexByPointer.showVertex} size={CheckboxSize.switcher} onClick={this.onClickVertex}>
                                <span className="text-next pointer">{store.trans["Show vertices"]}</span>
                            </CheckboxMiniComp>
                        }
                        {store.indexByPointer.dataGroup != IndexDataGroup.spectral && store.indexByPointer.compareSources &&
                            <IndexByPointLinesComp store={store} />
                        }
                        {store.indexByPointer.dataGroup != IndexDataGroup.spectral && !store.indexByPointer.compareSources && singleDataType != null &&
                            <div className="flex-columns-centerByVertical flex-stretch-item text-next"><span>{getShortNameOfIndexDataType(singleDataType, store)}</span></div>
                        }
                    </div>

                    {(store.indexByPointer.dataGroup == IndexDataGroup.temperature &&
                        (store.indexByPointer.temperatureCategory == TemperatureCategory.averageMinMaxDaily ||
                            store.indexByPointer.temperatureCategory == TemperatureCategory.averageDaily)) &&
                    <CheckboxMiniComp state={store.indexByPointer.temperatureCategory == TemperatureCategory.averageMinMaxDaily}
                                      onClick={this.onClickMinMax} classesContainer='IndexByPointChart2Comp-checkbox-minmax'
                                      size={CheckboxSize.switcher} >
                        <span className="pointer">Min-Max</span>
                    </CheckboxMiniComp>}
                    <div className="IndexByPointChart2Comp-chartButtonDiv">
                        <div className="IndexByPointChart2Comp-chartButton" id="test123" onClick={this.onDowloadPopup}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.9999 10.5C7.2624 10.5 7.4374 10.4125 7.6124 10.2375L12.5999 5.25L11.3749 4.025L7.8749 7.525V0H6.1249V7.525L2.6249 4.025L1.3999 5.25L6.3874 10.2375C6.5624 10.4125 6.7374 10.5 6.9999 10.5Z" stroke="none"/>
                                <path d="M13.125 12.25H0.875V14H13.125V12.25Z" stroke="none"/>
                            </svg>
                        </div>
                        {this.showDowloadPopup && <ContextMenuCommonComp onClose={this.onDowloadPopupClose}
                                                                   direction={PopupDirection.horizontal}
                                                                   popupCorner={PopupCorner.rightBottom}
                        >
                            <ContextItems items={[
                                {onClick: this.onExportImage, title: "Image PNG", key: "png"},
                                {onClick: this.onExportCsv, title: "Text CSV", key: "CSV"},
                                {onClick: this.onExportExcel, title: "OpenDocument (.ods)", key: "ods"}
                            ]} />
                        </ContextMenuCommonComp>}
                    </div>
                    {(this.props.calendarButtonVisible??true) && <React.Fragment>
                    <div className="IndexByPointChart2Comp-chartButtonSeparate" />
                    <div className="IndexByPointChart2Comp-chartButtonDiv">
                        <div className="IndexByPointChart2Comp-chartButton" onClick={this.onShowMonthsPopup}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.25 2.625H11.375V0.875C11.375 0.642936 11.2828 0.420376 11.1187 0.256282C10.9546 0.0921872 10.7321 0 10.5 0C10.2679 0 10.0454 0.0921872 9.88128 0.256282C9.71719 0.420376 9.625 0.642936 9.625 0.875V2.625H4.375V0.875C4.375 0.642936 4.28281 0.420376 4.11872 0.256282C3.95462 0.0921872 3.73206 0 3.5 0C3.26794 0 3.04538 0.0921872 2.88128 0.256282C2.71719 0.420376 2.625 0.642936 2.625 0.875V2.625H1.75C1.28587 2.625 0.840752 2.80937 0.512563 3.13756C0.184374 3.46575 0 3.91087 0 4.375L0 12.25C0 12.7141 0.184374 13.1592 0.512563 13.4874C0.840752 13.8156 1.28587 14 1.75 14H12.25C12.7141 14 13.1592 13.8156 13.4874 13.4874C13.8156 13.1592 14 12.7141 14 12.25V4.375C14 3.91087 13.8156 3.46575 13.4874 3.13756C13.1592 2.80937 12.7141 2.625 12.25 2.625ZM1.75 12.25V6.125H12.25V12.25H1.75Z" stroke="none"/>
                            </svg>
                        </div>
                        {this.showMonths && <ContextMenuCommonComp onClose={this.onCloseMonthsPopup}
                                                                   direction={PopupDirection.horizontal}
                                                                   popupCorner={PopupCorner.leftBottom}
                        >
                            <IndexByPointPopupMonthComp store={store} onClose={this.onCloseMonthsPopup} />
                        </ContextMenuCommonComp>}
                    </div>
                    </React.Fragment>}
                    {(this.props.settingsButtonVisible??true) && <div className="IndexByPointChart2Comp-chartButtonDiv">
                        <div className="IndexByPointChart2Comp-chartButton" onClick={this.onClickSettings}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6375 4.55L12.6 2.7125L11.375 1.4875L9.5375 2.45C9.275 2.275 8.925 2.1875 8.575 2.1L7.875 0H6.125L5.425 2.0125C5.1625 2.1 4.8125 2.1875 4.55 2.3625L2.7125 1.4L1.4 2.7125L2.3625 4.55C2.1875 4.8125 2.1 5.1625 2.0125 5.425L0 6.125V7.875L2.0125 8.575C2.1 8.925 2.275 9.1875 2.3625 9.5375L1.4 11.375L2.625 12.6L4.4625 11.6375C4.725 11.8125 5.075 11.9 5.425 11.9875L6.125 14H7.875L8.575 11.9875C8.925 11.9 9.1875 11.725 9.5375 11.6375L11.375 12.6L12.6 11.375L11.6375 9.5375C11.8125 9.275 11.9 8.925 11.9875 8.575L14 7.875V6.125L11.9875 5.425C11.9 5.1625 11.8125 4.8125 11.6375 4.55ZM7 9.625C5.5125 9.625 4.375 8.4875 4.375 7C4.375 5.5125 5.5125 4.375 7 4.375C8.4875 4.375 9.625 5.5125 9.625 7C9.625 8.4875 8.4875 9.625 7 9.625Z" stroke="none"/>
                            </svg>
                        </div>
                        {this.settingsDropDown &&
                            <ContextMenuCommonComp onClose={this.onClickSettings}>
                                <IndexByPointPopupSettingsComp onClose={this.onClickSettings} store={store} />
                            </ContextMenuCommonComp>
                        }
                    </div>}
                </div>
            </div>
        </div>;
    }
}
