import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './PhotoTagEditorGroupTag.scss';
import classNames from "classnames";
import {PhotoTagGroupStore} from "../../../../../store/photo/PhotoTagGroupStore";
import {PhotoTagEditorTag} from "./PhotoTagEditorTag";
import {ButtonLight} from "../../../../Common/ButtonLight";
import {action, observable} from "mobx";
import {InputStringWindow} from "../../../../Common/InputStringWindow";
import {PhotoTagEditorWindow} from "./PhotoTagEditorWindow";
import {ContextMenuCommonComp} from "../../../../Common/ContextMenuCommonComp";
import {ConfirmFormComp} from "../../../../Common/ConfirmFormComp";

export interface IPhotoTagEditorGroupTag extends IStoreProps{
    group: PhotoTagGroupStore
}

@observer
export class PhotoTagEditorGroupTag extends React.Component<IPhotoTagEditorGroupTag, undefined> {
    constructor(props: IPhotoTagEditorGroupTag) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickExpand(){
        this.props.group.expanded = !this.props.group.expanded;
    }
    @observable
    editName: string = "";
    @observable
    showAddTagName: boolean = false;

    @action
    onClickAddTagButton(){
        this.editName = "";
        this.showAddTagName = true;
    }
    @action
    onChangeEditText(str: string){
        this.editName = str;
    }
    @action
    onCloseEdit(){
        this.showAddTagName = false;
        this.showRenameGroupTagName = false;
    }
    @action
    async doAddTag(str: string){
        this.showAddTagName = false;
        await this.props.store.photo.tagEditor.sendAddTag(this.props.group.id, this.editName);
    }
    @observable
    openConfirmDelete: boolean = false;
    @action
    async onClickDeleteButton(){
        this.openConfirmDelete = true;
    }
    @action
    confiremedDelete(){
        this.openConfirmDelete = false;
        this.props.store.photo.tagEditor.sendDeleteTagGroup(this.props.group.id);
    }
    @action
    confimedCancel(){
        this.openConfirmDelete = false;
    }

    @observable
    showRenameGroupTagName: boolean = false;
    @action
    async doRenameGroupTagName(){
        await this.props.store.photo.tagEditor.sendRenameTagGroup(this.props.group.id, this.editName);
        this.showRenameGroupTagName = false;
    }
    @action
    doOpenRenameGroupTag(){
        this.showRenameGroupTagName = true;
        this.editName = this.props.group.name;
    }

    render() {
        let gt = this.props.group;
        let store = this.props.store;
        let tags: any[] = [];
        if (gt.expanded) {
            gt.tagsWithFilters.forEach(a => {
                tags.push(<PhotoTagEditorTag tag={a} store={store} key={a.tag_id}/>);
            });
        }

        return <div className="flex-rows PhotoTagEditorGroupTag-m">
            <div className={classNames("PhotoTagEditorGroupTag-titleRow PhotoTagEditorWindow-showHoverInitiator",{"PhotoTagEditorWindow-showAnyway":this.openConfirmDelete})}>
                    <div className={classNames("qtransit full-center-content icon_container-mini16 pointer",{"rotate90":gt.expanded})} onClick={this.onClickExpand}>
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.54922 5.00005L1.49922 9.05005L0.449219 8.00005L3.44922 5.00005L0.449218 2.00005L1.49922 0.950052L5.54922 5.00005Z" fill="#C5C5C5"/>
                        </svg>
                    </div>
                    <div className="PhotoTagEditorGroupTag-name">{gt.name}</div>
                    <div className="PhotoTagEditorGroupTag-titleBadget">{gt.tagsWithFilters.length}</div>
                    <div className="flex-stretch-item"></div>
                    <div className={classNames("flex-columns flex-align-items-center PhotoTagEditorWindow-showHover")}>
                        <div className="icon_container-mini16 pointer" onClick={this.doOpenRenameGroupTag}>
                            <svg width="14" height="14" fill="#C5C5C5" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.0875 3.0625L0.2625 9.8875C0.0875 10.0625 0 10.2375 0 10.5V13.125C0 13.65 0.35 14 0.875 14H3.5C3.7625 14 3.9375 13.9125 4.1125 13.7375L10.9375 6.9125L7.0875 3.0625Z"/>
                                <path d="M13.7375 2.8875L11.1125 0.2625C10.7625 -0.0875 10.2375 -0.0875 9.8875 0.2625L8.3125 1.8375L12.1625 5.6875L13.7375 4.1125C14.0875 3.7625 14.0875 3.2375 13.7375 2.8875Z"/>
                            </svg>
                        </div>
                        <div className="icon_container_red icon_container-mini16 text-next pointer" onClick={this.onClickDeleteButton}>
                            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 4.375V2.625H10.5V0.875C10.5 0.392 10.1089 0 9.625 0H4.375C3.89112 0 3.5 0.392 3.5 0.875V2.625H0V4.375H14ZM5.25 1.75H8.75V2.625H5.25V1.75Z"/>
                                <path d="M11.375 6.125V12.25H2.625V6.125H0.875V13.125C0.875 13.608 1.26613 14 1.75 14H12.25C12.7339 14 13.125 13.608 13.125 13.125V6.125H11.375Z"/>
                            </svg>
                            {this.openConfirmDelete && <ContextMenuCommonComp
                                autoCornerWidth={125} indentVertical={10}
                                autoCornerHeight={155} onClose={this.confimedCancel}
                                className="">

                                <ConfirmFormComp text={store.trans["Are you sure you want to delete the group?"]}>
                                    <div className="flex-columns">
                                        <div className="red underline-link AgroConfirmDeleteFarmComp-button" onClick={this.confiremedDelete}>{store.trans.Delete}</div>
                                        <div className="white text-next-2 underline-link AgroConfirmDeleteFarmComp-button" onClick={this.confimedCancel}>{store.trans.Cancel}</div>
                                    </div>
                                </ConfirmFormComp>
                            </ContextMenuCommonComp>}
                        </div>
                        <ButtonLight className="pointer text-next ButtonLight-blueBackground-onhover" onClick={this.onClickAddTagButton}>
                            <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5625 0.25H3.4375V3.4375H0.25V5.5625H3.4375V8.75H5.5625V5.5625H8.75V3.4375H5.5625V0.25Z"/>
                            </svg>
                            {store.trans.Tag}
                        </ButtonLight>
                    </div>
            </div>
            {tags}
            {this.showAddTagName && <InputStringWindow value={this.editName}
                title={store.trans["Create tag"]} name={store.trans.Title} okText={store.trans.Create} cancelText={store.trans.Cancel}
                onChange={this.onChangeEditText} onCancelClick={this.onCloseEdit} onOkClick={this.doAddTag}
                isDisable={PhotoTagEditorWindow.isDisableEditText(this.editName)}  />
            }
            {this.showRenameGroupTagName && <InputStringWindow value={this.editName}
                title={store.trans["Changing the name of a tag group"]} name={store.trans.Title} okText={store.trans.Update} cancelText={store.trans.Cancel}
                onChange={this.onChangeEditText} onCancelClick={this.onCloseEdit} onOkClick={this.doRenameGroupTagName}
                isDisable={PhotoTagEditorWindow.isDisableEditText(this.editName)}  />
            }
        </div>;
    }
}
