import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditRoutePanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";
import {AhoUtils} from "../../agroAhoUtils";

@observer
export class AgroAhoEditRoutePanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onCancelClick(e: any){
        let rstore = this.props.store.agroAhoStore.mapStore.mapEditRouteStore;
        // rstore.doDisableModeClick();
        // console.log('cfg1', AhoUtils.cp(rstore.mapStore.lastCfg));
        rstore.doSelectModeClick(true);
        // console.log('cfg2', AhoUtils.cp(rstore.mapStore.lastCfg));
    }

    onSaveClick(e: any){
        let rstore = this.props.store.agroAhoStore.mapStore.mapEditRouteStore;
        let canSave = this.props.store.agroAhoStore.mapStore.mapEditRouteStore.canSave;
        if (!canSave) return;
        rstore.doSaveClick();
        // this.props.store.agroAhoStore.editorStore.doSaveClick();
    }

    onZoomClick(){
        this.props.store.agroAhoStore.mapStore.mapEditRouteStore.doZoomClick();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let mstore = store_.mapStore.mapEditRouteStore;
        let canSave = mstore.canSave;
        let field_name = mstore.getFieldName();

        return <div className="AgroAhoEditRoutePanelComp-main">
            <div className="AgroAhoEditRoutePanelComp-window">
                <div>{store_.trans.Field}: {field_name}</div>
                <div className="AgroAhoEditRoutePanelComp-caption">{store_.trans2["Set the detour order"]}</div>
                <div className="AgroAhoEditRoutePanelComp-zoomCover" onClick={this.onZoomClick}>
                    <AgroAhoZoomSvg/>
                </div>
                <div className="AgroAhoEditRoutePanelComp-splitter"> </div>
                <div className="AgroAhoEditRoutePanelComp-cancelButton" onClick={this.onCancelClick}>
                    {store_.trans.Cancel}</div>
                <div className={classNames("AgroAhoEditRoutePanelComp-saveButton", {
                    "AgroAhoEditRoutePanelComp-disabled": !canSave
                })} onClick={this.onSaveClick}>
                    {store_.trans.Save}</div>
            </div>
        </div>
    }
}
