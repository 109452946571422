import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {observable} from "mobx";

export class BandStaticStyleProductStore extends CustomStore{
    class(): string {
        return "BandStaticStyleProductStore";
    }
    @save @observable
    name: string;
    @save @observable
    min: number;
    @save @observable
    max: number;
    @observable
    _minEdit: string;
    get minEdit(): string{
        if (this._minEdit == null) this._minEdit = this.min.toString();
        return this._minEdit;
    }
    set minEdit(value: string){
        this._minEdit = value;
    }
    @observable
    _maxEdit: string;
    get maxEdit(): string{
        if (this._maxEdit == null) this._maxEdit = this.max.toString();
        return this._maxEdit;
    }
    set maxEdit(value: string){
        this._maxEdit = value;
    }

    public apply(){
        this.min = parseInt(this.minEdit);
        if (isNaN(this.min)){
            throw 'Error in min value';
        }
        this.max = parseInt(this.maxEdit);
        if (isNaN(this.max)){
            throw 'Error in max value';
        }
        if (this.min < 1){
            throw 'Minimum value less than 1';
        }
        if (this.min > 10000){
            throw 'Minimum value is more than 4000';
        }
        if (this.max < 1){
            throw 'Maximum value less than 1';
        }
        if (this.max > 10000){
            throw 'Maximum value is more than 4000';
        }
        if (this.min > this.max){//автоматом исправляем
            let t = this.min;
            this.min = this.max;
            this.max = t;
        }
    }
}