import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import "./UploadComp.scss";
import {action, reaction} from "mobx";
import {UploadItemComp} from "./UploadItemComp";
import {CheckboxMiniComp, CheckboxSize, TriState} from "../../../../Common/CheckboxMiniComp";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {PhotoFileItemStore} from "../../../../../store/photo/PhotoFileItemStore";
import {isNumber} from "lodash-es";
import {ProgressUploadComp} from "./ProgressUploadComp";
import {IReactionDisposer} from "mobx/lib/internal";
import VizSensor from "react-visibility-sensor";

@observer
export class UploadComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.props.store.photo.initTags();

        this.disp = reaction(()=>{return {aa: this.props.store.photo.uploadImageStore.generalStatusUpload};},()=>{
        });
    }

    disp:IReactionDisposer = null;

    componentWillUnmount() {
        if (this.disp != null) this.disp();
        this.disp = null;
    }

    centerPhotos(){
        let cLatMin = Number.MAX_VALUE;
        let cLatMax = Number.MIN_VALUE;
        let cLonMin = Number.MAX_VALUE;
        let cLonMax = Number.MIN_VALUE;

        let cnt = 0;
        this.props.store.photo.uploadImageStore.files.forEach(a =>{
            if (a.lat != null && a.lon != null){
                if (cLatMin > a.lat) cLatMin = a.lat;
                if (cLatMax < a.lat) cLatMax = a.lat;
                if (cLonMin > a.lon) cLonMin = a.lon;
                if (cLonMax < a.lon) cLonMax = a.lon;
                cnt++;
            }
        });
        if (cnt > 0){
            this.props.store.map.mapbox.fitBounds([
                [cLonMin, cLatMin], // southwestern corner of the bounds
                [cLonMax, cLatMax] // northeastern corner of the bounds
            ], {maxZoom: 17});
        }
    }

    @action
    onMinimize(){
        this.props.store.photo.uploadImageStore.showPanel = false;
    }
    @action
    onClose(){
        if (this.props.store.photo.uploadImageStore.generalStatusUpload == LoadStatus.ready) {
            this.centerPhotos();
            this.props.store.map.superTools.photoLayersTool.refreshTiles();
        }
        this.props.store.photo.uploadImageStore.files = [];
        this.props.store.photo.uploadImageStore.showPanel = false;
    }
    @action
    onCancelUpload(){
        this.props.store.photo.uploadImageStore.cancelUpload();
    }

    @action doUpload(){
        this.props.store.photo.uploadImageStore.startUpload();
    }
    @action
    onClickSelectAll(){
        this.props.store.photo.uploadImageStore.selectAll = !this.props.store.photo.uploadImageStore.selectAll;
    }
    @action
    onClickNoDups(){
        this.props.store.photo.uploadImageStore.noLoadDups = !this.props.store.photo.uploadImageStore.noLoadDups;
    }

    @action
    onZoomToPhoto(item: PhotoFileItemStore){
        if (isNumber(item.lon)) {
            this.props.store.photo.uploadImageStore.showPanel = false;
            this.props.store.map.mapbox.flyTo({zoom: 16, center: {lat: item.lat, lng: item.lon}});
        }
    }

    render() {
        let store = this.props.store;
        let arr: any[] = [];
        let listFiles = store.photo.uploadImageStore;
        let enableUpload = true;

        listFiles.files.forEach((a, index) => {
            arr.push(
                <VizSensor key={index.toString()} partialVisibility={true}>{
                (args: { isVisible: boolean }) => {
                    if (args.isVisible){
                        return <UploadItemComp key={index.toString()} item={a} store={store} />
                    }else {
                        return <div key={index.toString()} className="UploadComp-emptyDiv"/>
                    }
                }}</VizSensor>
            );
            arr.push();
        });
        let uis = store.photo.uploadImageStore;

        return (
            <div className="modal-back">
                <div className="modal-full modal-full-frame UploadComp-main-div">
                    <div className="UploadComp-title-m flex-align-items-center">
                        <div className="flex-stretch-item UploadComp-title-label">{store.trans["Upload photos"]}</div>

                        {uis.files.length > 0 &&
                            (uis.generalStatusUpload != null || uis.hasError) &&
                            <div className="UploadComp-globalProgress-div">
                                {<div className="text-center">{Math.round(uis.uploadSize / uis.totalSize * 100)}%</div>}
                                <ProgressUploadComp className="PhotoPanel-Footer-progress" color={(uis.hasError)?"red":(uis.generalStatusUpload == LoadStatus.ready)?"green":null}
                                                    progress={uis.uploadSize / uis.totalSize} />
                                {(uis.generalStatusUpload == LoadStatus.loading) && <div className="text-center">{store.trans["Loading"]}</div>}
                                {(uis.generalStatusUpload == LoadStatus.ready) && <div className="text-center">{store.trans["Loading is complete"]}</div>}
                                {(uis.generalStatusUpload == null && uis.hasError) && <div className="text-center">{store.trans["Loading stopped"]}</div>}
                            </div>
                        }
                    </div>

                    <div className="flex-columns UploadComp-topButton-m flex-align-items-center UploadComp-margin-leftRigth">
                        <div className="flex-columns flex-align-items-center">
                            <input type="checkbox" className="css-checkbox-1" id="upload-select-all"
                                   checked={store.photo.uploadImageStore.noLoadDups}
                                   onChange={this.onClickNoDups}/>
                            <label htmlFor="upload-select-all" className="css-label-1" style={{margin: 0}}>{store.trans["Don't download possible duplicates"]}</label>
                        </div>
                    </div>
                    <div className="separator_line" />
                    <div className="style-4 overflowAutoY flex-stretch-item UploadComp-margin-leftRigth UploadComp-selectAll-div">
                        <div className="overflowVisible">
                            <div className="flex-columns flex-align-items-center" style={{marginBottom: "15px"}}>
                                <CheckboxMiniComp state={store.photo.uploadImageStore.selectAll?TriState.check:TriState.uncheck} classesBox="UploadItemComp-checkboxItem-m"
                                                  size={CheckboxSize.standart} onClick={this.onClickSelectAll} />
                                <span className="pointer" onClick={this.onClickSelectAll}>{store.trans["Select all"]}</span>
                            </div>
                            {arr}
                        </div>
                    </div>
                    <div className="separator_line" />
                    <div className="UploadComp-margin-leftRigth UploadComp-bottomButtons-margin">
                        {listFiles.generalStatusUpload == LoadStatus.loading &&
                        <button
                            className="large-button left-sidebar-cancel button-jump popup-exp-2button-button text-next UploadComp-bottomButtom-m"
                            onClick={this.onCancelUpload}>{store.trans.Cancel}
                        </button>
                        }
                        {listFiles.generalStatusUpload == LoadStatus.ready &&
                        <button
                            className="large-button left-sidebar-cancel button-jump popup-exp-2button-button text-next UploadComp-bottomButtom-m"
                            onClick={this.onClose}>{store.trans.Close}
                        </button>
                        }

                        {listFiles.generalStatusUpload == null && <React.Fragment>
                            <button onClick={this.doUpload}
                                    disabled={!enableUpload}
                                    className="large-button left-sidebar-active button-jump popup-exp-2button-button UploadComp-bottomButtom-m">
                                {store.trans.Upload}
                            </button>
                            <button
                                className="large-button left-sidebar-cancel button-jump popup-exp-2button-button text-next UploadComp-bottomButtom-m"
                                onClick={this.onClose}>{store.trans.Cancel}
                            </button>
                        </React.Fragment>}

                    </div>
                </div>
            </div>);
    }
}
