import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoStmt3Comp.scss'
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";
import {AgroAhoStepsComp} from "../uploadTable/AgroAhoStepsComp";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoSortColSvg} from "../icons/AgroAhoSortColSvg";
import {AgroAhoSwitcherSvg} from "../icons/AgroAhoSwitcherSvg";
import {AhoStmtSort} from "../../stmt/agroAhoStmtStore";
import {AgroAhoStmtIndcItem} from "./AgroAhoStmtIndcItem";
import {AgroAhoStmtExtItem} from "./AgroAhoStmtExtItem";
import Tooltip from 'rc-tooltip';
import {AgroAhoCrossSvg} from "../icons/AgroAhoCrossSvg";

@observer
export class AgroAhoStmt3Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    canNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        return !st.isExtResearch || !!st.exts.filter(e=>e.ext).length;
    }
    onDownloadInstructionsClick(){
        // let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        // Utils.downloadFile(url);
    }
    onClickNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (!this.canNext()) return;
        st.doNextClick();
    }
    onClickCancel(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doCancel();
    }
    onClickBack(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doBackClick();
    }

    onMarkMapClick(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.onMarkMapClick();
    }
    getTooltipList(arr: any[]){
        // let arr_ = arr.slice(0, 5);
        let arr__ = arr.map(i=><li key={i} className="AgroAhoStmt3Comp-mainIndcsItem">{i}</li>);
        return <div className="AgroAhoStmt3Comp-mainIndcsCover">
            <div className="AgroAhoStmt3Comp-mainIndcs style-4">
                <ul>{arr__}</ul>
                {/*{arr.length > 5 && <span>...</span>}*/}
            </div>
        </div>;
    }
    onExtResetClick(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doExtFieldReset();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore
        let st = store_.stmtStore;
        let arr = st.exts.map(i=><AgroAhoStmtExtItem key={i.cell_id} store={store} item={i}/>);
        let extCnt = st.exts.filter(f=>f.ext).length;
        let mainArr = st.indcs.filter(f=>f.main).map(i=>`${i.indc_name} (${i.standard})`);
        let mainIndcCnt = mainArr.length;
        let mainIndcs = this.getTooltipList(mainArr);
        let extArr = st.indcs.filter(f=>f.ext).map(i=>`${i.indc_name} (${i.standard})`);
        let extIndcCnt = extArr.length;
        let extIndcs = this.getTooltipList(extArr);

        return <div className="AgroAhoStmt3Comp-main">
            <div className="AgroAhoStmt3Comp-cover">
                <div className="AgroAhoStmt3Comp-topPanel">
                    <div className="AgroAhoStmt3Comp-titleCover">
                        <div className="AgroAhoStmt3Comp-title"><b>{store_.trans['Research plan']}.</b> {
                            store_.trans['Step 3: Selecting cells for advanced exploration']}</div>
                        <AgroAhoStepsComp step={st.stage} steps={4} style2={true}/>
                    </div>
                    <div className="AgroAhoStmt3Comp-msg">
                        <span>{store_.trans['Select from the list the cells for which you want to perform an extended study. You can select cells via the map (Mark on map button)']}</span>
                    </div>
                </div>
                <div className="AgroAhoStmt3Comp-centerPanel">
                    <div className="AgroAhoStmt3Comp-tableHover">
                        <div className="AgroAhoStmt3Comp-colField">
                            <div className="AgroAhoStmt3Comp-colCaption">{store_.trans['Field name']}</div>
                        </div>
                        <div className="AgroAhoStmt3Comp-colCell">
                            <div className="AgroAhoStmt3Comp-colCaption">{store_.trans['Cell name']}</div>
                        </div>
                        <div className="AgroAhoStmt3Comp-colResearch">
                            <div className="AgroAhoStmt3Comp-colCaption">{store_.trans['Main research']}</div>
                            {!!mainIndcCnt && <Tooltip placement="bottomLeft" align={{offset: [40, 0]}}
                                overlayClassName={"AgroAhoStmt3Comp-tooltip"} overlay={mainIndcs}>
                                <span className="AgroAhoStmt3Comp-indcCounter">{store_.trans['Indicators:']} {mainIndcCnt}</span>
                            </Tooltip>}
                            {!mainIndcCnt && <span className="AgroAhoStmt3Comp-indcCounter AgroAhoStmt3Comp-defCur">{
                                store_.trans['Indicators:']} 0</span>}
                        </div>
                        <div className="AgroAhoStmt3Comp-colExtResearch">
                            <div className="AgroAhoStmt3Comp-colExtTopCover">
                                <div className={classNames("AgroAhoStmt3Comp-colCaption", {
                                    "AgroAhoStmt3Comp-disabled": !st.isExtResearch})}>{store_.trans['Extended research']}</div>
                                {!!st.isExtResearch && <React.Fragment>
                                    <div className="AgroAhoStmt3Comp-counter">
                                        <span>{extCnt}</span>
                                        <div className="AgroAhoStmt3Comp-crossCover" onClick={this.onExtResetClick}>
                                            <AgroAhoCrossSvg className="AgroAhoStmt3Comp-cross"/>
                                        </div>
                                    </div>
                                    <div className="AgroAhoStmt3Comp-markMapButton" onClick={this.onMarkMapClick}>
                                        {store_.trans['Mark on map']}</div>
                                </React.Fragment>}
                            </div>
                            {!!st.isExtResearch && !!extIndcCnt && <Tooltip placement="bottomLeft" align={{offset: [40, 0]}}
                                overlayClassName={"AgroAhoStmt3Comp-tooltip"} overlay={extIndcs}>
                                <span className="AgroAhoStmt3Comp-indcCounter">{store_.trans['Indicators:']} {extIndcCnt}</span>
                            </Tooltip>}
                            {!!st.isExtResearch && !extIndcCnt && <span className="AgroAhoStmt3Comp-indcCounter AgroAhoStmt3Comp-defCur">{
                                store_.trans['Indicators:']} 0</span>}
                        </div>
                    </div>
                    <div className="AgroAhoStmt3Comp-table style-4">
                        {arr}
                    </div>
                </div>
                <div className="AgroAhoStmt3Comp-bottomPanel">
                    <div className="AgroAhoStmt3Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoStmt3Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoStmt3Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <button className="left-sidebar-cancel button-jump AgroAhoStmt3Comp-backButton"
                            onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </button>
                    <button className={classNames("left-sidebar-active AgroAhoStmt3Comp-nextButton",
                         {"AgroAhoStmt3Comp-disabled": !this.canNext(), "button-jump": !!this.canNext()})}
                         onClick={this.onClickNext}>{store_.trans.Next}</button>
                </div>
            </div>
        </div>
    }
}
