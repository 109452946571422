import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {FavoriteExportStore} from "../../../../store/export/FavoriteExportStore";
import classNames from "classnames";
import {action} from "mobx";
import {isString} from "lodash-es";
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";

interface ISatelliteExportComp extends IStoreProps{
    satExport: FavoriteExportStore;
    beforeCheckbox?: any;
    isFusion: boolean;
    isMosaic: boolean;
}
@observer
export class SatelliteExportComp extends React.Component<ISatelliteExportComp, undefined> {
    constructor(props: ISatelliteExportComp) {
        super(props);
        autoBindReact(this);
    }
    @action onClick_apply_cutline(){
        this.props.satExport.apply_cutline = !this.props.satExport.apply_cutline;
    }

    @action onClick_albedo(){
        this.props.satExport.albedo = !this.props.satExport.albedo;
    }
    @action onClick_combine_products(){
        this.props.satExport.combine_products = !this.props.satExport.combine_products;
    }
    @action onClick_mergeScenes(){
        this.props.satExport.mergeScenes = !this.props.satExport.mergeScenes;
    }
    @action onChangeProjectName(event: any){
        this.props.store.exportStore.projectName = event.target.value;
    }
    @action onChangeEmail(event: any){
        this.props.store.exportStore.email = event.target.value;
    }

    @action onClickBand(event: any, data?: any){
        let name = data;
        if (isString(name)){
            this.props.satExport.setBandValue(name, !this.props.satExport.getBandValue(name));
        }
    }

    @action onClickIndex(event: any, data?: any){
        let name = data;
        if (isString(name)){
            this.props.satExport.setIndexValue(name, !this.props.satExport.getIndexValue(name));
        }
    }

    render() {
        let store = this.props.store;
        let es = this.props.satExport;
        let hasGeometry = store.map.searchObject.isNotEmpty;
        let isFusion = this.props.isFusion;
        let isMosaic = this.props.isMosaic;
        let arrBands: any[] = [];
        es.getBandsNames.forEach(k => {
            let v = es.getBandValue(k);
            arrBands.push(
                <div className="popup-exp-band" key={k}>
                    <CheckboxMiniComp state={v} size={CheckboxSize.standart} onClick={this.onClickBand} data={k} classesContainer="pointer">
                        <span className="text-next12 button-next8">{k}</span>
                    </CheckboxMiniComp>
                </div>
            );
        });
        let arrIndexes: any[] = [];
        es.getIndexesNames.forEach(k =>{
            let v = es.getIndexValue(k);
            arrIndexes.push(
                <div className="popup-exp-band" key={k}>
                    <CheckboxMiniComp state={v} size={CheckboxSize.standart} onClick={this.onClickIndex} data={k} classesContainer="pointer">
                        <span className="text-next12 button-next8">{k}</span>
                    </CheckboxMiniComp>
                </div>
            );
        });

        return <React.Fragment>
            <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            {store.trans["Task name"]}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className="text-box-editor width100"
                               onChange={this.onChangeProjectName}
                               value={store.exportStore.projectName}/>
                    </div>
                </div>
                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            {store.trans.Email}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className="text-box-editor width100"
                               onChange={this.onChangeEmail}
                               value={store.exportStore.email}/>
                    </div>
                </div>
            {this.props.beforeCheckbox}
            <div className={classNames(" ")}>
                <div className="popup-exp-visible popup-exp-crop" style={{marginTop: "5px"}}>
                    <input type="checkbox" className="switch_1" id="cut"
                           checked={es.apply_cutline && hasGeometry} disabled={!hasGeometry}
                           onChange={this.onClick_apply_cutline}/><label
                    htmlFor="cut"
                    className="css-label-2">{store.trans["Crop to active area"]}</label>
                </div>
                {!isMosaic && !isFusion && <div className="popup-exp-visible popup-exp-crop">
                    <input type="checkbox" className="switch_1" id="albedo"
                           checked={es.albedo}
                           onChange={this.onClick_albedo}/><label
                    htmlFor="albedo"
                    className="css-label-2">{store.trans["Convert spectral bands to albedo (0-1)"]}</label>
                </div>}
                {!isMosaic && !isFusion && <div className="popup-exp-visible popup-exp-crop">
                    <input type="checkbox" className="switch_1" id="tiff"
                           checked={es.combine_products}
                           onChange={this.onClick_combine_products}/><label
                    htmlFor="tiff"
                    className="css-label-2">{store.trans["Combine products into single TIFF"]}</label>
                </div>}
                {!isMosaic && !isFusion && <div className="popup-exp-visible popup-exp-crop">
                    <input type="checkbox" className="switch_1" id="mergeScenes"
                           checked={es.mergeScenes}
                           onChange={this.onClick_mergeScenes}/><label
                    htmlFor="mergeScenes"
                    className="css-label-2">{store.trans["Merge scenes from single path"]}</label>
                </div>}
            </div>

            {this.props.children}

            {!isFusion && <div className={classNames("qtransit overflowHidden")}>
                <div className="popup-pref-in__separator"/>
                <div className={classNames("popup-exp-product popup-exp--key-value padding-bottom10")}>
                    <div className="popup-exp-product-col1">
                        <div className="popup-exp-prod">{store.trans["Spectral bands"]}</div>
                        {arrBands}
                    </div>
                    <div className="popup-exp-product-col2">
                        <div className="popup-exp-prod">{store.trans["Spectral indexes"]}</div>
                        {arrIndexes}
                    </div>
                </div>
            </div>}
        </React.Fragment>;
    }
}
