import * as React from 'react';
import {observer} from "mobx-react";
import autoBindReact from "auto-bind/react";
import {isFunction} from "lodash-es";
import classNames from "classnames";


export interface IAgroAhoRoundCheckboxSvgProps {
    onClick?: (e: any)=>void,
    className?: string,
    disabled?: boolean,
    checked?: boolean
}

@observer
export class AgroAhoRoundCheckboxSvg extends React.PureComponent<IAgroAhoRoundCheckboxSvgProps, undefined> {
    constructor(props: IAgroAhoRoundCheckboxSvgProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(e: any){
        if (this.props.disabled === true) return;
        if (isFunction(this.props.onClick)) this.props.onClick(e);
    }

    render() {
        return <React.Fragment>
            {this.props.checked &&
            <svg onClick={this.onClick} className={classNames(this.props.className)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5" y="5" width="8" height="8" rx="4" fill="#4DB6BC" stroke="#4DB6BC" strokeWidth="2"/>
                <rect x="1" y="1" width="16" height="16" rx="8" stroke="#4DB6BC" strokeWidth="2"/>
            </svg>}
            {!this.props.checked &&
            <svg onClick={this.onClick} className={classNames(this.props.className)}  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="16" rx="8" stroke="#454C52" strokeWidth="2"/>
            </svg>}
        </React.Fragment>;
    }
}
