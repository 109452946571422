export enum Sentinel2Level {
    L1 = "L1", L2 = "L2"
}
export enum Satellite {
    "Sentinel-1"="Sentinel-1",
    "Sentinel-2"="Sentinel-2",
    "Sentinel-5"="Sentinel-5",
    "Landsat-9"="Landsat-9",
    "Landsat-8"="Landsat-8",
    "Landsat-7"="Landsat-7",
    "Landsat-5"="Landsat-5",
    "Landsat-4"="Landsat-4",
}

export enum SatEnum2{
     S2L1C='S2L1C', S2L2A='S2L2A', LS8='LS8', LS9='LS9', LS7='LS7', LS45='LS4,LS5', MODIS='MODIS', S5L2A='S5L2A'
}

export enum SatEnum{
    S2 = "S2",
    L8 = "L8",
    L7E = "L7E",
    L45 = "L45"
}
export interface ISatellitesDicBool {
    S2: boolean,
    L8: boolean,
    L7E:boolean,
    L45:boolean
}
export interface ISatellitesDicStr {
    S2: string,
    L8: string,
    L7E:string,
    L45:string
}

export let satDic: ISatellitesDicStr =
{
    "S2" : "Sentinel-2",
    "L8": "Landsat-8 OLI + TIRS",
    "L7E": "Landsat-7 ETM+",
    "L45": "Landsat-5 TM"
}