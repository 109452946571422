import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {save} from "../PermalinkDecor";
import {fetchJsonGet, fetchJsonPost, fetchJsonPut} from "../../helper/utils/FetchUtils";
import {Utils} from "../../helper/utils/Utils";
import {ORDER_TYPE} from "../OrderListStore";

export class CropMaskStore extends CustomStore{
    @save @observable
    trainRatio: number = 0.9;
    @save @observable
    randomForestTrees: number = 100;
    @save @observable
    maxDepth: number = 15;
    @observable
    referenceJson: string = null;
    @observable
    referenceFilename: string = null;
    @observable
    sentinel2_grid_cells: string = "";

    async loadReference(ref_ds: number){
        let r = await fetchJsonGet(`/api/crop_mask/refds/get`,{ds_id:ref_ds, mode:"json"});
        this.referenceFilename = r.file_name;
        this.referenceJson = r.geom;
    }
    async sendCreateCropMask(){
        let p = {geom: this.referenceJson, file_name: this.referenceFilename};
        let r = await fetchJsonPost(`/api/crop_mask/refds/put`, p);
        let ds_id = r.ds_id;
        let grids = this.sentinel2_grid_cells.split(",").map(s => s.trim());

        let p2 = {
            name: this.root.exportStore.projectName,
            type: ORDER_TYPE.crop_mask,
            email: this.root.exportStore.email,
            params:{
                "order_type": ORDER_TYPE.crop_mask,
                "name": this.root.exportStore.projectName,
                "email": this.root.exportStore.email,
                'beg': Utils.getDateStr(this.root.searchPanel.filterDate.begin),
                'end': Utils.getDateStr(this.root.searchPanel.filterDate.end),
                //'region': this.root.map.searchObject.searchGeometry,
                'grid_cells': grids,
                'ref_ds': ds_id,
                'train_ratio': this.trainRatio,
                'tree_cnt': this.randomForestTrees,
                'tree_depth': this.maxDepth
        }};
        await fetchJsonPut(`/api/order_proc/order`, p2);
    }
}