import * as React from 'react';
import {CSSProperties} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";

export interface IValueByMouse {
    top: number;
    left: number;
    loading: boolean;
    value: string;
}

export interface IValueByMouseProps extends IStoreProps {
    value: IValueByMouse;
}

@observer
export class NdviValueByMoudeComp extends React.Component<IValueByMouseProps, undefined> {
    constructor(props: IValueByMouseProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        if (! this.props.value) return;
        let store = this.props.store;
        //if (this.ref.current)

        let st: CSSProperties = {
            borderRadius: "5px",
            background: "#2B3138",
            fontWeight: 600,
            fontSize: "10px",
            lineHeight: "14px",
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: this.props.value.top - 25, //store.map.ndviValueByMouseStore.y - 25,
            left: this.props.value.left, //store.map.ndviValueByMouseStore.x,
            minWidth: "30px",
            minHeight: "18px",
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.25)",
            zIndex: 1,
            transform: "translate(-50%, 0)",
            wordWrap: "normal",
        };
        let loading: CSSProperties = {
            background: "#C5C5C5",
            opacity: 0.42,
            width: "10px",
            height: "10px",
            borderRadius: "10px",
            boxSizing: "border-box",
            margin:"3px 5px",
        };
        let span: CSSProperties ={
            margin:"3px 5px",
            wordWrap: "normal",
            whiteSpace: "nowrap",
        }
        return <div style={st} className="full-center-content">
            {this.props.value.loading &&
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><title>ripple
                anim</title>
                <g fill="#212121" className="nc-icon-wrapper">
                    <g className="nc-loop-ripple-16-icon-f">
                        <circle cx="8" cy="8" fill="#c5c5c5" r="8"/>
                        <circle data-color="color-2" cx="8" cy="8" r="8"/>
                    </g>
                    <style>{`.nc-loop-ripple-16-icon-f{--animation-duration:1.2s}.nc-loop-ripple-16-icon-f *{transform-origin:50% 50%;animation:nc-loop-ripple-anim var(--animation-duration) infinite cubic-bezier(.215,.61,.355,1)}.nc-loop-ripple-16-icon-f :nth-child(2){animation-delay:calc(var(--animation-duration)/-2)}@keyframes nc-loop-ripple-anim{0%{opacity:1;transform:scale(.2)}100%{opacity:0;transform:scale(1)}}`}</style>
                </g>
            </svg>}
            {!this.props.value.loading && <span style={span} >{this.props.value.value}</span>}
        </div>;
    }
}
