import {CustomTool} from "../general/ContainerTools";
import * as mapboxgl from "maplibre-gl";
import {MapboxGeoJSONFeature, MapMouseEvent, PointLike} from "maplibre-gl";
import {SuperTools} from "../general/SuperTools";
import {ra} from "../../../helper/utils/mobxUtils";
import {A2SubModeLeftPanel} from "../../agro/A2AgroStore";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class ContextMenuTool extends CustomTool {
    tools: SuperTools;

    onMouseClick(e: MapMouseEvent & ToolEvent): void {
        if (!!(e.originalEvent.button == 0)) {
            let ms = this.tools.store.map;
            if (ms.menuPointer != null) {
                ra(() => {
                    ms.menuPointer = null;
                });
            }
        }
        if (!!(e.originalEvent.button & 2)) {
            let ms = this.tools.store.map;
            ra(() => {
                ms.menuPointer = {point: e.point, lngLat: e.lngLat};
                ms.menu_field_id = null;
                let bbox: [PointLike, PointLike] = [
                    new mapboxgl.Point(e.point.x - 5, e.point.y - 5),
                    new mapboxgl.Point(e.point.x + 5, e.point.y + 5)
                ];
                let features: MapboxGeoJSONFeature[] = ms.mapbox.queryRenderedFeatures(bbox);
                ms.selectedFeature = null;
                for (let i = 0; i < features.length; i++) {
                    if (features[i].layer.metadata?.geomdownloadable) {
                        ms.selectedFeature = features[i];
                        break;
                    }
                }

                let store = this.tools.store;
                if (store.agro2.permission.canEditObject()) {
                    let field_id = store.agro2.fieldToolController.fieldClickTool.clickTo_field_id(e.point);
                    ms.menu_field_id = field_id;
                } else ms.menu_field_id = null;


            });
            e.noPropagation();
        }
    }
}