import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoTourSvgProps {
    className?: string
}

export class AgroAhoTourSvg extends React.PureComponent<IAgroAhoTourSvgProps, undefined> {
    constructor(props: IAgroAhoTourSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg  className={classNames(this.props.className)} width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.8518 14C2.87451 14 3.70359 13.2165 3.70359 12.25C3.70359 11.2835 2.87451 10.5 1.8518 10.5C0.829077 10.5 0 11.2835 0 12.25C0 13.2165 0.829077 14 1.8518 14Z"/>
            <path d="M12.9612 3.5C13.9839 3.5 14.813 2.7165 14.813 1.75C14.813 0.783502 13.9839 0 12.9612 0C11.9385 0 11.1094 0.783502 11.1094 1.75C11.1094 2.7165 11.9385 3.5 12.9612 3.5Z"/>
            <path d="M12.0405 10.5C12.0405 10.9641 11.8454 11.4092 11.4981 11.7374C11.1508 12.0656 10.6798 12.25 10.1887 12.25C9.69754 12.25 9.22653 12.0656 8.87925 11.7374C8.53197 11.4092 8.33687 10.9641 8.33687 10.5V3.5C8.33687 2.57174 7.94667 1.6815 7.25211 1.02513C6.55756 0.368749 5.61553 0 4.63328 0C3.65103 0 2.709 0.368749 2.01444 1.02513C1.31989 1.6815 0.929688 2.57174 0.929688 3.5V8.75H2.78148V3.5C2.78148 3.03587 2.97658 2.59075 3.32386 2.26256C3.67114 1.93437 4.14215 1.75 4.63328 1.75C5.12441 1.75 5.59542 1.93437 5.9427 2.26256C6.28998 2.59075 6.48507 3.03587 6.48507 3.5V10.5C6.48507 11.4283 6.87527 12.3185 7.56983 12.9749C8.26439 13.6313 9.20641 14 10.1887 14C11.1709 14 12.1129 13.6313 12.8075 12.9749C13.5021 12.3185 13.8923 11.4283 13.8923 10.5V5.25H12.0405V10.5Z"/>
            <path d="M20.174 3.57925C20.1046 3.53328 20.0232 3.50619 19.9386 3.50094C19.854 3.49569 19.7695 3.51249 19.6944 3.5495C19.6192 3.5865 19.5562 3.6423 19.5123 3.71081C19.4684 3.77932 19.4452 3.8579 19.4453 3.938V10.063C19.4455 10.143 19.4689 10.2215 19.5129 10.2899C19.557 10.3582 19.6201 10.4138 19.6953 10.4506C19.7609 10.4834 19.834 10.5005 19.9083 10.5005C20.0033 10.5006 20.0961 10.4732 20.174 10.4218L24.8035 7.35925C24.8646 7.31893 24.9145 7.26526 24.949 7.2028C24.9835 7.14034 25.0015 7.07094 25.0015 7.0005C25.0015 6.93006 24.9835 6.86066 24.949 6.7982C24.9145 6.73574 24.8646 6.68207 24.8035 6.64175L20.174 3.57925Z"/>
        </svg>;
    }
}