import {IDictonaryType} from "../helper/utils/Utils";
import {CustomStore} from "./CustomStore";
import {ObservableMap, ObservableSet} from "mobx";

interface IFunctionCreateObjectByClass {
    (parent: any): any
}
export enum ClassValueUpdateType{
    save = "save",//по умолчанию
    update = "update"//обновляет объект store, не создавая новый экземпляр
}
enum ClassType{
    CustomStore="Store",//Наследник от CustomStore
    Date="Date",
    Array="Array",
    undefined="undefined",
    ObservableSet="ObservableSet",
    Set="Set",
    ObservableMap="ObservableMap",
    Map="Map",
    Obj="Obj"
}

export class ClassFactory {
    private static regClasses: IDictonaryType<IFunctionCreateObjectByClass> = {};
    private static SaveProperties: IDictonaryType<IDictonaryType<ClassValueUpdateType>> = {};

    public static registerProperty(target: object, propertyKey: string, value: ClassValueUpdateType){
        let classname = target.constructor.name;
        if (!ClassFactory.SaveProperties[classname]) ClassFactory.SaveProperties[classname] = {};
        let obj = ClassFactory.SaveProperties[classname];
        if (!obj[propertyKey]) obj[propertyKey] = value;
    }

    public static createClass(className: string, parent: any): object{
        let func = ClassFactory.regClasses[className];
        if (typeof func !== "function") return null;
        return func(parent);
    }

    public static registerClass(func: IFunctionCreateObjectByClass, className: string){
        if (!this.regClasses[className]) this.regClasses[className] = func;
        if (!ClassFactory.SaveProperties[className]) ClassFactory.SaveProperties[className] = {};
    }

    public static deepClone(obj: CustomStore, parent: CustomStore): CustomStore{
        let json = ClassFactory.toJson(obj, ClassValueUpdateType.save);
        let r = ClassFactory.loadSimple(json, parent);
        return r;
    }

    public static loadJsonTo(obj: any, json: any): void{
        return ClassFactory.loadStoreToObject2(json, obj, null);
    }


    static readonly classTypeProperty = "__ct";
    static readonly classNameProperty = "__cn";
    static readonly updateTypeProperty = "__ut";
    static readonly valueProperty = "v";
    public static toJson(obj: any, updateType: ClassValueUpdateType): any{
        if (obj instanceof CustomStore){
            let res: any = {};
            if (updateType != ClassValueUpdateType.save) res[ClassFactory.updateTypeProperty] = updateType;
            res[ClassFactory.classTypeProperty] = ClassType.CustomStore;
            let intClassname = obj.internalClassname();
            let regProps = ClassFactory.SaveProperties[intClassname];
            if (regProps != null) {
                let externalClassname = obj.class();
                if (externalClassname != ClassType.CustomStore){
                    res[ClassFactory.classNameProperty] = externalClassname;
                }
                for (let key in obj) {
                    if (key == "_parent") continue;
                    let updateType = regProps[key];
                    if (updateType == null) continue;
                    let v = (obj as any)[key];
                    if (typeof v == "function") continue;
                    if (typeof v == "undefined") continue;
                    res[key] = ClassFactory.toJson(v, updateType);
                }
            }
            return res;
        } else if (obj instanceof Date){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.Date;
            res[ClassFactory.valueProperty] = (obj as Date).getTime();
            return res;
        } else if (obj instanceof Array){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.Array;
            res[ClassFactory.valueProperty] = (obj as Array<any>).map(a => ClassFactory.toJson(a, ClassValueUpdateType.save));
            return res;
        } else if (obj instanceof ObservableSet){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.ObservableSet;
            res[ClassFactory.valueProperty] = Array.from(obj).map(a => ClassFactory.toJson(a, ClassValueUpdateType.save));
            return res;
        } else if (obj instanceof Set){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.Set;
            res[ClassFactory.valueProperty] = Array.from(obj).map(a => ClassFactory.toJson(a, ClassValueUpdateType.save));
            return res;
        } else if (obj instanceof ObservableMap){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.ObservableMap;
            let vv: any = [];
            (obj as Map<any, any>).forEach((value, key) => {
                vv.push({"k": ClassFactory.toJson(key, ClassValueUpdateType.save), "v": ClassFactory.toJson(value, ClassValueUpdateType.save)});
            });
            res[ClassFactory.valueProperty] = vv;
            return res;
        } else if (obj instanceof Map){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.Map;
            let vv: any = [];
            (obj as Map<any, any>).forEach((value, key) => {
                vv.push({"k": ClassFactory.toJson(key, ClassValueUpdateType.save), "v": ClassFactory.toJson(value, ClassValueUpdateType.save)});
            });
            res[ClassFactory.valueProperty] = vv;
            return res;
        } else if (obj == null) return null;
        else if (typeof obj == "undefined"){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.undefined;
            return res;
        }
        else if (typeof obj == "string") return obj;
        else if (typeof obj == "number") return obj;
        else if (typeof obj == "boolean") return obj;
        else if (obj.constructor.name == "Object"){
            let res: any = {};
            res[ClassFactory.classTypeProperty] = ClassType.Obj;
            let v: any = [];
            for (let key in obj) {
                v.push({"k": ClassFactory.toJson(key, ClassValueUpdateType.save), "v": ClassFactory.toJson(obj[key], ClassValueUpdateType.save)});
            }
            res[ClassFactory.valueProperty] = v;
            return res;
        }
        else {
            throw "Unknown object "+obj.constructor.name;
        }
    }

    static loadStoreToObject2(from: any, to: CustomStore, parent: CustomStore){
        if (typeof from == "object" && from != null){
            let intClassname = to.internalClassname();
            let regProps = ClassFactory.SaveProperties[intClassname];
            if (regProps == null){
                return;
            }
            for (let key in from) {
                if (key == ClassFactory.classTypeProperty || key == ClassFactory.classNameProperty || key == ClassFactory.updateTypeProperty) continue;
                if (regProps[key] == null) {//не пытаемся загрузить не зарегестрированные старые свойства
                    continue;
                }
                let v = from[key];
                try {
                    if (v != null && typeof v == "object" && v[ClassFactory.classTypeProperty] == ClassType.CustomStore) {
                        let updateType: ClassValueUpdateType = v[ClassFactory.updateTypeProperty];
                        let customV = (to as any)[key];
                        if (updateType == ClassValueUpdateType.update && customV instanceof CustomStore) {
                            (customV as CustomStore).beforeLoadPermalink();
                            ClassFactory.loadStoreToObject2(v, customV, to);
                        } else {
                            if (customV != null && customV instanceof CustomStore) {
                                (customV as CustomStore).dispose();
                            }
                            (to as any)[key] = ClassFactory.loadSimple(v, to);
                        }
                    } else {
                        (to as any)[key] = ClassFactory.loadSimple(v, to);
                    }
                }
                catch(err){
                    console.log(`key=${key}`);
                    console.log(from);
                    console.log(to);
                    console.error(err);
                }
            }
        }
    }

    private static loadSimple(from: any, parent: CustomStore): any{
        if (from == null) return from;
        if (typeof from == "string") return from;
        if (typeof from == "number") return from;
        if (typeof from == "boolean") return from;
        if (typeof from == "object"){
            let classType = from[ClassFactory.classTypeProperty];
            if (classType == ClassType.Array){
                let t = (from[ClassFactory.valueProperty] as Array<any>).map(a => {return ClassFactory.loadSimple(a, parent);});
                return t;
            }else if (classType == ClassType.Date){
                return new Date(from[ClassFactory.valueProperty]);
            }else if (classType == ClassType.undefined){
                return undefined;
            }else if (classType == ClassType.ObservableSet){
                let v = new ObservableSet();
                (from[ClassFactory.valueProperty] as Array<any>).forEach(a => v.add(ClassFactory.loadSimple(a, parent)));
                return v;
            }else if (classType == ClassType.Set){
                let v = new Set();
                (from[ClassFactory.valueProperty] as Array<any>).forEach(a => v.add(ClassFactory.loadSimple(a, parent)));
                return v;
            }else if (classType == ClassType.ObservableMap){
                let vv = new ObservableMap();
                (from[ClassFactory.valueProperty] as Array<any>).forEach(a =>{
                    let k = ClassFactory.loadSimple(a["k"], parent);
                    let v = ClassFactory.loadSimple(a["v"], parent);
                    vv.set(k, v);
                });
                return vv;
            }else if (classType == ClassType.Map){
                let vv = new Map();
                (from[ClassFactory.valueProperty] as Array<any>).forEach(a =>{
                    let k = ClassFactory.loadSimple(a["k"], parent);
                    let v = ClassFactory.loadSimple(a["v"], parent);
                    vv.set(k, v);
                });
                return vv;
            }else if (classType == ClassType.Obj){
                let vv: any = {};
                (from[ClassFactory.valueProperty] as Array<any>).forEach(a =>{
                    let k = ClassFactory.loadSimple(a["k"], parent);
                    let v = ClassFactory.loadSimple(a["v"], parent);
                    vv[k] = v;
                });
                return vv;
            }else{
                let className = from[ClassFactory.classNameProperty];
                if (ClassFactory.regClasses[className] != null){
                    let v = ClassFactory.createClass(className, parent);
                    ClassFactory.loadStoreToObject2(from, v as CustomStore, parent);
                    return  v;
                }else{
                    throw "Unknow classType "+classType +" className "+className;
                }
            }
        }
    }
}
