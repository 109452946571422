import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {action} from "mobx";
import classNames from "classnames";
import {Lang} from "../../../../pluginApi/store/Lang";

@observer
export class UserMenuComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onLogout() {
        this.props.store.user.requestLogout();
    }

    @action
    onShowAdminPanel() {
        this.props.store.userList.doLoadList();
        this.props.store.user.showMenu = false;
        this.props.store.user.showAdminPanel = true;
    }
    @action
    toRusssian(){
        this.props.store.setLang(Lang.ru);
    }
    @action
    toEnglish(){
        this.props.store.setLang(Lang.en);
    }
    @action
    toHin(){
        this.props.store.setLang(Lang.hin);
    }

    @action
    onClickPersonalArea(){
        this.props.store.user.personalArea.showForm = true;
        this.props.store.user.showMenu = false;
    }

    render() {
        let usr = this.props.store.user;
        let store = this.props.store;


        return <div className="user-menu-container">
            <div className="login-container"><span>{usr.curEmail}</span></div>
            <div className="user-menu-panel">
                <div className="clickable login-container" onClick={this.onClickPersonalArea}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 0H1C0.4 0 0 0.4 0 1V15C0 15.6 0.4 16 1 16H15C15.6 16 16 15.6 16 15V1C16 0.4 15.6 0 15 0ZM14 14H13C13 12.5 11.5 11 10 11H6C4.5 11 3 12.5 3 14H2V2H14V14Z" fill="#C5C5C5"/>
                        <path d="M8 10C9.65685 10 11 8.65685 11 7C11 5.34315 9.65685 4 8 4C6.34315 4 5 5.34315 5 7C5 8.65685 6.34315 10 8 10Z" fill="#C5C5C5"/>
                    </svg>
                    <span style={{marginLeft : 12}}>{this.props.store.trans["Personal Area"]}</span>
                </div>
                {usr.roles.findIndex(el => el == 'admin') >= 0 &&
                <div className="clickable login-container" onClick={this.onShowAdminPanel}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z" fill="#C5C5C5"/>
                        <path d="M7 13.171L10.054 10.117C9.414 10.046 8.732 10 8 10C4.808 10 2.461 10.795 1.163 11.382C0.45 11.705 0 12.417 0 13.2V16H7V13.171Z" fill="#C5C5C5"/>
                        <path d="M11 16H9V14L14 9L16 11L11 16Z" fill="#C5C5C5"/>
                    </svg>
                    <span style={{marginLeft : 12}}>{this.props.store.trans["Admin panel"]}</span>
                </div>}
                <div className="clickable login-container" onClick={this.onLogout}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 4L0 8L4 12V9H10V7H4V4Z" fill="#C5C5C5"/>
                        <path d="M15 16H6C5.4 16 5 15.6 5 15V12H7V14H14V2H7V4H5V1C5 0.4 5.4 0 6 0H15C15.6 0 16 0.4 16 1V15C16 15.6 15.6 16 15 16Z" fill="#C5C5C5"/>
                    </svg>
                    <span style={{marginLeft : 12}}>{this.props.store.trans["Log out"]}</span>
                </div>
            </div>
            <div className="login-container">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.548 9.55L8.182 7.745C7.52619 7.54482 6.89671 7.26685 6.307 6.917C6.74654 6.42941 7.11946 5.88567 7.416 5.3C7.72721 4.68891 7.95716 4.03974 8.1 3.369C8.689 3.369 9.079 3.389 9.519 3.429V1.62C9.03748 1.67203 8.55329 1.69541 8.069 1.69H5.687V1.08C5.68665 0.875178 5.70675 0.670832 5.747 0.470001H3.738C3.77922 0.674062 3.79932 0.881823 3.798 1.09V1.69H1.589C1.10504 1.69515 0.621206 1.67177 0.14 1.62V3.429C0.64 3.389 0.979 3.369 1.569 3.369C1.73855 3.96125 1.96982 4.53406 2.259 5.078C2.60815 5.74061 3.0414 6.35536 3.548 6.907C2.45071 7.54688 1.24995 7.98989 0 8.216C0.420415 8.74521 0.744977 9.34391 0.959 9.985C2.3811 9.60505 3.72712 8.98296 4.938 8.146C5.74428 8.72385 6.62146 9.19571 7.548 9.55ZM3.488 3.369H6.137C5.92419 4.26504 5.495 5.09526 4.887 5.787C4.22732 5.10952 3.74655 4.27857 3.488 3.369Z" fill="#C5C5C5"/>
                    <path d="M13.7171 16L12.9921 13.621H9.34906L8.62406 16H6.34106L9.86806 5.965H12.4591L16.0001 16H13.7171ZM12.4861 11.844C11.8194 9.688 11.4424 8.469 11.3551 8.187C11.2711 7.904 11.2101 7.681 11.1741 7.517C11.0234 8.09966 10.5927 9.542 9.88206 11.844H12.4861Z" fill="#C5C5C5"/>
                </svg>
                {(store.config.lang == null || store.config.lang.includes(Lang.ru)) && <span className={classNames("pointer",
                    {"bold blue-text":this.props.store.getLang() == Lang.ru})}
                      style={{marginLeft : 12}} onClick={this.toRusssian}>Ru</span>}
                {(store.config.lang == null || store.config.lang.includes(Lang.en)) && <span className={classNames("pointer",
                    {"bold blue-text":this.props.store.getLang() == Lang.en})}
                      style={{marginLeft : 12}} onClick={this.toEnglish}>En</span>}
                {(store.config.lang == null || store.config.lang.includes(Lang.hin)) && <span className={classNames("pointer",
                    {"bold blue-text":this.props.store.getLang() == Lang.hin})}
                      style={{marginLeft : 12}} onClick={this.toHin}>हिन्दी</span>}
            </div>
        </div>
    }
}