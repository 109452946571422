import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoBackSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
    isNext?: boolean;
}

export class AgroAhoBackSvg extends React.PureComponent<IAgroAhoBackSvgProps, undefined> {
    constructor(props: IAgroAhoBackSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let tr = this.props.isNext ? 'scale(-1, 1) translate(-16, 0)' : '';
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
                       width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path transform={tr} d="M10 13.0001C9.73484 13 9.48052 12.8946 9.29303 12.7071L5.29303 8.70708C5.10556 8.51955 5.00024 8.26525 5.00024 8.00008C5.00024 7.73492 5.10556 7.48061 5.29303 7.29308L9.29303 3.29308C9.38528 3.19757 9.49562 3.12139 9.61763 3.06898C9.73963 3.01657 9.87085 2.98898 10.0036 2.98783C10.1364 2.98668 10.2681 3.01198 10.391 3.06226C10.5139 3.11254 10.6255 3.18679 10.7194 3.28069C10.8133 3.37458 10.8876 3.48623 10.9379 3.60913C10.9881 3.73202 11.0134 3.8637 11.0123 3.99648C11.0111 4.12926 10.9835 4.26048 10.9311 4.38249C10.8787 4.50449 10.8025 4.61483 10.707 4.70708L7.41403 8.00008L10.707 11.2931C10.8468 11.4329 10.942 11.6111 10.9806 11.805C11.0192 11.999 10.9994 12.2 10.9237 12.3827C10.848 12.5654 10.7199 12.7216 10.5555 12.8315C10.3911 12.9414 10.1978 13 10 13.0001Z"/>
        </svg>;
    }
}
