import {action, observable} from "mobx";
import {A2ExportFormat} from "../A2AgroStore";
import {Utils} from "../../../helper/utils/Utils";
import {A2ConfirmActionStore} from "./A2ConfirmActionStore";
import {IQueryArguments} from "../../../helper/utils/IQueryArguments";

export enum A2ConfirmExportMode{
    selectedFields='selectedFields',
    folder='folder'
}
export class A2ConfirmExportStore extends A2ConfirmActionStore{
    @observable
    format: A2ExportFormat;
    @observable
    filename: string = "export";

    doActionExport(){
        this.doExport();
    }

    doExport(){
        this.doHide();
        if (this.mode_export == A2ConfirmExportMode.folder) this.doExportFolder();
        if (this.mode_export == A2ConfirmExportMode.selectedFields) this.doExportSelected();
    }
    doExportSelected(){
        let opt: IQueryArguments = {
            url: `/api/projects/${this.root.agro.projectName}/object/export`,
            params: {file_name: this.filename, format: this.format, filter: JSON.stringify(this.filterSelected)}
        };
        this.root.events.onAgroExportDownload.call(opt);
        let args = Utils.queryEncodeParams(opt.params);
        Utils.downloadFile(opt.url + "?"+args);
    }
    @action
    doExportFolder(){
        try {
            let filter = this.activeNavigatorItem.getAllFilters({notFirst: true});
            this.root.agro2.exportFields(filter, this.format, this.filename);
        }catch (err){
            this.root.addError(err);
        }

    }
}