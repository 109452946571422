import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";

export interface IAgroProjectTotalPanelComp extends IStoreProps{
    total: number;
}
@observer
export class AgroProjectTotalPanelComp extends React.Component<IAgroProjectTotalPanelComp, undefined> {
    constructor(props: IAgroProjectTotalPanelComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div style={{
            color: "#6B707B",
            fontWeight: 400, fontSize: "12px", lineHeight: 1.2, padding: "5px 10px 5px 14px",
            background: "#262B32",
            display: "flex", alignItems: "center",
            marginBottom: "10px"}}>
            <span>{store.trans.Projects}:</span>
            <span className="text-next-2">{this.props.total}</span>
        </div>;
    }
}
