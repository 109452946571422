import React from "react";

export const SmallRemoveAllSvg = () => {
    return <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 3V1.5H8.25V0.75C8.25 0.551088 8.17098 0.360322 8.03033 0.21967C7.88968 0.0790176 7.69891 0 7.5 0L4.5 0C4.30109 0 4.11032 0.0790176 3.96967 0.21967C3.82902 0.360322 3.75 0.551088 3.75 0.75V1.5H0V3H12Z" stroke="none"/>
        <path d="M1.5 3.75V10.5C1.5 10.8978 1.65804 11.2794 1.93934 11.5607C2.22064 11.842 2.60218 12 3 12H9C9.39782 12 9.77936 11.842 10.0607 11.5607C10.342 11.2794 10.5 10.8978 10.5 10.5V3.75H1.5ZM8.5605 9.375L7.5 10.4355L6 8.9355L4.5 10.4355L3.4395 9.375L4.9395 7.875L3.4395 6.375L4.5 5.3145L6 6.8145L7.5 5.3145L8.5605 6.375L7.0605 7.875L8.5605 9.375Z" stroke="none"/>
    </svg>;
}

export const RemoveAllSvg = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H11V1C11 0.734784 10.8946 0.48043 10.7071 0.292893C10.5196 0.105357 10.2652 0 10 0L6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3C0 3.26522 0.105357 3.51957 0.292893 3.70711C0.48043 3.89464 0.734784 4 1 4H15C15.2652 4 15.5196 3.89464 15.7071 3.70711C15.8946 3.51957 16 3.26522 16 3Z" stroke="none"/>
        <path d="M2 5V14C2 14.5304 2.21071 15.0391 2.58579 15.4142C2.96086 15.7893 3.46957 16 4 16H12C12.5304 16 13.0391 15.7893 13.4142 15.4142C13.7893 15.0391 14 14.5304 14 14V5H2ZM11.06 12.854L10.354 13.56C10.3076 13.6066 10.2524 13.6435 10.1916 13.6687C10.1309 13.6939 10.0658 13.7069 10 13.7069C9.93423 13.7069 9.86911 13.6939 9.80837 13.6687C9.74762 13.6435 9.69245 13.6066 9.646 13.56L8 11.914L6.354 13.56C6.30755 13.6066 6.25238 13.6435 6.19163 13.6687C6.13089 13.6939 6.06577 13.7069 6 13.7069C5.93423 13.7069 5.86911 13.6939 5.80837 13.6687C5.74762 13.6435 5.69245 13.6066 5.646 13.56L4.94 12.854C4.89344 12.8076 4.85649 12.7524 4.83129 12.6916C4.80608 12.6309 4.79311 12.5658 4.79311 12.5C4.79311 12.4342 4.80608 12.3691 4.83129 12.3084C4.85649 12.2476 4.89344 12.1924 4.94 12.146L6.586 10.5L4.94 8.854C4.89344 8.80755 4.85649 8.75238 4.83129 8.69163C4.80608 8.63089 4.79311 8.56577 4.79311 8.5C4.79311 8.43423 4.80608 8.36911 4.83129 8.30837C4.85649 8.24762 4.89344 8.19245 4.94 8.146L5.646 7.44C5.69245 7.39344 5.74762 7.35649 5.80837 7.33129C5.86911 7.30608 5.93423 7.29311 6 7.29311C6.06577 7.29311 6.13089 7.30608 6.19163 7.33129C6.25238 7.35649 6.30755 7.39344 6.354 7.44L8 9.086L9.646 7.44C9.69245 7.39344 9.74762 7.35649 9.80837 7.33129C9.86911 7.30608 9.93423 7.29311 10 7.29311C10.0658 7.29311 10.1309 7.30608 10.1916 7.33129C10.2524 7.35649 10.3076 7.39344 10.354 7.44L11.06 8.146C11.1066 8.19245 11.1435 8.24762 11.1687 8.30837C11.1939 8.36911 11.2069 8.43423 11.2069 8.5C11.2069 8.56577 11.1939 8.63089 11.1687 8.69163C11.1435 8.75238 11.1066 8.80755 11.06 8.854L9.414 10.5L11.06 12.146C11.1066 12.1924 11.1435 12.2476 11.1687 12.3084C11.1939 12.3691 11.2069 12.4342 11.2069 12.5C11.2069 12.5658 11.1939 12.6309 11.1687 12.6916C11.1435 12.7524 11.1066 12.8076 11.06 12.854Z" stroke="none"/>
    </svg>;
}


            
