import {ObservableCustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {action, observable, reaction} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";
import {ShowRoom2Store} from "./ShowRoom2Store";
import {IReactionDisposer} from "mobx/lib/internal";

export interface IShowRoomDistrict{
    "id": number,
    "distrname": string,
    "statename": string,

}

export class ShowRoom2FilterProjectStore extends ObservableCustomStore<ShowRoom2Store>{
    subscription(): IReactionDisposer[] {
        return [
            reaction(()=>{
                return {a: this.seasonId, b: this.cropId};
            },()=>{
                this.loadDistricts();
            })
        ];
    }

    @save @observable
    seasonId: number = null;
    @observable
    cropIdsBySeasonId: number[] = [];
    @observable
    cropIdsBySeasonIdStatus: LoadStatus = LoadStatus.ready;
    @save @observable
    cropId: number = null;

    isValid(): boolean{
        return this.cropId != null && this.seasonId != null;
    }

    @observable
    districts: IShowRoomDistrict[] = [];
    @observable
    districts_status: LoadStatus = null;
    @save @observable
    filter_districts_ids: Set<number> = new Set<number>();

    get showRoom(): ShowRoom2Store{
        return this.root.dataShowRoomStore;
    }

    @action
    loadDistricts(){
        let filter:any = {};
        if (this.seasonId != null) filter.seasons = [this.seasonId];
        if (this.cropId != null) filter.crops = [this.cropId];
        this.districts_status = LoadStatus.loading;
        this.districts = [];
        fetchJsonGet(`reports/api/districts/find?filter=${encodeURIComponent(JSON.stringify(filter))}&keys=id,distrname,statename`)
            .then((json) =>{
                ra(()=>{
                    this.districts_status = LoadStatus.ready;
                    this.districts = json;
                    let ds = new Set<number>(this.districts.map(a => a.id));
                    let arr = Array.from(this.filter_districts_ids.values());
                    arr.forEach(a => {
                        if (!ds.has(a)){
                            this.filter_districts_ids.delete(a);
                        }
                    });
                });
            })
            .catch(err => this.root.addError(err));
    }

    @action
    loadCropIdsBySeason(){
        this.cropIdsBySeasonId = [];
        if (this.seasonId == null){
            this.cropIdsBySeasonIdStatus = LoadStatus.ready;
            return;
        }
        this.cropIdsBySeasonIdStatus = LoadStatus.loading;
        fetchJsonGet(`/reports/api/season/${this.seasonId}/crops`).then( json =>{
            ra(()=>{
                this.cropIdsBySeasonId = json;
                if (!this.cropIdsBySeasonId.find(a => a == this.cropId)){
                    this.cropId = null;
                }
                this.cropIdsBySeasonIdStatus = LoadStatus.ready;
            });
        }).catch(err => this.root.addError(err));
    }
}