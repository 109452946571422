import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class MaximizeSvg extends CustomSvg {

    render() {
        return <svg className={this.getClasses()} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9L4.2 5.8" stroke="#C5C5C5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 5.8L1 9L4.2 9" stroke="#C5C5C5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.99993 1.00007L5.79993 4.20007" stroke="#C5C5C5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.79993 1.00007L8.99993 1.00007L8.99993 4.20007" stroke="#C5C5C5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>;
    }
}
