import {CheckboxMiniComp, TriState} from "./CheckboxMiniComp";
import * as React from "react";

export class CheckboxMiniOkComp extends CheckboxMiniComp{
    getImageMini(): any{
        let state = this.getTriState();
        if (state == TriState.check) return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="13" height="13" rx="3" fill="#4DB6BC" stroke="#4DB6BC" strokeWidth="2"/>
                <path d="M12 6.0386L10.5682 4.6875L6.47727 8.54779L4.43182 6.61765L3 7.96875L6.47727 11.25L12 6.0386Z" fill="#2B3138" strokeWidth="0"/>
            </svg>);
        if (state == TriState.uncheck) return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="13" height="13" rx="3" stroke="#454C52" strokeWidth="2"/>
            </svg>
        );
        if (state == TriState.partial) return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" className="svg-stroke" width="13" height="13" rx="3" fill="transparent" strokeWidth="2"/>
        <rect x="4.75" y="6.75" className="svg-fill svg-stroke" width="5.5" height="1.5" rx="0.75" strokeWidth="1.5"/>
            </svg>);
    }
}