export enum MeasuringStatus {
    None='None',
    Point='Point',
    Rectangle ='Rectangle',
    Polygon='Polygon',
    Polyline='Polyline',
    Circle='Circle',
    CreatePointIndex='CreatePointIndex',
    Ruler='Ruler',
    Ruler2='Ruler2',
    Ruler2Edit='Ruler2Edit',
    ndviValueByMouse = 'ndviValueByMouse',
    agroCreatePoint = 'agroCreatePoint',
    agroCreateLine = 'agroCreateLine',
    agroCreatePolygon = 'agroCreatePolygon',
    agroCreatePolygonHole = 'agroCreatePolygonHole',
    agroEdit = 'agroEdit',
    agroDeleteContour = 'agroDeleteContour',
    agroAutoPolygon = 'agroAutoPolygon',
    agroCutGeometry = 'agroCutGeometry',
    agroUnionGeometry = 'agroUnionGeometry',
}