import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoOkSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoOkSvg extends React.PureComponent<IAgroAhoOkSvgProps, undefined> {
    constructor(props: IAgroAhoOkSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg  className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
            width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 1.6L15.2 0C8.3 2 4.8 6.4 4.8 6.4L1.6 4L0 5.6L4.8 12C8.5 5.1 16 1.6 16 1.6Z" fill="#6ABC4D"/>
        </svg>;
    }
}
