import {agroAhoCfg} from "./agroAhoConfig";
import {LoadedIndicator} from "./agroAhoCardStore";
import {AhoFileLoadStage, AhoLoadStatus} from "./agroAhoUploadIndcStore";
import * as React from "react";

export class AhoUtils {
    static emptyObject(obj: any){
        return typeof obj === 'object' && obj !== null && !Object.keys(obj).length;
    }



    static check_(data: any, type: string, cfg: any, debug?: boolean){
        return this.check(data, type, debug, null, cfg);
    }

    static check(data: any, type: string, debug?: boolean, path?: string, config_?: any){ // type[]?
        // console.log('check', JSON.stringify(data), type);
        let path_ = (path ? path : '<'+type+'>') + ' ('+type+') = ';
        let data_ = data ? JSON.parse(JSON.stringify(data)) : data;
        if (debug) console.log('check>', path_, data_);
        if (typeof type !== 'string') {console.log(path_, data_, ' >>> ', type, 'not string'); return}
        let req = type.substring(type.length-1) !== '?';
        if (!req) type = type.substring(0, type.length - 1);
        let arr = type.substring(type.length-2) === '[]';
        if (arr){
            type = type.substring(0, type.length-2);
            if (!req && !data) return true;
            if (!Array.isArray(data)) {console.log(path_, data_, ' >>> ', data, 'of type', type, 'not array'); return}
            for (let i=0; i<data.length; i++) {
                let path_ = `${path || '<'+type+'>'}[${i}]`;
                if (!this.check(data[i], type, debug, path_, config_)) return;
            }
            return true;
        }
        if (type === 'str' && typeof data === 'string') return true;
        if (type === 'num' && typeof data === 'number') return true;
        if (type === 'bool' && typeof data === 'boolean') return true;
        if (!req && type === 'num' && (!data && data !== 0)) return true;
        if (!req && type === 'bool' && (!data && data !== false)) return true;
        if (!req && type !== 'num' && type !== 'bool' && !data) return true;
        if (typeof data !== 'object') {console.log(path_, data_, ' >>> ', data, 'of type', type, 'not object'); return}
        if (!req && this.emptyObject(data)) return true;
        // if (!req) type = type.substring(0, type.length - 1);
        let cfg = config_?.[type] || agroAhoCfg[type as keyof object];
        if (typeof cfg !== 'object') {console.log(path_, data_, ' >>> ', 'type', type, 'for data', data, 'not exists'); return}
        if (cfg['type__'] === 'DictOfAhoTypes') {
            let data_keys = Object.keys(data);
            for (let i=0; i<data_keys.length; i++){
                let data_key = data_keys[i];
                if (cfg['type_key'] === 'num' && !this.isNum(data_key)) {console.log(path_, ' >>> ', 'key', data_key, 'not num'); return}
                let data_val = data[data_key];
                let path__ = `${path || '<'+cfg['type_key']+'>'}.${data_key}`;
                if (!this.check(data_val, cfg['type_value'], debug, path__, config_)) return;
            }
            return true;
        }
        let keys = Object.keys(cfg);
        for (let i=0; i<keys.length; i++){
            let key = keys[i];
            let type_: string = cfg[key];
            let val = data[key];
            let path_ = `${path || '<'+type_+'>'}.${key}`;
            if (this.check(val, type_, debug, path_, config_)) continue;
            return;
        }
        return true;
    }

    static isNum(val: any) {
        return !isNaN(val) &&
            !isNaN(parseFloat(val));
    }

    static uploadFile(opt: any) { // opt: {url: '/api/projects/', field_name: 'asa_file', callback: f}
        let input: HTMLInputElement = document.querySelector('#agroAhoFileLoad');
        if (!input) {
            let div = document.createElement('div');
            div.innerHTML = `<input id="agroAhoFileLoad" type="file" style="display: none" accept=".ods"/>`;
            input = div.firstChild as HTMLInputElement;
            document.querySelector('body').appendChild(input);
        }
        input.onchange = (e: any)=>{
            // console.log('fl change');
            if (input.files.length == 0 || !opt?.url || !opt?.field_name) return;
            let file = e.target.files[0];
            input.value = '';
            this.uploadFile_(file, opt);
        }
        input.click();
    }

    static uploadFile_(file: any, opt: any){ // opt: {url: '/api/projects/', field_name: 'asa_file', callback: f}
        let req = new XMLHttpRequest();
        let fd = new FormData();
        if (!opt.callback) opt.callback = (msg: any)=>{/*console.log(msg)*/};
        opt.callback({type: 'change_file', data: file});

        fd.append(opt.field_name, file);
        req.open("post", opt.url);
        req.upload.onprogress = (event) => {
            let progress = Math.round((event.loaded / event.total) * 100);
            opt.callback({type: 'progress', data: progress})
        }
        req.onerror = () => {
            opt.callback({type: 'result', status: 'error'});
        }
        req.onload = () => {
            let msg = null;
            if (req.status !== 200) {
                if (req.response) {
                    let res = JSON.parse(req.response);
                    if (res && res.status === 'error')
                        msg = res.msg || res.message;
                }
                opt.callback({type: 'result', status: 'error', msg: msg});
            } else {
                let res = JSON.parse(req.response);
                if (res && res.status === 'error') {
                    msg = res.msg || res.message;
                    opt.callback({type: 'result', status: 'error', msg: msg});
                    return;
                }
                opt.callback({type: 'result', status: 'success', data: res});
            }
        };
        req.send(fd);
    }

    static cp(obj:any){
        if (typeof obj == 'object') return JSON.parse(JSON.stringify(obj))
            else return obj;
    }

    static getTimestamp(){ /* дата в формате гггг-мм-дд чч:мм:сс.ССС */
        let d = new Date();
        return d.getFullYear() + '-' + ('0'+(d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) +
            ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
    }

    static async delay(ms: number){
        return new Promise(resolve => {
            setTimeout(() => { resolve('') }, ms);
        });
    }
    static async wait(f: ()=>any, debugMsg?: string, steps?: number, ms?: number){
        if (f()) return f();
        for (let i=0;i<(steps || 25);i++) {
            await this.delay(ms || 300);
            if (debugMsg) console.log('iter wait', i,  (new Date()).toISOString(), debugMsg);
            if (f()) break;
        }
        return f();
    }
    static in(val: any, arr: any){
        if (!Array.isArray(arr) && typeof arr !== 'string') return false;
        return arr.indexOf(val) >= 0;
    }
    static escapeQuotes(str: string){
        return str.replace(/"/g, '\\"');
    }
}
