import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {NdviPanel} from "../Left/NdviPanel/NdviPanel";
import {ParamSearchPanel} from "../Left/ParamSearchPanel/ParamSearchPanel";
import {OrderListPanelComp} from "../Left/OrderList/OrderListPanelComp";
import {FavListDeleteAllComp} from "../Left/FavoritePanel/FavListDeleteAllComp";
import {SearchAddressComp} from "./SearchAddressComp";
import {AgroProjectFormComp} from "../Left/Agro/window/AgroProjectFormComp";
import {IValueByMouse, NdviValueByMoudeComp} from "./NdviValueByMoudeComp";
import {UploadComp} from "../Left/PhotoPanel/UploadPanel/UploadComp";
import {PhotoPanelChooseFilesComp} from "../Left/PhotoPanel/PhotoPanel/PhotoPanelChooseFilesComp";
import {PhotoViewer} from "../Left/PhotoPanel/PhotoViewer/PhotoViewer";
import {PhotoEditor} from "../Left/PhotoPanel/PhotoEditor/PhotoEditor";

import {ShowRoom2Filters} from "../Left/dataShowRoom2/filters/ShowRoom2Filters";
import {SceneHighlightWidgetComp} from "./SceneHighlightWidgetComp";
import {ShowRoom2MapPopupComp} from "../Left/dataShowRoom2/ShowRoom2MapPopupComp";
import {ShowRoom2RightYieldPerGp} from "../Left/dataShowRoom2/ShowRoom2RightYieldPerGp";
import {UrlSyncComp} from "../../UrlSyncComp";
import {RulerInfoComp} from "./RulerInfoComp";
import {GeoObjectMenuComp} from "./GeoObjectMenuComp";
import {ContextMenuComp} from "./ContextMenuComp";
import {LegendPanel} from "../Right/LegendPanel";
import {WindowTriState} from "../../../helper/structs/WindowTriState";
import {IndexByPointComp} from "../Right/IndexByPoint/IndexByPointComp";
import {MeasuringHintComp} from "./MeasuringHintComp";
import {AdminPanelComp} from "../User/AdminPanel/AdminPanelComp";
import {LeftPanelMode} from '../../../store/SearchStore';
import {A2ConfirmExportFormComp} from "../Left/Agro2/A2Common/A2ConfirmExportFormComp";
import {A2ConfirmDeleteFormComp} from "../Left/Agro2/A2Common/A2ConfirmDeleteFormComp";
import {PopupPanelComp} from '../Left/Popup/PopupPanelComp';
import {IndexByPointFullscreenComp} from "../Right/IndexByPoint/IndexByPointFullscreenComp";
import {ExportOptionsComp} from "../Left/ExportPanel/ExportOptionsComp";
import {Agro2FieldMultiEditComp} from "../Left/Agro2/A2FieldEdit/Agro2FieldMultiEditComp";
import {Agro2ToolsGeometryExt} from "../Left/Agro2/A2FieldEdit/Tools/Agro2ToolsGeometryExt";
import {ZaklPanelComp} from "../Left/Agro2/Zalog/ZaklPanel/ZaklPanelComp";
import {LayersListComp} from '../Right/LayersList/LayersListComp';
import {AgroProjectSecurityComp} from "../Left/Agro2/ProjectEditor/AgroProjectSecurityComp";
import {PersonalAreaComp} from "../User/PersonalArea/PersonalAreaComp";
import {Agro2ToolAuto} from "../Left/Agro2/A2FieldEdit/Tools/Agro2ToolAuto";
import {ProjSetting} from "../User/ProjectSettings/ProjSetting";
import {LegendDataShowRoom2Comp} from "../Right/LegendDataShowRoom2Comp";
import {ExportQgis} from "../Right/ExportQgis/ExportQgis";
import {LegendBaseMap} from "../Right/LegendBaseMap/LegendBaseMap";
import {LogView} from "../User/LogView/LogView";
import {computed, runInAction} from "mobx";
import {ProjectSettingPage} from "../../../store/agro/ProjectSettingStore";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {LegendLayerComp} from "../Right/LegendLayer/LegendLayerComp";
import { LegendHeightComp } from '../Right/LegendBaseMap/LegendHeightComp';
import { OverlayTool } from '../../../store/tools/class/OverlayTool';
import Agro2ToolHelp from '../Left/Agro2/A2FieldEdit/Tools/Agro2ToolHelp';
import DragConfirmModalComp from '../Right/LayersList/DragConfirmModalComp';
import DragCancelModalComp from '../Right/LayersList/DragCancelModalComp';
import DragDeleteModalComp from '../Right/LayersList/DragDeleteModalComp';


@observer
export class MapChildComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    doc_keyUp(e: any) {
        if (e.shiftKey && e.code === 'KeyV'&& (e.altKey || e.metaKey)) {
            if (this.props.store.agro2.projectInfo.projectType == null) return;
            runInAction(()=>{
                let p = this.props.store.agro2.projectList.projects.find(a => a.proj_name == this.props.store.agro2.projectInfo.projectName);

                this.props.store.agro2.projectEditor.setEditProject(p);

                this.props.store.agro2.projectSettings.openWindow();
                if (this.props.store.agro2.projectSettings.showProjectSettings){
                    this.props.store.agro2.projectSettings.page = ProjectSettingPage.styles;
                }
            });
        }
    }

    componentDidMount() {
        document.body.addEventListener("keyup", this.doc_keyUp);
    }
    componentWillUnmount() {
        document.body.removeEventListener("keyup", this.doc_keyUp);
    }

    @computed
    get isMeasuringActive(): boolean{
        let ms = this.props.store.map.measuringStatus;
        return ms == MeasuringStatus.Polygon || ms == MeasuringStatus.Rectangle || ms == MeasuringStatus.Polyline;
    }
    
    render() {
        let store = this.props.store;
        let ms = store.map;
        let mouseValue: IValueByMouse =
            {top: store.map.ndviValueByMouseStore.y, left: store.map.ndviValueByMouseStore.x,
             value: store.map.ndviValueByMouseStore.value, loading: store.map.ndviValueByMouseStore.loading};

        let children = store.events.onMapChildren.call([]);

        return <React.Fragment>
            {store.searchPanel.leftPanelMode == LeftPanelMode.search && store.searchPanel.showSearchProductPanel &&
                <NdviPanel store={store} sceneUiType={store.searchPanel.showNdviPanelSceneUiType} />}
            {store.searchPanel.leftPanelMode == LeftPanelMode.favorite && store.searchPanel.showFavoriteProductPanel &&
                <NdviPanel store={store} sceneUiType={store.searchPanel.showNdviPanelSceneUiType} />}
            {(store.searchPanel.leftPanelMode == LeftPanelMode.search || store.searchPanel.leftPanelMode == LeftPanelMode.favorite) && store.searchPanel.showParamsPanel &&
                <ParamSearchPanel store={store} />}

            {store.agro2.fieldToolController.autoPolygon.showHelp && <Agro2ToolHelp store={store}/>}
            {store.exportStore.showExportOptions && <ExportOptionsComp store={store} />}
            {store.searchPanel.showOrderList && <OrderListPanelComp store={store} />}
            {store.searchPanel.showDeleteAllScene && <FavListDeleteAllComp store={store} />}
            {store.searchPanel.searchAddress.isShowPanel() && <SearchAddressComp store={store} />}
            {store.agro.projectForm.showForm && <AgroProjectFormComp store={store} />}
            {store.map.ndviValueByMouseStore.visibleWidget && <NdviValueByMoudeComp store={store} value={mouseValue} />}
            {store.photo.uploadImageStore.showPanel && <UploadComp store={store} />}
            {store.photo.uploadImageStore.showChooseFiles && <PhotoPanelChooseFilesComp store={store} />}
            {store.photo.photoViewerShow && <PhotoViewer store={store} /> }
            {store.photo.editor.editorWindowShow && <PhotoEditor store={store} />}
            {store.dataShowRoomStore.filterPanelShow && <ShowRoom2Filters store={store} />}
            <SceneHighlightWidgetComp store={store} />
            {store.dataShowRoomStore.mapPopup.show && <ShowRoom2MapPopupComp store={store} />}
            {store.dataShowRoomStore.yieldMapPerGP.checked &&
            <ShowRoom2RightYieldPerGp store={store} />}
            {store.searchPanel.leftPanelMode == LeftPanelMode.agro && store.agro2.fieldEditorForm.showFieldMultiEditPanel && <Agro2FieldMultiEditComp store={store} />}
            {store.agro2.confirmExport.show && <A2ConfirmExportFormComp confirmStore={store.agro2.confirmExport} store={store} onSubmit={()=>{store.agro2.confirmExport.doActionExport();}} />}
            {store.agro2.confirmDelete.show && <A2ConfirmDeleteFormComp confirmStore={store.agro2.confirmDelete} store={store} />}
            {store.popup.data?.feature && <PopupPanelComp store={store}/>}
            {store.searchPanel.leftPanelMode == LeftPanelMode.agro && store.agro2.fieldToolController.showPolygonButtons && <Agro2ToolsGeometryExt store={store} />}
            {store.searchPanel.leftPanelMode == LeftPanelMode.agro && store.agro2.fieldToolController.autoPolygon.showPanel && <Agro2ToolAuto store={store} />}
            {store.agro2.zalogi.zakluchenie.showZakluchenie && <ZaklPanelComp store={store} />}
            {store.agro2.projectEditor.showSecurityForm && <AgroProjectSecurityComp store={store}/> }
            {store.user.personalArea.showForm && <PersonalAreaComp store={store} />}
            {store.layerListStore.showConfirmPopup && <DragConfirmModalComp store={store}/>}
            {store.layerListStore.showCancelPopup && <DragCancelModalComp store={store}/>}
            {store.layerListStore.showDeletePopup && <DragDeleteModalComp store={store}/>}
            {(store.agro2.projectSettings.showProjectSettings || store.agro2.projectSettings.hiddenProjectSettings) && <ProjSetting store={store} /> }

            <UrlSyncComp store={store} />
            <RulerInfoComp store={store} />
            {ms.superTools?.searchAddressMarker?.isMenuShown && <GeoObjectMenuComp store={store}/>}
            {ms.menuPointer && <ContextMenuComp store={store} />}
            {Object.keys(LeftPanelMode).includes(store.searchPanel.leftPanelMode) && <LegendDataShowRoom2Comp store={store} />}
            {Object.keys(LeftPanelMode).includes(store.searchPanel.leftPanelMode) && <LegendPanel store={store} />}
            {Object.keys(LeftPanelMode).includes(store.searchPanel.leftPanelMode) && <LegendLayerComp store={store} />}

            {store.indexByPointer.maxPanel && <IndexByPointFullscreenComp store={store} /> }
            {store.indexByPointer.showPanel != WindowTriState.close && !store.indexByPointer.maxPanel && <IndexByPointComp store={store} />}

            <LayersListComp store={store} showPanel={store.map.layersMenuOpened}/>
            {this.isMeasuringActive && ms.hintMousePointer && ms.isMouseInsideMap && <MeasuringHintComp store={store} />}

            {store.user.showAdminPanel && <AdminPanelComp store={store}/>}
            {store.qgisExport.showExportWindow && <ExportQgis store={store} />}
            {<LegendBaseMap store={store} /> }
            {store.user.logView.showLogView && <LogView logViewStore={store.user.logView} store={store} />}
            {this.props.store.map.currentBaselayerKey === 'heights' && <LegendHeightComp store={store}/>} 
            {children}
        </React.Fragment>;
    }
}
