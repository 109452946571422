import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class CloseSvg extends CustomSvg {

    render() {
        return <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()}>
            <path d="M6.4 0L4 2.4L1.6 0L0 1.6L2.4 4L0 6.4L1.6 8L4 5.6L6.4 8L8 6.4L5.6 4L8 1.6L6.4 0Z" fill="#C5C5C5"/>
        </svg>;
    }
}
