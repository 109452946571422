import {CustomStore} from "../CustomStore";
import {save, update} from "../PermalinkDecor";
import {computed, observable, toJS} from "mobx";
import {ProductSettingStore} from "./ProductSettingStore";
import {ActiveProductCodeStore} from "./ActiveProductCodeStore";
import {SceneUiType} from "../SearchStore";
import { IProductConfig } from "../config/ConfigStore";
import { ClassFactory, ClassValueUpdateType } from "../ClassFactory";
import { cloneDeep } from "lodash-es";
import { CheckStates } from "../../helper/structs/CheckStates";
import booleanIntersects from "@turf/boolean-intersects";

export class ProductSetStore extends CustomStore{

    @update @observable activeCodeCurrent: ActiveProductCodeStore = new ActiveProductCodeStore(this);

    @update @observable activeCodeFavorite: ActiveProductCodeStore = new ActiveProductCodeStore(this);

    defaultActiveCode: ActiveProductCodeStore = new ActiveProductCodeStore(this);

    @save
    private static _groupProducts : Map<string, ActiveProductCodeStore> = new Map<string, ActiveProductCodeStore>();
    public getGroupProduct(groupId: string, clone: boolean = false) {
        let dump = ProductSetStore._groupProducts.get(groupId);
        return clone? cloneDeep(dump): dump;
    }

    @save @observable
    private _favoriteProductGroupId: string = null;
    public get favoriteProductGroupId(): string {
        return this._favoriteProductGroupId;
    }
    public set favoriteProductGroupId(value: string) {
        if (this._favoriteProductGroupId == value) return;

        //save settings for current group
        if (this._favoriteProductGroupId) {
            this.onFavoriteProductChanged();
        }

        //load settings for new group
        if (value) {
            let dump = this.getGroupProduct(value);
            if (!dump) {
                // dump = ClassFactory.toJson(this.defaultActiveCode, ClassValueUpdateType.save);
                // this._groupProducts.set(value, dump);
                dump = this.saveGroupProduct(value, this.defaultActiveCode);
            }
            ClassFactory.loadJsonTo(this.activeCodeFavorite, dump);
        }

        this._favoriteProductGroupId = value;
    }

    saveGroupProduct(key: string, obj: ActiveProductCodeStore) {
        let dump = ClassFactory.toJson(obj, ClassValueUpdateType.save);
        ProductSetStore._groupProducts.set(key, dump);
        return dump;
    }

    saveGroupProductAndRefresh(key: string, obj: ActiveProductCodeStore){
        this.saveGroupProduct(key, obj);
        //console.log(this.root.searchPanel.currentGroup, this.root.searchPanel.currentSceneid);
        let fg = this.root.searchPanel.favoriteList.getGroup(key);
        if (this.root.searchPanel.currentSceneVisible &&
            this.root.map.mapScenesStore.favoriteScenesVisible &&
            (fg?.getSelected() || CheckStates.unchecked) != CheckStates.unchecked
        ) {
            let sgGeom = this.root.searchPanel.searchResult.getCurrentGroup()?.getGeometry();
            let fgGeom = fg.getGeometry();
            if (sgGeom && fgGeom && booleanIntersects(sgGeom, fgGeom))
                this.root.searchPanel.currentSceneVisible = false;
        }
        this.root.searchPanel.favoriteList.updateCounter++;
    }

    onFavoriteProductChanged() {
        if (this._favoriteProductGroupId)
            this.saveGroupProductAndRefresh(this._favoriteProductGroupId, this.activeCodeFavorite);
        //this.root.searchPanel.favoriteList.updateCounter++;
    }

    getProductByProdName(prod_name: string, sceneUiType: SceneUiType): ProductSettingStore{
        return this.getActiveCodeByUiType(sceneUiType).products.find(a => a.prod_name == prod_name);
    }

    getActiveCodeByUiType(sceneUiType: SceneUiType): ActiveProductCodeStore{
        if (sceneUiType == SceneUiType.current) return this.activeCodeCurrent;
        if (sceneUiType == SceneUiType.favorite) return this.activeCodeFavorite;
        throw "Unknow SceneUiType";
    }

    getFavoriteGroupProduct(groupId: string): ActiveProductCodeStore {
        let dump = this.getGroupProduct(groupId);
        if (! dump) {
            // dump = ClassFactory.toJson(this.defaultActiveCode, ClassValueUpdateType.save);
            // this._groupProducts.set(groupId, dump);
            dump = this.saveGroupProduct(groupId, this.defaultActiveCode);
        }
        let ap: ActiveProductCodeStore = new ActiveProductCodeStore(this);
        ClassFactory.loadJsonTo(ap, dump);
        return ap;
    }

    duplicateFavoriteGroupProduct(fromGroupId: string, toGroupId: string) {
        let cpy = this.getGroupProduct(fromGroupId, true);
        ProductSetStore._groupProducts.set(toGroupId, cpy);
    }

    copyFavoriteGroupProduct(groupId: string) {        
        if (this._favoriteProductGroupId == groupId) {

        }            
        this.getGroupProduct(groupId, true);
    }

    applyFavoriteGroupProduct(groupId: string, prod: ActiveProductCodeStore) {
        ProductSetStore._groupProducts.set(groupId, prod);
    }
    
    addProduct(cfg: IProductConfig) {
        [this.activeCodeCurrent, this.activeCodeFavorite, this.defaultActiveCode].forEach(store => {
            let ps = new ProductSettingStore(this);
            ps.prod_name = cfg.tile_prod_name;
            ps.unitName = cfg.unitName;
            ps.unitDescription = cfg.unitDescription;
            ps.productConfig = cfg;
            ps.loadDefault();
            store.products.push(ps);
        });
    }
   
}