import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {DropTextComp, DropTextFindType, IDropTextCompProps} from "../../../../Common/DropTextComp";
import classNames from "classnames";
import {DropDownComp, IDropDownCompProps, IDropDownItem} from "../../../../Common/DropDownComp";
import {action} from "mobx";
import {Utils} from "../../../../../helper/utils/Utils";
import "../A2Common/Agro2Common.scss";
import {A2FieldEditorCustomInputComp, IA2FieldEditorProps} from "./A2FieldEditorCustomInputComp";
import {A2ReferenceStore} from "../../../../../store/agro/A2ReferenceStore";
import {TextUtils} from "../../../../../helper/utils/TextUtils";
import {IProjectRefType} from "../../../../../store/user/UserStore";

@observer
export class A2FieldEditorRefComp extends A2FieldEditorCustomInputComp {
    constructor(props: IA2FieldEditorProps) {
        super(props);
        autoBindReact(this);
        this.init();
    }
    componentWillUnmount() {
        if (this.reference != null)
            this.reference.deleteUsed(this.usedHash);
    }

    usedHash: any = null;
    reference: A2ReferenceStore;
    async init(){
        this.props.field.referencePropertiesText[this.props.columnInfo.column_name] = {text: "", isNewValue: false};
        let ref = this.props.store.agro2.projectInfo.getReferenceByName(this.props.columnInfo.reference_name);
        this.reference = ref;
        if (ref.status == null){
            try {
                await ref.loadReference();
                this.setTextByRef();
                this.usedHash = this.reference.addUsed();
            }catch (err){
                this.props.store.addError(err);
            }
        }else{
            this.setTextByRef();
            this.usedHash = this.reference.addUsed();
        }
    }

    @action
    onChange(text: string, item: IDropDownItem):void{
        let store = this.props.store;
        this.props.field.changedColumns.add(this.props.columnInfo.column_name);
        if (item != null) {
            this.props.field.referencePropertiesText[this.props.columnInfo.column_name] = {text: text, isNewValue: false};
            this.props.field.properties[this.props.columnInfo.column_name] = item.data;
        }else {
            this.props.field.properties[this.props.columnInfo.column_name] = null;
            this.props.field.referencePropertiesText[this.props.columnInfo.column_name] = {text: text, isNewValue: true};
            if (text = "") this.props.field.referencePropertiesText[this.props.columnInfo.column_name] = {text: text, isNewValue: false};
        }
        if (this.props.onChange) this.props.onChange(this.props.data);
    }
    @action
    onChangeDropDown(item: IDropDownItem, newIndex: number){
        this.props.field.changedColumns.add(this.props.columnInfo.column_name);
        this.props.field.properties[this.props.columnInfo.column_name] = item.data;
    }

    setTextByRef(){
        this.props.field.referencePropertiesText[this.props.columnInfo.column_name] = {text: this.getValueFromRef() , isNewValue: false};
    }
    getValueFromRef() {
        let v = this.reference.ref.get(this.props.field.properties[this.props.columnInfo.column_name]);
        if (v != null) return v;
        return "";
    }


    render() {
        let store = this.props.store;
        let col = this.props.columnInfo;
        let cropItems: IDropDownItem[] = [];
        if (this.reference == null) return null;
        this.reference.ref.forEach((value, key) => {
            cropItems.push({key: key, value: value, data: key});
        });
        let ref = store.agro2.projectInfo.getReferenceByName(col.reference_name);

        cropItems = cropItems.sort((a,b)=> TextUtils.stringSortCompare(a.value, b.value));
        if (!this.props.columnInfo.required) {
            Utils.arrayInsert(cropItems, 0, {key: "", value: "-- " + store.trans["Not set"] + " --", data: null});
        }
        let text: string = "";
        if (this.props.field.referencePropertiesText[this.props.columnInfo.column_name]) {
            text = this.props.field.referencePropertiesText[this.props.columnInfo.column_name].text;
        }else{
            text = this.getValueFromRef();
        }

        let pref = this.getPreference();

        let placeholder = "";
        if (pref.isDiffValuesLabel) placeholder = this.props.store.trans["Different values"];
        if (pref.isClearValue) placeholder = this.props.store.trans["Clear value"];


        let isFix = ref.reference_type == IProjectRefType.global;
        let curKey: any = null;
        if (isFix){
            curKey = this.props.field.properties[this.props.columnInfo.column_name];
            if (curKey == null) curKey = "";
        }

        let dropTextCompProps: IDropTextCompProps;
        let dropDownCompProps: IDropDownCompProps;
        if (isFix){
            dropDownCompProps = {
                items: cropItems, currentKey: curKey, onChange: this.onChangeDropDown,
                placeHolder: placeholder,
                placeHolderSearch: store.trans.Search, className: "width100",
                disabled: this.props.isReadonly === true
            }
            let p: IOnAgroFormCreatePropertyDropDownCompRender = {
                dropDownCompProps: dropDownCompProps,
                dropTextCompProps: dropTextCompProps,
                self: this.props
            };
            this.props.store.events.onAgroFormCreatePropertyDropDownCompRender.call(p);
        }
        if (!isFix) {
            dropTextCompProps = {
                items: cropItems, text: text,
                onChange: this.onChange, findType: DropTextFindType.contain, caseSensitive: false,
                disabled: this.props.isReadonly === true,
                placeHolder: placeholder, hasDownButton: false,
                classNameDropDown: classNames("Agro2Common-dropDownText", {"disbaled": this.props.isReadonly === true}),
                classNameButton: classNames({"gray-fill gray-stroke": this.props.isReadonly === true}),
                classNameInput: classNames("Agro2CreateField-inputDark hide_placeholder_on_focus", {
                    "Agro2CreateField-disabled": this.props.isReadonly === true,
                    "Agro2CreateField-inputClearValue": pref.isClearValue,
                    "Agro2CreateField-referenceWithExtButton": pref.hasClearButton || pref.hasReturnButton,//больший padding если есть ещё кнопка
                    "red_border": this.props.validType != null
                }),
                className: classNames("width100"),
            }

        }
        return <div className="relative">
            {isFix && <DropDownComp {...dropDownCompProps}
            />}
            {!isFix && <DropTextComp {...dropTextCompProps}
                />}

                {(pref.hasClearButton || pref.hasReturnButton) && <div className={classNames("Agro2CreateField-referenceExtButtonDiv", {"Agro2CreateField-referenceExtButtonDiv-notFix": !isFix})}>
                    {pref.hasClearButton && <div className="Agro2CreateField-inputExtButtonSize" onClick={this.onClickClear}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z" fill="#C5C5C5"/>
                        </svg>
                    </div>}
                    {pref.hasReturnButton && <div className="Agro2CreateField-inputExtButtonSize" onClick={this.onClickReturn}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.25 9.975L6.475 8.75L3.85 6.125H8.75C10.2375 6.125 11.375 7.2625 11.375 8.75C11.375 10.2375 10.2375 11.375 8.75 11.375H1.75V13.125H8.75C11.2 13.125 13.125 11.2 13.125 8.75C13.125 6.3 11.2 4.375 8.75 4.375H3.85L6.475 1.75L5.25 0.525002L0.525002 5.25L5.25 9.975Z" fill="#C5C5C5"/>
                        </svg>
                    </div>}
                </div>}
            </div>;
    }
}
import {IOnAgroFormCreatePropertyDropDownCompRender} from "../../../../../../pluginApi/PluginsEvents";
