import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from '../../../../../helper/structs/IStoreProps';
import {
    A2NavigatorItemStore,
    IA2NavigationItemValue,
    NavigatorItemStoreType
} from "../../../../../store/agro/A2NavigatorItemStore";
import {action} from "mobx";
import './A2FolderCss.scss';
import {ra} from "../../../../../helper/utils/mobxUtils";
import {PenSvg} from "../../Agro/Buttons/PenSvg";
import {Utils} from "../../../../../helper/utils/Utils";
import classNames from "classnames";
import {IEventAgroGroupItemEditField} from "../../../../../../pluginApi/PluginsEvents";
import { A2ConfirmExportMode } from '../../../../../store/agro/Confirms/A2ConfirmExportStore';
import { TrashSvg } from '../../Agro/Buttons/TrashSvg';

export interface IA2FolderItemComp extends IStoreProps{
    value: IA2NavigationItemValue;
    highligth?: boolean;
}
@observer
export class A2FolderItemComp extends React.Component<IA2FolderItemComp, undefined> {
    constructor(props: IA2FolderItemComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        let store = this.props.store;
        let navItem = store.agro2.projectStruct.activeNavigatorPage;
        let index = store.agro2.projectStruct.structColumns.findIndex(a => navItem.columnInfo == a);
        if ((index + 1) < store.agro2.projectStruct.structColumns.length ){
            let d = store.agro2.projectStruct.structColumns[index + 1];
            navItem.column_value = this.props.value.key;
            navItem.column_stringValue = this.props.value.name;

            if (navItem.nextItem == null) navItem.nextItem = new A2NavigatorItemStore(navItem);
            navItem.nextItem.id_column = d.id;
            store.agro2.projectStruct.setActivePage(navItem.nextItem);
            navItem.nextItem.resetValues();

        }else{
            navItem.column_value = this.props.value.key;
            navItem.column_stringValue = this.props.value.name;

            if (navItem.nextItem == null) navItem.nextItem = new A2NavigatorItemStore(navItem);
            navItem.nextItem.type = NavigatorItemStoreType.fields;
            store.agro2.projectStruct.setActivePage(navItem.nextItem);
            navItem.nextItem.resetValues();
        }
    }
    async clickCenter(e: any){
        let store = this.props.store;
        let v = this.props.value;
        e.stopPropagation();
        try {
            let navItem = store.agro2.projectStruct.activeNavigatorPage;

            let filter: any = navItem.getAllFilters({notFirst:false, firstValue: v.key});


            let bbox = await store.agro2.getFieldsBboxByFilter(filter);
            ra(()=>{
                this.props.store.agro2.agroFieldVisible = true;
                store.map.zoomToBBox1in3(bbox);
            });
        }catch (err){
            store.addError(err);
        }
    }
    async clickGroupEdit(e: any){
        let store = this.props.store;
        let v = this.props.value;
        e.stopPropagation();
        try {
            let navItem = store.agro2.projectStruct.activeNavigatorPage;
            if (navItem == null) return;
            let filter: any = navItem.getAllFilters({notFirst: false, firstValue: v.key});
            store.agro2.fieldEditorForm.initGroupEditField(navItem, filter);//fieldEditorForm
            store.agro2.fieldEditorForm.properties[navItem.columnInfo.column_name] = v.key;
        }catch (err){
            store.addError(err);
        }
    }

    @action
    onMouseEnter(){
        let navItem = this.props.store.agro2.projectStruct.activeNavigatorPage;
        let v = this.props.value;
        let filter: any = navItem.getFilters({notFirst:false, firstValue: v.key});
        this.props.store.agro2.hover_folder = filter;
    }
    @action
    onMouseLeave(){
        this.props.store.agro2.hover_folder = null;
    }

    @action
    onClickDelete(e: any, value: IA2NavigationItemValue){
        e.stopPropagation();
        let agro = this.props.store.agro2;
        if (!agro.permission.canDeleteObject()) return;
        //this.popupShow = false;
        let navItem = agro.projectStruct.activeNavigatorPage;
        if (navItem == null) return;
        let t = this.props.value;
        agro.confirmDelete.fields = t.field_cnt;
        agro.confirmDelete.area = t.area_cnt;
        agro.confirmDelete.activeNavigatorItem = navItem;
        agro.confirmDelete.filterSelected = navItem.getAllFilters({notFirst: false, firstValue: t.key});

        let paths = navItem.getNaviPath();
        let pathText = this.props.store.agro.projectTitle;
        for(let i = paths.length - 1; i >= 1 ; i--){
            pathText += " > ";
            pathText += paths[i].column_stringValue;
        }
        pathText += " > " + value.name;
        agro.confirmDelete.mode_export = A2ConfirmExportMode.folder;
        agro.confirmDelete.path = pathText;
        agro.confirmDelete.doShow();
    }


    render() {
        let store = this.props.store;
        let v = this.props.value;

        let ev: IEventAgroGroupItemEditField = {value: v, ok: true};
        store.events.onAgroCanEditGroupItemField.call(ev);
        let title = v.name;
        if (title == null) title = "-- " + store.trans["Not set"] + " --";
        let canEdit = ev.ok;
        let showDeleteFieldStr: {ok: boolean} = {ok: true};
        store.events.onAgroCanDeleteField.call(showDeleteFieldStr);
        let canDelete = showDeleteFieldStr.ok;
        return <div className={classNames("A2FolderItemComp-row A2FolderItemComp-row-hover",
            )} onClick={this.onClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            {this.props.highligth && <div className="A2FolderItemComp-rowHighlight" />}
            <div className="A2FolderItemComp-td-folderIcon">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6V11.25C0 11.7 0.3 12 0.75 12H11.25C11.7 12 12 11.7 12 11.25V6H0Z" fill="#6B707B"/>
                    <path d="M12 4.5V3C12 2.55 11.7 2.25 11.25 2.25H6.3L4.275 0.225C4.125 0.075 3.975 0 3.75 0H0.75C0.3 0 0 0.3 0 0.75V4.5H12Z" fill="#6B707B"/>
                </svg>
            </div>
            <div className="A2FolderItemComp-td-title">{title}</div>
            <div className="A2FolderItemComp-td-sum">{Utils.roundDigitsWithSpace(v.field_cnt, 2)}</div>
            <div className={classNames("A2FolderItemComp-buttons ", {"A2FolderItemComp-buttons-hovered": this.props.highligth})}>
                {canDelete && <div className="A2FolderItemComp-button white-fill red_fill_onhover" onClick={(e: any) => {this.onClickDelete(e, v)}}>
                    <TrashSvg />
                </div>}
                {canEdit && <div className="A2FolderItemComp-button white_svg blue_svg_onhover" onClick={this.clickGroupEdit}>
                    <PenSvg/>
                </div>}
                <div className="A2FolderItemComp-button white_svg blue_svg_onhover"  onClick={this.clickCenter}>
                    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 8.25C7.24264 8.25 8.25 7.24264 8.25 6C8.25 4.75736 7.24264 3.75 6 3.75C4.75736 3.75 3.75 4.75736 3.75 6C3.75 7.24264 4.75736 8.25 6 8.25Z" stroke="none"/>
                        <path d="M1.5 4.5H0V0.75C0 0.3 0.3 0 0.75 0H4.5V1.5H1.5V4.5Z" stroke="none"/>
                        <path d="M12 4.5H10.5V1.5H7.5V0H11.25C11.7 0 12 0.3 12 0.75V4.5Z" stroke="none"/>
                        <path d="M11.25 12H7.5V10.5H10.5V7.5H12V11.25C12 11.7 11.7 12 11.25 12Z" stroke="none"/>
                        <path d="M4.5 12H0.75C0.3 12 0 11.7 0 11.25V7.5H1.5V10.5H4.5V12Z"  stroke="none"/>
                    </svg>
                </div>
            </div>
        </div>;
    }
}
