import * as React from 'react';
import {isBoolean, isFunction, isNumber, isString} from "lodash-es";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";

export interface IRangeStepCompProps {
    min?: number,
    value: number,
    max?: number,
    itemStr?: string,
    onChange?: (sender: any, value: number)=>void,
    disable?: boolean,
}

export class RangeStepComp extends React.PureComponent<IRangeStepCompProps, undefined> {
    constructor(props: IRangeStepCompProps) {
        super(props);
        autoBindReact(this);
        this.ref_bar = React.createRef();
    }

    ref_bar: any;

    OnDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>){
        if (this.props.disable) return;
        document.addEventListener("mousemove", this.onMove);
        document.addEventListener("mouseup", this.OnUp);
        this.doMove(event.pageX, event.pageY);
        return false;
    }

    onMove(event: MouseEvent){
        this.doMove(event.pageX, event.pageY);
        return false;
    }

    doMove(x: number, y: number){
        if (this.props.disable) return;
        let bar: HTMLDivElement = this.ref_bar.current;
        let rect = bar.getBoundingClientRect();
        let left = rect.left + window.scrollX;
        let right = rect.right + window.scrollX;
        let newProc = (x - left) / (right - left);
        let newValue = this.getMin() + newProc * (this.getMax() - this.getMin());
        if (newValue < this.getMin()) newValue = this.getMin();
        if (newValue > this.getMax()) newValue = this.getMax();
        if (newValue == this.props.value) return;
        if (isFunction(this.props.onChange)) this.props.onChange(this, newValue);
    }


    OnUp(event: MouseEvent){
        if (this.props.disable) return;
        document.removeEventListener("mousemove", this.onMove);
    }

    getMin(){
        let min = this.props.min;
        if (!isNumber(min)) min = 0;
        return min;
    }
    getMax(){
        let max = this.props.max;
        if (!isNumber(max)) max = 100;
        return max;
    }

    render() {
        let itemStr = this.props.itemStr;
        if (!isString(itemStr)) itemStr = "%";
        let min = this.getMin();
        let max = this.getMax();
        let value = this.props.value;
        let procRound = Math.round(value);
        let proc = value / (max - min) * 100;
        let st_pointer = {left: proc.toString()+"%"};
        let st_selected = {left: "0", width: proc.toString()+"%"};
        let disable = this.props.disable;
        let middleActive = procRound >= (max-min)/2 ? true : false
        if (!isBoolean(disable)) disable = false;

        return <div className="popup-pref-in__row3_right noselect">
            <div className={classNames("range-example-5 asRange", {"disable-backgroundColor": disable})} ref={this.ref_bar} onMouseDown={this.OnDown} >
                <div className={classNames('asRange-middle-pointer',{"middle-pointer-active": !disable && middleActive})}/>
                <div className="asRange-bar"/>
                <div className={classNames("asRange-pointer",{"disable-backgroundColor-after":disable,
                    "disable-backgroundColor-before": disable,
                "disable-pointer":disable})} tabIndex={0} style={st_pointer}><span
                    className={classNames("asRange-tip",
                        {"disable-text": disable})}>{procRound.toString()+itemStr}</span></div>
                <span className={classNames("asRange-selected", {"disable-backgroundColor": disable})} style={st_selected}/>
                <div className="asRange-scale">
                    <ul className="asRange-scale-lines">
                        <li className="asRange-scale-grid" style={{left: "0%"}}/>
                        <li style={{left: "5%"}}/>
                        <li style={{left: "10%"}}/>
                        <li style={{left: "15%"}}/>
                        <li style={{left: "20%"}}/>
                        <li className="asRange-scale-inlineGrid" style={{left: "25%"}}/>
                        <li style={{left: "30%"}}/>
                        <li style={{left: "35%"}}/>
                        <li style={{left: "40%"}}/>
                        <li style={{left: "45%"}}/>
                        <li className="asRange-scale-grid" style={{left: "50%"}}/>
                        <li style={{left: "55%"}}/>
                        <li style={{left: "60%"}}/>
                        <li style={{left: "65%"}}/>
                        <li style={{left: "70%"}}/>
                        <li className="asRange-scale-inlineGrid" style={{left: "75%"}}/>
                        <li style={{left: "80%"}}/>
                        <li style={{left: "85%"}}/>
                        <li style={{left: "90%"}}/>
                        <li style={{left: "95%"}}/>
                        <li className="asRange-scale-grid" style={{left: "100%"}}/>
                    </ul>
                    <ul className="asRange-scale-values">
                        <li style={{left: "0%"}}><span>0</span></li>
                        <li style={{left: "50%"}}><span>50</span></li>
                        <li style={{left: "100%"}}><span>100</span></li>
                    </ul>
                </div>
            </div>
            <div className={classNames("range__steps", {"disable": disable})}>
                <div className="range__steps_step"><span>{min}{itemStr}</span></div>
                <div className="range__steps_step"><span>{(max-min)/2}{itemStr}</span></div>
                <div className="range__steps_step"><span>{max}{itemStr}</span>
                </div>
            </div>
        </div>;
    }
}
