import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {computed, observable} from "mobx";
import {IndexByPointGraphStore} from "./IndexByPointGraphStore";

export interface IYearPeriod{
    year: number;
    isClimate: boolean;
}

export class IndexByPointYearStore extends CustomStore{
    class(): string {
        return "IndexByPointYearStore";
    }

    @save @observable
    idPoint: string;
    @save @observable
    _visible: boolean = true;
    get visible(): boolean{ return this._visible;}
    set visible(value: boolean){
        this._visible = value;
        this.graphs.forEach(a => a.visible = value);
    }
    @save @observable
    yearPeriod: IYearPeriod = {year: 0, isClimate: false}

    comparePeriod(yp: IYearPeriod): boolean{
        return IndexByPointYearStore.comparePeriod(this.yearPeriod, yp);
    }
    static comparePeriod(yp1: IYearPeriod, yp2: IYearPeriod): boolean{
        return yp1.year == yp2.year && yp1.isClimate == yp2.isClimate;
    }
    static sortPeriod(yp1: IYearPeriod, yp2: IYearPeriod): number{
        if (yp1.isClimate == yp2.isClimate == false){
            return yp1.year - yp2.year;
        }
        if (yp1.isClimate) return 1;
        if (yp2.isClimate) return -1;
        return 0;
    }
    @observable
    customColor: number | null = null;
    @computed
    get graphs(): IndexByPointGraphStore[]{
        return this.root.indexByPointer.graphs.filter(a => a.idPoint == this.idPoint && a.comparePeriod(this.yearPeriod));
    }
    @computed
    get color(): number {
        if (this.customColor !== null) {
            return this.customColor;
        }

        if (this.graphs.length != 1) return 0;
        return this.graphs[0].color;
    }
    @computed
    get singleGraph(): IndexByPointGraphStore{
        if (this.graphs.length != 1) return null;
        return this.graphs[0];}
        setCustomColor(newColor: number): void {
            this.customColor = newColor;
        }
    }
