import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../helper/structs/IStoreProps";
import {action} from "mobx";
import {Utils} from "../../helper/utils/Utils";

export class LastStackComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.id = Utils.randomStr();
    }
    id: string;
    @action
    componentWillUnmount() {
        this.props.store.removeTopComponent(this.id);
    }
    @action
    componentDidMount() {
        this.props.store.insertTopComponent(<React.Fragment key={this.id}>{this.props.children}</React.Fragment>, this.id);
    }
    @action
    componentDidUpdate(prevProps: Readonly<IStoreProps>, prevState: Readonly<undefined>, snapshot?: any) {
        this.props.store.removeTopComponent(this.id);
        this.props.store.insertTopComponent(<React.Fragment key={this.id}>{this.props.children}</React.Fragment>, this.id);
    }
    render() {
        return true;
    }

}
