import * as React from 'react';
import {ReactNode} from 'react';
import autoBindReact from "auto-bind/react";
import './ContextItems.scss';
import classNames from "classnames";

export interface IContextItem {
    data?: any;
    title: string;
    key: any;
    icon?: ReactNode;
    disabled?: boolean;
    classname?: string;
    onClick?: (item: IContextItem)=> any;
}
export interface IContextItemsProps {
    items: IContextItem[];
    onClickItem?: (item: IContextItem)=> any;
}

export class ContextItems extends React.PureComponent<IContextItemsProps, undefined> {
    constructor(props: IContextItemsProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(item: IContextItem){
        if (item.onClick) item.onClick(item);
        if (this.props.onClickItem) this.props.onClickItem(item);
    }
    render() {
        let items: any[] = this.props.items.map(a =>{
            let click = ()=>{
                this.onClick(a);
            };
            return <div key={a.key} className={classNames("ContextItems-item",
                        {
                            "ContextItems-item-disabled": a.disabled,
                            "ContextItems-item-active": !a.disabled,
                        }, a.classname)}
                        onClick={click}>
                {a.icon}
                {a.title}
            </div>;
        });

        return <div className="ContextItems-outer">
            {items}
        </div>
    }
}
