// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhotoEditor-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.PhotoEditor-header {
  display: flex;
  line-height: 22px;
  color: #C5C5C5;
  align-items: center;
  margin: 16px;
}

.PhotoEditor-headerTitle {
  font-weight: bold;
  font-size: 16px;
}

.PhotoEditor-headerClose {
  align-self: flex-start;
  cursor: pointer;
}

.PhotoEditor-marginLR {
  margin-left: 16px;
  margin-right: 16px;
}

.PhotoEditor-filters {
  margin-top: 21px;
}

.PhotoEditor-footer {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.PhotoEditorTools-selectAll {
  min-width: 194px;
  display: flex;
}

.PhotoEditorTools-main {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.PhotoEditorTools-item {
  margin-top: 5px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/PhotoPanel/PhotoEditor/PhotoEditor.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,oBAAA;AACF;;AACA;EACE,aAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;AAEF;;AAAA;EACE,iBAAA;EACA,eAAA;AAGF;;AADA;EACE,sBAAA;EACA,eAAA;AAIF;;AAFA;EACE,iBAAA;EACA,kBAAA;AAKF;;AAFA;EACE,gBAAA;AAKF;;AAHA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;AAMF;;AAHA;EACE,gBAAA;EACA,aAAA;AAMF;;AAJA;EACE,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAOF;;AALA;EACE,eAAA;EACA,kBAAA;AAQF","sourcesContent":[".PhotoEditor-window{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  align-items: stretch;\r\n}\r\n.PhotoEditor-header{\r\n  display: flex;\r\n  line-height: 22px;\r\n  color: #C5C5C5;\r\n  align-items: center;\r\n  margin: 16px;\r\n}\r\n.PhotoEditor-headerTitle{\r\n  font-weight: bold;\r\n  font-size: 16px;\r\n}\r\n.PhotoEditor-headerClose{\r\n  align-self: flex-start;\r\n  cursor: pointer;\r\n}\r\n.PhotoEditor-marginLR{\r\n  margin-left: 16px;\r\n  margin-right: 16px;\r\n}\r\n\r\n.PhotoEditor-filters{\r\n  margin-top: 21px;\r\n}\r\n.PhotoEditor-footer{\r\n  font-size: 12px;\r\n  line-height: 20px;\r\n  text-align: center;\r\n}\r\n\r\n.PhotoEditorTools-selectAll{\r\n  min-width: 194px;\r\n  display: flex;\r\n}\r\n.PhotoEditorTools-main{\r\n  align-items: center;\r\n  margin-top: 10px;\r\n  margin-bottom: 10px;\r\n  flex-wrap: wrap;\r\n}\r\n.PhotoEditorTools-item{\r\n  margin-top: 5px;\r\n  margin-bottom: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
