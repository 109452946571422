import {CustomStoreTool} from "../../app/store/tools/general/CustomStoreTool";
import {ToolEvent} from "../../pluginApi/tools/ToolEvent";
import {MeteoStore} from "./MeteoStore";
import {IMeteoSuperStore, LeftPanelModeMeteo} from "./meteoPlugin";
import {MeasuringStatus} from "../../app/helper/structs/MeasuringStatus";
import * as mapboxgl from "maplibre-gl";
import {MapMouseEvent} from "maplibre-gl";

export class MeteoClickTool extends CustomStoreTool{

    get meteoStore(): MeteoStore{
        return (this.store as any as IMeteoSuperStore).meteoStore;
    }
    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent) {
        this.setCursor(e);
    }

    setCursor(e: mapboxgl.MapMouseEvent & ToolEvent){
        if (this.store.map.measuringStatus == MeasuringStatus.ndviValueByMouse){
            e.cursor = ` url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.87249 4.87751L6.625 4.63003L6.37751 4.87751L3.00251 8.25251L2.94012 8.31491L2.91478 8.39938L2.91477 8.39939L2.91476 8.39943L2.91475 8.39946L2.91458 8.40003L2.91376 8.40274L2.91025 8.41423L2.89618 8.45951C2.8838 8.49891 2.86567 8.55556 2.84296 8.6237C2.79735 8.76052 2.73411 8.94106 2.66253 9.12001C2.58971 9.30208 2.51351 9.46908 2.44363 9.58555C2.42647 9.61415 2.41175 9.63619 2.39976 9.6527C1.42768 9.70513 0.65 10.5153 0.65 11.5C0.65 12.5183 1.4817 13.35 2.5 13.35C3.48467 13.35 4.29487 12.5723 4.3473 11.6002C4.36381 11.5883 4.38585 11.5735 4.41445 11.5564C4.53092 11.4865 4.69792 11.4103 4.87999 11.3375C5.05894 11.2659 5.23948 11.2026 5.3763 11.157C5.44444 11.1343 5.50109 11.1162 5.54049 11.1038L5.58577 11.0897L5.59726 11.0862L5.59997 11.0854L5.60054 11.0852L5.60057 11.0852L5.60061 11.0852L5.60062 11.0852L5.68509 11.0599L5.74749 10.9975L9.12249 7.62249L9.36998 7.375L9.12249 7.12751L6.87249 4.87751ZM2.36558 9.69282C2.36559 9.69266 2.36711 9.69115 2.37012 9.6888C2.36707 9.69181 2.36557 9.69298 2.36558 9.69282ZM4.30718 11.6344C4.30702 11.6344 4.3082 11.6329 4.3112 11.6299C4.30885 11.6329 4.30734 11.6344 4.30718 11.6344Z' fill='black' stroke='white' stroke-width='0.7'/%3E%3Cpath d='M13.1725 6.07751L11.92 4.825L12.7975 3.94749C13.5342 3.2108 13.5342 1.9392 12.7975 1.20251C12.0608 0.465829 10.7892 0.465829 10.0525 1.20251L9.175 2.08003L7.92249 0.827513L7.675 0.580025L7.42751 0.827513L6.00251 2.25251L5.75503 2.5L6.00251 2.74749L11.2525 7.99749L11.5 8.24497L11.7475 7.99749L13.1725 6.57249L13.42 6.325L13.1725 6.07751Z' fill='black' stroke='white' stroke-width='0.7'/%3E%3C/svg%3E%0A") 1 12, pointer`;
        }
    }

    onMouseDown(e: MapMouseEvent & ToolEvent): void {
        let p = e.lngLat;
        let store = this.store;
        if (e.dragging){
            this.meteoStore.visibleWidget = false;
            return;
        }
        if (store.map.measuringStatus != MeasuringStatus.ndviValueByMouse) return;
        this.setCursor(e);

        if (store.searchPanel.leftPanelMode == LeftPanelModeMeteo) {
            if (this.meteoStore.deviation) {
                this.meteoStore.getPixelValue(p);
                this.meteoStore.visibleWidget = true;
            }
        }else this.meteoStore.visibleWidget = false;
        e.noPropagation();
    }
}