import {ContainerTools} from "../general/ContainerTools";
import {CustomStoreTool} from "../general/CustomStoreTool";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {AutoPolygonModel, FieldAutoStore} from "../../agro/fieldForm/FieldAutoStore";
import {GeometryUtils} from "../../../helper/utils/GeometryUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {action, autorun, toJS} from "mobx";
import mapboxgl, {CirclePaint, FillPaint, LinePaint, LngLat} from "maplibre-gl";
import {ra} from "../../../helper/utils/mobxUtils";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import { Point, Polygon } from "geojson";
import { Utils } from "../../../helper/utils/Utils";

export class AutoPolygonTool extends CustomStoreTool{
    installed: boolean = false;
    static EDITING_POLYGON_SOURCE_ID = "CLASS_AutoEditingPolygon";
    static EDITING_POLYGON_FILL_ID = "CLASS_AutoEditingFillPolygon";
    static POINTS_SOURCE_ID = "CLASS_AutoPolygonPoints";
    static REMEMBERED_POLYGONS_SOURCE_ID = "CLASS_AutoRememberedPolygons";
    static REMEMBERED_POLYGONS_FILL_ID = "CLASS_AutoRememberedFillPolygons";

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                if (!this.store.map.mapReady) return;
                if (this.store.map.measuringStatus == MeasuringStatus.agroAutoPolygon) {
                    //if (this.installed) return;
                    this.unInstallLayer();
                    this.installLayers();
                    this.installed = true;
                } else {
                    if (!this.installed) return;
                    this.unInstallLayer();
                    this.installed = false;
                }
            })
        ];
    }

    installLayers(){
        if (this.map.getSource(AutoPolygonTool.EDITING_POLYGON_SOURCE_ID) == null) {
            let geoJson: any = toJS(this.store.agro2.fieldToolController.autoPolygon.editingPolygon);
            if (geoJson == null) geoJson = ContainerTools.EMPTY_SOURCE;
            this.map.addSource(AutoPolygonTool.EDITING_POLYGON_SOURCE_ID, {
                'type': 'geojson',
                'data': geoJson
            });
            this.addLayer({
                id: AutoPolygonTool.EDITING_POLYGON_SOURCE_ID,
                source: AutoPolygonTool.EDITING_POLYGON_SOURCE_ID,
                type: 'line',
                paint: <LinePaint>{
                    'line-color': 'rgb(255,94,84)',
                    "line-width": 4,
                }
            });
            this.addLayer({
                id: AutoPolygonTool.EDITING_POLYGON_FILL_ID,
                source: AutoPolygonTool.EDITING_POLYGON_SOURCE_ID,
                type: 'fill',
                paint: <FillPaint>{
                    'fill-opacity': 0
                }
            });
        }
        if (this.map.getSource(AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID) == null) {
            let geoJson: any = toJS(this.store.agro2.fieldToolController.autoPolygon.rememberedPolygons);
            if (geoJson == null) geoJson = ContainerTools.EMPTY_SOURCE;
            this.map.addSource(AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID, {
                'type': 'geojson',
                'data': geoJson
            });
            this.addLayer({
                id: AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID,
                source: AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID,
                type: 'line',
                paint: <LinePaint>{
                    'line-color': 'blue',
                    "line-width": 4,
                }
            });
            this.addLayer({
                id: AutoPolygonTool.REMEMBERED_POLYGONS_FILL_ID,
                source: AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID,
                type: 'fill',
                paint: <FillPaint>{
                    'fill-opacity': 0
                }
            });
        }
        if (this.map.getSource(AutoPolygonTool.POINTS_SOURCE_ID) == null) {
            let geoJson: any = toJS(this.store.agro2.fieldToolController.autoPolygon.points);
            if (geoJson == null) geoJson = ContainerTools.EMPTY_SOURCE;
            this.map.addSource(AutoPolygonTool.POINTS_SOURCE_ID, {
                'type': 'geojson',
                'data': geoJson
            });
            this.addLayer({
                id: AutoPolygonTool.POINTS_SOURCE_ID,
                source: AutoPolygonTool.POINTS_SOURCE_ID,
                type: 'circle',
                paint: <CirclePaint>{
                    'circle-color': ["case", ["==", ["get", "include"], false], "#FF0000", "#66ED5B"],
                    "circle-radius": 6,
                }
            });
        }
    }
    unInstallLayer(){
        if (this.map.getLayer(AutoPolygonTool.EDITING_POLYGON_SOURCE_ID) != null){
            this.removeLayer(AutoPolygonTool.EDITING_POLYGON_SOURCE_ID);
        }
        if (this.map.getLayer(AutoPolygonTool.EDITING_POLYGON_FILL_ID) != null){
            this.removeLayer(AutoPolygonTool.EDITING_POLYGON_FILL_ID);
        }
        if (this.map.getLayer(AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID) != null){
            this.removeLayer(AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID);
        }
        if (this.map.getLayer(AutoPolygonTool.REMEMBERED_POLYGONS_FILL_ID) != null){
            this.removeLayer(AutoPolygonTool.REMEMBERED_POLYGONS_FILL_ID);
        }
        if (this.map.getLayer(AutoPolygonTool.POINTS_SOURCE_ID) != null){
            this.removeLayer(AutoPolygonTool.POINTS_SOURCE_ID);
        }
        if (this.map.getSource(AutoPolygonTool.EDITING_POLYGON_SOURCE_ID) != null){
            this.map.removeSource(AutoPolygonTool.EDITING_POLYGON_SOURCE_ID);
        }
        if (this.map.getSource(AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID) != null){
            this.map.removeSource(AutoPolygonTool.REMEMBERED_POLYGONS_SOURCE_ID);
        }
        if (this.map.getSource(AutoPolygonTool.POINTS_SOURCE_ID) != null){
            this.map.removeSource(AutoPolygonTool.POINTS_SOURCE_ID);
        }
    }

    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (this.store.map.measuringStatus == MeasuringStatus.agroAutoPolygon) {
            //e.cursor = ` url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4.375 11.125L6.625 6.625L11.125 4.375L1 1Z' fill='%23212121' stroke='white'/%3E%3Ccircle cx='13.375' cy='13.375' r='5.625' fill='%23212121' stroke='white'/%3E%3Crect x='12.25' y='10' width='2.25' height='6.75' fill='%23D9D9D9'/%3E%3Crect x='10' y='14.5' width='2.25' height='6.75' transform='rotate(-90 10 14.5)' fill='%23D9D9D9'/%3E%3C/svg%3E") 1 1, pointer`;
            e.cursor = FieldAutoStore.AI_CURSOR;
            if (this.store.agro2.fieldToolController.autoPolygon.status == LoadStatus.loading) {
                e.cursor = "wait";
            }
            //e.cursor = "";
            //
        }
        //super.onMouseMove(e);
    }

    async onMouseClick(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (e.dragging) return;//если было что потянули то не обрабатываем
        let rightClick = (e.originalEvent.button & 2) != 0;
        if (this.store.map.measuringStatus == MeasuringStatus.agroAutoPolygon) {
            //e.noPropagation();
            if (this.store.agro2.fieldToolController.autoPolygon.status == LoadStatus.loading) {
                return;
            }
            if (e.originalEvent.shiftKey && rightClick)
                e.noPropagation();
            if (!e.originalEvent.shiftKey && rightClick) { //попали правым кликом в какой-то полигон?
                this.removePolygon(e);
            } else
                await this.addPolygon(e);
            //if (e.originalEvent.shiftKey) {
              //  this.deleteContour(e);
            //} else {
            //}
        }
    }

    @action
    deleteContour(e: mapboxgl.MapMouseEvent & ToolEvent){
        let store = this.store;
        let g = store.agro2.fieldToolController.autoPolygon.editingPolygon;
        if (g == null) return;
        let sg = GeometryUtils.getSimpleGeometries(g);
        let addr = GeometryUtils.getAddrContourByPoint(e.lngLat, sg);
        if (addr != null){
            GeometryUtils.deleteContourByAddr(sg, addr);
            store.agro2.fieldToolController.autoPolygon.editingPolygon = GeometryUtils.createGeometryBySimpleGeometry(sg) as Polygon;
        }
    }

    removePolygon(e: mapboxgl.MapMouseEvent & ToolEvent) {
        let map = this.store.map.mapbox;
        let auto = this.store.agro2.fieldToolController.autoPolygon;
        let fs = map.queryRenderedFeatures(e.point, {layers: [AutoPolygonTool.EDITING_POLYGON_FILL_ID, AutoPolygonTool.REMEMBERED_POLYGONS_FILL_ID]})
        if (fs.length == 0) return;
        e.isNoPropagation = true;
        let f = fs[0];
        if (f.layer.id == AutoPolygonTool.EDITING_POLYGON_FILL_ID)
            auto.editingPolygon = null;
        else {
            let fc = auto.rememberedPolygons;
            let idx = fc.features.findIndex(x => x.properties.id == f.properties.id);
            if (idx < 0) return;
            Utils.arrayRemoveByIndex(fc.features, idx);
            auto.rememberedPolygons = fc;
        }
    }

    async addPolygon(e: mapboxgl.MapMouseEvent & ToolEvent){
        let store = this.store;
        let auto = store.agro2.fieldToolController.autoPolygon;
        let shift = e.originalEvent.shiftKey;
        let rightClick = (e.originalEvent.button & 2) != 0;
        let pts = auto.points;            
        //без шифта - новый
        if (! shift && ! rightClick) {
            pts = {
                "type": "FeatureCollection",
                "features": [{"type": "Feature", "geometry": {
                    "type": "Point", "coordinates": e.lngLat.toArray()},
                    "properties": {"include": !rightClick}}]
            };
        }
        //если массив не пустой добавляем при нажатом шифте
        else if (pts && shift) {
            pts.features.push({"type": "Feature", "geometry": {
                "type": "Point", "coordinates": e.lngLat.toArray()},
                "properties": {"include": !rightClick}});
        }
        else return;
        auto.points = pts;
        //inclPoints = [e.lngLat];
        // if (rightClick) model = AutoPolygonModel.unetPlusPlus;
        try {
            // let g = await store.agro2.fieldToolController.autoPolygon.geometryByClick(sceneId, e.lngLat, model, store.agro2.fieldToolController.autoPolygon.simplify);
            let g = await auto.detectGeometry(AutoPolygonModel.sam);
            ra(() => {
                auto.editingPolygon = g;
                if (g == null)
                    store.addInfo(store.trans["Not field!"]);
            });
        }catch (err) {
            store.addError(err);
        }
    }
}
