import {Utils} from "../../../helper/utils/Utils";
import {IGraphData, IGraphDataInfo, IGraphDayValue, IndexByPointGraphStore} from "../IndexByPointGraphStore";
import {action, observable} from "mobx";
import {TemperatureCategory} from "../IndexByPointerStore";
import {ra} from "../../../helper/utils/mobxUtils";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {fetchJson} from "../../../helper/utils/FetchUtils";
import {IndexByPointerSourceCustomStore} from "./IndexByPointerSourceCustomStore";
import {CustomStore} from "../../CustomStore";

class IndexByPointerSourceGraphData extends CustomStore{
    @observable
    srcData: IGraphDayValue[] = [];
    @observable
    status: LoadStatus = null;

    getUrl: ()=> string;
    getValue: (v: any) => IGraphDayValue;

  public reset() {
    this.status = null;
    this.srcData = [];
  }

  public async loadValues(): Promise<void> {
    this.status = LoadStatus.loading;
    try {
      let res: IGraphDayValue[] = [];
      let arr: any[] = await fetchJson(this.getUrl());
      if (this.status == null) return;
      arr.forEach(a => {
        let v = this.getValue(a);
        if (v.dayOfYear >= 59 && !v.absoluteDate) {
          v.dayOfYear++;
        }
        res.push(v);
      });
      arr.forEach(a => {
        let v = this.getValue(a);
        v.dayOfYear = v.dayOfYear + Utils.DaysByYear;
        if (v.dayOfYear >= 425 && !v.absoluteDate) {
          v.dayOfYear++;
        }
        res.push(v);
      });
      this.srcData = res;
    } catch (err) {
      this.root.addError(err);
    }
  }
}

export class IndexByPointerSourceSimpleTempCustomStore extends IndexByPointerSourceCustomStore{
    constructor(parent: IndexByPointGraphStore) {
        super(parent);
        this.dataValue.getUrl = this.getUrlValue;
        this.dataMin.getUrl = this.getUrlMin;
        this.dataMax.getUrl = this.getUrlMax;
        this.dataValue.getValue = this.getValueSimple;
        this.dataMin.getValue = this.getValueMin;
        this.dataMax.getValue = this.getValueMax;
    }

    dataValue: IndexByPointerSourceGraphData = new IndexByPointerSourceGraphData(this);
    dataMin: IndexByPointerSourceGraphData = new IndexByPointerSourceGraphData(this);
    dataMax: IndexByPointerSourceGraphData = new IndexByPointerSourceGraphData(this);
    @observable
    computed: IGraphData[] = [];

    getGraphData(): IGraphDataInfo{
        let values = this.getData;

        return {data: values, hasMinMax: this.getCategory() == TemperatureCategory.averageMinMaxDaily};
    }

    @action reset(){
        this.computed = [];
        this.dataValue.reset();
        this.dataMin.reset();
        this.dataMax.reset();
        this._status = null;
    }

    protected async loadValues(): Promise<IGraphDayValue[]>{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url = `/api/meteo/era5/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=mean_temp`;
        return await fetchJson(url)
            .then(json => {
                let arr: IGraphDayValue[] = [];
                let r: { date: string, mean_temp: number }[] = json;
                r.forEach(a => {
                        arr.push(this.getGraphDataValue(a.date, a.mean_temp));
                    }
                );
                this.data = arr;
                this._status = LoadStatus.ready;
                return arr;
            });
    }

    protected convertValuesToData(values: IGraphDayValue[]): IGraphData[]{
        return values.map(a => <IGraphData>{dayOfYear: a.dayOfYear, absoluteDate: a.absoluteDate, value: a.value, min: a.value, max: a.value, sceneID: null});
    }

    protected getUrlValue(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url = `/api/meteo/era5/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=mean_temp`;
        return url;
    }
    protected getUrlMin(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url1 = `/api/meteo/era5/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=min_temp`;
        return url1;
    }
    protected getUrlMax(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url2 = `/api/meteo/era5/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=max_temp`;
        return url2;
    }
    protected getValueSimple(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.mean_temp);
    }
    protected getValueMin(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.min_temp);
    }
    protected getValueMax(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.max_temp);
    }


    protected async loadMinMax(values: IGraphData[]): Promise<IGraphData[]>{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url1 = `/api/meteo/era5/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=min_temp`;
        let url2 = `/api/meteo/era5/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=max_temp`;
        let r = await Promise.all([fetchJson(url1), fetchJson(url2)]);
        let json1 = r[0] as { date: string, min_temp: number }[];
        let json2 = r[1] as { date: string, max_temp: number }[];
        let dic: Map<number, IGraphData> = new Map<number, IGraphData>();
        values.forEach(a => {
            dic.set(a.dayOfYear, a);
        });
        json1.forEach(a =>{
            let t = this.getGraphDataValue(a.date, a.min_temp);
            if (dic.has(t.dayOfYear)){
                let t2 = dic.get(t.dayOfYear);
                t2.min = a.min_temp;
            }
        });
        json2.forEach(a =>{
            let t = this.getGraphDataValue(a.date, a.max_temp);
            if (dic.has(t.dayOfYear)){
                let t2 = dic.get(t.dayOfYear);
                t2.max = a.max_temp;
            }
        });
        return Array.from(dic.values());
    }


    protected async load() {
        try {
            ra(() => {
                this.status = LoadStatus.loading;
            });

            let cat = this.getCategory();
            let promises: Promise<any>[] = [];

            if (cat == TemperatureCategory.minDaily || cat == TemperatureCategory.averageMinMaxDaily){
                promises.push(this.dataMin.loadValues());
            } else this.dataMin.reset();
            if (cat == TemperatureCategory.maxDaily || cat == TemperatureCategory.averageMinMaxDaily){
                promises.push(this.dataMax.loadValues());
            } else this.dataMax.reset();
            if (cat != TemperatureCategory.maxDaily && cat != TemperatureCategory.minDaily){
                promises.push(this.dataValue.loadValues());
            } else this.dataValue.reset();

            await Promise.all(promises);

            let dic: Map<number, IGraphData> = new Map<number, IGraphData>();

            let addValues = (data: IndexByPointerSourceGraphData, setFunc: (value: IGraphDayValue, collector: IGraphData)=>void)=>{
                data.srcData.forEach(a => {
                    if (dic.has(a.dayOfYear)){
                        let t = dic.get(a.dayOfYear);
                        setFunc(a, t);
                    }else{
                        let t: IGraphData = {dayOfYear: a.dayOfYear, absoluteDate: a.absoluteDate, value: null, sceneID: null};
                        setFunc(a, t);
                        dic.set(a.dayOfYear, t);
                    }
                });
            }
            if (cat == TemperatureCategory.minDaily){
                addValues(this.dataMin, (value, collector) =>{
                    collector.value = value.value;
                });
            }else if (cat == TemperatureCategory.maxDaily){
                addValues(this.dataMax, (value, collector) =>{
                    collector.value = value.value;
                });
            }else if (cat == TemperatureCategory.averageMinMaxDaily){
                addValues(this.dataMin, (value, collector) =>{
                    collector.min = value.value;
                });
                addValues(this.dataMax, (value, collector) =>{
                    collector.max = value.value;
                });
                addValues(this.dataValue, (value, collector) =>{
                    collector.value = value.value;
                });
            }else{
                addValues(this.dataValue, (value, collector) =>{
                    collector.value = value.value;
                });
            }

            let arrResult = Array.from(dic.values());
            arrResult = arrResult.sort((a,b)=>{return a.dayOfYear - b.dayOfYear;});

            ra(() => {
                this.computed = arrResult;
                this.status = LoadStatus.ready;
            });
        }catch (e) {
            ra(() => {
                this.computed = [];
                this.status = LoadStatus.ready;
                this.root.addError(e);
            });
        }
    }

    protected getCategory(): TemperatureCategory{
        return this.parent.parent.temperatureCategory;
    }
    private get getData(): IGraphData[]{
        if (this.status == null) setImmediate(()=> {
            ra(()=>{
                this.load();
            });
            return [];
        });

        let src = this.computed;
        let cat = this.parent.parent.temperatureCategory;

        if (cat == TemperatureCategory.accumulated0) return this.accumGraphDayData(src, 0);
        if (cat == TemperatureCategory.accumulated5) return this.accumGraphDayData(src, 5);
        if (cat == TemperatureCategory.accumulated10) return this.accumGraphDayData(src, 10);
        return src;
    }

}