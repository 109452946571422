import {fetchJson} from "./FetchUtils";
import {Utils} from "./Utils";
import {LngLat} from "maplibre-gl";

export function getSuggestions(input: string, doneCallback: any, failCallback: any, lngLat: LngLat = null) {
    let reqParams = {
        q: input,
        native_args: true,
        limit: 10,
        provider: 'photon'
    };
    if (lngLat) {
        (reqParams as any).lat = lngLat.lat;
        (reqParams as any).lon = lngLat.lng;
    }
    fetchJson("/api/geocode/search?"+Utils.queryEncodeParams(reqParams))
        .then(json =>{
            doneCallback(json);
        })
        .catch(err =>{
            failCallback(err);
        });
}

export function searchText(input: string, doneCallback: any, failCallback: any) {
    let reqParams = {
        q : input,
        native_args: true,
        limit: 1,
        polygon_geojson: 1
    };
    fetchJson("/api/geocode/search?"+Utils.queryEncodeParams(reqParams))
        .then(json =>{
            doneCallback(json.length > 0? json[0]: null);
        })
        .catch(err =>{
            failCallback(err);
        });
}

export function searchId(id: string, doneCallback: any, failCallback: any) {
    let reqParams = {
        osm_ids : id,
        limit: 1,
        format: 'json',
        polygon_geojson: 1
    };
    fetchJson("/api/geocode/lookup?"+Utils.queryEncodeParams(reqParams))
        .then(json =>{
            doneCallback(json.length > 0? json[0]: null);
        })
        .catch(err =>{
            failCallback(err);
        });
}
