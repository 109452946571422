import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import * as React from "react";
import autoBindReact from "auto-bind/react";
import {LogEventInterfaces} from "../../../../store/user/LogView/LogEventInterfaces";
export interface ILogViewRowComp extends IStoreProps{
    logEvent: LogEventInterfaces,
}
@observer
export class LogViewRowComp extends React.Component<ILogViewRowComp, undefined> {
    constructor(props: ILogViewRowComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let le = this.props.logEvent;

        return <div className="LogView-value-row">
                    <div className="LogView-value-col-first"></div>
                    <div className="LogView-value-col-date">{le.date.toLocaleString("default", {"year":"numeric", "month":"2-digit",
                        "day":"2-digit", "hour":"2-digit", "minute":"2-digit"})}</div>
                    <div className="LogView-value-col-user">{le.fullname}</div>
                    <div className="LogView-value-col-email">{le.email}</div>
                    <div className="LogView-value-col-action">{store.user.logView.getTitleAction(le.operation)}</div>
                    <div className="LogView-value-col-end"></div>
                </div>;
            }
        }
