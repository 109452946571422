import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";

@observer
export class OrderStatusCanceled extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className="journal-status canceled">
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.375 12.625L12.625 14.375L10 11.75L7.375 14.375L5.625 12.625L8.25 10L5.625 7.375L7.375 5.625L10 8.25L12.625 5.625L14.375 7.375L11.75 10L14.375 12.625Z"/>
            </svg>
            <div className="journal-status-name">{store.trans.Canceled}</div>
        </div>
    }
}
