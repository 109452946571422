import {CustomStore} from "../CustomStore";
import {update} from "../PermalinkDecor";
import {IntervalStore} from "../IntervalStore";
import {observable, reaction} from "mobx";
import {PhotoTagGroupFilterStore} from "./PhotoTagGroupFilterStore";
export class PhotoFilterStore extends CustomStore{
    constructor(parent: CustomStore) {
        super(parent);

        if (parent != null) {
            setImmediate(()=>{
                reaction(() => {
                    return {
                        tags: parent.root.photo.tags
                    }
                }, (arg) => {
                    this.syncTags();
                });
            });
        }
    }
    class(): string {
        return "PhotoFilterStore";
    }

    syncTags(){
        this.tagGroupFilter.loadPhotoTagFromJson(this.root.photo.tags);
    }

    @update
    filterPhotoDate: IntervalStore = new IntervalStore(this);
    @observable
    tagGroupFilter: PhotoTagGroupFilterStore = new PhotoTagGroupFilterStore(this);
    @observable
    useExtandMap: boolean = true;


}