import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action, observable} from "mobx";
import {RadioButton} from "../../../../Common/RadioButton";
import {A2ExportFormat} from "../../../../../store/agro/A2AgroStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {RootPortal} from "../../../../Common/RootPortal";
import {ReactNode} from "react";
import { isFunction } from 'lodash-es';


export interface IA2ExportFormatComp extends IStoreProps{
    formats: A2ExportFormat[],
    extChilds?: ReactNode,
    defaultFormat?: A2ExportFormat,
    footerClass?: string,
    buttonClass?: string,
    title?: string;
    cancelText?: string,
    submitText?: string,
    onCancel: ()=>any,
    onSubmit: (format: A2ExportFormat)=>any,
    onSelectFormat?: (format: A2ExportFormat)=>any
}

@observer
export class A2ExportFormatComp extends React.Component<IA2ExportFormatComp, undefined> {
    constructor(props: IA2ExportFormatComp) {
        super(props);
        if (props.defaultFormat)
            this.format = props.defaultFormat;
        autoBindReact(this);
    }

    @observable
    format: A2ExportFormat = A2ExportFormat.geojson;

    @action
    onClickFormat(event: any, data: any){
        this.format = data;
        if (isFunction(this.props.onSelectFormat))
            this.props.onSelectFormat(this.format);
    }
    @action
    onClickCancel(){
        this.props.onCancel();
    }
    @action
    onClickSubmit(){
        this.props.onSubmit(this.format);
    }

    render() {
        let store = this.props.store;
        let format = this.format;
        let items: any[] =[];

        this.props.formats.forEach(a => { items.push(<div className="Forms-radioLineMargin" key={a}>
            <RadioButton checked={format == a}
                         data={a} onClick={this.onClickFormat}><span className="text-next">{TranslateUtils.getGeoFormatTitle(a)}</span></RadioButton>
        </div>);
        });

        return <RootPortal><div className="Form-backgray">
            <div className="Forms-self-center Forms-base" style={{maxWidth: "320px"}}>
                <div className="Forms-headerText Forms-marginTop Forms-marginLR Forms-headerBottomMargin">{this.props.title || store.trans.ExportTo}</div>
                <div className="Forms-marginLR">
                    {this.props.children}
                    {items}
                </div>
                {this.props.extChilds}
                <div className="Forms-baseline Forms-topIdent2" />
                <div className={`${this.props.footerClass} Forms-footerButtons`}>
                    <button onClick={this.onClickCancel} style={{minWidth: "100px"}}
                            className={`${this.props.buttonClass} large-button left-sidebar-cancel Forms-footerButtonRightMargin button-jump`}>{this.props.cancelText || store.trans.Cancel}</button>
                    <button onClick={this.onClickSubmit} style={{minWidth: "100px"}}
                            className={`${this.props.buttonClass} large-button left-sidebar-active Forms-footerButtonRightMargin button-jump`}>{this.props.submitText || store.trans.ExportTo}</button>
                </div>
            </div>
        </div>
        </RootPortal>;
    }
}

