import {CustomStoreTool} from "../general/CustomStoreTool";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {WindowTriState} from "../../../helper/structs/WindowTriState";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import mapboxgl from "maplibre-gl";

export class CreatePointIndexGraphTool extends CustomStoreTool{
    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (this.store.map.measuringStatus == MeasuringStatus.CreatePointIndex) {
            //if (e.cursor == null) e.cursor = ` url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='3.5' fill='white' stroke='%2334AAFF'/%3E%3Cpath d='M12.6653 12.4417L12.5162 12.5162L12.4417 12.6653L9.42595 18.6967L4.79057 4.79057L18.6967 9.42595L12.6653 12.4417Z' fill='white' stroke='black'/%3E%3C/svg%3E") 1 12, pointer`;
            if (e.cursor == null || e.cursor == "default") e.cursor = ` url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='3.5' fill='white' stroke='%2334AAFF'/%3E%3Cpath d='M12.6653 12.4417L12.5162 12.5162L12.4417 12.6653L9.42595 18.6967L4.79057 4.79057L18.6967 9.42595L12.6653 12.4417Z' fill='white' stroke='black'/%3E%3C/svg%3E") 4 3, pointer`;

        }
    }

    onMouseClick(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (this.store.map.measuringStatus == MeasuringStatus.CreatePointIndex) {
            let ms = this.store.map;
            ms.menuPointer = null;

            let llClick = e.lngLat;

            try {
                if (ms.measuringStatus == MeasuringStatus.CreatePointIndex) {
                    this.store.indexByPointer.showPanel = WindowTriState.show;
                    this.store.indexByPointer.addPoint(llClick.lat, llClick.lng);
                }

            } catch (e) {
                this.store.addError(e);
            }
        }
    }
}