import {IndexByPointerSourceCustomSimpleStore} from "./IndexByPointerSourceCustomSimpleStore";
import {Utils} from "../../../helper/utils/Utils";
import {IGraphDayValue} from "../IndexByPointGraphStore";
import {IndexByPointerSourceModisCustom} from "./IndexByPointerSourceModisCustom";

export class IndexByPointerSourceSimpleModisTerraNir extends IndexByPointerSourceModisCustom{


    protected getRequest(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return (this.root.indexByPointer.getProdStatUrl()+`?scene_source=MOD09GQ&filter={"acqdate":{"$>=":"${Utils.getDateStr(dateBegin)}","$<=":"${Utils.getDateStr(dateEnd)}"}}&lon=${gp.point.lng}&lat=${gp.point.lat}&product=B1&apikey=5b2176fa8786f7452f84b390c909c343`);
    }
    protected getValue(json: any): IGraphDayValue{
        let j: {date: string, stat:{B1:{avg: number}} } = json;
        return this.getGraphDataValue(j.date, j.stat.B1.avg);
    }
    protected getGraphDataInternal(): IGraphDayValue[]{
        return this.getData;
    }


    private get getData(): IGraphDayValue[]{
        let src: IGraphDayValue[];
        if (this.status == null) setImmediate(()=> { this.load() });
        src = this.data;
        return src;
    }

}