import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoUploadPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoModalType} from "../../agroAhoStore";



@observer
export class AgroAhoUploadPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    uploadElement: HTMLInputElement = null;

    @action
    uploadChanged(e:any){
        let ustore = this.props.store.agroAhoStore.uploadCellsStore;
        let input = this.uploadElement;
        if (input.files.length == 0) return;
        let file = e.target.files[0];
        input.value = '';
        ustore.uploadFile(file);
    }

    onChangeRef(node: any){
        this.uploadElement = node;
    }

    @action
    onClickSelect(){
        this.uploadElement.click();
    }

    @action
    onClickCancel(){
        let store_ = this.props.store.agroAhoStore;
        store_.toggleModal(false);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;

        return <div className="AgroAhoUploadPanelComp-main">
            <div className="AgroAhoUploadPanelComp-title">{store_.trans["Loading new cell borders"]}</div>
            <div className="AgroAhoUploadPanelComp-msg">
                <span>{store_.trans["You can upload cells in GeoJSON, KML, or SHP format in a zip archive. New cells must completely cover the area of ​​the fields within which these cells are located. But it is not necessary for new cells to cover all ASA map fields (cells of fields that are not in the uploaded file will remain unchanged)."]}</span>
                <br/>
                <span className="AgroAhoUploadPanelComp-red">{store_.trans["Attention"]}! </span>
                <span>{store_.trans["During the process of loading new cells, existing cells, tracks and ALL attached information will be deleted: indicators, recommended standards (if any)."]}</span>
            </div>

            <div className="AgroAhoUploadPanelComp-line"> </div>

            <div className="AgroAhoUploadPanelComp-buttons">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoUploadPanelComp-cancelButton"
                    onClick={this.onClickCancel}>{store_.trans.Cancel}</button>
                <button className="large-button left-sidebar-active button-jump AgroAhoUploadPanelComp-selectButton"
                    onClick={this.onClickSelect}>{store_.trans["Select a file"]}</button>
            </div>
            <input type="file" style={{display: "none"}} onChange={this.uploadChanged}
                   ref={this.onChangeRef} />
        </div>
    }
}
