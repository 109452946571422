import {FavoriteExportStore} from "./FavoriteExportStore";
import {IOrderedProducts} from "../config/ConfigStore";
import {Satellite} from "../../helper/utils/satellliteDic";
import {fetchJson} from "../../helper/utils/FetchUtils";
import {Utils} from "../../helper/utils/Utils";

export class LandsatExportStore extends FavoriteExportStore{
    get productConfig(): IOrderedProducts{
        return this.root.config.ordered_products[Satellite["Landsat-8"]];
    }

    canExport(): boolean{
        return (
            Utils.isStringNotEmpty(this.parent.projectName) &&
            this.parent.period.isValid() &&
            !this.root.map.searchObject.isEmpty &&
            this.getSelectedBands().concat(this.getSelectedIndexes()).length > 0
        );
    }
    afterMakeParams(params: any){}
    async sendOrder(){
        let url = "/api/proc_proxy/orders/daily_mosaic";
        let json = {
            order: {
                name: this.parent.projectName,
                //priority: "background",
                email: this.root.exportStore.email
            },
            //scene_ids: this.getScenes(),
            sat: "LS8+",
            date_start: Utils.getDateStr(this.parent.period.begin),
            date_end: Utils.getDateStr(this.parent.period.end),
            region: JSON.stringify(this.root.map.searchObject.singleGeometry),
            products: this.getSelectedBands().concat(this.getSelectedIndexes()),
            albedo: this.albedo,
            combine_products: this.combine_products,
            daily_mosaic: this.mergeScenes,
            apply_cutline: this.apply_cutline,
            max_scenes_number: 300
        }
        this.afterMakeParams(json);
        fetchJson(url,{
            method: 'POST', body: JSON.stringify(json)
        })
            .then( values => {
                this.root.addInfo("Sent");
            })
            .catch(reason => {
                this.root.addError(Utils.getErrorString(reason)+" "+url);
            })
    }
}