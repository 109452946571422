import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {action, autorun, observable, runInAction} from "mobx";
import {fetchJson, fetchJsonGet, fetchJsonPost} from "../../helper/utils/FetchUtils";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {Utils} from "../../helper/utils/Utils";
import {SuperStore} from "../SuperStore";
import {GlobalRender} from "../../global";
import {doLoadPermalink} from "../../index";
import {SignUpFormStore} from "./SignUpFormStore";
import {ConfirmationFormStore} from "../ConfirmationFormStore";
import {ForgotStore} from "./ForgotStore";
import {UrlCoder} from "../../helper/utils/UrlCoder";
import {A2ReferenceStore} from "../agro/A2ReferenceStore";
import {PersonalAreaStore} from "./PersonalAreaStore";
import {Lang} from "../../../pluginApi/store/Lang";
import mapboxgl from "maplibre-gl";
import urlJoin from "url-join";
import {LogViewStore} from "./LogView/LogViewStore";
import {ra} from "../../helper/utils/mobxUtils";
import { IOverlayConfig, IOverlayGroup } from "../config/ConfigStore";
export interface ILoginResponse{
    result: true | false,
    auth_token: string
}
export interface IPassThroughAuth{
    userSource: string;
    code: string;
}
export interface IAuthUserResponse{
    auth: 1 | 0,
    login: string,
    email: string,
    roles: string[],
    user_id: number,
    surname: string;
    given_name: string;
    token: {token: string; expir_date: string};
}
export enum ProjType{
    agro_base = 'agro_base',//"Агро"
    agro_insur = 'agro_insur',//АгроСтрахование
    parcel_assess = 'parcel_assess',//Оценка участков, залоги
    pnt_yld_assess = 'pnt_yld_assess',
    agro_region= 'agro_region',//МСХ регион
    carb='carb',//Карбоновые фермы
    slick="slick",
    yield_point = "yield_point",
}
export interface IProjectsProjResponse{
    perm?: string;
    proj_id: number;
    proj_name: string;
    proj_title: string;
    proj_entity: string;
    proj_schema: string;
    upd_time: string;
    proj_type: ProjType;
    prodstat_field_ds: string;
    stat: IProjectStat,
    proj_settings:IProjectSettings,
    proj_owner: {
        email: string,
        given_name: string,
        login: string,
        surname: string,
        user_id: string | number
    },
}
export interface IProjectStat{
    farm_cnt: number,
    field_cnt: number,
    field_area_sys: number,
    photo_cnt: number,
}
export interface IProjectSettings{
    default_active_area: string;
    groups: string[];
    obj_styles: any;

    //map_styles: IFieldLayerStyles;
}
export interface IProjectsResponse{
    can_create_project: number;
    projects: IProjectsProjResponse[];
}
export enum DatasetType{
    farm = 'farm',
    v_farm = 'v_farm',
    field = 'field',
    v_field = 'v_field',
    crop_rot = 'crop_rot',
    photo = 'photo',
}
export interface IFieldLayerStyles{
    fields: mapboxgl.Layer[],
    selected: mapboxgl.Layer[],
    hover: mapboxgl.Layer[]
};

export interface IProjectInfo extends IProjectsProjResponse{
    proj_schema: string;
    type_info:IType_info;
    proj_layers:  (IOverlayConfig | IOverlayGroup)[];
    

}
export interface IType_info{
    type_descr: string;
    type_schema:{
        obj: {
            file_import: IFileImport;
            table: IProjectTable;
            ui: {
                settings:{ show_history: number};
            };
            view: IProjectTable;
        }
        photo:{
            table: IProjectTable;
            view: IProjectTable;
        }
        refs: IProjectRef[]
    }
}
export interface IProjectRef{
    name: string;
    schema: string;
    table: string;
    type: IProjectRefType;
    columns: IProjectTableColumn[];
}
export interface IFileImport{
    const_value_columns: {name: string}[];
    mapping_columns: string[];
}
export enum IProjectRefType{
    global= 'global',
    local='local'
}
export enum ProjectGeometryType{
    point = 'point',
    line = 'line',
    polygon = 'polygon',
}
export interface IProjectTable{
    columns: IProjectTableColumn[];
    dataset: IProjectTableDataset;
    name: string;
    geometry: {
        types: {name: ProjectGeometryType, default?: number}[],
        name: string,
    },
    export?: {file_name: string}
}
export interface IProjectTableColumn{
    is_req?: number;
    is_pk?: number;
    is_fk?: number;
    name: string;
    ord: number;
    show?: number;
    title: IProjectTableLang;
    ref?: {name: string, col: string};
    ref_group?: string;
    is_main_name?: number;
    grouping?: {ord?: number};
    type: IProjectTableColumnType;
    readonly?: number;
}
export enum IProjectTableColumnType{
    str= 'str',
    int='int',
    float='float',
    date='date',
}
export interface IProjectTableDataset{
    ds_code: string;
    geom_column: string;
    id_column: string;
    mvt_attribs: string[];
    name_column: string;
}

export type IProjectTableLang = {
    [key in Lang]?: string;
}

export class UserStore extends ObservableCustomStore {
    class(): string {
        this.root.trans.Create
        return "UserStore";
    }

    signUpForm: SignUpFormStore = new SignUpFormStore(this);
    confirmForm: ConfirmationFormStore = new ConfirmationFormStore(this);
    forgotForm: ForgotStore = new ForgotStore(this);
    personalArea: PersonalAreaStore = new PersonalAreaStore(this);
    logView: LogViewStore = new LogViewStore(this);

    //@observable
    //authFormShow: boolean = false;
    @observable
    can_create_project: boolean = false;

    @observable
    isAuth: boolean = false;
    @observable
    receiveAuthStatus: LoadStatus = null;
    @observable
    curLogin: string = "";
    @observable
    curEmail: string = "";
    curUserId: number = null;
    @observable
    surname: string = "";
    @observable
    given_name: string = "";


    @observable
    roles: string[] = [];

    @observable
    formLogin: string = "";
    @observable
    formPassword: string = "";
    @observable
    formAuthStatus: LoadStatus = null;
    @observable
    showMenu: boolean = false;
    @observable
    showAdminPanel: boolean = false;


    @action
    requestLogout() {
        this.receiveAuthStatus = LoadStatus.loading;
        this.root.user.formLogin = "";
        this.root.user.formPassword = "";
        fetchJson("/api/auth/logout")
            .then(json => {
                runInAction(() => {
                    this.isAuth = false;
                    this.formPassword = "";
//                    this.authFormShow = true;
                    this.receiveAuthStatus = LoadStatus.ready;
                    this.resetStore();
                });

            }).then(async ()=>{
                try {
                    let extDomains = this.extendDomains();
                    for (let s of extDomains) {
                        let r = await fetchJson(urlJoin(s, "/api/auth/logout"), {
                            method: "POST",
                            credentials: 'include',
                        });
                    }
                }catch (e) {
                    this.root.addError(e);
                }
        })
            .catch(err => {
                this.root.addError(err);
                this.receiveAuthStatus = LoadStatus.ready;
            });
    }

    resetStore() {
        let store = this.root;
        store.active = false;
        let newStore: SuperStore = new SuperStore(null);
        newStore.history = store.history;
        newStore.config = store.config;
        newStore.init();


        newStore.user.setNoAuth();
        doLoadPermalink({}, newStore, "");
        store.dispose();
        GlobalRender.render(newStore);
    }

    isAuthOkResponse(j: IAuthUserResponse): boolean {
        return (j.auth == 1);
    }

    @action
    async setAuth(j: IAuthUserResponse) {
        this.isAuth = true;
        this.curLogin = j.login;
        this.curEmail = j.email;
        this.curUserId = j.user_id;
        this.receiveAuthStatus = LoadStatus.ready;
        this.surname = j.surname;
        this.given_name = j.given_name;

        if (j.roles) {
            this.roles = j.roles;
        } else this.roles = [];

        this.root.gotoUrl("/");

        let extDomains = this.extendDomains();
        for(let s of extDomains){
            let r = await fetchJson(urlJoin(s, "/api/auth/login"), {
                method: "POST",
                credentials: 'include',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: Utils.queryEncodeParams({token: j.token.token})
            });
            if (!r) this.root.addError( "Authorization error on the tiles domain");
        }

        return true;
    }

    @action
    setNoAuth() {
        this.curLogin = "";
        this.curEmail = "";
        this.curUserId = null;
        this.isAuth = false;
        this.receiveAuthStatus = LoadStatus.ready;
        //this.authFormShow = true;
        if (UrlCoder.getUrlPathOnly() == "/") {
            this.root.gotoUrl("/signin");
        }

    }


    @action
    initReferences() {
        let pi = this.root.agro2.projectInfo.projectInfoJson;
        this.root.agro2.projectInfo.references.clear();
        pi.type_info.type_schema.refs.forEach(ref => {
            let t = new A2ReferenceStore(this.root.agro2.projectInfo);
            t.reference_type = ref.type;
            t.reference_name = ref.name;
            if (!ref.columns) return;
            let col = ref.columns.find(col => col.is_pk == 1);
            if (col == null) throw `Не найден первичный ключ справочника ${ref.name}`;
            t.reference_primary_column = col.name;
            let col_name = ref.columns.find(col => col.is_main_name);
            if (col_name != null)
                t.reference_value_column = col_name.name;
            this.root.agro2.projectInfo.references.set(t.reference_name, t);
        });
    }


    checkPasswordCorrect(password: string): boolean {
        if (password.length < 8) return false;
        return true;
    }

    async requestForgot(email: string) {
        let r = await fetchJsonPost(`/api/auth/pwd_forget`, {email: email});
        if (r === false) {
            throw "Email is not found";
        }
    }

    async newPassSend(password: string, code: string) {
        let r = await fetchJsonPost(`/api/auth/pwd_new`, {pwd: password, code: code});
        if (r === false) {
            throw "Email is not found";
        }
    }

    async changePassSend(oldPassword: string, newPassword: string){
        await fetchJsonPost(`/api/auth/pwd_change`,{old_pwd: oldPassword, new_pwd: newPassword});
    }
    async changeNameSend(surname: string, given_name: string){
        await fetchJsonPost(`/api/auth/user/modify`,{values:{"given_name":given_name,"surname":surname}});
    }


    extendDomains(): string[]{
        let r: string[] = [];
        if (this.root.config.tile_domains){
            for(let s of this.root.config.tile_domains){
                r.push(s);
            }
        }
        if (this.root.config.prodstat_domains){
            for(let s of this.root.config.prodstat_domains){
                r.push(s);
            }
        }
        return r;;
    }
    async requestLogin(auth: IPassThroughAuth= null){
        this.formAuthStatus = LoadStatus.loading;
        let login = this.formLogin;
        let password = this.formPassword;
        try {
            let json = auth? await this.requestLoginViaUserSource(auth):
                await fetchJson("/api/auth/login", {
                    method: "POST",
                    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                    body: Utils.queryEncodeParams({login: login, pwd: password})
                });
            if (json){

                json = await fetchJsonGet('/api/auth/user');
                await this.root.agro2.projectList.projectListRequest();

                runInAction(async ()=>{
                    try {
                        if (!this.isAuthOkResponse(json)) {
                            this.setNoAuth();
                        } else {
                            await this.setAuth(json as IAuthUserResponse);
                        }
                        //this.authFormShow = false;
                        ra(()=>{
                            this.formAuthStatus = LoadStatus.ready;
                        });
                    }catch (e) {
                        this.root.addError(e);
                    }
                });
                this.root.agro2.projectList.initProjectIfSingle();
                this.root.gotoUrl('/');
            }else{
                runInAction(()=> {
                    this.isAuth = false;
                    this.formAuthStatus = LoadStatus.ready;
                    this.root.addError("Login or password is not correct");
                });
                this.root.gotoUrl("/signin");
            }
        }catch(err){
            this.root.addError(err);
            this.formAuthStatus = LoadStatus.ready;
        }
    }
    async requestLoginViaUserSource(auth: IPassThroughAuth) {
        return await fetchJson("/api/auth/login", {
            method: "POST",
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: Utils.queryEncodeParams({source: auth.userSource, code: auth.code})
        });
    }

    async requestRegister() {
        try {
            let json = await fetchJson("/api/auth/register", {
                method: "POST",
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: Utils.queryEncodeParams({email: this.signUpForm.email, given_name: this.signUpForm.firstName,
                    surname: this.signUpForm.secondName, pwd: this.signUpForm.password})
            });
        } catch(err){
            this.root.addError(err);
            this.formAuthStatus = LoadStatus.ready;
        }
    }
///auth/user
}
