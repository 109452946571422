import {FavoriteExportStore} from "./FavoriteExportStore";
import {IOrderedProducts} from "../config/ConfigStore";
import {Satellite} from "../../helper/utils/satellliteDic";
import {LandsatExportStore} from "./LandsatExportStore";

export class Sentinel2ExportStore extends LandsatExportStore{
    get productConfig(): IOrderedProducts{
        return this.root.config.ordered_products[Satellite["Sentinel-2"]];
    }
    afterMakeParams(params: any){
        params.sat = "S2";
    }
}