import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoDownloadSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoDownloadSvg extends React.PureComponent<IAgroAhoDownloadSvgProps, undefined> {
    constructor(props: IAgroAhoDownloadSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
              width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 12.25H1.75C1.51794 12.25 1.29538 12.3422 1.13128 12.5063C0.967187 12.6704 0.875 12.8929 0.875 13.125C0.875 13.3571 0.967187 13.5796 1.13128 13.7437C1.29538 13.9078 1.51794 14 1.75 14H12.25C12.4821 14 12.7046 13.9078 12.8687 13.7437C13.0328 13.5796 13.125 13.3571 13.125 13.125C13.125 12.8929 13.0328 12.6704 12.8687 12.5063C12.7046 12.3422 12.4821 12.25 12.25 12.25Z"/>
            <path d="M6.38137 10.2436C6.54545 10.4077 6.76797 10.4998 6.99999 10.4998C7.23201 10.4998 7.45453 10.4077 7.61862 10.2436L11.9936 5.86862C12.0772 5.78791 12.1438 5.69136 12.1897 5.5846C12.2356 5.47785 12.2597 5.36303 12.2607 5.24685C12.2617 5.13067 12.2396 5.01545 12.1956 4.90792C12.1516 4.80038 12.0866 4.70269 12.0045 4.62053C11.9223 4.53837 11.8246 4.4734 11.7171 4.42941C11.6095 4.38541 11.4943 4.36327 11.3781 4.36428C11.262 4.36529 11.1471 4.38943 11.0404 4.43529C10.9336 4.48114 10.8371 4.5478 10.7564 4.63138L7.87499 7.51275V0.875C7.87499 0.642936 7.7828 0.420376 7.61871 0.256282C7.45461 0.0921872 7.23205 0 6.99999 0C6.76793 0 6.54537 0.0921872 6.38127 0.256282C6.21718 0.420376 6.12499 0.642936 6.12499 0.875V7.51275L3.24362 4.63138C3.07859 4.47199 2.85756 4.38379 2.62814 4.38579C2.39872 4.38778 2.17926 4.4798 2.01702 4.64203C1.85479 4.80427 1.76277 5.02373 1.76078 5.25315C1.75878 5.48257 1.84698 5.7036 2.00636 5.86862L6.38137 10.2436Z"/>
        </svg>;
    }
}
