import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {isFunction, toNumber} from "lodash-es";

export interface ISoilGridsSourceDepthCompProps {
    items: string[];
    currentIndex: number;
    onClick?: (index: number)=> any;
    className?: string;
}

export class SoilGridsSourceDepthComp extends React.PureComponent<ISoilGridsSourceDepthCompProps, undefined> {
    constructor(props: ISoilGridsSourceDepthCompProps) {
        super(props);
        autoBindReact(this);
    }

    onClickItem(event: any){
        let y = toNumber(event.currentTarget.getAttribute("data-attr"));
        if (isFunction(this.props.onClick)) this.props.onClick(y);
    }

    render() {
        let arr: any[] = [];
        this.props.items.forEach((a, index) =>{
            arr.push(<div className={classNames("SoilGridsSourceDepth-item",{"SoilGridsSourceDepth-active": index == this.props.currentIndex})} key={index}>
                <div className="SoilGridsSourceDepth-itemCircle" onClick={this.onClickItem} data-attr={index.toString()}><div className="SoilGridsSourceDepth-itemCircleInner"/></div>

                <div className="SoilGridsSourceDepth-itemLabel"><div className="SoilGridsSourceDepth-itemLine"/><div className="SoilGridsSourceDepth-itemText" onClick={this.onClickItem} data-attr={index.toString()}>{a}</div></div>
            </div>);
        });
        return <div className={classNames("SoilGridsSourceDepth-main", this.props.className)}>
            <div className="SoilGridsSourceDepth-line">
                {arr}
            </div>
        </div>
    }
}
