// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndexByPointPopupGroupsItemComp-GroupItem {
  padding: 7px 25px;
  font-size: 13px;
  cursor: pointer;
}

.IndexByPointPopupGroupsItemComp-GroupItem:hover {
  background: #383F48;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/IndexByPoint/Popups/IndexByPointPopupGroupsItemComp.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,eAAA;EACA,eAAA;AAAF;;AAEA;EACE,mBAAA;AACF","sourcesContent":["\r\n.IndexByPointPopupGroupsItemComp-GroupItem{\r\n  padding: 7px 25px;\r\n  font-size: 13px;\r\n  cursor: pointer;\r\n}\r\n.IndexByPointPopupGroupsItemComp-GroupItem:hover{\r\n  background: #383F48;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
