import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {action} from "mobx";
import './AgroProjectFormComp.scss';

@observer
export class AgroProjectFormComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClose(){
        this.props.store.agro.projectForm.showForm = false;
    }
    @action
    onChangeCompany(e: any){
        this.props.store.agro.projectForm.company = e.currentTarget.value;
    }
    @action
    onChangeName(e: any){
        this.props.store.agro.projectForm.title = e.currentTarget.value;
    }
    @action
    async onCreate(){
        try {
            await this.props.store.agro.projectForm.sendCreate();
            await this.props.store.agro2.projectList.projectListRequest();
            this.onClose();
        }catch (err) {
            this.props.store.addError(err);
        }
    }
    @action
    async onUpdate(){
        try {
            await this.props.store.agro.projectForm.sendUpdate();
            await this.props.store.agro2.projectList.projectListRequest();
            this.onClose();
        }catch (err) {
            this.props.store.addError(err);
        }
    }

    render() {
        let store = this.props.store;
        let form = store.agro.projectForm;
        let enableOK = (form.company??"".length > 0) && (form.title??"".length > 0);

        return <div className="Agro-form AgroMultiCropFormComp-form">
            {form.editMode && <div className="AgroMultiCropFormComp-header Agro-createFerm-lr_margin">{store.trans["Edit project"]}</div>}
            {!form.editMode && <div className="AgroMultiCropFormComp-header Agro-createFerm-lr_margin">{store.trans["Create project"]}</div>}
            <div className="Agro-createFerm-line Agro-createFerm-nameLine" />
            <div>
                <div className="Agro-createFerm-nameLine Agro-createFerm-topmargin Agro-createFerm-lr_margin">
                    {store.trans["Name of the company"]}
                </div>
                <div className="Agro-createFerm-lr_margin relative">
                    <input type="text" className={classNames("text-box-editor width100")}
                           onChange={this.onChangeCompany} value={form.company??""} />
                </div>
            </div>
            <div>
                <div className="Agro-createFerm-nameLine Agro-createFerm-topmargin Agro-createFerm-lr_margin">
                    {store.trans["Project name"]}
                </div>
                <div className="Agro-createFerm-lr_margin relative">
                    <input type="text" className={classNames("text-box-editor width100")}
                           onChange={this.onChangeName} value={form.title??""} />
                </div>
            </div>
            <div className="Agro-createFerm-line Agro-createFerm-topmargin"></div>
            <div className="Agro-createFerm-topmargin Agro-createFerm-lr_margin flex-columns flex-distribute Agro-createFerm-footerMargin">
                <button className="large-button left-sidebar-cancel Agro-createFerm-button-width button-jump" onClick={this.onClose}>{store.trans.Cancel}</button>
                {!form.editMode && <button className="large-button left-sidebar-active Agro-createFerm-button-width button-jump" disabled={!enableOK} onClick={this.onCreate}>{store.trans.Create}</button>}
                {form.editMode && <button className="large-button left-sidebar-active Agro-createFerm-button-width button-jump" disabled={!enableOK} onClick={this.onUpdate}>{store.trans.Change}</button>}
            </div>

        </div>;
    }
}
