import {autorun, IReactionDisposer} from "mobx";
import {CustomStoreTool} from "../../../app/store/tools/general/CustomStoreTool";
import {IAgroAhoSuperStore, LeftPanelModeAgroAho} from "../agroAhoPlugin";
import {AgroAhoStore} from "../agroAhoStore";
import mapboxgl, {MapMouseEvent} from "maplibre-gl";
import {ToolEvent} from "../../../pluginApi/tools/ToolEvent";
import {AhoUtils} from "../agroAhoUtils";
import {IAhoLayers} from "./agroAhoMapStore";
import {AgroExportConst} from "../../agroExport/AgroExportConst";
import {AhoEditorMode} from "../agroAhoEditorStore";

export class AgroAhoTool extends CustomStoreTool {
    static FILL_LAYER_ID = "class_AgroAho_layer_fill";
    static LINE_LAYER_ID = "class_AgroAho_layer_line";
    static SOURCE_ID = "class_AgroAho_src";

    firstLayer: string;
    lastMouseUpPos: string;
    isInit: boolean = false;
    ahoLayers: IAhoLayers[] = [
        // labels > lines > tracks > cells > fields
        // labels
        IAhoLayers.RnLbl, // rec norm number
        IAhoLayers.CellLbl, // cell number
        IAhoLayers.FieldLbl, // fields number
        IAhoLayers.IndLbl, // indicator number
        IAhoLayers.TrackRouteLbl, // track route number
        // lines
        IAhoLayers.IndLine, // indicator line
        IAhoLayers.RnLine, // rn line
        // indicator
        IAhoLayers.Ind, // // indicator
        // tracks
        IAhoLayers.SamplePoint, // track sample point
        IAhoLayers.TrackFieldPoint, // track field point
        IAhoLayers.TrackCell, // track cell
        IAhoLayers.TrackField, // track field _fld
        // points
        IAhoLayers.Point, // points
        // cells
        IAhoLayers.Cell, // cells line
        IAhoLayers.CellFill, // cells fill _fill
        // fields
        IAhoLayers.Field, // fields
        // rec norm
        IAhoLayers.Rn, // rec norm
        // track route
        IAhoLayers.TrackRoute, // track route
        // sel cells
        IAhoLayers.SelCells // sel cells
    ]

    onInstall() {
        super.onInstall();
    }

    onUninstall() {
        super.onUninstall();
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.doInit();
                this.setupLayers();
            }), ...super.onSubscription()
        ];
    }

    doInit(){
        // this.agroAhoStore
        if (this.isInit) return;
        this.addBaseLayers();
        this.isInit = true;
        this.agroAhoStore.mapStore.updLayers();
    }

    addBaseLayers(){
        this.ahoLayers.forEach((l: IAhoLayers, i:number)=>{
            let id = this.getAhoBaseLayerId(l);
            let prev = i == 0 ?
                this.getNextLayerName() :
                this.getAhoBaseLayerId(this.ahoLayers[i-1]);
            this.addBaseLayer(id, prev);
        });
    }

    async addBaseLayer(id: string, prev: string){
        // console.log('add', id, prev);
        let map = this.store.map.mapbox;
        let data: any = {type: "Feature", properties: {name: ''}, geometry: { "type": "LineString", "coordinates": []}};
        if (!map.getSource(AgroAhoTool.SOURCE_ID)) {
            map.addSource(AgroAhoTool.SOURCE_ID, {
                'type': 'geojson',
                'data': data
            });
        }
        AhoUtils.wait(()=>map.getLayer(prev), 'l='+prev, 10, 100);
        if (!map.getLayer(id)){
            map.addLayer({
                id: id,
                source: AgroAhoTool.SOURCE_ID,
                type: 'line',
                paint: {}
            }, prev);
        }
    }

    get agroAhoStore(): AgroAhoStore{
        return (this.store as any as IAgroAhoSuperStore).agroAhoStore;
    }

    setupLayers() {
        // let store_ = this.agroAhoStore;
        // let cstore = store_.cardStore;
        // if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho) { // && cstore.winSettingVisible) {
        //     this.addLayers();
        // } else {
        //     // this.removeLayers();
        // }
    }

    // addLayers(){
    //     this.firstLayer = null;
    //     let map = this.store.map.mapbox;
    //     // let data: any = {type: "Feature", properties: {name: ''}, geometry: { "type": "LineString", "coordinates": [ [ 39.09542199, 46.3633428 ], [ 39.10757537, 46.38022671 ], [ 39.11324332, 46.3782716 ], [ 39.10098093, 46.36138709 ], [ 39.09542199, 46.3633428 ] ] }};
    //     let data: any = {type: "Feature", properties: {name: ''}, geometry: { "type": "LineString", "coordinates": []}};
    //     if (map.getSource(AgroAhoTool.SOURCE_ID) == null) {
    //         // console.log('DATA source 0:', data);
    //         if (typeof data != 'object' || !data.type) return;
    //         map.addSource(AgroAhoTool.SOURCE_ID, {
    //             'type': 'geojson',
    //             'data': data
    //         });
    //     }
    //     if (!map.getLayer(AgroAhoTool.LINE_LAYER_ID)){
    //         map.addLayer({
    //             id: AgroAhoTool.LINE_LAYER_ID,
    //             source: AgroAhoTool.SOURCE_ID,
    //             type: 'line',
    //             paint: {
    //                 "line-color": "#FF0000",
    //                 'line-width': 2,
    //                 "line-opacity": 1
    //             }
    //         }, this.getNextLayerName());
    //     }
    //
    //     this.firstLayer = AgroAhoTool.LINE_LAYER_ID;
    // }
    //
    // removeLayers(){
    //     let map = this.store.map.mapbox;
    //     if (map.getLayer(AgroAhoTool.LINE_LAYER_ID))
    //         map.removeLayer(AgroAhoTool.LINE_LAYER_ID);
    // }

    getAhoLayerId(layer_id: IAhoLayers): string{
        return `class_agroAho_${layer_id}_aho`;
    }
    getAhoBaseLayerId(layer_id: IAhoLayers): string{
        return `class_agroAho_${layer_id}_aho_base`;
    }

    async getPrevLayer_(layer_id?: IAhoLayers){

    }

    async getPrevLayer__(layer_id?: IAhoLayers){
        let store_ = this.agroAhoStore;
        let cs = store_.cardStore;
        let ms = store_.mapStore;

        let f_ds = cs?.card?.field_info?.ds_code || '';
        let c_ds = cs?.card?.cell_info?.ds_code || '';
        let t_ds = cs?.card?.track_info?.ds_code || '';
        let p_ds = cs?.card?.one_sample_point_info?.ds_code || '';
        let key = ms.lastIndicatorKey;
        let rn_key = ms.mapRNStore.rnKey;
        let map = this.store.map.mapbox;

        let noTrack = !Object.keys(cs.card.track_info).length ||
            cs.card.track_info.sum_len_m == 0;
        let noCell = !Object.keys(cs.card.cell_info).length;
        let noField = !Object.keys(cs.card.field_info).length;
        let noPoint = !Object.keys(cs.card.field_info).length;
        let cfg = ms.newCfg; // ms.lastCfg;

        // список всех слоев в порядке следования с признаком (on) наличия на карте
        let layers = [
            // labels > lines > tracks > cells > fields
            // labels
            {id: IAhoLayers.RnLbl, on: c_ds && cfg.recNormNumber}, // rec norm number
            {id: IAhoLayers.CellLbl, on: c_ds && cfg.cellsNumber}, // cell number
            {id: IAhoLayers.FieldLbl, on: f_ds && cfg.fieldsNumber}, // fields number
            {id: IAhoLayers.IndLbl, on: key?.ds && cfg.indicatorsNumber}, // indicator number
            {id: IAhoLayers.TrackRouteLbl, on: c_ds && cfg.trackRoute}, // track route number
            // lines
            {id: IAhoLayers.IndLine, on: key?.ds && cfg.indicators}, // indicator line
            {id: IAhoLayers.RnLine, on: key?.ds && cfg.recNorm}, // rn line
            // indicator
            {id: IAhoLayers.Ind, on: key?.ds && cfg.indicators}, // // indicator
            // tracks
            {id: IAhoLayers.TrackFieldPoint, on: t_ds && !noTrack && cfg.tracks}, // track field point
            {id: IAhoLayers.TrackCell, on: t_ds && !noTrack && cfg.tracks}, // track cell
            {id: IAhoLayers.TrackField, on: t_ds && !noTrack && cfg.tracks}, // track field _fld
            // points
            {id: IAhoLayers.Point, on: p_ds && !noPoint && cfg.points}, // points
            // cells
            {id: IAhoLayers.Cell, on: c_ds && !noCell && cfg.cells}, // cells line
            {id: IAhoLayers.CellFill, on: c_ds && !noCell && cfg.cells}, // cells fill _fill
            // fields
            {id: IAhoLayers.Field, on: f_ds && !noField && cfg.fields}, // fields
            // rec norm
            {id: IAhoLayers.Rn, on: c_ds && rn_key && cfg.recNorm}, // rec norm
            // track route
            {id: IAhoLayers.TrackRoute, on: c_ds && cfg.trackRoute}, // track route
        ];

        // console.log('layers:', AhoUtils.cp(layers));
        let res = null;
        // индекс добавляемого слоя
        let idx = layers.map(l=>l.id).indexOf(layer_id);
        // console.log('layers:', AhoUtils.cp(layers), 'idx:', layer_id, idx);
        if (idx > 0) {
            // console.log('p_idx on arr:', layers.slice(0, idx).reverse().map(l=>l.on));
            // нижние слои
            let layers_ = layers.slice(0, idx);
            // индекс первого имеющегося на карте нижнего слоя
            // for (let i = layers_.length - 1; i>=0; i--){
            for (let i = 0; i<layers_.length; i++){
                if (layers_[i].on &&
                    await AhoUtils.wait(()=>map.getLayer(`class_agroAho_${layers_[i].id}_aho`),
                        null /*`wait ${layer_id}`*/,10, 10)) {
                    res = layers_[i].id;
                    // return layers_[i].id;
                }
            }
        }
        // console.log('get_id', layer_id, res);
        return res;
    }

    // getPrevLayer__(layer_id?: string){
    //     let store_ = this.agroAhoStore;
    //     let cs = store_.cardStore;
    //     let ms = store_.mapStore;
    //
    //     let f_ds = cs?.card?.field_info?.ds_code || '';
    //     let c_ds = cs?.card?.cell_info?.ds_code || '';
    //     let t_ds = cs?.card?.track_info?.ds_code || '';
    //     let p_ds = cs?.card?.point_info?.ds_code || '';
    //     let key = ms.lastIndicatorKey;
    //     let rn_key = ms.mapRNStore.rnKey;
    //
    //     let noTrack = !Object.keys(cs.card.track_info).length ||
    //         cs.card.track_info.sum_len_m == 0;
    //     let noCell = !Object.keys(cs.card.cell_info).length;
    //     let noField = !Object.keys(cs.card.field_info).length;
    //     let noPoint = !Object.keys(cs.card.field_info).length;
    //
    //     // список всех слоев в порядке следования с признаком (on) наличия на карте
    //     let layers = []; // labels > tracks > cells > fields
    //     // labels
    //     if (c_ds) layers.push({id: ms.mapRNStore.getRecNormLabelLayerId(c_ds), on: ms.lastCfg.recNormNumber}); // rec norm number
    //     if (c_ds) layers.push({id: ms.getCellLabelLayerId(c_ds), on: ms.lastCfg.cellsNumber}); // cell number
    //     if (f_ds) layers.push({id: ms.getFieldLabelLayerId(f_ds), on: ms.lastCfg.fieldsNumber}); // fields number
    //     // indicator
    //     // if (key && ms.lastCfg.indicators) layers.push({id: ms.getIndicatorLayerId(key), on: ms.lastCfg.indicators}); // indicator
    //     // if (key?.ds) layers.push({id: ms.getIndicatorLineLayerId(key), on: ms.lastCfg.indicators}); // indicator line
    //     // if (key?.ds) layers.push({id: ms.mapRNStore.getRecNormLineLayerId(rn_key), on: ms.lastCfg.recNorm}); // rn line
    //     // if (key) layers.push({id: ms.getIndicatorLabelLayerId(key), on: ms.lastCfg.indicatorsNumber}); // indicator label
    //     // tracks
    //     if (t_ds && !noTrack) layers.push({id: ms.getCardLayerId(t_ds), on: ms.lastCfg.tracks}); // track cell
    //     if (t_ds && !noTrack) layers.push({id: ms.getCardLayerId(t_ds + '_fld'), on: ms.lastCfg.tracks}); // track field
    //     // points
    //     if (p_ds && !noPoint) layers.push({id: ms.getPointsLayerId(p_ds), on: ms.lastCfg.points}); // points
    //     // cells
    //     if (c_ds && !noCell) layers.push({id: ms.getCardLayerId(c_ds + '_fill'), on: ms.lastCfg.cells}); // cells fill
    //     if (c_ds && !noCell) layers.push({id: ms.getCardLayerId(c_ds), on: ms.lastCfg.cells}); // cells
    //     // fields
    //     if (f_ds && !noField) layers.push({id: ms.getCardLayerId(f_ds), on: ms.lastCfg.fields}); // fields
    //     // rec norm
    //     if (c_ds && rn_key) layers.push({id: ms.mapRNStore.getRecNormLayerId(rn_key), on: ms.lastCfg.recNorm});
    //
    //     // console.log('layers:', AhoUtils.cp(layers));
    //     // индекс добавляемого слоя
    //     let idx = layers.map(l=>l.id).indexOf(layer_id);
    //     if (layer_id == 'agro_base_proj276_asa_map_15_cell_rn_13_aho_rn_line') console.log('idx', idx);
    //     // console.log('idx', idx, layers, layer_id, ms.mapRNStore.rnKey && ms.mapRNStore.getRecNormLineLayerId(ms.mapRNStore.rnKey), ms.lastCfg.recNorm);
    //     if (idx > 0) {
    //         // console.log('p_idx on arr:', layers.slice(0, idx).reverse().map(l=>l.on));
    //         // нижние слои
    //         let layers_ = layers.slice(0, idx);
    //         // индекс первого имеющегося на карте нижнего слоя
    //         for (let i = layers_.length - 1; i>=0; i--){
    //             if (layers_[i].on) {
    //                 if (layer_id == 'agro_base_proj276_asa_map_15_cell_rn_13_aho_rn_line') console.log('prev004', layers_[i].id);
    //                 return layers_[i].id;
    //             }
    //         }
    //         //
    //         //
    //         // let p_idx = layers.slice(0, idx).reverse().map(l=>l.on).indexOf(true);
    //         // // let p_idx = layers.slice(0, idx).reverse().map(l=>l.on).indexOf(true);
    //         // // console.log('prev:', layer_id, p_idx, layers[idx - (p_idx + 1)].id);
    //         // if (p_idx > -1) return layers[idx - (p_idx + 1)].id;
    //     }
    //     return null;
    // }

    getPrevLayer(layer_id?: IAhoLayers){
        return this.getAhoBaseLayerId(layer_id);
    }

    // async getPrevLayer_old(layer_id?: IAhoLayers){
    //     let map = this.store.map.mapbox;
    //     let fl = await this.getPrevLayer_(layer_id);
    //     this.firstLayer = fl ? `class_agroAho_${fl}_aho` : null;
    //     let id = AgroAhoTool.LINE_LAYER_ID;
    //     let line_layer_id = map.getLayer(id) ? id : '';
    //     let prev = this.firstLayer || this.getNextLayerName() || line_layer_id;
    //     // console.log('prev:', prev, this.firstLayer, this.getNextLayerName(), line_layer_id);
    //     // console.log('prev:', layer_id, prev);
    //     if (!map.getLayer(prev)) {
    //         // console.log('agroAho getPrevLayer is null')
    //         return null;
    //     }
    //     return prev;
    // }

    public ownLastLayerName(): string{
        return this.firstLayer;
    }

    onDblclick(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho) {
            // console.log('onDblClick', e);
            e.noPropagation();
            // e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }
    onMouseClick(e: maplibregl.MapMouseEvent & ToolEvent): void {
        // console.log('tool mclick', this.store.map.measuringStatus);
        // if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho && e.originalEvent.button != 2) {
        //     e.noPropagation();
        //     e.preventDefault();
        //     return;
        // }
    }

    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent): void {
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho) {
            // disable cursor
            // e.cursor = "default";
            // this.store.root.map.superTools.moveMapTool.onMouseMove(e);
            // e.noPropagation();
        }
        // return super.onMouseMove(e);

        // return;
    }
    onMouseUp(e: MapMouseEvent & ToolEvent): void {
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho && e.originalEvent.button != 2) {
            let pos = JSON.stringify(e.lngLat);
            // console.log('onMouseUp', this.lastMouseUpPos, pos, this.lastMouseUpPos == pos);
            // if (this.lastMouseUpPos == pos) { // блокировать двойной клик

            // пробрасываем событие onMouseUp для обхода VoronovStopClickTool
            // для лечения бага "залипания" мыши после двойного клика при редактировании полигона:
            this.store.root.map.superTools.moveMapTool.onMouseUp(e);

            let mode = this.agroAhoStore.editorStore.mode;
            if (this.store.map.measuringStatus ==AgroExportConst.VORONOV_EDIT_GEOMETRY ||
                mode == AhoEditorMode.Select) {
                if (!e.dragging) e.noPropagation();
            }
            // e.noPropagation();

            this.lastMouseUpPos = pos;
        }
    }
}