import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {RightSidebarTop} from "./RightSidebarTop";
import {RightSidebarBottom} from "./RightSidebarBottom";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";

@observer
export class RightSidebar extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className={classNames("right-sidebar transit-map")}>
            <RightSidebarTop store={store}/><RightSidebarBottom store={store}/>
        </div>;
    }
}
