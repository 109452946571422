import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import * as mapboxgl from "maplibre-gl";
import {
    EventData,
    MapMouseEvent,
    MapStyleDataEvent,
    NavigationControl
} from "maplibre-gl";
import {action, autorun, observable, reaction} from "mobx";
import {BottomSidebarComp} from "./BottomSidebarComp";
import {addBinaryLayer, Utils} from "../../../helper/utils/Utils";
import {FeatureCollection} from "geojson";
import {MapStore} from "../../../store/MapStore";
import {IReactionDisposer} from "mobx/lib/internal";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {MapChildComp} from "./MapChildComp";
import {UrlSyncComp} from "../../UrlSyncComp";
import {ConstantsStore} from "../../../store/config/ConstantsStore";

@observer
export class MapComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.mapRef = React.createRef();
    }

    mapRef: any;
    map: mapboxgl.Map = null;
    navControl: NavigationControl = null;
    @observable previousUrl: string = "";
    static readonly MIN_SEARCH_ZOOM: number = 6;
    static readonly DEFAULT_TILE_SIZE: number = 256;
    static readonly MAX_ZOOM: number = 18;
    static readonly SEARCH_BOUNDS_COEF: number = 0.16; //половина от 1/3 пустой
    static readonly EMPTY_SOURCE: FeatureCollection = {
        type: "FeatureCollection",
        features: []
    };

    @observable test1: number = 0;
    @observable static minHeight: number;
    @observable static maxHeight: number;
    @observable currentRequestCount: number = 0;
    @observable latestPng: string | null = null;
    @observable latestCoordinates: Array<Array<number>> | null = null;
    binaryLayerTimeout: ReturnType<typeof setTimeout> | null = null;
    currentRequestId: number = 0;
    static getMinHeight(): number {
        return MapComp.minHeight;
    }

    static getMaxHeight(): number {
        return MapComp.maxHeight;
    }
    prev_style: mapboxgl.Style = null;
    disposers: IReactionDisposer[] = [];

    componentDidMount() {
        const initialExtend = new mapboxgl.LngLat(100.3515,65.1350);
        // this.props.store.map.center.setMbPoint(initialExtend)
        this.disposers.push(
            reaction(
                () => {
                    return {
                        cur: this.props.store.map.currentBaselayerKey
                    };
                },
                () => {
                    this.changeBaseLayer();
                },
                {fireImmediately: true}
            )
        );

        this.disposers.push(
            autorun(() => {
                this.props.store.searchPanel.refreshByTimer2();
            })
        );
    }

    @action
    async changeBaseLayer() {
        let ms = this.props.store.map;
        
        if (this.map == null) {
            this.firstStyleLoaded = false;
            ms.mapReady = false;
            let mapStyle = ms.getMapStyle();
            this.map = new mapboxgl.Map({
                attributionControl: false,
                accessToken: this.props.store.config.accessToken,
                container: this.mapRef.current,
                transformRequest:
                    this.props.store.map.mapTransformRequest,
                style: mapStyle
            });
            this.map.jumpTo({
                center: ms.center.getMbPoint(),
                zoom: ms.zoom
            });
            this.map.setMaxZoom(20);
            ms.setMapbox(this.map);
            this.map.on("moveend", this.onMoveEnd);
            this.map.on("click", this.onClick);
            this.map.on("mousemove", this.onMouseMove);
            this.map.on("mousedown", this.onMouseDown);
            this.map.on("mouseout", this.onMouseOut);
            //this.map.on("sourcedata", this.onSourcedata);
            //this.map.on("load", this.onLoad);
            this.map.on("styledata", this.onStyleData);
            this.map.on("rotateend", this.onRotateOrPitch);
            this.map.on("pitchend", this.onRotateOrPitch);
            //this.map.on("move", this.props.store.map.rulerInfo.onRecalcRulerInfo);
            this.map.on("zoomend", this.onChangeZoomEnd);
        } else {
            if (this.hackTimer == null)
                this.prev_style = this.map.getStyle();
            ms.mapReady = false;
            this.firstStyleLoaded = false;
            //this.deinstallDrawingComponent();
            //console.log('Set style: ', ms.getMapStyle());
            this.map.setStyle(ms.getMapStyle());
        }
        if (ms.currentBaselayerKey === "heights") {
            addBinaryLayer(this.props.store.map.mapbox,(minHeight: number, maxHeight: number) => {
                MapComp.minHeight = minHeight;
                MapComp.maxHeight = maxHeight;
            });
        }
    }

    @action
    onChangeZoomEnd() {
        let ms = this.props.store.map;
        if (!ms.mapReady) return;
        let newZoom = this.map.getZoom();
        if (ms.zoom != newZoom) ms.zoom = newZoom;
        //ms.rulerInfo.onRecalcRulerInfo();
        if (ms.currentBaselayerKey === "heights") {
            if (this.binaryLayerTimeout) {
                clearTimeout(this.binaryLayerTimeout);
            }
            this.binaryLayerTimeout = setTimeout(() => {
                addBinaryLayer(this.props.store.map.mapbox,(minHeight: number, maxHeight: number) => {
                    MapComp.minHeight = minHeight;
                    MapComp.maxHeight = maxHeight;
                });
            }, 200);
        }
    }

    @action
    componentWillUnmount() {
        let ms = this.props.store.map;
        this.deinstallDrawingComponent();
        if (this.map != null) {
            this.map.remove();
        }
        ms.setMapbox(null);
        this.disposers.forEach(a => a());
        this.disposers = [];
    }

    @action onRotateOrPitch() {
        let bearing = this.map.getBearing();
        let pitch = this.map.getPitch();
        if (bearing == 0 && pitch == 0 && this.navControl) {
            this.map.removeControl(this.navControl);
            this.navControl = null;
        } else if (!this.navControl && (bearing != 0 || pitch != 0)) {
            this.navControl = new NavigationControl({
                showCompass: true,
                showZoom: false,
                visualizePitch: true
            });
            this.map.addControl(this.navControl, "bottom-right");
            let panels = document.getElementsByClassName(
                "mapboxgl-ctrl-compass"
            );
            if (panels.length > 0) {
                panels[0].setAttribute("style", "cursor: pointer;");
                panels[0].setAttribute(
                    "title",
                    this.props.store.trans["Reset bearing to north"]
                );
            }
        }
    }

    deinstallDrawingComponent() {
        let ms = this.props.store.map;

        if (ms.superTools != null) {
            ms.superTools.container.uninstall();
        }
    }
    installDrawingComponent() {
        let ms = this.props.store.map;

        if (!ms.superTools.container.installed) {
            ms.superTools.container.install(this.map);
        }

        let panels = document.getElementsByClassName(
            "mapboxgl-ctrl-group mapboxgl-ctrl"
        );
        if (panels.length > 0)
            panels[0].setAttribute("style", "visibility: hidden;");
    }

    @action
    styleLoaded() {
        let ms = this.props.store.map;
        ms.mapReady = true;

        this.installDrawingComponent();
        //ms.zoomToSearchObject();
        this.setBounds(this.props.store.map);
    }

    restorePrevStyles() {
        let map = this.map;
        if (this.prev_style == null) return;
        for (let id in this.prev_style.sources) {
            let src = this.prev_style.sources[id];
            if (
                id.startsWith(ConstantsStore.COMMON_PREFIX) ||
                id.startsWith("mapbox-gl-draw-")
            ) {
                if (map.getSource(id) == null) {
                    map.addSource(id, src);
                }
            }
        }

        let beforeLayerId = "gl-draw-line.cold";
        if (map.getLayer(beforeLayerId) == null) beforeLayerId = null;
        for (let i = 0; i < this.prev_style.layers.length; i++) {
            let l = this.prev_style.layers[i];
            if (
                l.id.startsWith(ConstantsStore.COMMON_PREFIX) ||
                l.id.startsWith("gl-draw-")
            ) {
                if (map.getLayer(l.id) == null) {
                    map.addLayer(l, beforeLayerId);
                }
            }
        }
    }

    @action
    onMyOnStyleLoad() {
        this.restorePrevStyles();
        this.styleLoaded();
    }
    hackTimer: any = null;
    firstStyleLoaded: boolean = false;
    onStyleData(event: MapStyleDataEvent) {
        //Эта дрянь может вызываться несколько раз на одину загрузку карты
        //и потом снова вызываться когда уже не надо и изменения делаю я руками
        //console.log(this.hackTimer, this.map.isStyleLoaded(), this.firstStyleLoaded);
        if (this.hackTimer != null) {
            clearInterval(this.hackTimer);
            this.hackTimer = null;
        }
        if (this.firstStyleLoaded) return;
        this.hackTimer = setInterval(() => {
            if (this.map.isStyleLoaded()) {
                //console.log('timer callback', this.hackTimer, this.map.isStyleLoaded(), this.firstStyleLoaded);
                clearInterval(this.hackTimer);
                this.hackTimer = null;
                this.firstStyleLoaded = true;
                this.onMyOnStyleLoad();
            }
        }, 200);
    }

    setBounds(ms: MapStore) {
        let bb = this.map.getBounds();
        let b = Utils.getInnerBounds(bb, MapComp.SEARCH_BOUNDS_COEF);
        ms.searchBounds.setMbBounds(b);
        ms.bbox.setMbBounds(bb);
    }

    @action onMoveEnd() {
        let ms = this.props.store.map;
        if (!ms.mapReady) return;
        const {lng, lat} = this.map.getCenter();
        if (ms.center.lng != lng || ms.center.lat != lat) {
            ms.center.set(lat, lng);
        }
        ms.menuPointer = null;
        this.setBounds(ms);
        if (ms.currentBaselayerKey === "heights") {
            if (this.binaryLayerTimeout) {
                clearTimeout(this.binaryLayerTimeout);
            }
            this.binaryLayerTimeout = setTimeout(() => {
                addBinaryLayer(this.props.store.map.mapbox,(minHeight: number, maxHeight: number) => {
                    MapComp.minHeight = minHeight;
                    MapComp.maxHeight = maxHeight;
                });
            }, 200);
        }
    }

    @action onClick(e: MapMouseEvent & EventData) {}

    onMouseDown(e: MapMouseEvent & EventData) {}
    @action onMouseMove(e: MapMouseEvent & EventData) {
        let ms = this.props.store.map;
        ms.mousePointer = [e.lngLat.lng, e.lngLat.lat];
        ms.hintMousePointer = e.point;
        ms.isMouseInsideMap = true;
    }

    @action onMouseOut() {
        this.props.store.map.isMouseInsideMap = false;
    }

    render() {
        let store = this.props.store;
        let compareMode = store.map.compareModeEnabled;
        return (
            <div
                id='central-panel'
                className={classNames("transit-map", {
                    "extand-map": store.map.extandMap
                })}
            >
                <div id='map'>
                    <MapChildComp store={store} />
                    <div
                        ref={this.mapRef}
                        id='mapRef'
                        tabIndex={111}
                    />
                </div>
                {!compareMode && <BottomSidebarComp store={store} />}
            </div>
        );
    }
}
