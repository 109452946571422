import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoMapDebugItemComp.scss';
import classNames from "classnames";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoDelSvg} from "../../AgroAhoPanel/icons/AgroAhoDelSvg";
import {AgroAhoMapDebugStore} from "./agroAhoMapDebug";
import {AgroAhoEyeSvg} from "../../AgroAhoPanel/icons/AgroAhoEyeSvg";
import {observable} from "mobx";


export interface AgroAhoMapDebugItemCompProps {
    store: IAgroAhoSuperStore;
    layer:  maplibregl.AnyLayer;
}

@observer
export class AgroAhoMapDebugItemComp extends React.Component<AgroAhoMapDebugItemCompProps, undefined> {
    constructor(props: AgroAhoMapDebugItemCompProps) {
        super(props);
        autoBindReact(this);
        this.dstore = this.props.store.agroAhoStore.mapStore.mapDebugStore;
    }

    dstore: AgroAhoMapDebugStore;
    @observable
    vis: string = 'visible';

    onDelLayer(){
        this.dstore.doDelLayer(this.props.layer);
    }
    onHideLayer(){
        let map = this.props.store.root.map.mapbox;
        let id = this.props.layer.id;
        let vis = map.getLayoutProperty(id, 'visibility');
        this.vis = vis == 'none' ? 'visible' : 'none';
        map.setLayoutProperty(id, 'visibility', this.vis);
    }
    onClick(){
        this.dstore.onLayerClick(this.props.layer);
    }

    render() {
        let layer = this.props.layer;

        return <div className="AgroAhoMapDebugItemComp-main" onClick={this.onClick}>
            <div className="AgroAhoMapDebugItemComp-id">{layer.id}</div>
            <div className={classNames("AgroAhoMapDebugItemComp-hideCover", {
                "AgroAhoMapDebugItemComp-invisible": this.vis != 'visible'
            })}  onClick={this.onHideLayer}>
                <AgroAhoEyeSvg className="AgroAhoMapDebugItemComp-hideLayer"/>
            </div>
            <div className="AgroAhoMapDebugItemComp-delCover" onClick={this.onDelLayer}>
                <AgroAhoDelSvg className="AgroAhoMapDebugItemComp-delLayer"/>
            </div>
        </div>;
    }
}
