import {ContainerToolsState, IGeometryEditorEvents, IObjectByClick} from "../../../app/store/tools/general/ContainerTools";
import {observable} from "mobx";
import {Geometry} from "geojson";
import * as mapboxgl from "maplibre-gl";
import {makeLogger} from "ts-loader/dist/logger";

import {CustomStore} from "../../../app/store/CustomStore";
import {AgroAhoEditorStore} from "../agroAhoEditorStore";
import {ViewGeometryLayersTool} from "../../../app/store/tools/EditGeometry/ViewGeometryLayersTool";
import {GeometryUtils} from "../../../app/helper/utils/GeometryUtils";
import {MeasuringStatus} from "../../../app/helper/structs/MeasuringStatus";
import {VoronovEditManager} from "../../agroExport/tools/VoronovEditManager";
import {AgroExportConst} from "../../agroExport/AgroExportConst";

export class agroAhoVoronovEditManager extends VoronovEditManager implements IGeometryEditorEvents{
    constructor(props: CustomStore) {
        super(props);
        this.editorStore = <AgroAhoEditorStore>props;
        this.state.events = this;
    }

    editorStore: AgroAhoEditorStore;
    _lastSimpleGeometry: any;
    _lastMeasuringStatus: MeasuringStatus | string;

    canDeletePointOnEdit(): boolean {
        // return false;
        return true;
    }

    exit(){
        this._lastSimpleGeometry = JSON.parse(JSON.stringify(this.state.simpleGeometry));
        this.state.simpleGeometry = [];
        this.updateGeometry();
        this._lastMeasuringStatus = this.root.map.measuringStatus;
        if (this.root.map.measuringStatus == AgroExportConst.VORONOV_EDIT_GEOMETRY)
            this.root.map.resetMeasuringStatus();
    }
    restore(){
        this.state.simpleGeometry = this._lastSimpleGeometry;
        this.updateGeometry();
        this.root.map.setMeasuringStatus(this._lastMeasuringStatus);
    }
    isEdit(): boolean {
        // return (this.root.map.measuringStatus == MeasuringStatus.VORONOV_EDIT_GEOMETRY && this.state.simpleGeometry != null && this.root.voronovExportStore.showReadyLayers());//this.root.map.measuringStatus == MeasuringStatus.agroEdit;
        // return (this.root.map.measuringStatus == MeasuringStatus.VORONOV_EDIT_GEOMETRY &&
        // return (this.root.map.measuringStatus == MeasuringStatus.agroEdit &&
        // console.log('isEdit', this.root.map.measuringStatus == AgroExportConst.VORONOV_EDIT_GEOMETRY,
        //     this.state.simpleGeometry != null, this.editorStore.showReadyLayers());
        return (this.root.map.measuringStatus == AgroExportConst.VORONOV_EDIT_GEOMETRY &&
            this.state.simpleGeometry != null && this.editorStore.showReadyLayers());//this.root.map.measuringStatus == MeasuringStatus.agroEdit;
    }
}