import autoBindReact from "auto-bind/react";
import {action} from "mobx";
import {observer} from "mobx-react";
import React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './PopupPanelComp.scss';

@observer
export class PopupPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClose(){
        this.props.store.popup.data = null;        
    }

    render() {
        let ps = this.props.store.popup;
        let rows: any[] = [];
        let fields = ps.data.feature.layer.metadata?.["class:overlay:popup:fields"];
        let validFields = fields? new Set(fields) : null;
        if ('fid' in ps.data.feature.properties) {
            const fieldOrder = ["zone", "soil_0", "soil_1", "soil_2"];
            fieldOrder.forEach((fieldName : string) => {
                if (fieldName in ps.data.feature.properties) {
                    let displayName = fieldName;
                    if (fieldName === "soil_0") displayName = "Основной тип почв";
                    if (fieldName === "zone") displayName = "Зона";
                    if (fieldName === "soil_1") displayName = "Сопут. тип почв";
                    if (fieldName === "soil_2") displayName = "Сопут. тип почв 2";
                    if (validFields && !validFields.has(fieldName)) return;
                    rows.push(
                        <tr key={fieldName}>
                            <td className="PopupPanelComp-table-key">{displayName}</td>
                            <td className="PopupPanelComp-table-value">{ps.data.feature.properties[fieldName]}</td>
                        </tr>
                    );
                }
            });
        } else {
            Object.keys(ps.data.feature.properties).forEach((p : any) => {
                if (validFields && !validFields.has(p)) return;
                if (p === "id") return; 
                let displayName = p;
                if (p === "name") displayName = "Название"; 
                rows.push(
                    <tr key={p}>
                        <td className="PopupPanelComp-table-key">{displayName}</td>
                        <td className="PopupPanelComp-table-value">{ps.data.feature.properties[p]}</td>
                    </tr>
                );
            });
        }
        let arr = <div>
            <div className="PopupPanelComp-table-header">
                <div className="PopupPanelComp-table-headerText">{ps.data.overlay.title}</div>
                <div className="PopupPanelComp-table-headerClose">
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                        className="pointer" onClick={this.onClose}>
                        <path d="M10.1062 0.89375C9.83125 0.61875 9.41875 0.61875 9.14375 0.89375L5.5 4.5375L1.85625 0.89375C1.58125 0.61875 1.16875 0.61875 0.89375 0.89375C0.61875 1.16875 0.61875 1.58125 0.89375 1.85625L4.5375 5.5L0.89375 9.14375C0.61875 9.41875 0.61875 9.83125 0.89375 10.1062C1.03125 10.2437 1.16875 10.3125 1.375 10.3125C1.58125 10.3125 1.71875 10.2437 1.85625 10.1062L5.5 6.4625L9.14375 10.1062C9.28125 10.2437 9.4875 10.3125 9.625 10.3125C9.7625 10.3125 9.96875 10.2437 10.1062 10.1062C10.3812 9.83125 10.3812 9.41875 10.1062 9.14375L6.4625 5.5L10.1062 1.85625C10.3812 1.58125 10.3812 1.16875 10.1062 0.89375Z" fill="#C5C5C5"/>
                    </svg>
                </div>
            </div>
            {rows.length > 0 && <table className="PopupPanelComp-table">
                <tbody>
                    {rows}
                </tbody>
            </table>}
        </div>;
        return <div className="PopupPanelComp-popup" style={{top: (ps.data.y - 11)+"px", left: (ps.data.x - 1)+"px"}}>
            <div className="PopupPanelComp-popup-content">{arr}</div>
            <div className="PopupPanelComp-popup-pin"></div>
        </div>
    }
}