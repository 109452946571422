import {IGraphDayValue, IndexByPointGraphStore} from "../IndexByPointGraphStore";
import {Utils} from "../../../helper/utils/Utils";
import {IndexByPointerSourceSimpleTempCustomStore} from "./IndexByPointerSourceSimpleTempCustomStore";

export class IndexByPointerSourceSimpleTempNOAAStore extends IndexByPointerSourceSimpleTempCustomStore{
    constructor(parent: IndexByPointGraphStore) {
        super(parent);
        this.dataValue.getUrl = this.getUrlValue;
        this.dataMin.getUrl = this.getUrlMin;
        this.dataMax.getUrl = this.getUrlMax;
        this.dataValue.getValue = this.getValueSimple;
        this.dataMin.getValue = this.getValueMin;
        this.dataMax.getValue = this.getValueMax;
    }

    protected getUrlValue(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return `/api/meteo/noaa/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=mean_temp`;
    }
    protected getUrlMin(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return `/api/meteo/noaa/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=min_temp`;
    }
    protected getUrlMax(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return `/api/meteo/noaa/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=max_temp`;
    }
    protected getValueSimple(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.mean_temp);
    }
    protected getValueMin(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.min_temp);
    }
    protected getValueMax(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.max_temp);
    }
}