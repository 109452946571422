import {CustomStore} from "./CustomStore";
import {observable} from "mobx";
import {save, update} from "./PermalinkDecor";
import {IntervalStore} from "./IntervalStore";

export enum SearchParamsS5Type{
    scenes= 'scenes',
    composites='composites'
}

export enum SearchParamsS5CompositeMethod{
    newest='newest',
    oldest='oldest',
    max='max',
    min='min',
    mean='mean',
    median='median',
    rms='rms'
}

export class SearchParamsS5Store extends CustomStore{
    constructor(parent: CustomStore) {
        super(parent);
        let d = new Date();
        this.sceneYear = d.getFullYear();
        this.sceneMonth = d.getMonth();
    }
    @save @observable
    type: SearchParamsS5Type = SearchParamsS5Type.scenes;
    @save @observable
    sceneMonth: number = null;
    @save @observable
    sceneYear: number = null;
    @update
    compositeDate: IntervalStore = new IntervalStore(this);
    @save @observable
    compositeInterval: number = 10;
    @save @observable
    compositeMethod: SearchParamsS5CompositeMethod = SearchParamsS5CompositeMethod.median;
}