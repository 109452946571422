import {CustomTool} from "../general/ContainerTools";
import {MapStore} from "../../MapStore";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import mapboxgl from "maplibre-gl";

export class CancelContextMenuTool extends CustomTool {
    mapStore: MapStore;

    onMouseDown(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (!(e.originalEvent.button & 2)) {
            let ms = this.mapStore;
            if (ms.menuPointer != null) ms.menuPointer = null;
        }
    }
}