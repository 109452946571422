import * as React from 'react';
import {action} from "mobx";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import autoBindReact from "auto-bind/react";
import {ExportItemsType} from "../../../../store/export/ExportStore";


@observer
export class ExportDiffSatErrorComp extends React.PureComponent<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClose(){
        let store = this.props.store;
        store.exportStore.showExportOptions = false;
    }
    @action onClickListFavAll(){
        this.props.store.exportStore.exportItemsType = ExportItemsType.allFavorites;
    }
    @action onClickListFavVisible(){
        this.props.store.exportStore.exportItemsType = ExportItemsType.favoritesSelected;
    }

    render() {
        let store = this.props.store;
        let es = this.props.store.exportStore;

        return <div className="">
            <div className="center-div popup-exp_dif-img">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.4618 13.9519C40.4449 12.9688 40.9973 11.6353 40.9973 10.2449C40.9973 8.85454 40.4449 7.52109 39.4618 6.53793C38.4786 5.55477 37.1452 5.00244 35.7548 5.00244C34.3644 5.00244 33.0309 5.55477 32.0478 6.53793L14.2928 24.2929C14.1053 24.4805 14 24.7348 14 24.9999C14 25.2651 14.1053 25.5194 14.2928 25.7069L20.2928 31.7069C20.4803 31.8944 20.7346 31.9997 20.9998 31.9997C21.265 31.9997 21.5193 31.8944 21.7068 31.7069L39.4618 13.9519Z" fill="#6A7886"/>
                    <path d="M19.4138 15.9998L21.7068 13.7068C21.8943 13.5193 21.9996 13.265 21.9996 12.9998C21.9996 12.7346 21.8943 12.4803 21.7068 12.2928L9.70679 0.292786C9.51926 0.105315 9.26495 0 8.99979 0C8.73462 0 8.48031 0.105315 8.29279 0.292786L2.29279 6.29279C2.10532 6.48031 2 6.73462 2 6.99979C2 7.26495 2.10532 7.51926 2.29279 7.70679L14.2928 19.7068C14.4803 19.8943 14.7346 19.9996 14.9998 19.9996C15.265 19.9996 15.5193 19.8943 15.7068 19.7068L17.9998 17.4138L19.5858 18.9998L20.9998 17.5858L19.4138 15.9998Z" fill="#454C52"/>
                    <path d="M15 46C11.0231 45.9955 7.21044 44.4137 4.39837 41.6016C1.5863 38.7896 0.00449947 34.9769 0 31C0 30.7348 0.105357 30.4804 0.292893 30.2929C0.48043 30.1054 0.734784 30 1 30C1.26522 30 1.51957 30.1054 1.70711 30.2929C1.89464 30.4804 2 30.7348 2 31C2.00397 34.4466 3.37488 37.7509 5.812 40.188C8.24911 42.6251 11.5534 43.996 15 44C15.2652 44 15.5196 44.1054 15.7071 44.2929C15.8946 44.4804 16 44.7348 16 45C16 45.2652 15.8946 45.5196 15.7071 45.7071C15.5196 45.8946 15.2652 46 15 46Z" fill="#4DB6BC"/>
                    <path d="M15 40C12.6139 39.9974 10.3262 39.0483 8.63896 37.361C6.95171 35.6738 6.00265 33.3861 6 31C6 30.7348 6.10536 30.4804 6.29289 30.2929C6.48043 30.1054 6.73478 30 7 30C7.26522 30 7.51957 30.1054 7.70711 30.2929C7.89464 30.4804 8 30.7348 8 31C8.00238 32.8558 8.74064 34.6349 10.0529 35.9471C11.3651 37.2594 13.1442 37.9976 15 38C15.2652 38 15.5196 38.1054 15.7071 38.2929C15.8946 38.4804 16 38.7348 16 39C16 39.2652 15.8946 39.5196 15.7071 39.7071C15.5196 39.8946 15.2652 40 15 40Z" fill="#4DB6BC"/>
                    <path d="M4.41406 6.99957L9.00006 2.41357L11.5861 4.99957L7.00006 9.58557L4.41406 6.99957ZM8.41406 10.9996L13.0001 6.41357L15.5861 8.99957L11.0001 13.5856L8.41406 10.9996ZM15.0001 17.5856L12.4141 14.9996L17.0001 10.4136L19.5861 12.9996L15.0001 17.5856Z" fill="#6A7886"/>
                    <path d="M28.5858 27.9998L26.2928 30.2928C26.1053 30.4803 26 30.7346 26 30.9998C26 31.265 26.1053 31.5193 26.2928 31.7068L38.2928 43.7068C38.4803 43.8943 38.7346 43.9996 38.9998 43.9996C39.265 43.9996 39.5193 43.8943 39.7068 43.7068L45.7068 37.7068C45.8943 37.5193 45.9996 37.265 45.9996 36.9998C45.9996 36.7346 45.8943 36.4803 45.7068 36.2928L33.7068 24.2928C33.5193 24.1053 33.265 24 32.9998 24C32.7346 24 32.4803 24.1053 32.2928 24.2928L29.9998 26.5858L28.4138 24.9998L26.9998 26.4138L28.5858 27.9998Z" fill="#454C52"/>
                    <path d="M43.5861 36.9996L39.0001 41.5856L36.4141 38.9996L41.0001 34.4136L43.5861 36.9996ZM39.5861 32.9996L35.0001 37.5856L32.4141 34.9996L37.0001 30.4136L39.5861 32.9996ZM33.0001 26.4136L35.5861 28.9996L31.0001 33.5856L28.4141 30.9996L33.0001 26.4136Z" fill="#6A7886"/>
                    <path d="M34.7074 11.2929C34.5188 11.1108 34.2662 11.01 34.004 11.0122C33.7418 11.0145 33.491 11.1197 33.3056 11.3051C33.1202 11.4905 33.015 11.7413 33.0127 12.0035C33.0105 12.2657 33.1112 12.5183 33.2934 12.7069L37.0004 16.4139L38.4144 14.9999L34.7074 11.2929Z" fill="#454C52"/>
                </svg>
            </div>
            <div className="popup-exp_dif-text popup-exp_dif-text_title">
                Warning!
            </div>
            {this.props.children}
        </div>;
    }
}
