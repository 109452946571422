import {CustomStore} from "./CustomStore";
import {action, observable} from "mobx";
import {isDate} from "lodash-es";
import {save} from "./PermalinkDecor";
import {Utils} from "../helper/utils/Utils";

export class IntervalStore extends CustomStore{
    class(): string {return "IntervalStore";}

    @save @observable
    private _begin: number = null;
    get begin(): Date {
        if (this._begin == null) return null;
        return new Date(this._begin)
    }
    set begin(value: Date){
        let v = Utils.toDate(value);
        if (v != null) {
            this._begin = v.getTime();
        }else this._begin = null;
    }

    @save @observable
    private _end: number = null;

    get end(): Date {
        if (this._end == null) return null;
        return new Date(this._end);
    }
    set end(value: Date){
        let v = Utils.toDate(value);
        if (v != null) {
            this._end = v.getTime();
        }else this._end = null;
    }

    hasValid(): boolean{
        return isDate(this.begin) || isDate(this.end);
    }

    isValid(): boolean{
        return isDate(this.begin) && isDate(this.end);
    }

    isValidEnd(): boolean{
        return isDate(this.end);
    }

    isValidBegin(): boolean{
        return isDate(this.begin);
    }

    @action
    reset(): void{
        this.begin = null;
        this.end = null;
    }
}
