import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import "./ShowRoom2.scss";
import classNames from "classnames";
import {action} from "mobx";
import {SwitchText} from "../../../Common/SwitchText";
import {ShowRoom2Multiyear} from "./ShowRoom2Multiyear";
import {ShowRoom2Projects} from "./ShowRoom2Projects";
import {DataShowRoomMode} from "../../../../store/dataShowroom2/ShowRoom2Store";
import {ShowRoom2TopFilters} from "./ShowRoom2TopFilters";

@observer
export class ShowRoom2 extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickFilterPanelButton(){
        let store = this.props.store;
        if (this.disabledParams()) return;
        store.dataShowRoomStore.filterPanelShow = !store.dataShowRoomStore.filterPanelShow;
    }

    @action
    onClickSwitch(check1: boolean){
        let store = this.props.store;
        if (check1){
            store.dataShowRoomStore.dataShowRoomMode = DataShowRoomMode.Multiyear;
        }else{
            store.dataShowRoomStore.dataShowRoomMode = DataShowRoomMode.Projects;
        }
    }

    disabledParams(): boolean{
        return !this.props.store.dataShowRoomStore.filtersProject.isValid() && this.props.store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Projects;
    }
    render() {
        let store = this.props.store;
        let activeFilterPanel = store.dataShowRoomStore.filterPanelShow;


        return <div className="ShowRoom2-main">
            {/*  заголовок  */}
            <div className="ShowRoom2-titleDiv">
                <div className="left-sidebar__right-bottom__row1-left">Data showroom</div>
                <div className={
                    classNames("icon_container icon_container_fill icon_container-preferences qtransit",
                        {"icon_container-preferences-active": activeFilterPanel,
                            "opacity50": this.disabledParams()
                        })}
                     onClick={this.onClickFilterPanelButton}
                >

                    <svg width="31" height="18" viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className={classNames({"white-fill white-stroke": !activeFilterPanel, "blue-stroke blue-fill": activeFilterPanel})}>
                        <path d="M10.4546 3.18176H17" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 3.18176H3.18182" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.2729 9H17.0002" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 9H9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.4546 14.8182H17" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 14.8182H3.18182" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.36346 5.36364C6.56844 5.36364 7.54528 4.3868 7.54528 3.18182C7.54528 1.97683 6.56844 1 5.36346 1C4.15847 1 3.18164 1.97683 3.18164 3.18182C3.18164 4.3868 4.15847 5.36364 5.36346 5.36364Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
                        <path d="M11.1818 11.1819C12.3868 11.1819 13.3636 10.205 13.3636 9.00006C13.3636 7.79507 12.3868 6.81824 11.1818 6.81824C9.97683 6.81824 9 7.79507 9 9.00006C9 10.205 9.97683 11.1819 11.1818 11.1819Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
                        <path d="M5.36346 17C6.56844 17 7.54528 16.0232 7.54528 14.8182C7.54528 13.6132 6.56844 12.6364 5.36346 12.6364C4.15847 12.6364 3.18164 13.6132 3.18164 14.8182C3.18164 16.0232 4.15847 17 5.36346 17Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
                        <g>
                            <path d="M30 8.99953C30 8.83653 29.9205 8.68353 29.7865 8.59003L24.7865 5.09003C24.6345 4.98303 24.4345 4.97103 24.269 5.05603C24.1035 5.14253 24 5.31303 24 5.49953V12.5C24 12.6865 24.1035 12.8575 24.269 12.9435C24.4345 13.0285 24.634 13.0165 24.7865 12.9095L29.7865 9.40953C29.9205 9.31653 30 9.16353 30 9.00053C30 9.00003 30 9.00003 30 8.99953C30 9.00003 30 9.00003 30 8.99953Z"/>
                        </g>

                    </svg>{/*
                    <svg width="36" height="23" viewBox="0 0 36 23" xmlns="http://www.w3.org/2000/svg" className={classNames({"white-fill white-stroke": !activeFilterPanel, "blue-stroke blue-fill": activeFilterPanel})}>
                        <path d="M10.4546 8.18164H17" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 8.18164H3.18182" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.2727 14H17" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 14H9" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.4546 19.8184H17" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 19.8184H3.18182" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.36358 10.3636C6.56857 10.3636 7.5454 9.3868 7.5454 8.18182C7.5454 6.97683 6.56857 6 5.36358 6C4.1586 6 3.18176 6.97683 3.18176 8.18182C3.18176 9.3868 4.1586 10.3636 5.36358 10.3636Z" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.1818 16.182C12.3868 16.182 13.3636 15.2052 13.3636 14.0002C13.3636 12.7952 12.3868 11.8184 11.1818 11.8184C9.97683 11.8184 9 12.7952 9 14.0002C9 15.2052 9.97683 16.182 11.1818 16.182Z" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.36358 21.9999C6.56857 21.9999 7.5454 21.023 7.5454 19.818C7.5454 18.6131 6.56857 17.6362 5.36358 17.6362C4.1586 17.6362 3.18176 18.6131 3.18176 19.818C3.18176 21.023 4.1586 21.9999 5.36358 21.9999Z" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <g clipPath="url(#clip0)">
                            <path d="M30 13.9995C30 13.8365 29.9205 13.6835 29.7865 13.59L24.7865 10.09C24.6345 9.98303 24.4345 9.97103 24.269 10.056C24.1035 10.1425 24 10.313 24 10.4995V17.5C24 17.6865 24.1035 17.8575 24.269 17.9435C24.4345 18.0285 24.634 18.0165 24.7865 17.9095L29.7865 14.4095C29.9205 14.3165 30 14.1635 30 14.0005C30 14 30 14 30 13.9995C30 14 30 14 30 13.9995Z" stroke="none"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="8" height="8" fill="white" stroke="none" transform="translate(23 10)"/>
                            </clipPath>
                        </defs>
                    </svg>*/}
                </div>

            </div>
            <ShowRoom2TopFilters store={store} />
            {/* Переключатель Multiyear <=> Projects*/}
            <div className="ShowRoom2-switcher">
                <SwitchText text1="Multiyear"
                            text2="Projects"
                            check1={store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Multiyear}
                            onClick={this.onClickSwitch}/>
            </div>
            {store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Multiyear && <ShowRoom2Multiyear store={store}/>}
            {store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Projects && <ShowRoom2Projects store={store}/>}
        </div>;
    }
}
