import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {TrashSvg} from "../../../icons/Fav/TrashSvg";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {MyHint} from "../../../Common/MyHint";
import AOIButtonsComp from '../ParamSearchPanel/AOIButtonsComp';
import { CheckboxMiniComp, CheckboxSize, TriState } from '../../../Common/CheckboxMiniComp';
import { FavListDeleteAllComp } from './FavListDeleteAllComp';

@observer
export class FavTopMenuComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickSelectAll(){
        let store = this.props.store;
        store.searchPanel.favoriteList.inversSelectAll();
    }

    @action
    onClickDelete(){
        let store = this.props.store;
        if (store.searchPanel.favoriteList.groups.length == 0) return;
        store.searchPanel.showDeleteAllScene = true;
        //store.searchPanel.favoriteList.groups.length = 0;
    }

    render() {
        let store = this.props.store;
        let state: TriState = TriState.uncheck;
        if (store.searchPanel.favoriteList.areAllSelected())
            state = TriState.check;
        else if (store.searchPanel.favoriteList.isAnySelected())
            state = TriState.partial;
        let hasItems = store.searchPanel.favoriteList.groups.length > 0;
        let tr = store.trans;
        
        return <div className="left-sidebar__favitem__topmenu">
            {hasItems &&<React.Fragment>
            <CheckboxMiniComp
                    state={ state }
                    size={CheckboxSize.standart}
                    onClick={this.onClickSelectAll}>
            </CheckboxMiniComp>
            <div className="FavTopMenuComp-buttons">                
                <AOIButtonsComp store={store} aoiExists={store.map.searchObject.isNotEmpty} area={store.map.searchObject.areaStr} shortTitle={true}/>
                <MyHint text={tr["Remove all from selected"]}>
                    <div className={classNames("FavTopMenuComp icon_container red-svg", {"disabled": !hasItems})}
                        onClick={this.onClickDelete}>
                        <TrashSvg />
                {store.searchPanel.showDeleteAllScene && <FavListDeleteAllComp store={store} />}
                    </div>
                </MyHint>
            </div>
            </React.Fragment>}
        </div>;
    }
}
