import {CustomConfirmStore} from "../../CustomConfirmStore";
import {observable} from "mobx";
import {A2NavigatorItemStore} from "../A2NavigatorItemStore";
import {A2ConfirmExportMode} from "./A2ConfirmExportStore";

export class A2ConfirmActionStore  extends CustomConfirmStore{
    @observable
    fields: number;
    @observable
    area: number;
    @observable
    path: string;
    @observable
    mode_export: A2ConfirmExportMode;


    filterSelected: any;
    activeNavigatorItem: A2NavigatorItemStore;

}