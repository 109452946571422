import * as React from 'react';
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {Utils} from "../../../../helper/utils/Utils";
import {CenterSVG} from "../../../icons/CenterSVG";
import {StarEmptyMinusSvg} from "../../../icons/Fav/StarEmptyMinusSvg";
import {StarFullMinusSvg} from "../../../icons/Fav/StarFullMinusSvg";
import {SearchItemGroup} from "../../../../store/SearchItemGroup";
import {CheckStates} from "../../../../helper/structs/CheckStates";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {SearchSceneItemImgComp} from "../SearchPanel/SearchSceneItemImgComp";
import {SearchSceneMiniMap2Comp} from "../SearchPanel/SearchSceneMiniMap2Comp";
import {SceneMosaicSwitch} from "../../../../store/SearchStore";

export interface IFavItemComp extends IStoreProps{
    searchGroup: SearchItemGroup
}

@observer
export class FavItemComp extends React.PureComponent<IFavItemComp, undefined> {
    constructor(props: IFavItemComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onMouseOver() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = this.props.searchGroup.groupId();
    }

    @action
    onMouseEnter() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = this.props.searchGroup.groupId();
    }

    @action
    onMouseLeave() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = null;
    }



    @action
    onImgLoad(){
        //let imgUrl = this.getImgUrl();
        //if (this.imgUrlForLoad == imgUrl){
            this.loaded = true;
            this.error = false;
            this.forceUpdate();
        //}
    }

    @action
    onError(){
        //let imgUrl = SearchSceneItemComp.getImgUrl();

        //if (this.imgUrlForLoad == imgUrl){
            this.loaded = true;
            this.error = true;
            this.forceUpdate();
        //}
        //store.addError(Utils.getErrorString(err)+" "+imgUrl);
    }

    onClickImage(){
        let store = this.props.store;
        let sg = this.props.searchGroup;
        let it = sg.first().feature.properties;
        Utils.copyToClipboard(it.scene_id);

    }

    preview_error_url = "./img/preview_not_available.svg"

    imgUrlForLoad: string;
    loaded: boolean = false;
    error: boolean = false;
    @observable animate_delete = false;

    @action
    onClickSelect(){
        let sg = this.props.searchGroup;
        if (sg.getSelected() != CheckStates.checked){
            sg.setSelected(true);
        }else sg.setSelected(false);
    }
    @action
    onClickFavorite(event: any){
        this.animate_delete = true;
        event.stopPropagation();
    }
    @action
    CopyName(event: any){
        let si = this.props.searchGroup;
        let store = this.props.store;
        Utils.copyToClipboard(si.groupId());
        store.addInfo(store.trans.Copied);
        event.stopPropagation();
    }
    toCenter(event: any){
        let si = this.props.searchGroup;
        let bbox = si.getBbox();
        this.props.store.map.zoomFlyToBBox(bbox);
        event.stopPropagation();
    }
    
    @action
    onDeleteAnimationEnd(){
        let store = this.props.store;
        let sg = this.props.searchGroup;

        Utils.arrayRemoveByValue(store.searchPanel.favoriteList.groups, sg);
        store.searchPanel.favoriteList.totalRecords = store.searchPanel.favoriteList.groups.length;
    }

    render() {
        let store = this.props.store;
        let si = this.props.searchGroup;
        let it = si.first().feature.properties;

        let multiScene = (si.items.length > 1);
        let cloud = it.cloud.toString() + '%';
        if (multiScene){
            let min = it.cloud;
            let max = it.cloud;
            si.items.forEach(a => {
                if (a.feature.properties.cloud > max) max = a.feature.properties.cloud;
                if (a.feature.properties.cloud < min) min = a.feature.properties.cloud;
            });
            cloud = min.toString()+"-"+max.toString()+"%";
        }

        let sunelev = it.sunelev.toString() + '°';
        if (multiScene){
            let min = it.sunelev;
            let max = it.sunelev;
            si.items.forEach(a => {
                if (a.feature.properties.sunelev > max) max = a.feature.properties.sunelev;
                if (a.feature.properties.sunelev < min) min = a.feature.properties.sunelev;
            });
            sunelev = min.toString()+"-"+max.toString()+"°";
        }

        //let isCurrent = store.searchPanel.currentSceneid == it.sceneid;
        si.wasVisible = true;

        let is_selected = si.getSelected();

        let showError = (this.loaded && this.error);
        let leafleatImg = (store.map.searchObject.isNotEmpty)
            || store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.mosaic;


        return <div className={classNames("left-sidebar__favitem__row4-in",{"animate__animated animate__bounceOutLeft": this.animate_delete})}
                    onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onAnimationEnd={this.onDeleteAnimationEnd}
                    onClick={this.onClickSelect}
                    id={it.scene_id}>
            <div className={classNames("left-sidebar__favitem__row4-in-left relative pointer", {"blue-active": is_selected == CheckStates.checked})} >
                {/*
                {!this.loaded && <LoadingBars classes="loading-img" />}
                {showError && <img src={this.preview_error_url} className="preview_error img-radius"
                                   alt="Quicklook is not found" title={store.trans["Quicklook is not found"]} />
                }
                {!showError && <SearchSceneItemImgComp searchGroup={si} store={store} className="left-sidebar__favitem__row4-in-left-in img-radius"
                                                       onLoad={this.onImgLoad} onError={this.onError}/>
                }*/}
                {!leafleatImg && <SearchSceneItemImgComp searchGroup={this.props.searchGroup} store={store}
                                                         className={classNames("left-sidebar__favitem__row4-in-left-in img-radius")}
                />}

                {leafleatImg && <SearchSceneMiniMap2Comp store={store} searchGroup={this.props.searchGroup}
                                         className="left-sidebar__favitem__row4-in-left-in img-radius"
                />}
            </div>
            <div className="left-sidebar__favitem__row4-in-right pointer">
                <div className="left-sidebar__favitem__row4-in-right-el">
                    <div className="calendar_table dark-blue" onClick={this.onClickImage}/>
                    {it.acqdate} {!multiScene && it.acqtime}
                    <div className="pointer right_row_icon_iconbox left-sidebar__favitem__hidden_right" onClick={this.onClickFavorite} >
                        <div style={{display:"block", position:"relative"}}>
                        <StarFullMinusSvg className="left-sidebar__favitem__minus_full left-sidebar__favitem__minus"/>

                        <StarEmptyMinusSvg className="left-sidebar__favitem__minus_empty left-sidebar__favitem__minus" />
                        </div>
                    </div>
                </div>
                <div className="left-sidebar__favitem__row4-in-right-el">
                    <div className="cloud-sun_table dark-blue"/>
                    {cloud}
                </div>
                <div className="left-sidebar__favitem__row4-in-right-el">
                    <div className="satellite_table svg_table dark-blue"/>
                    <span className="left-sidebar__favitem__row4-in-right-span">{it.satellite}</span>
                    {!multiScene && <div className="maximize-area_table dark-blue"/>}
                    {!multiScene && <span className="left-sidebar__favitem__row4-in-right-span">{it.gridcode}</span>}
                    {multiScene && <span className="left-sidebar__favitem__row4-in-right-span">{TranslateUtils.format(store.trans["{0} scenes"], si.items.length)}</span>}
                    <div className="pointer right_row_icon_iconbox left-sidebar__favitem__hidden_right" onClick={this.toCenter}>
                        <CenterSVG className="settings-pref"/>
                    </div>
                </div>
            </div>
        </div>;
    }
}
