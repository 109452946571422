import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './ActiveSensorDay.scss';
import {action} from "mobx";
import {Export1OrderType} from "../../../../store/export/ExportStore";
import {SatelliteExportComp} from "../ExportPanel/SatelliteExportComp";
import {SpatialButtonsComp} from "../ParamSearchPanel/SpatialButtonsComp";
import {ReactDoubleDatePicker2Comp} from "../../../Common/ReactDoubleDatePicker2Comp";
import {FavoriteExportStore} from "../../../../store/export/FavoriteExportStore";
import {ra} from "../../../../helper/utils/mobxUtils";
import AOIButtonsComp from '../ParamSearchPanel/AOIButtonsComp';

interface IExport2LandsatComp extends IStoreProps{
    satExport: FavoriteExportStore;
}
@observer
export class Export2LandsatComp extends React.Component<IExport2LandsatComp, undefined> {
    constructor(props: IExport2LandsatComp) {
        super(props);
        autoBindReact(this);
        if (this.props.store.exportStore.email == ""){
            this.props.store.exportStore.email = this.props.store.user.curEmail;
        }
    }

    @action
    async onClickExport(){
        let store = this.props.store;
        if (!this.props.satExport.canExport()) return;
        try {
            await this.props.satExport.sendOrder();
            ra(()=>{
                store.addInfo(store.trans.Send);
            });
        }catch (err){
            store.addError(err);
        }
    }

    render() {
        let store = this.props.store;
        return <div className="ActiveSensorDay-rows">
            <SatelliteExportComp
                satExport={this.props.satExport}
                isFusion={false}
                isMosaic={false}
                store={store}
                beforeCheckbox={
                    <React.Fragment>
                        <div className="ActiveSensorDay-separatorSimple" style={{marginBottom: "5px"}}/>
                    <div className="flex-column" style={{marginBottom: "15px"}}>
                        <div className="flex-input-title">
                            {/* <div className="calendar_table margin-right-15 gray"/> */}
                            {store.trans.Period}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                        <div className="flex-input-value">
                            <ReactDoubleDatePicker2Comp store={store} interval={store.exportStore.period}/>
                        </div>
                    </div>
                    </React.Fragment>}
            >

                <div className="ActiveSensorDay-kv" style={{marginTop: "5px", marginBottom: "20px"}}>
                    <AOIButtonsComp store={store} aoiExists={store.map.searchObject.isNotEmpty} area={store.map.searchObject.areaStr}/>
                    {/* <div className="ActiveSensorDay-kvKey">
                        <div className="flex-stretch-item">
                            {store.trans["Area of Interest"]}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="ActiveSensorDay-kvValue">
                        <div className="flex-stretch-item flex-rows">
                            <SpatialButtonsComp onlyPolygonButtons={false}
                            activeArea store={store} className="ActiveSensorDay-spatialBox" classNameButton="ActiveSensorDay-spatialButton"
                            notAccent={true} />
                        </div>
                    </div> */}
                </div>

            </SatelliteExportComp>
            <div className="ActiveSensorDay-bottomDiv"style={{flexDirection:'column'}}>
            <div className="popup-pref-in__separator" style={{marginLeft:'-25px',width:'140%',marginRight:'-25px'}}/>
                <button onClick={this.onClickExport}
                        disabled={!this.props.satExport.canExport()}
                        className="large-button left-sidebar-active button-jump ActiveSensorDay-bottomButton">
                    {store.trans.Run}
                </button>

            </div>
        </div>;
    }
}
