import * as React from 'react';
import {CustomSvg} from "../../../../icons/CustomSvg";

export class PenSvg extends CustomSvg {

    render() {
        return <svg width="12" height="12" viewBox="0 0 12 12" className={this.getClasses()} style={this.getStyles()} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.075 2.625L0.225 8.475C0.075 8.625 0 8.775 0 9V11.25C0 11.7 0.3 12 0.75 12H3C3.225 12 3.375 11.925 3.525 11.775L9.375 5.925L6.075 2.625Z" stroke="none" />
            <path d="M11.775 2.475L9.525 0.225C9.225 -0.075 8.775 -0.075 8.475 0.225L7.125 1.575L10.425 4.875L11.775 3.525C12.075 3.225 12.075 2.775 11.775 2.475Z" stroke="none" />
        </svg>;
    }
}
