import * as React from 'react';
import {CSSProperties} from 'react';
import {IIconProp} from "./IIconProp";
import autoBindReact from "auto-bind/react";

export class CustomSvg extends React.PureComponent<IIconProp, undefined> {
    constructor(props: IIconProp) {
        super(props);
        autoBindReact(this);
    }

    public getClasses(): string{
        return this.props.className??"";
    }
    public getStyles(): CSSProperties{
        return this.props.style??{};
    }


}
