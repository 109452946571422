import {CustomStore} from "./CustomStore";
import {action, computed, observable} from "mobx";
import {LoadStatus} from "../helper/structs/LoadStatus";
import {SearchItemStore} from "./SearchItemStore";
import {SearchItemGroup} from "./SearchItemGroup";
import {Utils} from "../helper/utils/Utils";
import {save} from "./PermalinkDecor";
import {SyncUtils} from "../helper/utils/SyncUtils";
import { cloneDeep } from "lodash-es";
import { v4 } from "uuid";
import { CheckStates } from "../helper/structs/CheckStates";
import { ActiveProductCodeStore } from "./productSetting/ActiveProductCodeStore";

export class FavoritesListSceneStore extends CustomStore{
    class(): string {return "FavoritesListSceneStore";}
    //*************** состояние выборки
    @observable
    searchTotalState: LoadStatus = LoadStatus.ready;
    @observable
    searchState: LoadStatus = LoadStatus.ready;
    @save @observable
    totalRecords: number = 0;//сколько результатов в теории
    @save @observable
    totalRecordsDates: number = 0;//сколько результатов в теории по датам

    @save @observable
    groups: SearchItemGroup[] = [];
    @observable
    updateCounter: number = 0;
    @save @observable
    grouped: boolean = true;
    @save @observable
    autoSort: boolean = false;

    @save @observable
    savedProductSettings: ActiveProductCodeStore = null;

    @computed
    get currentItems():SearchItemStore[]{
        let g = this.groups.find(a => a.isCurrent());
        if (g == null) return [];
        return g.items;
    }

    @computed
    get records(): SearchItemStore[]{
        let r: SearchItemStore[] = [];
        for(let i = 0; i < this.groups.length; i++){
            r = r.concat(this.groups[i].items);
        }
        return r;
    }

    @action
    syncAddSceneItem(arr: SearchItemStore[]) {
        const idDic = new Set(arr.map(a => a.sceneId()));
    
        const groupMap = new Map<string, SearchItemGroup>();
        this.groups.forEach(group => {
            group.items.forEach(item => {
                groupMap.set(item.sceneId(), group);
            });
        });

        for (const item of arr) {
            const sceneId = item.sceneId();
            if (!groupMap.has(sceneId)) {
                this.addSceneItem(item, false);
                groupMap.set(sceneId, this.groups[0]);
            }
        }
        const forDel = this.records.filter(a => !idDic.has(a.sceneId()));
        forDel.forEach(a => this.removeSceneItem(a));
        this.sortGroups();
    } 
    @action sortGroups(){
        if (!this.autoSort) return;
        this.groups = this.groups.slice().sort((a,b) => b.first().feature.properties.acqdate.localeCompare(a.first().feature.properties.acqdate))
    }
    @action convertToMosaic(){
        this.grouped = true;
        for(let i = this.groups.length - 1; i >= 0; i--){
            let g = this.groups[i];
            for(let i1 = i - 1; i1 >= 0; i1--){
                if (this.isGroupedScenes(g.first(), this.groups[i1].first(), false)){
                    let sis = g.items.slice(0);
                    this.removeManySceneItem(sis);
                    this.addManySceneItem(sis);
                    break;
                }
            }
        }
    }
    @action addManySceneItem(si: SearchItemStore[]){
        for(let i = 0; i < si.length; i++) this.addSceneItem(si[i], false);
        this.sortGroups();
    }

    @action appendNewSceneItems(si: SearchItemStore[]) {
        for(let i = 0; i < si.length; i++) this.addSceneItem(si[i], true);
        this.sortGroups();
    }

    @action
    private addSceneItem(si: SearchItemStore, addNew: boolean) {
        const root = this.root;
        if (this.grouped) {
            let sceneGroups = this.groups.filter(a => this.isGroupedScenes(a.first(), si, addNew));
            if (sceneGroups.length == 0) {
                let targetGroup = new SearchItemGroup(root);
                sceneGroups.push(targetGroup);
                this.groups.unshift(targetGroup);
            }
            for (let g of sceneGroups) {
                //ignore existing
                if (g.items.filter(itm => itm.sceneId() == si.sceneId()).length > 0)
                    continue;
                let cpy = new SearchItemStore(this.root);
                cpy.feature = cloneDeep(si.feature);
                cpy.copyId = g.copyId;
                cpy.selected = g.getSelected() != CheckStates.unchecked;
                g.items.unshift(cpy); //push ?   
            }
        } else {
            let targetGroup = new SearchItemGroup(root);
            targetGroup.items.unshift(si);
            this.groups.unshift(targetGroup);
        }
    }
    isGroupedScenes(si1: SearchItemStore, si2: SearchItemStore, addNew: boolean): boolean{
        return (si1.feature.properties.acqdate == si2.feature.properties.acqdate) && 
            (si1.feature.properties.satellite == si2.feature.properties.satellite) && 
            (addNew || si1.copyId == si2.copyId);
    }

    @action duplicateGroup(sg: SearchItemGroup) {
        let copyId = v4();
        let targetGroup = new SearchItemGroup(this.root);
        targetGroup.copyId = copyId;
        sg.items.forEach(si => {
            let cpy = new SearchItemStore(this.root);
            cpy.feature = cloneDeep(si.feature);
            cpy.copyId = copyId;
            cpy.selected = true;
            targetGroup.items.unshift(cpy); //push ?
        });
        this.groups.unshift(targetGroup); //push ?
        this.root.map.productInfo.productsPassive.duplicateFavoriteGroupProduct(sg.groupId(true), targetGroup.groupId(true));
        this.totalRecords = this.groups.length;
    }

    @action copyProductSettings(sg: SearchItemGroup) {
        this.savedProductSettings = this.root.map.productInfo.productsPassive.getGroupProduct(sg.groupId(true), true);
    }

    @action applyProductSettings(sg: SearchItemGroup) {
        this.root.map.productInfo.productsPassive.applyFavoriteGroupProduct(sg.groupId(true), this.savedProductSettings);
        this.updateCounter++;
    }

    @action removeManySceneItem(si: SearchItemStore[]){
        for(let i = 0; i < si.length; i++) this.removeSceneItem(si[i]);
    }
    
    getGroup(groupId: string) {
        return this.groups.find(g => g.groupId(true) == groupId);
    }
    
    findGroup(sceneId: string): SearchItemGroup{
        for(let i = 0; i < this.groups.length; i++){
            //только выбранные
            if (this.groups[i].getSelected() == CheckStates.unchecked)
                continue;
            let d2 = this.groups[i].items.find(a => a.sceneId() === sceneId);
            if (d2 != null) return this.groups[i];
        }
        return null;
    }

    findItem(sceneId: string): SearchItemStore{
        let grp = this.findGroup(sceneId);
        if (grp == null) return null;
        let item = grp.items.find(a => a.sceneId() == sceneId);
        return item;
    }
    @action
    removeSceneItem(si: SearchItemStore) {
        for (let i = 0; i < this.groups.length; i++) {
            const group = this.groups[i];
            const itemIndex = group.items.findIndex(a => a.sceneId() === si.sceneId());
    
            if (itemIndex >= 0) {
                group.items.splice(itemIndex, 1);
                if (group.items.length === 0) {
                    this.groups.splice(i, 1);
                }
                //return;
            }
        }
    }

    getAllSceneItems(): SearchItemStore[]{
        let r: SearchItemStore[] = [];
        this.groups.forEach(a => {a.items.forEach(b => r.push(b))});
        return r;
    }

    @action setCountResult(count: number, count_dates: number){
        this.totalRecords = count;
        this.totalRecordsDates = count_dates;
        this.searchTotalState = LoadStatus.ready;
    }

    areAllSelected(): boolean{
        if (this.records.length == 0) return false;
        for(let i = 0; i < this.records.length; i++){
            if (!this.records[i].selected) return false;
        }
        return true;
    }

    isAnySelected(): boolean {
        for(let i = 0; i < this.records.length; i++){
            if (this.records[i].selected) return true;
        }
        return false;
    }

    @action inversSelectAll(){
        let all = this.areAllSelected();
        for (let i = 0; i < this.records.length; i++) {
            this.records[i].selected = !all;
            //this.root().map.setLayerVisibility(this.records[i].sceneId(), !all);
        }
    }
}
