import React from "react";

export interface IAddAOISvgProps {
    minimized: boolean
}

const AddAOISvg = (props: IAddAOISvgProps) => {
    return props.minimized? 
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" fill="#FFCD5F" fillOpacity="0.2" stroke="#FFCD5F" strokeLinejoin="round"/>
            <rect x="6.22241" y="3.88892" width="1.55556" height="6.22222" rx="0.777778" fill="#FFCD5F"/>
            <rect x="3.88867" y="7.77783" width="1.55556" height="6.22222" rx="0.777778" transform="rotate(-90 3.88867 7.77783)" fill="#FFCD5F"/>
        </svg>
        :
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill="#FFCD5F" fillOpacity="0.2" stroke="#FFCD5F" strokeLinejoin="round"/>
            <rect x="8" y="5" width="2" height="8" rx="1" fill="#FFCD5F"/>
            <rect x="5" y="10" width="2" height="8" rx="1" transform="rotate(-90 5 10)" fill="#FFCD5F"/>
        </svg>;
}

export default AddAOISvg;

