// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchSceneButtonsComp-maskTitle {
  font-weight: normal;
  font-size: 10px;
  line-height: 7px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.SearchSceneButtonsComp-maskSlider {
  width: 82px;
  height: 36px;
}

.SearchSceneButtonsComp-maskKeyValue {
  min-width: 82px;
  padding-right: 20px;
}

.SearchSceneButtonsComp-export {
  margin-right: 10px;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/SearchPanel/SearchSceneButtonsComp.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EAEA,mBAAA;EACA,mBAAA;AAAF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AACA;EACE,eAAA;EACA,mBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;AAEF","sourcesContent":[".SearchSceneButtonsComp-maskTitle{\r\n  font-weight: normal;\r\n  font-size: 10px;\r\n  line-height: 7px;\r\n//  margin-top: 15px;\r\n  margin-bottom: 10px;\r\n  white-space: nowrap;\r\n}\r\n.SearchSceneButtonsComp-maskSlider{\r\n  width: 82px;\r\n  height: 36px;\r\n}\r\n.SearchSceneButtonsComp-maskKeyValue{\r\n  min-width: 82px;\r\n  padding-right: 20px;\r\n}\r\n.SearchSceneButtonsComp-export{\r\n  margin-right: 10px;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  border-radius: 18px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  //opacity: 0.3;\r\n  width: 36px;\r\n  height: 36px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
