import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './VoronovSaveComp.scss';
import {action} from 'mobx';
import {ra} from "../../../../app/helper/utils/mobxUtils";
import {IAgroExportStoreProps} from "../../agroExportPlugin";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {ExportType} from "../../store/VoronovSaveStore";
import classNames from "classnames";

@observer
export class VoronovSaveComp extends React.Component<IAgroExportStoreProps, undefined> {
    constructor(props: IAgroExportStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickCancel(){
        this.props.store.agroExport.voronovExportStore.saveOptions.showSaveForm = false;
    }
    @action
    async onClickOk(){
        if (!this.canSave()) return;
        try {
            await this.props.store.agroExport.voronovExportStore.saveOptions.sendSave();
            ra(()=>{
                this.props.store.agroExport.voronovExportStore.saveOptions.showSaveForm = false;
                this.props.store.agroExport.voronovExportStore.numberOfSaves += 1;  // анимация иконки "анализ почвы"
            });
        } catch (err){
            this.props.store.addError(err);
        }
    }

    canSave(){
        let so = this.props.store.agroExport.voronovExportStore.saveOptions;
        return (so.exportType == ExportType.New && so.saveTitle) ||
            (so.exportType == ExportType.Exist && !!so.asaMapId);
    }

    @action
    onChangeTitle(e: any){
        let store = this.props.store;
        let so = store.agroExport.voronovExportStore.saveOptions;
        so.saveTitle = e.currentTarget.value;
    }

    onChangeType(item: IDropDownItem){
        let so = this.props.store.agroExport.voronovExportStore.saveOptions;
        so.exportType = item.key as ExportType;
    }

    onChangeAsaMap(item: IDropDownItem){
        let so = this.props.store.agroExport.voronovExportStore.saveOptions;
        so.asaMapId = item.key as number;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroExport;
        let so = store_.voronovExportStore.saveOptions;
        let pathItems: any[] = [];
        so.paths.forEach((v, index) => {
            if (index > 0){
                pathItems.push(<span className="VoronovSaveComp-pathArrow" key={v+"_arrow"}><svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6.00098L4 3.00098L0 0.000976562V6.00098Z" fill="#6B707B"/></svg></span>);
            }
            pathItems.push(<span className="" key={v}>{v}</span>);
        });
        let items: IDropDownItem[] = [
            {key: ExportType.New, value: store_.trans['Save new ASA maps']},
            {key: ExportType.Exist, value: store_.trans['Save to existing ASA maps']}
        ];
        let asaMaps:IDropDownItem[] = so.asaMaps.map(m=>{return {key: m.map_id, value: m.map_name}});

        return <div className="modal-back flex-full-center">
            <div className="modal-min modal-full-frame  VoronovSaveComp-form">
                <div className="VoronovSaveComp-title">Сохранение карты отбора проб (КОП)</div>
                <div className="VoronovSaveComp-subTitle">
                    <div className="VoronovSaveComp-path">
                        {pathItems}
                    </div>
                </div>
                <div className="VoronovSaveComp-statistic">
                    {store.trans.ObjectsFields}: {so.fieldsCount}, {store.trans["cells"]}: {so.parent.geoJsonCells?.data?.features?.length}
                </div>
                <div className="VoronovSaveComp-line" />
                {/* >>> */}
                <div className="VoronovSaveComp-actionCover">
                    <div className="VoronovSaveComp-name">
                        {store.trans.Action}
                    </div>
                    <div className="VoronovSaveComp-Input">
                        <div className="ProductItemExportComp-groupKeyValue">
                            <DropDownComp items={items} currentKey={so.exportType} onChange={this.onChangeType}
                                className="ProductItemExportComp-exportType" />
                        </div>
                    </div>
                </div>
                {/* <<< */}
                {so.exportType == ExportType.New && <div className="VoronovSaveComp-nameCover">
                    <div className="VoronovSaveComp-name">
                        {store.trans.Name}<span className="blue-text text-next-half">*</span>
                    </div>
                    <div className="VoronovSaveComp-Input">
                        <input type="text" className="text-box-editor width100" onChange={this.onChangeTitle}
                               value={so.saveTitle} placeholder={store_.trans['ASA map name']} />
                    </div>
                </div>}
                {so.exportType == ExportType.Exist && <div className="VoronovSaveComp-existListCover">
                    <div className="VoronovSaveComp-name">
                        {store.trans.Name}<span className="blue-text text-next-half">*</span>
                    </div>
                    <div className="VoronovSaveComp-Input">
                        <div className={classNames("VoronovSaveComp-groupKeyValue", {
                            "VoronovSaveComp-placeHolder": !so.asaMapId})}>
                            <DropDownComp items={asaMaps} placeHolder={store_.trans['ASA map name']}
                                currentKey={so.asaMapId} onChange={this.onChangeAsaMap}
                                className="VoronovSaveComp-exportType" placeHolderSearch={store_.trans['Search by ASA map']}/>
                        </div>
                    </div>
                </div>}
                <div className="VoronovSaveComp-line" />
                <div className="VoronovSaveComp-bottom">
                    <button className="left-sidebar-cancel large-button button-jump VoronovSaveComp-button text-next-2" onClick={this.onClickCancel}>
                        {store.trans.Cancel}
                    </button>
                    <button className={classNames(
                        "left-sidebar-active large-button VoronovSaveComp-button text-next-2", {
                            "button-jump": this.canSave(),
                            "VoronovSaveComp-disabled": !this.canSave()
                        })} onClick={this.onClickOk} >
                        {store.trans.Save}
                    </button>
                </div>
            </div>
        </div>;
    }
}
