import classNames from "classnames";
import React from "react";
import './ClassLegendComp.scss'

export interface ILegendColorDescription {
    min: number;
    max: number;
    color: string;
    description: string;
}

interface IClassLegendProps {
    classes: ILegendColorDescription[];    
}

const ClassLegendComp = (props: IClassLegendProps) => {
    let arr: any[] = [];
    let len = props.classes.length;
    if (len == 0) return null;
    if (len == 1) {}
    else {
        props.classes.forEach((cls, idx) => {
            let idxReal = (len - 1) - idx;
            let txtMax: string = Number.isNaN(cls.max)? "" : cls.max.toFixed(1);
            if (idx == 0) txtMax = "";
            //let c = getColor(a);

            arr.push(<React.Fragment key={idx}>
                <tr className="LegendDescreteArea-tableRow">
                    <td>
                        <div className="ClassLegendComp-tableCol2Text">{txtMax}</div>
                    </td>
                    <td className="LegendDescreteArea-tableCell1">
                        <div className="LegendDescreteArea-tableCell1Div">
                            <div data-id={idxReal.toString()}
                                className={classNames("LegendDescreteArea-ballon",
                                {"ClassLegendComp-ballon-top": idx == 0,
                                    "ClassLegendComp-ballon-middle": idx > 0 && idx < (len - 1),
                                    "ClassLegendComp-ballon-bottom": idx == len - 1,
                                    })}
                                  style={{background: cls.color}}>
                            </div>
                            {idx != len -1 && <div className="LegendDescreteArea-ballon-BottomLine ClassLegendComp-ballon-absLines"/>}
                        </div>
                    </td>
                    <td>
                        <div className="ClassLegendComp-tableCol3Text">{cls.description}</div>
                    </td>
                </tr>
            </React.Fragment>);
        })
    }
    return <table className="ClassLegendComp-table">
        <colgroup>
            <col className="ClassLegendComp-tableCol1"/>
            <col className="ClassLegendComp-tableCol2"/>
            <col className="ClassLegendComp-tableCol3"/>
        </colgroup>
        <tbody>
        {arr}
        </tbody>
    </table>;
}

export default ClassLegendComp;