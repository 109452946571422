import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";

export interface IConfirmFormCompProps {
    text: string,
    className?: string,
    classNameText?: string,
}

export class ConfirmFormComp extends React.PureComponent<IConfirmFormCompProps, undefined> {
    constructor(props: IConfirmFormCompProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <div className={classNames(this.props.className)} style={{background: "#2B3138", borderRadius: "6px", border: "1px solid #3E4751",
            padding:"10px 15px", fontSize: "12px", lineHeight: "16px"}}>
            <div className={classNames("white", this.props.classNameText)}> {this.props.text}</div>
            <div>
                {this.props.children}
            </div>
        </div>;
    }
}
