import * as React from 'react';
import {CSSProperties} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IProductItemComp} from "./ProductItemComp";
import {Utils} from "../../../../helper/utils/Utils";
import {Interpolation} from "../../../../store/productSetting/IndexBandProductStore";
import {ColorHelper} from "../../../../helper/utils/ColorHelper";

@observer
export class ProductItemIndexBandColorsComp extends React.Component<IProductItemComp, undefined> {
    constructor(props: IProductItemComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let ib = this.props.item.indexBand;
        let colors = this.props.item.indexBand.colors.colors;
        let arr: any[] = [];
        let backStyle: CSSProperties = {};
        let backGradient: string = "linear-gradient(90deg";
        if (ib.interpolation == Interpolation.linear && Utils.parseInt(ib.classes) != null) {
            for (let i = 0; i < colors.length; i++) {
                let kv = colors[i];
                function getK(index: number): number {
                    return (index / (colors.length - 1));
                }
                backGradient += `, ${ColorHelper.ColorRGBAtoCSS(kv.color)} ${getK(i) * 100}% `;

                let circleStyle: CSSProperties = {};
                circleStyle.background = `linear-gradient(${ColorHelper.ColorRGBAtoCSS(kv.color)}, ${ColorHelper.ColorRGBAtoCSS(kv.color)}), url('./img/chess.png')`;
                circleStyle.left = `calc(${getK(i) * 100}% - 7px)`;
            }
        }
        if (ib.interpolation == Interpolation.discrete && Utils.parseInt(ib.classes) != null){
            let dia = ib.getDiscreteClassValuesValidsInterval();
            let min = Utils.parseNumber(ib.min)??0;
            let max = Utils.parseNumber(ib.max)??0;
            let allSize = (max - min);
            for(let i = 0; i < dia.length; i++){
                let di = dia[i];
                backGradient += `, ${ColorHelper.ColorRGBAtoCSS(di.color)} ${((di.min - min) / allSize) * 100}%`;
                backGradient += `, ${ColorHelper.ColorRGBAtoCSS(di.color)} ${((di.max - min) / allSize) * 100}%`;
            }
        }

        backGradient += "), url('./img/chess.png')";
        backStyle.background = backGradient;


        return <div style={backStyle} className="ProductItemIndexBandColorsComp-back-m" >
            {arr}
        </div>;
    }
}
