import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './ShowRoom2TopFilters.scss';
import {action} from "mobx";
import {ShowRoom2TopFilterPopupItem} from "./ShowRoom2TopFilterPopupItem";
import {DataShowRoomMode} from "../../../../store/dataShowroom2/ShowRoom2Store";

@observer
export class ShowRoom2TopFilters extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    getSeasonSvg(): any{
        return <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg" className="text-prev">
            <path d="M6.1875 0H4.8125V1.375H6.1875V0Z"/>
            <path d="M9.82721 2.09798L8.85522 1.12531L7.88255 2.09729L8.85454 3.06997L9.82721 2.09798Z"/>
            <path d="M11 4.8125H9.625V6.1875H11V4.8125Z"/>
            <path d="M7.9304 8.95086L8.90308 9.92285L9.87506 8.95018L8.90239 7.97819L7.9304 8.95086Z"/>
            <path d="M6.1875 9.625H4.8125V11H6.1875V9.625Z" />
            <path d="M1.07684 8.90208L2.04883 9.87476L3.0215 8.90277L2.04952 7.93009L1.07684 8.90208Z"/>
            <path d="M1.375 4.8125H0V6.1875H1.375V4.8125Z"/>
            <path d="M3.06935 2.14521L2.09668 1.17322L1.12469 2.14589L2.09737 3.11788L3.06935 2.14521Z"/>
            <path d="M5.5 2.75C3.9875 2.75 2.75 3.9875 2.75 5.5C2.75 7.0125 3.9875 8.25 5.5 8.25C7.0125 8.25 8.25 7.0125 8.25 5.5C8.25 3.9875 7.0125 2.75 5.5 2.75Z"/>
        </svg>;
    }

    getProjectSvg(): any{
        return <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg" className="text-prev">
            <path d="M9.62495 1.09903e-05H1.37495C1.28323 -0.00550251 1.19138 0.00850303 1.10547 0.0411029C1.01956 0.0737027 0.941543 0.124155 0.876568 0.189129C0.811594 0.254104 0.761142 0.332121 0.728542 0.418031C0.695942 0.503941 0.681936 0.595789 0.68745 0.687511V10.3125C0.681936 10.4042 0.695942 10.4961 0.728542 10.582C0.761142 10.6679 0.811594 10.7459 0.876568 10.8109C0.941543 10.8759 1.01956 10.9263 1.10547 10.9589C1.19138 10.9915 1.28323 11.0055 1.37495 11H6.87495L10.3125 7.56251V0.687511C10.318 0.595789 10.304 0.503941 10.2714 0.418031C10.2388 0.332121 10.1883 0.254104 10.1233 0.189129C10.0584 0.124155 9.98034 0.0737027 9.89443 0.0411029C9.80852 0.00850303 9.71667 -0.00550251 9.62495 1.09903e-05ZM2.06245 1.37501H8.93745V6.87501H6.18745V9.62501H2.06245V1.37501Z"/>
            <path d="M7.5625 2.75H3.4375V3.4375H7.5625V2.75Z"/>
            <path d="M7.5625 4.8125H3.4375V5.5H7.5625V4.8125Z" />
            <path d="M4.8125 6.875H3.4375V7.5625H4.8125V6.875Z"/>
        </svg>;
    }
    getCropSvg():any{
        return <svg width="7" height="11" viewBox="0 0 7 11" xmlns="http://www.w3.org/2000/svg" className="text-prev">
            <path d="M3.5 8.25C3.5 7.33832 3.13784 6.46398 2.49318 5.81932C1.84852 5.17466 0.974182 4.8125 0.0625001 4.8125V5.5C0.0623383 6.29245 0.335976 7.06062 0.837102 7.6745C1.33823 8.28837 2.03606 8.71026 2.8125 8.86875V11H4.1875V8.86875C4.96394 8.71026 5.66177 8.28837 6.1629 7.6745C6.66402 7.06062 6.93766 6.29245 6.9375 5.5V4.8125C6.02582 4.8125 5.15148 5.17466 4.50682 5.81932C3.86216 6.46398 3.5 7.33832 3.5 8.25Z"/>
            <path d="M3.5 4.8125C3.88852 4.53366 4.21243 4.17442 4.44969 3.7592C4.68696 3.34399 4.83202 2.88254 4.875 2.40625C4.83202 1.92996 4.68696 1.46851 4.44969 1.0533C4.21243 0.638084 3.88852 0.278839 3.5 0C3.11148 0.278839 2.78757 0.638084 2.55031 1.0533C2.31304 1.46851 2.16798 1.92996 2.125 2.40625C2.16798 2.88254 2.31304 3.34399 2.55031 3.7592C2.78757 4.17442 3.11148 4.53366 3.5 4.8125Z" />
        </svg>;
    }

    @action
    onDeleteCrops(){
        let store = this.props.store;
        store.dataShowRoomStore.filtersMultiproject.cropFilters.clear();
    }

    @action
    onDeleteSeasons(){
        let store = this.props.store;
        store.dataShowRoomStore.filtersMultiproject.seasonsFilters.clear();
    }

    @action
    onDeleteDistricts(){
        let store = this.props.store;
        store.dataShowRoomStore.filtersProject.filter_districts_ids.clear();
    }

    render() {
        let store = this.props.store;
        let arr: any[] = [];
        if (store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Projects) {
            if (store.dataShowRoomStore.filtersProject.filter_districts_ids.size > 0){
                let chld: any[] = [];
                store.dataShowRoomStore.filtersProject.districts.forEach(a => {
                    if (store.dataShowRoomStore.filtersProject.filter_districts_ids.has(a.id)) {
                        chld.push(<div key={a.id}>{a.distrname}</div>);
                    }
                });
                arr.push(<ShowRoom2TopFilterPopupItem key="Districts" text={"Districts: "+chld.length.toString()} hasChilds={chld.length > 0} store={store}
                                                      onDelete={this.onDeleteDistricts}
                                                      svg={this.getProjectSvg()}>{chld}</ShowRoom2TopFilterPopupItem>);

            }
        }
        if (store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Multiyear) {
            if (store.dataShowRoomStore.filtersMultiproject.cropFilters.size > 0) {
                let chld: any[] = [];
                store.dataShowRoomStore.cropDataShort.forEach(a => {
                    if (store.dataShowRoomStore.filtersMultiproject.cropFilters.has(a.id)) {
                        chld.push(<div key={a.id}>{a.name}</div>);
                    }
                });
                if (store.dataShowRoomStore.filtersMultiproject.otherCropChecked) {
                    chld.push(<div key="other">Other</div>);
                }
                arr.push(<ShowRoom2TopFilterPopupItem key="crop" text={"Crops: "+chld.length.toString()} hasChilds={chld.length > 0} store={store}
                                                      onDelete={this.onDeleteCrops}
                                                      svg={this.getCropSvg()}>{chld}</ShowRoom2TopFilterPopupItem>);
            }

            if (store.dataShowRoomStore.filtersMultiproject.seasonsFilters.size > 0) {
                let chld: any[] = [];
                store.dataShowRoomStore.seasons.forEach(a => {
                    if (store.dataShowRoomStore.filtersMultiproject.seasonsFilters.has(a.id)) {
                        chld.push(<div key={a.id}>{a.name}</div>);
                    }
                });
                arr.push(<ShowRoom2TopFilterPopupItem key="seasons" text={"Seasons: "+chld.length.toString()} hasChilds={chld.length > 0}
                                                      store={store} onDelete={this.onDeleteSeasons}
                                                      svg={this.getSeasonSvg()}>{chld}</ShowRoom2TopFilterPopupItem>);
            }
        }

        return <div className="ShowRoom2TopFilters-div">{arr}</div>;
    }
}
