import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {SoilGridsGroupStore} from "../../../../store/solidGrids/SoilGridsGroupStore";
import {SoilGridsSourceComp} from "./SoilGridsSourceComp";

export interface ISoilGridsGroupComp extends IStoreProps{
    group: SoilGridsGroupStore
}

@observer
export class SoilGridsGroupComp extends React.Component<ISoilGridsGroupComp, undefined> {
    constructor(props: ISoilGridsGroupComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let items: any[] = [];
        let group = this.props.group;
        group.sources.forEach((a, index) => {
            items.push(<SoilGridsSourceComp store={store} source={a} key={index} />);
        });
        return <div className="SoilGridsGroup-main">
            <div className="SoilGridsGroup-title">
                {group.title}
            </div>
            {items}
        </div>;
    }
}
