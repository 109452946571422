import {CustomStore} from "./CustomStore";
import {FeatureCollection} from "geojson";
import {action, computed, observable, toJS} from "mobx";
import {fetchJsonPost} from "../helper/utils/FetchUtils";
import {save} from "./PermalinkDecor";
import {Geometry, point} from "@turf/helpers";
import bboxPolygon from "@turf/bbox-polygon";
import area from "@turf/area";
import length from "@turf/length";
import bbox from "@turf/bbox";
import distance from "@turf/distance";
import {LoadStatus} from "../helper/structs/LoadStatus";
import {ra} from "../helper/utils/mobxUtils";
import union from "@turf/union";
import {SearchGeomManager} from "./toolController/SearchGeomManager";
import {Utils} from "../helper/utils/Utils";

export class SearchObjectStore extends CustomStore{
    class(): string {return "SearchObjectStore";}

    public static readonly MaxSideForSearchKm = 400;//километры
    public geometryEditManager: SearchGeomManager = new SearchGeomManager(this);

    @save @observable
    private _content: FeatureCollection = null;
    @save @observable
    geom_id_saved: number = null;
    @observable
    geometry_save_status: LoadStatus = null;

    @save @observable
    fieldIds: number[] = [];
    @save @observable
    fieldDataset: string = null;
    @save @observable//геометрия задана пользователем, а не дефолтная из проекта
    user_defined: boolean = false;

    @computed
    get content():FeatureCollection{
        return toJS(this._content);
    }
    set content(value: FeatureCollection){
        if (value != null){
            if (!value.features && (value as any)["Features"]){
                value.features = (value as any)["Features"];
            }
        }
        this.user_defined = true;
        this._content = value;
        this.geom_id_saved = null;
        this.geometry_save_status = null;
        this.fieldDataset = null;
        this.fieldIds = [];
    }
    @action
    clear(){
        this.content = null;
        //this.root.searchPanel.switchSceneMosaic(SceneMosaicSwitch.scene);
    }
    @action
    async clearAndSetDefault(){
        this.content =  null;
        this.fieldDataset = null;
        this.fieldIds = [];
        await this.root.agro2.projectList.setDefaultActiveArea();
    }
    @computed
    get singleGeometry(): Geometry{
        if (this.isNotEmpty) {
            if (this.content.features.length == 1) return this.content.features[0].geometry as Geometry;
            let polygon = this.content.features.find(a => a.geometry != null && (a.geometry.type == "MultiPolygon" || a.geometry.type == "Polygon")).geometry;
            if (polygon != null){
                polygon = null;
                this.content.features.filter(a => a.geometry != null && (a.geometry.type == "MultiPolygon" || a.geometry.type == "Polygon")).map(a => {
                    if (polygon == null) {polygon = a.geometry;} else {
                        polygon = union(polygon as any, a.geometry as any).geometry as any;
                    }
                });
                return polygon as Geometry;
            }
            return this.content.features[0].geometry as Geometry;
        }
        return null;
    }

    get searchGeometry(): Geometry{
        if (this.singleGeometry != null)
            return this.singleGeometry;

        let b = this.root.map.searchBounds;
        if (b.isEmpty()) return null;
        return bboxPolygon([b.ne.lng, b.ne.lat, b.sw.lng, b.sw.lat]).geometry;
    }

    @observable
    visible: boolean = true;
    @save @observable
    name: string = null;

    @computed
    get areaContent(): number{
        if (this.isEmpty) return 0;
        return area(this.searchGeometry);
    }
    @computed
    get getMaxSideLengthKm(): number{
        if (this.isEmpty) throw "getMaxSide geometry is empty";
        let box = bbox(this.searchGeometry);//minX 0, minY 1, maxX 2, maxY 3
        let values: number[] = [];
        values.push(dist(box[0], box[1], box[0], box[3]));
        values.push(dist(box[2], box[1], box[2], box[3]));

        values.push(dist(box[0], box[3], box[2], box[3]));
        values.push(dist(box[0], box[1], box[2], box[1]));

        function dist(x1: number, y1: number, x2: number, y2: number): number{
            let p1 = point([x1, y1]);
            let p2 = point([x2, y2]);
            return distance(p1, p2, {units: "kilometers"});
        }
        values = values.sort();
        return values[0];
    }


    @computed
    get getAreaStr(){
        let ms = this.root.map;
        let info: string = '';
        if (ms.searchObject.isEmpty) return "";
        let fs = ms.searchObject.content.features;
        if (fs.length == 1 && fs[0].geometry.type == "Point")
            info = "0 "+this.root.trans["m"];// Utils.coosToString(fs[0].geometry.coordinates[0], fs[0].geometry.coordinates[1], ms.coosFormat);
        else {
            let sqValue = area(ms.searchObject.content);
            if (sqValue > 0.0) {
                info = sqValue > 100000.0 ? //0.1 km2
                    (sqValue / 1000000.0).toFixed(1) + ' '+this.root.trans["km²"]+' ':
                    sqValue.toFixed(0) + ' '+this.root.trans["m²"];
            } else {

                let lenValue = length(ms.searchObject.content);
                info = lenValue > 1 ?
                    lenValue.toFixed(1) + ' '+this.root.trans["km"] :
                    (lenValue * 1000).toFixed(0) + ' '+this.root.trans["m"];
            }
            info = Utils.formatNumberWithSpaces(info);
        }
        return info;
    }


    @computed
    get isEmpty(){
        return this.content == null;
    }
    @computed
    get isNotEmpty(){
        return !this.isEmpty;
    }

    saveGeometryToServer(){
        this.geometry_save_status = LoadStatus.loading;
        let p = {data: this.searchGeometry, type:"geom"};
        fetchJsonPost(`api/jsonput`, p).then((r)=>{
            ra(()=>{
                this.geom_id_saved = r.id;
                this.geometry_save_status = LoadStatus.ready;
            });
        }).catch((err)=>{this.root.addError(err)});
    }
}
