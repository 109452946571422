import autoBind from "auto-bind";
import {
    AddrContour,
    AddrPoint,
    ContourPoints,
    GeometryUtils,
    ISimpleGeometry,
    SimpleGeometryType
} from "../utils/GeometryUtils";
import {Position} from "geojson";
import {GActionType, IGActionGeometry, IGActionHole, IGActionPoint} from "./IGeometryAction";
import {cloneDeep} from "lodash-es";

export class GeometryActionCreator {
    static insertPoint(addrPoint: AddrPoint, pos: Position): IGActionPoint{
        return <IGActionPoint>{actionType: GActionType.insertPoint, addrPoint: addrPoint, newValue: pos};
    }
    static deletePoint(simpleGeometry: ISimpleGeometry[], addrPoint: AddrPoint): IGActionPoint{
        let p = GeometryUtils.getGeometryPoint2(simpleGeometry, addrPoint);
        return {actionType: GActionType.deletePoint, addrPoint: addrPoint, oldValue: p};
    }
    static movePoint(simpleGeometry: ISimpleGeometry[], addrPoint: AddrPoint, pos: Position): IGActionPoint{
        let p = GeometryUtils.getGeometryPoint2(simpleGeometry, addrPoint);
        return {actionType: GActionType.movePoint,
            addrPoint: addrPoint, newValue: pos, oldValue: p};
    }
    static insertHole(addrCont: AddrContour, hole: ContourPoints): IGActionHole{
        return {actionType: GActionType.insertHole, addrContour: addrCont, newValue: hole };
    }
    static deleteHole(simpleGeometry: ISimpleGeometry[], addrCont: AddrContour): IGActionHole{
        let t = GeometryUtils.getContourPoints(simpleGeometry, addrCont);
        return  {actionType: GActionType.deleteHole, addrContour: addrCont, oldValue: cloneDeep(t)};
    }
    static insertGeometry(index: number, sg: ISimpleGeometry): IGActionGeometry{
        return {actionType: GActionType.insertGeom, index: index, newValue: sg};
    }
    static deleteGeometry(simpleGeometry: ISimpleGeometry[], index: number): IGActionGeometry{
        let t = simpleGeometry[index];
        return  {actionType: GActionType.deleteGeom, index: index, oldValue: cloneDeep(t)};
    }

}