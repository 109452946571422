import {ObservableCustomStore} from "../CustomStore";
import {save, update} from "../PermalinkDecor";
import {A2ProjectStructStore} from "./A2ProjectStructStore";
import {action, autorun, observable, reaction, when} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {A2FieldFormStore} from "./fieldForm/A2FieldFormStore";
import {Feature, FeatureCollection, Geometry} from "geojson";
import {fetchJsonGet, fetchJsonPost} from "../../helper/utils/FetchUtils";
import {A2ProjectInfo} from "./A2ProjectInfo";
import {Utils} from "../../helper/utils/Utils";
import {A2UploadFormStore} from "./UploadFields/A2UploadFormStore";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {GeometryUtils} from "../../helper/utils/GeometryUtils";
import {IResponseFieldProperties} from "./AgroStore";
import {A2ConfirmExportStore} from "./Confirms/A2ConfirmExportStore";
import {A2ConfirmDeleteStore} from "./Confirms/A2ConfirmDeleteStore";
import {AgroEditManager} from "../toolController/AgroEditManager";
import {FieldToolController} from "./fieldForm/FieldToolController";
import {ProjType} from "../user/UserStore";
import {ZalogiStore} from "./Zalogi/ZalogiStore";
import {A2PermissionStore} from "./A2PermissionStore";
import {SuperStore} from "../SuperStore";
import {A2ProjectEditorStore} from "./A2ProjectEditorStore";
import {A2ProjectListStore} from "./A2ProjectListStore";
import {ProjectSettingStore} from "./ProjectSettingStore";
import {IA2NavigationItemValue} from "./A2NavigatorItemStore";
import {IQueryArguments} from "../../helper/utils/IQueryArguments";
import {A2FieldStyleStore} from "./A2FieldStyleStore";
import {ra} from "../../helper/utils/mobxUtils";
import {LeftPanelMode} from "../SearchStore";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import { A2ConfirmCreateStore } from "./Confirms/A2ConfirmCreateStore";

export interface IKeyColumn{
    value: any;
    column: string;
}
export enum A2NavigatorSection{
    projects='projects',
    folder='folder'
}

export enum A2SubModeLeftPanel{
    tree='tree',
    editOrCreateField='editOrCreateField',
}

export enum A2ExportFormat{
    geojson = 'geojson',
    csv = 'csv',
    shp ='shp',
    kml='kml'
}
export interface IGroupResult{
    obj_cnt: number;
    area_sys: number;
}

export class A2AgroStore extends ObservableCustomStore<SuperStore>{

    projectList: A2ProjectListStore = new A2ProjectListStore(this);

    projectEditor: A2ProjectEditorStore = new A2ProjectEditorStore(this);
    @update
    zalogi: ZalogiStore = new ZalogiStore(this);

    @update
    projectSettings: ProjectSettingStore = new ProjectSettingStore(this);

    @observable
    agroFieldVisible: boolean = true;

    @update
    styles: A2FieldStyleStore = new A2FieldStyleStore(this);
    @update
    projectStruct: A2ProjectStructStore = new A2ProjectStructStore(this);
    @save @observable
    navigatorSection = A2NavigatorSection.projects;
    @update
    projectInfo: A2ProjectInfo = new A2ProjectInfo(this);
    @observable
    subModeLeftPanel: A2SubModeLeftPanel = A2SubModeLeftPanel.tree;
    @observable
    hover_field_id: number;
    @observable
    hover_folder: IKeyColumn[] = null;

    @save @observable
    filterMapShowOnlyCurrentNavigatorPath: boolean = false;//показывать только поля для текущего уровня вложенности в левом навигаторе по полям

    editManager: AgroEditManager = new AgroEditManager(this);
    fieldToolController: FieldToolController = new FieldToolController(this);

    @observable
    tileFieldsContentVersion = "";

    fieldEditorForm: A2FieldFormStore = new A2FieldFormStore(this);

    uploadFieldsForm: A2UploadFormStore = new A2UploadFormStore(this);

    confirmExport: A2ConfirmExportStore = new A2ConfirmExportStore(this);
    confirmDelete: A2ConfirmDeleteStore = new A2ConfirmDeleteStore(this);
    confirmCreate: A2ConfirmCreateStore = new A2ConfirmCreateStore(this);

    permission: A2PermissionStore = new A2PermissionStore(this);

    @observable
    agroButtonPopupVisible: boolean = false;

    @save @observable
    filterSelectedChecked: boolean = false;


    subscription(): IReactionDisposer[] {
        return [
            autorun(()=>{
                if (this.tileFieldsContentVersion != "") {// следим за изменнением переменной и перезагружаем все панели агро
                    this.projectStruct.resetAllValues();
                }
            }),
            reaction(()=>{//сброс ховера папок агро, если что-то сменили
                return {a: this.projectStruct.activeNavigatorPage, b: this.projectStruct.activeNavigatorPage?.type, panel: this.root.searchPanel.leftPanelMode};
            }, ()=>{
                this.hover_folder = null;
            })
        ];
    }

    @action
    openCreateFieldPanel(){
        this.fieldEditorForm.initNewItem();
        this.fieldEditorForm.fillDefaultColumns();
        this.subModeLeftPanel = A2SubModeLeftPanel.editOrCreateField;
        this.fieldEditorForm.showFieldMultiEditPanel = false;
    }

    @action
    incVersionFieldTiles(){
        this.tileFieldsContentVersion = Utils.makeId();
    }

    getField(field_id: number): Promise<Feature>{
        let url = `/api/projects/${this.root.agro.projectName}/object/select`;
        let filter: any = {geom: 1, filter: {}};
        filter.filter[this.root.agro2.projectInfo.fieldName_id] = field_id;
        return fetchJsonGet(url, filter).then(json => {
            let f: FeatureCollection = json;
            if (f.features.length == 0) return null;
            return f.features[0];
        });
    }
    getFields(field_id: number[]): Promise<Feature[]>{
        let url = `/api/projects/${this.root.agro.projectName}/object/select`;
        let filter: any = {geom: 1, filter: {}};
        filter.filter[this.root.agro2.projectInfo.fieldName_id] = {"$in":field_id};
        return fetchJsonPost(url, filter).then(json => {
            let f: FeatureCollection = json;
            if (f.features.length == 0) return null;
            return f.features;
        });
    }
    async removeFields(field_ids: number[]): Promise<any>{
        let filter: any ={filter: {}};
        filter.filter[this.root.agro2.projectInfo.fieldName_id] = {"$in":field_ids};
        await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/object/delete`, filter);
        this.root.agro2.incVersionFieldTiles();
    }
    async removeFieldsFilter(filter: any): Promise<any>{
        await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/object/delete`, {filter: filter});
        this.root.agro2.incVersionFieldTiles();
    }

    getFieldsBbox(field_ids: number[]): Promise<BBox2d>{
        let filter: any = {};
        filter[this.root.agro2.projectInfo.fieldName_id] = {"$in": field_ids};
        let url = `/api/projects/${this.root.agro.projectName}/object/extent`;
        return fetchJsonPost(url, {filter: filter}).then(json =>{
           return GeometryUtils.getBbox(json.min_lon, json.max_lon, json.min_lat, json.max_lat);
        });
    }
    getFieldsBboxByFilter(filter: any): Promise<BBox2d>{
        let url = `/api/projects/${this.root.agro.projectName}/object/extent`;
        return fetchJsonPost(url, {filter: filter}).then(json =>{
            return GeometryUtils.getBbox(json.min_lon, json.max_lon, json.min_lat, json.max_lat);
        });
    }

    ///получение количества полей и площади по фильтру
    groupFields(filter: any, columns: string[] = []): Promise<IGroupResult>{
        let url = `/api/projects/${this.root.agro.projectName}/object/groups`;
        return fetchJsonPost(url,{columns: columns.join(","), filter: filter}).then(json => {
            return Promise.resolve( (json as IGroupResult[])[0]);
        });
    }

    exportFields(filter: any, format: A2ExportFormat, filename: string){
        let opt: IQueryArguments = {
            url: `/api/projects/${this.root.agro.projectName}/object/export`,
            params: {file_name: filename, format: format, filter: JSON.stringify(filter)}
        };
        this.root.events.onAgroExportDownload.call(opt);
        let args = Utils.queryEncodeParams(opt.params);
        Utils.downloadFile(opt.url + "?"+args);
    }
    getHistory(field_id: number): Promise<Feature<Geometry, IResponseFieldProperties>[]>{
        let url =`/api/projects/${this.root.agro.projectName}/object/history?object_id=${field_id}`;
        return fetchJsonGet(url,{object_id: field_id}).then((fc: FeatureCollection) => {
            return fc.features as Feature<Geometry, IResponseFieldProperties>[];
        });
    }


    @action//перевод панели навигации по полям на предыдущий уровень от списка полей в котором field_id.
    async gotoFieldPrevLevel(field_id: any) {
        let st = this.root;
        let feature = await st.agro2.getField(field_id);
        if (feature != null) {
            ra(() => {
                st.searchPanel.switchPanel(LeftPanelMode.agro);
                st.agro2.projectStruct.setPathByField(feature);
                st.agro2.projectStruct.setActivePage(st.agro2.projectStruct.activeNavigatorPage.parentItem);
            });
        }
    }
        @action
    async gotoFieldId(field_id: any, setSelection: boolean = false){
        let st = this.root;
        let feature = await st.agro2.getField(field_id);
        if (feature != null) {
            ra(() => {
                st.searchPanel.switchPanel(LeftPanelMode.agro);
                st.agro2.projectStruct.setPathByField(feature);
            });
            await when(() => {
                let ok = st.agro2.projectStruct.activeNavigatorPage.child_status == LoadStatus.ready ;
                if (!ok) return false;
                if (st.agro2.projectStruct.activeNavigatorPage.parentItem == null) return true;
                return st.agro2.projectStruct.activeNavigatorPage.parentItem.child_status == LoadStatus.ready;
            });
            await Utils.pauseAsync(300);
            let page = st.agro2.projectStruct.activeNavigatorPage;
            let t = page.fields.find(a => a.id == field_id);
            if (t != null) {
                t.flash = true;
                if (setSelection) {
                    page.setSelected(t.id, !page.isSelected(t.id));
                }
            }
            let elem = document.getElementById("AgroFieldItemComp-" + field_id.toString());
            if (elem != null) {
                //elem.scrollIntoView();
                Utils.scrollIntoView(elem);
            }
        }
    }
}