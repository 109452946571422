import {ObservableCustomStore} from "../../CustomStore";
import {observable, toJS} from "mobx";
import {ra} from "../../../helper/utils/mobxUtils";
import {isEqual, isString} from "lodash-es";
import {Utils} from "../../../helper/utils/Utils";
import {A2ReferenceStore} from "../A2ReferenceStore";
import {IProjectRefType} from "../../user/UserStore";
import {A2ColumnType} from "../A2ColumnItemStore";

export enum A2FieldFormDrawMode{
    empty= 'empty',
    create='create',
    edit='edit'
}


export enum A2FieldFormNotValidType{
    isNotValid='isNotValid',
    isRequired='isRequired'
}

export enum FieldEditModeType {
    edit='edit',
    insert='insert',
    multiEdit='multiEdit',
    groupEdit='groupEdit',
}

export interface IA2FieldFormReferenceText{
    text: string;
    isNewValue: boolean;
}

export class A2FieldFormCustomStore extends ObservableCustomStore{
    @observable
    properties: Record<string, any> ={};
    @observable
    referencePropertiesText: Record<string, IA2FieldFormReferenceText> = {};
    @observable
    notValidProperties: Record<string, A2FieldFormNotValidType> = {};
    @observable
    changedColumns: Set<string> = new Set<string>();//перечень изменённых колонок
    @observable
    hasDiffValue: Set<string> = new Set<string>();//перечень колонок, которые содержат разные значения
    @observable
    labelsErrorAnimation: boolean = false;

    public clear(){
        this.properties = {};
        this.labelsErrorAnimation = false;
        this.referencePropertiesText = {};
        this.notValidProperties = {};
        this.hasDiffValue.clear();
        this.changedColumns.clear();
    }

    resetInitialValueProperties(columnName: string){}

    public getNotValidColumns(emptyRequiredIsNotValid: boolean): Record<string, A2FieldFormNotValidType>{
        let newObj: Record<string, A2FieldFormNotValidType> = {};
        let arr = this.root.agro2.projectStruct.columns_items;
        for(let i = 0; i < arr.length; i++){
            let a = arr[i];
            let hasNewRefValue = false;
            if (this.properties[a.column_name] == null){
                if (this.referencePropertiesText[a.column_name] != null &&
                    this.referencePropertiesText[a.column_name].isNewValue){
                    hasNewRefValue = true;
                }
            }
            if (hasNewRefValue){
                if (a.reference_name != "" && !this.root.agro2.projectInfo.canAddRecordToReference(a.reference_name)) {
                    if (!((this.properties[a.column_name] == null || this.properties[a.column_name] == "") && !a.required))
                        newObj[a.column_name] = A2FieldFormNotValidType.isNotValid;
                }
            }
            if (a.required && emptyRequiredIsNotValid){
                if ((this.properties[a.column_name] == "" || this.properties[a.column_name] == null) && !hasNewRefValue){
                    newObj[a.column_name] = A2FieldFormNotValidType.isRequired;
                }
            }
        }
        return newObj;
    }
    checkFieldsValid(): boolean{
        let newObj = this.getNotValidColumns(true);

        ra(()=>{
            let old = toJS(this.notValidProperties);
            if (!isEqual(old, newObj)) this.notValidProperties = newObj;
        });

        //isEqual()
        return Utils.allKeys(newObj).length == 0;
    }

    getColumnsHasNewRefValues(): string[]{
        let r: string[] = [];
        this.root.agro2.projectStruct.columns_items.forEach(a => {
            if (!this.changedColumns.has(a.column_name)) return;
            let hasNewRefValue = false;
            if (this.properties[a.column_name] == null) {
                if (this.referencePropertiesText[a.column_name] != null &&
                    this.referencePropertiesText[a.column_name].text != "" &&
                    this.referencePropertiesText[a.column_name].isNewValue) {
                    r.push(a.column_name);
                }
            }
        });
        return r;
    }

    public async saveNewValues(): Promise<void>{
        let columns = this.getColumnsHasNewRefValues();
        let refs: A2ReferenceStore[] = [];
        let promises = columns.map(columnName => {
            let newValue = this.referencePropertiesText[columnName].text;
            let col = this.root.agro2.projectStruct.getColumnByName(columnName);
            let ref = this.root.agro2.projectInfo.getReferenceByName(col.reference_name);
            refs.push(ref);
            if (ref.reference_type == IProjectRefType.global) throw this.root.trans["Reference cannot be changed"];
            return ref.addReferenceRecord([newValue]);
        });
        let res = await Promise.all(promises);
        ra(()=>{
            refs.forEach(r => r.refresh());
            res.forEach((r, index) => {
                let id = r[0];
                let colName = columns[index];
                this.properties[colName] = id;
                this.referencePropertiesText[colName].isNewValue = false;
            });
        });
    }

    public getPropertiesForSaving(): any{
        let r:any = {};//this.properties
        this.root.agro2.projectStruct.columns_items.forEach(a => {
            if (!this.changedColumns.has(a.column_name)) return;
            let hasNewRefValue = false;
            if (this.properties[a.column_name] == null) {
                if (this.referencePropertiesText[a.column_name] != null && this.referencePropertiesText[a.column_name].text.trim() != "" &&
                    this.referencePropertiesText[a.column_name].isNewValue) {
                    hasNewRefValue = true;
                }
            }
            if (hasNewRefValue) {
                //throw `New value in reference`;
                r[a.column_name] = {};
                r[a.column_name][a.reference_value_column] = this.referencePropertiesText[a.column_name].text;
            }else{
                if (a.column_type == A2ColumnType.number && this.properties[a.column_name] != null){
                    let v = Utils.parseNumber(this.properties[a.column_name]);
                    if (v == null) throw this.root.agro2.projectInfo.translate.getColumnTitle(a.column_name) + " "+this.root.trans["is not a number"];
                    r[a.column_name] = v;
                }else
                if (a.column_type == A2ColumnType.date){
                    let v = this.properties[a.column_name];
                    if (v == null){
                        r[a.column_name] = v;
                    }else if (isString(v)){
                        r[a.column_name] = v;
                    }else {
                        r[a.column_name] = Utils.getDateStr(v);
                    }
                }else {
                    r[a.column_name] = this.properties[a.column_name];
                }
            }
        });
        return r;
    }

}