import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoCheckSvgProps {
    className?: string
}

export class AgroAhoCheckSvg extends React.PureComponent<IAgroAhoCheckSvgProps, undefined> {
    render() {
        return <svg  className={classNames(this.props.className)}
             width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.4001 1.99961L8.0001 0.599609L4.0001 4.59961L2.0001 2.59961L0.600098 3.99961L4.0001 7.39961L9.4001 1.99961Z" fill="#C5C5C5"/>
        </svg>;
    }
}