import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {action, observable} from "mobx";
import {ContextMenuCommonComp} from "../../../Common/ContextMenuCommonComp";
import {ConfirmFormComp} from "../../../Common/ConfirmFormComp";

@observer
export class IndexByPointDeleteAllPointsComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action onClickDeleteAllPoints(){
        this.props.store.indexByPointer.graphs = [];
        this.props.store.indexByPointer.yearPoints = [];
        this.props.store.indexByPointer.points = [];
    }

    @observable
    open: boolean = false;

    @action
    onClosePopup(){
        this.open = false;
    }

    @action
    onOpen(){this.open = true;}

    render() {
        let store = this.props.store;
        return <div onClick={this.onOpen}
                    className={classNames("indexByPoint_headerline-button indexByPoint_headerline-marginLeftButton",
                                                    "red_svg_onhover white_svg",
                                                    "indexByPoint_headerline-deleteAll full-center-content")}>

            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3V1.5H8.25V0.75C8.25 0.551088 8.17098 0.360322 8.03033 0.21967C7.88968 0.0790176 7.69891 0 7.5 0L4.5 0C4.30109 0 4.11032 0.0790176 3.96967 0.21967C3.82902 0.360322 3.75 0.551088 3.75 0.75V1.5H0V3H12Z" stroke="none"/>
                <path d="M1.5 3.75V10.5C1.5 10.8978 1.65804 11.2794 1.93934 11.5607C2.22064 11.842 2.60218 12 3 12H9C9.39782 12 9.77936 11.842 10.0607 11.5607C10.342 11.2794 10.5 10.8978 10.5 10.5V3.75H1.5ZM8.5605 9.375L7.5 10.4355L6 8.9355L4.5 10.4355L3.4395 9.375L4.9395 7.875L3.4395 6.375L4.5 5.3145L6 6.8145L7.5 5.3145L8.5605 6.375L7.0605 7.875L8.5605 9.375Z" stroke="none"/>
            </svg>

            {/*<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.1 0.5L4 2.6L1.9 0.5L0.5 1.9L2.6 4L0.5 6.1L1.9 7.5L4 5.4L6.1 7.5L7.5 6.1L5.4 4L7.5 1.9L6.1 0.5Z" />
            </svg>*/}
            {this.open && <ContextMenuCommonComp
                autoCornerWidth={125}
                autoCornerHeight={155}
                className="">

                <ConfirmFormComp className=""
                                 text={store.trans["Are you sure to delete all points?"]}>
                    <div className="flex-columns">
                        <div className="red underline-link AgroConfirmDeleteFarmComp-button" onClick={this.onClickDeleteAllPoints}>{store.trans.Delete}</div>
                        <div className="white text-next-2 underline-link AgroConfirmDeleteFarmComp-button" onClick={this.onClosePopup}>{store.trans.Cancel}</div>
                    </div>
                </ConfirmFormComp>
            </ContextMenuCommonComp>}
        </div>;
    }
}
