// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndexByPointChart2Comp-checkbox-minmax {
  min-width: 77px;
  height: 28px;
}

.IndexByPointChart2Comp-source-compare-div {
  padding-top: 4px;
}

#compareElement,
#tableElement {
  display: none;
}

.screenshotModal {
  max-height: none !important;
}

.screenshotOnly {
  display: block !important;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/IndexByPoint/IndexByPointChart2Comp.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AACA;;EAEE,aAAA;AAEF;;AAAA;EACI,2BAAA;AAGJ;;AADA;EACE,yBAAA;EACA,WAAA;AAIF","sourcesContent":[".IndexByPointChart2Comp-checkbox-minmax {\n    min-width: 77px;\n    height: 28px;\n}\n\n.IndexByPointChart2Comp-source-compare-div {\n    padding-top: 4px;\n}\n#compareElement,\n#tableElement {\n  display: none;\n}\n.screenshotModal{\n    max-height: none !important;\n}\n.screenshotOnly {\n  display: block !important; \n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
