// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputNumber-div {
  height: 30px;
  position: relative;
}

.InputNumber-input {
  z-index: 10;
  width: 100%;
  height: 100%;
  -moz-appearance: textfield; /* Firefox */
}

.InputNumber-input::-webkit-outer-spin-button,
.InputNumber-input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.InputNumber-arrow, .InputNumber-arrowBottom, .InputNumber-arrowTop {
  position: absolute;
  z-index: 20;
  right: 7px;
  cursor: pointer;
}

.InputNumber-arrowTop {
  top: 15%;
}

.InputNumber-arrowBottom {
  bottom: 15%;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/InputNumber.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,0BAAA,EAAA,YAAA;AACF;;AAEA;;EAEE,8CAAA;EACA,wBAAA;EACA,SAAA,EAAA,uEAAA;AACF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;AAAF;;AAEA;EAEE,QAAA;AAAF;;AAEA;EAEE,WAAA;AAAF","sourcesContent":[".InputNumber-div{\r\n  height: 30px;\r\n  position: relative;\r\n  //display: inline;\r\n}\r\n.InputNumber-input{\r\n  z-index: 10;\r\n  width: 100%;\r\n  height: 100%;\r\n  -moz-appearance:textfield; /* Firefox */\r\n}\r\n\r\n.InputNumber-input::-webkit-outer-spin-button,\r\n.InputNumber-input::-webkit-inner-spin-button {\r\n  /* display: none; <- Crashes Chrome on hover */\r\n  -webkit-appearance: none;\r\n  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */\r\n}\r\n\r\n\r\n.InputNumber-arrow{\r\n  position: absolute;\r\n  z-index: 20;\r\n  right: 7px;\r\n  cursor: pointer;\r\n}\r\n.InputNumber-arrowTop{\r\n  @extend .InputNumber-arrow;\r\n  top: 15%;\r\n}\r\n.InputNumber-arrowBottom{\r\n  @extend .InputNumber-arrow;\r\n  bottom: 15%;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
