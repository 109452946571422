import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {action} from "mobx";
import {A2ConfirmExportMode} from "../../../../../store/agro/Confirms/A2ConfirmExportStore";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2ConfirmDeleteStore} from "../../../../../store/agro/Confirms/A2ConfirmDeleteStore";
import { Utils } from '../../../../../helper/utils/Utils';

export interface IA2ConfirmDeleteFormComp extends IStoreProps{
    confirmStore: A2ConfirmDeleteStore
}

@observer
export class A2ConfirmDeleteFormComp extends React.Component<IA2ConfirmDeleteFormComp, undefined> {
    constructor(props: IA2ConfirmDeleteFormComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickCancel(){
        this.props.confirmStore.doCancel();
    }
    @action
    onClickSubmit(){
        this.props.confirmStore.doDelete();
    }

    render() {
        let store = this.props.store;
        let confirmStore = this.props.confirmStore;
        if (!confirmStore.show) return null;

        return <div className="Form-backgray">
            <div className="Forms-self-center Forms-base"  style={{maxWidth: "320px"}}>
                <div className="Forms-headerText Forms-marginTop Forms-marginLR Forms-headerBottomMargin">{store.trans.Delete}</div>
                <div className="Forms-marginLR">
                    <div className="red-text" style={{marginTop: "15px", marginBottom:"25px"}}>
                        {store.trans["Attention! The selected objects will be permanently removed from the project with all information attached"]}
                    </div>
                    {confirmStore.mode_export == A2ConfirmExportMode.folder && <div className="gray-text">{store.trans["Nesting"]}: {confirmStore.path}</div>}
                    <div className="gray-text">{store.trans.ObjectsFields}: {confirmStore.fields}</div>
                    <div className="gray-text">{store.trans.Area}: {Utils.roundDigitsWithSpace(confirmStore.area, 2)} {store.trans.ha}</div>
                </div>
                <div className="Forms-baseline Forms-topIdent2" />
                <div className="Forms-footerButtons">
                    <button onClick={this.onClickCancel} style={{minWidth: "100px"}}
                            className="large-button left-sidebar-cancel Forms-footerButtonRightMargin button-jump">{store.trans.Cancel}</button>
                    <button onClick={this.onClickSubmit} style={{minWidth: "100px"}}
                            className="large-button left-sidebar-red Forms-footerButtonRightMargin button-jump">{store.trans.Delete}</button>
                </div>
            </div>
        </div>;
    }}
