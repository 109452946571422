// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoRecNormItemComp-main {
  padding: 10px 10px 10px 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AgroAhoRecNormItemComp-checkbox {
  margin-right: 10px;
}

.AgroAhoRecNormItemComp-name {
  color: #C5C5C5;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AgroAhoRecNormItemComp-splitter {
  flex-grow: 1;
}

.AgroAhoRecNormItemComp-unit {
  color: #6A7886;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AgroAhoRecNormItemComp-del {
  display: none;
}

.AgroAhoRecNormItemComp-main:hover .AgroAhoRecNormItemComp-del {
  display: block;
}

.AgroAhoRecNormItemComp-main:hover .AgroAhoRecNormItemComp-unit {
  display: none;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/card/AgroAhoRecNormItemComp.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,cAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,cAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAKF;;AAHA;EACE,aAAA;AAMF;;AAJA;EACE,cAAA;AAOF;;AALA;EACE,aAAA;AAQF","sourcesContent":[".AgroAhoRecNormItemComp-main {\r\n  padding: 10px 10px 10px 32px;\r\n  display: flex;\r\n  align-items: center;\r\n  cursor: pointer;\r\n}\r\n.AgroAhoRecNormItemComp-checkbox {\r\n  margin-right: 10px;\r\n}\r\n.AgroAhoRecNormItemComp-name {\r\n  color: #C5C5C5;\r\n  font-family: \"Open Sans\", sans-serif;\r\n  font-size: 12px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: normal;\r\n}\r\n.AgroAhoRecNormItemComp-splitter {\r\n  flex-grow: 1;\r\n}\r\n.AgroAhoRecNormItemComp-unit {\r\n  color: #6A7886;\r\n  font-family: \"Open Sans\", sans-serif;\r\n  font-size: 12px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: normal;\r\n}\r\n.AgroAhoRecNormItemComp-del {\r\n  display: none;\r\n}\r\n.AgroAhoRecNormItemComp-main:hover .AgroAhoRecNormItemComp-del {\r\n  display: block;\r\n}\r\n.AgroAhoRecNormItemComp-main:hover .AgroAhoRecNormItemComp-unit {\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
