import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoMatchSvgProps {
    className?: string
}

export class AgroAhoMatchSvg extends React.PureComponent<IAgroAhoMatchSvgProps, undefined> {
    constructor(props: IAgroAhoMatchSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} width="15" height="10" viewBox="0 0 15 10"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.21875 5H10.7812" stroke="#6B707B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.09375 9.5H4.6875C2.35781 9.5 0.46875 7.485 0.46875 5C0.46875 2.515 2.35781 0.5 4.6875 0.5H6.09375" stroke="#6B707B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.90625 9.5H10.3125C12.6422 9.5 14.5312 7.485 14.5312 5C14.5312 2.515 12.6422 0.5 10.3125 0.5H8.90625" stroke="#6B707B" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>;
    }
}