import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoErrorSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoErrorSvg extends React.PureComponent<IAgroAhoErrorSvgProps, undefined> {
    constructor(props: IAgroAhoErrorSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 12C7.448 12 7 11.552 7 11C7 10.448 7.448 10 8 10C8.552 10 9 10.448 9 11C9 11.552 8.552 12 8 12ZM9 8.5C9 8.776 8.776 9 8.5 9H7.5C7.224 9 7 8.776 7 8.5V4.5C7 4.224 7.224 4 7.5 4H8.5C8.776 4 9 4.224 9 4.5V8.5Z"/>
        </svg>;
    }
}
