import {CustomStore} from "../../CustomStore";
import {
    IGraphData,
    IGraphDataInfo,
    IGraphDayValue,
    IndexByPointGraphStore
} from "../IndexByPointGraphStore";
import {action, observable} from "mobx";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {Utils} from "../../../helper/utils/Utils";
import {da} from "date-fns/locale";

export abstract class IndexByPointerSourceCustomStore extends CustomStore<IndexByPointGraphStore> {
    @observable
    protected data: IGraphDayValue[] = [];
    @observable
    protected _status: LoadStatus = null;
    get status(): LoadStatus {
        return this._status;
    }
    protected set status(value: LoadStatus) {
        this._status = value;
    }

    abstract getGraphData(): IGraphDataInfo;

    @action reset() {
        this._status = null;
        this.data = [];
    }

    protected convertStrDate(dateStr: string): number {
        let date = new Date(dateStr);
        return Utils.getDayOfYearRelativeByGlobalAllLeapYears(
            date,
            this.parent.year
        );
        //return Utils.getDayOfYear(date);
    }
    protected getGraphDataValue(
        dateStr: string,
        value: number
    ): IGraphDayValue {
        let date = new Date(dateStr);
        return {
            value: value,
            dayOfYear: this.convertStrDate(dateStr),
            absoluteDate: date.getTime()
        };
    }

    accumGraphDayValue(
        values: IGraphDayValue[],
        zero: number
    ): IGraphDayValue[] {
        let accum = 0;
        let res: IGraphDayValue[] = [];
        values.forEach(a => {
            // let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
            // let dayOfYearBegin = Utils.getDayOfYear(dateBegin);
            // let dayOfYearEnd = Utils.getDayOfYear(dateEnd);
            // if (dayOfYearBegin > dayOfYearEnd) {
            //     values = values.slice(dayOfYearEnd - 1);
            // }
            if (a.value >= zero) accum += a.value;
            res.push({dayOfYear: a.dayOfYear, value: accum});
        });
        return res;
    }
    accumGraphDayData(
        values: IGraphData[],
        zero: number
    ): IGraphData[] {
        let accum = 0;
        let res: IGraphData[] = [];
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        const {dayOfYearBegin, dayOfYearEnd} = Utils.adjustDayOfYear(
            dateBegin,
            dateEnd
        );
        let count = 0;
        values.forEach(a => {
            if (
                dayOfYearBegin > dayOfYearEnd &&
                a.absoluteDate === undefined
            ) {
                if (count < dayOfYearEnd) {
                    count++;
                    return;
                }
                if (a.value >= zero) accum += a.value;
                res.push({
                    dayOfYear: a.dayOfYear,
                    value: accum,
                    min: a.min,
                    sceneID: a.sceneID,
                    absoluteDate: a.absoluteDate,
                    max: a.max
                });
            } else {
                if (a.value >= zero) accum += a.value;
                res.push({
                    dayOfYear: a.dayOfYear,
                    value: accum,
                    min: a.min,
                    sceneID: a.sceneID,
                    absoluteDate: a.absoluteDate,
                    max: a.max
                });
            }
        });
        return res;
    }
}
