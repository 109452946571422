import React from "react";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import { observer } from "mobx-react";
import { TemporalGroupStore } from "../../../../store/OverlayObjectStore";
import './TemporalGroupComp.scss';
import classNames from "classnames";
import { observable } from "mobx";
import { Utils } from "../../../../helper/utils/Utils";

export interface ITemporalGroupProps extends IStoreProps {
    group: TemporalGroupStore;
}

@observer
export class TemporalGroupComp extends React.Component<ITemporalGroupProps> {
    @observable
    state = {
        startDateEditing: false,
        endDateEditing: false,
        startDate: this.props.group.start,
        endDate: this.props.group.end
    }

    constructor(props: ITemporalGroupProps) {
        super(props);
        autoBindReact(this);        
    }

    onStartDateBeginChange(e: any) {
        this.setState({startDate: e.currentTarget.value});
    }

    onStartDateChanged(e: any) {
        this.setState({startDateEditing: false});
        this.validateStartDate(e.currentTarget.value);
    }

    onStartDateKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        this.validateStartDate(e.currentTarget.value);
    }

    validateStartDate(value: string) {
        let ts = Date.parse(value);
        if (isNaN(ts))
            this.setState({startDate: this.props.group.start});        
        else {
            let newDate = new Date(ts);
            let oldDate = new Date(this.props.group.start);
            if (oldDate != newDate)
                this.props.group.start = Utils.formatDate(newDate);
        }
    }

    // updateDateValue(val: any, targetProp: string, stateField: string) {
    //     let ts = Date.parse(val);
    //     let targetPropValue = (this.props.group as any)[targetProp];
    //     if (isNaN(ts)) {
    //         let s: any = {};
    //         s[stateField] = targetPropValue;
    //         this.setState(s);
    //     }
    //     else {
    //         let newDate = new Date(ts);
    //         let oldDate = new Date(targetPropValue);
    //         if (oldDate != newDate)
    //         (this.props.group as any)[targetProp] = Utils.formatDate(newDate);
    //     }
    // }

    onStartDateBeginEdit() {
        this.setState({startDateEditing: true});
    }

    onEndDateBeginChange(e: any) {
        this.setState({endDate: e.currentTarget.value});
    }

    onEndDateChanged(e: any) {
        this.setState({endDateEditing: false});
        this.validateEndDate(e.currentTarget.value);
        // let ts = Date.parse(e.currentTarget.value);
        // if (isNaN(ts))
        //     this.setState({endDate: this.props.group.end});        
        // else {
        //     let newDate = new Date(ts);
        //     let oldDate = new Date(this.props.group.end);
        //     if (oldDate != newDate)
        //         this.props.group.end = Utils.formatDate(newDate);
        // }
        // // if (isDate(e.currentTarget.value))
        // //     this.props.group.end = e.currentTarget.value;
        // // else
        // //     this.setState({endDate: this.props.group.end});
    }

    onEndDateKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        this.validateEndDate(e.currentTarget.value);
    }

    validateEndDate(value: string) {
        let ts = Date.parse(value);
        if (isNaN(ts))
            this.setState({endDate: this.props.group.end});        
        else {
            let newDate = new Date(ts);
            let oldDate = new Date(this.props.group.end);
            if (oldDate != newDate)
                this.props.group.end = Utils.formatDate(newDate);
        }
    }

    onEndDateBeginEdit() {
        this.setState({endDateEditing: true});
    }

    onChangePeriod(toLeft: boolean) {
        let e = new Date(this.props.group.end) as any;
        let s = new Date(this.props.group.start) as any;
        let d: number = (toLeft? -1: +1) * Math.max(e - s, 60 * 60 * 24 * 1000); // если начало=конец - сдвиг на 1 сутки
        let sDate = Utils.formatDate(new Date(s.valueOf() + d));
        let eDate = Utils.formatDate(new Date(e.valueOf() + d));
        this.setState({startDate: sDate, endDate: eDate});
        this.props.group.setPeriod(sDate, eDate);
    }

    render() {
        return <div className="TemporalGroupComp">
            <span className="TemporalGroupComp-header">Period</span>
            <div className="TemporalGroupComp-dates">
                <div className="TemporalGroupComp-date-arrow-left" onClick={() => this.onChangePeriod(true)}>
                    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.73099 0.0566962C5.65002 0.0144549 5.55902 -0.00480208 5.46788 0.00101564C5.37673 0.00683336 5.28892 0.0375035 5.21398 0.0896972L0.213825 3.5898C0.147808 3.63588 0.0938944 3.69722 0.0566671 3.76861C0.0194399 3.83999 0 3.91931 0 3.99982C0 4.08032 0.0194399 4.15964 0.0566671 4.23103C0.0938944 4.30241 0.147808 4.36375 0.213825 4.40983L5.21398 7.90994C5.29814 7.96868 5.39835 8.00011 5.50099 7.99994C5.58115 7.99996 5.66011 7.98039 5.73099 7.94294C5.81222 7.90086 5.88034 7.8373 5.92795 7.75919C5.97556 7.68108 6.00083 7.5914 6.001 7.49992V0.49971C6.00083 0.408232 5.97556 0.318556 5.92795 0.240443C5.88034 0.162331 5.81222 0.0987756 5.73099 0.0566962Z" fill="#6A7886"/>
                    </svg>
                </div>
                <input type="text" maxLength={10} placeholder="YYYY-MM-DD"
                    onChange={this.onStartDateBeginChange}
                    onBlur={this.onStartDateChanged}
                    onFocus={this.onStartDateBeginEdit}
                    onKeyDown={this.onStartDateKeyPressed}
                    className={classNames("TemporalGroupComp-date-text-input number-without-arrow",
                        {"TemporalGroupComp-date-text-input-active": this.state.startDateEditing,
                         "TemporalGroupComp-date-text-input-passive": !this.state.startDateEditing})}
                    value={this.state.startDate}/>
                <div className="TemporalGroupComp-date-delim"/>
                <input type="text" maxLength={10} placeholder="YYYY-MM-DD"
                    onChange={this.onEndDateBeginChange}
                    onBlur={this.onEndDateChanged}
                    onFocus={this.onEndDateBeginEdit}
                    onKeyDown={this.onEndDateKeyPressed}
                    className={classNames("TemporalGroupComp-date-text-input number-without-arrow",
                        {"TemporalGroupComp-date-text-input-active": this.state.endDateEditing,
                         "TemporalGroupComp-date-text-input-passive": !this.state.endDateEditing})}
                    value={this.state.endDate}/>
                <div className="TemporalGroupComp-date-arrow-right" onClick={() => this.onChangePeriod(false)}>
                    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.27096 0.0566962C0.351934 0.0144549 0.442931 -0.00480208 0.534075 0.00101564C0.625219 0.00683336 0.713029 0.0375035 0.787975 0.0896972L5.78813 3.5898C5.85415 3.63588 5.90806 3.69722 5.94529 3.76861C5.98251 3.83999 6.00195 3.91931 6.00195 3.99982C6.00195 4.08032 5.98251 4.15964 5.94529 4.23103C5.90806 4.30241 5.85415 4.36375 5.78813 4.40983L0.787975 7.90994C0.703811 7.96868 0.603607 8.00011 0.500967 7.99994C0.420799 7.99996 0.341841 7.98039 0.27096 7.94294C0.189736 7.90086 0.121611 7.8373 0.0740027 7.75919C0.0263944 7.68108 0.00112724 7.5914 0.000951767 7.49992V0.49971C0.00112724 0.408232 0.0263944 0.318556 0.0740027 0.240443C0.121611 0.162331 0.189736 0.0987756 0.27096 0.0566962Z" fill="#6A7886"/>
                    </svg>
                </div>
            </div>
        </div>;
    }
}