import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoTableUpload2Comp.scss'
import {action, observable} from "mobx";
import classNames from "classnames";
import {AgroAhoProgressComp} from "./../AgroAhoProgressComp";
import {AgroAhoDownloadFileSvg} from "./../AgroAhoDownloadSvg";
import {Utils} from "../../../../app/helper/utils/Utils";
import {AhoFileLoadStage, AhoLoadStatus} from "../../agroAhoUploadIndcStore";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoStepsComp} from "./AgroAhoStepsComp";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AgroAhoCardTourItemComp} from "../card/AgroAhoCardTourItemComp";
import {AhoUploadTablePath} from "../../agroAhoUploadTableStore";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";

@observer
export class AgroAhoTableUpload2Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickUpload(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadClick();
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadCancelClick();
    }

    @action
    onClickBack(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadBackClick();
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        Utils.downloadFile(url);
    }

    onSelTourChange(tour: any){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.curTour = tour?.data;
        // console.log('sel', this.curTour, tour);
    }

    render() {
        let store = this.props.store;
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        let ustore = store_.uploadTableStore;
        let path = store_.trans['Create a new tour'];
        let tour_name = tstore.newTourName;
        let tour_date = tstore.newTourDate;
        if (ustore.ahoUploadTablePath == AhoUploadTablePath.ExtTour) {
            tour_name = tstore.curTour?.tour_name
            tour_date = new Date(tstore.curTour?.tour_date);
            path = store_.trans['Loading into an existing tour'];
        }




        return <div className={classNames("AgroAhoTableUpload2Comp-main",{
            "AgroAhoTableUpload2Comp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoTableUpload2Comp-cover">
                <div className="AgroAhoTableUpload2Comp-topPanel">
                    <div className="AgroAhoTableUpload2Comp-titleCover">
                        <div className="AgroAhoTableUpload2Comp-title">{store_.trans['Step 2. Loading a file with agrochemical indicators']}</div>
                        <AgroAhoStepsComp step={ustore.ahoUploadTableStage}/>
                    </div>
                    <div className="AgroAhoTableUpload2Comp-msg">
                        <span>{store_.trans['The data downloaded from the table file (AHO indicators, recommended application rates) must be assigned to a particular Tour. Grouping data by Tour allows, for example, to save a history of values for one cell over different seasons.']}</span>
                    </div>
                    <div className="AgroAhoTableUpload2Comp-line"/>

                    <div className="AgroAhoTableUpload2Comp-selTourCaption">{store_.trans['Tour selection']}</div>
                    <div className="AgroAhoTableUpload2Comp-selTourText">{path}</div>
                    <div className="AgroAhoTableUpload2Comp-selTourCaption">{store_.trans['Tour name']}</div>
                    <div className="AgroAhoTableUpload2Comp-selTourText">{tour_name}</div>
                    <div className="AgroAhoTableUpload2Comp-selTourCaption">{store_.trans['Tour date']}</div>
                    <div className="AgroAhoTableUpload2Comp-selTourText">{store_.format_date(tour_date)}</div>


                </div>
                <div className="AgroAhoTableUpload2Comp-bottomPanel">
                    {/*<div className="AgroAhoTableUpload2Comp-linkSample" onClick={this.onDownloadSampleClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Sample}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload2Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Instruction}</span>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload2Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoTableUpload2Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoTableUpload2Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>

                    <button className="left-sidebar-cancel button-jump AgroAhoTableUpload2Comp-backButton"
                            onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </button>
                    <button className="left-sidebar-active button-jump AgroAhoTableUpload2Comp-uploadButton"
                            onClick={this.onClickUpload}>{store.trans['Upload file']}</button>

                    {/*<div className="AgroAhoTableUpload2Comp-backButton" onClick={this.onClickBack}>*/}
                    {/*    <AgroAhoBackSvg/>*/}
                    {/*    <span>{store.trans.Back}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload2Comp-uploadButton"*/}
                    {/*     onClick={this.onClickUpload}>{store.trans['Upload file']}</div>*/}
                </div>
            </div>
        </div>
    }
}
