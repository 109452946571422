import {ObservableCustomStore} from "../CustomStore";
import {action, autorun, observable} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {fetchJsonGet, fetchJsonPost} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";
import {IProjectRefType} from "../user/UserStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {Utils} from "../../helper/utils/Utils";
import {save, update} from "../PermalinkDecor";

export class A2ReferenceStore extends ObservableCustomStore{
    class(): string {
        return "A2ReferenceStore";
    }

    @save
    reference_name: string;
    @save
    reference_primary_column: string;//колонка ключа
    @save
    reference_value_column: string;//колонка значения
    @save
    reference_type:IProjectRefType;

    @update @observable
    ref: Map<number, string> = new Map<number, string>();

    @observable
    private version: number = 0;

    @observable
    status: LoadStatus = null;

    private uses: Set<string> = new Set<string>();//сколько компонент использует справочник. Подсчёт ссылок. Если >0 то автообновляется

    subscription(): IReactionDisposer[] {
        return [autorun(
            ()=>{
                if (this.uses.size > 0 && this.status == null){
                    this.loadReference();
                }
            }
        )];
    }

    @action
    refresh(){
        this.status = null;
    }
    addUsed(): any{
        let hash = Utils.randomStr();
        this.uses.add(hash);
        return hash;
    }

    deleteUsed(hash: string){
        if (hash == null) return;
        if (this.uses.has(hash)) this.uses.delete(hash);
    }

    @action
    incVersion(){
        this.version++;
        this.status = null;
    }
    @action
    loadReference(): Promise<void>{
        let store = this.root;
        this.status = LoadStatus.loading;
        let url = `/api/projects/${store.agro.projectName}/reference/${this.reference_name}/list?v=${this.version}`;

        return fetchJsonGet(url).then(json =>{
            ra(()=>{
                this.status = LoadStatus.ready;
                let values: any[] = json;
                this.ref.clear();
                values.forEach(a => {
                    let v = a.values;
                    let key = v[this.reference_primary_column];
                    let value = v[this.reference_value_column];
                    this.ref.set(key, value);
                });
            });
            return Promise.resolve();
        }).catch(err => {
            ra(()=>{
                this.ref.clear();
                store.addError(err);
                this.status = LoadStatus.ready;
            });
        });
    }
    async addReferenceRecord(values: string[]): Promise<number[]>{
        //https://dev-class.ctrl2go.com/api/projects/rshb51/reference/farm/add?values=[{"farm_name":"Авангард2"}]
        let arr: any[] = values.map(v => {
            let p:any = {};
            p[this.reference_value_column] = v;
            return p;
        });
        let prms = {values:arr};
        let url = `/api/projects/${this.root.agro.projectName}/reference/${this.reference_name}/add`;
        let json = await fetchJsonPost(url, prms) as any;
        return json.ref_ids as number[];
    }
}