// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndexByPointPopupSettingsComp-main {
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 15px;
  font-weight: 400;
  font-size: 11px;
  min-width: 350px;
  color: #C5C5C5;
}

.IndexByPointPopupSettingsComp-title {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 8px;
}

.IndexByPointPopupSettingsComp-footer {
  margin-top: 20px;
}

.IndexByPointPopupSettingsComp-divider {
  padding-top: 15px;
  border-bottom: 1px solid #242930;
  margin-bottom: 15px;
}

.IndexByPointPopupSettingsComp-sourceItem {
  margin-right: 15px;
}

.IndexByPointPopupSettingsComp-nextDiv {
  margin-bottom: 15px;
}

.IndexByPointPopupSettingsComp-rightPad {
  padding-right: 15px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/IndexByPoint/Popups/IndexByPointPopupSettingsComp.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,0CAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AADA;EACE,iBAAA;EACA,gCAAA;EACA,mBAAA;AAIF;;AAFA;EACE,kBAAA;AAKF;;AAHA;EACE,mBAAA;AAMF;;AAJA;EACE,mBAAA;AAOF","sourcesContent":[".IndexByPointPopupSettingsComp-main{\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);\r\n  border-radius: 8px;\r\n  padding: 15px;\r\n  font-weight: 400;\r\n  font-size: 11px;\r\n  min-width: 350px;\r\n  color: #C5C5C5;\r\n}\r\n.IndexByPointPopupSettingsComp-title{\r\n  font-weight: 400;\r\n  font-size: 10px;\r\n  line-height: 14px;\r\n  margin-bottom: 8px;\r\n}\r\n.IndexByPointPopupSettingsComp-footer{\r\n  margin-top: 20px;\r\n}\r\n.IndexByPointPopupSettingsComp-divider{\r\n  padding-top: 15px;\r\n  border-bottom: 1px solid #242930;\r\n  margin-bottom: 15px;\r\n}\r\n.IndexByPointPopupSettingsComp-sourceItem{\r\n  margin-right: 15px;\r\n}\r\n.IndexByPointPopupSettingsComp-nextDiv{\r\n  margin-bottom: 15px;\r\n}\r\n.IndexByPointPopupSettingsComp-rightPad{\r\n  padding-right: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
