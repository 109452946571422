import * as React from 'react';
import {observer} from "mobx-react";
import {A2FieldEditorPenSvg} from "./A2FieldEditorPenSvg";
import {A2FieldEditorInlineErrorComp} from "./A2FieldEditorInlineErrorComp";
import {A2FieldEditorCustomInputComp} from "./A2FieldEditorCustomInputComp";
import classNames from "classnames";
import { FieldEditModeType } from '../../../../../store/agro/fieldForm/A2FieldFormCustomStore';

@observer
export class A2FieldEditorKeyComp extends A2FieldEditorCustomInputComp {

    render() {
        let store = this.props.store;
        let pref = this.getPreference();
        let isNewForm = store.agro2.fieldEditorForm.editMode == FieldEditModeType.insert
        return <div className={this.props.className}>
            <div className={classNames("Agro2CreateField-createFerm-nameLine",{"Agro2CreateField-createFerm-nameLineReadonly": this.props.isReadonly})}>
                <div className="flex-stretch-item">
                    <span>{this.props.columnInfo.title}</span>
                    <span>{this.props.columnInfo.required  && <span className="Agro2Common-createFerm-star blue-text">*</span>}</span>
                    {pref.isChanged && !isNewForm &&<A2FieldEditorPenSvg />}
                </div>
                <A2FieldEditorInlineErrorComp field={this.props.field} validType={this.props.validType} store={store} />
            </div>
            {this.props.children}
        </div>;
    }
}
