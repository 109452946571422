import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {IProjectInfo, IProjectRefType, ProjType} from "../user/UserStore";
import {A2TranslateStore} from "./A2TranslateStore";
import {A2ReferenceStore} from "./A2ReferenceStore";
import {save, update} from "../PermalinkDecor";
import {ProjectRole} from "./A2PermissionStore";
import {isArray} from "lodash-es";

export class A2ProjectInfo extends CustomStore{

    get projectType(): ProjType{
        return this.projectInfoJson?.proj_type;
    }
    get projectName(): string{
        return this.projectInfoJson?.proj_name;
    }

    get projectTypeFilters(): ProjType[]{
        if (isArray(this.root.config?.projectTypeFilters)){
            return this.root.config?.projectTypeFilters;
        }else{
            return [];
        }
    }

    @save @observable
    projectInfoJson: IProjectInfo = null;
    @save
    fieldName_columnName = "field_name";
    @save
    fieldName_id = "field_id";
    @save
    fieldName_season = "season_id";
    @save
    fieldName_area_sys = "area_sys";

    translate: A2TranslateStore = new A2TranslateStore(this);

    @update @observable
    references: Map<string, A2ReferenceStore> = new Map<string, A2ReferenceStore>();

    getReferenceByName(name: string):A2ReferenceStore{
        return this.references.get(name);
    }

    canAddRecordToReference(referenceName: string): boolean{
        let r = this.getReferenceByName(referenceName);
        if (r == null) return false;
        return (r.reference_type == IProjectRefType.local);
    }

    getFieldVectorLayerId(): string{
        return this.projectInfoJson?.type_info?.type_schema?.obj?.view?.dataset?.ds_code;
    }
}