import { Utils } from "../../../helper/utils/Utils";
import { IGraphData, IGraphDataInfo, IGraphDayValue } from "../IndexByPointGraphStore";
import { IndexByPointerSourceCustomSimpleStore } from "./IndexByPointerSourceCustomSimpleStore";
import { MeteoStationInfo } from "./MeteoStationInfo";

export class IndexByPointerSourceSimpleSoilMoistureMeteoStationStore extends IndexByPointerSourceCustomSimpleStore{
    private _meteoStation: MeteoStationInfo = null;
    public get meteoStation() : MeteoStationInfo {
        let gp = this.parent.gPoint;
        if (! this._meteoStation)
            this._meteoStation = new MeteoStationInfo(gp.point.lng, gp.point.lat);
        return this._meteoStation;
    }

    getGraphData(): IGraphDataInfo{
        let res: IGraphData[] = [];
        let arr: IGraphDayValue[] = this.getGraphDataInternal();
        arr.forEach(a => {
            res.push({dayOfYear: a.dayOfYear, value: Math.round(a.value * 100) / 100, sceneID: null});
        });
        return {data: res, hasMinMax: false};
    }

    protected getRequest(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return (`/api/meteo/station/${this.meteoStation.station_id}/sm?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}`)
    }
    protected getValue(json: any): IGraphDayValue{
        let j: {date: string, sm: number} = json;
        return this.getGraphDataValue(j.date, j.sm);
    }
    protected getGraphDataInternal(): IGraphDayValue[]{
        return this.getData;
    }


    private get getData(): IGraphDayValue[]{
        let src: IGraphDayValue[];
        if (this.status == null) setImmediate(()=> { this.load() });
        src = this.data;
        return src;
    }

}