import {CustomStore} from "./CustomStore";
import {action, observable} from "mobx";
import bbox from "@turf/bbox";
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";

export class SearchSuggestion {
    constructor(name: string, id: string, type: string) {
        this.text = name;
        this.id = type.substr(0, 1).toUpperCase() + id;
    }
    text: string = null;
    id: string = null;
}

export class SearchAddressStore extends CustomStore{
    class(): string {return "SearchAddressStore";}

    @observable
    private showSearchAddress: boolean = false;

    @observable searchAddressText: string = "";

    @observable suggestions: SearchSuggestion[] = [];

    @action
    public showPanel(){
        if (this.showSearchAddress === true) return;
        let geoObj = this.root.map.superTools.searchAddressMarker.geoObject;
        this.root.searchPanel.closeAllLeftPanels();
        if (geoObj)
            this.searchAddressText = geoObj.properties.name;
        this.showSearchAddress = true;        
    }

    public isShowPanel(): boolean{
        return this.showSearchAddress;
    }

    @action
    public closePanel(){
        if (this.showSearchAddress === false) return;
        this.showSearchAddress = false;
        //this.searchAddressText = "";
        this.suggestions = [];
    }

    @action
    public setSearchAddressMarker(txt: string, lat: number, lon: number){
        return this.setSearchAddressPolygon(txt, lat, lon, null);
    }

    @action
    public setSearchAddressPolygon(name: string, lat: number, lon: number, geom: GeoJSON.Geometry){
        let feature: GeoJSON.Feature = {type: "Feature", properties: {name: name, lat: lat, lon: lon},
            geometry: geom ?? {type: "Point", coordinates: [lon, lat]}};
        let bb = bbox(feature) as BBox2d;
        this.root.map.superTools.searchAddressMarker.geoObject = feature;
        this.root.map.zoomToBBox1in3(bb, { maxZoom:  this.root.map.zoom});
    }
}
