import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import '../../Export2Panel/ActiveSensorDay.scss';
import './Sentinel5PanelComp.scss';
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {DateUtils} from "../../../../../helper/utils/DateUtils";
import {action} from "mobx";

@observer
export class Sentinel5ScenesComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onChangeYear(item: IDropDownItem, newIndex: number){
        this.props.store.searchPanel.searchParamsS5.sceneYear = item.data;
    }
    @action
    onChangeMonth(item: IDropDownItem, newIndex: number){
        this.props.store.searchPanel.searchParamsS5.sceneMonth = item.data;
    }

    render() {
        let store = this.props.store;
        let s5store = store.searchPanel.searchParamsS5;

        let data = store.searchPanel.allSentinel5Data;

        let yearItems: IDropDownItem[] = [];
        let yearsWithDubs = data.map(a => {
            let d = new Date(a.date);
            return d.getFullYear();
        });
        let years = [...(new Set(yearsWithDubs))];
        years.forEach(y =>{
            yearItems.push({value: y.toString(), key: y.toString(), data: y});
        });

        let monthWithDubs = data.filter(a => (new Date(a.date)).getFullYear() == s5store.sceneYear).map(a => {
            let d = new Date(a.date);
            return d.getMonth();
        });
        let month = [...(new Set(monthWithDubs))];
        let monthItems: IDropDownItem[] = [];
        month.forEach(i =>{
            monthItems.push({value: DateUtils.getMonthName(i, store.trans), key: i.toString(), data: i});
        });

        return <div className='Sentinel5Panel-flex-container'>
            <div className="flex-row-centerByVertical Sentinel5Panel-marginTop15">
                <div className="flex-columns-centerByVertical flex-stretch-item">
                    <div className="text-10">{store.trans.Year}</div>
                </div>
                <div className="flex-columns-centerByVertical Sentinel5Panel-monthValue">
                    <DropDownComp items={yearItems} onChange={this.onChangeYear}
                                  currentKey={s5store.sceneYear} className="width100" />
                </div>
            </div>
            <div className="flex-row-centerByVertical Sentinel5Panel-marginTop15">
                <div className="flex-columns-centerByVertical flex-stretch-item">
                    <div className="text-10">{store.trans.Month}</div>
                </div>
                <div className="Sentinel5Panel-monthValue Sentinel5Panel-monthValue">
                    <DropDownComp items={monthItems} onChange={this.onChangeMonth}
                                  currentKey={s5store.sceneMonth} className="width100" />
                </div>
            </div>
        </div>;
    }
}
