import {CustomStore} from "./CustomStore";
import {action, observable} from "mobx";
import {save} from "./PermalinkDecor";
import {CheckStates} from "../helper/structs/CheckStates";
import {SearchItemStore} from "./SearchItemStore";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {GeometryUtils} from "../helper/utils/GeometryUtils";

export class SearchItemGroup extends CustomStore{
    class(): string {return "SearchItemGroup";}
    first(): SearchItemStore{
        return this.items[0];
    }
    groupId(): string{
        let t = this.first();
        if (t == null) return null;
        return t.sceneId();
    }
    isCurrent():boolean{
        let root = this.root;
        let r = this.items.find(a => a.sceneId() == root.searchPanel.currentSceneid);
        return r != null;
    }
    @save @observable items: SearchItemStore[] = [];
    wasVisible: boolean = false;

    //@observable visibleInList: boolean = false;

    @action
    setSelected(value: boolean){
        this.items.forEach(a => a.selected = value);
    }
    getSelected(): CheckStates{
        let r: CheckStates = CheckStates.unchecked;
        let sum = 0;
        this.items.forEach(
            a => {
                if (a.selected) sum++;
            }
        );
        if (sum == this.items.length) r = CheckStates.checked;
        else if (sum > 0) r = CheckStates.partial;
        return r;
    }

    getSceneIds(): string[]{
        return this.items.map(a => a.sceneId());
    }

    getBbox(): BBox2d{
        let bboxAll: BBox2d = null;
        this.items.forEach((si, index) => {
                let meta = si.feature.properties;
                let bBox2: BBox2d = [Math.min(meta.bl_x, meta.tl_x), Math.min(meta.bl_y, meta.br_y),
                    Math.max(meta.tr_x, meta.br_x), Math.max(meta.tr_y, meta.tl_y)];
                if (index == 0) bboxAll = bBox2;
                else {
                    bboxAll = GeometryUtils.unionBbox(bboxAll, bBox2);
                }
            });
        return bboxAll;
    }
}

