import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDelRNPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";


@observer
export class AgroAhoDelRNPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store_ = this.props.store.agroAhoStore;
        // console.log('del rn')
        // store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let cstore = store_.cardStore;
        let mstore = store_.mapStore;
        return <div className="AgroAhoDelRNPanelComp-main">
            <div className="AgroAhoDelRNPanelComp-caption">{store.trans.Delete}</div>
            <div className="AgroAhoDelRNPanelComp-msg">{
                TranslateUtils.createSpansWithReplaceMarkers(
                    store_.trans['Attention! All data for culture {0} will be deleted'],
                    ustore.getCropName(mstore.mapRNStore.curRecNormItem.crop_id)
                )}</div>

            <div className="AgroAhoDelRNPanelComp-line"> </div>
            <div className="AgroAhoDelRNPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoDelRNPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className="large-button left-sidebar-red button-jump"
                    onClick={this.onClickDelete}>{store.trans.Delete}</button>
            </div>
        </div>;
    }
}
