import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {ContextMenuCommonComp} from "./ContextMenuCommonComp";
import {ConfirmFormComp} from "./ConfirmFormComp";
import {Utils} from "../../helper/utils/Utils";
import classNames from "classnames";
import {CustomConfirmStore} from "../../store/CustomConfirmStore";
import './ConfirmPopupComp.scss';
import {action} from "mobx";
import {isFunction} from "lodash-es";

export interface IConfirmPopupComp{
    text: string;
    confirmStore: CustomConfirmStore;
    clickOk?: ()=>any;
    cancelText?: string;
    okText?: string;
    classNameOkButton?: string;
    classNameButtonsDiv?: string;
}
@observer
export class ConfirmPopupComp extends React.Component<IConfirmPopupComp, undefined> {
    constructor(props: IConfirmPopupComp) {
        super(props);
        autoBindReact(this);
    }

    componentWillUnmount() {

    }

    @action
    onClickOk(){
        let cstore = this.props.confirmStore;
        cstore.show = false;
        if (isFunction(this.props.clickOk)) this.props.clickOk();
    }
    @action
    onClickCancel(){
        let cstore = this.props.confirmStore;
        cstore.doCancel();
    }

    render() {
        let cstore = this.props.confirmStore;
        let cancelText = this.props.cancelText;
        if (!Utils.isStringNotEmpty(cancelText)) cancelText = cstore.root.trans.Cancel;
        let okText = this.props.okText;
        if (!Utils.isStringNotEmpty(okText)) okText = cstore.root.trans.OK;

        if (cstore.show) {
            return <ContextMenuCommonComp
                autoCornerWidth={125} indentVertical={10}
                autoCornerHeight={155} onClose={this.onClickCancel}
                className="">
                <ConfirmFormComp text={this.props.text} classNameText="ConfirmPopupComp-text" className="IndexByPointPopupGroupsComp-dropDown">
                    {!this.props.children && <div className={classNames("flex-columns", this.props.classNameButtonsDiv)}>
                        <div
                            className={classNames("white underline-link ConfirmPopupComp-button", this.props.classNameOkButton)}
                            onClick={this.onClickOk}>{okText}</div>
                        <div className="white underline-link ConfirmPopupComp-button"
                             onClick={this.onClickCancel}>{cancelText}</div>
                    </div>}
                    {this.props.children}
                </ConfirmFormComp>
            </ContextMenuCommonComp>;
        }
        return null;
    }
}
