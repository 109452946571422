import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action, observable} from "mobx";
import {LeftPanelMode, SceneMosaicSwitch, SceneUiType, SearchSceneSourceType} from "../../../../store/SearchStore";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {SwitchText} from "../../../Common/SwitchText";
import {DropDownNumberSlider} from "../../../Common/DropDownNumberSlider";
import './SearchSceneButtonsComp.scss';
import {Utils} from "../../../../helper/utils/Utils";
import {ExportItemsCategory} from "../../../../store/export/ExportStore";
import {MyHint} from "../../../Common/MyHint";

export interface ISearchSceneButtonsComp extends IStoreProps
{
    sceneUiType: SceneUiType;
    enable: boolean;
    //exportButtonShow: boolean
}
@observer
export class SearchSceneButtonsComp extends React.Component<ISearchSceneButtonsComp, undefined> {
    constructor(props: ISearchSceneButtonsComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickNdvi(){
        this.props.store.searchPanel.switchNdviPanel(this.props.sceneUiType);
    }

    @action
    clickToMosaic(){
        this.props.store.searchPanel.switchSceneMosaic(SceneMosaicSwitch.mosaic);
    }
    @action
    clickToScene(){
        this.props.store.searchPanel.switchSceneMosaic(SceneMosaicSwitch.scene);
    }
    @observable
    aa: boolean = true;
    @action
    clickTextSwitch(check1: boolean){
        let store = this.props.store;
        //if (store.map.searchObject.isEmpty) return;
        if (check1){
            this.props.store.searchPanel.switchSceneMosaic(SceneMosaicSwitch.scene);
        }else{
            this.props.store.searchPanel.switchSceneMosaic(SceneMosaicSwitch.mosaic);
        }
    }

    @action onChangeMask(value: number){
        this.props.store.map.productInfo.mask_quality = value;
    }
    @action onChangeOpacity(value: number){
        this.props.store.map.productInfo.opacity = value;
    }

    exportFavoriteButtonEnabled(): boolean{
        let store = this.props.store;
        return (store.searchPanel.leftPanelMode == LeftPanelMode.search && Utils.isStringNotEmpty(store.searchPanel.currentSceneid)) ||
            (store.searchPanel.leftPanelMode == LeftPanelMode.favorite && store.searchPanel.favoriteList.getAllSceneItems().length > 0);
        //return (store.exportStore.getSceneItems().length > 0 );
    }
    @action
    onClickExportFavorite(){
        let store = this.props.store;
        if (!this.exportFavoriteButtonEnabled()) return;
        if (store.searchPanel.leftPanelMode == LeftPanelMode.search) {
            if (!store.exportStore.showExportOptions) {
                store.exportStore.showExportOptions = true;
                store.exportStore.exportItemsCategory = ExportItemsCategory.current;
            }else {
                store.exportStore.showExportOptions = false;
            }
        }
        if (store.searchPanel.leftPanelMode == LeftPanelMode.favorite) {
            if (!store.exportStore.showExportOptions){//окно ещё не открыто
                store.searchPanel.closeAllLeftPanels();
                store.exportStore.exportItemsCategory = ExportItemsCategory.favorites;
                store.exportStore.showExportOptions = true;
            }else {
                store.exportStore.showExportOptions = false;
            }
        }
    }

    render() {
        let store = this.props.store;
        let active = this.props.sceneUiType == SceneUiType.current && store.searchPanel.showSearchProductPanel 
            || this.props.sceneUiType == SceneUiType.favorite && store.searchPanel.showFavoriteProductPanel;

        let t = store.map.productInfo.activeProductsSet.getActiveCodeByUiType(this.props.sceneUiType).currentProductStore;// .getActiveProductBySceneUiType(this.props.sceneUiType);
        let curProduct = t.productConfig.abbreviation;

        //store.map.productInfo
        return <div className={classNames("left-sidebar__right-bottom__row3", {"disable": !this.props.enable})}>
            {store.searchPanel.sourceType == SearchSceneSourceType.sentinel5 &&
                <React.Fragment>
                    <div className="flex-columns-centerByVertical">
                    <div className="SearchSceneButtonsComp-maskKeyValue">
                        <div className="SearchSceneButtonsComp-maskTitle">{store.trans["Min. quality"]}</div>
                        <div className="SearchSceneButtonsComp-maskSlider">
                            <DropDownNumberSlider onChange={this.onChangeMask} value={store.map.productInfo.mask_quality}
                                                  min={0} max={100} className="height100"/>
                        </div>
                    </div>
                    <div className="SearchSceneButtonsComp-maskKeyValue">
                        <div className="SearchSceneButtonsComp-maskTitle">{store.trans.Opacity}</div>
                        <div className="SearchSceneButtonsComp-maskSlider">
                            <DropDownNumberSlider onChange={this.onChangeOpacity} value={store.map.productInfo.opacity}
                                                  min={0} max={100} className="height100" />
                        </div>
                    </div>
                    </div>
                </React.Fragment>
            }
            {(store.searchPanel.sourceType == SearchSceneSourceType.passive ||
                store.searchPanel.sourceType == SearchSceneSourceType.sentinel1) &&
                <React.Fragment>
                    <SwitchText text1={store.trans.Scenes}
                        text2={store.trans.Dates}
                        //disabledUnchecked={store.map.searchObject.isEmpty}
                        className=""
                        check1={store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.scene}
                        onClick={this.clickTextSwitch}/>
                    <div className="flex-fit-item"/>
                    {this.props.sceneUiType == SceneUiType.favorite && <MyHint text={store.trans.ExportTo} key="exportButton2">
                        <div className={classNames("SearchSceneButtonsComp-export",
                            {"pointer":this.exportFavoriteButtonEnabled(), "opacity30": this.props.enable && !this.exportFavoriteButtonEnabled()} )} onClick={this.onClickExportFavorite}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 16H1C0.4 16 0 15.6 0 15V3C0 2.4 0.4 2 1 2H4V4H2V14H14V9H16V15C16 15.6 15.6 16 15 16Z" fill="#C5C5C5"/>
                                <path d="M10 3C6.8 3 4 5.5 4 10C5.1 8.3 6.4 7 10 7V10L16 5L10 0V3Z" fill="#C5C5C5"/>
                            </svg>
                        </div>
                    </MyHint>}
                </React.Fragment>}

            <MyHint text={store.trans.Products}>
                <button className={classNames("left-sidebar__right-bottom__row3_right_button",
                    {"text-blue": active})} onClick={this.onClickNdvi}>
                    <div className={classNames("ndvi qtransit", {"gray": !active, "blue": active})}/>
                    {curProduct}
                    <div className={classNames("arrow_pref qtransit", {"gray": !active, "blue": active, "any-skale-x": active})}/>
                </button>
            </MyHint>
        </div>;
    }
}
