import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCellsPanelComp.scss'
import classNames from "classnames";
import {action, observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat, CellItem} from "../../agroAhoCardStore";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoSortSvg} from "../icons/AgroAhoSortSvg";
import {AgroAhoWindowComp} from "../AgroAhoWindowComp";
import {AgroAhoCardFieldItemComp} from "../card/AgroAhoCardFieldItemComp";
import {AgroAhoCardCellItemComp} from "../card/AgroAhoCardCellItemComp";
import {AhoUtils} from "../../agroAhoUtils";
import {AgroAhoSwitcherSvg} from "../icons/AgroAhoSwitcherSvg";
import {AgroAhoPencilSvg} from "../icons/AgroAhoPencilSvg";
import {TextUtils} from "../../../../app/helper/utils/TextUtils";

@observer
export class AgroAhoCellsPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClose(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.cellsShow = false;
    }

    @action
    filterChanged(e:any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.doCellFilterChanged(e.target.value);
    }

    onClickVisFields(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        if (!cstore.getFieldSelected()?.length) return;
        cstore.cellsVisFields = !cstore.cellsVisFields;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let total = 0;
        let cellsItems: CellItem[] = null;
        let cellFiltered = 0;
        if (cstore.cellsItems) {
            cellsItems  = cstore.cellsItems;
            let selFieldIds = cstore.fieldsItems.filter((f: any)=>f.checked).map(f=>f.field_id);
            if (selFieldIds.length && cstore.cellsVisFields) cellsItems = cellsItems.filter(c=>
                selFieldIds.indexOf(c.field_id) >= 0);
            let isNum = cellsItems.every(i=>AhoUtils.isNum(i.cell_name));
            if (isNum) cellsItems = cellsItems.slice().sort((a,b)=>
                cstore.sortCellDesc ? +b.cell_name - +a.cell_name : +a.cell_name - +b.cell_name);
            if (!isNum) cellsItems = cellsItems.slice().sort((a,b)=>
                cstore.sortCellDesc ? TextUtils.smartTextSort(a.cell_name, b.cell_name) :
                TextUtils.smartTextSort(b.cell_name, a.cell_name));
            cellFiltered = cellsItems.filter(c=>c.filtered).length;
        }
        let fieldsFiltered = cellsItems && cstore.cellsItems?.length !== cellsItems.length;
        let cellsFound = (cellsItems?.length || 0) - cellFiltered;
        let selFields = cstore.getFieldSelected()?.length || 0;



        // console.log('cellsItems', AhoUtils.cp(cellsItems));
        // if (cstore.cellsItems) {
        //     let selectedItems = cstore.CellsItems.filter((f: any)=>f.checked);
        //     selected = selectedItems.length;
        //     // if (selected == cstore.CellsItems.length) selected = 0;
        //     selectedItems.forEach(i=>{
        //         total += i.area_ha;
        //     });
        // }
        total = total && parseFloat(total.toFixed(1));
        total = store_.numWithSpaces(total);
        // let area: any = cstore.card.cell_info.sum_area_ha && parseFloat(cstore.card.field_info.sum_area_ha.toFixed(1));
        let area = '0';
        area = store_.numWithSpaces(area);

        return <div className="AgroAhoCellsPanelComp-main">
            <div className="AgroAhoCellsPanelComp-closer" onClick={this.onClose}>
                <div className="close-button-icon"/>
            </div>
            <div className="AgroAhoCellsPanelComp-header">
                <div>{store.trans.Cells}</div>
                <div className={classNames("AgroAhoCellsPanelComp-stat",
                    {"AgroAhoCellsPanelComp-hidden": !cstore.cellsShow || !cellsItems})}>
                    {!!cstore.searchCellFilter && <span>{store.trans.Found}:&nbsp;{cellsFound}&nbsp;{
                        store_.trans.pcs}</span>}
                    {!cstore.searchCellFilter && !fieldsFiltered &&
                        <span>{store_.trans["Total cells"]}:&nbsp;{cstore.card.cell_info.cell_cnt}&nbsp;{
                            store_.trans.pcs}</span>}
                    {!cstore.searchCellFilter && fieldsFiltered &&
                        <span>{store.trans["Cells:"]}&nbsp;{cellsItems?.length}&nbsp;/&nbsp;{
                            cstore.card.cell_info.cell_cnt}&nbsp;{store_.trans.pcs}</span>}
                </div>
            </div>
            <div className="AgroAhoCellsPanelComp-listHeader">
                <input type="text" className="AgroAhoCellsPanelComp-filter text-box-editor"
                       value={cstore.searchCellFilter} placeholder={store_.trans["Search by cell name"]}
                       onChange={this.filterChanged}/>
                {/*<AgroAhoPencilSvg className="AgroAhoCellsPanelComp-editSvg" onClick={cstore.doCellEditClick}/>*/}
                <AgroAhoSortSvg className="AgroAhoCellsPanelComp-sortSvg" desc={cstore.sortCellDesc}
                    onClick={cstore.doCellSortClick}/>
            </div>

            <div className={classNames("AgroAhoCellsPanelComp-switcherCover",
                {"AgroAhoCellsPanelComp-switcherDisabled": !selFields})} onClick={this.onClickVisFields}>
                <AgroAhoSwitcherSvg className="AgroAhoCellsPanelComp-switcher" checked={cstore.cellsVisFields}/>
                <span>{store_.trans["Show cells of selected fields only"]}
                    {/*{!!selFields && <span> ({selFields})</span>}*/}
                </span>
            </div>

            <div className="AgroAhoCellsPanelComp-CellsCover style-4">
                {cellsItems && cellsItems.map((f: any)=><AgroAhoCardCellItemComp key={f.cell_id}
                    store={store} cell={f}/>)}
            </div>
        </div>
    }
}
