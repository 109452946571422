import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {LeftSidebarRightTop} from "./LeftSidebarRightTop";
import {SearchSceneButtonsComp} from "./SearchPanel/SearchSceneButtonsComp";
import {SearchSceneFiltersComp} from "./SearchPanel/SearchSceneFiltersComp";
import {SearchSceneListComp} from "./SearchPanel/SearchSceneListComp";
import {SearchSceneTitleComp} from "./SearchPanel/SearchSceneTitleComp";
import {SearchSceneFooterComp} from "./SearchPanel/SearchSceneFooterComp";
import {LeftPanelMode, SceneMosaicSwitch, SceneUiType} from "../../../store/SearchStore";
import {FavListComp} from "./FavoritePanel/FavListComp";
import {FavTopMenuComp} from "./FavoritePanel/FavTopMenuComp";
import autoBindReact from "auto-bind/react";
import {AgroPanelComp} from "./Agro2/ProjectEditor/AgroPanelComp";
import {PhotoPanelComp} from "./PhotoPanel/PhotoPanel/PhotoPanelComp";
import {ShowRoomPanelComp} from "./ShowRoom/ShowRoomPanelComp";
import {SoilGridsPanelComp} from "./SoilGrids/SoilGridsPanelComp";
import {Export2PanelComp} from "./Export2Panel/Export2PanelComp";
import classNames from "classnames";
import {ShowRoom2} from "./dataShowRoom2/ShowRoom2";
import {Agro2CreateField} from "./Agro2/A2FieldEdit/Agro2CreateField";
import {A2UploadFormComp} from "./Agro2/A2Upload/A2UploadFormComp";
import {A2UploadSelectFileComp} from "./Agro2/A2Upload/A2UploadSelectFileComp";
import {A2FieldEditorTitleComp} from "./Agro2/A2FieldEdit/A2FieldEditorTitleComp";
import {A2SubModeLeftPanel} from "../../../store/agro/A2AgroStore";
import {SearchScenePlugComp} from "./SearchPanel/SearchScenePlugComp";
import {EgrnPanelComp} from './Egrn/EgrnPanelComp';
import {AgroProjectCreateComp} from "./Agro2/ProjectEditor/AgroProjectCreateComp";
import FavSceneTitleComp from './FavoritePanel/FavSceneTitleComp';


export class ElementHolder{
    element: HTMLElement;
}

@observer
export class LeftSidebarRight extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.uploadInput = new ElementHolder();
    }

    onAnimationEnd(){
        this.props.store.map.mapbox.resize();
    }

    uploadInput: ElementHolder;

    onChangeUploadRefInput3(elem: HTMLInputElement){
        this.uploadInput.element = elem;
    }

    render() {
        let store = this.props.store;
        let sp = store.searchPanel;
        let showList = (store.map.searchObject.isNotEmpty || store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.scene);
        let children = store.events.onLeftPanelChildren.call([]);
        let compareMode = store.map.compareModeEnabled;
        let hasResults = store.searchPanel.favoriteList.groups.length > 0;
        return <div className={classNames("transit-map","left-sidebar__right", this.props.className)} onAnimationEnd={this.onAnimationEnd}  onTransitionEnd={this.onAnimationEnd}>
            <LeftSidebarRightTop store={store} />
            {store.searchPanel.leftPanelMode == LeftPanelMode.search &&
                <React.Fragment>
                    <div className="left-sidebar__right-bottom" id="select">
                        <SearchSceneTitleComp store={store} />
                        <SearchSceneFiltersComp store={store} />
                        <SearchSceneButtonsComp store={store} sceneUiType={SceneUiType.current} enable={true}/>

                        {!showList && <SearchScenePlugComp store={store} />}
                        {showList && <SearchSceneListComp store={store} list={store.searchPanel.searchResult}/>}

                    </div>
                    {store.searchPanel.leftPanelMode == LeftPanelMode.search && showList && !compareMode &&
                        store.searchPanel.showSearchList() &&
                        <SearchSceneFooterComp store={store} />
                    }
                </React.Fragment>
            }
            {store.searchPanel.leftPanelMode == LeftPanelMode.favorite &&
                <React.Fragment>
                    <div className="left-sidebar__right-bottom flex-stretch-item" id="select">
                        <FavSceneTitleComp title={store.trans['Selected images']}/>
                        <SearchSceneButtonsComp store={store} sceneUiType={SceneUiType.favorite} enable={hasResults} />
                        {hasResults && <FavTopMenuComp store={store} />}
                        <FavListComp store={store} list={store.searchPanel.favoriteList}  reorder/>
                    </div>
                </React.Fragment>}

            { store.searchPanel.leftPanelMode == LeftPanelMode.agro && store.agro2.subModeLeftPanel == A2SubModeLeftPanel.tree &&
                <AgroPanelComp store={store} uploadInput={this.uploadInput} />
            }
            { store.searchPanel.leftPanelMode == LeftPanelMode.agro &&
            store.agro2.subModeLeftPanel == A2SubModeLeftPanel.editOrCreateField &&
            <React.Fragment>
                <A2FieldEditorTitleComp store={store}/>
                <Agro2CreateField store={store} className="Agro2Common-topLeftBorder" />
            </React.Fragment>
            }
            {store.searchPanel.leftPanelMode == LeftPanelMode.createProject && <AgroProjectCreateComp store={store} isCreate={true} />}
            {store.searchPanel.leftPanelMode == LeftPanelMode.editProject && <AgroProjectCreateComp store={store} isCreate={false} />}

            { store.searchPanel.leftPanelMode == LeftPanelMode.photo &&
                <PhotoPanelComp store={store} />
            }
            { store.searchPanel.leftPanelMode == LeftPanelMode.showRoom &&
                <ShowRoomPanelComp store={store} />
            }
            { store.searchPanel.leftPanelMode == LeftPanelMode.soilGrid &&
                <SoilGridsPanelComp store={store} />
            }
            { store.searchPanel.leftPanelMode == LeftPanelMode.orders &&
            <Export2PanelComp store={store} />
            }


            { store.searchPanel.leftPanelMode == LeftPanelMode.showRoom2 &&
                <ShowRoom2 store={store} />
            }
            { store.searchPanel.leftPanelMode == LeftPanelMode.uploadFields &&
                <React.Fragment>
                    <A2FieldEditorTitleComp store={store}/>
                    <A2UploadFormComp store={store} />
                </React.Fragment>
            }
            { store.searchPanel.leftPanelMode == LeftPanelMode.test &&
                <EgrnPanelComp store={store} />
            }
            {children}
            <A2UploadSelectFileComp store={store} onChangeRefInput={this.onChangeUploadRefInput3} />
        </div>;
    }
}
