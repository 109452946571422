import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class EllapseGroupSvg extends CustomSvg {
    render() {
        return <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()}>
            <path d="M0.625009 1.25006C0.625046 1.07703 0.676384 0.90789 0.772532 0.764031C0.868682 0.620173 1.00532 0.508051 1.16519 0.441839C1.32505 0.375628 1.50095 0.3583 1.67066 0.392047C1.84037 0.425793 1.99626 0.509099 2.11863 0.631433L5.00001 3.51281L7.88139 0.631433C8.04641 0.472045 8.26744 0.383849 8.49686 0.385843C8.72628 0.387836 8.94574 0.47986 9.10798 0.642092C9.27021 0.804324 9.36223 1.02378 9.36422 1.25321C9.36622 1.48263 9.27802 1.70366 9.11863 1.86868L5.61863 5.36868C5.45455 5.53272 5.23203 5.62487 5.00001 5.62487C4.76799 5.62487 4.54547 5.53272 4.38138 5.36868L0.881385 1.86868C0.717278 1.70463 0.625059 1.4821 0.625009 1.25006Z" fill="#C5C5C5"/>
        </svg>
    }
}
