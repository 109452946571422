import autoBind from "auto-bind";

export class DelayRunOnce {
    constructor() {
        autoBind(this);
    }
    timer: any = null;
    funcRun: ()=>any = null;

    //при повторном запуске таймер сбрасывается и начинается заного
    runOnceReset(func: ()=>any, timeout: number){
        this.clearTimer();
        this.funcRun = func;
        this.timer = setTimeout(this.timerExecuter, timeout);
    }
    //при повторном запуске таймер не сбрасывется
    runOnce(func: ()=>any, timeout: number){
        this.funcRun = func;
        if (this.timer == null) {
            this.timer = setTimeout(this.timerExecuter, timeout);
        }
    }
    private timerExecuter(){
        this.timer = null;
        this.funcRun();
    }
    clearTimer(){
        if (this.timer != null){
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
}