// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoSwitcherSvg-cover {
  fill: #C5C5C5;
}

.AgroAhoSwitcherSvg-circle {
  transition: all 0.2s ease-in-out;
  fill: #2B3138;
  transform: translateX(-8.8px);
}

.AgroAhoSwitcherSvg-checked .AgroAhoSwitcherSvg-cover {
  fill: #4DB6BC;
}

.AgroAhoSwitcherSvg-checked .AgroAhoSwitcherSvg-circle {
  transform: translateX(0);
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/icons/AgroAhoSwitcherSvg.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,gCAAA;EACA,aAAA;EACA,6BAAA;AAEF;;AAAA;EACE,aAAA;AAGF;;AADA;EACE,wBAAA;AAIF","sourcesContent":[".AgroAhoSwitcherSvg-cover {\r\n  fill: #C5C5C5;\r\n}\r\n.AgroAhoSwitcherSvg-circle {\r\n  transition: all .2s ease-in-out;\r\n  fill: #2B3138;\r\n  transform: translateX(-8.8px);\r\n}\r\n.AgroAhoSwitcherSvg-checked .AgroAhoSwitcherSvg-cover {\r\n  fill: #4DB6BC;\r\n}\r\n.AgroAhoSwitcherSvg-checked .AgroAhoSwitcherSvg-circle {\r\n  transform: translateX(0);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
