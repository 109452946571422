import {CustomStore} from "../CustomStore";
import {update} from "../PermalinkDecor";
import {computed, observable} from "mobx";
import {ProductSettingStore} from "./ProductSettingStore";
import {ActiveProductCodeStore} from "./ActiveProductCodeStore";
import {SceneUiType} from "../SearchStore";
import { IProductConfig } from "../config/ConfigStore";

export class ProductSetStore extends CustomStore{

    // @update @observable
    // products: ProductSettingStore[] = [];

    @update @observable activeCodeCurrent: ActiveProductCodeStore = new ActiveProductCodeStore(this);

    @update @observable activeCodeFavorite: ActiveProductCodeStore = new ActiveProductCodeStore(this);

    getProductByProdName(prod_name: string, sceneUiType: SceneUiType): ProductSettingStore{
        return this.getActiveCodeByUiType(sceneUiType).products.find(a => a.prod_name == prod_name);
    }

    getActiveCodeByUiType(sceneUiType: SceneUiType): ActiveProductCodeStore{
        if (sceneUiType == SceneUiType.current) return this.activeCodeCurrent;
        if (sceneUiType == SceneUiType.favorite) return this.activeCodeFavorite;
        throw "Unknow SceneUiType";
    }
    // getActiveProductBySceneUiType(sceneUiType: SceneUiType): ProductSettingStore{
    //     return this.getProductByProdName(this.getActiveCodeByUiType(sceneUiType).productCode);
    // }
    
    addProduct(cfg: IProductConfig) {
        [this.activeCodeCurrent, this.activeCodeFavorite].forEach(store => {
            let ps = new ProductSettingStore(this);
            ps.prod_name = cfg.tile_prod_name;
            ps.unitName = cfg.unitName;
            ps.unitDescription = cfg.unitDescription;
            ps.productConfig = cfg;
            ps.loadDefault();
            store.products.push(ps);
        });
    }
   
}