import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoProgressComp.scss'
import {IAgroAhoStoreProps} from "../agroAhoPlugin";


@observer
export class AgroAhoProgressComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store.agroAhoStore;

        return <div className="AgroAhoProgressComp-main">
            <div className="AgroAhoProgressComp-line" style={{width: store.uploadIndcStore.ahoProgress + '%'}}> </div>
        </div>
    }
}
