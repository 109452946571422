import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {SoilGridsSourceStore} from "../../../../store/solidGrids/SoilGridsSourceStore";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {RadioButton} from "../../../Common/RadioButton";
import {action} from "mobx";
import classNames from "classnames";
import {SoilGridsSourceDepthComp} from "./SoilGridsSourceDepthComp";
import './SoilGridsSourceDepthComp.scss';
import {SoilGridsSourcePalleteComp} from "./SoilGridsSourcePalleteComp";
import {SoilGridsSourceClassesComp} from "./SoilGridsSourceClassesComp";

export interface ISoilGridsSourceComp extends IStoreProps{
    source: SoilGridsSourceStore
}
@observer
export class SoilGridsSourceComp extends React.Component<ISoilGridsSourceComp, undefined> {
    constructor(props: ISoilGridsSourceComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onCheckSource(){
        this.props.store.soilGrids.setCheckSource(this.props.source);
    }
    @action
    onExpand(){
        this.props.store.soilGrids.setExpandSource(this.props.source, !this.props.source.extend);
    }

    @action
    onClickLayer(index: number){
        this.props.source.currentLayerIndex = index;
    }

    render() {
        let store = this.props.store;
        let source = this.props.source;

        let layers = source.layers.map(a => a.title);

        let labels: string[] = [];
        labels.push(source.minValue.toString());
        labels.push(Math.round((source.maxValue - source.minValue)/2).toString());
        labels.push(source.maxValue.toString());

        return <div className={classNames("SoilGridsSource-main", {"SoilGridsSource-mainExpanded": source.extend} )}>
            <div className="SoilGridsSource-topDiv">
                <RadioButton checked={source.checked} onClick={this.onCheckSource} />
                <div className="flex-stretch-item SoilGridsSource-topDivName pointer" onClick={this.onCheckSource}>{source.sources_title}</div>
                <div className="SoilGridsSource-topDivUnits" >{source.units_legend}</div>
                <div className={classNames("SoilGridsSource-topDivArrow qtransit",{"visible-important rotate90": source.extend})} onClick={this.onExpand}>
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.00001 11.4L0.600006 10L4.60001 6L0.600006 2L2.00001 0.599998L7.40001 6L2.00001 11.4Z" fill="#C5C5C5"/>
                    </svg>
                </div>
            </div>
            {source.classes.length > 0 && source.extend &&
                    <SoilGridsSourceClassesComp store={store} classes={source.classes} />
            }
            {source.classes.length == 0  && source.extend &&
            <div className="SoilGridsSource-expandDiv">
                <div className="SoilGridsSource-expandTools">
                    <div className="SoilGridsSource-expandTools-depth">
                        <div className="SoilGridsSource-expandTools-title">Depth (in cm)</div>
                        <SoilGridsSourceDepthComp items={layers} currentIndex={this.props.source.currentLayerIndex} onClick={this.onClickLayer} />
                    </div>
                    <div className="SoilGridsSource-expandTools-pallete">
                        <div className="SoilGridsSource-expandTools-title">Legend (in {source.units_legend})</div>
                        <div className="SoilGridsSource-expandTools-rightPallete">
                            <SoilGridsSourcePalleteComp colors={source.pallete} labels={labels} />
                        </div>
                    </div>
                </div>

            </div>}
        </div>;
    }
}
