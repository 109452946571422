import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {action, autorun, observable, toJS} from "mobx";
import {save, update} from "../PermalinkDecor";
import {cloneDeep, isArray, isNumber, isUndefined} from "lodash-es";
import {Utils} from "../../helper/utils/Utils";
import {IndexByPointGraphStore} from "./IndexByPointGraphStore";
import {MBUtils} from "../../helper/utils/MBUtils";
import {WindowTriState} from "../../helper/structs/WindowTriState";
import {SyncUtils} from "../../helper/utils/SyncUtils";
import {SuperStore} from "../SuperStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {ra} from "../../helper/utils/mobxUtils";
import {
    IndexByPointYearStore,
    IYearPeriod
} from "./IndexByPointYearStore";
import {
    IndexAreaType,
    IndexByPointGraphPointStore
} from "./IndexByPointGraphPointStore";
import {Satellite} from "../../helper/utils/satellliteDic";
import {TranslateUtils} from "../../helper/lang/TranslateUtils";
import {IConfigStore} from "../config/ConfigStore";
import urlJoin from "url-join";
import {Translate_en} from "../../helper/lang/Translate_en";
import {ColorHelper} from "../../helper/utils/ColorHelper";

export enum Polarization {
    VV = "VV",
    VH = "VH",
    HH = "HH",
    HV = "HV"
}
export enum IndexDataGroup{
    spectral='spectral',
    radar_iw='radar_iw',//Радиолокационная съемка
    radar_ew='radar_ew',//Радиолокационная съемка
    modis='modis',
    temperature='temperature',
    precipitation='precipitation',//осадки
    solarRadiation='solarRadiation',//Солнечная радиация
    relativeHumidity='relativeHumidity',//Относительная влажность
    soilMoisture='soilMoisture',
    snowDepth='snowDepth'
}
export enum IndexDataType {
    modis_ndvi = "modis_ndvi",
    modis_TERRA_RED = "modis_TERRA_RED",
    modis_TERRA_NIR = "modis_TERRA_NIR",

    temperatureNOAA = 'temperatureNOAA',
    precipitationNOAA = 'precipitationNOAA',
    vegIndex = 'vegIndex',
    ndvi250 = 'ndvi250',
    temperatureEra5 = 'temperatureEra5',
    temperatureIbm = 'temperatureIbm',
    temperatureAvg = 'temperatureAvg',
    precipitationEra5 = 'precipitationEra5',
    precipitationAvg = 'precipitationAvg',
    precipitationIbm = 'precipitationIbm',
    solarRad = 'solarRad',
    humidityEra5 = 'humidityEra5',
    humidityIbm = 'humidityIbm',
    radar_s1_iw = 'radar_s1_iw',
    radar_s1_ew = 'radar_s1_ew',
    temperatureMeteoStation = 'temperatureMeteoStation',
    precipitationMeteoStation = 'precipitationMeteoStation',
    soilMoistureSmap = 'soilMoistureSmap',
    soilMoistureMeteoStation = 'soilMoistureMeteoStation',
    snowDepthSmap = 'snowDepthSmap'
}

export type IndexDataGroups = {
    [key in IndexDataGroup]: IndexDataType[];
};
// Группы по типам
let _IndexDataGroups: IndexDataGroups = {
    modis:[IndexDataType.modis_ndvi, IndexDataType.modis_TERRA_RED, IndexDataType.modis_TERRA_NIR],
    spectral:[IndexDataType.vegIndex],
    radar_iw:[IndexDataType.radar_s1_iw],
    radar_ew:[IndexDataType.radar_s1_ew],
    temperature:[IndexDataType.temperatureNOAA, IndexDataType.temperatureEra5, IndexDataType.temperatureAvg, IndexDataType.temperatureMeteoStation],
    precipitation:[IndexDataType.precipitationNOAA, IndexDataType.precipitationEra5, IndexDataType.precipitationAvg, IndexDataType.precipitationMeteoStation],
    solarRadiation:[IndexDataType.solarRad],
    relativeHumidity:[IndexDataType.humidityEra5],
    soilMoisture:[IndexDataType.soilMoistureSmap, IndexDataType.soilMoistureMeteoStation],
    snowDepth:[IndexDataType.snowDepthSmap]
}
//Типы которые не используются в сравненнии источников
let _ignoreCompareIndexDataType = [
    IndexDataType.temperatureAvg,
    IndexDataType.precipitationAvg
];

let _DefaultIndexType: {[key in IndexDataGroup]: IndexDataType} = {
    [IndexDataGroup.modis]: IndexDataType.modis_ndvi,
    [IndexDataGroup.precipitation]: IndexDataType.precipitationAvg,
    [IndexDataGroup.temperature]: IndexDataType.temperatureAvg,
    [IndexDataGroup.spectral]: IndexDataType.vegIndex,
    [IndexDataGroup.relativeHumidity]: IndexDataType.humidityEra5,
    [IndexDataGroup.radar_iw]: IndexDataType.radar_s1_iw,
    [IndexDataGroup.radar_ew]: IndexDataType.radar_s1_ew,
    [IndexDataGroup.solarRadiation]: IndexDataType.solarRad,
    [IndexDataGroup.soilMoisture]: IndexDataType.soilMoistureSmap,
    [IndexDataGroup.snowDepth]: IndexDataType.snowDepthSmap
}

export function getIgnoreCompareIndexDataType(): IndexDataType[] {
    return _ignoreCompareIndexDataType;
}
export function getIndexDataGroups(): IndexDataGroups {
    return _IndexDataGroups;
}

export function loadSettingIndexByPointFromConfig(
    config: IConfigStore
) {
    if (config.indexByPoint?.indexDataGroups) {
        _IndexDataGroups = config.indexByPoint?.indexDataGroups;
    }
    if (config.indexByPoint?.defaultIndexType) {
        _DefaultIndexType = config.indexByPoint?.defaultIndexType;
    }
}

// Типы по группам
type IndexDataTypes = {[key in IndexDataType]: IndexDataGroup};
let _IndexDataTypes: IndexDataTypes;
export function getIndexDataTypes(): IndexDataTypes {
    if (_IndexDataTypes != null) return _IndexDataTypes;
    _IndexDataTypes = {} as any;
    let dg = getIndexDataGroups();
    for (let k in dg) {
        let arr = (dg as any)[k];
        for (let v of arr) {
            (_IndexDataTypes as any)[v] = k;
        }
    }
    return _IndexDataTypes;
}

export function getDefaultIndexType(
    group: IndexDataGroup
): IndexDataType {
    return _DefaultIndexType[group];
}
export function groupHasClimate(group: IndexDataGroup): boolean {
    return (
        group == IndexDataGroup.temperature ||
        group == IndexDataGroup.precipitation
    );
}
export function getNameOfIndexDataType(
    dataType: IndexDataType,
    store: SuperStore
): string {
    switch (dataType) {
        case IndexDataType.modis_ndvi:
            return "MOD13, NDVI";
        case IndexDataType.modis_TERRA_RED:
            return "MOD09, RED";
        case IndexDataType.modis_TERRA_NIR:
            return "MOD09, NIR";

        case IndexDataType.vegIndex:
            return store.trans.NDVI;
        case IndexDataType.ndvi250:
            return store.trans["NDVI (250 m)"];
        case IndexDataType.temperatureNOAA:
            return store.trans["NOAA (since 2015)"];
        case IndexDataType.temperatureEra5:
            return store.trans["ERA5 (since 2010)"];
        case IndexDataType.temperatureAvg:
            return "(NOAA+ERA5)/2";
        case IndexDataType.temperatureMeteoStation:
            return store.trans["Weather stations MTS"];
        case IndexDataType.precipitationNOAA:
            return store.trans["NOAA (since 2015)"];
        case IndexDataType.precipitationEra5:
            return store.trans["ERA5 (since 2010)"];
        case IndexDataType.precipitationIbm:
            return store.trans["IBM"];
        case IndexDataType.precipitationAvg:
            return "(NOAA+ERA5)/2";
        case IndexDataType.precipitationMeteoStation:
        case IndexDataType.soilMoistureMeteoStation:
        case IndexDataType.temperatureMeteoStation:
            return store.trans["Weather stations MTS"];
        case IndexDataType.soilMoistureSmap:
        case IndexDataType.snowDepthSmap:
            return store.trans["SMAP"];
        case IndexDataType.solarRad:
            return store.trans["ERA5 (since 2010)"];
        case IndexDataType.humidityEra5:
            return store.trans["ERA5 (since 2010)"];
        case IndexDataType.radar_s1_ew:
            return "Sentinel-1 EW";
        case IndexDataType.radar_s1_iw:
            return "Sentinel-1 IW";
        case IndexDataType.humidityIbm:
            return store.trans["IBM"];
    }
}

export function getShortNameOfIndexDataType(
    dataType: IndexDataType,
    store: SuperStore,
    translate: boolean = true
): string {
    let tr = translate ? store.trans : new Translate_en();
    switch (dataType) {
        case IndexDataType.modis_ndvi:
            return "MODIS NDVI";
        case IndexDataType.modis_TERRA_RED:
            return "TERRA-RED";
        case IndexDataType.modis_TERRA_NIR:
            return "TERRA-NIR";
        case IndexDataType.vegIndex:
            return tr.NDVI;
        case IndexDataType.ndvi250:
            return tr["NDVI (250 m)"];
        case IndexDataType.temperatureNOAA:
            return tr["NOAA"];
        case IndexDataType.temperatureEra5:
            return tr["ERA5"];
        case IndexDataType.temperatureAvg:
            return "(NOAA+ERA5)/2";
        case IndexDataType.temperatureMeteoStation:
            return tr["Weather stations MTS"];
        case IndexDataType.precipitationNOAA:
            return tr["NOAA"];
        case IndexDataType.precipitationEra5:
            return tr["ERA5"];
        case IndexDataType.precipitationAvg:
            return "(NOAA+ERA5)/2";
        case IndexDataType.precipitationIbm:
            return tr["IBM"];
        case IndexDataType.soilMoistureSmap:
        case IndexDataType.snowDepthSmap:
            return tr["SMAP"];
        case IndexDataType.precipitationMeteoStation:
        case IndexDataType.soilMoistureMeteoStation:
        case IndexDataType.temperatureMeteoStation:
            return tr["Weather stations MTS"];
        case IndexDataType.solarRad:
            return tr["ERA5"];
        case IndexDataType.humidityEra5:
            return tr["ERA5"];
        case IndexDataType.radar_s1_ew:
            return "Sentinel-1 EW";
        case IndexDataType.radar_s1_iw:
            return "Sentinel-1 IW";
        case IndexDataType.humidityIbm:
            return tr["IBM"];
    }
}

export enum PrecipitationCategory {
    daily = "daily",
    accumulated = "accumulated0"
}
export enum SolarRadCategory {
    daily = "daily",
    accumulated = "accumulated0"
}

export enum TemperatureCategory {
    minDaily = "minDaily",
    maxDaily = "maxDaily",
    averageDaily = "averageDaily",
    averageMinMaxDaily = "averageMinMaxDaily",
    accumulated0 = "accumulated0",
    accumulated5 = "accumulated5",
    accumulated10 = "accumulated10"
}

export interface YearInfo {
    year: number;
    isClimate: boolean;
}

export class IndexByPointerStore extends ObservableCustomStore {
    constructor(parent: CustomStore) {
        super(parent);
    }
    class(): string {
        return "IndexByPointerStore";
    }

    subscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.syncGraphSet();
            })
        ];
    }
    predefinedColors = [
        "#F26D91",
        "#04ADBF",
        "#EB3C2C",
        "#23FA93",
        "#E36020",
        "#147FFF",
        "#FFF232",
        "#8A95A2",
        "#F9CB7D",
        "#C5A8FF",
        "#64C733",
        "#CAEA5D",
        "#FB198B",
        "#8EEBED",
        "#A6583C"
    ];
    readonly MAX_POINTS = 10;
    @save
    @observable
    showPanel: WindowTriState = WindowTriState.close;
    @save
    @observable
    hidePanel: boolean = false;
    @save
    @observable
    maxPanel: boolean = false;
    @save
    @observable
    radius: string = "";
    @save
    @observable
    productCode: string = "NDVI";
    @save
    @observable
    satellites: Satellite[] = [Satellite["Sentinel-2"]];
    @save
    @observable
    showVertex: boolean = true;
    @save
    @observable
    monthBegin: number = 0;
    @save
    @observable
    monthEnd: number = 11;
    @save @observable colorMode:
        | "everyYear"
        | "everyPoint"
        | "everyChar" = "everyChar";
    @action
    setColorMode(mode: "everyYear" | "everyPoint" | "everyChar") {
        this.colorMode = mode;
    }
    get isCrossYear() {
        return this.monthEnd < this.monthBegin;
    }
    getBeginDate(year: number): Date {
        return new Date(year, this.monthBegin, 1);
    }
    getEndDate(
        year: number,
        firstDayNextMonth: boolean = false
    ): Date {
        if (this.isCrossYear)
            return new Date(
                year + 1,
                this.monthEnd + 1,
                firstDayNextMonth ? 1 : 0
            );
        return new Date(
            year,
            this.monthEnd + 1,
            firstDayNextMonth ? 1 : 0
        );
    }

    @save
    @observable
    dataGroup: IndexDataGroup = IndexDataGroup.spectral;
    @save
    @observable
    temperatureCategory = TemperatureCategory.averageDaily;
    @save
    @observable
    precipitationCategory = PrecipitationCategory.accumulated;
    @save
    @observable
    solarRadCategory = SolarRadCategory.accumulated;

    @save
    @observable
    polarIW = Polarization.VV; //vh
    @save
    @observable
    polarEW = Polarization.HH; //hv

    @save
    @observable
    compareSources: boolean = false;
    @save
    @observable
    typesData: IndexDataType[] = [IndexDataType.vegIndex];

    @save
    @observable
    yearPeriodsData: IYearPeriod[] = [
        {year: IndexByPointerStore.defaultYear(), isClimate: false}
    ];

    @update
    @observable
    points: IndexByPointGraphPointStore[] = []; //точки/поля
    @save
    @observable
    graphs: IndexByPointGraphStore[] = []; //отдельные графики
    @save
    @observable
    yearPoints: IndexByPointYearStore[] = []; //года точек, для каждой точки и каждого года

    @save
    @observable
    topGraphId: string = ""; //какой
    @save
    @observable
    currentGraphId: string = "";

    syncGraphSet() {
        let arr2: IndexByPointGraphStore[] = [];
        let colors = this.graphs
            .map(a => a.color)
            .filter(a => a != null);
            let graphCounter = 0
        this.points.forEach(a => {
            a.yearPoints.forEach(y => {
                this.typesData.forEach(td => {
                    let g = this.addGraph(a, td, y.yearPeriod,);
                    g.visible = y.visible;
                    if (g.color == null) {
                        let col = MBUtils.getNewColor(colors);
                        colors.push(col);
                        g.color = col;
                    }
                    arr2.push(g);
                    graphCounter++
                });
            });
        });

        ra(() => {
            let newArr = SyncUtils.syncArraysByIdFunc(
                this.graphs,
                arr2,
                item => item.hashForCompare()
            );
            if (
                !SyncUtils.compareArraysByIdFunc(
                    newArr,
                    this.graphs.slice(),
                    item => item.hashForCompare()
                )
            ) {
                this.graphs = newArr;
            }
        });
    }

    syncTypesByGroup() {
        let types = [...this.typesData];
        let compare = this.compareSources;
        let grp = this.dataGroup;
        //удаляем которые не относятся к данной ветке
        let to = getIndexDataTypes();
        ra(() => {
            this.typesData = types.filter(a => to[a] == grp);

            if (!compare) {
                if (this.typesData.length == 0) {
                    this.typesData.push(getDefaultIndexType(grp));
                } else {
                    this.typesData.length = 1;
                }
            }
        });
    }

    getNewNumPoint(): number {
        let num = -1;
        for (let i = 1; i < 10; i++) {
            let idx = this.points.findIndex(p => p.num == i);
            if (idx < 0) {
                num = i;
                break;
            }
        }
        return num;
    }
    @action
    applyColorModeToNewPoint(p: IndexByPointGraphPointStore): void {
        const yearColorMap: Map<string, string> = new Map();
        let baseColorIndex = this.points.length - 1;

        if (this.colorMode === "everyYear") {
            const uniqueYearsSet = new Set<string>();
            this.points.forEach(existingPoint => {
                existingPoint.yearPoints.forEach(yearPoint => {
                    const yearOrUniqueId = yearPoint.yearPeriod
                        .isClimate
                        ? `climate-${yearPoint.yearPeriod.year}`
                        : `${yearPoint.yearPeriod.year}`;
                    uniqueYearsSet.add(yearOrUniqueId);

                    if (!yearColorMap.has(yearOrUniqueId)) {
                        const color = ColorHelper.numToCssHexRgb(
                            yearPoint.color
                        );
                        yearColorMap.set(yearOrUniqueId, color);
                    }
                });
            });

            p.yearPoints.forEach(yearPoint => {
                const yearOrUniqueId = yearPoint.yearPeriod.isClimate
                    ? `climate-${yearPoint.yearPeriod.year}`
                    : `${yearPoint.yearPeriod.year}`;

                // Применяем новый цвет если не найден существующий для года
                if (!yearColorMap.has(yearOrUniqueId)) {
                    const newColor =
                        this.predefinedColors[
                            baseColorIndex %
                                this.predefinedColors.length
                        ];
                    yearColorMap.set(yearOrUniqueId, newColor);
                    baseColorIndex++;
                }

                const color = yearColorMap.get(yearOrUniqueId)!;
                const numericColor = ColorHelper.parseColor(color);
                const finalColor =
                    numericColor.r * 65536 +
                    numericColor.g * 256 +
                    numericColor.b;

                yearPoint.setCustomColor(finalColor);
                yearPoint.graphs?.forEach(graph => {
                    graph.color = finalColor;
                });
            });
        }
    }

    @action
    addPoint(lat: number, lon: number) {
        this.checkMaxPoints();
        let p = new IndexByPointGraphPointStore(this);
        p.areaType = IndexAreaType.point;
        p.point.set(lat, lon);
        p.num = this.getNewNumPoint();
        if (p.num < 0) return;
        this.points.push(p);
        this.otherAdd(p);
        this.applyColorModeToNewPoint(p);
    }
    @action
    addField(
        field_id: number,
        name: string,
        lat: number,
        lon: number
    ): IndexByPointGraphPointStore {
        this.checkMaxPoints();
        let p = new IndexByPointGraphPointStore(this);
        p.areaType = IndexAreaType.field;
        p.field_id = field_id;
        p.field_name = name;
        p.point.set(lat, lon);

        this.points.push(p);
        this.otherAdd(p);
        return p;
    }
    checkMaxPoints() {
        if (this.points.length == this.MAX_POINTS)
            throw TranslateUtils.format(
                this.root.trans["Maximum number of points: {0}"],
                this.MAX_POINTS
            );
    }

    getSingleYearPoint(): IndexByPointYearStore {
        return this.yearPoints.find(a => a.visible);
    }
    setSingleYearPoint(): void {
        let single = this.yearPoints.find(a => a.visible);
        if (single != null) {
            this.yearPoints.forEach(a => {
                if (a != single) {
                    a.visible = false;
                }
            });
        } else {
            if (this.yearPoints.length > 0) {
                this.yearPoints[0].visible = true;
            }
        }
    }

    otherAdd(p: IndexByPointGraphPointStore): void {
        if (this.compareSources) {
            this.setSingleYearPoint();
        }
        this.yearPeriodsData.forEach(y => {
            let t = new IndexByPointYearStore(this);
            t.idPoint = p.id;
            t.yearPeriod = cloneDeep(toJS(y));
            t.visible =
                !this.compareSources ||
                this.getSingleYearPoint() == null;
            this.yearPoints.push(t);
        });
    }

    removePoint(p: IndexByPointGraphPointStore): void {
        this.points = this.points.filter(a => a.id != p.id);
        this.graphs = this.graphs.filter(a => a.idPoint != p.id);
        this.yearPoints = this.yearPoints.filter(
            a => a.idPoint != p.id
        );
    }

    getProdStatUrl(): string {
        let s: string = "/api/prodstat";
        if (
            isArray(this.root.config.prodstat_domains) &&
            this.root.config.prodstat_domains.length > 0
        ) {
            let items: string[] = this.root.config.prodstat_domains;
            let domain =
                items[Math.floor(Math.random() * items.length)];
            s = urlJoin(domain, s);
        }
        return s;
    }
    @action
addGraph(
    p: IndexByPointGraphPointStore,
    type: IndexDataType,
    year: IYearPeriod
): IndexByPointGraphStore {
    let existingGraph = this.graphs.find(
        g => g.idPoint === p.id && g.yearPeriod.year === year.year && g.yearPeriod.isClimate === year.isClimate&&
        g.dataType === type
    );

    if (existingGraph) {
        return existingGraph;
    }
    let t = new IndexByPointGraphStore(this, {
        idPoint: p.id,
        productCode: this.productCode,
        color: null,
        dataType: type,
        radius: this.getRadiusNumber(),
        satellites: this.satellites,
        yearPeriod: year
    });

    let finalColor: number | null = null;
    const usedColors = new Set(this.graphs.map(g => g.color));
    if (this.colorMode === "everyYear") {
        const yearOrUniqueId = year.isClimate ? "climate" : `${year.year}`;
        const existingColor = this.yearPoints.find(yearPoint => {
            const uniqueId = yearPoint.yearPeriod.isClimate ? "climate" : `${yearPoint.yearPeriod.year}`;
            return uniqueId === yearOrUniqueId;
        })?.color;

        if (existingColor) {
            finalColor = existingColor;
        } else {
            finalColor = this.getUniqueColor(usedColors);
            usedColors.add(finalColor);
            this.yearPoints.forEach(y => {
                if (y.yearPeriod.year === year.year && y.yearPeriod.isClimate === year.isClimate) {
                    y.customColor = finalColor;
                }
            });
        }
    } 
    else if (this.colorMode === "everyPoint") {
        const pointIndex = this.points.findIndex(point => point.id === p.id);
        finalColor = this.getUniqueColor(usedColors, pointIndex);
        usedColors.add(finalColor);
    } 
    else if (this.colorMode === "everyChar") {
        const colorIndex = this.graphs.length % this.predefinedColors.length;
        finalColor = this.getUniqueColor(usedColors, colorIndex);
        usedColors.add(finalColor);
    }

    t.color = finalColor;
    this.graphs = [...this.graphs, t];
    return t;
}

getUniqueColor(usedColors: Set<number>, seedIndex: number = 0): number {
    const predefinedColorsCount = this.predefinedColors.length;

    for (let i = 0; i < predefinedColorsCount; i++) {
        const colorIndex = (seedIndex + i) % predefinedColorsCount;
        const colorHex = this.predefinedColors[colorIndex];
        const numericColor = ColorHelper.parseColor(colorHex);

        if (numericColor) {
            const colorNumber = numericColor.r * 65536 + numericColor.g * 256 + numericColor.b;

            if (!usedColors.has(colorNumber)) {
                return colorNumber;
            }
        }
    }

    const fallbackColor = this.predefinedColors[seedIndex % predefinedColorsCount];
    const fallbackNumeric = ColorHelper.parseColor(fallbackColor);
    return fallbackNumeric.r * 65536 + fallbackNumeric.g * 256 + fallbackNumeric.b;
}

    static defaultYear(): number {
        let now = new Date();
        let defaultYear: number = now.getFullYear();
        if (now.getMonth() < 3) {
            defaultYear = now.getFullYear() - 1;
        }
        return defaultYear;
    }

    @action
    clearPoints(): void {
        this.points = [];
        this.graphs = [];
    }

    @action
    deletePoint(p: IndexByPointGraphPointStore): void {
        Utils.arrayRemoveByValue(this.points, p);
    }

    getRadiusNumber(): number {
        if (
            isUndefined(this.radius) ||
            this.radius == null ||
            this.radius == ""
        )
            return null;
        let t = parseFloat(this.radius);
        if (isNumber(t)) return t;
        return null;
    }

    /* напамять Формирование файла для скачивания. Можент пригодиться
    downloadFile(){
        let txt = "sceneID,date,value\n";
        this.indexes.forEach(a => {
            txt += `${a.sceneID},${a.date},${a.stat[this.indexes_for_product].avg}\n`;
        });

        let e = document.createElement('a');
        e.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt));
        let fileName = 'ss.csv';
        e.setAttribute('download', fileName);
        e.style.display = 'none';
        document.body.appendChild(e);
        e.click();
        document.body.removeChild(e);
    }*/
}
