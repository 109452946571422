import {ObservableCustomStore} from "../../../../app/store/CustomStore";
import {AgroAhoMapStore} from "../agroAhoMapStore";
import {autorun, observable, reaction} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";


export class AgroAhoMapDebugStore extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;
    debugMode: boolean = false;
    @observable
    debugShow: boolean = false;
    debugInit: boolean = false;
    @observable
    min: boolean = false;
    @observable
    layers:  maplibregl.AnyLayer[] = [];
    @observable
    curLayer: maplibregl.AnyLayer = null;

    subscription(): IReactionDisposer[]{
        return [
            autorun( ()=>{if (!this.debugInit) this.init()})
        ];
    }

    init(){
        this.debugInit = true;
        if (!this.debugMode) return;
        console.log('MAP DEBUG MODE');
        this.debugShow = true;
    }

    onDelLayer(){
        if (!this.curLayer) return;
        this.doDelLayer(this.curLayer);
    }
    doDelLayer(layer: maplibregl.AnyLayer){
        let map = this.root.map.mapbox;
        map.removeLayer(layer.id);
        this.doRefresh();
    }
    doRefresh(){
        let map = this.root.map.mapbox;
        this.layers = map.getStyle().layers;
    }
    onLayerClick(layer: maplibregl.AnyLayer){
        this.curLayer = layer;
    }
    doTest(){
        console.log('map debug test click');
        this.mapStore.parentStore.cardStore.doUpdFieldsOpacity();
        // this.mapStore.mapRNStore.
    }

}