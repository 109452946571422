import {CustomStore} from "./CustomStore";
import {action, computed, observable} from "mobx";
import * as mapboxgl from "maplibre-gl";
import {LngLat, LngLatBounds, MapboxGeoJSONFeature, Point, RequestParameters, ResourceType, Style} from "maplibre-gl";
import bbox from "@turf/bbox";
import {MBUtils} from "../helper/utils/MBUtils";
import {FeatureCollection, Polygon} from "geojson";
import {CoordinateStringFormat} from "../helper/utils/CoordinateStringFormat";
import {MeasuringStatus} from "../helper/structs/MeasuringStatus";
import {Utils} from "../helper/utils/Utils";
import {IBaseLayer} from "./config/ConfigStore";
import {fetchJson} from "../helper/utils/FetchUtils";
import {OverlayObjectStore} from "./OverlayObjectStore";
import {MapRulerInfoStore} from "./map/MapRulerInfoStore";

import {SearchObjectStore} from "./SearchObjectStore";
import {save, update} from "./PermalinkDecor";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {SuperTools} from "./tools/general/SuperTools";
import {IDrawRulerState} from "./tools/class/RulerTool";
import {ProductsStore} from "./productSetting/ProductsStore";
import {PresetColors, PresetColorValue} from "./productSetting/IndexBandProductStore";
import {isArray, isFunction, isString} from "lodash-es";
import {ColorHelper} from "../helper/utils/ColorHelper";
import {NdviValueByMouseStore} from "./map/NdviValueByMouseStore";
import {ra} from "../helper/utils/mobxUtils";
import {ProductSetStore} from "./productSetting/ProductSetStore";
import {MapRootStore} from "./map/mapLayers/MapRootStore";
import {MapScenesStore} from "./MapScenesStore";
import {GibsOverlayStore} from "./GibsOverlayStore";
import {DapFieldsMaskOverlayStore} from "./DapFieldsMaskOverlayStore";
import {GfsWindOverlayStore} from "./GfsWindOverlayStore";
import {CreateGeometryType} from "./tools/general/ContainerTools";
import {LegendStore} from "./map/LegendStore";


export class MapStore extends CustomStore{
    class(): string {return "MapStore";}
    

    @update
    legend: LegendStore = new LegendStore(this);
    @save @observable coosFormat : CoordinateStringFormat = CoordinateStringFormat.abs;

    @update @observable
    private _center: PointStore = new PointStore(this);
    get center(): PointStore{ return this._center;}

    @save @observable
    zoom: number = 1;

    superMap: MapRootStore = new MapRootStore(this);

    @observable
    ndviValueByMouseStore: NdviValueByMouseStore = new NdviValueByMouseStore(this);

    @observable mousePointer: [number, number] = [null, null];

    @observable menuPointer: { point: Point, lngLat: LngLat} = null;
    @observable menu_field_id: number = null;
    @observable selectedFeature: MapboxGeoJSONFeature = null;

    @observable hintMousePointer: Point = null;
    @observable hintText: string = '';

    //@observable measuringMenuOpened: boolean = false;
    //@observable measuringMenuClosing: boolean = false;

    @save @observable layersMenuOpened: boolean = false;
    @observable permalinkPanelOpen: boolean = false;

    //@observable rulerHovered: boolean = false;

    @observable rulerPointer: { point: Point, lngLat: LngLat} = null;

    @observable private _compareModeEnabled: boolean = false;
    @computed get compareModeEnabled(): boolean {
        return this._compareModeEnabled;}
    @action setCompareModeEnabled() {
        this._compareModeEnabled = !this._compareModeEnabled;}
    @observable private _baseMapTitle: string = "";
    @computed get baseMapTitle(): string {
        return this._baseMapTitle;}
    @action setBaseMapTitle(title:string) {
        this._baseMapTitle = title;}
    @observable private _compareMapTitle: string = "";
    @computed get compareMapTitle(): string {
        return this._compareMapTitle;}
    @action setCompareMapTitle(title:string) {
        this._compareMapTitle = title;}
    
    @observable
    defaultMeasuringStatus: MeasuringStatus|string = MeasuringStatus.None;
    @observable
    private _measuringStatus: MeasuringStatus|string = MeasuringStatus.None;
    get measuringStatus(): MeasuringStatus|string{
        return this._measuringStatus;
    }
    @observable isMouseInsideMap: boolean = false;

    @update @observable productInfo: ProductsStore = new ProductsStore(this);
    @save @observable ndviPanelScrollPos = 0;

    @update @observable searchObject: SearchObjectStore = new SearchObjectStore(this);

    @update mapScenesStore: MapScenesStore = new MapScenesStore(this);


    @save @observable currentBaselayerKey: string = null;

    @update @observable overlays: OverlayObjectStore = new OverlayObjectStore(this);

    @update @observable gibs: GibsOverlayStore = new GibsOverlayStore(this);

    @update @observable dfm: DapFieldsMaskOverlayStore = new DapFieldsMaskOverlayStore(this);

    @update @observable gfsWind: GfsWindOverlayStore = new GfsWindOverlayStore(this);


    @save @observable
    private _extandMap: boolean = false;

    get extandMap(): boolean{ return this._extandMap;}

    private extandMapInterval: any = null;

    setExtandMap(value: boolean){
        this._extandMap = value;
        let clearTimer = ()=>{
            if (this.extandMapInterval != null) {
                clearInterval(this.extandMapInterval);
                this.extandMapInterval = null;
            }
        }
        this.extandMapInterval = setInterval(()=>{
            if(this.mapbox != null) this.mapbox.resize();
        }, 100);
        setTimeout(()=>{
            clearTimer();
            if (this.mapbox != null)
                this.mapbox.resize();
        }, 500);
    }

    @computed
    get presetColors(): PresetColors[] {
        let r: PresetColors[] = [];
        if (isArray(this.root.config.preset_colors)){
            this.root.config.preset_colors.forEach(a => {
                let pc = new PresetColors(this);
                if (a.name == null) throw "Preset color has not 'name' property";
                pc.name = a.name;
                if (a.id == null) throw `Preset color has not 'id' property. 'Name'=${a.name}`;
                pc.id = a.id;
                pc.colors = [];
                if (isArray(a.values)){
                    a.values.forEach(b => {
                        let c = new PresetColorValue(pc);
                        if (isString(b.color)){
                            c.color = ColorHelper.parseColor(b.color);
                        }else{
                            c.color = ColorHelper.parseColor(b.color);
                        }
                        pc.colors.push(c);
                    });
                }
                r.push(pc);
            });
            return r;
        }else return [];
    };

    @observable
    private _mapbox: mapboxgl.Map;
    get mapbox(){ return this._mapbox;}

    @observable
    public mapReady: boolean = false;//карта загружена и созданы все наши служебные слои

    superTools: SuperTools = new SuperTools(this);

    rulerInfo: MapRulerInfoStore = new MapRulerInfoStore(this);

    @save @observable
    private _searchBounds: BoundsStore = new BoundsStore(this);
    get searchBounds(): BoundsStore {return this._searchBounds;}

    @save @observable
    private _bbox: BoundsStore = new BoundsStore(this);
    get bbox(): BoundsStore {return this._bbox;}

    @action toPosition(lat: number, lon: number){
        this.mapbox.setCenter(new LngLat(lon, lat));
    }
    @action incZoom(){
        this.mapbox.setZoom(this.zoom + 1);
    }
    @action decZoom(){
        this.mapbox.setZoom(this.zoom - 1);
    }

    resetMeasuringStatus(){
        this.doSetMeasuringStatus(this.defaultMeasuringStatus);
    }

    setMeasuringStatus(status: MeasuringStatus|string) {
        if (status == MeasuringStatus.None) throw "Use resetMeasuringStatus";
        this.doSetMeasuringStatus(status);
    }
    private doSetMeasuringStatus(status: MeasuringStatus|string) {
        let oldStatus = this._measuringStatus;
        this._measuringStatus = status;
        this.root.events.onChangeMeasuringStatus.call({oldStatus: oldStatus, newStatus: status});
    }

    mapTransformRequest(url: string, resourceType: ResourceType): RequestParameters {
        let t: RequestParameters = {
            url: url

        };
        if (isArray(this.root.config.tile_domains) && this.root.config.tile_domains.length > 0)
        {
            let credNeed = false;
            this.root.config.tile_domains.forEach(a => {
                if (url.indexOf(a) >= 0) credNeed = true;
            });
            if (credNeed) t.credentials = 'include';
        }
        return t;
    }


    @action createPolygon() {
        this.root.searchPanel.showParamsPanel = false;
        //this.searchObject.visible = false;
        this.searchObject.geometryEditManager.state.simpleGeometry = [];
        this.searchObject.geometryEditManager.state.createGeometryType = CreateGeometryType.Polygon;
        this.setMeasuringStatus(MeasuringStatus.Polygon);
    }

    @action createRectangle() {
        this.root.searchPanel.showParamsPanel = false;
        //this.searchObject.visible = false;
        this.searchObject.geometryEditManager.state.simpleGeometry = [];
        this.searchObject.geometryEditManager.state.createGeometryType = CreateGeometryType.Rectangle;
        this.setMeasuringStatus(MeasuringStatus.Rectangle);
    }

    @action createLine() {
        this.root.searchPanel.showParamsPanel = false;
        //this.searchObject.visible = false;
        this.searchObject.geometryEditManager.state.simpleGeometry = [];
        this.searchObject.geometryEditManager.state.createGeometryType = CreateGeometryType.Line;
        this.setMeasuringStatus(MeasuringStatus.Polyline);
    }


    uploadSearchContour(file : File) {
        let uploadUrl = "/api/geojson_file_to_obj";
        const fd = new FormData();
        fd.append('format', 'geojson');
        fd.append('geojson', file);
        fd.append('save', '1');
        //fd.append('config', 'dev');

        fetchJson(uploadUrl, {
            method: 'POST',
            body: fd
        })
            .then(value => {
                ra(()=>{
                    let t = value.content.type;

                    if (t == "FeatureCollection"){
                        this.searchObject.content = value.content as FeatureCollection;
                    }else
                    if (value.content.type == "Feature"){
                        this.searchObject.content = {'type': 'FeatureCollection', 'features': [value.content]}
                    }else
                    if (t == "Point" || t=="MultiPoint" || t=="LineString" || t =="MultiLineString" || t=="Polygon" || t=="MultiPolygon"){
                        this.searchObject.content = {'type': 'FeatureCollection', 'features': [{"type": "Feature", properties:{}, geometry: value.content}]}
                    }else throw "GeoJson is not valid";

                    this.searchObject.name = value.properties.filename;
                });
            })
            .then(_ => {
                ra(()=> {
                    this.zoomToSearchObject();
                });
            })
            .catch(reason => {
                ra(()=>{
                    this.searchObject.clear();
                    this.root.addError(Utils.getErrorString(reason));
                });
            });
    }

    @action uploadChanged(e: any) {
        if (e.target.files.length == 0) return;
        this.uploadSearchContour(e.target.files[0]);
    }

    zoomToSearchObject() {
        if (this.searchObject.isEmpty) return;
        this.zoomToBBox1in3(bbox(this.searchObject.searchGeometry) as BBox2d);
    }

    zoomToBBox(bb : BBox2d, fitBoundsOptions : mapboxgl.FitBoundsOptions = null) {
        let options = fitBoundsOptions || { padding: 20 };
        this.mapbox.fitBounds(bb, options);
    }
    zoomFlyToBBox(bb : BBox2d) {
        this.mapbox.fitBounds(bb, {
            padding: 20,
            linear: true
        });
    }

    //Зум на терть экрана от bbox
    zoomToBBox1in3(bb : BBox2d, fitBoundsOptions : mapboxgl.FitBoundsOptions = null) {
        let w = this.mapbox.getCanvas().width;
        let h = this.mapbox.getCanvas().height;
        let min = Math.min(w, h);

        let options = fitBoundsOptions || { padding: Math.round(min / 6) };
        this.mapbox.fitBounds(bb, options);
    }

    //@action!!не надо
    public getMapStyle(): any{
        let baseLayer: IBaseLayer = null;
        if (this.currentBaselayerKey != null){
            baseLayer = this.root.config.map_layers.baselayers.find(a => a.key == this.currentBaselayerKey);
        }

        let style: string | Style;

        if (baseLayer == null) {
            style = <Style>{
                version: 8,
                glyphs: this.root.config.map_layers.defaultGlyphs,
                sprite: this.root.config.map_layers.defaultSprite,
                sources: {},
                zoom: this.zoom,
                center: this.center.getAsPosition(),
                "transition": {
                    "duration": 0,
                    "delay": 0
                },
                layers: []
            };
        } else if (baseLayer.mapbox_style)
            style = baseLayer.mapbox_style;
        else
            style = {
                version: 8,
                glyphs: this.root.config.map_layers.defaultGlyphs,
                sprite: this.root.config.map_layers.defaultSprite,
                sources: {
                    "baselayer_src": {
                        "type": "raster",
                        "tiles": [
                            Utils.getAbsoluteUrlPath(baseLayer.tiles)
                        ],
                        "tileSize": 256,
                        "attribution":""
                    }
                },
                layers: [
                    {
                        "id": "baselayer_id",
                        "type": "raster",
                        "source": "baselayer_src",
                        "minzoom": 0,
                        "maxzoom": 22
                    }
                ],
                zoom: this.zoom,
                center: this.center.getAsPosition(),
            };
        return style;
    }

    @action
    setMapbox(map: mapboxgl.Map){
        this._mapbox = map;
        this.mapReady = false;
    }

}

export class PointStore extends CustomStore {
    class(): string {return "PointStore";}

    @save @observable
    _lat: number = 0;
    get lat(): number{ return this._lat}

    @save @observable
    _lng: number = 0;
    get lng(): number{ return this._lng}

    isNull(): boolean{
        return this.lat == null || this.lng == null;
    }
    isZero(): boolean{
        return this.lat == 0 && this.lng == 0;
    }

    @action
    set(lat: number, lng: number): void{
        this._lat = lat;
        this._lng = lng;
    }
    @action
    setMbPoint(mbPoint: mapboxgl.LngLat): void{
        this._lat = mbPoint.lat;
        this._lng = mbPoint.lng;
    }

    //@computed
    getMbPoint(): mapboxgl.LngLat{
        return new mapboxgl.LngLat(this.lng, this.lat);
    }
    getAsPosition(): number[]{
        return MBUtils.llToPosition(this.getMbPoint());
    }
}

export class BoundsStore extends CustomStore {
    class(): string {return "BoundsStore";}
    @save @observable
    sw: PointStore = new PointStore(this);//юго-запад
    @save @observable
    ne: PointStore = new PointStore(this);//северо-восток

    getLatLon(): LngLatBounds{
        return new LngLatBounds(this.sw.getMbPoint(), this.ne.getMbPoint());
    }
    @computed
    get geoJson(): Polygon{
        return {type: "Polygon", coordinates: [[
                [this.sw.lng, this.sw.lat],
                [this.sw.lng, this.ne.lat],
                [this.ne.lng, this.ne.lat],
                [this.ne.lng, this.sw.lat],
                [this.sw.lng, this.sw.lat]
            ]]};
    }

    @action
    setMbBounds(mbBounds: mapboxgl.LngLatBounds){
        this.sw.setMbPoint(mbBounds.getSouthWest());
        this.ne.setMbPoint(mbBounds.getNorthEast());
    }

    //@computed
    getWidthLng(): number{
        return this.sw.lng - this.ne.lng;
    }

    //@computed
    getWidthLat(): number{
        return this.sw.lat - this.ne.lat;
    }

    //@computed
    isEmpty(): boolean{
        return (this.getWidthLat() == 0) || (this.getWidthLng() == 0);
    }
}
/*

*/