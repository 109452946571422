import React from "react";
import autoBindReact from "auto-bind/react";
import {action, observable} from "mobx";
import tinycolor from "tinycolor2";
import {CloseSvg} from "../../../icons/MiniIcon/CloseSvg";
import {HexColorPicker} from "react-colorful";
import classNames from "classnames";
import { SuperStore } from "../../../../store/SuperStore";

export interface IColorPickerProps {
    store?: SuperStore;
    color: string;
    opacity: number;
    onChange?: (color: string) => void;
    onClose?: (e: any) => void;
    setColor: (color: string) => void;
    setOpacity: (opacity: number) => void;
    variant?: "default" | "preset";
}

export class ColorPicker extends React.Component<IColorPickerProps> {
    @observable
    state = {
        color: this.props.color,
        opacity: Math.round(100 * this.props.opacity) + "%"
    };

    predefinedColors = [
        "#F26D91",
        "#04ADBF",
        "#EB3C2C",
        "#23FA93",
        "#E36020",
        "#147FFF",
        "#FFF232",
        "#8A95A2",
        "#F9CB7D",
        "#C5A8FF",
        "#64C733",
        "#CAEA5D",
        "#FB198B",
        "#8EEBED",
        "#A6583C",
        "#CC0D30",
        "#9D10C8",
        "#BF4565"
    ];

    constructor(props: IColorPickerProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onColorChange(color: any) {
        this.state.color = color;
        this.props.onChange?.(color);
    }

    @action
    onHexChanging(e: any) {
        this.setState({color: e.currentTarget.value});
    }

    @action
    onHexChange(e: any) {
        let hex = e.currentTarget.value;
        let color = tinycolor(hex);
        if (color.isValid()) {
            this.props.setColor(color.toHexString());
        } else {
            this.setState({color: this.props.color});
        }
    }

    @action
    onHexKeyPressed(e: any) {
        if (e.keyCode !== 13) return;
        let color = tinycolor(this.state.color);
        if (color.isValid()) {
            this.props.setColor(color.toHexString());
        } else {
            this.setState({color: this.props.color});
        }
    }

    @action
    onOpacityChanging(e: any) {
        this.setState({opacity: e.currentTarget.value});
    }

    setOpacity(opacity: number) {
        this.setState({opacity: Math.round(100 * opacity) + "%"});
        this.props.setOpacity(opacity);
    }

    @action
    onOpacityChange(e: any) {
        let op = e.currentTarget.value.replace("%", "");
        if (!isNaN(op) && op > 0 && op <= 100) {
            this.setOpacity(op / 100);
        } else {
            this.setState({
                opacity: Math.round(100 * this.props.opacity) + "%"
            });
        }
    }

    @action
    onOpacityKeyPressed(e: any) {
        if (e.keyCode !== 13) return;
        let op: string = this.state.opacity.replace("%", "");
        let numericOp = Number(op);
        if (!isNaN(numericOp) && numericOp > 0 && numericOp <= 100) {
            this.setOpacity(numericOp / 100);
        } else {
            this.setState({
                opacity: Math.round(100 * this.props.opacity) + "%"
            });
        }
    }

    render() {
        const isDefaultVariant = this.props.variant !== "preset";
        return (
            <div className='LayerStyleComp-color-color-picker'>
                <div className='LayerStyleComp-color-color-picker-header'>
                    <span>{isDefaultVariant? "Color picker": this.props.store.trans["Choose the color"]}</span>
                    <div
                        onClick={this.props.onClose}
                        className='pointer'
                    >
                        <CloseSvg />
                    </div>
                </div>

                <div className='LayerStyleComp-color-color-picker-palette'>
                    {isDefaultVariant && (
                        <HexColorPicker
                            color={this.props.color}
                            onChange={this.onColorChange}
                        />
                    )}
                </div>

                <div className='LayerStyleComp-color-color-picker-editor'>
                {isDefaultVariant ? (<span className='LayerStyleComp-color-color-picker-editor-span'>
                    Hex
                </span>): (
                            <div
                                className='LayerStyleComp-color-picker-predefined-color'
                                style={{backgroundColor:  this.state.color, alignSelf:"center",border:'1px solid #242930',cursor:'default'}}
                            />)}
                    <div className='LayerStyleComp-color-color-picker-editor-inputs'>
                        <input
                            type='text'
                            className={classNames(
                                'LayerStyleComp-color-color-picker-editor-hex',
                                {
                                    'Custom-color-picker-class': !isDefaultVariant, 
                                }
                            )}
                            value={this.state.color.toUpperCase()}
                            onChange={this.onHexChanging}
                            onBlur={this.onHexChange}
                            onKeyDown={this.onHexKeyPressed}
                        />
                       {isDefaultVariant && <input
                            type='text'
                            className='LayerStyleComp-color-color-picker-editor-opacity'
                            value={this.state.opacity}
                            maxLength={4}
                            pattern='[0-9]{1,3}'
                            onChange={this.onOpacityChanging}
                            onBlur={this.onOpacityChange}
                            onKeyDown={this.onOpacityKeyPressed}
                        />}
                    </div>
                </div>
                {!isDefaultVariant && (
                    <div className='LayerStyleComp-color-color-picker-predefined-color-panel'>
                        {this.predefinedColors.map(presetColor => (
                            <div
                                key={presetColor}
                                className='LayerStyleComp-color-picker-predefined-color'
                                style={{backgroundColor: presetColor}}
                                onClick={() =>
                                    this.onColorChange(presetColor)
                                }
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
