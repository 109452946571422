import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {IndexByPointTableRowComp} from "./IndexByPointTableRowComp";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import autoBindReact from "auto-bind/react";
import IndexByPointTableColorComp from './IndexByPointTableColorComp';

interface IIndexByPointTableCompProps extends IStoreProps {
    isColor?: boolean; 
}

@observer
export class IndexByPointTableComp extends React.Component< IIndexByPointTableCompProps,undefined> {
    constructor(props: IIndexByPointTableCompProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        const { store, isColor } = this.props;

        const arr = store.indexByPointer.points.map((a) =>
            isColor ? (
                <IndexByPointTableColorComp store={store} point={a} key={a.id.toString()} />
            ) : (
                <IndexByPointTableRowComp store={store} point={a} key={a.id.toString()} />
            )
        );
            return <SimpleBar className="width100 height100 scrollbar-standart IndexByPointTableComp" style={{height: isColor? "150px":undefined}} forceVisible="y" autoHide={false} >
                {arr}
            </SimpleBar>;
    }
}
