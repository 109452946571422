import {CustomStore} from "../CustomStore";
import {SoilGridsLayerStore} from "./SoilGridsLayerStore";
import {observable} from "mobx";
import {ISoilGridsSourceClass} from "../config/ConfigStore";

export class SoilGridsSourceStore extends CustomStore{
    sources_title: string;
    units_legend: string;
    pallete: string[];
    minValue: number;
    maxValue: number;

    classes: ISoilGridsSourceClass[] = [];

    @observable
    currentLayerIndex: number = 0;

    layers: SoilGridsLayerStore[] = [];
    @observable
    checked: boolean = false;
    @observable
    extend: boolean = false;
}