import {CustomStore} from "../CustomStore";
import {action, computed, observable, ObservableSet} from "mobx";
import {IProjectsProjResponse, ProjType} from "../user/UserStore";
import {ProjectRole} from "./A2PermissionStore";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {fetchJsonGet, fetchJsonPost} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";
import {LeftPanelMode} from "../SearchStore";
import {TranslateUtils} from "../../helper/lang/TranslateUtils";
import {ErrorCodeToString} from "../../helper/lang/ErrorCodeToString";

export interface ISecurityInfo{
    perm: ProjectRole;
    given_name: string;
    surname: string;
    email: string;
    user_id: number;
}
interface IUserSecurityResponse{
    "values": [{
        "proj_id": number,//110
        "proj_name": string,//"proj110",
        "proj_title": string,//"TestAgro21",
        "user_id": number, //144,
        "email": string,//"mamont80@mail.ru",
        "login": string,//"mamont80@mail.ru",
        "given_name": string,//"mmm",
        "surname": string,//"ssss",
        "perm": ProjectRole,//"edit"
    }]
}

export class A2ProjectEditorStore extends CustomStore{
    @observable
    title: string = "";
    @observable
    edit_proj_id: number;
    @observable
    edit_prj_name: string;
    @observable
    projectType: ProjType = ProjType.agro_base;
    @observable
    showSecurityForm: boolean = false;
    @observable
    updated_user_id: ObservableSet<number> = new ObservableSet<number>();
    @observable
    searchString: string = "";


    @observable
    proj_users: ISecurityInfo[] = [
        {user_id:1, email: "sample@sample.com", perm: ProjectRole.edit, given_name: "Александр", surname:"Александров"},
        {user_id:2, email: "sample@agasgdlkgsd.com", perm: ProjectRole.view, given_name: "Александр", surname:"Александровsadfjlksdaf"},
        {user_id:3, email: "sample2@sample.com", perm: ProjectRole.admin, given_name: "Алекс", surname:"Александров"}
    ];
    @computed
    get filtered_proj_users(): ISecurityInfo[]{
        return this.proj_users.filter(a => {
            return TranslateUtils.getUserFullName(a.surname, a.given_name).toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase()) ||
                a.email.toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase());
        });
    }

    @observable
    proj_users_load: LoadStatus = LoadStatus.ready;

    @computed
    get projectInfo(): IProjectsProjResponse{
        return this.root.agro2.projectList.projects.find(a => a.proj_id == this.edit_proj_id);
    }

    @action
    toEditProject(proj: IProjectsProjResponse){
        this.setEditProject(proj);
        this.root.searchPanel.switchPanel(LeftPanelMode.editProject);
    }

    setEditProject(proj: IProjectsProjResponse){
        this.title = proj.proj_title;
        this.projectType = proj.proj_type;
        this.edit_proj_id = proj.proj_id;
        this.edit_prj_name = proj.proj_name;
        this.proj_users = [];
        this.updated_user_id.clear();
    }

    @action
    toCreateProject(){
        this.title = "";
        this.projectType = ProjType.agro_base;
        this.edit_proj_id = null;
        this.edit_prj_name = null;
        this.proj_users = [];
        this.updated_user_id.clear();
        this.root.searchPanel.switchPanel(LeftPanelMode.createProject);
    }

    @action
    async sendUpdateProjectTitle(proj_name: string, new_title: string){
        let url = `/api/projects/${proj_name}/modify`;
        await fetchJsonPost(url, {values: JSON.stringify({"proj_title": new_title})});
        ra(()=>{
            if (this.root.agro.projectName == proj_name){
                this.root.agro.projectTitle = new_title;
            }
            let t = this.root.agro2.projectList.projectsFiltered.find(a => a.proj_id == this.edit_proj_id);
            if (t != null){
                t.proj_title = new_title;
            }
        });
    }

    @action
    async sendUpdatePerm(user_id: number, perm: ProjectRole){
        ra(() =>{
            this.root.agro2.projectEditor.proj_users.forEach(a => {
                if (a.user_id == user_id) a.perm = perm;
            });
        });
        let url = `/api/projects/${this.edit_prj_name}/user/modify`;
        let v: {code: number} = await fetchJsonPost(url, {user_id: user_id, perm: perm});
        if (v.code != null && v.code != 0){
            throw ErrorCodeToString(this.root, v.code);
        }
        ra(() => {
            if (!this.updated_user_id.has(user_id)) this.updated_user_id.add(user_id);
        });
    }

    @action
    async loadUserSecurityCatch(){
        let url = `/api/projects/${this.edit_prj_name}/user/select`;
        this.proj_users = [];
        this.proj_users_load = LoadStatus.loading;
        try {
            let r: IUserSecurityResponse = await fetchJsonGet(url);
            ra(()=>{
                this.proj_users = [];
                this.proj_users_load = LoadStatus.ready;
                r.values.forEach(a =>{
                    let si: ISecurityInfo = {
                        user_id: a.user_id,
                        perm: a.perm,
                        surname: a.surname,
                        given_name: a.given_name,
                        email: a.email
                    };
                    this.proj_users.push(si);
                });
            });
        }catch (e) {
            ra(()=>{
                this.proj_users_load = LoadStatus.ready;
                this.root.addError(e);
            });
        }
    }

    @action
    async sendCreateProject(): Promise<IProjectsProjResponse>{
        let url = `/api/projects/create`;
        return await fetchJsonPost(url, {values: JSON.stringify({"proj_type":this.projectType,"proj_title":this.title})}) as IProjectsProjResponse;
    }
    async getUserIdByEmail(email: string): Promise<number>{
        let url =`/api/auth/user/search?`;
        let r: {user_id?: number, code?: number} = await fetchJsonGet(url, {email: email});
        if (r.code != null && r.code != 0){
            throw ErrorCodeToString(this.root, r.code);
        }
        return r.user_id;
    }
    @action
    async sendDeleteProject(proj_name: string){
        let url = `/api/projects/${proj_name}/delete`;
        await fetchJsonPost(url, {});
    }
    //Удаление пользователя из проекта

    async sendDeleteUserFromProject(user_id: number){
        let url = `/api/projects/${this.edit_prj_name}/user/delete`;
        await fetchJsonPost(url, {user_id: user_id});
        ra(() => {
            if (this.updated_user_id.has(user_id)) this.updated_user_id.delete(user_id);
        });
    }

}