import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {manyCheck} from "../../helper/utils/Utils";

@observer
export class LogoSvgComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        if (manyCheck(()=>store.config.theme, ()=>store.config.theme.logo)){
            return <img src={store.config.theme.logo}/>;
        }

        return <svg width="100" height="24" viewBox="0 0 100 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_40_273)">
        <path d="M16.5024 4.90164H20.6141L22.081 7.92076H16.5024V4.90164ZM16.7762 9.55464H22.081V12.7158H12.8062V0.284149H22.081V3.44535H16.7762V9.55464Z" fill="#C5C5C5"/>
        <path d="M6.30399 12.8101C5.39036 12.8101 4.54661 12.6584 3.77272 12.3551C3.00958 12.0401 2.34317 11.5967 1.7735 11.025C1.21458 10.4534 0.779265 9.77671 0.467559 8.99504C0.155853 8.21337 0 7.35003 0 6.40503C0 5.46002 0.155853 4.59669 0.467559 3.81502C0.779265 3.03335 1.21995 2.35668 1.78962 1.78501C2.35929 1.21334 3.03107 0.775836 3.80496 0.472502C4.5896 0.157501 5.44948 0 6.3846 0C7.50244 0 8.49668 0.204168 9.3673 0.612502C10.2487 1.02084 10.9742 1.60417 11.5439 2.36251L9.10934 4.69002C8.75464 4.24668 8.36769 3.91418 7.9485 3.69252C7.54006 3.45918 7.08325 3.34251 6.57807 3.34251C6.15888 3.34251 5.77731 3.41251 5.43336 3.55252C5.10016 3.69252 4.81532 3.89668 4.57885 4.16502C4.34239 4.43335 4.15966 4.75419 4.03068 5.12752C3.9017 5.50086 3.83721 5.92669 3.83721 6.40503C3.83721 6.86003 3.9017 7.28003 4.03068 7.66503C4.15966 8.03837 4.34239 8.3592 4.57885 8.62754C4.81532 8.89587 5.09478 9.10587 5.41724 9.25754C5.75044 9.39754 6.12126 9.46754 6.5297 9.46754C6.95964 9.46754 7.37883 9.39171 7.78728 9.24004C8.19572 9.07671 8.63103 8.80254 9.09322 8.41754L11.2214 11.2525C10.5335 11.7542 9.74887 12.1392 8.8675 12.4076C7.98612 12.6759 7.13162 12.8101 6.30399 12.8101ZM7.91626 10.7275V6.07253H11.2214V11.2525L7.91626 10.7275Z" fill="#C5C5C5"/>
        <path d="M88.4213 13C87.4174 13 86.4477 12.8816 85.5123 12.6448C84.5768 12.408 83.8068 12.1002 83.2021 11.7213L84.5027 8.66667C85.0731 9.01002 85.7062 9.28825 86.4021 9.50137C87.1094 9.70264 87.7939 9.80328 88.4556 9.80328C88.8434 9.80328 89.1457 9.7796 89.3625 9.73224C89.5907 9.67304 89.7561 9.59608 89.8587 9.50137C89.9614 9.39481 90.0128 9.27049 90.0128 9.12842C90.0128 8.90346 89.893 8.72586 89.6534 8.59563C89.4138 8.46539 89.0944 8.35883 88.6951 8.27596C88.3073 8.18124 87.8794 8.08652 87.4117 7.9918C86.944 7.88525 86.4706 7.74909 85.9914 7.58333C85.5237 7.41758 85.0902 7.19854 84.6909 6.92623C84.303 6.65392 83.9893 6.29872 83.7497 5.86066C83.5102 5.41075 83.3904 4.85428 83.3904 4.19126C83.3904 3.42168 83.5957 2.72313 84.0064 2.09563C84.4285 1.45628 85.0503 0.947177 85.8716 0.568306C86.7044 0.189435 87.7368 0 88.9689 0C89.7789 0 90.5775 0.088798 91.3646 0.266393C92.1518 0.443989 92.8591 0.716302 93.4865 1.08333L92.2716 4.12022C91.6783 3.81239 91.1022 3.58151 90.5432 3.4276C89.9956 3.27368 89.4595 3.19672 88.9347 3.19672C88.5468 3.19672 88.2388 3.23224 88.0106 3.30328C87.7825 3.37432 87.6171 3.46903 87.5144 3.58743C87.4231 3.70583 87.3775 3.83607 87.3775 3.97814C87.3775 4.19126 87.4973 4.36293 87.7368 4.49317C87.9764 4.61157 88.2901 4.7122 88.678 4.79508C89.0773 4.87796 89.5108 4.96676 89.9785 5.06148C90.4577 5.15619 90.9311 5.28643 91.3988 5.45219C91.8666 5.61794 92.2944 5.83698 92.6822 6.10929C93.0815 6.3816 93.4009 6.73679 93.6405 7.17486C93.8801 7.61293 93.9999 8.15756 93.9999 8.80874C93.9999 9.56648 93.7888 10.265 93.3667 10.9044C92.956 11.5319 92.34 12.041 91.5186 12.4317C90.6972 12.8106 89.6648 13 88.4213 13Z" fill="#4DB6BC"/>
        <path d="M77.1752 13C76.1713 13 75.2016 12.8816 74.2662 12.6448C73.3307 12.408 72.5607 12.1002 71.9561 11.7213L73.2566 8.66667C73.827 9.01002 74.4601 9.28825 75.156 9.50137C75.8633 9.70264 76.5478 9.80328 77.2095 9.80328C77.5973 9.80328 77.8996 9.7796 78.1164 9.73224C78.3446 9.67304 78.51 9.59608 78.6127 9.50137C78.7153 9.39481 78.7667 9.27049 78.7667 9.12842C78.7667 8.90346 78.6469 8.72586 78.4073 8.59563C78.1677 8.46539 77.8483 8.35883 77.449 8.27596C77.0612 8.18124 76.6334 8.08652 76.1656 7.9918C75.6979 7.88525 75.2245 7.74909 74.7453 7.58333C74.2776 7.41758 73.8441 7.19854 73.4448 6.92623C73.0569 6.65392 72.7432 6.29873 72.5036 5.86066C72.2641 5.41075 72.1443 4.85428 72.1443 4.19126C72.1443 3.42168 72.3496 2.72313 72.7603 2.09563C73.1824 1.45628 73.8042 0.947177 74.6255 0.568306C75.4583 0.189435 76.4908 0 77.7228 0C78.5328 0 79.3314 0.088798 80.1185 0.266393C80.9057 0.443989 81.613 0.716302 82.2404 1.08333L81.0255 4.12022C80.4322 3.81239 79.8561 3.58151 79.2971 3.4276C78.7495 3.27368 78.2134 3.19672 77.6886 3.19672C77.3007 3.19672 76.9927 3.23224 76.7645 3.30328C76.5364 3.37432 76.371 3.46903 76.2683 3.58743C76.177 3.70583 76.1314 3.83607 76.1314 3.97814C76.1314 4.19126 76.2512 4.36293 76.4908 4.49317C76.7303 4.61157 77.044 4.7122 77.4319 4.79508C77.8312 4.87796 78.2647 4.96676 78.7324 5.06148C79.2116 5.15619 79.685 5.28643 80.1527 5.45219C80.6205 5.61794 81.0483 5.83698 81.4361 6.10929C81.8354 6.3816 82.1549 6.73679 82.3944 7.17486C82.634 7.61293 82.7538 8.15756 82.7538 8.80874C82.7538 9.56648 82.5427 10.265 82.1206 10.9044C81.7099 11.5319 81.0939 12.041 80.2725 12.4317C79.4511 12.8106 78.4187 13 77.1752 13Z" fill="#4DB6BC"/>
        <path d="M57.6216 12.7158L62.8579 0.284149H66.8279L72.0642 12.7158H67.8888L64.0215 2.27322H65.5958L61.7285 12.7158H57.6216Z" fill="#4DB6BC"/>
        <path d="M49.0181 12.7158V0.284149H53.0565V9.46584H58.4639L56.9262 12.7158H49.0181Z" fill="#4DB6BC"/>
        <path d="M42.4789 13C41.5206 13 40.6308 12.8461 39.8094 12.5383C38.9994 12.2186 38.2921 11.7687 37.6875 11.1885C37.0943 10.6084 36.6323 9.92168 36.3014 9.12842C35.9706 8.33516 35.8052 7.45902 35.8052 6.5C35.8052 5.54098 35.9706 4.66484 36.3014 3.87158C36.6323 3.07832 37.0943 2.39162 37.6875 1.81148C38.2921 1.23133 38.9994 0.78734 39.8094 0.479508C40.6308 0.159836 41.5206 0 42.4789 0C43.6539 0 44.692 0.213115 45.5933 0.639344C46.5059 1.06557 47.2589 1.68124 47.8521 2.48634L45.3024 4.84836C44.9487 4.38661 44.5552 4.03142 44.1216 3.78279C43.6995 3.52231 43.2204 3.39208 42.6842 3.39208C42.2621 3.39208 41.88 3.46311 41.5377 3.60519C41.1955 3.74727 40.8989 3.95446 40.6479 4.22678C40.4083 4.49909 40.2201 4.8306 40.0832 5.22131C39.9463 5.60018 39.8779 6.02641 39.8779 6.5C39.8779 6.97359 39.9463 7.40574 40.0832 7.79645C40.2201 8.17532 40.4083 8.50091 40.6479 8.77322C40.8989 9.04554 41.1955 9.25273 41.5377 9.39481C41.88 9.53689 42.2621 9.60792 42.6842 9.60792C43.2204 9.60792 43.6995 9.48361 44.1216 9.23497C44.5552 8.9745 44.9487 8.61339 45.3024 8.15164L47.8521 10.5137C47.2589 11.3069 46.5059 11.9226 45.5933 12.3607C44.692 12.7869 43.6539 13 42.4789 13Z" fill="#4DB6BC"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.0722 12.5205C27.905 12.8402 28.8062 13 29.7759 13C30.757 13 31.6582 12.8402 32.4796 12.5205C33.301 12.2008 34.014 11.7509 34.6186 11.1708C34.9834 10.8136 35.2984 10.4198 35.5635 9.98931C35.3992 9.71784 35.254 9.43087 35.1279 9.12842C34.797 8.33516 34.6316 7.45902 34.6316 6.5C34.6316 5.54098 34.797 4.66484 35.1279 3.87158C35.2538 3.56965 35.3987 3.28316 35.5627 3.0121C35.2977 2.58503 34.983 2.19666 34.6186 1.84699C34.014 1.25501 33.301 0.79918 32.4796 0.479508C31.6582 0.159836 30.757 0 29.7759 0C28.8062 0 27.905 0.159836 27.0722 0.479508C26.2508 0.79918 25.5378 1.25501 24.9332 1.84699C24.3285 2.42714 23.8551 3.11384 23.5129 3.9071C23.182 4.68852 23.0166 5.55282 23.0166 6.5C23.0166 7.43534 23.182 8.29964 23.5129 9.0929C23.8551 9.88616 24.3285 10.5788 24.9332 11.1708C25.5378 11.7509 26.2508 12.2008 27.0722 12.5205ZM30.8197 9.39481C30.5003 9.53689 30.1523 9.60792 29.7759 9.60792C29.3994 9.60792 29.0458 9.53689 28.7149 9.39481C28.3955 9.25273 28.1103 9.05146 27.8593 8.79098C27.6198 8.51867 27.4315 8.19308 27.2946 7.81421C27.1577 7.4235 27.0893 6.98543 27.0893 6.5C27.0893 6.01457 27.1577 5.58242 27.2946 5.20355C27.4315 4.81284 27.6198 4.48725 27.8593 4.22678C28.1103 3.95446 28.3955 3.74727 28.7149 3.60519C29.0458 3.46311 29.3994 3.39208 29.7759 3.39208C30.1523 3.39208 30.5003 3.46311 30.8197 3.60519C31.1505 3.74727 31.4357 3.95446 31.6753 4.22678C31.9263 4.48725 32.1202 4.81284 32.2571 5.20355C32.394 5.58242 32.4625 6.01457 32.4625 6.5C32.4625 6.98543 32.394 7.4235 32.2571 7.81421C32.1202 8.19308 31.9263 8.51867 31.6753 8.79098C31.4357 9.05146 31.1505 9.25273 30.8197 9.39481Z" fill="#C5C5C5"/>
        </g>
        <defs>
        <clipPath id="clip0_40_273">
        <rect width="100" height="46" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
        /*
        return <svg width="100" height="21" viewBox="0 0 100 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.0602 17.2791C35.7619 17.2791 34.5849 17.0024 33.5293 16.4489C32.4857 15.8835 31.6606 15.1074 31.0539 14.1209C30.4594 13.1223 30.1621 11.9974 30.1621 10.7461C30.1621 9.49486 30.4594 8.37595 31.0539 7.38939C31.6606 6.39079 32.4857 5.61478 33.5293 5.06134C34.5849 4.49587 35.768 4.21313 37.0784 4.21313C38.1826 4.21313 39.1776 4.40563 40.0633 4.79064C40.9612 5.17564 41.7135 5.72907 42.3202 6.45095L40.4274 8.18345C39.5659 7.19689 38.4981 6.70361 37.224 6.70361C36.4353 6.70361 35.7316 6.87806 35.1127 7.22697C34.4939 7.56384 34.0085 8.03907 33.6567 8.65267C33.3169 9.26626 33.147 9.96408 33.147 10.7461C33.147 11.5281 33.3169 12.226 33.6567 12.8395C34.0085 13.4531 34.4939 13.9344 35.1127 14.2833C35.7316 14.6202 36.4353 14.7886 37.224 14.7886C38.4981 14.7886 39.5659 14.2893 40.4274 13.2907L42.3202 15.0232C41.7135 15.7571 40.9612 16.3166 40.0633 16.7016C39.1654 17.0866 38.1644 17.2791 37.0602 17.2791Z" fill="#4DB6BC"/>
            <path d="M46.6775 4.4297H49.626V14.6803H56.0145V17.0625H46.6775V4.4297Z" fill="#4DB6BC"/>
            <path d="M68.5252 14.3555H62.61L61.4815 17.0625H58.4602L64.1389 4.4297H67.051L72.7478 17.0625H69.6537L68.5252 14.3555ZM67.597 12.1357L65.5767 7.29915L63.5564 12.1357H67.597Z" fill="#4DB6BC"/>
            <path d="M80.6219 17.2791C79.6148 17.2791 78.6381 17.1467 77.6916 16.8821C76.7573 16.6053 76.005 16.2504 75.4347 15.8173L76.4358 13.6156C76.9818 14.0126 77.6309 14.3314 78.3832 14.572C79.1355 14.8127 79.8878 14.933 80.6401 14.933C81.4774 14.933 82.0962 14.8127 82.4966 14.572C82.897 14.3194 83.0973 13.9885 83.0973 13.5795C83.0973 13.2787 82.9759 13.032 82.7332 12.8395C82.5027 12.635 82.1993 12.4726 81.8232 12.3523C81.4592 12.232 80.9617 12.0996 80.3307 11.9552C79.36 11.7267 78.5653 11.4981 77.9464 11.2695C77.3276 11.0409 76.7937 10.6739 76.3448 10.1686C75.9079 9.66329 75.6895 8.98954 75.6895 8.14736C75.6895 7.41345 75.8897 6.75173 76.2902 6.1622C76.6906 5.56064 77.2912 5.0854 78.092 4.73649C78.905 4.38759 79.8939 4.21313 81.0588 4.21313C81.8717 4.21313 82.6665 4.30938 83.4431 4.50188C84.2196 4.69438 84.8991 4.9711 85.4816 5.33204L84.5715 7.55181C83.3945 6.89009 82.2175 6.55923 81.0406 6.55923C80.2155 6.55923 79.6027 6.69157 79.2023 6.95626C78.814 7.22095 78.6199 7.56986 78.6199 8.00298C78.6199 8.43611 78.8443 8.76095 79.2933 8.97751C79.7544 9.18204 80.4521 9.38658 81.3864 9.59111C82.3571 9.8197 83.1519 10.0483 83.7707 10.2769C84.3895 10.5055 84.9173 10.8664 85.3542 11.3597C85.8031 11.853 86.0276 12.5207 86.0276 13.3629C86.0276 14.0848 85.8213 14.7465 85.4088 15.3481C85.0083 15.9376 84.4016 16.4068 83.5887 16.7557C82.7757 17.1046 81.7868 17.2791 80.6219 17.2791Z" fill="#4DB6BC"/>
            <path d="M94.5946 17.2791C93.5875 17.2791 92.6107 17.1467 91.6643 16.8821C90.73 16.6053 89.9777 16.2504 89.4074 15.8173L90.4084 13.6156C90.9545 14.0126 91.6036 14.3314 92.3559 14.572C93.1082 14.8127 93.8605 14.933 94.6128 14.933C95.4501 14.933 96.0689 14.8127 96.4693 14.572C96.8697 14.3194 97.0699 13.9885 97.0699 13.5795C97.0699 13.2787 96.9486 13.032 96.7059 12.8395C96.4754 12.635 96.172 12.4726 95.7959 12.3523C95.4318 12.232 94.9344 12.0996 94.3034 11.9552C93.3327 11.7267 92.5379 11.4981 91.9191 11.2695C91.3003 11.0409 90.7664 10.6739 90.3174 10.1686C89.8806 9.66329 89.6622 8.98954 89.6622 8.14736C89.6622 7.41345 89.8624 6.75173 90.2628 6.1622C90.6632 5.56064 91.2639 5.0854 92.0647 4.73649C92.8777 4.38759 93.8666 4.21313 95.0314 4.21313C95.8444 4.21313 96.6392 4.30938 97.4157 4.50188C98.1923 4.69438 98.8718 4.9711 99.4542 5.33204L98.5442 7.55181C97.3672 6.89009 96.1902 6.55923 95.0132 6.55923C94.1881 6.55923 93.5754 6.69157 93.1749 6.95626C92.7867 7.22095 92.5925 7.56986 92.5925 8.00298C92.5925 8.43611 92.817 8.76095 93.2659 8.97751C93.727 9.18204 94.4247 9.38658 95.359 9.59111C96.3298 9.8197 97.1245 10.0483 97.7433 10.2769C98.3622 10.5055 98.89 10.8664 99.3268 11.3597C99.7758 11.853 100 12.5207 100 13.3629C100 14.0848 99.794 14.7465 99.3814 15.3481C98.981 15.9376 98.3743 16.4068 97.5613 16.7557C96.7484 17.1046 95.7595 17.2791 94.5946 17.2791Z" fill="#4DB6BC"/>
            <rect width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect y="2.53952" width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect y="5.07904" width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect y="7.61859" width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect x="21.2285" y="21" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 21)" fill="#4DB6BC"/>
            <rect x="21.2285" y="18.4605" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 18.4605)" fill="#4DB6BC"/>
            <rect x="21.2285" y="15.921" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 15.921)" fill="#4DB6BC"/>
            <rect x="21.2285" y="13.3814" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 13.3814)" fill="#4DB6BC"/>
            <rect x="21.2773" y="0.000213623" width="9.27909" height="1.67463" transform="rotate(90 21.2773 0.000213623)" fill="white"/>
            <rect x="18.7168" width="9.27909" height="1.67463" transform="rotate(90 18.7168 0)" fill="white"/>
            <rect x="16.1553" width="9.27909" height="1.67463" transform="rotate(90 16.1553 0)" fill="white"/>
            <rect x="13.5947" width="9.27909" height="1.67463" transform="rotate(90 13.5947 0)" fill="white"/>
            <rect y="21" width="9.27909" height="1.67463" transform="rotate(-90 0 21)" fill="white"/>
            <rect x="2.56055" y="21" width="9.27909" height="1.67463" transform="rotate(-90 2.56055 21)" fill="white"/>
            <rect x="5.12207" y="21" width="9.27909" height="1.67463" transform="rotate(-90 5.12207 21)" fill="white"/>
            <rect x="7.68457" y="21" width="9.27909" height="1.67463" transform="rotate(-90 7.68457 21)" fill="white"/>
        </svg>;*/
        
    }
}
