import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoArrowRightSvgProps {
    className?: string;
}

export class AgroAhoArrowRightSvg extends React.PureComponent<IAgroAhoArrowRightSvgProps, undefined> {
    constructor(props: IAgroAhoArrowRightSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)}
            width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.4375 0.6875L17.4375 8L8.4375 15.3125V10.8125H3.9375V5.1875H8.4375V0.6875Z" stroke="#6D7785" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.5625 5.1875V10.8125" stroke="#6D7785" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>;
    }
}
