import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import './ExportQgis.scss';
import classNames from "classnames";
import {action, observable} from "mobx";
import {A2NavigatorItemListComp} from "../../Left/Agro2/A2Navigator/A2NavigatorItemListComp";
import {Feature, Geometry} from "@turf/helpers";
import {ISearchPropertiesRest, SceneUiType} from "../../../../store/SearchStore";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";
import {ra} from "../../../../helper/utils/mobxUtils";
import {SearchItemStore} from "../../../../store/SearchItemStore";
import { RadioButton } from "../../../Common/RadioButton";
import { QgisExportFormat } from "../../../../store/export/QgisExportStore";
import InformationIconComp from "../../../Common/InformationIconComp";

@observer
export class ExportQgis extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        ra(()=>{
            let store = this.props.store;
            if (store.agro2.projectInfo.getFieldVectorLayerId()) this.props.store.qgisExport.filename = store.agro2.projectInfo.getFieldVectorLayerId();
            else this.props.store.qgisExport.filename = "export";
        });
    }
    @observable
    downloading: boolean = false;
    @action
    async onClickExport(){
        try {
            this.downloading = true;
            await this.props.store.qgisExport.exportToQgis();

            ra(()=>{
                this.downloading = false;
            });
        }catch (e){
            ra(()=>{
                this.downloading = false;
                this.props.store.addError(e);
            });
        }
    }
    @action
    onClickCancel(){
        this.props.store.qgisExport.showExportWindow = false;
    }
    @action
    onClickAddContourLines(){
        this.props.store.qgisExport.addContourLines = !this.props.store.qgisExport.addContourLines;
    }
    @action
    onClick_contourRuler(){
        this.props.store.qgisExport.contourRuler = !this.props.store.qgisExport.contourRuler;
    }
    @action
    onClickAddSurveyPoints() {
        this.props.store.qgisExport.addSurveyPoints = !this.props.store.qgisExport.addSurveyPoints;
    }
    @action
    onClick_onlySelected(){
        this.props.store.qgisExport.onlySelected = !this.props.store.qgisExport.onlySelected;
    }
    @action
    onClick_izoLines(){
        this.props.store.qgisExport.izoLines = !this.props.store.qgisExport.izoLines;
    }
    @action
    onChange_Filename(e: any){
        let txt = e.currentTarget.value;
        this.props.store.qgisExport.filename = txt;
    }
    render() {
        let store = this.props.store;
        let qgis = this.props.store.qgisExport;
        let productName = store.map.productInfo.activeProductsSet.activeCodeCurrent.currentProductStore.productConfig.full_name;//currentProductStore.productConfig.full_name
        let isIndexProduct = qgis.isIndexProduct;
        let curScenes = store.searchPanel.searchResult.currentItems;

        let isMultiScene = false;

        let isSingleScene = false;
        let scene: Feature<Geometry, ISearchPropertiesRest> = null;
        let sceneInfo = qgis.getSceneInfo();
        if (sceneInfo != null && sceneInfo.sceneIds.length > 0){

            let item: SearchItemStore = null;
            if (sceneInfo.UiType == SceneUiType.current) item = store.searchPanel.searchResult.findItem(sceneInfo.sceneIds[0]);
            if (sceneInfo.UiType == SceneUiType.favorite) item = store.searchPanel.favoriteList.findItem(sceneInfo.sceneIds[0])

            scene = item.feature;
            if (sceneInfo.sceneIds.length > 1){
                isMultiScene = true;
            }else{
                isSingleScene = true;
            }
        }

        return <div className="modal-back flex-full-center">
            <div className="modal-full-frame ExportQgis-window">
                <div className="ExportQgis-body">
                    <div className="ExportQgis-title">Экспорт окна карты</div>
                    <div className="ExportQgis-desc"><div className="ExportQgis-descText">Из объектов в пределах текущего окна карты, включая растр и вектор, создаются файл(ы) для загрузки в мобильное и/или настольное приложения</div></div>
                    <div className="ExportQgis-filenameDiv">
                        <div className="ExportQgis-filenameDesc">
                            {store.trans["File name"]}
                        </div>
                        <input type="text" className="text-box-editor width100" onChange={this.onChange_Filename} value={qgis.filename} />
                    </div>

                    <div className="ExportQgis-panels">
                        {this.downloading && <div className="ExportQgis-panelsLoading flex-full-center">
                            <div>
                                <div className="">
                                    <div className="spinner"/>
                                </div>
                                <div className="gray-text ExportQgis-loadingText">Идет подготовка к экспорту. Это не займет много времени</div>
                            </div>
                        </div>}
                        <div>
                            <div className="ExportQgis-panel exportFormat">
                                <div className="ExportQgis-panelBorder exportFormat flex-grow">
                                    <div className="ExportQgis-panelTitle exportFormat">
                                        <span>Формат экспорта</span>
                                        <InformationIconComp hintClass="ExportQgis-format-info">
                                            <div className="ExportQgis-format-hint">
                                                <span className="ExportQgis-panelTitle exportFormatInfo">GeoJSON + GeoTIFF</span>
                                                <span className="ExportQgis-format-description">ZIP-архив, включая QGIS proj. файл для моб. прилож. QField и/или настольного QGIS</span>
                                                <div className="ExportQgis-format-description-sep"></div>
                                                <span className="ExportQgis-panelTitle exportFormatInfo">KMZ / KML</span>
                                                <span className="ExportQgis-format-description">Для моб. прилож. AlpineQuest и/или настольного Google Earth Pro</span>
                                            </div>
                                        </InformationIconComp>
                                    </div>
                                    <div className='ExportQgis-format-selector-panel'>
                                        <div>
                                            <RadioButton checked={qgis.exportFormat == QgisExportFormat.qgis}
                                                onClick={() => qgis.exportFormat = QgisExportFormat.qgis}>
                                                    <div className='ExportQgis-radio-text'>GeoJSON + GeoTIFF</div>
                                            </RadioButton>
                                        </div>
                                        <div>
                                            <RadioButton checked={qgis.exportFormat == QgisExportFormat.kml}
                                                onClick={() => qgis.exportFormat = QgisExportFormat.kml} >
                                                    <div className='ExportQgis-radio-text'>KMZ / KML</div>
                                            </RadioButton>                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ExportQgis-sep"></div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div className="ExportQgis-panel">
                                <div className="ExportQgis-panelBorder flex-grow">
                                    <div className="ExportQgis-panelTitle">Снимок</div>
                                    {(!isSingleScene && !isMultiScene) && <div className="ExportQgis-text ExportQgis-margin">Отсутствует</div>}
                                    {(isSingleScene || isMultiScene) && <React.Fragment>
                                        <div className="ExportQgis-sceneList">
                                            <div className="ExportQgis-sceneRow">
                                                <div className="ExportQgis-sceneImg">
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14.0625 1.875H12.1875V0H9.375V1.875H5.625V0H2.8125V1.875H0.9375C0.42 1.875 0 2.295 0 2.8125V14.0625C0 14.58 0.42 15 0.9375 15H14.0625C14.58 15 15 14.58 15 14.0625V2.8125C15 2.295 14.58 1.875 14.0625 1.875ZM13.125 13.125H1.875V6.5625H13.125V13.125Z" fill="#3A7BAF"/>
                                                    </svg>
                                                </div>
                                                <div className="ExportQgis-sceneValue">
                                                    {scene.properties.acqdate} {isSingleScene && scene.properties.acqtime}
                                                </div>
                                            </div>
                                            <div className="ExportQgis-sceneRow">
                                                <div className="ExportQgis-sceneImg">
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.6875 15H3.75C2.75544 15 1.80161 14.6049 1.09835 13.9017C0.395088 13.1984 0 12.2446 0 11.25V10.3125H1.875V11.25C1.875 11.7473 2.07254 12.2242 2.42417 12.5758C2.77581 12.9275 3.25272 13.125 3.75 13.125H4.6875V15Z" fill="#3A7BAF"/>
                                                        <path d="M12.1875 9.37503H10.7006L9.56906 8.24347L13.4719 4.34066C13.6565 4.15599 13.803 3.93675 13.903 3.69547C14.0029 3.45418 14.0544 3.19557 14.0544 2.93441C14.0544 2.67324 14.0029 2.41464 13.903 2.17335C13.803 1.93207 13.6565 1.71283 13.4719 1.52816C13.2872 1.34349 13.068 1.197 12.8267 1.09706C12.5854 0.997112 12.3268 0.945671 12.0656 0.945671C11.5382 0.945671 11.0323 1.1552 10.6594 1.52816L6.75656 5.43097L5.61562 4.29003L5.60625 2.83128L2.84813 0.0740967L0 2.92222L2.8125 5.62503H4.29938L5.43094 6.7566L3.75 8.43753L6.5625 11.25L8.24344 9.5691L9.375 10.7007V12.1875L12.1969 15.0094L14.9906 12.2166L12.1875 9.37503Z" fill="#3A7BAF"/>
                                                    </svg>
                                                </div>
                                                <div className="ExportQgis-sceneValue">
                                                    {scene.properties.satellite}
                                                </div>
                                            </div>
                                            <div className="ExportQgis-sceneRow">
                                                <div className="ExportQgis-sceneImg">
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g opacity="0.7">
                                                            <path d="M3 0.5C3 0.223858 2.77614 0 2.5 0C2.22386 0 2 0.223858 2 0.5V14.5C2 14.7761 2.22386 15 2.5 15C2.77614 15 3 14.7761 3 14.5V0.5Z" fill="#3A7BAF"/>
                                                            <path d="M8 0.5C8 0.223858 7.77614 0 7.5 0C7.22386 0 7 0.223858 7 0.5V14.5C7 14.7761 7.22386 15 7.5 15C7.77614 15 8 14.7761 8 14.5V0.5Z" fill="#3A7BAF"/>
                                                            <path d="M13 0.5C13 0.223858 12.7761 0 12.5 0C12.2239 0 12 0.223858 12 0.5V14.5C12 14.7761 12.2239 15 12.5 15C12.7761 15 13 14.7761 13 14.5V0.5Z" fill="#3A7BAF"/>
                                                            <path d="M0.5 12C0.223858 12 0 12.2239 0 12.5C0 12.7761 0.223858 13 0.5 13H14.5C14.7761 13 15 12.7761 15 12.5C15 12.2239 14.7761 12 14.5 12H0.5Z" fill="#3A7BAF"/>
                                                            <path d="M0.5 7C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H14.5C14.7761 8 15 7.77614 15 7.5C15 7.22386 14.7761 7 14.5 7L0.5 7Z" fill="#3A7BAF"/>
                                                            <path d="M0.5 2C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3L14.5 3C14.7761 3 15 2.77614 15 2.5C15 2.22386 14.7761 2 14.5 2L0.5 2Z" fill="#3A7BAF"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="ExportQgis-sceneValue">
                                                    {isSingleScene && scene.properties.gridcode}
                                                    {isMultiScene && <span>{TranslateUtils.format(store.trans["{0} scenes"], curScenes.length)}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ExportQgis-product ExportQgis-margin">
                                            Продукт: {productName}
                                        </div>
                                        </React.Fragment>
                                    }
                                    {isIndexProduct && <div className="ExportQgis-text ExportQgis-margin ExportQgis-sceneRow" >
                                        <CheckboxMiniComp state={qgis.addContourLines} size={CheckboxSize.switcher} onClick={this.onClickAddContourLines}
                                                        disabled={(!isSingleScene && !isMultiScene)}
                                                        classesContainer="pointer">
                                            <span className="text-next">Добавить контурные линии</span>
                                        </CheckboxMiniComp>
                                    </div>}
                                    {store.searchPanel.cutFieldsByBorder && <div className="ExportQgis-sceneRow ExportQgis-margin ExportQgis-sceneRow">
                                        <div className="flex-full-center">
                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.87504 7.37504C3.58704 7.37504 3.29904 7.26479 3.07966 7.04541L0.829662 4.79541C0.389787 4.35554 0.389787 3.64454 0.829662 3.20466C1.26954 2.76479 1.98054 2.76479 2.42041 3.20466L3.87504 4.65929L7.57966 0.954662C8.01954 0.514787 8.73054 0.514787 9.17041 0.954662C9.61029 1.39454 9.61029 2.10554 9.17041 2.54541L4.67041 7.04541C4.45104 7.26479 4.16304 7.37504 3.87504 7.37504Z" fill="#4DB6BC"/>
                                            </svg>
                                        </div>
                                        <div className="flex-columns-centerByVertical">
                                            <span className="text-next">Обрезать по активной области</span>
                                        </div>
                                    </div>}

                                    <div className="ExportQgis-panelFooter" />
                                </div>
                            </div>
                            <div className="ExportQgis-panel">
                                <div className="ExportQgis-panelBorder flex-grow">
                                    <div className="ExportQgis-panelTitle">Векторные объекты</div>
                                    {(qgis.hasFields || qgis.hasSurveyPoints) && <React.Fragment>
                                        {qgis.hasFields && <div className="ExportQgis-fieldsBorder">
                                            <A2NavigatorItemListComp store={store} activeLinks={false} />
                                        </div>}
                                        {qgis.hasSelectedFields && <div className="ExportQgis-text ExportQgis-margin ExportQgis-top ExportQgis-switcherLine">
                                            <CheckboxMiniComp state={qgis.onlySelected} size={CheckboxSize.switcher} onClick={this.onClick_onlySelected} classesContainer="pointer" >
                                                <span className="text-next">Экспортировать только выделенное</span>
                                            </CheckboxMiniComp>
                                        </div>}
                                        {qgis.hasSurveyPoints && <div className="ExportQgis-text ExportQgis-margin ExportQgis-switcherLine">
                                            <CheckboxMiniComp state={qgis.addSurveyPoints} size={CheckboxSize.switcher} onClick={this.onClickAddSurveyPoints} classesContainer="pointer" >
                                                <span className="text-next">Экспортировать точки осмотров</span>
                                            </CheckboxMiniComp>
                                        </div>}
                                    </React.Fragment>}
                                    {!(qgis.hasFields || qgis.hasSurveyPoints) && <div className="ExportQgis-text ExportQgis-margin">Нет объектов</div>}
                                    <div className="ExportQgis-panelFooter" />
                                </div>
                                <div className="ExportQgis-panelBorder ExportQgis-topMini">
                                    <div className="ExportQgis-panelTitle">Подложка</div>
                                    <div className="ExportQgis-margin ExportQgis-text">Google satellite</div>
                                    <div className="ExportQgis-text ExportQgis-margin ExportQgis-switcherLine">
                                        <CheckboxMiniComp state={qgis.izoLines} size={CheckboxSize.switcher} onClick={this.onClick_izoLines} classesContainer="pointer" >
                                            <span className="text-next">Изолинии, уклоны, отмывка</span>
                                        </CheckboxMiniComp>
                                    </div>
                                    <div className="ExportQgis-panelFooter" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height: "25px"}} />
                    <div className="separator_line"></div>
                    <div className="ExportQgis-footer">
                        <button
                            className="large-button left-sidebar-cancel button-jump popup-exp-2button-button"
                            onClick={this.onClickCancel}>
                            {store.trans.Cancel}
                        </button>
                        <button onClick={this.onClickExport} disabled={this.downloading}
                                className={classNames("large-button left-sidebar-active button-jump popup-exp-2button-button text-next-2")}
                        >
                            {store.trans.ExportTo}
                        </button>
                    </div>
                </div>
            </div>
        </div>;
    }
}