import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import classNames from "classnames";
import {ProjType} from "../../../../../store/user/UserStore";
import {PhotoPanelChooseFilesComp} from "./PhotoPanelChooseFilesComp";

@observer
export class PhotoPanelTitleComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }


    @action
    onClickUpload(){
        let store = this.props.store;

        if (store.agro.projectName != null) {
            this.props.store.photo.uploadImageStore.uploadElement.value = "";
            this.props.store.photo.uploadImageStore.uploadElement.click();
        }
    }

    render() {
        let store = this.props.store;
        let uploadStore = store.photo.uploadImageStore;
        return <div className="PhotoPanel-title-div PhotoPanel-margin">
            <div className="PhotoPanel-title-text flex-stretch-item">{store.trans.Photos}</div>
            <div className={
                classNames("icon_container icon_container_fill icon_container-preferences qtransit PhotoPanel-addPhotoButton",
                    {"icon_container-preferences-active": uploadStore.showPanel})}
                 onClick={this.onClickUpload}>
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_901_9)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.6327 3.40001H15.75C16.3467 3.40001 16.919 3.63882 17.341 4.0639C17.7629 4.48899 18 5.06552 18 5.66668V9.52779C16.9385 8.57771 15.5367 8 14 8C13.2457 8 12.524 8.13917 11.859 8.39325C11.8418 8.36563 11.8242 8.33823 11.8062 8.31107C11.4354 7.75194 10.9083 7.31616 10.2916 7.05882C9.67486 6.80148 8.99626 6.73415 8.34157 6.86534C7.68688 6.99653 7.08552 7.32035 6.61351 7.79585C6.14151 8.27134 5.82008 8.87717 5.68985 9.5367C5.55962 10.1962 5.62646 10.8799 5.88191 11.5011C6.13735 12.1224 6.56993 12.6534 7.12495 13.027C7.40367 13.2146 7.70698 13.3583 8.02443 13.4549C8.00826 13.6344 8 13.8162 8 14C8 15.0929 8.29218 16.1175 8.80269 17H2.25C1.65326 17 1.08097 16.7612 0.65901 16.3361C0.237053 15.911 0 15.3345 0 14.7333V5.66668C0 5.06552 0.237053 4.48899 0.65901 4.0639C1.08097 3.63882 1.65326 3.40001 2.25 3.40001H4.36725L5.74312 0.62675C5.83658 0.438353 5.98029 0.279915 6.15814 0.169211C6.33599 0.058508 6.54094 -8.12509e-05 6.75 1.68906e-05H11.25C11.46 -0.00113801 11.6662 0.0569531 11.8451 0.167715C12.024 0.278478 12.1686 0.437488 12.2625 0.62675L13.6327 3.40001Z"/>
                        <path d="M18 12.6667H15.3333V10H12.6667V12.6667H10V15.3333H12.6667V18H15.3333V15.3333H18V12.6667Z"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_901_9">
                            <rect width="18" height="18" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <PhotoPanelChooseFilesComp store={store} />
        </div>;
    }

}
