import {DOMElement, ReactElement} from "react";
import * as ReactDOM from "react-dom";
import {Utils} from "./Utils";
import {TextUtils} from "./TextUtils";
import * as React from "react";

export interface SvgDescription{
    svg: string;
    width: number;
    height: number;
}
export class ReactUtils{

    static getHighlight(str: string, substr: string, highlightClass: string = "blue-text", ordinaryClass: string = ""): any[]{
        let spanWords: any[] = [];
        if (str == null) return [];
        if (substr == null) return [<span className={ordinaryClass}>{str}</span>];
        let words = TextUtils.findSubstrings(str, substr, false);
        words.forEach((w, index)=>{
            let eq = TextUtils.isEqual(w, substr, false);
            if (eq) spanWords.push(<span key={index+w} className={highlightClass}>{w}</span>);
        else spanWords.push(<span key={index+w} className={ordinaryClass}>{w}</span>);
        });
        return spanWords;
    }

    static getComponentHtmlAsString(node:  ReactElement | DOMElement<any, any>): Promise<string>{
        return new Promise<string>((resolve) => {
            let div = document.createElement("div");
            ReactDOM.render(node, div, ()=>{
                let svg = div.innerHTML;
                ReactDOM.unmountComponentAtNode(div);
                resolve(svg);
            });
        });
    }

    static getSvgDescription(node:  ReactElement | DOMElement<any, any>): Promise<SvgDescription>{
        return new Promise<SvgDescription>((resolve) => {
            let div = document.createElement("div");
            ReactDOM.render(node, div, ()=>{
                let svg = div.innerHTML;
                let width = div.firstElementChild.getAttribute("width");
                let height = div.firstElementChild.getAttribute("height");
                ReactDOM.unmountComponentAtNode(div);
                resolve({svg: svg, width: Utils.parseNumber(width), height: Utils.parseNumber(height)});
            });
        });
    }
}