import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class CloudButtonSvg extends CustomSvg {
    render() {
        return <svg width="18" height="18" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style={this.getStyles()} className={this.getClasses()}>
            <path fillRule="evenodd" clipRule="evenodd" d="M488.334,247.417c14.624,18.916,23.666,42.312,23.666,68c0,61.749-50.25,112-112,112
                c-13.688,0-27.084-2.5-39.709-7.333c-28.958,25.249-65.749,39.333-104.291,39.333c-38.5,0-75.334-14.084-104.291-39.333
                c-12.625,4.833-26.021,7.333-39.709,7.333c-61.75,0-112-50.251-112-112c0-61.75,50.25-112,112-112
                c5.312,0,10.562,0.375,15.792,1.125c0.521-0.729,1.166-1.375,1.708-2.083c-0.812-4.938-1.5-9.896-1.5-15.041
                c0-49.167,40-89.146,89.125-89.146c2.416,0,4.812,0.104,7.188,0.292c23.125-28.854,58-45.979,95.688-45.979
                c37.709,0,72.562,17.125,95.688,45.979c2.375-0.188,4.75-0.292,7.146-0.292C472,98.271,512,138.25,512,187.417
                C512,210.583,502.916,231.521,488.334,247.417z"/>
        </svg>
    }
}
