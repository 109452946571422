import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {action} from "mobx";
import {observer} from "mobx-react";
import React, { MouseEvent } from "react";
import {fetchJsonGet} from "../../../helper/utils/FetchUtils";
import {IDapFieldsMaskOverlayParams} from "../../../store/config/ConfigStore";
import { CheckboxMiniComp, CheckboxSize, TriState } from "../../Common/CheckboxMiniComp";
import {DropDownComp, IDropDownItem} from "../../Common/DropDownComp";
import { GearSvg } from "../../icons/MiniIcon/GearSvg";
import { SmallCenterToSvg } from "../Left/Agro/Buttons/CenterToSvg";
import './ParameterizedOverlays.scss';
import { IParameterizedOverlayProps } from "../Right/LayersList/LayersListStore";
import { EllapseGroupSvg } from "../../icons/MiniIcon/EllapseGroupSvg";
import { ExpandGroupSvg } from "../../icons/MiniIcon/ExpandGroupSvg";

@observer
export class DapFieldsMaskOverlayComp extends React.Component<IParameterizedOverlayProps, undefined> {
    constructor(props: IParameterizedOverlayProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onOverlayToggled(e: any) {
        let dfm = this.props.store.map.dfm;
        let active = !dfm.active;
        dfm.active = active;
        if (active && dfm.currentProject == null) { 
            fetchJsonGet('/reports/api/projects').then(r => {
                dfm.projects = r;
            }).catch( err => {this.props.store.root.addError(err)} );
        }
    }

    @action
    onProjectChanged(e: any) {
        let dfm = this.props.store.map.dfm;
        if (dfm.currentProject == e.data) return;
        dfm.currentProject = e.data;
        dfm.currentSubproject = null;
        dfm.subprojects = [];
        dfm.currentModel = null;
        dfm.models = [];
        fetchJsonGet(`/reports/api/projects/${dfm.currentProject.id_project}/subprojects`)
        .then(r => {
            dfm.subprojects = r;
        }).catch( err => {this.props.store.root.addError(err)} );
    }

    @action
    onSubprojectChanged(e: any) {
        let dfm = this.props.store.map.dfm;
        if (dfm.currentSubproject == e.data) return;
        dfm.currentSubproject = e.data;
        dfm.currentModel = null;
        dfm.models = [];
        fetchJsonGet(`/reports/api/subprojects/${dfm.currentSubproject.id_subproject}/models`)
        .then(r => {
            dfm.models = r;
        }).catch( err => {this.props.store.root.addError(err)} );
    }

    @action
    onModelChanged(e: any) {
        let dfm = this.props.store.map.dfm;
        if (dfm.currentModel == e.data) return;
        dfm.currentModel = e.data;
    }

    onCenterToMaskBbox(event: MouseEvent) {
        event.stopPropagation();
        let map = this.props.store.map;
        map.zoomToBBox(map.dfm.currentModel.bbox as BBox2d);
    }

    render() {
        let ov = this.props.overlay;
        let params = ov.params as IDapFieldsMaskOverlayParams;
        let store = this.props.store;
        let dfm = store.map.dfm
        let active = dfm.active;

        let projectItems: IDropDownItem[] = [];
        dfm.projects.forEach(p => projectItems.push({key: p.id_project, value: p.name_project, data: p}));

        let subprojectItems: IDropDownItem[] = [];
        dfm.subprojects.forEach(d => subprojectItems.push({key: d.id_subproject, value: d.name_subproject + ", " + d.crop, data: d}));

        let modelItems: IDropDownItem[] = [];
        dfm.models.forEach(c => modelItems.push({key: c.uuid, value: c.model, data: c}));

        return <div className="ParameterizedOverlays-div"
                    style={{height: "auto"}} key={ov.id}>
                    <div className="ParameterizedOverlays-title" onClick={this.onOverlayToggled}>
                    <>
                                <div className="LayersListComp-overlays-group-item-expand-div">
                                {active? <EllapseGroupSvg/>: <ExpandGroupSvg/>}
                                </div>
                            
                            </>
                        <CheckboxMiniComp checkedEye state={active? TriState.check:TriState.uncheck}
                                        size={CheckboxSize.standart} classesContainer="pointer" styleBox={{marginLeft:"-15px"}}>
                        </CheckboxMiniComp>
                        <span className={classNames("LayersListComp-param-overlay-title", {
                            "LayersListComp-active-color": active,
                            "LayersListComp-inactive-color": !active
                        })}>{ov.title}</span>                        
                        {active && dfm.currentModel?.bbox && <div className="ParameterizedOverlays-gear blue_svg_onhover" onClick={this.onCenterToMaskBbox}>
                            <SmallCenterToSvg/>
                        </div>}
                        <div className="ParameterizedOverlays-gear">
                            <GearSvg/>
                        </div>
                    </div>
        {active && <div className="ParameterizedOverlays-grid DapFieldsMaskOverlayComp-grid">
            <div>
                <div className="DapFieldsMaskOverlayComp-vertical-line"></div>
                <div className="DapFieldsMaskOverlayComp-horizontal-line DapFieldsMaskOverlayComp-project-line"></div>
                <div className="DapFieldsMaskOverlayComp-horizontal-line DapFieldsMaskOverlayComp-district-line"></div>
                <div className="DapFieldsMaskOverlayComp-horizontal-line DapFieldsMaskOverlayComp-crop-line"></div>
            </div>
            <div>
                <div>
                    <span className="ParameterizedOverlays-paramname">Project:</span>
                    <DropDownComp currentKey={dfm.currentProject?.id_project} items={projectItems}
                                        onChange={this.onProjectChanged} className={'ParameterizedOverlays-drop-down'}>
                    </DropDownComp>
                </div>
                <div style={{paddingTop: 3}}>
                    <span className="ParameterizedOverlays-paramname">Subproject:</span>
                    <DropDownComp currentKey={dfm.currentSubproject?.id_subproject} items={subprojectItems}
                                        onChange={this.onSubprojectChanged} className={'ParameterizedOverlays-drop-down'}>
                    </DropDownComp>
                </div>
                <div style={{paddingTop: 3}}>
                    <span className="ParameterizedOverlays-paramname">Model:</span>
                    <DropDownComp currentKey={dfm.currentModel?.uuid} items={modelItems}
                                        onChange={this.onModelChanged} className={'ParameterizedOverlays-drop-down'}>
                    </DropDownComp>
                </div>
            </div>
        </div>}
    </div>
    }
}