import * as React from 'react';
import {IStoreProps} from "../../../../helper/structs/IStoreProps";


export class OrderStatusReady extends React.PureComponent<IStoreProps, undefined> {
    render() {
        let store = this.props.store;
        return <div className="journal-status success">
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM8.75 14.25L4.5 10L6.25 8.25L8.75 10.75L13.75 5.75L15.5 7.5L8.75 14.25Z"/>
            </svg>
            <div className="journal-status-name">{store.trans.Ready}</div>
        </div>;

    }
}
