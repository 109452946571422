import {ObservableCustomStore} from "../../app/store/CustomStore";
import {observable} from "mobx";
import {AgroAhoStore, AhoModalType} from "./agroAhoStore";
import {IAhoUrlType} from "./agroAhoStore";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {TrackInfo} from "./agroAhoCardStore";
import {Utils} from "../../app/helper/utils/Utils";
// import {LoadedIndicator} from "./agroAhoCardStore";


export enum AhoCellsValidateStatus {
    Ready= 'Ready',
    NoValid = 'NoValid',
    ServerError = 'ServerError'
}

export enum AhoCellsUploadStage {
    Ready = 'Ready',
    Success = 'Success',
    Error = 'Error'
}

// {"cnt": int, "columns": [{"name": "col_name", "values": ["v1", "v2", "v3", "v4", "v5"]}], "file_id": "identificator"
// "extent": {"lon_min": 41.883363343, "lon_max": 41.889371634, "lat_min": 44.497173345, "lat_max": 44.512534965}, "file_name": "cell.geojson"}
export interface ICellsUploadColumn {
    name: string;
    values: string[];
}

export interface ICellsUploadExtent {
    lon_min: number;
    lon_max: number;
    lat_min: number;
    lat_max: number;
}

export interface ICellsUploadResult {
    cnt: number;
    columns: ICellsUploadColumn[];
    extent: ICellsUploadExtent;
    file_id: string;
    file_name: string;
}


export class AgroAhoUploadCellsStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    @observable
    ahoProgress: number = 0;
    // @observable
    // ahoLoadedIndicators: LoadedIndicator[];
    cellsLoadedResult: ICellsUploadResult;
    cellValidateResult: any;
    @observable
    ahoValidateStatus: AhoCellsValidateStatus = AhoCellsValidateStatus.Ready;    
    @observable
    ahoUploadStage: AhoCellsUploadStage = AhoCellsUploadStage.Ready;
    uploadErrMsg: string = '';
    validateErrMsg: string = '';
    @observable
    column: ICellsUploadColumn = null;


    async uploadFile(file: any){
        let req = new XMLHttpRequest();
        let fd = new FormData();

        this.ahoProgress = 0;
        this.cellsLoadedResult = null;
        this.ahoUploadStage =AhoCellsUploadStage.Ready;

        fd.append("cell_file", file);
        // /api/projects/kazminskiy/asa_map/78/cell/upload?mode=sample
        req.open("post", `/api/projects/${this.parentStore.projStore.projName}/asa_map/${this.parentStore.cardStore.card.map_id}/cell/upload?mode=sample`);
        req.upload.onprogress = (event) => {
            this.ahoProgress = Math.round((event.loaded / event.total) * 100);
        }
        req.onerror = () => {
            this.uploadErrMsg = this.parentStore.trans['Loading error'];
            this.ahoUploadStage = AhoCellsUploadStage.Error;
            this.showError();
        }
        req.onload = () => {
            if (req.status !== 200) {                
                this.ahoUploadStage = AhoCellsUploadStage.Error;
                this.uploadErrMsg = this.parentStore.trans['Loading error'];
                if (req.response) {
                    let res = JSON.parse(req.response);
                    if (res && res.status === 'error')
                        this.uploadErrMsg = res.msg || res.message || '';
                }
                this.ahoUploadStage = AhoCellsUploadStage.Error;
                this.showError();
            } else {
                let res = JSON.parse(req.response);
                // let err_cnt = res?.errors?.common?.length || 0;
                // err_cnt += res?.errors?.row?.length || 0;
                if (res && (res.status === 'error')) {
                    // if (err_cnt) {
                    //     this.ahoLog = JSON.stringify(res, undefined, 4);
                    // } else
                    this.uploadErrMsg = res.msg || res.message || '';
                    this.ahoUploadStage = AhoCellsUploadStage.Error;
                    this.showError();
                    return;
                }
                // let arr: LoadedIndicator[] = [];
                // let is_warn: boolean = false;
                // if (res.indicators && Object.keys(res.indicators).length) {
                //     for (let key in res.indicators){
                //         let indc = res.indicators[key];
                //         arr.push({name: this.parentStore.cardStore.getIndicatorName(key), count: indc.probe_cnt, indc_code: key,
                //             warning: false});
                //     }
                // }
                // if (res.columns?.length) {
                //     for (let key in res.columns){
                //         let col = res.columns[key];
                //         if (col.col_class == 'extra_prop') {
                //             is_warn = true;
                //             arr.push({name: '', count: '', indc_code: col.col_name, warning: true})
                //         }
                //     }
                // }
                // if (arr.length) {
                //     this.ahoLoadedIndicators = arr;
                //     this.ahoLoadStatus = is_warn ? AhoLoadStatus.Warning : AhoLoadStatus.Success;
                // }
                this.ahoValidateStatus = AhoCellsValidateStatus.Ready;
                this.cellsLoadedResult = res;
                this.column = res.columns[0];
                this.parentStore.toggleModal(false);
                this.parentStore.toggleModal(true, AhoModalType.SelectFile);

                // this.ahoUploadStage = AhoCellsUploadStage.Success;
            }

        };
        req.send(fd);
    }

    showError(){
        this.root.addError(this.uploadErrMsg);
        this.parentStore.toggleModal(false);
    }

    async uploadFile__(file: any){
        this.cellsLoadedResult = {"cnt": 16, "columns": [{"name": "row_num", "values": ["1", "2", "3", "4", "5"]}, {"name": "cell_id", "values": ["11", "9", "15", "3", "5"]}, {"name": "cell_num", "values": ["9", "3", "5", "4", "10"]}, {"name": "no3", "values": ["38.5", "34.0", "75.0", "20.6", "12.0"]}], "extent": {"lon_min": 41.883363343, "lon_max": 41.889371634, "lat_min": 44.497173345, "lat_max": 44.512534965}, "file_id": "e80b3f8dc6f311ff839182bf3f6dcf9a", "file_name": "cell.geojson"};
        this.parentStore.toggleModal(false);
        this.parentStore.toggleModal(true, AhoModalType.SelectFile);
    }

    clearFileLoadData(){
        this.ahoProgress = 0;        
        this.uploadErrMsg = '';
        this.cellsLoadedResult = null;
        this.ahoValidateStatus = AhoCellsValidateStatus.Ready;
        this.ahoUploadStage = AhoCellsUploadStage.Ready;
    }

    async onClickSelectColumn(cell_name: string){
        let res = await this.doValidate(cell_name);
        let isNoResult = !res || (res && !res.errors);
        let isNoValid = res && (
            (res.errors?.common && res.errors.common.length) || (res.errors?.row && res.errors.row.length)
        );
        if (isNoResult) {
            this.validateErrMsg = res ? JSON.stringify(res) : this.parentStore.trans['Loading error'];
            this.ahoValidateStatus = AhoCellsValidateStatus.ServerError;
        }
        if (!isNoResult && isNoValid) {
            this.cellValidateResult = res;
            this.ahoValidateStatus = AhoCellsValidateStatus.NoValid;
        }
        if (!isNoResult && !isNoValid) await this.saveClick();
    }

    async doValidate(cell_name: string){
        let store_ = this.parentStore;
        let r = [
            store_.projStore.projName,
            store_.cardStore.card.map_id,
            this.cellsLoadedResult.file_id,
            cell_name
        ];
        // /api/projects/kazminskiy/asa_map/78/cell/upload?mode=validate&file_id=&cell_id=<имя столбца id ячейки>
        // let url = `/api/projects/${r[0]}/asa_map/${r[1]}/cell/upload?mode=validate&file_id=${r[2]}&cell_id=${r[3]}`;
        let url = `/api/projects/${r[0]}/asa_map/${r[1]}/cell/upload?mode=validate&file_id=${r[2]}&cell_id=${r[3]}`;
        try {return await fetchJsonGet(url)} catch (e) {/*console.log('e:', e)*/}
    }

    async doSave(){
        // console.log('saved1');
        let store_ = this.parentStore;
        // /api/projects/kazminskiy/asa_map/78/cell/upload?mode=save&file_id=&cell_id=<имя столбца id ячейки>
        let r = [
            store_.projStore.projName,
            store_.cardStore.card.map_id,
            this.cellsLoadedResult.file_id,
            this.column.name
        ];
        // console.log('saved2');
        // let url = `/api/projects/${r[0]}/asa_map/${r[1]}/cell/upload?mode=save&file_id=${r[2]}&cell_id=${r[3]}`;
        let url = `/api/projects/${r[0]}/asa_map/${r[1]}/cell/upload?mode=save&file_id=${r[2]}&cell_name=${r[3]}`;
        // try {return await fetchJsonGet(url)} catch (e) {}
        // console.log('saved3');
        return await fetchJsonGet(url).catch(()=>{});
    }

    async deleteTrackLayer(){
        let ms = this.parentStore.mapStore;
        let cfg = ms.getCardLayersState();
        cfg.tracks = false;
        await ms.updCardLayers({cfg_: cfg, caller: 'deleteTrackLayer'});
    }

    async saveClick(){
        let res = await this.doSave();
        // console.log('saved click', res);
        if (!res || res.result !== 'ok') {
            this.validateErrMsg = res ? JSON.stringify(res) : this.parentStore.trans["Save error"];
            this.ahoValidateStatus = AhoCellsValidateStatus.ServerError;
            return;
        }
        // this.parentStore.cardStore.isCellsCanGenerate = true;
        this.parentStore.toggleModal(false);
        this.clearFileLoadData();
        this.ahoProgress = 0;
        this.cellsLoadedResult = null;
        this.ahoValidateStatus = AhoCellsValidateStatus.Ready;
        this.ahoUploadStage = AhoCellsUploadStage.Ready;
        this.parentStore.mapStore.resetCellCache();
        await this.deleteTrackLayer();
        await this.parentStore.cardStore.updIndicators();
        await this.parentStore.mapStore.updAllCardLayers(400);
        // console.log('fields update 0');
        // await this.parentStore.cardStore.updFieldsInfo();
        await this.parentStore.cardStore.updCellsInfo();
        // console.log('fields update 1');
        this.root.addInfo(this.parentStore.trans["Cell outlines loaded successfully"]);
    }

    loadCancelClick(){
        this.parentStore.toggleModal(false);
        this.clearFileLoadData();
        this.ahoProgress = 0;
        this.cellsLoadedResult = null;
        this.ahoValidateStatus = AhoCellsValidateStatus.Ready;
        this.ahoUploadStage = AhoCellsUploadStage.Ready;
    }


}