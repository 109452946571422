import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import './LegendFixIndexComp.scss';
import {ProductSettingStore} from "../../../store/productSetting/ProductSettingStore";
import classNames from "classnames";
import {TranslateUtils} from "../../../helper/lang/TranslateUtils";

export interface ILegendFixIndexComp extends IStoreProps{
    prod: ProductSettingStore
}

type CellType = "center" | "top" | "bottom" | "single";
@observer
export class LegendFixIndexComp extends React.Component<ILegendFixIndexComp, undefined> {
    constructor(props: ILegendFixIndexComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let rows: any[] = [];
        let prod = this.props.prod;
        if (prod.productConfig == null || prod.productConfig.fixedIndexColors == null) return null;
        let arr= prod.productConfig.fixedIndexColors;
        arr.forEach((a, index) => {
            let typeCell: CellType = "center";
            if (index == 0) typeCell = "top";
            if (index == arr.length - 1) typeCell = "bottom";
            if (arr.length == 1) typeCell = "single";
            let text = TranslateUtils.getTranslateFromDic(a.title, store.getLang());

            rows.push(<tr key={index.toString()+a.color}>
                <td className="LegendFixIndexComp-td"><div className={classNames("LegendFixIndexComp-ballon",{
                    "LegendFixIndexComp-ballon-middle": typeCell == "center",
                    "LegendFixIndexComp-ballon-top": typeCell == "top",
                    "LegendFixIndexComp-ballon-bottom": typeCell == "bottom",
                    "LegendFixIndexComp-ballon-single": typeCell == "single",
                })} style={{background: a.color}} /></td>
                <td className="LegendFixIndexComp-td">
                    <div className="flex-columns-centerByVertical">
                        <div className="LegendFixIndexComp-line"/>
                        <div className="LegendFixIndexComp-text">{text}</div>
                    </div>
                </td>
            </tr>);
        });

        return <div>
            <div className="legend-title">{this.props.prod.productConfig.abbreviation}</div>
            <table className="LegendFixIndexComp-table">
                <colgroup>
                    <col className="LegendFixIndexComp-tableCol1"/>
                    <col className="LegendFixIndexComp-tableCol2"/>
                </colgroup>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>;
    }
}
