import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {IShowRoomDistrict} from "../../../../../store/dataShowroom2/ShowRoom2FilterProjectStore";
import {CheckboxMiniComp, CheckboxSize} from "../../../../Common/CheckboxMiniComp";
import {action} from "mobx";
import {fetchJsonGet} from "../../../../../helper/utils/FetchUtils";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";

export interface IShowRoom2FiltersDistrictsItem extends IStoreProps{
    item: IShowRoomDistrict
}
@observer
export class ShowRoom2FiltersDistrictsItem extends React.Component<IShowRoom2FiltersDistrictsItem, undefined> {
    constructor(props: IShowRoom2FiltersDistrictsItem) {
        super(props);
        autoBindReact(this);
    }

    getState(): boolean{
        return this.props.store.dataShowRoomStore.filtersProject.filter_districts_ids.has(this.props.item.id);
    }
    @action
    onClick(){
        let newValue = !this.getState();
        if (newValue){
            this.props.store.dataShowRoomStore.filtersProject.filter_districts_ids.add(this.props.item.id);
        }else{
            this.props.store.dataShowRoomStore.filtersProject.filter_districts_ids.delete(this.props.item.id);
        }
    }

    async onZoom(){
        let store = this.props.store;
        let json = await fetchJsonGet(`/reports/api/districts/${this.props.item.id}/bbox`);
        //{"sw": [73.12259060200006, 35.52792040400004], "ne": [74.16699100000005, 35.98840000000007]}
        let b: BBox2d = [json.sw[0], json.sw[1], json.ne[0], json.ne[1]]; //bbox(f) as BBox2d;
        store.map.zoomToBBox1in3(b);
    }

    render() {
        let store = this.props.store;
        return <div className="ShowRoom2FiltersDistrictsState-line-district ShowRoom2FiltersDistrictsState-line-hover">
            <CheckboxMiniComp state={this.getState()} size={CheckboxSize.standart} onClick={this.onClick} />
            <div className="ShowRoom2FiltersDistrictsState-line-label" onClick={this.onClick}>{this.props.item.distrname}</div>
            <svg className="ShowRoom2FiltersDistrictsState-line-zoom" onClick={this.onZoom}
                width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 8.25C7.24264 8.25 8.25 7.24264 8.25 6C8.25 4.75736 7.24264 3.75 6 3.75C4.75736 3.75 3.75 4.75736 3.75 6C3.75 7.24264 4.75736 8.25 6 8.25Z" fill="#C5C5C5"/>
                <path d="M1.5 4.5H0V0.75C0 0.3 0.3 0 0.75 0H4.5V1.5H1.5V4.5Z" fill="#C5C5C5"/>
                <path d="M12 4.5H10.5V1.5H7.5V0H11.25C11.7 0 12 0.3 12 0.75V4.5Z" fill="#C5C5C5"/>
                <path d="M11.25 12H7.5V10.5H10.5V7.5H12V11.25C12 11.7 11.7 12 11.25 12Z" fill="#C5C5C5"/>
                <path d="M4.5 12H0.75C0.3 12 0 11.7 0 11.25V7.5H1.5V10.5H4.5V12Z" fill="#C5C5C5"/>
            </svg>
        </div>;
    }
}
