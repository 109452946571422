import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from '../../../../../helper/structs/IStoreProps';
import {IndexDataGroup} from "../../../../../store/indeByPoint/IndexByPointerStore";
import './IndexByPointPopupGroupsItemComp.scss';
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {CheckboxMiniComp, CheckboxSize} from "../../../../Common/CheckboxMiniComp";
import {action} from "mobx";

export interface IIndexByPointPopupGroupsItemComp extends IStoreProps{
    group: IndexDataGroup,
    checked: boolean,
    onClick: (group: IndexDataGroup)=>any,
}
@observer
export class IndexByPointPopupGroupsItemComp extends React.Component<IIndexByPointPopupGroupsItemComp, undefined> {
    constructor(props: IIndexByPointPopupGroupsItemComp) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClick(){
        this.props.onClick(this.props.group);
    }

    render() {
        let store = this.props.store;
        return <div className="IndexByPointPopupGroupsItemComp-GroupItem" onClick={this.onClick}>
            <CheckboxMiniComp state={this.props.checked} size={CheckboxSize.grayV} classesContainer="pointer">
                <span className="text-next">{TranslateUtils.getTitleIndexDataGroup(this.props.group, store)}</span>
            </CheckboxMiniComp>
        </div>;
    }
}
