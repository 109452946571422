import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {LegendLinearComp} from "./LegendLinearComp";
import {ColorHelper, IRGBA} from "../../../helper/utils/ColorHelper";
import {IDiscreteInterval} from "../../../store/productSetting/IndexBandProductStore";
import classNames from "classnames";
import {Utils} from "../../../helper/utils/Utils";
import {ra} from "../../../helper/utils/mobxUtils";
import "./LegendDiscreteComp.scss";
import {LegendAreaMode} from "../../../store/productSetting/LegendAreaStore";
import {action} from "mobx";
import {CheckboxMiniComp, CheckboxSize} from "../../Common/CheckboxMiniComp";
import {LegendCheckBoxComp} from "./LegendCheckBoxComp";

export interface ILegendDiscreteComp extends IStoreProps{
    title?: string;
    intervals: IDiscreteInterval[];
    areaMode?: LegendAreaMode;
    numAreas: number[];//площади по интервалам
    onClickCheckBox?: (idx :number)=>any,
    showCheckBoxes?: boolean;//default= true
    footerComp?: any;
    tableClass?: string;
}
@observer
export class LegendDiscreteComp extends React.Component<ILegendDiscreteComp, undefined> {
    constructor(props: ILegendDiscreteComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    toArea(){
        this.props.store.map.productInfo.legendAreaStore.mode = LegendAreaMode.area;
    }
    @action
    toProcent(){
        this.props.store.map.productInfo.legendAreaStore.mode = LegendAreaMode.procent;
    }

    render() {
        let store = this.props.store;

        let dia = this.props.intervals;
        let arr: any[] = [];
        let areaMode: LegendAreaMode = this.props.areaMode;

        let numsValues: number[] = [];
        dia.forEach((a, index) =>{
            if (index == 0) numsValues.push(a.min);
            numsValues.push(a.max);
        });
        let txtNums = LegendLinearComp.toNumbersToText(numsValues);
        let numToText: Map<number, string> = new Map<number, string>();
        txtNums.forEach((a, index) =>{
            numToText.set(numsValues[index], txtNums[index]);
        });

        let numAreas = this.props.numAreas;
        numAreas = numAreas.reverse();
        let sumArea = 0;
        numAreas.forEach(a => sumArea += a);

        let txtAreas = LegendLinearComp.toNumbersToText([...numAreas, sumArea]);//подмешиваем туда же сумму площадей
        let sumAreaText = txtAreas[txtAreas.length - 1];


        function getColor(dia: IDiscreteInterval):IRGBA{
            let c = {...dia.color};
            return c;
        }

        function getAreaStr(index: number){
            let areaStr: string = null;
            if (index < 0) return null;
            if (areaMode == LegendAreaMode.area) {
                if (index < txtAreas.length) areaStr = txtAreas[index];
            }
            if (areaMode == LegendAreaMode.procent) {
                if (index < numAreas.length) areaStr = Math.round(numAreas[index] / sumArea * 100).toString()+"%";
            }
            return areaStr;
        }


        let showCheckBoxes = (this.props.showCheckBoxes??true);
        if (dia.length > 0) {
            if (dia.length == 1) {
                let a = dia[0];

                let c = getColor(dia[0]);
                let areaStr: string = getAreaStr(0);
                let txtMin: string = store.trans.less;// numToText.get(dia[0].min);
                let txtMax: string = store.trans.more; numToText.get(dia[0].max);


                arr.push(<React.Fragment key={1}>
                    <tr className="LegendDescreteArea-tableRow">
                        <td className="LegendDescreteArea-tableCell1">
                            {showCheckBoxes && <div style={{top: "15px"}} className="LegendDescreteArea-check">
                                <CheckboxMiniComp state={a.isTransparent} size={CheckboxSize.standart} onClick={click} data-attr={"0"} />
                            </div>}
                            <div className="LegendDescreteArea-ballon LegendDescreteArea-ballon-single"
                                 style={{background: ColorHelper.ColorRGBAtoCSS(c)}}
                                 onClick={click} data-id={"0"} />
                            <div className="LegendDescreteArea-ballon-TopLine LegendDescreteArea-ballon-absLines"/>
                        </td>
                        <td>
                            <div className="LegendDescreteArea-tableCol2Text">{txtMax}</div>
                        </td>
                        <td className="LegendDescreteArea-tableCol3">

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="LegendDescreteArea-tableRowDashed">
                            <div className="LegendDescreteArea-tableRowDivDashed"/>
                        </td>
                        <td className="LegendDescreteArea-tableRowDashed"/>
                    </tr>
                    <tr className="LegendDescreteArea-tableRow">
                        <td className="LegendDescreteArea-tableCell1">
                        </td>
                        <td>
                            <div className="LegendDescreteArea-tableCol2Text">{txtMin}</div>
                        </td>
                        <td  className={classNames("LegendDescreteArea-tableCol3", {"LegendDescreteArea-hidden": areaStr == null})}>
                            <div className="LegendDescreteArea-tableCol3Text">{areaStr}</div>
                        </td>
                    </tr>
                </React.Fragment>);
            } else {
                dia.forEach((a, index)=>{
                    let idxReal = (dia.length - 1) - index;
                    let txtMax: string = numToText.get(a.max);
                    if (index == 0) txtMax = store.trans.more;
                    let c = getColor(a);
                    let areaStr: string = getAreaStr(index - 1);
                    let areaStrCur: string = getAreaStr(index);


                    arr.push(<React.Fragment key={index}>
                        <tr className="LegendDescreteArea-tableRow">
                            <td className="LegendDescreteArea-tableCell1">
                                <div className="LegendDescreteArea-tableCell1Div">
                                    {showCheckBoxes && <div className="LegendDescreteArea-check flex-full-center">
                                        <LegendCheckBoxComp checked={!a.isTransparent} onClick={click2} data={idxReal} store={store} />
                                    </div>}
                                    <div onClick={click} data-id={idxReal.toString()}
                                        className={classNames("LegendDescreteArea-ballon",
                                        {"LegendDescreteArea-ballon-top": index == 0,
                                            "LegendDescreteArea-ballon-middle": index > 0 && index < (dia.length - 1),
                                            "LegendDescreteArea-ballon-bottom": index == dia.length - 1,
                                            })}
                                          style={{background: ColorHelper.ColorRGBAtoCSS(c)}}>
                                        {a.isTransparent && <div style={{color:"transparent"}}>
                                            <svg width="16" height="31" viewBox="0 0 16 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 28.6627V0H13.9109L0 28.6627ZM16 2.53457V31H2.18485L16 2.53457Z" fill="#22282F"/>
                                            </svg>
                                        </div>}
                                    </div>

                                    {<div className="LegendDescreteArea-ballon-BottomLine LegendDescreteArea-ballon-absLines"/>}
                                    {index == 0 && <div className="LegendDescreteArea-ballon-TopLine LegendDescreteArea-ballon-absLines"/>}


                                </div>
                            </td>
                            <td>
                                <div className="LegendDescreteArea-tableCol2Text">{txtMax}</div>
                            </td>
                            <td className={classNames("LegendDescreteArea-tableCol3", {"LegendDescreteArea-hidden": areaStrCur == null})}>
                                <div className="LegendDescreteArea-tableCol3Text">{areaStr}</div>
                            </td>
                        </tr>
                        <tr className={classNames({"LegendDescreteArea-hidden": areaStrCur == null})}>
                            <td colSpan={2} className="LegendDescreteArea-tableRowDashed">
                                <div className="LegendDescreteArea-tableRowDivDashed"/>
                            </td>
                            <td className="LegendDescreteArea-tableRowDashed"/>
                        </tr>
                    </React.Fragment>);
                });

                let a = dia[dia.length - 1];
                let txtMin: string = store.trans.less; //numToText.get(a.min);

                let areaStr: string = getAreaStr(dia.length - 1);

                arr.push(<tr className="LegendDescreteArea-tableRow" key="last">
                        <td>
                        </td>
                        <td>
                            <div className="LegendDescreteArea-tableCol2Text">{txtMin}</div>
                        </td>
                        <td className={classNames("LegendDescreteArea-tableCol3", {"LegendDescreteArea-hidden": areaStr == null})}>
                            <div className="LegendDescreteArea-tableCol3Text">{areaStr}</div>
                        </td>
                    </tr>
                );

            }
        }

        let onClickCheckBox = this.props.onClickCheckBox;
        function click(e: any){
            ra(()=>{
                let idx = Utils.parseNumber(e.currentTarget.getAttribute("data-id"));
                if (idx == null) idx = Utils.parseNumber(e.currentTarget.getAttribute("data-attr"));
                if (onClickCheckBox) onClickCheckBox(idx);
            });
        }
        function click2(idx: number) {
            ra(()=>{
                if (onClickCheckBox) onClickCheckBox(idx);
            });
        }

        return <div>
            {this.props.title && <div className="legend-title">{this.props.title}</div>}
            <table className={this.props.tableClass ?? "LegendDescreteArea-table"}>
            <colgroup>
                <col className={classNames({"LegendDescreteArea-tableCol1": showCheckBoxes, "LegendDescreteArea-tableCol1-hiddenCheckbox": !showCheckBoxes})}/>
                <col className="LegendDescreteArea-tableCol2"/>
                <col className="LegendDescreteArea-tableCol3"/>
            </colgroup>
            <tbody>
            {arr}
            </tbody>
        </table>
            {numAreas.length > 0 &&
            <div className="LegendDescreteArea-footer">
                <div className="LegendDescreteArea-switcher">
                    <div onClick={this.toArea}
                        className={classNames("LegendDescreteArea-switcherItem",{"LegendDescreteArea-switcherItemActive": areaMode == LegendAreaMode.area})}>ha</div>
                    <div onClick={this.toProcent}
                        className={classNames("LegendDescreteArea-switcherItem",{"LegendDescreteArea-switcherItemActive": areaMode == LegendAreaMode.procent})}>
                        <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.32708 0.383438L1.63379 8.9234L2.67385 9.61677L8.36715 1.07681L7.32708 0.383438Z"/>
                            <path
                                d="M4.375 2.5C4.375 2.12916 4.26503 1.76665 4.05901 1.45831C3.85298 1.14996 3.56014 0.909641 3.21753 0.767727C2.87492 0.625812 2.49792 0.588681 2.13421 0.661028C1.77049 0.733376 1.4364 0.911952 1.17418 1.17418C0.911952 1.4364 0.733376 1.77049 0.661028 2.13421C0.588681 2.49792 0.625812 2.87492 0.767727 3.21753C0.909641 3.56014 1.14996 3.85298 1.45831 4.05901C1.76665 4.26503 2.12916 4.375 2.5 4.375C2.99728 4.375 3.4742 4.17746 3.82583 3.82583C4.17746 3.4742 4.375 2.99728 4.375 2.5ZM1.875 2.5C1.875 2.37639 1.91166 2.25555 1.98033 2.15277C2.04901 2.04999 2.14662 1.96988 2.26082 1.92258C2.37503 1.87527 2.50069 1.86289 2.62193 1.88701C2.74317 1.91113 2.85453 1.97065 2.94194 2.05806C3.02935 2.14547 3.08888 2.25683 3.11299 2.37807C3.13711 2.49931 3.12473 2.62497 3.07743 2.73918C3.03012 2.85338 2.95001 2.95099 2.84723 3.01967C2.74445 3.08835 2.62361 3.125 2.5 3.125C2.33424 3.125 2.17527 3.05915 2.05806 2.94194C1.94085 2.82473 1.875 2.66576 1.875 2.5Z"/>
                            <path
                                d="M7.5 5.625C7.12916 5.625 6.76665 5.73497 6.45831 5.94099C6.14996 6.14702 5.90964 6.43986 5.76773 6.78247C5.62581 7.12508 5.58868 7.50208 5.66103 7.86579C5.73338 8.22951 5.91195 8.5636 6.17418 8.82583C6.4364 9.08805 6.77049 9.26662 7.13421 9.33897C7.49792 9.41132 7.87492 9.37419 8.21753 9.23227C8.56014 9.09036 8.85298 8.85004 9.05901 8.54169C9.26503 8.23335 9.375 7.87084 9.375 7.5C9.375 7.00272 9.17746 6.52581 8.82583 6.17417C8.4742 5.82254 7.99728 5.625 7.5 5.625ZM7.5 8.125C7.37639 8.125 7.25555 8.08834 7.15277 8.01967C7.04999 7.95099 6.96988 7.85338 6.92258 7.73918C6.87527 7.62497 6.86289 7.49931 6.88701 7.37807C6.91113 7.25683 6.97065 7.14547 7.05806 7.05806C7.14547 6.97065 7.25683 6.91112 7.37807 6.88701C7.49931 6.86289 7.62497 6.87527 7.73918 6.92258C7.85338 6.96988 7.95099 7.04999 8.01967 7.15277C8.08835 7.25555 8.125 7.37639 8.125 7.5C8.125 7.66576 8.05915 7.82473 7.94194 7.94194C7.82473 8.05915 7.66576 8.125 7.5 8.125Z"/>
                        </svg>
                    </div>
                </div>
                {areaMode == LegendAreaMode.area &&<div className="LegendDescreteArea-footerSumAreaLabel">{sumAreaText} {store.trans.ha}</div>}
            </div>}
            {this.props.footerComp}
        </div>;
    }
}
