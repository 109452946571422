// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowRoom2Layer {
  margin: 8px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ShowRoom2Layer-label {
  margin-left: 8px;
  flex-grow: 1;
}

.ShowRoom2Layer-stat {
  font-size: 11px;
  color: #6D7785;
  width: 80px;
  padding-right: 16px;
  text-align: right;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.ShowRoom2Layer-stat-loading {
  width: 30px;
  max-width: 30px;
  height: 10px;
}

div.ShowRoom2Layer-disabled {
  opacity: 0.3;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/dataShowRoom2/ShowRoom2Layer.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACF;;AACA;EACE,gBAAA;EACA,YAAA;AAEF;;AAAA;EACE,eAAA;EACA,cAAA;EACA,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,yBAAA;AAGF;;AADA;EACE,WAAA;EACA,eAAA;EACA,YAAA;AAIF;;AAFA;EACE,YAAA;EACA,eAAA;AAKF","sourcesContent":[".ShowRoom2Layer{\r\n  margin: 8px 15px;\r\n  display: flex;\r\n  align-items: center;\r\n  cursor: pointer;\r\n}\r\n.ShowRoom2Layer-label{\r\n  margin-left: 8px;\r\n  flex-grow: 1;\r\n}\r\n.ShowRoom2Layer-stat{\r\n  font-size: 11px;\r\n  color: #6D7785;\r\n  width: 80px;\r\n  padding-right: 16px;\r\n  text-align: right;\r\n  box-sizing: border-box;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n.ShowRoom2Layer-stat-loading{\r\n  width: 30px;\r\n  max-width: 30px;\r\n  height: 10px;\r\n}\r\ndiv.ShowRoom2Layer-disabled{\r\n  opacity: 0.3;\r\n  cursor: default;\r\n}\r\n.ShowRoom2Layer-icon{\r\n\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
