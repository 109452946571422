import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {action, observable} from "mobx";
import {ContextMenuCommonComp} from "../../../Common/ContextMenuCommonComp";
import {ConfirmFormComp} from "../../../Common/ConfirmFormComp";
import { SmallRemoveAllSvg } from '../../../icons/MiniIcon/RemoveAllSvg';

@observer
export class IndexByPointDeleteAllPointsComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action onClickDeleteAllPoints(){
        this.props.store.indexByPointer.graphs = [];
        this.props.store.indexByPointer.yearPoints = [];
        this.props.store.indexByPointer.points = [];
    }

    @observable
    open: boolean = false;

    @action
    onClosePopup(){
        this.open = false;
    }

    @action
    onOpen(){this.open = true;}

    render() {
        let store = this.props.store;
        return <div onClick={this.onOpen}
                    className={classNames("indexByPoint_headerline-button indexByPoint_headerline-marginLeftButton",
                                                    "red-svg",
                                                    "indexByPoint_headerline-deleteAll full-center-content")}
                                                    >

            <SmallRemoveAllSvg/>
            {this.open && <ContextMenuCommonComp
                indentVertical={5}
                autoCornerWidth={125}
                autoCornerHeight={155}
                onClose={this.onClosePopup}
                className="">

                <ConfirmFormComp className=""
                                 text={store.trans["Are you sure to delete all points?"]}>
                    <div className="flex-columns">
                        <div className="red underline-link AgroConfirmDeleteFarmComp-button" onClick={this.onClickDeleteAllPoints}>{store.trans.Delete}</div>
                        <div className="white text-next-2 underline-link AgroConfirmDeleteFarmComp-button" onClick={this.onClosePopup}>{store.trans.Cancel}</div>
                    </div>
                </ConfirmFormComp>
            </ContextMenuCommonComp>}
        </div>;
    }
}
