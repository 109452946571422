import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './Sentinel5PanelComp.scss';
import {SwitchText} from "../../../../Common/SwitchText";
import {SearchParamsS5Type} from "../../../../../store/SearchParamsS5Store";
import {action} from "mobx";
import {Sentinel5ScenesComp} from "./Sentinel5ScenesComp";
import {Sentinel5CompositesComp} from "./Sentinel5CompositesComp";

@observer
export class Sentinel5PanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    clickTextSwitch(check1: boolean){
        if(check1){
            this.props.store.searchPanel.searchParamsS5.type = SearchParamsS5Type.scenes;
        }else this.props.store.searchPanel.searchParamsS5.type = SearchParamsS5Type.composites;
    }

    render() {
        let store = this.props.store;
        return <div className="Sentinel5Panel-panel-div">
            <div className="separator_line "/>
            <SwitchText text1={store.trans.Day}
                        text2={store.trans["Period"]}
                        className="Sentinel5Panel-marginTop15"
                        check1={store.searchPanel.searchParamsS5.type == SearchParamsS5Type.scenes}
                        onClick={this.clickTextSwitch}
            />
            {store.searchPanel.searchParamsS5.type == SearchParamsS5Type.scenes && <Sentinel5ScenesComp store={store} />}
            {store.searchPanel.searchParamsS5.type == SearchParamsS5Type.composites && <Sentinel5CompositesComp store={store} /> }
        </div>;
    }
}
