import {ISuperStore} from "../../pluginApi/store/SuperStore";
import {classRegPlugin} from "../../pluginApi";
import {ra} from "../../app/helper/utils/mobxUtils";
import {ClassFactory, ClassValueUpdateType} from "../../app/store/ClassFactory";
import {AgroAhoStore, AhoStage} from "./agroAhoStore";
import {AgroAhoEvents} from "./AgroAhoEvents";
import {isString} from "lodash-es";
import {IUrlParams} from "../../app/helper/utils/UrlCoder";
import {MeteoTool} from "../meteo/MeteoTool";
import {MeteoClickTool} from "../meteo/MeteoClickTool";
import {IMeteoSuperStore} from "../meteo/meteoPlugin";
import {AgroAhoTool} from "./map/agroAhoTool";
import {DeleteContourTool} from "../../app/store/tools/EditGeometry/DeleteContourTool";
import {MidPointTool} from "../../app/store/tools/EditGeometry/MidPointTool";
import {CreatePolygonDrawTool} from "../../app/store/tools/EditGeometry/CreatePolygonDrawTool";
import {AutoPolygonTool} from "../../app/store/tools/EditGeometry/AutoPolygonTool";
import {EditUndoRedoTool} from "../../app/store/tools/EditGeometry/EditUndoRedoTool";
import {ViewGeometryLayersTool} from "../../app/store/tools/EditGeometry/ViewGeometryLayersTool";
import {EditDrawTool} from "../../app/store/tools/EditGeometry/EditDrawTool";
import {VoronovStopClickTool} from "../agroExport/tools/voronov/VoronovStopClickTool";

export interface IAgroAhoStoreProps {
    store: IAgroAhoSuperStore,
    className?: string
}
export interface IAgroAhoSuperStore extends ISuperStore{
    agroAhoStore: AgroAhoStore;
    // agrometrikaAhoStore: AgrometrikaAhoStore;
    agrometrikaAhoStore: AgroAhoStore;
}

classRegPlugin({pluginName: "agroAho",
        initStore: init,
    }
);

export const LeftPanelModeAgroAho = 'agrometrikaAho';


//export const LeftPanelModeMeteo = 'meteo';

function init(store: IAgroAhoSuperStore){
    ra(()=>{
        store.agroAhoStore = new AgroAhoStore(store as any);
        // TODO debug
        (document as any).agroAhoStore = store.agroAhoStore;
        store.agrometrikaAhoStore = store.agroAhoStore;
        // store.agrometrikaAhoStore = new AgrometrikaAhoStore(store as any);
        // ClassFactory.registerProperty(store, "agroAhoStore", ClassValueUpdateType.update);
        ClassFactory.registerProperty(store, "agrometrikaAhoStore", ClassValueUpdateType.update);
        //store.agroAhoStore.active = true; //store.config.meteoShow;
        store.agroAhoStore.calculateTranslate();
        store.events.onChangeLang.addListen(()=>{
            store.agroAhoStore.calculateTranslate();
        });
        let ev = new AgroAhoEvents(store);
        store.events.onLeftPanelChildren.addListen((st, children)=>{
            ev.onLeftPanelChildrens(children);
        });

        // store.events.onReadUrl.addListen((st, pm: IUrlParams) =>{
        //     if (isString(pm.aho)) {
        //         store.agroAhoStore.urlStore.urlPermalinkCode = pm.aho;
        //     }
        // });
        // store.events.onWriteUrl.addListen((st, p: IUrlParams)=>{
        //     if (store.searchPanel.leftPanelMode === LeftPanelModeAgroAho &&
        //         store.agroAhoStore.ahoStage === AhoStage.Card && store.agroAhoStore.urlStore.urlAhoCode) {
        //         p.aho = store.agroAhoStore.urlStore.urlAhoCode;
        //     }
        // })

        store.events.onMapChildren.addListen((st, children)=>{
            ev.onMapChildren(children);
        });
        store.events.onLeftSidebarLeftChildren.addListen((st, children)=>{
            ev.onLeftSidebarLeftChildren(children);
        });
        addTools(store);
    });

}

function addTools(store: IAgroAhoSuperStore){

    let mstore = store.agroAhoStore.mapStore;
    let estore = store.agroAhoStore.editorStore;
    mstore.agroAhoTool = new AgroAhoTool(mstore.root, "AgroAhoTool");
    // store.map.superTools.pushToolAfter(mstore.agroAhoTool, null);
    store.map.superTools.pushToolAfter(mstore.agroAhoTool, ['SearchConourTool']);

    let cont = mstore.root.map.superTools.container;
    let state = estore.editManager.state;

    let viewGeomEditVor = new ViewGeometryLayersTool(cont, state, "ViewAhoGeometryLayersTool-editVor");
    estore.editManager.viewTool = viewGeomEditVor;
    viewGeomEditVor.Prefix = "class_toolsAhoEditVor_";
    // viewGeomEditVor.colorVertex = "#000000"; //"#FFFF00"; // обводка вершин
    // viewGeomEditVor.colorDarkBlue = '#15ED6B'; //"#ffFF50"; // линии
    // viewGeomEditVor.colorMidPoint = '#FFFFFF' //"#ffFF50"; // центр средней точки
    // viewGeomEditVor.colorMidPointLight = "#FFFFFF"; // центр вершин
    // viewGeomEditVor.colorVertexMidPoint = '#000000' // обводка средней точки
    // viewGeomEditVor.lineWidth = 4;
    viewGeomEditVor.style.colorSecondDotOuter = "#000000"; //"#FFFF00"; // обводка вершин
    viewGeomEditVor.style.colorFill = '#15ED6B'; //"#ffFF50"; // линии
    viewGeomEditVor.style.colorLine = '#15ED6B'; //"#ffFF50"; // линии 2
    viewGeomEditVor.style.colorInsertDotInner = '#FFFFFF' //"#ffFF50"; // центр средней точки
    viewGeomEditVor.style.colorFirstDotInner = "#FFFFFF"; // центр вершин
    viewGeomEditVor.style.colorSecondDotInner = "#FFFFFF"; // центр вершин 2
    viewGeomEditVor.style.colorInsertDotOuter = '#000000' // обводка средней точки
    viewGeomEditVor.style.widthLine = 4;
    cont.measures.push(viewGeomEditVor);
    let editVor = new EditDrawTool(cont, state, "EditAhoDrawTool-editVor");
    editVor.minPoints = 2;
    cont.measures.push(editVor);
    let midPointTool = new MidPointTool(cont, state, "MidAhoPointTool");
    cont.measures.push(midPointTool);

    // store.map.superTools.pushToolAfter(new VoronovStopClickTool(store.root, "VoronovAhoStopClick"),["AgroAhoTool"]);


    // setTimeout(()=>{
    //     console.log('layers', store.map.mapbox.getStyle().layers);
    // }, 1000);

}