import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2ColumnItemStore} from "../../../../../store/agro/A2ColumnItemStore";
import {action} from "mobx";
import {
    A2FieldFormCustomStore,
    A2FieldFormNotValidType
} from "../../../../../store/agro/fieldForm/A2FieldFormCustomStore";
import {A2FieldStore} from "../../../../../store/agro/A2FieldStore";

export interface IA2FieldEditorProps extends IStoreProps{
    columnInfo: A2ColumnItemStore;
    hasDiffValue?: boolean;
    isMultiEdit?: boolean;
    isReadonly?: boolean;
    field: A2FieldFormCustomStore;
    validType?: A2FieldFormNotValidType;
    data?: any;
    onChange?: (data: any)=>void;
}

export class A2FieldEditorCustomInputComp extends React.Component<IA2FieldEditorProps, undefined> {
    constructor(props: IA2FieldEditorProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickClear(){
        this.props.field.properties[this.props.columnInfo.column_name] = null;
        if (this.props.field.referencePropertiesText[this.props.columnInfo.column_name])
            this.props.field.referencePropertiesText[this.props.columnInfo.column_name] = {text: "", isNewValue: false};
        this.props.field.changedColumns.add(this.props.columnInfo.column_name);
    }

    @action
    onClickReturn(){
        this.props.field.resetInitialValueProperties(this.props.columnInfo.column_name);
        this.props.field.changedColumns.delete(this.props.columnInfo.column_name);
        this.setTextByRef();
    }

    getPreference(): {hasReturnButton: boolean, hasClearButton: boolean, isDiffValuesLabel: boolean, isClearValue: boolean, isChanged: boolean}{
        let hasReturnButton = this.props.isMultiEdit && this.props.field.changedColumns.has(this.props.columnInfo.column_name);
        let v = this.props.field.properties[this.props.columnInfo.column_name];
        let hasClearButton = (this.props.isMultiEdit && v != null);
        if (hasReturnButton && hasClearButton) hasClearButton = false;
        let isDiffValuesLabel = (v == null && this.props.isMultiEdit  && this.props.field.hasDiffValue.has(this.props.columnInfo.column_name));
        let isClearValue = (this.props.isMultiEdit && this.props.field.changedColumns.has(this.props.columnInfo.column_name) && v == null);
        let isChanged = this.props.field.changedColumns.has(this.props.columnInfo.column_name);
        return {hasReturnButton, hasClearButton, isDiffValuesLabel, isClearValue, isChanged};
    }
    setTextByRef(){}
}
