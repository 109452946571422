import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {SearchSceneItemComp} from "./SearchSceneItemComp";
import {isString} from "lodash-es";
import VizSensor from 'react-visibility-sensor';
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {TelescopeSVG} from "../../../icons/TelescopeSVG";
import {ListSceneStore} from "../../../../store/ListSceneStore";
import autoBindReact from "auto-bind/react";
import {SuperStore} from "../../../../store/SuperStore";
import {SearchSceneItemS5Comp} from "./SearchSceneItemS5Comp";
import {SatEnum2} from "../../../../helper/utils/satellliteDic";
import {action} from "mobx";
import {ScrollSaveComp} from "../../../Common/ScrollSaveComp";
import {Utils} from "../../../../helper/utils/Utils";

export interface ISearchSceneListComp extends IStoreProps{
    list: ListSceneStore
}

@observer
export class SearchSceneListComp extends React.PureComponent<ISearchSceneListComp, {showItemsCount:any}> {
    private observer: IntersectionObserver | null = null; 
    private loadMoreTriggerRef = React.createRef<HTMLDivElement>();
    private itemsPerPage = 50;
    constructor(props: ISearchSceneListComp) {
        super(props);
        autoBindReact(this);
        this.state = {
            showItemsCount: this.itemsPerPage
        };
    }

    static scrollToCurrent(store: SuperStore, smooth: boolean = false){
        let curSceneId = store.searchPanel.currentSceneid;
        if (isString(curSceneId) && curSceneId != ""){
            let elem = document.getElementById(curSceneId);
            if (elem != null) {
                //(elem as any).parentNode.scrollTop = elem.offsetTop;
                Utils.scrollIntoView2(elem);
                //elem.scrollIntoView({behavior: smooth ? "smooth" : "auto"});//!
            }
            else  {
                curSceneId.length > 24 ?
                curSceneId = curSceneId.slice(0, -13):curSceneId = curSceneId.slice(0, -9);
                let matchedElem = document.querySelector('[id^="' + curSceneId + '"]');
                if (matchedElem !== null) {
                    Utils.scrollIntoView2(matchedElem);
                }
            }
        }
    }
    @action
    onScroll(pos: number){
        this.props.store.searchPanel.scrollPos = pos;
    }
    observeElement() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadMoreItems();
                  console.log("===", entry)
                }
            });
        });

        if (this.loadMoreTriggerRef.current) {
            observer.observe(this.loadMoreTriggerRef.current); 
            this.observer = observer;
        }
    }
    loadMoreItems() {
        this.setState((prevState) => ({
            showItemsCount: prevState.showItemsCount + this.itemsPerPage
        }));
    }
    componentDidMount() {
      
        this.observeElement(); 
    }
    componentWillUnmount() {
        if (this.observer && this.loadMoreTriggerRef.current) {
            this.observer.unobserve(this.loadMoreTriggerRef.current);
        }
    }
    render() {
      
        let store = this.props.store;
        let sr = this.props.list;
        let arr:any[] = null;
        let showSearch = store.searchPanel.showSearchList();
        let loading = sr.searchState == LoadStatus.loading;
       
        function Plug(props: {id: any}){
            return <div id={props.id}
                        className="left-sidebar__right-bottom__row4-in"/>;
        }
        if (showSearch) {
                arr = [];
                sr.groups.forEach(rec => {
                    arr.push(<VizSensor key={rec.groupId()} partialVisibility={true}>{
                        (args: { isVisible: boolean }) => {
                            if (!args.isVisible) return <Plug id={rec.groupId()} key={rec.groupId()} />;
                            let f = rec.first();
                            if (f != null && f.feature.properties.scene_type == SatEnum2.S5L2A) {
                                return <SearchSceneItemS5Comp searchGroup={rec} store={store}
                                                              key={rec.groupId()}/>;
                            }
                            return <SearchSceneItemComp searchGroup={rec} store={store}
                                                        key={rec.groupId()}/>;
                        }
                    }</VizSensor>);
                });
                if (arr.length == 0){
                    arr.push(<div className="zoom-for-scene" key="no"><p>{store.trans["Nothing found, change search parameters"]}</p></div>);
                }
        }
        let showItems = arr?.slice(0, this.state.showItemsCount);
     
        return <div className="left-sidebar__right-bottom__row4 flex-fit-item">
            {showSearch && loading /*&& <div className="loading-search-background">
                <div className="loading-search">
                    <div className="spinner"/>
                </div>
            </div>*/}
            <ScrollSaveComp className="left-sidebar__right-bottom__row4-list style-4" scrollTop={store.searchPanel.scrollPos} onScroll={this.onScroll}>
            {!showSearch && <div className="zoom-for-scene">
                <div>
                    <TelescopeSVG />
                </div>
                <p>{store.trans["Zoom in to search images"]}</p>
            </div>}
            {arr}
            {/* {showItems}
             <div ref={this.loadMoreTriggerRef} style={{height:"20px"}}/>  */}
            </ScrollSaveComp>
        </div>;
    }

}
