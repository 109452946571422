import {ContainerTools, CustomTool} from "../general/ContainerTools";
import mapboxgl, {
    AnyLayer,
    CirclePaint,
    FillPaint,
    Layer,
    LinePaint,
    SymbolLayer,
    SymbolLayout,
    SymbolPaint
} from "maplibre-gl";
import {SuperTools} from "../general/SuperTools";
import {MapComp} from "../../../components/panels/Map/MapComp";
import {autorun, observable, toJS} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {ra} from "../../../helper/utils/mobxUtils";
import {FieldToolController} from "../../agro/fieldForm/FieldToolController";
import {IFieldLayerStyles, ProjType} from "../../user/UserStore";
import {cloneDeep, isArray} from "lodash-es";
import {IAgroMapStyles} from "../../PluginApi/PluginApiStore";
import {A2NavigatorSection} from "../../agro/A2AgroStore";

export enum LayerClass {
    fields = "fields",
    labels = "labels",
    selected = "selected",
    hover = "hover"
}

export class FieldLayersTool extends CustomTool{
    constructor(container: ContainerTools, fieldToolController: FieldToolController, name: string) {
        super(container, name);
        this.fieldToolController = fieldToolController;
    }
    fieldToolController: FieldToolController;

    private static FIELDS_SOURCE_ID = 'class_FIELDS_SOURCE_ID';

    private static FIELD_LAYER_POSTFIX = '_FIELD';
    private static SELECTED_LAYER_POSTFIX = '_SEL';
    private static HOVER_LAYER_POSTFIX = '_HOVER';
    private static LABELS_LAYER_POSTFIX = '_LABELS';

    tools: SuperTools;
    @observable
    internalInstalled: boolean = false;
    private layersIds: string[] = [];

    getLayerId(layerClass: LayerClass, index: number): string{
        if (layerClass == LayerClass.fields) return FieldLayersTool.FIELDS_SOURCE_ID+FieldLayersTool.FIELD_LAYER_POSTFIX+ index.toString();
        if (layerClass == LayerClass.labels) return FieldLayersTool.FIELDS_SOURCE_ID+FieldLayersTool.LABELS_LAYER_POSTFIX+ index.toString();
        if (layerClass == LayerClass.selected) return FieldLayersTool.FIELDS_SOURCE_ID+FieldLayersTool.SELECTED_LAYER_POSTFIX+ index.toString();
        if (layerClass == LayerClass.hover) return FieldLayersTool.FIELDS_SOURCE_ID+FieldLayersTool.HOVER_LAYER_POSTFIX + index.toString();
        throw "Unkonw label class";
    }
    layersVisible(): boolean{
        return this.tools.store.agro2.agroFieldVisible && this.tools.store.agro2.navigatorSection == A2NavigatorSection.folder &&
            (this.tools.store.agro2.projectInfo.projectType == ProjType.agro_base ||
                this.tools.store.agro2.projectInfo.projectType == ProjType.parcel_assess ||
                this.tools.store.agro2.projectInfo.projectType == ProjType.slick ||
                this.tools.store.agro2.projectInfo.projectType == ProjType.yield_point ||
                this.tools.store.agro2.projectInfo.projectType == ProjType.agro_insur ||
                this.tools.store.agro2.projectInfo.projectType == ProjType.pnt_yld_assess);
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(
                () => {
                    if (!this.tools.store.map.mapReady) return;
                    if (this.tools.store.agro.projectName == null || this.tools.store.agro2.projectStruct.activeNavigatorPage == null) {
                        if (this.internalInstalled) this.doUnInstall();
                        return;
                    }
                    //получаем фильтры чтобы подписаться на изменения
                    if (this.getFieldTileUrl() == null) return;

                    this.fieldToolController.getLayerFieldsFilter(0, 0);
                    ra(() => {
                        this.doUnInstall();
                    });
                    if (this.layersVisible()) {
                        ra(() => {
                            this.doInstall();
                        });
                    }
                }),
            autorun(() => {
                if (this.internalInstalled) {
                    this.setFilters();
                }
            })
        ];
    }

    reInstallLayers(){
        if (this.map == null) return;
        this.removeLayers();
        if (this.internalInstalled == true) {
            this.addLayer2(FieldLayersTool.FIELDS_SOURCE_ID, this.tools.store.agro2.projectInfo.getFieldVectorLayerId());
        }
    }

    doUnInstall(){
        this.removeLayers();
        if (this.map.getSource(FieldLayersTool.FIELDS_SOURCE_ID) != null)
            this.map.removeSource(FieldLayersTool.FIELDS_SOURCE_ID);
        this.internalInstalled = false;
    }

    doInstall(){
        if (this.map.getSource(FieldLayersTool.FIELDS_SOURCE_ID) == null){
            let tileUrl = this.getFieldTileUrl();
            this.map.addSource(FieldLayersTool.FIELDS_SOURCE_ID, {
                type: 'vector',
                tiles: [tileUrl],
                scheme: "xyz",
                maxzoom: MapComp.MAX_ZOOM
            });
        }
        this.addLayer2(FieldLayersTool.FIELDS_SOURCE_ID, this.tools.store.agro2.projectInfo.getFieldVectorLayerId());

        //this.addLayer( FieldLayersTool.FIELDS_SOURCE_ID, this.tools.store.agro2.projectInfo.getFieldVectorLayerId(), beforeLayer);
        this.internalInstalled = true;
    }

    private getFieldTileUrl(): string{
        let filter: any = {};
        let store = this.tools.store;
        if (this.tools.store.agro2.projectInfo?.projectInfoJson?.proj_type == null) return null;
        if (this.tools.store.agro2.projectInfo?.projectInfoJson?.proj_type == ProjType.agro_base ||
            this.tools.store.agro2.projectInfo?.projectInfoJson?.proj_type == ProjType.agro_insur) {
            let paths = this.tools.store.agro2.projectStruct.activeNavigatorPage.getNaviPath();
            let season = paths.find(a => a.columnInfo != null && a.columnInfo.column_name == store.agro2.projectInfo.fieldName_season);
            if (season == null) return null;
            if (season.child_values.length == 1){
                filter[store.agro2.projectInfo.fieldName_season] = season.child_values[0].key;
            }else if (season.column_value != null){
                filter[store.agro2.projectInfo.fieldName_season] = season.column_value;
            }else return null;
        }
        return `${window.location.origin}/api/projects/${store.agro.projectName}/object/tile?z={z}&x={x}&y={y}&extent=1024&version=${store.agro2.tileFieldsContentVersion}&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    public removeLayers(){
        if (this.internalInstalled) {
            let removeLayer = (layerId: string) => {
                    this.removeLayer(layerId);
            }
            this.layersIds.forEach((id, index)=>{
                removeLayer(id);
            });
        }
    }

    private addFilterLayer(layer: mapboxgl.Layer, option: {isHover?: boolean, isSelection?: boolean}){
        let filter = layer.filter;
        if (filter == null) filter = ["all"];
        if (!(isArray(filter) && filter[0] == "all")){
            filter = ["all", filter];
        }
        filter.push(this.fieldToolController.getFilter(option?.isSelection, option?.isHover));
        layer.filter = toJS(filter);
    }


    getLayerStyles(): IFieldLayerStyles {
        let r: IFieldLayerStyles = {
            fields: [
                {
                    id: null,
                    type: 'fill',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                    ],
                    layout: {
                    },
                    paint: <FillPaint>{
                        //'fill-color': '#4DB6BC',
                        'fill-opacity': 0,
                    }
                },
                {
                    id: null,
                    type: 'line',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]],
  //                      ["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                    ],
                    paint: <LinePaint>{
                        'line-color':
                            '#DE2996',
                        'line-width': 4,
                    }
                },
                {
                    id: null,
                    type: 'line',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]],
                        //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                    ],
                    paint: <LinePaint>{
                        'line-color': '#DE2996',
                        'line-width': 4,
                    }
                }, {
                    id: null,
                    type: 'circle',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]],
                        //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                    ],
                    layout: {
                    },
                    paint: <CirclePaint>{
                        'circle-color': '#DE2996',
                        'circle-radius': 4,
                    }
                }
            ],
            selected: [
                {
                    id: null,
                    type: 'fill',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                    ],
                    layout: {
                    },
                    paint: <FillPaint>{
                        'fill-opacity': 0,
                    }
                },
                {
                    id: null,
                    type: 'line',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                    ],
                    paint: <LinePaint>{
                        'line-color': '#4DB6BC',
                        'line-width': 4,
                    }
                },
                {
                    id: null,
                    type: 'circle',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                    ],
                    paint: <CirclePaint>{
                        'circle-color': '#4DB6BC',
                        'circle-radius': 4,
                    }
                },
            ],
            hover: [
                {
                    id: null,
                    type: 'fill',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                    ],
                    layout: {
                    },
                    paint: <FillPaint>{
                        'fill-color': '#4DB6BC',
                        'fill-opacity': 0.5,
                    }
                },
                {
                    id: null,
                    type: 'line',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]]
                    ],
                    paint: <LinePaint>{
                        'line-color': '#0080ff',
                        'line-width': 0,
                    }
                },

                {
                    id: null,
                    type: 'circle',
                    filter: ["all",
                        ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                    ],
                    paint: <CirclePaint>{
                        'circle-color': '#0080ff',
                        'circle-radius': 4,
                    }
                }
            ]
        }
        ;
        let options: IAgroMapStyles = {styles: r};
        this.fieldToolController.root.pluginsApi.onGetAgroMapStyles(options);
        r = options.styles;

        let fieldLayers = this.tools.store.agro2.styles.layers;
        if (fieldLayers != null && isArray(fieldLayers) && fieldLayers.length > 0) {
            r.fields = fieldLayers;
        }

        //if (this.fieldToolController.root.agro2.projectInfo.projectInfoJson.proj_settings.map_styles != null)
        //    r = this.fieldToolController.root.agro2.projectInfo.projectInfoJson.proj_settings.map_styles;
        (r.fields || []).forEach((st, index)=>{
            st.id = this.getLayerId(LayerClass.fields, index);
        });
        (r.selected || []).forEach((st, index)=>{
            st.id = this.getLayerId(LayerClass.selected, index);
        });
        (r.hover || []).forEach((st, index)=>{
            st.id = this.getLayerId(LayerClass.hover, index);
        });
        return cloneDeep(r);
    }

    public addLayer2(src_id: string, srcLayer: string){
        let styles = this.getLayerStyles();

        this.layersIds = [];
        let createLayer = (st: Layer) => {
            st.source = src_id;
            st["source-layer"] = srcLayer;
            this.addLayer(st as AnyLayer);
            this.layersIds.push(st.id);
        };

        (styles.fields || []).forEach((st, index) => {
            if (st.layout == null) st.layout = {};
            if (st.paint == null) st.paint = {};
            this.addFilterLayer(st, {isHover: false, isSelection: false});
            createLayer(st);
        });
        (styles.selected || []).forEach((st, index) =>{
            this.addFilterLayer(st, {isHover: false, isSelection: true});
            createLayer(st);
        });
        (styles.hover || []).forEach((st, index) =>{
            this.addFilterLayer(st, {isHover: true, isSelection: false});
            createLayer(st);
        });
        /*
        let name_column = this.tools.store.agro2.projectInfo.fieldName_columnName;
        if (name_column != null) {
            (styles.labels || []).forEach((st, index) => {
                this.addFilterLayer(st, {isHover: false, isSelection: false});
                if (st.layout == null) st.layout = {};
                let v: any = ['get', name_column];
                if ((st as SymbolLayer).layout['text-field'] == null) (st as SymbolLayer).layout['text-field'] = v;
                createLayer(st);
            });
        }*/
    }

    setFilters(){
        if (this.internalInstalled) {
            let styles = this.getLayerStyles();
            (styles.fields || []).forEach((st, index)=>{
                this.addFilterLayer(st, {isHover: false, isSelection: false});
                this.map.setFilter(st.id,toJS(st.filter));
            });
            (styles.selected || []).forEach((st, index)=>{
                this.addFilterLayer(st, {isHover: false, isSelection: true});
                this.map.setFilter(st.id, toJS(st.filter));
            });
            (styles.hover || []).forEach((st, index)=>{
                this.addFilterLayer(st, {isHover: true, isSelection: false});
                this.map.setFilter(st.id, toJS(st.filter));
            });
        }
    }
}