import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroExportPointsItemComp.scss'
import {action} from "mobx";
import {IAgroExportSuperStore} from "../agroExportPlugin";


export interface IAgroExportPointsItemCompProps {
    store: IAgroExportSuperStore;
    farm_name: string;
    fields: string[];
}

@observer
export class AgroExportPointsItemComp extends React.PureComponent<IAgroExportPointsItemCompProps, undefined> {
    constructor(props: IAgroExportPointsItemCompProps) {
        super(props);
        autoBindReact(this);
    }


    render() {
        let store = this.props.store;
        let estore = store.agroExport;
        let title = `${estore.trans2.fields}: ${this.props.fields.length}`;
        if (this.props.farm_name) title = `${this.props.farm_name}, ${title}`;
        let fields = this.props.fields.join(', ');

        return <div className="AgroExportPointsItemComp-main">
            <div className="AgroExportPointsItemComp-title">{title}</div>
            <div className="AgroExportPointsItemComp-fields">{fields}</div>
        </div>
    }
}
