import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AhoStmtExtItem} from "../../stmt/agroAhoStmtStore";
import {observer} from "mobx-react";
import autoBindReact from "auto-bind/react";
import React from "react";
import './AgroAhoStmtExtItem.scss'
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoCheckMarkSvg} from "../icons/AgroAhoCheckMarkSvg";
import classNames from "classnames";


export interface IAgroAhoStmtExtItemProps {
    store: IAgroAhoSuperStore;
    item: AhoStmtExtItem
}

@observer
export class AgroAhoStmtExtItem extends React.PureComponent<IAgroAhoStmtExtItemProps, undefined> {
    constructor(props: IAgroAhoStmtExtItemProps) {
        super(props);
        autoBindReact(this);
    }

    onExtCheckboxClick(){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (!st.isExtResearch) return;
        this.props.item.ext = !this.props.item.ext;
    }

    render() {
        let st = this.props.store.agroAhoStore.stmtStore;
        return <div className="AgroAhoStmtExtItem-main">
            <div className="AgroAhoStmtExtItem-field">{this.props.item.field_name}</div>
            <div className="AgroAhoStmtExtItem-cell">{this.props.item.cell_name}</div>
            <AgroAhoCheckMarkSvg className="AgroAhoStmtExtItem-mainCheckbox"/>
            <AgroAhoRectCheckboxSvg className={classNames("AgroAhoStmtExtItem-extCheckbox", {
                "AgroAhoStmtExtItem-disabled": !st.isExtResearch})} checked={this.props.item.ext}
                onClick={this.onExtCheckboxClick}/>
        </div>
    }
}
