import * as React from 'react';
import {CustomSvg} from "./CustomSvg";

export class LoadingAniSvg extends CustomSvg {

    render() {
        return <svg className={this.getClasses()} style={{margin: "auto", background: "none", display: "block", shapeRendering: "auto"}} width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="rotate(0 50 50)">
                <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8888888888888888s" repeatCount="indefinite"/>
                </rect>
            </g><g transform="rotate(40 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7777777777777778s" repeatCount="indefinite"/>
            </rect>
        </g><g transform="rotate(80 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"/>
            </rect>
        </g><g transform="rotate(120 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5555555555555556s" repeatCount="indefinite"/>
            </rect>
        </g><g transform="rotate(160 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4444444444444444s" repeatCount="indefinite"/>
            </rect>
        </g><g transform="rotate(200 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"/>
            </rect>
        </g><g transform="rotate(240 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2222222222222222s" repeatCount="indefinite"/>
            </rect>
        </g><g transform="rotate(280 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1111111111111111s" repeatCount="indefinite"/>
            </rect>
        </g><g transform="rotate(320 50 50)">
            <rect x="43.5" y="12.5" rx="6.5" ry="6.5" width="13" height="13" fill="#ffffff">
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"/>
            </rect>
        </g>
        </svg>;
    }
}
