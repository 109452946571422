import React from 'react'
import { CheckboxMiniComp, TriState, CheckboxSize } from '../../../Common/CheckboxMiniComp'
import { SuperStore } from '../../../../store/SuperStore';
import { observer } from 'mobx-react';

interface IProps {
	store: SuperStore; 
  }
const DragConfirmModalComp: React.FC<IProps> = observer(({store} )=> {

  let isSaveStyleActive = store.layerListStore.isStylesToChange
  let isVisibleByDefault = store.layerListStore.isVisibleByDefault
  const onSaveClick = () => {
    store.layerListStore.callHandleConfirmSaveFunction();
    store.layerListStore.showConfirmPopup = false
  };

  return (
	<div className='modal-back'>
                    <div className='modal-full modal-full-frame DraggbleOverlaysCompPopup-window'>
                    <div  className="DraggbleOverlaysCompPopup-window-title">Сохранить изменения в панели слоев</div>
                    <div style={{display:"flex", gap:"10px",marginBottom:"10px",cursor:'pointer'}}> 

                    <CheckboxMiniComp 
                    state={
                         TriState.check 
                    }
                    size={CheckboxSize.standart}
                    disabled={true}
                    classesContainer='pointer LayersListComp-overlays-group-overlay-checkbox'
                    ></CheckboxMiniComp>
                    <div style={{fontSize:"12px",opacity:"0.3"}}> Структура слоев </div>
                    </div>
                    <div onClick={() =>{store.layerListStore.isVisibleByDefault = !store.layerListStore.isVisibleByDefault} } style={{display:"flex", gap:"10px",marginBottom:"10px",cursor:"pointer"}}> 
                    <CheckboxMiniComp
                    state={
                      isVisibleByDefault ? TriState.check : TriState.uncheck
                    }
                    size={CheckboxSize.standart}
                    classesContainer='pointer LayersListComp-overlays-group-overlay-checkbox'
                    ></CheckboxMiniComp>
                    <div style={{fontSize:"12px"}}> Включенные слои будут видны по умолчанию </div>
                    </div>
                    <div onClick={() =>{store.layerListStore.isStylesToChange = !store.layerListStore.isStylesToChange} } style={{display:"flex", gap:"10px",marginBottom:"35px",cursor:"pointer"}}> 
                    <CheckboxMiniComp
                    state={
                        isSaveStyleActive ? TriState.check : TriState.uncheck
                    }
                    
                    size={CheckboxSize.standart}
                    classesContainer='pointer LayersListComp-overlays-group-overlay-checkbox'
                    ></CheckboxMiniComp>
                    <div style={{fontSize:"12px"}}> Стили слоев </div>
                    </div>
                    <div className="DraggbleOverlaysComp-separator"></div>
                    <div className="DraggbleOverlaysComp-edit-buttons">
                    <button className="DraggbleOverlaysComp-cancel-button button-jump" 
                    onClick={()=>{store.layerListStore.showConfirmPopup = false}}>{store.trans.Cancel}</button>
                    <button className="DraggbleOverlaysComp-save-button button-jump" 
                    onClick={onSaveClick}> {store.trans.Save}</button>
                    </div>
                    </div>
                </div>
  )
})

export default DragConfirmModalComp