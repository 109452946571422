import * as React from 'react';
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {Utils} from "../../../../helper/utils/Utils";
import {CenterSVG} from "../../../icons/CenterSVG";
import {StarEmptyMinusSvg} from "../../../icons/Fav/StarEmptyMinusSvg";
import {StarFullMinusSvg} from "../../../icons/Fav/StarFullMinusSvg";
import {SearchItemGroup} from "../../../../store/SearchItemGroup";
import {CheckStates} from "../../../../helper/structs/CheckStates";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {SearchSceneItemImgComp} from "../SearchPanel/SearchSceneItemImgComp";
import {SearchSceneMiniMap2Comp} from "../SearchPanel/SearchSceneMiniMap2Comp";
import {SceneMosaicSwitch, SceneUiType} from "../../../../store/SearchStore";
import "./FavoritePanel.scss";
import { SearchItemStore } from '../../../../store/SearchItemStore';
import ItemMenuSvg from '../../../icons/ItemMenuSvg';
import { ContextMenuCommonComp, PopupCorner, PopupDirection } from '../../../Common/ContextMenuCommonComp';

export interface IFavItemComp extends IStoreProps{
    searchGroup: SearchItemGroup
}

@observer
export class FavItemComp extends React.PureComponent<IFavItemComp, undefined> {
    constructor(props: IFavItemComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onMouseOverGroup() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = this.props.searchGroup.groupId(true);
    }

    @action
    onMouseEnterGroup() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = this.props.searchGroup.groupId(true);
    }

    @action
    onMouseEnterScene(idx: number) {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = this.props.searchGroup.groupId(true) + `>${idx}`;
    }

    @action
    onMouseLeave() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = null;
    }



    @action
    onImgLoad(){
        //let imgUrl = this.getImgUrl();
        //if (this.imgUrlForLoad == imgUrl){
            this.loaded = true;
            this.error = false;
            this.forceUpdate();
        //}
    }

    @action
    onError(){
        //let imgUrl = SearchSceneItemComp.getImgUrl();

        //if (this.imgUrlForLoad == imgUrl){
            this.loaded = true;
            this.error = true;
            this.forceUpdate();
        //}
        //store.addError(Utils.getErrorString(err)+" "+imgUrl);
    }

    onClickImage(){
        let store = this.props.store;
        let sg = this.props.searchGroup;
        let it = sg.first().feature.properties;
        Utils.copyToClipboard(it.scene_id);

    }

    preview_error_url = "./img/preview_not_available.svg"

    imgUrlForLoad: string;
    loaded: boolean = false;
    error: boolean = false;
    @observable animateDeleteGroup = false;
    @observable animateDeleteSceneIndex: number = null;
    @observable showMenuItem: boolean = false;

    @action
    onClickSelect(){
        let sg = this.props.searchGroup;
        if (sg.getSelected() != CheckStates.checked){
            sg.setSelected(true);
        }else sg.setSelected(false);
    }
    @action
    onClickFavoriteGroup(event: any){
        this.animateDeleteGroup = true;
        event.stopPropagation();
    }
    @action
    onClickFavoriteScene(idx: number, event: any){
        this.animateDeleteSceneIndex = idx;
        event.stopPropagation();
    }
    @action
    CopyName(event: any){
        let si = this.props.searchGroup;
        let store = this.props.store;
        Utils.copyToClipboard(si.groupId());
        store.addInfo(store.trans.Copied);
        event.stopPropagation();
    }
    centerToGroup(event: any){
        let si = this.props.searchGroup;
        let bbox = si.getBbox();
        this.props.store.map.zoomFlyToBBox(bbox);
        event.stopPropagation();
    }

    centerToScene(si: SearchItemStore, event: any) {
        this.props.store.map.zoomFlyToBBox(si.bbox);
        event.stopPropagation();
    }
    
    @action
    onDeleteGroupAnimationEnd(){
        let store = this.props.store;
        let sg = this.props.searchGroup;
        Utils.arrayRemoveByValue(store.searchPanel.favoriteList.groups, sg);
        store.searchPanel.favoriteList.totalRecords = store.searchPanel.favoriteList.groups.length;
    }

    @action
    onDeleteSceneAnimationEnd(idx: number){
        let store = this.props.store;
        let sg = this.props.searchGroup;
        let si = this.props.searchGroup.items[idx];
        // Utils.arrayRemoveByIndex(this.props.searchGroup.items, idx);
        // if (this.props.searchGroup.items.length == 0)
        //     Utils.arrayRemoveByValue(store.searchPanel.favoriteList.groups, sg);
        store.searchPanel.favoriteList.removeSceneItem(si);
        this.animateDeleteSceneIndex = null;
        store.searchPanel.favoriteList.totalRecords = store.searchPanel.favoriteList.groups.length;
    }

    @action
    onClickProduct(groupId: string, event: any) {
        this.props.store.searchPanel.switchNdviPanel(SceneUiType.favorite, groupId);
        event.stopPropagation();
    }

    @action
    onClickGroupScenes(sg: string, event: any) {
        if (this.props.store.searchPanel.favoriteGroupScenes == sg)
            this.props.store.searchPanel.favoriteGroupScenes = null;
        else
            this.props.store.searchPanel.favoriteGroupScenes = sg;
        event.stopPropagation();
    }

    @action
    onClickItemMenu(event: any) {
        this.showMenuItem = true;
        event.stopPropagation();
    }

    @action
    onCloseItemMenu() {
        this.showMenuItem = false;
    }

    @action
    onClickDuplicateProduct(g: SearchItemGroup, event: any) {
        this.showMenuItem = false;
        this.props.store.searchPanel.favoriteList.duplicateGroup(g);
        event.stopPropagation();
    }

    @action
    onClickCopyProductSettings(g: SearchItemGroup, event: any) {
        this.showMenuItem = false;
        this.props.store.searchPanel.favoriteList.copyProductSettings(g);
        event.stopPropagation();
    }

    @action
    onClickApplyProductSettings(g: SearchItemGroup, event: any) {
        this.showMenuItem = false;
        this.props.store.searchPanel.favoriteList.applyProductSettings(g);
        event.stopPropagation();
    }

    render() {
        let store = this.props.store;
        let sg = this.props.searchGroup;
        let groupId = sg.groupId(true);
        let isActiveGroup = store.map.productInfo.activeProductsSet.favoriteProductGroupId == groupId;
        let it = sg.first().feature.properties;

        let multiScene = (sg.items.length > 1);
        //let isCurrent = store.searchPanel.currentSceneid == it.sceneid;
        sg.wasVisible = true;

        let is_selected = sg.getSelected();

        let showError = (this.loaded && this.error);
        let leafleatImg = (store.map.searchObject.isNotEmpty)
            || true; //store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.mosaic;

        let clouds: number[] = sg.items.map(i => Number.parseFloat(i.feature.properties.cloud));
        let minCloud = Math.min(...clouds);
        let maxCloud = Math.max(...clouds);
        let cloudsInterval = ((minCloud == maxCloud)? minCloud : minCloud + "-" + maxCloud);

        return <React.Fragment><div className={classNames("left-sidebar__favitem__row4-in",{"animate__animated animate__bounceOutLeft": this.animateDeleteGroup})}
                    onMouseEnter={this.onMouseEnterGroup} onMouseLeave={this.onMouseLeave} onAnimationEnd={this.onDeleteGroupAnimationEnd}
                    onClick={this.onClickSelect}
                    id={it.scene_id}>
            <div className={classNames("left-sidebar__favitem__row4-in-active", {"hide": is_selected != CheckStates.checked})}></div>
            <div className={classNames("left-sidebar__favitem__row4-in-left relative pointer", {"blue-active": is_selected == CheckStates.checked})} >
                {/*
                {!this.loaded && <LoadingBars classes="loading-img" />}
                {showError && <img src={this.preview_error_url} className="preview_error img-radius"
                                   alt="Quicklook is not found" title={store.trans["Quicklook is not found"]} />
                }
                {!showError && <SearchSceneItemImgComp searchGroup={si} store={store} className="left-sidebar__favitem__row4-in-left-in img-radius"
                                                       onLoad={this.onImgLoad} onError={this.onError}/>
                }*/}
                {!leafleatImg && <SearchSceneItemImgComp searchGroup={this.props.searchGroup} store={store}
                                                         className={classNames("left-sidebar__favitem__row4-in-left-in img-radius")}
                />}

                {leafleatImg && <SearchSceneMiniMap2Comp store={store} searchGroup={this.props.searchGroup} sceneType={SceneUiType.favorite}
                                         className="left-sidebar__favitem__row4-in-left-in img-radius"
                />}

                {multiScene && <div className='left-sidebar__favitem__row4-in-left-in-multi-icon'>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.08333 5.33301H0.583333C0.233333 5.33301 0 5.56634 0 5.91634V9.41634C0 9.76634 0.233333 9.99967 0.583333 9.99967H4.08333C4.43333 9.99967 4.66667 9.76634 4.66667 9.41634V5.91634C4.66667 5.56634 4.43333 5.33301 4.08333 5.33301Z" fill="#C5C5C5"></path>
                    <path d="M6.73325 2.66699H1.33325V4.00033H5.99992V8.66699H7.33325V3.26699C7.33325 2.90699 7.09325 2.66699 6.73325 2.66699Z" fill="#C5C5C5"></path>
                    <path d="M9.375 0H4V1.33333H8.66667V6H10V0.625C10 0.25 9.75 0 9.375 0Z" fill="#C5C5C5"></path>
                    </svg>
                </div>}
            </div>
            <div className="left-sidebar__favitem__row4-in-right pointer">
                <div className="left-sidebar__favitem__row4-in-right-el FavItemComp-topline">
                    <div className="FavItemComp-topline-dates">
                        <div className="FavItemComp calendar_table dark-blue" onClick={this.onClickImage}/>
                        {it.acqdate}{!multiScene && <span>{it.acqtime} UTC</span>}
                    </div>
                    <div className={classNames('FavItemComp-topline-buttons', {'active': this.showMenuItem})}>
                        <div className="pointer FavItemComp-topline right_row_icon_iconbox left-sidebar__favitem__hidden_right" onClick={this.onClickFavoriteGroup} >
                            <div style={{display:"block", position:"relative"}}>
                            <StarFullMinusSvg className="left-sidebar__favitem__minus_full left-sidebar__favitem__minus"/>
                            <StarEmptyMinusSvg className="left-sidebar__favitem__minus_empty left-sidebar__favitem__minus" />
                            </div>
                        </div>
                        <div className="pointer FavItemComp-topline right_row_icon_iconbox left-sidebar__favitem__hidden_right" onClick={this.onClickItemMenu}>                            
                            <ItemMenuSvg className={classNames("settings-pref", {"active": this.showMenuItem})}/>
                            {this.showMenuItem && <ContextMenuCommonComp onClose={this.onCloseItemMenu}
                                                    autoCornerHeight={100} autoCornerWidth={100}
                                                    direction={PopupDirection.vertical}
                                                    popupCorner={PopupCorner.rightTop}
                            >
                                <div className="FavItemComp-popup-props">
                                    <div className={classNames("flex-columns flex-align-items-center A2NavigatorComp-popup-props-line")}
                                        key="copy_product_settings" onClick={e => this.onClickCopyProductSettings(sg, e)}>
                                        <div className="flex-stretch-item text-next">
                                            {store.trans["Copy product settings"]}
                                        </div>
                                    </div>
                                    <div className={classNames("flex-columns flex-align-items-center A2NavigatorComp-popup-props-line", {"disabled": this.props.store.searchPanel.favoriteList.savedProductSettings == null})}
                                        key="paste_product_settings" onClick={e => this.onClickApplyProductSettings(sg, e)}>
                                        <div className="flex-stretch-item text-next">
                                            {store.trans["Paste product settings"]}
                                        </div>
                                    </div>
                                    <div className={classNames("flex-columns flex-align-items-center A2NavigatorComp-popup-props-line")}
                                        key="duplicate_product" onClick={e => this.onClickDuplicateProduct(sg, e)}>
                                        <div className="flex-stretch-item text-next">
                                            {store.trans[multiScene? "Create a group duplicate": "Create a scene duplicate"]}
                                        </div>
                                    </div>
                                    <div className={classNames("flex-columns flex-align-items-center A2NavigatorComp-popup-props-line", {"disabled": true})}
                                        key="create_mask">
                                        <div className="flex-stretch-item text-next">
                                            {store.trans["Create a mask from the Area of ​Interest"]}
                                        </div>
                                    </div>
                                </div>
                            </ContextMenuCommonComp>}
                        </div>
                        <div className="pointer FavItemComp-topline right_row_icon_iconbox left-sidebar__favitem__hidden_right" onClick={this.centerToGroup}>
                            <CenterSVG className="settings-pref"/>
                        </div>
                    </div>
                </div>
                <div className="left-sidebar__favitem__row4-in-right-el">
                    <div className="FavItemComp satellite_table svg_table dark-blue"/>
                    <span className="left-sidebar__favitem__row4-in-right-span">{it.satellite}</span>
                </div>
                <div className="FavItemComp-bottomline left-sidebar__favitem__row4-in-right-el">
                    {!multiScene && <div className='flex-columns'>
                        <div className="FavItemComp maximize-area_table dark-blue"/>
                        <span className="left-sidebar__favitem__row4-in-right-span">{it.gridcode} | {cloudsInterval}%</span>
                    </div>}
                    {multiScene && <div className='FavItemComp-product-group-scenes'>
                        <span className={classNames("FavItemComp left-sidebar__favitem__row4-in-right-span", 
                            {"active": store.searchPanel.favoriteGroupScenes == groupId})} onClick={(e) => this.onClickGroupScenes(groupId, e)}>
                        {TranslateUtils.format(store.trans["Scenes: {0}"], sg.items.length)}, {cloudsInterval}%
                        </span>
                        <div className='FavItemComp-product-arrow-down'>
                            {store.searchPanel.favoriteGroupScenes == groupId && <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.90992 0.787001C7.96246 0.712042 7.99342 0.624097 7.99941 0.532752C8.00541 0.441408 7.98622 0.350171 7.94392 0.268987C7.90163 0.187802 7.83786 0.119787 7.75956 0.0723551C7.68127 0.0249233 7.59146 -0.000104519 7.49992 3.06077e-07L0.499916 0C0.408441 0.000175615 0.318768 0.0254419 0.240657 0.0730486C0.162547 0.120655 0.0989938 0.188778 0.0569157 0.27C0.0194616 0.340878 -0.00010683 0.419835 -8.48989e-05 0.5C-0.00025275 0.602636 0.0311699 0.702838 0.0899157 0.787L3.58992 5.787C3.63599 5.85301 3.69733 5.90693 3.76871 5.94415C3.84009 5.98138 3.91941 6.00082 3.99992 6.00082C4.08042 6.00082 4.15974 5.98138 4.23112 5.94415C4.3025 5.90693 4.36384 5.85301 4.40991 5.787L7.90992 0.787001Z" fill="#4DB6BC"></path>
                            </svg>}
                        </div>
                    </div>}
                    <div className="flex-columns">
                        <div className={classNames("FavItemComp-product", {"active": isActiveGroup})} onClick={(e) => this.onClickProduct(groupId, e)}>
                            {(isActiveGroup? store.map.productInfo.activeProductsSet.activeCodeFavorite : 
                            store.map.productInfo.activeProductsSet.getFavoriteGroupProduct(groupId)).currentProductStore.productConfig.abbreviation}
                        </div>
                        {isActiveGroup && <div className='FavItemComp-product-arrow'>
                            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.787001 0.0900848C0.712042 0.0375403 0.624097 0.00658214 0.532752 0.000585758C0.441408 -0.00541063 0.350171 0.0137848 0.268987 0.0560791C0.187802 0.0983734 0.119787 0.162143 0.0723551 0.240437C0.0249233 0.318731 -0.000104501 0.408544 3.27958e-07 0.500085V7.50008C0.000175947 7.59156 0.0254422 7.68123 0.0730489 7.75934C0.120656 7.83745 0.188778 7.90101 0.27 7.94308C0.340878 7.98054 0.419835 8.00011 0.5 8.00008C0.602637 8.00025 0.702839 7.96883 0.787001 7.91008L5.787 4.41008C5.85301 4.36401 5.90693 4.30267 5.94415 4.23129C5.98138 4.1599 6.00082 4.08059 6.00082 4.00008C6.00082 3.91958 5.98138 3.84026 5.94415 3.76888C5.90693 3.6975 5.85301 3.63616 5.787 3.59008L0.787001 0.0900848Z" fill="#4DB6BC"/>
                            </svg>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        {multiScene && store.searchPanel.favoriteGroupScenes == groupId && <div>
            {sg.items.map((itm, idx) => <div className={classNames('FavItemComp-single-scene', 
                    {"animate__animated animate__bounceOutLeft": this.animateDeleteGroup || idx == this.animateDeleteSceneIndex})}
                    onAnimationEnd={() => this.onDeleteSceneAnimationEnd(idx)}
                    onMouseEnter={() => this.onMouseEnterScene(idx)} onMouseLeave={this.onMouseLeave} >
                <SearchSceneMiniMap2Comp store={store} searchGroup={this.props.searchGroup} groupItemIndex={idx} sceneType={SceneUiType.favorite}
                                         className="FavItemComp-single-scene-img" />
                <div className="FavItemComp-single-scene-data-div">
                    <div className="FavItemComp-single-scene-data">
                        <div className="FavItemComp-single-scene-data-row">
                            <div className="scene_grid_mini"/>
                            {itm.feature.properties.gridcode}
                        </div>
                        <div className="FavItemComp-single-scene-data-row">
                        <div className="cloud-sun_table_mini"/>
                            {itm.feature.properties.cloud}
                        </div>
                    </div>
                    <div className='FavItemComp-topline-buttons'>
                        <div className="pointer FavItemComp-topline right_row_icon_iconbox left-sidebar__favitem__hidden_right" onClick={(e) => this.onClickFavoriteScene(idx, e)} >
                            <div style={{display:"block", position:"relative"}}>
                            <StarFullMinusSvg className="left-sidebar__favitem__minus_full left-sidebar__favitem__minus"/>
                            <StarEmptyMinusSvg className="left-sidebar__favitem__minus_empty left-sidebar__favitem__minus" />
                            </div>
                        </div>
                        <div className="pointer FavItemComp-topline right_row_icon_iconbox left-sidebar__favitem__hidden_right" onClick={(e) => this.centerToScene(itm, e)}>
                            <CenterSVG className="settings-pref"/>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>}
        </React.Fragment>;
    }
}
