import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import './ButtonLight.scss';
import {isFunction} from "lodash-es";

export interface IButtonLightProps {
    onClick?: ()=>void,
    className?: string,
    disabled?: boolean,
}

export class ButtonLight extends React.PureComponent<IButtonLightProps, undefined> {
    constructor(props: IButtonLightProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(){
        if (this.props.disabled === true) return;
        if (isFunction(this.props.onClick)) this.props.onClick();
    }

    render() {
        return <div className={classNames("ButtonLight", this.props.className, {"ButtonLight-disabled": this.props.disabled})} onClick={this.onClick}>{this.props.children}</div>;
    }
}
