import {CustomStore} from "../CustomStore";
import {action, observable} from "mobx";
import {PhotoTagGroupFilterStore} from "./PhotoTagGroupFilterStore";
import {fetchJsonPost} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";

export class PhotoTagEditorStore extends CustomStore{
    @observable
    private _photoTagEditorWindowShow: boolean = false;
    get photoTagEditorWindowShow(): boolean{
        return this._photoTagEditorWindowShow;
    }

    tagGroups: PhotoTagGroupFilterStore = new PhotoTagGroupFilterStore(this);

    resync(){
        this.tagGroups.loadPhotoTagFromJson(this.root.photo.tags);
    }
    @action
    showTagEditor(){
        this._photoTagEditorWindowShow = true;
    }
    @action
    closeTagEditor(){
        this._photoTagEditorWindowShow = false;
    }

    @action
    async sendDeleteTagGroup(tagGroup_id: number){
        this.root.photo.tags = this.root.photo.tags.filter(a => a.group_id != tagGroup_id);
        await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/tag_group/del`,{group_id:tagGroup_id});
    }
    @action
    async sendDeleteTag(tag_id: number){
        this.root.photo.tags.forEach(g => g.group_tags = g.group_tags.filter(t => t.tag_id != tag_id));
        await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/tag_group/del`,{group_id:tag_id});
    }
    @action
    async sendAddTagGroup(tag_group_name: string): Promise<number>{
        let json = await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/tag_group/add`,{group_name:tag_group_name});
        let newId: number = json.group_id;
        ra(()=>{
            this.root.photo.tags.push({group_id: newId, group_tags: [], group_name: tag_group_name});
        });
        return newId;
    }
    @action
    async sendAddTag(tag_group_id: number, tag_name: string): Promise<number>{
        let json = await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/tag/add`,{tag_name:tag_name, group_id: tag_group_id});
        let newId: number = json.tag_id;
        ra(()=>{
            this.root.photo.tags.forEach(tg =>{
                if (tg.group_id == tag_group_id){
                    tg.group_tags.push({tag_id: newId, tag_name: tag_name});
                }
            });
        });
        return newId;
    }
    @action
    async sendRenameTag(tag_id: number, newName: string){
        ra(()=>{
            this.root.photo.tags.forEach(tg =>{
                tg.group_tags.forEach(t => {
                    if (t.tag_id == tag_id) t.tag_name = newName;
                });
            });
        });
        await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/tag/modify`,{tag_name:newName,tag_id: tag_id});
    }
    @action
    async sendRenameTagGroup(group_id: number, newName: string){
        ra(()=>{
            this.root.photo.tags.forEach(tg =>{
                    if (tg.group_id == group_id) tg.group_name = newName;
            });
        });
        await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/tag_group/modify`,{group_name:newName,group_id: group_id});
    }


}