import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './PersonalAreaComp.scss';
import {CloseSvg} from "../../../icons/MiniIcon/CloseSvg";
import {action, observable} from "mobx";
import classNames from "classnames";
import {PersonalArea_PassPanel} from "./PersonalArea_PassPanel";
import {PersonalArea_StatPanel} from "./PersonalArea_StatPanel";
import {PersonalArea_NamePanel} from "./PersonalArea_NamePanel";

@observer
export class PersonalAreaComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClose(){
        this.props.store.user.personalArea.showForm = false;
    }


    render() {
        let store = this.props.store;
        return <div className="modal-back">
            <div className="modal-full modal-full-frame PersonalAreaComp-window">
                <div className="PersonalAreaComp-body">
                    <div className="PersonalAreaComp-left">
                        <div className="PersonalAreaComp-leftTitle">
                            {store.trans["Personal Are"]}
                        </div>
                        <div className="PersonalAreaComp-leftList">
                            <div className="PersonalAreaComp-leftItem active">{store.trans["General information"]}</div>
                        </div>
                        <div className="PersonalAreaComp-leftBottom">
                        </div>
                    </div>
                    <div className="PersonalAreaComp-right">
                        <div className="PersonalAreaComp-close white_svg blue_svg_onhover pointer" onClick={this.onClose}>
                            <svg width="14" height="14" viewBox="0 0 14 14"  xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" stroke="none"/>
                            </svg>
                        </div>
                        <div className="PersonalAreaComp-rightColumns">
                            <div className="PersonalAreaComp-rightCol">
                                <div className="PersonalAreaComp-rightColTitle">
                                    {store.trans["Personal data"]}
                                </div>
                                <div className="PersonalAreaComp-rightColBody style-4 overflowAutoY">
                                    <PersonalArea_NamePanel store={store} />
                                    <PersonalArea_PassPanel store={store} />
                                </div>
                            </div>
                            <div className="PersonalAreaComp-rightCol">
                                <div className="PersonalAreaComp-rightColTitle">
                                    <div className="flex-stretch-item">{store.trans["Current period"]}</div>
                                    <div className="PersonalAreaComp-rightColTitleRight">
                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5.11765L3.8 8.41176L1 11.7059V15H9V11.7059L6.6 8.41176L9 5.11765V1H1V5.11765Z" stroke="#6D7785" strokeWidth="1.5" strokeLinejoin="round"/>
                                        </svg>
                                        <span className="text-next">364 дн.</span>
                                    </div>
                                </div>
                                <PersonalArea_StatPanel store={store} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
