import {GeoJSONSource} from "maplibre-gl";
import {CustomStoreTool} from "../general/CustomStoreTool";
import {FeatureCollection} from "geojson";
import {MapComp} from "../../../components/panels/Map/MapComp";
import {autorun} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {MBUtils} from "../../../helper/utils/MBUtils";

export class SearchConourTool extends CustomStoreTool{
    static readonly COMMON_PREFIX = "class_";
    static readonly SEARCH_SOURCE_ID : string = SearchConourTool.COMMON_PREFIX+'search_rect_src';
    static readonly SEARCH_LINE_LAYER_ID : string = SearchConourTool.COMMON_PREFIX+'search_line_layer';
    static readonly SEARCH_POINT_LAYER_ID : string = SearchConourTool.COMMON_PREFIX+'search_point_layer';

    onSubscription(): IReactionDisposer[] {
        return [autorun(() => {
            this.updateContour();
        })];
    }

    onInstall() {

        let map = this.map;

        if (map.getSource(SearchConourTool.SEARCH_SOURCE_ID) == null) {
            map.addSource(SearchConourTool.SEARCH_SOURCE_ID, {
                'type': 'geojson',
                'data': this.getSourceContent()
            });
        }
        if (map.getLayer(SearchConourTool.SEARCH_POINT_LAYER_ID) == null) {
            this.addLayer({
                'id': SearchConourTool.SEARCH_POINT_LAYER_ID,
                'type': 'circle',
                'source': SearchConourTool.SEARCH_SOURCE_ID,
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#FFCE5F',
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#FFFFFF'
                },
                "filter": ["==", "$type", "Point"]
            });
        }
        if (map.getLayer(SearchConourTool.SEARCH_LINE_LAYER_ID) == null) {
            this.addLayer({
                'id': SearchConourTool.SEARCH_LINE_LAYER_ID,
                'type': 'line',
                'source': SearchConourTool.SEARCH_SOURCE_ID,
                'paint': {
                    'line-color': '#FFCE5F',
                    'line-opacity': 1.0,
                    'line-width': 4
                },
                "filter": ["!=", "$type", "Point"]
            });
        }

    }

    onUninstall() {
        let map = this.map;
        if (map.getLayer(SearchConourTool.SEARCH_LINE_LAYER_ID) != null) this.removeLayer(SearchConourTool.SEARCH_LINE_LAYER_ID);
        if (map.getLayer(SearchConourTool.SEARCH_POINT_LAYER_ID) != null) this.removeLayer(SearchConourTool.SEARCH_POINT_LAYER_ID);
        if (map.getSource(SearchConourTool.SEARCH_SOURCE_ID) != null) map.removeSource(SearchConourTool.SEARCH_SOURCE_ID);

    }


    public updateContour(){
        let map = this.map;
        if (map != null) {
            let src = map.getSource(SearchConourTool.SEARCH_SOURCE_ID);

            if (src) (src as GeoJSONSource)
                .setData(this.getSourceContent());
            MBUtils.setLayerVisibility(this.map, SearchConourTool.SEARCH_LINE_LAYER_ID, this.store.map.searchObject.visible);
            MBUtils.setLayerVisibility(this.map, SearchConourTool.SEARCH_POINT_LAYER_ID, this.store.map.searchObject.visible);
        }
    }
    private getSourceContent(): FeatureCollection{
        return (this.store.map.searchObject.content != null) ? this.store.map.searchObject.content : MapComp.EMPTY_SOURCE;
    }
}
