import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './AdminPanel_DownFiltersComp.scss';
import {UserListFilterItems} from "../../../../store/user/UserListStore";
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";
import {ra} from "../../../../helper/utils/mobxUtils";
import {action} from "mobx";

@observer
export class AdminPanel_DownFiltersComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickItem(event: any, data?: any){
        let item = data;
        let check = this.props.store.userList.filterItems.has(item);
        if (check){
            this.props.store.userList.filterItems.delete(item);
        }else{
            this.props.store.userList.filterItems.add(item);
        }
    }

    getItem(item: UserListFilterItems): any{
        let check = this.props.store.userList.filterItems.has(item);
        return <div key={item} className="AdminPanel_DownFiltersComp-GroupItem"  >
            <CheckboxMiniComp classesContainer="pointer" state={check} size={CheckboxSize.standart} data={item} onClick={this.onClickItem}>
                <span className="text-next">{this.props.store.userList.getTitleFilter(item)}</span>
            </CheckboxMiniComp>
        </div>;
    }

    render() {
        let store = this.props.store;
        //IndexByPointPopupGroupsItemComp-GroupItem
        return <div className="AdminPanel_DownFiltersComp-dropDown">
            <div className="AdminPanel_DownFiltersComp-subGroupTitle">{store.trans.Role}</div>
            {this.getItem(UserListFilterItems.filter_role_admin)}
            {this.getItem(UserListFilterItems.filter_role_owner)}
            {this.getItem(UserListFilterItems.filter_role_user)}
            {this.getItem(UserListFilterItems.filter_role_guest)}
            <div className="AdminPanel_DownFiltersComp-subGroupTitle">{store.trans.Status}</div>
            {this.getItem(UserListFilterItems.filter_status_active)}
            {this.getItem(UserListFilterItems.filter_status_blocked)}
            <div className="AdminPanel_DownFiltersComp-subGroupTitle">{store.trans.Subscription}</div>
            {this.getItem(UserListFilterItems.filter_subscr_limit)}
            {this.getItem(UserListFilterItems.filter_subscr_unlimit)}
        </div>;
    }
}
