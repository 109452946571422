import {CustomStore} from "../CustomStore";
import {IPhotoProperties, PhotoStore} from "./PhotoStore";
import {action, autorun, computed, observable} from "mobx";
import {PhotoFileItemStore} from "./PhotoFileItemStore";
import {Feature} from "geojson";
import {SyncUtils} from "../../helper/utils/SyncUtils";
import {PhotoSelectTagStore} from "./PhotoSelectTagStore";
import {fetchJsonPost} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";

export class PhotoEditorStore extends CustomStore{
    constructor(parent: CustomStore) {
        super(parent);
        if (parent != null){
            setImmediate(()=>{
                let ps = (parent as PhotoStore);
                autorun(()=> {
                    if (!ps.editor.editorWindowShow) return;
                    this.syncPhotos(ps.features);
                });
            });
        }
    }

    class(): string {
        return "PhotoEditorStore";
    }

    @observable
    busySending: boolean = false;

    @observable
    editorWindowShow: boolean = false;

    @observable
    selectTags: PhotoSelectTagStore = new PhotoSelectTagStore(this);

    @observable
    photos: PhotoFileItemStore[] = [];


    @computed
    get selectAll(): boolean{
        let allSelected = true;
        if (this.photos.length == 0) return false;
        this.photos.forEach(a => {if (!a.selected) allSelected = false;});
        return allSelected;
    }
    set selectAll(value: boolean){
        this.photos.forEach(a => {a.selected = value});
    }

    //@action
    syncPhotos(features: Feature<null, IPhotoProperties>[]){
        SyncUtils.syncDataAndState(
            this.photos, features,
            (old) => old.photo_id.toString(),
            (newDataItem) => newDataItem.properties.photo_id.toString(),
            newDataItem => {
                let t: PhotoFileItemStore = new PhotoFileItemStore(this);
                t.photo_id = newDataItem.properties.photo_id;
                t.version = newDataItem.properties.version;
                t.name = newDataItem.properties.file_name;
                t.lat = newDataItem.properties.lat;
                t.lon = newDataItem.properties.lon;
                t.tags_ids = newDataItem.properties.tags;
                t.date = (new Date(newDataItem.properties.photo_time)).getTime();
                t.region_id = newDataItem.properties.region_id;
                t.farm_id = newDataItem.properties.farm_id;
                return t;
            }, (oldState, newDataItem) => {
                oldState.version = newDataItem.properties.version;
                oldState.name = newDataItem.properties.file_name;
                oldState.lat = newDataItem.properties.lat;
                oldState.lon = newDataItem.properties.lon;
                oldState.tags_ids = newDataItem.properties.tags;
                oldState.region_id = newDataItem.properties.region_id;
                oldState.farm_id = newDataItem.properties.farm_id;
                oldState.date = (new Date(newDataItem.properties.photo_time)).getTime();
            }
        );
    }

    @action
    async sendDeleteTags(photo_id: number[], tags: number[]){
        try{
            this.busySending = true;
            let url = `/api/projects/${this.root.agro.projectName}/photo/tag/del`;//?filter={"photo_id":[20,30]}
            await fetchJsonPost(url,{filter:{photo_id: {"$in":photo_id}}, tag_id: tags});
        }catch (e) {
            this.root.addError(e);
        }
        ra(()=>{this.busySending = false;});
    }


    async sendAddTagByFilter(tags: number[]){
        await this.root.photo.editor.sendModifyPhotoByFilter(`/api/projects/${this.root.agro.projectName}/photo/tag/add`,{"tag_id":tags});
    }

    @action
    async sendAddTag(photo_id: number[], tags: number[]){
        try {
            this.busySending = true;
            await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/photo/tag/add`, {filter:{photo_id: {"$in":photo_id}}, tag_id: tags});
        }catch (e) {
            this.root.addError(e);
        }
        ra(()=>{this.busySending = false;});
    }
    async sendRemoveTagByFilter(tags: number[]){
        await this.root.photo.editor.sendModifyPhotoByFilter(`/api/projects/${this.root.agro.projectName}/photo/tag/del`,{"tag_id":tags});
    }
    @action
    async sendRemoveTag(photo_id: number[], tags: number[]){
        try {
            this.busySending = true;
            await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/photo/tag/del`, {filter:{photo_id: {"$in":photo_id}}, tag_id: tags});
        }catch (e) {
            this.root.addError(e);
        }
        ra(()=>{this.busySending = false;});
    }
    @action
    async sendChangeFarm(photo_id: number[], newFarmId: number){
        try {
            this.busySending = true;
            let fa: Partial<Feature<null, IPhotoProperties>>[] = photo_id.map(a => {
                let q: Partial<Feature<null, IPhotoProperties>> = {properties: {photo_id: a, farm_id: newFarmId}};
                return q;
            });

            await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/photo/modify`, {features: fa});
        }catch (e) {
            this.root.addError(e);
        }
        ra(()=>{this.busySending = false;});
    }

    async sendChangeFarmByFilter(newFarmId: number){
        try {
            this.busySending = true;

            await this.sendModifyPhotoByFilter(`/api/projects/${this.root.agro.projectName}/photo/modify`,
                {values:{farm_id: newFarmId}});
        }catch (e) {
            this.root.addError(e);
        }
        ra(()=>{this.busySending = false;});
    }

    async sendDeletePhotosByFilter(){
        await this.root.photo.editor.sendModifyPhotoByFilter(`/api/projects/${this.root.agro.projectName}/photo/delete`);
    }
    @action
    async sendDeletePhotos(photo_id: number[]){
        try {
            this.busySending = true;
            let url = `/api/projects/${this.root.agro.projectName}/photo/delete`;//?filter={"photo_id":[20,30]}
            await fetchJsonPost(url, {filter: {"photo_id":{"$in":photo_id}}});
        }catch (e) {
            this.root.addError(e);
        }
        ra(()=>{this.busySending = false;});
    }
    @action
    async sendModifyPhotoByFilter(url: string, params: any = {}){
        try {
            this.busySending = true;
            await fetchJsonPost(url, { ...params,...this.root.photo.getParamsPhotoListRequest({url: url})});
        }catch (e) {
            this.root.addError(e);
        }
        ra(()=>{this.busySending = false;});
    }
}