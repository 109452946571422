import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {LegendCommonComp} from "../LegendCommonComp";
import {IntStyleLegend} from "../../../../helper/intStyle/IntStyleLegend";
import {LegendLayerItem} from "./LegendLayerItem";
import {LegendMinComp} from "./LegendMinComp";
import {action} from "mobx";
import { WindowTriState } from "../../../../helper/structs/WindowTriState";

@observer
export class LegendLayerComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClickMinimize(){
        this.props.store.map.legend.legendMinimize = true;
    }

    render() {
        let store = this.props.store;
        if (!store.map.legend.legendLayerShow) return null;
        let min = store.map.legend.legendMinimize;
        let st: React.CSSProperties = {right: "5px"};
        if (store.indexByPointer.showPanel == WindowTriState.show){
            st.right = "440px";
        }
        else if (store.map.layersMenuOpened) {
            st.right = "355px"
        }

        if (min) {
            return <LegendMinComp store={store} style={st}/>;
        }

        let jsonStyle = store.agro2.styles.styleJson;
        if (jsonStyle == null) return null;
        let l = IntStyleLegend.getLegendInfo(jsonStyle, store.map.zoom);
        if (l == null || l.categories == null) return null;
        let arr = l.categories.map((a, index) => <LegendLayerItem item={a} store={store} key={index.toString()+" "+a.name??""} />);
        if (arr.length == 1) return null;

        return (
            <LegendCommonComp store={store} className="legendLayer-panel qtransit" style={st}>
                <div className="legendLayer-title">{l.title??""}</div>
                <div className="legendLayer-minButton pointer" onClick={this.onClickMinimize}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99949 10V8.75H7.86574L4.55762 5.44187L5.44137 4.55813L8.74949 7.86625V5H9.99949V9.375C9.99949 9.72 9.71949 10 9.37449 10H4.99949Z" fill="#C5C5C5"/>
                        <path d="M10 0L0.625 0C0.28 0 0 0.28 0 0.625L0 10H1.25L1.25 1.25L10 1.25V0Z" fill="#C5C5C5"/>
                    </svg>
                </div>
                {arr}
            </LegendCommonComp>
        );
    }
}