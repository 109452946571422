import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ShowRoom2LayerStore} from "../../../../store/dataShowroom2/ShowRoom2LayerStore";
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";
import {action} from "mobx";
import "./ShowRoom2Layer.scss";
import classNames from "classnames";
import {LoadStatus} from "../../../../helper/structs/LoadStatus";

export interface IShowRoom2Layer extends IStoreProps{
    layer: ShowRoom2LayerStore;
    className?: string;
    disabled?: boolean;
}
@observer
export class ShowRoom2Layer extends React.Component<IShowRoom2Layer, undefined> {
    constructor(props: IShowRoom2Layer) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickCheck(){
        let layer = this.props.layer;
        if (this.props.disabled) return;
        layer.checked = !layer.checked;
    }

    render() {
        let store = this.props.store;
        let layer = this.props.layer;
        let dis = this.props.disabled??false;
        let stat: any = null;
        if (layer.countPoints_status == LoadStatus.loading){
            stat = <div className="ShowRoom2Layer-stat"><div className="ShowRoom2Layer-stat-loading linear-background-gray" /></div>;
        }else
        if (layer.countPoints_status == LoadStatus.ready){
            stat = <div className="ShowRoom2Layer-stat">{layer.countPoints}</div>;
        }
        return <div className={classNames("ShowRoom2Layer", this.props.className,{"ShowRoom2Layer-disabled": this.props.disabled})} onClick={this.onClickCheck} >
            <CheckboxMiniComp state={layer.checked} size={CheckboxSize.switcher}/>
            <div className="ShowRoom2Layer-label">{layer.title}</div>
            {stat}
            {this.props.children}
        </div>;
    }
}
