import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {IPhotoFilterInfo, PhotoFilterType, PhotoPanelTopFilterItemComp} from "./PhotoPanelTopFilterItemComp";
import './PhotoPanelTopFiltersComp.scss';
import '../PhotoPanel/PhotoPanel.scss';
import {Utils} from "../../../../../helper/utils/Utils";
import {action} from "mobx";
import classNames from "classnames";

export interface IPhotoPanelTopFiltersComp extends IStoreProps{
    className?: string;
}
@observer
export class PhotoPanelTopFiltersComp extends React.Component<IPhotoPanelTopFiltersComp, undefined> {
    constructor(props: IPhotoPanelTopFiltersComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onDelete(data: IPhotoFilterInfo){
        if (data.filterType == PhotoFilterType.data){
            this.props.store.photo.photoFilter.filterPhotoDate.reset();
        }
        if (data.filterType == PhotoFilterType.tag){
            this.props.store.photo.photoFilter.tagGroupFilter.tagGroups.forEach(a => {
                a.tags.forEach(t => {
                    if (t.tag_id == data.id){
                        t.checked = false;
                    }
                });
            });
        }
    }

    render() {
        let store = this.props.store;
        let arr: any[] = [];
        let filter = store.photo.photoFilter;
        if (filter.filterPhotoDate.hasValid()){
            let txt = "";
            if (filter.filterPhotoDate.isValidBegin()){
                txt += Utils.formatDate(filter.filterPhotoDate.begin)+" ";
            }
            txt += "-";
            if (filter.filterPhotoDate.isValidEnd()) {
                txt += " " + Utils.formatDate(filter.filterPhotoDate.end);
            }
            arr.push(<PhotoPanelTopFilterItemComp text={txt} key="date" store={store} highlight={true}
                                                  data={{filterType: PhotoFilterType.data}} onClickDelete={this.onDelete} >
                <svg width="11" height="11" className="text-prev" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3125 1.375H8.9375V0H6.875V1.375H4.125V0H2.0625V1.375H0.6875C0.308 1.375 0 1.683 0 2.0625V10.3125C0 10.692 0.308 11 0.6875 11H10.3125C10.692 11 11 10.692 11 10.3125V2.0625C11 1.683 10.692 1.375 10.3125 1.375ZM9.625 9.625H1.375V4.8125H9.625V9.625Z" fill="#C5C5C5"/>
                </svg>
            </PhotoPanelTopFilterItemComp>);
        }
        filter.tagGroupFilter.tagGroups.forEach(a => {a.tags.forEach(t => {
            if (t.checked)
                arr.push(<PhotoPanelTopFilterItemComp text={t.tag_name} key={t.tag_id.toString()} highlight={true}
                                                      data={{filterType: PhotoFilterType.tag, id: t.tag_id}}
                                                      onClickDelete={this.onDelete} store={store}/>);
        })});

        if (arr.length == 0) return null;
        return <div className={classNames(this.props.className)}><div className="PhotoPanelTopFiltersComp">
            {arr}
        </div></div>;
    }
}
