// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoMergeIsoXmlItemComp-main {
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 0 16px;
}

.AgroAhoMergeIsoXmlItemComp-main:hover {
  background: #383F48;
}

.AgroAhoMergeIsoXmlItemComp-name {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #C5C5C5;
}

.AgroAhoMergeIsoXmlItemComp-area {
  flex-shrink: 0;
  text-align: right;
  color: #6A7886;
}

.AgroAhoMergeIsoXmlItemComp-disabledButton {
  opacity: 0.5;
  cursor: default;
}

.AgroAhoMergeIsoXmlItemComp-checkbox {
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/cards/AgroAhoMergeIsoXmlItemComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;AAGF;;AADA;EACE,cAAA;EACA,iBAAA;EACA,cAAA;AAIF;;AAFA;EACE,YAAA;EACA,eAAA;AAKF;;AAHA;EACE,kBAAA;AAMF","sourcesContent":[".AgroAhoMergeIsoXmlItemComp-main {\r\n  display: flex;\r\n  align-items: center;\r\n  height: 35px;\r\n  cursor: pointer;\r\n  font-family: Open Sans, sans-serif;\r\n  font-size: 12px;\r\n  font-weight: 400;\r\n  padding: 0 16px;\r\n}\r\n.AgroAhoMergeIsoXmlItemComp-main:hover {\r\n  background: #383F48;\r\n}\r\n.AgroAhoMergeIsoXmlItemComp-name {\r\n  flex-grow: 1;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  color: #C5C5C5;\r\n}\r\n.AgroAhoMergeIsoXmlItemComp-area {\r\n  flex-shrink: 0;\r\n  text-align: right;\r\n  color: #6A7886;\r\n}\r\n.AgroAhoMergeIsoXmlItemComp-disabledButton {\r\n  opacity: .5;\r\n  cursor: default;\r\n}\r\n.AgroAhoMergeIsoXmlItemComp-checkbox {\r\n  margin-right: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
