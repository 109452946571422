import * as React from 'react';
import {observer} from "mobx-react";
import {action} from "mobx";
import {IStoreProps} from "../../../app/helper/structs/IStoreProps";
import {MinSvg} from "../../../app/components/icons/Legend/MinSvg";
import {MaxSvg} from "../../../app/components/icons/Legend/MaxSvg";
import {LegendLinearComp} from "../../../app/components/panels/Right/LegendLinearComp";
import {ColorHelper} from '../../../app/helper/utils/ColorHelper';
import {WindowTriState} from '../../../app/helper/structs/WindowTriState';
import {LeftPanelMode} from '../../../app/store/SearchStore';
import {IMeteoStoreProps, IMeteoSuperStore, LeftPanelModeMeteo} from "../meteoPlugin";
import autoBindReact from "auto-bind/react";
import { LegendDiscreteComp } from '../../../app/components/panels/Right/LegendDiscreteComp';
import { IDiscreteInterval } from '../../../app/store/productSetting/IndexBandProductStore';
import { Visibility } from 'maplibre-gl';
import { IDeviation, MeteoStore, ViewMode } from '../MeteoStore';
import { MeteoTool } from '../MeteoTool';
import Legend from '../../../app/components/Common/Legend/Legend';
import { ISuperStore } from '../../../pluginApi/store/SuperStore';
import { useState } from 'react';
import ClassLegendComp from './ClassLegendComp';



interface IMeteoLegendProps {
    store: IMeteoSuperStore;
    deviation: IDeviation;
    showLegend: boolean;
}

const MeteoLegendComp = (props: IMeteoLegendProps) => {
    const {store, deviation, showLegend} = props;
    const [visible, setVisible] = useState(store.meteoStore.showData);

    let meteo = store.meteoStore;
    let map = store.map.mapbox;

    const toggleVisible = (visible: boolean)  => {
        setVisible(visible);
        let vis: Visibility = visible? "visible": "none";
        map.setLayoutProperty(MeteoTool.METEO_GRID_LAYER, 'visibility', vis);
    }

    if (store.searchPanel.leftPanelMode != LeftPanelModeMeteo || ! deviation || !showLegend) return null;

    let di: IDiscreteInterval[] = [];
    if (meteo.currentParams.valFunction == 'div') {
        let ranges = deviation.ranges.slice().reverse().concat([0]);
        let colors = meteo.colors.slice().reverse().map(c => ColorHelper.parseColor(c));
        for (let i = 0; i < ranges.length; i++)
            di.push({
                min: ranges[i],
                max: i == 0? 250: ranges[i - 1],
                color: colors[i],
                isTransparent: false
            }); 
    }
    else if (meteo.currentMeteoParam.code == "gtk") {
        let ranges = [1.5, 0.9, 0.7, 0.5, 0];
        let colors = meteo.colors.slice().reverse().map(c => ColorHelper.parseColor(c));
        for (let i = 0; i < ranges.length; i++)
            di.push({
                min: ranges[i],
                max: i == 0? 250: ranges[i - 1],
                color: colors[i],
                isTransparent: false
            }); 
    }
    let linearLegendTitle = null;
    switch (true) {
        case meteo.currentParams.valFunction == 'div': linearLegendTitle = 'Отношен. к норме'; break;
        case meteo.currentParams.valFunction == 'norm': linearLegendTitle = 'Климат. норма'; break;
        case meteo.currentParams.dataType == 'temp': linearLegendTitle = 'Сумма актив. темп.'; break;
        case meteo.currentParams.dataType == 'prec': linearLegendTitle = 'Сумма осадков'; break;
        case meteo.currentParams.dataType == 'gtk': linearLegendTitle = 'Зоны увлажнения'; break;
    }

    let footer = <React.Fragment>
        <div className="separator_line"/>
        <div className={"legend-panel_linearTitle"}>{meteo.stats.unit}</div>
    </React.Fragment>;

    let legendBody : any = null;
    if (meteo.currentParams.valFunction == 'div')
        legendBody = <LegendDiscreteComp intervals={di} store={store as any} showCheckBoxes={false} numAreas={[]} footerComp={footer} tableClass='MeteoLegendComp-dicretelegend-table'/>;
    else if (meteo.currentMeteoParam.code == "gtk")
        legendBody = <ClassLegendComp classes={MeteoStore.HUMIDIFICATION_ZONES}/>;
    else
        legendBody = <LegendLinearComp store={store as any} max={ deviation.max} min={ deviation.min} visibleCheckbox={false}
                            description={meteo.stats.unit} title={meteo.stats.unit} hintClassName="MeteoLegendComp-legend-title"
                            colors={meteo.colors.map(c => ColorHelper.parseColor(c))}                                
                            values={[deviation.min,...deviation.ranges, deviation.max]}/>;

    return (<Legend header={linearLegendTitle} minimized={false} showData={true} 
        showDataCallback={toggleVisible} content={legendBody} store={store as ISuperStore}/>);
}

export default MeteoLegendComp;