import { ra } from "../../../helper/utils/mobxUtils";
import { Utils } from "../../../helper/utils/Utils";
import { TemperatureCategory } from "../IndexByPointerStore";
import { IGraphData, IGraphDataInfo, IGraphDayValue, IndexByPointGraphStore } from "../IndexByPointGraphStore";
import { IndexByPointerSourceCustomStore } from "./IndexByPointerSourceCustomStore";
import { IndexByPointerSourceSimpleTempCustomStore } from "./IndexByPointerSourceSimpleTempCustomStore";
import { MeteoStationInfo } from "./MeteoStationInfo";

 export class IndexByPointerSourceSimpleTempMeteoStationStore extends IndexByPointerSourceSimpleTempCustomStore{
    private _meteoStation: MeteoStationInfo = null;
    public get meteoStation() : MeteoStationInfo {
        let gp = this.parent.gPoint;
        if (! this._meteoStation)
            this._meteoStation = new MeteoStationInfo(gp.point.lng, gp.point.lat);
        return this._meteoStation;
    }

    constructor(parent: IndexByPointGraphStore) {
        super(parent);
        this.dataValue.getUrl = this.getUrlValue;
        this.dataMin.getUrl = this.getUrlMin;
        this.dataMax.getUrl = this.getUrlMax;
        this.dataValue.getValue = this.getValueSimple;
        this.dataMin.getValue = this.getValueMin;
        this.dataMax.getValue = this.getValueMax;
    }

    protected getUrlValue(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url = `/api/meteo/station/${this.meteoStation.station_id}/temp?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=mean_temp`;
        return url;
    }
    protected getUrlMin(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url1 = `/api/meteo/station/${this.meteoStation.station_id}/temp?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=min_temp`;
        return url1;
    }
    protected getUrlMax(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        let url2 = `/api/meteo/station/${this.meteoStation.station_id}/temp?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=max_temp`;
        return url2;
    }
    protected getValueSimple(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.mean_temp);
    }
    protected getValueMin(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.min_temp);
    }
    protected getValueMax(a: any): IGraphDayValue{
        return this.getGraphDataValue(a.date, a.max_temp);
    }

}