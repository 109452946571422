import {ObservableCustomStore} from "../../app/store/CustomStore";
import {Utils} from "../../app/helper/utils/Utils";
import {AgroAhoStore, AhoModalType} from "./agroAhoStore";
import {AhoCardShowType, AhoExportFormat, AhoIndicatorFormat, ExportRNFertType} from "./agroAhoCardStore";
import {AhoUtils} from "./agroAhoUtils";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";


export class AgroAhoFileStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    cancelExport: boolean = false;

    doCellExport(format: AhoExportFormat){
        let pname = this.parentStore.projStore.projName;
        let mstore = this.parentStore.mapStore;
        let cstore = this.parentStore.cardStore;
        let fields = '';
        if (cstore.layersExportVisFields) {
            let fields_ = cstore.getFieldSelected();
            if (fields_) {
                if (fields_.length == 1) fields = `&field_id=${fields_[0]}`;
                if (fields_.length > 1) fields = `&field_ids=${fields_.join(',')}`;
            }
        }
        let url = `/api/projects/${pname}/asa_map/${this.parentStore.cardStore.card.map_id}/export/layers?format=${format}${fields}`;
        if (mstore.isNewProj()) {
            let filter = `?format=${format}`;
            let fields = this.parentStore.cardStore.getFieldSelected();
            if (fields?.length) filter += `&field_ids=${fields.join(',')}`;
            url = `/api/projects/${pname}/asa2/map/${this.parentStore.cardStore.card.map_id}/layers/export${filter}`;
        }
        // console.log('url:', url);
        Utils.downloadFile(url);
    }

    // doExport_(card_id: number, is_cartogram: boolean, is_indicators: boolean,
    //          label_type: AhoCardShowType, is_soft: boolean, is_vis: boolean){
    //     let mstore = this.parentStore.mapStore;
    //     if (mstore.isNewProj()) this.doExportNew(card_id, is_cartogram, is_indicators, label_type, is_soft, is_vis)
    //         else this.doExportOld(card_id, is_cartogram, is_indicators, label_type, is_soft, is_vis);
    // }

    // doExportOld(card_id: number, is_cartogram: boolean, is_indicators: boolean,
    //          label_type: AhoCardShowType, is_soft: boolean, is_vis: boolean){
    //     let label = '';
    //     if (label_type == AhoCardShowType.Indicators) label = 'indc_val';
    //     if (label_type == AhoCardShowType.Cells) label = 'cell_id';
    //     let scale_name = is_soft ? 'agrosoft' : 'agroplem';
    //     // let indc_code = this.parentStore.cardStore.selectedIndicator.indc_code;
    //     // let filter = `?indicators=${indc_code}&map={"base_layer":{"type":"google","opacity":100},`+
    //     //     `"indc_layer":{"label":"${label}","scale_name":"${scale_name}"}}`;
    //     let filter = `?map={"base_layer":{"type":"google","opacity":100},`+
    //         `"indc_layer":{"label":"${label}","scale_name":"${scale_name}"}}`;
    //     if (is_vis) {
    //         let fields = this.parentStore.cardStore.getFieldSelected();
    //         if (fields?.length) filter += `&field_ids=${fields.join(',')}`;
    //     }
    //     let url = `/api/projects/${this.parentStore.projStore.projName}/asa_map/${card_id}/export/`;
    //     if (is_indicators && !is_cartogram) url = url + 'cells_indicators';
    //     if (is_cartogram && !is_indicators) url = url + 'cartograms' + filter;
    //     if (is_cartogram && is_indicators) url = url + 'indicators' + filter;
    //
    //     console.log('url:', url);
    //     Utils.downloadFile(url);
    //     // if (is_indicators && !is_cartogram) this.downloadIndicators(card_id);
    //     // if (is_cartogram && !is_indicators) this.downloadCartogram(card_id, is_cells, is_soft);
    //     // if (is_cartogram && is_indicators) this.downloadCartogramIndicators(card_id);
    // }

    async doExport(callback?: (res: string)=>void){
        let store_ = this.parentStore;
        let cs = store_.cardStore;
        let ts = store_.tourStore;
        let mType: AhoModalType = store_.ahoModalType;
        let errMsg: string = 'error export';
        // store_.toggleModal(false);

        // {store_.ahoModalType == AhoModalType.ExportIndicators && <AgroAhoExportPanelComp store={store}/> }
        //     {store_.ahoModalType == AhoModalType.ExportRN && <AgroAhoExportRNPanelComp store={store}/> }

        let fields = '';
        if (cs.exportVisFields) {
            let fields_ = cs.getFieldSelected();
            if (fields_) {
                if (fields_.length == 1) fields = `&field_id=${fields_[0]}`;
                if (fields_.length > 1) fields = `&field_ids=${fields_.join(',')}`;
            }
        }
        let tours = '';
        if (!ts.isNoSelectedTours) {
            let tours_ = ts.selTours().map(t=>t.tour_id);
            if (tours_.length == 1) tours = `&tour_id=${tours_[0]}`;
            if (tours_.length > 1) tours = `&tour_ids=${tours_.join(',')}`;
        }
        let label = '';
        if (cs.ahoCardShowType == AhoCardShowType.Indicators) label = 'indc_val';
        if (cs.ahoCardShowType == AhoCardShowType.Cells) label = 'cell_id';
        let scale_name = '';
        if (cs.indcFormat == AhoIndicatorFormat.AgroSoft) scale_name = 'agrosoft';
        if (cs.indcFormat == AhoIndicatorFormat.Agroplem) scale_name = 'agroplem';
        if (cs.indcFormat == AhoIndicatorFormat.FosAgro) scale_name = 'fosagro';

        let filter = '';
        let files = [];
        if (mType == AhoModalType.ExportIndicators) {
            if (cs.indicatorsChecked) files.push('"indc_table": {"format":"ods"}');
            if (cs.cartogramChecked) files.push(`"carto":{"map": {"base_layer":{"type":"google"},`+
                `"indc_layer":{"label":"${label}","scale_name":"${scale_name}"}}}`);
            if (cs.cellsChecked) files.push('"indc_geom": {"format":"shape"}');
            let fn = store_.cardStore.exportFileName;
            let fname = fn ? `&filename=${fn}` : '';
            filter = `?async=1${fname}${tours}${fields}&files={${files.join(',')}}`;
        }
        if (mType == AhoModalType.ExportRN) {
            if (cs.normsChecked) files.push('"rn_table": {"format":"ods"}');
            let fert_filter = '';
            if (cs.fertChecked) {
                let rn_id = store_.mapStore.mapRNStore.curRecNormItem.rn_id;
                let g_size = cs.gridGrainSize;
                if (cs.exportRNFertType == ExportRNFertType.TechShp) files.push('"grid_shape": {}');
                if (cs.exportRNFertType == ExportRNFertType.TechXml) files.push('"grid_isoxml": {}');
                fert_filter = `&rn_id=${rn_id}&grid={"cell_size":${g_size}}`;
                if (cs.fertTotal != cs.recNormStat.fert_total_volume_kg)
                    fert_filter += `&fert_total_volume_kg=${cs.fertTotal}`;
            }
            let fn = store_.cardStore.exportRNFileName;
            let fname = fn ? `&filename=${fn}` : '';
            filter = `?async=1${fname}${tours}${fields}${fert_filter}&files={${files.join(',')}}`;
        }

        // filter = `?async=1&ods_report=${cs.indicatorsChecked ? 1 : 0}&carto_report=${cs.cartogramChecked ? 1 : 0}`+
        //     `${tours}${fields}&files={carto:{map: {"base_layer":{"type":"google"},`+
        //     `"indc_layer":{"label":"${label}","scale_name":"${scale_name}"}}}}`;

        // filter = `?map={"base_layer":{"type":"google"},`+
        //     `"indc_layer":{"label":"${label}","scale_name":"${scale_name}"}}&`+
        //     `carto_report=${is_cartogram ? 1 : 0}&ods_report=${is_indicators ? 1 : 0}`+
        //     `${tours}${fields}`;
        let p = [store_.projStore.projName, cs.card.map_id];
        let url_ = `/api/projects/${p[0]}/asa2/map/${p[1]}/tours/export`;
        let url = url_ + filter;
        console.log('url:', url);
        // return;
        let r = await fetchJsonGet(url).catch(err => store_.root.addError(err));
        console.log('export result', AhoUtils.cp(r));
        let err = '';
        if (r?.task_code) {
            let max_steps = 5;
            let n_steps = 0;
            this.cancelExport = false;
            let int_id = setInterval(async ()=>{
                if (this.cancelExport) {
                    this.cancelExport = false;
                    clearInterval(int_id);
                    if (callback) callback('cancel');
                }
                url = `/api/task/get?task_code=${r.task_code}`;
                let r_ = await fetchJsonGet(url).catch(err_ => err = err_);
                console.log('iter', n_steps, r_);
                // if (r_ == 'error' || n_steps >= max_steps) err = 'error export';
                if (r_?.status == 'error') err = errMsg;
                if (r_?.status == 'ok') {
                    url = `/api/projects/${p[0]}/asa2/map/${p[1]}/tours/export/async_result?task_code=${r.task_code}`
                    clearInterval(int_id);
                    if (callback) callback('ok');
                    Utils.downloadFile(url);
                }
                if (err) {
                    clearInterval(int_id);
                    store_.root.addError(err);
                    if (callback) callback(errMsg);
                }
                n_steps += 1;
            }, 1000);
        } else {
            store_.root.addError(errMsg);
            if (callback) callback(errMsg);
        }




        // Utils.downloadFile(url);

        // let fields = '';
        // if (is_vis) {
        //     let fields_ = cstore.getFieldSelected();
        //     if (fields_) {
        //         if (fields_.length == 1) fields = `&field_id=${fields_[0]}`;
        //         if (fields_.length > 1) fields = `&field_ids=${fields_.join(',')}`;
        //     }
        // }
        // let tours = '';
        // if (!cstore.isNoSelectedTours) {
        //     let tours_ = cstore.selTours.map(t=>t.tour_id);
        //     if (tours_.length == 1) tours = `&tour_id=${tours_[0]}`;
        //     if (tours_.length > 1) tours = `&tour_ids=${tours_.join(',')}`;
        // }
        // let label = '';
        // if (label_type == AhoCardShowType.Indicators) label = 'indc_val';
        // if (label_type == AhoCardShowType.Cells) label = 'cell_id';
        // let scale_name = is_soft ? 'agrosoft' : 'agroplem';
        //
        // let filter = `?map={"base_layer":{"type":"google"},`+
        //     `"indc_layer":{"label":"${label}","scale_name":"${scale_name}"}}&`+
        //     `carto_report=${is_cartogram ? 1 : 0}&ods_report=${is_indicators ? 1 : 0}`+
        //     `${tours}${fields}`;
        // let url_ = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${card_id}/tours/export`;
        // let url = url_ + filter;
        // console.log('url:', url);
        // Utils.downloadFile(url);
    }



    // downloadCartogramIndicators(card_id: number){
    //     let url = `/api/projects/${this.parentStore.projStore.projName}/asa_map/${card_id}/export/indicators`;
    //     Utils.downloadFile(url);
    // }
    //
    // downloadIndicators(card_id: number){
    //     let url = `/api/projects/${this.parentStore.projStore.projName}/asa_map/${card_id}/export/cells_indicators`;
    //     Utils.downloadFile(url);
    // }
    //
    // downloadCartogram(card_id: number,is_cells: boolean, is_soft: boolean){
    //
    //     // let base_layer = this.root.map.currentBaselayerKey;
    //
    //     // cartograms?indicators=K2O_M&map={"base_layer":{"type":"google","opacity":100},
    //     // "indc_layer":{"label":"indc_val","scale_name":"agrosoft"}}
    //     // console.log('cart:', label, scale_name, base_layer, indc_code);
    //     // let url = `/api/projects/${this.parentStore.projStore.projName}/asa_map/${card_id}/export/cartograms`;
    //
    //     let url = `/api/projects/${this.parentStore.projStore.projName}/asa_map/${card_id}/export/cartograms` + filter;
    //     // console.log('url:', url);
    //     Utils.downloadFile(url);
    // }

}