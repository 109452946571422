import {ObservableCustomStore} from "../../../../app/store/CustomStore";
import {AgroAhoMapStore, IAhoLayers} from "../agroAhoMapStore";
import {IndicatorKey} from "../../agroAhoCardStore";
import {CirclePaint} from "maplibre-gl";
import {Feature, FeatureCollection} from "geojson";
import {AhoEditorMode} from "../../agroAhoEditorStore";
import {AhoUtils} from "../../agroAhoUtils";


export class AgroAhoTrackFieldPointLayer extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;

    getTFPSourceId(ds: string): string{
        return  ds + '_tfp_src_aho';
    }
    getTFPLayerId(): string{
        return `class_agroAho_${IAhoLayers.TrackFieldPoint}_aho`;
    }

    async getTrackFPIds(field_id: number){
        let ms = this.mapStore;
        let cs = ms.parentStore.cardStore;
        // let data = await ms.getCellCardGeoJSONs({ds: cs.card.track_info.ds_code, no_geom: true,
        //     track_type: 'field', type: IAhoLayers.TrackFieldPoint});
        let data = await this.getTrackFieldPointData(cs.card.track_info.ds_code);
        let res: number[] = [];
        if (data?.features) data.features.forEach((f: any)=>{
            let fid = f.properties.field_id;
            let tid = f.id;
            if (fid == field_id) res.push(tid);
        });
        return res;
    }

    async getTrackFieldPointData(ds: string, upd?: boolean){
        let data: any = await this.mapStore.getCellCardGeoJSONs({ds: ds, track_type: 'field',
            type: IAhoLayers.TrackFieldPoint, upd: !!upd});
        let feats: Feature[] = [];
        if (data?.features) {
            // console.log('FEAT:', AhoUtils.cp(data.features));
            data.features.forEach((f: any, idx: number)=>{
                let coords = f.geometry.coordinates;
                feats.push({"type": "Feature", id: f.properties.track_id, "properties": f.properties, "geometry": {"type": "MultiPoint",
                    "coordinates": [coords[0], coords[coords.length - 1] ]}});
            });
        } else return;
        return {'type': 'FeatureCollection', 'features': feats} as FeatureCollection;
    }
    async addTFPLayer(ds: string){
        if (!ds) return;
        let data: FeatureCollection = await this.getTrackFieldPointData(ds, true);
        if (!data) return;
        // console.log('p data', AhoUtils.cp(data));
        let src_id = this.getTFPSourceId(ds);
        let map = this.root.map.mapbox;
        this.delTFPLayer();

        if (!map.getSource(src_id)){
            await map.addSource(src_id,{
                type: 'geojson',
                data: data
            });
        }

        let layer_id = this.getTFPLayerId();
        // console.log('layer_id', layer_id);
        if (!map.getLayer(layer_id)){
            map.addLayer({
                id: layer_id,
                type: 'circle',
                source: src_id,
                minzoom: 12,
                layout: {},
                paint: <CirclePaint>{
                    'circle-color': '#FFF',
                    'circle-radius': ['interpolate', ['linear'],
                        ['zoom'],
                        12, 3,
                        20, 7
                    ],
                    'circle-opacity': [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                        ['feature-state', 'opacity'],
                        ['==',['feature-state', 'opac'], 1],
                        0.2, 1
                    ],
                    'circle-stroke-width': 3,
                    'circle-stroke-color': '#15db64',
                    'circle-stroke-opacity': [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                        ['feature-state', 'opacity'],
                        ['==',['feature-state', 'opac'], 1],
                        0.2, 1
                    ],
                }
            }, await this.mapStore.getPrevLayer(IAhoLayers.TrackFieldPoint));
        }
        // setTimeout(()=>{
        // let data_ = this.root.map.mapbox.querySourceFeatures(src_id);
        // console.log('data1_', AhoUtils.cp(data_))}, 1000);
    }

    async setTFPHidden(hidden: boolean) {
        // let cstore = this.mapStore.parentStore.cardStore;
        let map = this.root.map.mapbox;
        // let ds = cstore.card.track_info.ds_code;
        let layer_id = this.getTFPLayerId();
        if (!map.getLayer(layer_id)) return;
        let vis = hidden ? 'none' : 'visible';
        map.setLayoutProperty(layer_id, 'visibility', vis);
        // console.log('set tfp vis', layer_id, vis);
        // map.setPaintProperty(layer_id, 'circle-opacity', opacity ? 0.2 : 1);
        // map.setPaintProperty(layer_id, 'circle-stroke-opacity', opacity ? 0.2 : 1);
    }
    async setTFPOpacity(opacity: boolean) {
        let map = this.root.map.mapbox;
        let layer_id = this.getTFPLayerId();
        if (!map.getLayer(layer_id)) return;
        map.setPaintProperty(layer_id, 'circle-opacity', opacity ? 0.2 : null);
        map.setPaintProperty(layer_id, 'circle-stroke-opacity', opacity ? 0.2 : null);
    }

    delTFPLayer(){
        let map = this.root.map.mapbox;
        let layer_id = this.getTFPLayerId();
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
    }

    // f.field_id, checked && !f.checked, checked && f.checked
    // async updOpacity(field_id: number, checked: boolean, selected: boolean){
    //     let cstore = this.mapStore.parentStore.cardStore;
    //     let map = this.root.map.mapbox;
    //     let ds = cstore.card.track_info.ds_code;
    //     let src_id = this.getTFPSourceId(ds);
    //     // let data = map.querySourceFeatures(src_id);
    //
    //     let data: FeatureCollection = await this.getTrackFieldPointData(ds);
    //
    //     // console.log('data:', data, field_id);
    //     if (!data?.features) return;
    //
    //     // let fdata: any = await this.mapStore.getCellCardGeoJSONs({ds: ds, track_type: 'field',
    //     //     type: IAhoLayers.TrackFieldPoint});
    //     // console.log('fdata', AhoUtils.cp(fdata));
    //
    //     // let fdata: any = await this.mapStore.getCellCardGeoJSONs({ds: ds, no_geom: true,
    //     //     track_type: 'field', type: IAhoLayers.TrackCell});
    //     // console.log('fdata:', data, field_id);
    //     //
    //
    //
    //     data.features.forEach((ft: any)=>{
    //         // console.log('setFOp',src_id, ft, ft.id, checked, selected);
    //         // console.log('ft',ft);
    //         if (ft.properties.field_id == field_id) {
    //             this.mapStore.setFeatureIndcOpacity(src_id, ft.id, checked, selected);
    //
    //             // let testFt = this.root.map.mapbox.getFeatureState({ source: src_id, id: ft.id});
    //             // console.log('setFOp',src_id, ft.id, field_id, testFt, checked, selected);
    //         }
    //     });
    // }

    async updTFPLayers(){
        let cstore = this.mapStore.parentStore.cardStore;
        let map = this.root.map.mapbox;
        let ds = cstore.card.track_info.ds_code;
        let src_id = this.getTFPSourceId(ds);
        let source: any = map.getSource(src_id);
        let data: FeatureCollection = await this.getTrackFieldPointData(ds, true);
        // console.log('src', data);
        source.setData(data);
        // console.log('data2', AhoUtils.cp(data));
        // source.setTiles(source.tiles);
    }


    // async updOpacity_(data: FeatureCollection, ds: string){
    //     let cstore = this.mapStore.parentStore.cardStore;
    //     if (!cstore.fieldsItems) return;
    //     let checked = !cstore.fieldsItems.every(i=>!i.checked);
    //     for (let i=0; i<cstore.fieldsItems.length; i++){
    //         let fld = cstore.fieldsItems[i];
    //         data.features.forEach((ft: any)=>{
    //             if (ft.properties.field_id == fld.field_id) {
    //                 // console.log('setFOp',ds, ft, ft.properties.id, checked && !fld.checked,
    //                 //     checked && fld.checked);
    //                 this.mapStore.setFeatureIndcOpacity(ds, ft.properties.id, checked && !fld.checked,
    //                     checked && fld.checked);
    //             }
    //         });
    //     }
    // }
}