import React, { Fragment, RefObject, useRef, useState } from "react"
import AddAOISvg from "../../../icons/MiniIcon/AddAOISvg"
import './AOIButtonsComp.scss';
import classNames from "classnames";
import { CenterPlace, ContextMenuCommonComp, PopupCorner, PopupDirection } from "../../../Common/ContextMenuCommonComp";
import { MyHint } from "../../../Common/MyHint";
import { Translate_en } from "../../../../helper/lang/Translate_en";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import { Utils } from "../../../../helper/utils/Utils";
import { Lang } from "../../../../../pluginApi/store/Lang";

export interface IAddAOIButtonCompProps extends IStoreProps {
    minimized: boolean;
    showUpload?: boolean;
    showPolygon?: boolean;
    showLine?: boolean;
    showRect?: boolean;
    notAccent?:boolean
}

const AddAOIButtonComp = (props: IAddAOIButtonCompProps) => {
    let tr = props.store.trans;
    let ms = props.store.map;
    const inputOpenFileRef : RefObject<HTMLInputElement> = useRef(null);

    const [pressed, setPressed] = useState(false);

    const buttonPressed = () => {
        setPressed(! pressed);
    }

    const uploadPressed = () => {
        setPressed(false);
        if (inputOpenFileRef.current) inputOpenFileRef.current.click();
    }

    const createAfterClose = (createFunc: () => void) => {
        setPressed(false);
        createFunc();
    }

    return <div className={classNames('AddAOIButtonComp-div', {'active': pressed, 'minimized': props.minimized})} onClick={buttonPressed}>
        <input type="file" ref={inputOpenFileRef}
                onChange={ms.uploadChanged} accept=".json,.geojson"
                style={{opacity: 0.0, cursor: "pointer", display: "none"}}/>
        <AddAOISvg minimized={props.minimized}/>{!props.minimized && <span className="AddAOIButtonComp-title">{tr["Area of Interest"]}</span>}
        {pressed && <ContextMenuCommonComp onClose={() => setPressed(false)} parentClickNotIgnore={true} 
                        {...props.minimized? {isCenter: true, centerPlace: CenterPlace.bottom}
                        : {direction: PopupDirection.vertical, indentHorizontal: 15, popupCorner: PopupCorner.rightTop}}>                        
            <div className="AddAOIButtonComp SelectGeometryType-dropDownPanenl">
                {(props.showUpload??true) && <MyHint text={tr["File in .geojson format, EPSG:4326"]}>
                    <div className="AddAOIButtonComp SelectGeometryType-dropDownButton" onClick={uploadPressed}>{/*download*/}
                    <div className="flex-full-center">
                        <svg width="16" height="17" viewBox="0 0 16 17"
                                xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.06689 16L8.06689 3.95435" fill="none" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M14.0669 9.42963L8.0669 3.95435L2.06689 9.42963" fill="none"
                                    strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <rect width="16" height="2" rx="1" stroke="#none"/>
                        </svg>
                    </div>
                </div></MyHint>}
                {(props.showPolygon??true) && <div className="AddAOIButtonComp SelectGeometryType-dropDownButton" onClick={() => createAfterClose(ms.createPolygon)}>{/*polygon*/}
                    <div className="flex-full-center">
                        <svg width="16" height="16" viewBox="0 0 16 16" stroke="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.77783" width="4.44444" height="4.44444" />
                            <rect width="4.44444" height="4.44444" transform="matrix(-1 0 0 1 14.2222 0)"
                                    />
                            <rect y="11.5557" width="4.44444" height="4.44444" />
                            <rect x="11.5557" y="11.5557" width="4.44444" height="4.44444"/>
                            <rect x="1.77783" y="10.7012" width="5.64444" height="0.888889"
                                    transform="rotate(-71.9858 1.77783 10.7012)" />
                            <rect width="5.64444" height="0.888889"
                                    transform="matrix(-0.309253 -0.95098 -0.95098 0.309253 14.2222 10.7012)"
                                    />
                            <rect x="5.3335" y="13.3335" width="5.33333" height="0.888889"/>
                            <rect x="7.11133" y="1.77783" width="1.77778" height="0.888889"/>
                        </svg>
                    </div>
                </div>}
                {(props.showLine??true) &&
                    <div className="AddAOIButtonComp SelectGeometryType-dropDownButton" onClick={() => createAfterClose(ms.createLine)}>{/*line*/}
                        <div className="flex-full-center">
                            <svg width="16" height="16" viewBox="0 0 16 16" stroke="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                <rect width="4.44444" height="4.44444" rx="2.22222"/>
                                <path d="M11.5557 11.5557H16.0001V16.0001H11.5557V11.5557Z"/>
                                <rect x="10.5405" y="11.1687" width="8.88889" height="0.888889"
                                        transform="rotate(-135 10.5405 11.1687)"/>
                            </svg>
                        </div>
                    </div>}
                {(props.showRect??true) && <div className="AddAOIButtonComp SelectGeometryType-dropDownButton" onClick={() => createAfterClose(ms.createRectangle)}>{/*rect*/}
                    <div className="flex-full-center">
                        <svg width="16" height="16" viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg">
                            <rect x="5.3335" y="1.77783" width="5.33333" height="0.888889" stroke="none"/>
                            <rect width="4.44444" height="4.44444" stroke="none"/>
                            <rect y="11.5557" width="4.44444" height="4.44444" stroke="none"/>
                            <rect x="11.5557" width="4.44444" height="4.44444" stroke="none"/>
                            <rect x="11.5557" y="11.5557" width="4.44444" height="4.44444" stroke="none"/>
                            <rect x="5.3335" y="13.3335" width="5.33333" height="0.888889" stroke="none"/>
                            <rect x="13.3335" y="10.6665" width="5.33333" height="0.888889"
                                    transform="rotate(-90 13.3335 10.6665)" stroke="none"/>
                            <rect x="1.77783" y="10.6665" width="5.33333" height="0.888889"
                                    transform="rotate(-90 1.77783 10.6665)" stroke="none"/>
                        </svg>
                    </div>
                </div>}
            </div>
        </ContextMenuCommonComp>}
    </div>
}

export interface IAOIButtonsCompProps extends IStoreProps {
    shortTitle?: boolean;
    aoiExists: boolean;
    area: string;
    notAccent?:boolean
    classNameButton?: string;
}

const AOIButtonsComp = (props: IAOIButtonsCompProps) => {
    let ms = props.store.map;
    let tr = props.store.trans;
    const clearGeometry = async () => {
        try {
            await ms.searchObject.clearAndSetDefault(false);
        }catch (e) {
            props.store.addError(e);
        }
    }

    const downloadGeometry = () => {
        let fileName = (ms.searchObject.name? ms.searchObject.name : 'border') + '.geojson';
        Utils.downloadJson(fileName, ms.searchObject.content);
    }

    let title = props.shortTitle? tr["AOI"] : `${tr["Area of Interest"]} (${tr["AOI"]})`;

    return props.aoiExists? 
        <div className="AOIButtonsComp-div">
            <div className="pointer" onClick={ms.zoomToSearchObject}>
                <span className="AOIButtonsComp-area">{title}:</span>
                <span className="AOIButtonsComp-area value">{props.area}</span>
            </div>
            <div className={classNames("AOIButtonsComp flex-columns")}>
                <div className="AOIButtonsComp-separator"></div>
                <MyHint text={tr["Create an area"]}>
                    <AddAOIButtonComp store={props.store} minimized={true}/>
                </MyHint>
                <MyHint text={tr.Download}>
                    <div
                        className={classNames("AOIButtonComp-spatialButton icon_container icon_container_fill", props.classNameButton)}
                        onClick={downloadGeometry}>
                        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.87502 11.025V3.5H6.12502V11.025L2.62502 7.525L1.40002 8.75L6.38752 13.7375C6.56252 13.9125 6.73752 14 7.00002 14C7.26252 14 7.43752 13.9125 7.61252 13.7375L12.6 8.75L11.375 7.525L7.87502 11.025Z"/>
                            <path d="M13.125 0H0.875V1.75H13.125V0Z"/>
                        </svg>
                    </div>
                </MyHint>
                <MyHint text={tr.Delete}>
                    <div
                        className={classNames("AOIButtonComp-spatialButton icon_container white_svg red_svg_onhover", props.classNameButton)}
                        onClick={clearGeometry}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8125 1.1875L1.1875 10.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.1875 1.1875L10.8125 10.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </MyHint>
            </div>
        </div> :
        <AddAOIButtonComp store={props.store} minimized={false}/>
}

export default AOIButtonsComp;