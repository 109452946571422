import * as React from 'react';
import {RefObject} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import './ParamSearchPanel.scss';
import {Utils} from "../../../../helper/utils/Utils";
import {SpatialButtonsComp} from "./SpatialButtonsComp";

@observer
export class ParamSearchSpatialFilterComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.inputOpenFileRef = React.createRef();
    }

    private readonly inputOpenFileRef : RefObject<HTMLInputElement>;

    componentDidMount() {
        if (this.inputOpenFileRef.current != null)
            this.inputOpenFileRef.current.onchange = this.props.store.map.uploadChanged;
    }

    render() {
        let store = this.props.store;
        let ms = store.map;
        let areaText = ms.searchObject.getAreaStr; //(area / 1000000).toString() + " km²";
        return <div className="popup-pref-in__row7">
            <div className="ParamSearchPanel-activeArea">
                <span className="ParamSearchPanel-Search_area-marginLeft">
                    {`${store.trans["Area of Interest"]} (${store.trans.AOI})`}
                </span>
                <div className="flex-fit-item flex-columns ParamSearchPanel-spatialButtonsBox">
                    <SpatialButtonsComp store={store} classNameButton=""/>
                </div>
            </div>
            {ms.searchObject.isNotEmpty &&
                <div className="ParamSearchPanel-geometryInfo">
                    <span className="ParamSearchPanel-geometryName">{ms.searchObject.name}</span>
                    <span className="ParamSearchPanel-geometryArea">{areaText}</span>
                </div>
            }
        </div>;
    }
}
