import {CustomStore} from "../CustomStore";
import {action, observable, runInAction} from "mobx";
import {LngLat} from "maplibre-gl";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {Utils} from "../../helper/utils/Utils";
import {SceneUiType, SearchStore} from "../SearchStore";

export class NdviValueByMouseStore extends CustomStore{

    @observable
    visibleWidget: boolean = false;
    @observable
    x: number;
    @observable
    y: number;
    @observable
    value: string;
    @observable
    loading: boolean = false;
    @observable
    needReload: boolean = false;
    reloadCenter: LngLat;
    reloadSceneId: string;

    @action
    hideWidget(){
        this.visibleWidget = false;
    }
    @action
async getHeightValue(p: LngLat): Promise<void> {
    let scrPnt = this.root.map.mapbox.project(p);
    this.x = scrPnt.x;
    this.y = scrPnt.y;

    if (this.loading) {
        this.reloadCenter = p;
        this.needReload = true;
        return;
    }
    let needPause = false;
    if (this.needReload) {
        needPause = true;
    }
    this.needReload = false;
    this.visibleWidget = true;
    this.loading = true;

    let url = this.root.indexByPointer.getProdStatUrl()+`?product=dem&lon=${encodeURIComponent(p.lng)}&lat=${encodeURIComponent(p.lat)}&zoom=${Math.ceil(this.root.map.zoom)}`;
    // let url = `https://dev-class.ctrl2go.com/api/prodstat?product=dem&lon=${encodeURIComponent(p.lng)}&lat=${encodeURIComponent(p.lat)}&zoom=${Math.ceil(this.root.map.zoom)}`;
   
    try {
        if (needPause) await Utils.pauseAsync(500);
        let r = await fetchJsonGet(url);
        runInAction(() => {
            if (!r || !r.h) {
                this.loading = false;
                this.visibleWidget = false;
                return;
            }
            this.value = `${r.h}`;
            this.loading = false;
            if (this.needReload) {
                this.getHeightValue(this.reloadCenter);
            }
        });
    } catch (e) {
        runInAction(() => {
            this.loading = false;
            this.visibleWidget = false;
            this.root.addError(e);
        });
    }
}


    @action
    async getRasterIndexValue(sceneId: string, p: LngLat, sceneUiType: SceneUiType): Promise<void>{
        let scrPnt = this.root.map.mapbox.project(p);
        this.x = scrPnt.x;
        this.y = scrPnt.y;

        if (this.loading){
            this.reloadCenter = p;
            this.reloadSceneId = sceneId;
            this.needReload = true;
            return ;
        }
        let needPause = false;
        if (this.needReload){
            needPause = true;
        }
        this.needReload = false;
        this.visibleWidget = true;
        this.loading = true;
        let arg = "";
        let prodCodes: string[] = [];
        let prod = this.root.map.productInfo.activeProductsSet.getActiveCodeByUiType(sceneUiType).currentProductStore;
        if (prod.bandType == "index" || prod.bandType == "fixedIndex") {
            prodCodes.push(this.root.map.productInfo.activeProductsSet.getActiveCodeByUiType(sceneUiType).productCode);
        }else{
            prod.productConfig.bands.forEach(a => {
                prodCodes.push(a);
            });
        }


        let f = {"scene_id":sceneId};
        let url = this.root.indexByPointer.getProdStatUrl()+`?filter=${encodeURIComponent(JSON.stringify(f))}&lat=${encodeURIComponent(p.lat)}&zoom=${Math.ceil(this.root.map.zoom)}&lon=${encodeURIComponent(p.lng)}&mask=valid&product=${encodeURIComponent(prodCodes.join(","))}`;
        try {
            if (needPause) await Utils.pauseAsync(500);
            let r = await fetchJsonGet(url);
            runInAction(()=>{
                if (r.length == 0 || !r[0].stat){
                    this.loading = false;
                    this.visibleWidget = false;
                    return;
                }

                let obj = r[0].stat;
                let vals: string[] = [];
                prodCodes.forEach(a => {
                    if (obj[a] && obj[a].avg) {
                        if (SearchStore.is_S5_SeceneId(sceneId)){
                            vals.push(obj[a].avg.toExponential(3));
                        }else
                            vals.push(obj[a].avg);
                    }
                });
                /*
                if (prod.bandType == "fixedIndex" && vals.length == 1){
                    let idxVal = Utils.parseInt(vals[0]);
                    let arr = prod.productConfig.fixedIndexColors;
                    if (idxVal >= 0 && idxVal < arr.length){
                        this.value =  TranslateUtils.getTranslateFromDic(arr[idxVal].title, this.root.getLang());;
                    }else this.value = vals.join(", ");
                }else{
                    this.value = vals.join(", ");
                }*/
                this.value = vals.join(", ");


                //this.visibleWidget = true;
                this.loading = false;
                if (this.needReload){
                    this.getRasterIndexValue(this.reloadSceneId, this.reloadCenter, sceneUiType);
                }
            });
        }
        catch(e){
            runInAction(()=>{
                this.loading = false;
                this.visibleWidget = false;
                this.root.addError(e);
            });
        }
    }
}