import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoCrossSvgProps {
    className?: string
}

export class AgroAhoCrossSvg extends React.PureComponent<IAgroAhoCrossSvgProps, undefined> {
    constructor(props: IAgroAhoCrossSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.35 0.65C7.15 0.45 6.85 0.45 6.65 0.65L4 3.3L1.35 0.65C1.15 0.45 0.85 0.45 0.65 0.65C0.45 0.85 0.45 1.15 0.65 1.35L3.3 4L0.65 6.65C0.45 6.85 0.45 7.15 0.65 7.35C0.75 7.45 0.85 7.5 1 7.5C1.15 7.5 1.25 7.45 1.35 7.35L4 4.7L6.65 7.35C6.75 7.45 6.9 7.5 7 7.5C7.1 7.5 7.25 7.45 7.35 7.35C7.55 7.15 7.55 6.85 7.35 6.65L4.7 4L7.35 1.35C7.55 1.15 7.55 0.85 7.35 0.65Z" fill="#262B32"/>
        </svg>;
    }
}