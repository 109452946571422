import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {Polarization} from "../../../../../store/indeByPoint/IndexByPointerStore";
import './IndexByPointPopupSettingsRadarComp.scss';
import {action} from "mobx";
import {RadioButton} from "../../../../Common/RadioButton";
import {InputNumber} from "../../../../Common/InputNumber";
import classNames from "classnames";

export interface IIndexByPointPopupSettingsRadarCompProps extends IStoreProps{
    radius: string;
    onChangeRadiusStr: (radius: string)=>any;
    onChangeRadiusNum?: (radius: number)=>any;
    polar: Polarization;
    onChangePolarization: (p: Polarization)=>any;
    polarItems: Polarization[];
    autoSubmit?: boolean,
}

export class IndexByPointPopupSettingsRadarComp extends React.PureComponent<IIndexByPointPopupSettingsRadarCompProps, undefined> {
    constructor(props: IIndexByPointPopupSettingsRadarCompProps) {
        super(props);
        autoBindReact(this);
    }
    @action onChangeRadiusStr(str: string){
        this.props.onChangeRadiusStr(str);
    }
    @action onChangeRadiusNum(num: number){
        if (this.props.onChangeRadiusNum) this.props.onChangeRadiusNum(num);
    }

    onKeyOnlyNums(event: any) {
        if (!/([0-9])/g.test(event.key)) {
            event.preventDefault();
        }
    }
    onClickPolarItem(event: any, data: any){
        this.props.onChangePolarization(data);
    }

    render() {
        let store = this.props.store;
        let polItems: any[] = this.props.polarItems.map(a => <RadioButton checked={a == this.props.polar} onClick={this.onClickPolarItem} data={a} key={a} >
            <span className="IndexByPointPopupSettingsRadarComp-polarItemLabel">{a}</span>
        </RadioButton> );

        return <div className="IndexByPointPopupSettingsRadarComp-main">
            <div className="IndexByPointPopupSettingsRadarComp-row">
                <div className="IndexByPointPopupSettingsRadarComp-radius">
                    <div className="IndexByPointPopupSettingsRadarComp-radiusLabel">{store.trans["Radius (m)"]}</div>
                    <div className="IndexByPointPopupSettingsRadarComp-radiusInput">
                        <InputNumber maxLength={2} pattern="[0-9]{,3}" placeholder={store.trans["< 100m"]}
                                     className={classNames("text-box-editor width100 number-without-arrow",
                                         {"blue-border-onfocus": this.props.autoSubmit??false})} max={99} min={0}
                                     changeOnExitOrEnter={this.props.autoSubmit??false}
                                     value={this.props.radius} onChange={this.onChangeRadiusStr} onChangeNumber={this.onChangeRadiusNum} />
                    </div>
                </div>
                <div className="IndexByPointPopupSettingsRadarComp-polar">
                    <div className="IndexByPointPopupSettingsRadarComp-polarLabel">
                        {store.trans["Polarization type"]}
                    </div>
                    <div className="IndexByPointPopupSettingsRadarComp-polarItems">
                        {polItems}
                    </div>
                </div>
            </div>
            <div className="IndexByPointPopupSettingsRadarComp-divider" />
        </div>;
    }
}
