// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FieldsDelineation-separateLine {
  margin-top: 20px;
}

.FieldsDelineation-or {
  color: #6A7886;
}

.FieldsDelineation-activeYear {
  color: #4DB6BC;
}

.FieldsDelineation-passiveYear {
  cursor: pointer;
}

.FieldsDelineation-bottomBlueLine {
  margin-top: 3px;
  height: 3px;
  background: #4DB6BC;
  border-radius: 3px;
}

.FieldsDelineation-bottomHiddenLine {
  margin-top: 3px;
  height: 3px;
  background: transparent;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Export2Panel/FieldsDelineation.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AAFF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,eAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AACA;EACE,eAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;AAEF","sourcesContent":[".FieldsDelineation-main{\r\n\r\n}\r\n.FieldsDelineation-separateLine{\r\n  margin-top: 20px;\r\n}\r\n.FieldsDelineation-or {\r\n  color: #6A7886;\r\n}\r\n\r\n.FieldsDelineation-activeYear{\r\n  color: #4DB6BC;\r\n}\r\n.FieldsDelineation-passiveYear{\r\n  cursor: pointer;\r\n}\r\n.FieldsDelineation-bottomBlueLine{\r\n  margin-top: 3px;\r\n  height: 3px;\r\n  background: #4DB6BC;\r\n  border-radius: 3px;\r\n}\r\n.FieldsDelineation-bottomHiddenLine{\r\n  margin-top: 3px;\r\n  height: 3px;\r\n  background: transparent;\r\n  border-radius: 3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
