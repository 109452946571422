// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rulerInfo-outside {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  cursor: none;
}

.rulerInfo {
  pointer-events: none;
  left: -50%;
  position: relative;
  background-color: rgba(43, 49, 56, 0.7803921569);
  border: solid 1px #3E4751;
  border-radius: 6px;
  padding: 1px 7px;
  color: #c5c5c5;
  white-space: nowrap;
  cursor: inherit;
}

.rulerInfo-close {
  cursor: pointer;
  pointer-events: auto;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Map/RulerInfo.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,oBAAA;EACA,YAAA;AACF;;AACA;EACE,oBAAA;EACA,UAAA;EACA,kBAAA;EACA,gDAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;AAEF;;AAAA;EACE,eAAA;EACA,oBAAA;EACA,gBAAA;AAGF","sourcesContent":[".rulerInfo-outside{\n  position: absolute;\n  z-index: 2;\n  pointer-events: none;\n  cursor: none;\n}\n.rulerInfo{\n  pointer-events: none;\n  left: -50%;\n  position: relative;\n  background-color: #2b3138c7;\n  border: solid 1px #3E4751;\n  border-radius: 6px;\n  padding: 1px 7px;\n  color: #c5c5c5;\n  white-space: nowrap;\n  cursor: inherit;\n}\n.rulerInfo-close{\n  cursor: pointer;\n  pointer-events: auto;\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
