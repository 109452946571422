import * as React from "react";
import {IAgroPanelComp} from "../ProjectEditor/AgroPanelComp";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2NavigatorItemStore} from "../../../../../store/agro/A2NavigatorItemStore";
import {A2NavigatorSection} from "../../../../../store/agro/A2AgroStore";
import {A2NavigatorItemComp} from "./A2NavigatorItemComp";
import classNames from "classnames";
import {observer} from "mobx-react";

export interface IA2NavigatorItemListComp extends IStoreProps{
    activeLinks?: boolean;//default=true
}
@observer
export class A2NavigatorItemListComp extends React.Component<IA2NavigatorItemListComp, undefined> {
    render(){
        let store = this.props.store;
        let items: any[] = [];
        let t: A2NavigatorItemStore = store.agro2.projectStruct.navigatorRootItem;
        let isProjectList = store.agro2.navigatorSection == A2NavigatorSection.projects;// store.agro2.projectStruct.allNavigatorItems.find(a => a.activePage) == null;
        let wasInProject = store.agro2.projectStruct.allNavigatorItems.find(a => a.activePage) != null;
        let wasActive = isProjectList;
        if (t != null && t.parentItem == null){
            if (store.agro.projectName != null) {
                let text = store.agro.projectTitle;
                let active = (store.agro2.projectStruct.navigatorRootItem != null && store.agro2.projectStruct.navigatorRootItem.activePage
                    && store.agro2.navigatorSection == A2NavigatorSection.folder);
                if (active) wasActive = true;
                let dis = !active && wasActive;
                items.push(<A2NavigatorItemComp store={store} key={"projects"} first={true} text={text}
                                                active={active} disabled={!active && wasActive}
                                                activeLink={this.props.activeLinks ?? true}
                                                section={A2NavigatorSection.projects} bold={true}/>);
            }
        }
        while (t != null){
            if (t.nextItem == null) break;
            let active = false;
            if (t.nextItem != null) active = t.nextItem.activePage && store.agro2.navigatorSection == A2NavigatorSection.folder;
            let text = t.visible_columnValue();
            if (active) wasActive = true;
            let hint = "";
            if (t.columnInfo != null) hint = t.columnInfo.title;
            let dis = !active && wasActive;
            items.push(<A2NavigatorItemComp navItemStore={t} store={store} key={t.id_column} first={false}
                                            hint={hint}
                                            section={A2NavigatorSection.folder} disabled={dis}
                                            activeLink={this.props.activeLinks ?? true}
                                            text={text} active={active}/>);
            t = t.nextItem;
        }
        return <div className={classNames("A2NavigatorComp-child", this.props.className)}>
            {items}{" "}
        </div>;
    }
}
