import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './IndexByPointPopupSettingsVegetComp.scss';
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {Satellite} from "../../../../../helper/utils/satellliteDic";
import {intersection} from "lodash-es";
import {InputNumber} from "../../../../Common/InputNumber";
import classNames from "classnames";

export interface IIndexByPointPopupSettingsVegetComp extends IStoreProps{
    radius: string;
    onChangeRadiusStr: (radius: string)=>any;
    onChangeRadiusNum?: (radius: number)=>any;
    index: string;
    onChangeIndex: (index: string)=>any;
    satellites: string[];
    onChangeSatellites: (index: Satellite[])=>any;
    autoSubmit?: boolean,

}
enum SatelliteSet {
    Landsat = "Landsat 8-9",
    Sentinel = "Sentinel-2",
    MultiSattelite = "MultiLandsat",
}

@observer
export class IndexByPointPopupSettingsVegetComp extends React.Component<IIndexByPointPopupSettingsVegetComp, undefined> {
    constructor(props: IIndexByPointPopupSettingsVegetComp) {
        super(props);
        autoBindReact(this);
    }
    @action onChangeRadiusStr(str: string){
        this.props.onChangeRadiusStr(str);
    }
    @action onChangeRadiusNum(num: number){
        if (this.props.onChangeRadiusNum) this.props.onChangeRadiusNum(num);
    }


    onKeyOnlyNums(event: any) {
        if (!/([0-9])/g.test(event.key)) {
            event.preventDefault();
        }
    }

    onChangeSatellite(item: IDropDownItem){
        if (item.key == SatelliteSet.MultiSattelite){
            this.props.onChangeSatellites([Satellite["Sentinel-2"], Satellite["Landsat-8"], Satellite["Landsat-9"]]);
        }
        if (item.key == SatelliteSet.Sentinel){
            this.props.onChangeSatellites([Satellite["Sentinel-2"]]);
        }
        if (item.key == SatelliteSet.Landsat){
            this.props.onChangeSatellites([Satellite["Landsat-8"], Satellite["Landsat-9"]]);
        }
    }
    render() {
        let store = this.props.store;

        let allProducts: string[][] = [];


        if (store.config?.ordered_products[Satellite["Landsat-8"]]){
            allProducts.push(store.config?.ordered_products[Satellite["Landsat-8"]].indexes);
        }
        if (store.config?.ordered_products[Satellite["Landsat-9"]]){
            allProducts.push(store.config?.ordered_products[Satellite["Landsat-9"]].indexes);
        }
        if (store.config?.ordered_products[Satellite["Sentinel-2"]]){
            allProducts.push(store.config?.ordered_products[Satellite["Sentinel-2"]].indexes);
        }
        let products: string[] = intersection(...allProducts);
        let indexItems: IDropDownItem[] = products.map(a => {
                let t: IDropDownItem = {key: a, data: a, value: a};
                return t;
            }
        );
        let satellitesItems: IDropDownItem[] = [
            {key: SatelliteSet.Landsat, value: "Landsat 8-9"},
            {key: SatelliteSet.Sentinel, value: "Sentinel-2"},
            {key: SatelliteSet.MultiSattelite, value: "Landsat & Sentinel"},
        ];
        let curSatItem: string = null;
        if (this.props.satellites.find(a => a == Satellite["Sentinel-2"]))
            curSatItem = SatelliteSet.Sentinel;
        if (this.props.satellites.find(a => a == Satellite["Landsat-8"]) && this.props.satellites.find(a => a == Satellite["Landsat-9"]))
            curSatItem = SatelliteSet.Landsat;
        if (this.props.satellites.find(a => a == Satellite["Landsat-8"]) && this.props.satellites.find(a => a == Satellite["Landsat-9"])  && this.props.satellites.find(a => a == Satellite["Sentinel-2"]))
            curSatItem = SatelliteSet.MultiSattelite;


        return <div className="IndexByPointPopupSettingsComp-nextDiv">

                <div className="IndexByPointPopupSettingsComp-title">{store.trans.Products}</div>
                <div className="IndexByPointPopupSettingsComp-nextDiv">
                    <DropDownComp items={indexItems} currentKey={this.props.index}
                                  onChange={((item, newIndex) => {this.props.onChangeIndex(item.data)})}
                    />
                </div>
                <div className="flex-columns IndexByPointPopupSettingsComp-nextDiv">
                    <div className="flex-stretch-item">
                        <div className="IndexByPointPopupSettingsComp-title">{store.trans["Radius (m)"]}</div>
                        <div className="IndexByPointPopupSettingsComp-rightPad">
                            <InputNumber maxLength={2} pattern="[0-9]{,3}" placeholder={store.trans["< 100m"]}
                                         className={classNames("text-box-editor width100 number-without-arrow",
                                             {"blue-border-onfocus": this.props.autoSubmit??false})}
                                         onChangeNumber={this.onChangeRadiusNum} max={99} min={0}
                                         changeOnExitOrEnter={this.props.autoSubmit??false}
                                         value={this.props.radius} onChange={this.onChangeRadiusStr}/>
                        </div>
                    </div>
                    <div className="" style={{flexBasis: "215px"}}>
                        <div className="IndexByPointPopupSettingsComp-title">{store.trans["Satellites"]}</div>
                        <div className="">
                            <DropDownComp items={satellitesItems} currentKey={curSatItem} onChange={this.onChangeSatellite}/>
                        </div>
                    </div>
                </div>
        </div>;
    }
}
