import * as React from 'react';
import * as ReactDOM from "react-dom";
import {SuperStore} from "./store/SuperStore";
import {WarningComp} from "./components/panels/Map/WarningComp";
import {MapPageComp} from "./components/MapPageComp";
import {Utils} from "./helper/utils/Utils";
import {isString} from "lodash-es";
import {Route, Router} from "react-router";
import {ForgotPassComp} from "./components/panels/User/ForgotPassComp";
import {ConfirmationFormComp, ModeConfirmationForm} from "./components/panels/User/ConfirmationFormComp";
import {SignInPanelComp} from "./components/panels/User/SignInPanelComp";
import {SignUpFormComp} from "./components/panels/User/SignUpFormComp";
import {InfoComp} from "./components/panels/Map/InfoComp";
import {NewPassComp} from "./components/panels/User/NewPassComp";
import {LastStackRootManagerComp} from "./components/Common/LastStackRootManagerComp";
import {DebugPanelComp} from "./components/panels/Map/DebugPanelComp";

export class GlobalRender {
    static render(store: SuperStore, callback: ()=>void = null):void{
//        {(store.user.authFormShow || !store.user.isAuth) && }

        let tags = (
            <div className="body flex-columns" >
                <Router history={store.history}>
                    <Route path={["/","/index.html"]} exact={true}>
                        <MapPageComp store={store} />
                    </Route>
                    <Route path={["/signup", "/signup.html"]}>
                        <SignUpFormComp store={store} />
                    </Route>
                    <Route path={["/signin", "/signin.html"]}>
                        <SignInPanelComp store={store} />
                    </Route>
                    <Route path={["/forgot", "/forgot.html"]}>
                        <ForgotPassComp store={store} />
                    </Route>
                    <Route path={["/newPass","newPass.html"]}>
                        <NewPassComp store={store} />
                    </Route>
                    <Route path={["/confirmEmail","/confirmEmail.html"]}>
                        <ConfirmationFormComp store={store} mode={ModeConfirmationForm.clickInnerEmail} />
                    </Route>
                    <Route path={["/sendedEmail","/sendedEmail.html"]}>
                        <ConfirmationFormComp store={store} mode={ModeConfirmationForm.sendedEmail} />
                    </Route>
                </Router>
                <LastStackRootManagerComp store={store} />
                <InfoComp store={store} />
                <WarningComp store={store} />
                <DebugPanelComp store={store} />
                <div></div>
            </div>
        );


        ReactDOM.render(tags, document.getElementById('mainContent'), callback);

        GlobalRender.addTestTools(store);
    }

    static addTestTools(store: SuperStore){
        (window as any).replaceMapStyle = (st: any)=>{
            if (isString(st)) st = JSON.parse(st);
            if (!isString(st.id)) console.error("Свойство id не найдено");
            let id = st.id;
            let json = store.map.mapbox.getLayer(id);
            store.map.mapbox.removeLayer(id);
            store.map.mapbox.addLayer(st, null);
        }
        (window as any).gotoUrl = (st: any)=>{
            store.history.push(st);
        }
    }

    static renderCriticalError(err: any){
        let s = Utils.getErrorString(err);
        console.error(err);
        let tags = <div style={{color: "red"}}>{s}</div>
        ReactDOM.render(tags, document.getElementById('mainContent'));
    }
    //static replaceMapStyle
}