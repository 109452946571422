import * as mapboxgl from "maplibre-gl";
import maplibregl, {LngLat, MapMouseEvent} from "maplibre-gl";
import {
    AddrContour,
    AddrPoint,
    GeometryUtils,
    ISimpleGeometry,
    SimpleGeometryType
} from "../../../helper/utils/GeometryUtils";
import {ContainerTools, ContainerToolsState, CreateGeometryType, DrawPointType} from "../general/ContainerTools";
import {CustomEditGeometryTool} from "./CustomEditGeometryTool";
import {Position} from "geojson";
import {MBUtils} from "../../../helper/utils/MBUtils";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import {GActionTransactionType} from "../../../helper/geometryAction/IGeometryAction";
import {MoveMapQuickTool} from "../mapCommon/MoveMapQuickTool";
import {MoveMapTool} from "../mapCommon/MoveMapTool";
import {GeometryActionTransaction} from "../../../helper/geometryAction/ActionManager";
import { action } from "mobx";

export class CreatePolygonDrawTool extends CustomEditGeometryTool {

    constructor(container: ContainerTools, state: ContainerToolsState, name: string) {
        super(container, state, name);
        let t = new MoveMapTool(container, name+"+movemap");
        t.isActive = ()=>{
            return this.isActive();
        }
        this.beforeSubTools.push(t);
    }
    protected isActive(){
        return this.state?.events?.isCreateGeometry && this.state.events.isCreateGeometry();
    }
    onMouseDown(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (this.isActive()) {
            this.state.lastMouseScrCoord = MBUtils.pointToPosition(e.point);
        }
    }
    
    cancelRightClick : boolean = false;
    
    @action
    onMouseClick(e: MapMouseEvent & ToolEvent): void {
        if (this.cancelRightClick)
            e.noPropagation();
    }

    @action
    onMouseUp(e: MapMouseEvent & ToolEvent): void {
        this.cancelRightClick = false;
        if (this.isActive()) {
            this.state.lastMouseScrCoord = MBUtils.pointToPosition(e.point);
            if (e.dragging) return;

            let rightButton = (e.originalEvent.button & 2) != 0;
            if (rightButton) {
                if (!this.callRightClickCreate(e)){
                    e.noPropagation();
                    this.cancelRightClick = true;
                    return;
                }
            }

            let gia = this.state.simpleGeometry;
            let oclick = this.getObjectByClick(e.point);
            if (this.state.curAddrContour != null && oclick.pointType == DrawPointType.vertex) {
                let gi = GeometryUtils.getSimpleGeometryByAddr(gia, this.state.curAddrContour);
                if (gi != null) {
                    let addrPoint = oclick.pointIndex;
                    let red_point = (addrPoint[2] == 0 && GeometryUtils.isEqualAddrContour(this.state.curAddrContour, GeometryUtils.getAddrContourByAddrPoint(addrPoint)));
                    if (red_point) {
                        if (!this.callClickFirstPointPolygon(e)) return;
                    }
                }
            }

            let tr: GeometryActionTransaction = null
            if (this.state.createGeometryType != null) {
                e.noPropagation();
                let addrContour: AddrContour;
                if (this.state.events.onBeforeCreateGeometry) this.state.events.onBeforeCreateGeometry(e);
                gia = this.state.simpleGeometry;

                tr = this.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.insertPoint);
                if (this.state.createGeometryType == CreateGeometryType.Rectangle){
                    addrContour = CreatePolygonDrawTool.doCreateGeometry(this.state.createGeometryType, gia, tr);
                    this.state.curAddrContour = addrContour;
                    let pos = MBUtils.llToPosition(e.lngLat);
                    this.doInsertPoint(pos, this.state.curAddrContour, gia, tr);
                    this.state.createGeometryType = CreateGeometryType.RectangleSecondPoint;
                    CreatePolygonDrawTool.updateCursorLines(this.state, MBUtils.llToPosition(e.lngLat), gia);
                    this.callChange();
                    this.updateGeometry();
                    return;
                }
                if (this.state.createGeometryType == CreateGeometryType.RectangleSecondPoint){//вторая точка
                    this.state.createGeometryType = null;
                    let pos = MBUtils.llToPosition(e.lngLat);
                    this.doInsertRectangle(pos, this.state.curAddrContour, gia, tr);
                    this.state.createGeometryType = null;
                    this.state.resetMovedPoints();
                    this.callChange();
                    this.updateGeometry();
                    this.callClickFirstPointPolygon(e);
                    return;
                }

                if (this.state.createGeometryType == CreateGeometryType.Hole) {
                    addrContour = this.getAddrContourForCreateHole(e.lngLat)
                    if (addrContour == null) return;
                    addrContour = CreatePolygonDrawTool.doCreateGeometry(this.state.createGeometryType, gia, tr, addrContour);
                } else {
                    addrContour = CreatePolygonDrawTool.doCreateGeometry(this.state.createGeometryType, gia, tr);
                }
                this.state.curAddrContour = addrContour;
                this.state.createGeometryType = null;

            }
            if (this.state.curAddrContour != null) {
                e.noPropagation();
                gia = this.state.simpleGeometry;
                let pos = MBUtils.llToPosition(e.lngLat);
                this.doInsertPoint(pos, this.state.curAddrContour, gia, tr);
                this.callChange();
            }
            this.updateGeometry();
            CreatePolygonDrawTool.updateCursorLines(this.state, MBUtils.llToPosition(e.lngLat), gia);
        }
    }
    isCtrlPressed(e: mapboxgl.MapMouseEvent): boolean{
        return e.originalEvent.ctrlKey || !!(e.originalEvent.buttons & 4) == true;
    }




    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent) {
        if (this.isActive()) {
            if (this.state.events.onMouseMove) this.state.events.onMouseMove(e);
            this.state.lastMouseScrCoord = MBUtils.pointToPosition(e.point);
            if (e.cursor == null || e.cursor == "default") {
                if (this.canCreateGeometry(e.lngLat)) {
                    e.cursor = `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.66528 8.44168L8.51621 8.51621L8.44168 8.66528L5.42595 14.6967L0.790569 0.790569L14.6967 5.42595L8.66528 8.44168Z' fill='white' stroke='black'/%3E%3C/svg%3E%0A") 1 1, pointer`;
                } else e.cursor = "default";
            }
            //e.cursor = "default";
            let gia = this.state.simpleGeometry;
            let oclick = this.getObjectByClick(e.point);
            if (this.state.curAddrContour != null && oclick.pointType == DrawPointType.vertex) {
                let gi = GeometryUtils.getSimpleGeometryByAddr(gia, this.state.curAddrContour);
                if (gi != null && gi.simple == SimpleGeometryType.Polygon) {
                    let addrPoint = oclick.pointIndex;
                    let red_point = (gi.simple == SimpleGeometryType.Polygon && addrPoint[2] == 0 && GeometryUtils.isEqualAddrContour(this.state.curAddrContour, GeometryUtils.getAddrContourByAddrPoint(addrPoint)));
                    if (red_point) {
                        e.cursor = "pointer";
                    }
                }
            }

            this.state.movedPointCoord = MBUtils.llToPosition(e.lngLat);

            CreatePolygonDrawTool.updateCursorLines(this.state, MBUtils.llToPosition(e.lngLat), gia);
            this.updateMovedPoint();
        }
    }

    canCreateGeometry(latLon: LngLat): boolean{
        if (this.state.createGeometryType == CreateGeometryType.Hole){
            let c = this.getAddrContourForCreateHole(latLon);
            if (c == null) return false;
        }
        return true;
    }

    getAddrContourForCreateHole(latLon: LngLat): AddrContour{
        let gia = this.state.simpleGeometry;
        let addrContour: AddrContour;
        if (this.state.createGeometryType == CreateGeometryType.Hole) {
            addrContour = GeometryUtils.getAddrContourByPoint(latLon, gia);
            if (addrContour == null) {
                return null;
            }
            let sg = GeometryUtils.getSimpleGeometryByAddr(gia, addrContour);
            if ((addrContour[1] == 0) && sg.simple == SimpleGeometryType.Polygon) return addrContour;
        }
        return null;
    }


    public static doCreateGeometry(gt: CreateGeometryType, gia: ISimpleGeometry[], tr: GeometryActionTransaction, addrContour: AddrContour = null): AddrContour{
        let sg: ISimpleGeometry = null;
        if (gt == CreateGeometryType.Rectangle) {
            sg = {simple: SimpleGeometryType.Polygon, contour: [], holes: []};
            tr.insertGeometry(gia.length, sg);
        }
        if (gt == CreateGeometryType.Point) {
            sg = {simple: SimpleGeometryType.Point, contour: [], holes: []};
            tr.insertGeometry(gia.length, sg);
        }
        if (gt == CreateGeometryType.Polygon) {
            sg = {simple: SimpleGeometryType.Polygon, contour: [], holes: []};
            tr.insertGeometry(gia.length, sg);
        }
        if (gt == CreateGeometryType.Line) {
            sg = {simple: SimpleGeometryType.Line, contour: [], holes: []};
            tr.insertGeometry(gia.length, sg);
        }
        if (gt == CreateGeometryType.Hole) {
            let g = GeometryUtils.getSimpleGeometryByAddr(gia, addrContour);
            if (g == null) throw "Geometry is not found";

            if (g.holes == null) g.holes = [];
            let a:AddrContour = [addrContour[0], g.holes.length + 1];
            tr.insertHole(a, []);
            return a;
        }
        if (sg != null){
            return [gia.length - 1, 0];
        }else throw "Unknow geometry type";
    }

    public static updateCursorLines(state: ContainerToolsState, mouseGeoPos: Position, gia: ISimpleGeometry[]){
        if (state.curAddrContour == null) return;
        let g = GeometryUtils.getSimpleGeometryByAddr(gia, state.curAddrContour);
        if (g == null) return;

        if (g.simple == SimpleGeometryType.Polygon){
            let addrLast = GeometryUtils.getLastPointAddr(gia, state.curAddrContour);
            let addrFirst = GeometryUtils.getFirstPointAddr(gia, state.curAddrContour);

            if (addrFirst != null && addrLast != null && !GeometryUtils.isEqualAddrPoint(addrFirst, addrLast)) state.movedNextPointCoord = GeometryUtils.getGeometryPoint2(gia, addrFirst); else state.movedNextPointCoord = null;
            state.movedPointCoord = mouseGeoPos;
            if (addrLast != null) state.movedPrevPointCoord = GeometryUtils.getGeometryPoint2(gia, addrLast); else state.movedPrevPointCoord = null;
        }else
        if (g.simple == SimpleGeometryType.Line){
            let addrLast = GeometryUtils.getLastPointAddr(gia, state.curAddrContour);
            state.movedNextPointCoord = null;
            state.movedPointCoord = mouseGeoPos;
            if (addrLast != null)
                state.movedPrevPointCoord = GeometryUtils.getGeometryPoint2(gia, addrLast); else state.movedPrevPointCoord = null;
        }else{
            state.movedNextPointCoord = null;
            state.movedPointCoord = null;
            state.movedPrevPointCoord = null;
        }
    }

    public doInsertPoint(pos: Position, addrContour: AddrContour, gia: ISimpleGeometry[], tr: GeometryActionTransaction = null){
        let curPointAddr: AddrPoint;
        if (tr == null) tr = this.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.insertPoint);
        let pointsArr = GeometryUtils.getContourPoints(gia, addrContour);
        curPointAddr = GeometryUtils.getAddrPointByAddrContour(addrContour);
        curPointAddr[2] = pointsArr.length;
        tr.insertPoint(curPointAddr, pos);
        //GeometryUtils.insertGeometryPoint2(gia, curPointAddr, pos);
    }
    public doInsertRectangle(pos: Position, addrContour: AddrContour, gia: ISimpleGeometry[], tr: GeometryActionTransaction){
        let curPointAddr: AddrPoint;
        let pointsArr = GeometryUtils.getContourPoints(gia, addrContour);
        curPointAddr = GeometryUtils.getAddrPointByAddrContour(addrContour);

        let firstP = GeometryUtils.getGeometryPoint2(gia, curPointAddr);
        curPointAddr[2] = pointsArr.length;
        tr.insertPoint(curPointAddr, [pos[0], firstP[1]]);
        tr.insertPoint(curPointAddr, pos);
        tr.insertPoint(curPointAddr, [firstP[0], pos[1]]);
        tr.insertPoint(curPointAddr, [firstP[0], firstP[1]]);
        //GeometryUtils.insertGeometryPoint2(gia, curPointAddr, pos);
    }

}