import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {fetchJsonPost} from "../../helper/utils/FetchUtils";

export class SignUpFormStore extends CustomStore{
    @observable
    firstName: string = '';
    @observable
    firstNameUpdated: boolean = false;
    @observable
    secondName: string = '';
    @observable
    secondNameUpdated: boolean = false;
    @observable
    email: string = '';
    @observable
    emailUpdated: boolean = false;
    @observable
    emailFocused: boolean = false;
    @observable
    password: string = '';
    @observable
    passwordShow: boolean = false;
    @observable
    passwordUpdated: boolean = false;
    @observable
    password2: string = '';
    @observable
    password2Updated: boolean = false;
    @observable
    tryingSubmit: boolean = false;//была неудачная попытка отправки формы


    public resetForm(){
        this.tryingSubmit = false;
        this.firstName = '';
        this.firstNameUpdated = false;
        this.secondName = '';
        this.secondNameUpdated = false;
        this.email = '';
        this.emailUpdated = false;
        this.password = '';
        this.passwordUpdated = false;
        this.password2 = '';
        this.password2Updated = false;
    }
    async sendRegistration(){
        let url = `/api/auth/register`;
        let arg = {email: this.email, pwd: this.password, given_name: this.firstName, surname: this.secondName};
        await fetchJsonPost(url, arg);
    }
}