import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";

export interface IAgroAhoFieldSvgProps {
    className?: string;
}

export class AgroAhoFieldSvg extends React.PureComponent<IAgroAhoFieldSvgProps, undefined> {
    constructor(props: IAgroAhoFieldSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.787 5.09057C24.712 5.03803 24.6241 5.00707 24.5328 5.00107C24.4414 4.99508 24.3502 5.01427 24.269 5.05657C24.1878 5.09886 24.1198 5.16263 24.0724 5.24093C24.0249 5.31922 23.9999 5.40903 24 5.50057V12.5006C24.0002 12.592 24.0254 12.6817 24.073 12.7598C24.1207 12.8379 24.1888 12.9015 24.27 12.9436C24.3409 12.981 24.4198 13.0006 24.5 13.0006C24.6026 13.0007 24.7028 12.9693 24.787 12.9106L29.787 9.41057C29.853 9.36449 29.9069 9.30316 29.9442 9.23177C29.9814 9.16039 30.0008 9.08108 30.0008 9.00057C30.0008 8.92007 29.9814 8.84075 29.9442 8.76937C29.9069 8.69799 29.853 8.63665 29.787 8.59057L24.787 5.09057Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34314 0 0 1.34314 0 3V8.0998C3.06946 8.7942 9.214 11.5644 13.7755 18H15C16.6569 18 18 16.6569 18 15V14.7526C17.4784 14.7054 16.9984 14.6889 16.559 14.6964C14.4315 14.7328 13.3487 15.3271 13.0269 15.6457L11.6199 14.2244C12.4489 13.4036 14.1227 12.7378 16.5247 12.6967C16.9858 12.6888 17.4774 12.7036 18 12.7454V11.9208C16.8232 11.8682 15.8039 11.9211 14.9325 12.0411C12.5275 12.3723 11.3371 13.1977 10.9782 13.6433L9.4206 12.3887C10.2206 11.3955 11.9804 10.4288 14.6596 10.0598C15.6361 9.9253 16.7472 9.8678 18 9.9189V9.1164C12.3169 9.0098 9.5024 10.9066 8.7997 11.8425L7.2003 10.6418C8.4129 9.0266 11.9029 7.016 18 7.1193V3C18 1.34314 16.6569 0 15 0H3ZM0 10.8535V10.1605C2.58255 10.8633 7.3734 13.1192 11.2731 18H10.256C6.80859 13.4182 2.59555 11.4504 0 10.8535ZM7.6787 18C4.95227 14.9062 1.94382 13.462 0 12.9168V13.5646C1.64514 13.8672 4.16208 15.0633 6.36639 18H7.6787ZM3.7464 18C2.35403 16.5617 0.98828 15.8989 0.0671401 15.6341C0.35812 16.9864 1.5607 18 3 18H3.7464ZM5.5 7C6.88071 7 8 5.88071 8 4.5C8 3.11929 6.88071 2 5.5 2C4.11929 2 3 3.11929 3 4.5C3 5.88071 4.11929 7 5.5 7Z"/>
        </svg>;
    }
}