import * as React from 'react';
import {CustomSvg} from "../CustomSvg";
import { IIconProp } from '../IIconProp';

export class BorderStyleSvg extends CustomSvg {
    render() {
        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18" height="18" fill="#2B3138"/>
        <rect y="14" width="18" height="4" rx="2" fill="#C5C5C5"/>
        <rect y="6" width="18" height="3" rx="1.5" fill="#C5C5C5"/>
        <rect width="18" height="1" rx="0.5" fill="#C5C5C5"/>
        </svg>     
    }
}
