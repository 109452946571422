import {CustomStore} from "./CustomStore";
import {save, update} from "./PermalinkDecor";
import {IntervalStore} from "./IntervalStore";
import {observable} from "mobx";
import {Utils} from "../helper/utils/Utils";

export enum Sentinel1Mode{ IW='IW', EW='EW'}
export class SearchParamsS1Store extends CustomStore{

    @update
    filterDate: IntervalStore = new IntervalStore(this);

    @save @observable
    mode: Sentinel1Mode = Sentinel1Mode.IW;

    @save @observable
    relativeOrbit: string = "";

    isValidRelativeOrbit(): boolean{
        let v = this.getRealativeOrbitNumbers();
        return (v != null);
    }
    //Орбиты в виде массива, null в случае ошибки
    getRealativeOrbitNumbers(): number[]{
        let arrStr = this.relativeOrbit.split(",").map(a => a.trim());
        if (arrStr.length == 1 && arrStr[0] == "") return [];
        let arr = arrStr.map(a => Utils.parseInt(a));
        if (arr.filter(a => a == null || a < 1 || a > 175).length > 0) return null;
        return arr;
    }
}