import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {observable} from "mobx";

export class AdaptiveStyleProductStore extends CustomStore{
    class(): string {
        return "AdaptiveStyleProductStore";
    }
    @save @observable
    std_m: number;

    @save @observable
    mask: string;
}