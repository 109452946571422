import {action, autorun, computed, observable} from "mobx";
import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {LeftPanelMode, SceneUiType} from "../SearchStore";
import {ProductSettingStore} from "../productSetting/ProductSettingStore";
import {save} from "../PermalinkDecor";
import {IReactionDisposer} from "mobx/lib/internal";
import intersect from "@turf/intersect";
import { Utils } from "../../helper/utils/Utils";
import { Polygon } from "@turf/helpers";
import { MultiPolygon } from "geojson";
import { ActiveProductCodeStore } from "../productSetting/ActiveProductCodeStore";

export class LegendStore extends ObservableCustomStore{
    @save @observable legendMinimize: boolean = false;

    @observable
    private _invisibleCategories: Set<any> = new Set<any>();

    @action
    clearVisibleCategories(){
        this._invisibleCategories.clear();
    }

    isVisibleCategories(categoryValue: any): boolean{
        return !this._invisibleCategories.has(categoryValue);
    }
    @action
    setVisibleCategories(categoryValue: any, visible: boolean){
        if (visible) this._invisibleCategories.delete(categoryValue)
        else this._invisibleCategories.add(categoryValue);
        this.root.agro2.fieldToolController.fieldLayerTool.reInstallLayers();
    }
    @computed
    get legendNdviShow(): boolean{
        //let store = this.root;
        let sp = this.root.searchPanel;
        let pp = this.root.map.productInfo.productsPassive;
        let lpm = sp.leftPanelMode;
        if (!(
            lpm == LeftPanelMode.agro ||
            lpm == LeftPanelMode.photo ||
            lpm == LeftPanelMode.search ||
            lpm == LeftPanelMode.favorite ||
            lpm == LeftPanelMode.createProject ||
            lpm == LeftPanelMode.editProject ||
            lpm == LeftPanelMode.uploadFields)) return false;
        let topSceneType = sp.top_sceneType;
        // let f1 = store.searchPanel.currentGroup?.first()?.feature?.geometry as Polygon | MultiPolygon;
        // let f2 = store.map.bbox.geoJson;
        // let isInExtent = Utils.hasIntersection(f1, f2);     
        // console.log(isInExtent);
        //console.log(topSceneType);
        if (! topSceneType) return false;
        let bandType = (topSceneType == SceneUiType.favorite
            ? pp.getFavoriteGroupProduct(sp.currentGroup.groupId(true))
            : pp.getActiveCodeByUiType(SceneUiType.current)
        ).currentProductStore.bandType;
        return ["index", "fixedIndex"].indexOf(bandType) >= 0;
        // return ["index", "fixedIndex"].indexOf(this.prodInfo?.prod?.bandType) >= 0;
        // if (this.prodInfo == null) return false;
        // return true;
    }
    get legendLayerShow(): boolean{
        return !this.legendNdviShow && this.root.agro2.agroFieldVisible;
    }


    @computed
    get prodInfo(): {prod: ActiveProductCodeStore}{
        let store = this.root;
        let prod: ActiveProductCodeStore = null;
        if (store.map.productInfo.activeProductsSet == null) return null;
        if (store.map.mapScenesStore.favoriteScenesVisible && 
            (store.searchPanel.top_sceneType == SceneUiType.favorite || !store.searchPanel.currentSceneVisible) &&
            store.searchPanel.favoriteList.getAllSceneItems().filter(a => a.selected).length > 0) {
            //prod = store.map.productInfo.activeProductsSet.activeCodeFavorite.currentProductStore;
            //let ap = sceneType == SceneUiType.current? pi.productsPassive.getActiveCodeByUiType(sceneType):
            prod = store.map.productInfo.activeProductsSet.getFavoriteGroupProduct(store.searchPanel.currentGroup.groupId(true));
            if (this.isVisibleProd(prod.currentProductStore))
                return {prod: prod };
        }
        if (store.searchPanel.currentSceneVisible && store.searchPanel.top_sceneType == SceneUiType.current &&
            store.searchPanel.currentSceneid != null) {
            prod = store.map.productInfo.activeProductsSet.activeCodeCurrent;
            if (this.isVisibleProd(prod.currentProductStore))
                return {prod: prod};
        }
        return null;
    }
    isVisibleProd(prod: ProductSettingStore): boolean{
        return !(prod == null || prod.bandType == "RGB");
    }

}