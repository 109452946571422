import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {LeftAuthPanelComp} from "./LeftAuthPanelComp";
import classNames from "classnames";
import {action, observable} from "mobx";
import {Utils} from "../../../helper/utils/Utils";

@observer
export class ForgotPassComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    sending: boolean = false;

    @action async onSubmit(event: any){
        event.preventDefault();
        //if (this.props.store.user.formAuthStatus == LoadStatus.loading) return false;
        let email = this.props.store.user.forgotForm.email;
        this.sending = true;
        try {
            await this.props.store.user.requestForgot(email);
        }catch (e){
            this.sending = false;
            this.props.store.addError(e);
            return false;
        }

        this.sending = false;
        this.props.store.addInfo(this.props.store.trans["A link to reset your password has been sent to your email."]);
        this.props.store.gotoUrl("/signin");
        return false;
    }
    @action onClickBack(){
        this.props.store.gotoUrl("/signin");
    }
    @action onChangeEmailName(event: any){
        this.props.store.user.forgotForm.email = event.target.value;
    }

    render() {
        let store = this.props.store;
        let form = store.user.forgotForm;
        let emailError: string = null;
        let enableSubmit = !this.sending && Utils.isStringNotEmpty(form.email);

        return <div className="authorize-background">
            <div className="authorize-background2">
                <LeftAuthPanelComp store={store} />
                <div className="authorize-d2 style-4">
                    <form className="authorize-form authorize-form-width" onSubmit={this.onSubmit} autoComplete="off">
                        <div className="UserForm-title-m flex-columns flex-align-items-center UserForm-title-marginTop">
                            <div className="flex-fit-item">{store.trans["Forgot password"]}</div>
                            <div onClick={this.onClickBack} className="pointer">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="19.5" stroke="#595D62"/>
                                    <path d="M31.25 20.75H8.75" stroke="#595D62" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.25 13.25L8.75 20.75L16.25 28.25" stroke="#595D62" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>

                        <div className="UserForms-labelDiv-m">
                            <div className="authorize-in-right__row_label-left">
                                Email
                                <span className="UserForms-Star-m">*</span>
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type="email"
                                   className={classNames("UserForms-inputText-m", {"red_border": emailError})}
                                   onChange={this.onChangeEmailName} value={form.email} />
                            {emailError && <div className="UserForms-errorHint-m">{emailError}</div>}
                        </div>


                        <div className="UserForms-footer-m">
                            <button style={{width:"100%"}} disabled={!enableSubmit}
                                    className={classNames("authorize-submit-form", {"opacity20": !enableSubmit})}
                                    type="submit">{store.trans.Send}</button>
                        </div>
                        <div className="UserForms-descriptionGray-m UserForms-descriptionGray-topMargin">
                            {store.trans["Enter the email address to which we will send you an email with a link to create a new password"]}
                        </div>
                    </form>
                    <div className="authorize-form-zatyk" />
                </div>
            </div>
        </div>;
    }
}
