import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IndexBandProductStore, Interpolation} from "../../../app/store/productSetting/IndexBandProductStore";
import './ProductItemExportComp.scss';
import classNames from "classnames";
import {action, observable} from "mobx";
import {RadioButton} from "../../../app/components/Common/RadioButton";
import {
    IndexBandExportFormat,
    IndexBandExportGeometry,
    IndexBandExportPixel, IndexBandExportStore
} from "../store/IndexBandExportStore";
import {CheckboxMiniComp, CheckboxSize} from "../../../app/components/Common/CheckboxMiniComp";
import {fetchBytesPost, fetchJson} from "../../../app/helper/utils/FetchUtils";
import {ProductItemIndexBandComp} from "../../../app/components/panels/Left/NdviPanel/ProductItemIndexBandComp";
import {ProductSettingStore} from "../../../app/store/productSetting/ProductSettingStore";
import {ra} from "../../../app/helper/utils/mobxUtils";
import {ProductItemExportNormaComp} from "./ProductItemExportNormaComp";
import {Utils} from "../../../app/helper/utils/Utils";
import {IAgroExportStoreProps} from "../agroExportPlugin";
import {DropDownComp, IDropDownItem} from "../../../app/components/Common/DropDownComp";

//index: IndexBandProductStore
export interface IProductItemExportComp extends  IAgroExportStoreProps{
    index: IndexBandProductStore,
    prod: ProductSettingStore,
    exp: IndexBandExportStore
}
@observer
export class ProductItemExportComp extends React.Component<IProductItemExportComp, undefined> {
    constructor(props: IProductItemExportComp) {
        super(props);
        autoBindReact(this);
        ra(()=>{
            this.props.exp.filename = this.props.exp.filename_default;
        });
    }

    @action
    async onClickOk(){
        if (this.loading) return;
        this.loading = true;
        let index = this.props.index;
        let store = this.props.store;
        let exp = this.props.exp;
        let filename = exp.filename;
        if (filename == null) filename = exp.filename_default;

        //https://dev-class.ctrl2go.com/api/raster/stat/area/export?mode=zones&file=ndvi_zones&format=geojson&z=13&scene_id=S2B_L2A_2021-04-19_121500_34UEF&product=NDVI&classes=[[0,0.4],[0.4,0.5],[0.5,0.9]]&area={"type":"Polygon","coordinates":[[[21.974,54.602],[22.004,54.602],[22.004,54.588],[21.974,54.588],[21.974,54.602]]]}
        let p: any = {};
        if (exp.zone == IndexBandExportGeometry.zone) p.mode = "zones"; else p.mode = "grid";

        if (exp.format == IndexBandExportFormat.shp) p.format = "shp";
        else
        if (exp.format == IndexBandExportFormat.isoXml) p.format = "isoxml"; else p.format = "geojson";
        p.file = "zones";
        let scenes = ProductItemIndexBandComp.getScenes(store);
        p.scene_id = scenes.join(",");
        if (store.map.searchObject.fieldDataset != null){
            p.area = {"dskey":store.map.searchObject.fieldDataset,"filters":{"field_id":{"$in":store.map.searchObject.fieldIds}}};
        }else{
            let area: any = store.map.searchObject.searchGeometry;
            p.area = JSON.stringify(area);
        }
        if (exp.pixel == IndexBandExportPixel.p7) p.z = 14;
        if (exp.pixel == IndexBandExportPixel.p15) p.z = 13;
        if (exp.pixel == IndexBandExportPixel.p30) p.z = 12;
        p.product = this.props.prod.prod_name;
        let clArr = index.getDiscreteClassValuesValidsInterval();
        p.notExportInvisible = (exp.notExportInvisible && this.enabledNotExportInvisble()) ? 1 : 0;
        p.cut_out_visible_classes = (exp.cutVisible && this.enableCutVisible()) ? 1 : 0;
        if (index.interpolation == Interpolation.discrete && exp.normaChecked){
            let base = exp.normaBase;
            p.application_rates = [];
            exp.normaClasses.forEach(a =>{
                let v = base + base * a / 100;
                p.application_rates.push(v);
            });
        }
        if (exp.fert_id != null){
            p.fert_id = exp.fert_id;
        }
        p.classes = clArr.map((a, index) => {
            if (index == 0) return [null, a.max, a.isTransparent ? 0 : 1];
            else
                if (index == clArr.length - 1) return [a.min, null, a.isTransparent ? 0 : 1];
            else
                return [a.min, a.max, a.isTransparent ? 0 : 1];
        });
        p.segr = exp.splitByFields ? 1 : 0;
        try {
            let urlPermalink = "/api/jsonput";
            let jsonPermalink = this.props.store.toPermalink();
            let valuePermalink = await fetchJson(urlPermalink, {method: "POST", headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: Utils.queryEncodeParams({data: jsonPermalink})});
            p.permalink = valuePermalink.id;
            p.buf_metr = exp.intrWidth;

            let arr = await fetchBytesPost(`/api/raster/stat/area/export`, p);
            let blb = new Blob([arr], {type: 'application/octet-stream'});
            var url = window.URL.createObjectURL(blb);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename + ".zip";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();

            ra(()=>{
                exp.show = false;
                this.loading = false;
                store.addInfo(store.trans["Loading is complete"]);
            });
        }catch (e){
            ra(()=>{
                store.addError(e);
                exp.show = false;
                this.loading = false;
            });
        }
    }
    @observable
    loading: boolean = false;
    @action
    onClickCancel(){
        let exp = this.props.exp;
        exp.show = false;
    }

    @action
    onClickPixel(e:any, data: any){
        let exp = this.props.exp;
        exp.pixel = data;
    }
    @action
    onClickGeometry(e:any, data: any){
        let exp = this.props.exp;
        exp.zone = data;
        if (exp.zone == IndexBandExportGeometry.grid && exp.format == IndexBandExportFormat.geojson)
            exp.format = IndexBandExportFormat.isoXml;
        if (exp.zone == IndexBandExportGeometry.zone && exp.format == IndexBandExportFormat.isoXml)
            exp.format = IndexBandExportFormat.geojson;
    }
    @action
    onClickFormat(e:any, data: any){
        let exp = this.props.exp;
        exp.format = data;
    }


    enabledNotExportInvisble(): boolean{
        let index = this.props.index;
        let exp = this.props.exp;
        if (exp.normaChecked) return false;
        if (!index.hasTransparentClasses()) return false;
        return !exp.cutVisible;
    }
    @action
    OnClickNotExportInvisible(){
        let exp = this.props.exp;
        exp.notExportInvisible = !exp.notExportInvisible;
    }


    enableCutVisible(): boolean{
        let index = this.props.index;
        let exp = this.props.exp;
        if (exp.normaChecked) return false;
        if (!index.hasTransparentClasses()) return false;
        return !exp.notExportInvisible;
    }
    enableSplit(): boolean{
        let exp = this.props.exp;
        if (exp.normaChecked) return false;
        return true;
    }
    enableZone(): boolean{
        let exp = this.props.exp;
        if (exp.normaChecked) return false;
        return true;
    }
    @action
    OnCutVisible(){
        if (!this.enableCutVisible()) return;
        let exp = this.props.exp;
        let index = this.props.index;
        exp.cutVisible = !exp.cutVisible;
        exp.normaChecked = false;
    }

    @action
    OnClickSplitByFields(){
        let exp = this.props.exp;
        exp.splitByFields = !exp.splitByFields;
    }
    @action
    onChange_Filename(e: any){
        let exp = this.props.exp;

        let txt = e.currentTarget.value;
        exp.filename = txt;
    }
    @action
    OnChangeIntrWidth(item: IDropDownItem, newIndex: number){
        let exp = this.props.exp;
        if (item == null) exp.intrWidth = 0; else exp.intrWidth = item.data;
    }

    render() {
        let store = this.props.store;
        let index = this.props.index;
        let exp = this.props.exp;

        let totalFertilizer = 0;
        let totalArea = 0;
        let avgFertilizer;
        if (index.interpolation == Interpolation.discrete && exp.normaChecked){
            let base = exp.normaBase;
            exp.normaClasses.forEach((a, idx) =>{
                let v = base + base * a / 100;
                let area = 0;
                if (store.map.productInfo.legendAreaStore.areas.length > idx){
                    area = store.map.productInfo.legendAreaStore.areas[idx];
                }
                totalFertilizer += area * v;
                totalArea += area;
            });
        }
        avgFertilizer = totalFertilizer / totalArea;

        let intrWidth: IDropDownItem[] = [
            {data: 0, value: "Без интерполяции", key:0},
            {data: 10, value: "10 м", key:10},
            {data: 20, value: "20 м", key:20},
            {data: 30, value: "30 м", key:30},
            {data: 50, value: "50 м", key:50},
        ];

        return <div className="modal-back full-center-content">
            <div className="modal-min ProductItemExportComp-main">
                <div className="ProductItemExportComp-mainPanels">
                    <div className="ProductItemExportComp-mainLeft">
                        <div className="ProductItemExportComp-title">{store.trans["Exporting a map by spectral indices"]}</div>
                        <div className="ProductItemExportComp-filenameDiv">
                                <div className="ProductItemExportComp-filenameDesc">
                                    <span>{store.trans["File name"]}</span>
                                    <span className="gray-text">ZIP</span>
                                </div>
                                <input type="text" className="text-box-editor width100"
                                       onChange={this.onChange_Filename} value={exp.filename}/>
                        </div>
                        <div className="ProductItemExportComp-group">
                            <div className="ProductItemExportComp-groupTitle">{store.trans["Pixel Size (m)"]}</div>
                            <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.pixel == IndexBandExportPixel.p7}
                                             data={IndexBandExportPixel.p7} onClick={this.onClickPixel}>
                                    <div className="ProductItemExportComp-groupLabel">≈7.5</div>
                                </RadioButton>
                            </div>
                            <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.pixel == IndexBandExportPixel.p15}
                                             data={IndexBandExportPixel.p15} onClick={this.onClickPixel}>
                                    <div className="ProductItemExportComp-groupLabel">≈15</div>
                                </RadioButton>

                            </div>
                            <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.pixel == IndexBandExportPixel.p30}
                                             data={IndexBandExportPixel.p30} onClick={this.onClickPixel}>
                                    <div className="ProductItemExportComp-groupLabel">≈30</div>
                                </RadioButton>
                            </div>
                        </div>
                        <div className="ProductItemExportComp-group">
                            <div className="ProductItemExportComp-groupTitle">Ширина интерполяции вдоль границы поля</div>
                            <div className="ProductItemExportComp-groupKeyValue">
                                <DropDownComp items={intrWidth} currentKey={exp.intrWidth} onChange={this.OnChangeIntrWidth} className="ProductItemExportComp-intplWidth" />
                            </div>
                        </div>
                        <div className="ProductItemExportComp-group">
                            <div className={classNames("ProductItemExportComp-groupTitle", {"opacity30": !this.enableZone()})}>
                                {store.trans["Geometry type"]}
                            </div>
                            <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.zone == IndexBandExportGeometry.grid} disabled={!this.enableZone()}
                                             data={IndexBandExportGeometry.grid} onClick={this.onClickGeometry}>
                                    <div className="ProductItemExportComp-groupLabel">{store.trans.Grid}</div>
                                </RadioButton>

                            </div>
                            <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.zone == IndexBandExportGeometry.zone} disabled={!this.enableZone()}
                                             data={IndexBandExportGeometry.zone} onClick={this.onClickGeometry}>
                                    <div className="ProductItemExportComp-groupLabel">{store.trans.Zones}</div>
                                </RadioButton>
                            </div>
                        </div>

                        <div className="ProductItemExportComp-group">
                            <div className="ProductItemExportComp-groupTitle">{store.trans["File format"]}</div>
                            {exp.zone != IndexBandExportGeometry.grid && <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.format == IndexBandExportFormat.geojson}
                                             data={IndexBandExportFormat.geojson} onClick={this.onClickFormat}>
                                    <div className="ProductItemExportComp-groupLabel">.geojson</div>
                                </RadioButton>
                            </div>}
                            {exp.zone == IndexBandExportGeometry.grid && <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.format == IndexBandExportFormat.isoXml}
                                             data={IndexBandExportFormat.isoXml} onClick={this.onClickFormat}>
                                    <div className="ProductItemExportComp-groupLabel">{store.trans["ISO-XML (file for technology)"]}</div>
                                </RadioButton>
                            </div>}
                            <div className="ProductItemExportComp-groupKeyValue">
                                <RadioButton checked={exp.format == IndexBandExportFormat.shp}
                                             data={IndexBandExportFormat.shp} onClick={this.onClickFormat}>
                                    <div className="ProductItemExportComp-groupLabel">.shp</div>
                                </RadioButton>
                            </div>
                        </div>

                        <div className="ProductItemExportComp-invisible">
                            <div className="flex-columns">
                                <CheckboxMiniComp state={exp.notExportInvisible}
                                                  disabled={!this.enabledNotExportInvisble()}
                                                  size={CheckboxSize.standart} onClick={this.OnClickNotExportInvisible}>
                                    <div className="text-next pointer">{store.trans["Export only visible classes"]}</div>
                                </CheckboxMiniComp>
                            </div>
                        </div>
                        <div className="ProductItemExportComp-invisible">
                            <div className="flex-columns">
                                <CheckboxMiniComp state={exp.cutVisible} size={CheckboxSize.standart}
                                                  disabled={!this.enableCutVisible()}

                                                  onClick={this.OnCutVisible}>
                                    <div
                                        className="text-next pointer">{store.trans["Cut visible classes from active area"]}</div>
                                </CheckboxMiniComp>
                            </div>
                        </div>
                        <div className="ProductItemExportComp-invisible">
                            <div className="flex-columns">
                                <CheckboxMiniComp state={exp.splitByFields} size={CheckboxSize.standart}
                                                  disabled={!this.enableSplit()}
                                                  onClick={this.OnClickSplitByFields}>
                                    <div className="text-next pointer">{store.trans["Split by objects"]}</div>
                                </CheckboxMiniComp>
                            </div>
                        </div>
                        <div className="ProductItemExportComp-separate"/>
                    </div>
                    {index.interpolation == Interpolation.discrete && <div className="ProductItemExportComp-mainRight">
                        <ProductItemExportNormaComp {...this.props} />
                    </div>}
                </div>
                <div className="separator_line-flex" />

                <div className="ProductItemExportComp-footer">
                    {totalArea > 0 && <div className="flex-stretch-item">
                        <div className="ProductItemExportComp-gray">{store.trans["Total area"]}: {(new Intl.NumberFormat()).format(totalArea)} га</div>
                        <div className="ProductItemExportComp-gray">{store.trans["Total application volume"]}: {(new Intl.NumberFormat()).format(totalFertilizer)} кг</div>
                        <div className="ProductItemExportComp-gray">{store.trans["Average rate"]}: {(new Intl.NumberFormat()).format(avgFertilizer)} кг/га</div>
                    </div>}
                    <button onClick={this.onClickCancel} className={
                        classNames(
                            "large-button left-sidebar-cancel pointer ProductItemExportComp-button button-jump",
                            "full-center-content")}>
                        {store.trans.Cancel}
                    </button>
                    <button onClick={this.onClickOk} className={
                        classNames(
                            {"opacity50": this.loading, "button-jump": !this.loading},
                            "large-button left-sidebar-active pointer ProductItemExportComp-button",
                            "full-center-content")}>
                        {store.trans.Export}
                    </button>
                </div>
            </div>
        </div>;
    }
}
