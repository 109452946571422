import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import './SceneHighlightWidgetComp.scss';
import {action} from "mobx";
import {SearchSceneListComp} from "../Left/SearchPanel/SearchSceneListComp";
import {ISearchPropertiesRest, LeftPanelMode, SceneUiType} from "../../../store/SearchStore";
import classNames from "classnames";
import {SearchItemGroup} from "../../../store/SearchItemGroup";
import './widgets/widgets.scss';
@observer
export class SceneHighlightWidgetComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickVisible(){
        let store = this.props.store;
        store.searchPanel.currentSceneVisible = !store.searchPanel.currentSceneVisible;
    }
    @action
    toCenter(){
        let store = this.props.store;
        store.searchPanel.switchPanel(LeftPanelMode.search);
        SearchSceneListComp.scrollToCurrent(this.props.store, true);
        /*let grp = store.searchPanel.searchResult.findGroup(store.searchPanel.top_sceneId);
        let bbox = grp.getBbox();
        this.props.store.map.zoomFlyToBBox(bbox);*/
    }
    @action
    onClickCutBorder(){
        let store = this.props.store;
        store.searchPanel.cutFieldsByBorder = !store.searchPanel.cutFieldsByBorder;
        //this.props.store.map.resetFavorites();
        //this.props.store.map.syncFavorites();
    }
    @action
    onClickFieldVisible(){
        this.props.store.agro2.agroFieldVisible = !this.props.store.agro2.agroFieldVisible;
    }
    @action
    onClickPhotoVisible(){
        this.props.store.photo.photoLayerVisible = !this.props.store.photo.photoLayerVisible;
    }
    @action
    onClickFavVisible(){
        this.props.store.map.mapScenesStore.favoriteScenesVisible = !this.props.store.map.mapScenesStore.favoriteScenesVisible;
    }
    lastText: string = "";

    render() {
        let store = this.props.store;
    
        let favEnable = (store.searchPanel.favoriteList.getAllSceneItems().length > 0);
        let fieldEnabled = (store.config.agroShow && store.config.searchImage);
        let photoEnabled = (store.config.photoShow);
        let cutActiveAreaEnabled = (store.map.searchObject.isNotEmpty);
        let curEnbable = store.searchPanel.searchResult.currentItems.length > 0;
        if (!photoEnabled && !fieldEnabled && !favEnable && !curEnbable) return null;

        let iconUrl = store.config.theme?.agro_button_icon_mini;
        let html: any = null;
        let agroSvg: any = null;
        if (iconUrl){
            html = {__html: iconUrl};
            agroSvg = <div dangerouslySetInnerHTML={html} />
        }

        let dateScene: string = null;
        let productName: string = null;
        let disabledCurScene: boolean = store.searchPanel.top_scene_disabled;

        if (store.searchPanel.top_sceneId != null && store.searchPanel.top_sceneType != null){
            let grp: SearchItemGroup = store.searchPanel.currentGroup;
            if (grp != null){
                dateScene = grp.first().feature.properties.acqdate;
            }
            productName = store.map.productInfo.productsPassive.getActiveCodeByUiType(store.searchPanel.top_sceneType).currentProductStore.productConfig.abbreviation;
        }

        return <div className="widgets-back-m">
            <div className={classNames("SceneHighlightWidgetComp-form-m SceneHighlightWidgetComp-form-content")}>
                <div className="SceneHighlightWidgetComp-text-div text-white">
                    <div className={classNames("SceneHighlightWidgetComp-text1-m qtransit  flex-columns-centerByVertical",
                        {
                            "SceneHighlightWidgetComp-text1-hidden": dateScene == null,
                            "blueSilver2-text gray2-fill": !disabledCurScene,
                            "blueSilver2-text gray2-fill opacity30": disabledCurScene
                        })}>
                        <a className={classNames(" flex-columns-centerByVertical link-noUnderline link-blueOnHover")} onClick={this.toCenter}>

                            {store.searchPanel.top_sceneType == SceneUiType.current &&
                                <div className="text-prev">
                                    <svg width="10" height="10" viewBox="0 0 10 10" stroke="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 10H2.5C1.83696 10 1.20107 9.73661 0.732233 9.26777C0.263392 8.79893 0 8.16304 0 7.5V6.875H1.25V7.5C1.25 7.83152 1.3817 8.14946 1.61612 8.38388C1.85054 8.6183 2.16848 8.75 2.5 8.75H3.125V10Z" />
                                        <path d="M8.125 6.25H7.13375L6.37937 5.49563L8.98125 2.89375C9.10436 2.77064 9.20202 2.62448 9.26865 2.46362C9.33528 2.30277 9.36958 2.13036 9.36958 1.95625C9.36958 1.78214 9.33528 1.60974 9.26865 1.44888C9.20202 1.28802 9.10436 1.14187 8.98125 1.01875C8.85814 0.895638 8.71198 0.797979 8.55112 0.73135C8.39026 0.664721 8.21786 0.630427 8.04375 0.630427C7.69212 0.630427 7.35489 0.770112 7.10625 1.01875L4.50437 3.62063L3.74375 2.86L3.7375 1.8875L1.89875 0.0493774L0 1.94813L1.875 3.75H2.86625L3.62063 4.50438L2.5 5.625L4.375 7.5L5.49563 6.37938L6.25 7.13375V8.125L8.13125 10.0063L9.99375 8.14438L8.125 6.25Z"/>
                                    </svg>
                                </div>
                            }
                            {store.searchPanel.top_sceneType == SceneUiType.favorite &&
                                <div className="text-prev">
                                    <svg width="11" height="10" viewBox="0 0 11 10"  stroke="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.46571 3.30724L6.76811 2.89664L5.56119 0.335455C5.34993 -0.111818 4.65116 -0.111818 4.4399 0.335455L3.23361 2.89664L0.535385 3.30724C0.0247414 3.38517 -0.182141 4.04527 0.189122 4.4251L2.14169 6.41851L1.68105 9.23378C1.59417 9.76684 2.12919 10.1761 2.58796 9.92401L5.00055 8.59529L7.41376 9.92467C7.86877 10.1742 8.40817 9.77143 8.32067 9.23444L7.86002 6.41916L9.8126 4.42575C10.1832 4.04527 9.97635 3.38517 9.46571 3.30724Z"/>
                                    </svg>
                                </div>
                            }

                            <span className="">{dateScene}</span>
                            <span className="text-next" style={{lineHeight:"15px"}}>{productName}</span>
                        </a>

                    </div>

                    <div className="SceneHighlightWidgetComp-text2-m qtransit">
                        <div className={classNames("SceneHighlightWidgetComp-miniButton", {
                            "opacity30 white-fill def-cursor": !curEnbable,
                            "white-fill": curEnbable && !store.searchPanel.currentSceneVisible,
                            "blue-fill": curEnbable && store.searchPanel.currentSceneVisible})}

                             onClick={this.onClickVisible}>
                            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.375 14H3.5C2.57174 14 1.6815 13.6313 1.02513 12.9749C0.368749 12.3185 0 11.4283 0 10.5V9.625H1.75V10.5C1.75 10.9641 1.93437 11.4092 2.26256 11.7374C2.59075 12.0656 3.03587 12.25 3.5 12.25H4.375V14Z" />
                                <path d="M11.375 8.75H9.98725L8.93112 7.69387L12.5737 4.05125C12.7461 3.87889 12.8828 3.67427 12.9761 3.44907C13.0694 3.22387 13.1174 2.9825 13.1174 2.73875C13.1174 2.49499 13.0694 2.25363 12.9761 2.02843C12.8828 1.80323 12.7461 1.59861 12.5737 1.42625C12.4014 1.25389 12.1968 1.11716 11.9716 1.02388C11.7464 0.930603 11.505 0.882592 11.2612 0.882592C10.769 0.882592 10.2968 1.07815 9.94875 1.42625L6.30612 5.06887L5.24125 4.004L5.2325 2.6425L2.65825 0.0691223L0 2.72737L2.625 5.25H4.01275L5.06887 6.30612L3.5 7.875L6.125 10.5L7.69387 8.93112L8.75 9.98725V11.375L11.3837 14.0087L13.9912 11.4021L11.375 8.75Z" />
                            </svg>
                        </div>

                        <div onClick={this.onClickFieldVisible}
                            className={classNames("SceneHighlightWidgetComp-miniButton", {
                                "opacity30 white-fill def-cursor": !fieldEnabled,
                                "white-fill": !store.agro2.agroFieldVisible && fieldEnabled,
                                "blue-fill": store.agro2.agroFieldVisible && fieldEnabled})}>
                            {agroSvg}
                            {!agroSvg &&
                                <svg width="14" height="14" viewBox="0 0 14 14" stroke="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.33342 0C8.35867 0 7.37168 0.326417 6.55676 0.928342C8.70342 1.67461 10.5001 3.67204 10.5001 6.58824C10.5001 8.19636 9.54459 10.0165 8.47826 11.5522C8.68942 11.7852 8.85042 11.9553 8.91926 12.0265C9.02892 12.1397 9.17767 12.2032 9.33342 12.2032C9.48917 12.2032 9.63792 12.1397 9.74759 12.0265C10.1828 11.5755 14.0001 7.54652 14.0001 4.79144C14.0001 1.792 11.6277 0 9.33342 0Z" />
                                    <path d="M4.66665 1.79675C2.37241 1.79675 0 3.58875 0 6.5882C0 9.34328 3.81732 13.3723 4.25249 13.8233C4.36215 13.9365 4.5109 14 4.66665 14C4.8224 14 4.97115 13.9365 5.08082 13.8233C5.51598 13.3723 9.33331 9.34328 9.33331 6.5882C9.33331 3.58875 6.9609 1.79675 4.66665 1.79675ZM4.66665 7.78606C4.02207 7.78606 3.49999 7.25001 3.49999 6.5882C3.49999 5.92638 4.02207 5.39034 4.66665 5.39034C5.31123 5.39034 5.83332 5.92638 5.83332 6.5882C5.83332 7.25001 5.31123 7.78606 4.66665 7.78606Z" />
                                </svg>}
                        </div>


                        <div onClick={this.onClickPhotoVisible}
                             className={classNames("SceneHighlightWidgetComp-miniButton", {
                                 "opacity30 white-fill def-cursor": !photoEnabled,
                                 "white-fill": !store.photo.photoLayerVisible && photoEnabled,
                                 "blue-fill": store.photo.photoLayerVisible && photoEnabled})}>
                            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M12.25 2.33333H10.2772L9.2715 0.322C9.22301 0.225228 9.14855 0.143858 9.05645 0.0869907C8.96435 0.0301237 8.85824 3.63223e-06 8.75 0L5.25 0C5.14176 3.63223e-06 5.03565 0.0301237 4.94355 0.0869907C4.85145 0.143858 4.77699 0.225228 4.7285 0.322L3.72283 2.33333H1.75C1.28587 2.33333 0.840752 2.51771 0.512563 2.8459C0.184374 3.17409 0 3.6192 0 4.08333L0 12.25C0 12.7141 0.184374 13.1592 0.512563 13.4874C0.840752 13.8156 1.28587 14 1.75 14H12.25C12.7141 14 13.1592 13.8156 13.4874 13.4874C13.8156 13.1592 14 12.7141 14 12.25V4.08333C14 3.6192 13.8156 3.17409 13.4874 2.8459C13.1592 2.51771 12.7141 2.33333 12.25 2.33333ZM7 11.0833C6.42314 11.0833 5.85923 10.9123 5.37959 10.5918C4.89994 10.2713 4.52611 9.81578 4.30535 9.28283C4.0846 8.74988 4.02684 8.16343 4.13938 7.59765C4.25192 7.03188 4.5297 6.51217 4.9376 6.10427C5.34551 5.69637 5.86521 5.41858 6.43099 5.30604C6.99676 5.1935 7.58321 5.25126 8.11616 5.47202C8.64911 5.69277 9.10463 6.06661 9.42512 6.54625C9.74561 7.0259 9.91667 7.5898 9.91667 8.16667C9.91667 8.94021 9.60938 9.68208 9.06239 10.2291C8.51541 10.776 7.77355 11.0833 7 11.0833Z"/>
                            </svg>
                        </div>
                       
                        {store.config.searchImage &&
                            <div onClick={this.onClickFavVisible}
                                 className={classNames("SceneHighlightWidgetComp-miniButton", {
                                     "opacity30 white-fill def-cursor": !favEnable,
                                     "white-fill": !store.map.mapScenesStore.favoriteScenesVisible && favEnable,
                                     "blue-fill": store.map.mapScenesStore.favoriteScenesVisible && favEnable})}>
                                <svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.2515 4.62997L9.47501 4.05515L7.78539 0.469621C7.48964 -0.15654 6.51139 -0.15654 6.21564 0.469621L4.52689 4.05515L0.749512 4.62997C0.0346367 4.73906 -0.254988 5.66318 0.264762 6.19491L2.99826 8.98559L2.35339 12.9268C2.23176 13.6731 2.98076 14.2461 3.62301 13.8931L7.00051 12.033L10.3789 13.894C11.0159 14.2433 11.771 13.6795 11.6485 12.9277L11.0036 8.98651L13.7371 6.19583C14.256 5.66318 13.9664 4.73906 13.2515 4.62997Z"/>
                                </svg>
                            </div>
                        }

                        {store.config.searchImage &&
                            <div className={classNames("SceneHighlightWidgetComp-miniButton", {
                                "opacity30 white-fill def-cursor": !cutActiveAreaEnabled,
                                "white-fill": !store.searchPanel.cutFieldsByBorder && cutActiveAreaEnabled,
                                "yellow-fill": store.searchPanel.cutFieldsByBorder && cutActiveAreaEnabled})}
                                 onClick={this.onClickCutBorder}>
                                <svg width="14" height="14" viewBox="0 0 14 14"  xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4004_269)">
                                        <path d="M13.125 0H0.875C0.391751 0 0 0.391751 0 0.875V13.125C0 13.6082 0.391751 14 0.875 14H13.125C13.6082 14 14 13.6082 14 13.125V0.875C14 0.391751 13.6082 0 13.125 0Z"/>
                                    </g>
                                    <g clipPath="url(#clip1_4004_269)">
                                        <path d="M8.67132 5.61021L10.5533 2.25167C10.6477 2.08284 10.6711 1.88344 10.6185 1.69733C10.5658 1.51122 10.4414 1.35364 10.2726 1.25927C10.1037 1.1649 9.90434 1.14146 9.71823 1.1941C9.53212 1.24675 9.37455 1.37117 9.28017 1.54L7.83569 4.11833L8.67132 5.61021Z" fill="#262B32"/>
                                        <path d="M7.41798 7.84874L6.58236 6.35614L5.35444 8.54802C5.12776 8.28368 4.84069 8.0779 4.51755 7.94813C4.19442 7.81835 3.84476 7.76842 3.49821 7.80255C3.15167 7.83668 2.81847 7.95387 2.52686 8.14419C2.23525 8.33451 1.99384 8.59235 1.82309 8.89583L1.4585 9.52729C1.16908 10.0302 1.09098 10.6275 1.24135 11.1879C1.39172 11.7484 1.75827 12.2263 2.26059 12.5169C2.50951 12.6607 2.78434 12.754 3.06935 12.7915C3.35436 12.829 3.64397 12.81 3.92163 12.7355C4.19928 12.661 4.45952 12.5325 4.68749 12.3574C4.91545 12.1822 5.10665 11.9639 5.25017 11.7148L7.41798 7.84874ZM2.98975 11.2518C2.82228 11.1551 2.70008 10.9958 2.65004 10.809C2.59999 10.6222 2.62619 10.4232 2.72288 10.2557L3.08746 9.625C3.1595 9.50008 3.26696 9.39933 3.39625 9.33548C3.52554 9.27163 3.67086 9.24754 3.81384 9.26627C3.95681 9.285 4.09103 9.34571 4.19951 9.44071C4.30799 9.53571 4.38587 9.66074 4.4233 9.8C4.44853 9.89246 4.45508 9.98903 4.44256 10.0841C4.43004 10.1791 4.3987 10.2707 4.35038 10.3534L3.9858 10.9849C3.8891 11.1524 3.72984 11.2746 3.54304 11.3246C3.35625 11.3747 3.15723 11.3485 2.98975 11.2518Z" fill="#262B32"/>
                                        <path d="M12.5418 9.52656L12.1773 8.89437C12.0061 8.59153 11.7645 8.33435 11.473 8.14457C11.1815 7.95478 10.8485 7.83797 10.5023 7.804C10.1561 7.77003 9.80681 7.8199 9.48395 7.9494C9.16109 8.0789 8.87414 8.28423 8.64737 8.54802L4.72008 1.54C4.62571 1.37117 4.46813 1.24675 4.28202 1.1941C4.09591 1.14146 3.89651 1.1649 3.72768 1.25927C3.55886 1.35364 3.43443 1.51122 3.38179 1.69733C3.32914 1.88344 3.35258 2.08284 3.44695 2.25167L8.75018 11.7141C8.8937 11.9631 9.08491 12.1815 9.31287 12.3566C9.54083 12.5318 9.80107 12.6603 10.0787 12.7348C10.3564 12.8092 10.646 12.8283 10.931 12.7908C11.216 12.7533 11.4908 12.6599 11.7398 12.5161C12.2421 12.2256 12.6086 11.7477 12.759 11.1872C12.9094 10.6267 12.8313 10.0295 12.5418 9.52656ZM11.0106 11.2518C10.8431 11.3485 10.6441 11.3747 10.4573 11.3246C10.2705 11.2746 10.1113 11.1524 10.0146 10.9849L9.64997 10.3534C9.55328 10.1859 9.52711 9.98675 9.57723 9.79989C9.62735 9.61302 9.74964 9.45372 9.91721 9.35703C10.0848 9.26034 10.2839 9.23417 10.4708 9.28429C10.6576 9.3344 10.8169 9.4567 10.9136 9.62427L11.2782 10.2557C11.326 10.3387 11.3571 10.4303 11.3695 10.5252C11.382 10.6202 11.3756 10.7167 11.3507 10.8092C11.3259 10.9017 11.2831 10.9884 11.2247 11.0643C11.1663 11.1403 11.0936 11.204 11.0106 11.2518Z" fill="#262B32"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4004_269">
                                            <rect width="14" height="14" fill="white"/>
                                        </clipPath>
                                        <clipPath id="clip1_4004_269">
                                            <rect width="11.6667" height="11.6667" fill="white" transform="translate(1.16675 1.16666)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={classNames("SceneHighlightWidgetComp-compareButton", {
                            "hidden":!curEnbable && !favEnable || dateScene == null })}
                             onClick={this.props.store.map.setCompareModeEnabled}
                             >
                            <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.84375 6.59375H1.59375C1.00695 6.59375 0.53125 7.06945 0.53125 7.65625V19.3438C0.53125 19.9306 1.00695 20.4062 1.59375 20.4062H5.84375C6.43055 20.4062 6.90625 19.9306 6.90625 19.3438V7.65625C6.90625 7.06945 6.43055 6.59375 5.84375 6.59375Z" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.0938 8.71875V7.65625C12.0938 7.37446 12.2057 7.10421 12.4049 6.90495C12.6042 6.70569 12.8745 6.59375 13.1562 6.59375H13.6875" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.6875 20.4062H13.1562C12.8745 20.4062 12.6042 20.2943 12.4049 20.0951C12.2057 19.8958 12.0938 19.6255 12.0938 19.3438V18.2812" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.4688 18.2812V19.3438C18.4688 19.6255 18.3568 19.8958 18.1576 20.0951C17.9583 20.2943 17.688 20.4062 17.4062 20.4062H16.875" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.4688 11.9062V15.0938" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.0938 11.9062V15.0938" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.875 6.59375H17.4062C17.688 6.59375 17.9583 6.70569 18.1576 6.90495C18.3568 7.10421 18.4688 7.37446 18.4688 7.65625V8.71875" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
<rect x="9.25" y="4.25" width="0.5" height="18.5" stroke="#C5C5C5" strokeWidth="0.5"/>
<path d="M9.9147 4.48933C9.71651 4.78356 9.28348 4.78356 9.0853 4.48934L7.93349 2.77933C7.70978 2.44722 7.94776 2 8.34818 2L10.6518 2C11.0522 2 11.2902 2.44722 11.0665 2.77933L9.9147 4.48933Z" stroke="#C5C5C5"/>
<path d="M9.0853 22.5107C9.28349 22.2164 9.71652 22.2164 9.9147 22.5107L11.0665 24.2207C11.2902 24.5528 11.0522 25 10.6518 25L8.34818 25C7.94775 25 7.70978 24.5528 7.93348 24.2207L9.0853 22.5107Z" stroke="#C5C5C5"/>
</svg>

                        </div>
        </div>;
    }
}
