import {CustomStore} from "../CustomStore";

export class ConstantsStore extends CustomStore{
    MAX_ACTIVE_FIEDLS: number = 300;
    static readonly COMMON_PREFIX = "class_";

    static Zal_req_status_id = "req_status_id";//
    static Zal_cadaster_num = "cadastr_num";//
    static Zal_req_id = "req_id";//


}