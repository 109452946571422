import {
    ContainerTools,
    ContainerToolsState,
    CreateGeometryType,
    DrawPointType,
    IObjectByClick
} from "../general/ContainerTools";
import * as mapboxgl from "maplibre-gl";
import {
    CircleLayer,
    CirclePaint,
    FillLayer,
    FillPaint,
    GeoJSONSource, LineLayer,
    LinePaint,
    MapboxGeoJSONFeature,
    PointLike
} from "maplibre-gl";
import {Feature, FeatureCollection, Geometry, Point, Position} from "geojson";
import {
    AddrContour,
    AddrPoint,
    ContourPoints,
    GeometryUtils,
    ISimpleGeometry,
    SimpleGeometryType
} from "../../../helper/utils/GeometryUtils";
import {cloneDeep} from "lodash-es";
import {MBUtils} from "../../../helper/utils/MBUtils";
import {CustomEditGeometryTool} from "./CustomEditGeometryTool";
import {autorun, computed} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";

export class ViewGeometryStyle{
    colorFirstDotOuter = '#861717';
    colorFirstDotInner = "#ffffff";
    colorSecondDotOuter = '#001bba';
    colorSecondDotInner= "#ffffff";
    colorInsertDotOuter = '#1f2567';
    colorInsertDotInner= '#5e66a5';
    colorLine= "#31559d";
    colorLineOuter = "#ffffff";
    widthLine = 2;
    colorFill= "#31559d";
    opacityFill = 0.15;

    public setBlueStyle(){
        this.colorFirstDotOuter = '#861717';
        this.colorFirstDotInner = "#ffffff";
        this.colorSecondDotOuter = '#001bba';
        this.colorSecondDotInner= "#ffffff";
        this.colorInsertDotOuter = '#1f2567';
        this.colorInsertDotInner= '#5e66a5';
        this.colorLine= "#31559d";
        this.widthLine = 2;
        this.colorFill= "#31559d";
    }
    public setRedStyle(){
        this.colorFirstDotOuter = '#861717';
        this.colorFirstDotInner = "#ffffff";
        this.colorSecondDotOuter = '#FF5E54';
        this.colorSecondDotInner= "#ffffff";
        this.colorInsertDotOuter = '#FF5E54';
        this.colorInsertDotInner= '#fce5e5';
        this.colorLine= "#FF5E54";
        this.widthLine = 2;
        this.colorFill= "#FF5E54";
    }
    public setYellowStyle(){
        this.colorFirstDotOuter = '#861717';
        this.colorFirstDotInner = "#ffffff";
        this.colorSecondDotOuter = "#FFCE5F";
        this.colorSecondDotInner= "#ffffff";
        this.colorInsertDotOuter = '#d9bb83';
        this.colorInsertDotInner= '#fce5e5';
        this.colorLine= "#FFCE5F";
        this.widthLine = 2;
        this.colorFill= "#FFCE5F";
    }

}
export class ViewGeometryLayersTool extends CustomEditGeometryTool{
    constructor(container: ContainerTools, state: ContainerToolsState, name: string) {
        super(container, state, name);
        this.style = new ViewGeometryStyle();
        this.activeStyle = this.style;
    }

    Custom_GEOMETRY_FILL_Style: FillLayer = null;
    Custom_GEOMETRY_LINES_LAYEROVER_Style: LineLayer = null;
    Custom_GEOMETRY_LINES_Style: LineLayer = null;
    Custom_GEOMETRY_VERTEX1_Style: CircleLayer = null;
    Custom_GEOMETRY_VERTEX2_Style: CircleLayer = null;
    Custom_MOVED_LINE_Style: LineLayer = null;
    Custom_MOVED_LINE_OVERLAY_Style: LineLayer = null;
    Custom_MOVED_FILL_Style: FillLayer = null;


    onInstall() {
        super.onInstall();
        this.installSources();
        this.installLayers();
        this.updateGeometry();
        if (this.onInstallEvent != null) this.onInstallEvent();
    }

    onUninstall() {
        this.uninstallLayers();
        this.uninstallSources();
    }

    onInstallEvent: ()=>void = null;

    style: ViewGeometryStyle;
    activeStyle: ViewGeometryStyle;

    //чтобы легче было искать ссылки на них
    static readonly active_contour = "active_contour";
    static readonly point_type = "point_type";
    static readonly index = "index";
    static readonly first = "first";
    static readonly ContourAddrProperty = "contourAddr";
    static readonly ContourNumProperty = "contourNum";
    static readonly IsPolygonProperty = "isPolygon";
    static readonly IsHoleProperty = "isHole";


    Prefix = "class_tools_";

    get MOVED_LINE_SOURCE_ID(){ return this.Prefix + 'MOVED_SOURCE_ID'};
    get MOVED_LINE_LAYER_ID(){ return this.Prefix + 'MOVED_LINE_LAYER_ID'};
    get MOVED_LINE_OVERLAY_LAYER_ID(){ return this.Prefix + 'MOVED_LINE_OVERLAY_LAYER_ID'};
    get MOVED_FILL_LAYER_ID(){ return this.Prefix + 'MOVED_FILL_LAYER_ID'};

    get GEOMETRY_FILL_SOURCE_ID(){ return this.Prefix + 'GEOMETRY_FILL_SOURCE_ID'};//геометрия
    get GEOMETRY_FILL_LAYER_ID(){ return this.Prefix + 'GEOMETRY_FILL_LAYER_ID'};

    get GEOMETRY_LINES_SOURCE_ID(){ return this.Prefix + 'GEOMETRY_LINES_SOURCE_ID'};//линии геометрии
    get GEOMETRY_LINES_LAYER_ID(){ return this.Prefix + 'GEOMETRY_LINES_LAYER_ID'};
    get GEOMETRY_LINES_LAYEROVER_ID(){ return this.Prefix + 'GEOMETRY_LINESOVER_LAYER_ID'};

    get GEOMETRY_VERTEX_SOURCE_ID(){ return this.Prefix + 'GEOMETRY_VERTEX_SOURCE_ID'};//вершины + "средние точки"(MIDPOINTS)
    get GEOMETRY_VERTEX_LAYER_ID(){ return this.Prefix + 'GEOMETRY_VERTEX_LAYER_ID'};//вершины геометрии, средние точки, конечная точка
    get GEOMETRY_VERTEX2_LAYER_ID(){ return this.Prefix + 'GEOMETRY_VERTEX2_LAYER_ID'};//вершины геометрии, средние точки, конечная точка

    get Highlight_Contours_SOURCE_ID(){ return this.Prefix + 'Highlight_Contours_SOURCE_ID'};//подсветка контуров
    get Highlight_Contours_LAYER_ID(){ return this.Prefix + 'Highlight_Contours_LAYER_ID'};//подсветка контуров

    public reinstallLayers(){
        this.uninstallLayers();
        this.installLayers();
    }
    protected uninstallLayers(){
        let ths = this;
        if (this.map != null) {
            this.removeLayer(ths.MOVED_LINE_LAYER_ID);
            this.removeLayer(ths.MOVED_FILL_LAYER_ID);
            this.removeLayer(ths.GEOMETRY_FILL_LAYER_ID);
            this.removeLayer(ths.GEOMETRY_LINES_LAYER_ID);
            this.removeLayer(ths.GEOMETRY_LINES_LAYEROVER_ID);
            this.removeLayer(ths.GEOMETRY_VERTEX_LAYER_ID );
            this.removeLayer(ths.GEOMETRY_VERTEX2_LAYER_ID);
            this.removeLayer(ths.Highlight_Contours_LAYER_ID );
        }
    }
    protected uninstallSources(){
        let ths = this;
        if (this.map != null) {
            this.map.removeSource(ths.MOVED_LINE_SOURCE_ID);
            this.map.removeSource(ths.GEOMETRY_FILL_SOURCE_ID);
            this.map.removeSource(ths.GEOMETRY_LINES_SOURCE_ID);
            this.map.removeSource(ths.GEOMETRY_VERTEX_SOURCE_ID);
            this.map.removeSource(ths.Highlight_Contours_SOURCE_ID);
        }
    }

    protected installSources(){
        let ths = this;
        if (this.map.getSource(ths.MOVED_LINE_SOURCE_ID) == null) {
            this.map.addSource(ths.MOVED_LINE_SOURCE_ID, {
                'type': 'geojson',
                'data': ContainerTools.EMPTY_SOURCE
            });
        }
        if (this.map.getSource(ths.GEOMETRY_FILL_SOURCE_ID) == null) {
            this.map.addSource(ths.GEOMETRY_FILL_SOURCE_ID, {
                'type': 'geojson',
                'data': ContainerTools.EMPTY_SOURCE
            });
        }
        if (this.map.getSource(ths.GEOMETRY_LINES_SOURCE_ID) == null) {
            this.map.addSource(ths.GEOMETRY_LINES_SOURCE_ID, {
                'type': 'geojson',
                'data': ContainerTools.EMPTY_SOURCE
            });
        }
        if (this.map.getSource(ths.GEOMETRY_VERTEX_SOURCE_ID) == null) {
            this.map.addSource(ths.GEOMETRY_VERTEX_SOURCE_ID, {
                'type': 'geojson',
                'data': ContainerTools.EMPTY_SOURCE
            });
        }
        if (this.map.getSource(ths.Highlight_Contours_SOURCE_ID) == null) {
            this.map.addSource(ths.Highlight_Contours_SOURCE_ID, {
                'type': 'geojson',
                'data': ContainerTools.EMPTY_SOURCE
            });
        }
    }
    @computed
    get activeContourNum(): number{
        if (this.state.curAddrContour == null) return -2;
        return this.state.curAddrContour[0];
    }
    isActiveContour(addrContour: AddrContour): boolean{
        return  GeometryUtils.isEqualAddrContour(addrContour, this.state.curAddrContour) || GeometryUtils.isEqualAddrContour(addrContour, this.state.highlightContourAddr);
    }

    protected installLayers(){
        let ths = this;
        //*********** слои

        if (this.map.getLayer(ths.GEOMETRY_FILL_LAYER_ID) == null) {
            if (this.Custom_GEOMETRY_FILL_Style){
                this.Custom_GEOMETRY_FILL_Style.id = ths.GEOMETRY_FILL_LAYER_ID;
                this.Custom_GEOMETRY_FILL_Style.source = ths.GEOMETRY_FILL_SOURCE_ID;
                this.addLayer(this.Custom_GEOMETRY_FILL_Style);
            } else this.addLayer({
                id: ths.GEOMETRY_FILL_LAYER_ID,
                type: 'fill',
                source: ths.GEOMETRY_FILL_SOURCE_ID,
                paint: <FillPaint>{
                    'fill-color': ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.colorFill,
                        this.style.colorFill
                    ],
                    "fill-opacity": ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.opacityFill,
                        this.style.opacityFill
                    ]
                }
            });
        }

        if (this.map.getLayer(ths.GEOMETRY_LINES_LAYEROVER_ID) == null) {
            if (this.Custom_GEOMETRY_LINES_LAYEROVER_Style){
                this.Custom_GEOMETRY_LINES_LAYEROVER_Style.id = ths.GEOMETRY_LINES_LAYEROVER_ID;
                this.Custom_GEOMETRY_LINES_LAYEROVER_Style.source = ths.GEOMETRY_LINES_SOURCE_ID;
                this.addLayer(this.Custom_GEOMETRY_LINES_LAYEROVER_Style);
            } else this.addLayer({
                id: ths.GEOMETRY_LINES_LAYEROVER_ID,
                type: 'line',
                source: ths.GEOMETRY_LINES_SOURCE_ID,
                paint: <LinePaint>{
                    "line-color": ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.colorLineOuter,
                        this.style.colorLineOuter
                    ],
                    "line-opacity": 0.5,
                    "line-width": this.style.widthLine + 1,
                }
            });
        }

        if (this.map.getLayer(ths.GEOMETRY_LINES_LAYER_ID) == null) {
            if (this.Custom_GEOMETRY_LINES_Style){
                this.Custom_GEOMETRY_LINES_Style.id = ths.GEOMETRY_LINES_LAYER_ID;
                this.Custom_GEOMETRY_LINES_Style.source = ths.GEOMETRY_LINES_SOURCE_ID;
                this.addLayer(this.Custom_GEOMETRY_LINES_Style);
            } else this.addLayer({
                id: ths.GEOMETRY_LINES_LAYER_ID,
                type: 'line',
                source: ths.GEOMETRY_LINES_SOURCE_ID,
                paint: <LinePaint>{
                    "line-color": ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.colorLine,
                        this.style.colorLine
                    ],
                    "line-width": ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.widthLine,
                        this.style.widthLine
                    ]
                }
            });
        }
        if (this.map.getLayer(ths.MOVED_LINE_OVERLAY_LAYER_ID) == null) {
            if (this.Custom_MOVED_LINE_OVERLAY_Style){
                this.Custom_MOVED_LINE_OVERLAY_Style.id = ths.MOVED_LINE_OVERLAY_LAYER_ID;
                this.Custom_MOVED_LINE_OVERLAY_Style.source = ths.MOVED_LINE_SOURCE_ID;
                this.addLayer(this.Custom_MOVED_LINE_OVERLAY_Style);
            } else this.addLayer({
                id: ths.MOVED_LINE_OVERLAY_LAYER_ID,
                type: 'line',
                source: ths.MOVED_LINE_SOURCE_ID,
                paint: <LinePaint>{
                    "line-color": ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.colorLineOuter,
                        this.style.colorLineOuter
                    ],
                    "line-width": ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.widthLine + 1,
                        this.style.widthLine + 1
                    ],
                    "line-opacity": 0.2,
                }
            });
        }

        if (this.map.getLayer(ths.MOVED_LINE_LAYER_ID) == null) {
            if (this.Custom_MOVED_LINE_Style){
                this.Custom_MOVED_LINE_Style.id = ths.MOVED_LINE_LAYER_ID;
                this.Custom_MOVED_LINE_Style.source = ths.MOVED_LINE_SOURCE_ID;
                this.addLayer(this.Custom_MOVED_LINE_Style);
            }else {
                this.addLayer({
                    id: ths.MOVED_LINE_LAYER_ID,
                    type: 'line',
                    source: ths.MOVED_LINE_SOURCE_ID,
                    paint: <LinePaint>{
                        "line-color": ['case',
                            ['get', 'active_contour'],
                            this.activeStyle.colorLine,
                            this.style.colorLine
                        ],
                        "line-width": ['case',
                            ['get', 'active_contour'],
                            this.activeStyle.widthLine,
                            this.style.widthLine
                        ],
                        "line-dasharray": [3, 1],
                    }
                });
            }
        }

        if (this.map.getLayer(ths.MOVED_FILL_LAYER_ID) == null) {
            if (this.Custom_MOVED_FILL_Style){
                this.Custom_MOVED_FILL_Style.id = ths.MOVED_FILL_LAYER_ID;
                this.Custom_MOVED_FILL_Style.source = ths.MOVED_LINE_SOURCE_ID;
                this.addLayer(this.Custom_MOVED_FILL_Style);
            } else this.addLayer({
                id: ths.MOVED_FILL_LAYER_ID,
                type: 'fill',
                source: ths.MOVED_LINE_SOURCE_ID,
                paint: <FillPaint>{
                    'fill-color': ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.colorFill,
                        this.style.colorFill
                    ],
                    "fill-opacity": ['case',
                        ['get', 'active_contour'],
                        this.activeStyle.opacityFill,
                        this.style.opacityFill
                    ]
                }
            });
        }

        if (this.map.getLayer(ths.GEOMETRY_VERTEX_LAYER_ID) == null) {
            if (this.Custom_GEOMETRY_VERTEX1_Style){
                this.Custom_GEOMETRY_VERTEX1_Style.id = ths.GEOMETRY_VERTEX_LAYER_ID;
                this.Custom_GEOMETRY_VERTEX1_Style.source = ths.GEOMETRY_VERTEX_SOURCE_ID;
                this.addLayer(this.Custom_GEOMETRY_VERTEX1_Style);
            } else this.addLayer({
                id: ths.GEOMETRY_VERTEX_LAYER_ID,
                type: 'circle',
                source: ths.GEOMETRY_VERTEX_SOURCE_ID,
                paint: <CirclePaint>{
                    'circle-radius':
                        ['case',
                            ["all",
                                ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.vertex],
                                ['==',['get', ViewGeometryLayersTool.first], true]
                            ],4,
                            3],
                    'circle-opacity': 0.9,
                    'circle-color':
                        ['case',
                            ['get', 'active_contour'],
                            ['case',
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.vertex],
                                    ['==',['get', 'last'], true]
                                ],this.activeStyle.colorFirstDotInner,
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.midpoint]
                                ], this.activeStyle.colorInsertDotInner,
                                this.activeStyle.colorSecondDotInner
                            ],
                            ['case',
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.vertex],
                                    ['==',['get', 'last'], true]
                                ],this.style.colorFirstDotInner,
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.midpoint]
                                ], this.style.colorInsertDotInner,
                                this.style.colorSecondDotInner
                            ]
                        ],

                    'circle-stroke-width': 1,

                    'circle-stroke-color':
                        ['case',
                            ['get', 'active_contour'],
                            ['case',
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.vertex],
                                    ['==',['get', ViewGeometryLayersTool.first], true]
                                ],this.activeStyle.colorFirstDotOuter,
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.midpoint]
                                ],this.activeStyle.colorInsertDotOuter,
                                this.activeStyle.colorSecondDotOuter
                            ],

                            ['case',
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.vertex],
                                    ['==',['get', ViewGeometryLayersTool.first], true]
                                ],this.style.colorFirstDotOuter,
                                ["all",
                                    ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.midpoint]
                                ],this.style.colorInsertDotOuter,
                                this.style.colorSecondDotOuter
                            ]
                        ]
                }
            });
            if (this.map.getLayer(ths.GEOMETRY_VERTEX2_LAYER_ID) == null) {
                if (this.Custom_GEOMETRY_VERTEX2_Style){
                    this.Custom_GEOMETRY_VERTEX2_Style.id = ths.GEOMETRY_VERTEX2_LAYER_ID;
                    this.Custom_GEOMETRY_VERTEX2_Style.source = ths.GEOMETRY_VERTEX_SOURCE_ID;
                    this.addLayer(this.Custom_GEOMETRY_VERTEX2_Style);
                } else this.addLayer({
                    id: ths.GEOMETRY_VERTEX2_LAYER_ID,
                    type: 'circle',
                    source: ths.GEOMETRY_VERTEX_SOURCE_ID,
                    filter:
                        ["all",
                            ["==", ["get", ViewGeometryLayersTool.point_type], DrawPointType.vertex],
                            ['==', ['get', ViewGeometryLayersTool.first], true]
                        ],
                    paint: <CirclePaint>{
                        'circle-radius': 2,
                        'circle-color': ['case',
                            ['get', 'active_contour'],
                            this.activeStyle.colorFirstDotOuter,
                            this.style.colorFirstDotOuter
                        ]
                    }
                });
            }
        }

    }
    public updateGeometry(){
        if (!this.map) return;
        let gia = this.state.simpleGeometry;
        this.vertexUpdate(gia);
        this.geometryLinesUpdate(gia);
        this.polygonUpdate(gia);

        this.doUpdateMovedPoint();
        this.map.triggerRepaint();
    }

    public updateMovedPoint(){
        this.doUpdateMovedPoint();
        this.map.triggerRepaint();
    }

    private doUpdateMovedPoint(){
        let line: ContourPoints = [];
        let isPolygon = false;
        if (this.state.createGeometryType == CreateGeometryType.RectangleSecondPoint){
            if (this.state.movedPrevPointCoord != null && this.state.movedPointCoord != null) {
                let p1 = this.state.movedPrevPointCoord;
                let p2 = this.state.movedPointCoord;
                line.push(p1);
                line.push([p1[0], p2[1]]);
                line.push(p2);
                line.push([p2[0], p1[1]]);
                line.push(p1);
                isPolygon = true;
            }
        }else {
            if (this.state.movedPrevPointCoord != null) {
                line.push(this.state.movedPrevPointCoord);
            }

            if (this.state.movedPointCoord != null) {
                line.push(this.state.movedPointCoord);
                //gia.push({simple: SimpleGeometryType.Point, contour: [this.state.movedPointCoord], holes: []});
            }
            if (this.state.movedNextPointCoord != null) {
                line.push(this.state.movedNextPointCoord);
            }
        }
        let srcLine = this.map.getSource(this.MOVED_LINE_SOURCE_ID);
        if (srcLine != null) {
            if (line.length == 0) {
                (<GeoJSONSource>srcLine).setData(GeometryUtils.createEmptyCollection());
            } else {

                let g = GeometryUtils.createGeometryBySimpleGeometry([{
                    simple: (isPolygon)?SimpleGeometryType.Polygon:SimpleGeometryType.Line,
                    contour: line,
                    holes: []
                }]);
                let geom: Feature = {
                    type: "Feature",
                    geometry: g,
                    properties: {
                        [ViewGeometryLayersTool.active_contour]: this.state.events.isCreateGeometry?this.state.events.isCreateGeometry():false,
                        [ViewGeometryLayersTool.ContourNumProperty]:
                            (this.state.curAddrContour != null && this.state.curAddrContour.length > 0) ? this.state.curAddrContour[0] : -5
                    },
                };
                (<GeoJSONSource>srcLine).setData(geom);
            }
        }
    }

    protected polygonUpdate(gia: ISimpleGeometry[]){
        let srcGeom = this.map.getSource(this.GEOMETRY_FILL_SOURCE_ID);

        if (gia.length > 0) {
            let collection = <FeatureCollection>{'type': 'FeatureCollection', 'features': []};
            gia.forEach((p, index) =>{
                if (p.simple != SimpleGeometryType.Polygon) return;
                let g = GeometryUtils.createGeometryBySimpleGeometry([p]);
                let addrCont: AddrContour = [index, 0];
                let g2: Geometry = cloneDeep(g);
                let feature: Feature = {
                    type: "Feature",
                    geometry: g2,
                    properties: {
                        [ViewGeometryLayersTool.active_contour]: this.isActiveContour(addrCont),
                        [ViewGeometryLayersTool.ContourNumProperty]: index
                    },
                };
                collection.features.push(feature);
            });
            (<GeoJSONSource>srcGeom).setData(collection);
        }else{
            (<GeoJSONSource>srcGeom).setData(GeometryUtils.createEmptyCollection());
        }
    }

    protected geometryLinesUpdate(gia: ISimpleGeometry[]){
        let srcGeom = this.map.getSource(this.GEOMETRY_LINES_SOURCE_ID);
        let features: FeatureCollection = {type: "FeatureCollection", features: []};

        gia.forEach((g, index) =>{
            if (g.simple != SimpleGeometryType.Polygon) return;
            if (g.contour.length > 0){
                let addrCont: AddrContour = [index, 0];
                features.features.push(
                    {
                        type: "Feature",
                        geometry: GeometryUtils.createGeometryBySimpleGeometry([{simple: SimpleGeometryType.Line, contour: GeometryUtils.closeRing(g.contour), holes: []}]),
                        properties: {
                            [ViewGeometryLayersTool.ContourAddrProperty]: JSON.stringify(addrCont),
                            [ViewGeometryLayersTool.active_contour]: this.isActiveContour(addrCont),
                            [ViewGeometryLayersTool.ContourNumProperty]: index,
                            [ViewGeometryLayersTool.IsPolygonProperty]: true,
                            [ViewGeometryLayersTool.IsHoleProperty]: false,
                        }
                    }
                );
            }
            if (g.holes != null){
                g.holes.forEach((h, index_hole) => {
                    let addrCont: AddrContour = [index, index_hole + 1];
                    if (h.length > 0) features.features.push(
                        {
                            type: "Feature",
                            geometry: GeometryUtils.createGeometryBySimpleGeometry([{simple: SimpleGeometryType.Line, contour: GeometryUtils.closeRing(h), holes: []}]),
                            properties: {
                                [ViewGeometryLayersTool.ContourAddrProperty]: JSON.stringify(addrCont),
                                [ViewGeometryLayersTool.active_contour]: this.isActiveContour(addrCont),
                                [ViewGeometryLayersTool.ContourNumProperty]: index,
                                [ViewGeometryLayersTool.IsPolygonProperty]: true,
                                [ViewGeometryLayersTool.IsHoleProperty]: true,
                            }
                        }
                    );
                });
            }
        });

        gia.forEach((g, index) =>{
            if (g.simple != SimpleGeometryType.Line) return;
            if (g.contour.length > 0){
                let addrCont: AddrContour = [index, 0];
                features.features.push(
                    {
                        type: "Feature",
                        geometry: GeometryUtils.createGeometryBySimpleGeometry([{simple: SimpleGeometryType.Line, contour: g.contour, holes: []}]),
                        properties: {
                            [ViewGeometryLayersTool.ContourAddrProperty]: JSON.stringify([index, 0]),
                            [ViewGeometryLayersTool.active_contour]: this.isActiveContour(addrCont),
                            [ViewGeometryLayersTool.ContourNumProperty]: index,
                            [ViewGeometryLayersTool.IsPolygonProperty]: false,
                        }
                    }
                );

            }
        });

        (<GeoJSONSource>srcGeom).setData(features);
    }

    protected vertexUpdate(gia: ISimpleGeometry[]){
        let pnts: FeatureCollection = {type: "FeatureCollection", features: []};
        if (this.state.midPoint != null){
            let f = this.createPoint(this.state.midPoint.point, this.state.midPoint.afterPointAddr);
            f.properties[ViewGeometryLayersTool.point_type] = DrawPointType.midpoint;
            pnts.features.push(f);
        }
        gia.forEach((gi, index_geom) =>{
            gi.contour.forEach((p, index_p) => {
                let addrPoint: AddrPoint = [index_geom, 0, index_p];
                let red_point = (gi.simple == SimpleGeometryType.Polygon && index_p == 0 && GeometryUtils.isEqualAddrContour(this.state.curAddrContour, GeometryUtils.getAddrContourByAddrPoint(addrPoint)));
                pnts.features.push(this.createPoint(p, addrPoint, red_point));
            })
            if (gi.holes != null){
                gi.holes.forEach((h, index_c)=>{
                    h.forEach((p, index_p) => {
                        let addrPoint: AddrPoint = [index_geom, index_c + 1, index_p];
                        let red_point = (gi.simple == SimpleGeometryType.Polygon && index_p == 0 && GeometryUtils.isEqualAddrContour(this.state.curAddrContour, GeometryUtils.getAddrContourByAddrPoint(addrPoint)));
                        pnts.features.push(this.createPoint(p, addrPoint, red_point));
                    })
                });
            }
        });

        let src1 = this.map.getSource(this.GEOMETRY_VERTEX_SOURCE_ID);
        if (src1 != null) {
            (<GeoJSONSource>src1).setData(pnts);
        }
    }
    createPoint(p: Position, addr: AddrPoint, first: boolean = false): Feature{

        let f: Feature = {
            type: "Feature",
            properties: {},
            geometry: {type: "Point", coordinates: cloneDeep(p)}
        };
        f.properties[ViewGeometryLayersTool.point_type] = DrawPointType.vertex;
        f.properties[ViewGeometryLayersTool.index] = JSON.stringify(addr);
        f.properties[ViewGeometryLayersTool.first] = first;
        f.properties[ViewGeometryLayersTool.active_contour] = this.isActiveContour(GeometryUtils.getAddrContourByAddrPoint(addr));
        f.properties[ViewGeometryLayersTool.ContourNumProperty] = addr[0];
        f.properties[ViewGeometryLayersTool.ContourAddrProperty] = JSON.stringify(GeometryUtils.getAddrContourByAddrPoint(addr));
        f.id = f.properties[ViewGeometryLayersTool.index];

        return f;
    }

    getNearGeoPoint(point: mapboxgl.Point): Position{
        let oclick = this.getObjectByClick(point);
        let p = oclick.pointCoord;
        if (p != null) return p;
        let ll = this.map.unproject(point);
        p = MBUtils.llToPosition(ll);
        return p;
    }

    getObjectByClick(point: mapboxgl.Point):IObjectByClick {
        let r: IObjectByClick = {pointIndex: null, pointType: null, pointCoord: null, layerId: null, sourceId: null, pointInternal: null};
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(point.x - 3, point.y - 3),
            new mapboxgl.Point(point.x + 3, point.y + 3)
        ];
        let res: MapboxGeoJSONFeature[] = this.map.queryRenderedFeatures(bbox,
            {layers:[this.GEOMETRY_VERTEX_LAYER_ID]});
        if (res.length == 0) return r;

        let bestIndex: AddrPoint = null;
        let bestDist: number = Number.MAX_VALUE;
        let bestType: DrawPointType = null;
        let bestCoord: Position = null;
        let bestRes: MapboxGeoJSONFeature = null;
        res.forEach(a => {
            if (a.properties[ViewGeometryLayersTool.point_type] == "vertex" || a.properties[ViewGeometryLayersTool.point_type] == "midpoint"){
                let scrP = this.map.project(MBUtils.positionToLL((<Point>a.geometry).coordinates));
                let d = GeometryUtils.distance(MBUtils.pointToPosition(point), MBUtils.pointToPosition(scrP));
                if (a.properties[ViewGeometryLayersTool.point_type] == "midpoint") d = d + 2;//понижаем приоритет
                if (d < bestDist){
                    bestDist = d;
                    bestIndex = JSON.parse(a.properties[ViewGeometryLayersTool.index]);
                    bestType = a.properties[ViewGeometryLayersTool.point_type] as DrawPointType;
                    bestCoord = (<Point>a.geometry).coordinates;
                    bestRes = a;
                }
            }
        });
        if (bestIndex != null){
            r.pointIndex = bestIndex;
            r.pointType = bestType;
            r.pointCoord = bestCoord;
            if (bestRes.source != null)
                r.sourceId = bestRes.source;
            if (bestRes.layer != null)
                r.layerId = bestRes.layer.id;
            r.pointInternal = bestRes;
        }
        return r;
    }

}