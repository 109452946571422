import {CustomStore} from "../CustomStore";
import {A2AgroStore} from "./A2AgroStore";

export enum ProjectRole{
    admin = 'admin',
    edit = 'edit',
    view = 'view'
}

export class A2PermissionStore extends CustomStore<A2AgroStore>{
    readonly defaultRole = ProjectRole.admin;
    role: ProjectRole = ProjectRole.admin;

    canCreateObject(): boolean{
        return this.isEditRole();
    }
    canEditObject(): boolean{
        return this.isEditRole();
    }
    canDeleteObject(): boolean{
        return this.isEditRole();
    }

    private isEditRole(): boolean{
        return this.role == ProjectRole.admin || this.role == ProjectRole.edit;
    }
}