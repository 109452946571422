import * as React from 'react';
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import autoBindReact from "auto-bind/react";

export interface IOrderActionDownloadCompProps extends IStoreProps{
    order_id: string
}

@observer
export class OrderActionDownloadComp extends React.PureComponent<IOrderActionDownloadCompProps, undefined> {
    constructor(props: IOrderActionDownloadCompProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className="journal-action journal-status" >
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 7L10.5 3.5H7.875V0H6.125V3.5H3.5L7 7Z" />
                <path d="M13.125 0H10.5V1.75H12.25V8.75H1.75V1.75H3.5V0H0.875C0.35 0 0 0.35 0 0.875V13.125C0 13.65 0.35 14 0.875 14H13.125C13.65 14 14 13.65 14 13.125V0.875C14 0.35 13.65 0 13.125 0Z"/>
            </svg>
            <div className="journal-status-name">{store.trans.Download}</div>
        </div>
    }
}
