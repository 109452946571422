import * as React from "react";
import {Suspense} from "react";
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IProductItemComp} from "./ProductItemComp";
import {
    ClassValue,
    IndexBandProductStore,
    Interpolation
} from "../../../../store/productSetting/IndexBandProductStore";
import classNames from "classnames";
import {ProductItemIndexBandColorsComp} from "./ProductItemIndexBandColorsComp";
import {action, reaction} from "mobx";
import {IDropDownItem} from "../../../Common/DropDownComp";
import {PresetColorComp} from "./PresetColorComp";
import {Utils} from "../../../../helper/utils/Utils";
import {DelayRunOnce} from "../../../../helper/utils/DelayRunOnce";
import {fetchJsonPost} from "../../../../helper/utils/FetchUtils";
import {ra} from "../../../../helper/utils/mobxUtils";
import {InputNumber} from "../../../Common/InputNumber";
import {LeftPanelMode} from "../../../../store/SearchStore";
import {InputNumberArrow} from "../../../Common/InputNumberArrow";
import {ISuperStore} from "../../../../../pluginApi/store/SuperStore";

const ProductItemIndexBandColor2Comp = React.lazy(
    () => import("./ProductItemIndexBandColor2Comp")
);
const ProductItemIndexBandColorInput2Comp = React.lazy(
    () => import("./ProductItemIndexBandColorInput2Comp")
);

@observer
export class ProductItemIndexBandComp extends React.Component<
    IProductItemComp,
    undefined
> {
    constructor(props: IProductItemComp) {
        super(props);
        autoBindReact(this);
    }

    delay: DelayRunOnce = new DelayRunOnce();

    @action
    onDefault() {
        this.props.item.loadDefault();
        this.props.item.changed = true;
    }

    @action
    onMaxChange(num: number) {
        if (num == null || num < this.props.item.indexBand.min) {
            return this.props.item.indexBand.max.toString();
        }
        this.props.item.indexBand.max = num;
        this.props.item.changed = true;
        this.props.item.indexBand.recalculateClassValuesPartial();
    }
    @action
    onMinChange(num: number) {
        if (num == null || num > this.props.item.indexBand.max) {
            return this.props.item.indexBand.min.toString();
        }
        this.props.item.indexBand.min = num;
        this.props.item.indexBand.recalculateClassValuesPartial();
        this.props.item.changed = true;
    }
    @action
    onClassesChange(n: number) {
        if (n == null) return;
        this.props.item.indexBand.classes = n;
        this.props.item.indexBand.recalculateClassValuesFull();
        this.props.item.changed = true;
    }
    @action
    onClassesKeyPress(e: any) {
        if (e.charCode == 13) {
            this.props.item.indexBand.recalculateClassValuesFull();
            this.props.item.changed = true;
        }
    }

    @action
    onChangeInterpolation(item: IDropDownItem, newIndex: number) {
        this.props.item.indexBand.interpolation =
            item.key as Interpolation;
        this.props.item.changed = true;
    }
    @action
    onChangeColorPreset(newPresetName: string): void {
        let store = this.props.store;
        let p = store.map.presetColors.find(
            a => a.id == newPresetName
        );
        this.props.item.indexBand.colors = p;
        this.props.item.changed = true;
    }

    @action
    onChangeInterpolation2(e: any) {
        let att = e.currentTarget.getAttribute("data-typename");
        let item = this.props.item;
        item.indexBand.interpolation = att;
        this.props.item.changed = true;
    }
    
    public static getScenes(store: ISuperStore): string[] {
        let fav_scenes: string[] = store.searchPanel.favoriteList
            .getAllSceneItems()
            .map(a => a.sceneId());
        let active_scenes: string[] =
            store.searchPanel.searchResult.currentItems.map(a =>
                a.sceneId()
            );
        if (store.searchPanel.leftPanelMode == LeftPanelMode.search)
            return active_scenes;
        return fav_scenes;
    }

    enabledMinMax(): boolean {
        let store = this.props.store;
        return (
            ProductItemIndexBandComp.getScenes(store).length > 0 &&
            store.map.searchObject.isNotEmpty
        );
    }
    async getMinMax() {
        if (!this.enabledMinMax()) return;
        let store = this.props.store;
        let item = this.props.item;
        try {
            let scenes = ProductItemIndexBandComp.getScenes(store);
            let zone: any = store.map.searchObject.searchGeometry;
            let p = {
                mode: "quantile",
                q: "0,1",
                product: item.prod_name,
                scene_id: scenes.join(","),
                zone: JSON.stringify(zone)
            };
            //?mode=minmax&product=${item.prod_name}&scene_id=${scenes.join(",")}&zone=${JSON.stringify(zone)}`);
            let res = (await fetchJsonPost(
                `/api/raster/stat/area`,
                p
            )) as {vals: number[]};
            ra(() => {
                console.log("",res)
                this.props.item.indexBand.min = res.vals[0];
                this.props.item.indexBand.max =
                    res.vals[res.vals.length - 1];
                this.props.item.indexBand.recalculateClassValuesFull();
                this.props.item.changed = true;
            });
        } catch (e) {
            store.addError(e);
        }
    }
    enabledAreaEquals(): boolean {
        let index: IndexBandProductStore = this.props.item.indexBand;
        let store = this.props.store;
        let classes = Utils.parseNumber(index.classes);
        if (
            index.interpolation != Interpolation.discrete ||
            ProductItemIndexBandComp.getScenes(store).length == 0 ||
            classes < 2 ||
            store.map.searchObject.isEmpty
        )
            return false;
        return true;
    }
    async getAreaEquals() {
        let store = this.props.store;
        let item = this.props.item;
        let index: IndexBandProductStore = this.props.item.indexBand;
        if (!this.enabledAreaEquals()) return;
        let classes = Utils.parseNumber(index.classes);

        let q: number[] = [];
        let t = 1 / classes;
        for (let i = 1; i < classes; i++) {
            q.push(i * t);
        }

        try {
            let scenes = ProductItemIndexBandComp.getScenes(store);

            let zone: any = store.map.searchObject.searchGeometry; //{"dskey": store.agro.datasetVField, "filters": {"field_id": {"$in": field_ids}}};
            let p = {
                q: q.map(a => a.toString()).join(","),
                mode: "quantile",
                product: item.prod_name,
                scene_id: scenes.join(","),
                zone: JSON.stringify(zone)
            };
            let res = (await fetchJsonPost(
                `/api/raster/stat/area`,
                p
            )) as {vals: number[]};
            ra(() => {
                this.props.item.indexBand.min = res.vals[0];
                let vals: number[] = [];
                for (let i = 1; i < res.vals.length - 1; i++)
                    vals.push(res.vals[i]);
                this.props.item.indexBand.classValues2 =
                    vals.map<ClassValue>(a => {
                        return ClassValue.create(
                            this.props.item.indexBand,
                            a,
                            true
                        );
                    });
                this.props.item.indexBand.max =
                    res.vals[res.vals.length - 1];
                this.props.item.changed = true;
            });
        } catch (e) {
            store.addError(e);
        }
    }
    async getIntervalEquals() {
        await this.getAreaEquals();
        let index: IndexBandProductStore = this.props.item.indexBand;
        reaction(
            () => [index.min, index.max],
            ([newMin, newMax]) => {    
                let classes = Utils.parseNumber(index.classes);
    
                if (newMin == null || newMax == null || classes < 2) {
                    return;
                }
    
                let step = (newMax - newMin) / (classes );
                let vals: number[] = [];
    
                for (let i = 1; i < classes; i++) {
                    let value = newMin + step * i;
                    vals.push(value);
                }
    
                ra(() => {
                    this.props.item.indexBand.classValues2=(
                        vals.map<ClassValue>(a =>
                            ClassValue.create(
                                this.props.item.indexBand,
                                a,
                                true
                            )
                        )
                    );
                    this.props.item.changed = true;
                });
            },
            { fireImmediately: true } 
        );
    }
    render() {
        let store = this.props.store;
        let item = this.props.item;
        let isActiveArea = store.map.searchObject.isNotEmpty
        let index: IndexBandProductStore = this.props.item.indexBand;
        let child: any[] = [];
        let isDisable = store.searchPanel.leftPanelMode != LeftPanelMode.favorite && !!!store.searchPanel.currentSceneid 
        child =
            this.props.store.events.onNdviProductItemChildren.call(
                child,
                this.props
            );
        return (<>
            <div className={classNames("style-4", "ProductItemIndexBandComp", {
                                       "ProductItemIndexBandComp_disable": isDisable
                                      })}
            >
                <div className='flex-rows margin-bottom20'>
                    <div className='ProductItemComp-textLabel-m'>
                        {store.trans.Palette}
                    </div>
                    <PresetColorComp
                        store={store}
                        currentPreset={index.colors.id}
                        onChange={this.onChangeColorPreset}
                    />
                </div>
                <div className='ProductItemIndexBandComp-line margin-bottom20' />
                <div className='ProductItemComp-props-line ProductItemIndexBandComp-nextLine-div'>
                    <div className='ProductItemIndexBandComp-interpolationSwitch'>
                        <div
                            onClick={this.onChangeInterpolation2}
                            data-typename={Interpolation.linear}
                            className={classNames(
                                "popup-ndvi-in__view-row-tileMode-item full-center-content item-left ProductItemIndexBandComp-interpolationSwitchItem",
                                {
                                    "active-button":
                                        index.interpolation ==
                                        Interpolation.linear
                                }
                            )}
                        >
                            {store.trans["Gradient"]}
                        </div>
                        <div
                            onClick={this.onChangeInterpolation2}
                            data-typename={Interpolation.discrete}
                            className={classNames(
                                "popup-ndvi-in__view-row-tileMode-item full-center-content item-right ProductItemIndexBandComp-interpolationSwitchItem",
                                {
                                    "active-button":
                                        index.interpolation ==
                                        Interpolation.discrete
                                }
                            )}
                        >
                            {store.trans["Discrete"]}
                        </div>
                    </div>
                </div>
                {index.interpolation == Interpolation.linear && (
                    <div className='ProductItemIndexBandComp-colors-div'>
                        <ProductItemIndexBandColorsComp
                            item={this.props.item}
                            store={this.props.store}
                            productCode={this.props.productCode}
                        />
                    </div>
                )}
                {index.interpolation == Interpolation.discrete && (
                    <div className='ProductItemIndexBandComp-colors2-div'>
                        <Suspense
                            fallback={
                                <div style={{height: "29px"}}>
                                    {store.trans.Downloading}...
                                </div>
                            }
                        >
                            <ProductItemIndexBandColor2Comp
                                item={this.props.item}
                                store={this.props.store}
                            />
                        </Suspense>
                    </div>
                )}
                <div className='ProductItemIndexBandComp-mmDiv'>
                    <div className='ProductItemIndexBandComp-mmDivItem'>
                        <div className='ProductItemIndexBandComp-value-title'>
                            {store.trans["Minimum"]}
                        </div>
                        <InputNumber
                            onChangeNumber={this.onMinChange}
                            hasStandartStyles={false}
                            changeOnExitOrEnter={true}
                            className='text-box-editor number-without-arrow ProductItemIndexBandComp-mmDivItemLabelInput blue-border-onfocus'
                            min={item.getIndexStyle.min}
                            max={item.getIndexStyle.max}
                            value={index.min.toFixed(2)}
                        />
                        <div className='ProductItemIndexBandComp-mmDivItemLabelDiv'>
                            <div className='ProductItemIndexBandComp-mmDivItemLabelText'>
                                {store.trans['Absolute min']}:{" "}
                                {item.getIndexStyle.min}
                            </div>
                        </div>
                    </div>
                    <div className='flex-stretch-item '>
                        {/* {store.map.searchObject.isNotEmpty && (
                            <div className='yellow text-center'>
                                {
                                    store.trans[
                                        "Active area is defined"
                                    ]
                                }
                            </div>
                        )} */}
                    </div>
                    <div className='ProductItemIndexBandComp-mmDivItem'>
                        <div className='right-align ProductItemIndexBandComp-value-title'>
                            {store.trans["Maximum"]}
                        </div>
                        <InputNumber
                            onChangeNumber={this.onMaxChange}
                            hasStandartStyles={false}
                            changeOnExitOrEnter={true}
                            className='text-box-editor number-without-arrow ProductItemIndexBandComp-mmDivItemLabelInput blue-border-onfocus flex-align-items-end ProductItemIndexBandComp-max'
                            min={item.getIndexStyle.min}
                            max={item.getIndexStyle.max}
                            value={index.max.toFixed(2)}
                        />
                        <div className='ProductItemIndexBandComp-mmDivItemLabelDiv right-align'>
                            <div className='ProductItemIndexBandComp-mmDivItemLabelText'>
                                {store.trans['Absolute max']}:{" "}
                                {item.getIndexStyle.max}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames('ProductItemComp-props-line ProductItemIndexBandComp-nextLine-div ',
                    {'ProductItemComp-props-classes': index.interpolation ==
                        Interpolation.discrete}
                )}>
                    {index.interpolation ==
                        Interpolation.discrete && (
                        <>
                            <span className='ProductItemComp-textLabel-m ProductItemComp-textLabel-inputLabel'>
                                {store.trans["Number of classes"]}
                            </span>
                            <div className='flex-columns flex-align-items-center'>
                                <InputNumberArrow
                                    value={index.classes}
                                    onClassesKeyPress={
                                        this.onClassesKeyPress
                                    }
                                    changeOnExitOrEnter={true}
                                    onChangeNumber={
                                        this.onClassesChange
                                    }
                                    classNameInput='text-box-editor number-without-arrow ProductItemIndexBandComp-edit-classes flex-strech-item blue-border-onfocus margin-right-10'
                                    min={2}
                                    max={7}
                                    arrowUpClassName='ProductItemComp-custom-arrow-up'
                                    arrowDownClassName='ProductItemComp-custom-arrow-down'
                                />
                                <span
                                    className='ProductItemComp-textLabel-m ProductItemComp-textLabel-inputLabel'
                                    style={{marginBottom: 0}}
                                >
                                    {store.trans.Maximum}
                                      {": 7"}
                                </span>
                            </div>
                            <div className='ProductItemComp-textClasses'> {store.trans["Class thresholds:"]}</div>
                            <Suspense
                                fallback={
                                    <div style={{height: "29px"}}>
                                        {store.trans.Downloading}...
                                    </div>
                                }
                            >
                                <ProductItemIndexBandColorInput2Comp
                                    item={this.props.item}
                                    store={this.props.store}
                                />
                            </Suspense>
                        </>
                    )}
                    <div className='flex-stretch-item' />
                </div>
                <div className='ProductItemIndexBandComp-divMinMax'>
                    <div className='ProductItemIndexBandComp-divMinMax-Label-row1'>
                    {index.interpolation ==
                            Interpolation.discrete && (
                        <div className='ProductItemIndexBandComp-divMinMax-Label flex-stretch-item'>
                        {store.trans[
                            "Calculate min-max and thresholds by Area of Interest"
                        ]}
                        </div>
                    )}
                    {index.interpolation ==
                            Interpolation.linear && (
                        <div className='ProductItemIndexBandComp-divMinMax-Label flex-stretch-item'>
                        {store.trans[
                            "Calculate min-max by Area of Interest"
                        ]}
                        </div>
                    )}
                        <div>
                            <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z'
                                    stroke='#4DB6BC'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z'
                                    fill='#4DB6BC'
                                />
                                <path
                                    d='M8 12.5V7'
                                    stroke='#4DB6BC'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        </div>
                    </div>
                   {!isActiveArea && <div className="ProductItemIndexBandComp-divMinMax-Label-title">
                    <span>  {store.trans["Necessary to specify the"]}{" "}</span>
                    <span>  {store.trans["Area of Interest"]}</span>
                    </div>}
                   {isActiveArea && <div className='right-align flex-center-content journal-table-all '>
                        {index.interpolation ==
                            Interpolation.linear && (
                            <button disabled={!this.enabledMinMax()}
                                onClick={this.getMinMax}
                                className={classNames(
                                    "ProductItemIndexBandComp-divMinMax-Button full-center-content",
                                    {disabled: !this.enabledMinMax()}
                                )}
                            >
                                {store.trans["Min-Max"]}
                            </button>
                        )}

                        {index.interpolation ==
                            Interpolation.discrete && (
                            <>
                                <button disabled={!this.enabledMinMax()}
                                    onClick={this.getIntervalEquals}
                                    className={classNames(
                                        "ProductItemIndexBandComp-divMinMax-Button ProductItemPropComp-button-next  full-center-content",
                                        {
                                            disabled:
                                                !this.enabledMinMax()
                                        }
                                    )}
                                >
                                    {store.trans["Equal intervals"]}
                                </button>
                                <button disabled={!this.enabledAreaEquals()}
                                    onClick={this.getAreaEquals}
                                    className={classNames(
                                        "ProductItemIndexBandComp-divMinMax-Button ProductItemPropComp-button-next  full-center-content",
                                        {
                                            disabled:
                                                !this.enabledAreaEquals()
                                        }
                                    )}
                                >
                                    {store.trans["Equal area"]}
                                </button>
                            </>
                        )}
                    </div>}
                </div>
               
            </div>
            <div
                    key='footer'
                    className={classNames(
                        "ProductItemIndexBandComp-footer",
                        // {
                        //     "ProductItemIndexBandComp_disable": isDisable
                        //    }
                    )}
                >
                    {child}
                    <div className='flex-nowrap flex-center-content'>
                    </div>
                    <div
                        onClick={this.onDefault}
                        key='cancel'
                        className={classNames(
                            "ProductItemPropComp-button",
                            "active_on_hover white-fill pointer",
                            "full-center-content"
                        )}
                    >
                        {store.trans.Default}
                    </div>
                </div>
            </>
        );
    }
}
