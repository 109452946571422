import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoLegendRNPanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";

@observer
export class AgroAhoLegendRNPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    isMinimized: boolean = false;

    onMinClick(){
        this.isMinimized = !this.isMinimized;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        // let sel_indc = store_.cardStore.selectedIndicator;
        // console.log('selected indicator', store_.cardStore.selectedIndicator);

        // let list = sel_indc?.visibility?.scales?.agrosoft;
        // if (store_.cardStore.indcFormat == AhoIndicatorFormat.Agroplem)
        //     list = sel_indc?.visibility?.scales?.agroplem;


        // let noAgrosoft = !sel_indc?.visibility?.scales?.agrosoft?.length;
        // let list = noAgrosoft ? sel_indc.visibility.scales.agroplem : sel_indc.visibility.scales.agrosoft;

        // let list = store_.cardStore.selectedIndicator?.info?.visibility;
        let list = store_.cardStore.cardRNStore.getRNInfo();
        if (!list) return <div> </div>;
        // list = list.slice().reverse();
        // console.log('data:', JSON.stringify(list));

        let arr: any[] = [];
        for (let i=0; i<list.length; i++) {
            let item = list[i];
            let val = item.max + '';
            let class_ = 'AgroAhoLegendRNPanelComp-ballon';
            if (i === 0) {
                val = '';
                class_ += ' AgroAhoLegendRNPanelComp-ballonTop';
            }
            if (i === list.length - 1) {
                class_ += ' AgroAhoLegendRNPanelComp-ballonBottom';
            }
            arr.push(<tr key={i}>
                {/*<td>{val}</td>*/}
                <td>
                    {/*{i == 0 && <div className={'AgroAhoLegendRNPanelComp-topLine'}> </div>}*/}
                    <div className={class_} style={{backgroundColor: item.color}}> </div>
                    {/*{i != 0 && <div className='AgroAhoLegendRNPanelComp-bottomLine'> </div>}*/}
                    <div className='AgroAhoLegendRNPanelComp-centerLine'> </div>
                </td>
                <td>
                    <div className='AgroAhoLegendRNPanelComp-name'>{item.name}</div>
                </td>
            </tr>);
        }
        let fert_id = store_.mapStore.mapRNStore.curRecNormItem.fert_id;
        let title = store_.uploadTableStore.getFertName(fert_id);
        // className={classNames("tswitcher-item tswitcher-item-right",{
        //     "tswitcher-disabledItem": check1 && this.props.disabledUnchecked,
        //         "tswitcher-checked": !check1})}

            // arr.push(<ShowRoom2FiltersListItem item={a} onCheck={this.props.onCheck} onUncheck={this.props.onUncheck} store={this.props.store} key={a.id} />);

        return <div className={classNames("AgroAhoLegendRNPanelComp-main",{
            "AgroAhoLegendRNPanelComp-minimized": this.isMinimized})}>
            <div className="AgroAhoLegendRNPanelComp-title">{title}</div>
            <table className="AgroAhoLegendRNPanelComp-table">
                <tbody>
                    {arr}
                </tbody>
            </table>
            <div className="AgroAhoLegendRNPanelComp-unit">{store_.trans['kg/ha']}</div>
            <svg className="AgroAhoLegendRNPanelComp-min" onClick={this.onMinClick} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.99998 8V7H6.29298L3.64648 4.3535L4.35348 3.6465L6.99998 6.293V4H7.99998L7.99998 7.5C7.99998 7.776 7.77598 8 7.49998 8H3.99998Z" fill="#C5C5C5"/>
                <path d="M8 0L0.5 0C0.224 0 0 0.224 0 0.5L0 8H1L1 1L8 1L8 0Z" fill="#C5C5C5"/>
            </svg>
            <div className="AgroAhoLegendRNPanelComp-minCaption">{this.props.store.trans.Legend}</div>
        </div>
    }
}
