// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonLight {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 3px 8px 2px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4DB6BC;
  fill: #4DB6BC;
  border: solid 1px #4DB6BC;
}

div.ButtonLight-disabled {
  opacity: 0.3;
  cursor: default;
}

div.ButtonLight-blueBackground {
  color: #2B3138;
  fill: #2B3138;
  background: #4DB6BC;
  border: solid 1px #4DB6BC;
}

div.ButtonLight-redBorder {
  color: #E94F4F;
  border: solid 1px #E94F4F;
}

div.ButtonLight-redBackground {
  color: #2B3138;
  background: #E94F4F;
  border: solid 1px #E94F4F;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/ButtonLight.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,wBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,yBAAA;AACF;;AACA;EACE,YAAA;EACA,eAAA;AAEF;;AAAA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAGF;;AADA;EACE,cAAA;EACA,yBAAA;AAIF;;AAFA;EACE,cAAA;EACA,mBAAA;EACA,yBAAA;AAKF","sourcesContent":[".ButtonLight{\r\n  display: flex;\r\n  align-items: center;\r\n  border-radius: 6px;\r\n  padding: 3px 8px 2px 8px;\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  color: #4DB6BC;\r\n  fill: #4DB6BC;\r\n  border: solid 1px #4DB6BC;\r\n}\r\ndiv.ButtonLight-disabled{\r\n  opacity: 0.3;\r\n  cursor: default;\r\n}\r\ndiv.ButtonLight-blueBackground{\r\n  color: #2B3138;\r\n  fill: #2B3138;\r\n  background: #4DB6BC;\r\n  border: solid 1px #4DB6BC;\r\n}\r\ndiv.ButtonLight-redBorder{\r\n  color: #E94F4F;\r\n  border: solid 1px #E94F4F;\r\n}\r\ndiv.ButtonLight-redBackground{\r\n  color: #2B3138;\r\n  background: #E94F4F;\r\n  border: solid 1px #E94F4F;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
