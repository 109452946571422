import {ShowRoom2Clickable, ShowRoom2LayerCustomTool} from "./ShowRoom2LayerCustomTool";
import * as mapboxgl from "maplibre-gl";
import {FillPaint, LinePaint, MapboxGeoJSONFeature, PointLike} from "maplibre-gl";
import {action, autorun} from "mobx";
import {fetchJsonGet} from "../../../helper/utils/FetchUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {ra} from "../../../helper/utils/mobxUtils";
import {DataShowRoomMode} from "../../dataShowroom2/ShowRoom2Store";
import {ShowroomLayerType} from "../../dataShowroom2/ShowRoom2LayerStore";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class ShowRoom2LayerDistrictsTool extends ShowRoom2LayerCustomTool implements ShowRoom2Clickable{

    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent) {
        //super.onMouseMove(e);
        if (!this.layerVisible()) return;
        let map = this.store.map.mapbox;
        if (!map.getSource(this.getSourceId())) return;
        let point = e.point;
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(point.x - 1, point.y - 1),
            new mapboxgl.Point(point.x + 1, point.y + 1)
        ];
        let res: MapboxGeoJSONFeature[] = this.map.queryRenderedFeatures(bbox,
            {layers: [this.layerIdFill()]});
        if (res.length > 0) {
            e.cursor = 'pointer';
        }
    }

    @action
    loadPopupById(id: any, feature: MapboxGeoJSONFeature):void{
        this.store.dataShowRoomStore.mapPopup.showRoom2LayerName = ShowroomLayerType.districts;
        let mp = this.store.dataShowRoomStore.mapPopup;
        mp.status = LoadStatus.ready;
        mp.data = {};
        mp.data["district"] = feature.properties['distrname'];
        mp.data["state"] = feature.properties['statename'];
    }

    onSubscription(): IReactionDisposer[] {
        let store = this.store;
        return [...super.onSubscription(),
            autorun(() => {
                if (store.dataShowRoomStore.active && store.dataShowRoomStore.districts.checked && this.visibleLayer()) {
                    this.loadFilterDistritcs();
                }
            })];
    }

    layerIdLine(): string{
        return this.getLayerId()+"_line";
    }
    layerIdFill(): string{
        return this.getLayerId()+"_fill";
    }

    updateFilter(){
        let map = this.store.map.mapbox;
        if (map.getLayer(this.layerIdLine())){
            map.setFilter(this.layerIdLine(), this.getFilterDistrict());
        }
        if (map.getLayer(this.layerIdFill())){
            map.setFilter(this.layerIdFill(), this.getFilterDistrict());
        }
    }

    getFilterDistrict(): any{
        return ["in",["id"],["literal",this.idDistricts]];
    }
    idDistricts: number[] = [];

    async addLayers() {
        let map = this.store.map.mapbox;
        //await this.loadFilterDistritcs();
        if (!map.getSource(this.getSourceId())){
            map.addSource(this.getSourceId(),{
                type: 'vector',
                tiles: [`${window.location.origin}/api/vectorlayer/india_districts/tile?x={x}&y={y}&z={z}`],
                scheme: "xyz",
            });
        }
        if (!map.getLayer(this.layerIdFill())){
            this.addLayer({
                id: this.layerIdFill(),
                source: this.getSourceId(),
                "source-layer": 'india_districts',
                type: 'fill',
                filter: this.getFilterDistrict(),
                paint: <FillPaint>{
                    'fill-opacity': 0,
                }
            });
        }
        if (!map.getLayer(this.layerIdLine())){
            this.addLayer({
                id: this.layerIdLine(),
                source: this.getSourceId(),
                "source-layer": 'india_districts',
                type: 'line',
                filter: this.getFilterDistrict(),
                paint: <LinePaint>{
                    'line-color': 'rgb(255, 0, 255)',
                    'line-width': 3,
                }
            });
        }
    }
    removeLayers() {
        let map = this.store.map.mapbox;
        if (map.getLayer(this.layerIdLine())){
            this.removeLayer(this.layerIdLine());
        }
        if (map.getLayer(this.layerIdFill())){
            this.removeLayer(this.layerIdFill());
        }

        super.removeLayers();
    }

    loadFilterDistritcs(){
        if (this.store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Multiyear) {
            fetchJsonGet(`/reports/api/districts/find?filter=${JSON.stringify(this.store.dataShowRoomStore.getFilters())}`).then(r => {
                ra(() => {
                    this.idDistricts = r;
                    this.updateFilter();
                });
            }).catch(err => {
                this.store.addError(err)
            });
        }
        if (this.store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Projects) {
            if (this.store.dataShowRoomStore.filtersProject.filter_districts_ids.size == 0){
                this.idDistricts = this.store.dataShowRoomStore.filtersProject.districts.map(a => a.id);
            }else {
                this.idDistricts = Array.from(this.store.dataShowRoomStore.filtersProject.filter_districts_ids.values());
            }
            this.updateFilter();
        }
    }

}