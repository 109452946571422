import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {IShowRoomDistrict} from "../../../../../store/dataShowroom2/ShowRoom2FilterProjectStore";
import {CheckboxMiniComp, CheckboxSize} from "../../../../Common/CheckboxMiniComp";
import './ShowRoom2FiltersDistrictsState.scss';
import {ShowRoom2FiltersDistrictsItem} from "./ShowRoom2FiltersDistrictsItem";
import {action} from "mobx";

export interface IShowRoom2FiltersDistrictsState extends IStoreProps{
    districts: IShowRoomDistrict[],
    stateName: string
}
@observer
export class ShowRoom2FiltersDistrictsState extends React.Component<IShowRoom2FiltersDistrictsState, undefined> {
    constructor(props: IShowRoom2FiltersDistrictsState) {
        super(props);
        autoBindReact(this);
    }

    getStateAll(): boolean{
        let store = this.props.store;
        let r = true;
        this.props.districts.forEach(a => {
            if (!store.dataShowRoomStore.filtersProject.filter_districts_ids.has(a.id)){ r = false}
        });
        return r;
    }
    @action
    onClickState(){
        let store = this.props.store;
        let newValue = !this.getStateAll();
        this.props.districts.forEach(a => {
            if (newValue){
                if (!store.dataShowRoomStore.filtersProject.filter_districts_ids.has(a.id)){
                    store.dataShowRoomStore.filtersProject.filter_districts_ids.add(a.id);
                }
            }else{
                if (store.dataShowRoomStore.filtersProject.filter_districts_ids.has(a.id)){
                    store.dataShowRoomStore.filtersProject.filter_districts_ids.delete(a.id);
                }
            }
        });
    }

    render() {
        let store = this.props.store;
        let state = this.props.districts[0];
        let arr: any[] = [];
        this.props.districts.forEach(a => {
            arr.push(<ShowRoom2FiltersDistrictsItem store={store} item={a} key={a.id} />);
        });

        return <div>
            <div className="ShowRoom2FiltersDistrictsState-line-state ShowRoom2FiltersDistrictsState-line-hover" onClick={this.onClickState}>
                <CheckboxMiniComp state={this.getStateAll()} size={CheckboxSize.standart} />
                <div className="ShowRoom2FiltersDistrictsState-line-label">{this.props.stateName}</div>
            </div>
            <div className="">
                {arr}
            </div>
        </div>;
    }
}
