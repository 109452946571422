import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import * as mapboxgl from "maplibre-gl";
import {action} from "mobx";
import {BottomSidebarComp} from "./BottomSidebarComp";
import {addBinaryLayer, Utils} from "../../../helper/utils/Utils";
import {FeatureCollection} from "geojson";
import {MapStore} from "../../../store/MapStore";
import autoBindReact from "auto-bind/react";
import {Compare} from "maplibre-gl-compare-ts";
import "../Map/MapCompareComp.scss";
import { SceneUiType } from "../../../store/SearchStore";
import { OverlayObjectStore } from "../../../store/OverlayObjectStore";
import { AnySourceData, ImageSource, ImageSourceRaw, Layer} from "maplibre-gl";
import { OverlayTool } from "../../../store/tools/class/OverlayTool";
import { IOverlayConfig } from "../../../store/config/ConfigStore";
import { EStyleType, LayersListStore } from "../Right/LayersList/LayersListStore";

interface IDynamicRasterSource {
    urlTemplate: string;
    lastUrl: string;
    overlayId: string;
}
@observer
export class MapCompareComp extends React.Component<
    IStoreProps,
    undefined
> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.compareMapRef = React.createRef();
        this.baseMapRef = React.createRef();
    }
    initialCenter: mapboxgl.LngLat | null = null;
    initialZoom: number | null = null;
    compareMapRef: any;
    baseMapRef: any;
    compareInstance: any = null;
    compareMap: mapboxgl.Map = null;
    baseMap: mapboxgl.Map = null;
    moveTimerHandle: any = null;
    binaryLayerTimeout: any = null;
    static readonly SEARCH_BOUNDS_COEF: number = 0.16; //половина от 1/3 пустой
   
    private dynamicRasterSources : Map<string, IDynamicRasterSource> = new Map<string, IDynamicRasterSource>();

    componentDidMount() {
        this.initializeCompare();
    }
    @action
    componentWillUnmount() {
        if (this.compareMap && this.initialCenter && this.initialZoom !== null) {
            this.compareMap.setCenter(this.initialCenter);
            this.compareMap.setZoom(this.initialZoom);
        }
        if (this.compareMap != null) {
            this.compareMap.remove();
        }
        if (this.baseMap != null) {
            this.baseMap.remove();
        }
        if (this.compareInstance != null) {
            this.compareInstance.remove();
        }
        if (this.moveTimerHandle) {
            clearTimeout(this.moveTimerHandle);
        }
        if (this.binaryLayerTimeout) {
            clearTimeout(this.binaryLayerTimeout);
        }
        this.props.store.map.setBaseMapTitle("")
        this.props.store.map.setCompareMapTitle("")
    }
    
    onChangeZoomEnd() {
        let ms = this.props.store.map;
        if (!ms.mapReady) return;
        let newZoom = this.compareMap.getZoom();
        if (ms.zoom != newZoom) ms.zoom = newZoom;
    }
     onMoveEnd() {
        let ms = this.props.store.map;
        if (!ms.mapReady) return;
        const {lng, lat} = this.compareMap.getCenter();
        if (ms.center.lng != lng || ms.center.lat != lat) {
            ms.center.set(lat, lng);
        }
        ms.menuPointer = null;
        this.setBounds(ms);
        if (this.moveTimerHandle)
            clearTimeout(this.moveTimerHandle);
        this.moveTimerHandle = setTimeout(() => { 
        this.dynamicRasterSources.forEach((drs, id) => {            
            if (! this.props.store.map.overlays.getVisible(drs.overlayId))
                return;
            let ip = this.getImageParams(drs.urlTemplate,this.baseMap);
            (this.baseMap.getSource(id) as ImageSource).updateImage(ip);
            (this.compareMap.getSource(id) as ImageSource).updateImage(ip);
            drs.lastUrl = ip.url;
        });        
        }, 300);
        if (ms.currentBaselayerKey === "heights") {
            if (this.binaryLayerTimeout) {
                clearTimeout(this.binaryLayerTimeout);
            }
            this.binaryLayerTimeout = setTimeout(() => {
                addBinaryLayer(this.baseMap,() => {
                });
            }, 200);
   }
    }
    setBounds(ms: MapStore) {
        let bb = this.compareMap.getBounds();
        let b = Utils.getInnerBounds(
            bb,
            MapCompareComp.SEARCH_BOUNDS_COEF
        );
        ms.searchBounds.setMbBounds(b);
        ms.bbox.setMbBounds(bb);
    }
    @action updateMousePosition(e:any) {
        let ms = this.props.store.map;
        ms.mousePointer = [e.lngLat.lng, e.lngLat.lat];
    }
    getImageParams(urlTemplate: string, mapbox: mapboxgl.Map) {
        let c = mapbox.getCanvas();
        let bbox = mapbox.getBounds();
        let z = mapbox.getZoom() + 0.5;
        let w = bbox.getWest();
        let e = bbox.getEast();
        let n = bbox.getNorth();
        let s = bbox.getSouth();
        return {
            'url': urlTemplate.replace('{w}', w.toString())
                .replace('{e}', e.toString()).replace('{n}', n.toString())
                .replace('{s}', s.toString()).replace('{z}', z.toString())
                .replace('{W}', c.width.toString()).replace('{H}', c.height.toString()),
            'coordinates': [
                [w, n],
                [e, n],
                [e, s],
                [w, s]
              ]
        }
    }
    addSource(i: number,map:mapboxgl.Map) {
        let ms = this.props.store.map;
        let ov = ms.overlays.visibleOverlays[i];
        let id = OverlayTool.OVERLAY_SOURCE_PREFIX + i;
        if (map.getSource(id) == null) {           
            let src: AnySourceData = ov.source;
            if (ov.source.type == "image") {
                let imgSrc = ov.source as ImageSourceRaw;
                if (imgSrc.url.match("\{[e,n,s,w,z,W,H]\}")) {
                    let ip = this.getImageParams(imgSrc.url, map);
                    this.dynamicRasterSources.set(id, 
                        {urlTemplate: imgSrc.url, overlayId: ov.id, lastUrl: ip.url});
                    src = {...src, ...ip};
                }
            }
            this.props.store.map.overlays.prepareTemporalSource(ov);
            map.addSource(id, Utils.prepareAbsoluteUrls(src));
        }
    }
    addOverlays (map:mapboxgl.Map){
        let visibleOverlays = this.props.store.map.overlays.visibleOverlays 
        visibleOverlays.forEach((item, ind) => {
            this.addSource(ind,map);
            this.addOverlayLayers(item, ind,map);
        });
    }
    addOverlayLayers(ovsi: IOverlayConfig, i: number,map:mapboxgl.Map){
        let style = LayersListStore.getOverlaySimpleStyle(ovsi);
        if (style?.type == EStyleType.FILL && style.lineLayerIndex < 0) {
            let pl : Layer = {
                id: "#temp",
                type: "line",
                layout: {"visibility": "none"},
                paint: {},
                metadata: {"class:overlay:border:visibility": false}
            };
            if (ovsi.source.type != "geojson") pl["source-layer"] = ovsi.id;
            ovsi.layers.unshift(pl);
        }
        let ov = this.props.store.map.overlays;
        for (let j = ovsi.layers.length - 1; j >= 0; j--) {
            let vis = ovsi.layers[j].metadata?.["class:overlay:border:visibility"]?? ov.getVisible(ovsi.id);
            let lr : Layer = {
                ...ovsi.layers[j],
                'id': OverlayObjectStore.getOverlayLayerName(ovsi.id, j),
                'source': OverlayTool.OVERLAY_SOURCE_PREFIX + i,
                'layout': {'visibility': vis? 'visible' : 'none', ...ovsi.layers[j].layout},
            };
            if (map.getLayer(lr.id) == null) {
               map.addLayer(lr as any);
            }
        }
    }
    addBaseLayer(){
        const ms = this.props.store.map
        const layer = this.props.store.config.map_layers.baselayers.find(i => i.key === ms.currentBaselayerKey);
        if (layer) {
            ms.setBaseMapTitle(layer.title);
            this.baseMap.addSource(layer.title, {
                type: "raster",
                tiles: [Utils.getAbsoluteUrlPath(layer.tiles)]
            });
            this.baseMap.addLayer({
                id: layer.title,
                type: "raster",
                source: layer.title
            });
            this.baseMap.setStyle( ms.getMapStyle());
        }
        if (ms.currentBaselayerKey === "heights") {
            addBinaryLayer(this.baseMap, () => { });
        }
    }
    addFavoriteScene(map: mapboxgl.Map,order: number,isBaseMap:boolean){
        const ms = this.props.store.map;       
        const favoriteItems = this.props.store.searchPanel.favoriteList.getAllSceneItems().filter(group => {
            return group.selected === true;
        });
        let fp = ms.productInfo.productsPassive.getFavoriteGroupProduct(favoriteItems[order].dayGroupId).productCode.toUpperCase();
        const sceneProps = favoriteItems[order].feature.properties;
        const url = ms.mapScenesStore.getTileUrl(favoriteItems[order], SceneUiType.favorite);
        map.addSource(`class_scene_src_${sceneProps.scene_id}-current`, {
            type: "raster",
            tiles: [url]
        });
        map.addLayer({
            id: `class_scene_src_${sceneProps.scene_id}-current`,
            type: "raster",
            source: `class_scene_src_${sceneProps.scene_id}-current`
        });
        isBaseMap? ms.setBaseMapTitle(`${sceneProps.satellite},${fp}, ${sceneProps.acqdate}`):
        ms.setCompareMapTitle(`${sceneProps.satellite},${fp}, ${sceneProps.acqdate}`)
    }
    addFavoriteGroup(map: mapboxgl.Map,order: number,isBaseMap:boolean,useVisible:boolean = true){
        let ms = this.props.store.map;
        if (order >= this.props.store.searchPanel.favoriteList.groups.length) {
            return;
        }
        let group = this.props.store.searchPanel.favoriteList.groups[order];
        let fp = ms.productInfo.productsPassive.getFavoriteGroupProduct(group.groupId(true)).productCode.toUpperCase();
        let visibleGroup = this.props.store.searchPanel.favoriteList.groups.filter(group => {
            return group.items.some(item => item.selected === true);
        })[order];
        useVisible ?  group = visibleGroup : group 
        group.items.forEach((item) => {
            const sceneProps = item.feature.properties;
            const url = ms.mapScenesStore.getTileUrl(item, SceneUiType.favorite);
            map.addSource(`class_scene_src_${sceneProps.scene_id}-favorite`, {
                type: "raster",
                tiles: [url]
            });
            map.addLayer({
                id: `class_scene_src_${sceneProps.scene_id}-favorite`,
                type: "raster",
                source: `class_scene_src_${sceneProps.scene_id}-favorite`
            });       
        });        
        isBaseMap? ms.setBaseMapTitle(`${group.items[0].feature.properties.satellite},${fp}, ${group.items[0].feature.properties.acqdate}`):
        ms.setCompareMapTitle(`${group.items[0].feature.properties.satellite},${fp}, ${group.items[0].feature.properties.acqdate}`)
    }
    @action initializeCompare() {
        let cs = this.props.store.searchPanel.currentSceneid;
        let ms = this.props.store.map;
        let mapStyle = ms.getMapStyle();
        const favoriteItems = this.props.store.searchPanel.favoriteList.getAllSceneItems().filter(group => {
            return group.selected === true;
        });
        let cp = ms.productInfo.productsPassive.activeCodeCurrent.productCode.toUpperCase();
       
        if (this.baseMap == null && this.baseMapRef.current) {
            this.baseMap = new mapboxgl.Map({
                container: this.baseMapRef.current,
                accessToken: this.props.store.config.accessToken,
                style: mapStyle,
                center: ms.center,
                zoom: ms.zoom + 1
            });  
            this.baseMap.on("load", () => {
                const grouped = this.props.store.searchPanel.favoriteList.grouped;
                const groups = this.props.store.searchPanel.favoriteList.groups.filter(group => {
                    return group.items.some(item => item.selected === true);
                });
                if (cs && favoriteItems.length > 0) {
                    if (grouped && groups.length >= 1) {
                        this.addFavoriteGroup(this.baseMap, 0, true);
                    } else {
                        this.addFavoriteScene(this.baseMap,0,true)
                    }
                } else if (favoriteItems.length > 1) {
                    if(groups.length === 1){
                        this.addBaseLayer()
                    }
                    if (grouped && groups.length > 1) {
                        this.addFavoriteGroup(this.baseMap, 1, true);
                    } else if (groups.length > 1) {
                        this.addFavoriteScene(this.baseMap,1,true)
                    }
                } else {
                    this.addBaseLayer()

                }
                this.addOverlays(this.baseMap);
            });
                    
            this.baseMap.on('mousemove', this.updateMousePosition);
            this.baseMap.on("moveend", this.onMoveEnd);
            this.baseMap.on("zoomend", this.onChangeZoomEnd);
        }
    
        if (this.compareMap == null && this.compareMapRef.current) {
            this.compareMap = new mapboxgl.Map({
                container: this.compareMapRef.current,
                accessToken: this.props.store.config.accessToken,
                style: mapStyle,
                center: ms.center,
                zoom: ms.zoom + 1
            });
            if (this.initialCenter === null) {
                this.initialCenter = this.compareMap.getCenter();
            }
            if (this.initialZoom === null) {
                this.initialZoom = this.compareMap.getZoom();
            }
            this.compareMap.on("load", () => {
                 if (cs) {
                    if (this.props.store.searchPanel.searchResult.grouped) {
                        this.props.store.searchPanel.searchResult.currentItems.forEach((item) => {
                            const sceneProps = item.feature.properties;
                            const url = ms.mapScenesStore.getTileUrl(item, SceneUiType.current);
                            this.compareMap.addSource(`class_scene_src_${sceneProps.scene_id}-current`, {
                                type: "raster",
                                tiles: [url]
                            });
                            this.compareMap.addLayer({
                                id: `class_scene_src_${sceneProps.scene_id}-current`,
                                type: "raster",
                                source: `class_scene_src_${sceneProps.scene_id}-current`
                            });
                        });
                        ms.setCompareMapTitle(`${this.props.store.searchPanel.searchResult.currentItems[0].feature.properties.satellite},${cp}, ${this.props.store.searchPanel.searchResult.currentItems[0].feature.properties.acqdate}`)
                        this.addOverlays(this.compareMap)
                    }

                    const titleParams = this.props.store.searchPanel.searchResult.currentItems.find(
                        item => item.feature.properties.scene_id === cs
                    );
                    if (titleParams) {
                        const sceneProps = titleParams.feature.properties;
                        const url = ms.mapScenesStore.getTileUrl(titleParams, SceneUiType.current);
                        this.compareMap.addSource(`class_scene_src_${sceneProps.scene_id}-current-compare`, {
                            type: "raster",
                            tiles: [url]
                        });
                        this.compareMap.addLayer({
                            id: `class_scene_src_${sceneProps.scene_id}-current-compare`,
                            type: "raster",
                            source: `class_scene_src_${sceneProps.scene_id}-current-compare`
                        });
                        ms.setCompareMapTitle(`${sceneProps.satellite},${cp}, ${sceneProps.acqdate}`);
                    }
                } else if (favoriteItems.length > 0) {
                     if (this.props.store.searchPanel.favoriteList.grouped) {
                        this.addFavoriteGroup(this.compareMap,0,false,false)
                    } else {
                        this.addFavoriteScene(this.compareMap,0,false)
                }
            }
            this.addOverlays(this.compareMap)
        });
            this.compareMap.on('mousemove', this.updateMousePosition);
            this.compareMap.on("moveend", this.onMoveEnd);
            this.compareMap.on("zoomend", this.onChangeZoomEnd);
        }
    
        if (this.compareInstance == null && this.baseMap && this.compareMap) {
            this.compareInstance = new Compare(this.baseMap as any, this.compareMap as any, "#comparison-container");
        }
    }    

    render() {
        let store = this.props.store;
        let baseTitle= store.map.baseMapTitle
        let compareTitle= store.map.compareMapTitle
        return (
            <>
                <div className='modal-back'>
                    <div className='modal-full modal-full-frame MapCompareMode-window'>
                        <div className='compareMode-widget-comp'>
                            <div>{baseTitle}</div>
                            <div className='compareMode-button'>
                                <div>
                                    <svg
                                        width='19'
                                        height='27'
                                        viewBox='0 0 19 27'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            d='M5.84375 6.59375H1.59375C1.00695 6.59375 0.53125 7.06945 0.53125 7.65625V19.3438C0.53125 19.9306 1.00695 20.4062 1.59375 20.4062H5.84375C6.43055 20.4062 6.90625 19.9306 6.90625 19.3438V7.65625C6.90625 7.06945 6.43055 6.59375 5.84375 6.59375Z'
                                            stroke='#4DB6BC'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M12.0938 8.71875V7.65625C12.0938 7.37446 12.2057 7.10421 12.4049 6.90495C12.6042 6.70569 12.8745 6.59375 13.1562 6.59375H13.6875'
                                            stroke='#4DB6BC'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M13.6875 20.4062H13.1562C12.8745 20.4062 12.6042 20.2943 12.4049 20.0951C12.2057 19.8958 12.0938 19.6255 12.0938 19.3438V18.2812'
                                            stroke='#4DB6BC'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M18.4688 18.2812V19.3438C18.4688 19.6255 18.3568 19.8958 18.1576 20.0951C17.9583 20.2943 17.688 20.4062 17.4062 20.4062H16.875'
                                            stroke='#4DB6BC'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M18.4688 11.9062V15.0938'
                                            stroke='#4DB6BC'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M12.0938 11.9062V15.0938'
                                            stroke='#4DB6BC'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M16.875 6.59375H17.4062C17.688 6.59375 17.9583 6.70569 18.1576 6.90495C18.3568 7.10421 18.4688 7.37446 18.4688 7.65625V8.71875'
                                            stroke='#4DB6BC'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <rect
                                            x='9.25'
                                            y='4.25'
                                            width='0.5'
                                            height='18.5'
                                            stroke='#4DB6BC'
                                            strokeWidth='0.5'
                                        />
                                        <path
                                            d='M9.9147 4.48933C9.71651 4.78356 9.28348 4.78356 9.0853 4.48934L7.93349 2.77933C7.70978 2.44722 7.94776 2 8.34818 2L10.6518 2C11.0522 2 11.2902 2.44722 11.0665 2.77933L9.9147 4.48933Z'
                                            stroke='#4DB6BC'
                                        />
                                        <path
                                            d='M9.0853 22.5107C9.28349 22.2164 9.71652 22.2164 9.9147 22.5107L11.0665 24.2207C11.2902 24.5528 11.0522 25 10.6518 25L8.34818 25C7.94775 25 7.70978 24.5528 7.93348 24.2207L9.0853 22.5107Z'
                                            stroke='#4DB6BC'
                                        />
                                    </svg>
                                </div>
                                <div>{store.trans["Compare mode"]}</div>
                                <div
                                    onClick={
                                      this.props.store.map.setCompareModeEnabled
                                    }
                                >
                                    <svg
                                        width='11'
                                        height='11'
                                        viewBox='0 0 11 11'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            d='M10.7643 0.235714C10.45 -0.0785714 9.97857 -0.0785714 9.66429 0.235714L5.5 4.4L1.33571 0.235714C1.02143 -0.0785714 0.55 -0.0785714 0.235714 0.235714C-0.0785714 0.55 -0.0785714 1.02143 0.235714 1.33571L4.4 5.5L0.235714 9.66429C-0.0785714 9.97857 -0.0785714 10.45 0.235714 10.7643C0.392857 10.9214 0.55 11 0.785714 11C1.02143 11 1.17857 10.9214 1.33571 10.7643L5.5 6.6L9.66429 10.7643C9.82143 10.9214 10.0571 11 10.2143 11C10.3714 11 10.6071 10.9214 10.7643 10.7643C11.0786 10.45 11.0786 9.97857 10.7643 9.66429L6.6 5.5L10.7643 1.33571C11.0786 1.02143 11.0786 0.55 10.7643 0.235714Z'
                                            fill='#C5C5C5'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div>{compareTitle}</div>
                        </div>
                        <div
                            id='comparison-container'
                            style={{
                                position: "relative",
                                height: "100%"
                            }}
                        >
                            <div
                                ref={this.compareMapRef}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px"
                                }}
                            />
                            <div
                                ref={this.baseMapRef}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px"
                                }}
                            />
                        </div>
                        <BottomSidebarComp store={store} />
                    </div>
                </div>
            </>
        );
    }
}
