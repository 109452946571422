import {MapMouseEvent} from "maplibre-gl";
import {CustomTool} from "../general/ContainerTools";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class StopTool extends CustomTool {
    onDblclick(e: MapMouseEvent & ToolEvent): boolean {
        return false;
    }

    onMouseMove(e: MapMouseEvent & ToolEvent): boolean {
        if (e.cursor == null) e.cursor = 'default';
        return false;
    }

    onMouseDown(e: MapMouseEvent & ToolEvent): void {
    }

    onMouseUp(e: MapMouseEvent & ToolEvent): void {
    }

    onContextMenu(e: ToolEvent): void {
    }

    onMouseEnter(e: ToolEvent): void {
    }

    onMouseLeave(e: ToolEvent): void {
    }

    onMouseClick(e: MapMouseEvent & ToolEvent): void {
    }
}