import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";

@observer
export class InfoComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClose(){
        let store = this.props.store;
        store.clearInfos();
    }

    render() {
        let store = this.props.store;
        if (store.infos.length == 0){
            return null;
        }
        let childs: any[] = [];
        store.infos.forEach( (err, idx) => childs.push(<div key={idx.toString()}>{err}</div>));

        return <div className="popup-large-size unopacity">
            <div className="popup-close" onClick={this.onClose}/>
            <div className="o-complite" />
            <div className="warning-text">{childs}</div>
        </div>;
    }
}
