import {Utils} from "../../../helper/utils/Utils";
import {IGraphData, IGraphDataInfo, IGraphDayValue} from "../IndexByPointGraphStore";
import {IndexByPointerSourceCustomSimpleStore} from "./IndexByPointerSourceCustomSimpleStore";

export class IndexByPointerSourceSimpleSmapSnowDepthStore extends IndexByPointerSourceCustomSimpleStore{

    getGraphData(): IGraphDataInfo{
        let res: IGraphData[] = [];
        let arr: IGraphDayValue[] = this.getGraphDataInternal();
        arr.forEach(a => {
            res.push({dayOfYear: a.dayOfYear, value: Math.round(a.value * 100) / 100, sceneID: null as any});
        });
        return {data: res, hasMinMax: false};
    }

    protected getRequest(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return (`/api/meteo/smap/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=snow_depth`)
    }
    protected getValue(json: any): IGraphDayValue{
        let j: {date: string, snow_depth: number} = json;
        return this.getGraphDataValue(j.date, j.snow_depth);
    }
    protected getGraphDataInternal(): IGraphDayValue[]{
        return this.getData;

    }


    private get getData(): IGraphDayValue[]{
        let src: IGraphDayValue[];
        if (this.status == null) setImmediate(()=> { this.load(true) });
        src = this.data;
        return src;
    }

}