import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {
    ContainerToolsState,
    CreateGeometryType,
    IGeometryEditorEvents,
    IObjectByClick
} from "../tools/general/ContainerTools";
import {ViewGeometryLayersTool} from "../tools/EditGeometry/ViewGeometryLayersTool";
import {action} from "mobx";
import {MeasuringStatus} from "../../helper/structs/MeasuringStatus";
import maplibregl, {MapMouseEvent} from "maplibre-gl";
import {ToolEvent} from "../../../pluginApi/tools/ToolEvent";
import {SimpleGeometryType} from "../../helper/utils/GeometryUtils";
import {MBUtils} from "../../helper/utils/MBUtils";
import {ra} from "../../helper/utils/mobxUtils";

export class RulerManager extends ObservableCustomStore implements IGeometryEditorEvents{
    constructor(parent: CustomStore) {
        super(parent);
        this.state.events = this;
    }

    public state: ContainerToolsState = new ContainerToolsState();

    private _viewEditorGeometry: ViewGeometryLayersTool;
    get viewEditorGeometry(): ViewGeometryLayersTool{
        return this._viewEditorGeometry;
    }
    set viewEditorGeometry(value: ViewGeometryLayersTool){
        this._viewEditorGeometry = value;
        if (value != null){
            value.onInstallEvent = ()=>{
                ra(()=> {
                    this.root.map.rulerInfo.onRecalcRulerInfo2();
                    this.root.map.rulerInfo.setLastPoint();
                });
            }
        }
    }

    buttonRulerActive(): boolean{
        let store = this.root;
        return store.map.measuringStatus == MeasuringStatus.Ruler2 || store.map.measuringStatus == MeasuringStatus.Ruler2Edit;
    }
    @action
    clickStartRuler(){
        let store = this.root;
        let empty = this.state.simpleGeometry == null || this.state.simpleGeometry.length == 0;
        if (store.map.measuringStatus == MeasuringStatus.Ruler2 || store.map.measuringStatus == MeasuringStatus.Ruler2Edit) {
            store.map.resetMeasuringStatus();
        } else {
            if (empty) {
                store.map.rulerInfo.rulerManager.state.createGeometryType = CreateGeometryType.Line;
                store.map.setMeasuringStatus(MeasuringStatus.Ruler2);
            }else{
                store.map.rulerInfo.rulerManager.state.createGeometryType = CreateGeometryType.Line;
                store.map.setMeasuringStatus(MeasuringStatus.Ruler2Edit);
            }
        }
    }
    @action
    clickDeleteAll(){
        let store = this.root;
        this.state.simpleGeometry = [];
        this.state.resetMovedPoints();
        this.state.curAddrContour = null;
        this.state.createGeometryType = CreateGeometryType.Line;
        store.map.setMeasuringStatus(MeasuringStatus.Ruler2);
        store.map.rulerInfo.scrPoint = null;
        this.viewEditorGeometry.updateGeometry();
    }

    getObjectByClick(point: maplibregl.Point): IObjectByClick {
        return this.viewEditorGeometry.getObjectByClick(point);
    }
    public updateMovedPoint() {
        this.viewEditorGeometry.updateMovedPoint();
    }

    public updateGeometry(){
        this.viewEditorGeometry.updateGeometry();
    }

    onMouseMove(e: maplibregl.MapMouseEvent & ToolEvent) {
        if (this.root.map.measuringStatus == MeasuringStatus.Ruler2) {
            let p = MBUtils.pointToPosition(e.point);
            this.root.map.rulerInfo.onRecalcRulerInfo2(p);
        }
    }

    onClickFirstPointPolygon(e : MapMouseEvent & ToolEvent): boolean{
        let a = this.state.simpleGeometry.length > 0;
        let b = this.state.simpleGeometry[0].simple == SimpleGeometryType.Line;
        let c = this.state.simpleGeometry[0].contour.length > 1;
        let d = (!MBUtils.isEqualPoint(this.state.simpleGeometry[0].contour[0],
            this.state.simpleGeometry[0].contour[this.state.simpleGeometry[0].contour.length - 1]));
        if (a &&
            b &&
            c && d

        ){
            this.state.simpleGeometry[0].contour.push(this.state.simpleGeometry[0].contour[0]);
        }
        return this.onClickRightButton(e);
    }
    onClickRightButton(e : MapMouseEvent & ToolEvent): boolean{
        this.state.resetMovedPoints();
        this.Finished();
        e.noPropagation();
        this.root.map.rulerInfo.onRecalcRulerInfo2();
        return false;
    }
    @action
    onChangeGeometry() {
        this.root.map.rulerInfo.onRecalcRulerInfo2();
        this.root.map.rulerInfo.setLastPoint();
        //this._changes++;
    }

    @action
    onZoomOrMove() {
        let store = this.root;
        if (this.state.simpleGeometry != null && this.state.simpleGeometry.length > 0) {
            this.root.map.rulerInfo.scrPoint = null;
            this.root.map.rulerInfo.setLastPoint();
        }
    }

    @action
    public onBeforeCreateGeometry(e: maplibregl.MapMouseEvent & ToolEvent) {
        this.state.resetMovedPoints();
        this.state.simpleGeometry = [];
        this.state.curAddrContour = null;
        this.state.createGeometryType = CreateGeometryType.Line;
        this.viewEditorGeometry.updateGeometry();
        this.viewEditorGeometry.updateMovedPoint();
        this.root.map.setMeasuringStatus(MeasuringStatus.Ruler2);
    }

    @action
    public Finished(){
        this.state.resetMovedPoints();
        this.state.curAddrContour = null;
        this.viewEditorGeometry.updateGeometry();
        this.viewEditorGeometry.updateMovedPoint();
        this.state.createGeometryType = CreateGeometryType.Line;
        this.root.map.setMeasuringStatus(MeasuringStatus.Ruler2Edit);
        this.root.map.rulerInfo.setLastPoint();
    }
    isCreateGeometry(): boolean{
        return (this.root.map.measuringStatus == MeasuringStatus.Ruler2 || this.root.map.measuringStatus == MeasuringStatus.Ruler2Edit);
    }
    isEdit(): boolean{
        return (this.root.map.measuringStatus == MeasuringStatus.Ruler2Edit);
    }
    isDeleteContour(): boolean{ return false;}

    setStyles(){
        this.viewEditorGeometry.Custom_MOVED_LINE_OVERLAY_Style = {
            id: null,
            type: "line",
            paint:{
                "line-color": '#FFF',
                "line-width": 6,
                'line-opacity': 0.3
            }
        };
        this.viewEditorGeometry.Custom_MOVED_LINE_Style = {
            id: null,
            type: "line",
            paint:{
                "line-color": '#000',
                "line-width": 2,
                "line-dasharray": [4, 4],
            }
        };
        this.viewEditorGeometry.Custom_GEOMETRY_LINES_LAYEROVER_Style = {
            id: null,
            type: "line",
            paint:{
                "line-color": '#FFF',
                "line-width": 6,
                'line-opacity': 0.6
            }
        };
        this.viewEditorGeometry.Custom_GEOMETRY_LINES_Style = {
            id: null,
            type: "line",
            paint:{
                "line-color": '#000',
                "line-width": 2,
            }
        };
        this.viewEditorGeometry.Custom_GEOMETRY_VERTEX1_Style = {
            id: null,
            type: "circle",
            paint: {
                'circle-radius': 8,
                'circle-color': '#FFF',
                'circle-opacity': 0.9,
            }
        };
        this.viewEditorGeometry.Custom_GEOMETRY_VERTEX2_Style = {
            id: null,
            type: "circle",
            paint: {
                'circle-radius': 4,
                'circle-color': '#FFF',
                'circle-opacity': 1,
                'circle-stroke-width': 2,
                'circle-stroke-color': "#000"
            }
        };
    }
}