// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndexByPointPopupMonthComp-main {
  color: #C5C5C5;
  width: 340px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.IndexByPointPopupMonthComp-divider {
  width: 9px;
  height: 1px;
  border-top: 1px solid #3E4751;
  box-sizing: border-box;
  margin: 8px;
}

.IndexByPointPopupMonthComp-title {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 8px;
}

.IndexByPointPopupMonthComp-button {
  margin-top: 16px;
  min-width: 43px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/IndexByPoint/Popups/IndexByPointPopupMonthComp.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,0CAAA;EACA,kBAAA;AACF;;AACA;EACE,UAAA;EACA,WAAA;EACA,6BAAA;EACA,sBAAA;EACA,WAAA;AAEF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAGF;;AADA;EACE,gBAAA;EACA,eAAA;AAIF","sourcesContent":[".IndexByPointPopupMonthComp-main{\r\n  color: #C5C5C5;\r\n  width: 340px;\r\n  padding: 15px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);\r\n  border-radius: 8px;\r\n}\r\n.IndexByPointPopupMonthComp-divider{\r\n  width: 9px;\r\n  height: 1px;\r\n  border-top: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  margin: 8px;\r\n}\r\n.IndexByPointPopupMonthComp-title {\r\n  font-weight: 400;\r\n  font-size: 10px;\r\n  line-height: 12px;\r\n  margin-bottom: 8px;\r\n}\r\n.IndexByPointPopupMonthComp-button{\r\n  margin-top: 16px;\r\n  min-width: 43px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
