import {CustomStore} from "../../CustomStore";
import {action, computed, observable, toJS} from "mobx";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {FeatureCollection, Geometry, MultiPolygon, Point, Polygon, Feature} from "geojson";
import {LngLat} from "maplibre-gl";
import {fetchJsonGet, fetchJsonPostWithJsonParams} from "../../../helper/utils/FetchUtils";
import {GeometryUtils} from "../../../helper/utils/GeometryUtils";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {FieldEditModeType} from "./A2FieldFormCustomStore";
import {GActionTransactionType} from "../../../helper/geometryAction/IGeometryAction";
import { save } from "../../PermalinkDecor";
import union from "@turf/union";
import intersect from "@turf/intersect"
import { v4 } from "uuid";
import { PhotoTagGroupFilterStore } from "../../photo/PhotoTagGroupFilterStore";
import { featureCollection } from "@turf/helpers";
import difference from "@turf/difference";
import { ra } from "../../../helper/utils/mobxUtils";
import { Utils } from "../../../helper/utils/Utils";

export enum AutoPolygonModel{
    unetValid='unet-valid', unetPlusPlus='unet++', sam='sam'
}

export enum SamModelSource {
    sentinel = "sentinel",
    google = "google",
    ESRI = "ESRI",
    bing = "bing"
}

export interface IDetectionBBox {
    z: number,
    xg0: number,
    yg0: number,
    xg1: number,
    yg1: number
}

export interface IDetectionSource {
    src: SamModelSource;
    scene_ids: string[];
}

export class FieldAutoStore extends CustomStore{

    static readonly AI_CURSOR : string = `url('/img/cursors/cursor_auto.svg') 0 0, wait`;

    @observable
    private _simplify: number = 2;
    public get simplify(): number {
        return this._simplify;
    }
    public set simplify(value: number) {
        let needRecalc = this._simplify != value && this.editingPolygon;
        this._simplify = value;
        if (! needRecalc) return;
        this.detectGeometry(AutoPolygonModel.sam, false)
        .then(g => {
            ra(() => {
                this.editingPolygon = g;
                if (g == null)
                    this.root.addInfo(this.root.trans["Not field!"]);
            })            
        })
        .catch(err => {
            this.root.addError(err);
        })
    }
    @observable
    minimize: boolean = false;
    @observable
    showHelp: boolean = false;
    @observable @save
    private _editingPolygon : Polygon | MultiPolygon;
    public get editingPolygon() : Polygon | MultiPolygon {
        return this._editingPolygon;
    }
    public set editingPolygon(v : Polygon | MultiPolygon) {
        this._editingPolygon = v;
        if (v == null) this.points = null;
    }    
    @observable @save
    points: FeatureCollection<Point> = null;
    @observable @save
    rememberedPolygons: FeatureCollection<Polygon | MultiPolygon> = null;
    bbox: IDetectionBBox;

    status: LoadStatus = null;

    @action
    applyGeometry(){
        let js = toJS(this.editingPolygon);
        if (js == null) return;
        let sg = this.root.agro2.editManager.state.simpleGeometry;
        let tr = this.root.agro2.editManager.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.autoGeometry);
        let newSg = GeometryUtils.getSimpleGeometries(js);
        newSg.reverse().forEach(g => {
            tr.insertGeometry(0, g);
        });
        this.root.agro2.editManager.state.resetMovedPoints();
        if (this.root.agro2.editManager.state.events.updateGeometry) this.root.agro2.editManager.state.events.updateGeometry();
        this.editingPolygon = null;
    }

    isHole(): boolean {
        if (! this.editingPolygon || ! this.rememberedPolygons) return false;
        for (let i = 0; i < this.rememberedPolygons.features.length; i++) {
            if (Utils.hasIntersection(this.editingPolygon, this.rememberedPolygons.features[i]))
                return true;
        }
        return false;
    }

    @action
    remember() {
        if (!this.canRemember) return;
        let fc = this.rememberedPolygons;
        let newFeature : Feature<Polygon | MultiPolygon> = {"type": "Feature", "geometry": this.editingPolygon, "properties": {"id": v4()}}
        if (fc) {
            if (this.isHole()) {
                for (let i = 0; i < fc.features.length; i++)
                    fc.features[i] = difference(fc.features[i], newFeature);
            } else {
                fc.features.push(newFeature);
            }
            this.rememberedPolygons = fc;
        } else {
            this.rememberedPolygons = {
                "type": "FeatureCollection",
                "features": [newFeature]
            };
        }
        this.editingPolygon = null;
    }

    @action
    applyAll() {
        if (! this.canApply) return;
        let polys: (Feature<Polygon | MultiPolygon>)[] = [];
        let newFeature : Feature<Polygon | MultiPolygon> = !this.editingPolygon? null:
            {"type": "Feature", "geometry": this.editingPolygon, "properties": {"id": v4()}};
        let remPolys = (this.rememberedPolygons && this.rememberedPolygons.features.length > 0)?
            this.rememberedPolygons.features : [];
        if (this.isHole()) {
            for (let i = 0; i < remPolys.length; i++)
                polys.push(difference(remPolys[i], newFeature));
        }
        else {
            if (this.editingPolygon) polys = [newFeature];
            polys = polys.concat(remPolys);
        }
        for (let i = 1; i < polys.length; i++) {
            polys[0] = union(polys[0], polys[i]);
        }
        this.rememberedPolygons = null;
        this.editingPolygon = polys[0].geometry;
        this.applyGeometry();
    }

    get canApply(): boolean {        
        return this.editingPolygon != null || this.rememberedPolygons != null && this.rememberedPolygons.features.length > 0;
    }

    get canRemember(): boolean{
        return this.editingPolygon != null;
    }

    @computed
    get showPanel(): boolean{
        let store = this.root;
        let ok = (store.agro2.fieldEditorForm.editMode == FieldEditModeType.edit ||
            store.agro2.fieldEditorForm.editMode == FieldEditModeType.insert);
        if (!ok) return false;
        return store.map.measuringStatus == MeasuringStatus.agroAutoPolygon;
    }

    @computed
    get currentSource(): IDetectionSource {
        let scenes = this.root.map.superTools.sceneFavoritesTool.currentScenes;
        if (scenes.length > 0)
            return {src: SamModelSource.sentinel, scene_ids: scenes};
        switch (this.root.map.currentBaselayerKey.toLowerCase()) {
            case 'bing_sat': return {src: SamModelSource.bing, scene_ids: null};
            case 'esri_sat': return {src: SamModelSource.ESRI, scene_ids: null};
            case 'google_sat': return {src: SamModelSource.google, scene_ids: null};
        }
        throw 'Can not determine source';
    }
    

    async geometryByClick(sceneId: string, point: LngLat, model: AutoPolygonModel, tolerance: number): Promise<Geometry>{
        let url = `/field_detection/api/border/point`;
        let prms = {
            scene_id: sceneId,
            lat: point.lat,
            lon: point.lng,
            model: model,
            box_size: 5120,
            simplify_tolerance: tolerance
        };
        this.status = LoadStatus.loading;
        try {
            let r = await fetchJsonGet(url, prms);
            return r.geojson;
        }finally {
            this.status = LoadStatus.ready;
        }
    }

    async detectGeometry(model: AutoPolygonModel, updateBBox: boolean=true) : Promise<Polygon> {
        if (model != AutoPolygonModel.sam) throw "Invalid model";
        let inclPoints = this.points.features.filter(f => f.properties.include);
        let exclPoints = this.points.features.filter(f => !f.properties.include);
        let includePoints = inclPoints.map(f => LngLat.convert((f.geometry as Point).coordinates as [number, number]));
        let excludePoints = exclPoints.map(f => LngLat.convert((f.geometry as Point).coordinates as [number, number]));
        let map = this.root.map.mapbox;
        let url = `/sam/predict`;
        let points = includePoints.map(p => p.toArray()).map(i => Object({xg: i[0], yg: i[1], include: true})).concat(
            excludePoints.map(p => p.toArray()).map(i => Object({xg: i[0], yg: i[1], include: false}))
        );
        if (points.length == 1 && updateBBox) {
            let b = map.getBounds();        
            this.bbox = {
                z: map.getZoom(),
                xg0: b.getWest(),
                yg0: b.getSouth(),
                xg1: b.getEast(),
                yg1: b.getNorth()
            };
        }
        let prms = {
            ...this.bbox,
            ...this.currentSource,
            points: points,
            simplify_tolerance: this.simplify,
        };
        this.status = LoadStatus.loading;
        try {
            let r = await fetchJsonPostWithJsonParams(url, prms);
            return JSON.parse(r.poly);
        } finally {
            this.status = LoadStatus.ready;
            if (this.root.map.measuringStatus == MeasuringStatus.agroAutoPolygon)
                map.getCanvas().style.cursor = FieldAutoStore.AI_CURSOR;
        }
    }
}