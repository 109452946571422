import {CustomStore} from "../CustomStore";
import {observable} from "mobx";

export enum Sentinel1product{ GRD='GRD', SLC='SLC'}
export class ActiveSensorDayStore extends CustomStore{
    class(): string {return "ActiveSensorDayStore";}

    @observable
    polarizationTypeVV: boolean = false;
    
    @observable
    polarizationTypeVH: boolean = false;

    orbitsText: string = "";

    @observable
    orbits: number[] = [];

    @observable
    multitimeFiltering: boolean = false;

    @observable
    product: Sentinel1product = Sentinel1product.GRD;
   
    @observable
    crop_to_region: boolean = true;
}