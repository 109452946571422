import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {MinSvg} from "../../icons/Legend/MinSvg";
import {MaxSvg} from "../../icons/Legend/MaxSvg";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import {IndexBandProductStore, Interpolation} from "../../../store/productSetting/IndexBandProductStore";
import {LegendLinearComp} from "./LegendLinearComp";
import {Utils} from "../../../helper/utils/Utils";
import {LegendDiscreteComp} from "./LegendDiscreteComp";
import {ProductSettingStore} from "../../../store/productSetting/ProductSettingStore";
import {LegendFixIndexComp} from "./LegendFixIndexComp";
import {LegendCommonComp} from "./LegendCommonComp";
import {LeftPanelMode, SceneUiType} from "../../../store/SearchStore";
import {LegendDiscrete} from "./LegendDiscrete";
import {LegendMinComp} from "./LegendLayer/LegendMinComp";
import { WindowTriState } from '../../../helper/structs/WindowTriState';
import { defaultContainerStyler } from '../../Common/Legend/Legend';

@observer
export class LegendPanel extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    toMin() {
        this.props.store.map.legend.legendMinimize = true;
    }


    onChangeTransparentLinear(options: {isMore: boolean, isLess: boolean, value: boolean}){
        let store = this.props.store;
        let info = store.map.legend.prodInfo;
        if (info == null) return;
        if (options.isMore)
            info.prod.currentProductStore.indexBand.transparentMax = options.value;
        if (options.isLess)
            info.prod.currentProductStore.indexBand.transparentMin = options.value;
        if (store.searchPanel.top_sceneType == SceneUiType.favorite)
            store.map.productInfo.activeProductsSet.saveGroupProductAndRefresh(store.searchPanel.currentGroup.groupId(true), info.prod);
    }


    render() {
        let store = this.props.store;
        if (!store.map.legend.legendNdviShow) return null;

        let st = defaultContainerStyler(store);
        // let st: React.CSSProperties = {right: "5px"};
        // if (store.indexByPointer.showPanel == WindowTriState.show){
        //     st.right = "440px";
        // }
        // else if (store.map.layersMenuOpened) {
        //     st.right = "355px"
        // }

        let min = store.map.legend.legendMinimize;
        if (min) {
            return <LegendMinComp store={store} style={st}/>;
        }

        let info = store.map.legend.prodInfo;
        if (info == null) return null;
        let prod = info.prod;
        let b: IndexBandProductStore = info.prod.currentProductStore.indexBand;
        // let pp = store.map.productInfo.productsPassive;
        // let sp = store.searchPanel;
        // let bandType = (sp.top_sceneType == SceneUiType.favorite
        //     ? pp.getFavoriteGroupProduct(sp.currentGroup.groupId(true))
        //     : pp.getActiveCodeByUiType(SceneUiType.current)
        // ).currentProductStore.bandType;
        
        let interpolation: Interpolation = b.interpolation;

        return <LegendCommonComp store={store} className="legend-panel qtransit" style={st}>
            {<React.Fragment>
                {prod.currentProductStore.bandType == "fixedIndex" && <LegendFixIndexComp prod={prod.currentProductStore} store={store} />}
                {prod.currentProductStore.bandType != "fixedIndex" &&
                    <React.Fragment>
                        {interpolation == Interpolation.discrete && <LegendDiscrete store={store} prod={prod} />}
                        {interpolation == Interpolation.linear &&
                        <LegendLinearComp store={store} max={Utils.parseNumber(b.max)} min={Utils.parseNumber(b.min)} visibleCheckbox={true}
                                          description={prod.currentProductStore.unitDescription} values={null} transparentMore={b.transparentMax} transparentLess={b.transparentMin}
                                          onChangeTransparent={this.onChangeTransparentLinear} top_name={prod.currentProductStore.productConfig.abbreviation}
                                          colors={b.colors.colors.map(a => a.color)} title={prod.currentProductStore.unitName}  />}
                        <div className="legend-panel_toMin" onClick={this.toMin}><MinSvg/></div>
                    </React.Fragment>
                }
            </React.Fragment>}
        </LegendCommonComp>;
    }
}
