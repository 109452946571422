import * as React from 'react';
import {IStoreProps} from "../../../../helper/structs/IStoreProps";

export class OrderStatusError extends React.PureComponent<IStoreProps, undefined> {
    render() {
        let store = this.props.store;
        return <div className="journal-status error">
            <svg width="20" height="19" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7501 15.6574L11.8538 1.09989C11.6591 0.738725 11.3621 0.443046 11.0001 0.249894C10.5082 -0.0133168 9.93233 -0.0716803 9.39768 0.0874988C8.86303 0.246678 8.41285 0.610523 8.14506 1.09989L0.250061 15.6574C0.0774269 15.9777 -0.00888954 16.3375 -0.000406475 16.7013C0.00807659 17.0651 0.111067 17.4205 0.298445 17.7324C0.485823 18.0444 0.751142 18.3022 1.06833 18.4806C1.38552 18.659 1.74366 18.7518 2.10756 18.7499H17.8926C18.2414 18.7499 18.5848 18.664 18.8926 18.4999C19.1364 18.3705 19.3522 18.1941 19.5275 17.9808C19.7028 17.7676 19.8342 17.5218 19.914 17.2575C19.9938 16.9933 20.0206 16.7158 19.9926 16.4412C19.9647 16.1666 19.8814 15.9002 19.7501 15.6574ZM10.0001 16.2499C9.75283 16.2499 9.51116 16.1766 9.3056 16.0392C9.10004 15.9019 8.93982 15.7067 8.84521 15.4782C8.7506 15.2498 8.72585 14.9985 8.77408 14.756C8.82231 14.5136 8.94136 14.2908 9.11618 14.116C9.29099 13.9412 9.51372 13.8221 9.7562 13.7739C9.99867 13.7257 10.25 13.7504 10.4784 13.845C10.7068 13.9397 10.902 14.0999 11.0394 14.3054C11.1767 14.511 11.2501 14.7527 11.2501 14.9999C11.2501 15.3314 11.1184 15.6494 10.8839 15.8838C10.6495 16.1182 10.3316 16.2499 10.0001 16.2499ZM11.2501 11.8749C11.2501 12.0407 11.1842 12.1996 11.067 12.3168C10.9498 12.434 10.7908 12.4999 10.6251 12.4999H9.37506C9.2093 12.4999 9.05033 12.434 8.93312 12.3168C8.81591 12.1996 8.75006 12.0407 8.75006 11.8749V6.87489C8.75006 6.70913 8.81591 6.55016 8.93312 6.43295C9.05033 6.31574 9.2093 6.24989 9.37506 6.24989H10.6251C10.7908 6.24989 10.9498 6.31574 11.067 6.43295C11.1842 6.55016 11.2501 6.70913 11.2501 6.87489V11.8749Z"/>
            </svg>
            <div className="journal-status-name">{store.trans["Fatal error"]}</div>
        </div>
    }
}
