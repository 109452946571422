import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {isString} from "lodash-es";
import {AgroProjectItemComp} from "./AgroProjectItemComp";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../../icons/LoadingAniSvg";
import classNames from "classnames";
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../../../Common/ContextMenuCommonComp";
import {action, observable} from "mobx";
import {AgroProjectListFiltersComp} from "./AgroProjectListFiltersComp";
import {AgroProjectTotalPanelComp} from "./AgroProjectTotalPanelComp";

@observer
export class AgroProjectListComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }


    render() {
        let store = this.props.store;
        let projListStore = this.props.store.agro2.projectList;
        let projects = projListStore.projectsFiltered.slice();

        let items: any[] = [];
        projects.forEach(proj =>{
            items.push(<AgroProjectItemComp store={store} projectInfo={proj} key={proj.proj_name} />);
        });

        return <div className="flex-fit-item AgroFarmList-table height100">
                <AgroProjectTotalPanelComp store={store} total={projects.length} />
                <AgroProjectListFiltersComp store={store} />

                <div className="AgroFarmList-table flex-stretch-item table-scroll-overlay style-4">
                    {store.agro2.projectStruct.loadProjectStatus == LoadStatus.loading && <div className="flex-center-content"><LoadingAniSvg /></div>}
                    {store.agro2.projectStruct.loadProjectStatus != LoadStatus.loading && items}
                </div>
        </div>;
    }
}
