import {CirclePaint, Layout, SymbolLayout, SymbolPaint, VectorSource} from "maplibre-gl";
import {autorun, reaction} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {CustomStoreTool} from "../general/CustomStoreTool";
import {LeftPanelMode} from "../../SearchStore";

export class PhotoLayersTool extends CustomStoreTool{
    static PHOTO_SOURCE_ID = 'class_PHOTO_SOURCE_ID';
    static PHOTO_LAYER_ID = 'class_PHOTO_LAYER';
    static PHOTO_LAYER_ID2 = 'class_PHOTO_LAYER2';
    static PHOTO_LAYER_TEXT_ID = 'class_PHOTO_LAYER_TEXT';
    disp: IReactionDisposer = null;
    disposerVisibleLayers: IReactionDisposer = null;
    disposer: IReactionDisposer;
    layersInstalled: boolean = false;

    private getPhotoTileUrl(): string{
        return this.store.photo.getUrlPhotoList({isTile: true});
    }


    onInstall() {
        this.disp = autorun(() => {
            this.doUninstall();
            this.doInstall();
        });
    }

    onUninstall() {
        this.doUninstall();
        this.disp();
        this.disp = null;
    }


    public refreshTiles(){
        if (this.layersInstalled){
            let srcLayer = this.store.map.mapbox.getSource(PhotoLayersTool.PHOTO_SOURCE_ID);
            if (srcLayer != null){
                let src = (srcLayer as VectorSource);
                this.store.photo.tileVersion = Math.round(Math.random() * 10000000);
                src.tiles = [this.getPhotoTileUrl()];
                this.store.map.mapbox.triggerRepaint();
            }
        }
    }

    layersVsisble(): boolean{
        return this.store.photo.photoLayerVisible && this.store.agro.projectName != null;
    }

    private doInstall(){
        if (!this.store.map.mapReady) return;
        this.disposer =  autorun(() => {
            if (!this.store.map.mapReady) return;
            if (!this.store.photo.photoLayerVisible) return;
            if (this.store.map.bbox.isEmpty()) return;
            if (this.store.agro.datasetPhoto == null) return;
            if (this.store.agro.projectName == null) return;
            if (this.store.searchPanel.leftPanelMode == LeftPanelMode.photo) {
                this.store.photo.loadPhotoList();
            }
        });

        if (this.store.agro.datasetPhoto == null) return;
        //this.map.querySourceFeatures()
        let beforeLayer: string = this.getNextLayerName();
       
        if (this.map.getSource(PhotoLayersTool.PHOTO_SOURCE_ID) == null){
            this.map.addSource(PhotoLayersTool.PHOTO_SOURCE_ID, {
                type: 'vector',
                tiles: [this.getPhotoTileUrl()],
                scheme: "xyz",
                //maxzoom : MapComp.MAX_ZOOM
            });
            this.addLayer({
                id: PhotoLayersTool.PHOTO_LAYER_ID,
                source: PhotoLayersTool.PHOTO_SOURCE_ID,
                "source-layer": this.store.agro.datasetPhoto,
                type: 'circle',
                layout:{visibility: this.layersVsisble()?"visible":"none"},
                paint: <CirclePaint>{
                    'circle-radius': [
                        'match',
                        ['to-string',['>', ['get', 'photo_cnt'], 1]],
                        'true', 9,
                        5
                    ],
                    'circle-color': [
                        'match',
                        ['to-string',['>', ['get', 'photo_cnt'], 1]],
                        'true', 'rgba(255,0,0,1)',
                        'rgba(0,255,0,1)'
                    ],
                    'circle-stroke-width': 1,
                    'circle-stroke-color': [
                        'match',
                        ['to-string',['>', ['get', 'photo_cnt'], 1]],
                        'true', 'rgb(144,0,0)',
                        'rgb(21,111,21)'
                    ],
                }
            });

            this.addLayer({
                id: PhotoLayersTool.PHOTO_LAYER_TEXT_ID,
                source: PhotoLayersTool.PHOTO_SOURCE_ID,
                "source-layer": this.store.agro.datasetPhoto,
                type: 'symbol',
                filter: ['>', ['get', 'photo_cnt'], 1],
                layout:<SymbolLayout>{
                    'text-field': ['get', 'photo_cnt'],
                    "text-allow-overlap": true,
                    'text-font': [
                        'Open Sans Semibold'
                    ],
                    'text-anchor': 'center',
                    "text-size": 8,
                    visibility: this.layersVsisble()?"visible":"none",
                },
                paint: <SymbolPaint>{
                    "text-color": 'black'
                }
            });
        }
        this.layersInstalled = true;

        this.disposerVisibleLayers = reaction(()=>{
            return {aa: this.store.photo.photoLayerVisible, bb: this.store.agro.projectName};
            },()=>{
            if (this.layersInstalled){
                if (this.store.map.mapbox.getLayer(PhotoLayersTool.PHOTO_LAYER_ID)){
                    this.store.map.mapbox.setLayoutProperty(PhotoLayersTool.PHOTO_LAYER_ID, 'visibility',  this.layersVsisble()?"visible":"none" );
                }
                if (this.store.map.mapbox.getLayer(PhotoLayersTool.PHOTO_LAYER_TEXT_ID)){
                    this.store.map.mapbox.setLayoutProperty(PhotoLayersTool.PHOTO_LAYER_TEXT_ID, 'visibility',  this.layersVsisble()?"visible":"none" );
                }
            }
        });
    }

    public getImages(){
        let map = this.map;
        let mbf = map.querySourceFeatures(PhotoLayersTool.PHOTO_SOURCE_ID, {
            sourceLayer: this.store.agro.datasetPhoto
        });
        let counter = mbf.length;
        mbf.forEach(a => {
            let id = a.properties["photo_id"];
            let url = `/api/projects/${this.store.agro.projectName}/photo/image?photo_id=${id}&width=100`;
            map.loadImage(url, (error: any, image: any)=>{
                counter--;
                if (error) throw error;
                map.addImage("photoImg-"+id, image);
                if (counter == 0){
                    this.addLayer({
                        id: PhotoLayersTool.PHOTO_LAYER_ID2,
                        source: PhotoLayersTool.PHOTO_SOURCE_ID,
                        "source-layer": this.store.agro.datasetPhoto,
                        type: 'symbol',
                        layout: <Layout>{
                            'icon-image': ["concat","photoImg-", ['get', 'photo_id']], // reference the image
                            //'icon-size': 0.25
                        }
                    });
                }
            });
        });


    }

    private doUninstall(){
        if (this.map != null){
            if (this.map.getLayer(PhotoLayersTool.PHOTO_LAYER_ID) != null) {
                this.removeLayer(PhotoLayersTool.PHOTO_LAYER_ID);
            }
            if (this.map.getLayer(PhotoLayersTool.PHOTO_LAYER_TEXT_ID) != null) {
                this.removeLayer(PhotoLayersTool.PHOTO_LAYER_TEXT_ID);
            }
            if (this.map.getSource(PhotoLayersTool.PHOTO_SOURCE_ID) != null) {
                this.map.removeSource(PhotoLayersTool.PHOTO_SOURCE_ID);
            }
        }
        this.layersInstalled = false;
        if (this.disposer != null) {
            this.disposer();
            this.disposer = null;
        }
        if (this.disposerVisibleLayers != null){
            this.disposerVisibleLayers();
            this.disposerVisibleLayers = null;
        }
    }

}