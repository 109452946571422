import {Utils} from "../../../helper/utils/Utils";
import {IGraphDayValue, IndexByPointGraphStore} from "../IndexByPointGraphStore";
import {IndexByPointerSourceSimpleTempCustomStore} from "./IndexByPointerSourceSimpleTempCustomStore";

export class IndexByPointerSourceSimpleTempNOAAClimateStore extends IndexByPointerSourceSimpleTempCustomStore{
    constructor(parent: IndexByPointGraphStore) {
        super(parent);
        this.dataValue.getUrl = this.getUrlValue;
        this.dataMin.getUrl = this.getUrlMin;
        this.dataMax.getUrl = this.getUrlMax;
        this.dataValue.getValue = this.getValueSimple;
        this.dataMin.getValue = this.getValueMin;
        this.dataMax.getValue = this.getValueMax;
    }

    protected getUrlValue(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        const {dayOfYearBegin, dayOfYearEnd} = Utils.adjustDayOfYear(
            dateBegin,
            dateEnd
        );
        let url = `/api/meteo/psl_tmp_daily_ltm/time?lon=${gp.point.lng}&lat=${gp.point.lat}&product=avg&from_day=${dayOfYearBegin}&to_day=${dayOfYearEnd}`;
        return url;
    }
    protected getUrlMin(): string{
        let gp = this.parent.gPoint;
        let url = `/api/meteo/psl_tmp_daily_ltm/time?lon=${gp.point.lng}&lat=${gp.point.lat}&product=min`;
        return url;
    }
    protected getUrlMax(): string{
        let gp = this.parent.gPoint;
        let url = `/api/meteo/psl_tmp_daily_ltm/time?lon=${gp.point.lng}&lat=${gp.point.lat}&product=max`;
        return url;
    }
    protected getValueSimple(a: any): IGraphDayValue{
        return {dayOfYear: a.day - 1, value: a.tempc};
    }
    protected getValueMin(a: any): IGraphDayValue{
        return {dayOfYear: a.day - 1, value: a.tempc};
    }
    protected getValueMax(a: any): IGraphDayValue{
        return {dayOfYear: a.day - 1, value: a.tempc};
    }


}