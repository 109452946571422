import {autorun, IReactionDisposer} from "mobx";
import {CustomStoreTool} from "../general/CustomStoreTool";
import {Utils} from "../../../helper/utils/Utils";
//import {WindGL} from "../../../../libs/wind-gl";
import {WindGL}  from "../../../../libs/WindGL";

export class GfsWindOverlayTool extends CustomStoreTool {
    static LAYER_ID = "class_gfsWindLayer";
    static SOURCE_ID = "class_gfsWindSource";
    static CANVAS_ID = "wind-canvas";
    
    wind : any;
    pxRatio : number = Math.max(Math.floor(window.devicePixelRatio) || 1, 2); 
    isRetina : boolean = this.pxRatio !== 1;
    canvas : HTMLCanvasElement = null;
    onInstall() {
        let mapDiv = this.store.map.mapbox.getContainer();
        this.canvas = document.getElementById(GfsWindOverlayTool.CANVAS_ID) as HTMLCanvasElement;
        if (! this.canvas) {
            this.canvas = document.createElement('canvas');
            this.canvas.id = GfsWindOverlayTool.CANVAS_ID;
            this.canvas.width = mapDiv.clientWidth;
            this.canvas.height = mapDiv.clientHeight;
            this.canvas.hidden = true;
            mapDiv.after(this.canvas);
        }
        let gl = this.canvas.getContext('webgl', {antialiasing: false}) as WebGLRenderingContext;
        this.wind = new WindGL(gl);
        this.wind.numParticles = 65536;
        this.updateRetina()
        // this.frame();
        // this.updateWind('2023032000');
    }

    frame() {
        if (! this.store.map.gfsWind.active)
            return;
        if (this.wind.windData) {
            this.wind.draw();
        }
        requestAnimationFrame(this.frame);
    }

    updateRetina() {
        const ratio = this.isRetina ? this.pxRatio : 1;
        this.canvas.width = 1440 * ratio;
        this.canvas.height = 720 * ratio;
        this.wind.resize();
    }

    updateWind(name: string) {
        fetch(`wind_data/${name}.json`)
        .then((resp) => resp.json())
        .then((windData) => {
            const windImage = new Image();
            windData.image = windImage;
            windImage.src = `wind_data/${name}.png`;
            windImage.onload = () => this.wind.setWind(windData);
        });
    }

    onUninstall() {
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.setupLayers();
            }), ...super.onSubscription()
        ];
    }

    setupLayers() {
        this.unloadOverlay();
        this.loadOverlay();
    }

    unloadOverlay() {
        let map = this.store.map.mapbox;
        if (map.getLayer(GfsWindOverlayTool.LAYER_ID))
            this.removeLayer(GfsWindOverlayTool.LAYER_ID);
        if (map.getSource(GfsWindOverlayTool.SOURCE_ID))
            map.removeSource(GfsWindOverlayTool.SOURCE_ID);
    }

    loadOverlay(){
        let map = this.store.map.mapbox;
        let wind = this.store.map.gfsWind;
        if (! wind.active) return;
        let imageSrc = wind.params.dataTemplateUrl.replace("{date}", Utils.formatDate(wind.date));

        if (! map.getSource(GfsWindOverlayTool.SOURCE_ID))
            map.addSource(GfsWindOverlayTool.SOURCE_ID, {
                type: 'canvas',
                canvas: GfsWindOverlayTool.CANVAS_ID,
                coordinates: [
                    [-180, 85.0511],
                    [180, 85.0511],
                    [180, -85.0511],
                    [-180, -85.0511]
                ],
                animate: true
            });
        this.addLayer({
            id: GfsWindOverlayTool.LAYER_ID,
            type: 'raster',
            source: GfsWindOverlayTool.SOURCE_ID
        });
        this.frame();
        this.updateWind('2023032000');
    }
}