import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {ProductItemComp} from "./ProductItemComp";
import autoBindReact from "auto-bind/react";
import {LeftPanelMode, SceneUiType} from "../../../../store/SearchStore";
import {ScrollSaveComp} from "../../../Common/ScrollSaveComp";
import ProductSettingsComp from './ProductSettingsComp';
import classNames from 'classnames';


export interface INdviPanel extends IStoreProps{
    sceneUiType: SceneUiType
}
@observer
export class NdviPanel extends React.Component<INdviPanel, undefined> {
    constructor(props: INdviPanel) {
        super(props);
        autoBindReact(this);
    }

    @action
    close(){
        let store = this.props.store;
        if (store.searchPanel.showSearchProductPanel)
            store.searchPanel.showSearchProductPanel = false;
        if (store.map.productInfo.activeProductsSet.favoriteProductGroupId)
            store.map.productInfo.activeProductsSet.favoriteProductGroupId = null;
    }
    @action
    onScroll(pos: number){
        this.props.store.map.ndviPanelScrollPos = pos;
    }
    render() {
        let store = this.props.store;
        let arr: any[] = [];
        let prodCodeStore = store.map.productInfo.activeProductsSet.getActiveCodeByUiType(this.props.sceneUiType);

        //store.map.productInfo.activeProductsSet.products.forEach(a => {
        prodCodeStore.products.forEach(a => {
            arr.push(<ProductItemComp store={store} item={a} key={a.prod_name} productCode={prodCodeStore} />);
        });
        let expandedItem = prodCodeStore.products.find(a => a.expand);
        let isProductOpen = !!expandedItem;
        let isDisable = store.searchPanel.leftPanelMode != LeftPanelMode.favorite && !!!store.searchPanel.currentSceneid 
        return <div className="popup-ndvi active-flex">
            <div className="popup-ndvi-in">
                <div className="popup-ndvi-in-closer" onClick={this.close}>
                    <div className="close-button-icon"/>
                </div>
                {!isProductOpen && (
                <>
                    <div className="popup-ndvi-in__row1">
                        {store.trans.Products}
                    </div>
                    <ScrollSaveComp
                        className={classNames("style-4", "flex-stretch-item", "popup-ndvi-in__view",
                        {
                            "popup-ndvi-in__view_disable": isDisable
                        }
                        )}
                        scrollTop={store.map.ndviPanelScrollPos}
                        onScroll={this.onScroll}
                    >
                        {arr}
                    </ScrollSaveComp>
                </>
            )}
                    {isProductOpen && <ProductSettingsComp store={store} item={expandedItem} productCode={prodCodeStore}/>}
            </div>
        </div>;
    }
}
