import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './IndexByPointPopupMonthComp.scss';
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {DateUtils} from "../../../../../helper/utils/DateUtils";
import {action, autorun, observable, runInAction} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";

export interface IIndexByPointPopupMonthComp extends IStoreProps{
    onClose?: ()=>any,
    autoSubmit?: boolean,
}
@observer
export class IndexByPointPopupMonthComp extends React.Component<IIndexByPointPopupMonthComp, undefined> {
    constructor(props: IIndexByPointPopupMonthComp) {
        super(props);
        autoBindReact(this);
        if (this.props.autoSubmit){
            this.autoReloadSettings = autorun(()=>{
                this.getSettings();
            });
        }else{
            this.getSettings();
        }
    }
    @observable
    monthBegin: number;
    @observable
    monthEnd: number;
    autoReloadSettings: IReactionDisposer;

    getSettings(){
        this.monthEnd = this.props.store.indexByPointer.monthEnd;
        this.monthBegin = this.props.store.indexByPointer.monthBegin;
    }

    componentWillUnmount() {
        if (this.autoReloadSettings != null) this.autoReloadSettings();
    }

    @action
    onSubmit(){
        if (this.props.onClose != null) this.props.onClose();
        let store = this.props.store;
        store.indexByPointer.monthEnd = this.monthEnd;
        store.indexByPointer.monthBegin = this.monthBegin;
        store.indexByPointer.graphs.forEach((a) => {
            runInAction(() => {
                a.resetStatus();
            });
        });
    }
    onChangeMonthBegin(item: IDropDownItem, newIndex: number){
        this.monthBegin = item.data;
        if (this.props.autoSubmit) this.onSubmit();
    }
    onChangeMonthEnd(item: IDropDownItem, newIndex: number){
        this.monthEnd = item.data;
        if (this.props.autoSubmit) this.onSubmit();
    }

    render() {
        let store = this.props.store;
        let monthItems: IDropDownItem[] = [];
        for(let i = 0; i <= 11; i++){
            monthItems.push({value: DateUtils.getMonthName(i, store.trans), key: i.toString(), data: i});
        }

        let className = "IndexByPointPopupMonthComp-main";
        if (this.props.className) className = this.props.className;

        return <div className={className}>
            <div className="IndexByPointPopupMonthComp-title">{store.trans.Period}</div>
            <div className="flex-columns-centerByVertical">
                <DropDownComp items={monthItems} currentKey={this.monthBegin.toString()}
                              onChange={this.onChangeMonthBegin}
                              className="flex-stretch-item" />
                <div className="flex-full-center">
                    <div className="IndexByPointPopupMonthComp-divider" />
                </div>
                <DropDownComp items={monthItems} currentKey={this.monthEnd.toString()}
                              onChange={this.onChangeMonthEnd}
                              className="flex-stretch-item" />
            </div>
            {!this.props.autoSubmit &&
            <div className="flex-full-center">
                <button className="large-button left-sidebar-active button-jump IndexByPointPopupMonthComp-button" onClick={this.onSubmit} >
                    {store.trans.Apply}
                </button>
            </div>}
        </div>;
    }
}
