import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {action, computed, observable} from "mobx";
import {
    IProductConfig,
    IProductStyleAdaptiveConfig,
    IProductStyleIndexConfig,
    IProductStyleStaticConfig,
    IProductType,
    ProductStyleType
} from "../config/ConfigStore";
import {ClassFactory} from "../ClassFactory";
import {StaticStyleProductStore} from "./StaticStyleProductStore";
import {AdaptiveStyleProductStore} from "./AdaptiveStyleProductStore";
import {BandStaticStyleProductStore} from "./BandStaticStyleProductStore";
import {IndexBandProductStore} from "./IndexBandProductStore";
import {isNumber, isString} from "lodash-es";


export class ProductSettingStore extends CustomStore{
    class(): string {
        return "ProductSettingStore";
    }
    @save
    productConfig: IProductConfig = null;

    @save @observable
    prod_name: string;
    @save @observable
    unitName: string = "";
    @save @observable
    unitDescription: string = "";
    @save @observable
    staticStyle: StaticStyleProductStore = new StaticStyleProductStore(this);
    @save @observable
    adaptiveStyle: AdaptiveStyleProductStore = new AdaptiveStyleProductStore(this);

    @save @observable
    styleType: ProductStyleType = null;

    @save @observable
    indexBand: IndexBandProductStore = new IndexBandProductStore(this);

    @observable
    changed: boolean = false;
//    private _editor: ProductSettingStore = null;

    @computed
    get bandType(): IProductType{
        return this.productConfig.type;
    }
    @computed
    get editor(): ProductSettingStore{
        return this;
        let r =  ClassFactory.deepClone(this, this) as ProductSettingStore;
        r.productConfig = this.productConfig;
        return r;
    }


    @save @observable
    _expand: boolean = false;
    get expand(): boolean{ return this._expand;}
    set expand(value: boolean){
        if (this._expand == value) return;
        //this._editor = null;
        this._expand = value;
    }

    public styleTypes(): string[]{
        let cfg = this.productConfig;
        if (!cfg) return [];
        if (!cfg.styles) return [];
        return cfg.styles.map(a => a.type);
    }

    getStyles(): any{
        let st: any = null;
        if (this.productConfig.type == "index"){
            st = this.indexBand.getTileSetting();
        } else
        if (this.hasStyles()){
            if (this.styleType == ProductStyleType.static){
                let bnds = this.staticStyle.bands.map(a => {return {"band": a.name, "min": a.min, "max": a.max}});
                st = {type: "static", "bands": bnds};
            }
            if (this.styleType == ProductStyleType.adaptive){
                let cfg = this.productConfig;
                let bnds = cfg.bands.map(a => {return {"band": a}});
                st = {
                    type: "adaptive",
                    "bands": bnds,
                    "mask": this.adaptiveStyle.mask,
                    "std_m": this.adaptiveStyle.std_m};
            }
        }
        return st;
    }
    public hasStyles(): boolean{
        return this.styleTypes().length > 0;
    }

    @computed
    get getIndexStyle(): IProductStyleIndexConfig{
        let p = this.productConfig;
        if (p == null) return null;
        return p.styles.find(a => a.type == ProductStyleType.index) as IProductStyleIndexConfig;
    }
    @computed
    get getAdaptiveStyle(): IProductStyleAdaptiveConfig{
        let p = this.productConfig;
        if (p == null) return null;
        return p.styles.find(a => a.type == "adaptive") as IProductStyleAdaptiveConfig;
    }
    @computed
    get getStaticStyle(): IProductStyleStaticConfig{
        let p = this.productConfig;
        if (p == null) return null;
        return p.styles.find(a => a.type == "static") as IProductStyleStaticConfig;
    }
    @action
    public loadDefault(){
        if (this.productConfig.type == "index"){
            let idxConf = this.getIndexStyle;
            if (idxConf == null) return;
            if (isNumber(idxConf.def_min)) this.indexBand.min = idxConf.def_min;
            if (isNumber(idxConf.def_max)) {
                this.indexBand.max = idxConf.def_max;
            }
            if (isNumber(idxConf.def_classes)) this.indexBand.classes = idxConf.def_classes;
            if (isString(idxConf.def_interpolation)) this.indexBand.interpolation = idxConf.def_interpolation;
            this.indexBand.recalculateClassValuesFull();
            if (isString(idxConf.def_color_preset)){
                let pc = this.root.map.presetColors.find(a => a.id == idxConf.def_color_preset);
                if (pc != null){
                    this.indexBand.colors = pc;
                }
            }
            return;
        }
        let st = this.getStaticStyle;
        this.styleType = this.productConfig.def_style;
        if (st != null){
            this.staticStyle.bands = [];
            st.bands.forEach(a => {
                let b = new BandStaticStyleProductStore(this);
                b.min = a.min;
                b.max = a.max;
                b.maxEdit = a.max.toString();
                b.minEdit = a.min.toString();
                b.name = a.band;
                this.staticStyle.bands.push(b);
            });
        }
        let ad = this.getAdaptiveStyle;
        if (ad != null){
            this.adaptiveStyle.std_m = ad.std_m;
            let defMask = ad.masks.find(a => a.default);
            if (defMask == null){
                this.adaptiveStyle.mask = defMask.mask;
            }else{
                if (ad.masks.length > 0){
                    this.adaptiveStyle.mask = ad.masks[0].mask;
                }
            }
        }
    }
}

