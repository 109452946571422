import {CustomStoreTool} from "../../../../app/store/tools/general/CustomStoreTool";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import {MapboxGeoJSONFeature, PointLike} from "maplibre-gl";
import * as mapboxgl from "maplibre-gl";
import {SampleSelectionTool} from "./SampleSelectionTool";
import {IAgroExportSuperStore} from "../../agroExportPlugin";

export class VoronovClickTool extends CustomStoreTool {
    get agroStore(): IAgroExportSuperStore{
        return this.store.getRootAs<IAgroExportSuperStore>();
    }
    onMouseDown(e: maplibregl.MapMouseEvent & ToolEvent) {
        if (!this.agroStore.agroExport.voronovExportStore.showReadyLayers()) return;
        if (!this.agroStore.agroExport.voronovExportStore.canEdit()) return;
        if (this.agroStore.agroExport.voronovExportStore.editManager.isEdit()) return;
        let point = e.point;
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(point.x - 5, point.y - 5),
            new mapboxgl.Point(point.x + 5, point.y + 5)
        ];
        let res: MapboxGeoJSONFeature[] = this.map.queryRenderedFeatures(bbox,
            {layers:[
                    SampleSelectionTool.CELLSTracks_NAME
                ]});
        if (res.length == 0) return;
        this.agroStore.agroExport.voronovExportStore.editManager.toEdit(res[0].geometry);
        e.noPropagation();
    }
}