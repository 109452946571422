import * as React from 'react';
import {observer} from "mobx-react";
import './AgroAhoSpinnerComp.scss'
import {IAgroAhoStoreProps} from "../agroAhoPlugin";


@observer
export class AgroAhoSpinnerComp extends React.Component<IAgroAhoStoreProps, undefined> {

    render() {
        let store = this.props.store;

        return <div className="AgroAhoSpinnerComp-main">
            <div className="AgroAhoSpinnerComp-title">
                <div className="AgroAhoSpinnerComp-path">{store.trans['Loading...']}</div>
            </div>
            <div className="AgroAhoSpinnerComp-cover">
                <div className="loading-search">
                    <div className="spinner"/>
                </div>
            </div>
        </div>
    }
}
