import {CustomStoreTool} from "../general/CustomStoreTool";
import {IReactionDisposer} from "mobx/lib/internal";
import {ShowRoom2LayerDistrictsTool} from "./ShowRoom2LayerDistrictsTool";
import {ShowRoom2LayerPointCceTool} from "./ShowRoom2LayerPointCceTool";
import {ShowRoom2LayerPointClassTool} from "./ShowRoom2LayerPointClassTool";
import {ShowRoom2LayerPointYieldAssTool} from "./ShowRoom2LayerPointYieldAssTool";
import {ShowRoom2PopupTool} from "./ShowRoom2PopupTool";
import {ShowRoom2LayerYieldMapGpTool} from "./ShowRoom2LayerYieldMapGpTool";

export class ShowRoom2Tool extends CustomStoreTool{

    districts: ShowRoom2LayerDistrictsTool;
    cce: ShowRoom2LayerPointCceTool;
    class: ShowRoom2LayerPointClassTool;
    yieldAss: ShowRoom2LayerPointYieldAssTool;
    yieldMapGp: ShowRoom2LayerYieldMapGpTool;
    popup: ShowRoom2PopupTool;

    onInstall() {
        super.onInstall();
    }

    onUninstall() {
        super.onUninstall();
    }

    onSubscription(): IReactionDisposer[] {
        return [];
    }

}