import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../helper/structs/IStoreProps";
/*
* LastStack компоненты для вставки детей поверх всех.
*/
@observer
export class LastStackRootManagerComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let c = store.getTopComponents;
        return <React.Fragment>{c}</React.Fragment>;
    }
}
