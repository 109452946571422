import React from "react";
import { IIconProp } from "../IIconProp";

const CompactListSvg = (props: IIconProp) => {
    return <svg width="25" height="16" viewBox="0 0 25 16" className={props.className} xmlns="http://www.w3.org/2000/svg">
        <path d="M1.40369 3.42396L1.49969 3.36296V5.99996C1.49969 6.41396 1.83569 6.74996 2.24969 6.74996C2.66369 6.74996 2.99969 6.41396 2.99969 5.99996V1.99996C2.99969 1.72696 2.85069 1.47496 2.61169 1.34296C2.37169 1.21196 2.07969 1.22096 1.84869 1.36596L0.601687 2.15596C0.251687 2.37796 0.147687 2.84096 0.369687 3.19096C0.591687 3.54196 1.05669 3.64396 1.40469 3.42296L1.40369 3.42396Z" />
        <path d="M15 2H7C6.448 2 6 2.448 6 3C6 3.552 6.448 4 7 4H15C15.552 4 16 3.552 16 3C16 2.448 15.552 2 15 2Z" />
        <path d="M15 12H7C6.448 12 6 12.448 6 13C6 13.552 6.448 14 7 14H15C15.552 14 16 13.552 16 13C16 12.448 15.552 12 15 12Z" />
        <path d="M3.83004 10.3892C3.83004 10.658 3.7747 10.8909 3.66403 11.0878C3.55336 11.2848 3.40316 11.4458 3.21344 11.5709C3.02635 11.6959 2.81555 11.7878 2.58103 11.8463V11.8703C3.0448 11.9288 3.39657 12.0725 3.63636 12.3014C3.87879 12.5303 4 12.8363 4 13.2196C4 13.5602 3.917 13.8649 3.75099 14.1337C3.58762 14.4025 3.33465 14.6141 2.99209 14.7685C2.64954 14.9228 2.20817 15 1.66798 15C1.34914 15 1.05138 14.9734 0.774704 14.9202C0.603172 14.8885 0.437834 14.8459 0.278691 14.7924C0.106562 14.7344 0 14.5674 0 14.3857C0 14.043 0.368005 13.8164 0.698926 13.9056C0.725388 13.9128 0.751965 13.9196 0.778656 13.9261C1.05007 13.99 1.30303 14.022 1.53755 14.022C1.97497 14.022 2.28063 13.9461 2.45455 13.7944C2.63109 13.6401 2.71937 13.4245 2.71937 13.1477C2.71937 12.9854 2.67852 12.8483 2.59684 12.7365C2.51515 12.6248 2.37286 12.5396 2.16996 12.481C1.9697 12.4225 1.68906 12.3932 1.32806 12.3932C1.08576 12.3932 0.889328 12.1968 0.889328 11.9545V11.8937C0.889328 11.6471 1.0893 11.4471 1.33597 11.4471C1.6917 11.4471 1.96179 11.4138 2.14625 11.3473C2.33333 11.2781 2.45982 11.185 2.52569 11.0679C2.5942 10.9481 2.62846 10.8124 2.62846 10.6607C2.62846 10.4531 2.56522 10.2908 2.43874 10.1737C2.31225 10.0566 2.10145 9.998 1.80632 9.998C1.62187 9.998 1.45323 10.022 1.3004 10.0699C1.1502 10.1151 1.01449 10.171 0.893281 10.2375C0.711855 10.3331 0.481135 10.2868 0.367611 10.116L0.284581 9.99107C0.129903 9.75838 0.185288 9.44148 0.436061 9.31826C0.551237 9.26166 0.673341 9.21013 0.802372 9.16367C1.10804 9.05456 1.47167 9 1.89328 9C2.4888 9 2.96047 9.12109 3.3083 9.36327C3.65613 9.60546 3.83004 9.94744 3.83004 10.3892Z"/>
        <path d="M24.0514 9.36473H23.1205C23.1433 9.344 23.1661 9.32436 23.1876 9.30473L23.3292 9.176C24.1602 8.42764 24.8166 7.83636 24.8128 6.92873C24.8128 6.61236 24.7243 6.30582 24.5611 6.04291C24.1918 5.44509 23.4431 5.05782 22.5577 5.00436C21.3726 4.93891 20.3039 5.61636 20.0269 6.62109C19.9055 7.06073 20.2204 7.50145 20.7301 7.60618C21.2386 7.712 21.7508 7.43818 21.8722 6.99963C21.9342 6.77818 22.1859 6.62436 22.4287 6.63636C22.6412 6.64945 22.8309 6.72145 22.8878 6.81309C22.8916 6.82073 22.9144 6.86 22.9144 6.93309C22.9144 7.16873 22.5868 7.47527 21.9582 8.04254L21.814 8.17236C21.4472 8.50291 20.9047 9.00036 20.2432 9.63418C19.9928 9.87418 19.9295 10.2189 20.0826 10.5145C20.2356 10.8102 20.5746 11 20.9489 11H24.0514C24.575 11 25 10.6335 25 10.1818C25 9.73018 24.575 9.36364 24.0514 9.36364V9.36473Z" fill="#4DB6BC" stroke="none"/>
        <path d="M17.375 7.40005H12.3752V5.60018C12.3752 5.48713 12.342 5.37637 12.2793 5.28066C12.2166 5.18495 12.1271 5.10818 12.0209 5.05921C11.9148 5.01024 11.7964 4.99105 11.6794 5.00386C11.5624 5.01667 11.4515 5.06096 11.3596 5.13162L8.23466 7.53144C8.16144 7.58764 8.10233 7.65894 8.06171 7.74005C8.02109 7.82116 8 7.91 8 8C8 8.09 8.02109 8.17884 8.06171 8.25995C8.10233 8.34106 8.16144 8.41236 8.23466 8.46856L11.3596 10.8684C11.4515 10.939 11.5624 10.9833 11.6794 10.9961C11.7964 11.0089 11.9148 10.9898 12.0209 10.9408C12.1271 10.8918 12.2166 10.8151 12.2793 10.7193C12.342 10.6236 12.3752 10.5129 12.3752 10.3998V8.59995H17.375C17.5408 8.59995 17.6997 8.53675 17.8169 8.42423C17.9342 8.31172 18 8.15912 18 8C18 7.84088 17.9342 7.68828 17.8169 7.57577C17.6997 7.46325 17.5408 7.40005 17.375 7.40005Z" fill="#4DB6BC" stroke="none"/>
    </svg>;
}

export default CompactListSvg;