import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoCardStore} from "../agroAhoCardStore";
import {observable} from "mobx";
import {AhoUtils} from "../agroAhoUtils";
import {AhoNameColValuesError, UploadTableData} from "../agroAhoUploadTableStore";
import {ra} from "../../../app/helper/utils/mobxUtils";
import {fetchJsonGet} from "../../../app/helper/utils/FetchUtils";
import {Utils} from "../../../app/helper/utils/Utils";


export enum EditCellsStatus {
    Unset = 'Unset',
    NoNamesErr = 'NoNamesErr',
    DuplicateErr = 'DuplicateErr',
    DuplicateNewErr = 'DuplicateNewErr',
    FileErr = 'FileErr',
    NoOldNameColumnErr = 'NoOldNameColumnErr',
    NoNewNameColumnErr = 'NoNewNameColumnErr',
    Success = 'Success'
}

export class AgroAhoEditCellsStore extends ObservableCustomStore {
    constructor(parent: AgroAhoCardStore) {
        super(parent);
        this.cardStore = parent;
    }

    cardStore: AgroAhoCardStore;
    @observable
    status: EditCellsStatus = EditCellsStatus.Unset;
    @observable
    list: any[] = [];
    @observable
    file_name: string = '';
    @observable
    loadedFileData: any = null;

    resetList(){
        this.file_name = '';
        this.loadedFileData = null;
        this.status = EditCellsStatus.Unset;
        this.list = [];
    }

    getFkErr(){
        let store_ = this.cardStore.parentStore;
        let r = this.loadedFileData;
        let res: string[] = [];
        (r.columns || []).forEach((c: any)=>{
            if (c.title == 'cell_name' && (c.fk_error || []).length) {
                c.fk_error.forEach((f: any)=>{
                    res.push(`${f.cell_name} (${store_.trans.row}: ${f.row})`);
                });
            }
        });
        return res.length ? res : null;
    }

    getDuplicates(){
        let store_ = this.cardStore.parentStore;
        let r = this.loadedFileData;
        let res: any = null;
        (r.columns || []).forEach((c: any)=>{
            let d = c.dubles || {};
            if (Object.keys(d).length) {
                let l: any = [];
                Object.keys(d).forEach((k: any)=>{
                    (d[k].rows || []).forEach((v_: any)=>l.push(`${k} (${store_.trans.row}: ${v_})`))
                });
                if (c.title == 'cell_name' || c.title == 'cell_name_new') {
                    res = {title: c.title, list: l};
                }
            }
        });
        return res;
    }

    uploadProgress(res: any){

        // console.log('res', res);
        if (!res) return;
        if (res.type == 'result' && res.status == 'success') {
            let r = res.data;
            this.loadedFileData = r;
            // console.log('file loaded', AhoUtils.cp(r));
            let d = this.getDuplicates();
            let f = this.getFkErr();
            // console.log('err:', AhoUtils.cp(d), AhoUtils.cp(f));
            if (r.err == 0) {
                ra(()=>{
                    this.list = r.values.map((v: any)=>{return {old: v.cell_name, new: v.cell_name_new}});
                    this.status = EditCellsStatus.Success;
                });
            } else {
                if (r.required_columns?.[0]?.title == 'cell_name_new') {
                    this.status = EditCellsStatus.NoNewNameColumnErr;
                } else if (r.required_columns?.[0]?.title == 'cell_name') {
                    this.status = EditCellsStatus.NoOldNameColumnErr;
                } else if (d) {
                    this.list = d.list;
                    this.status = d.title == 'cell_name' ? EditCellsStatus.DuplicateErr :
                        EditCellsStatus.DuplicateNewErr;
                } else if (f) {
                    this.list = f;
                    this.status = EditCellsStatus.NoNamesErr;
                } else {
                    this.root.trans['Loading error'];
                }
            }

        }
        if (res.type == 'error') {
            let msg = res.msg || this.root.trans['Loading error'];
            this.root.addError(msg);
        }
        if (res.type == 'progress') {
            // console.log('upload progress', res.data);
        }
        if (res.type == 'change_file') {
           this.file_name = res.data?.name;
        }
    }

    onLoadTemplate(){
        let r = [
            this.cardStore.parentStore.projStore.projName,
            this.cardStore.card.map_id
        ];
        let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/cell/rename/file/template/get`;
        // console.log('url:', url);
        Utils.downloadFile(url);
    }

    onSave(){
        let store_ = this.cardStore.parentStore;
        let r = [
            this.cardStore.parentStore.projStore.projName,
            this.cardStore.card.map_id,
            this.loadedFileData.file_id
        ];
        let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/cell/rename/file/save?file_id=${r[2]}`;
        fetchJsonGet(url).then(async res=>{
            await this.cardStore.updCellsInfo();
            await this.cardStore.parentStore.mapStore.updLayers();
            this.root.addInfo(this.root.trans['Saved successfully']);
            store_.toggleModal(false);
            // console.log('r', AhoUtils.cp(res));
            // if (res.result == 'ok') {
            //
            // } //else this.root.addError(this.parentStore.trans['Deletion error']);
        }).catch(err => this.root.addError(err));
    }

    async loadTable(){
        let proj_name = this.cardStore.parentStore.projStore.projName;
        let map_id = this.cardStore.card.map_id;
        let opt = {
            url: `/api/projects/${proj_name}/asa2/map/${map_id}/cell/rename/file/info`,
            field_name: 'cell_file',
            callback: this.uploadProgress
        }
        AhoUtils.uploadFile(opt);

        // let res_ = {type: 'result', status: 'success', data: {}}
        // this.uploadProgress(res_);
    }



}