import * as React from 'react';

export class AgroAhoHelpFileSvg extends React.Component {

    render() {
        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 14.625C8.379 14.625 7.875 14.121 7.875 13.5C7.875 12.879 8.379 12.375 9 12.375C9.621 12.375 10.125 12.879 10.125 13.5C10.125 14.121 9.621 14.625 9 14.625ZM10.8259 9.1035C10.2668 9.58387 10.125 9.74588 10.125 10.125C10.125 10.7471 9.621 11.25 9 11.25C8.379 11.25 7.875 10.7471 7.875 10.125C7.875 8.66925 8.76937 7.902 9.36225 7.39462C9.92137 6.9165 10.0631 6.75337 10.0631 6.37537C10.0631 6.1695 10.0631 5.625 9.00113 5.625C8.51063 5.652 7.9875 5.877 7.58588 6.25612C7.13475 6.68138 6.4215 6.66 5.99512 6.21C5.56875 5.75775 5.589 5.04562 6.04125 4.61925C6.831 3.87562 7.86037 3.4335 8.94263 3.37725H8.946C10.9845 3.37725 12.3131 4.58213 12.3131 6.3765C12.3131 7.83113 11.4187 8.59838 10.827 9.10463L10.8259 9.1035Z" fill="#50A9F0"/>
        </svg>;
    }
}
