import {SuperStore} from "../SuperStore";
import {CustomStore} from "../CustomStore";
import {TranslateOver_ParcelAssess_en} from "../../helper/lang/TranslateOverride/TranslateOver_ParcelAssess_en";
import {TranslateOver_ParcelAssess_ru} from "../../helper/lang/TranslateOverride/TranslateOver_ParcelAssess_ru";
import {IFieldLayerStyles, ProjType} from "../user/UserStore";
import {ButtonGeometryCreateType} from "../agro/fieldForm/A2FieldFormStore";
import {DefaultLang, Lang} from "../../../pluginApi/store/Lang";
import {CirclePaint, FillPaint, LinePaint, SymbolLayout, SymbolPaint} from "maplibre-gl";

export interface IAgroMapStyles{
    styles: IFieldLayerStyles;
}
export class PluginApiStore extends CustomStore{
    getStore(): SuperStore{
        return this.root;
    }

    onGetAgroNewGeometryTypes(options: {buttons: Map<ButtonGeometryCreateType, boolean>}){
        if (!this.root.config.autoPolygon){
            options.buttons.set(ButtonGeometryCreateType.auto, false);
        }
        if (this.root.agro2.projectInfo.projectType == ProjType.slick){
            Array.from(options.buttons.keys()).forEach(k => {
                if (k != ButtonGeometryCreateType.polygon){
                    options.buttons.set(k, false);
                }
            });
        }
        if (this.root.agro2.projectInfo.projectType == ProjType.pnt_yld_assess){
            Array.from(options.buttons.keys()).forEach(k => {
                if (k != ButtonGeometryCreateType.point){
                    options.buttons.set(k, false);
                }
            });
        }
        if (this.root.agro2.projectInfo.projectType == ProjType.agro_base ||
            this.root.agro2.projectInfo.projectType == ProjType.agro_insur){
            Array.from(options.buttons.keys()).forEach(k => {
                if (k != ButtonGeometryCreateType.polygon && k != ButtonGeometryCreateType.auto){
                    options.buttons.set(k, false);
                }
            });
        }
        this.root.events.onAgroNewGeometryTypes.call(options.buttons);
    }

    onGetAgroMapStyles(options: IAgroMapStyles){
        this.getAgroStyle(options);
    }

    onChangeLanguage(){
        this.overrideTranslate(false);
    }
    onChangeStore(){
        this.overrideTranslate();
    }
    onSelectProject(){
        this.overrideTranslate();
    }
    onChangeProject(){
        this.overrideTranslate(true);
    }

    private overrideTranslateFromConfig(){
        let store = this.getStore();
        if (store.config.translate){

            let dic: any = null;
            dic = store.config.translate[store.getLang()];
            if (dic == null){
                dic = store.config.translate[DefaultLang];
            }
            if (dic != null){
                let words = Object.keys(dic);
                words.forEach(w => {
                    (store.trans as any)[w] = dic[w] as any;
                });
            }
        }
    }
    private overrideTranslate(reloadTranslate: boolean = true){
        let store = this.getStore();
        if (reloadTranslate) store.loadTranslate(store.getLang());
        this.overrideTranslateFromConfig();
        let prjType = store.agro2.projectInfo.projectType;
        if (prjType == ProjType.agro_base || prjType == ProjType.agro_region || prjType == ProjType.agro_insur) {
            if (this.getStore().getLang() == Lang.en) {
                TranslateOver_ParcelAssess_en.updateTranslate(store);
            }
            if (this.getStore().getLang() == Lang.ru) {
                TranslateOver_ParcelAssess_ru.updateTranslate(store);
            }
        }
    }

    private getAgroStyle(options: IAgroMapStyles): any {
        if (this.root.agro2.projectInfo.projectType == ProjType.agro_base || this.root.agro2.projectInfo.projectType == ProjType.agro_insur) {
            options.styles = <any>{
                fields: [
                    {
                        id: null,
                        type: 'fill',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                        ],
                        layout: {},
                        paint: <FillPaint>{
                            //'fill-color': '#4DB6BC',
                            'fill-color':
                                        ['match', ['get', 'crop_id'],
                                            188, '#00FF00',
                                            189, '#FFFF00',
                                            191, '#FF0000',
                                            2, '#FFA500',
                                            184, '#FFA500',
                                            26, '#008000',
                                            19, '#8FBC8F',
                                            201, '#0000FF',
                                            202, '#9370DB',
                                            6, '#FF00FF',
                                            182, '#DB7093',
                                            135, '#808080',
                                            186, '#000000',
                                            211, '#87CEEB',
                                            193, '#A0522D',
                                            18, '#800080',
                                            197, '#008080',
                                            33, '#808000',
                                            36, '#FFC0CB',
                                            37, '#99958C',
                                            32, '#FFE4B5',
                                            136, '#D8BFD8',
                                            158, '#D8BFD8',
                                            10004, '#D8BFD8',
                                            8, '#FFA07A',
                                            200, '#FFD700',
                                            199, '#40E0D0',
                                            198, '#C0C0C0',
                                            97, '#4B0082',
                                            51, '#4B0082',
                                            49, '#4B0082',
                                            190, '#C71585',
                                            74, '#9370DB',
                                            12, '#FF6347',
                                            13, '#CD853F',
                                            '#DE2996'
                                        ],

                            'fill-opacity': [
                                'step',
                                ['zoom'],
                                [//значение до 12
                                    'case',
                                    ['in', ['get', 'crop_id'], ['literal',[188,189,191,2,184,26,19,201,202,6,182,135,186,211,193,18,197, 33,36,37,32,136,158,10004,8,200,199,198,97,51,49,1990,74,12,13]]],
                                    1,
                                    0
                                ]
                                , 12, 0
                            ]
                            //['case', ['<=', ['zoom'], 11],
                                  //      ['case', ['in', ['get', 'crop_id'], 188,189,191,2,184,26,19,201,202,6,182,135,186,211,193,18,197,
                                    //    33,36,37,32,136,158,10004,8,200,199,198,97,51,49,1990,74,12,13], 1, 0],
                                    //0]//else
                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]],
                            //                      ["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                        ],
                        paint: <LinePaint>{
                            'line-color':
                                '#DE2996',
                            'line-width': 4,
                            'line-opacity':
                                [
                                    'step',
                                    ['zoom'],
                                    [//значение до 12
                                        'case',
                                        ['in', ['get', 'crop_id'], ['literal',[188,189,191,2,184,26,19,201,202,6,182,135,186,211,193,18,197, 33,36,37,32,136,158,10004,8,200,199,198,97,51,49,1990,74,12,13]]],
                                        0,
                                        1
                                    ],
                                    12,//значение отсечки зума
                                    1
//будут видны
                                ]//else

                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]],
                            //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                        ],
                        paint: <LinePaint>{
                            'line-color': '#DE2996',
                            'line-width': 4,
                        }
                    }, {
                        id: null,
                        type: 'circle',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]],
                            //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                        ],
                        layout: {},
                        paint: <CirclePaint>{
                            'circle-color': '#DE2996',
                            'circle-radius': 4,
                        }
                    }
                ],
                selected: [
                    {
                        id: null,
                        type: 'fill',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                        ],
                        layout: {},
                        paint: <FillPaint>{
                            'fill-opacity': 0,
                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                        ],
                        paint: <LinePaint>{
                            'line-color': '#4DB6BC',
                            'line-width': 4,
                        }
                    },
                    {
                        id: null,
                        type: 'circle',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                        ],
                        paint: <CirclePaint>{
                            'circle-color': '#4DB6BC',
                            'circle-radius': 4,
                        }
                    },
                ],
                hover: [
                    {
                        id: null,
                        type: 'fill',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                        ],
                        layout: {},
                        paint: <FillPaint>{
                            'fill-color': '#4DB6BC',
                            'fill-opacity': 0.5,
                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]]
                        ],
                        paint: <LinePaint>{
                            'line-color': '#0080ff',
                            'line-width': 0,
                        }
                    },

                    {
                        id: null,
                        type: 'circle',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                        ],
                        paint: <CirclePaint>{
                            'circle-color': '#0080ff',
                            'circle-radius': 4,
                        }
                    }
                ],
                labels: [
                    {
                        id: null,
                        type: 'symbol',
                        minzoom: 11,
                        layout: <SymbolLayout>{
                            'text-variable-anchor': ['center'],
                            'text-size': 12,
                            'text-radial-offset': 0.5,
                            'text-font': ["Noto Sans Regular", "Arial Unicode MS Regular"],
                            'text-field': ["concat", ["get", "field_name"], "\n", ["get","crop_name"]]
                        },
                        paint: <SymbolPaint>{
                            'text-halo-color': '#C5C5C5',
                            'text-color': "#3E4751",
                            'text-halo-width': 2,
                        }
                    }
                ]
            }
        }
        ;

        if (this.root.agro2.projectInfo.projectType == ProjType.slick) {
            options.styles = <any>{
                fields: [
                    {
                        id: null,
                        type: 'fill',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                        ],
                        layout: {
                        },
                        paint: <FillPaint>{
                            'fill-opacity': 0,
                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]],
                        ],
                        paint: <LinePaint>{
                            'line-color':
                                '#c20707',
                            'line-width': 3,
                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]],
                        ],
                        paint: <LinePaint>{
                            'line-color': '#c20707',
                            'line-width': 4,
                        }
                    }, {
                        id: null,
                        type: 'circle',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]],
                            //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                        ],
                        layout: {
                        },
                        paint: <CirclePaint>{
                            'circle-color': '#c20707',
                            'circle-radius': 4,
                        }
                    }
                ],
                selected: [
                    {
                        id: null,
                        type: 'fill',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                        ],
                        layout: {
                        },
                        paint: <FillPaint>{
                            'fill-opacity': 0,
                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                        ],
                        paint: <LinePaint>{
                            'line-color': '#4DB6BC',
                            'line-width': 4,
                        }
                    },
                    {
                        id: null,
                        type: 'circle',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                        ],
                        paint: <CirclePaint>{
                            'circle-color': '#4DB6BC',
                            'circle-radius': 4,
                        }
                    },
                ],
                hover: [
                    {
                        id: null,
                        type: 'fill',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                        ],
                        layout: {
                        },
                        paint: <FillPaint>{
                            'fill-color': '#4DB6BC',
                            'fill-opacity': 0.5,
                        }
                    },
                    {
                        id: null,
                        type: 'line',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]]
                        ],
                        paint: <LinePaint>{
                            'line-color': '#0080ff',
                            'line-width': 0,
                        }
                    },

                    {
                        id: null,
                        type: 'circle',
                        filter: ["all",
                            ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                        ],
                        paint: <CirclePaint>{
                            'circle-color': '#0080ff',
                            'circle-radius': 4,
                        }
                    }
                ],
                labels: [
                    {
                        id: null,
                        type: 'symbol',
                        minzoom: 5,
                        layout: <SymbolLayout>{
                            'text-variable-anchor': ['center'],
                            'text-size': 12,
                            'text-radial-offset': 0.5,
                            'text-font': ["Noto Sans Regular", "Arial Unicode MS Regular"],
                        },
                        paint: <SymbolPaint>{
                            'text-halo-color': '#C5C5C5',
                            'text-color': "#3E4751",
                            'text-halo-width': 2,
                        }
                    }
                ]
            };
        }
        if (this.root.agro2.projectInfo.projectType == ProjType.parcel_assess) {
            options.styles = <any>{
                'hover': [
                    {
                        'type': 'fill',
                        'paint': {'fill-color': '#4DB6BC', 'fill-opacity': 0.5},
                        'filter': ['all', ['in', ['geometry-type'], ['literal', ['Polygon', 'MultiPolygon']]]],
                        'layout': {}
                    },
                    {
                        'type': 'line',
                        'paint': {'line-color': '#4DB6BC', 'line-width': 4},
                        'filter': ['all', ['in', ['geometry-type'], ['literal', ['LineString', 'MultiLineString']]]]
                    },
                    {
                        'type': 'circle',
                        'paint': {
                            'circle-color': '#4DB6BC',
                            'circle-radius': ['match', ['get', 'obj_type_id'], 14, 6, 4]
                        },
                        'filter': ['all', ['in', ['geometry-type'], ['literal', ['Point', 'MultiPoint']]]]
                    }
                ],
                'fields': [
                    {
                        'type': 'line',
                        'paint': {
                            'line-color': ['match', ['get', 'type_name'],
                                'Граница участка', '#DE2996',
                                'Площадь возделывания 2023', '#99D9EA',
                                'Площадь возделывания 2022', '#3F48CC',
                                'Площадь возделывания 2021','#0FFCD7',
                                'Здания', '#FF0000',
                                'Опоры ЛЭП', '#000000',
                                '#DE2996'
                                //
                            ],
                            'line-width': 4
                        },
                        /*'filter':
                            ['any',
                                ['>=', ['zoom'], 10]]*/
                    },
                    {
                        'type': 'circle',
                        'paint': {
                            'circle-color': ['match', ['get', 'obj_type_id'], 14, '#C3C3C3',11, '#0a080a', '#DE2996'],
                            'circle-radius': ['match', ['get', 'obj_type_id'], 14, 6, 11,4, 4],
                            'circle-stroke-color': ['match', ['get', 'obj_type_id'], 14, '#0a080a',11, '#C3C3C3',  '#0a080a'],
                            'circle-stroke-width': 3,
                        },
                        'filter': ['all', ['in', ['geometry-type'], ['literal', ['Point', 'MultiPoint']]], ['>=', ['zoom'], 10]],
                        'layout': {}
                    }
                ],
                "labels": <any>[],
                'selected': [
                    {
                        'type': 'fill',
                        'paint': {'fill-opacity': 0},
                        'filter': ['all', ['in', ['geometry-type'], ['literal', ['Polygon', 'MultiPolygon', 'LineString', 'MultiLineString']]]]
                    },
                    {
                        'type': 'line',
                        'paint': {'line-color': '#4DB6BC', 'line-width': 4},
                        'filter': ['all', ['in', ['geometry-type'], ['literal', ['Polygon', 'MultiPolygon', 'LineString', 'MultiLineString']]]]
                    },
                    {
                        'type': 'circle',
                        'paint': {'circle-color': '#4DB6BC', 'circle-radius': 4},
                        'filter': ['all', ['in', ['geometry-type'], ['literal', ['Point', 'MultiPoint']]]]
                    }
                ]
            };
        }
        if (this.root.agro2.projectInfo.projectType == ProjType.yield_point){
            options.styles = <any>{
                    fields: [
                        {
                            id: null,
                            type: 'fill',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                            ],
                            layout: {
                            },
                            paint: <FillPaint>{
                                //'fill-color': '#4DB6BC',
                                'fill-opacity': 0,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]],
                                //                      ["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                            ],
                            paint: <LinePaint>{
                                'line-color':
                                    '#DE2996',
                                'line-width': 4,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]],
                                //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                            ],
                            paint: <LinePaint>{
                                'line-color': '#DE2996',
                                'line-width': 4,
                            }
                        }, {
                            id: null,
                            type: 'circle',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]],
                                //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                            ],
                            layout: {
                            },
                            paint: <CirclePaint>{
                                'circle-color': ['match', ['get', 'status_id'], 1, '#DE2996', '#35de29'],
                                'circle-radius': 4,
                            }
                        }
                    ],
                    selected: [
                        {
                            id: null,
                            type: 'fill',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                            ],
                            layout: {
                            },
                            paint: <FillPaint>{
                                'fill-opacity': 0,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                            ],
                            paint: <LinePaint>{
                                'line-color': '#4DB6BC',
                                'line-width': 4,
                            }
                        },
                        {
                            id: null,
                            type: 'circle',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                            ],
                            paint: <CirclePaint>{
                                'circle-color': '#4DB6BC',
                                'circle-radius': 4,
                            }
                        },
                    ],
                    hover: [
                        {
                            id: null,
                            type: 'fill',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                            ],
                            layout: {
                            },
                            paint: <FillPaint>{
                                'fill-color': '#4DB6BC',
                                'fill-opacity': 0.5,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]]
                            ],
                            paint: <LinePaint>{
                                'line-color': '#0080ff',
                                'line-width': 0,
                            }
                        },

                        {
                            id: null,
                            type: 'circle',
                            minzoom: 9,
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                            ],
                            paint: <CirclePaint>{
                                'circle-color': '#0080ff',
                                'circle-radius': 4,
                            }
                        }
                    ],
                    labels: [
                        {
                            id: null,
                            type: 'symbol',
                            minzoom: 9,
                            layout: <SymbolLayout>{
                                'text-variable-anchor': ['bottom'],
                                //'text-offset': [0, 10],
                                'text-size': 12,
                                'text-radial-offset': 0.5,
                                'text-font': ["Noto Sans Regular", "Arial Unicode MS Regular"],
                            },
                            paint: <SymbolPaint>{
                                'text-halo-color': '#C5C5C5',
                                'text-color': "#3E4751",
                                'text-halo-width': 2,
                            }
                        }
                    ]
                }
            ;

        }
        if (this.root.agro2.projectInfo.projectType == ProjType.pnt_yld_assess){
            options.styles = <any>{
                    fields: [
                        {
                            id: null,
                            type: 'fill',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                            ],
                            layout: {
                            },
                            paint: <FillPaint>{
                                'fill-opacity': 0,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]],
                                //                      ["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                            ],
                            paint: <LinePaint>{
                                'line-color':
                                    '#DE2996',
                                'line-width': 4,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]],
                                //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                            ],
                            paint: <LinePaint>{
                                'line-color': '#DE2996',
                                'line-width': 4,
                            }
                        }, {
                            id: null,
                            type: 'circle',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]],
                                //["any", ["==", ['get', 'obj_type_id'], 1], ['>=', ['zoom'], 10]]
                            ],
                            layout: {
                            },
                            paint: <CirclePaint>{
                                'circle-color': ['match', ['get', 'type_id'],
                                    10, '#ff00ff', 20, '#15db64', 30, '#bf9000', 40, '#38761d',
                                    50, '#f3f3f3', 60, '#0000ff',
                                    '#00ffff'],
                                //'circle-color': '#DE2996',
                                'circle-radius': 4,
                            }
                        }
                    ],
                    selected: [
                        {
                            id: null,
                            type: 'fill',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                            ],
                            layout: {
                            },
                            paint: <FillPaint>{
                                'fill-opacity': 0,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon", "LineString", "MultiLineString"]]]
                            ],
                            paint: <LinePaint>{
                                'line-color': '#4DB6BC',
                                'line-width': 4,
                            }
                        },
                        {
                            id: null,
                            type: 'circle',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                            ],
                            paint: <CirclePaint>{
                                'circle-color': '#4DB6BC',
                                'circle-radius': 4,
                            }
                        },
                    ],
                    hover: [
                        {
                            id: null,
                            type: 'fill',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Polygon", "MultiPolygon"]]]
                            ],
                            layout: {
                            },
                            paint: <FillPaint>{
                                'fill-color': '#4DB6BC',
                                'fill-opacity': 0.5,
                            }
                        },
                        {
                            id: null,
                            type: 'line',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["LineString", "MultiLineString"]]]
                            ],
                            paint: <LinePaint>{
                                'line-color': '#0080ff',
                                'line-width': 0,
                            }
                        },

                        {
                            id: null,
                            type: 'circle',
                            filter: ["all",
                                ["in", ["geometry-type"], ["literal", ["Point", "MultiPoint"]]]
                            ],
                            paint: <CirclePaint>{
                                'circle-color': '#0080ff',
                                'circle-radius': 4,
                            }
                        }
                    ],
                    labels: [
                        {
                            id: null,
                            type: 'symbol',
                            minzoom: 11,
                            layout: <SymbolLayout>{
                                'text-variable-anchor': ['center'],
                                'text-size': 12,
                                'text-radial-offset': 0.5,
                                'text-font': ["Noto Sans Regular", "Arial Unicode MS Regular"],
                            },
                            paint: <SymbolPaint>{
                                'text-halo-color': '#C5C5C5',
                                'text-color': "#3E4751",
                                'text-halo-width': 2,
                            }
                        }
                    ]
                }
            ;

        }
    }
}