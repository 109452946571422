import * as React from 'react';
import {IIconProp} from "../IIconProp";

export class MaxSvg extends React.PureComponent<IIconProp, undefined> {

    render() {
        return <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.00002 3.09039e-08L4.00002 1L1.70702 1L4.35352 3.6465L3.64652 4.3535L1.00002 1.707L1.00002 4L1.65622e-05 4L1.62562e-05 0.5C1.6232e-05 0.224 0.224016 3.61012e-07 0.500016 3.36884e-07L4.00002 3.09039e-08Z" fill="#C5C5C5"/>
            <path d="M6.99382e-07 8L7.5 8C7.776 8 8 7.776 8 7.5L8 0L7 8.74228e-08L7 7L6.11959e-07 7L6.99382e-07 8Z" fill="#C5C5C5"/>
        </svg>;
    }
}
