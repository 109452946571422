import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoMapStore, IAhoLayers} from "./agroAhoMapStore";
import {observable, reaction} from "mobx";
import {SymbolLayout, SymbolPaint} from "maplibre-gl";
import {fetchJsonGet} from "../../../app/helper/utils/FetchUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {MeasuringStatus} from "../../../app/helper/structs/MeasuringStatus";

export enum AhoEditRouteMode {
    Disabled='Disabled',
    Select= 'Select',
    Edit = 'Edit',
}

export interface RouteCell {
    cell_id: number;
    order: number;
}


export class AgroAhoEditRoute extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;
    @observable
    mode: AhoEditRouteMode = AhoEditRouteMode.Disabled;
    isEventsInit: boolean = false;
    cells: RouteCell[] = [];
    field_track_id: number = null;
    field_id: number = null;
    cell_id: number = null;
    ds: string;
    lastOrder: number = 0;
    src_data: any = null;
    @observable
    canSave: boolean = false;

    subscription(): IReactionDisposer[]{
        return [
            reaction(()=>this.root.map.measuringStatus,
                ()=>this.doChangeMeasuringStatus())
        ]
    }

    doChangeMeasuringStatus(){
        let map = this.root.map;
        if (map.measuringStatus == MeasuringStatus.Ruler2 || map.measuringStatus == MeasuringStatus.Ruler2Edit ||
            map.measuringStatus == MeasuringStatus.ndviValueByMouse) this.doDisableModeClick();
    }

    doSaveClick(){
        let store_ = this.mapStore.parentStore;
        // [ {cell_id: int, "connect_points": [[xN, yN]]} ]
        let cells_ = this.cells.slice().sort((a, b)=>a.order - b.order);
        let p = {params: {
            field_track_id: this.field_track_id,
            // cells: cells_.map(c=>{ return {cell_id: c.cell_id, connect_points: []}})
            cells: cells_.map(c=>{ return {cell_id: c.cell_id}})
        }};
        // console.log('params:', params);
        let r = [
            store_.projStore.projName,
            store_.cardStore.card.map_id
        ];
        let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/track/reorder`;
        fetchJsonGet(url, p).then(()=>{
            this.mode = AhoEditRouteMode.Disabled;
            this.mapStore.reloadTracks();
            this.root.addInfo(this.root.trans['Saved successfully']);
        }).catch(err=>this.root.addError(err));
    }

    doZoomClick(){
        let field: any = {field_id: this.field_id};
        this.mapStore.parentStore.cardStore.doFieldZoomClick(field, 0.005);
    }

    getRouteData() {
        let cell_ids = this.cells.map(c => c.cell_id);
        if (this.mode == AhoEditRouteMode.Edit && cell_ids.length && this.field_id) {
            return {field_id: this.field_id, cell_ids: cell_ids};
        }
    }

    resetData(){
        this.cells = [];
        this.lastOrder = 0;
        this.field_id = null;
        this.src_data = null;
        this.deleteRouteLayer();
        this.deleteSource();
    }

    doDisableModeClick() {
        this.mode = AhoEditRouteMode.Disabled;
        this.resetData();
    }

    async doSelectModeClick(afterEdit?: boolean) {
        let cs = this.mapStore.parentStore.cardStore;
        // console.log('sel', this.mode);
        if (afterEdit) {
            this.resetData();
            this.addRouteLayer(cs.card.cell_info.ds_code);
        }
        this.mode = AhoEditRouteMode.Select;
    }

    getRouteSourceId(ds: string): string {
        return `${ds}_aho_route_src`;
    }

    getRouteFieldId(){
        let fid: any = null;
        if (this.mapStore.layersShow && this.mode == AhoEditRouteMode.Edit) fid = this.field_id;
        return fid;
    }

    async addRouteLayer(ds: string) {
        // console.log('add layer');
        if (!ds) return;
        this.ds = ds;
        let data = await this.mapStore.getCellCardGeoJSONs({ds: this.ds, type: IAhoLayers.Cell, copy: true});
        let map = this.root.map.mapbox;
        this.deleteRouteLayer();
        let source_id = this.getRouteSourceId(this.ds);
        if (!map.getSource(source_id)) {
            if (typeof data != 'object' || !data.type) return;
            map.addSource(source_id, {
                type: 'geojson',
                data: data
            });
        }

        let layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.TrackRoute);
        if (!map.getLayer(layer_id)) {
            map.addLayer({
                id: layer_id,
                source: source_id,
                type: 'fill',
                paint: {
                    'fill-color': '#E46C0A',
                    'fill-opacity': [
                        'case',
                        ['>', ["to-number",['feature-state', 'order'],0], 0],
                        0.5,
                        0
                    ]
                }
            }, await this.mapStore.getPrevLayer(IAhoLayers.TrackRoute));
            if (!this.isEventsInit) {
                // console.log('layer_id', layer_id);
                map.on('click', layer_id, (e: any) => {
                    this.onCellClick(e, layer_id);
                });
                map.on('dblclick', layer_id, (e: any) => {
                    this.onCellDblClick(e, layer_id);
                });
                map.on('mouseenter', layer_id, () => {
                    map.getCanvas().classList.add('AgroAhoCursorPointer');
                });
                map.on('mouseleave', layer_id, () => {
                    map.getCanvas().classList.remove('AgroAhoCursorPointer');
                });
                this.isEventsInit = true;
            }
            let lbl_layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.TrackRouteLbl);
            if (!map.getLayer(lbl_layer_id)) {
                map.addLayer({
                    id: lbl_layer_id,
                    source: source_id,
                    type: 'symbol',
                    layout:<SymbolLayout>{
                        'text-field': ['get', 'order'],
                        'text-font': [
                            'Open Sans Semibold'
                        ],
                        'text-anchor': 'center',
                        "text-allow-overlap": true,
                        "text-size": 14
                    },
                    paint: <SymbolPaint>{
                        "text-color": 'black',
                        "text-halo-color": "white",
                        "text-halo-width": 1,
                        "text-opacity": [
                            'case',
                            ['>', ["to-number",['feature-state', 'order'],0], 0],
                            1,
                            0
                        ]
                    }
                }, await this.mapStore.getPrevLayer(IAhoLayers.TrackRouteLbl));
            }
        }

    }

    deleteRouteLayer() {
        let map = this.root.map.mapbox;
        let layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.TrackRoute);
        if (map.getLayer(layer_id)) {
            map.removeLayer(layer_id);
        }
        let lbl_layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.TrackRouteLbl);
        if (map.getLayer(lbl_layer_id)) {
            map.removeLayer(lbl_layer_id);
        }
    }

    deleteSource(){
        let map = this.root.map.mapbox;
        let source_id = this.getRouteSourceId(this.ds);
        if (map.getSource(source_id)) {
            map.removeSource(source_id);
        }
    }

    updCellIds_() {
        if (!this.field_id) return;
        this.cells = this.mapStore.parentStore.cardStore.cellsItems
            .filter(c => c.field_id == this.field_id).map(c => {
                return {cell_id: c.cell_id, order: 0}
            });
    }

    async updSource() {
        if (!this.cells || !this.cells.length) return;
        let cell_ids = this.cells.map(c => c.cell_id);
        let data = await this.mapStore.getCellCardGeoJSONs({ds: this.ds, type: IAhoLayers.Cell, copy: true});
        if (!data) return;
        let feats = data.features.filter((f: any) => cell_ids.indexOf(f?.properties?.cell_id) >= 0);
        feats.forEach((f: any) => {
            let cell_id = f?.properties?.cell_id;
            if (cell_id) f.id = cell_id;
        });
        data.features = feats;
        let source_id = this.getRouteSourceId(this.ds);
        let map = this.root.map.mapbox;
        let src: any = map.getSource(source_id);
        if (!src) return;
        this.src_data = data;
        src.setData(data);
    }

    async updSourceOrder() {
        if (!this.src_data && !this.cells) return;
        let source_id = this.getRouteSourceId(this.ds);
        let map = this.root.map.mapbox;
        let src: any = map.getSource(source_id);
        if (!src) return;
        this.src_data.features.forEach((f: any)=>{
            this.cells.forEach(c=>{
                if (f.properties.cell_id == c.cell_id) f.properties.order = c.order;
            });
        });
        // console.log('src_data', AhoUtils.cp(this.src_data));
        src.setData(this.src_data);
    }

    async updCellIds(lngLat: any) {
        let store_ = this.mapStore.parentStore;
        let r = [
            store_.projStore.projName,
            store_.cardStore.card.map_id
        ];
        // /api/projects/proj103/asa2/map/43/track/get?lon=35.40910&lat=52.506457
        let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/track/get?lon=${lngLat.lng}&lat=${lngLat.lat}`;
        if (this.cell_id) url += `&cell_id=${this.cell_id}`;
        let res = await fetchJsonGet(url).catch(e=>this.root.addError('Error loading cell data'));
        if (!res?.cells?.length) this.updCellIds_();
        if (!res?.cells?.length) return;
        this.field_track_id = res?.field_track_id;
        this.cells = res.cells.map((c: any)=>{return {cell_id: c.cell_id, order: 0}});
    }

    getFieldName(){
        let cs = this.mapStore.parentStore.cardStore;
        return cs.fieldsItems?.filter(f=>f.field_id == this.field_id)?.[0]?.field_name;
    }

    async onCellDblClick(e: any, layer_id: string) {
        let store_ = this.mapStore.parentStore;
        let cs = store_.cardStore;
        let feat = null;
        if (e.features && e.features.length > 0 && e.features[0].layer.id == layer_id) feat = e.features[0];
        if (!feat || feat.layer.id !== layer_id) return false;
        this.field_id = feat?.properties?.field_id;
        await this.updCellIds(e.lngLat);
        if (!this.field_id || !this.cells) return;
        let fcur = cs.fieldsItems.filter(f=>f.field_id == this.field_id);
        if (cs.getFieldSelected().length && fcur.length && !fcur[0].checked)
            cs.setSelectAllFields(false);
        await this.updSource();
        this.mode = AhoEditRouteMode.Edit;
    }

    updCellOrder(cell_id: number) {
        if (!cell_id || !this.cells) return;
        let maxOrder: number = null;
        this.cells.forEach(c => {
            if (!maxOrder && c.cell_id == cell_id) {
                if (c.order == 0) {
                    c.order = ++this.lastOrder;
                } else {
                    maxOrder = c.order - 1;
                    this.lastOrder = maxOrder;
                    this.cells.forEach(c => {
                        if (c.order > maxOrder) c.order = 0;
                    });
                }
            }
        });
    }

    async onCellClick(e: any, layer_id: string) {
        if (this.mode == AhoEditRouteMode.Select) this.cell_id = e?.features[0]?.properties?.cell_id;
        if (this.mode !== AhoEditRouteMode.Edit) return;
        let feat = null;
        if (e.features && e.features.length > 0 && e.features[0].layer.id == layer_id) feat = e.features[0];
        if (!feat || feat.layer.id !== layer_id) return false;
        let cell_id = feat?.properties?.cell_id;
        this.updCellOrder(cell_id);
        this.updCellsOrder();
        this.updSourceOrder();
        this.canSave = this.cells.every(c=>!!c.order);
    }

    updCellsOrder() {
        if (!this.cells) return;
        let map = this.root.map.mapbox;
        let source_id = this.getRouteSourceId(this.ds);
        if (!map.getSource(source_id)) return;
        this.cells.forEach(c => {
            this.root.map.mapbox.setFeatureState(
                { source: source_id, id: c.cell_id},
                {order: c.order});
        });
    }

}