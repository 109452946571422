import { action, autorun, IReactionDisposer, observable } from "mobx";
import { CustomStoreTool } from "../general/CustomStoreTool";
import { ContainerTools } from "../general/ContainerTools";
import { GeoJSONSource, MapMouseEvent, Marker, SymbolLayer, SymbolLayout } from "maplibre-gl";
import { MBUtils } from "../../../helper/utils/MBUtils";
import { MeasuringStatus } from "../../../helper/structs/MeasuringStatus";
import { ToolEvent } from "../../../../pluginApi/tools/ToolEvent";
import { Feature, Point } from "geojson";
import { SyncUtils } from "../../../helper/utils/SyncUtils";
import { save } from "../../PermalinkDecor";
import { v4 } from "uuid";
import React from "react";

export class SurveyPointsTool extends CustomStoreTool {
    static SURVEY_POINTS_SOURCE = "class_survey_points_src";
    static SURVEY_POINTS_LAYER = "class_survey_points_layer";
    static SURVEY_POINTS_DRAG_SOURCE = "class_survey_points_drag_src";
    static SURVEY_POINTS_DRAG_LAYER = "class_survey_points_drag_layer";

    static SURVEY_POINTS_IMAGE = "class_survey_points_img";
    static SURVEY_POINTS_IMAGES = [
        {   name:"class_survey_points_1_img", width: 23, height: 24,
            svg: "<svg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18 1H5C2.79086 1 1 2.79086 1 5V13.1935C1 15.4027 2.79086 17.1935 5 17.1935H6.3395C7.04468 17.1935 7.69775 17.5649 8.05831 18.1709L9.7812 21.0668C10.5565 22.37 12.4435 22.37 13.2188 21.0668L14.9417 18.1709C15.3023 17.5649 15.9553 17.1935 16.6605 17.1935H18C20.2091 17.1935 22 15.4027 22 13.1935V5C22 2.79086 20.2091 1 18 1Z' fill='#262B32' stroke='white' stroke-width='2'/></svg>"
        },
        {
            name: "class_survey_points_2_img", width: 31, height: 24,
            svg: "<svg width='31' height='24' viewBox='0 0 31 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M26 1H5C2.79086 1 1 2.79086 1 5V13.1935C1 15.4027 2.79086 17.1935 5 17.1935H9.19877C9.78736 17.1935 10.3461 17.4528 10.7261 17.9023L13.9727 21.7424C14.7716 22.6874 16.2284 22.6874 17.0273 21.7424L20.2739 17.9023C20.6539 17.4528 21.2126 17.1935 21.8012 17.1935H26C28.2091 17.1935 30 15.4027 30 13.1935V5C30 2.79086 28.2091 1 26 1Z' fill='#262B32' stroke='white' stroke-width='2'/></svg>"
        },
        {
            name: "class_survey_points_3_img", width: 36, height: 24,
            svg: "<svg width='36' height='24' viewBox='0 0 36 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M31 1H5C2.79086 1 1 2.79086 1 5V13.1935C1 15.4027 2.79086 17.1935 5 17.1935H10.941C11.4707 17.1935 11.9788 17.4037 12.3537 17.7779L16.5872 22.0029C17.3679 22.782 18.6321 22.782 19.4128 22.0029L23.6463 17.7779C24.0212 17.4037 24.5293 17.1935 25.059 17.1935H31C33.2091 17.1935 35 15.4027 35 13.1935V5C35 2.79086 33.2091 1 31 1Z' fill='#262B32' stroke='white' stroke-width='2'/></svg>"
        },
        {
            name: "class_survey_points_4_img", width: 43, height: 24,
            svg: "<svg width='43' height='24' viewBox='0 0 43 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M38 1H5C2.79086 1 1 2.79086 1 5V13.1935C1 15.4027 2.79086 17.1935 5 17.1935H13.3375C13.7995 17.1935 14.2472 17.3535 14.6046 17.6461L20.2329 22.2549C20.9699 22.8584 22.0301 22.8584 22.7671 22.2549L28.3954 17.6461C28.7528 17.3535 29.2005 17.1935 29.6625 17.1935H38C40.2091 17.1935 42 15.4027 42 13.1935V5C42 2.79086 40.2091 1 38 1Z' fill='#262B32' stroke='white' stroke-width='2'/></svg>"
        },
        {
            name: "class_survey_points_tag_1_img", width: 25, height: 27,
            svg: "<svg width='25' height='27' viewBox='0 0 25 27' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18 4H5C2.79086 4 1 5.79086 1 8V16.1935C1 18.4027 2.79086 20.1935 5 20.1935H6.3395C7.04468 20.1935 7.69775 20.5649 8.05831 21.1709L9.7812 24.0668C10.5565 25.37 12.4435 25.37 13.2188 24.0668L14.9417 21.1709C15.3023 20.5649 15.9553 20.1935 16.6605 20.1935H18C20.2091 20.1935 22 18.4027 22 16.1935V8C22 5.79086 20.2091 4 18 4Z' fill='#262B32' stroke='white' stroke-width='2'/><circle cx='20.5' cy='4.5' r='4' fill='#4DB6BC' stroke='white'/></svg>"
        },
        {
            name: "class_survey_points_tag_2_img", width: 33, height: 27,
            svg: "<svg width='33' height='27' viewBox='0 0 33 27' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M26 4H5C2.79086 4 1 5.79086 1 8V16.1935C1 18.4027 2.79086 20.1935 5 20.1935H9.19877C9.78736 20.1935 10.3461 20.4528 10.7261 20.9023L13.9727 24.7424C14.7716 25.6874 16.2284 25.6874 17.0273 24.7424L20.2739 20.9023C20.6539 20.4528 21.2126 20.1935 21.8012 20.1935H26C28.2091 20.1935 30 18.4027 30 16.1935V8C30 5.79086 28.2091 4 26 4Z' fill='#262B32' stroke='white' stroke-width='2'/><circle cx='29' cy='4' r='3.5' fill='#4DB6BC' stroke='white'/></svg>"
        },
        {
            name: "class_survey_points_tag_3_img", width: 38, height: 27,
            svg: "<svg width='38' height='27' viewBox='0 0 38 27' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M31 4H5C2.79086 4 1 5.79086 1 8V16.1935C1 18.4027 2.79086 20.1935 5 20.1935H10.941C11.4707 20.1935 11.9788 20.4037 12.3537 20.7779L16.5872 25.0029C17.3679 25.782 18.6321 25.782 19.4128 25.0029L23.6463 20.7779C24.0212 20.4037 24.5293 20.1935 25.059 20.1935H31C33.2091 20.1935 35 18.4027 35 16.1935V8C35 5.79086 33.2091 4 31 4Z' fill='#262B32' stroke='white' stroke-width='2'/><circle cx='34' cy='4' r='3.5' fill='#4DB6BC' stroke='white'/></svg>"
        },
        {
            name: "class_survey_points_tag_4_img", width: 45, height: 27,
            svg: "<svg width='45' height='27' viewBox='0 0 45 27' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M38 4H5C2.79086 4 1 5.79086 1 8V16.1935C1 18.4027 2.79086 20.1935 5 20.1935H13.3375C13.7995 20.1935 14.2472 20.3535 14.6046 20.6461L20.2329 25.2549C20.9699 25.8584 22.0301 25.8584 22.7671 25.2549L28.3954 20.6461C28.7528 20.3535 29.2005 20.1935 29.6625 20.1935H38C40.2091 20.1935 42 18.4027 42 16.1935V8C42 5.79086 40.2091 4 38 4Z' fill='#262B32' stroke='white' stroke-width='2'/><circle cx='41' cy='4' r='3.5' fill='#4DB6BC' stroke='white'/></svg>"
        },
    ];
    static SURVEY_POINTS_TAG_IMAGE = "class_survey_points_tag_img";
    static SURVEY_POINTS_IMAGE_SVG = '<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4C1 2.34315 2.34315 1 4 1H20C21.6569 1 23 2.34315 23 4V9C23 10.6569 21.6569 12 20 12H17.3472C16.4903 12 15.6743 12.3664 15.105 13.0069L12.7474 15.6592C12.3496 16.1067 11.6504 16.1067 11.2526 15.6592L8.89503 13.0069C8.32573 12.3664 7.50972 12 6.6528 12H4C2.34315 12 1 10.6569 1 9V4Z" fill="#3E4751" stroke="white"/></svg>';
    static SURVEY_POINTS_TAG_IMAGE_SVG = '<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6C1 4.34315 2.34315 3 4 3H20C21.6569 3 23 4.34315 23 6V11C23 12.6569 21.6569 14 20 14H17.3472C16.4903 14 15.6743 14.3664 15.105 15.0069L12.7474 17.6592C12.3496 18.1067 11.6504 18.1067 11.2526 17.6592L8.89503 15.0069C8.32573 14.3664 7.50972 14 6.6528 14H4C2.34315 14 1 12.6569 1 11V6Z" fill="#3E4751" stroke="white"/><circle cx="22.5" cy="3.5" r="3" fill="#4DB6BC" stroke="white"/></svg>';
    static SURVEY_POINTS_CURSOR : string = ` url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='3.5' fill='white' stroke='%2334AAFF'/%3E%3Cpath d='M12.6653 12.4417L12.5162 12.5162L12.4417 12.6653L9.42595 18.6967L4.79057 4.79057L18.6967 9.42595L12.6653 12.4417Z' fill='white' stroke='black'/%3E%3C/svg%3E") 4 3, pointer`;

    async onInstall() {
        super.onInstall();
        const promises = SurveyPointsTool.SURVEY_POINTS_IMAGES.map(async(img) => MBUtils.loadSvgToMapbox(img.svg, this.store.map.mapbox, img.width, img.height, img.name))
        await Promise.all(promises);
    }

    onUninstall() {
        super.onUninstall();
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                if (!this.store.map.mapReady) return;
                this.setupLayers();
            })
        ];
    }
    
    getImage(numLength: number, tagged: boolean) {
        let idx = numLength - 1;
        if (idx > 3) idx = 3;
        if (tagged) idx += 4;
        return SurveyPointsTool.SURVEY_POINTS_IMAGES[idx];
    }

    getIconExpression() {
        let expr: any[] = ["case"];
        for (let tagged of [false, true])
            for (let len of [1, 2, 3, 4]) {
                expr.push(["all", [tagged? "!=" : "==", ["get", "comment"], ""], ["==", ["length", ["to-string", ["get", "num"]]], len]]);
                expr.push(this.getImage(len, tagged).name);
            }
        expr.push("");
        return expr;
    }

    setupLayers() {
        //update layers
        if (!this.store.map.mapReady) return;
        let map = this.store.map.mapbox;
        let sp = this.store.map.surveyPoints.points;
        if (!map.getSource(SurveyPointsTool.SURVEY_POINTS_SOURCE)) {
            map.addSource(SurveyPointsTool.SURVEY_POINTS_SOURCE,{
                type: 'geojson',
                data: sp// this.points
            });
        }
        else
            (map.getSource(SurveyPointsTool.SURVEY_POINTS_SOURCE) as GeoJSONSource).setData(sp);
        if (! map.getSource(SurveyPointsTool.SURVEY_POINTS_DRAG_SOURCE))
            map.addSource(SurveyPointsTool.SURVEY_POINTS_DRAG_SOURCE,{
                type: 'geojson',
                data: ContainerTools.EMPTY_SOURCE
            });
        else 
            (map.getSource(SurveyPointsTool.SURVEY_POINTS_DRAG_SOURCE) as GeoJSONSource).setData(ContainerTools.EMPTY_SOURCE);

        if (!map.getLayer(SurveyPointsTool.SURVEY_POINTS_LAYER)){
            this.addLayer({
                id: SurveyPointsTool.SURVEY_POINTS_LAYER,
                type: "symbol",
                source: SurveyPointsTool.SURVEY_POINTS_SOURCE,
                layout: <SymbolLayout>{
                    "icon-image": this.getIconExpression(),
                    "icon-allow-overlap": true,
                    "symbol-sort-key": ["get", "num"],
                    "text-optional": true,
                    "icon-anchor": "bottom",
                    "text-field": [ "get", "num" ],
                    "text-font": [ "Noto Sans Bold" ],
                    "text-size": 11,
                    "text-anchor": "bottom",
                    "text-offset": ["case", ["==", ["get", "comment"], ""], ["literal", [0, -0.7]], ["literal", [0, -0.65]]]
                },
                paint: {
                    "text-color": "#fff"
                }
            } as SymbolLayer);
        }
        if (!map.getLayer(SurveyPointsTool.SURVEY_POINTS_DRAG_LAYER)){
            this.addLayer({
                id: SurveyPointsTool.SURVEY_POINTS_DRAG_LAYER,
                type: "symbol",
                source: SurveyPointsTool.SURVEY_POINTS_DRAG_SOURCE,
                layout: <SymbolLayout>{
                    "icon-image": this.getIconExpression(),
                    "icon-allow-overlap": true,
                    "symbol-sort-key": ["get", "num"],
                    "icon-anchor": "bottom",
                },
                paint: {
                    // "text-color": "#fff"
                }
            } as SymbolLayer);
        }
    }

    onMouseMove(e: MapMouseEvent & ToolEvent) {
        let ms = this.store.map;
        if (this.store.map.measuringStatus != MeasuringStatus.surveyPoint) return;
        if (this.dragging) {
            e.cursor = 'grabbing';
            let sp = ms.surveyPoints.points;
            let cfp = this.clickedFeature?.properties;
            let updFeature = sp.features.find((f) => f.properties.id == cfp.id);
            if (updFeature) {
                (updFeature.geometry as Point).coordinates = ms.mapbox.unproject([e.point.x - cfp.dxy[0], e.point.y - cfp.dxy[1]]).toArray();
                (this.map.getSource(SurveyPointsTool.SURVEY_POINTS_DRAG_SOURCE) as GeoJSONSource).setData({type: "FeatureCollection", features: [updFeature]});
            }
            let vis: any = this.clickedFeature? ["case", ["!=", ["get", "id"], ["literal", cfp.id]], 1, 0] : 1;
            ms.mapbox.setPaintProperty(SurveyPointsTool.SURVEY_POINTS_LAYER, "icon-opacity", vis);
            ms.mapbox.setPaintProperty(SurveyPointsTool.SURVEY_POINTS_LAYER, "text-opacity", vis);
    
            e.noPropagation();            
            return;
        }
        let fts = ms.mapbox.queryRenderedFeatures(e.point, {layers: [SurveyPointsTool.SURVEY_POINTS_LAYER]});
        if (e.cursor == null || e.cursor == "default")
            e.cursor = fts.length > 0? "pointer" :SurveyPointsTool.SURVEY_POINTS_CURSOR;
    }

    clickedFeature: Feature = null;
    dragging: boolean = false;

    onMouseDown(e: MapMouseEvent & ToolEvent) {
        let ms = this.store.map;
        if (ms.measuringStatus != MeasuringStatus.surveyPoint) return;
        ms.menuPointer = null;
        let fts = ms.mapbox.queryRenderedFeatures(e.point, {layers: [SurveyPointsTool.SURVEY_POINTS_LAYER]});
        this.clickedFeature = fts.length == 0? null : fts[0];
        let cfp = this.clickedFeature?.properties;
        this.dragging = this.clickedFeature != null;
        if (cfp) {
            let p0 = ms.mapbox.project((fts[0].geometry as any).coordinates);
            cfp.dxy = [Math.round(e.point.x - p0.x), Math.round(e.point.y - p0.y)];
        }
    }

    onMouseUp(e: MapMouseEvent & ToolEvent): void {
        let ms = this.store.map;
        if (ms.measuringStatus != MeasuringStatus.surveyPoint)
            return;
        let needUpdate = this.dragging;
        this.dragging = false;
        if (! needUpdate)
            return;
        (ms.mapbox.getSource(SurveyPointsTool.SURVEY_POINTS_DRAG_SOURCE) as GeoJSONSource).setData(ContainerTools.EMPTY_SOURCE);
        //console.log(2);

        ms.mapbox.setPaintProperty(SurveyPointsTool.SURVEY_POINTS_LAYER, "icon-opacity", 1);
        ms.mapbox.setPaintProperty(SurveyPointsTool.SURVEY_POINTS_LAYER, "text-opacity", 1);
        let sp = ms.surveyPoints.points;
        let cfp = this.clickedFeature.properties;
        let updFeature = sp.features.find((f) => f.properties.id == cfp.id);
        if (!updFeature)
            return;
        (updFeature.geometry as Point).coordinates = ms.mapbox.unproject([e.point.x - cfp.dxy[0], e.point.y - cfp.dxy[1]]).toArray();
        (this.map.getSource(SurveyPointsTool.SURVEY_POINTS_SOURCE) as GeoJSONSource).setData(sp);
        //console.log(3);
    }

    onMouseClick(e: MapMouseEvent & ToolEvent) {
        if (this.clickedFeature || e.originalEvent.button != 0 || this.store.map.measuringStatus != MeasuringStatus.surveyPoint) return;
        let sp = this.store.map.surveyPoints;
        let pts = sp.points;
        let newFeature = sp.createNewPoint(e.lngLat.toArray(), [e.point.x, e.point.y]);
        if (pts.features.length > 0) {
            let lastFeature = pts.features[pts.features.length - 1];
            if (SyncUtils.compareArrays((newFeature.geometry as Point).coordinates,
                    (lastFeature.geometry as Point).coordinates, (x, y) => x == y) &&
                SyncUtils.compareArrays(newFeature.properties.xy, lastFeature.properties.xy, (x, y) => x == y)) {
                    sp.lastPointNumber--;
                    return; //ignore dbl click
            }
        }
        this.add(newFeature);
    }

    onDblclick(e: MapMouseEvent & ToolEvent) {
        let ms = this.store.map;
        if (ms.measuringStatus != MeasuringStatus.surveyPoint) return;
        e.noPropagation();
        if (! this.clickedFeature) return;
        this.remove(this.clickedFeature);
        this.clickedFeature = null;
    }

    @action
    add(p: Feature) {
        let sp = this.store.map.surveyPoints;
        sp.points.features.push(p);
        sp.points = {...sp.points};
        (this.map.getSource(SurveyPointsTool.SURVEY_POINTS_SOURCE) as GeoJSONSource).setData(sp.points);
    }

    @action
    remove(p: Feature=null) {
        let sp = this.store.map.surveyPoints;
        if (p) {
            let fts = sp.points.features.filter((f) => f.properties.id != p.properties.id);
            sp.points = {...sp.points, features: fts}
        }
        else {
            sp.points = {...ContainerTools.EMPTY_SOURCE};
            sp.lastPointNumber = 1;
        }
        (this.map.getSource(SurveyPointsTool.SURVEY_POINTS_SOURCE) as GeoJSONSource).setData(sp.points);
        //console.log(5);
    }

    @action
    compactNumbers() {
        let sp = this.store.map.surveyPoints;
        let pts = sp.points;
        pts.features.forEach((f, i) => {f.properties.num = i + 1})
        sp.points = {...pts};
        (this.map.getSource(SurveyPointsTool.SURVEY_POINTS_SOURCE) as GeoJSONSource).setData(sp.points);
        //console.log(6);
        sp.lastPointNumber = pts.features.length + 1;
    }

    @action
    updatePoint(p: Feature) {
        let sp = this.store.map.surveyPoints;
        let pts = sp.points;
        pts.features.forEach((f) => {if (f.properties.id == p.properties.id) f.properties = p.properties;});
        let fts = pts.features.slice().sort((f1, f2) => f1.properties.num - f2.properties.num);
        if (sp.lastPointNumber < p.properties.num)
            sp.lastPointNumber = p.properties.num + 1;
        sp.points = {...pts, features: fts};
        (this.map.getSource(SurveyPointsTool.SURVEY_POINTS_SOURCE) as GeoJSONSource).setData(sp.points);
        //console.log(7);
    }
}