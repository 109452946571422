import * as React from 'react';
import {RefObject, Suspense} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {IndexByPointPopupGroupsComp} from "./Popups/IndexByPointPopupGroupsComp";
import {IndexByPointPopupMonthComp} from "./Popups/IndexByPointPopupMonthComp";
import IndexByPointChart2Comp from "./IndexByPointChart2Comp";
import './IndexByPointFullscreenComp.scss';
import {IndexByPointYearsDropDownComp} from "./IndexByPointYearsDropDownComp";
import {IndexByPointDeleteAllPointsComp} from "./IndexByPointDeleteAllPointsComp";
import {IndexByPointMonthTitleComp} from "./IndexByPointMonthTitleComp";
import {IndexByPointTableComp} from "./IndexByPointTableComp";
import {IndexByPointPopupSettingsComp} from "./Popups/IndexByPointPopupSettingsComp";
import {action} from "mobx";
import IndexByPointColorPickerCom from './IndexByPointColorPickerCom';

@observer
export class IndexByPointFullscreenComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.legendRef = React.createRef();
    }

    legendRef: RefObject<any> = null;

    @action
    onMinimize(){
        this.props.store.indexByPointer.maxPanel = false;
    }

    render() {
        let store = this.props.store;
            
        return <div className="modal-back">
            <div id='fullscreen-modal' className="modal-full modal-full-frame IndexByPointFullscreenComp-window">
                <div className="IndexByPointFullscreenComp-left scroll-mini overflowAutoY">
                    <div className="indexByPoint-padright">
                        <div className="IndexByPointFullscreenComp-title">{store.trans.Charts}</div>
                        <div className="IndexByPointFullscreenComp-categoryTitle">{store.trans["Data type"]}</div>
                        <IndexByPointPopupGroupsComp store={store} className="" />
                        <div className="separator_line" style={{marginTop: "15px", marginBottom: "15px"}}/>
                        <IndexByPointPopupSettingsComp store={store} autoSubmit={true}
                                                       className="IndexByPointFullscreenComp-IndexByPointPopupSettingsComp-main" />
                        <div className="separator_line" style={{marginBottom: "15px"}} />
                        <IndexByPointPopupMonthComp store={store}  autoSubmit={true}
                                                    className="IndexByPointFullscreenComp-IndexByPointPopupMonthComp-main" />
                    </div>
                    <div className="width100 indexByPoint-opacity-div indexByPoint-padright indexByPoint_headerline">
                        <div className="indexByPoint_headerline-title">{store.trans.Points}</div>
                        <div className="bottom-line-gray height1 flex-stretch-item"/>
                        <IndexByPointYearsDropDownComp store={store} />
                        <IndexByPointColorPickerCom isScenario store={store}/>
                        <IndexByPointDeleteAllPointsComp store={store} />
                    </div>
                    <IndexByPointMonthTitleComp store={store} />
                    <div style={{paddingLeft:'6px'}} className="scrollbar-standart IndexByPointTableComp-cont">
                        <IndexByPointTableComp store={store}/>
                    </div>
                </div>
                <div className="IndexByPointFullscreenComp-right">
                    <div className="blue_fill_onhover white-fill pointer IndexByPointFullscreenComp-minimize" onClick={this.onMinimize}>
                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 12.6L9.7 8.3L8.3 9.7L12.6 14H8V16H15C15.6 16 16 15.6 16 15V8H14V12.6Z" />
                            <path d="M7 7H0V0H7V7ZM2 5H5V2H2V5Z" />
                        </svg>
                    </div>
                    <Suspense fallback={<div style={{padding: "10px 15px"}}>{store.trans.Downloading}...</div>}>
                        <IndexByPointChart2Comp calendarButtonVisible={false} settingsButtonVisible={false}
                            store={store} className="IndexByPointFullscreenComp-graph"/>
                    </Suspense>
                </div>
            </div>
        </div>;
    }
}
