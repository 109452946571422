import {ObservableCustomStore} from "../../CustomStore";
import {autorun, observable} from "mobx";
import {fetchJsonGet, fetchJsonPost} from "../../../helper/utils/FetchUtils";
import {ra} from "../../../helper/utils/mobxUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelMode} from "../../SearchStore";
import {ConstantsStore} from "../../config/ConstantsStore";
import {A2FieldStore} from "../A2FieldStore";
import {ProjType} from "../../user/UserStore";

export class ZalZakluchenieStore extends ObservableCustomStore{
    @observable
    showZakluchenie: boolean = false;
    @observable
    readOnly: boolean = false;

    @observable
    req_id: number;
    @observable
    cad_num: string;
    @observable
    description: string;
    @observable
    analiz_okrug: string;
    @observable
    analiz_izm: string;
    @observable
    vyvod: string;
    @observable
    permalink: string;
    @observable
    neighboring_plots: string;

    subscription(): IReactionDisposer[] {
        return [autorun(async()=>{
            try {
                let store = this.root;
                if (this.showZakluchenie) {
                    if ((store.searchPanel.leftPanelMode != LeftPanelMode.agro) ||
                        (this.req_id != this.getReqId())
                    ) {
                        await this.writeValues();
                        ra(async () => {
                            this.showZakluchenie = false;
                        });
                    }
                }
            }catch (err){
                ra(()=>{
                    this.root.addError(err);
                });
            }
        })];
    }

    getCadNum(): string{
        let r = this.getFirstRec();
        if (r == null) return null;
        return r.properties[ConstantsStore.Zal_cadaster_num];
    }
    getReqId(): number{
        let r = this.getFirstRec();
        if (r == null) return null;
        return r.properties[ConstantsStore.Zal_req_id];
    }

    getFirstRec(): A2FieldStore{
        let store = this.root;
        if (store.agro2.projectInfo.projectType != ProjType.parcel_assess) return null;
        let navigatorItem = store.agro2.projectStruct.activeNavigatorPage;
        if (navigatorItem.fields.length == 0) return null;
        let firstItem = navigatorItem.fields[0];
        return firstItem;
    }

    async saveReport() {
        try {
            let url = `/api/projects/${this.root.agro.projectName}/report/save`;
            let res = await fetchJsonPost(url, {cadastr_num: this.cad_num});
            //console.log(res);
            ra(()=>{
                let navigatorItem = this.root.agro2.projectStruct.activeNavigatorPage;
                if (navigatorItem != null) navigatorItem.resetValues();
            });    
            return true;
        }catch (e) {
            this.root.addError(e);
            return false;
        }
        finally {

        }
    }

    async writeValues(){
        try {
            let url = `/api/projects/${this.root.agro.projectName}/reference/conclusion/modify`;
            await fetchJsonPost(url, {values: [{
                    cadastr_num: this.cad_num,
                    general: this.description,
                    surroundings: this.analiz_okrug,
                    retro: this.analiz_izm,
                    summary: this.vyvod,
                    permalink: this.permalink,
                    adjacent: this.neighboring_plots
                }]});
            return true;
        }catch (e) {
            this.root.addError(e);
            return false;
        }
    }
    async readValues(cadastr_num: string, req_id: number){
        try {

            let url = `/api/projects/${this.root.agro.projectName}/reference/conclusion/list`;
            let r = await fetchJsonGet(url, {filter: {"cadastr_num": cadastr_num}});
            if (r.length != 1){
                throw `Не найдено заключение для участка ${cadastr_num}`;
            }
            ra(()=>{
                let v = r[0].values;
                this.req_id = req_id;
                this.cad_num = cadastr_num;
                this.description = v.general;
                this.analiz_izm = v.retro;
                this.analiz_okrug = v.surroundings;
                this.vyvod = v.summary;
                this.permalink = v.permalink;
                this.neighboring_plots = v.adjacent;
            });
        }catch (e) {
            this.root.addError(e);
        }
    }

}