import {ObservableCustomStore} from "../../app/store/CustomStore";
import {action, observable, reaction} from "mobx";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {AgroAhoStore, AhoCheckbox3StateType, AhoModalType, AhoStage} from "./agroAhoStore";
import {Indicator} from "./agroAhoProjStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelModeAgroAho} from "./agroAhoPlugin";
import {AhoEditorMode} from "./agroAhoEditorStore";
import {ra} from "../../app/helper/utils/mobxUtils";
import {TextUtils} from "../../app/helper/utils/TextUtils";
import area from "@turf/area";
import {AhoUtils} from "./agroAhoUtils";
import {AgroAhoCardRNStore} from "./card/agroAhoCardRN";
import {IAhoLayers} from "./map/agroAhoMapStore";
import {AgroAhoCardFieldStore, FieldSortType} from "./card/agroAhoFields";
import {OpacityLayers} from "./map/agroAhoOpacity";


interface ProbeCnt {
    probe_cnt: number
}

interface DictIndicators {
    [indc_code: string]: ProbeCnt;
}

interface CellInfo {
    cell_cnt: number;
    ds_code: string;
    indicators?: DictIndicators
}

interface FieldInfo {
    ds_code: string;
    field_cnt: number;
    sum_area_ha: number;
}

export interface IExtent {
    lon_min: number;
    lon_max: number;
    lat_min: number;
    lat_max: number;
}

interface PointInfo {
    ds_code: string;
    extent: IExtent;
    point_cnt: number;
}

export interface TrackInfo {
    ds_code: string;
    sum_len_m: number;
}

export interface Card {
    map_id: number;
    map_name: string;
    indc_cnt: number;
    extent?: IExtent;
    cell_info: CellInfo;
    field_info: FieldInfo;
    point_info: PointInfo;
    track_info: TrackInfo;
    upd_tm: string;
    selected?: boolean;
    filtered?: boolean;
    proj_name?: string;
}

export interface IndicatorKey {
    ds: string;
    indc_code: string;
    format: AhoIndicatorFormat
}

export interface RecNormKey {
    ds: string;
    rn_id: string;
}


export enum AhoIndicatorFormat {
    AgroSoft = 'AgroSoft',
    Agroplem = 'Agroplem',
    Disabled = 'Disabled'
}

export enum AhoCardShowType {
    Indicators= 'Indicators',
    Cells = 'Cells',
    WithoutLabels = 'WithoutLabels'
}

export enum AhoExportFormat {
    GeoJSON= 'geojson',
    KML = 'kml',
    Shapefile = 'shape'
}

export enum SoilAnalysisType {
    Indicators = 'Indicators',
    RecNorms = 'RecNorms',
    Spinner = 'Spinner'
}

export enum ExportRNFertType {
    TechXml = 'TechXml',
    TechShp = 'TechShp',
    CellsShp = 'CellsShp'
}

export enum GridGrainSize {
    ggs10 = 10,
    ggs15 = 15,
    ggs20 = 20,
    ggs30 = 30
}

export interface LoadedIndicator {
    name: string;
    count: string;
    indc_code: string;
    warning: boolean;
}

export interface FieldItem {
    area_ha: number;
    field_id: number;
    field_name: string;
    field_num: number;
    crop_name?: string;
    checked?: boolean;
    filtered?: boolean;
    opacity?: OpacityLayers;
}

export interface CellItem {
    area_ha?: number;
    field_id: number;
    cell_id: number;
    cell_name: string;
    cell_num: number;
    filtered?: boolean;
    opacity?: OpacityLayers;
}

export enum CopyType {
    New = 'New',
    Exist = 'Exist'
}

export interface ISelectedFields {
    ids: number[];
    area: number;
}

export interface RecNormStat {
    fert_total_volume_kg: number,
    fert_min_kg_ha: number,
    fert_avg_kg_ha: number,
    fert_max_kg_ha: number
}


export class AgroAhoCardStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    @observable
    card: Card;
    @observable
    newName: string = '';
    @observable
    cardIndicators: Indicator[] = null;
    @observable
    selectedIndicator: Indicator;
    @observable
    removableIndicator: Indicator;
    @observable
    soilAnalysisType: SoilAnalysisType = SoilAnalysisType.Spinner; // SoilAnalysisType.Indicators;
    @observable
    soilAnalysisShow: boolean = true;
    // indicatorsShow: boolean = true;
    @observable
    ahoCardShowType: AhoCardShowType = AhoCardShowType.Indicators;
    @observable
    lastCardName: string = null;
    ds_code: string;
    // @observable
    // ahoPLink: string = '';
    @observable
    winSettingVisible: boolean = false;
    @observable
    isCellsCanGenerate: boolean = false;
    @observable
    indcFormat: AhoIndicatorFormat = AhoIndicatorFormat.AgroSoft;
    // defIndcFormat: AhoIndicatorFormat = AhoIndicatorFormat.AgroSoft;
    formatAgroSoftDisabled: boolean = false;
    formatAgroplemDisabled: boolean = false;
    @observable
    fieldsItems: FieldItem[] = [];
    @observable
    cellsItems: CellItem[] = [];
    @observable
    selectedFields: ISelectedFields;
    @observable
    fieldsShow: boolean = false;
    @observable
    fieldsLoading: boolean = false;
    @observable
    cellsShow: boolean = false;
    @observable
    searchFilter: string = '';
    @observable
    searchCellFilter: string = '';
    @observable
    selectAll: AhoCheckbox3StateType = AhoCheckbox3StateType.NoChecked;
    @observable
    sortDesc: boolean = true;
    @observable
    sortCellDesc: boolean = false;
    ignoreChangeConfig: boolean = false;
    cellsAreaCalculated: boolean = false;
    // >>> export >>>
    @observable
    exportFileName: string = '';
    @observable
    exportRNFileName: string = '';
    @observable
    exportRNFertType: ExportRNFertType = ExportRNFertType.TechXml;
    @observable
    gridGrainSize: GridGrainSize = GridGrainSize.ggs10;
    @observable
    exportVisFields: boolean = false;
    @observable
    layersExportVisFields: boolean = false;

    @observable
    cartogramChecked: boolean = true;
    @observable
    indicatorsChecked: boolean = false;
    @observable
    cellsChecked: boolean = false;
    // rn
    @observable
    normsChecked: boolean = false;
    @observable
    fertChecked: boolean = true;
    @observable
    fertTotalStr: string = '';
    recNormStat: RecNormStat = null;
    @observable
    fertMinStat: number = 0;
    @observable
    fertAvgStat: number = 0;
    @observable
    fertMaxStat: number = 0;
    // <<< export <<<
    @observable
    fieldsNumberShow: boolean = false;
    @observable
    delIndcSelFields: boolean = false;
    @observable
    copyType: CopyType = CopyType.New;
    @observable
    copyNewMapName: string = '';
    @observable
    copyAsaMapId: number = null;
    @observable
    copyShift: string = '';
    @observable
    fieldDouble: string = '';
    @observable
    cellDouble: string = '';
    @observable
    cellInterval = {min: 0, max: 0};
    @observable
    hideIndc: boolean = false;
    noUpdFieldOpacity: boolean = false;


    subscription(): IReactionDisposer[]{
        let ms = this.parentStore.mapStore;
        let ts = this.parentStore.tourStore;
        return [
            reaction(()=>[ms.layersShow, ms.layerFieldOutlinesShow, ms.layerCellsShow,
                    ms.layerTracksShow, this.soilAnalysisShow, this.soilAnalysisType, ms.layerPointsShow,
                    this.ahoCardShowType, this.selectedIndicator, this.indcFormat, JSON.stringify(ts.toursItems),
                    JSON.stringify(ms.mapRNStore.curRecNormItem), this.fieldsNumberShow, this.hideIndc,
                    ms.mapEditRouteStore.mode],
                ()=>this.doChangeConfig()),
            reaction(()=>[this.root.searchPanel.leftPanelMode],
                ()=>this.doChangeAhoTab()),
            reaction(()=>[this.parentStore.ahoStage],
                ()=>this.doChangeAhoStage()),
            reaction(()=>[JSON.stringify(this.fieldsItems)],
                ()=>this.doUpdFieldsItems()),
            // reaction(()=>[this.selectedIndicator],
            //     ()=>{this.doUpdFieldsOpacity(true)}),
            reaction(()=>[this.parentStore.root.agro2.agroFieldVisible],
                ()=>this.parentStore.urlStore.updPermalink()),
        ]
    }

    cardRNStore: AgroAhoCardRNStore = new AgroAhoCardRNStore(this);
    cardFieldStore: AgroAhoCardFieldStore = new AgroAhoCardFieldStore(this);
    fertTotal: number = null;


    // get fertTotal(): number{
    //     let val = this.fertTotalStr.replace(/ /g, '');
    //     return this.parentStore.isNum(val) ? +val : null;
    // }
    // set fertTotal(val: any){
    //     let sval = (val+'' || '').replace(/ /g, '');
    //     if (!this.parentStore.isNum(sval)) return;
    //     this.fertTotalStr = this.parentStore.numWithSpaces(sval);
    // }

    // doUpdFieldsItems(newVal: any, oldVal: any){
    doUpdFieldsItems(){
        if (this.noUpdFieldOpacity) return;
        // if (this.isEqualFieldObjects(val, this.oldFieldsItems)) return;
        this.doUpdFieldsOpacity();
        this.parentStore.urlStore.updPermalink();
    }

    // isEqualFieldObjects(v0: string, v1: string){
    //     if (!v0 && !v1) return true;
    //     if (!v0 || !v1) return false;
    //     let v0_: FieldItem[] = JSON.parse(v0);
    //     let v1_: FieldItem[] = JSON.parse(v1);
    //     let res = true;
    //     let obj0: any = {};
    //     let obj1: any = {};
    //     v0_.forEach(f0=>obj0[f0.field_id] = f0);
    //     v1_.forEach(f1=>obj0[f1.field_id] = f1);
    //     v0_.forEach(f0=>{
    //         let f1: FieldItem = obj1[f0.field_id];
    //         if (!f1) res = false
    //         else if (f0.checked != f1.checked) res = false;
    //     });
    //     v1_.forEach(f1=>{
    //         let f0: FieldItem = obj0[f1.field_id];
    //         if (!f0) res = false
    //         else if (f1.checked != f0.checked) res = false;
    //     });
    //     return res;
    // }

    updCellInterval(){
        this.fieldDouble = '';
        this.cellDouble = '';
        let min = -1;
        let max = 0;
        if (this.fieldsItems) {
            this.fieldsItems.forEach(f=>{
                if (this.cellsItems)
                    this.cellsItems.forEach(c=>{
                        if (c.field_id == f.field_id && f.checked) {
                            if (c.cell_id > max) max = c.cell_id;
                            if (c.cell_id < min || min < 0) min = c.cell_id;
                        }
                    });
            });
        }
        this.cellInterval = {min: min, max: max};
    }

    getFieldSelected(allIfNoSelect?: boolean){
        let selected: number[] = [];
        // console.log('this.fieldsItems', AhoUtils.cp(this.fieldsItems));
        if (this.fieldsItems) {
            let selectedItems = this.fieldsItems.filter((f: any)=>f.checked);
            selected = selectedItems.map(i=>i.field_id);
            // if (selected.length == this.fieldsItems.length) selected = null;
            if (allIfNoSelect && selected.length == 0) selected = this.fieldsItems.map(f=>f.field_id);
        }
        return selected;
    }
    getCellSelected(){
        let fids = this.getFieldSelected();
        if (this.fieldsItems?.length && !fids.length) fids = this.fieldsItems.map(i=>i.field_id);
        let selected: number[] = [];
        if (fids.length && this.cellsItems?.length) {
            selected = this.cellsItems.filter(c=> fids.indexOf(c.field_id) >= 0).map(c=>c.cell_id);
        }
        return selected;
    }
    doFilterChanged(val: string){
        this.searchFilter = val;
        this.updFilteredFields(val);
    }

    doCellFilterChanged(val: string){
        this.searchCellFilter = val;
        this.updFilteredCells(val);
    }

    // @action
    async doUpdFieldsOpacity(indc?: boolean){
        this.parentStore.mapStore.opacityStore.updOpacityLayers();
    }
    // async doUpdFieldsOpacity_(indc?: boolean){
    //     if (this.ignoreChangeConfig) return;
    //     // console.log('doUpdFieldsOpacity', indc);
    //     let mstore = this.parentStore.mapStore;
    //     let tstore = mstore.trackFieldPointStore;
    //     if (!this.fieldsItems) {
    //         // console.log('doUpdFieldsOpacity: no fieldsItems');
    //         return;
    //     }
    //     // console.log('this.fieldsItems', JSON.parse(JSON.stringify(this.fieldsItems)));
    //     let checked = !this.fieldsItems.every(i=>!i.checked);
    //     // let arrayOfPromises = this.fieldsItems.map(row=>{
    //     //
    //     // });
    //
    //     let routeData = mstore.mapEditRouteStore.getRouteData();
    //     for (let i=0; i<this.fieldsItems.length; i++){
    //         let f = this.fieldsItems[i];
    //         let selOpacity = checked && !f.checked;
    //         let routeOpacity = routeData && routeData.field_id != f.field_id;
    //         let sel = checked && f.checked;
    //         let routeSel = routeData && routeData.field_id == f.field_id;
    //
    //         if (!indc) {
    //             // Границы полей field_id
    //             mstore.setFeatureOpacity(this.card.field_info.ds_code, f.field_id, selOpacity || routeOpacity,
    //                 null, sel || routeSel);
    //             // Границы ячеек cell_id
    //             this.updCellOpacity(f.field_id, selOpacity || routeOpacity, sel || routeSel, routeData?.cell_ids);
    //             //
    //             this.updTrackOpacity(f.field_id, selOpacity || !!routeData, sel || routeSel);
    //             this.updTrackFOpacity(f.field_id, selOpacity || !!routeData, sel || routeSel);
    //             // tstore.setTFPOpacity(!!routeData);
    //             tstore.updOpacity(f.field_id, selOpacity || !!routeData, sel);
    //             mstore.setCardLabelOpacity(!!routeData);
    //         }
    //         // await this.updIndcsOpacity(f.field_id, checked && !f.checked, checked && f.checked);
    //         // await this.parentStore.mapStore.mapRNStore.updRNOpacity(f.field_id, checked && !f.checked,
    //         //     checked && f.checked);
    //     }
    //
    //     // this.fieldsItems.forEach(async f=>{
    //     //     if (!indc) {
    //     //         mstore.setFeatureOpacity(this.card.field_info.ds_code, f.field_id, checked && !f.checked,
    //     //             null, checked && f.checked);
    //     //         this.updCellOpacity(f.field_id, checked && !f.checked, checked && f.checked);
    //     //         this.updTrackOpacity(f.field_id, checked && !f.checked, checked && f.checked);
    //     //         this.updTrackFOpacity(f.field_id, checked && !f.checked, checked && f.checked);
    //     //     }
    //     //     await this.updIndcsOpacity(f.field_id, checked && !f.checked, checked && f.checked);
    //     // });
    // }
    // async updCellOpacity(field_id: number, checked: boolean, selected: boolean, cell_ids?: number[]){
    //     let mstore = this.parentStore.mapStore;
    //     // console.log('updCellOpacity');
    //     // console.log('before get geojson updCellOpacity', this.card.cell_info.ds_code);
    //     let data: any = await mstore.getCellCardGeoJSONs({ds: this.card.cell_info.ds_code, no_geom: true,
    //         type: IAhoLayers.Cell});
    //     if (!data?.features) return;
    //     // console.log('data:', data);
    //     data.features.forEach((f: any)=>{
    //         // console.log('fid', AhoUtils.cp(cell_ids), f.properties.cell_id, (cell_ids || []).indexOf(f.properties.cell_id) );
    //         let routeOpacity = cell_ids && cell_ids.indexOf(f.properties.cell_id) < 0;
    //         if ((f.properties.field_id == field_id && !routeOpacity) || routeOpacity)
    //             mstore.setFeatureOpacity(this.card.cell_info.ds_code, f.properties.cell_id, checked, null,
    //                 selected);
    //     });
    // }
    async getTrackIds(field_id: number){
        return await this.getTrackIds_(field_id, 'cell');
    }
    async getTrackFIds(field_id: number){
        return await this.getTrackIds_(field_id, 'field');
    }
    async getTrackIds_(field_id: number, track_type: string){
        let ms = this.parentStore.mapStore;
        let data: any = await ms.getCellCardGeoJSONs({ds: this.card.track_info.ds_code, no_geom: true,
            track_type: track_type, type: IAhoLayers.TrackCell});
        let res: number[] = [];
        if (data?.features) data.features.forEach((f: any)=>{
            let fid = f.properties.field_id;
            let tid = f.properties.track_id;
            if (fid == field_id) res.push(tid);
        });
        return res;
    }
    async updTrackOpacity(field_id: number, checked: boolean, selected: boolean){
        let mstore = this.parentStore.mapStore;
        // console.log('before get geojson updTrackOpacity', this.card.track_info.ds_code);
        let data: any = await mstore.getCellCardGeoJSONs({ds: this.card.track_info.ds_code, no_geom: true,
            track_type: 'cell', type: IAhoLayers.TrackCell});
            // filter_: '?filter={"track_type":"cell"}&geom=0', type: IAhoLayers.TrackCell});
        if (!data?.features) return;
        // console.log('data:', data);
        data.features.forEach((f: any)=>{
            if (f.properties.field_id == field_id) {
                mstore.setFeatureOpacity(this.card.track_info.ds_code, f.properties.track_id, checked, null,
                    selected);
            }
        });
    }
    async updTrackFOpacity(field_id: number, checked: boolean, selected: boolean){
        let mstore = this.parentStore.mapStore;
        // console.log('before get geojson updTrackFOpacity', this.card.track_info.ds_code);
        let data: any = await mstore.getCellCardGeoJSONs({ds: this.card.track_info.ds_code, no_geom: true,
            track_type: 'field', type: IAhoLayers.TrackCell});
            // filter_: '?filter={"track_type":"field"}&geom=0', type: IAhoLayers.TrackCell});
        if (!data?.features) return;
        // console.log('data:', data);
        data.features.forEach((f: any)=>{
            if (f.properties.field_id == field_id) {
                mstore.setFeatureOpacity(this.card.track_info.ds_code, f.properties.track_id, checked, '_fld',
                    selected);
            }
        });
    }
    async updIndcsOpacity(field_id: number, checked: boolean, selected: boolean){
        let mstore = this.parentStore.mapStore;

        // console.log('updIndcsOpacity', !!mstore.indicatorKey, mstore.indicatorKey, JSON.stringify(mstore.indicatorKey));
        // console.log('this.cardIndicators', AhoUtils.cp(this.cardIndicators));
        // if (this.cardIndicators && this.cardIndicators.length && !mstore.indicatorKey)
        //     this.setIndicator(this.cardIndicators[0]);
        if (!mstore.indicatorKey) return;
        // console.log('indc opac upd');
        // console.log('before get geojson updIndcsOpacity', this.card.cell_info.ds_code);
        let data: any = await mstore.getCellCardGeoJSONs({ds: this.card.cell_info.ds_code, no_geom: true,
            type: IAhoLayers.Cell});
        // console.log('updIndcsOpacity data', field_id, data, data?.features);
        if (!data?.features) return;
        let ds = mstore.getCellSourceId(mstore.indicatorKey);
        // console.log('data:', data, ds);
        data.features.forEach((f: any)=>{
            if (f.properties.field_id == field_id) {
                // console.log('f.id', f.id, f.properties.field_id, ds, checked);
                mstore.setFeatureIndcOpacity(ds, f.id, checked, selected);
            }
        });
    }


    // async getCellGeom(cell_id: number){
    //     let cs = this.parentStore.cardStore;
    //     let data: any = await this.getCellCardGeoJSONs(cs.card.track_info.ds_code, true);
    //     let feat = data.features.filter((f: any)=>f?.properties?.cell_id === cell_id);
    //     if (feat.length) return feat[0].geometry;
    // }
    // async getFieldsInfo(ds: string){
    //     let geojson: any = await this.getCellCardGeoJSONs(ds, null, true);
    //     let feats = geojson?.features;
    //     if (feats) return (feats as any).map((f: any)=>f.properties);
    // }




    isCardVisible(){
        return this.root.searchPanel.leftPanelMode === LeftPanelModeAgroAho &&
            this.parentStore.ahoStage === AhoStage.Card;
    }
    isCardsVisible(){
        return this.root.searchPanel.leftPanelMode === LeftPanelModeAgroAho &&
            this.parentStore.ahoStage === AhoStage.Cards;
    }

    origAgroEditorEvents: any;
    emptyEvent: any = (e: any)=>{
        return true;
    };
    hideNoAhoElements(disable: boolean){
        let el = document.getElementById('map');
        if (el) el.classList.toggle('AgroAhoTmpHidePopupMenuItem', disable);
    }


    // disableAgroEditorEvents(disable: boolean){
        // let dt = this.root.map.superTools.editDrawTool;
        // if (!this.origAgroEditorEvents) {
        //     this.origAgroEditorEvents = {
        //         onDblClick: dt.onDblclick,
        //         onMouseUp: dt.onMouseUp,
        //         onMouseClick: dt.onMouseClick
        //     };
        // }
        // let el = document.getElementById('map');
        // let orig = this.origAgroEditorEvents;
        // if (disable) {
        //     if (el) el.classList.add('AgroAhoTmpHidePopupMenuItem');
            // dt.onDblclick = this.emptyEvent;
            // dt.onMouseUp = this.emptyEvent;
            // dt.onMouseClick = this.emptyEvent;
        // } else {
        //     if (el) el.classList.remove('AgroAhoTmpHidePopupMenuItem');
            // if (this.origAgroEditorEvents) {
            //     dt.onDblclick = orig.onDblClick;
            //     dt.onMouseDown = orig.onMouseDown;
            //     dt.onMouseUp = orig.onMouseUp;
            //     dt.onMouseMove = orig.onMouseMove;
            //     dt.onMouseClick = orig.onMouseClick;
            // }
        // }
    // }

    doChangeAhoTab(){
        let estore = this.parentStore.editorStore;
        let rstore = this.parentStore.mapStore.mapEditRouteStore;
        if (this.parentStore.appInitInProgress) return;
        // console.log('change aho tab');

        this.doChangeCardVisible();

        if (this.root.searchPanel.leftPanelMode === LeftPanelModeAgroAho) {
            // if (estore.mode != AhoEditorMode.Disabled) estore.restore();
            // // this.disableAgroEditorEvents(true);
            this.hideNoAhoElements(true);
        } else {
            estore.exit();
            rstore.doDisableModeClick();
            this.hideNoAhoElements(false);
            // this.disableAgroEditorEvents(false);
            // this.parentStore.root.agro2.agroFieldVisible = estore._lastAgroFieldVisible;
        }
    }

    doChangeAhoStage(){
        this.doChangeCardVisible();
        let estore = this.parentStore.editorStore;
        let rstore = this.parentStore.mapStore.mapEditRouteStore;
        if (this.parentStore.ahoStage !== AhoStage.Card) {
            estore.exit();
            rstore.doDisableModeClick();
            estore.mode = AhoEditorMode.Disabled;
        }
    }

    doChangeCardVisible(){
        this.parentStore.mapStore.doChangeCardVisible(this.isCardVisible());
        this.parentStore.urlStore.updPermalink();
        // if (!isVisible) this.parentStore.urlStore.clearPermalink();
        // this.parentStore.urlStore.updUrlCode(!isVisible);
    }

    doChangeConfig(){
        if (this.ignoreChangeConfig) return;
        this.parentStore.mapStore.doChangeConfig();
        this.parentStore.urlStore.updPermalink();
    }

    // subscription(): IReactionDisposer[]{
    //     return [
    //         reaction(()=>this.selectedIndicator, ()=>this.doSelectIndicator())
    //     ]
    // }
    //
    // doSelectIndicator(){
    //     this.parentStore.mapStore.updCardLayers();
    //  }

    async doLoadCard(map_id: number, reload?: boolean){
        let proj_name = this.parentStore.projStore.projName;
        let url = `/api/projects/${proj_name}/asa2/map/${map_id}/info`;
        // console.log('plink', this.parentStore.ahoPermalink);
        let card: Card = await this.parentStore.cachedFetchJsonGet(url, reload);
        if (AhoUtils.check(card, 'Card')) {
            card.proj_name = proj_name;
            return card;
        } else console.error('Wrong card format');
    }

    doUploadCells(){
        this.parentStore.toggleModal(true, AhoModalType.UploadCells);
    }

    async doEditCard(){
        if (this.newName === this.card.map_name || this.newName === '') return;
        // let newName = this.newName.replace(/"/g, '\\"');
        let newName = AhoUtils.escapeQuotes(this.newName);
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${this.card.map_id}/modify?values={"map_name":"${newName}"}`;
        // console.log('newName:', newName, url);
        await fetchJsonGet(url).then(json=>{
            if (json.result === 'ok') {
                this.card.map_name = this.newName;
                this.lastCardName = this.newName;
            }
        });
    }
    async doLoadCardIndicators(card_id: number){
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${card_id}/info`;
        let json = await this.parentStore.cachedFetchJsonGet(url);
        return json.cell_info.indicators;
    }

    onDeleteIndicators(){
        this.parentStore.toggleModal(true, AhoModalType.DeleteIndicators);
    }

    async deleteIndicators(card_id: number){
        if (!this.cardIndicators?.length) return;
        // let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/tour/tour_id/observ/delete?indc_codes=${r[2]}`;
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${card_id}/tour/tour_id/observ/delete`;
        let res = await fetchJsonGet(url);
        if (res.result === 'ok') await this.updIndicators();
    }

    onDeleteIndicator(indc: Indicator){
        this.removableIndicator = indc;
        this.delIndcSelFields = !!this.getFieldSelected().length;
        this.parentStore.tourStore.updToursForDel();
        this.parentStore.toggleModal(true, AhoModalType.DeleteIndicator);
    }

    async deleteIndicator_(card_id: number, indc: Indicator){
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa_map/${card_id}/indicators/${indc.indc_code}/delete`;
        let res = await fetchJsonGet(url);
        if (res.result === 'ok') await this.updIndicators();
    }

    async updCard(){
        let card = await this.doLoadCard(this.card.map_id, true);
        if (!card) return;
        this.card = card;
    }

    async updIndicators(){
        await this.updCard();
        this.cardIndicators = await this.getIndicators();
        this.doUpdSelIndicator();
    }

    // async updCells(){
    //     let card = await this.doLoadCard(this.card.map_id);
    //     if (card.cell_info) this.card.cell_info = card.cell_info;
    // }

    async getIndicators(){
        let mstore = this.parentStore.mapStore;
        let tstore = this.parentStore.tourStore;
        let cs_store = this.parentStore.cardsStore;
        if (mstore.isNewProj()) await tstore.doTmpLoadIndcsFromTours(mstore.parentStore.cardStore.card.map_id);
        let cardIndicators = this.card.cell_info?.indicators || {};
        let res = this.parentStore.projStore.indicators.filter(
            (item: Indicator)=>cardIndicators[item.lower_code]);
        // console.log('DEBUG:', AhoUtils.cp(cardIndicators), this.parentStore.projStore.indicators);
        res.sort((a: any, b: any) => a.indc_id > b.indc_id ? 1 : -1);
        // console.log('cardIndicators', cardIndicators, 'res', res);
        // let hz: Indicator[] = [];
        return res;
    }

    isLegendPalette(){
        let isAgroSoftDisabled = !this.selectedIndicator?.visibility?.scales?.agrosoft?.length;
        let isAgroplemDisabled = !this.selectedIndicator?.visibility?.scales?.agroplem?.length;
        let isAgroSoft = this.indcFormat == AhoIndicatorFormat.AgroSoft;
        let isAgroplem = this.indcFormat == AhoIndicatorFormat.Agroplem;
        return (isAgroSoft && !isAgroSoftDisabled) || (isAgroplem && !isAgroplemDisabled);
    }

    async deleteIndicator(map_id: number, indc: Indicator, tour_ids: number[], sel_fields?: boolean){
        let selectedFields = this.fieldsItems.filter((f: any)=>f.checked);
        let fields = '';
        if (selectedFields.length) fields = selectedFields.map(f=>f.field_id).join(',');
        let pstore = this.parentStore;
        let r = [pstore.projStore.projName, map_id, indc.indc_code];
        // console.log('del ind', r);
        //         /api/projects/proj_name/asa2/map/map_id/tour/tour_id/observ/delete
        let del_promises:any[] = [];
        tour_ids.forEach(t_id=>{
            let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/tour/${t_id}/observ/delete?indc_codes=${r[2]}`;
            if (sel_fields) url += `&field_ids=${fields}`;
            del_promises.push(fetchJsonGet(url).catch(()=>{}));
        });
        let results = await Promise.all(del_promises);
        if (results.every(r=>r?.result == 'ok')) pstore.root.addInfo(pstore.trans['Successfully deleted']);
        // console.log('res del ind', res);
        await AhoUtils.delay(300);
        await this.updIndicators();
    }

    async deleteRecNorm(map_id: number, rn_id: number, tour_ids: number[]){
        let pstore = this.parentStore;
        let tstore = this.parentStore.tourStore;
        let r = [pstore.projStore.projName, map_id, rn_id];
        // console.log('del rn', r);
        // /api/projects/proj_name/asa2/map/map_id/tour/tour_id/rec_norm/delete
        let del_promises:any[] = [];
        tour_ids.forEach(t_id=>{
            let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/tour/${t_id}/rec_norm/delete?rn_id=${r[2]}`;
            del_promises.push(fetchJsonGet(url).catch(()=>{}));
        });
        let results = await Promise.all(del_promises);
        if (results.every(r=>r?.result == 'ok')) pstore.root.addInfo(pstore.trans['Successfully deleted']);

        await AhoUtils.delay(300);
        await tstore.loadsTours();
        if (pstore.cardStore.cardRNStore.hasRecNorm()) await pstore.cardStore.cardRNStore.setRecNormTab()
            else await this.checkAhoDataTabs();
    }

    setIndicator(indicator: Indicator){
        // let ms = this.parentStore.mapStore;
        // console.log('last:', JSON.stringify(ms.lastIndicatorKey), JSON.stringify(ms.getIndicatorKey()));
        // if (JSON.stringify(ms.lastIndicatorKey) !== JSON.stringify(ms.getIndicatorKey())) {
        //     ms.lastIndicatorKey = null;
        //     ms.initLastIndicatorKey(indicator);
        //     console.log('new last:', JSON.stringify(ms.lastIndicatorKey));
        // }


        this.hideIndc = false;
        this.selectedIndicator = indicator;
        // this.formatAgroSoftDisabled = !indicator?.visibility?.scales?.agrosoft?.length;
        // this.formatAgroplemDisabled = !indicator?.visibility?.scales?.agroplem?.length;
        // this.formatAgroplemDisabled = !this.formatAgroSoftDisabled;
        // if (this.defIndcFormat == AhoIndicatorFormat.AgroSoft)
        //     this.indcFormat = this.formatAgroSoftDisabled ? AhoIndicatorFormat.Agroplem : AhoIndicatorFormat.AgroSoft;
        // if (this.defIndcFormat == AhoIndicatorFormat.Agroplem)
        //     this.indcFormat = this.formatAgroplemDisabled ? AhoIndicatorFormat.AgroSoft : AhoIndicatorFormat.Agroplem;
        // if (this.formatAgroSoftDisabled && this.indcFormat == AhoIndicatorFormat.AgroSoft)
        //     this.indcFormat = AhoIndicatorFormat.Agroplem;
        // console.log('format, soft:', this.formatAgroSoftDisabled, JSON.parse(JSON.stringify(indicator)),
        //     'plem:', this.formatAgroplemDisabled);
        // console.log('setl indc', JSON.stringify(this.selectedIndicator));
    }

    @action
    indcLegendSettingClick(format: AhoIndicatorFormat){
        this.indcFormat = format;
        // this.defIndcFormat = format;
    }

    async doLoadPointInfo(card_id: number){
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${card_id}/info`;
        let json = await this.parentStore.cachedFetchJsonGet(url);
        return json?.point_info;
    }

    async updPointInfo(card: Card){
        // console.log('card', card);
        if (!card) return;
        if (!card.point_info) {
            let point_info = await this.doLoadPointInfo(card.map_id).catch(()=>{});
            if (point_info) card.point_info = point_info;
        }
    }

    @action
    async updFieldsInfo(restore?: boolean){
        let fields: FieldItem[] = await this.parentStore.mapStore.getFieldsInfo(this.card.field_info.ds_code);
        if (restore) {
            fields.forEach(f=>{
                this.fieldsItems.forEach(f_=>{
                    if (f.field_id == f_.field_id) {
                        f.checked = f_.checked;
                        f.filtered = f_.filtered;
                    }
                });
            });
        }
        this.fieldsItems = fields;
            // console.log('new fieldsItems', AhoUtils.cp(this.fieldsItems));
        if (!restore) this.sortFields(false);
    }

    async updCellsInfo(){
        this.cellsItems = await this.parentStore.mapStore.getFieldsInfo(this.card.cell_info.ds_code, IAhoLayers.Cell);
        this.cellsAreaCalculated = false;
        // console.log('updCellsInfo', AhoUtils.cp(this.cellsItems));
    }

    async cardClick(card: Card){
        let mstore = this.parentStore.mapStore;
        let tstore = this.parentStore.tourStore;
        let cardRNStore = this.parentStore.cardStore.cardRNStore;
        try {
            if (mstore.isNewProj() && !AhoUtils.check(card, 'Card'))
                return this.root.addError('Wrong card format');
            ra(async ()=>{
                this.hideIndc = false;
                this.ignoreChangeConfig = true;
                this.parentStore.ahoTopSpinnerShow = true;
                this.card = card;
                // console.log('card click', card.map_id, card.map_name);
                if (mstore.isNewProj()) await this.updPointInfo(card);
                this.indcFormat = this.parentStore.projStore.visFormat;
                this.isCellsCanGenerate = false;
                this.fieldsShow = false;
                tstore.toursShow = false;
                this.lastCardName = card.map_name;
                this.cardIndicators = await this.getIndicators();
                // this.fieldsItems = await this.parentStore.mapStore.getFieldsInfo(this.card.field_info.ds_code);
                // this.sortFields(false);
                await this.updFieldsInfo();
                await this.updCellsInfo();

                // this.cellsItems = await this.parentStore.mapStore.getFieldsInfo(this.card.cell_info.ds_code);
                if (mstore.isNewProj()) await tstore.loadsTours();
                this.cellsAreaCalculated = false;
                // this.indicatorsShow = !!this.cardIndicators.length;
                this.soilAnalysisShow = !!this.cardIndicators.length || cardRNStore.hasRecNorm();
                this.soilAnalysisType = SoilAnalysisType.Indicators;
                await this.checkAhoDataTabs();
                this.parentStore.mapStore.layersShow = !this.soilAnalysisShow; //!this.cardIndicators.length;
                this.ignoreChangeConfig = false;
                this.setIndicator(this.cardIndicators.length ? this.cardIndicators[0] : null);
                this.parentStore.ahoTopSpinnerShow = false;
                // await this.updRecNorm();
                await this.checkAhoDataTabs();
                this.parentStore.ahoStage = AhoStage.Card;
            });

        }catch (e) {
            ra(()=>{
                this.root.addError(e);
            });
        }
    }

    async checkAhoDataTabs(){
        let cardRNStore = this.parentStore.cardStore.cardRNStore;
        let hasRecNorm = cardRNStore.hasRecNorm();
        let hasIndcs = this.cardIndicators.length > 0;
        if (this.soilAnalysisType == SoilAnalysisType.RecNorms && !hasRecNorm && hasIndcs) {
            this.soilAnalysisType = SoilAnalysisType.Indicators;
        }
        if (this.soilAnalysisType == SoilAnalysisType.Indicators && !hasIndcs && hasRecNorm) {
            await cardRNStore.setRecNormTab();
        }
    }

    @action
    async updCellsArea(){
        let cs = this.parentStore.cardStore;
        let ms = this.parentStore.mapStore;
        let data: any = await ms.getCellCardGeoJSONs({ds: cs.card.cell_info.ds_code, type: IAhoLayers.Cell});
        ra(()=> {
            this.cellsItems.forEach(c => {
                let feat = data.features.filter((f: any) => f?.properties?.cell_id === c.cell_id);
                if (feat.length) c.area_ha = area(feat[0]) / 10000;
            });
        });
    }

    getCard(id: number) {
        let card_ = this.parentStore.cardsStore.cards.filter(item=>item.map_id === id);
        if (card_.length) return card_[0];
    }


    async doSaveIndicators(ds_code: string){
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa_map/${this.card.map_id}/upload?action=save&file_ds_code=${ds_code}`;
        return await fetchJsonGet(url);
    }



    getIndicatorName(indc_code: string){
        let arr = this.parentStore.projStore.indicators.filter(item=>item.lower_code === indc_code);
        if (arr.length) return arr[0].indc_name;
        return '';
    }

    async indicatorBackClick(){
        let cs_store = this.parentStore.cardsStore;
        cs_store.doCardsFilterChanged('');
        this.cellsShow = false;
        await cs_store.doLoadCards(true);
        this.parentStore.ahoStage = AhoStage.Cards;
    }

    doFieldClick(field: FieldItem){
        field.checked = !field.checked;
        this.updSelectAll();
        this.exportVisFields = false;
        // this.selectAll = this.fieldsItems.every(f=>!!f.checked);
        // this.parentStore.urlStore.updPermalink();
    }

    async doFieldZoomClick(field: FieldItem, buffer?: number){
        let bbox: any = await this.parentStore.mapStore.getFieldBbox(field.field_id);
        if (buffer) bbox = this.addBboxBuffer(bbox, buffer);
        this.root.map.zoomToBBox(bbox);
    }

    addBboxBuffer(bbox: any, buffer: number){
        // [minX, minY, maxX, maxY]
        return [
            bbox[0] - bbox[0] / 100 * buffer,
            bbox[1] - bbox[1] / 100 * buffer,
            bbox[2] + bbox[2] / 100 * buffer,
            bbox[3] + bbox[3] / 100 * buffer
        ];
    }

    async doCellZoomClick(cell: CellItem){
        let bbox: any = await this.parentStore.mapStore.getCellBbox(cell.cell_id);
        this.root.map.zoomToBBox(bbox);
    }

    doFieldsClearClick(){
        this.setSelectAllFields(false);
    }

    setSelectAllFields(value: boolean){
        ra(()=>{
            this.fieldsItems.filter(f=>!f.filtered).forEach(f=>f.checked=value);
            this.updSelectAll();
        });
    }

    updSelectAll(){
        let filtered = this.fieldsItems.filter(f=>!f.filtered);
        let isAll = filtered.every(f=>!!f.checked);
        let isNo = filtered.every(f=>!f.checked);
        if (isAll) this.selectAll = AhoCheckbox3StateType.Checked
        else if (isNo) this.selectAll = AhoCheckbox3StateType.NoChecked
        else this.selectAll = AhoCheckbox3StateType.Indeterminate;
    }

    doSelectAllClick(){
        if (this.selectAll == AhoCheckbox3StateType.NoChecked)
            this.selectAll = AhoCheckbox3StateType.Checked
        else this.selectAll = AhoCheckbox3StateType.NoChecked;
        this.setSelectAllFields(this.selectAll == AhoCheckbox3StateType.Checked);
    }

    isFilteredField(field: FieldItem, filter: string){
        let isNameFiltered = field.field_name.toLowerCase().indexOf(filter.toLowerCase()) == -1;
        let isCropFiltered = (field.crop_name || '').toLowerCase().indexOf(filter.toLowerCase()) == -1;
        return isNameFiltered && isCropFiltered;
    }

    updFilteredFields(filter: string){
        ra(()=>{
            this.noUpdFieldOpacity = true;
            this.fieldsItems.forEach(f=>f.filtered = this.isFilteredField(f, filter));
            this.noUpdFieldOpacity = false;
            this.updSelectAll();
        });
    }

    @action
    updFilteredCells(filter: string){
        this.cellsItems.forEach(f=>f.filtered = (f.cell_id + '').toLowerCase().indexOf(filter.toLowerCase()) == -1);
    }

    doSortFieldsClick(){
        this.sortDesc = !this.sortDesc;
        this.sortFields(this.sortDesc);
    }

    doUpdSelIndicator(){
        let cs_store = this.parentStore.cardsStore;
        let tstore = this.parentStore.tourStore;
        let indc_ids = tstore.doGetSelTourIndicatorCodes();
        let indicators = this.cardIndicators.filter(c=>indc_ids.indexOf(c.lower_code)>=0);
        let isNoSel = indicators.every(i=>i.lower_code != this.selectedIndicator?.lower_code);
        // console.log('upd sel indc0', this.selectedIndicator?.lower_code, this.selectedIndicator?.indc_name, isNoSel);
        if (isNoSel){
            this.setIndicator(indicators[0]);
            // console.log('upd sel indc', indicators[0].lower_code, indicators[0].indc_name);
        }
    }

    sortFields(desc: boolean){
        this.noUpdFieldOpacity = true;
        if (desc) {
            this.fieldsItems = this.fieldsItems.slice().sort((a,b)=> TextUtils.stringSortCompare(b.field_name, a.field_name));
        } else {
            this.fieldsItems = this.fieldsItems.slice().sort((a,b)=> TextUtils.stringSortCompare(a.field_name, b.field_name));
        }
        this.noUpdFieldOpacity = false;
        this.cardFieldStore.sortType = desc ? FieldSortType.ByNameDesc : FieldSortType.ByName;
        // console.log('type1:', this.cardFieldStore.sortType, this.sortDesc);
    }

    sortCropFields(byName: boolean){
        let cf = this.cardFieldStore;
        if (byName) {
            this.sortDesc = !this.sortDesc;
            this.sortFields(this.sortDesc);
            cf.sortType = this.sortDesc ? FieldSortType.ByNameDesc : FieldSortType.ByName;
        } else {
            cf.sortType = cf.sortType == FieldSortType.ByCrop ? FieldSortType.ByCropDesc : FieldSortType.ByCrop;
            this.sortDesc = cf.sortType == FieldSortType.ByCropDesc;
            this.noUpdFieldOpacity = true;
            if (this.sortDesc) {
                this.fieldsItems = this.fieldsItems.slice().sort((a, b) => TextUtils.stringSortCompare(b.crop_name, a.crop_name));
            } else
                this.fieldsItems = this.fieldsItems.slice().sort((a,b)=> TextUtils.stringSortCompare(a.crop_name, b.crop_name));
            this.noUpdFieldOpacity = false;
        }
        // console.log('type2:', cf.sortType, this.sortDesc);
    }

    doCellSortClick(){
        this.sortCellDesc = !this.sortCellDesc;
        // if (this.sortCellDesc) {
        //     this.cellsItems = this.cellsItems.slice().sort((a,b)=> b.cell_id - a.cell_id);
        // } else {
        //     this.cellsItems = this.cellsItems.slice().sort((a,b)=> a.cell_id - b.cell_id);
        // }
    }
    fieldsCopyResetData(){
        this.copyType = CopyType.New;
        this.copyNewMapName = '';
        this.copyAsaMapId = null;
    }
    onFieldsCopyClick(){
        this.fieldsCopyResetData();
        let cards_ = this.parentStore.cardsStore.cards.filter(c => !c.filtered && c.map_id != this.card.map_id);
        // this.copyAsaMapId = cards_[0].map_id;
        this.parentStore.toggleModal(true, AhoModalType.CopyFields);
    }
    doFieldsDel(){
        let selectedFields = this.fieldsItems.filter((f: any)=>f.checked);
        let r = [
            this.parentStore.projStore.projName,
            this.card.map_id,
            selectedFields.map(f=>f.field_id).join(',')
        ];
        // /api/projects/<proj_name>/asa2/map/<int:map_id>/field/delete?field_ids=1,2,3
        let url_ = `/api/projects/${r[0]}/asa2/map/${r[1]}/field/delete?field_ids=${r[2]}`;
        fetchJsonGet(url_).then(async r=>{
            if (r.result == 'ok') {
                await AhoUtils.delay(1300);
                this.parentStore.mapStore.resetCellCache();
                await this.updCard();
                await this.updFieldsInfo(true);
                await this.updCellsInfo();
                await this.parentStore.mapStore.updLayers();
                await this.parentStore.mapStore.trackFieldPointStore.updTFPLayers();
                this.root.addInfo(this.parentStore.trans['Successfully deleted']);
            } else this.root.addError(this.parentStore.trans['Deletion error']);
        }).catch(err => this.root.addError(err));
        // console.log('del fields');
    }
    async doFieldCopy(){
        let isErr = false;
        let dstMapId: number = null;
        this.parentStore.mapStore.resetCellCache();
        if (this.copyType == CopyType.New) {
            if (!this.copyNewMapName) return;
            let r = [
                this.parentStore.projStore.projName,
                this.copyNewMapName
            ];
            let url = `/api/projects/${r[0]}/asa2/map/create?map={"map_name":"${r[1]}","field_ds_code":"","cell_ds_code":"","track_ds_code":""}`;
            // let url = `/api/projects/${r[0]}/asa2/map/create?map={"map_name":"${r[1]}"}`;
            await fetchJsonGet(url).then(async r=>{
                if (r?.map_id) {
                    dstMapId = r.map_id;
                } else isErr = true;
            }).catch(err => {
                isErr = true;
                this.root.addError(err);
            });
        }
        if (isErr) {
            this.root.addError('Error creating a new ASA map');
            return;
        }

        if (this.copyType == CopyType.Exist) dstMapId = this.copyAsaMapId;
        let selFields = this.fieldsItems.filter((f: any)=>f.checked);
        let selFieldsIds = selFields.map(f=>f.field_id);
        let r = [
            this.parentStore.projStore.projName,
            this.card.map_id
        ];
        // /api/projects/<proj_name>/asa2/map/<int:map_id>/field/delete?field_ids=1,2,3
        // let url_ = `/api/projects/${r[0]}/asa2/map/${r[1]}/copy`;
        let url_ = `/api/projects/${r[0]}/asa2/map/${r[1]}/layers/copy`;
        let p = {
            params: {
                "dst_map": {"map_id": dstMapId},
                "field": {"field_ids": selFieldsIds},
                "cell": {"cell_id_shift": this.copyShift ? +this.copyShift : 0},
                // "track": {}
            }
        };
        fetchJsonGet(url_, p).then(async r=>{
            if (r?.status == 'ok') {
                this.fieldDouble = '';
                this.cellDouble = '';
                this.parentStore.toggleModal(false);
                this.root.addInfo(this.parentStore.trans['Successfully copied']);
                await this.parentStore.cardsStore.doLoadCards(true);
            }
            if (r?.status == 'error') {
                // let msg = [];
                if (r?.errors?.dupl_fields && r?.errors?.dupl_fields.length) {
                    this.fieldDouble = r?.errors?.dupl_fields?.map((f: any)=>f.field_name).join(', ');
                } else if (r?.errors?.dupl_cells && r?.errors?.dupl_cells.length) {
                    // let fields: any = {};
                    let cells: any = [];
                    r.errors.dupl_cells.forEach((c: any)=>{
                        // if (!fields[c.field_id]) fields[c.field_id] = {name: c.field_name, cells: []}
                        // fields[c.field_id].cells.push(c.cell_id);
                        cells.push(this.parentStore.isNum(c.cell_id) ? +c.cell_id : c.cell_id);
                    });
                    // let fields_: any[] = [];
                    // Object.keys(fields).forEach((key: any)=>{
                    //     let f = fields[key];
                    //     fields_.push(f.name + ': ' + f.cells.join(', '));
                    // });

                    this.cellDouble = cells.sort((a: any, b: any)=> TextUtils.smartTextSort(a, b)).join(', ');
                        //r.errors.dupl_cells.map((c: any)=>c.cell_id).join(', '));
                }
                // this.cellDouble = msg.join(' ');
            }
        }).catch(err => this.root.addError(err));
    }
    onHideIndcClick(){
        this.hideIndc = !this.hideIndc;
    }
    getStat(){
        let stat = {flds: 0, cells: 0, area: 0, fflds: 0, fcells: 0, farea: 0, rflds: 0, rcells: 0, rarea: 0};
        if (this.fieldsItems) {
            this.fieldsItems.forEach(f=>{
                let cells_cnt = 0;
                if (this.cellsItems)
                    cells_cnt = this.cellsItems.filter(c=>c.field_id === f.field_id).length;
                stat.flds += 1;
                stat.cells += cells_cnt;
                stat.area += f.area_ha;
                if (f.checked) {
                    stat.fflds += 1;
                    stat.fcells += cells_cnt;
                    stat.farea += f.area_ha;
                }
            });
        }
        stat.rflds = stat.fflds || stat.flds;
        stat.rcells = stat.fcells || stat.cells;
        stat.rarea = stat.farea || stat.area;
        return stat;
    }
    async updRecNormStat(){
        // /api/projects/proj_name/asa2/map/map_id/rec_norm/stat
        let store_ = this.parentStore;
        let cs = store_.cardStore;
        let ts = store_.tourStore;
        let rs = store_.mapStore.mapRNStore;

        let rn_id = rs.curRecNormItem?.rn_id;
        let f_ids = cs.fieldsItems.map(f=>f.field_id).join(',');
        let t_ids = ts.toursItems.map(t=>t.tour_id).join(',');
        let g_size = cs.gridGrainSize;
        if (cs.exportVisFields) {
            let hasRN = this.cardRNStore.hasRNSelFields();
            let fields_ = cs.getFieldSelected();
            if (fields_.length && hasRN) f_ids = fields_.join(',');
        }
        if (!ts.isNoSelectedTours) {
            let tours_ = ts.selTours().map(t=>t.tour_id);
            if (tours_.length) t_ids = tours_.join(',');
        }
        let r = [
            this.parentStore.projStore.projName,
            this.card.map_id,
            `"rn_id":${rn_id},"field_ids":[${f_ids}],"tour_ids":[${t_ids}],"grid_cell_size":${g_size}`
        ];
        let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/rec_norm/stat?params={${r[2]}}`;
        this.recNormStat = await fetchJsonGet(url).catch(err => this.root.addError(err));
        let isNo = this.fieldsItems.every(f=>!f.checked);
        let sel_area = this.fieldsItems.filter((f: any)=>isNo || f.checked)
            .reduce((sum, cur)=> sum + (cur.area_ha || 0), 0);
        this.recNormStat.fert_avg_kg_ha = this.recNormStat.fert_total_volume_kg / sel_area;
        this.updRecNorm();
    }
    setFertTotalStr(val: any){
        let sval = (val+'' || '').replace(/ /g, '');
        if (sval !== '0') sval = sval.replace(/^0+/g, '');
        if (!this.parentStore.isNum(sval)) return;
        this.fertTotalStr = this.parentStore.numWithSpaces(sval);
    }
    updRecNorm(){
        this.fertTotal = this.recNormStat.fert_total_volume_kg;
        this.setFertTotalStr(this.fertTotal);
        this.fertMinStat = this.recNormStat.fert_min_kg_ha;
        this.fertAvgStat = this.recNormStat.fert_avg_kg_ha;
        this.fertMaxStat = this.recNormStat.fert_max_kg_ha;
    }
    recalcRecNormMinMax(){
        let k = this.fertTotal / this.recNormStat.fert_total_volume_kg;
        this.fertMinStat = this.recNormStat.fert_min_kg_ha * k;
        this.fertAvgStat = this.recNormStat.fert_avg_kg_ha * k;
        this.fertMaxStat = this.recNormStat.fert_max_kg_ha * k;
    }
}