import {classRegPlugin} from "../../pluginApi";
import {ra} from "../../app/helper/utils/mobxUtils";
import {ClassFactory, ClassValueUpdateType} from "../../app/store/ClassFactory";
import {AgroExportStore} from "./store/agroExportStore";
import {ISuperStore} from "../../pluginApi/store/SuperStore";
import {AgroExportEvents} from "./AgroExportEvents";
import {ViewGeometryLayersTool} from "../../app/store/tools/EditGeometry/ViewGeometryLayersTool";
import {VoronovStopClickTool} from "./tools/voronov/VoronovStopClickTool";
import {EditDrawTool} from "../../app/store/tools/EditGeometry/EditDrawTool";
import {VoronovClickTool} from "./tools/voronov/VoronovClickTool";
import {IMeteoSuperStore} from "../meteo/meteoPlugin";
import {SampleSelectionTool} from "./tools/voronov/SampleSelectionTool";

export interface IAgroExportStoreProps {
    store: IAgroExportSuperStore,
    className?: string
}
export interface IAgroExportSuperStore extends ISuperStore{
    agroExport: AgroExportStore;
}


classRegPlugin({pluginName: "AgroExport",
        initStore: init,
    }
);

function init(store: IAgroExportSuperStore){
    ra(()=>{
        store.agroExport = new AgroExportStore(store as any);
        // ClassFactory.registerProperty(store, "agroReportStore", ClassValueUpdateType.update);
        ClassFactory.registerProperty(store, "agroExport", ClassValueUpdateType.update);
        store.agroExport.calculateTranslate();
        store.events.onChangeLang.addListen(()=>{
            store.agroExport.calculateTranslate();
        });

        let ev = new AgroExportEvents(store);
        store.events.onNdviProductItemChildren.addListen((st, children, arg)=>{
            ev.onNdviProductItemChildren(children, arg);
        });
        store.events.onMapChildren.addListen((st, children)=>{
            ev.onMapChildren(children);
        });

        store.events.onAgroFieldSelectedDropDownChildren.addListen((st, children)=>{
            ev.onAgroFieldSelectedDropDownChildren(children);
        });
        addTools(store);//сразу добавляем
        store.events.onInitTools.addListen(()=>{//если потребуется пересоздать
            addTools(store);
        });
    });
}

function addTools(store: IAgroExportSuperStore){
    let viewGeomEditVor = new ViewGeometryLayersTool(store.map.superTools.container, store.agroExport.voronovExportStore.editManager.state, "ViewGeometryLayersTool-editVor");
    store.agroExport.voronovExportStore.editManager.viewTool = viewGeomEditVor;

    viewGeomEditVor.Prefix = "class_toolsEditVor_";
    viewGeomEditVor.style.setRedStyle()
    let editVor = new EditDrawTool(store.map.superTools.container, store.agroExport.voronovExportStore.editManager.state, "EditDrawTool-editVor");
    let vorClick = new VoronovClickTool(store.root, "VoronovClick");
    let sampleSelection = new SampleSelectionTool(store.root, "SampleSelectionTool");


    store.map.superTools.pushToolAfter(editVor,["SearchConourTool"]);//3 EditDrawTool-editVor
    store.map.superTools.pushToolAfter(viewGeomEditVor,["SearchConourTool"]);//2 ViewGeometryLayersTool-editVor
    store.map.superTools.pushToolAfter(vorClick,["SearchConourTool"]);//4 VoronovClickTool
    store.map.superTools.pushToolAfter(sampleSelection,["SearchConourTool"]);
    store.map.superTools.pushToolAfter(new VoronovStopClickTool(store.root, "VoronovStopClick"),["SearchConourTool"]);//1 VoronovStopClick

}
