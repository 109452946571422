import {action, autorun, computed, observable} from "mobx";
import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {LeftPanelMode} from "../SearchStore";
import {ProductSettingStore} from "../productSetting/ProductSettingStore";
import {save} from "../PermalinkDecor";
import {IReactionDisposer} from "mobx/lib/internal";
import intersect from "@turf/intersect";
import { Utils } from "../../helper/utils/Utils";
import { Polygon } from "@turf/helpers";
import { MultiPolygon } from "geojson";

export class LegendStore extends ObservableCustomStore{
    @save @observable legendMinimize: boolean = false;

    @observable
    private _invisibleCategories: Set<any> = new Set<any>();

    @action
    clearVisibleCategories(){
        this._invisibleCategories.clear();
    }

    isVisibleCategories(categoryValue: any): boolean{
        return !this._invisibleCategories.has(categoryValue);
    }
    @action
    setVisibleCategories(categoryValue: any, visible: boolean){
        if (visible) this._invisibleCategories.delete(categoryValue)
        else this._invisibleCategories.add(categoryValue);
        this.root.agro2.fieldToolController.fieldLayerTool.reInstallLayers();
    }
    @computed
    get legendNdviShow(): boolean{
        let store = this.root;
        let lpm = store.searchPanel.leftPanelMode;
        if (!(
            lpm == LeftPanelMode.agro ||
            lpm == LeftPanelMode.photo ||
            lpm == LeftPanelMode.search ||
            lpm == LeftPanelMode.favorite ||
            lpm == LeftPanelMode.createProject ||
            lpm == LeftPanelMode.editProject ||
            lpm == LeftPanelMode.uploadFields)) return false;
        let topSceneType = store.searchPanel.top_sceneType;
        // let f1 = store.searchPanel.currentGroup?.first()?.feature?.geometry as Polygon | MultiPolygon;
        // let f2 = store.map.bbox.geoJson;
        // let isInExtent = Utils.hasIntersection(f1, f2);     
        // console.log(isInExtent);
        //console.log(topSceneType);
        if (! topSceneType) return false;   
        return ["index", "fixedIndex"].indexOf(store.map.productInfo.productsPassive.getActiveCodeByUiType(topSceneType).currentProductStore.bandType) >= 0;
        // return ["index", "fixedIndex"].indexOf(this.prodInfo?.prod?.bandType) >= 0;
        // if (this.prodInfo == null) return false;
        // return true;
    }
    get legendLayerShow(): boolean{
        return !this.legendNdviShow && this.root.agro2.agroFieldVisible;
    }


    @computed
    get prodInfo(): {prod: ProductSettingStore}{
        let store = this.root;
        let prod: ProductSettingStore = null;
        if (store.map.productInfo.activeProductsSet == null) return null;
        if (store.map.mapScenesStore.favoriteScenesVisible &&
            store.searchPanel.favoriteList.getAllSceneItems().length > 0) {
            prod = store.map.productInfo.activeProductsSet.activeCodeFavorite.currentProductStore;
            if (this.isVisibleProd(prod))
                return {prod: prod};
        }
        if (store.searchPanel.currentSceneVisible &&
            store.searchPanel.currentSceneid != null) {
            prod = store.map.productInfo.activeProductsSet.activeCodeCurrent.currentProductStore;
            if (this.isVisibleProd(prod))
                return {prod: prod};
        }
        return null;
    }
    isVisibleProd(prod: ProductSettingStore): boolean{
        return !(prod == null || prod.bandType == "RGB");
    }

}