import {Translate} from "../../../app/helper/lang/Translate";

export class AgroExportTranslate_en extends Translate{
    "Save new ASA maps"=""
    "Save to existing ASA maps"=""
    "ASA map name"=""
    "Search by ASA map"=""
    "Can not add"=""
    "The following fields are already in the ASA map"=""
    "It is necessary to remove intersecting fields from the ASA map or from the current breakdown"=""
    "Unable to save"=""
    "The following cell numbers are already in the ASA map"=""
    'It is necessary to change the numbers in the breakdown, to do this, use the "Cell numbers" setting'=""
    "Number of fields"=""
    "Data saved in ASA map"=""
    "Save error"=""
    'ASA map with name "{0}" already exists'=""
    "By average cell area"=""
    "By total number of cells"=""
    "By the number of cells for each field"=""
    "Construction method"=""
    "Avg. square"=""
    "Cell numbers with"=""
    "Numbers"=""
    "Cells"=""
    "pcs"=""
    "Voronoi diagrams / avg. area"=""
    "Voronoi diagrams / number of samples"=""
    "Rectangular grid / number of samples"=""
    "Rectangular grid / avg. area"=""
    "Voronoi diagrams"=""
    "Adaptive partitioning"=""
    "Rectangular grid"=""
    "Z-shaped"=""
    "Linear"=""
    "Selection route"=""
    "Num. of sampl."=""
    "Diagonal"=""
    "Avg. line"=""
}
