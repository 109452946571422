import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {GeometryUtils} from "../../../../../helper/utils/GeometryUtils";
import bbox from "@turf/bbox";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {CheckboxMiniComp, CheckboxSize, TriState} from "../../../../Common/CheckboxMiniComp";
import {A2LineButton} from "./A2LineButton";
import {A2FieldSelectedButton} from "./A2FieldSelectedButton";
import classNames from 'classnames';
import A2FieldFilterSelectedCheckbox from './A2FieldFilterSelectedCheckbox';

@observer
export class A2FieldPanelButtonComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickSelectAll(){
        let store = this.props.store;
        let agro = store.agro2;
        let noSelection = this.selectedCount() == 0;
        let page = agro.projectStruct.activeNavigatorPage;
        page.clearSelection();        
        if (noSelection){
            agro.projectStruct.activeNavigatorPage.filtered_fields.forEach(a => page.setSelected(a.id, true));
        }
        if (agro.filterSelectedChecked && agro.projectStruct.activeNavigatorPage.selectedFields.length == 0)
            agro.filterSelectedChecked = false;
    }
    @action
    clickUnselect(){
        let store = this.props.store;
        let page = store.agro2.projectStruct.activeNavigatorPage;
        page.fields.forEach(a => page.setSelected(a.id, false));
    }

    @action
    onClickSort(){
        this.props.store.agro.order_desc = !this.props.store.agro.order_desc;
    }

    @action
    onClickZoomToAllFields(){
        let store = this.props.store;
        let field_ids = store.agro2.projectStruct.activeNavigatorPage.fields.map(a => a.id);

        store.agro2.getFields(field_ids).then(f => {
            let ubbox: BBox2d = null;
            f.forEach(a => {
                if (!GeometryUtils.isGeometryEmpty(a.geometry)){
                    let b = bbox(a.geometry) as BBox2d;
                    if (ubbox == null) ubbox = b; else
                        ubbox = GeometryUtils.unionBbox(ubbox, b);
                }
            })
            if (ubbox != null){
                store.map.zoomToBBox1in3(ubbox);
            }
        }).catch(err => store.addError(err));
    }

    selectedCount(): number{
        let store = this.props.store;
        return store.agro2.projectStruct.activeNavigatorPage.selectedFields.length;
    }
    getAllFieldSelected(){
        let store = this.props.store;
        let allFind = store.agro2.projectStruct.activeNavigatorPage.filtered_fields.find(a => store.agro2.projectStruct.activeNavigatorPage.selectedFields.find(b => b.id == a.id) == null) == null;

        return allFind
            && store.agro2.projectStruct.activeNavigatorPage.filtered_fields.length > 0;
    }

    @action
    onClickAddField(){
        if (!this.props.store.agro2.permission.canCreateObject()) return;
        this.props.store.agro2.openCreateFieldPanel();
    }

    render() {
        let store = this.props.store;
        let allFieldSelected = this.getAllFieldSelected();
        let anyFieldSelected = store.agro2.projectStruct.activeNavigatorPage.selectedFields.length > 0;
        let state: TriState = TriState.uncheck;
        if (allFieldSelected)
            state = TriState.check;
        else if (anyFieldSelected)
            state = TriState.partial;

        let showCreateFiledStr: {ok: boolean} = {ok: true};
        store.events.onAgroCanCreateField.call(showCreateFiledStr);
        let showCreateFiled = showCreateFiledStr.ok;


        return <div className="flex-columns flex-center-content" style={{margin: "1px 8px 5px 14px"}}>
            <div className="flex-stretch-item flex-columns flex-center-content">
                <CheckboxMiniComp
                    state={ state }
                    size={CheckboxSize.standart}
                    onClick={this.onClickSelectAll}
                >
                </CheckboxMiniComp>
                <div className={"A2FieldSelectedButton-div"}>
                    <A2FieldFilterSelectedCheckbox checked={store.agro2.filterSelectedChecked} 
                    hasSelected={anyFieldSelected} enabled={!this.getAllFieldSelected() || this.selectedCount() == 0} store={store}/>
                    <A2FieldSelectedButton store={store} />
                </div>                
            </div>
            <A2LineButton onClick={this.onClickZoomToAllFields}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="blue_stroke_onhover white-stroke" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 4.5V1.5C0.5 0.948 0.948 0.5 1.5 0.5H4.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.5 0.5H14.5C15.052 0.5 15.5 0.948 15.5 1.5V4.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15.5 11.5V14.5C15.5 15.052 15.052 15.5 14.5 15.5H11.5"  strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.5 15.5H1.5C0.948 15.5 0.5 15.052 0.5 14.5V11.5"  strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.5 8C11.5 9.933 9.933 11.5 8 11.5C6.067 11.5 4.5 9.933 4.5 8C4.5 6.067 6.067 4.5 8 4.5C9.933 4.5 11.5 6.067 11.5 8Z" />
                </svg>
            </A2LineButton>
            <A2LineButton onClick={this.onClickSort} className="blue_stroke_onhover white-stroke">
                {this.props.store.agro.order_desc &&
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2645_23389)">
                            <path d="M0.5 6.5L2.75 0.5H3.25L5.5 6.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.25 4.5H4.75" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M0.5 9.5H5.5L0.5 15.5H5.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.5 15.5V0.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.5 4.5L11.5 0.5L7.5 4.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2645_23389">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                }
                {!this.props.store.agro.order_desc &&
                    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6.5L2.75 0.5H3.25L5.5 6.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.25 4.5H4.75" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M0.5 9.5H5.5L0.5 15.5H5.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.5 0.5V15.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.5 11.5L11.5 15.5L15.5 11.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                }
            </A2LineButton>
            <div style={{borderRight: "1px solid #6B707B", width: "1px", height: "24px", margin: "0 4px"}} />
            <A2LineButton className="blue_svg_onhover white-svg" onClick={this.onClickAddField} disabled={!showCreateFiled} >
                <svg width="14" height="14" viewBox="0 0 14 14" stroke="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 5.25H8.75V0.875C8.75 0.642936 8.65781 0.420376 8.49372 0.256282C8.32963 0.0921874 8.10706 0 7.875 0H6.125C5.89294 0 5.67038 0.0921874 5.50628 0.256282C5.34219 0.420376 5.25 0.642936 5.25 0.875V5.25H0.875C0.642936 5.25 0.420376 5.34219 0.256282 5.50628C0.0921874 5.67038 0 5.89294 0 6.125V7.875C0 8.10706 0.0921874 8.32963 0.256282 8.49372C0.420376 8.65781 0.642936 8.75 0.875 8.75H5.25V13.125C5.25 13.3571 5.34219 13.5796 5.50628 13.7437C5.67038 13.9078 5.89294 14 6.125 14H7.875C8.10706 14 8.32963 13.9078 8.49372 13.7437C8.65781 13.5796 8.75 13.3571 8.75 13.125V8.75H13.125C13.3571 8.75 13.5796 8.65781 13.7437 8.49372C13.9078 8.32963 14 8.10706 14 7.875V6.125C14 5.89294 13.9078 5.67038 13.7437 5.50628C13.5796 5.34219 13.3571 5.25 13.125 5.25Z"/>
                </svg>
            </A2LineButton>
        </div>;
    }
}
