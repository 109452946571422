import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoTriangleSvgProps {
    className?: string
    expand?: boolean;
}

export class AgroAhoTriangleSvg extends React.PureComponent<IAgroAhoTriangleSvgProps, undefined> {
    constructor(props: IAgroAhoTriangleSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let res = <svg className={classNames(this.props.className)}
            width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.88679 0.918042C9.95247 0.830602 9.99116 0.728013 9.99866 0.62146C10.0062 0.514906 9.98216 0.408477 9.9293 0.313775C9.87643 0.219073 9.79673 0.139732 9.69886 0.0844028C9.601 0.0290732 9.48875 -0.000121923 9.37433 3.55216e-07L0.624957 -2.7231e-08C0.510622 0.000204829 0.398539 0.0296781 0.300907 0.0852117C0.203277 0.140745 0.12384 0.220211 0.0712461 0.314957C0.0244322 0.397637 -2.57706e-05 0.48974 9.28179e-07 0.583254C-0.000208885 0.70298 0.0390663 0.819866 0.112493 0.918042L4.48718 6.75058C4.54477 6.82759 4.62144 6.89048 4.71066 6.9339C4.79988 6.97732 4.89902 7 4.99964 7C5.10027 7 5.1994 6.97732 5.28862 6.9339C5.37785 6.89048 5.45451 6.82759 5.51211 6.75058L9.88679 0.918042Z" fill="#C5C5C5"/>
        </svg>;
        if (!this.props.expand)
            res = <svg className={classNames(this.props.className)}
                width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.72837 0.613208C1.64579 0.547532 1.5489 0.508837 1.44826 0.501342C1.34763 0.493848 1.24711 0.51784 1.15767 0.570704C1.06823 0.623568 0.993298 0.703275 0.941042 0.801135C0.888786 0.898996 0.861213 1.01125 0.861328 1.12567L0.861328 9.87504C0.861522 9.98938 0.889358 10.1015 0.941806 10.1991C0.994255 10.2967 1.06931 10.3762 1.15879 10.4288C1.23687 10.4756 1.32386 10.5 1.41218 10.5C1.52525 10.5002 1.63565 10.4609 1.72837 10.3875L7.23688 6.01282C7.3096 5.95523 7.369 5.87856 7.41001 5.78934C7.45102 5.70012 7.47244 5.60098 7.47244 5.50036C7.47244 5.39973 7.45102 5.3006 7.41001 5.21138C7.369 5.12215 7.3096 5.04549 7.23688 4.98789L1.72837 0.613208Z" fill="#C5C5C5"/>
            </svg>;

        return res;
    }
}