import {CustomStore} from "./CustomStore";
import {action, observable} from "mobx";
import {save} from "./PermalinkDecor";
import {CheckStates} from "../helper/structs/CheckStates";
import {SearchItemStore} from "./SearchItemStore";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {GeometryUtils} from "../helper/utils/GeometryUtils";
import { cloneDeep } from "lodash-es";
import union from "@turf/union";
import { Feature, MultiPolygon, Polygon } from "geojson";

export class SearchItemGroup extends CustomStore{
    class(): string {return "SearchItemGroup";}
    first(): SearchItemStore{
        return this.items[0];
    }
    at(idx: number): SearchItemStore {
        return this.items[idx];
    }
    groupId(favMode: boolean=false): string{
        let t = this.first();
        if (t == null) return null;
        if (! favMode)
            return t.sceneId();
        return t.dayGroupId;//dayGroupId(t.feature.properties, this.copyId);
    }
    isCurrent():boolean{
        let root = this.root;
        let r = this.items.find(a => a.sceneId() == root.searchPanel.currentSceneid);
        return r != null;
    }
    @save @observable items: SearchItemStore[] = [];
    wasVisible: boolean = false;

    @save
    copyId: string = null;

    //@observable visibleInList: boolean = false;

    @action
    setSelected(value: boolean){
        this.items.forEach(a => a.selected = value);
    }
    getSelected(): CheckStates{
        let r: CheckStates = CheckStates.unchecked;
        let sum = 0;
        this.items.forEach(
            a => {
                if (a.selected) sum++;
            }
        );
        if (sum == this.items.length) r = CheckStates.checked;
        else if (sum > 0) r = CheckStates.partial;
        return r;
    }

    getSceneIds(): string[]{
        return this.items.map(a => a.sceneId());
    }

    getBbox(): BBox2d{
        let bboxAll: BBox2d = null;
        this.items.forEach((si, index) => {
                let bBox2: BBox2d = si.bbox;
                if (index == 0) bboxAll = bBox2;
                else {
                    bboxAll = GeometryUtils.unionBbox(bboxAll, bBox2);
                }
            });
        return bboxAll;
    }

    getGeometry() {
        if (this.items.length == 0)
            return null;
        let f = cloneDeep(this.items[0].feature) as Feature<Polygon | MultiPolygon>;
        for (let i = 1; i < this.items.length; i++) {
            f = union(f, this.items[i].feature as Feature<Polygon | MultiPolygon>);
        }
        return f.geometry;        
    }
}

