import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoAlignSvgProps {
    className?: string
}

export class AgroAhoAlignSvg extends React.PureComponent<IAgroAhoAlignSvgProps, undefined> {
    constructor(props: IAgroAhoAlignSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)}
                width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
            <rect width="6" height="2" rx="1" stroke="none"/>
            <rect y="4" width="8.5" height="2" rx="1" stroke="none"/>
            <rect y="8" width="11" height="2" rx="1" stroke="none"/>
        </svg>;
    }
}