import { fetchJsonGet, fetchJsonSyncGet } from "../../../helper/utils/FetchUtils";
import { Utils } from "../../../helper/utils/Utils";
import { PrecipitationCategory } from "../IndexByPointerStore";
import { IndexByPointGraphPointStore } from "../IndexByPointGraphPointStore";
import { IGraphDayValue, IndexByPointGraphStore } from "../IndexByPointGraphStore";
import { IndexByPointerSourceCustomSimpleStore } from "./IndexByPointerSourceCustomSimpleStore";
import { MeteoStationInfo } from "./MeteoStationInfo";

export class IndexByPointerSourceSimplePrecipMeteoStationStore extends IndexByPointerSourceCustomSimpleStore{
    private _meteoStation: MeteoStationInfo = null;
    public get meteoStation() : MeteoStationInfo {
        let gp = this.parent.gPoint;
        if (! this._meteoStation)
            this._meteoStation = new MeteoStationInfo(gp.point.lng, gp.point.lat);
        return this._meteoStation;
    }
    
    protected getRequest(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return `/api/meteo/station/${this.meteoStation.station_id}/prec?from_date=${Utils.getDateStr(
            dateBegin
        )}&to_date=${Utils.getDateStr(dateEnd)}&lon=${
            gp.point.lng
        }&lat=${gp.point.lat}`;
    }
    protected getValue(json: any): IGraphDayValue{
        let j: {date: string, prec: number} = json;
        return this.getGraphDataValue(j.date, j.prec);
    }
    protected getGraphDataInternal(): IGraphDayValue[]{
        return this.getData;
    }

    private get getData(): IGraphDayValue[]{
        let src: IGraphDayValue[];
        if (this.status == null) setImmediate(()=> { this.load() });
        src = this.data;
        if (this.parent.parent.precipitationCategory == PrecipitationCategory.daily) return src;
        if (this.parent.parent.precipitationCategory == PrecipitationCategory.accumulated) return this.accumGraphDayValue(src, 0);
        return [];
    }

}