export class AgroExpUtils {

    static async delay(ms: number){

        return new Promise(resolve => {
            setTimeout(() => { resolve('') }, ms);
        });
    }

    static async wait(f: ()=>any, debugMsg?: string, steps?: number, ms?: number){
        if (await f()) return f();
        for (let i=0;i<(steps || 25);i++) {
            await this.delay(ms || 300);
            if (debugMsg) console.log('iter wait', i,  (new Date()).toISOString(), debugMsg);
            if (await f()) break;
        }
        return f();
    }

    static html2el(html: string){
        let div = document.createElement('div');
        div.innerHTML = html;
        return div.firstChild;
    }

    static cp(obj:any){
        if (typeof obj == 'object') return JSON.parse(JSON.stringify(obj))
        else return obj;
    }

}