import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {computed, observable} from "mobx";
import {IResponseFieldProperties} from "./AgroStore";
import {Geometry} from "geojson";
import {GeometryUtils, SimpleGeometryType} from "../../helper/utils/GeometryUtils";
import length from "@turf/length";
import {Browser} from "leaflet";
import retina = Browser.retina;

export interface IA2FieldTitle{
    title: string;
    field: A2FieldStore;
}

export class A2FieldStore extends CustomStore {
    class(): string {
        return "A2FieldStore";
    }

    //@save
    @observable
    id: number;

    get name(): string {
        return this.properties[this.root.agro2.projectInfo.fieldName_columnName];
    }

    get area(): number{
        return this.properties[this.root.agro2.projectInfo.fieldName_area_sys];
    }

    //@save
    @observable
    geometry: Geometry;

    //@save
    @observable
    properties: IResponseFieldProperties;

    @observable
    flash: boolean;
    @observable
    expanded: boolean = false;

    @computed
    get simpleGeometryTypeNullable(): SimpleGeometryType{
        let geom = this.geometry;
        if (geom != null){
            let sg = GeometryUtils.getSimpleGeometries(geom);
            let types = GeometryUtils.getSimpleTypes(sg);
            if (types.find(a => a == SimpleGeometryType.Point)) return SimpleGeometryType.Point;
            if (types.find(a => a == SimpleGeometryType.Line)) return SimpleGeometryType.Line;
            if (types.find(a => a == SimpleGeometryType.Polygon)) return SimpleGeometryType.Polygon;
        }
        return null;
    }

    @computed
    get geometriesCount(): number{
        let geom = this.geometry;
        if (geom == null) return 0;
        let sg = GeometryUtils.getSimpleGeometries(geom);
        let countGeometries = 0;
        sg.forEach(g => {
            sg.forEach(a => {
                if (a.simple == SimpleGeometryType.Point) countGeometries += a.contour.length;
                if (a.simple == SimpleGeometryType.Line || a.simple == SimpleGeometryType.Polygon) countGeometries++;
            });
        });
        return countGeometries;
    }

    @computed
    get lengthLines(): number{
        let geom = this.geometry;
        if (geom == null) return 0;
        let lengthGeom = 0;
        let sg = GeometryUtils.getSimpleGeometries(geom);
        let types = GeometryUtils.getSimpleTypes(sg);
        sg.filter(a => a.simple == SimpleGeometryType.Line).forEach(a => {
            lengthGeom += length({
                "type": "Feature",
                "properties":{},
                "geometry": GeometryUtils.createGeometryBySimpleGeometry([a])
            }, {units: 'meters'});
        });
        return lengthGeom;
    }
}