// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotSelectedFieldsComp-list-main {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.NotSelectedFieldsComp-img-main {
  margin-top: 53px;
  margin-bottom: 20px;
}

.NotSelectedFieldsComp-arrowSvg-main {
  align-self: flex-start;
}

.NotSelectedFieldsComp-divLine-cancel {
  margin-top: 10px;
  margin-bottom: 20px;
}

.NotSelectedFieldsComp-divLine-main {
  box-sizing: border-box;
  height: 1px;
  border-bottom: 1px solid #22282F;
}

.NotSelectedFieldsComp-leftRight-m {
  margin-left: 15px;
  margin-right: 15px;
}

.NotSelectedFieldsComp-footer-margin {
  align-self: stretch;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/A2Common/NotSelectedFieldsComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wBAAA;EACA,mBAAA;EAEA,eAAA;EACA,iBAAA;EACA,gBAAA;AAAF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AACA;EACE,sBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,mBAAA;AAGF;;AADA;EACE,sBAAA;EACA,WAAA;EACA,gCAAA;AAIF;;AAFA;EACE,iBAAA;EACA,kBAAA;AAKF;;AAHA;EACE,mBAAA;EACA,mBAAA;AAMF","sourcesContent":[".NotSelectedFieldsComp-list-main{\r\n  display: flex;\r\n  flex-flow: column nowrap;\r\n  align-items: center;\r\n\r\n  font-size: 16px;\r\n  line-height: 22px;\r\n  font-weight: 400;\r\n}\r\n.NotSelectedFieldsComp-img-main{\r\n  margin-top: 53px;\r\n  margin-bottom: 20px;\r\n}\r\n.NotSelectedFieldsComp-arrowSvg-main{\r\n  align-self: flex-start;\r\n}\r\n.NotSelectedFieldsComp-divLine-cancel{\r\n  margin-top: 10px;\r\n  margin-bottom: 20px;\r\n}\r\n.NotSelectedFieldsComp-divLine-main{\r\n  box-sizing: border-box;\r\n  height: 1px;\r\n  border-bottom: 1px solid #22282F;\r\n}\r\n.NotSelectedFieldsComp-leftRight-m{\r\n  margin-left: 15px;\r\n  margin-right: 15px;\r\n}\r\n.NotSelectedFieldsComp-footer-margin{\r\n  align-self: stretch;\r\n  margin-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
