import {CustomStore} from "../CustomStore";
import {SoilGridsGroupStore} from "./SoilGridsGroupStore";
import {ISoilGrids, ISoilGridsGroup, ISoilGridsSource, ISoilGridsSourceLayer} from "../config/ConfigStore";
import {SoilGridsSourceStore} from "./SoilGridsSourceStore";
import {SoilGridsLayerStore} from "./SoilGridsLayerStore";
import {action, computed} from "mobx";
import {Utils} from "../../helper/utils/Utils";
import {isArray} from "lodash-es";

export class SoilGridsStore extends CustomStore{
    active: boolean = false;
    groups: SoilGridsGroupStore[] =[];
    baseUrl: string;

    @action
    setCheckSource(src: SoilGridsSourceStore){
        this.groups.forEach(a =>{
            a.sources.forEach(b => {
                b.checked = (b == src);
                if (!b.checked && b.extend){
                    b.extend = false;
                }
            });
        });
    }

    @action
    setExpandSource(src: SoilGridsSourceStore, value: boolean){
        this.groups.forEach(a =>{
            a.sources.forEach(b => {
                if (b == src) b.extend = value; else b.extend = false;
            });
        });
    }

    @computed
    get selectedSource(): SoilGridsSourceStore{
        let r: SoilGridsSourceStore = null;
        this.groups.forEach(a =>{
            a.sources.forEach(b => {
                if (b.checked) r = b;
            });
        });
        return r;
    }

    loadFromConfig(cfg: ISoilGrids){
        this.groups = [];
        if (cfg == null) return;
        if (cfg.groups == null) return;
        this.active = true;
        this.baseUrl = cfg.baseUrl;
        cfg.groups.forEach(a =>{
            let grp = new SoilGridsGroupStore(this);
            this.loadGroup(grp, a);
            this.groups.push(grp);
        });

    }
    private loadGroup(grp: SoilGridsGroupStore, cfg: ISoilGridsGroup){
        grp.sources = [];
        grp.title= cfg.titleGroup;
        cfg.sources.forEach(a => {
            let t = new SoilGridsSourceStore(grp);
            this.loadSource(t, a);
            grp.sources.push(t);
        });
    }
    private loadSource(grp: SoilGridsSourceStore, cfg: ISoilGridsSource){
        grp.layers = [];
        grp.sources_title= cfg.sources_title;
        grp.pallete = cfg.pallete;
        grp.minValue = cfg.minValue;
        grp.maxValue = cfg.maxValue;
        if (isArray(cfg.classes) && cfg.classes.length > 0){
            grp.classes = cfg.classes;
        }
        grp.units_legend = cfg.units_legend;
        let ths = this;
        cfg.layers.forEach(a => {
            let t = new SoilGridsLayerStore(grp);
            loadLayer(t, a);
            grp.layers.push(t);
        });
        function loadLayer(grp2: SoilGridsLayerStore, cfg2: ISoilGridsSourceLayer){
            grp2.title = cfg2.title;
            if (Utils.isStringNotEmpty(cfg2.tiles)){
                grp2.tiles = cfg2.tiles;
                return;
            }
            let pal:any = [];
            let step = (grp.maxValue - grp.minValue)/ (grp.pallete.length - 1);
            let q = grp.minValue;
            grp.pallete.forEach(a =>{
                pal.push([q, a]);
                q += step;
            });
            let st = `{"interpolation":"linear","palette":${JSON.stringify(pal)}}`;
            grp2.tiles = ths.baseUrl + `?product_id=${cfg.short_name}_${cfg2.title}cm_mean&z={z}&x={x}&y={y}&style=${encodeURIComponent(st)}`;
        }
    }
}