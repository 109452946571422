import './LogView.scss';
import {observer} from "mobx-react";
import * as React from "react";
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {LogViewStore} from "../../../../store/user/LogView/LogViewStore";
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../../Common/ContextMenuCommonComp";
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {action, observable} from "mobx";
import {LogViewRowComp} from "./LogViewRowComp";
import {LogView_DownFiltersComp} from "./LogView_DownFiltersComp";
import {AdminPanel_FilterBadgetComp} from "../AdminPanel/AdminPanel_FilterBadgetComp";
import {ScrollSaveComp} from "../../../Common/ScrollSaveComp";

export interface ILogView extends IStoreProps{
    logViewStore: LogViewStore;
}
@observer
export class LogView extends React.Component<ILogView, undefined> {
    constructor(props: ILogView) {
        super(props);
        autoBindReact(this);
    }

    @observable
    scrollPos: number = 0;


    @observable
    filtersDropDown: boolean = false;
    @action
    onClose(){
        this.props.logViewStore.closeLogView();
    }
    @action
    onClickFilterDropDown(){
        this.filtersDropDown = true;
    }
    @action
    async onCloseFilterDropDown(){
        this.filtersDropDown = false;
    }
    @action
    async onDeleteUserBadget(){
        this.props.logViewStore.filters.filter_userId = null;
    }
    @action
    async onDeleteOperBadget(){
        this.props.logViewStore.filters.filter_operation = null;
    }
    @action
    async onDeleteDateBadget(){
        this.props.logViewStore.filters.interval.end = null;
        this.props.logViewStore.filters.interval.begin = null;
    }

    @action
    onClickExport(){
        this.props.logViewStore.exportLog();
    }
    @action
    onScroll(pos: number, max: number){
        this.scrollPos = pos;
        let store = this.props.store;
        let lvs = store.user.logView;
        if ((max - pos) < 100 && lvs.logLoadStatus == LoadStatus.ready && !lvs.allEventsLoaded)
            lvs.nextLogEvents();
    }
    render() {
        let store = this.props.store;
        let lvs = store.user.logView;
        let items: any[] = [];

        lvs.logEvents.forEach(u => {
            items.push(<LogViewRowComp store={store} logEvent={u} key={"logview"+u.action_id.toString()} />);
        });

        let filterBadgets: any[] = [];

        if (lvs.filters.filter_userId != null) filterBadgets.push(<AdminPanel_FilterBadgetComp store={store} text={lvs.filters.filter_userBadgetText} key="user"
                                                                                               onClose={this.onDeleteUserBadget} />);
        if (lvs.filters.filter_operation != null)
            filterBadgets.push(<AdminPanel_FilterBadgetComp store={store} text={lvs.getTitleAction(lvs.filters.filter_operation)} key="oper"
                                                                                                  onClose={this.onDeleteOperBadget} />);
        if (lvs.filters.interval.isValidBegin() || lvs.filters.interval.isValidEnd()) {
            let txt = "";
            if (lvs.filters.interval.isValidBegin()) txt += lvs.filters.interval.begin.toLocaleString("default", {"year":"numeric", "month":"2-digit",
                "day":"2-digit", "hour":"2-digit", "minute":"2-digit"});
            txt += " - ";
            if (lvs.filters.interval.isValidEnd()) txt += lvs.filters.interval.end.toLocaleString("default", {"year":"numeric", "month":"2-digit",
                "day":"2-digit", "hour":"2-digit", "minute":"2-digit"});
            filterBadgets.push(<AdminPanel_FilterBadgetComp store={store} text={txt} onClose={this.onDeleteDateBadget} key="date" />);
        }


        return <div className="modal-back">
            <div className="modal-full modal-full-frame modal-with-header LogView-window">
                <div className="LogView-header">
                    <div className="AdminPanelComp-headerTitle">{store.trans["History of user actions"]}</div>

                    <div className="text-next-2 AdminPanelComp-headerFilterButton white_svg blue_svg_onhover blue-border-onhover" onClick={this.onClickFilterDropDown}>
                        <svg width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 0.5V3.5L6.5 7.5V15.5L9.5 13.5V7.5L15.5 3.5V0.5H0.5Z" fill="none"
                                  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {lvs.filters.countFilters() > 0 && <div className="AdminPanelComp-headerFilterButtonCounter">
                            {lvs.filters.countFilters()}
                        </div>}
                        {this.filtersDropDown && <ContextMenuCommonComp  className="" onClose={this.onCloseFilterDropDown} indentHorizontal={5}
                                                                         indentVertical={5} popupCorner={PopupCorner.rightTop} direction={PopupDirection.vertical}>
                            <LogView_DownFiltersComp store={store} />
                        </ContextMenuCommonComp>}
                    </div>

                    {lvs.logLoadStatus == LoadStatus.loading && <div className="header-text text-white2 text-next-2">{store.trans["Loading..."]}</div>}
                    {filterBadgets}
                    <div className="modal-button-close" onClick={this.onClose}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z"
                                fill="#C5C5C5"/>
                        </svg>
                    </div>
                </div>
                <div className="modal-body flex-rows flex-stretch-item">
                    {
                        <React.Fragment>
                            <div className="journal-body-div journal-body-div1 table-scroll style-4 text-white journal-text bold admin-div">
                                <table className="table1-head AdminPanelComp-tableHeader">
                                    <colgroup>
                                        <col className="LogView-table-col-first" />
                                        <col className="LogView-table-col-date"/>
                                        <col className="LogView-table-col-user"/>
                                        <col className="LogView-table-col-email"/>
                                        <col className="LogView-table-col-action"/>
                                        <col className="LogView-table-col-end"/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                        <td>{store.trans["Date / time"]}</td>
                                        <td>{store.trans.User}</td>
                                        <td>{store.trans.Email}</td>
                                        <td>{store.trans.Action}</td>
                                        <td>
                                            <div className="flex-columns-centerByVertical" style={{justifyContent: "flex-end"}}>
                                                <div className="LogView-table-exportButton" onClick={this.onClickExport}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_21_2086)">
                                                            <path d="M8 0.5V11.5" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M3.5 7L8 11.5L12.5 7" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M0.5 15.5H15.5" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_21_2086">
                                                                <rect width="16" height="16" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ScrollSaveComp scrollTop={this.scrollPos} onScroll={this.onScroll}
                                            className="journal-body-div journal-body-div2 flex-fit-item table-scroll style-4 text-white journal-text admin-div">
                                {items}
                            </ScrollSaveComp>
                        </React.Fragment>}
                </div>
            </div>
        </div>;
    }
}