// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoSpinnerComp-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.AgroAhoSpinnerComp-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  height: 24px;
  background: #22282F;
}

.AgroAhoSpinnerComp-path {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #6B707B;
}

.AgroAhoSpinnerComp-cover {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/AgroAhoSpinnerComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;AAEF;;AAAA;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAGF;;AADA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAIF","sourcesContent":[".AgroAhoSpinnerComp-main {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex-grow: 1;\r\n}\r\n.AgroAhoSpinnerComp-title {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding-left: 15px;\r\n  height: 24px;\r\n  background: #22282F;\r\n}\r\n.AgroAhoSpinnerComp-path {\r\n  font-family: Open Sans;\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 11px;\r\n  color: #6B707B;\r\n}\r\n.AgroAhoSpinnerComp-cover {\r\n  flex-grow: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
