import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {ProductSettingStore} from "../../../store/productSetting/ProductSettingStore";
import {observer} from "mobx-react";
import * as React from "react";
import autoBindReact from "auto-bind/react";
import {LegendCommonComp} from "./LegendCommonComp";
import {LegendDiscreteComp} from "./LegendDiscreteComp";
import {IDiscreteInterval} from "../../../store/productSetting/IndexBandProductStore";
import {LegendLinearComp} from "./LegendLinearComp";
import {ra} from "../../../helper/utils/mobxUtils";
import {Utils} from "../../../helper/utils/Utils";
import {LegendAreaMode} from "../../../store/productSetting/LegendAreaStore";
import { SceneUiType } from "../../../store/SearchStore";
import { ActiveProductCodeStore } from "../../../store/productSetting/ActiveProductCodeStore";

export interface ILegendDiscrete extends IStoreProps{
    prod: ActiveProductCodeStore;
}
@observer
export class LegendDiscrete extends React.Component<ILegendDiscrete, undefined> {
    constructor(props: ILegendDiscrete) {
        super(props);
        autoBindReact(this);
    }
    render(){
        let store = this.props.store;
        let prod = this.props.prod;
        let title = this.props.prod.currentProductStore.productConfig.abbreviation;
        let currentProductStore = this.props.prod;
        let ib = currentProductStore.currentProductStore.indexBand;
        let dia:IDiscreteInterval[];
        let sp = store.searchPanel;
        dia = ib.getDiscreteClassValuesValidsInterval();


        let numAreas = [...store.map.productInfo.legendAreaStore.areas];
        let areaMode = store.map.productInfo.legendAreaStore.mode;

        function click2(idx: number) {
            ra(()=>{
                if (idx >= ib.classValues2.length) ib.lastClassVisible = !ib.lastClassVisible;
                else {
                    ib.classValues2[idx].visible = !ib.classValues2[idx].visible;
                }
                if (store.searchPanel.top_sceneType == SceneUiType.favorite)
                    store.map.productInfo.activeProductsSet.saveGroupProductAndRefresh(store.searchPanel.currentGroup.groupId(true), prod);
            });
        }
        dia = dia.reverse();

        return <LegendDiscreteComp store={store} title={title} intervals={dia}
                                   areaMode={areaMode} numAreas={numAreas} onClickCheckBox={click2}

        />;
    }
}