import {ObservableCustomStore} from "../CustomStore";
import {action, computed, observable, reaction, runInAction} from "mobx";
import {IProjectInfo, IProjectsProjResponse, IProjectsResponse, IProjectTableColumnType} from "../user/UserStore";
import {OrderByType} from "../../const/OrderByType";
import {Utils} from "../../helper/utils/Utils";
import {FilterProjectsType} from "./AgroStore";
import {A2NavigatorSection} from "./A2AgroStore";
import {fetchJson, fetchJsonGet} from "../../helper/utils/FetchUtils";
import {ProjectRole} from "./A2PermissionStore";
import {A2ColumnItemStore, A2ColumnType} from "./A2ColumnItemStore";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {ra} from "../../helper/utils/mobxUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {TranslateUtils} from "../../helper/lang/TranslateUtils";
import {Lang} from "../../../pluginApi/store/Lang";
import {isString} from "lodash-es";
import JSON5 from 'json5';


export class A2ProjectListStore extends ObservableCustomStore{
    //@save
    @observable
    projects: IProjectsProjResponse[] = [];
    @observable
    filter_projects: string = "";
    @observable
    filter_projects_type: FilterProjectsType = FilterProjectsType.byDate;
    @observable
    filter_projects_direction: OrderByType = OrderByType.DESC;

    @observable
    agroButtonPopupVisible: boolean = false;
    @observable
    projectListLoadStatus: LoadStatus = null;

    subscription(): IReactionDisposer[] {
        return [
            reaction(()=>{
                return {navigatorSection: this.root.agro2.navigatorSection};
            },async ()=>{
                if (this.root.agro2.navigatorSection == A2NavigatorSection.projects && this.projectListLoadStatus != LoadStatus.loading){
                    try {
                        await this.projectListRequest();
                    }catch (e) {
                        this.root.addError(e);
                    }
                }
            })
        ];
    }

    @computed
    get isMultiProject(){
        return this.projects.length > 1 || this.root.user.roles.find(a => a == "admin" || a == "agro_proj_admin");
    }

    @computed
    get projectsFiltered(): IProjectsProjResponse[]{
        let arr = this.projects.slice();
        arr = arr.filter((p)=>{
            if (this.root.agro2.projectInfo.projectTypeFilters.length > 0){
                return this.root.agro2.projectInfo.projectTypeFilters.indexOf(p.proj_type) >= 0;
            }else return true;
        });
        if (this.filter_projects_type == FilterProjectsType.byDate){
            arr = arr.sort((a,b)=>{
                if (this.filter_projects_direction == OrderByType.DESC)
                    return ((new Date(b.upd_time)).getTime() - (new Date(a.upd_time)).getTime());
                else
                    return ((new Date(a.upd_time)).getTime() - (new Date(b.upd_time)).getTime());
            });
        }
        if (this.filter_projects_type == FilterProjectsType.byName){
            arr = arr.sort((a,b)=>{
                if (this.filter_projects_direction == OrderByType.DESC)
                    return Utils.compareSortString(b.proj_title, a.proj_title);
                else
                    return Utils.compareSortString(a.proj_title, b.proj_title);
            });
        }
        return arr.filter(a => {
            if (a.proj_title.toLocaleLowerCase().includes(this.filter_projects.toLocaleLowerCase())) return true;
            if (a.proj_owner.email.toLocaleLowerCase().includes(this.filter_projects.toLocaleLowerCase())) return true;
            if (this.getUserName(a).toLocaleLowerCase().includes(this.filter_projects.toLocaleLowerCase())) return true;
            return false;
        });
    }
    getUserName(proj: IProjectsProjResponse){
        return TranslateUtils.getUserFullName(proj.proj_owner.surname, proj.proj_owner.given_name);
    }

    @action
    initProjectIfSingle(){
        let store = this.root;
        if (store.agro2.projectList.projects.length != 1) return;
        if (store.agro2.navigatorSection == A2NavigatorSection.projects){
            store.agro2.projectStruct.initFirstPage();
            store.agro2.projectStruct.navigatorRootItem.activePage = true;
            store.agro2.navigatorSection = A2NavigatorSection.folder;
        }
    }

    projectListRequest(): Promise<void>{
        this.projectListLoadStatus = LoadStatus.loading;

        return fetchJson("/api/projects").then((json: IProjectsResponse) => {
            return runInAction(() => {
                this.projectListLoadStatus = LoadStatus.ready;
                this.root.user.can_create_project = json.can_create_project == 1;
                this.root.agro2.projectList.projects = json.projects;
                if (this.root.agro.projectName == null) {
                    if (this.root.agro2.projectList.projects.length == 1) {
                        let pjName = this.root.agro2.projectList.projects[0].proj_name;
                        return this.root.agro2.projectList.projectInfoRequest(pjName);
                    } else {
                        return Promise.resolve();
                    }
                }else return Promise.resolve();
            });
        }).catch(err=> {
            ra(()=>{
                this.projectListLoadStatus = LoadStatus.ready;
                this.root.addError(err);
            });
        });
    }

    @action
    projectInfoRequest(projName: string): Promise<void> {
        this.root.agro2.projectStruct.loadProjectStatus = LoadStatus.loading;
        
        return Promise.all([
            fetch("/config.json?rnd=" + Math.random()) 
                .then(response => response.text())
                .then(text => {
                    let jsonText = text.split('{{urlOrigin}}').join(window.location.origin);
                    return JSON5.parse(jsonText); 
                }),
            fetchJson(`/api/projects/${encodeURIComponent(projName)}/info`) 
        ])
        .then(([config, json]: [any, { project: IProjectInfo }]) => {
            runInAction(async () => {
                try {
                    let agro = this.root.agro;
                    let store = this.root;
                    let changeProject = false;

                    this.root.agro2.projectInfo.projectInfoJson = json.project;
                    this.root.agro.datasetPhoto = json.project?.type_info?.type_schema?.photo?.view?.dataset?.ds_code;
    
                    let p = json.project;
                    let defaultLayers = config.map_layers.overlays;  
                    if (p.proj_name && Object.keys(p.proj_layers).length ) {
                            this.root.config.map_layers.overlays = p.proj_layers; 
                            this.root.map.overlays.init();
                        } else {
                            this.root.config.map_layers.overlays = defaultLayers;
                            this.root.map.overlays.init();
                    }
    
                    if (agro.projectName != p.proj_name) changeProject = true;
                    agro.projectName = p.proj_name;
    
                    if (p.perm) {
                        this.root.agro2.permission.role = p.perm as ProjectRole;
                    } else {
                        this.root.agro2.permission.role = this.root.agro2.permission.defaultRole;
                    }
    
                    agro.projectTitle = p.proj_title;
                    agro.projectStat = p.stat;
    
                    this.root.agro2.projectInfo.fieldName_columnName = p.type_info.type_schema.obj.view.dataset.name_column;
                    this.root.agro2.projectInfo.fieldName_id = p.type_info.type_schema.obj.view.dataset.id_column;
    
                    if (p.proj_settings.obj_styles != null) {
                        if (isString(p.proj_settings.obj_styles)) {
                            this.root.agro2.styles.styleTextApply(p.proj_settings.obj_styles);
                        } else {
                            let s = JSON.stringify(p.proj_settings.obj_styles, null, 2);
                            this.root.agro2.styles.styleTextApply(s);
                        }
                    }
    
                    this.initColumns();
                    this.root.user.initReferences();
    
                    store.agro2.projectStruct.loadProjectStatus = LoadStatus.ready;
                    if (changeProject) {
                        store.agro2.projectStruct.initFirstPage();
                        store.agro2.projectStruct.navigatorRootItem.activePage = true;
                        store.agro2.navigatorSection = A2NavigatorSection.folder;
                    }
                    store.events.onChangeProject.call();
                    store.pluginsApi.onChangeProject();
                    await store.agro2.projectList.setDefaultActiveArea();
                } catch (e: any) {
                    this.root.addError(e);
                }
            });
        })
        .catch(e => {
            ra(() => {
                this.root.agro2.projectStruct.loadProjectStatus = LoadStatus.ready;
            });
            throw e;
        });
    } 

    @action
    async loadProject(proj_name: string){
        let store = this.root;
        ra(()=>{
            store.agro2.projectStruct.loadProjectStatus = LoadStatus.loading;
        });
        await store.agro2.projectList.projectInfoRequest(proj_name);
        ra(() => {
            store.agro2.projectStruct.initFirstPage();
            store.agro2.projectStruct.navigatorRootItem.activePage = true;
            store.agro2.navigatorSection = A2NavigatorSection.folder;
            store.agro2.projectList.filter_projects = "";
        });
    }

    @action
    async setDefaultActiveArea(){
        let store = this.root;
        let def_active_area: any[] = null;
        if (store.map.searchObject.user_defined && !store.map.searchObject.isEmpty || !store.agro2.projectInfo.projectInfoJson) return;
        let js = store.agro2.projectInfo.projectInfoJson.proj_settings.default_active_area;
        if (Utils.isStringNotEmpty(js)){
            def_active_area = await fetchJsonGet("/api/jsonget", {id: js});
        }
        ra(()=>{
            if (def_active_area != null) {
                store.map.searchObject.content = {
                    'type': 'FeatureCollection',
                    'features': [{
                        type: "Feature",
                        properties: {},
                        geometry: <any>def_active_area[0].data.geometry
                    }]
                };
                store.map.searchObject.user_defined = false;
                if (! store.initialPermalink)
                    store.map.zoomToSearchObject();
            }else{
                store.map.searchObject.content = null;
                store.map.searchObject.user_defined = false;
            }
        });
    }
    @action
    initColumns(){
        let pi = this.root.agro2.projectInfo.projectInfoJson;
        this.root.agro2.projectStruct.columns_items = [];
        this.root.agro2.projectStruct.structIds = [...pi.proj_settings.groups];
        let tmpArr: A2ColumnItemStore[] = [];
        pi.type_info.type_schema.obj.view.columns.forEach(c => {
            let t = new A2ColumnItemStore(this.root.agro2);
            t.column_name = c.name;
            if (c.is_req == 1) t.required = true;
            for(let l in c.title){
                t.title_store[l as string] = c.title[l as Lang];
            }
            t.order = c.ord??0;
            if (c.show == 1) t.show = true;
            if (c.show == 0) t.show = false;

            if (c.is_fk === 1){
                let ref_group = c.ref_group;
                if (ref_group == null) throw `ref_group is not found for column '${c.name}'`;
                let ref_name = c.ref?.name;
                if (ref_name == null) throw `Ref name is not found for column '${c.name}'`;
                t.column_type = A2ColumnType.reference;
                let ref = pi.type_info.type_schema.refs.find(r => r.name == ref_name);
                if (ref == null) throw `Ref '${ref_name}' is not found for column '${c.name}'`;

                let ref_main_column = ref.columns.find(c => c.is_main_name == 1);
                if (ref_main_column == null) throw `Main column not found for ref '${ref_name}'`;
                let ref_pk = ref.columns.find(c => c.is_pk == 1);
                if (ref_pk == null) throw `PK column not found for ref '${ref_name}'`;

                t.reference_group_name = ref_group;
                t.reference_primary_column = ref_pk.name;
                t.reference_value_column = ref_main_column.name;//r.ref.col;

                let r = pi.type_info.type_schema.obj.view.columns.find(a => a.ref?.name == ref_name && a.ref?.col == ref_main_column.name && a.ref_group == ref_group);
                if (r == null) throw `View column not found with ref.name=${ref_name} and ref.col=${ref_main_column.name}`;
                //проблемы с поиском farm
                t.column_nameString = r.name;
                t.reference_name = ref_name;
            }
            else if (c.type == IProjectTableColumnType.str) t.column_type = A2ColumnType.string;
            else if (c.type == IProjectTableColumnType.date) t.column_type = A2ColumnType.date;
            else if (c.type == IProjectTableColumnType.int) t.column_type = A2ColumnType.number;
            else if (c.type == IProjectTableColumnType.float) t.column_type = A2ColumnType.number;
            else throw "Неизвесный тип колонки";
            if (c.readonly === 1) t.readOnly = true;
            if (c.ref && c.is_fk !== 1) t.readOnly = true;

            tmpArr.push(t);
        });
        this.root.agro2.projectStruct.columns_items = tmpArr.sort((a,b) => a.order - b.order);
    }


}