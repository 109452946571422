import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoMapDebugComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";
import {AgroAhoDelSvg} from "../../AgroAhoPanel/icons/AgroAhoDelSvg";
import {AgroAhoRefreshSvg} from "../../AgroAhoPanel/icons/AgroAhoRefreshSvg";
import {AgroAhoMapDebugStore} from "./agroAhoMapDebug";
import {AgroAhoMapDebugItemComp} from "./AgroAhoMapDebugItemComp";

@observer
export class AgroAhoMapDebugComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
        this.dstore = this.props.store.agroAhoStore.mapStore.mapDebugStore;
    }

    dstore: AgroAhoMapDebugStore;

    minClick(){
        this.dstore.min = !this.dstore.min;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let dstore = store_.mapStore.mapDebugStore;
        let curName = dstore.curLayer?.id;
        let arr = dstore.layers.map(l=><AgroAhoMapDebugItemComp key={l.id} store={store} layer={l}/>);

        return <div className={classNames("AgroAhoMapDebugComp-main",{
                "AgroAhoMapDebugComp-min": dstore.min})}>
            <div className="AgroAhoMapDebugComp-titleCover">
                <div className="AgroAhoMapDebugComp-title" onClick={this.minClick}>Debug</div>
                <div className="AgroAhoMapDebugComp-titleButtons">
                    <div className="AgroAhoMapDebugComp-refreshCover" onClick={this.dstore.doRefresh}>
                        <AgroAhoRefreshSvg className="AgroAhoMapDebugComp-refresh"/>
                    </div>
                    <div className="AgroAhoMapDebugComp-btnTest" onClick={this.dstore.doTest}>Test</div>
                </div>
            </div>
            <div className="AgroAhoMapDebugComp-mainCover">
                <div className="AgroAhoMapDebugComp-curLayerCover">
                    <div className="AgroAhoMapDebugComp-curLayerName">{curName}</div>
                    <div className="AgroAhoMapDebugComp-delCover" onClick={this.dstore.onDelLayer}>
                        <AgroAhoDelSvg className="AgroAhoMapDebugComp-delLayer"/>
                    </div>
                </div>
                <div className="AgroAhoMapDebugComp-layerList style-4">
                    {arr}
                </div>
            </div>
        </div>
    }
}
