import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import "./AgroProjectSecurityComp.scss";
import {action, computed, observable} from 'mobx';
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../../icons/LoadingAniSvg";
import {ISecurityInfo} from "../../../../../store/agro/A2ProjectEditorStore";
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {ProjectRole} from "../../../../../store/agro/A2PermissionStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {AgroProjectNewUserComp} from "./AgroProjectNewUserComp";
import {AgroProjectSecurityRowComp} from "./AgroProjectSecurityRowComp";
import {Utils} from "../../../../../helper/utils/Utils";
import classNames from "classnames";

interface IAgroProjectSecurityComp extends IStoreProps{
    showTitle?: boolean; //default=true
    isForm?: boolean;//default=true
    bodyClassName?: string;
}
@observer
export class AgroProjectSecurityComp extends React.Component<IAgroProjectSecurityComp, undefined> {
    constructor(props: IAgroProjectSecurityComp) {
        super(props);
        autoBindReact(this);
    }
    @observable
    showNewUser: boolean = false;
    @observable
    newUserName: string = "";
    @observable
    newUserRole: ProjectRole = ProjectRole.view;

    componentDidMount() {
        try {
            this.props.store.agro2.projectEditor.loadUserSecurityCatch();
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    clickAddUser(){
        this.newUserName = "";
        this.showNewUser = true;
    }
    @action
    onChangeNewUserRole(newValue: ProjectRole){
        this.newUserRole = newValue;
    }
    @action
    onChangeNewUserTitle(text: string){
        this.newUserName = text;
    }
    @action
    onCancelNewUser(){
        this.showNewUser = false;
    }
    @action
    async onOkNewUser(){
        this.showNewUser = false;
        let store = this.props.store;
        try {
            let user_id = await store.agro2.projectEditor.getUserIdByEmail(this.newUserName);
            await store.agro2.projectEditor.sendUpdatePerm(user_id, this.newUserRole);
            await store.agro2.projectEditor.loadUserSecurityCatch();
            store.addInfo("Пользователь добавлен");
        }catch (e){
            store.addError(e);
        }
    }

    getHeaderComp(){
        let store = this.props.store;
        let projEditor = store.agro2.projectEditor;
        //let store = this.props.store;
        return <div className="AgroProjectSecurityComp-header">
            <div className="AgroProjectSecurityComp-title flex-stretch-item flex-columns-centerByVertical">
                {store.trans["User access privileges"]}
            </div>
            <div className="flex-columns-centerByVertical">
                <div className="flex-columns-centerByVertical pointer white_svg blue_svg_onhover AgroProjectSecurityComp-close" onClick={this.onCancel}>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" stroke="none"/>
                    </svg>
                </div>
            </div>
        </div>;
    }
    getFooterComp(){
        let store = this.props.store;
        return <div className="AgroProjectSecurityComp-footer">
            <button className="left-sidebar-active button-jump Agro2Common-Form-footerButton"  onClick={this.clickAddUser}>
                <svg width="15" height="16" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 10.5H5.5C4.17392 10.5 2.90215 11.0268 1.96447 11.9645C1.02678 12.9021 0.5 14.1739 0.5 15.5H9.5" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 7.5C8.933 7.5 10.5 5.933 10.5 4C10.5 2.067 8.933 0.5 7 0.5C5.067 0.5 3.5 2.067 3.5 4C3.5 5.933 5.067 7.5 7 7.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.5 10.5V14.5" stroke="#2B3138" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5 12.5H14.5" stroke="#2B3138" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="text-next">{store.trans["Add user"]}</span>
            </button>
        </div>;
    }

    @action
    onCancel(){
        this.props.store.agro2.projectEditor.showSecurityForm = false;
    }


    isOwner(securityInfo: ISecurityInfo): boolean{
        let store = this.props.store;
        return store.agro2.projectEditor?.projectInfo?.proj_owner?.user_id == securityInfo?.user_id;
    }

    onChangeFilter(e: any){
        let store = this.props.store;
        let txt = e.currentTarget.value;
        store.agro2.projectEditor.searchString = txt;
    }
    @action
    onClearFilter(){
        let store = this.props.store;
        store.agro2.projectEditor.searchString = "";
    }

    getSearchPanel(): any{
        let store = this.props.store;
        return <div className="AgroProjectSecurityComp-headerFilter">
            <div className="relative">
                <input type="text" style={{margin: ""}}
                       className={classNames("text-box-editor AdminPanelComp-headerFilterInput")}
                       value={store.agro2.projectEditor.searchString}
                       onChange={this.onChangeFilter}
                       placeholder={store.trans["Search by name or email"]}/>
                <div className={classNames("AdminPanelComp-headerFilterInputClose white_svg blue_svg_onhover", {"opacity30": store.agro2.projectEditor.searchString == ""})}
                     onClick={this.onClearFilter}>
                    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z"
                            stroke="none"/>
                    </svg>
                </div>
            </div>
            <div className="AdminPanelComp-totalUsers">{store.trans["Users"]}: {store.agro2.projectEditor.filtered_proj_users.length}</div>
        </div>;
    }

    render() {
        let store = this.props.store;
        let projEditor = store.agro2.projectEditor;

        let items: any[] = [];
        if (projEditor.proj_users_load == LoadStatus.loading || projEditor.proj_users_load == LoadStatus.ready) {
            let users = projEditor.filtered_proj_users.slice();
            users = users.sort((a, b) => {
                if (this.isOwner(a)) return -1;
                if (this.isOwner(b)) return 1;
                return Utils.compareSortString(a.email, b.email);
            });
            items = users
                .map(a => <AgroProjectSecurityRowComp store={store} item={a} key={a.email} isOwner={this.isOwner(a)}/>);
        }
        let body = <React.Fragment>{this.showNewUser && <AgroProjectNewUserComp store={store}
                                                                                user={this.newUserName}
                                                                                onChangeTitle={this.onChangeNewUserTitle}
                                                                                role={this.newUserRole}
                                                                                onChangeType={this.onChangeNewUserRole}
                                                                                onCancel={this.onCancelNewUser}
                                                                                onOk={this.onOkNewUser} /> }
            {(this.props.showTitle??true) && this.getHeaderComp()}
            {this.getSearchPanel()}
        <div className="AgroProjectSecurityComp-table">
            <div className="AgroProjectSecurityComp-tableHeader AgroProjectSecurityComp-row">
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Name">
                    {store.trans["User name"]}
                </div>
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Email">
                    {store.trans.Email}
                </div>
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Perm">
                    {store.trans["Access rights"]}
                </div>
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Del">
                    {store.trans.Delete}
                </div>
            </div>
            <div className="AgroProjectSecurityComp-tableData style-4 overflowAutoY">
                {projEditor.proj_users_load == LoadStatus.loading && <div className="flex-full-center"><LoadingAniSvg /></div>}
                {items}
            </div>

        </div>
            {this.getFooterComp()}
        </React.Fragment>;

        if (this.props.isForm??true) {
            return <div className="modal-back">
                <div className="modal-full modal-full-frame AgroProjectSecurityComp-form text-wrap">
                    {body}
                </div>
            </div>;
        }else return <div className={this.props.bodyClassName}>{body}</div>;
    }
}
