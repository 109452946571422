import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoMergeIsoXmlSelAllComp.scss'
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {MergeIsoXmlFarm, MergeIsoXmlField} from "../../agroAhoUploadIsoXmlStore";
import {action} from "mobx";

export interface AgroAhoMergeIsoXmlSelAllCompProps {
    store: IAgroAhoSuperStore;
    farm: MergeIsoXmlFarm;
    selAll?: boolean;
}

@observer
export class AgroAhoMergeIsoXmlSelAllComp extends React.Component<AgroAhoMergeIsoXmlSelAllCompProps, undefined> {
    constructor(props: AgroAhoMergeIsoXmlSelAllCompProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(){
        let ustore = this.props.store.agroAhoStore.cardsStore.uploadIsoXMlStore;
        ustore.doSelAllClick(this.props.farm);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;

        return <div className="AgroAhoMergeIsoXmlSelAllComp-main" onClick={this.onClick}>
            <AgroAhoRectCheckboxSvg className="AgroAhoMergeIsoXmlSelAllComp-checkbox" checked={this.props.selAll}/>
            <div className="AgroAhoMergeIsoXmlSelAllComp-name">{store_.trans["Select all"]}</div>
        </div>;
    }
}
