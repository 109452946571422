import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoMergeIsoXmlItemComp.scss'
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {MergeIsoXmlField} from "../../agroAhoUploadIsoXmlStore";
import {action} from "mobx";

export interface AgroAhoMergeIsoXmlItemCompProps {
    store: IAgroAhoSuperStore;
    field: MergeIsoXmlField;
}

@observer
export class AgroAhoMergeIsoXmlItemComp extends React.Component<AgroAhoMergeIsoXmlItemCompProps, undefined> {
    constructor(props: AgroAhoMergeIsoXmlItemCompProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(){
        this.props.field.selected = !this.props.field.selected;
    }

    render() {
        let field = this.props.field;
        let store = this.props.store;
        let store_ = store.agroAhoStore;

        let area: any = field.area_m2 && parseFloat((field.area_m2 / 10000).toFixed(1));
        area = store_.numWithSpaces(area);

        return <div className="AgroAhoMergeIsoXmlItemComp-main" onClick={this.onClick}>
            <AgroAhoRectCheckboxSvg className="AgroAhoMergeIsoXmlItemComp-checkbox" checked={field.selected}/>
            <div className="AgroAhoMergeIsoXmlItemComp-name">{field.field_name}</div>
            <div className="AgroAhoMergeIsoXmlItemComp-area">{area}&nbsp;{store.trans.ha}</div>
        </div>;
    }
}
