import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {PhotoTagStore} from "../../../../../store/photo/PhotoTagStore";
import './PhotoFilterLeftTag.scss';
import {CheckboxMiniComp, CheckboxSize} from "../../../../Common/CheckboxMiniComp";
import {action} from "mobx";
import classNames from "classnames";

export interface IPhotoFilterLeftTag extends IStoreProps{
    tag: PhotoTagStore,
    disabled?: boolean,
    highlight?: boolean,
}
@observer
export class PhotoFilterLeftTag extends React.Component<IPhotoFilterLeftTag, undefined> {
    constructor(props: IPhotoFilterLeftTag) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickCheckbox(){
        if (this.props.disabled) return;
        this.props.tag.checked = !this.props.tag.checked;
    }

    render() {
        let store = this.props.store;
        let tag = this.props.tag;
        return <div className={classNames("PhotoFilterLeftTag-main", {"opacity50": this.props.disabled})}>
            <CheckboxMiniComp state={tag.checked} size={CheckboxSize.standart}  onClick={this.onClickCheckbox} />
            <span className={classNames("PhotoFilterLeftTag-label",{"blue-text": this.props.highlight})}  onClick={this.onClickCheckbox}>{tag.tag_name}</span>
        </div>;
    }
}
