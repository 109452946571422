import {CustomStore} from "../CustomStore";
import {action, observable} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {IDictonaryType} from "../../helper/utils/Utils";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";
import {ShowroomLayerType} from "./ShowRoom2LayerStore";
import {ShowRoom2Store} from "./ShowRoom2Store";


export interface ICcePointResponse{
    created_at: string,//2021-03-20 17:47:36
    "biomass_kg": string, //"12,450"
    "block": string, //"Balasore",
    "gp": string, //"Kashaphala",
    "district": string,// "Baleshwar",
    "state": string, //"Odisha",
    "weight_kg": string, //"11,500"
    "season": string, //"Kharif 2020",
    "year": number, //2020,
    "type": string, //"CCE",
    "crop":  string, //"Paddy",
    // "lat_poi": 21.53401, "lon_poi": 87.159775, "lon": 87.159775, "lat": 21.53401
}
export interface IYieldAssResponse{
    "id": number, // 11001001,
    "yield": number, //2667.8,
    "lon": number, //83.67957,
    "lat": number,// 21.29665,
    "gp": string, //"Bargarh",
    "gp_yield": number,// 2441.845,
    "district": string, //"Bargarh",
    "state": string,//"Odisha",
    "season": string, //"Kharif-20",
    "crop": string //"rice"
}


export class ShowRoom2MapPopupStore extends CustomStore<ShowRoom2Store>{
    @observable
    show: boolean = false;
    @observable
    status: LoadStatus = null;
    @observable
    data: IDictonaryType<any> = {};
    @observable
    showRoom2LayerName: ShowroomLayerType = null;
    @observable
    lat: number;
    @observable
    lon: number;
    @observable
    x: number;
    @observable
    y: number;

    @action
    async loadCCE(id: number){
        this.status = LoadStatus.loading;
        this.data = {};
        try {
            let j = await fetchJsonGet(`/reports/api/class/${encodeURIComponent(id)}`) as ICcePointResponse;
            ra(() =>{
                this.status = LoadStatus.ready;
                this.data = {};
                this.data["biomass_kg"] = j.biomass_kg;
                this.data["block"] = j.block;
                this.data["gp"] = j.gp;
                this.data["created_at"] = j.created_at;
                this.data["district"] = j.district;
                this.data["state"] = j.state;
                this.data["weight_kg"] = j.weight_kg;
                this.data["season"] = j.season;
                this.data["year"] = j.year;
                this.data["type"] = j.type;
                this.data["crop"] = j.crop;
            });
        }catch (e) {
            this.root.addError(e);
        }
    }
    @action
    async loadInfoYieldAss(id: any){
        this.status = LoadStatus.loading;
        this.data = {};
        try {
            let j = await fetchJsonGet(`/reports/api/yield/${encodeURIComponent(id)}`) as IYieldAssResponse;
            ra(() =>{
                this.status = LoadStatus.ready;
                this.data = {};
                this.data["gp"] = j.gp;
                this.data["crop"] = j.crop;
                this.data["yield"] = j.yield;
                this.data["district"] = j.district;
                this.data["state"] = j.state;
                this.data["gp_yield"] = j.gp_yield;
                this.data["season"] = j.season;
            });
        }catch (e) {
            this.root.addError(e);
        }
    }

}