import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoExportLayersPanelComp.scss'
import {action, observable} from "mobx";
import classNames from "classnames";
import {AgroAhoRoundCheckboxSvg} from "./AgroAhoRoundCheckboxSvg";
import {AhoExportFormat} from "../agroAhoCardStore";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {AgroAhoSwitcherSvg} from "./icons/AgroAhoSwitcherSvg";


@observer
export class AgroAhoExportLayersPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    format: AhoExportFormat = AhoExportFormat.GeoJSON;

    @action
    onGeoJSONClick(){
        this.format = AhoExportFormat.GeoJSON;
    }

    @action
    onKMLClick(){
        this.format = AhoExportFormat.KML;
    }

    @action
    onShapefileClick(){
        this.format = AhoExportFormat.Shapefile;
    }

    @action
    onClickExport(){
        let store_ = this.props.store.agroAhoStore;
        store_.fileStore.doCellExport(this.format);
        store_.toggleModal(false);
    }

    @action
    onClickCancel(){
        let store_ = this.props.store.agroAhoStore;
        store_.toggleModal(false);
    }

    @action
    onClickLayersVisFields(){
        let cs = this.props.store.agroAhoStore.cardStore;
        if (!cs.getFieldSelected()?.length) return;
        cs.layersExportVisFields = !cs.layersExportVisFields;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cs = store_.cardStore;
        let st = cs.getStat();
        let area = cs.layersExportVisFields ? store_.toFloat(st.rarea) : store_.toFloat(st.area);

        return <div className="AgroAhoExportLayersPanelComp-main">
            <div className="AgroAhoExportLayersPanelComp-title">{store.trans.Export}</div>
            <div className="AgroAhoExportLayersPanelComp-msg">
                <span>{store.trans["Area (ha)"]}:&nbsp;</span>
                <span>{area || ''}</span>
            </div>
            <div className="AgroAhoExportLayersPanelComp-formatTitle">{store.trans.Format}</div>

            <div className="AgroAhoExportLayersPanelComp-checkboxCover" onClick={this.onGeoJSONClick}>
                <AgroAhoRoundCheckboxSvg className="AgroAhoExportLayersPanelComp-formatCheckbox"
                                               checked={this.format == AhoExportFormat.GeoJSON}/>
                <span className="AgroAhoExportLayersPanelComp-formatCaption">GeoJSON</span>
            </div>
            <div className="AgroAhoExportLayersPanelComp-checkboxCover" onClick={this.onKMLClick}>
                <AgroAhoRoundCheckboxSvg className="AgroAhoExportLayersPanelComp-formatCheckbox"
                                               checked={this.format == AhoExportFormat.KML}/>
                <span className="AgroAhoExportLayersPanelComp-formatCaption">KML</span>
            </div>
            <div className="AgroAhoExportLayersPanelComp-checkboxCover" onClick={this.onShapefileClick}>
                <AgroAhoRoundCheckboxSvg className="AgroAhoExportLayersPanelComp-formatCheckbox"
                                                checked={this.format == AhoExportFormat.Shapefile}/>
                <span className="AgroAhoExportLayersPanelComp-formatCaption">ESRI Shapefile</span>
            </div>

            <div className={classNames("AgroAhoExportLayersPanelComp-checkboxCover AgroAhoExportLayersPanelComp-switcherCover",
                {"AgroAhoExportLayersPanelComp-switcherDisabled": !cs.getFieldSelected()?.length})}
                 onClick={this.onClickLayersVisFields}>
                <AgroAhoSwitcherSvg className="AgroAhoExportLayersPanelComp-switcher" checked={cs.layersExportVisFields}/>
                <span className="AgroAhoExportLayersPanelComp-formatCaption">{store_.trans["Export only selected fields"]}</span>
            </div>

            <div className="AgroAhoExportLayersPanelComp-line"> </div>

            <div className="AgroAhoExportLayersPanelComp-buttons">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoExportLayersPanelComp-cancelButton"
                    onClick={this.onClickCancel}>{store_.trans.Cancel}</button>
                <button className="large-button left-sidebar-active button-jump AgroAhoExportLayersPanelComp-exportButton"
                    onClick={this.onClickExport}>{store_.trans.Export}</button>
            </div>
        </div>
    }
}
