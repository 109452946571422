import autoBindReact from "auto-bind/react";
import {action} from "mobx";
import {observer} from "mobx-react";
import React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {EgrnMinimapComp} from "./EgrnMinimapComp";

@observer
export class EgrnInfoWindow extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClose(){
        this.props.store.egrnStore.activeParcel = null;
    }

    render() {
        let store = this.props.store;

        return <div className="modal-back">
        <div className="modal-full modal-full-frame PhotoTagEditorWindow-window">
            <div className="flex-columns flex-align-items-start">
                <div className="PhotoTagEditorWindow-header flex-stretch-item">{store.egrnStore.activeParcel['user_cadnum']}</div>
                    <div className="PhotoTagEditorWindow-topCloser" onClick={this.onClose}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" fill="#C5C5C5"/>
                        </svg>
                    </div>
                </div>
                <div className="EgrnInfoWindow-info-div">
                    <EgrnMinimapComp store={store} className="EgrnMinimapComp-minimap"></EgrnMinimapComp>
                    <textarea readOnly={true} className="EgrnInfoWindow-info-textarea" value={JSON.stringify(this.props.store.egrnStore.activeParcel)}/>
                </div>
            </div>
        </div>;
    }
}