import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {PhotoTagGroupStore} from "../../../../../store/photo/PhotoTagGroupStore";
import './PhotoFilterLeftTag.scss';
import {PhotoFilterLeftTag} from "./PhotoFilterLeftTag";
import {action} from "mobx";
import {PhotoTagStore} from "../../../../../store/photo/PhotoTagStore";

export interface IPhotoFilterLeftTagGroup extends IStoreProps{
    group: PhotoTagGroupStore,
    tags: PhotoTagStore[],
}

@observer
export class PhotoFilterLeftTagGroup extends React.Component<IPhotoFilterLeftTagGroup, undefined> {
    constructor(props: IPhotoFilterLeftTagGroup) {
        super(props);
        autoBindReact(this);
    }

    @action
    onChangeSearchFilter(event: any){
        this.props.group.groupNameFilter = event.target.value;
    }
    @action
    onExpend(){
        this.props.group.expanded = true;
    }
    @action
    onHide(){
        this.props.group.expanded = false;
    }

    render() {
        let store = this.props.store;
        let tg = this.props.group;
        let tagNodes: any[] = [];
        let tags = this.props.tags;

        let needExpand = tags.length > PhotoTagGroupStore.MAX_TAGS_VIEW;

        tags.forEach((a, idx) => {
            if (needExpand && !tg.expanded && idx >= PhotoTagGroupStore.MAX_TAGS_VIEW) return;
            tagNodes.push(<PhotoFilterLeftTag tag={a} store={store} key={a.tag_id} highlight={a.highlight} disabled={a.disabled} />);
        });


        return <div className="PhotoFilterLeftTagGroup-main">
            <div className="PhotoFilterLeftTagGroup-titleDiv">
                <div className="PhotoFilterLeftTagGroup-titleLabel">
                    {tg.name}
                </div>
                <div className="PhotoFilterLeftTagGroup-titleBadget">{tg.tagsWithFilters.length}</div>
                {((needExpand && tg.expanded) || tg.groupNameFilter.length > 0) && <div className="flex-stretch-item" style={{marginLeft: "10px"}}>
                    <input type="text" className="text-box-editor width100 PhotoFilterLeftTagGroup-titleSearch"
                           onChange={this.onChangeSearchFilter} placeholder={store.trans["Search by tags"]}
                           value={tg.groupNameFilter}/>
                </div>}
            </div>
            {tagNodes}
            {needExpand && tg.expanded && <div className="PhotoFilterLeftTagGroup-expandLabel" onClick={this.onHide}>{store.trans.Minimize}</div>}
            {needExpand && !tg.expanded && <div className="PhotoFilterLeftTagGroup-expandLabel"  onClick={this.onExpend}>{store.trans["Show all"]}</div>}
        </div>;
    }
}
