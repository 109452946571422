import React from "react";

export interface IPointMarkerSvg {
    isActive: boolean;
}

const PointMarkerSvg = (props: IPointMarkerSvg) => {
    return <svg
    width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 6.88235C13 10.1152 10.3298 12.7647 7 12.7647C3.67024 12.7647 1 10.1152 1 6.88235C1 3.64953 3.67024 1 7 1C10.3298 1 13 3.64953 13 6.88235Z" fill="none" strokeWidth="2"/>
    <path d="M7.71886 17.56C7.42823 18.1467 6.57177 18.1467 6.28114 17.56L4.08056 13.118C3.8243 12.6007 4.21055 12 4.79942 12L9.20058 12C9.78945 12 10.1757 12.6007 9.91944 13.118L7.71886 17.56Z" stroke="none" />
    {props.isActive && <rect x="4" y="5.5" width="2.12136" height="6.36408" rx="1" transform="rotate(-45 4 5.5)" stroke="none"/>}
    {props.isActive && <rect x="5.5" y="10" width="2.12136" height="6.36408" rx="1" transform="rotate(-135 5.5 10)" stroke="none"/>}
    {!props.isActive && <rect x="6" y="4" width="2" height="6" rx="1"  stroke="none"/>}
    {!props.isActive && <rect x="4" y="8" width="2" height="6" rx="1" transform="rotate(-90 4 8)" stroke="none"/>}
    </svg>;    
}

export default PointMarkerSvg;