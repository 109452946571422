import {CustomStoreTool} from "../general/CustomStoreTool";
import {ShowRoom2LayerStore} from "../../dataShowroom2/ShowRoom2LayerStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {autorun} from "mobx";
import {MapboxGeoJSONFeature} from "maplibre-gl";

export interface IShowRoom2LayerFilters{
    crops?: number[];
    seasons?: number[];
    districts?: number[];
}
export interface ShowRoom2Clickable{
    loadPopupById(id: any, feature: MapboxGeoJSONFeature):void;
}

export abstract class ShowRoom2LayerCustomTool extends CustomStoreTool{
    showRoomLayerStore: ShowRoom2LayerStore;

    visibleLayer(): boolean{
        return this.layerVisible();
    }

    getLayerId(): string{
        return "class_ShowRoom2_layer_"+this.showRoomLayerStore.title;
    }
    getSourceId(): string{
        return "class_ShowRoom2_src_"+this.showRoomLayerStore.title;
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.setupLayers();
            }), ...super.onSubscription()
        ];
    }

    layerVisible(): boolean{
        return this.showRoomLayerStore.checked;
    }

    setupLayers(){
        if (this.store.dataShowRoomStore.active){
            let map = this.store.map.mapbox;
            if (this.layerVisible()){
                this.removeLayers();
                this.addLayers();
            }else{
                this.removeLayers();
            }
        }else{
            this.removeLayers();
        }
    }

    abstract addLayers(

    ): any;


    removeLayers(){
        let map = this.store.map.mapbox;
        if (map.getLayer(this.getLayerId())){
            this.removeLayer(this.getLayerId());
        }
        if (map.getSource(this.getSourceId())){
            map.removeSource(this.getSourceId());
        }
    }
}