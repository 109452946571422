import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroExportPointsComp.scss';
import {action} from 'mobx';
import classNames from "classnames";
import {IAgroExportStoreProps} from "../agroExportPlugin";
import {TranslateUtils} from "../../../app/helper/lang/TranslateUtils";
import {AgroExportPointsItemComp} from "./AgroExportPointsItemComp";


@observer
export class AgroExportPointsComp extends React.Component<IAgroExportStoreProps, undefined> {
    constructor(props: IAgroExportStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onChangeName(e: any){
        let store_ = this.props.store.agroExport.pointsStore;
        store_.newAsaMapName = e.currentTarget.value;
    }

    canCreate(){
        let store_ = this.props.store.agroExport.pointsStore;
        return !!store_.newAsaMapName;
    }

    onClickCancel(){
        let store_ = this.props.store.agroExport.pointsStore;
        store_.showCreateForm = false;
    }

    onClickCreate(){
        if (!this.canCreate()) return;
        let store_ = this.props.store.agroExport.pointsStore;
        store_.doAsaMapCreate();
        store_.showCreateForm = false;
    }

    render() {
        let store = this.props.store;
        let estore = store.agroExport;
        let pstore = estore.pointsStore;
        let farms_cnt = Object.keys(pstore.farms).length;
        let title = `${store.trans.Season} ${pstore.seasonName} | ${estore.trans.Farms}: ${farms_cnt}, ` +
            `${estore.trans.fields}: ${pstore.fieldsCnt}, ${estore.trans.points}: ${pstore.pointsCnt}, ` +
            `${estore.trans.cells}: ${pstore.pointsCnt}`;
        let arr = Object.keys(pstore.farms).map((k: any)=><AgroExportPointsItemComp key={k} store={store}
            farm_name={k} fields={pstore.farms[k]}/>)
         // cstore.fieldsItems.map((f: any)=><AgroAhoCardFieldItemComp key={f.field_id}
        //                     store={store} field={f}/>)


        return <div className="modal-back flex-full-center">
            <div className="modal-full-frame AgroExportPointsComp-form">
                <div className="AgroExportPointsComp-title">
                    {estore.trans['Creation of a sampling map (ASA) for inspection points']}</div>
                <div className={classNames("AgroExportPointsComp-subTitle", {
                    "AgroExportPointsComp-hidden": !pstore.outPoints})}>{TranslateUtils.format(
                    estore.trans['There are points ({1} pcs.) outside the {0} season fields that will not be used when creating the ASA map'],
                    pstore.seasonName, pstore.outPoints)}</div>
                <div className="AgroExportPointsComp-line" />
                <div className="AgroExportPointsComp-name">
                    {store.trans.Name}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroExportPointsComp-Input">
                    <input type="text" className="text-box-editor width100" onChange={this.onChangeName}
                           value={pstore.newAsaMapName} placeholder={estore.trans['New ASA map']} />
                </div>
                <div className="AgroExportPointsComp-listCover">
                    <div className="AgroExportPointsComp-listTitle">{title}</div>
                    <div className="AgroExportPointsComp-list style-4">{arr}</div>
                </div>
                <div className="AgroExportPointsComp-line" />
                <div className="AgroExportPointsComp-bottom">
                    <button className="left-sidebar-cancel large-button button-jump AgroExportPointsComp-button text-next-2" onClick={this.onClickCancel}>
                        {store.trans.Cancel}
                    </button>
                    <button className={classNames(
                        "left-sidebar-active large-button AgroExportPointsComp-button text-next-2", {
                            "button-jump": this.canCreate(),
                            "AgroExportPointsComp-disabled": !this.canCreate()
                        })} onClick={this.onClickCreate} >
                        {store.trans.Create}
                    </button>
                </div>
            </div>
        </div>;
    }
}
