import {CustomStore} from "../CustomStore";
import {Geometry, LineString, Polygon, Position} from "geojson";
import {action, observable} from "mobx";
import {MBUtils} from "../../helper/utils/MBUtils";
import area from "@turf/area";
import length from "@turf/length";
import {RulerManager} from "../toolController/RulerManager";
import {GeometryUtils} from "../../helper/utils/GeometryUtils";

export class MapRulerInfoStore extends CustomStore{
    class(): string {return "MapRulerInfoStore";}
    @observable
    isPolygon: boolean;
    @observable
    _scrPoint: Position = null;
    get scrPoint(): Position{
        return this._scrPoint;
    }
    set scrPoint(value: Position){
        this._scrPoint = value;
    }
    @observable
    scrPointLastPoint: Position = null;
    @observable
    area: number;
    @observable
    areaPostfix: string;
    @observable
    perimeter: number;
    @observable
    perimeterPostfix: string;

    rulerManager: RulerManager = new RulerManager(this);

    @action
    deleteAllPoints(){
        this.onRecalcRulerInfo2();
    }

    getGeometry(): Geometry{
        let ms = this.root.map;

        return ms.rulerInfo.rulerManager.state.getGeometry();
    }

    setLastPoint(){
        let state = this.rulerManager.state;
        if (state.simpleGeometry == null || state.simpleGeometry.length == 0) {
            this.scrPoint = null;
            return;
        }
        let g = GeometryUtils.createGeometryBySimpleGeometry(state.simpleGeometry);
        let ms = this.root.map;
        if (g == null || g.type != "LineString") {
            this.root.map.rulerInfo.scrPoint = null;
            return;
        }
        if (ms.mapbox == null) {
            this.scrPoint = null;
            return;
        }
        let line = (g as LineString);
        let lastP = line.coordinates[line.coordinates.length - 1];
        let scrCoordP = MBUtils.pointToPosition(ms.mapbox.project(MBUtils.positionToLL(lastP)));
        this.scrPoint = scrCoordP;
    }
    @action
    onRecalcRulerInfo2(cursorScrCoord: Position = null){
        let sg = this.rulerManager.state.simpleGeometry;
        if (sg == null) {
            this.scrPoint = null;
            return;
        }
        let g = GeometryUtils.createGeometryBySimpleGeometry(this.rulerManager.state.simpleGeometry);
        let ms = this.root.map;
        if (g == null || g.type != "LineString") {
            this.scrPoint = null;
            return;
        }
        let line = (g as LineString);
        if (line.coordinates.length == 0){
            this.scrPoint = null;
            return;
        }
        let lastP = line.coordinates[line.coordinates.length - 1];
        let state = this.rulerManager.state;
        if (state?.events?.isCreateGeometry && state.events.isCreateGeometry() && cursorScrCoord != null) {
            this.scrPoint = cursorScrCoord;
            let pp = MBUtils.llToPosition(ms.mapbox.unproject(MBUtils.positionToPoint(cursorScrCoord)));
            line.coordinates.push(pp);
        }else {
            this.setLastPoint();
        }


        if (MBUtils.isEqualPoint(line.coordinates[0], line.coordinates[line.coordinates.length - 1])){
            this.isPolygon = true;
            let poly: Polygon = {type: "Polygon", coordinates:[line.coordinates]};

            let sqValue = area(poly);
            if (sqValue > 0.0) {
                if (sqValue > 100000.0) //0.1 km2
                {
                    this.area = (sqValue / 1000000.0);
                    this.areaPostfix = ' '+this.root.trans["km²"];
                } else{
                    this.area = sqValue;
                    this.areaPostfix = ' '+this.root.trans["m²"];
                }
            }else{
                this.area = 0;
                this.areaPostfix = "";
            }
        }else{
            this.isPolygon = false;
            let gc: any = {type:"GeometryCollection", geometries: [line as Geometry]};
            let lenValue = length(gc, {units: "meters"});
            if (lenValue > 1000){
                this.perimeter = lenValue / 1000;
                this.perimeterPostfix = " "+this.root.trans["km"];
            }else{
                this.perimeter = lenValue;
                this.perimeterPostfix = " "+this.root.trans["m"];
            }
        }

    }

}
