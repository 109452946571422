import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import { isFunction } from "lodash-es";
import React from "react";
import { BorderStyle } from "./LayersListStore";

export interface IBorderSwitcherProps {
    border: BorderStyle;
    className?: string;
    onChange?: (style: BorderStyle)=>any;
}

export class BorderSwitcher extends React.PureComponent<IBorderSwitcherProps, undefined> {
    constructor(props: IBorderSwitcherProps) {
        super(props);
        autoBindReact(this);
    }
    onChange(){        
        if (isFunction(this.props.onChange))
            this.props.onChange(this.props.border == BorderStyle.Solid? 
                BorderStyle.DashDash : BorderStyle.Solid);
    }

    render() {
        let check1 = this.props.border == BorderStyle.Solid;
        return <div className={classNames("border-switcher", this.props.className)}>
            <div className={classNames("tswitcher-item tswitcher-item-left",{
                //"tswitcher-disabledItem": !check1 && this.props.disabledUnchecked,
                "tswitcher-checked": check1})}
                 onClick={this.onChange}>
                    <div style={{
                        border: "1px solid",
                        width: "21px",
                        borderRadius: "1.5px"
                    }}/>
                 </div>
            <div className={classNames("tswitcher-item tswitcher-item-right",{
                //"tswitcher-disabledItem": check1 && this.props.disabledUnchecked,
                "tswitcher-checked": !check1})}
                 onClick={this.onChange}>
                    <div style={{
                        border: "1px dashed",
                        width: "21px",
                        borderRadius: "1.5px"
                    }}/>
                 </div>
            <div className={classNames("border-switcher-back", {"border-switcher-back-right": !check1, "border-switcher-back-left": check1})}/>
        </div>;
    }
}
