import * as React from 'react';
import {LeftPanelMode} from "../../app/store/SearchStore";
import {MeteoPanelComp} from "./Meteo/MeteoPanelComp";
import {IEmergancySuperStore} from "../emergency/emergencyPlugin";
import {ReactElement} from "react";
import {EmergencyPanelComp} from "../emergency/Emergency/EmergencyPanelComp";
import {EmergencyLeftPanelMode} from "../emergency/EmergencyEvents";
import {IMeteoSuperStore, LeftPanelModeMeteo} from "./meteoPlugin";
import autoBind from "auto-bind";
import {MyHint} from "../../app/components/Common/MyHint";
import {EmergencySvg} from "../../app/components/icons/Left/EmergencySvg";
import classNames from "classnames";
import {action} from "mobx";
import {MeteoButtonSvg} from "../../app/components/icons/Left/MeteoButtonSvg";
import {NdviValueByMoudeComp} from "../../app/components/panels/Map/NdviValueByMoudeComp";
import {EmergencyFiltersPanel} from "../emergency/Emergency/EmergencyFiltersPanel";
import MeteoLegendComp from './Meteo/MeteoLegendComp';
import { ViewMode } from './MeteoStore';
import { ra } from '../../app/helper/utils/mobxUtils';

export class MeteoEvents {
    constructor(store: IMeteoSuperStore) {
        autoBind(this);
        this.store = store;
    }

    store: IMeteoSuperStore;

    onLeftPanelChildrens(children: ReactElement[]): void{
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeMeteo)
            children.push(<MeteoPanelComp store={this.store} key="meteo" />);
    }
    @action
    onClickMeteo(){
        this.store.searchPanel.switchPanel(LeftPanelModeMeteo);
    }
    onMapChildren(children: ReactElement[]){
        let meteo = this.store.meteoStore;
        if (meteo.visibleWidget) children.push(<NdviValueByMoudeComp store={this.store as any} value={meteo.mouseValue}  key="meteoWidget"/>);
        children.push(<MeteoLegendComp store={this.store as any} deviation={meteo.deviation} 
            showLegend={meteo.viewMode == ViewMode.Map} key={"MeteoLegend"} />);
    }

    onLeftSidebarLeftChildren(children: ReactElement[]){
        let leftMode = this.store.searchPanel.leftPanelMode;
        children.push(<React.Fragment key="meteo">
            <MyHint text={this.store.meteoStore.trans["Meteo"]} placement="right">
                <div className="icon_container " onClick={this.onClickMeteo}>
                    <MeteoButtonSvg className={classNames({
                        "blue-fill": leftMode == LeftPanelModeMeteo,
                        "white-fill": leftMode != LeftPanelModeMeteo
                    })} />
                </div>
            </MyHint>
            <div className="spacer-5"/>
        </React.Fragment>);
    }
}
