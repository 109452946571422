import {CustomStoreTool} from "../../../../app/store/tools/general/CustomStoreTool";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import {MeasuringStatus} from "../../../../app/helper/structs/MeasuringStatus";
import {IAgroExportSuperStore} from "../../agroExportPlugin";
import {AgroExportConst} from "../../AgroExportConst";
//останавливает обработку клика, если это не перемещение карты и работает режим редактирования
export class VoronovStopClickTool extends CustomStoreTool {

    onMouseUp(e: maplibregl.MapMouseEvent & ToolEvent) {
        if (this.store.map.measuringStatus ==AgroExportConst.VORONOV_EDIT_GEOMETRY) {
            if (!e.dragging) e.noPropagation();
        }
    }
}