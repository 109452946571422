import {runInAction} from "mobx";

//короткая версия runInAction
export function ra(f: ()=>any){
    runInAction(f);
}
export function rai(f: ()=>any){
    setImmediate(()=>{
        runInAction(f);
    });
}
export function any(): any{
    return {};
}