import autoBind from "auto-bind";

export class ActionManager {
    constructor(state: ContainerToolsState) {
        autoBind(this);
        this.state = state;
    }
    history: GeometryActionHistory = new GeometryActionHistory();

    private state: ContainerToolsState;
    startGeometryFieldTransaction(type: GActionTransactionType): GeometryActionTransaction{
        let t: IGActionTransaction = {actions: [], transactionType: type, actionType: GActionType.group};
        let r = new GeometryActionTransaction();
        r.transaction = t;
        r.getGeometry = () =>{
            return this.state.simpleGeometry;
        }
        this.history.createAction(t);
        return r;
    }
    canUndo(): boolean{
        return this.history.canUndo();
    }
    undo(){
        let a = this.history.undo();
        let a2 = GeometryActionApply.reversAction(a);
        let t: GeometryActionApply = new GeometryActionApply();
        t.simpleGeometry = this.state.simpleGeometry;
        t.doAction(a2);
        if (this.state?.events?.onChangeGeometry) this.state.events.onChangeGeometry();
        if (this.state?.events?.updateGeometry) this.state.events.updateGeometry();
        this.state.resetMovedPoints();
        if (this.state?.events?.updateMovedPoint) this.state.events.updateMovedPoint();
    }
    canRedo(): boolean{
        return this.history.canRedo();
    }
    redo(){
        let a = this.history.redo();
        let t: GeometryActionApply = new GeometryActionApply();
        t.simpleGeometry = this.state.simpleGeometry;
        t.doAction(a);
        if (this.state?.events?.onChangeGeometry) this.state.events.onChangeGeometry();
        if (this.state?.events?.updateGeometry) this.state.events.updateGeometry();
        this.state.resetMovedPoints();
        if (this.state?.events?.updateMovedPoint) this.state.events.updateMovedPoint();
    }
}

export class ActionTransaction{
    transaction: IGActionTransaction;

}
export class GeometryActionTransaction extends ActionTransaction{

    getGeometry: () => ISimpleGeometry[] = null;

    apply(a: IGAction){
        let t: GeometryActionApply = new GeometryActionApply();
        t.simpleGeometry = this.getGeometry();
        t.doAction(a);
        this.transaction.actions.push(a);
    }
    insertPoint(addrPoint: AddrPoint, pos: Position){
        this.apply(GeometryActionCreator.insertPoint(addrPoint, pos));
    }
    deletePoint(addrPoint: AddrPoint){
        this.apply(GeometryActionCreator.deletePoint(this.getGeometry(), addrPoint));
    }
    movePoint(addrPoint: AddrPoint, pos: Position){
        this.apply(GeometryActionCreator.movePoint(this.getGeometry(), addrPoint, pos));
    }
    insertHole(addrCont: AddrContour, hole: ContourPoints){
        this.apply(GeometryActionCreator.insertHole(addrCont, hole));
    }
    deleteHole(addrCont: AddrContour){
        this.apply(GeometryActionCreator.deleteHole(this.getGeometry(), addrCont));
    }
    insertGeometry(index: number, sg: ISimpleGeometry){
        this.apply(GeometryActionCreator.insertGeometry(index, sg));
    }
    deleteGeometry(index: number){
        this.apply(GeometryActionCreator.deleteGeometry(this.getGeometry(), index));
    }
}
import {ContainerToolsState} from "../../store/tools/general/ContainerTools";
import {
    GActionTransactionType,
    GActionType, IGAction,
    IGActionTransaction
} from "./IGeometryAction";
import {GeometryActionHistory} from "./GeometryActionHistory";
import {AddrContour, AddrPoint, ContourPoints, ISimpleGeometry} from "../utils/GeometryUtils";
import {Position} from "geojson";
import {GeometryActionApply} from "./GeometryActionApply";
import {GeometryActionCreator} from "./GeometryActionCreator";
