import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {PhotoPanelTopFilterItemComp} from "../PhotoPanel/PhotoFiltersElements/PhotoPanelTopFilterItemComp";
import classNames from "classnames";
import {ContextMenuCommonComp} from "../../../Common/ContextMenuCommonComp";
import {ShowRoom2TopFilterPopup} from "./ShowRoom2TopFilterPopup";
import {action, observable} from "mobx";
import {ra} from "../../../../helper/utils/mobxUtils";


export interface IShowRoom2TopFilterPopupItem extends IStoreProps{
    svg: any;
    text: string;
    onDelete: ()=>any,
    hasChilds: boolean,
}
@observer
export class ShowRoom2TopFilterPopupItem extends React.Component<IShowRoom2TopFilterPopupItem, undefined> {
    constructor(props: IShowRoom2TopFilterPopupItem) {
        super(props);
        autoBindReact(this);
    }

    @observable
    dropDown: boolean = false;

    timerClose: any = null;

    @action
    onClickItem(){
        this.dropDown = true;
        this.clearTimer();
    }

    clearTimer(){
        if (this.timerClose != null){
            clearTimeout(this.timerClose);
            this.timerClose = null;
        }
    }

    onClosePopup(){
        this.clearTimer();
        this.timerClose = setTimeout(()=>{
            ra(()=>{this.dropDown = false;});
        }, 300);
    }

    render() {
        let store = this.props.store;
        return <div key="crop" className="flex-columns" onMouseEnter={this.onClickItem} onMouseLeave={this.onClosePopup}>
            <div className="flex-rows">
                <PhotoPanelTopFilterItemComp store={store} text={this.props.text}
                                             className={classNames({
                                                 "ShowRoom2TopFilters-item": !this.dropDown,
                                                 "blue": this.dropDown})}
                                             onClick={this.onClickItem} onClickDelete={this.props.onDelete} >
                    {this.props.svg}
                </PhotoPanelTopFilterItemComp>
            </div>
            {this.dropDown && this.props.hasChilds && <ContextMenuCommonComp onClose={this.onClosePopup}>
                <ShowRoom2TopFilterPopup store={store} onMouseEnter={this.onClickItem} onMouseLeave={this.onClosePopup}>
                    {this.props.children}
                </ShowRoom2TopFilterPopup>
            </ContextMenuCommonComp>}
        </div>;
    }
}
