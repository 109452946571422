// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A2UploadFormItemColumn-exampleStr {
  font-size: 11px;
  line-height: 12px;
  opacity: 0.5;
}

.A2UploadFormItemColumn-columnName {
  font-size: 13px;
  line-height: 16px;
}

.A2UploadFormItemColumn-key {
  padding-bottom: 15px;
}

.A2UploadFormItemColumn-value {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.A2UploadFormItemColumn-noimport {
  background: #3E4751 !important;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/A2Upload/A2UploadFormItemColumn.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AACF;;AACA;EACE,eAAA;EACA,iBAAA;AAEF;;AAAA;EACE,oBAAA;AAGF;;AADA;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;AAIF;;AAFA;EACE,8BAAA;AAKF","sourcesContent":[".A2UploadFormItemColumn-exampleStr {\r\n  font-size: 11px;\r\n  line-height: 12px;\r\n  opacity: 0.5;\r\n}\r\n.A2UploadFormItemColumn-columnName {\r\n  font-size: 13px;\r\n  line-height: 16px;\r\n}\r\n.A2UploadFormItemColumn-key{\r\n  padding-bottom: 15px;\r\n}\r\n.A2UploadFormItemColumn-value{\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding-bottom: 15px;\r\n}\r\n.A2UploadFormItemColumn-noimport {\r\n  background: #3E4751!important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
