import {CustomStore} from "../CustomStore";
import {action, computed, get, observable, set, toJS} from "mobx";
import {IDictonaryType, Utils} from "../../helper/utils/Utils";
import {SearchItemStore} from "../SearchItemStore";
import {fetchJson} from "../../helper/utils/FetchUtils";
import {IOrderInfoParams, ORDER_TYPE} from "../OrderListStore";
import {save, update} from "../PermalinkDecor";
import {IOrderedProducts} from "../config/ConfigStore";
import {CropMaskStore} from "./CropMaskStore";
import {ActiveSensorDayStore} from "./ActiveSensorDayStore";
import {Satellite} from "../../helper/utils/satellliteDic";
import {Export2ModisStore} from "./Export2ModisStore";
import {LandsatExportStore} from "./LandsatExportStore";
import {FavoriteExportStore} from "./FavoriteExportStore";
import {Sentinel2ExportStore} from "./Sentinel2ExportStore";
import {IntervalStore} from "../IntervalStore";
import {FieldsDelineationStore} from "./FieldsDelineationStore";
import {TextUtils} from "../../helper/utils/TextUtils";

export enum Export2TypeOrders{
    Sentinel1,
    Cropmask,
    Modis,
    Landsat,
    Sentinel2,
    FieldsDelineation,

}

export enum ExportItemsType{
    allFavorites, favoritesSelected
}
export enum ExportItemsCategory{
    favorites, current
}
export enum Export1OrderType{
    scenes='scenes',
    mosaic='mosaic',
    fusion='fusion'
}

export class ExportStore extends CustomStore{
    constructor(parent: CustomStore) {
        super(parent);
    }
    class(): string {return "ExportStore";}

    @observable
    showExportOptions: boolean = false;

    cropMaskStore: CropMaskStore = new CropMaskStore(this);
    activeSensorDay: ActiveSensorDayStore = new ActiveSensorDayStore(this);
    modis: Export2ModisStore = new Export2ModisStore(this);
    @update
    fieldsDelineation: FieldsDelineationStore = new FieldsDelineationStore(this);

    @update
    landsatExport: LandsatExportStore = new LandsatExportStore(this);
    @update
    sentinel2Export: Sentinel2ExportStore = new Sentinel2ExportStore(this);
    @update
    favoriteExport: FavoriteExportStore = new FavoriteExportStore(this);

    @save @observable
    export2TypeOrders: Export2TypeOrders = Export2TypeOrders.Landsat;

    @save @observable
    projectName: string = "";
    @save @observable
    email: string = "";

    @save @observable
    exportItemsType: ExportItemsType = ExportItemsType.allFavorites;
    @save @observable
    exportItemsCategory: ExportItemsCategory = ExportItemsCategory.favorites;
    @update @observable
    period: IntervalStore = new IntervalStore(this);

    @save @observable
    mosaic: boolean = false;
    @save @observable
    fusion: boolean = false;

    getOrder1Type(): Export1OrderType{
        let curOrderType = Export1OrderType.scenes;
        if (this.mosaic) curOrderType = Export1OrderType.mosaic;
        if (this.fusion) curOrderType = Export1OrderType.fusion
        return curOrderType;
    }

    makeOnlineEnable(){
        let scenes = this.getSceneItems();
        return (scenes.filter(a => a.feature.properties.tiles_exists == false).length > 0);
    }

    @computed
    get isValidOptions(): boolean{
        if (this.isInvalidMosaic) return false;
        return (this.getScenes().length > 0) &&
            (this.projectName.length > 0) &&
            (this.favoriteExport.getSelectedBands().length > 0 || this.favoriteExport.getSelectedIndexes().length > 0 || this.getOrder1Type() == Export1OrderType.fusion);
    }

    @computed
    get isInvalidSat(): boolean{//проверка что одинаковые спутники
        let lst = this.getSceneItems();
        if (lst.length == 0) return true;
        let c = lst[0];
        return lst.find(
            a =>
                !((a.feature.properties.satellite == c.feature.properties.satellite ||
                        TextUtils.eachOneOfAny([a.feature.properties.satellite, c.feature.properties.satellite], ["Landsat-8", "Landsat-9"], false))
                    && a.feature.properties.level == c.feature.properties.level)
        ) != null || this.isInvalidFusion;
    }

    @computed
    get isInvalidMosaic(): boolean{//проверка что есть разные даты
        let curOrderType = this.getOrder1Type();
        if (curOrderType != Export1OrderType.mosaic) return false;
        let lst = this.getSceneItems();
        if (lst.length == 0) return true;
        let c = lst[0];
        let r = lst.find(
            a =>
                (a.feature.properties.acqdate != c.feature.properties.acqdate)
        ) == null;
        return r;
    }

    get isInvalidFusion(): boolean{
        if (this.getOrder1Type() != Export1OrderType.fusion) return false;
        let lst = this.getSceneItems();
        return lst.filter(a => a.feature.properties.satellite != Satellite["Landsat-8"] && a.feature.properties.satellite != Satellite["Landsat-7"]
                                  && a.feature.properties.satellite != Satellite["Landsat-9"]).length > 0;
    }

    legacyExportSentinel1(){
        let params: IOrderInfoParams = <any>{};

        params.order_type = ORDER_TYPE.s1_radar;
        params.polars = [];
        if (this.root.exportStore.activeSensorDay.polarizationTypeVV) params.polars.push("VV");
        if (this.root.exportStore.activeSensorDay.polarizationTypeVH) params.polars.push("VH");

        params.date0 = Utils.formatDate(this.root.searchPanel.filterDate.begin);
        params.date1 = Utils.formatDate(this.root.searchPanel.filterDate.end);
        let so = this.root.map.searchObject;
        params.region = so.searchGeometry;

        this.root.searchPanel.closeAllLeftPanels();

        let url = `/api/order_proc/order?name=${encodeURIComponent(this.projectName)}&type=scene_product&email=${encodeURIComponent(this.email)}`;
        let json = {params: params};

        fetchJson(url,{
            method: 'PUT', body: JSON.stringify(json)
        })
            .then( values => {
                this.root.addInfo("Sended");
            })
            .catch(reason => {
                this.root.addError(Utils.getErrorString(reason)+" "+url);
            })
    }

    exportSentinel1() {
        let polars = [];
        if (this.root.exportStore.activeSensorDay.polarizationTypeVV) polars.push("VV");
        if (this.root.exportStore.activeSensorDay.polarizationTypeVH) polars.push("VH");
        let orbits = this.root.exportStore.activeSensorDay.orbits;
        this.root.searchPanel.closeAllLeftPanels();

        let url = "/api/proc_proxy/orders/s1_data";
        let json = {
            date_start: Utils.formatDate(this.root.searchPanel.filterDate.begin),
            date_end: Utils.formatDate(this.root.searchPanel.filterDate.end),
            polars: polars,
            region: JSON.stringify(this.root.map.searchObject.singleGeometry),
            order: {
                name: this.projectName,
                //priority: "background",
                email: this.root.exportStore.email
            },
            orbits: orbits,
            multi_temporal_filter: this.root.exportStore.activeSensorDay.multitimeFiltering,
            max_scenes_number: 300,
            product: this.root.exportStore.activeSensorDay.product,
            crop_to_region: this.root.exportStore.activeSensorDay.crop_to_region
        };

        fetchJson(url,{
            method: 'POST', body: JSON.stringify(json)
        })
        .then( values => {
            this.root.addInfo("Sent");
        })
        .catch(reason => {
            this.root.addError(Utils.getErrorString(reason)+" "+url);
        });
    }

    @action
    exportDailyMosaic() {
        if (!this.isValidOptions) return;
        if (this.fusion) return this.export();
        let json: any = {
            order: {
                name: this.projectName,
                //priority: "background",
                email: this.root.exportStore.email
            },
            scene_ids: this.getScenes(),
            //sat: "",
            //date_start: "2222-01-01",
            //date_end: "2222-01-01",
            region: (this.root.map.searchObject.singleGeometry == null) ? null : JSON.stringify(this.root.map.searchObject.singleGeometry),
            products: this.favoriteExport.getSelectedBands().concat(this.favoriteExport.getSelectedIndexes()),
            albedo: this.favoriteExport.albedo,
            combine_products: this.favoriteExport.combine_products,
            apply_cutline: this.favoriteExport.apply_cutline,
            max_scenes_number: 300
        }

        let url = "/api/proc_proxy/orders/daily_mosaic";
        if (this.mosaic) {
            url = "/api/proc_proxy/orders/mosaic";
            json.mosaic_mode  = "single";
        }else {
            json.daily_mosaic = this.favoriteExport.mergeScenes;
        }


        fetchJson(url,{
            method: 'POST', body: JSON.stringify(json)
        })
            .then( values => {
                this.root.addInfo("Sent");
            })
            .catch(reason => {
                this.root.addError(Utils.getErrorString(reason)+" "+url);
            })
    }

    @action
    export(){
        if (!this.isValidOptions) return;
        let typeTask = "order-s2_scene";
        if (this.mosaic)  typeTask = "order-mosaic";
        //if (!this.mosaic){
            //в этом случае анализируем для сцен из LS8 ставим этот тип заказа
            //typeTask = "order-ls8_scene";
        //}


        let params: IOrderInfoParams = <any>{};

        params.scene_ids = this.getScenes();
        params.scene_filter = { 'scene_id': {'$in':this.getScenes()}};
        params.products = this.favoriteExport.getSelectedBands().concat(this.favoriteExport.getSelectedIndexes());
        if (this.landsatExport.apply_cutline && this.root.map.searchObject.isNotEmpty) params.apply_cutline = 1;
        params.albedo = this.landsatExport.albedo ? 1 : 0;
        params.combine_products = this.landsatExport.combine_products ? 1 : 0;
        params.daily_mosaic = this.landsatExport.mergeScenes ? 1 : 0;

        let so = this.root.map.searchObject;
        params.region = so.content;
        if (so.isNotEmpty){
            params.region = so.searchGeometry;
        }
        params.order_type = ORDER_TYPE.daily_mosaic;
        if (this.fusion) params.order_type = ORDER_TYPE.landsat_fusion;
        /*
        if (this.make_online_value()) params.order_type = ORDER_TYPE.scene_tile;
        else
        if (this.root.exportStore.mosaic) params.order_type = ORDER_TYPE.scene_mosaic;
        else params.order_type = ORDER_TYPE.scene_separate;
         */
        //params.fit_method = {algorithm: 'linear'};

        let url = `/api/order_proc/order?name=${encodeURIComponent(this.projectName)}&type=scene_product&email=${encodeURIComponent(this.email)}`;
        //const fd = new FormData();
        //fd.append("order_name", this.projectName);
        //fd.append("email", this.email);
        //if (this.mosaic) fd.append("processing_type", "mosaic");
        //else fd.append("processing_type", "separate");
/*
        let ids = this.getScenes().join(",");
        fd.append("scene_filter", ids);
        fd.append("veginds", this.getSelectedIndexes().join(","));
        fd.append("bands", this.getSelectedBands().join(","));
        if (this.root().map.searchObject != null){
            let so = this.root().map.searchObject;
            fd.append("border_file", so.name);
            fd.append("geom_id", so.geomId as any);
            fd.append("border", JSON.stringify(so.content));
        }*/
        let json = {params: params};

        this.root.searchPanel.closeAllLeftPanels();

        fetchJson(url,{
            method: 'PUT', body: JSON.stringify(json)
        })
            .then( values => {
                this.root.addInfo("Sent");
            })
            .catch(reason => {
                this.root.addError(Utils.getErrorString(reason)+" "+url);
            })
    }

    getScenes(): string[]{
        return  this.getSceneItems().map(a => a.sceneId());
    }

    getSceneItems(): SearchItemStore[]{
        if (this.exportItemsCategory == ExportItemsCategory.favorites) {
            if (this.exportItemsType == ExportItemsType.favoritesSelected) return this.root.searchPanel.favoriteList.records.filter(a => a.selected);

            if (this.exportItemsType == ExportItemsType.allFavorites) return this.root.searchPanel.favoriteList.records;
        }

        return this.root.searchPanel.searchResult.getAllSceneItems().filter(a => a.sceneId() == this.root.searchPanel.currentSceneid);
    }


}
