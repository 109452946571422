import {CustomStore} from "../CustomStore";
import {computed, observable, runInAction} from "mobx";
import {Feature} from "geojson";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {Utils} from "../../helper/utils/Utils";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {isArrayLikeObject, isObjectLike, isString} from "lodash-es";
import {PhotoFilterStore} from "./PhotoFilterStore";
import {PhotoEditorStore} from "./PhotoEditorStore";
import {ra} from "../../helper/utils/mobxUtils";
import {UploadImageStore} from "./UploadImageStore";
import {PhotoTagEditorStore} from "./PhotoTagEditorStore";
import {save, update} from "../PermalinkDecor";


export enum PhotoPanelMode {
    photo = 'photo',
    filters = 'filters',
}

export interface IPhotoProperties{
    dir?: number;
    field_id?: number;
    file_name?: string;//"1.jpg"
    file_size?: number;
    group_name?: string;//"1AG1W27A"
    height?: number;
    lat?: number;
    lon?: number;
    photo_id?: number;
    photo_time?: string;//"2015-09-15T08:20:44"
    tags?: number[];
    upd_time?: string;//"2015-09-15T08:20:44"
    upd_user_id?: number;
    width?: number;
    version?: number;

    region_id?: number;
    contract_year?: number;
    farm_id?: number;
}
export interface IPhotoRequestGeomFilter{
    oper: "intersects" | "contains";
    geometry: string;//WKT POLYGON((33 44,34 44,34 45,33 45,33 44))
}
export interface IPhotoTagGroup{
    group_id?: number;
    group_name: string;
    group_tags: IPhotoTag[];
}
export interface IPhotoTag{
    tag_name: string;
    tag_id?: number;
}

export interface IOptionPhotoListRequest{
    isCount?: boolean,
    isTile?: boolean,
    isNextPage?: boolean,
    noLimit?: boolean,
    url?: string
}
export class PhotoStore extends CustomStore{
    constructor(parent: CustomStore) {
        super(parent);
    }
    class(): string {
        return "PhotoStore";
    }

    static LOAD_ROWS_BY_PAGE = 200;

    uploadImageStore: UploadImageStore = new UploadImageStore(this);

    tagEditor: PhotoTagEditorStore = new PhotoTagEditorStore(this);

    @observable
    tags: IPhotoTagGroup[] = [];

    private _tagLoaded: boolean = false;

    initTags(){
        if (!this._tagLoaded){
            this._tagLoaded = true;
            this.loadTags();
        }
    }
    clearTags(){
        this._tagLoaded = false;
    }

    @computed
    get getTagByIdDictonary(): Map<number, IPhotoTag>{
        let _tagById  = new Map<number, IPhotoTag>();
        this.tags.forEach(a =>{
            a.group_tags.forEach(b => {
                _tagById.set(b.tag_id, b);
            });
        });

        return _tagById;
    }

    @update @observable
    photoFilter: PhotoFilterStore = new PhotoFilterStore(this);
    @save @observable
    photoLayerVisible: boolean = true;

    editor: PhotoEditorStore = new PhotoEditorStore(this);

    @observable
    tileVersion: number = 0;
    @observable
    panelMode: PhotoPanelMode = PhotoPanelMode.photo;

    @observable
    private _features: Feature<null, IPhotoProperties>[] = [];

    get features(): Feature<null, IPhotoProperties>[]{
        return this._features;
    }
    set features(value: Feature<null, IPhotoProperties>[]){
        this._features = value;
        if (this.featureCurrentPhoto == null && this.currentPhotoId != null){
            this.currentPhotoId = null;
        }
    }
    @observable
    featuresLoadToEnd: boolean = true;
    @observable
    featuresTotalCount: number = null;
    @observable
    featuresLoadStatus: LoadStatus = null;

    @observable
    loadedPhotoViewerImage: boolean = false;
    @observable
    loadedPhotoViewerImageAnimation: boolean = false;

    getFeatureByPhotoId(photoId: number): Feature<null, IPhotoProperties>{
        return this.features.find(a => a.properties.photo_id == photoId);
    }

    @observable
    private _currentPhotoId: any;
    get currentPhotoId(): any{
        return this._currentPhotoId;
    }
    set currentPhotoId(value: any){
        if (this._currentPhotoId != value){
            if (value != null) this.root.searchPanel.closeAllLeftPanels();
            this.loadedPhotoViewerImage = false;
            this.loadedPhotoViewerImageAnimation = false;
        }
        this._currentPhotoId = value;
    }

    @computed
    get photoViewerShow(): boolean{
        return this.currentPhotoId != null;
    }

    @computed
    get featureCurrentPhoto() :Feature<null, IPhotoProperties>{
        return this._features.find(a => a.properties.photo_id == this.currentPhotoId);
    }

    async loadPhotoList(){
        try {
            let newUrl = this.getUrlPhotoList();

            if (this.urlPhotoLoad != null) {
                this.urlPhotoLoad = newUrl;
                return;
            }
            this.urlPhotoLoad = newUrl;
            await Utils.pauseAsync(500);
            let url = this.urlPhotoLoad;
            this.urlPhotoLoad = null;
            await this.doLoadPhotoList(url);
        }catch (e) {
            this.root.addError(e);
        }
    }

    async doLoadPhotoListNextPage(){
        try {
            let url = this.getUrlPhotoList({isNextPage: true});
            await this.doLoadPhotoList(url, true);
        }catch (e) {
            this.root.addError(e);
        }
    }

    getUrlPhotoList(options: IOptionPhotoListRequest = {}): string{

        let newUrl = `/api/projects/${this.root.agro.projectName}/photo/`;
        if (isString(options.url)){
            newUrl = options.url;
        }
        if (options.isTile) newUrl = window.location.origin + newUrl + `tile?z={z}&x={x}&y={y}&version=${this.tileVersion}`;else
        if (options.isCount) newUrl+="select?mode=count";else {
            newUrl += `select?`;
            if (!options.noLimit) newUrl += `limit=${PhotoStore.LOAD_ROWS_BY_PAGE}`;
        }
        if (!options.isCount) {
            newUrl += `&orderby=[{"column":"photo_time","direction":"desc"}]`;
        }

        let prms = this.getParamsPhotoListRequest(options);
        newUrl += "&"+Utils.queryEncodeParams(prms);

        return newUrl;
    }

    getParamsPhotoListRequest(options: IOptionPhotoListRequest): any{
        let bbox = this.root.map.mapbox.getBounds();
        let S = bbox.getSouth();
        let N = bbox.getNorth();
        let E = bbox.getEast();
        let W = bbox.getWest();
        let str = `POLYGON((${W} ${S}, ${E} ${S}, ${E} ${N}, ${W} ${N}, ${W} ${S}))`;

        let tags: any = {};
        let filter: any = {};
        this.photoFilter.tagGroupFilter.tagGroups.forEach(a =>{
            let arrTags = a.tags.filter(b => b.checked).map(c =>c.tag_id);
            if (arrTags.length > 0){
                tags[a.id.toString()] = arrTags;
            }
        });
        if (options.isNextPage){
            let min_Photo_id: number = null;
            this.features.forEach((p)=>{
                if (min_Photo_id == null || p.properties.photo_id < min_Photo_id){
                    min_Photo_id = p.properties.photo_id;
                }
            });
            if (min_Photo_id != null) filter["photo_id"] = {"$<": min_Photo_id};
        }


        if (!options.isTile && (this.photoFilter.useExtandMap || !this.editor.editorWindowShow)) filter["geom"]= {"$intersects":{"$geometry": str}};

        if (this.photoFilter.filterPhotoDate.hasValid()){
            filter["$and"] = [];
            if (this.photoFilter.filterPhotoDate.isValidBegin()){
                filter["$and"].push({"photo_time":{"$>=": Utils.getDateStr(this.photoFilter.filterPhotoDate.begin)}});
            }
            if (this.photoFilter.filterPhotoDate.isValidEnd()){
                filter["$and"].push({"photo_time":{"$<": Utils.getDateStr(this.photoFilter.filterPhotoDate.end)}});
            }
        }
        let r: any = {};
        if (Utils.allKeys(tags).length > 0) r.tag_filter=tags;

        if (Utils.allKeys(filter).length > 0) r.filter=filter;

        return r;
    }

    urlPhotoLoad: string = null;//если не null то есть новая заявка на загрузку

    async doLoadPhotoListCount(): Promise<number>{
        let url = this.getUrlPhotoList({isCount: true});
        let json = await fetchJsonGet(url);
        let cnt = json["count"];
        return cnt;
    }

    async doLoadPhotoList(url: string, nextPage: boolean = false){
        runInAction(()=>{
            this.featuresLoadStatus = LoadStatus.loading;
        });
        try {
            let [json, cnt] = await Promise.all([ fetchJsonGet(url), this.doLoadPhotoListCount()]);
            runInAction(() => {
                this.featuresLoadStatus = null;
                if (isObjectLike(json) && isArrayLikeObject(json.features)){
                    this.featuresLoadToEnd = (json.features.length != PhotoStore.LOAD_ROWS_BY_PAGE);
                    if (!nextPage){this.features = json.features;}else {
                        this.features = this.features.concat(json.features);
                    }

                }else this.features  =[];

                if (this.urlPhotoLoad == null) this.featuresLoadStatus = LoadStatus.ready;
                this.featuresTotalCount = cnt;
            });
        }catch (e) {
            this.featuresLoadStatus = null;
            throw e;
        }
    }

    async loadTags(){
        try {
            let res: IPhotoTagGroup[] = await fetchJsonGet(`/api/projects/${this.root.agro.projectName}/tag/select`);
            ra(()=>{
                this.tags = res;
            });
        }catch (e) {
            this.root.addError(e);
        }
    }
}