import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {SoilGridsGroupComp} from "./SoilGridsGroupComp";
import "./SoilGrids.scss";

@observer
export class SoilGridsPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let items: any[] = [];
        store.soilGrids.groups.forEach((a, index) =>{
            items.push(<SoilGridsGroupComp store={store} group={a} key={index} />);
        });
        return <div className="SoilGridsPanel-main">
            <div className="SoilGridsPanel-title">Soil grids</div>
            <div className="flex-stretch-item table-scroll style-4">
                    {items}
            </div>
        </div>;
    }
}
