// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegendDataShowRoom2Comp-div {
  padding: 5px;
}

.LegendDataShowRoom2Comp-table {
  border-collapse: collapse;
}

.LegendDataShowRoom2Comp-box {
  width: 14px;
  height: 14px;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 3px;
}

.LegendDataShowRoom2Comp-label {
  margin: 0 2px 0 6px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/LegendDataShowRoom2Comp.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;AAGF;;AADA;EACE,mBAAA;AAIF","sourcesContent":[".LegendDataShowRoom2Comp-div{\r\n  padding: 5px;\r\n}\r\n.LegendDataShowRoom2Comp-table {\r\n  border-collapse: collapse;\r\n}\r\n.LegendDataShowRoom2Comp-box{\r\n  width: 14px;\r\n  height: 14px;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  border-radius: 3px;\r\n}\r\n.LegendDataShowRoom2Comp-label{\r\n  margin: 0 2px 0 6px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
