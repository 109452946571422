import {IAuthUserResponse} from "./store/user/UserStore";
import JSON5 from 'json5';
import 'setimmediate';
import {SuperStore} from "./store/SuperStore";
import {GlobalRender} from "./global";
import {configure, when} from "mobx";
import {UrlCoder} from "./helper/utils/UrlCoder";
import 'animate.css/animate.css';
import '../scss/base.scss';
import {initClassFactory} from "./store/ClassFactoryInit";
import {fetchJson} from "./helper/utils/FetchUtils";
import * as QueryString from "qs";
import {Utils} from "./helper/utils/Utils";
import {createBrowserHistory} from "history";
import {ra} from "./helper/utils/mobxUtils";
import 'maplibre-gl/dist/maplibre-gl.css';
import {TextUtils} from "./helper/utils/TextUtils";

function entryPoint() {
        Promise.all([
                fetch("/config.json?rnd="+Math.random()).then(a => a.text()),
                fetch("/api/auth/user").then(res => res.json()),
            
            ]).then(async([txt, json])=>{
                    let tmpl = txt;
            
                    let jsonText = tmpl.split('{{urlOrigin}}').join(window.location.origin);
                    let a = JSON5.parse(jsonText);
            
                    configure({ enforceActions: "never" });//нельзя менять сторы вне @action методов
            
                    let store = new SuperStore(null);
            
                    store.history = createBrowserHistory();
            
                    initClassFactory();
                    store.config = a;
                    store.init();
            
                    if (!store.user.isAuthOkResponse(json)){
                            store.user.setNoAuth();
                    }else{
                            await store.user.setAuth(json as IAuthUserResponse);
                    }
                    loadPermalink(store);
                    if (store.user.isAuth){
                            return store.agro2.projectList.projectListRequest().then(()=>{
                                    store.agro2.projectList.initProjectIfSingle();
                                    return Promise.resolve(store);
                            });
                    }
            
                    return Promise.resolve(store);
                }
            ).then((store: SuperStore) => {
                    GlobalRender.render(store, () => {
                    });
            }).catch((err) =>{
                    GlobalRender.renderCriticalError(err);
            });
}

let code = new URL(location.href).searchParams.get('code');
if (location.pathname.toLowerCase().indexOf('newpass') < 0 &&
        location.pathname.toLowerCase().indexOf('confirm_email') < 0 &&
        code) {
        fetchJson("/api/auth/login", {
                method: "POST",
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: Utils.queryEncodeParams({source: 'class-cloud', code: code})
        })
        .then(json => entryPoint());
}
else
        entryPoint();


function loadPermalink(store: SuperStore){
        ra(()=>{
                let paramsUrlSrc = window.location.search;
                let paramsUrl = paramsUrlSrc;
                if (paramsUrl.indexOf("?") >= 0) paramsUrl = paramsUrl.substr(paramsUrl.indexOf("?") + 1);
                let pm: any = {};
                if (paramsUrl) pm = QueryString.parse(paramsUrl);
                //mobx - наблюдает за переменной когда она станет TRUE,  запускает функцию
                if (!store.user.isAuth) {
                        when(() => store.user.isAuth
                            , () => {
                                    doLoadPermalink(pm, store, paramsUrlSrc);
                            });
                }else{
                        doLoadPermalink(pm, store, paramsUrlSrc);
                }
        });
}
export function doLoadPermalink(pm: any, store: SuperStore, paramsUrlSrc: string){
        if (pm.link){
                fetchJson('/api/jsonget?'+ Utils.queryEncodeParams({id: pm.link})).then((res)=>{
                        if (res.length == 0){
                                store.addError(Utils.getErrorString("Permalink is not found"));
                                return;
                        }
                        let jsonPermalink = res[0].data;
                        store.fromPermalink(jsonPermalink, pm.link);
                }).catch((err)=>{
                        store.addError(Utils.getErrorString(err));
                });
        }
        else
                UrlCoder.readUrl(store, paramsUrlSrc);
}

