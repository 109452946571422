// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhotoTagEditorGroupTag-titleRow {
  padding-left: 13px;
  padding-right: 16px;
  height: 36px;
  align-items: center;
  display: flex;
}

.PhotoTagEditorGroupTag-titleRow:hover {
  background: #22282F;
}

.PhotoTagEditorGroupTag-titleBadget {
  background: #454C52;
  border-radius: 8px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  padding: 2px 9px;
}

.PhotoTagEditorGroupTag-name {
  padding-left: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/PhotoPanel/PhotoTagEditor/PhotoTagEditorGroupTag.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;AADF;;AAGA;EACE,mBAAA;AAAF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AACA;EACE,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAEF","sourcesContent":[".PhotoTagEditorGroupTag-m{\r\n}\r\n.PhotoTagEditorGroupTag-titleRow{\r\n  padding-left: 13px;\r\n  padding-right: 16px;\r\n  height: 36px;\r\n  align-items: center;\r\n  display: flex;\r\n}\r\n.PhotoTagEditorGroupTag-titleRow:hover{\r\n  background: #22282F;\r\n}\r\n.PhotoTagEditorGroupTag-titleBadget{\r\n  background: #454C52;\r\n  border-radius: 8px;\r\n  margin-left: 5px;\r\n  font-weight: bold;\r\n  font-size: 10px;\r\n  line-height: 14px;\r\n  padding: 2px 9px;\r\n}\r\n.PhotoTagEditorGroupTag-name {\r\n  padding-left: 4px;\r\n  font-style: normal;\r\n  font-weight: bold;\r\n  font-size: 13px;\r\n  line-height: 22px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
