import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import {ra} from "../../../../helper/utils/mobxUtils";

export interface IOrderLinkProps extends IStoreProps{
    order_id: string,
    order_name: string,
    is_legacy: boolean
}

@observer
export class OrderLink extends React.Component<IOrderLinkProps, undefined> {
    constructor(props: IOrderLinkProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    async onLoad(){
        let store = this.props.store;
        await store.orderList.loadOrder(this.props);
        ra(()=>{
            store.searchPanel.showOrderList = false;
        });

    }

    render() {
        let store = this.props.store;
        return <span className="link-text" onClick={this.onLoad}>{this.props.order_name}</span>;
    }
}
