import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../helper/structs/IStoreProps";
import {LeftSidebarComp} from "./panels/Left/LeftSidebarComp";
import {ExtandMapComp} from "./panels/Left/ExtandMapComp";
import {MapComp} from "./panels/Map/MapComp";
import {RightSidebar} from "./panels/Right/RightSidebar";
import {SignInPanelComp} from "./panels/User/SignInPanelComp";
import autoBindReact from "auto-bind/react";
import {DebugPanelComp} from "./panels/Map/DebugPanelComp";
import { MapCompareComp } from './panels/Map/MapCompareComp';

@observer
export class MapPageComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let compareMode = store.map.compareModeEnabled
        return <React.Fragment>
            {store.user.isAuth &&
                <React.Fragment>
                    <LeftSidebarComp store={store} />
                    <ExtandMapComp store={store}/>
                    <MapComp store={store} />
                    <RightSidebar store={store} />
                    {compareMode && <MapCompareComp store={store}/>}
                </React.Fragment>
            }
            {(!store.user.isAuth) && <SignInPanelComp store={store} />}

            </React.Fragment>
        ;
    }
}
