// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A2UploadFormComp-main {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}

.A2UploadFormComp-header {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #EEEEEE;
  display: flex;
}

.A2UploadFormComp-header-file {
  display: flex;
  justify-content: space-between;
  color: #6B707B;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.2;
  margin: 0 15px 20px 15px;
}

div.A2UploadFormComp-header-fileObj {
  font-weight: 400;
}

.A2UploadFormComp-header-i {
  margin-left: 10px;
}

.A2UploadFormComp-header-back {
  margin-right: 10px;
}

.A2UploadFormComp-tableHeader {
  color: #6B707B;
  font-size: 13px;
  line-height: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/A2Upload/A2UploadFormComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AACF;;AACA;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;AAEF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,wBAAA;AAGF;;AADA;EACE,gBAAA;AAIF;;AAFA;EACE,iBAAA;AAKF;;AAHA;EACE,kBAAA;AAMF;;AAJA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,oBAAA;AAOF","sourcesContent":[".A2UploadFormComp-main{\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex-shrink: 1;\r\n  flex-grow: 1;\r\n  overflow: hidden;\r\n}\r\n.A2UploadFormComp-header{\r\n  margin-top: 20px;\r\n  margin-bottom: 15px;\r\n  font-size: 16px;\r\n  line-height: 22px;\r\n  font-weight: bold;\r\n  color: #EEEEEE;\r\n  display: flex;\r\n}\r\n.A2UploadFormComp-header-file{\r\n  display: flex;\r\n  justify-content: space-between;\r\n  color: #6B707B;\r\n  font-weight: 700;\r\n  font-size: 13px;\r\n  line-height: 1.2;\r\n  margin: 0 15px 20px 15px;\r\n}\r\ndiv.A2UploadFormComp-header-fileObj{\r\n  font-weight: 400;\r\n}\r\n.A2UploadFormComp-header-i{\r\n  margin-left: 10px;\r\n}\r\n.A2UploadFormComp-header-back{\r\n  margin-right: 10px;\r\n}\r\n.A2UploadFormComp-tableHeader{\r\n  color: #6B707B;\r\n  font-size: 13px;\r\n  line-height: 20px;\r\n  padding-top: 15px;\r\n  padding-bottom: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
