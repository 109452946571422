import * as mapboxgl from "maplibre-gl";
import {MapMouseEvent, Point, PointLike} from "maplibre-gl";
import {AddrContour, GeometryUtils, ISimpleGeometry, SimpleGeometryType} from "../../../helper/utils/GeometryUtils";
import {CustomEditGeometryTool} from "./CustomEditGeometryTool";
import {ViewGeometryLayersTool} from "./ViewGeometryLayersTool";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import {GActionTransactionType} from "../../../helper/geometryAction/IGeometryAction";

export class DeleteContourTool extends CustomEditGeometryTool {

    viewGeometryLayersTool: ViewGeometryLayersTool;

    onInstall() {
        super.onInstall();

    }

    contourAddr: AddrContour;

    lineByClickOrNull(point: Point): AddrContour{
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(point.x - 3, point.y - 3),
            new mapboxgl.Point(point.x + 3, point.y + 3)
        ];

        let res = this.map.queryRenderedFeatures(bbox, {layers: [this.viewGeometryLayersTool.GEOMETRY_LINES_LAYER_ID]});
        if (res.length > 0){
            let json = res[0].properties[ViewGeometryLayersTool.ContourAddrProperty];
            return JSON.parse(json);
        }
        return null;
    }

    onMouseMove(e: MapMouseEvent & ToolEvent): void {
        if (this.state?.events?.isDeleteContour && this.state.events.isDeleteContour()) {
            e.cursor = "default";

            if (this.state.simpleGeometry != null) {
                let gia = this.state.simpleGeometry;
                let addr = this.lineByClickOrNull(e.point);
                //над линией
                if (addr != null) {
                    this.state.highlightContourAddr = addr;
                    this.updateGeometry();
                } else {
                    addr = GeometryUtils.getAddrContourByPoint(e.lngLat, gia);
                    if (addr != null && (this.contourAddr == null || !GeometryUtils.isEqualAddrContour(this.contourAddr, addr))) {
                        this.state.highlightContourAddr = addr;
                        this.updateGeometry();
                    }
                }


                if (addr == null) {
                    this.state.highlightContourAddr = null;
                    this.updateGeometry();
                }
                else e.cursor = "pointer";
                if (!GeometryUtils.isEqualAddrContour(this.contourAddr, addr)) {
                    this.contourAddr = addr;
                    this.updateGeometry();
                }
            }
        }
    }

    onMouseClick(e: MapMouseEvent & ToolEvent): void {
        if (this.state?.events?.isDeleteContour && this.state.events.isDeleteContour()) {
            let gia = this.state.simpleGeometry;
            let addr = this.lineByClickOrNull(e.point);//проверяем линии
            if (addr == null) addr = GeometryUtils.getAddrContourByPoint(e.lngLat, gia);
            if (addr != null) {
                var tr = this.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.deleteContour);
                if (addr[1] == 0){
                    tr.deleteGeometry(addr[0]);
                }else
                    tr.deleteHole(addr);
                //GeometryUtils.deleteContourByAddr(gia, addr);
                this.callChange();
                this.callDeleteRing(e);
            }
            e.noPropagation();
        }
    }


}