import * as React from 'react';
import {observer} from "mobx-react";
import {ContextMenuCommonComp, PopupCorner} from "../../../Common/ContextMenuCommonComp";
import {isFunction} from "lodash-es";
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {IndexByPointGraphPointStore} from "../../../../store/indeByPoint/IndexByPointGraphPointStore";

export interface IIndexByPointDescriptionEditorComp extends  IStoreProps{
    point: IndexByPointGraphPointStore,
    onClose: ()=>void
}

@observer
export class IndexByPointDescriptionEditorComp extends React.Component<IIndexByPointDescriptionEditorComp, undefined> {
    constructor(props: IIndexByPointDescriptionEditorComp) {
        super(props);
        autoBindReact(this);
    }
    onChangeDesc(event: any){
        this.props.point.description = event.target.value;
    }

    close(){
        if (isFunction(this.props.onClose)) this.props.onClose();
    }

    render() {
        let store = this.props.store;
        return <ContextMenuCommonComp
            autoCornerWidth={350}
            autoCornerHeight={50}
            popupCorner={PopupCorner.rightBottom}
            className="contextMenuCommon IndexByPointDescription-popup"
            indentVertical={2}
            indentHorizontal={2}
            parentClickNotIgnore={true}
            onClose={this.close}>
            <div className="flex-columns">
                <div className="flex-stretch-item IndexByPointDescription-inputDiv">
                    <input type="text" className="IndexByPointDescription-inputText width100" placeholder={store.trans.Description}
                       value={this.props.point.description} min={0} onChange={this.onChangeDesc}/>
                </div>
                <div className="IndexByPointDescription-button full-center-content" onClick={this.close}>
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.625 3.375L3.75 6.5L9.375 0.875" stroke="#C5C5C5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
        </ContextMenuCommonComp>;
    }
}
