import * as React from 'react';
import {CustomSvg} from "./CustomSvg";

export class InfoSVG extends CustomSvg {

    render() {
        return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className={this.getClasses()} xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM6 10.5C3.525 10.5 1.5 8.475 1.5 6C1.5 3.525 3.525 1.5 6 1.5C8.475 1.5 10.5 3.525 10.5 6C10.5 8.475 8.475 10.5 6 10.5Z" fill="#6D7785"/>
            <path d="M6.75 5.25H5.25V9H6.75V5.25Z" fill="#6D7785"/>
            <path d="M6 4.5C6.41421 4.5 6.75 4.16421 6.75 3.75C6.75 3.33579 6.41421 3 6 3C5.58579 3 5.25 3.33579 5.25 3.75C5.25 4.16421 5.58579 4.5 6 4.5Z" fill="#6D7785"/>
        </svg>;
    }
}
