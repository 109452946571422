import React, { useState } from "react";
import { A2ExportFormatComp } from "../../Left/Agro2/A2Common/A2ExportFormatComp";
import { A2ExportFormat } from "../../../../store/agro/A2AgroStore";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import { Utils } from "../../../../helper/utils/Utils";

export interface ISurveyPointsEditorCompProps extends IStoreProps {
}

const SurveyPointsEditorComp = (props: ISurveyPointsEditorCompProps) => {
    let st = props.store;
    const [num, setNum] = useState(st.map.surveyPoints.editingPoint.properties.num);
    const [comment, setComment] = useState(st.map.surveyPoints.editingPoint.properties.comment);
    const [failed, setFailed] = useState(false);

    const onCancel = () => {
        st.map.surveyPoints.editingPoint = null;
    }    

    const onSave = () => {
        let nmbr = Utils.parsePositiveInt(num);
        if (isNaN(nmbr) || st.map.surveyPoints.numExists(num)) {
            setFailed(true);
            return;
        }
        let prps = {...st.map.surveyPoints.editingPoint.properties, num: nmbr, comment: comment};
        st.map.superTools.surveyPointsTool.updatePoint({...st.map.surveyPoints.editingPoint, properties: prps});
        st.map.surveyPoints.editingPoint = null;
    }

    return <A2ExportFormatComp formats={[]} onCancel={ onCancel } onSubmit={ onSave } store={st}
            footerClass="SurveyPointsExportComp" buttonClass="SurveyPointsExportComp"
            title={st.trans["Edit an inspection point"]} submitText={st.trans.Save}>
        <div>
            <div className="Forms_filenameText">{st.trans["Number"]}</div>
            <input type="text" className={classNames("text-box-editor width100", {"red_border": failed})}
                    maxLength={4} value={num} onChange={e => {setFailed(false); setNum(e.target.value)}}/>
            <div className={classNames("SurveyPointsComp-point-num-warning", {"warning": failed})}>{st.trans["The number already exists"]}</div>
            <div className="Forms_filenameText">{st.trans["Comment"]}</div>
            <input type="text" className="text-box-editor width100"
                    value={comment} onChange={e => setComment(e.target.value)}/>
        </div>
    </A2ExportFormatComp>;
}

export default SurveyPointsEditorComp;