import {IPaint} from "./styleInterface/IPaint";

export const StyleDefaultMaxZoom = 30;
export const StyleDefaultMinZoom = 0;


export function getDefaultIPaint(): IPaint{
    return {
        point: {
            //visible: false,
            size: 1,
            color: "#000000",
            "stroke-color": "#000000",
            "stroke-width": 0,
            type: "circle",
            opacity: 1,
            imgUrl: null,
            maxZoom: 30,
            minZoom: 0
        },
        polygon:{
            contour:{
                //visible: true,
                color: "#000000",
                opacity: 1,
                width: 1,
                maxZoom: 30,
                minZoom: 0
            },
            fill: {
                //visible: true,
                color: "#000000",
                opacity: 1,
                maxZoom: 30,
                minZoom: 0
            }
        },
        polyline:{
            //visible: true,
            color: "#000000",
            width: 1,
            opacity: 1,
            maxZoom: 30,
            minZoom: 0
        },
        label:{
            //visible: false,
            color: "#ffffff",
            "halo-color": "#000000",
            "halo-width": 0,
            //maxZoom: 30,
            //minZoom: 0,
            text: "",
            size: 8,
        }

    }
}