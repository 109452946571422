import * as React from 'react';
import {CustomSvg} from "../../../../icons/CustomSvg";

export class SmallCenterToSvg extends CustomSvg {

    render() {
        return <svg width="12" height="12" viewBox="0 0 12 12" className={this.getClasses()} style={this.getStyles()}  xmlns="http://www.w3.org/2000/svg">
            <path d="M6 8.25C7.24264 8.25 8.25 7.24264 8.25 6C8.25 4.75736 7.24264 3.75 6 3.75C4.75736 3.75 3.75 4.75736 3.75 6C3.75 7.24264 4.75736 8.25 6 8.25Z"  stroke="none" />
            <path d="M1.5 4.5H0V0.75C0 0.3 0.3 0 0.75 0H4.5V1.5H1.5V4.5Z" stroke="none" />
            <path d="M12 4.5H10.5V1.5H7.5V0H11.25C11.7 0 12 0.3 12 0.75V4.5Z" stroke="none" />
            <path d="M11.25 12H7.5V10.5H10.5V7.5H12V11.25C12 11.7 11.7 12 11.25 12Z" stroke="none" />
            <path d="M4.5 12H0.75C0.3 12 0 11.7 0 11.25V7.5H1.5V10.5H4.5V12Z" stroke="none" />
        </svg>;
    }
}

export class CenterToSvg extends CustomSvg {
    render() {
        return <svg width="16" height="16" viewBox="0 0 16 16" className={this.getClasses()} style={this.getStyles()} xmlns="http://www.w3.org/2000/svg">
            <path d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" stroke="none"/>
            <path d="M2 6H0V1C0 0.4 0.4 0 1 0H6V2H2V6Z" stroke="none"/>
            <path d="M16 6H14V2H10V0H15C15.6 0 16 0.4 16 1V6Z" stroke="none"/>
            <path d="M15 16H10V14H14V10H16V15C16 15.6 15.6 16 15 16Z" stroke="none"/>
            <path d="M6 16H1C0.4 16 0 15.6 0 15V10H2V14H6V16Z" stroke="none"/>
        </svg>;
    }    
}