import { observable } from "mobx";
import { CustomStore } from "./CustomStore";
import { save } from "./PermalinkDecor";
import { ContainerTools } from "./tools/general/ContainerTools";
import { Feature } from "geojson";
import { MeasuringStatus } from "../helper/structs/MeasuringStatus";
import { v4 } from "uuid";

export class SurveyPointsStore extends CustomStore {
    class(): string {return "SurveyPointsStore";}

    @save @observable
    points: GeoJSON.FeatureCollection = {...ContainerTools.EMPTY_SOURCE};
    
    
    numExists(num: number) : boolean {
        if (! this.editingPoint) return false;
        let fts = this.points.features;
        for (let i = 0; i < fts.length; i++) {
            if (fts[i].properties.num == num && fts[i].properties.id != this.editingPoint.properties.id)
                return true;
        }
        return false;
    }

    @save
    lastPointNumber: number = 1;
 
    @observable
    showExport: boolean = false;

    @observable
    editingPoint: Feature = null;

    @save @observable
    private _showPanel: boolean = false;
    public get showPanel(): boolean {
        return this._showPanel;
    }
    public set showPanel(value: boolean) {
        this._showPanel = value;
        let map = this.root.map;
        if (!value && map.measuringStatus == MeasuringStatus.surveyPoint)
            map.resetMeasuringStatus();            
    }

    public createNewPoint(coos: number[], xy?: number[]) : Feature {
        return {"type": "Feature",            
            "properties": {"id": v4(), "num": this.lastPointNumber++, "comment": "", "xy": xy}, 
            "geometry": {"type": "Point", "coordinates": coos}};
    }
}