import { useState } from "react";
import { DropDownComp, IDropDownItem } from "../DropDownComp";
import React from "react";
import isFunction from "lodash-es/isFunction";
import './DropDownRangeSelector.scss'

export interface IRangeValues {
    value1: any;
    value2: any;
}

interface IDropDownRangeSelectorProps {
    currentValue: IRangeValues;
    values1: any[];
    values2? : any[];
    onChange?: (value: any) => void;
}

const DropDownRangeSelector = (props: IDropDownRangeSelectorProps) => {
    const {currentValue} = props;
    const [value, setValue] = useState(currentValue);

    const onValue1Changed = (item: IDropDownItem) => {
        let newVal = {...value, value1: {code: item.key}};
        setValue(newVal);
        if (isFunction(props.onChange)) props.onChange(newVal);
    }

    const onValue2Changed = (item: IDropDownItem) => {
        let newVal = {...value, value2: {code: item.key}};
        setValue(newVal);
        if (isFunction(props.onChange)) props.onChange(newVal);
    }

    return <div className="DropDownRangeSelector-main">
        <DropDownComp currentKey={value.value1.code} items={props.values1}
            onChange={onValue1Changed} className={'DropDownRangeSelector-dropdown'}>
        </DropDownComp>
        <div className="DropDownRangeSelector-divider"/>
        <DropDownComp currentKey={value.value2.code} items={props.values2 || props.values1}
            onChange={onValue2Changed} className={'DropDownRangeSelector-dropdown'}>
        </DropDownComp>
    </div>;
}

export default DropDownRangeSelector;