import {action, observe} from "mobx";
import {observer} from "mobx-react";
import * as React from "react";
import {IRshbReportStoreProps} from "../../rshbReport/rshbPlugin";
import {IAgroExportStoreProps} from "../agroExportPlugin";
import classNames from "classnames";
import autoBindReact from "auto-bind/react";
import {IndexBandProductStore, Interpolation} from "../../../app/store/productSetting/IndexBandProductStore";
import {ProductSettingStore} from "../../../app/store/productSetting/ProductSettingStore";
import {ActiveProductCodeStore} from "../../../app/store/productSetting/ActiveProductCodeStore";
import {ProductItemIndexBandComp} from "../../../app/components/panels/Left/NdviPanel/ProductItemIndexBandComp";
import {ISuperStore} from "../../../pluginApi/store/SuperStore";
import {LeftPanelMode} from "../../../app/store/SearchStore";
import {ProductItemExportComp} from "./ProductItemExportComp";

interface IAgroExportProductComp extends IAgroExportStoreProps{
    item: ProductSettingStore,
    productCode: ActiveProductCodeStore
}
enum ErrorExport{
    ok = 'ok',
    scenes = 'scenes',
    area = 'area'
}
@observer
export class AgroExportProductComp extends React.Component<IAgroExportProductComp, undefined>  {
    constructor(props: IAgroExportProductComp) {
        super(props);
        autoBindReact(this);
    }
    @action
    onExport(){
        if (this.canExport() != ErrorExport.ok) return;
        let item = this.props.item;
        let exp = this.props.store.agroExport.getIndexBandExportStore(item.prod_name);
        exp.show = true;
    }
    canExport(): ErrorExport{
        let item = this.props.item;
        let store = this.props.store;
        if (store.map.searchObject.isEmpty) return ErrorExport.area;
        if (ProductItemIndexBandComp.getScenes(store).length == 0) return ErrorExport.scenes;
        return ErrorExport.ok;
    }

    render(){
        let store = this.props.store;
        let item = this.props.item;
        let exp = store.agroExport.getIndexBandExportStore(item.prod_name);
        let index: IndexBandProductStore = this.props.item.indexBand;

        let viewDownloadButton = index.interpolation == Interpolation.discrete && store.config.download_index_band_discrete;
        let errExport = this.canExport();

        return <React.Fragment>
            {exp.show && <ProductItemExportComp store={store} prod={this.props.item} index={index} exp={exp}/> }
            {viewDownloadButton && <div onClick={this.onExport} style={{width:"40px", minWidth:"40px", height:"40px"}} className={
                classNames("ProductItemPropComp-button",
                    "blue-fill pointer",{"opacity30 def-cursor": this.canExport() != ErrorExport.ok},
                    "full-center-content")}>
                <svg width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.99961 12C7.29961 12 7.49961 11.9 7.69961 11.7L13.3996 6L11.9996 4.6L7.99961 8.6V0H5.99961V8.6L1.99961 4.6L0.599609 6L6.29961 11.7C6.49961 11.9 6.69961 12 6.99961 12Z"/>
                    <path d="M14 14H0V16H14V14Z" />
                </svg>
            </div>}
            <div className="flex-stretch-item" />
            {viewDownloadButton && (errExport == ErrorExport.area) && <div className="ProductItemIndexBandComp-exportDescrption">{store.trans["For export, set the active area"]}</div>}
            {viewDownloadButton && (errExport == ErrorExport.scenes) && <div className="ProductItemIndexBandComp-exportDescrption">{store.trans["Select scenes to export"]}</div>}
        </React.Fragment>;
    }
}