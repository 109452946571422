import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import classNames from "classnames";
import {action} from "mobx";
import ReactDatePicker from "react-datepicker";
import {CustomInputComponent, ReactDoubleDatePicker2Comp} from "../../../../Common/ReactDoubleDatePicker2Comp";
import {A2FieldEditorCustomInputComp, IA2FieldEditorProps} from "./A2FieldEditorCustomInputComp";

@observer
export class A2FieldEditorDateComp extends A2FieldEditorCustomInputComp {
    constructor(props: IA2FieldEditorProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onChangeDate(date: Date){
        this.props.field.properties[this.props.columnInfo.column_name] = date;
        this.props.field.changedColumns.add(this.props.columnInfo.column_name);
        if (this.props.onChange) this.props.onChange(this.props.data);
    }

    render() {
        let store = this.props.store;
        let v = this.props.field.properties[this.props.columnInfo.column_name];
        let d: Date = null;
        if (v != null){
            if (v instanceof Date) d = v;
            else d = new Date(v);
        }
        let pref = this.getPreference();

        let placeHolder = "YYYY-MM-DD";
        if (pref.isDiffValuesLabel) placeHolder = this.props.store.trans["Different values"];
        if (pref.isClearValue) placeHolder = this.props.store.trans["Clear value"];


        return <div className="relative flex-columns Agro2CreateField-dateInput">
                <ReactDatePicker
                    locale={this.props.store.getLocale()}
                    wrapperClassName="flex-strech-item"
                    tabIndex={1}
                    placeholderText={placeHolder}
                    readOnly={this.props.isReadonly === true}
                    className={classNames("text-box-editor width100 Agro2CreateField-inputDark",
                        {
                            //"Agro2CreateField-greenBorder": pref.isChanged,
                            "Agro2CreateField-inputClearValue": pref.isClearValue,
                            "Agro2CreateField-inputWithExtButton": this.props.isMultiEdit,
                            "red_border":this.props.validType != null
                        })}
                    onChange={date => this.onChangeDate(date as Date)}
                    selected={d}
                    monthsShown={1}
                    dateFormat={ReactDoubleDatePicker2Comp.FORMAT}
                    showYearDropdown
                    yearDropdownItemNumber={5}
                    scrollableYearDropdown
                    popperPlacement="bottom-end"
                    showPopperArrow={false}
                    /*customInput={
                        <CustomInputComponent
                            //onClick={this.onFocusBegin}
                            className='date-input date-input-item1'
                        />
                    }*/
                />
                {(pref.hasClearButton || pref.hasReturnButton) && <div className="Agro2CreateField-inputExtButtonDiv">
                    {pref.hasClearButton && <div className="Agro2CreateField-inputExtButtonSize" onClick={this.onClickClear}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z" fill="#C5C5C5"/>
                        </svg>
                    </div>}
                    {pref.hasReturnButton && <div className="Agro2CreateField-inputExtButtonSize" onClick={this.onClickReturn}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.25 9.975L6.475 8.75L3.85 6.125H8.75C10.2375 6.125 11.375 7.2625 11.375 8.75C11.375 10.2375 10.2375 11.375 8.75 11.375H1.75V13.125H8.75C11.2 13.125 13.125 11.2 13.125 8.75C13.125 6.3 11.2 4.375 8.75 4.375H3.85L6.475 1.75L5.25 0.525002L0.525002 5.25L5.25 9.975Z" fill="#C5C5C5"/>
                        </svg>
                    </div>}
                </div>}
            </div>;
    }
}
