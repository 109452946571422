import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ShowRoom2Layer} from "./ShowRoom2Layer";

@observer
export class ShowRoom2Multiyear extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let sr = store.dataShowRoomStore;
        return <div>
            <ShowRoom2Layer store={store} layer={sr.ccePoints} >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" style={{marginRight: "3px"}} xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="12" height="12" rx="2" fill="white"/>
                        <rect x="2" y="2" width="8" height="8" rx="1" fill="#6B707B"/>
                    </g>
                </svg>
            </ShowRoom2Layer>
            <ShowRoom2Layer store={store} layer={sr.classPoints} >
                <svg width="12" height="12" viewBox="0 0 12 12" style={{marginRight: "3px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="12" height="12" rx="6" fill="white"/>
                        <rect x="2" y="2" width="8" height="8" rx="4" fill="#6B707B"/>
                    </g>
                </svg>
            </ShowRoom2Layer>
            <ShowRoom2Layer store={store} layer={sr.yieldAssessmentPoints} >
                <svg width="14" height="14" viewBox="0 0 14 14" style={{marginRight: "3px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M3.71131 2.30383C3.88994 1.99443 4.22007 1.80383 4.57734 1.80383L9.42263 1.80383C9.7799 1.80383 10.11 1.99443 10.2887 2.30383L12.7113 6.49999C12.8899 6.80939 12.8899 7.19058 12.7113 7.49999L10.2887 11.6961C10.11 12.0055 9.7799 12.1961 9.42263 12.1961L4.57734 12.1961C4.22007 12.1961 3.88994 12.0055 3.71131 11.6961L1.28866 7.49999C1.11003 7.19058 1.11003 6.80939 1.28866 6.49999L3.71131 2.30383Z" fill="#6B707B" stroke="white" strokeWidth="2"/>
                </svg>
            </ShowRoom2Layer>
            <ShowRoom2Layer store={store} layer={sr.districts} >
            </ShowRoom2Layer>
        </div>;
    }
}
