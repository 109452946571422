import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {ISecurityInfo} from "../../../../../store/agro/A2ProjectEditorStore";
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {ProjectRole} from "../../../../../store/agro/A2PermissionStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {action, computed} from "mobx";
import {CustomConfirmStore} from "../../../../../store/CustomConfirmStore";
import {ConfirmPopupComp} from "../../../../Common/ConfirmPopupComp";

interface IAgroProjectSecurityRowComp extends IStoreProps{
    item: ISecurityInfo;
    isOwner: boolean;
}
@observer
export class AgroProjectSecurityRowComp extends React.Component<IAgroProjectSecurityRowComp, undefined> {
    constructor(props: IAgroProjectSecurityRowComp) {
        super(props);
        autoBindReact(this);
    }

    popupDeleteStore: CustomConfirmStore = new CustomConfirmStore(null);

    getPermComp(row: ISecurityInfo): any{
        let store = this.props.store;
        let items: IDropDownItem[] = [
            {key: ProjectRole.view, value: TranslateUtils.getProjectRoleTitle(ProjectRole.view, store)},
            {key: ProjectRole.edit, value: TranslateUtils.getProjectRoleTitle(ProjectRole.edit, store)},
            {key: ProjectRole.admin, value: TranslateUtils.getProjectRoleTitle(ProjectRole.admin, store)}
        ];

        return <DropDownComp items={items} currentKey={row.perm} className="AgroProjectSecurityComp-role DropDowm-DarkBack"
                             classNameDown="DropDowm-DarkBack"
                             onChange={async (item: IDropDownItem, newIndex: number)=>{
                                 await this.changePermision(row, item.key as any);
                             }} />;
    }

    async changePermision(row: ISecurityInfo, new_perm: ProjectRole){
        try {
            await this.props.store.agro2.projectEditor.sendUpdatePerm(row.user_id, new_perm);
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    async clickDelete(row: ISecurityInfo){
        try{
            let store = this.props.store;
            store.agro2.projectEditor.proj_users = store.agro2.projectEditor.proj_users.filter(a => a.user_id != row.user_id);
            await store.agro2.projectEditor.sendDeleteUserFromProject(row.user_id);
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    onClickOpenPopupDelete(){
        this.popupDeleteStore.doShow();
    }

    render() {
        let store = this.props.store;
        let projEditor = store.agro2.projectEditor;
        let isOwner = this.props.isOwner;
        let a = this.props.item;

        return <div className="AgroProjectSecurityComp-row AgroProjectSecurityComp-row-data" key={a.user_id}>
            <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Name">
                {a.surname} {a.given_name}
            </div>
            <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Email">
                {a.email}
            </div>
            {isOwner && <React.Fragment>
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Perm">{store.trans["Project owner"]}</div>
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Del"></div>
            </React.Fragment>}
            {!isOwner && <React.Fragment>
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Perm">
                    {this.getPermComp(a)}
                    {projEditor.updated_user_id.has(a.user_id) && <div className="AgroProjectSecurityComp-updatedItem">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.4001 1.99998L8.0001 0.599976L4.0001 4.59998L2.0001 2.59998L0.600098 3.99998L4.0001 7.39998L9.4001 1.99998Z" fill="#6ABC4D"/>
                        </svg>
                    </div>}
                </div>
                <div className="AgroProjectSecurityComp-col-Common AgroProjectSecurityComp-col-Del">
                    <div className="flex-full-center AgroProjectSecurityComp-trash white_svg red_svg_onhover red_border_onhover pointer" onClick={this.onClickOpenPopupDelete}>
                        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0002 4.375V2.625H10.5002V0.875C10.5002 0.392 10.1091 0 9.62524 0H4.37524C3.89137 0 3.50024 0.392 3.50024 0.875V2.625H0.000244141V4.375H14.0002ZM5.25024 1.75H8.75024V2.625H5.25024V1.75Z" stroke="none"/>
                            <path d="M11.3752 6.125V12.25H2.62524V6.125H0.875244V13.125C0.875244 13.608 1.26637 14 1.75024 14H12.2502C12.7341 14 13.1252 13.608 13.1252 13.125V6.125H11.3752Z" stroke="none"/>
                        </svg>
                        <ConfirmPopupComp text={store.trans["Are you sure you want to delete the user?"]} confirmStore={this.popupDeleteStore}
                                          classNameOkButton="red-text"
                                          okText={store.trans.Delete} cancelText={store.trans.Cancel}
                                          clickOk={() => {
                                              this.clickDelete(this.props.item);
                                          }
                                          } >
                        </ConfirmPopupComp>
                    </div>
                </div>
            </React.Fragment>}
        </div>;
    }
}
