import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";

export interface IOrderActionCancelCompProps  extends IStoreProps{
    order_id: any;
}

@observer
export class OrderActionCancelComp extends React.PureComponent<IOrderActionCancelCompProps, undefined> {
    constructor(props: IOrderActionCancelCompProps) {
        super(props);
        autoBindReact(this);
    }

    async clickCancel(){
        try {
            await this.props.store.orderList.sendCancelOrder(this.props.order_id);
            this.props.store.addInfo(this.props.store.trans["Canceled successfully"]);
            this.props.store.orderList.doLoadList();
        }catch (e){
            this.props.store.addError(e);
        }
    }

    render() {
        let store = this.props.store;
        return <div className="journal-action journal-status" onClick={this.clickCancel}>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM10.0625 8.8375L8.8375 10.0625L7 8.225L5.1625 10.0625L3.9375 8.8375L5.775 7L3.9375 5.1625L5.1625 3.9375L7 5.775L8.8375 3.9375L10.0625 5.1625L8.225 7L10.0625 8.8375Z" />
            </svg>
            <div className="journal-status-name underline-link">{store.trans.Cancel}</div>
        </div>;
    }
}
