import {CustomStore} from "./CustomStore";
import {Feature, Geometry} from "@turf/helpers";
import {ISearchPropertiesRest} from "./SearchStore";
import {observable} from "mobx";
import {save} from "./PermalinkDecor";
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import { Satellite } from "../helper/utils/satellliteDic";

export class SearchItemStore extends CustomStore{
    class(): string {return "SearchItemStore";}

    @save @observable
    feature: Feature<Geometry, ISearchPropertiesRest>;
    @save @observable
    selected: boolean = false;
    @observable
    animate_fav_icon: boolean = false;

    @save
    public copyId: string = null;

    public get dayGroupId() : string{
        return this.feature.properties.scene_type + "_" + this.feature.properties.acqdate + (this.copyId? `#${this.copyId}` : '');
    }
    
    get satellite(): Satellite {
        return this.feature?.properties?.satellite;
      }
      
    public sceneId(): string{
        return this.feature.properties.scene_id;
    }

    public get bbox(): BBox2d {
        let meta = this.feature.properties
        return [Math.min(meta.bl_x, meta.tl_x), Math.min(meta.bl_y, meta.br_y),
                            Math.max(meta.tr_x, meta.br_x), Math.max(meta.tr_y, meta.tl_y)]
    }
}
