import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import classNames from "classnames";
import autoBindReact from "auto-bind/react";
import {LeftAuthPanelComp} from "./LeftAuthPanelComp";
import {NavLink} from "react-router-dom";

@observer
export class SignInPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action async onSubmit(event: any){
        event.preventDefault();
        if (this.props.store.user.formAuthStatus == LoadStatus.loading) return false;
        await this.props.store.user.requestLogin();
        return false;
    }

    @action onChangeLogin(event: any){
        this.props.store.user.formLogin = event.target.value;
    }
    @action onChangePassword(event: any){
        this.props.store.user.formPassword = event.target.value;
    }
    @action clickSignUp(){
        //this.props.store.user.authFormShow = false;
        this.props.store.gotoUrl("/signup");
    }

    render() {
        let store = this.props.store;
        return <div className="authorize-background">
            <div className="authorize-background2">
                <LeftAuthPanelComp store={store} />
                <div className="authorize-d2 style-4">
                    <form className="authorize-form authorize-form-width UserForm-title-marginTop" onSubmit={this.onSubmit} autoComplete="off">
                        <div className="authorize-in-right__row1 ">{store.trans["Sign in"]}</div>

                        <div className="authorize-in-right__row_label-in">
                            <div className="authorize-in-right__row_label-left">
                                {store.trans.User}
                            </div>
                            <div className="authorize-in-right__row_label-right gray-text">{store.trans["Login or Email"]}</div>
                        </div>
                        <input type="text" name="Email" className="authorize-in-right__row-2-3" onChange={this.onChangeLogin} value={store.user.formLogin} />
                        <div className="authorize-in-right__row_label-in">
                            <div className="authorize-in-right__row_label-left">{store.trans.Password}</div>
                            <div className="authorize-in-right__row_label-right"><NavLink to="/forgot" className="link-text link-noUnderline abc">{store.trans["Forgot password"]}</NavLink></div>
                        </div>
                        <input type="Password" name="Password" className="authorize-in-right__row-2-3"  onChange={this.onChangePassword} value={store.user.formPassword} />
                        <button className={classNames("authorize-submit-form", {"opacity20": this.props.store.user.formAuthStatus == LoadStatus.loading})}
                                type="submit">{store.trans["Log in"]}</button>
                        <div className="authorize-in-right__row_label-in authorize-footer-marginTop ">
                            <div className="authorize-in-right__row_label-left not-yet-with-us">{store.trans["Not yet with us?"]}</div>
                            <div className="authorize-in-right__row_label-right not-yet-with-us-line">&nbsp;</div>
                        </div>
                    </form>
                    <button className="reg-now authorize-form-width" onClick={this.clickSignUp}>{store.trans["Sign up"]}</button>

                    <div className="authorize-form-zatyk" />
                </div>
            </div>
        </div>;
    }
}
