import {CustomStore} from "../../CustomStore";
import {observable} from "mobx";
import {ZalZakluchenieStore} from "./ZalZakluchenieStore";
import {update} from "../../PermalinkDecor";
import {fetchJsonPost} from "../../../helper/utils/FetchUtils";
import {ra} from "../../../helper/utils/mobxUtils";

export class ZalogiStore extends CustomStore{
    @update
    zakluchenie = new ZalZakluchenieStore(this);

    async changeStatus(new_status: number, req_id: number){
        let url = `/api/projects/${this.root.agro.projectName}/reference/request/modify`;
        await fetchJsonPost(url, {values: [{
                req_id: req_id,
                req_status_id: new_status
            }]
        });
        ra(()=>{
            let navigatorItem = this.root.agro2.projectStruct.activeNavigatorPage;
            if (navigatorItem != null) navigatorItem.resetValues();
        });
    }
}