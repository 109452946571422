import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {computed, observable} from "mobx";
import {ProductSettingStore} from "./ProductSettingStore";
import {ProductSetStore} from "./ProductSetStore";
import cloneDeep from "lodash-es/cloneDeep";

export class ActiveProductCodeStore extends CustomStore{

    @save @observable productCode: string = "nat-c";

    setProductCodeWithExpand(productCode: string){
        let ff = this.products.find(a => a.prod_name == productCode);
        if (ff == null){
            throw `Product "${productCode}" is not found`;
            //return;
        }
        this.productCode = productCode;
        this.products.filter(a => a.prod_name != productCode).forEach(a => {
            a.expand = false;
        });
    }

    get productSetStore(): ProductSetStore{
        return (this.parent as ProductSetStore);
    }

    @computed
    get currentProductStore(): ProductSettingStore{
        //return this.productSetStore.getProductByProdName(this.productCode);
        return this.products.find(a => a.prod_name == this.productCode)
    }

    @save @observable     
    private _products : ProductSettingStore[] = [];
    public get products() : ProductSettingStore[] {
        // if (! this._products) {
        //     let ps : ProductSettingStore[] = [];
        //     this.productSetStore.products.forEach(p => ps.push({...p} as ProductSettingStore));
        //     this._products = ps;
        // }
        // // if (! this._products)
        // //     this._products = cloneDeep(this.productSetStore.products); //SLOW
        return this._products;
    }
}