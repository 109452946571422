import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ProductSettingStore} from "../../../../store/productSetting/ProductSettingStore";
import "./ProductCss.scss";
import {action} from "mobx";
import classNames from "classnames";
import {BandStaticStyleProductStore} from "../../../../store/productSetting/BandStaticStyleProductStore";
import {InputNumber} from "../../../Common/InputNumber";

export interface IProductItemPropStaticBandComp extends IStoreProps{
    band: BandStaticStyleProductStore,
    item: ProductSettingStore,
    index: number,
    readonly: boolean
}
@observer
export class ProductItemPropStaticBandComp extends React.Component<IProductItemPropStaticBandComp, undefined> {
    constructor(props: IProductItemPropStaticBandComp) {
        super(props);
        autoBindReact(this);
    }
    @action
    onChangeMin(num: number){
        this.props.band.min = num;
        this.props.item.changed = true;
    }
    @action
    onChangeMax(num: number){
        this.props.band.max = num;
        this.props.item.changed = true;
    }

    render() {
        let store = this.props.store;
        let band = this.props.band;
        let color = {background: "#DA5D5D"};
        if (this.props.index == 1) color = {background: "#76DA5D"};
        if (this.props.index == 2) color = {background: "#4DA7EE"};

        return <div className={classNames("ProductItemPropStaticBandComp flex-columns", {"readonly": this.props.readonly})}>
            <span className="ProductItemPropStaticBandComp-icon" style={color} />
            <span className="flex-stretch-item text-white">{band.name}</span>
            <InputNumber className="ProductItemPropStaticBandComp-inputNum blue-border-onfocus"
                   disabled={this.props.readonly} changeOnExitOrEnter={true}
                   value={band.min} min={1} max={10000} onChangeNumber={this.onChangeMin}/>
            <span className="ProductItemPropStaticBandComp-delimeter">-</span>
            <InputNumber className="ProductItemPropStaticBandComp-inputNum blue-border-onfocus"
                   disabled={this.props.readonly} changeOnExitOrEnter={true}
                   value={band.max} min={1} max={10000} onChangeNumber={this.onChangeMax}/>
        </div>;
    }
}
