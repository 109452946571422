import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoDel3SvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoDel3Svg extends React.PureComponent<IAgroAhoDel3SvgProps, undefined> {
    constructor(props: IAgroAhoDel3SvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
            width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.875 13.125C1.87648 13.6218 2.0745 14.0979 2.42581 14.4492C2.77712 14.8005 3.25318 14.9985 3.75 15H11.25C11.7468 14.9985 12.2229 14.8005 12.5742 14.4492C12.9255 14.0979 13.1235 13.6218 13.125 13.125V5.625H1.875V13.125Z" fill="#E94F4F"/>
            <path d="M14.0625 2.8125H10.3125V0.9375C10.3125 0.68886 10.2137 0.450403 10.0379 0.274587C9.8621 0.0987721 9.62364 0 9.375 0L5.625 0C5.37636 0 5.1379 0.0987721 4.96209 0.274587C4.78627 0.450403 4.6875 0.68886 4.6875 0.9375V2.8125H0.9375C0.68886 2.8125 0.450403 2.91127 0.274587 3.08709C0.0987721 3.2629 0 3.50136 0 3.75C0 3.99864 0.0987721 4.2371 0.274587 4.41291C0.450403 4.58873 0.68886 4.6875 0.9375 4.6875H14.0625C14.3111 4.6875 14.5496 4.58873 14.7254 4.41291C14.9012 4.2371 15 3.99864 15 3.75C15 3.50136 14.9012 3.2629 14.7254 3.08709C14.5496 2.91127 14.3111 2.8125 14.0625 2.8125ZM6.5625 1.875H8.4375V2.8125H6.5625V1.875Z" fill="#E94F4F"/>
        </svg>;
    }
}
