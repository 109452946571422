import {IndexByPointerSourceCustomStore} from "./IndexByPointerSourceCustomStore";
import {action, observable, runInAction} from "mobx";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {Utils} from "../../../helper/utils/Utils";
import {fetchJson} from "../../../helper/utils/FetchUtils";
import {IGraphData, IGraphDataInfo, IResponseNdvi500Modis} from "../IndexByPointGraphStore";

export class IndexByPointerSourceVegIndex500Store extends IndexByPointerSourceCustomStore{
    @observable
    private ndvi500Data: IResponseNdvi500Modis[] = [];

    getGraphData(): IGraphDataInfo {
        if (this.status == null) setImmediate(()=>{this.loadNdvi500()});
        let res: IGraphData[] = [];
        this.ndvi500Data.slice().forEach(a => {
            res.push({
                dayOfYear: Utils.getDayOfYearRelativeByGlobalAllLeapYears(new Date(a.date), this.parent.year),
                absoluteDate: (new Date(a.date)).getTime(),
                value: a.stat.ndvi.avg,
                sceneID: a.sceneID});
        });
        return {data: res, hasMinMax: false};
    }
    @action
    loadNdvi500(){
        let gp = this.parent.gPoint;
        if (this.parent.isClimate){
            this._status = LoadStatus.ready;
            this.ndvi500Data = [];
            return;
        }
        this._status = LoadStatus.loading;
        let {dateBegin, dateEnd} = this.parent.getDateInterval();

        let f = {"scene_type":{"$in":["S2L2A","LS8", "LS9"]},"acqdate":{"$>=":Utils.dateOnlyToISOString(dateBegin),"$<=":Utils.dateOnlyToISOString(dateEnd)}};
        fetchJson(this.root.indexByPointer.getProdStatUrl()+`?filter=${encodeURIComponent(JSON.stringify(f))}&sat=MODIS&product=ndvi&lon=${gp.point.lng}&lat=${gp.point.lat}`)
            .then(json => {
                runInAction(() =>{
                    let j: IResponseNdvi500Modis[] = json;
                    j.filter(a => a.error != null).forEach(a => {
                        this.root.addError(a.error.message);
                    });
                    this.ndvi500Data = j.filter(a => a.error == null);
                    this._status = LoadStatus.ready;
                });
            }).catch(err =>{
            runInAction(() => {
                this.root.addError(err);
                this._status = LoadStatus.ready;
            });
        });
    }

}