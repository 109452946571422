import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './PhotoFilterLeft.scss';
import {ReactDoubleDatePicker2Comp} from "../../../../Common/ReactDoubleDatePicker2Comp";
import {action, autorun} from "mobx";
import classNames from "classnames";
import {PhotoFilterLeftTagGroup} from "./PhotoFilterLeftTagGroup";
import {ProjType} from "../../../../../store/user/UserStore";
import {IReactionDisposer} from "mobx/lib/internal";

@observer
export class PhotoFilterLeft extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);

        this.props.store.photo.initTags();
        this.disp = autorun(()=>{
            this.props.store.photo.photoFilter.syncTags();
        });
    }
    disp: IReactionDisposer = null;

    componentWillUnmount() {
        if (this.disp != null) this.disp();
        this.disp = null;
    }

    @action
    onChangeTagSearch(event: any){
        this.props.store.photo.photoFilter.tagGroupFilter.filter = event.target.value;
    }


    @action
    onClearTags(){
        this.props.store.photo.photoFilter.tagGroupFilter.tagGroups.forEach(a => a.tags.forEach(b => b.checked = false));
    }

    @action
    clickUseExtandMap(){
        if (!this.enabledUseExtandMap()) return;
        this.props.store.photo.photoFilter.useExtandMap = !this.props.store.photo.photoFilter.useExtandMap;
    }

    enabledUseExtandMap(): boolean{
        return this.props.store.photo.editor.editorWindowShow;
    }

    render() {
        let store = this.props.store;
        let agro = this.props.store.agro;
        let photoFilter = this.props.store.photo.photoFilter;

        let tagCount = 0;
        photoFilter.tagGroupFilter.tagGroups.forEach(a => tagCount = tagCount + a.tagsWithFilters.length);
        let tagSelectedCount = 0;
        photoFilter.tagGroupFilter.tagGroups.forEach(a => tagSelectedCount = tagSelectedCount + a.tags.filter(b => b.checked).length);

        let tagGroups: any[] = [];
        photoFilter.tagGroupFilter.tagGroups.forEach(a => {
           if (a.tagsWithFilters.length > 0 || a.groupNameFilter.length > 0){
               tagGroups.push(<PhotoFilterLeftTagGroup group={a}  tags={a.tagsWithFilters} store={store} key={a.id} />)
           }
        });

        return <div className="PhotoFilterLeft-main">

            <div className="PhotoFilterLeft-keyValue">
                <div className="PhotoFilterLeft-keyValueKey">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0625 1.875H12.1875V0H9.375V1.875H5.625V0H2.8125V1.875H0.9375C0.42 1.875 0 2.295 0 2.8125V14.0625C0 14.58 0.42 15 0.9375 15H14.0625C14.58 15 15 14.58 15 14.0625V2.8125C15 2.295 14.58 1.875 14.0625 1.875ZM13.125 13.125H1.875V6.5625H13.125V13.125Z" fill="#C5C5C5"/>
                    </svg>
                    <span className="text-next">{store.trans.Date}</span>
                </div>
                <div className="PhotoFilterLeft-keyValueValue PhotoFilterLeft-DateFilter">
                    <ReactDoubleDatePicker2Comp
                        interval={photoFilter.filterPhotoDate} store={store} />
                </div>
            </div>

            <div className={classNames("PhotoFilterLeft-useExtand",{"opacity30": !this.enabledUseExtandMap()})}>
                    <input type="checkbox" className="switch_1" id="use_extand"
                           checked={photoFilter.useExtandMap || !this.enabledUseExtandMap()}
                           onChange={this.clickUseExtandMap}/><label
                    htmlFor="use_extand"
                    className="css-label-2">{store.trans["Use map extent"]}</label>
            </div>

            <div className="PhotoFilterLeft-keyValue">
                <div className="PhotoFilterLeft-keyValueKey">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.707 7.207L7.5 0H2V2H5.914L13.414 9.5C13.4818 9.571 13.5443 9.64687 13.601 9.727L14.707 8.621C14.8945 8.43347 14.9998 8.17916 14.9998 7.914C14.9998 7.64884 14.8945 7.39453 14.707 7.207Z" fill="#C5C5C5"/>
                        <path d="M12.707 10.207L5.5 3H0V8.5L7.207 15.707C7.39453 15.8945 7.64884 15.9998 7.914 15.9998C8.17916 15.9998 8.43347 15.8945 8.621 15.707L12.707 11.621C12.8945 11.4335 12.9998 11.1792 12.9998 10.914C12.9998 10.6488 12.8945 10.3945 12.707 10.207ZM3 7C2.80222 7 2.60888 6.94135 2.44443 6.83147C2.27998 6.72159 2.15181 6.56541 2.07612 6.38268C2.00043 6.19996 1.98063 5.99889 2.01921 5.80491C2.0578 5.61093 2.15304 5.43275 2.29289 5.29289C2.43275 5.15304 2.61093 5.0578 2.80491 5.01921C2.99889 4.98063 3.19996 5.00043 3.38268 5.07612C3.56541 5.15181 3.72159 5.27998 3.83147 5.44443C3.94135 5.60888 4 5.80222 4 6C4 6.26522 3.89464 6.51957 3.70711 6.70711C3.51957 6.89464 3.26522 7 3 7Z" fill="#C5C5C5"/>
                    </svg>
                    <span className="text-next">{store.trans.Tags}</span>
                    {tagSelectedCount > 0 &&
                        <span className="flex-columns">
                            <span className="PhotoFilterLeft-tagSearchBadgetLeft PhotoFilterLeft-tagSearchBadget">{tagSelectedCount}/{tagCount}</span>
                            <span className="PhotoFilterLeft-tagSearchBadgetRight PhotoFilterLeft-tagSearchBadget" onClick={this.onClearTags}>
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.2 0L4.5 2.7L1.8 0L0 1.8L2.7 4.5L0 7.2L1.8 9L4.5 6.3L7.2 9L9 7.2L6.3 4.5L9 1.8L7.2 0Z" fill="#C5C5C5"/>
                                </svg>
                            </span>
                        </span>
                    }
                    {tagSelectedCount == 0 && <span className="PhotoFilterLeft-tagSearchBadgetSingle PhotoFilterLeft-tagSearchBadget">{tagCount}</span>}

                </div>
                <div className="PhotoFilterLeft-keyValueValue">
                    <input type="text" className="text-box-editor width100"
                           onChange={this.onChangeTagSearch} placeholder={store.trans["Search by tags"]}
                           value={store.photo.photoFilter.tagGroupFilter.filter}/>
                </div>
            </div>
            <div className="PhotoFilterLeft-tags">
                <div className="style-4 overflowAutoY full100">
                    {tagGroups}
                </div>
            </div>


        </div>;
    }
}
