import {CustomStore} from "../CustomStore";
import {action, observable, toJS} from "mobx";
import {save} from "../PermalinkDecor";
import {ColorHelper, IRGBA} from "../../helper/utils/ColorHelper";
import {Utils} from "../../helper/utils/Utils";

export enum Interpolation {
    discrete = 'discrete',
    linear = 'linear'
}
export interface IDiscreteInterval{
    min: number;
    max: number;
    color: IRGBA;
    isTransparent: boolean;
}
export interface ILinearInterval{
    value: number;
    color: IRGBA;
}


export class PresetColorValue extends CustomStore {
    class(): string {
        return "PresetColorValue";
    }

    @save @observable
    _color: Readonly<IRGBA>;
    get color(): Readonly<IRGBA>{ return toJS(this._color); }
    set color(value: Readonly<IRGBA>){ this._color = value; }
}

export class PresetColors extends CustomStore {
    constructor(parent: CustomStore) {
        super(parent);

        this.colors = [];
        let c: PresetColorValue;
        c = new PresetColorValue(this);
        c.color = ColorHelper.parseColor("#00ff00");
        this.colors.push(c);

        c = new PresetColorValue(this);
        c.color = ColorHelper.parseColor("#0000ff");
        this.colors.push(c);

        c = new PresetColorValue(this);
        c.color = ColorHelper.parseColor("#00ffff");
        this.colors.push(c);
    }

    class(): string {
        return "PresetColors";
    }

    @save @observable
    name: string;
    @save @observable
    id: string;
    @save @observable
    colors: PresetColorValue[] = [];
}
export class ClassValue extends CustomStore{
    @save @observable
    value: number;
    @save @observable
    visible: boolean;

    class(): string {
        return "ClassValue";
    }

    static create(parent: CustomStore, value: number, visible: boolean): ClassValue{
        let t = new ClassValue(parent);
        t.value = value;
        t.visible = visible;
        return t;
    }
}

export class IndexBandProductStore extends CustomStore {
    class(): string {
        return "IndexBandProductStore";
    }

    @save @observable
    _min: number = 0;
    get min(): number{
        return  this._min;
    }
    set min(value: number){
        this._min = value;
    }

    @save @observable
    _max: number = 100;
    get max(): number{
        return  this._max;
    }
    set max(value: number){
        this._max = value;
    }

    @save @observable
    _classes: number = 2;
    get classes(): number{
        return  this._classes;
    }
    set classes(value: number){
        this._classes = value;
    }

    recalculateClassValuesFull(){
        let v = Utils.parseNumber(this._classes);
        let min = this.min;
        let max = this.max;

        if (v != null && min != null && max != null) {
            let arr: number[] = [];
            let step = (max - min) / v;
            for(let i = 1; i <= v - 1; i++){
                let k = min + i * step;
                arr.push(k);
            }
            this.classValues2 = arr.map((v, index) => {
                let visible = true;
                if (index < this.classValues2.length){
                    visible = this.classValues2[index].visible;
                }
                return ClassValue.create(this, v, visible);
            });
        }else this.classValues2 = [];
    }

    recalculateClassValuesPartial(){
        let classes = Utils.parseNumber(this.classes);
        let min = this.min;
        let max = this.max;
        let valids = this.classValues2.filter(a => a.value >= min && a.value <= max);
        if (valids.length == 0 || this.classValues2.length == 0) {
            this.recalculateClassValuesFull();
            return;
        }
        if (this.classValues2.length == valids.length) return;
        let left = false;//с какой стороны подстраивать
        let v = this.classValues2[0].value;
        if (!(v >= min && v <= max)) left = true;
        //отбрасываем не валидные
        this.classValues2 = this.classValues2.filter(a => a.value >= min && a.value <= max);

        let count = classes - this.classValues2.length;
        if (left){
            let v2 = this.classValues2[0].value;
            let v1 = min;
            let step = (v2 - v1) / (count + 1);
            for(let i = 0; i < count; i++){
                let newValue = step * (i + 1) + v1;
                Utils.arrayInsert(this.classValues2, i, ClassValue.create(this,newValue, true));
            }
        }else{
            let v1 = this.classValues2[this.classValues2.length - 1].value;
            let v2 = max;
            let step = (v2 - v1) / (count + 1);
            for(let i = 0; i < count; i++){
                let newValue = step * (i + 1) + v1;
                Utils.arrayInsert(this.classValues2, i, ClassValue.create(this,newValue, true));
            }
        }
    }
    @save @observable
    classValues2: ClassValue[] = [];
    @save @observable
    lastClassVisible: boolean = true;

    @save @observable
    interpolation: Interpolation = Interpolation.linear;
    @save @observable
    transparentMin: boolean = false;
    @save @observable
    transparentMax: boolean = false;
    @save @observable
    colors: PresetColors = new PresetColors(this);

    hasTransparentClasses(): boolean{
        let t = this.classValues2.find(a => !a.visible);
        return (t != null || !this.lastClassVisible);
    }

    getTileSetting(): any {
        let r: any = {};

        if (this.interpolation == Interpolation.linear){
            r.interpolation = "linear";
            let colors = this.colors.colors;
            let palette: any[] = [];

            if (this.transparentMin){
                palette.push([this.min, `0,0,0,0`]);
            }
            let lia = this.getLinearInteval();
            lia.forEach(a => {
                let c = a.color;
                let v = a.value;
                palette.push([v, `${ColorHelper.colorComponentNormal(c.r)}, ${ColorHelper.colorComponentNormal(c.g)}, ${ColorHelper.colorComponentNormal(c.b)}`]);
            });
            if (this.transparentMax){
                palette.push([this.max, `0,0,0,0`]);
            }
            r.palette = palette;
        }
        if (this.interpolation == Interpolation.discrete) {
            r.interpolation = "discrete";
            let dia: IDiscreteInterval[];
            dia = this.getDiscreteClassValuesValidsInterval();
            let palette: any[] = [];
            dia.forEach((a, index) => {
                let c = a.color;
                let tmin = a.min;

                palette.push([tmin,`${ColorHelper.colorComponentNormal(c.r)}, ${ColorHelper.colorComponentNormal(c.g)}, ${ColorHelper.colorComponentNormal(c.b)}, ${a.isTransparent?0:255}`]);
                if (index == dia.length - 1){
                    palette.push([tmin,`${ColorHelper.colorComponentNormal(c.r)}, ${ColorHelper.colorComponentNormal(c.g)}, ${ColorHelper.colorComponentNormal(c.b)}, ${a.isTransparent?0:255}`]);
                }
            });
            r.palette = palette;
        }
        return r;
    }

    //d - место в градиенте, 0..1
    getLinearColor(d: number): IRGBA {
        let colors = this.colors.colors;
        if (colors.length == 1) {
            return colors[0].color;
        }
        let prevIndex: number = null;

        let d1 = 0 / (colors.length - 1);
        if (d < d1) {
            return colors[0].color;
        } else {
            let i: number = 1;
            while (i < colors.length) {
                let d2 = i / (colors.length - 1);
                if (d1 <= d && d < d2) prevIndex = i - 1;
                d1 = d2;
                i++;
            }
            if (prevIndex == null) {
                return colors[colors.length - 1].color;
            }
        }
        let nextIndex: number = prevIndex + 1;

        let prevD = prevIndex / (colors.length - 1);
        let nextD = nextIndex / (colors.length - 1);
        let prevC = colors[prevIndex].color;
        let nextC = colors[nextIndex].color;
        if (prevIndex == nextIndex) {
            return colors[nextIndex].color;
        }

        function avgM(v1: number, v2: number): number {
            let m = nextD - prevD;
            let st = d - prevD;
            return v1 * ((m - st) / m) + v2 * (st / m);
        }

        let c: IRGBA = {
            r: avgM(prevC.r, nextC.r),
            g: avgM(prevC.g, nextC.g),
            b: avgM(prevC.b, nextC.b),
            a: avgM(prevC.a, nextC.a)
        }
        return c;
    }

    getLinearInteval(): ILinearInterval[]{
        let classes = this.colors.colors.length;
        let min = Utils.parseNumber(this.min);
        let max = Utils.parseNumber(this.max);
        let colors = this.colors.colors;
        let size = (max - min) / (classes - 1);
        let r: ILinearInterval[] = [];
        if (classes < 1 || colors.length == 0)
            return [{value: min, color: {r: 0, a: 1, b: 0, g: 0} }, {value: max, color: {r: 0, a: 1, b: 0, g: 0} }];
        if (classes == 1)
            return [{value: min, color: colors[0].color }, {value: max, color: colors[0].color }];


        for(let i = 0; i < classes; i++){
            let v = min + size * i;
            let d = i / (classes - 1);
            let color = this.getLinearColor(d);
            r.push({value: v, color: color});
        }
        return r;
    }
    /*
    getDiscreteInterval(): IDiscreteInterval[]{
        let classes = this.classes as number;
        let colors = this.colors.colors;
        let res: IDiscreteInterval[] = [];
        let min = Utils.parseNumber(this.min);
        let max = Utils.parseNumber(this.max);
        if (min == null || max == null) return [];
        if (classes < 1 || min == max)
            return [{min: min, max: max, color: {r: 0, a: 1, b: 0, g: 0}, isTransparent: true }];
        if (classes == 1)
            return [{min: min, max: max, color: colors[0].color, isTransparent: true }];

        let size = (max - min) / classes;

        for(let i = 0; i < classes; i++){
            let di: IDiscreteInterval = {
                min: min + size * i,
                max: min + size * (i + 1),
                color: this.getLinearColor( i / (classes - 1) ),
                isTransparent: true
            };
            res.push(di);
        }
        return res;
    }
*/
    getDiscreteClassValuesValidsInterval(): IDiscreteInterval[]{
        let values = this.classValues2;
        let min = Utils.parseNumber(this.min);
        let max = Utils.parseNumber(this.max);
        let dia: IDiscreteInterval[] = [];
        if (values.length == 0){
            dia.push({min: min, color: this.getLinearColor(0), max: max, isTransparent: false});
            return dia;
        }
        let prevValue = min;

        values.forEach((a, idx) =>{
            dia.push({
                min: prevValue,
                color: this.getLinearColor(idx / (values.length + 1)),
                max: a.value,
                isTransparent: !a.visible});
            prevValue = a.value;
        });
        let last = values[values.length - 1];
        if (last.value != max|| dia.length < values.length + 1){
            dia.push({
                min: prevValue,
                color: this.getLinearColor(1),
                max: max,
                isTransparent: !this.lastClassVisible});
        }
        return dia;
        //dia.push({min: prevValue, color: this.getLinearColor(1), max: max, isTransparent: false});
        //return dia.filter(a => (a.min > min && a.min <= max) || (a.max >= min && a.max < max));
    }

}