import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class GearSvg extends CustomSvg {

    render() {
        return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()}>
            <path d="M11.6375 4.55L12.6 2.7125L11.375 1.4875L9.5375 2.45C9.275 2.275 8.925 2.1875 8.575 2.1L7.875 0H6.125L5.425 2.0125C5.1625 2.1 4.8125 2.1875 4.55 2.3625L2.7125 1.4L1.4 2.7125L2.3625 4.55C2.1875 4.8125 2.1 5.1625 2.0125 5.425L0 6.125V7.875L2.0125 8.575C2.1 8.925 2.275 9.1875 2.3625 9.5375L1.4 11.375L2.625 12.6L4.4625 11.6375C4.725 11.8125 5.075 11.9 5.425 11.9875L6.125 14H7.875L8.575 11.9875C8.925 11.9 9.1875 11.725 9.5375 11.6375L11.375 12.6L12.6 11.375L11.6375 9.5375C11.8125 9.275 11.9 8.925 11.9875 8.575L14 7.875V6.125L11.9875 5.425C11.9 5.1625 11.8125 4.8125 11.6375 4.55ZM7 9.625C5.5125 9.625 4.375 8.4875 4.375 7C4.375 5.5125 5.5125 4.375 7 4.375C8.4875 4.375 9.625 5.5125 9.625 7C9.625 8.4875 8.4875 9.625 7 9.625Z" fill="#6A7886"/>
        </svg>
    }
}
