import {IGActionTransaction} from "./IGeometryAction";
import autoBind from "auto-bind";

export interface IGeometryActionHistory {
    readonly countActions: number;
    readonly currentIndexAction: number;
    historyDeath: number;

    getAction(index: number): IGActionTransaction;

    createAction(action: IGActionTransaction): void;

    lastAction(): IGActionTransaction;

    hasLastAction(): boolean;

    undo(): IGActionTransaction;

    canUndo(): boolean;

    redo(): IGActionTransaction;

    canRedo(): boolean;
}

export class GeometryActionHistory implements IGeometryActionHistory {
    constructor() {
        autoBind(this);
    }
    private _actions: IGActionTransaction[] = [];
    private _currentIndex: number = -1;

    get countActions(): number {
        return this._actions.length;
    }

    get currentIndexAction(): number {
        return this._currentIndex;
    }

    getAction(index: number): IGActionTransaction {
        return this._actions[index];
    }

    private _historyDeath = 100 ;//глубина хранения истории
    get historyDeath(): number {
        return this._historyDeath;
    }

    set historyDeath(value: number){
        if (value < 0) throw "incorrect historyDeath value";
        this._historyDeath = value;
    }

    createAction(action: IGActionTransaction) {
        this._actions.splice(this._currentIndex + 1);//удаляем всё за текущим
        this._actions.push(action);
        this._currentIndex++;
    }

    lastAction(): IGActionTransaction {
        if (!this.hasLastAction()) throw "No last geometry action";
        return this._actions[this._currentIndex];
    }

    hasLastAction(): boolean {
        return (this._actions.length > 0);
    }

    //отмена действия
    undo(): IGActionTransaction {
        if (!this.canUndo()) throw "Can not redo";
        let t = this._actions[this._currentIndex];
        this._currentIndex--;
        return t;
    }

    canUndo(): boolean {
        return (this._currentIndex >= 0);
    }

    //повтор действия
    redo(): IGActionTransaction {
        if (!this.canRedo()) throw "Can not redo";
        this._currentIndex++;
        let t = this._actions[this._currentIndex];
        return t;
    }

    canRedo(): boolean {
        return (this._currentIndex < (this._actions.length - 1));
    }
}