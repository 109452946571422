import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../../helper/structs/IStoreProps";
import './ZaklPanelComp.scss';
import {action} from "mobx";
import classNames from "classnames";
import {A2ColumnType} from "../../../../../../store/agro/A2ColumnItemStore";
import {ra} from "../../../../../../helper/utils/mobxUtils";
import {ZalConstants} from "../../../../../../const/ZalConstants";

export interface IZaklPanelComp extends IStoreProps{
}
@observer
export class ZaklPanelComp extends React.Component<IZaklPanelComp, undefined> {
    constructor(props: IZaklPanelComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    async onClose(){
        let store = this.props.store;
        if (!store.agro2.zalogi.zakluchenie.readOnly) {
            await store.agro2.zalogi.zakluchenie.writeValues();
        }
        ra(()=>{
            store.agro2.zalogi.zakluchenie.showZakluchenie = false;
        });
    }
    @action
    async onSubmit(){
        let store = this.props.store;
        if (store.agro2.zalogi.zakluchenie.readOnly) {
            store.agro2.zalogi.zakluchenie.showZakluchenie = false;
            return;
        }
        if (! await store.agro2.zalogi.zakluchenie.writeValues()) return;
        if (! await store.agro2.zalogi.zakluchenie.saveReport()) return;
        //await store.agro2.zalogi.changeStatus(ZalConstants["Отчет готов"], store.agro2.zalogi.zakluchenie.req_id);
        ra(()=>{
            store.agro2.zalogi.zakluchenie.showZakluchenie = false;
            store.addInfo("Отчет готов");
        });
    }
    @action
    OnChangeDescr(event: any){
        if (this.props.store.agro2.zalogi.zakluchenie.readOnly) return;
        this.props.store.agro2.zalogi.zakluchenie.description = event.target.value;
    }
    @action
    onChangeAnalizOkr(event: any){
        if (this.props.store.agro2.zalogi.zakluchenie.readOnly) return;
        this.props.store.agro2.zalogi.zakluchenie.analiz_okrug = event.target.value;
    }
    @action
    onChangeAnalizIzm(event: any){
        if (this.props.store.agro2.zalogi.zakluchenie.readOnly) return;
        this.props.store.agro2.zalogi.zakluchenie.analiz_izm = event.target.value;
    }
    @action
    onChangeVyvod(event: any){
        if (this.props.store.agro2.zalogi.zakluchenie.readOnly) return;
        this.props.store.agro2.zalogi.zakluchenie.vyvod = event.target.value;
    }
    @action
    onChangePermalink(event: any){
        if (this.props.store.agro2.zalogi.zakluchenie.readOnly) return;
        this.props.store.agro2.zalogi.zakluchenie.permalink = event.target.value;
    }
    @action
    onChange_neighboring_plots(event: any){
        if (this.props.store.agro2.zalogi.zakluchenie.readOnly) return;
        this.props.store.agro2.zalogi.zakluchenie.neighboring_plots = event.target.value;
    }

    render() {
        let store = this.props.store;
        let zakl = store.agro2.zalogi.zakluchenie;
        let readOnly = this.props.store.agro2.zalogi.zakluchenie.readOnly;

        return <div className="popup-pref ZaklPanelComp-main">
            <div className="ZaklPanelComp-closeDiv" >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="ZaklPanelComp-closeButton" onClick={this.onClose}>
                    <path d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z" fill="#C5C5C5"/>
                </svg>
            </div>
            <div className="ZaklPanelComp-divHeader">
                <div className="ZaklPanelComp-title">Заключение</div>
                {/* <div className="ZaklPanelComp-egrn">Информация из ЕГРН</div> */}
            </div>
            <div className="ZaklPanelComp-divContentParent">
                <div className="style-4 overflowAutoY ZaklPanelComp-divContentParentScroll">
                    <div className="ZaklPanelComp-divContent">
                        <div className="ZaklPanelComp-rowKeyValue">
                            <div className="ZaklPanelComp-rowKey">
                                <span>Ссылка на снимок</span>
                                <span className="ZaklPanelComp-star">*</span>
                            </div>
                            <div className="ZaklPanelComp-rowValue">
                                <input type="text ZaklPanelComp-singleInput"
                                       placeholder="Пермалинк" readOnly={readOnly}
                                       className="text-box-editor width100 ZaklPanelComp-inputStr hide_placeholder_on_focus"
                                       onChange={this.onChangePermalink} value={zakl.permalink} />
                            </div>
                        </div>

                        <div className="ZaklPanelComp-rowKeyValue">
                            <div className="ZaklPanelComp-rowKey">
                                <span>Соседние участки</span>
                            </div>
                            <div className="ZaklPanelComp-rowValue">
                                <input type="text ZaklPanelComp-singleInput"
                                       placeholder="Соседние участки" readOnly={readOnly}
                                       className="text-box-editor width100 ZaklPanelComp-inputStr hide_placeholder_on_focus"
                                       onChange={this.onChange_neighboring_plots} value={zakl.neighboring_plots} />
                            </div>
                        </div>

                        <div className="ZaklPanelComp-rowKeyValue">
                            <div className="ZaklPanelComp-rowKey">
                                <span>Общее описание</span>
                                <span className="ZaklPanelComp-star">*</span>
                            </div>
                            <div className="ZaklPanelComp-rowValue">
                                <textarea rows={6} className="ZaklPanelComp-multiInput style-4" value={zakl.description} onChange={this.OnChangeDescr}
                                          readOnly={readOnly}
                                          style={{width: "100%", resize: "none"}} />
                            </div>
                        </div>

                        <div className="ZaklPanelComp-rowKeyValue">
                            <div className="ZaklPanelComp-rowKey">
                                <span>Анализ окружения</span>
                                <span className="ZaklPanelComp-star">*</span>
                            </div>
                            <div className="ZaklPanelComp-rowValue">
                                <textarea rows={6} className="ZaklPanelComp-multiInput style-4" value={zakl.analiz_okrug} onChange={this.onChangeAnalizOkr}
                                          readOnly={readOnly}
                                          style={{width: "100%", resize: "none"}}/>
                            </div>
                        </div>
                        <div className="ZaklPanelComp-rowKeyValue">
                            <div className="ZaklPanelComp-rowKey">
                                <span>Анализ изменений</span>
                                <span className="ZaklPanelComp-star">*</span>
                            </div>
                            <div className="ZaklPanelComp-rowValue">
                                <textarea rows={6} className="ZaklPanelComp-multiInput style-4" value={zakl.analiz_izm} onChange={this.onChangeAnalizIzm}
                                          readOnly={readOnly}
                                          style={{width: "100%", resize: "none"}}/>
                            </div>
                        </div>
                        <div className="ZaklPanelComp-rowKeyValue">
                            <div className="ZaklPanelComp-rowKey">
                                <span>Выводы</span>
                                <span className="ZaklPanelComp-star">*</span>
                            </div>
                            <div className="ZaklPanelComp-rowValue">
                                <textarea rows={6} value={zakl.vyvod} onChange={this.onChangeVyvod}
                                          readOnly={readOnly}
                                          className="ZaklPanelComp-multiInput style-4" style={{width: "100%", resize: "none"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(!readOnly) && <div className="ZaklPanelComp-divFooter">
                <button className="left-sidebar-active button-jump ZaklPanelComp-button" onClick={this.onSubmit}>
                    Создать отчет
                </button>
            </div>}
        </div>;
    }
}
