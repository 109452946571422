import * as React from 'react';
import classNames from "classnames";
import {isFunction} from "lodash-es";

export interface IBackCircleProps {
    onClick?: () => any;
    className?: string;
}

export class BackCircle extends React.PureComponent<IBackCircleProps, undefined> {
    render() {
        return <div className={classNames("Agro2Common-backCircle", this.props.className)} onClick={() => {if (isFunction(this.props.onClick)) this.props.onClick();}}>
            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 5.5H1.5" stroke="#4DB6BC" strokeMiterlimit="10"/>
                <path d="M5.5 9.5L1.5 5.5L5.5 1.5" stroke="#4DB6BC" strokeMiterlimit="10" strokeLinecap="square"/>
            </svg>
        </div>
    }
}
