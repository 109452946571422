import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {SearchSceneItemComp} from "./SearchSceneItemComp";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {SearchItemGroup} from "../../../../store/SearchItemGroup";
import {observable} from "mobx";
import classNames from "classnames";
import {SceneMosaicSwitch} from "../../../../store/SearchStore";
import {Utils} from "../../../../helper/utils/Utils";
import {SearchItemStore} from "../../../../store/SearchItemStore";

export interface ISearchSceneItemImgComp extends IStoreProps{
    searchGroup: SearchItemGroup,
    className?: string,
}

@observer
export class SearchSceneItemImgComp extends React.Component<ISearchSceneItemImgComp, undefined> {
    constructor(props: ISearchSceneItemImgComp) {
        super(props);
        autoBindReact(this);
        //this.startLoading();
    }
    loaded: boolean = false;
    error: boolean = false;

    onLoad(){
        this.loaded = true;
        this.error = false;
        this.forceUpdate();
    }
    onError(){
        this.loaded = true;
        this.error = true;
        this.forceUpdate();
    }

    componentWillUnmount() {
        this.unmounted = true;
        this.freeImage();
    }

    public static getImgUrlItem(searchItem: SearchItemStore): {url: string, params: any}{
        let it = searchItem.feature.properties;
        if (it.ql_exists != null && !it.ql_exists){
            return {url: SearchSceneItemComp.preview_error_url, params: {}};
        }
        let r:any = {url:`/api/quicklookimageorigin`, params: {scene_id: it.scene_id, ver: it.version, source: it.source}};
        if (it.scene_type == "S5L2A") r.params.product = searchItem.root.map.productInfo.productsS5.activeCodeCurrent.productCode;
        return r;
    }
    public static getImgUrl(searchGroup: SearchItemGroup): {url: string, params: any}{
        let sg = searchGroup;
        let store = sg.root;

        if (store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.scene) {
            return SearchSceneItemImgComp.getImgUrlItem(searchGroup.first());
        }else{
            let ids = sg.items.map(a => a.sceneId());
            let ids_str = ids.join(",");
            let r = "";
            let prms: any = {scene_ids:ids_str};
            r += "/api/mosaic_preview";
            prms.width=Math.round(125*window.devicePixelRatio)
            prms.height=Math.round(125*window.devicePixelRatio);
            if (store.map.searchObject.isNotEmpty) {
                //if (store.map.searchObject.geomId > 0) r += "&geom_id="+encodeURIComponent(store.map.searchObject.geomId);
                //else
                if (store.map.searchObject.searchGeometry != null) prms.border = store.map.searchObject.searchGeometry;
            }
            return {url: r, params: prms};
        }
    }


    freeImage(){
        if (this.contentImg != null) {
            URL.revokeObjectURL(this.contentImg);
            this.contentImg = null;
        }
    }

    unmounted: boolean = false;
    @observable
    contentImg: string = null;
    //img: ArrayBuffer;
    lastHash: string = null;
    preview_error_url = "./img/preview_not_available.svg"

    render() {
        /*
        let showError = (this.loaded && this.error);
        let store = this.props.store;


        let h1 = SearchSceneItemImgComp.getImgUrlHash(this.props.searchGroup);
        if (h1 != this.lastHash){
            this.lastHash = h1;
            rai(()=>{
                this.startLoading();
            });
        }
        if (!this.loaded) return <LoadingBars classes="loading-img" />;
        if (showError) return <img src={this.preview_error_url} className="preview_error img-radius"
                                   alt="Quicklook is not found" title={store.trans["Quicklook is not found"]} />;

        */
        //if (this.contentImg == null) return null;
        let urlParams = SearchSceneItemImgComp.getImgUrl(this.props.searchGroup);// this.contentImg;
        let src = urlParams.url + "?"+Utils.queryEncodeParams(urlParams.params);
        return <img className={classNames(this.props.className)} src={src} />;
    }
}
