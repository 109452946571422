import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {action} from "mobx";
import {observer} from "mobx-react";
import React, {Fragment} from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {
    IOverlayConfig,
    IOverlayGroup
} from "../../../../store/config/ConfigStore";
import {
    CheckboxMiniComp,
    CheckboxSize,
    TriState
} from "../../../Common/CheckboxMiniComp";
import {CloseSvg} from "../../../icons/MiniIcon/CloseSvg";
import "./LayersListComp.scss";
import {
    ContextMenuCommonComp,
    PopupCorner,
    PopupDirection
} from "../../../Common/ContextMenuCommonComp";
import {LayerStyleComp} from "./LayerStyleComp";
import {
    BorderStyle,
    EStyleType,
    LayersListStore,
    OverlaySimpleStyle
} from "./LayersListStore";
import {EllapseGroupSvg} from "../../../icons/MiniIcon/EllapseGroupSvg";
import {ExpandGroupSvg} from "../../../icons/MiniIcon/ExpandGroupSvg";
import {GibsOverlayComp} from "../../Map/GibsOverlayComp";
import {DapFieldsMaskOverlayComp} from "../../Map/DapFieldsMaskOverlayComp";
import {WindOverlayComp} from "../../Map/WindOverlayComp";
import {ClockSvg} from "../../../icons/MiniIcon/ClockSvg";
import {TemporalGroupComp} from "./TemporalGroupComp";
import DraggableOverlaysComp, {
    DraggableOverlaysCompRef
} from "./DraggableOverlaysComp";

interface ILayersListComp extends IStoreProps {
    showPanel: boolean;
}

@observer
export class LayersListComp extends React.Component<
    ILayersListComp,
    {isBaseLayersExtended:boolean }
> {
    static COMPONENTS = {
        gibs_modis: GibsOverlayComp,
        dap_fields_mask: DapFieldsMaskOverlayComp,
        gfs_wind: WindOverlayComp
    };

    constructor(props: ILayersListComp) {
        super(props);
        autoBindReact(this);
        this.draggableOverlaysCompRef = React.createRef();
        this.state = {
            isBaseLayersExtended: true,
        };
    }
    private draggableOverlaysCompRef =
        React.createRef<DraggableOverlaysCompRef>();  
    @action
    toggleEditing = () => {
        this.props.store.layerListStore.isEditingMode = !this.props.store.layerListStore.isEditingMode
    };
    @action
    toggleBaseLayersExtended = () => {
        this.setState(prevState => ({
            isBaseLayersExtended: !prevState.isBaseLayersExtended
        }));
    };
    @action
    handleSave = () => {
        this.props.store.layerListStore.showConfirmPopup = true
    };
   
    @action
    resetToInitialTreeData = () => {
        this.props.store.layerListStore.showCancelPopup = true
    };

    addNewParentNode = () => {
        this.draggableOverlaysCompRef.current?.addNewParentNode();
    };
    @action
    onClose() {
        this.props.store.map.layersMenuOpened = false;
    }

    @action
    onBaseLayerChange(e: any) {
        let id = e.currentTarget.getAttribute("data-baselayer");
        this.props.store.map.currentBaselayerKey = id;
    }

    @action
    onOverlayChange(e: any) {
        let id = e.currentTarget.getAttribute("data-overlay");
        let oldState = this.props.store.map.overlays.getVisible(id);
        this.props.store.map.overlays.setVisible(id, !oldState);
    }

    @action
    onGroupChange(e: any) {
        let id = e.currentTarget.getAttribute("data-group");
        let oldState = this.props.store.map.overlays.isExpanded(id);
        this.props.store.map.overlays.setExpanded(id, !oldState);
    }

    @action
    onStyleClick(e: any) {
        let st = this.props.store;
        let lls = st.layerListStore;
        let layerId = e.currentTarget.getAttribute("data-overlay");
        let groupId = e.currentTarget.getAttribute("data-group");
        let ov: IOverlayConfig = null;
        if (!groupId)
            ov = st.config.map_layers.overlays.find(
                a => a.id == layerId
            ) as IOverlayConfig;
        else {
            let gr = st.config.map_layers.overlays.find(
                g => g.id == groupId
            ) as IOverlayGroup;
            ov = gr.overlays.find(
                a => a.id == layerId
            ) as IOverlayConfig;
        }
        let style = LayersListStore.getOverlaySimpleStyle(ov);
        if (!style) {
            lls.activeOverlay = null;
            lls.stylePaintProperties = null;
        } else {
            lls.activeOverlay = {
                ...ov,
                initialVisibility: st.map.overlays.getVisible(ov.id)
            };
            let vis = false;
            if (style.circlePaint)
                vis =
                    (style.circlePaint[
                        "circle-stroke-opacity"
                    ] as number) > 0;
            else if (style.lineLayerIndex >= 0)
                vis =
                    ov.layers[style.lineLayerIndex].metadata?.[
                        "class:overlay:border:visibility"
                    ];
            lls.showBorderOption = //Boolean(style.type == EStyleType.CIRCLE && style.borderVisibility == 'visible') ||
                Boolean(
                    (style.type == EStyleType.CIRCLE ||
                        style.type == EStyleType.FILL) &&
                        (vis || style.borderVisibility == "visible")
                );
            lls.stylePaintProperties = {
                fillColor:
                    ((style.fillPaint?.["fill-color"] ||
                        style.linePaint?.["line-color"] ||
                        style.circlePaint?.[
                            "circle-color"
                        ]) as string) ||
                    OverlaySimpleStyle.DEFAULT_COLOR,
                fillOpacity:
                    ((style.fillPaint?.["fill-opacity"] ||
                        style.linePaint?.["line-opacity"] ||
                        style.circlePaint?.[
                            "circle-opacity"
                        ]) as number) || 1,
                fillVisibility: style.fillVisibility,
                circleRadius: style.circlePaint
                    ? (style.circlePaint[
                          "circle-radius"
                      ] as number) || 4
                    : null,
                borderWidth:
                    ((style.circlePaint?.["circle-stroke-width"] ||
                        style.linePaint?.["line-width"]) as number) ||
                    1,
                borderColor:
                    ((style.circlePaint?.["circle-stroke-color"] ||
                        style.linePaint?.["line-color"] ||
                        style.fillPaint?.[
                            "fill-outline-color"
                        ]) as string) ||
                    OverlaySimpleStyle.DEFAULT_COLOR,
                borderVisibility: vis
                    ? "visible"
                    : style.borderVisibility,
                borderOpacity: style.circlePaint
                    ? (style.circlePaint[
                          "circle-stroke-opacity"
                      ] as number) || 0
                    : (style.linePaint?.["line-opacity"] as number) ||
                      1,
                borderStyle:
                    (style.linePaint?.["line-dasharray"] &&
                        BorderStyle.DashDash) ||
                    BorderStyle.Solid,
                dashedFreq:
                    (style.linePaint?.[
                        "line-dasharray"
                    ]?.[0] as number) || 2,
                dashedSpace:
                    (style.linePaint?.[
                        "line-dasharray"
                    ]?.[1] as number) || 2
            };
        }
        this.props.store.layerListStore.showStyleWindow = Boolean(
            this.props.store.layerListStore.activeOverlay
        );
        e.stopPropagation();
    }

    @action
    onStyleWindowClose() {
        let lls = this.props.store.layerListStore;
        console.log(this.props.store);
        lls.activeOverlay = null;
        lls.showStyleWindow = false;
    }

    @action
    onTemporalGroupClick(e: any) {
        let lls = this.props.store.layerListStore;
        let groupId = e.currentTarget.getAttribute("data-group");
        //console.log(groupId);
        lls.activeTemporalGroup =
            this.props.store.map.overlays.temporalGroup(groupId);
        //lls.showTemporalGroupWindow = true;
        e.stopPropagation();
    }

    @action
    onTemporalGroupWindowClose() {
        let lls = this.props.store.layerListStore;
        lls.activeTemporalGroup = null;
        //lls.showTemporalGroupWindow = false;
    }

    createCircleLayerSvg(color: string, strokeColor: string = null) {
        return strokeColor ? (
            <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    x='3'
                    y='3'
                    width='12'
                    height='12'
                    rx='6'
                    fill={color}
                    stroke={strokeColor}
                    strokeWidth='2'
                />
            </svg>
        ) : (
            <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    x='4'
                    y='4'
                    width='10'
                    height='10'
                    rx='5'
                    fill={color}
                />
            </svg>
        );
    }

    createLineLayerSvg(color: string) {
        return (
            <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    x='15'
                    y='1'
                    width='3'
                    height='20'
                    rx='1.5'
                    transform='rotate(45 15 1)'
                    fill={color}
                />
            </svg>
        );
    }

    createFillLayerSvg(fillColor: string, borderColor: string) {
        return (
            <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    x='2'
                    y='2'
                    width='14'
                    height='14'
                    fill={fillColor}
                />
                <rect
                    x='1'
                    y='1'
                    width='16'
                    height='16'
                    rx='1'
                    stroke={borderColor}
                    strokeWidth='2'
                />
            </svg>
        );
    }

    createEmptySvg() {
        return <svg width='18' height='18' viewBox='0 0 18 18'></svg>;
    }

    createStyleSvg(overlay: IOverlayConfig) {
        let st = LayersListStore.getOverlaySimpleStyle(overlay);
        if (!st) return this.createEmptySvg();

        if (st.circlePaint)
            return this.createCircleLayerSvg(
                (st.circlePaint["circle-color"] as string) ||
                    OverlaySimpleStyle.DEFAULT_COLOR,
                st.borderVisibility == "none"
                    ? null
                    : (st.circlePaint[
                          "circle-stroke-color"
                      ] as string) || OverlaySimpleStyle.DEFAULT_COLOR
            );
        if (
            st.linePaint &&
            (!st.fillPaint ||
                (st.fillPaint["fill-opacity"] ?? 1) == 0)
        )
            return this.createLineLayerSvg(
                (st.linePaint["line-color"] as string) ||
                    OverlaySimpleStyle.DEFAULT_COLOR
            );
        if (st.fillPaint) {
            let fillColor = st.fillPaint["fill-color"];
            let vis =
                overlay.layers[st.lineLayerIndex]?.metadata?.[
                    "class:overlay:border:visibility"
                ] || st.borderVisibility == "visible";
            let borderColor = !vis
                ? st.fillPaint["fill-color"]
                : st.linePaint?.["line-color"] ||
                  st.fillPaint["fill-outline-color"] ||
                  OverlaySimpleStyle.DEFAULT_COLOR;
            return this.createFillLayerSvg(
                fillColor as string,
                borderColor as string
            );
        }
        return this.createEmptySvg();
    }

    createLayerItem(
        lr: IOverlayConfig,
        isActive: boolean,
        gr: IOverlayGroup = null
    ) {
        return (
            <Fragment>
                <CheckboxMiniComp
                    checkedEye
                    state={
                        isActive ? TriState.check : TriState.uncheck
                    }
                    size={CheckboxSize.standart}
                    classesContainer='pointer LayersListComp-overlays-group-overlay-checkbox'
                ></CheckboxMiniComp>

                <div
                    className={classNames(
                        "LayersListComp-overlays-overlay-title", 
                            "LayersListComp-inactive-color"
                    )}
                >
                    <span className='LayersListComp-overlays-overlay-title-span'>
                        {lr.title}
                    </span>
                </div>
                <div
                    className='LayersListComp-overlays-overlay-icon'
                    data-overlay={lr.id}
                    data-group={gr?.id}
                    onClick={this.onStyleClick}
                >
                    {this.createStyleSvg(lr)}
                </div>
                {this.props.store.layerListStore.showStyleWindow &&
                    this.props.store.layerListStore.activeOverlay
                        ?.id == lr.id && (
                        <ContextMenuCommonComp
                            onClose={this.onStyleWindowClose}
                            direction={PopupDirection.horizontal}
                            popupCorner={PopupCorner.rightBottom}
                        >
                            <LayerStyleComp
                                store={this.props.store}
                            />
                        </ContextMenuCommonComp>
                    )}
            </Fragment>
        );
    }

    render() {
        let store = this.props.store;
        let isDisabled = store.layerListStore.isButtonActive
         let isProject = store.root.agro.projectName
         let isAdmin = store.root.user.roles.includes('admin')
       //  console.log(isAdmin)
       // let isProject = true
        let baseLayers: any[] = [];
        let overlays: any[] = [];
        let parOverlays: any = [];
        let isEditing = store.layerListStore.isEditingMode
        const {isBaseLayersExtended} = this.state
        store.config.map_layers.baselayers.forEach((lr, i) => {
            let isActive = lr.key == store.map.currentBaselayerKey;
            baseLayers.push(
                <div
                    className='LayersListComp-baselayer'
                    key={lr.key}
                    data-baselayer={lr.key}
                    onClick={this.onBaseLayerChange}
                >
                    <div
                        className={
                            isActive
                                ? "LayersListComp-baselayer-icon-active-border"
                                : "LayersListComp-baselayer-icon-inactive-border"
                        }
                    >
                        <div
                            className='LayersListComp-baselayer-icon'
                            style={{
                                backgroundImage: `url("${lr.icon}")`
                            }}
                        ></div>
                    </div>
                    <div className='LayersListComp-overlays-overlay-icon'>
                        <span
                            className={classNames(
                                "LayersListComp-baselayer-title",
                                {
                                    "LayersListComp-active-color":
                                        isActive,
                                    "LayersListComp-inactive-color":
                                        !isActive
                                }
                            )}
                        >
                            {lr.title}
                        </span>
                    </div>
                </div>
            );
        });
        let isFirstGroupContainer = true
        store.config.map_layers.overlays.forEach((item,i) => {
            if (Object.keys(item).indexOf("overlays") < 0) {
                //is overlay
                let lr = item as IOverlayConfig;
                let isActive = store.map.overlays.getVisible(lr.id);

               
                overlays.push(
                    <div
                        className='LayersListComp-overlays-overlay'
                        key={lr.id}
                        data-overlay={lr.id}
                        onClick={this.onOverlayChange}
                    >
                        {this.createLayerItem(lr, isActive)}
                    </div>
                );
                //   console.log(overlays)
            } else {
                //is group
                let gr = item as IOverlayGroup;
                let tmpGr = store.map.overlays.temporalGroup(gr.id);
                let isExpanded = store.map.overlays.isExpanded(gr.id);
                let groupOverlays: any[] = [];
                //let groupOverlayLines: any[] = [];
                let activeCount: number = 0;
                let totalCount: number = 0;
                
                gr.overlays.forEach((lr, i) => {
                    let isActive = store.map.overlays.getVisible(
                        lr.id
                    );
                    activeCount += Number(isActive);
                    totalCount += 1;
                    if (isExpanded) {
                        groupOverlays.push(
                            <div
                        
                                className='LayersListComp-overlays-group-overlay-item'
                                key={lr.id}
                            >
                                <div
                                className={classNames({
                                    "LayersListComp-overlays-group-overlay-item-vertical-line":
                                        i !==
                                        gr.overlays.length - 1,
                                    "LayersListComp-overlays-group-overlay-item-vertical-line-last":
                                        i ===
                                        gr.overlays.length - 1
                                })}
                                   
                                />
                                <div className='LayersListComp-overlays-group-overlay-item-horizontal-line' />
                                <div
                                    className='LayersListComp-overlays-group-overlay'
                                    data-overlay={lr.id}
                                    data-group={gr.id}
                                    onClick={this.onOverlayChange}
                                >
                                    {this.createLayerItem(
                                        lr,
                                        isActive,
                                        gr
                                    )}
                                </div>
                            </div>
                        );
                        // groupOverlays.push(<div className="LayersListComp-overlays-group-overlay" key={lr.id} data-overlay={lr.id} data-group={gr.id} onClick={this.onOverlayChange}>
                        //     {this.createLayerItem(lr, isActive, gr)}
                        // </div>);
                        // groupOverlayLines.push(<div className="LayersListComp-overlays-group-list-item-line" key={lr.id}></div>);
                    } else if (isActive)
                        groupOverlays.push(
                            <span
                                className='LayersListComp-group-collapsed-overlay'
                                key={lr.id}
                            >
                                {lr.title}
                            </span>
                        );
                });
                overlays.push(
                    <div
                    className={classNames(
                        'LayersListComp-overlays-group-container',
                        { 'LayersListComp-overlays-group-container-first': isFirstGroupContainer } 
                    )}
                        key={gr.id}
                    >
                        <div
                            className={classNames(
                                "LayersListComp-overlays-group-item",
                                {
                                    "LayersListComp-overlays-group-expanded-item":
                                        !isExpanded,  
                                }
                            )}
                            onClick={this.onGroupChange}
                            data-group={gr.id}
                        >
                            <div
                                className={classNames(
                                    "LayersListComp-overlays-group-item-header",
                                    {
                                        "LayersListComp-overlays-group-item-header-common":
                                            !gr.temporalParams,
                                        "LayersListComp-overlays-group-item-header-temporal":
                                            gr.temporalParams
                                    }
                                )}
                            >
                                <div
                                    className={classNames(
                                        "LayersListComp-overlays-group-item-select-marker",
                                        {
                                            opacity0:
                                                activeCount == 0 ||
                                                isExpanded
                                        }
                                    )}
                                ></div>
                                {!gr.temporalParams && (
                                    <Fragment>
                                        <div className='LayersListComp-overlays-group-item-expand-div'>
                                            {isExpanded ? (
                                                <EllapseGroupSvg />
                                            ) : (
                                                <ExpandGroupSvg />
                                            )}
                                        </div>
                                        <span className='LayersListComp-overlays-group-item-groupname'>
                                            {gr.title}
                                        </span>
                                    </Fragment>
                                )}
                                {gr.temporalParams && (
                                    <Fragment>
                                        <div className='LayersListComp-overlays-group-item-header-temporal-div'>
                                            <div className='LayersListComp-overlays-group-item-expand-div'>
                                                {isExpanded ? (
                                                    <EllapseGroupSvg />
                                                ) : (
                                                    <ExpandGroupSvg />
                                                )}
                                            </div>
                                            <span className='LayersListComp-overlays-group-item-groupname'>
                                                {gr.title}
                                            </span>
                                            <div></div>
                                            <span
                                                className={classNames("LayersListComp-overlays-group-item-header-temporal-date", {
                                                        "LayersListComp-overlays-group-item-header-temporal-date-active": activeCount > 0
                                                    }
                                                )}
                                                onClick={this.onTemporalGroupClick}
                                                data-group={gr.id}
                                            >{tmpGr? `${tmpGr.start} - ${tmpGr.end}` : ""}</span>
                                        </div>
                                        {
                                            //this.props.store.layerListStore.showTemporalGroupWindow &&
                                            this.props.store.layerListStore.activeTemporalGroup?.group.id == gr.id && (
                                                <ContextMenuCommonComp
                                                    onClose= { this.onTemporalGroupWindowClose }
                                                    direction= { PopupDirection.horizontal }
                                                    popupCorner= { PopupCorner.rightBottom}>
                                                    <TemporalGroupComp
                                                        store={store}
                                                        group={ this.props.store.layerListStore.activeTemporalGroup }
                                                    />
                                                </ContextMenuCommonComp>
                                            )
                                        }
                                    </Fragment>
                                )}
                            </div>
                            {gr.temporalParams && <ClockSvg />}
                        </div>
                        {/* {groupOverlays.length > 0 && isExpanded && <div className="LayersListComp-group-expanded-div-container">
                            <div className="LayersListComp-overlays-group-list">
                                <div className="LayersListComp-overlays-group-list-line"></div>
                                <div className="LayersListComp-overlays-group-list-item-lines">{groupOverlayLines}</div>
                            </div>
                            <div className="LayersListComp-group-expanded-div">{groupOverlays}</div>
                        </div>} */}
                        {groupOverlays.length > 0 && isExpanded && (
                            <Fragment>{groupOverlays}</Fragment>
                        )}
                    </div>
                );
                isFirstGroupContainer = false;  
            }
        });
        if (store.config.parameterizedOverlays) {
            store.config.parameterizedOverlays.forEach((ov, i) => {
                let comp = LayersListComp.COMPONENTS[ov.id];
                if (comp)
                    parOverlays.push(
                        React.createElement(comp, {
                            store: store,
                            overlay: ov,
                            key: i
                        })
                    );
            });
        }
        return (
            <div
                className={classNames(
                    "LayersListComp-main qtransit",
                    {width0: !this.props.showPanel}
                )}
            >
                <div className='LayersListComp-title-wrapper'>
                    <div style={isEditing ? { width: '170px' } : undefined}>

                    <div className='LayersListComp-title'>
                        {store.trans["Overlays Panel"]}
                    </div>
                  {isProject &&  <div className='LayersListComp-project-title'>{store.root.agro.projectTitle}</div>}
                    </div>
                 { isAdmin &&  <div 
                        className={classNames(
                            "LayersListComp-settings-button",
                            {background: isEditing},
                            {"LayersListComp-settings-button-disable" : !isProject}
                        )}
                        onClick={() => {
                            if (isProject) { 
                                if (isDisabled) {
                                    this.toggleEditing();
                                } else {
                                    if (isEditing) {
                                        this.resetToInitialTreeData();
                                    } else {
                                        this.toggleEditing(); 
                                    }
                                }
                            }
                        }}
                    >
                        {isEditing ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                               
                            >
                                <div>Редактирование</div>
                                <div
                                    className='close_overlays_edit'
                                    
                                />
                            </div>
                        ) : (
                            <div style={{margin:"0px 3px"}}
                                className='settings_table_midi'
                               
                            />
                        )}
                    </div>}
                </div>
                   
                <div className='LayersListComp-baselayers'>
                    <div className='LayersListComp-header'>
                        <span className='LayersListComp-baselayers-header'>
                            {store.trans["Baselayers"]}
                        </span>
                        <div className='LayersListComp-separator'></div>
                        <div 
                            className='LayersListComp-overlays-group-item-expand-div'
                            onClick={this.toggleBaseLayersExtended} 
                            style={{ cursor: 'pointer', backgroundColor:"#3E4751" }}
                            >
                                {isBaseLayersExtended ? <EllapseGroupSvg /> : <ExpandGroupSvg />}
                                </div>
                    </div>
                   {isBaseLayersExtended &&  <div className='LayersListComp-baselayers-grid'>
                         {baseLayers} 
                    </div> }
                </div>
                <div className='LayersListComp-overlays style-4'>
                    <div className='LayersListComp-header LayersListComp-header-editing'>
                        <span className='LayersListComp-baselayers-header'>
                            {store.trans["Overlays"]}
                        </span>
                        <div className='LayersListComp-separator'></div>
                        {isEditing && (
                            <div className='LayersListComp-header-subtitle'>
                                Менеджер слоев
                            </div>
                        )}
                        {isEditing && (
                            <div
                                className='add_overlays_icon'
                                onClick={this.addNewParentNode}
                            />
                        )}
                    </div>
                    <div className='LayersListComp-overlays-grid'>
                        {isEditing ? (
                            <DraggableOverlaysComp
                                ref={this.draggableOverlaysCompRef}
                                showPanel={false}
                                store={store}
                                onStyleWindowClose={
                                    this.onStyleWindowClose
                                }
                                onTemporalGroupClick={this.onTemporalGroupClick}
                                onStyleClick={this.onStyleClick}
                                createStyleSvg={this.createStyleSvg}
                                onOverlayChange={this.onOverlayChange}
                                parOverlays={parOverlays}
                                onTemporalGroupWindowClose={this.onTemporalGroupWindowClose}
                            />
                        ) : (
                            overlays
                        )}
                         {!isEditing && parOverlays}
                    </div>
                </div>
                {isEditing && (
                    <div className='LayersListComp-edit-buttons'>
                        <button
                            className='LayersListComp-cancel-button button-jump'
                            onClick={isDisabled?this.toggleEditing : this.resetToInitialTreeData}
                        >
                          {store.trans.Cancel}
                        </button>
                        <button
                            className='LayersListComp-save-button button-jump'
                            onClick={this.handleSave}
                            disabled={isDisabled}
                            style={isDisabled ? {opacity:0.3} : {opacity:1}}
                        >
                           {store.trans.Save}
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
