import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2FieldItemComp} from "./A2FieldItemComp";
import './A2FieldList.scss';
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../../icons/LoadingAniSvg";
import {A2FieldPanelButtonComp} from "./A2FieldPanelButtonComp";
import {TextUtils} from "../../../../../helper/utils/TextUtils";
import {ZalFieldListPanelComp} from "../Zalog/ZalFieldListPanel/ZalFieldListPanelComp";
import {ScrollSaveComp} from "../../../../Common/ScrollSaveComp";
import {action} from "mobx";
import {ra} from "../../../../../helper/utils/mobxUtils";
import {IA2FieldTitle} from "../../../../../store/agro/A2FieldStore";

@observer
export class A2FieldListComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onScroll(pos: number){
        let store = this.props.store;
        let navigatorItem = store.agro2.projectStruct.activeNavigatorPage;
        navigatorItem.scrollTop = pos;
    }
    render() {
        let store = this.props.store;
        let items: any[] = [];
        let navigatorItem = store.agro2.projectStruct.activeNavigatorPage;
        //if ( store.agro.fieldsLoadStatus == LoadStatus.ready){
        let arr = navigatorItem.filtered_fields.map(a => a).sort((a, b)=> {
            let at: IA2FieldTitle ={title: a.name, field: a };
            let bt: IA2FieldTitle ={title: b.name, field: b };
            store.events.onAgroGetFieldTitle.call(at);
            store.events.onAgroGetFieldTitle.call(bt);
            if (store.agro.order_desc) {
                return  TextUtils.smartTextSort(bt.title, at.title);
            }
            return  TextUtils.smartTextSort(at.title, bt.title);
        });
        arr.forEach(a => {
            let isSelected = navigatorItem.isSelected(a.id);
            if (store.agro2.filterSelectedChecked && !isSelected) return;
            items.push(<A2FieldItemComp store={store} field={a} key={a.id.toString()}
                                        isHighlight={a.id == navigatorItem.last_opened_field_id}
                                        isSelected={isSelected}
                                        onOpenField={(field_id)=>{
                                            ra(()=>{
                                                navigatorItem.last_opened_field_id = field_id;
                                            });
                                        }}
                                      setSelected={(value)=>{navigatorItem.setSelected(a.id, value)}}/>);
        });
        let children: any[] = [];
        children.push(<ZalFieldListPanelComp store={store} key="Zalogi" />);
        children = store.events.onFieldList.call(children);

        return <React.Fragment>
            <A2FieldPanelButtonComp store={store} />
            <ScrollSaveComp className="flex-fit-item A2FieldList-table height100 margin-top10 table-scroll style-4"
                            scrollTop={navigatorItem.scrollTop}
                            onScroll={this.onScroll}>
                    {navigatorItem.child_status == LoadStatus.loading && <LoadingAniSvg />}
                    {navigatorItem.child_status == LoadStatus.ready && items}
            </ScrollSaveComp>
            {children}
        </React.Fragment>;
    }
}
