import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../../icons/LoadingAniSvg";
import {A2FolderItemComp} from "./A2FolderItemComp";
import './A2FolderCss.scss';
import {TextUtils} from "../../../../../helper/utils/TextUtils";
import {ScrollSaveComp} from "../../../../Common/ScrollSaveComp";
import {action} from "mobx";

@observer
export class A2FolderListComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onScroll(pos: number){
        let store = this.props.store;
        let navigatorItem = store.agro2.projectStruct.activeNavigatorPage;
        navigatorItem.scrollTop = pos;
    }
    render() {
        let store = this.props.store;

        let navItem = store.agro2.projectStruct.activeNavigatorPage;
        let items: any[] = [];
        let childs = [...navItem.filtered_child_values].sort((a,b)=> {
            if (navItem.sort_order) return TextUtils.smartTextSort(a.name, b.name);
            return TextUtils.smartTextSort(b.name, a.name);
        });
        store.events.onFolderListItems.call(childs);
        childs.forEach(a => {
            let highlight = (navItem && navItem.column_stringValue == a.name);
            items.push(<A2FolderItemComp value={a} store={store} key={a.key} highligth={highlight} />);
        });

        let children: any[] = [];
        children = store.events.onFieldList.call(children);

        return <React.Fragment>
            <ScrollSaveComp scrollTop={navItem.scrollTop}
                               onScroll={this.onScroll}
                                className="flex-fit-item A2FolderListComp-table height100 margin-top10 table-scroll style-4">
            {navItem.child_status == LoadStatus.loading && <LoadingAniSvg />}
            {navItem.child_status == LoadStatus.ready && <div className="A2FolderListComp-table">{items}</div>}
        </ScrollSaveComp>
            {children}
        </React.Fragment>;
    }
}
