import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class TrashSvg extends CustomSvg{
    render() {// fill="#E94F4F"
        return <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()} style={this.getStyles()}>
            <path d="M16 5V3H12V1C12 0.448 11.553 0 11 0H5C4.447 0 4 0.448 4 1V3H0V5H16ZM6 2H10V3H6V2Z"/>
            <path d="M13 7V14H3V7H1V15C1 15.552 1.447 16 2 16H14C14.553 16 15 15.552 15 15V7H13Z" />
        </svg>;
    }
}
