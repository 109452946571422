import {IExtent, TrackInfo} from "./agroAhoCardStore";

const agroAhoCfg = {
    Card: {
        map_id: 'num',
        map_name: 'str',
        cell_info: 'CellInfo',
        field_info: 'FieldInfo',
        one_sample_point_info: 'OneSamplePointInfo?',
        track_info: 'TrackInfo',
        upd_tm: 'str',
        selected: 'bool?'
    },
    CardNoValid: {
        map_id: 'num',
        map_name: 'str',
        cell_info: 'CellInfo?',
        field_info: 'FieldInfo?',
        one_sample_point_info: 'OneSamplePointInfo?',
        track_info: 'TrackInfo?',
        upd_tm: 'str',
        selected: 'bool?'
    },
    CellInfo: {
        cell_cnt: 'num',
        ds_code: 'str',
        indicators: 'DictIndicators?'
    },
    DictIndicators: {
        type__: 'DictOfAhoTypes',
        type_key: 'str',
        type_value: 'ProbeCnt'
    },
    ProbeCnt: {
        probe_cnt: 'num'
    },
    FieldInfo: {
        ds_code: 'str',
        field_cnt: 'num',
        sum_area_ha: 'num'
    },
    OneSamplePointInfo: {
        ds_code: 'str',
        // extent: 'IExtent',
        point_cnt: 'num'
    },
    IExtent: {
        lon_min: 'num',
        lon_max: 'num',
        lat_min: 'num',
        lat_max: 'num'
    },
    TrackInfo: {
        ds_code: 'str?',
        sum_len_m: 'num?'
    },
    // UploadTableCnt: {
    //     cnt: 'num'
    // },
    UploadTableSampleItem: {
        row_num: 'num',
        value: 'str',
        type: 'str'
    },
    UploadTableColumn: {
        num: 'num',
        title: 'str',
        class: 'str?',
        indc_code: 'str?',
        // sample: 'str[]',
        sample: 'UploadTableSampleItem[]',
        // cnt: 'UploadTableCnt'
        cnt: 'num'
    },
    UploadTable: {
        file_id: 'str',
        columns: 'UploadTableColumn[]'
    },
    Indicator: {
        description: 'str',
        indc_code: 'str',
        indc_id: 'num',
        indc_name: 'str',
        standard: 'str',
        symbol: 'str',
        unit: 'str'
        // visibility
    },
    ICropValues: {
        crop_id: 'num',
        crop_name: 'str',
        is_rn: 'bool'
    },
    ICrop: {
        has_obj: 'num',
        values: 'ICropValues'
    },
    IFertInfo: {
        type: 'str'
    },
    IFertValues: {
        fert_id: 'num',
        fert_info: 'IFertInfo',
        fert_name: 'str',
        is_rn: 'bool'
    },
    IFert: {
        has_obj: 'num',
        values: 'IFertValues'
    },
    ObservColumnCnt: {
        cnt: 'num'
    },
    DictObservColumn: {
        type__: 'DictOfAhoTypes',
        type_key: 'str',
        type_value: 'ObservColumnCnt'
    },
    IndcsColumnCnt: {
        obs_cnt: 'num'
    },
    DictIndcsColumn: {
        type__: 'DictOfAhoTypes',
        type_key: 'str',
        type_value: 'IndcsColumnCnt'
    },
    ITourObserv: {
        columns: 'DictObservColumn?',
        indicators: 'DictIndcsColumn?'
    },
    ITour: {
        ins_tm: 'str',
        map_id: 'num',
        observ: 'ITourObserv',
        rec_norm: 'ITourInfoRecNorm?',
        tour_date: 'str',
        tour_id: 'num',
        tour_name: 'str',
        upd_tm: 'str'
    },
    RecNormColumnCnt: {
        cnt: 'num',
        rn_id: 'num?',
        crop_id: 'num?',
        fert_id: 'num?',
        centner_ha: 'num?'
    },
    DictRecNormColumn: {
        type__: 'DictOfAhoTypes',
        type_key: 'str',
        type_value: 'RecNormColumnCnt'
    },
    ITourInfoRecNorm: {
        columns: 'DictRecNormColumn'
    }
}

export {agroAhoCfg}