import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoMapStore, IAhoLayers} from "./agroAhoMapStore";
import {observable, reaction} from "mobx";
import {SymbolLayout, SymbolPaint} from "maplibre-gl";
import {fetchJsonGet} from "../../../app/helper/utils/FetchUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {MeasuringStatus} from "../../../app/helper/structs/MeasuringStatus";
import {AgroExportConst} from "../../agroExport/AgroExportConst";
import {AgroAhoConst} from "./AgroAhoConst";
import {AhoEditRouteMode} from "./agroAhoEditRoute";
import {AhoUtils} from "../agroAhoUtils";

export interface SelCell {
    cell_id: number;
    ext: boolean;
}

export class AgroAhoStmtSelectCells extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;
    @observable
    exts: SelCell[] = [];
    isEventsInit: boolean = false;
    ds: string;
    src_data: any = null;

    subscription(): IReactionDisposer[]{
        return [
            reaction(()=>this.root.map.measuringStatus,
                ()=>this.doChangeMeasuringStatus())
        ]
    }

    doChangeMeasuringStatus(){
        let map = this.root.map;
        if (map.measuringStatus != AgroAhoConst.AGRO_AHO_SEL_CELLS)
            this.doDisableMode();
    }

    async doSelCellsModeClick() {
        let cs = this.mapStore.parentStore.cardStore;
        this.resetData();
        this.addSelCellsLayer(cs.card.cell_info.ds_code);
        this.root.map.setMeasuringStatus(AgroAhoConst.AGRO_AHO_SEL_CELLS);
    }

    doSaveClick(){
        this.mapStore.mapEditRouteStore.doDisableModeClick();
    }

    doZoomClick(){
        let st = this.mapStore.parentStore.stmtStore;
        let field: any = {sel_fields_ids: st.exts.map(e=>e.cell_id)};
        // this.mapStore.parentStore.cardStore.doFieldZoomClick(field, 0.005);
    }

    // getRouteData() {
    //     let cell_ids = this.cells.map(c => c.cell_id);
    //     if (this.mode == AhoEditRouteMode.Edit && cell_ids.length && this.field_id) {
    //         return {field_id: this.field_id, cell_ids: cell_ids};
    //     }
    // }

    saveData(){
        let st = this.mapStore.parentStore.stmtStore;
        st.exts.forEach(e=>{
            this.exts.forEach(e_=>{
                if (e.cell_id == e_.cell_id) e.ext = e_.ext;
            });
        });
    }

    resetData(){
        //let st = this.mapStore.parentStore.stmtStore;
        this.exts = []; //st.exts.map(e=>{return {cell_id: e.cell_id, ext: e.ext}});
        this.src_data = null;
        this.deleteSelCellsLayer();
        this.deleteSource();
    }

    doDisableMode() {
        if (this.root.map.measuringStatus == AgroAhoConst.AGRO_AHO_SEL_CELLS)
            this.root.map.resetMeasuringStatus();
        this.resetData();
        this.mapStore.parentStore.stmtStore.doCancel();
    }

    getSelCellsSourceId(ds: string): string {
        return `${ds}_aho_stmt_src`;
    }

    async addSelCellsLayer(ds: string) {
        let st = this.mapStore.parentStore.stmtStore;
        this.exts = st.exts.map(e=>{return {cell_id: e.cell_id, ext: e.ext}});
        let cell_ids = this.exts.map(e=>e.cell_id);
        // console.log('add layer');
        if (!ds) return;
        this.ds = ds;
        let data = await this.mapStore.getCellCardGeoJSONs({ds: this.ds, type: IAhoLayers.Cell, copy: true});
        let map = this.root.map.mapbox;
        this.deleteSelCellsLayer();
        let source_id = this.getSelCellsSourceId(this.ds);
        if (!map.getSource(source_id)) {
            if (typeof data != 'object' || !data.type) return;
            data.features.forEach((f: any)=>{
                let cell_id = f.properties.cell_id;
                if (cell_ids.indexOf(cell_id) >= 0) {
                    f.properties.selcell = this.exts.filter(e=>e.cell_id==cell_id)[0].ext ? 1 : 0;
                }
            });
            // console.log('DATA:', AhoUtils.cp(data));
            this.src_data = data;
            map.addSource(source_id, {
                type: 'geojson',
                data: data
            });
        }

        let layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.SelCells);
        if (!map.getLayer(layer_id)) {
            map.addLayer({
                id: layer_id,
                source: source_id,
                type: 'fill',
                paint: {
                    'fill-color': '#E46C0A',
                    'fill-opacity': [
                        'case',
                        // ['>', ["to-number",['feature-state', 'selcell'],0], 0],
                        ['>', ["to-number",['get', 'selcell'],0], 0],
                        0.5,
                        0
                    ]
                }
            }, await this.mapStore.getPrevLayer(IAhoLayers.SelCells));
            if (!this.isEventsInit) {
                // console.log('layer_id', layer_id);
                map.on('click', layer_id, (e: any) => {
                    this.onCellClick(e, layer_id);
                });
                // map.on('mouseenter', layer_id, () => {
                //     map.getCanvas().classList.add('AgroAhoCursorPointer');
                // });
                // map.on('mouseleave', layer_id, () => {
                //     map.getCanvas().classList.remove('AgroAhoCursorPointer');
                // });
                this.isEventsInit = true;
            }
        }
    }

    deleteSelCellsLayer() {
        let map = this.root.map.mapbox;
        let layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.SelCells);
        if (map.getLayer(layer_id)) {
            map.removeLayer(layer_id);
        }
    }

    deleteSource(){
        let map = this.root.map.mapbox;
        let source_id = this.getSelCellsSourceId(this.ds);
        if (map.getSource(source_id)) {
            map.removeSource(source_id);
        }
    }

    // async onSelCellsClick(e: any, layer_id: string) {
    //     let store_ = this.mapStore.parentStore;
    //     let cs = store_.cardStore;
    //     let feat = null;
    //     if (e.features && e.features.length > 0 && e.features[0].layer.id == layer_id) feat = e.features[0];
    //     if (!feat || feat.layer.id !== layer_id) return false;
    //     console.log('cell click', feat?.properties);
    //
    //     // this.field_id = feat?.properties?.field_id;
    //     // await this.updCellIds(e.lngLat);
    //     // if (!this.field_id || !this.cells) return;
    //
    // }



    async onCellClick(e: any, layer_id: string) {
        let cell_id = e?.features[0]?.properties?.cell_id;
        let val: any = undefined;
        this.exts.forEach(e=>{
            if (e.cell_id == cell_id) {
                e.ext = !e.ext;
                val = e.ext;
            }
        })
        // console.log('click', val, typeof val !== 'undefined');
        if (typeof val !== 'undefined') {
            this.src_data.features.forEach((f: any)=>{
                if (f.properties.cell_id == cell_id)
                    f.properties.selcell = val ? 1 : 0;
            });
            let source_id = this.getSelCellsSourceId(this.ds);
            let map = this.root.map.mapbox;
            let src: any = map.getSource(source_id);
            if (!src) return;
            src.setData(this.src_data);
            // console.log('DATA:', AhoUtils.cp(this.src_data));
        }
        // console.log('DATA:', AhoUtils.cp(data));
        // console.log('cell_id1', cell_id);
        // if (this.sel_cells_ids.indexOf(cell_id)) {}
        // let feat = null;
        // if (e.features && e.features.length > 0 && e.features[0].layer.id == layer_id) feat = e.features[0];
        // if (!feat || feat.layer.id !== layer_id) return false;
        // let cell_id = feat?.properties?.cell_id;
        // console.log('cell_id2', cell_id);
        // this.updSourceOrder();
        // this.canSave = this.cells.every(c=>!!c.order);
    }

    // updCellsOrder() {
    //     // if (!this.sel_cells_ids) return;
    //     let map = this.root.map.mapbox;
    //     let source_id = this.getSelCellsSourceId(this.ds);
    //     if (!map.getSource(source_id)) return;
    //     // this.sel_cells_ids.forEach(cell_id => {
    //     //     this.root.map.mapbox.setFeatureState(
    //     //         { source: source_id, id: cell_id},
    //     //         {scell: 1});
    //     // });
    // }

}