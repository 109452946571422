import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import "./AgroAhoSwitcherSvg.scss"


export interface IAgroAhoSwitcherSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
    checked?: boolean;
}

export class AgroAhoSwitcherSvg extends React.PureComponent<IAgroAhoSwitcherSvgProps, undefined> {
    constructor(props: IAgroAhoSwitcherSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className, {"AgroAhoSwitcherSvg-checked": this.props.checked})}
            onClick={this.props.onClick ? this.props.onClick : ()=>false} width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="AgroAhoSwitcherSvg-cover" d="M7.5 0L16.5 0C20.7 0 24 3.3 24 7.5C24 11.7 20.7 15 16.5 15H7.5C3.3 15 0 11.7 0 7.5C0 3.3 3.3 0 7.5 0Z"/>
            <path className="AgroAhoSwitcherSvg-circle" d="M16.5 12C19.05 12 21 10.05 21 7.5C21 4.95 19.05 3 16.5 3C13.95 3 12 4.95 12 7.5C12 10.05 13.95 12 16.5 12Z"/>
        </svg>;
    }
}
