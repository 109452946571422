import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action, runInAction} from "mobx";
import {ExportDiffSatErrorComp} from "./ExportDiffSatErrorComp";
import autoBindReact from "auto-bind/react";
import {Export1OrderType, ExportItemsCategory, ExportItemsType} from "../../../../store/export/ExportStore";
import {ISwitchItem, SwitchItems} from "../../../Common/SwitchItems";
import {InfoButtonComp} from "../../../Common/InfoButtonComp";
import {SatelliteExportComp} from "./SatelliteExportComp";
import classNames from 'classnames';


@observer
export class ExportOptionsComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        setImmediate(()=>{
            runInAction(()=>{
                this.props.store.exportStore.email = this.props.store.user.curEmail;
            });
        });
    }

    @action
    onClose(){
        let store = this.props.store;
        store.exportStore.showExportOptions = false;
    }



    @action onClickListFavAll(){
        this.props.store.exportStore.exportItemsType = ExportItemsType.allFavorites;
    }
    @action onClickListFavVisible(){
        this.props.store.exportStore.exportItemsType = ExportItemsType.favoritesSelected;
    }

    onClickExport(){
        this.props.store.exportStore.exportDailyMosaic();
    }
    @action
    onChangeOrderType(item: ISwitchItem){
        if (item.key == Export1OrderType.fusion){

            this.props.store.exportStore.mosaic = false;
            this.props.store.exportStore.fusion = true;
        }
        if (item.key == Export1OrderType.mosaic){
            this.props.store.exportStore.mosaic = true;
            this.props.store.exportStore.fusion = false;
        }
        if (item.key == Export1OrderType.scenes){
            this.props.store.exportStore.mosaic = false;
            this.props.store.exportStore.fusion = false;
        }
    }

    render() {
        let store = this.props.store;
        let es = this.props.store.exportStore;

        let allScenesCount = store.searchPanel.favoriteList.records.length;
        let onlyVisibleCount = store.searchPanel.favoriteList.records.filter(a => a.selected).length;


        let hasGeometry = store.map.searchObject.isNotEmpty;
        let onlineEnable = es.makeOnlineEnable();
        let curOrderType = es.getOrder1Type();


        return <div>
            <div className="popup-pref active-flex">
                {
                <div className="popup-pref-in ">
                    <div  className="vertical-scroll style-4 width100 flex-stretch-item"  >
                        <div className="popup-pref-in-closer" onClick={this.onClose}>
                            <div className="close-button-icon"></div>
                        </div>
                        <div className="popup-exp-title">
                            <div className="popup-exp-row-header">{store.trans["Export options"]}</div>
                            {/* <InfoButtonComp text={store.trans["Select the attributes of your table from the uploaded file that you want to import and match them to the characteristics of your objects"]} /> */}
                        </div>
                        {es.exportItemsCategory == ExportItemsCategory.favorites && <React.Fragment>
                        <div className="popup-exp-visible">
                            <input type="radio" name="radiog_lite-1" id="radio1" className="css-checkbox"
                                   onChange={this.onClickListFavAll} checked={es.exportItemsType == ExportItemsType.allFavorites}/><label
                            htmlFor="radio1" className="css-label ">{store.trans["All"]}<span className="badget text-next">{allScenesCount}</span></label>
                            <input type="radio" name="radiog_lite-1" id="radio2" className="css-checkbox"
                                   onChange={this.onClickListFavVisible} checked={es.exportItemsType == ExportItemsType.favoritesSelected}/><label
                            htmlFor="radio2" className="css-label ">{store.trans["Only visible"]}<span className="badget text-next">{onlyVisibleCount}</span></label>
                        </div>
                        {/* <div className="popup-pref-in__separator"/> */}
                        </React.Fragment>}

                        <SwitchItems items={[
                            {key: Export1OrderType.scenes, value: store.trans.Scenes},
                            {key: Export1OrderType.mosaic, value: store.trans.Mosaic},
                            {key: Export1OrderType.fusion, value: "Fusion (LS 7-8)"}]}
                                     currentKey={curOrderType}
                                     onChange={this.onChangeOrderType}
                                     className={"exportOptions-switcher"}  />
                        {es.isInvalidMosaic && <ExportDiffSatErrorComp store={store} >
                            <div className="popup-exp_dif-text">
                                "Mosaic" task is active only if there are images for different dates.
                            </div>
                            <div className="popup-exp_dif-text">
                                For one date scenes use "Scenes" task (turn on there option "Merge scenes from single path")
                            </div>
                        </ExportDiffSatErrorComp>}
                        {es.isInvalidSat && !es.isInvalidFusion &&
                            <ExportDiffSatErrorComp store={store} >
                                <div className="popup-exp_dif-text">
                                    An order can contain scenes
                                </div>
                                <div className="popup-exp_dif-text">
                                    only of one type (satellite X product level)
                                </div>
                            </ExportDiffSatErrorComp>}
                        {es.isInvalidSat && es.isInvalidFusion &&
                            <ExportDiffSatErrorComp store={store} >
                                <div className="popup-exp_dif-text">
                                    Fusion works only for Landsat-7,
                                </div>
                                <div className="popup-exp_dif-text">
                                    Landsat-8 scenes
                                </div>
                            </ExportDiffSatErrorComp>}
                        {!es.isInvalidMosaic && !es.isInvalidSat && <SatelliteExportComp
                            satExport={store.exportStore.favoriteExport}
                            isMosaic={curOrderType == Export1OrderType.mosaic}
                            isFusion={curOrderType == Export1OrderType.fusion}
                            store={store}/>}
                    </div>
                    <div className="popup-pref-in__separator mini"/>
                    <div className="popup-exp-2button">
                        <button
                            style={{width:'100px'}}
                            className="large-button left-sidebar-cancel  button-jump popup-exp-2button-button"
                            onClick={this.onClose}>{store.trans.Cancel} 
                        </button>
                        {/* <div className="popup-exp-2button-div"/> */}
                        <button style={{width:'120px'}}
                                onClick={this.onClickExport}
                                disabled={!es.isValidOptions}
                                className="large-button left-sidebar-active button-jump popup-exp-2button-button">
                            {store.trans.Run}
                        </button>
                    </div>
                </div>
                }
            </div>
        </div>;
    }
}
