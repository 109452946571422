import mapboxgl, {MapboxGeoJSONFeature, MapMouseEvent, PointLike} from "maplibre-gl";
import {CustomStoreTool} from "../general/CustomStoreTool";
import {SuperTools} from "../general/SuperTools";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import { OverlayObjectStore } from "../../OverlayObjectStore";

export class PopupTool extends CustomStoreTool {

    get tools(): SuperTools{
        return this.store.map.superTools;
    }

    moving: boolean = false;

    onMouseClick(e: MapMouseEvent & ToolEvent): void {
        let ms = this.tools.store.map;
        if (ms.measuringStatus != MeasuringStatus.None 
            //|| ms.superTools.searchAddressMarker.geoObject
        ) return;
        if (this.moving) {
            this.moving = false;
            return;
        }
        let ps = this.tools.store.popup;
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(e.point.x - 5, e.point.y - 5),
            new mapboxgl.Point(e.point.x + 5, e.point.y + 5)
        ];
        let layers: any = {};
        ms.overlays.visibleOverlays.forEach(ov => {
            ov.layers.forEach((lr, idx) => {
                let layerId = OverlayObjectStore.getOverlayLayerName(ov.id, idx);
                layers[layerId] = {overlay: ov};
            });
        });
        let features: MapboxGeoJSONFeature[] = ms.mapbox.queryRenderedFeatures(bbox, {layers: Object.keys(layers)});
        let found = features.length > 0;        
        ps.lngLat = found ? e.lngLat : null;
        ps.data = found ? {
            x: e.point.x,
            y: e.point.y,
            feature: features[0],
            overlay: layers[features[0].layer.id].overlay
        } : null;
        if (found) e.isNoPropagation = true;
    }


    onDblclick(e: MapMouseEvent & ToolEvent): void {
        this.tools.store.popup.data = null;
    }

    onMove(e: MapMouseEvent & ToolEvent): void {
        let ps = this.tools.store.popup;
        if (!ps.lngLat) return;
        let map = this.tools.store.map.mapbox;
        let pt = map.project(ps.lngLat);
        ps.data = {...ps.data, x: pt.x, y: pt.y};
        let delta = (e.originalEvent as any)?.wheelDelta | 0;
        this.moving = (delta == 0);
    }
}