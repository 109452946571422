// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoPanelComp-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-left: 1px solid #22282F;
  border-top: 1px solid #22282F;
  padding-bottom: 15px;
  position: relative;
}

.left-sidebar__right-top .top-button:nth-child(2),
.left-sidebar__right-top .top-button:nth-child(3) .arrow_pref {
  display: none;
}

.left-sidebar__right-top div.top-button.top-button-big {
  width: 40px;
}

.AgroAhoPanelComp-animateDouble {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
}

#map.AgroAhoTmpHidePopupMenuItem div.popup-right-click__row:nth-child(4),
#map.AgroAhoTmpHidePopupMenuItem div.legendLayer-panel {
  display: none;
}

.AgroAhoWelcome-agroSoft,
.AgroAhoWelcome-c2g {
  text-decoration: none;
  color: #6B707B;
}

.AgroAhoWelcome-agroSoft {
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/AgroAhoPanelComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,6BAAA;EACA,oBAAA;EACA,kBAAA;AACF;;AACA;;EAEE,aAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AADA;EACE,4BAAA;EACA,oCAAA;AAIF;;AAOA;;EAEE,aAAA;AAJF;;AAaA;;EAEE,qBAAA;EACA,cAAA;AAVF;;AAYA;EACE,kBAAA;AATF","sourcesContent":[".AgroAhoPanelComp-main {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex-grow: 1;\r\n  border-left: 1px solid #22282F;\r\n  border-top: 1px solid #22282F;\r\n  padding-bottom: 15px;\r\n  position: relative;\r\n}\r\n.left-sidebar__right-top .top-button:nth-child(2),\r\n.left-sidebar__right-top .top-button:nth-child(3) .arrow_pref {\r\n  display: none;\r\n}\r\n.left-sidebar__right-top div.top-button.top-button-big {\r\n  width: 40px;\r\n}\r\n.AgroAhoPanelComp-animateDouble {\r\n  animation-iteration-count: 2;\r\n  -webkit-animation-iteration-count: 2;\r\n\r\n  //-webkit-animation-duration: calc(1s * 1.5);\r\n  //animation-duration: calc(1s * 1.5);\r\n  //-webkit-animation-duration: calc(var(--animate-duration) * 1.5);\r\n  //animation-duration: calc(var(--animate-duration) * 1.5);\r\n  //-webkit-animation-name: bounceInDouble;\r\n  //animation-name: bounceInDouble;\r\n  //-webkit-animation-fill-mode: both;\r\n  //animation-fill-mode: both;\r\n}\r\n#map.AgroAhoTmpHidePopupMenuItem div.popup-right-click__row:nth-child(4),\r\n#map.AgroAhoTmpHidePopupMenuItem div.legendLayer-panel {\r\n  display: none;\r\n}\r\n\r\n.AgroAhoCursorPointer {\r\n  // disable cursor\r\n  //cursor: pointer !important;\r\n}\r\n\r\n// >>> modify base code >>> //\r\n.AgroAhoWelcome-agroSoft,\r\n.AgroAhoWelcome-c2g {\r\n  text-decoration: none;\r\n  color: #6B707B;\r\n}\r\n.AgroAhoWelcome-agroSoft {\r\n  margin-right: 16px;\r\n}\r\n// <<< modify base code <<< //"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
