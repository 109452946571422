import * as React from 'react';
import {CustomSvg} from "../../../../app/components/icons/CustomSvg";

export class AgroAhoSvg extends CustomSvg {

    render() {
        return <svg className={this.getClasses()} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0625 0H2.9375C2.62684 0 2.375 0.25184 2.375 0.5625V2.8125C2.375 3.12316 2.62684 3.375 2.9375 3.375H13.0625C13.3732 3.375 13.625 3.12316 13.625 2.8125V0.5625C13.625 0.25184 13.3732 0 13.0625 0Z"/>
            <path d="M10.8125 9.918V4.5H5.18755V9.918L1.5538 14.2133C1.29203 14.5075 1.10854 14.8628 1.02027 15.2466C0.931988 15.6304 0.941756 16.0302 1.04867 16.4093C1.18792 16.871 1.4727 17.2754 1.86054 17.562C2.24839 17.8487 2.71851 18.0023 3.2008 18H12.7993C13.2816 18.0023 13.7517 17.8487 14.1395 17.562C14.5274 17.2754 14.8122 16.871 14.9514 16.4093C15.0582 16.0303 15.068 15.6306 14.9799 15.2469C14.8918 14.8632 14.7087 14.5077 14.4474 14.2133L10.8125 9.918Z" />
        </svg>;
    }
}
