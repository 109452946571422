import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroExportStore} from "./agroExportStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {observable, reaction} from "mobx";
import {IAgroExportSuperStore} from "../agroExportPlugin";
import autoBind from "auto-bind";
import * as React from 'react';
import {AgroExpUtils} from "./AgroExportUtils";
import {fetchJsonPost} from "../../../app/helper/utils/FetchUtils";


export enum AgroExpError{
    NoSeason = 'NoSeason',
    NoFields = 'NoFields',
    ErrCreating = 'ErrCreating'
}

export class AgroExportPointsStore extends ObservableCustomStore {
    constructor(parent: AgroExportStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroExportStore;
    @observable
    newAsaMapName: string = '';
    @observable
    showCreateForm: boolean = false;
    farms: any = {};
    seasonName: string = '';
    seasonId: number = 0;
    fieldsCnt: number = 0;
    pointsCnt: number = 0;
    outPoints: number = 0;
    oneSamplePoints: string = '';

    subscription(): IReactionDisposer[]{
        return [
            reaction(()=>this.root.map.surveyPoints?.showPanel, this.doPanelShow),
            reaction(()=>this.root.map.surveyPoints?.points, this.doPointsChange)
        ]
    }

    getBut(){
        return document.querySelector('.AgroExportPointsPanel-createAsaMapButton');
    }
    getMsg(){
        return document.querySelector('.AgroExportPointsPanel-createAsaMapMsg');
    }

    async savePoints(){
        let fts_ = this.root.map.surveyPoints?.points?.features;
        if (fts_) {
            let season = this.root.agro2.projectStruct.activeNavigatorPage.getNaviPath()
                .find(a => a.columnInfo != null && a.columnInfo.column_name
                    == this.root.agro2.projectInfo.fieldName_season);
            let season_id = season?.column_value;
            this.seasonName = season?.column_stringValue;
            this.seasonId = 0;
            if (season_id) {
                this.seasonId = season_id;
                let fts = fts_.map(f=>{return {properties: {point_id: f.properties.num}, geometry: f.geometry}});
                let url = '/api/survey_points/save';
                let saveRes = await fetchJsonPost(url, {survey_points: fts});
                // let saveRes = {point_list_id: '35d5394049af6278ed8b802952f11b07'};
                // let saveRes = {point_list_id: false};
                // /api/survey_points/get_fields?point_list_id=b3261cda5f08cad45f16a26f0d88fbcd&proj_name=kazm11&season_id=13
                this.oneSamplePoints = '';
                if (saveRes.point_list_id) {
                    this.oneSamplePoints = saveRes.point_list_id;
                    let params = {
                        point_list_id: saveRes.point_list_id,
                        proj_name: this.root.agro.projectName,
                        season_id: season_id
                    };
                    url = '/api/survey_points/get_fields';
                    let res = await fetchJsonPost(url, params);

                    // console.log('get_fields:', AgroExpUtils.cp(res));
                    if (res?.fields) {
                        this.fieldsCnt = res.fields.length;
                        this.pointsCnt = 0;
                        let frms: any = {};
                        res.fields.forEach((v: any)=>{
                            if (!frms[v.farm_name]) frms[v.farm_name] = [];
                            frms[v.farm_name].push(v.field_name);
                            this.pointsCnt += v.point_cnt;
                        });
                        this.outPoints = fts_.length - this.pointsCnt;
                        // console.log('p:', fts_.length, this.pointsCnt, this.outPoints);
                        this.farms = frms;
                        this.newAsaMapName = '';
                        this.showCreateForm = true;
                    }

                } else this.errShow(AgroExpError.ErrCreating, 'no point_list_id');
            } else this.errShow(AgroExpError.NoSeason);
        } else this.errShow(AgroExpError.ErrCreating, 'no points features');
    }

    async doAsaMapCreate(){
        // /api/projects/kazm11/asa2/map/create?map_name=Test_A01&season_id=13&one_sample_points=df8f203ef8f24d51e1c85d8137dc7c4a
        // one_sample_points = результат ендпоинта /api/survey_points/save

        // let params = {
        //     point_list_id: saveRes.point_list_id,
        //     proj_name: this.root.agro.projectName,
        //     season_id: season_id
        // };
        // url = '/api/survey_points/get_fields';

        let params = {
            map_name: this.newAsaMapName,
            season_id: this.seasonId,
            one_sample_points: this.oneSamplePoints
        };
        let url = `/api/projects/${this.root.agro.projectName}/asa2/map/create`;
        let res = await fetchJsonPost(url, params);
        if (res?.status == 'ok') {
            this.root.addInfo(this.parentStore.trans['The ASA map successfully created']);
            this.parentStore.voronovExportStore.numberOfSaves += 1;  // анимация иконки "анализ почвы"
        } else this.root.addError(this.parentStore.trans['Error creating ASA map']);
    }

    async doPanelShow(){
        if (this.root.map.surveyPoints?.showPanel) {
            let panel = document.querySelector('.SurveyPointsComp-footer-panel');
            if (panel) {
                if (!this.getBut()) {
                    let but = AgroExpUtils.html2el(`<button 
                        class="large-button left-sidebar-cancel button-jump AgroExportPointsPanel-createAsaMapButton">
                        <span>${this.parentStore.trans['Create a ASA map']}</span>
                    </button>`);
                    panel.prepend(but);
                    but.addEventListener('click', this.savePoints);
                }
                if (!this.getMsg()){
                    let msg = AgroExpUtils.html2el('<div class="AgroExportPointsPanel-createAsaMapMsg '+
                        'AgroExportPointsPanel-createAsaMapMsgHidden"></div>');
                    let parent = document.querySelector('.SurveyPointsComp-main');
                    // let footer = document.querySelector('.SurveyPointsComp-footer');
                    // parent.insertBefore(msg, footer);
                    parent.append(msg);
                }
            }
            this.doPointsChange();
        }
    }

    errShow(err: AgroExpError, consoleMsg?: string){
        console.log('error export points:', consoleMsg || err);
        let msg: any = this.getMsg();
        if (msg) {
            let txt = this.parentStore.trans['Error creating ASA map'];
            if (err == AgroExpError.NoFields) txt = this.parentStore.trans['Creation is impossible! To create a ASA map inspection points must be within the field boundaries.'];
            if (err == AgroExpError.NoSeason) txt = this.parentStore.trans['Season not selected! In Fields select season'];
            msg.style.textAlign = err == AgroExpError.ErrCreating ? 'left' : 'center';
            msg.innerHTML = txt;
            msg.classList.remove('AgroExportPointsPanel-createAsaMapMsgHidden');
            setTimeout(()=>msg.classList.add('AgroExportPointsPanel-createAsaMapMsgHidden'), 5000);
        }
    }

    canCreate(){
        return !!this.root.map.surveyPoints?.points?.features?.length;
    }

    doPointsChange(){
        let but = this.getBut();
        if (but) {
            but.classList.toggle('AgroExportPointsPanel-createAsaMapButtonDisable', !this.canCreate());
            but.classList.toggle('button-jump', this.canCreate());
        }
    }

}