import * as React from 'react';
import {CSSProperties} from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import './SoilGridsSourcePallete.scss';

export interface ISoilGridSourcePalleteCompProps {
    colors: string[];
    labels: string[];
    className?: string;
}

export class SoilGridsSourcePalleteComp extends React.PureComponent<ISoilGridSourcePalleteCompProps, undefined> {
    constructor(props: ISoilGridSourcePalleteCompProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        if (!this.props.colors || this.props.colors.length < 2) return null;
        let labelElems: any[] = [];
        this.props.labels.forEach(a => {
            labelElems.push(<div className="SoilGridsSourceDepth-itemLabel" key={a}>
                <div className="SoilGridsSourceDepth-itemLine"/>
                <div className="SoilGridsSourceDepth-itemText" data-attr="0">{a}</div>
            </div>);
        });

        let str = "";
        let cnt = this.props.colors.length;
        let step = 1 / (cnt - 1);
        this.props.colors.forEach((c, index) => {
            if (index > 0) str+=" ,";
            str += ` ${c} ${(index*step)*100}%`;
        });
        let backColor: string = `linear-gradient(180deg, ${str})`;//"linear-gradient(180deg, #F0F9E8 0%, #C6E9C6 24.48%, #77CAC5 47.92%, #4CA9C9 73.44%, #0868AC 100%)"
        let style: CSSProperties = {background: backColor};
        return <div className={classNames("SoilGridsSourcePallete-divOuter")}>
            <div className="SoilGridsSourcePallete-divPallete">
            <div className={classNames("SoilGridsSourcePallete-main",this.props.className)} >
                <div style={style} className="SoilGridsSourcePallete-inner"/>
            </div>
            </div>
            <div className="SoilGridsSourcePallete-divLabels">
                {labelElems}
            </div>
        </div>;
    }
}
