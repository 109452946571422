import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoMergeIsoXmlComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoRoundCheckboxSvg} from "../AgroAhoRoundCheckboxSvg";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";
import {AgroAhoMergeIsoXmlItemComp} from "./AgroAhoMergeIsoXmlItemComp";
import classNames from "classnames";
import {AgroAhoMergeIsoXmlFarmComp} from "./AgroAhoMergeIsoXmlFarmComp";
import {AhoUtils} from "../../agroAhoUtils";

@observer
export class AgroAhoMergeIsoXmlComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    async onClickMerge(){
        if (!this.canMerge()) return;
        let store_ = this.props.store.agroAhoStore;
        let res = await store_.cardsStore.uploadIsoXMlStore.doMerge();
        // store.projStore.setVisFormat();
        store_.toggleModal(false);
    }
    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }
    onInputChanged(e:any){
        let ust = this.props.store.agroAhoStore.cardsStore.uploadIsoXMlStore;
        ust.mergeFileName = e.target.value;
    }
    canMerge(){
        let ust = this.props.store.agroAhoStore.cardsStore.uploadIsoXMlStore;
        let name = ust.mergeFileName;
        let isSelect = ust.mergeIsoXmlFileFarmsDictArr.some(fd=>{
            return Object.keys(fd.dict).some(farm_id=>{
                return fd.dict[+farm_id].fields.some(f=>f.selected);
            });
        });
        // let list1 = Object.keys(ust.mergeFarms1).some(farm_id=>{
        //     return ust.mergeFarms1[+farm_id].fields.some(f=>f.selected);
        // });
        // let list2 = ust.mergeFarms2 && Object.keys(ust.mergeFarms2).some(farm_id=>{
        //     return ust.mergeFarms2[+farm_id].fields.some(f=>f.selected);
        // });
        // return name && (list1 || list2);
        return name && isSelect;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ust = store_.cardsStore.uploadIsoXMlStore;

        let files = ust.mergeIsoXmlFileFarmsDictArr.map(da=>{
            return {file_name: da.file_name, farms: Object.keys(da.dict).map(farm_id=>{
                let farm = da.dict[+farm_id];
                return <AgroAhoMergeIsoXmlFarmComp key={farm_id} store={store} farm={farm}/>
            })};
        });

        // let list1 = Object.keys(ust.mergeFarms1).map(farm_id=>{
        //     let farm = ust.mergeFarms1[+farm_id];
        //     return <AgroAhoMergeIsoXmlFarmComp key={farm_id} store={store} farm={farm}/>
        // });
        // let list2 = null;
        // if (ust.mergeFarms2) {
        //     list2 = Object.keys(ust.mergeFarms2).map(farm_id=>{
        //         let farm = ust.mergeFarms2[+farm_id];
        //         return <AgroAhoMergeIsoXmlFarmComp key={farm_id} store={store} farm={farm}/>
        //     });
        // }

        // return <div className={classNames("AgroAhoMergeIsoXmlComp-main", {
        //     "AgroAhoMergeIsoXmlComp-oneFile": files.length == 1})}>
        return <div className="AgroAhoMergeIsoXmlComp-main">
            <div className="AgroAhoMergeIsoXmlComp-caption">{store_.trans['Merge multiple ISO-XML']}</div>
            <div className="AgroAhoMergeIsoXmlComp-titleCover">
                <div className="AgroAhoMergeIsoXmlComp-title">{store_.trans['Merged file name']}</div>
                <div className="AgroAhoMergeIsoXmlComp-titleAsterisk">*</div>
                <div className="AgroAhoMergeIsoXmlComp-zip">ZIP</div>
            </div>
            <input type="text" className="AgroAhoMergeIsoXmlComp-input text-box-editor"
                value={ust.mergeFileName} onChange={this.onInputChanged}/>

            <div className="AgroAhoMergeIsoXmlComp-listWrapper">
                <div className="AgroAhoMergeIsoXmlComp-filesListCover style-4">
                    {files.map((f,i)=><div key={i} className="AgroAhoMergeIsoXmlComp-listCover">
                        <div className="AgroAhoMergeIsoXmlComp-listTitle">{f.file_name}</div>
                        {/*<div className="AgroAhoMergeIsoXmlComp-listFileName">{ust.mergeFile1Name}</div>*/}
                        <div className="AgroAhoMergeIsoXmlComp-list style-4">{f.farms}</div>
                    </div>)}
                </div>
            </div>

            {/*<div className="AgroAhoMergeIsoXmlComp-listCover">*/}
            {/*    <div className="AgroAhoMergeIsoXmlComp-listTitle">{store_.trans.File}-1</div>*/}
            {/*    /!*<div className="AgroAhoMergeIsoXmlComp-listFileName">{ust.mergeFile1Name}</div>*!/*/}
            {/*    <div className="AgroAhoMergeIsoXmlComp-list style-4">{list1}</div>*/}
            {/*</div>*/}
            {/*<div className="AgroAhoMergeIsoXmlComp-listCover">*/}
            {/*    <div className="AgroAhoMergeIsoXmlComp-listTitle">{store_.trans.File}-2</div>*/}
            {/*    /!*<div className="AgroAhoMergeIsoXmlComp-listFileName">{ust.mergeFile2Name}</div>*!/*/}
            {/*    <div className="AgroAhoMergeIsoXmlComp-list style-4">{list2}</div>*/}
            {/*</div>*/}

            <div className="AgroAhoMergeIsoXmlComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoMergeIsoXmlComp-cancel"
                    onClick={this.onClickCancel}>{store_.trans2.Cancel}</button>
                <button className={classNames("large-button left-sidebar-active", {
                    "button-jump": this.canMerge(),
                    "AgroAhoMergeIsoXmlItemComp-disabledButton": !this.canMerge()})}
                    onClick={this.onClickMerge}>{store_.trans.Merge}</button>
            </div>
        </div>;
    }
}
