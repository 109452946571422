import {MapCustomItemStore} from "./MapCustomItemStore";

export class MapLayerItemStore extends MapCustomItemStore{
    sourceId: string;
    subLayers: MapLayerSubLayerStore[] = [];

    source: MapSourceStore = null;
    sourceLayer: string = null;

    get itemType():MapItemType{
        return MapItemType.layer;
    }
    setVisibleOnMap(visible: boolean){
        this.subLayers.forEach(l => {l.setVisibleOnMap(visible && this.visible)});
    }
}
import {MapSourceStore} from "./MapSourceStore";
import {MapLayerSubLayerStore} from "./MapLayerSubLayerStore";
import {MapItemType} from "../../config/MapConfigInterfaces";
