import React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './FavoritePanel.scss';

interface IFavSceneTitleCompProps {
    title: string;
}

const FavSceneTitleComp = (props: IFavSceneTitleCompProps) => {
    return (<div className="FavSceneTitleComp">{props.title}</div>);
}

export default FavSceneTitleComp;