import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {Utils} from "../../../helper/utils/Utils";
import {action} from "mobx";
import {nextFormat} from "../../../helper/utils/CoordinateStringFormat";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


@observer
export class BottomSidebarComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    static TEST_MIN_MULTIPLIER : number = 42;
    static TEST_MAX_MULTIPLIER : number = 84;
    static EQUATOR_MPP : number = 78271.484;

    @action onClick() {
        let ms = this.props.store.map;
        ms.coosFormat = nextFormat(ms.coosFormat);
    }

    static getScaleParams(lat: number, zoom: number) : {scale: number, ratio: number} {
        let mpp = BottomSidebarComp.EQUATOR_MPP * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
        let max = mpp*BottomSidebarComp.TEST_MAX_MULTIPLIER;
        if (max < 1)
            return {scale: 1, ratio: 1};
        let scales = [5, 3, 2, 1];
        let maxExpo = max.toExponential();
        let firstDigit = parseInt(maxExpo[0]);
        let multiplier = Math.pow(10, parseInt(maxExpo.substring(maxExpo.indexOf('+') + 1)));
        let selected: number = null;
        for (let i = 0; i < scales.length; i++) {
            if (scales[i] <= firstDigit) {
                selected = scales[i];
                break;
            }
        }
        selected *= multiplier;
        return {scale: selected, ratio: selected / max};
    }

    render() {
        let ms = this.props.store.map;
        let store = this.props.store;
        let compareMode = store.map.compareModeEnabled;
        let p = BottomSidebarComp.getScaleParams(ms.center.lat, ms.zoom);
        let scaleStr = (p.scale < 1000)? p.scale.toString() + " m" : (p.scale / 1000).toString() + " km";
        let scaleWidth = Math.round(BottomSidebarComp.TEST_MAX_MULTIPLIER * p.ratio);
        return <div className={classNames("copyright transit-map",compareMode? 'copyright-compare' : '')}>
            <span>{store.trans["© Ctrl2GO"]}</span>
            <span className="copyright-coords">{store.trans["lat, lon:"]} {Utils.coosToString(ms.mousePointer[0], ms.mousePointer[1], ms.coosFormat)}</span>
            <div className="copyright-right">
                <div onClick={this.onClick} className="refresh"/>
                <div className="measuring-panel">
                    <div className="rectangle" style={{
                        width : scaleWidth,
                        left : BottomSidebarComp.TEST_MAX_MULTIPLIER - scaleWidth
                    }}/>
                </div>
                <span style={{width : 50}}>{scaleStr}</span>
            </div>
        </div>;
    }
}
