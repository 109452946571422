import './LogView_DownFiltersComp.scss';
import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {ReactDoubleDatePicker2Comp} from "../../../Common/ReactDoubleDatePicker2Comp";
import {DropDownComp, IDropDownItem} from "../../../Common/DropDownComp";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {LogOperation} from "../../../../store/user/LogView/LogViewStore";
import {action} from "mobx";


@observer
export class LogView_DownFiltersComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        setImmediate(async()=>{
            await this.props.store.user.logView.filters.doLoadUserList();
        });
    }
    @action
    onChangeUser(item: IDropDownItem, newIndex: number){
        this.props.store.user.logView.filters.filter_userBadgetText = item.value;
        this.props.store.user.logView.filters.filter_userId = item.key as any;
    }
    @action
    onChangeOper(item: IDropDownItem, newIndex: number){
        this.props.store.user.logView.filters.filter_operation = item.key as any;
    }

    render() {
        let store = this.props.store;
        let lvs = store.user.logView;

        let usersItems: IDropDownItem[] = [];
        usersItems.push({key: null, value: store.trans.All});
        lvs.filters.users.forEach(a =>{
            usersItems.push({key: a.user_id, value: TranslateUtils.getUserFullName(a.surname , a.given_name) + " | " + a.email});
        });
        let operItems: IDropDownItem[] = [];
        operItems.push({key: null, value: store.trans.All});
        operItems.push({key: LogOperation.change_geometry_oks, value: lvs.getTitleAction(LogOperation.change_geometry_oks)});
        operItems.push({key: LogOperation.create_request, value: lvs.getTitleAction(LogOperation.create_request)});
        operItems.push({key: LogOperation.login, value: lvs.getTitleAction(LogOperation.login)});
        operItems.push({key: LogOperation.logout, value: lvs.getTitleAction(LogOperation.logout)});
        operItems.push({key: LogOperation.download_report, value: lvs.getTitleAction(LogOperation.download_report)});

        //IndexByPointPopupGroupsItemComp-GroupItem
        return <div className="LogView_DownFiltersComp-dropDown">
            <div className="LogView_DownFiltersComp-title">
                {store.trans.Filters}
            </div>
            <div className="LogView_DownFiltersComp-row flex-columns-centerByVertical">
                <div className="flex-full-center">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0625 1.875H12.1875V0H9.375V1.875H5.625V0H2.8125V1.875H0.9375C0.42 1.875 0 2.295 0 2.8125V14.0625C0 14.58 0.42 15 0.9375 15H14.0625C14.58 15 15 14.58 15 14.0625V2.8125C15 2.295 14.58 1.875 14.0625 1.875ZM13.125 13.125H1.875V6.5625H13.125V13.125Z" fill="#C5C5C5"/>
                    </svg>
                </div>
                <div className="LogView_DownFiltersComp-rowTitle">{store.trans.Dates}</div>
                <div className="LogView_DownFiltersComp-periodCalendar">
                    <ReactDoubleDatePicker2Comp store={store} interval={lvs.filters.interval} />
                </div>
            </div>
            <div className="LogView_DownFiltersComp-row">
                <div className="LogView_DownFiltersComp-rowHeader">
                    {store.trans.Users}
                </div>
                <div>
                    <DropDownComp items={usersItems} currentKey={lvs.filters.filter_userId} onChange={this.onChangeUser}/>
                </div>
            </div>
            <div className="LogView_DownFiltersComp-row">
                <div className="LogView_DownFiltersComp-rowHeader">
                    {store.trans["Action type"]}
                </div>
                <div>
                    <DropDownComp items={operItems} currentKey={lvs.filters.filter_operation} onChange={this.onChangeOper} />
                </div>
            </div>
        </div>;
    }
}
