import {CustomStore} from "../CustomStore";
import {PhotoTagGroupStore} from "./PhotoTagGroupStore";
import {SyncUtils} from "../../helper/utils/SyncUtils";
import {PhotoTagStore} from "./PhotoTagStore";
import {IPhotoTag, IPhotoTagGroup} from "./PhotoStore";
import {computed, observable} from "mobx";

export class PhotoTagGroupFilterStore extends CustomStore{
    constructor(parent: CustomStore) {
        super(parent);
    }

    class(): string {
        return "PhotoTagGroupFilterStore";
    }
    @observable
    tagGroups: PhotoTagGroupStore[] = [];
    @observable
    filter: string = "";

    @computed
    get checkedTagsId(): PhotoTagStore[]{
        let r: PhotoTagStore[] = [];
        this.tagGroups.forEach(g => g.tags.forEach(t => {if(t.checked) r.push(t);}));
        return r;
    }

    loadPhotoTagFromJson(json: IPhotoTagGroup[]){
        SyncUtils.syncDataAndState(
            this.tagGroups,
            json,
            (old) => old.id.toString(),
            (newDataItem) => newDataItem.group_id.toString(),
            newDataItem => {
                let t: PhotoTagGroupStore = new PhotoTagGroupStore(this);
                t.id = newDataItem.group_id;
                t.name = newDataItem.group_name;
                syncTags(t.tags, newDataItem.group_tags, t);
                return t;
            },
            (oldState, newDataItem) => {
                oldState.name = newDataItem.group_name;
                syncTags(oldState.tags, newDataItem.group_tags, oldState);
            }
        );
        function syncTags(state: PhotoTagStore[], data: IPhotoTag[], parent: PhotoTagGroupStore){
            SyncUtils.syncDataAndState(state, data,
                (old) => old.tag_id.toString(),
                newDataItem => newDataItem.tag_id.toString(),
                newDataItem => {
                    let t: PhotoTagStore = new PhotoTagStore(parent);
                    t.tag_id = newDataItem.tag_id;
                    t.tag_name = newDataItem.tag_name;
                    return t;
                },(oldState, newDataItem) => {
                    oldState.tag_name = newDataItem.tag_name;
                }
            );
        }
    }
}