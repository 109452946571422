import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {HelpComp} from "../../Common/Forms/HelpComp";

@observer
export class ExtandMapComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }


    @action
    onClick(){
        let store = this.props.store;
        store.map.setExtandMap(!store.map.extandMap);
    }


    render() {
        let store = this.props.store;
        return <div className={classNames("left-sidebar__left-bottom transit-map")}>
            {store.config?.theme?.help_link && <a href={store.config?.theme?.help_link} target="_blank">
                <div className={classNames("icon_container icon_container_fill relative")}  style={{marginBottom: "13px"}}>
                <HelpComp />
            </div></a>}

            <div className={classNames("icon_container icon_container_fill relative")} onClick={this.onClick}>
                {!store.map.extandMap && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18 0H0V18H18V0ZM4 2C2.89543 2 2 2.89543 2 4V14C2 15.1046 2.89543 16 4 16H14C15.1046 16 16 15.1046 16 14V4C16 2.89543 15.1046 2 14 2H4Z" fill="#C4C4C4"/>
                    <path d="M3 4.5C3 3.67157 3.67157 3 4.5 3H6V15H4.5C3.67157 15 3 14.3284 3 13.5V4.5Z" fill="#C4C4C4"/>
                    <g clipPath="url(#clip0_16_5416)">
                        <path d="M11 10L14.5 10C14.8 10 15 9.75714 15 9.5V8.5C15 8.24286 14.8 8 14.5 8L11 8V5L7 9L11 13V10Z" fill="#C5C5C5"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_16_5416">
                            <rect width="8" height="8" fill="white" transform="translate(15 13) rotate(-180)"/>
                        </clipPath>
                    </defs>
                </svg>}
                {store.map.extandMap && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18 0H0V18H18V0ZM4 2C2.89543 2 2 2.89543 2 4V14C2 15.1046 2.89543 16 4 16H14C15.1046 16 16 15.1046 16 14V4C16 2.89543 15.1046 2 14 2H4Z" fill="#C4C4C4"/>
                    <path d="M3 4.5C3 3.67157 3.67157 3 4.5 3H6V15H4.5C3.67157 15 3 14.3284 3 13.5V4.5Z" fill="#C4C4C4"/>
                    <g clipPath="url(#clip0_16_5406)">
                        <path d="M11 8L7.5 8C7.2 8 7 8.24286 7 8.5L7 9.5C7 9.75714 7.2 10 7.5 10L11 10L11 13L15 9L11 5L11 8Z" fill="#C5C5C5"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_16_5406">
                            <rect width="8" height="8" fill="white" transform="translate(15 13) rotate(-180)"/>
                        </clipPath>
                    </defs>
                </svg>
                }
            </div>
        </div>;
    }
}
