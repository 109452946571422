import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";

@observer
export class FavListDeleteAllComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store = this.props.store;
        store.searchPanel.favoriteList.groups.length = 0;
        store.searchPanel.showDeleteAllScene = false;
    }

    @action
    onClickCancel(){
        let store = this.props.store;
        store.searchPanel.showDeleteAllScene = false;
    }

    render() {
        let store = this.props.store;
        return <div className="left-sidebar__favitem__delete_back">
            <div>{store.trans["Delete_all_elements_from_favorites?"]}</div>
            <div className="left-sidebar__favitem__delete_group_button">
                <span className="left-sidebar__favitem__delete_button left-sidebar__favitem__delete_button-red pointer" onClick={this.onClickDelete}>{store.trans.Delete}</span>
                <span className="left-sidebar__favitem__delete_button pointer" onClick={this.onClickCancel}>{store.trans.Cancel}</span>
            </div>
        </div>;
    }
}
