import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './IndexByPointPopupSettingsComp.scss';
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {
    getIgnoreCompareIndexDataType,
    getIndexDataGroups,
    getNameOfIndexDataType,
    IndexDataGroup,
    IndexDataType,
    Polarization,
    PrecipitationCategory,
    SolarRadCategory,
    TemperatureCategory
} from "../../../../../store/indeByPoint/IndexByPointerStore";
import classNames from "classnames";
import {action, autorun, observable, runInAction} from "mobx";
import {CheckboxMiniComp, CheckboxSize} from "../../../../Common/CheckboxMiniComp";
import {IndexByPointPopupSettingsVegetComp} from "./IndexByPointPopupSettingsVegetComp";
import {IndexByPointPopupSettingsRadarComp} from "./IndexByPointPopupSettingsRadarComp";
import {IReactionDisposer} from "mobx/lib/internal";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {Utils} from "../../../../../helper/utils/Utils";
import {Satellite} from "../../../../../helper/utils/satellliteDic";

interface IIndexByPointPopupSettingsComp extends IStoreProps{
    onClose?: ()=>any,
    autoSubmit?: boolean,
}
@observer
export class IndexByPointPopupSettingsComp extends React.Component<IIndexByPointPopupSettingsComp, undefined> {
    constructor(props: IIndexByPointPopupSettingsComp) {
        super(props);
        autoBindReact(this);

        if (this.props.autoSubmit){
            this.autoReloadSettings = autorun(()=>{
                this.getSettings();
            });
        }else{
            this.getSettings();
        }
    }

    autoReloadSettings: IReactionDisposer;

    componentWillUnmount() {
        if (this.autoReloadSettings != null) this.autoReloadSettings();
    }

    getSettings(){
        this.precipitationCategory = this.props.store.indexByPointer.precipitationCategory;
        this.temperatureCategory = this.props.store.indexByPointer.temperatureCategory;
        this.solarRadCategory = this.props.store.indexByPointer.solarRadCategory;
        this.compareSources = this.props.store.indexByPointer.compareSources;
        this.typeData = null;
        if (this.props.store.indexByPointer.typesData.length > 0){
            this.typeData = this.props.store.indexByPointer.typesData[0]
        }
        this.radius = this.props.store.indexByPointer.radius;
        this.satellites = this.props.store.indexByPointer.satellites;
        this.productCode = this.props.store.indexByPointer.productCode;
        this.polarEW = this.props.store.indexByPointer.polarEW;
        this.polarIW = this.props.store.indexByPointer.polarIW;
    }

    @action onChangeTypeData(item: IDropDownItem, newIndex: number){
        this.typeData = item.data;
        if (this.props.autoSubmit) this.onClickApply();
    }
    @action onChangetempCateg(item: IDropDownItem, newIndex: number){
        this.temperatureCategory = item.key as TemperatureCategory;
        if (this.props.autoSubmit) this.onClickApply();
    }
    @action onChangePrecipCateg(item: IDropDownItem, newIndex: number){
        this.precipitationCategory = item.key as PrecipitationCategory;
        if (this.props.autoSubmit) this.onClickApply();
    }
    @action onChangeSolRadCateg(item: IDropDownItem, newIndex: number){
        this.solarRadCategory = item.key as SolarRadCategory;
        if (this.props.autoSubmit) this.onClickApply();
    }
    @observable
    temperatureCategory: TemperatureCategory;
    @observable
    precipitationCategory: PrecipitationCategory;
    @observable
    solarRadCategory: SolarRadCategory;
    @observable
    compareSources: boolean;
    @observable
    typeData: IndexDataType = null;
    @observable
    polarIW: Polarization = null;
    @observable
    polarEW: Polarization = null;
    @observable
    radius: string = "";
    @observable
    productCode: string = "";
    @observable
    satellites: Satellite[];

    @action onChangePolarIW(p: Polarization){
        this.polarIW = p;
        if (this.props.autoSubmit) this.onClickApply();
    }

    @action onChangePolarEW(p: Polarization){
        this.polarEW = p;
        if (this.props.autoSubmit) this.onClickApply();
    }
    @action onChangeRadiusStr(s: string){
        if (!this.props.autoSubmit) {
            this.radius = s;
        }
    }
    @action onChangeRadiusNum(num: number){
        if (this.props.autoSubmit) {
            this.radius = num.toString();
            this.onClickApply();
        }
    }
    @action onChangeProductCode(s: string){
        this.productCode = s;
        if (this.props.autoSubmit) this.onClickApply();
    }
    @action onChangeSatellites(s: Satellite[]){
        this.satellites = s;
        if (this.props.autoSubmit) this.onClickApply();
    }

    @action
    onClickApply(){
        this.props.store.indexByPointer.precipitationCategory = this.precipitationCategory;
        this.props.store.indexByPointer.temperatureCategory = this.temperatureCategory;
        this.props.store.indexByPointer.compareSources = this.compareSources;
        this.props.store.indexByPointer.solarRadCategory = this.solarRadCategory;

        this.props.store.indexByPointer.radius = this.radius;
        this.props.store.indexByPointer.satellites = this.satellites;
        this.props.store.indexByPointer.productCode = this.productCode;
        this.props.store.indexByPointer.polarEW = this.polarEW;
        this.props.store.indexByPointer.polarIW = this.polarIW;
        this.props.store.indexByPointer.syncTypesByGroup();
        if (this.compareSources){
            this.props.store.indexByPointer.setSingleYearPoint();
            let types = getIndexDataGroups()[this.props.store.indexByPointer.dataGroup];
            types = types.filter(a => getIgnoreCompareIndexDataType().find(b => b == a) == null);
            this.props.store.indexByPointer.typesData = types;
        }else{
            if (this.typeData != null) {
                this.props.store.indexByPointer.typesData = [this.typeData];
            }else this.props.store.indexByPointer.typesData = [];
        }
        this.props.store.indexByPointer.graphs.forEach((a) => {
            runInAction(() => {
                a.resetStatus();
            });
        });
        if (this.props.onClose) this.props.onClose();
    }


    @action
    clickCompareSources(){
        this.compareSources = !this.compareSources;
        if (this.props.autoSubmit) this.onClickApply();
    }

    render() {
        let store = this.props.store;
        let st = store.indexByPointer;

        //tempCategories
        let tempCategItems: IDropDownItem[] = [
            //{key: TemperatureCategory.accumulated0, value: TranslateUtils.getTemperatureCategoryTitle(TemperatureCategory.accumulated0, store)},
            {key: TemperatureCategory.accumulated5, value: TranslateUtils.getTemperatureCategoryTitle(TemperatureCategory.accumulated5, store)},
            {key: TemperatureCategory.accumulated10, value: TranslateUtils.getTemperatureCategoryTitle(TemperatureCategory.accumulated10, store)},
            {key: TemperatureCategory.maxDaily, value: TranslateUtils.getTemperatureCategoryTitle(TemperatureCategory.maxDaily, store)},
            {key: TemperatureCategory.minDaily, value: TranslateUtils.getTemperatureCategoryTitle(TemperatureCategory.minDaily, store)},
        ];
        if (this.temperatureCategory == TemperatureCategory.averageMinMaxDaily){
            Utils.arrayInsert(tempCategItems,0, {key: TemperatureCategory.averageMinMaxDaily, value: TranslateUtils.getTemperatureCategoryTitle(TemperatureCategory.averageMinMaxDaily, store)});
        }else Utils.arrayInsert(tempCategItems,0, {key: TemperatureCategory.averageDaily, value: TranslateUtils.getTemperatureCategoryTitle(TemperatureCategory.averageDaily, store)});

        let tempCategItemsCurrent: number | string = null;
        tempCategItems.forEach((a, index) => {
            if (a.key == this.temperatureCategory) tempCategItemsCurrent = a.key;
            a.childNode = <React.Fragment key={index + "_c"}>
                <svg width="12" height="10"
                     style={{margin: "0 10px"}}
                     className={classNames({"hide": a.key != this.temperatureCategory})}
                     viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.2 6.19995L1.8 3.79995L0 5.59995L4.2 9.79995L12 1.99995L10.2 0.199951L4.2 6.19995Z"
                          fill="#4DB6BC"/>
                </svg>
                <span>{a.value}</span>
            </React.Fragment>;
        });

        //precipCategItems
        let precipCategItems: IDropDownItem[] = [
            {key: PrecipitationCategory.daily, value: TranslateUtils.getPrecipitationCategoryTitle(PrecipitationCategory.daily, store)},
            {key: PrecipitationCategory.accumulated, value: TranslateUtils.getPrecipitationCategoryTitle(PrecipitationCategory.accumulated, store)},
        ];
        let precipCategItemsCurrent: number | string = null;
        precipCategItems.forEach((a, index) => {
            if (a.key == this.precipitationCategory) precipCategItemsCurrent = a.key;
            a.childNode = <React.Fragment key={index + "_a"}>
                <svg width="12" height="10"
                     style={{margin: "0 10px"}}
                     className={classNames({"hide": a.key != this.precipitationCategory})}
                     viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.2 6.19995L1.8 3.79995L0 5.59995L4.2 9.79995L12 1.99995L10.2 0.199951L4.2 6.19995Z"
                          fill="#4DB6BC"/>
                </svg>
                <span>{a.value}</span>
            </React.Fragment>;
        });

        let solRadCategItems: IDropDownItem[] = [
            {key: SolarRadCategory.daily, value: TranslateUtils.getSolarRadCategoryTitle(SolarRadCategory.daily, store)},
            {key: SolarRadCategory.accumulated, value: TranslateUtils.getSolarRadCategoryTitle(SolarRadCategory.accumulated, store)},
        ];
        let solRadCategItemsCurrent: number | string = null;
        solRadCategItems.forEach((a, index) => {
            if (a.key == this.solarRadCategory) solRadCategItemsCurrent = a.key;
            a.childNode = <React.Fragment key={index + "_a"}>
                <svg width="12" height="10"
                     style={{margin: "0 10px"}}
                     className={classNames({"hide": a.key != this.precipitationCategory})}
                     viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.2 6.19995L1.8 3.79995L0 5.59995L4.2 9.79995L12 1.99995L10.2 0.199951L4.2 6.19995Z"
                          fill="#4DB6BC"/>
                </svg>
                <span>{a.value}</span>
            </React.Fragment>;
        });


        let sourcesItems: IDropDownItem[] = [];

        let sourcesCurrentKey: number | string = null;
        let types = getIndexDataGroups()[st.dataGroup];
        types.forEach(a =>{
            sourcesItems.push({key: a, data: a, value: getNameOfIndexDataType(a, store)});
        });
        if (types.length > 0){
            if (this.compareSources){
                if (types.length > 0) sourcesCurrentKey = types[0];
            }else{
                sourcesCurrentKey = this.typeData;
            }
        }

        let className = "IndexByPointPopupSettingsComp-main";
        if (this.props.className) className = this.props.className;

        return <div className={className}>
            {(st.points.length > 0) && (st.dataGroup == IndexDataGroup.temperature)
            &&
            <div className="IndexByPointPopupSettingsComp-nextDiv">
                <div className="IndexByPointPopupSettingsComp-title">{store.trans["Category"]}</div>
                <DropDownComp currentKey={tempCategItemsCurrent} items={tempCategItems}
                              className={classNames("flex-fit-item")}
                              onChange={this.onChangetempCateg}/>
                <div className="IndexByPointPopupSettingsComp-divider" />
            </div>
            }
            {(st.points.length > 0) && (st.dataGroup == IndexDataGroup.precipitation) &&
            <div className="IndexByPointPopupSettingsComp-nextDiv">
                <div className="IndexByPointPopupSettingsComp-title">{store.trans["Category"]}</div>
                <DropDownComp currentKey={precipCategItemsCurrent} items={precipCategItems}
                              className={classNames("flex-fit-item")}
                              onChange={this.onChangePrecipCateg}/>
                <div className="IndexByPointPopupSettingsComp-divider" />
            </div>
            }
            {(st.points.length > 0) && (st.dataGroup == IndexDataGroup.solarRadiation) &&
            <div className="IndexByPointPopupSettingsComp-nextDiv">
                <div className="IndexByPointPopupSettingsComp-title">{store.trans["Category"]}</div>
                <DropDownComp currentKey={solRadCategItemsCurrent} items={solRadCategItems}
                              className={classNames("flex-fit-item")}
                              onChange={this.onChangeSolRadCateg}/>
                <div className="IndexByPointPopupSettingsComp-divider" />
            </div>
            }
            {sourcesItems.length > 1 &&
                <div className="IndexByPointPopupSettingsComp-nextDiv">
                    {st.dataGroup != IndexDataGroup.modis && <div className="IndexByPointPopupSettingsComp-title">{store.trans["Sources"]}</div>}
                    {st.dataGroup == IndexDataGroup.modis && <div className="IndexByPointPopupSettingsComp-title">{store.trans.Products}</div>}
                    <DropDownComp currentKey={sourcesCurrentKey} items={sourcesItems} disabled={this.compareSources}
                                  className={classNames("flex-fit-item")}
                                  onChange={this.onChangeTypeData}/>
                </div>
            }
            {st.dataGroup == IndexDataGroup.radar_iw &&
                <IndexByPointPopupSettingsRadarComp store={store} polarItems={[Polarization.VV, Polarization.VH]} onChangePolarization={this.onChangePolarIW}
                                                polar={this.polarIW} autoSubmit={this.props.autoSubmit??false}
                                                radius={this.radius} onChangeRadiusStr={this.onChangeRadiusStr} onChangeRadiusNum={this.onChangeRadiusNum} />
            }
            {st.dataGroup == IndexDataGroup.radar_ew &&
                <IndexByPointPopupSettingsRadarComp store={store} polarItems={[Polarization.HH, Polarization.HV]} onChangePolarization={this.onChangePolarEW}
                                                polar={this.polarEW} autoSubmit={this.props.autoSubmit??false}
                                                radius={this.radius} onChangeRadiusStr={this.onChangeRadiusStr} onChangeRadiusNum={this.onChangeRadiusNum} />
            }
            {st.dataGroup == IndexDataGroup.spectral &&
                <IndexByPointPopupSettingsVegetComp store={store} autoSubmit={this.props.autoSubmit??false}
                                                    index={this.productCode} onChangeIndex={this.onChangeProductCode}
                                                    satellites={this.satellites} onChangeSatellites={this.onChangeSatellites}
                                                    radius={this.radius} onChangeRadiusStr={this.onChangeRadiusStr} onChangeRadiusNum={this.onChangeRadiusNum} />
            }
            {(sourcesItems.length > 1)&& (st.dataGroup != IndexDataGroup.modis) && <div className="IndexByPointPopupSettingsComp-nextDiv">
                <div className="flex-columns ">
                    <CheckboxMiniComp state={this.compareSources} size={CheckboxSize.switcher} onClick={this.clickCompareSources}>
                        <span className="text-next pointer">{store.trans["Compare sources"]}</span>
                    </CheckboxMiniComp>
                </div>
            </div>}
            {!this.props.autoSubmit &&
                <div className="flex-full-center IndexByPointPopupSettingsComp-footer">
                    <button className="large-button left-sidebar-active button-jump"
                            onClick={this.onClickApply}>{store.trans.Apply}</button>
                </div>
            }
        </div>;
    }
}
