import {CustomStore} from "../../../app/store/CustomStore";
import {ContainerToolsState, IGeometryEditorEvents, IObjectByClick} from "../../../app/store/tools/general/ContainerTools";
import {observable} from "mobx";
import {ViewGeometryLayersTool} from "../../../app/store/tools/EditGeometry/ViewGeometryLayersTool";
import {Geometry} from "geojson";
import {GeometryUtils} from "../../../app/helper/utils/GeometryUtils";
import * as mapboxgl from "maplibre-gl";
import {AgroExportConst} from "../AgroExportConst";
import {IAgroExportSuperStore} from "../agroExportPlugin";

export class VoronovEditManager extends CustomStore implements IGeometryEditorEvents{
    constructor(props: CustomStore) {
        super(props);
        this.state.events = this;
    }
    get agroStore(): IAgroExportSuperStore{
        return this.root.getRootAs<IAgroExportSuperStore>();
    }
    public state: ContainerToolsState = new ContainerToolsState();
    viewTool: ViewGeometryLayersTool;
    @observable
    _changes: number = 0;
    get changes(): number{
        return this._changes;
    }
    onChangeGeometry(): void{
        // можно сразу обработать перемещение точки
    }
    public toEdit(g: Geometry){
        this.state.resetMovedPoints();
        this.state.simpleGeometry = GeometryUtils.getSimpleGeometries(g);
        this.root.map.setMeasuringStatus(AgroExportConst.VORONOV_EDIT_GEOMETRY);
        this.resetAll();
        this.updateGeometry();
        this.resetChanges();
    }
    canDeletePointOnEdit(): boolean {
        return false;
    }

    resetChanges(){
        if (this._changes == 0) this._changes = -1;
        else this._changes = 0;
    }
    isEdit(): boolean {
        return (this.root.map.measuringStatus == AgroExportConst.VORONOV_EDIT_GEOMETRY && this.state.simpleGeometry != null && this.agroStore.agroExport.voronovExportStore.showReadyLayers());//this.root.map.measuringStatus == MeasuringStatus.agroEdit;
    }
    private resetAll(){
        this.state.resetMovedPoints();
        this.state.midPoint = null;
        this.state.movingPoint = false;
        this.state.curAddrContour = null;
        this.state.highlightContourAddr = null;
    }
    getObjectByClick(point: mapboxgl.Point):IObjectByClick{
        return this.viewTool.getObjectByClick(point);
    }
    public updateMovedPoint() {
        this.viewTool.updateMovedPoint();
    }

    public updateGeometry(){
        this.viewTool.updateGeometry();
    }

}