// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoTopSpinnerComp-main {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(43, 49, 56, 0.6274509804);
}

.AgroAhoTopSpinnerComp-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/AgroAhoTopSpinnerComp.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,0CAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAEF","sourcesContent":[".AgroAhoTopSpinnerComp-main {\n  position: absolute;\n  z-index: 1000;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: #2B3138A0;\n}\n.AgroAhoTopSpinnerComp-cover {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
