import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoMergeIsoXmlFarmComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps, IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoRoundCheckboxSvg} from "../AgroAhoRoundCheckboxSvg";
import {AhoIndicatorFormat, CellItem} from "../../agroAhoCardStore";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoMergeIsoXmlItemComp} from "./AgroAhoMergeIsoXmlItemComp";
import {MergeIsoXmlFarm} from "../../agroAhoUploadIsoXmlStore";
import {AgroAhoMergeIsoXmlSelAllComp} from "./AgroAhoMergeIsoXmlSelAllComp";

export interface AgroAhoMergeIsoXmlFarmCompProps {
    store: IAgroAhoSuperStore;
    farm: MergeIsoXmlFarm;
}

@observer
export class AgroAhoMergeIsoXmlFarmComp extends React.Component<AgroAhoMergeIsoXmlFarmCompProps, undefined> {
    constructor(props: AgroAhoMergeIsoXmlFarmCompProps) {
        super(props);
        autoBindReact(this);
    }

    selAll: boolean = false;

    render() {
        let farm = this.props.farm;
        let store = this.props.store;
        let area = farm.fields.filter(f=>f.selected)
            .reduce((sum, cur)=> sum + cur.area_m2, 0);
        if (area) {
            area = area && parseFloat((area / 10000).toFixed(1));
            area = store.agroAhoStore.numWithSpaces(area);
        }
        let selAll = farm.fields.every(f=>f.selected);

        return <div className="AgroAhoMergeIsoXmlFarmComp-main">
            <div className="AgroAhoMergeIsoXmlFarmComp-titleCover">
                <div className="AgroAhoMergeIsoXmlFarmComp-name">{farm.farm_name}</div>
                {!!area && <div className="AgroAhoMergeIsoXmlFarmComp-area">{area}&nbsp;{store.trans.ha}</div>}
            </div>
            <AgroAhoMergeIsoXmlSelAllComp key={farm.farm_id} store={store} farm={farm} selAll={selAll}/>
            <div className="AgroAhoMergeIsoXmlFarmComp-fields">
                {farm.fields.map(f=><AgroAhoMergeIsoXmlItemComp key={f.field_id} store={store} field={f}/>)}
            </div>
        </div>;
    }
}
