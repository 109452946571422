// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogView_DownFiltersComp-dropDown {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  min-width: 315px;
  min-height: 515px;
  color: #C5C5C5;
}

.LogView_DownFiltersComp-title {
  padding: 15px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
}

.LogView_DownFiltersComp-row {
  padding: 7px 15px;
}

.LogView_DownFiltersComp-rowTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-left: 15px;
  padding-right: 15px;
}

.LogView_DownFiltersComp-rowHeader {
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
}

.LogView_DownFiltersComp-periodCalendar {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/User/LogView/LogView_DownFiltersComp.scss"],"names":[],"mappings":"AAAA;EACE,oDAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,gBAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAIA;EACE,aAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADF;;AAGA;EACE,iBAAA;AAAF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AAAA;EAEE,YAAA;AAEF","sourcesContent":[".LogView_DownFiltersComp-dropDown{\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  background: #2B3138;\n  border: 1px solid #3E4751;\n  box-sizing: border-box;\n  border-radius: 10px;\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 20px;\n\n  min-width: 315px;\n  min-height: 515px;\n  color: #C5C5C5;\n  //padding-top: 15px;\n  //padding-bottom: 15px;\n}\n.LogView_DownFiltersComp-title{\n  padding: 15px;\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 20px;\n}\n.LogView_DownFiltersComp-row{\n  padding: 7px 15px;\n}\n.LogView_DownFiltersComp-rowTitle{\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.LogView_DownFiltersComp-rowHeader{\n  font-weight: 400;\n  font-size: 10px;\n  line-height: 22px;\n}\n.LogView_DownFiltersComp-periodCalendar{\n  //width: 400px;\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
