import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import './FieldsDelineation.scss';
import './ActiveSensorDay.scss';
import {ReactDoubleDatePicker2Comp} from "../../../Common/ReactDoubleDatePicker2Comp";
import {action} from "mobx";
import classNames from "classnames";
import {DropDownComp, IDropDownItem} from "../../../Common/DropDownComp";
import {Export2TypeOrders} from "../../../../store/export/ExportStore";
import {ModelAutoField} from "../../../../store/export/FieldsDelineationStore";
import {ra} from "../../../../helper/utils/mobxUtils";
import {AutoPolygonModel} from "../../../../store/agro/fieldForm/FieldAutoStore";

@observer
export class FieldsDelineation extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onChangeProjectName(event: any){
        this.props.store.exportStore.projectName = event.target.value;
    }
    @action
    onChangeEmail(event: any){
        this.props.store.exportStore.email = event.target.value;
    }

    @action
    onClickYear(){
        this.props.store.exportStore.fieldsDelineation.periodByYear = true;
    }
    @action
    onClickPeriod(){
        this.props.store.exportStore.fieldsDelineation.periodByYear = false;
    }
    @action
    onSentinel2_grid_cells(event: any){
        this.props.store.exportStore.fieldsDelineation.gridCell = event.target.value;
    }
    @action
    onChangeYear(item: IDropDownItem){
        this.props.store.exportStore.fieldsDelineation.year = item.key as any;
    }
    @action
    onChangeModel(item: IDropDownItem){
        this.props.store.exportStore.fieldsDelineation.modelField = item.key as any;
    }
    canRun(): boolean{
        let exp = this.props.store.exportStore;
        let fd = exp.fieldsDelineation;
        if (!fd.periodByYear && !exp.period.isValid()) return false;
        if (fd.periodByYear && !(fd.year > 0)) return false;
        return !this.sending && exp.projectName != "" && exp.email != "" && (fd.gridCell != null);
    }
    sending: boolean = false;
    @action
    async onRun(){
        let store = this.props.store;
        if (!this.canRun()) return;
        this.sending = true;
        try {
            await store.exportStore.fieldsDelineation.doExport();
            ra(()=>{
                store.addInfo("Sended");
            });
        }catch (e) {
            store.addError(e);
        }
        ra(()=> {
            this.sending = false;
        });
    }
    render() {
        let store = this.props.store;
        let fd = store.exportStore.fieldsDelineation;

        let modelItems: IDropDownItem[] =
            [
                {key:AutoPolygonModel.unetValid, value: AutoPolygonModel.unetValid}
            ];
        let curYear = new Date().getFullYear();
        let yearItems: IDropDownItem[] = [];
        for(let i = 2018; i <= curYear; i++){
            yearItems.push({key: i, value: i.toString()});
        }


        return (
            <div className="ActiveSensorDay-rows" style={{height: 'calc(100% - 83px)'}}>
                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            {store.trans["Task name"]}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className="text-box-editor width100"
                               onChange={this.onChangeProjectName}
                               value={store.exportStore.projectName}/>
                    </div>
                </div>
                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            {store.trans.Email}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className="text-box-editor width100"
                               onChange={this.onChangeEmail}
                               value={store.exportStore.email}/>
                    </div>
                </div>

                <div className=" popup-pref-in__separator" />

                <div className="flex-column">
                    <div className="ActiveSensorDay-kvKeyCenter" style={{marginBottom:'15px'}}>
                        <span className={classNames({
                            "FieldsDelineation-activeYear": !fd.periodByYear, "FieldsDelineation-passiveYear": fd.periodByYear
                        })}  onClick={this.onClickPeriod}>
                            {store.trans.Period}
                            <div className={classNames({"FieldsDelineation-bottomBlueLine": !fd.periodByYear, "FieldsDelineation-bottomHiddenLine": fd.periodByYear})}></div>
                        </span>
                        <span className="text-next FieldsDelineation-or">
                            {store.trans.or}
                            <div className="FieldsDelineation-bottomHiddenLine"></div>
                        </span>
                        <span className={classNames({
                            "FieldsDelineation-activeYear": fd.periodByYear, "FieldsDelineation-passiveYear": !fd.periodByYear
                        }, "text-next")} onClick={this.onClickYear}>
                            {store.trans.Year}
                            <div className={classNames({"FieldsDelineation-bottomBlueLine": fd.periodByYear, "FieldsDelineation-bottomHiddenLine": !fd.periodByYear})}></div>
                        </span>
                    </div>
                    <div className="flex-input-value">
                        {!fd.periodByYear && <ReactDoubleDatePicker2Comp store={store} interval={store.exportStore.period}/>}
                        {fd.periodByYear && <DropDownComp items={yearItems}
                                                          className="Export2Panel-switchModeDropdown width100"
                                                          currentKey={fd.year} onChange={this.onChangeYear} />}
                    </div>
                </div>


                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            Model
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <DropDownComp items={modelItems} currentKey={fd.modelField}
                                      onChange={this.onChangeModel}
                                      className="Export2Panel-switchModeDropdown width100" />
                    </div>
                </div>


                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            Sentinel-2 grid cells
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className="text-box-editor width100"
                               onChange={this.onSentinel2_grid_cells} placeholder="32VNH"
                               value={fd.gridCell}/>
                    </div>
                </div>

                <div className="ActiveSensorDay-bottomDiv"style={{flexDirection:'column'}}>
                <div className="popup-pref-in__separator" style={{marginLeft:'-25px',width:'140%',marginRight:'-25px'}}/>
                    <button onClick={this.onRun}
                            disabled={!this.canRun()}
                            className="large-button left-sidebar-active button-jump ActiveSensorDay-bottomButton">
                        {store.trans.Run}
                    </button>

                </div>
            </div>
        );
    }
}