import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {autorun, observable, reaction} from "mobx";
import {fetchJsonGet} from "../../../app/helper/utils/FetchUtils";
import {LinePaint, MapboxGeoJSONFeature, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {GeometryUtils} from "../../../app/helper/utils/GeometryUtils";
import {AgroAhoStore, AhoStage} from "../agroAhoStore";
import {AhoCardShowType, AhoIndicatorFormat, Card, IndicatorKey} from "../agroAhoCardStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelModeAgroAho} from "../agroAhoPlugin";
import {FeatureCollection} from "geojson";
import centroid from "@turf/centroid";
import {EditDrawTool} from "../../../app/store/tools/EditGeometry/EditDrawTool";
import {ContainerToolsState} from "../../../app/store/tools/general/ContainerTools";
import {ViewGeometryLayersTool} from "../../../app/store/tools/EditGeometry/ViewGeometryLayersTool";
import {AgroAhoMapStore, IAhoLayers} from "./agroAhoMapStore";
import {TextUtils} from "../../../app/helper/utils/TextUtils";
import {AhoUtils} from "../agroAhoUtils";


export class AgroAhoMapTourStore extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;

    resetCellJSON(){

    }

    // async getDataJSON(ds: string, upd?: boolean){
    async getDataJSON(ds: string, type: IAhoLayers){
        let parent = this.mapStore.parentStore;
        let cs = parent.cardStore;
        let ts = parent.tourStore;
        let td = AhoUtils.cp(ts.selTours(true));
        // let url = `${window.location.origin}/api/vectorlayer/${ds}/geojson`;
        // let url_ = `${url}?tours=${JSON.stringify(cs.torusToDisplay.map(t=>t.tour_id))}`;
        // console.log('geojson002', url);
        // store_.cards = store_.cards.slice().sort((a,b)=> TextUtils.stringSortCompare(a.map_name, b.map_name));
        // if (upd || (!upd && !parent.cacheJsons[url])) {
        //     let data = await fetchJsonGet(url);

            let data = await this.mapStore.getCellCardGeoJSONs({ds: ds, type: type});
            if (data && data.features) {
                for (let i=0; i<data.features.length; i++) {
                    let f = data.features[i];
                    let p = f.properties;
                    f.properties = {cell_id: p.cell_id, cell_num: p.cell_num, field_id: p.field_id};
                    f.id = i;
                    // for (let i_t=cs.torusToDisplay.length-1; i_t>=0; i_t--) {
                    //     let t = cs.torusToDisplay[i_t];
                    for (let i_t=td.length-1; i_t>=0; i_t--) {
                        let t = td[i_t];
                        // console.log('cell_id', AhoUtils.cp(p.cell_id), AhoUtils.cp(t.observ_vals));
                        if (t.observ_vals){
                            for (let i_c=0; i_c<t.observ_vals.length; i_c++){
                                let c = t.observ_vals[i_c];
                                // console.log('cell_id maptour', c.cell_id, AhoUtils.cp(t.observ_vals));
                                // let p_ = f.properties;
                                // let new_p = {cell_num: p_.cell_num, field_id: p_.field_id};
                                if (c.cell_id == p.cell_id) {
                                    for (let key of Object.keys(c)) {
                                        if (c[key] || c[key] === 0) f.properties[key] = c[key];
                                    }
                                    // f.properties = Object.assign(new_p, c);
                                    // console.log('cell_id=', c.cell_id, f.id, f.properties);
                                }
                            }
                        }
                        if (t.rec_norm_vals) {
                            // console.log('rec_norm_vals', AhoUtils.cp(t.rec_norm_vals));
                            // console.log('t', AhoUtils.cp(t));
                            for (let i_c=0; i_c<t.rec_norm_vals.length; i_c++){
                                let c = t.rec_norm_vals[i_c];
                                if (c.cell_id == p.cell_id) {
                                    for (let key of Object.keys(c)) {
                                        // console.log('c[key]', c.cell_id, key, c[key]);
                                        if (c[key] || c[key] === 0) f.properties[key] = c[key];
                                    }
                                    // console.log('cell_id=', c.cell_id, f.id, f.properties);
                                }
                            }
                        }
                    }
                }
                // console.log('json:', data, JSON.parse(JSON.stringify(cs.toursItems)));
                // parent.cacheJsons[url] = data;
            }
        // }
        // return parent.cacheJsons[url];

        return data;
    }

}