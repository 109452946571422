import {CustomStoreTool} from "../general/CustomStoreTool";
import {IReactionDisposer} from "mobx/lib/internal";
import {action, autorun} from "mobx";
import * as mapboxgl from "maplibre-gl";
import {MapboxGeoJSONFeature, PointLike} from "maplibre-gl";
import centroid from "@turf/centroid";
import {ShowRoom2Tool} from "./ShowRoom2Tool";
import distance from "@turf/distance";
import {ShowRoom2Clickable} from "./ShowRoom2LayerCustomTool";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class ShowRoom2PopupTool extends CustomStoreTool{

    get showRoom2Tool(): ShowRoom2Tool{
        return this.store.map.superTools.showRoom2;
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.recalc();
            }),
        ];
    }

    recalc(){
        let sr = this.store.dataShowRoomStore;
        if (sr.mapPopup.show) {
            let mapbox = this.store.map.mapbox;
            let pp = mapbox.project({lon: sr.mapPopup.lon, lat: sr.mapPopup.lat});
            sr.mapPopup.x = pp.x;
            sr.mapPopup.y = pp.y;
        }
    }

    onMove(e: mapboxgl.MapMouseEvent & ToolEvent) {
        super.onMove(e);
        this.recalc();
        //this.store.dataShowRoomStore.dataShowRoomMode
    }

    onMoveEnd(e: ToolEvent) {
        super.onMoveEnd(e);
        this.recalc();
    }

    @action onMouseClick(e: mapboxgl.MapMouseEvent & ToolEvent) {
        let map = this.store.map.mapbox;

        let point = e.point;
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(point.x - 2, point.y - 2),
            new mapboxgl.Point(point.x + 2, point.y + 2)
        ];
        let layers: string[] = [];
        if (this.showRoom2Tool.cce.visibleLayer()) layers.push(this.showRoom2Tool.cce.layerIdPoints());
        if (this.showRoom2Tool.class.visibleLayer()) layers.push(this.showRoom2Tool.class.layerIdPoints());
        if (this.showRoom2Tool.yieldAss.visibleLayer()) layers.push(this.showRoom2Tool.yieldAss.layerIdPoints());
        if (this.showRoom2Tool.yieldMapGp.visibleLayer()) layers.push(this.showRoom2Tool.yieldMapGp.layerIdPoints());
        if (this.showRoom2Tool.districts.visibleLayer()) layers.push(this.showRoom2Tool.districts.layerIdFill());

        let res: MapboxGeoJSONFeature[] = map.queryRenderedFeatures(bbox,
            {layers: layers});
        if (res.length > 0) {
            //res[0].id

            let r: MapboxGeoJSONFeature = res[0];
            let minD = 1000000;
            res.forEach(a => {
                let d = distance(centroid(a.geometry as any) as any, [e.lngLat.lng, e.lngLat.lat]);
                if (d < minD) {
                    r = a;
                    minD = d;
                }
            });
            let id = r.id;
            let tool: ShowRoom2Clickable = null;
            if (this.showRoom2Tool.cce.getSourceId() == r.source) tool = this.showRoom2Tool.cce;
            if (this.showRoom2Tool.class.getSourceId() == r.source) tool = this.showRoom2Tool.class;
            if (this.showRoom2Tool.yieldAss.getSourceId() == r.source) tool = this.showRoom2Tool.yieldAss;
            if (this.showRoom2Tool.yieldMapGp.getSourceId() == r.source) tool = this.showRoom2Tool.yieldMapGp;
            if (this.showRoom2Tool.districts.getSourceId() == r.source) tool = this.showRoom2Tool.districts;

            if (tool != null) {
                let pCenter = centroid(r.geometry as any);
                this.store.dataShowRoomStore.mapPopup.show = true;
                if (this.showRoom2Tool.yieldMapGp.getSourceId() == r.source || this.showRoom2Tool.districts.getSourceId() == r.source) {
                    this.store.dataShowRoomStore.mapPopup.lat = e.lngLat.lat;
                    this.store.dataShowRoomStore.mapPopup.lon = e.lngLat.lng;
                } else {
                    this.store.dataShowRoomStore.mapPopup.lat = pCenter.geometry.coordinates[1];
                    this.store.dataShowRoomStore.mapPopup.lon = pCenter.geometry.coordinates[0];
                }
                tool.loadPopupById(id, r);
            }
        } else {
            this.store.dataShowRoomStore.mapPopup.show = false;
        }
    }
}