import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";

@observer
export class RightSidebarBottom extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let zoom = Math.ceil(store.map.zoom);
        return <div className="right-sidebar__bottom noselect">
            <div className="icon_container icon_container_fill" onClick={store.map.incZoom}>
                <div className="plus_right_sidebar gray flex-rows"/>
            </div>
            <div className="right-sidebar__bottom_num">{zoom}</div>
            <div className="icon_container icon_container_fill" onClick={store.map.decZoom}>
                <div className="minus_right_sidebar gray"/>
            </div>
        </div>;
    }
}
