import bbox from "@turf/bbox";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import mapboxgl from "maplibre-gl";

@observer
export class EgrnMinimapComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.mapRef = React.createRef();
    }

    componentDidMount(): void {
        let ms = this.props.store.map;
        if (this.map) return;

        this.map = new mapboxgl.Map({
            accessToken: this.props.store.config.accessToken,
            container: this.mapRef.current,
            transformRequest: this.props.store.map.mapTransformRequest,
            style: ms.getMapStyle()
        });
        this.map.setMaxZoom(20);
        this.map.on("styledata", this.onMapCreate);

    }

    mapRef: any;
    map: mapboxgl.Map = null;

    onMapCreate() {
        let egrn = this.props.store.egrnStore;
        if (! egrn.activeParcel?.geom) return;
        if (this.map.getSource("parcel") == null) {
            this.map.addSource("parcel", {
                'type': 'geojson',
                'data': egrn.activeParcel.geom
            });
        }
        if (this.map.getLayer("parcel") == null) {
            this.map.addLayer({
                'id': "parcel",
                'type': 'line',
                'source': "parcel",
                'layout': {},
                'paint': {
                    'line-color': '#f00',
                    'line-width': 2,
                    'line-opacity': 0.8
                }
            });
        }
        let bb = bbox(egrn.activeParcel.geom) as BBox2d;
        this.map.fitBounds(bb, {
            padding: 20,
            linear: true
        });
    }

    render() {
        let store = this.props.store;
        let ms = store.map;

        return <div className="EgrnMinimapComp-minimap-div transit-map">
            <div ref={this.mapRef} className="EgrnMinimapComp-minimap-container">
            </div>
        </div>;
    }
}