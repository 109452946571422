import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ColorHelper} from "../../../../helper/utils/ColorHelper";
import {getShortNameOfIndexDataType} from "../../../../store/indeByPoint/IndexByPointerStore";
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../icons/LoadingAniSvg";
import "./IndexByPointLinesComp.scss";

@observer
export class IndexByPointLinesComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let ibp = store.indexByPointer;

        let graphs = ibp.graphs.filter(a => a.visible);
        let arr: any[] = graphs.map(a => {
            return <div style={{alignItems:"center",gap:0}} className="flex-columns-centerByVertical" key={a.id}>
                {a.status == LoadStatus.loading && <LoadingAniSvg  />}
                {a.status == LoadStatus.ready && <div style={{width: "17px", height: "5px", boxSizing: "border-box", borderRadius: "4px", background: ColorHelper.numToCssHexRgb(a.colorByDataType)}} />}
                <div className="text-next text-prev">{getShortNameOfIndexDataType(a.dataType, store)}</div>
            </div>;
        });

        //store.indexByPointer.typesData

        return <div className="IndexByPointLinesComp-div flex-stretch-item ">
            {arr}
        </div>;
    }
}
