import {IGraphData, IGraphDataInfo, IGraphDayValue} from "../IndexByPointGraphStore";
import {IndexByPointerSourceCustomSimpleStore} from "./IndexByPointerSourceCustomSimpleStore";

export abstract class IndexByPointerSourceModisCustom extends IndexByPointerSourceCustomSimpleStore{
    getGraphData(): IGraphDataInfo{
        let res: IGraphData[] = [];
        let arr: IGraphDayValue[] = this.getGraphDataInternal();
        arr.forEach(a => {
            res.push({dayOfYear: a.dayOfYear, value: a.value, sceneID: null});
        });
        return {data: res, hasMinMax: false};
    }

}