import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {ProgressUploadComp} from "../UploadPanel/ProgressUploadComp";
import classNames from "classnames";

@observer
export class PhotoPanelFooterComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onShow(){
        this.props.store.searchPanel.closeAllLeftPanels();
        this.props.store.photo.uploadImageStore.showPanel = true;
    }

    render() {
        let store = this.props.store;
        let uis = store.photo.uploadImageStore;

        return <div className={classNames("PhotoPanel-Footer-main qtransit",
            {"lineheight0": (store.photo.editor.editorWindowShow)})}>
            <div className="PhotoPanel-Footer-topline " />
                <div className="PhotoPanel-Footer-line">
                    <div className="PhotoPanel-Footer-fotoCount">{store.trans.Photo}: {store.photo.featuresTotalCount}</div>

                    {uis.files.length > 0 && uis.generalStatusUpload == LoadStatus.loading &&
                        <ProgressUploadComp  className="PhotoPanel-Footer-progress" progress={uis.uploadSize / uis.totalSize} />
                    }
                    {uis.files.length > 0 && uis.generalStatusUpload == null && !uis.hasError &&
                      <div className="nowrap text-next flex-stretch-item right-align flex-align-items-center PhotoPanel-Footer-message" />
                    }
                    {uis.files.length > 0 && uis.generalStatusUpload == null && uis.hasError &&
                        <div className="nowrap text-next flex-stretch-item right-align flex-align-items-center PhotoPanel-Footer-message">

                            <svg width="16" height="16" viewBox="0 0 16 16" className="text-next" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 12C7.4 12 7 11.6 7 11C7 10.4 7.4 10 8 10C8.6 10 9 10.4 9 11C9 11.6 8.6 12 8 12ZM9 9H7V4H9V9Z" fill="#E94F4F"/>
                            </svg>
                            <div className="red nowrap text-next">{store.trans["Loading error"]}</div>
                        </div>
                    }
                    {uis.files.length > 0 && uis.allUploaded &&
                        <div className="nowrap text-next flex-stretch-item right-align flex-align-items-center PhotoPanel-Footer-message">{store.trans["All photos uploaded"]}</div>
                    }
                    {uis.files.length > 0 && <div className="icon_container icon_container_fill icon_container34 relative" onClick={this.onShow}>
                        <svg width="16" height="16" viewBox="0 0 16 16" className="PhotoPanel-Footer-fullButton" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 1V8H14V3.4L9.7 7.7L8.3 6.3L12.6 2H8V0H15C15.6 0 16 0.4 16 1Z" fill="#C5C5C5"/>
                            <path d="M0 9H7V16H0V9Z" fill="#C5C5C5"/>
                        </svg>
                    </div>}
                </div>

        </div>;
    }
}
