import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoOdsSvgProps {
    className?: string
}

export class AgroAhoOdsSvg extends React.PureComponent<IAgroAhoOdsSvgProps, undefined> {
    constructor(props: IAgroAhoOdsSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)}
            width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44 27H4L1 23L5 19H43L47 23L44 27Z" fill="#0E512B"/>
            <path d="M41 47H7C5.895 47 5 46.105 5 45V3C5 1.895 5.895 1 7 1H31L43 13V45C43 46.105 42.105 47 41 47Z" fill="#E6E6E6"/>
            <path d="M31 1V11C31 12.105 31.895 13 33 13H43L31 1Z" fill="#B3B3B3"/>
            <path d="M45 41H3C1.895 41 1 40.105 1 39V23H47V39C47 40.105 46.105 41 45 41Z" fill="#1B7343"/>
            <path d="M19.9175 32.497C19.9175 33.1708 19.8318 33.7847 19.6604 34.3389C19.4932 34.889 19.2298 35.3635 18.8702 35.7621C18.5107 36.1608 18.0487 36.4678 17.4843 36.6831C16.9199 36.8944 16.2447 37 15.4588 37C14.6895 37 14.0227 36.8944 13.4583 36.6831C12.8981 36.4678 12.4361 36.1628 12.0724 35.7681C11.7086 35.3694 11.439 34.893 11.2634 34.3389C11.0878 33.7847 11 33.1668 11 32.4851C11 31.5761 11.1568 30.7867 11.4703 30.1169C11.7881 29.4432 12.2772 28.9229 12.9378 28.5561C13.5983 28.1854 14.4428 28 15.4713 28C16.5165 28 17.3652 28.1854 18.0174 28.5561C18.6737 28.9269 19.1545 29.4492 19.4597 30.1229C19.7649 30.7967 19.9175 31.588 19.9175 32.497ZM13.6088 32.497C13.6088 33.0233 13.6715 33.4757 13.7969 33.8545C13.9223 34.2332 14.1209 34.5243 14.3927 34.7276C14.6644 34.9309 15.0198 35.0326 15.4588 35.0326C15.9145 35.0326 16.2761 34.9309 16.5437 34.7276C16.8154 34.5243 17.0098 34.2332 17.1269 33.8545C17.2481 33.4757 17.3087 33.0233 17.3087 32.497C17.3087 31.7076 17.1708 31.0837 16.8948 30.6252C16.6189 30.1668 16.1444 29.9375 15.4713 29.9375C15.024 29.9375 14.6623 30.0412 14.3864 30.2485C14.1146 30.4558 13.9161 30.7508 13.7906 31.1336C13.6694 31.5163 13.6088 31.9708 13.6088 32.497Z" fill="white"/>
            <path d="M29.3555 32.3236C29.3555 33.3402 29.1569 34.1854 28.7598 34.8591C28.3626 35.5329 27.8024 36.0392 27.0791 36.3781C26.36 36.713 25.5134 36.8804 24.5393 36.8804H21.5731V28.1375H24.7463C25.7329 28.1375 26.5691 28.299 27.2547 28.6219C27.9403 28.9449 28.4608 29.4173 28.8162 30.0392C29.1758 30.6611 29.3555 31.4226 29.3555 32.3236ZM26.7844 32.4073C26.7844 31.8771 26.7091 31.4385 26.5586 31.0917C26.4123 30.7409 26.1907 30.4797 25.8939 30.3083C25.597 30.1329 25.2229 30.0452 24.7713 30.0452H24.0502V34.9429H24.602C25.3546 34.9429 25.9064 34.7355 26.2576 34.3209C26.6088 33.9023 26.7844 33.2645 26.7844 32.4073Z" fill="white"/>
            <path d="M37 34.2252C37 34.6997 36.8746 35.1502 36.6237 35.5767C36.3729 35.9993 35.982 36.3422 35.451 36.6053C34.9243 36.8684 34.2428 37 33.4067 37C32.9886 37 32.6207 36.9821 32.3029 36.9462C31.9894 36.9103 31.6967 36.8525 31.425 36.7728C31.1532 36.689 30.871 36.5814 30.5784 36.4498V34.3449C31.0759 34.5841 31.5776 34.7674 32.0835 34.895C32.5893 35.0186 33.0471 35.0804 33.4568 35.0804C33.7035 35.0804 33.9042 35.0525 34.0589 34.9967C34.2177 34.9409 34.3348 34.8651 34.41 34.7694C34.4853 34.6698 34.5229 34.5561 34.5229 34.4286C34.5229 34.2771 34.4665 34.1495 34.3536 34.0458C34.2449 33.9382 34.0693 33.8266 33.8268 33.711C33.5843 33.5953 33.2645 33.4518 32.8673 33.2804C32.5287 33.1289 32.2214 32.9734 31.9455 32.814C31.6737 32.6545 31.4396 32.4731 31.2431 32.2698C31.0508 32.0625 30.9024 31.8213 30.7979 31.5462C30.6934 31.2711 30.6411 30.9462 30.6411 30.5714C30.6411 30.0093 30.7853 29.5389 31.0738 29.1601C31.3665 28.7774 31.772 28.4904 32.2904 28.299C32.813 28.1076 33.415 28.012 34.0965 28.012C34.6943 28.012 35.2315 28.0757 35.7082 28.2033C36.1889 28.3309 36.6196 28.4764 37 28.6399L36.2412 30.4638C35.8482 30.2924 35.4615 30.1568 35.081 30.0571C34.7006 29.9535 34.3473 29.9017 34.0212 29.9017C33.808 29.9017 33.6324 29.9276 33.4945 29.9794C33.3607 30.0272 33.2603 30.093 33.1934 30.1767C33.1307 30.2605 33.0994 30.3561 33.0994 30.4638C33.0994 30.5993 33.1537 30.7209 33.2624 30.8286C33.3753 30.9362 33.5613 31.0538 33.8205 31.1814C34.0839 31.305 34.4414 31.4664 34.8929 31.6658C35.3402 31.8571 35.7207 32.0645 36.0343 32.2877C36.3478 32.507 36.5861 32.7721 36.7492 33.0831C36.9164 33.39 37 33.7708 37 34.2252Z" fill="white"/>
        </svg>;
    }
}