import {IndexByPointerSourceCustomStore} from "./IndexByPointerSourceCustomStore";
import {IGraphDataInfo, IndexByPointGraphStore} from "../IndexByPointGraphStore";
import {action} from "mobx";
import {LoadStatus} from "../../../helper/structs/LoadStatus";

export class IndexByPointerSourceEmpty extends IndexByPointerSourceCustomStore{
    constructor(parent: IndexByPointGraphStore) {
        super(parent);
        this._status = LoadStatus.ready;
    }
    @action reset(){
        this._status = LoadStatus.ready;
    }

    getGraphData(): IGraphDataInfo{
        return {data: [], hasMinMax: false};
    }
}