import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './PhotoPanel.scss';
import {PhotoPanelTitleComp} from "./PhotoPanelTitleComp";
import {PhotoPanelFooterComp} from "./PhotoPanelFooterComp";
import {PhotoPanelSwitcher} from "./PhotoPanelSwitcher";
import {PhotoPanelTopFiltersComp} from "../PhotoFiltersElements/PhotoPanelTopFiltersComp";
import {PhotoPanelPhotoListComp} from "../PhotoList/PhotoPanelPhotoListComp";
import {PhotoPanelMode} from "../../../../../store/photo/PhotoStore";
import {PhotoFilterLeft} from "../PhotoFilter/PhotoFilterLeft";

@observer
export class PhotoPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.props.store.photo.initTags();
    }

    render() {
        let store = this.props.store;
        let uis = store.photo.uploadImageStore;
        //{uis.files.length == 0 && <PhotoPanelEmptyComp />}
        return <div className="PhotoPanel-main-div">
            <PhotoPanelTitleComp store={store} />

            <div className="flex-stretch-item flex-rows overflowHidden">
                <PhotoPanelSwitcher store={store} />
                {store.photo.panelMode == PhotoPanelMode.photo &&
                <React.Fragment>
                    <PhotoPanelTopFiltersComp className="PhotoPanel-margin PhotoPanel-filters" store={store} />
                    <PhotoPanelPhotoListComp store={store} />
                </React.Fragment>}
                {store.photo.panelMode == PhotoPanelMode.filters &&
                    <PhotoFilterLeft store={store} />
                }
            </div>
            <PhotoPanelFooterComp store={store} />
        </div>;
    }
}
