import * as React from 'react';
import {CustomSvg} from "./CustomSvg";

export class ArrowSvg extends CustomSvg {

    render() {
        return <svg width="8" height="12" viewBox="0 0 8 12" className={this.getClasses()} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.39961 6.00002L1.99961 11.4L0.599609 10L4.59961 6.00002L0.599609 2.00002L1.99961 0.600025L7.39961 6.00002Z" />
        </svg>;
    }
}
