import {observable, toJS} from "mobx";
import {IClassPluginOptions} from "./index";
import {SuperStore} from "../app/store/SuperStore";
import {ReactElement, ReactNode} from "react";
import {cloneDeep} from "lodash-es";

export class PluginsController {
    public static initNewStore(store: SuperStore){
        if (PluginsController.plugins == null) PluginsController._plugins = [];
        PluginsController._store = store;
        PluginsController.reInitPlugins();
    }
    @observable
    private static _plugins: IClassPluginOptions[]
    public static get plugins(): IClassPluginOptions[]{
        return PluginsController._plugins;
    }

    private static _store: SuperStore = null;

    static get store(): SuperStore{
        return PluginsController._store;
    }

    static getPlugins(): IClassPluginOptions[]{
        return toJS(PluginsController._plugins);
    }
    public static addPlugin(options: IClassPluginOptions){
        if (PluginsController.plugins == null) PluginsController._plugins = [];
        PluginsController.plugins.push(options);
        if (options.initStore && PluginsController.store != null) {
            options.initStore(PluginsController.store);
        }
    }

    private static reInitPlugins(){
        PluginsController.plugins.forEach(a => {
           if (a.initStore) a.initStore(PluginsController.store);
        });
    }
}