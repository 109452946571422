import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {LeftAuthPanelComp} from "./LeftAuthPanelComp";
import {UrlCoder} from "../../../helper/utils/UrlCoder";
import {action} from "mobx";

export enum ModeConfirmationForm {
    sendedEmail = 'sendedEmail',//отправлена ссылка для подтверждения на email
    clickInnerEmail = 'clickInnerEmail', //Страница по клику на ссылку в email
}
interface IConfirmationFormComp extends IStoreProps{
    mode: ModeConfirmationForm;
}

@observer
export class ConfirmationFormComp extends React.Component<IConfirmationFormComp, undefined> {
    constructor(props: IConfirmationFormComp) {
        super(props);
        autoBindReact(this);
        let c = props.store.user.confirmForm;
        if (c.confirmed == null && this.props.mode == ModeConfirmationForm.clickInnerEmail){
            let pm = UrlCoder.getQueryParams();
            if (pm.code != null){
                c.doConfirmEmail(pm.code);
            }
        }
    }

    @action
    onClickSignIn(event: any){
        event.preventDefault();
        this.props.store.gotoUrl("/signin");
        return false;
    }

    render() {
        let store = this.props.store;
        let title:string = '';
        let msg:string = '';

        if(this.props.mode == ModeConfirmationForm.sendedEmail){
            title = store.trans["Congratulations!"];
            msg = store.trans["Registration was successful, an email was sent to your mail with a confirmation link"];
        }
        if(this.props.mode == ModeConfirmationForm.clickInnerEmail){
            if (store.user.confirmForm.confirmed == null) {
                title = store.trans.Loading;
                msg = store.trans["Sending confirmation..."];
            }else if (store.user.confirmForm.confirmed === true) {
                title = store.trans["Mail confirmed"];
                msg = store.trans["Your mail has been verified, you can now log in"];
            }else if (store.user.confirmForm.confirmed === false) {
                title = store.trans.Error;
                msg = store.trans["Security code is incorrect or has expired"];
            }
        }

        return <div className="authorize-background">
            <div className="authorize-background2">
                <LeftAuthPanelComp store={store} />
                <div className="authorize-d2 style-4">
                    <form className="authorize-form authorize-form-width" autoComplete="off" onSubmit={this.onClickSignIn}>
                        <div className="UserForm-title-m flex-columns flex-align-items-center UserForm-title-marginTop">
                            <div className="flex-fit-item">{title}</div>
                            <div onClick={this.onClickSignIn} className="pointer">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="19.5" stroke="#595D62"/>
                                    <path d="M25.5 14.5L14.5 25.5" stroke="#595D62" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.5 14.5L25.5 25.5" stroke="#595D62" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>

                        <div className="UserForms-descriptionGray-m UserForms-descriptionGray-topMargin">
                            {msg}
                        </div>

                        {this.props.mode == ModeConfirmationForm.clickInnerEmail && <button className="authorize-submit-form" type="submit">Log in</button>}
                    </form>
                    <div className="authorize-form-zatyk" />
                </div>
            </div>
        </div>;
    }
}