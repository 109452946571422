import {Translate} from "./Translate";

export class Translate_en extends Translate{
    "Select scenes to export" = ""
    "Exporting a map window"=""
    "ISO-XML (file for technology)"=""
    "Fertilizer"=""
    "Create an area"=""
    "File in .geojson format, EPSG:4326"=""
    "File name"=""
    "User doesn't exist" =""
    "User isn't valid" = ""
    "Project doesn't exist or was deleted" = ""
    "You don't have access to perform this operation" = ""
    "Search by name or email"=""
    "Sum of active (daily average > 10°)"=""
    "Sum of active (daily average > 5°)"=""
    "To move the map while drawing, hold down the key"=""

    "To quickly open this window, click"=""
    "Visualization"=""
    "Delete object"=""
    "Edit object"=""

    "Action type"=""
    "Date / time"=""
    "Create the request"=""
    "Download the report"=""
    "Changing the contours of the ACS"=""
    "View action log"=""
    "History of user actions"=""
    "pcs"=""
    "Merge/Add patch"=""
    "Split parts"=""
    "Add hole"=""
    "Delete part"=""
    "Delete all"=""

    "Map window export" = ""
    "Search"=""
    "Fatal error"=""
    "Canceled"=""
    "Deleted"=""
    "Group order"=""
    "Categories"=""
    "Save for current session only"=""
    "Groups"=""
    "lat, lon:"=""
    "(geojson, kml, shp in zip)" = ""
    "To download Sentinel-1 images go to the {0} tab in the sidebar"=""
    "For export, set the active area"=""
    more = ""
    less = ""
    or = ""
    "Not field!"=""
    "Go to another project"=""
    "Change name"=""
    "Set up project"=""
    "Project settings"=""
    "Order type"=""
    "Merge by dates"=""
    "Merge scenes into single dates within current map extent"=""
    "cells"=""
    "double click"=""
    "pictures"=""
    "charts"=""
    "fields"=""
    "photos"=""
    "Remaining for the period:"=""
    "Period start date:"=""
    "Period end date:"=""
    "Turn on Sentinel-2 scene"=""
    "Merge scenes from single path"=""
    "Manager"=""
    "Аdding a comment"=""
    "Editing a comment"=""
    "Unlimit"=""
    "Limit"=""
    "Subscription"=""
    "Comment"=""
    "Search by name, email, comments"=""
    "Search by title or owner"=""
    "Admin Panel"=""
    "Project owner"=""
    "Confirmation does not match password"=""
    "Password has been changed"=""
    "Change password"=""
    "Current password"=""
    "New password again"=""
    "Change personal data"=""
    "Firstname"=""
    "Surname"=""
    "Current period"=""
    "Personal data"=""
    "General information"=""
    "Personal Are"=""
    "Average rate"=""
    "Total application volume"=""
    "Total area"=""
    "Pixel Size (m)"=""
    "Exporting a map by spectral indices"=""
    "Geometry type"=""
    "Zones"=""
    "Grid"=""
    "File format"=""
    "Export only visible classes"=""
    "Cut visible classes from active area"=""
    "Split by objects"=""
    "Attention! The selected objects will be permanently removed from the project with all information attached"=""
    "Nesting"=""
    Format=""
    object_create='Create object'
    object_creation='Create object'
    object_editing='Edit object'
    objects_editing='Edit objects'
    object_border='Object border'

    "You have no uploaded\nphotos, you can\nload them with\nbuttons"=""
    "Are you sure you want to delete the user?"=""
    "Project type"=""
    "Change permissions"=""
    "Are you sure you want to delete the project and all attached data?"=""
    "Project {0} has been deleted"=""
    "sortByName"="Title"
    "sortByDate"="Last modified"
    "User Email"=""
    "Adding a new user"=""
    "Access rights"=""
    "User access privileges"=""
    "Add user"=""
    "View"=""
    "Edit"=""
    "EditTo"="Edit"
    "Agro"=""
    "Agricultural insurance"=""
    "Parcel assess"=""
    "pnt_yld_assess"=""
    "Canceled successfully"="";
    "Show only current objects on map"=""
    "Show all objects on map"=""
    "Revert to default"=""
    "MODIS"="MODIS"
    "Daily precipitation"=""
    "Accumulated precipitation"=""
    "Daily radiation"=""
    "Accumulated radiation"=""
    "Day" = "День"
    "Help"=""
    "Maximum number of points: {0}"=""
    "From 1 to 175 (comma separated)"=""
    "Relative orbit"=""
    "To combine scenes from the same date, create a hotspot at the bottom of the Image search options panel {0}"=""
    "Charts"=""
    "Points"=""
    "Satellites"=""
    "Sources"=""
    "NOAA"="NOAA"
    "ERA5"="ERA5"
    "NOAA (since 2015)"=""
    "ERA5 (since 2010)"=""
    "IBM"="IBM"
    "SMAP"="SMAP"
    "Weather stations MTS"="Weather stations MTS"

    "Compare sources"=""
    "Spectral indices"="Spectral indices"
    "Sentinel-1 IW"="Sentinel-1 IW"
    "Sentinel-1 EW"="Sentinel-1 EW"
    "Solar radiation"="Solar radiation"
    "Relative Humidity"="Relative Humidity"
    "Soil moisture"="Soil moisture"

    "Space photography"=""
    "Weather data"=""
    "Category"=""


    "Maximum number of objects in active area: {0}"= ""
    "Show vertices"=""
    "Selected"=""

    "Convert spectral bands to albedo (0-1)"=""
    "Combine products into single TIFF"=""
    "Cancel active area"=""
    "Remove from Area of Interest"=""
    "Downloading" = ""
    "Select file format to export"="";
    "Select the attributes of your table from the uploaded file that you want to import and match them to the characteristics of your objects"="";
    "Objects not selected"="";
    "Import from file"="";
    "Project structure"="";
    "Reference cannot be changed"="";
    "is not a number"="";
    "Not set"="";
    "Removed successfully"="";
    "Clear value"="";
    "Different values"= "";
    "Not valid value" = "";
    "Total"="";
    "Don't import"=""
    "Area (ha)"=""
    "Saved successfully"="";
    "Remove all"=""
    "Pixel value"=""
    "Permalink"=""
    "Layers"=""
    "Measure"=""
    "Soil grid"=""
    "Order"=""
    "Orders"=""
    "Selected images"=""
    "OK"=""
    "Equal area"=""
    "Min-Max"=""
    "Calc. by active area"=""
    "Gradient" = ""
    "Discrete"=""
    "Active area is defined"=""
    "Active zone created" = ""
    "Active area created" = ""
    "Actions with selected objects" = ""
    "Selected objects to active area" = ""
    "Transform to active area" = ""
    "Set as Area of Interest" = ""
    "Too large area for preview"=""
    "Cropmask"=""
    "Mosaic"=""
    "The password must contain at least 8 characters, consist of letters of the Latin alphabet (A-z), Arabic numbers (0-9) and special characters."=""
    "Password changed successfully"=""
    "New password"=""
    "A link to reset your password has been sent to your email."=""
    "Interval (days)"=""
    "Opacity"=""
    "Min. quality"=""
    "< 100m"=""
    "The newest"=""
    "Takes the newest among valid pixels"=""
    "The oldest"=""
    "Takes the oldest among valid pixels"=""
    "Maximum"=""
    "Calculates maximum among valid pixels"=""
    "Minimum"=""
    "Calculates minimum among valid pixels"=""
    "Mean"=""
    "Calculates mean among valid pixels"=""
    "Median"=""
    "Calculates median among valid pixels"=""
    "Root mean square"=""
    "Calculates root mean square among valid pixels"=""

    Method=""
    December=""
    November=""
    October=""
    September=""
    August=""
    July=""
    June=""
    May=""
    April=""
    March=""
    February=""
    January=""
    "Month" = ""
    "Period" = ""
    "RSHB" = ""
    "Create the first request" = ""
    "Sentinel-5" = ""
    ObjectsFields = "Objects"
    Found = "Found"
    "Search options" = "Search options"
    "ha" = ""
    "sq. km" = "sq. km"
    "sq. m" = "sq. m"
    "Sign in via"=""
    "Already have an account?"=""
    "Privacy Policy"=""
    "Terms of Use"=""
    "By clicking on the \"Sign up\" button, I accept the {0} and {1} and consent to the processing of personal data, as well as to receive advertising and other types of letters associated with my account" = ""
    "Confirm password"=""
    "Email is also your login"=""
    "Second name"=""
    "First name"=""
    "Password mismatch"=""
    "Please specify password"=""
    "Please specify valid email"=""
    "Please specify second name"=""
    "Please specify first name"=""
    "Sign up"=""
    "Not yet with us?"=""
    "Log in"=""
    Password=""
    "Login or Email"=""
    "Sign in"=""
    Welcome=""
    "Send"=""
    "Forgot password"=""
    "Enter the email address to which we will send you an email with a link to create a new password"=""
    "Security code is incorrect or has expired"=""
    "Your mail has been verified, you can now log in"=""
    "Mail confirmed"=""
    "Sending confirmation..."=""
    "Registration was successful, an email was sent to your mail with a confirmation link"=""
    "Congratulations!"=""
    Status=""
    Role=""
    "Last enter"=""
    "User name"=""
    Users=""
    "Active"=""
    "Blocked"=""
    "Guest"=""
    "User"=""
    "Administrator"=""
    "Copied!"=""
    Legend=""
    "Select year"=""
    "Climate"=""
    Description=""
    "Are you sure to delete all points?"=""
    Index=""
    "Radius (m)"=""
    "Use the cursor {0} to place a point on the map where you would like to calculate the data type" = ""
    "Polygon is not included in the spatial filter of images search"=""
    "The point is not included in the spatial filter of image search"=""
    Mode=""
    "Search: coordinates, OSM objects"=""
    "Get object profile"=""
    "Get point profile"=""
    "Copy coordinates"=""
    "Get geometries"=""
    "© Ctrl2GO"=""

    "Loading objects from a file"=""

    "Project name"=""
    "Name of the company"=""
    "Create project"=""
    "Edit project"=""
    "Crop"=""
    "Object border"=""
    Save=""
    Return=""
    "Year"=""
    "Required data"=""
    "Delete selection"=""
    "Create object"=""
    "Project"=""
    "Projects"=""

    "There is no georeference"=""
    "Upload"=""
    "Don't download possible duplicates"=""
    "Loading stopped"=""
    "Loading is complete"=""
    Loading=""
    "Upload photos"=""
    "Not found"=""
    "Add"=""
    "Selected photos"=""
    "Removing tags"=""
    "Adding tags"=""
    "Create group"=""
    "Create tag group"=""
    "Tags reference"=""
    "Changing the tag name"=""
    "Name"=""
    Change=""
    "Are you sure you want to remove the tag?"=""
    Photos = ""
    "Filters"=""
    "Gallery"=""
    "All photos uploaded"=""
    "Loading error"=""
    "Select files" = ""
    "Uploading photos"=""
    "Show all"=""
    "Minimize"=""
    "Search by tags"=""
    "Tags"=""
    "Use map extent"=""
    "Delete photos ({0})?"=""
    "Delete photo"=""
    "Delete tags"=""
    Photo = ""
    "List" = ""
    Farm = ""
    "Farm name"= "Farm name"
    "Region"=""
    Season=""
    "Change farm"=""
    "m" = ""
    "km" = ""
    "m²" = ""
    "km²" = ""
    "meters" = ""
    "degrees" = ""
    "Slopes" = ""
    "Slopes °" = ""
    "Heights" = ""
    "Heights, m" = ""
    Area = ""
    AA = ""
    AOI = ""
    Palettes=""
    Palette=""
    Min=""
    Max=""
    Classes=""
    Interpolation=""
    "Pixels transparent <min"=""
    "Pixels transparent >max"=""
    Default=""
    Apply=""
    Remember=""
    "AI digitization"=""
    "Polygon simplification, m"=""
    "Mean +/- STD x"=""
    Mask=""
    LUT=""
    "Loading..."=""
    "Order history"=""
    "Order ID"=""
    "Start / Finish"=""
    Action=""
    Error=""
    Ready=""
    Wait=""
    "Polarization type"=""
    "Comma separated relative orbit numbers" = ""
    "Multitime filtering" = "";
    "Specify search area to activate Run button"=""
    "Active area"=""
    "Area of Interest"=""
    "{0} scenes" = ""
    "Quicklook is not found" = ""
    "Export options" = ""
    "Crop to active area" = ""
    "Products" = ""
    "Product" = ""
    "Delete_all_elements_from_favorites?" = ""//used
    "Changing the name of a tag group" = ""
    "Create tag" = ""
    "Delete" = ""
    "Tag" = ""
    "Spectral bands" = ""
    "Spectral indexes" = ""
    "Email" = ""
    "Copied" = ""
    "Are you sure you want to delete the group?" = ""
    "Personal Area" = ""
    "Log out" = ""
    "Admin panel" = ""
    Create = ""
    Update = ""
    All = ""
    NDVI = "NDVI"
    "Chart type" = ""
    "Accumulated daily mean" = ""
    Static = ""
    Adaptive = ""
    Title = ""
    hello = ""
    Scenes = ""
    Dates = ""
    Export = ""
    ExportTo = "Export"
    "Make online" = ""
    Date = ""
    "Image search" = ""
    "Max. cloud coverage" = ""
    "Data type" = ""
    "Metadata" = ""
    Images = ""
    Sensors = ""
    "Sensor" = ""
    "Select all" = ""
    "Off nadir angle" = ""
    "Zoom in to search images" = ""
    "Nothing found, change search parameters" = ""
    "Total found" = ""
    "Listed" = ""
    "Back" = ""
    "No images" = ""
    "Sentinel-1" = ""
    "Sentinel 2 + Landsat" = ""
    "Passive sensors (night)" = ""
    "All visible" = ""
    "Only visible" = ""
    "Task name" = ""
    "Orbit number filter" = ""
    "Properties" = ""

    "Exit without save"= ""
    "Cancel" = ""
    "Close" = ""
    "Cancel upload" = ""
    "Run" = ""
    "Adjust pixel values" = ""
    
    "Compare mode" = ""
    "Overlays Panel" = ""
    "Baselayers" = ""
    "Overlays" = ""
    "Reset bearing to north" = ""

    "Click to draw a point" = ""
    "Click to draw vetrices and double click to finish" = ""
    "Click to draw a vertex, drag and click to finish" = ""

    "Download" = ""
    "Remove" = ""
    "Set active area" = ""
    "Set Area of Interest" = ""
    "Value type" = ""
    "Precipitation, NOAA" = ""
    "recipitation, NOAA, mm" = ""
    "Precipitation, ERA5" = ""
    "Precipitation mm" = ""
    "Precipitation, IBM" = ""
    "Precipitation, IBM, mm" = ""

    "Relative humidity, IBM" = ""
    "Relative humidity, IBM, %" = ""
    "Relative humidity, ERA5" = ""
    "Relative humidity, %" = ""
    "Volume moisture fraction" = ""

    "Surface solar radiation, MJ/m²" = ""
    "Surface solar radiation, ERA5" = ""
    "Temperature, NOAA" = ""
    "Temperature, NOAA, °C" = ""
    "Temperature, ERA5" = ""
    "Temperature °C" = ""
    "Temperature, IBM" = ""
    "Temperature, IBM, °C" = ""
    "Daily mean" = ""
    "Daily min, max" = "";
    "Daily minimum" = ""
    "Daily maximum" = ""
    "Daily" = ""
    "NDVI (250 m)" = "NDVI (250 m)"

    "Cartogram parameters" = ""
    "Parameter" = ""
    "Aggregation for the period" = ""
    "Indicator value" = ""
    "Operator" = ""
    "Threshold value" = ""
    "Color settings" = ""
    "Quantiles, min-max" = ""
    "Scale" = ""
    "Temperature" = "Temperature"
    "Precipitation" = "Precipitation"
    "Active temperature, °C" = ""
    "Atmospheric/soil drought" = ""
    "Precipitation, mm" = ""
    "Selyaninov hydrothermal coefficient" = ""
    "Sum" = ""
    "Multiyear mean of sum" = ""
    "Difference with multiyear mean" = ""
    "Relation to the climatic norm for the period" = ""
    "Ratio to multiyear mean (in percentages)" = ""
    "No filter" = ""
    ">" = ""
    "≥" = ""
    "=" = ""
    "≤" = ""
    "<" = ""
    "≠" = ""
    "Incorrect dates" = ""
    "Period must be no more than 366 days" = ""
    "The period length is no more than 366 days" = ""
    "The period length is no more than 90 days" = ""
    "(no more than 90 days)" = ""
    "(no more than 366 days)" = ""
    "The period length is no more than N days" = "The period length is no more than ${0} days"
    "Data available since 2010-01-01" = ""
    "Cadastral number" = ""
    "Creating a request" = ""
    "Create a request" = ""
    "Borrower" = ""
    "Pledger" = ""
    "Specialist" = ""
    "Regions" = ""
    "Requests" = ""
    "Entered information" = ""
    "Information from EGRN" = ""
    "Cadastral boundary" = ""
    "Address" = ""
    "Request with cadastral number {0} has already been created" = ""
    "Permitted use" = ""
    "Cadastral value" = ""
    "Price change date" = ""
    "Take to work" = ""
    "EGRN" = ""
    "Waiting for processing" = ""
    "Creating report" = ""
    "Report ready" = ""
    "Report is editing" = ""
    "Request deleted" = ""
    "EGRN error" = ""
    "Encumbrances" = ""
    "No encumbrances" = ""
    "Encumbrances found" = ""
    "Request {0} not found" = ""
    "Registered rights / encumbrances" = ""
    "Owner" = ""
    "Right" = ""
    "Encumbrance" = ""
    "Number" = ""
    "Recipient" = ""
    "Document" = ""
    "Wrong format" = ""
    "open on PKK" = ""
    "Report not created" = ""
    "New request" = ""
    "Agri. Soil Analysis Data" = ""
    "Editing a agricultural soil analysis map (ASA Map)" = ""
    "Create a agricultural soil analysis map (ASA Map)" = ""
    "Merge Selected" = ""
    "Delete selected" = ""
    "Agricultural soil analysis maps (ASA Maps)" = ""
    "Trial evaluation of the work of cartograms" = ""
    "Cells" = ""
    "Cells:" = ""
    "Field outlines" = ""
    "Tracks" = ""
    "Download indicators" = ""
    "Loading indicators" = ""
    "Gradations and palettes" = ""
    "Visualization settings"=""
    "Cell labels" = ""
    "Meaning of indicators" = ""
    "Cell numbers" = ""
    "Scale and palette type" = ""
    "Cancellation" = ""
    "Cartograms" = ""
    "Reports"=""
    "ASA Maps" = ""
    "Attention! The selected objects will be permanently deleted with all information attached" = ""
    "To get started, download data from the laboratory in Excel format" = ""
    "Agrochemical data can be downloaded in OpenDocument (.ods) format (supported by MS Excel, Google Sheets, OpenOffice, etc.). The column names must match the" = ""
    "pattern" = ""
    ". How to correct the original spreadsheet file from the lab is described in" = ""
    "instructions" = ""
    "The file has been successfully processed and matches the sample" = ""
    "The file was processed successfully." = ""
    "Some column names do not match the template" = ""
    "The file has been processed with errors. Fix the following bugs" = ""
    "Loaded indicators" = ""
    "Number of samples" = ""
    "Columns" = ""
    "Indicators" = ""
    "Indicators:" = ""
    "Probes" = ""
    "Sample" = ""
    "Instruction" = ""
    "Delete blue contour" = ""
    "Delete contour" = ""
    "Link to cells (permalink)" = ""
    "Upload file" = ""
    "Editing AXO metadata" = ""
    "Are you sure you want to delete AXO data with name" = ""
    "Fields" = ""
    "Units" = ""
    "Standard" = ""
    "Filtration" = ""
    "Cancel all" = ""
    "Introduction date" = ""
    "Reason for introduction" = ""
    "Code" = ""
    "Administrative unit" = ""
    "Values filter" = ""
    "Reference" = ""
    "YYYY-MM-DD" = ""
}