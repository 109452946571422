import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observable} from "mobx";
import {Utils} from "../../helper/utils/Utils";
import {isFunction} from "lodash-es";
import classNames from "classnames";
import {IInputNumberProps, InputNumber} from "./InputNumber";

export interface IInputNumberArrowProps {
    value: string|number,
    min?: number,
    max?: number,
    hasStandartStyles?: boolean,//default true
    classNameDiv?: string,
    classNameInput?: string,
    onChange?: (str: string)=>any,
    onChangeNumber?: (num: number)=>any,
    onClassesKeyPress?: (e:any)=>any,
    disabled?: boolean,
    changeOnExitOrEnter?: boolean,
    arrowUpClassName?: string; 
    arrowDownClassName?: string;
}

export class InputNumberArrow extends React.PureComponent<IInputNumberArrowProps, undefined> {
    constructor(props: IInputNumberArrowProps) {
        super(props);
        autoBindReact(this);
        this.value = this.props.value;
    }

    @observable
    value: any;

    componentDidUpdate(prevProps: Readonly<IInputNumberProps>, prevState: Readonly<undefined>, snapshot?: any) {
        if (prevProps.value != this.props.value){
            this.value = this.props.value;
        }
    }

    private onChange(str: string){
        if (this.props.disabled) return;
        this.value = str;
        if (this.props.onChange) this.props.onChange(str);
    }

    onClickUp(){
        if (this.props.disabled) return;
        let n = Utils.parseNumber(this.getValue());
        if (n != null) {
            n = n + 1;
            this.setValue(n);
        }
    }
    onClickDown(){
        if (this.props.disabled) return;
        let n = Utils.parseNumber(this.getValue());
        if (n != null) {
            n = n - 1;
            this.setValue(n);
        }
    }

    getValue(): any{
        let v = this.props.value;
        if (this.props.changeOnExitOrEnter){
            v = this.value;
        }
        return v;
    }

    setValue(n: number){
        let min = Utils.parseNumber(this.props.min);
        let max = Utils.parseNumber(this.props.max);
        if (min != null && n < min) return;
        if (max != null && n > max) return;
        if (this.props.changeOnExitOrEnter) {
            this.value = n;
        }
        if (isFunction(this.props.onChange)) this.props.onChange(n.toString());
        if (isFunction(this.props.onChangeNumber)) this.props.onChangeNumber(n);
    }

    render() {
        let v = this.getValue();
        return <div className={classNames({"InputNumber-div": this.props.hasStandartStyles??true}, this.props.classNameDiv)}>
            <InputNumber value={v} onChangeNumber={this.props.onChangeNumber} onChange={this.onChange} changeOnExitOrEnter={this.props.changeOnExitOrEnter}
                         min={this.props.min} max={this.props.max} className={this.props.classNameInput} hasStandartStyles={this.props.hasStandartStyles}
                         disabled={this.props.disabled} onClassesKeyPress={this.props.onClassesKeyPress}
            />
            <svg className={classNames("InputNumber-arrowTop", this.props.arrowUpClassName)} width="8" height="8" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.onClickUp}>
                <path d="M7.125 3.875L4 0.125L0.875 3.875H7.125Z" fill="#6D7785"/>
            </svg>
            <svg className={classNames("InputNumber-arrowBottom", this.props.arrowDownClassName)} width="8" height="8" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.onClickDown}>
                <path d="M0.875001 0.125L4 3.875L7.125 0.125L0.875001 0.125Z" fill="#6D7785"/>
            </svg>
        </div>;
    }

}
