import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {ReactDoubleDatePicker2Comp} from "../../../../Common/ReactDoubleDatePicker2Comp";
import {RadioButton} from "../../../../Common/RadioButton";
import {Sentinel1Mode} from "../../../../../store/SearchParamsS1Store";
import {action} from "mobx";
import classNames from "classnames";
import "./ParamSearchSentinel1Comp.scss";
import {LeftPanelMode} from "../../../../../store/SearchStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";

@observer
export class ParamSearchSentinel1Comp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    changeMode(event: any, data: any){
        let s1 = this.props.store.searchPanel.searchParamsS1;
        s1.mode = data;
    }
    @action
    onChangeRelativeOrbit(e: any){
        let s1 = this.props.store.searchPanel.searchParamsS1;
        s1.relativeOrbit = e.currentTarget.value;
    }
    @action
    onClickOrders(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.orders);
    }

    render() {
        let store = this.props.store;
        let s1 = store.searchPanel.searchParamsS1;
        return <div className="height100 width100 flex-stretch-item flex-rows">
            <div className="popup-pref-in__separator"/>
            <div className="popup-pref-in__row2">
                <div className="popup-pref-in__row2_left">
                    {store.trans.Period}
                </div>
                <div className="popup-pref-in__row2_right">
                    <ReactDoubleDatePicker2Comp store={store} interval={s1.filterDate}/>
                </div>
            </div>
            <div className="popup-pref-in__separator"/>
            <div className="ParamSearchSentinel1Comp-modeSeparate">
                <span className="">{store.trans.Mode}</span>
            </div>

            <div>
                <RadioButton className="ParamSearchSentinel1Comp-radioButton"
                        checked={s1.mode == Sentinel1Mode.IW} data={Sentinel1Mode.IW} onClick={this.changeMode}>
                    <span className="ParamSearchSentinel1Comp-radioButtonText">IW</span>
                    <span className="ParamSearchSentinel1Comp-modeGrayText">Interferometric Wide Swath 10m x 10m,  Polarization VV+VH</span>
                </RadioButton>
                <RadioButton className="ParamSearchSentinel1Comp-radioButton"
                        checked={s1.mode == Sentinel1Mode.EW} data={Sentinel1Mode.EW} onClick={this.changeMode}>
                    <span className="ParamSearchSentinel1Comp-radioButtonText">EW</span>
                    <span className="ParamSearchSentinel1Comp-modeGrayText">Extra-Wide Swath 40m x 40m, Polarization: HH+HV</span>
                </RadioButton>
            </div>

            <div className="popup-pref-in__separator"/>

            <div className="popup-pref-in__row2" style={{marginBottom: "10px"}}>
                <div className="popup-pref-in__row2_left">
                    {store.trans["Relative orbit"]}
                </div>
                <div className="popup-pref-in__row2_right">
                    <input type="text" placeholder="37, 53, 128"
                           className={classNames("text-box-editor width100  hide_placeholder_on_focus",{"red_border": !s1.isValidRelativeOrbit()})}
                           onChange={this.onChangeRelativeOrbit} value={s1.relativeOrbit??""} />
                </div>
            </div>
            <div className="ParamSearchSentinel1Comp-relativeOrbitGrey">
                <div className="ParamSearchSentinel1Comp-relativeOrbitGreyText">{store.trans["From 1 to 175 (comma separated)"]}</div>
            </div>

            <div className="flex-stretch-item flex-rows-centerByBoth ParamSearchSentinel1Comp-bottomLabel">
                <div className="text-center">
                    {TranslateUtils.createSpansWithReplaceMarkers(this.props.store.trans["To download Sentinel-1 images go to the {0} tab in the sidebar"],
                        <a className="link-text-underlineOnHover" onClick={this.onClickOrders}>{store.trans.Orders}</a>)}
                </div>
            </div>
        </div>;
    }
}
