import * as mapboxgl from "maplibre-gl";
import {EventData, LngLat, MapboxGeoJSONFeature, MapMouseEvent, Point} from "maplibre-gl";
import {FeatureCollection, Geometry, Position} from "geojson";
import autoBind from "auto-bind";
import {AddrContour, AddrPoint, GeometryUtils, ISimpleGeometry} from "../../../helper/utils/GeometryUtils";

import {MBUtils} from "../../../helper/utils/MBUtils";
import {Utils} from "../../../helper/utils/Utils";
import {IReactionDisposer} from "mobx/lib/internal";
import {ApiTool} from "../../../../pluginApi/tools/ApiTool";
import {IToolEvent, ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import {computed, observable} from "mobx";
import {IGeometryActionHistory} from "../../../helper/geometryAction/GeometryActionHistory";
import {ActionManager} from "../../../helper/geometryAction/ActionManager";
import {Exception} from "sass";

export enum DrawPointType{
    vertex = 'vertex',
    midpoint = 'midpoint'
}

export interface IObjectByClick{
    pointIndex: AddrPoint,//адрес вертекса в геометрии
    pointCoord: Position,//гео координаты вертекса
    pointType: DrawPointType,//что это: точка в геометрии или виртуальная точка
    layerId: string;
    sourceId: string;//
    pointInternal: MapboxGeoJSONFeature;
}

export interface IStopMouseParams{
    stopMouseMove?: boolean;//default = true
    stopMouseDown?: boolean;//default = true
    stopMouseUp?: boolean;//default = true
    tools?: CustomTool[];//ignore tools - инструменты которые будут продолжать получать события мыши
}

export interface IFuncToolEvent{
    (e: ToolEvent): void;
}

export enum CreateGeometryType {
    "Point"="Point",
    "Line"="Line",
    "Polygon" = "Polygon",
    "Hole" = "Hole",
    "Rectangle" = "Rectangle",
    "RectangleSecondPoint" = "RectangleSecondPoint"
}

export enum PrimaryTool{
    none = 'none',
    //по перворму клику будет новый полигон и переход в drawContour
    createGeometry = 'createGeometry',
    //редактируется геометрия
    edit = 'edit',
    deleteContour = 'deleteContour',
}


export interface IMidPoint{
    afterPointAddr: AddrPoint;
    point: Position;
}

export interface IGeometryEditorEvents{
    updateGeometry?(): void,
    updateMovedPoint?(): void,

    getObjectByClick(point: mapboxgl.Point):IObjectByClick,
    onMouseMove?(e: mapboxgl.MapMouseEvent & ToolEvent):void,
    onClickFirstPointPolygon?(e : MapMouseEvent & ToolEvent): boolean,
    onClickRightButton?(e : MapMouseEvent & ToolEvent): boolean,
    onBeforeCreateGeometry?(e: MapMouseEvent & ToolEvent): void,
    onChangeGeometry?(): void,
    onDeleteContour?(e : MapMouseEvent & ToolEvent): void,
    canDeletePointOnEdit?(): boolean;

    isCreateGeometry?(): boolean,
    isEdit?(): boolean,
    isDeleteContour?(): boolean,
}

export class ContainerToolsState{
    constructor() {
        autoBind(this);
    }
    @observable
    simpleGeometry: ISimpleGeometry[] = [];
    getGeometry(): Geometry{
        if (this.simpleGeometry == null || this.simpleGeometry.length == 0) return null;
        return GeometryUtils.createGeometryBySimpleGeometry(this.simpleGeometry);
    }

    @observable
    highlightContourAddr: AddrContour = null;

    events: IGeometryEditorEvents;

    @observable
    private _createGeometryType: CreateGeometryType = null;
    get createGeometryType(): CreateGeometryType{
        return this._createGeometryType;
    }
    set createGeometryType(value: CreateGeometryType){
        this._createGeometryType = value;
    }
    actionManager: ActionManager = new ActionManager(this);

    //Адрес создаваемого контура линии, полигона или дырки
    @observable
    curAddrContour: AddrContour = null;

    //координаты перемещаемой или новой точки + линий к существующим
    movedPointCoord: Position = null;
    movedPrevPointCoord: Position = null;
    movedNextPointCoord: Position = null;

    lastMouseScrCoord: Position = null;
    resetMovedPoints(){
        this.movedPrevPointCoord = null;
        this.movedPointCoord = null;
        this.movedNextPointCoord = null;
    }
    movingPoint: boolean = false;
    midPoint: IMidPoint = null;//серединная точка выделена
    debug: string = "";
}

export class ContainerTools{
    constructor() {
        autoBind(this);
    }
    static readonly EMPTY_SOURCE : FeatureCollection = {'type': 'FeatureCollection', 'features': []};

    public map: mapboxgl.Map;
    @observable
    public measures: CustomTool[] = [];

    @computed
    get allTools(): CustomTool[]{
        let r: CustomTool[] = [];

        function addTool(tool: CustomTool){
            if (tool.beforeSubTools != null){
                tool.beforeSubTools.forEach(t => {
                    addTool(t);
                })
            }
            r.push(tool);
            if (tool.afterSubTools != null){
                tool.afterSubTools.forEach(t => {
                    addTool(t);
                })
            }
        }
        this.measures.forEach(t => {
            addTool(t);
        });
        return r;
    }
    public debugMessages: boolean = false;
    public clickTolerance = 5;
    public dragTolerance = 10;
    public dragTimeTolerance = 200;
    public dragTimeToleranceMax = 1000;
    public hackerStopOnMoveStartEnd: boolean = false;
    protected cursorMap: string = null;
    protected _stopMouseParams: IStopMouseParams = {stopMouseDown: false, stopMouseMove: false, stopMouseUp: false, tools: []};

    private mouseDragStartScreen: Position;
    private mouseDragStartTime: number;
    private mouseDown: boolean = false;
    private mouseLastPoint: Point;
    private mouseLastLngLat: LngLat;
    private mouseDragging: boolean = false;

    private _installed: boolean = false;
    get installed(){
        return this._installed;
    }

    public install(map: mapboxgl.Map){
        this.map = map;
        if (this._installed) return;
        this._installed = true;
        this.installEvents();
        this.allTools.forEach(a => {
            a.onInstall();
        });
        this.allTools.forEach(a => {
            a.subscrption();
        });
    }

    public uninstall(){
        if (!this._installed) return;
        this._installed = false;
        this.allTools.forEach(a => {
            a.unsubscrption();
        });

        this.uninstallEvents();
        this.allTools.forEach(a => a.onUninstall());
    }

    protected installEvents() {
        this.map.on("movestart", this.onMoveStart);
        this.map.on("move", this.onMove);
        this.map.on('moveend', this.onMoveEnd);
        this.map.on("mousemove", this.onMouseMove);
        this.map.on("mousedown", this.onMouseDown);
        this.map.on("mouseup", this.onMouseUp);
        this.map.on("zoom", this.onZoom);

        document.addEventListener("mouseup", this.onMouseUpDocument);
        this.map.on("mouseover", this.onMouseEnter);
        this.map.on("mouseout", this.onMouseLeave);
        this.map.on('contextmenu', this.onContextMenu);
        this.map.on('dblclick', this.onDblclick);
        this.map.on('dblclick', this.onDblclick);
        this.map.on('sourcedata', this.onSourcedata);
    }
    protected uninstallEvents(){
        document.removeEventListener("mouseup", this.onMouseUpDocument);
        this.map.off("movestart", this.onMoveStart);
        this.map.off("move", this.onMove);
        this.map.off('moveend', this.onMoveEnd);
        this.map.off("mousemove", this.onMouseMove);
        this.map.off("mousedown", this.onMouseDown);
        this.map.off("mouseup", this.onMouseUp);
        this.map.off("mouseover", this.onMouseEnter);
        this.map.off("mouseout", this.onMouseLeave);
        this.map.off('contextmenu', this.onContextMenu);
        this.map.off('dblclick', this.onDblclick);
        this.map.off('sourcedata', this.onSourcedata);
        this.map.off("zoom", this.onZoom);
    }

    protected onContextMenu(e: EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => a.onContextMenu, p);
        this.receiveInnerEventObj(p);
        //if (!r) e.preventDefault();
        return r;
    }
    protected onMouseEnter(e: EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => a.onMouseEnter, p);
        this.receiveInnerEventObj(p);
        return r;
    }
    protected onMouseLeave(e: EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => a.onMouseLeave, p);
        this.receiveInnerEventObj(p);
        return r;
    }
    protected onMouseClick(e : MapMouseEvent & EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => a.onMouseClick, p);
        this.receiveInnerEventObj(p);
        if (!r) e.preventDefault();
        return r;
    }
    protected onZoom(e: any): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => a.onZoom, p);
        this.receiveInnerEventObj(p);
        return r;
    }

    protected onSourcedata(e : any): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => a.onSourcedata, p);
        this.receiveInnerEventObj(p);
        //if (!r) e.preventDefault();
        return r;
    }
    protected onDblclick(e : MapMouseEvent & EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => a.onDblclick, p);
        this.receiveInnerEventObj(p);
        if (!r) e.preventDefault();
        return r;
    }

    public onMoveStart(e : MapMouseEvent & EventData): boolean{
        if (this.hackerStopOnMoveStartEnd) return null;
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => {
            if (this._stopMouseParams.stopMouseMove){
                if (this._stopMouseParams.tools.find(t => t == a) == null) return null;
            }
            return a.onMoveStart;
        }, p, "onMoveStart");
        this.receiveInnerEventObj(p);
        //if (!r) e.preventDefault();
        return r;
    }
    public onMoveEnd(e : MapMouseEvent & EventData): boolean{
        if (this.hackerStopOnMoveStartEnd) return null;
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => {
            if (this._stopMouseParams.stopMouseMove){
                if (this._stopMouseParams.tools.find(t => t == a) == null) return null;
            }
            return a.onMoveEnd;
        }, p, "onMoveEnd");
        this.receiveInnerEventObj(p);
        //if (!r) e.preventDefault();
        return r;
    }

    public onMove(e : MapMouseEvent & EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => {
            if (this._stopMouseParams.stopMouseMove){
                if (this._stopMouseParams.tools.find(t => t == a) == null) return null;
            }
            return a.onMove;
        }, p, "onMove");
        this.receiveInnerEventObj(p);
        //if (!r) e.preventDefault();
        return r;
    }
    protected onMouseDown(e : MapMouseEvent & EventData): boolean{
        this.mouseDown = true;
        this.mouseDragStartScreen = MBUtils.pointToPosition(e.point);
        this.mouseDragStartTime = performance.now();

        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => {
            if (this._stopMouseParams.stopMouseDown){
                if (this._stopMouseParams.tools.find(t => t == a) == null) return null;
            }

            return a.onMouseDown;
        }, p, "onMouseDown");

        this.receiveInnerEventObj(p);
        if (!r) e.preventDefault();
        return r;
    }
    //глобально обрабатываем на случай если MouseUp сработало над другим компонентом, но начало над нашим
    protected onMouseUpDocument(ev: MouseEvent){
        setImmediate(()=>{
            if (this.mouseDown){
                this.onMouseUp({
                    point: this.mouseLastPoint,
                    lngLat: this.mouseLastLngLat,
                    originalEvent: ev,
                    type: 'mouseup',
                    target: this.map,
                    defaultPrevented: true,
                    preventDefault: ()=>{}
                });

            }
        });
    }
    protected onMouseUp(e : MapMouseEvent & EventData): boolean{
        this.mouseDown = false;
        let stopDrag = false;
        let p = this.getInnerEventObj(e);
        if (this.mouseDragging){
            this.onMouseDragEnd(e);
            stopDrag = true;
        }
        let r = this.callSubEvents((a) => {
            if (this._stopMouseParams.stopMouseUp){
                if (this._stopMouseParams.tools.find(t => t == a) == null) return null;
            }
            return a.onMouseUp;
        }, p, "onMouseUp");

        if ((this.mouseDragStartScreen == null) ||
            GeometryUtils.distance(this.mouseDragStartScreen, MBUtils.pointToPosition(e.point)) < this.clickTolerance && !this.mouseDragging){
            this.onMouseClick(e);
        }
        this.receiveInnerEventObj(p);
        if (stopDrag){
            this.mouseDragging = false;
        }
        if (!r) e.preventDefault();
        return r;
    }
    protected onMouseMove(e : MapMouseEvent & EventData): boolean{
        this.mouseLastLngLat = e.lngLat;
        this.mouseLastPoint = e.point;
        if (this.mouseDown && !this.mouseDragging){
            let d = performance.now() - this.mouseDragStartTime;
            if (
                ((GeometryUtils.distance(this.mouseDragStartScreen, MBUtils.pointToPosition(e.point)) > (this.dragTolerance)) &&
                (d > this.dragTimeTolerance))
                || (d > this.dragTimeToleranceMax)
            ){
                this.mouseDragging = true;
                this.onMouseDragStart(e);
            }
        }
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => {
            if (this._stopMouseParams.stopMouseMove){
                if (this._stopMouseParams.tools.find(t => t == a) == null) return null;
            }
            return a.onMouseMove;
        }, p, "onMouseMove");
        this.receiveInnerEventObj(p);
        if (!r) e.preventDefault();
        return r;
    }

    protected onMouseDragStart(e : MapMouseEvent & EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => {
            return a.onMouseDragStart;
        }, p, "onMouseDragStart");
        this.receiveInnerEventObj(p);
        if (!r) e.preventDefault();
        return r;
    }
    protected onMouseDragEnd(e : MapMouseEvent & EventData): boolean{
        let p = this.getInnerEventObj(e);
        let r = this.callSubEvents((a) => {
            return a.onMouseDragEnd;
        }, p, "onMouseDragEnd");
        this.receiveInnerEventObj(p);
        if (!r) e.preventDefault();
        return r;
    }
    protected getInnerEventObj(obj: any): IToolEvent{
        let r: IToolEvent = obj;
        //r.cursor = null;
        let cursor: string = null;
        Object.defineProperty(r, "cursor",
            {
                get: () => {
                    return cursor;
                },
                set: (value: string) =>{
                    cursor = value;
                },
                enumerable: true,
                configurable: true,
            }
            );
        r.dragging = this.mouseDragging;
        r.oldCursor = this.cursorMap;

        r.isNoPropagation = false;

        r.noPropagation = () => {
            //let e = new Error();
            //console.log(e);
            r.isNoPropagation = true;
        };
        return r as IToolEvent;
    }

    protected receiveInnerEventObj(e: IToolEvent){
        if (e.cursor != null && this.cursorMap != e.cursor){
            this.cursorMap = e.cursor;
            //let t = this.map.getCanvas().style.cursor;
            //if (t != this.cursorMap)
            this.map.getCanvas().style.cursor = this.cursorMap;
        }
    }

    protected callSubEvents(callbackfn: (value: CustomTool, index: number) => IFuncToolEvent, value: ToolEvent, debugMsg: string = null): boolean{
        let stopPropogation = false;
        for(let i = this.allTools.length - 1; i >= 0;i--){
            let m = this.allTools[i];
            if (stopPropogation) continue;
            let f = callbackfn(m, i);
            if (f == null) continue;
            f(value);

            stopPropogation = value.isNoPropagation;

            if (stopPropogation) {
                if (this.debugMessages && debugMsg != null){
                    let msg = Utils.objectClassName(this.allTools[i]);
                    msg += " "+debugMsg;
                    console.log(msg);
                }
            }
        }
        return false;
    }
}

export class CustomTool{
    constructor(container: ContainerTools, name: string) {
        autoBind(this);
        this._name = name;
        this.container = container;
    }

    _name: string = "";
    get name(): string { return this._name; }

    beforeSubTools: CustomTool[] = [];
    afterSubTools: CustomTool[] = [];

    protected container: ContainerTools;

    get map(): mapboxgl.Map{
        return this.container.map;
    }

    private internalSubcriptions: IReactionDisposer[] = [];

    onSubscription(): IReactionDisposer[] {
        return [];
    }

    public subscrption(){
        this.unsubscrption();
        this.internalSubcriptions = this.onSubscription();
    }
    public unsubscrption(){
        this.internalSubcriptions.forEach(a => a());
        this.internalSubcriptions = [];
    }

    //возвращает последний добавленный слой этого иснтрумента
    private ownFirstLayerName(): string{
        if (this.layers.length == 0) return null;
        return this.layers[0];
    }

    //получает последний добавленный слой предыдущих компонентов
    public getNextLayerName(): string{
        let index = this.container.allTools.findIndex(a => a == this);
        let i = index + 1;
        while (i < this.container.allTools.length){
            let t = this.container.allTools[i];
            let layer = t.ownFirstLayerName();
            if (Utils.isStringNotEmpty(layer)) return layer;
            i++;
        }
        return null;
    }

    layers: string[] = [];
    addLayer(layer: maplibregl.AnyLayer){
        let nextName = this.getNextLayerName();
        this.map.addLayer(layer, nextName);
        this.layers.push(layer.id);
    }
    insertLayer(layer: maplibregl.AnyLayer, before: string){
        if (before == null){
            this.addLayer(layer);
        }else{
            let idx: number = -1;
            idx = this.layers.findIndex(a => a == before);
            if (idx < 0) throw "Mapbox layer is not found: "+before;
            this.map.addLayer(layer, before);
            Utils.arrayInsert(this.layers, idx, layer.id);
        }
    }

    moveLayer(idLayer: string, beforeLayerID?: string){
        if (beforeLayerID == null)
            this.map.moveLayer(idLayer, this.getNextLayerName());
        else this.map.moveLayer(idLayer, beforeLayerID);
    }
    removeLayer(layerId: string){
        let idx = this.layers.findIndex(a => a == layerId);
        if (idx >= 0) {
            Utils.arrayRemoveByIndex(this.layers, idx);
            if (this.map.getLayer(layerId) != null)
                this.map.removeLayer(layerId)
        }
    }
    removeAllOwnLayers(){
        this.layers.forEach(a =>{
            if (this.map.getLayer(a) != null) this.map.removeLayer(a);
        });
        this.layers = [];
    }

    onInstall() {
    }

    onUninstall() {
    }

    onSourcedata(e: ToolEvent): void {
    }

    onDblclick(e: MapMouseEvent & ToolEvent): void {
    }

    onMouseDragStart(e: MapMouseEvent & ToolEvent): void {
    }

    onMouseDragEnd(e: MapMouseEvent & ToolEvent): void {
    }

    onMouseMove(e: MapMouseEvent & ToolEvent): void {
    }

    onMouseDown(e: MapMouseEvent & ToolEvent): void {
    }

    onMouseUp(e: MapMouseEvent & ToolEvent): void {
    }

    //public onDragStart(e : MapMouseEvent & ToolEvent): void {}
    //publi onDrag
    onMoveStart(e: MapMouseEvent & ToolEvent): void {
    }

    onMove(e: MapMouseEvent & ToolEvent): void {
    }

    onContextMenu(e: ToolEvent): void {
    }

    onMouseEnter(e: ToolEvent): void {
    }

    onMouseLeave(e: ToolEvent): void {
    }

    onMouseClick(e: MapMouseEvent & ToolEvent): void {
    }

    onMoveEnd(e: ToolEvent): void {
    }

    onZoom(): void{

    }
}

