import {CustomStore} from "../CustomStore";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {Utils} from "../../helper/utils/Utils";

export class SocketStore extends CustomStore{
    msRefresh = 1000 * 20;
    lastVersionFields: any = null;
    lastVersionPhoto: any = null;

    //socket: Socket;

    public init(){
        /*
        this.socket = io();
        this.socket.on('', (args: any) => {
            console.log(args);
        });*/
        this.checkVersion();
    }
    async checkVersion(){
        try {
            if (this.root.user.isAuth && Utils.isStringNotEmpty(this.root.agro.projectName)) {
                let json = await fetchJsonGet(`/api/projects/${this.root.agro.projectName}/vect_ds_versions`);///dataset/meta/${this.root.agro.dataset}
                let newVer = json.versions.objects;
                if (this.lastVersionFields == null) {
                    this.lastVersionFields = newVer;
                } else if (this.lastVersionFields != newVer) {
                    this.lastVersionFields = newVer;
                    this.root.agro2.incVersionFieldTiles();
                }
                /*
                let newVerPhoto = json.versions.photo;
                if (this.lastVersionPhoto == null){
                    this.lastVersionPhoto = newVerPhoto;
                }else if (this.lastVersionPhoto != newVerPhoto){
                    this.lastVersionPhoto = newVerPhoto;
                    this.refreshPhoto();
                    this.root.photo.loadPhotoList();
                }*/
            }
        }catch (e) {
            console.error(e);
        }
        if (this.root.active) setTimeout(this.checkVersion, this.msRefresh);
    }


}