import {CustomStore} from "../CustomStore";
import {action, observable} from "mobx";
import {PhotoTagGroupFilterStore} from "./PhotoTagGroupFilterStore";

export enum PhotoSelectTagMode {
    add= 'add',
    remove='remove'
}
export class PhotoSelectTagStore extends CustomStore{

    @observable
    private _selectTagPanelShow: boolean = false;
    get selectTagPanelShow(): boolean {return this._selectTagPanelShow;}

    mode: PhotoSelectTagMode = null;

    @observable
    tagGroupFilter: PhotoTagGroupFilterStore = new PhotoTagGroupFilterStore(this);

    @action
    showAddTag(){
        let photoTagGroupFilterStore = this.tagGroupFilter;

        this.tagGroupFilter.loadPhotoTagFromJson(this.root.photo.tags);

        let tagsCount: Map<number, number> = new Map<number, number>();//key - tag_id, count
        let features = this.root.photo.editor.photos.filter(f => f.selected);
        features.forEach(
            f =>{
                f.tags_ids.forEach(t=>{
                    if (!tagsCount.has(t)) tagsCount.set(t, 1); else
                        tagsCount.set(t, tagsCount.get(t) + 1);
                });
            }
        );

        photoTagGroupFilterStore.tagGroups.forEach(g => g.tags.forEach(t => {
            t.disabled = (tagsCount.has(t.tag_id) && tagsCount.get(t.tag_id) == features.length);
            t.checked = t.disabled;
            t.highlight = tagsCount.has(t.tag_id);
        }));

        this._selectTagPanelShow = true;
        this.mode = PhotoSelectTagMode.add;
    }

    @action
    closeSelectTagPanel(){
        this._selectTagPanelShow = false;
    }

    @action
    showRemoveTags(){
        let photoTagGroupFilterStore = this.tagGroupFilter;
        this.tagGroupFilter.loadPhotoTagFromJson(this.root.photo.tags);

        let tagsCount: Map<number, number> = new Map<number, number>();//key - tag_id, count
        let features = this.root.photo.editor.photos.filter(f => f.selected);
        features.forEach(
            f =>{
                f.tags_ids.forEach(t=>{
                    if (!tagsCount.has(t)) tagsCount.set(t, 1); else
                        tagsCount.set(t, tagsCount.get(t) + 1);
                });
            }
        );
        //Удаляем не используемые теги и группы

        photoTagGroupFilterStore.tagGroups.forEach(g => {
            g.tags.forEach(t => {
                t.disabled = !tagsCount.has(t.tag_id);
                t.highlight = tagsCount.get(t.tag_id) == features.length;
                t.checked = false;
            });
            g.tags = g.tags.filter(z => !z.disabled);
        });
        photoTagGroupFilterStore.tagGroups = photoTagGroupFilterStore.tagGroups.filter(a => a.tags.find(t => !t.disabled));


        this._selectTagPanelShow = true;
        this.mode = PhotoSelectTagMode.remove;
    }

}