import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {ShowRoom2FiltersDistrictsState} from "./ShowRoom2FiltersDistrictsState";
import {IShowRoomDistrict} from "../../../../../store/dataShowroom2/ShowRoom2FilterProjectStore";

@observer
export class ShowRoom2FiltersDistricts extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;

        let byState: Record<string, IShowRoomDistrict[]> = {};
        //ShowRoom2FiltersDistrictsState
        store.dataShowRoomStore.filtersProject.districts.forEach(a =>{
            if (!byState[a.statename]){
                byState[a.statename] = [];
            }
            byState[a.statename].push(a);
        });
        let arr: any[] = [];
        let cnt = 0;
        for (const byStateKey in byState) {
            if (cnt > 0) arr.push(<div className="separator_line-flex ShowRoom2Filters-separateList" key={byStateKey+"_s"}/>);
            arr.push(<ShowRoom2FiltersDistrictsState key={byStateKey} store={store} districts={byState[byStateKey]} stateName={byStateKey} />);
            cnt++;
        }
        return <div>
            <div className="ShowRoom2Filters-subtitle">
                Districts
            </div>
            {arr}
        </div>;
    }
}
