import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {observable} from "mobx";
import {BandStaticStyleProductStore} from "./BandStaticStyleProductStore";

export class StaticStyleProductStore extends CustomStore{
    class(): string {
        return "StaticStyleProductStore";
    }
    @save @observable
    bands: BandStaticStyleProductStore[] = [];

    apply(){
        this.bands.forEach(a => a.apply());
    }
}

