import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCopyFieldsComp.scss';
import {action} from 'mobx';
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {CopyType} from "../../agroAhoCardStore";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";
import {AhoUtils} from "../../agroAhoUtils";

@observer
export class AgroAhoCopyFieldsComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickCancel(){
        this.props.store.agroAhoStore.toggleModal(false);
    }
    @action
    onClickOk(){
        if (!this.canSave()) return;
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.doFieldCopy();
        // this.props.store.agroAhoStore.toggleModal(false);
    }

    canSave(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        return (cstore.copyType == CopyType.New && cstore.copyNewMapName) ||
            (cstore.copyType == CopyType.Exist && !!cstore.copyAsaMapId && !cstore.fieldDouble && !cstore.cellDouble);
    }

    @action
    onChangeTitle(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.copyNewMapName = e.currentTarget.value;
    }

    onChangeType(item: IDropDownItem){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.copyType = item.key as CopyType;
        if (cstore.copyType == CopyType.Exist) cstore.updCellInterval();
    }

    onChangeAsaMap(item: IDropDownItem){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.copyAsaMapId = item.key as number;
        cstore.updCellInterval();
    }

    onChangeShift(e: any){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let val = e.currentTarget.value;
        if (!store_.isNum(val)) return;
        cstore.copyShift = val;
        cstore.updCellInterval();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let cs_store = store_.cardsStore;
        let items: IDropDownItem[] = [
            {key: CopyType.New, value: store_.trans['Copy to new ASA map']},
            {key: CopyType.Exist, value: store_.trans['Copy to existing ASA map']}
        ];
        let selectedFields = cstore.fieldsItems.filter((f: any)=>f.checked);

        let cards_ = cs_store.cards.filter(c => !c.filtered && c.map_id != cstore.card.map_id);
        let cards:IDropDownItem[] = cards_.map(c=>{return {key: c.map_id, value: c.map_name}});
        let selCard: any = cards.filter((c: any)=>c.key == cstore.copyAsaMapId);
        let mapName = selCard.length ? selCard[0].value : '';

        let cells = 0;
        selectedFields.forEach(f=>{
            let cells_cnt = 0;
            if (cstore.cellsItems)
                cells_cnt = cstore.cellsItems.filter(c=>c.field_id === f.field_id).length;
            cells += cells_cnt;
        });
        let ci = cstore.cellInterval;
        let sh = cstore.copyShift ? +cstore.copyShift : 0;
        let cellInterval = `${ci.min + sh}-${ci.max + sh}`;
        if (!cstore.fieldDouble && !cstore.cellDouble && sh)
            cellInterval = `${ci.min}-${ci.max} -> ${ci.min + sh}-${ci.max + sh}`;


        return <div className="AgroAhoCopyFieldsComp-main">
            <div className="AgroAhoCopyFieldsComp-title">{store_.trans['Copying selected fields']}</div>
            <div className="AgroAhoCopyFieldsComp-statistic">
                {store_.trans2.Fields}: {selectedFields.length}, {store_.trans["cells"]}: {cells}
            </div>
            <div className="AgroAhoCopyFieldsComp-line" />
            {/* >>> */}
            <div className="AgroAhoCopyFieldsComp-actionCover">
                <div className="AgroAhoCopyFieldsComp-name">
                    {store.trans.Action}
                </div>
                <div className="AgroAhoCopyFieldsComp-Input">
                    {/*ProductItemExportComp-groupKeyValue*/}
                    <div className="AgroAhoCopyFieldsComp-groupKeyValue">
                        <DropDownComp items={items} currentKey={cstore.copyType} onChange={this.onChangeType}
                            className="AgroAhoCopyFieldsComp-exportType" />
                    </div>
                </div>
            </div>
            {/* <<< */}
            {cstore.copyType == CopyType.New && <div className="AgroAhoCopyFieldsComp-nameCover">
                <div className="AgroAhoCopyFieldsComp-name">
                    {store.trans.Name}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoCopyFieldsComp-Input">
                    <input type="text" className="text-box-editor width100" onChange={this.onChangeTitle}
                           value={cstore.copyNewMapName} placeholder={store_.trans['New ASA map']} />
                </div>
            </div>}
            {cstore.copyType == CopyType.Exist && <div className="AgroAhoCopyFieldsComp-existListCover">
                <div className="AgroAhoCopyFieldsComp-name">
                    {store.trans.Name}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoCopyFieldsComp-Input">
                    <div className="ProductItemExportComp-groupKeyValue">
                        <DropDownComp items={cards} currentKey={cstore.copyAsaMapId} onChange={this.onChangeAsaMap}
                            className={classNames("ProductItemExportComp-exportType", {
                                "ProductItemExportComp-placeHolder": !cstore.copyAsaMapId
                            })} placeHolder={store_.trans['ASA map name']}
                            placeHolderSearch={store_.trans['Search by ASA map']}/>
                    </div>
                </div>

                <div className="AgroAhoCopyFieldsComp-name">
                    {store_.trans['Add value to cell numbers']}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoCopyFieldsComp-shiftCover">
                    <div className="AgroAhoCopyFieldsComp-shift">
                        <input type="text" className="text-box-editor width100" onChange={this.onChangeShift}
                               value={cstore.copyShift} placeholder="100" />
                    </div>
                    <div className="AgroAhoCopyFieldsComp-shiftStat">
                        {!cstore.fieldDouble && !cstore.cellDouble ? store_.trans['Numbers of copied cells'] :
                            store_.trans['Cell range']}: {cellInterval}
                    </div>
                </div>
                <div className="AgroAhoCopyFieldsComp-shiftMsg">
                    {store_.trans['The value specified in the input field above will be added to the cell numbers of all copied fields.']}
                </div>
                <div className={classNames("AgroAhoCopyFieldsComp-cellDoubleCover", {
                    "AgroAhoCopyFieldsComp-hidden": !cstore.fieldDouble && !cstore.cellDouble})}>
                    <div className="AgroAhoCopyFieldsComp-cellDoubleTitle">
                        {cstore.fieldDouble ?
                            TranslateUtils.format(store_.trans['The ASA map {0} already has the following fields:'], mapName) :
                            TranslateUtils.format(store_.trans['The ASA map {0} already has the following cell numbers:'], mapName)}
                    </div>
                    <div className="AgroAhoCopyFieldsComp-cellDoubleList">{cstore.fieldDouble ? cstore.fieldDouble
                        : cstore.cellDouble}</div>
                </div>

            </div>}
            <div className="AgroAhoCopyFieldsComp-line" />
            <div className="AgroAhoCopyFieldsComp-bottom">
                <button className="left-sidebar-cancel large-button button-jump AgroAhoCopyFieldsComp-button text-next-2" onClick={this.onClickCancel}>
                    {store.trans.Cancel}
                </button>
                <button className={classNames(
                    "left-sidebar-active large-button AgroAhoCopyFieldsComp-button text-next-2", {
                        "button-jump": this.canSave(),
                        "AgroAhoCopyFieldsComp-disabled": !this.canSave(),
                        "AgroAhoCopyFieldsComp-hidden": !!cstore.fieldDouble
                    })} onClick={this.onClickOk} >
                    {store_.trans.Copy}
                </button>
            </div>

        </div>;
    }
}
