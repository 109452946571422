import {CustomStore} from "./CustomStore";
import {action, observable, runInAction} from "mobx";
import {replaceAll, Utils} from "../helper/utils/Utils";
import {ISearchPropertiesRest, LeftPanelMode} from "./SearchStore";
import {Feature, FeatureCollection, Geometry} from "@turf/helpers";
import {SearchItemStore} from "./SearchItemStore";
import {LoadStatus} from "../helper/structs/LoadStatus";
import {isArray, isString} from "lodash-es";
import {fetchJson, fetchJsonPost} from "../helper/utils/FetchUtils";
import {Export2TypeOrders} from "./export/ExportStore";
import {ra} from "../helper/utils/mobxUtils";
import {IOrderLinkProps} from "../components/panels/Left/OrderList/OrderLink";
import {FavoriteExportStore} from "./export/FavoriteExportStore";

export enum LegacyOrderStatus {SUCCESS= 'SUCCESS', ERROR='ERROR', WAIT='WAIT', DELETED='DELETED'}
export enum OrderStatus {WAIT='WAIT', STANDBY='STANDBY', RUN='RUN', DONE='DONE', PAUSE='PAUSE',
                         CANCELED='CANCELED', CANCEL='CANCEL', FATAL='FATAL' }
export enum OrderResult {FATAL='FATAL', SUCCESS='SUCCESS', ERRORS='ERRORS'}


export interface IOrder{
    order_type: string
    ctime: string//"2020-09-19   16:18:38"
    email: string//"potanin.michael@gmail.com"
    etime: string//"2020-09-19   16:53:58"
    order_id: string//207
    name: string//"test_new33"
    status: OrderStatus //(LegacyOrderStatus | OrderStatus)//"SUCCESS"
    result: OrderResult
    can_download: boolean
}

interface ILegacyOrderInfo{
    email: string,
    ctime: string,
    etime: string,
    name: string,
    order_id: number,
    params: IOrderInfoParams,
    status: string,
    task: string,
    user_id: any,
    order_name: string,
    order_type: ORDER_TYPE,
}

interface IOrderInfo {
    order_id: number,
    name: string,
    app: string,
    type: ORDER_TYPE,
    login: string,
    email: string,
    priority: string,
    ctime: string, //"2022-04-12T09:42:34.898174"
    stime: string, 
    etime: string,//"2022-04-12 10:08:26.225121"
    date_start: string,
    date_end: string,

    status: OrderStatus,
    progress: string,
    result: OrderResult,
    n_errors: number,
    report: {"text": "", "errors": []},
    user_data: any,
    operator: any, 
    params: IS1OrderParams | IDailyMosaicOrderParams | ICropMaskOrderParams | IModisOrderParams, // | other
    events: any[]
}

export enum ORDER_TYPE{
    scene_mosaic = 'scene_mosaic',
    scene_separate = 'scene_separate',
    scene_tile = 'scene_tile',
    s1_radar = 's1_radar',
    crop_mask = 'crop_mask',
    daily_mosaic = 'daily_mosaic',
    landsat_fusion = 'landsat_fusion',
    s1_data = 's1_data',
    daily_mosaic_beta = 'daily_mosaic_beta',
    modis_data = 'modis_data',
    mosaic = 'mosaic',
    fields_mgrs = 'fields_mgrs',
}
export interface IOrderInfoParams{
    mosaic: number,
    products: string[],
    filename: string,
    geom_id: number,
    region: any,
    order_type: ORDER_TYPE,
    scene_ids: string[],
    scene_filter: any,
    fit: number,
    apply_cutline: number,
    albedo:number;
    combine_products: number;
    daily_mosaic: number;

    date0: string, //"2020-03-01",
    date1: string, // "2020-08-31"
    polars: ("VH" | "VV")[],

    beg: string,
    end: string,
    train_ratio: number,
    tree_cnt: number,
    tree_depth: number,
    grid_cells: string[],
    ref_ds: number,
}
export interface IS1OrderParams {
    date_start: string, //"2022-03-19"
    date_end: string, //"2022-03-19"
    polars: ("VH" | "VV")[],
    region: string,
    multi_temporal_filter: boolean,
    orbits: number[]
}
export interface IModisOrderParams {
    date_start: string, //"2022-03-19"
    date_end: string, //"2022-03-19"
    region: string,
    products: string[],
}
export interface IDailyMosaicOrderParams {
    scene_ids: string[],
    sat: ("S2" | "LS8+"),
    date_start: string, //"2022-03-19"
    date_end: string, //"2022-03-19"
    region: string,
    products: string[],
    albedo: boolean,
    combine_products: boolean,
    daily_mosaic: boolean,
    apply_cutline: boolean
}
export interface ICropMaskOrderParams {
    beg: string, //"2022-03-01"
    email: string
    end: string, //"2022-07-10"
    grid_cells: string[],
    name: string,
    order_type: string,
    ref_ds: number,
    train_ratio: number,
    tree_cnt: number,
    tree_depth: number
}

export class OrderListStore extends CustomStore{
    class(): string {return "OrderListStore";}

    @observable loading: boolean = false;
    @observable loaded: boolean = false;
    @observable orders: IOrder[] = [];

    @action
    doLoadList(){
        let url = "/api/proc_proxy/order/list";
        this.loading = true;
        fetchJson(url)
            .then( value => {
                this.setOrder(value);
            })
            .catch(reason => {
                this.setError(reason);
            });
    }
    @action
    private setOrder(value: IOrder[]){
        this.orders = value as IOrder[];
        this.orders = this.orders.slice().sort((a,b)=>{
            return ((new Date(b.ctime)).getTime() - (new Date(a.ctime)).getTime());
        });
        this.loading = false;
        this.loaded = true;
    }
    @action
    private setError(reason: string){
        this.loading = false;
        this.loaded = true;
        this.root.searchPanel.favoriteList.searchState = LoadStatus.ready;
        this.root.addError(Utils.getErrorString(reason));
    }

    @action
    async loadOrder(order: IOrderLinkProps){
        try {
            // let url = order.is_legacy? '/api/order_proc/order?' : '/api/proc_proxy/order?view=1&';
            // url += 'order_id=' + encodeURIComponent(order.order_id);
            let url = `/api/proc_proxy/order/${order.order_id}`;
            let fc: FeatureCollection;
            let store = this.root;
            store.searchPanel.favoriteList.searchState = LoadStatus.loading;
            let ths = this;
            let value = await fetchJson(url);
            runInAction(async () => {
                if (order.is_legacy)
                    await this.loadLegacyOrder(value as ILegacyOrderInfo);
                else
                    await this.loadNewOrder(value as IOrderInfo);
            });
        }catch (e) {
            this.root.addError(e);
        }
    }

    private loadRegion(oi: IOrderInfo){
        let p = oi.params as IS1OrderParams;
        //костыль с подменой ковычек
        let region = isString(p.region)? JSON.parse(replaceAll(p.region,"'", '"')) : null;
        //let region = isString(p.region)? JSON.parse(p.region) : null;
        this.setGeometry(region, oi.name);
    }
    @action
    private async loadNewOrder(oi: IOrderInfo) {
        let store = this.root;

        let closeAllAndSwitchTo = function(mode : LeftPanelMode) {
            store.searchPanel.closeAllLeftPanels();
            store.searchPanel.switchPanel(mode);
        }

        let openFav = (fc: FeatureCollection, scene_ids: string[])=>{
            this.setFavList(fc, scene_ids);
            closeAllAndSwitchTo(LeftPanelMode.favorite);
        };
        if (oi.type == ORDER_TYPE.fields_mgrs){
            this.setCommonInfo(oi);
            store.exportStore.fieldsDelineation.loadInfo(oi.params as any);
            store.exportStore.export2TypeOrders = Export2TypeOrders.FieldsDelineation;
            closeAllAndSwitchTo(LeftPanelMode.orders);
        }
        if (oi.type == ORDER_TYPE.modis_data) {
            let p = oi.params as IModisOrderParams;
            //костыль с подменой ковычек
            let region = isString(p.region)? JSON.parse(replaceAll(p.region,"'", '"')) : null;
            this.setGeometry(region, oi.name);
            this.setCommonInfo(oi);
            store.searchPanel.filterDate.begin = new Date(p.date_start);
            store.searchPanel.filterDate.end = new Date(p.date_end);
            store.exportStore.modis.loadOrder(p);
            store.exportStore.export2TypeOrders = Export2TypeOrders.Modis;
            closeAllAndSwitchTo(LeftPanelMode.orders);
        }
        if (oi.type == ORDER_TYPE.s1_data) {
            let p = oi.params as IS1OrderParams;
            this.loadRegion(oi);
            this.setCommonInfo(oi);
            store.exportStore.activeSensorDay.polarizationTypeVV = (p.polars.lastIndexOf("VV") >= 0);
            store.exportStore.activeSensorDay.polarizationTypeVH = (p.polars.lastIndexOf("VH") >= 0);
            store.exportStore.activeSensorDay.multitimeFiltering = (p.multi_temporal_filter);
            store.exportStore.activeSensorDay.orbits = (p.orbits);
            if (p.orbits) store.exportStore.activeSensorDay.orbitsText = p.orbits.join();
            store.searchPanel.filterDate.begin = new Date(p.date_start);
            store.searchPanel.filterDate.end = new Date(p.date_end);
            store.exportStore.export2TypeOrders = Export2TypeOrders.Sentinel1;
            closeAllAndSwitchTo(LeftPanelMode.orders);
            setTimeout(() =>{
                store.map.zoomToSearchObject();
            }, 500);
            return;
        }
        if (oi.type == ORDER_TYPE.mosaic) {
            let p = oi.params as IDailyMosaicOrderParams;
            this.setCommonInfo(oi);
            store.exportStore.mosaic = true;
            store.exportStore.fusion = false;

            this.loadBands(p.products, store.exportStore.favoriteExport);
            this.loadGeometry(oi);
            //this.setOrderInfo(oi);
            let filter ={"scene_id": p.scene_ids};
            let value2 = await fetchJsonPost("/api/satmeta", {"filter": JSON.stringify(filter)});
            ra(()=> {
                openFav(value2, p.scene_ids);
                store.searchPanel.closeAllLeftPanels();
                store.searchPanel.switchPanel(LeftPanelMode.favorite);
            });

        }
        if (oi.type == ORDER_TYPE.daily_mosaic) {
            let p = oi.params as IDailyMosaicOrderParams;
            store.exportStore.favoriteExport.apply_cutline = p.apply_cutline;
            if (!isArray(p.scene_ids) || p.scene_ids.length == 0) {

                this.setDailyMosaicSatOrder(oi);
                return;

            } else {
                this.setDailyMosaicOrder(oi);
                let filter ={"scene_id": p.scene_ids};
                let value2 = await fetchJsonPost("/api/satmeta", {"filter": JSON.stringify(filter)});
                runInAction(()=> openFav(value2, p.scene_ids));
            }    
        }
        if (oi.type == ORDER_TYPE.crop_mask) {
            let p = oi.params as ICropMaskOrderParams;
            await store.exportStore.cropMaskStore.loadReference(p.ref_ds);
                this.setCommonInfo(oi);
                store.exportStore.projectName = p.name;
                store.exportStore.email = oi.email;
                store.searchPanel.filterDate.begin = new Date(p.beg);
                store.searchPanel.filterDate.end = new Date(p.end);
                store.exportStore.cropMaskStore.trainRatio = p.train_ratio;
                store.exportStore.cropMaskStore.maxDepth = p.tree_depth;
                store.exportStore.cropMaskStore.randomForestTrees = p.tree_cnt;
                if (p.grid_cells) {
                    store.exportStore.cropMaskStore.sentinel2_grid_cells = p.grid_cells.join(",");
                }
                store.exportStore.export2TypeOrders = Export2TypeOrders.Cropmask;
                store.searchPanel.closeAllLeftPanels();
                store.searchPanel.switchPanel(LeftPanelMode.orders);
                setTimeout(() =>{
                    store.map.zoomToSearchObject();
                }, 500);

        }
    }

    private async loadLegacyOrder(oi: ILegacyOrderInfo) {
        let store = this.root;
        if (isString(oi.params.region)) oi.params.region = JSON.parse(oi.params.region);

        if (oi.order_type == ORDER_TYPE.crop_mask) {
            let ref_ds = oi.params.ref_ds;
            await store.exportStore.cropMaskStore.loadReference(ref_ds);
            ra(()=>{
                this.setOrderInfo(oi);
                store.exportStore.projectName = oi.order_name;
                store.exportStore.email = oi.email;
                store.searchPanel.filterDate.begin = new Date(oi.params.beg);
                store.searchPanel.filterDate.end = new Date(oi.params.end);
                store.exportStore.cropMaskStore.trainRatio = oi.params.train_ratio;
                store.exportStore.cropMaskStore.maxDepth = oi.params.tree_depth;
                store.exportStore.cropMaskStore.randomForestTrees = oi.params.tree_cnt;
                if (oi.params.grid_cells) {
                    store.exportStore.cropMaskStore.sentinel2_grid_cells = oi.params.grid_cells.join(",");
                }
                store.exportStore.export2TypeOrders = Export2TypeOrders.Cropmask;
                store.searchPanel.closeAllLeftPanels();
                store.searchPanel.switchPanel(LeftPanelMode.orders);
                setTimeout(() =>{
                    store.map.zoomToSearchObject();
                }, 500);

            });
            return;
        }
        if (oi.order_type == ORDER_TYPE.s1_radar) {
            this.setOrderInfo(oi);
            store.exportStore.projectName = oi.order_name;
            store.exportStore.email = oi.email;
            store.exportStore.activeSensorDay.polarizationTypeVV = (oi.params.polars.lastIndexOf("VV") >= 0);
            store.exportStore.activeSensorDay.polarizationTypeVH = (oi.params.polars.lastIndexOf("VH") >= 0);
            store.searchPanel.filterDate.begin = new Date(oi.params.date0);
            store.searchPanel.filterDate.end = new Date(oi.params.date1);
            store.exportStore.export2TypeOrders = Export2TypeOrders.Sentinel1;
            store.searchPanel.closeAllLeftPanels();
            store.searchPanel.switchPanel(LeftPanelMode.orders);
            setTimeout(() =>{
                store.map.zoomToSearchObject();
            }, 500);

            return;
        }

        if (!isArray(oi.params.scene_ids) || oi.params.scene_ids.length == 0) {
            this.setFavList(null, null);
            this.setOrderInfo(oi);
            store.searchPanel.closeAllLeftPanels();
            store.searchPanel.switchPanel(LeftPanelMode.favorite);
        } else {
            this.setOrderInfo(oi);
            let filter ={"scene_id": oi.params.scene_ids};
            let value2 = await fetchJsonPost("/api/satmeta", {"filter": JSON.stringify(filter)});
            runInAction(()=>{
                this.setFavList(value2, oi.params.scene_ids);
                store.searchPanel.closeAllLeftPanels();
                store.searchPanel.switchPanel(LeftPanelMode.favorite);
            });
        }
    }

    public async sendCancelOrder(order_id: any){
        let url = `/api/proc_proxy/order/${order_id}/cancel`;
        await fetchJsonPost(url);
    }
    @action
    private setCommonInfo(oi: IOrderInfo) {
        let store = this.root;
        store.exportStore.projectName = oi.name;
        store.exportStore.email = oi.email;
    }

    @action
    private setDailyMosaicSatOrder(oi: IOrderInfo) {
        this.setCommonInfo(oi);
        this.loadRegion(oi);
        let store = this.root;
        let p = oi.params as IDailyMosaicOrderParams;
        let s: FavoriteExportStore = null;
        if (p.sat == "LS8+"){
            s = store.exportStore.landsatExport;
            store.exportStore.export2TypeOrders = Export2TypeOrders.Landsat;
        }
        if (p.sat == "S2"){
            s = store.exportStore.sentinel2Export;
            store.exportStore.export2TypeOrders = Export2TypeOrders.Sentinel2;
        }
        if (s != null) {
            s.albedo = p.albedo;
            s.apply_cutline = p.apply_cutline;
            s.albedo = p.albedo;
            s.combine_products = p.combine_products;
            s.mergeScenes = !!p.daily_mosaic;
            store.exportStore.period.begin = new Date(p.date_start);
            store.exportStore.period.end = new Date(p.date_end);
            this.loadBands(p.products, s);
        }
        store.searchPanel.switchPanel(LeftPanelMode.orders);
    }

    @action
    private setDailyMosaicOrder(oi: IOrderInfo) {
        let store = this.root;
        let p = oi.params as IDailyMosaicOrderParams;
        this.setCommonInfo(oi);
        store.exportStore.mosaic = false; //oi.params.order_type == ORDER_TYPE.scene_mosaic;
        store.exportStore.fusion = false; //oi.params.order_type == ORDER_TYPE.landsat_fusion;
        //store.exportStore.Adjust_pixel_values = (oi.params.fit >= 1);
        store.exportStore.favoriteExport.apply_cutline = p.apply_cutline;
        store.exportStore.favoriteExport.albedo = p.albedo;
        store.exportStore.favoriteExport.combine_products = p.combine_products;
        store.exportStore.favoriteExport.mergeScenes = !!p.daily_mosaic;

        this.loadBands(p.products, store.exportStore.favoriteExport);
        this.loadGeometry(oi);
    }
    loadGeometry(oi: IOrderInfo) {
        let p = oi.params as IDailyMosaicOrderParams;
        let region = isString(p.region)? JSON.parse(p.region) : null;
        this.setGeometry(region, oi.name);
    }
    private loadBands(products: string[], store: FavoriteExportStore){
        let bands = products;
        let veginds = products;

        store.cleatBandValue();
        if (isArray(bands)) {
            bands.forEach(a => {
                store.setBandValue(a, true);
            });
        }

        store.clearIndexValue();
        if (isArray(veginds)) {
            veginds.forEach(a => {
                store.setIndexValue(a, true);
            });
        }

    }

    @action
    private setOrderInfo(oi: ILegacyOrderInfo){
        let store = this.root;
        store.exportStore.email = oi.email;
        store.exportStore.projectName = oi.name;
        store.exportStore.mosaic = oi.params.order_type == ORDER_TYPE.scene_mosaic;
        store.exportStore.fusion = oi.params.order_type == ORDER_TYPE.landsat_fusion;

        store.exportStore.favoriteExport.apply_cutline = (oi.params.apply_cutline == 1);
        store.exportStore.favoriteExport.albedo = (oi.params.albedo == 1);
        store.exportStore.favoriteExport.combine_products = (oi.params.combine_products == 1);
        store.exportStore.favoriteExport.mergeScenes = (oi.params.daily_mosaic == 1);

        let geomName = "";
        //geomName = oi.border_file;
        if (!isString(geomName)) geomName = ""
        let bands = oi.params.products;
        let veginds = oi.params.products;

        store.exportStore.favoriteExport.cleatBandValue();
        if (isArray(bands)) {
            bands.forEach(a => {
                store.exportStore.favoriteExport.setBandValue(a, true);
            });
        }

        store.exportStore.favoriteExport.clearIndexValue();
        if (isArray(veginds)) {
            veginds.forEach(a => {
                store.exportStore.favoriteExport.setIndexValue(a, true);
            });
        }
        this.setGeometry(oi.params.region, oi.params.filename);
    }

    @action
    setGeometry(region: any, filename: string) {
        let map = this.root.map;
        if (region != null){
            if (region.type === "FeatureCollection"){
                map.searchObject.content = region;
                map.searchObject.name = filename;
            }else {
                map.searchObject.content = {
                    features: [{
                        geometry: region,
                        id: "1",
                        properties: {name: "1111"}, type: "Feature"
                    }],
                    type: 'FeatureCollection'
                };
                map.searchObject.name = filename;
            }
        }
    }

    @action
    private setFavList(fc: FeatureCollection, scene_ids: string[]){
        let store = this.root;
        //store.searchPanel.favoriteList.groups = [];

        let arrSi: SearchItemStore[] = [];
        store.searchPanel.favoriteList.searchState = LoadStatus.ready;
        if(fc == null) {
            return;
        }
        scene_ids.forEach(scene_id => {
            let f = fc.features.find(f => (f as Feature<Geometry, ISearchPropertiesRest>).properties.scene_id == scene_id);
            if (f == null) throw `Scene ${scene_id} is not found`;
            let sp: SearchItemStore = new SearchItemStore(store.searchPanel.favoriteList);
            sp.feature = f as Feature<Geometry, ISearchPropertiesRest>;
            sp.selected = true;
            arrSi.push(sp);
        });
        store.searchPanel.favoriteList.syncAddSceneItem(arrSi.reverse());
        setTimeout(() =>{
            store.map.zoomToSearchObject();
        }, 500);

    }
}
