import {Translate} from "../../../app/helper/lang/Translate";
import {MeteoTranslate_en} from "./MeteoTranslate_en";

export class MeteoTranslate_ru extends MeteoTranslate_en{
    "Meteo" = "Метео"
    "Weather map" = "Метео картограммы"
    "Draught on date" = "Наличие засухи на дату"
    "Draught days per season" = "Количество дней с засухой на сезон"
    "In months" = "За месяцы"
    "Period" = "Период"
    "Date of analysis" = "Дата анализа"
    "10 days before the analysis date" = "10 дней до даты анализа"
    "30 days before the analysis date" = "30 дней до даты анализа"
    "The assessment..." = "Оценка наличия засухи на выбранную дату выполняется на основе расчетов различных индексов по совокупным данным за 30-дневный период, предшествующий выбранной дате"
    "Arbitrary period" = "Произвольный период"
    "Create report" = "Создать отчет"
}