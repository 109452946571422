import React from "react";
import {IProductItemComp} from "./ProductItemComp";
import {observer} from "mobx-react";
import {ProductItemIndexBandComp} from "./ProductItemIndexBandComp";
import {ProductItemPropComp} from "./ProductItemPropComp";
import {InfoSVG} from "../../../icons/InfoSVG";
import {action, runInAction} from "mobx";
import "./ProductSettingsComp.scss";
const ProductSettingsComp: React.FC<IProductItemComp> = observer(
    ({store, item, productCode}) => {
        let cur = productCode.productCode == item.prod_name;
        const onClickBack = action(
            (e: React.MouseEvent<HTMLDivElement>) => {
                runInAction(() => {
                    item.expand = !item.expand;
                });
                e.stopPropagation();
            }
        );
        return (
            <>
                <div className='ProductSettingsComp-header'>
                    <div onClick={onClickBack} className='pointer'>
                        <svg
                            width='26'
                            height='26'
                            viewBox='0 0 26 26'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <g clipPath='url(#clip0_260_10625)'>
                                <path
                                    d='M19.5625 13H6.4375'
                                    stroke='#4DB6BC'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M10.8125 17.375L6.4375 13L10.8125 8.625'
                                    stroke='#4DB6BC'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </g>
                            <circle
                                cx='13'
                                cy='13'
                                r='12.5'
                                stroke='#4DB6BC'
                            />
                            <defs>
                                <clipPath id='clip0_260_10625'>
                                    <rect
                                        width='14'
                                        height='14'
                                        fill='white'
                                        transform='translate(6 6)'
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className='popup-ndvi-in__view-row-title flex-fit-item popup-ndvi-in__view-row_border'>
                        <div className='popup-ndvi-in__view-row-title-row1'>
                            {item.productConfig.full_name}
                            {cur && <InfoSVG className='text-next' />}
                        </div>
                        <div className='popup-ndvi-in__view-row-title-row2'>
                            {item.productConfig.formulae}
                        </div>
                    </div>
                    <div className='popup-ndvi-in__view-row-img-div'>
                        <img
                            src={item.productConfig.icon}
                            className='popup-ndvi-in__view-row-img'
                        />
                    </div>
                </div>
                {item.expand && item.productConfig.type == "RGB" && (
                    <ProductItemPropComp
                        store={store}
                        item={item.editor}
                        productCode={productCode}
                    />
                )}
                {item.expand &&
                    item.productConfig.type == "index" && (
                        <ProductItemIndexBandComp
                            store={store}
                            item={item.editor}
                            productCode={productCode}
                        />
                    )}
            </>
        );
    }
);

export default ProductSettingsComp;
