import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {Agro2CreateField} from "./Agro2CreateField";

@observer
export class Agro2FieldMultiEditComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }


    render() {
        let store = this.props.store;
        return <div className="popup-pref active-flex">
                <div className="Agro2CreateField-multiEditPanel">
                    <Agro2CreateField store={store} />
                </div>
        </div>;
    }
}
