import {isFunction} from "lodash-es";

export class SyncUtils{
    //Синхронизирует два массива, по возможности используя элементы из старого массива
    //Для сравнения используется функция id, возвращает новый массив
    static syncArraysByIdFunc<T>(
            oldArray: T[],
            newArray: T[],
            getIdFunc: (item: T)=> string,
        ): T[]{
        let dicOld: Record<string, T> = {};
        oldArray.forEach(a => {
            dicOld[getIdFunc(a)] = a;
        });
        let resArray: T[] = [];
        newArray.forEach(a => {
            let id: string = getIdFunc(a);
            if (dicOld[id]){
                resArray.push(dicOld[id]);
            }else resArray.push(a);
        });
        return resArray;
    }

    static compareArrays<T>(array1: T[], array2: T[], isEqual: (t1: T, t2: T)=> boolean): boolean{
        if (array1.length != array2.length) return false;
        for(let i = 0; i < array1.length; i++){
            if (!isEqual(array1[i], array2[i])) return false;
        }
        return true;
    }
    static compareArraysByIdFunc<T>(array1: T[], array2: T[], getIdFunc: (item: T)=> string): boolean{
        if (array1.length != array2.length) return false;
        for(let i = 0; i < array1.length; i++){
            if (getIdFunc(array1[i]) != getIdFunc(array2[i])) return false;
        }
        return true;
    }

    //Патерн синхронизации данных сервера и их представления с интерфейсом с сохранением старого состояния
    //по возможности не приводит к изменениям никаких объектов
    //Возвращает true - если не поменялся, false - если поменялся массив
    static syncDataAndState<T, D>(
        oldArrayState: T[],
        arrayData: D[],
        getIdState: (oldState: T) => string,
        getIdData: (newDataItem: D) => string,
        createStateItem: (newDataItem: D) => T,
        updateInner?: (oldState: T, newDataItem: D) => void
    ): boolean {
        let r: T[] = [];
        let oldDic: Record<string, T> = {};
        oldArrayState.forEach(a => oldDic[getIdState(a)] = a);
        arrayData.forEach(d => {
            let id = getIdData(d);
            let oldS = oldDic[id];
            if (oldS != null){
                if (isFunction(updateInner)) updateInner(oldS, d);
                r.push(oldS);
                delete oldDic[id];
            }else{
                let newS = createStateItem(d);
                r.push(newS);
            }
        });
        //синхронизируем массивы
        let needReplace = false;
        if (r.length != oldArrayState.length){
            needReplace = true;
        }else{
            for(let i = 0; i < oldArrayState.length; i++){
                if (oldArrayState[i] != r[i]){
                    needReplace = true;
                    break;
                }
            }
        }
        if (needReplace){
            oldArrayState.splice(0, oldArrayState.length);
            for(let i = 0; i < r.length; i++){
                oldArrayState.push(r[i]);
            }
            return false;
        }
        return true;
    }

    static syncArraysByIdArray<T>(
        oldArray: T[],
        newIds: string[],
        getIdFunc: (item: T)=> string,
        createItem: (id: string, index: number) => T
    ): T[]{
        let dicOld: Record<string, T> = {};
        oldArray.forEach(a => {
            dicOld[getIdFunc(a)] = a;
        });
        let resArray: T[] = [];
        newIds.forEach((id, index) => {
            if (dicOld[id]){
                resArray.push(dicOld[id]);
            }else resArray.push(createItem(id, index));
        });
        return resArray;
    }

}