// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPanel_DownFiltersComp-GroupItem {
  padding: 7px 25px;
  display: flex;
}

.AdminPanel_DownFiltersComp-GroupItem:hover {
  background: #383F48;
}

.AdminPanel_DownFiltersComp-dropDown {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  min-width: 315px;
  color: #C5C5C5;
  padding-top: 15px;
  padding-bottom: 15px;
}

.AdminPanel_DownFiltersComp-subGroupTitle {
  padding: 7px 25px;
  font-weight: 600;
  font-size: 13px;
  background: #262B32;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/User/AdminPanel/AdminPanel_DownFiltersComp.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAGA,aAAA;AADF;;AAGA;EACE,mBAAA;AAAF;;AAEA;EACE,oDAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,oBAAA;AAAF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AACF","sourcesContent":[".AdminPanel_DownFiltersComp-GroupItem{\r\n  padding: 7px 25px;\r\n\r\n  //cursor: pointer;\r\n  display: flex;\r\n}\r\n.AdminPanel_DownFiltersComp-GroupItem:hover{\r\n  background: #383F48;\r\n}\r\n.AdminPanel_DownFiltersComp-dropDown{\r\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  border-radius: 10px;\r\n  font-weight: 400;\r\n  font-size: 13px;\r\n  line-height: 20px;\r\n\r\n  min-width: 315px;\r\n  color: #C5C5C5;\r\n  padding-top: 15px;\r\n  padding-bottom: 15px;\r\n}\r\n.AdminPanel_DownFiltersComp-subGroupTitle{\r\n  padding: 7px 25px;\r\n  font-weight: 600;\r\n  font-size: 13px;\r\n  background: #262B32;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
