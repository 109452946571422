import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";

export class PhotoFileItemStore extends CustomStore{
    name: string = null;
    size: number = null;
    date: number = null;

    @observable
    content: File = null;
    private _contentStr: string = null;



    lat: number = null;
    lon: number = null;
    photo_id: number = null;
    @observable
    version: number = null;

    was_visible: boolean = false;
    @observable
    tags_ids: number[] = [];

    @observable
    region_id: number = null;
    @observable
    contract_year: string = null;
    @observable
    farm_id: number = null;

    @observable
    selected: boolean = false;
    @observable
    status: LoadStatus = null;
    @observable
    errorLoading: boolean = false;
    @observable
    sendBytes: number = null;//уже отслано байт в сыром виде
    needSend: number = null;//надо отправить байт в сыром виде

    xhr: XMLHttpRequest = null;

    getImageContentStr(size: number = 110){
        if (this._contentStr != null) return this._contentStr;

        if (this.content != null) {
            this._contentStr = URL.createObjectURL(this.content);
            return this._contentStr;
        }
        return `/api/projects/${this.root.agro.projectName}/photo/image?photo_id=${this.photo_id}&width=${size}&version=${this.version}`;
    }

    resetBeforeUpload(){
        this.errorLoading = false;
        this.status = null;
        if (this.xhr != null){
            this.xhr.abort();
            this.xhr = null;
        }
        this.sendBytes = 0;
    }
}