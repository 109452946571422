import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoEditSvgProps {
    className?: string
}

export class AgroAhoEditSvg extends React.PureComponent<IAgroAhoEditSvgProps, undefined> {
    constructor(props: IAgroAhoEditSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.0875 3.0625L0.2625 9.8875C0.0875 10.0625 0 10.2375 0 10.5V13.125C0 13.65 0.35 14 0.875 14H3.5C3.7625 14 3.9375 13.9125 4.1125 13.7375L10.9375 6.9125L7.0875 3.0625Z"/>
            <path d="M13.7375 2.8875L11.1125 0.2625C10.7625 -0.0875 10.2375 -0.0875 9.8875 0.2625L8.3125 1.8375L12.1625 5.6875L13.7375 4.1125C14.0875 3.7625 14.0875 3.2375 13.7375 2.8875Z"/>
        </svg>;
    }
}