import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";

import './UploadComp.scss';
import {Utils} from "../../../../../helper/utils/Utils";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {action, observable} from "mobx";
import {CheckboxMiniComp, CheckboxSize, TriState} from "../../../../Common/CheckboxMiniComp";
import classNames from "classnames";
import {CoordinateStringFormat} from "../../../../../helper/utils/CoordinateStringFormat";
import {ProgressUploadComp} from "./ProgressUploadComp";
import {PhotoFileItemStore} from "../../../../../store/photo/PhotoFileItemStore";
import {isFunction} from "lodash-es";
import {TagsForPhoto} from "./TagsForPhoto";
import {ra} from "../../../../../helper/utils/mobxUtils";
import {ProjType} from "../../../../../store/user/UserStore";
import {MyHint} from "../../../../Common/MyHint";

export interface IUploadItemComp extends IStoreProps{
    item: PhotoFileItemStore;
    onDeleteTag?: (photo_id: number, tag_id: number)=>void;
    onZoomToPhoto?: (item: PhotoFileItemStore)=>void;
}

@observer
export class UploadItemComp extends React.Component<IUploadItemComp, undefined> {
    constructor(props: IUploadItemComp) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClickSelect(){
        this.props.item.selected = !this.props.item.selected;
    }

    @action
    onDeleteTag(photo_id: number, tag_id: number){
        if (isFunction(this.props.onDeleteTag)) this.props.onDeleteTag(photo_id, tag_id);
    }

    @observable
    showHint: boolean = false;

    @action
    onClickImage(){
        this.showHint = !this.showHint;
    }

    timerHide: any = null;
    timerShow: any = null;

    @action
    timeoutExitFocus(){
        this.clearTimerHide();
        this.showHint = false;
    }

    clearTimerHide(){
        if (this.timerHide != null) clearTimeout(this.timerHide);
        this.timerHide = null;
    }
    startTimerHide(){
        this.clearTimerShow();
        this.clearTimerHide();
        this.timerHide = setTimeout(this.timeoutExitFocus, 200);
    }

    clearTimerShow(){
        if (this.timerShow != null) clearTimeout(this.timerShow);
        this.timerShow = null;
    }
    startTimerShow(){
        this.clearTimerHide();
        this.clearTimerShow();
        this.timerShow = setTimeout(()=>{ra(()=>{this.showHint = true; this.clearTimerShow();})}, 700);
    }

    @action
    onMouseEnterDiv(){
        this.startTimerShow();
    }
    onMouseLeaveDiv(){
        this.startTimerHide();
    }

    clickZoomPhoto(){
        if (isFunction(this.props.onZoomToPhoto)) this.props.onZoomToPhoto(this.props.item);
    }

    render() {
        let store = this.props.store;
        let a = this.props.item;
        let hasCoord = (a.lat != null && a.lon != null);
        let fname = a.name;
        if (fname.endsWith(".jpg") || fname.endsWith(".png")) fname = fname.substr(0, fname.length - 4);
        else if (fname.endsWith(".jpeg")) fname = fname.substr(0, fname.length - 5);


        return <div className="flex-columns UploadComp-item-m">
            <div className="UploadItemComp-checkboxItem-m">
                <CheckboxMiniComp state={a.selected?TriState.check:TriState.uncheck} size={CheckboxSize.standart} onClick={this.onClickSelect} />
            </div>
            <div className={classNames("UploadComp-image_div",{"red_border": a.errorLoading})}
                 onMouseEnter={this.onMouseEnterDiv} onMouseLeave={this.onMouseLeaveDiv}>
                <MyHint
                    mouseEnterDelay={0.5}
                    overlayInnerStyle={{width: "350px",
                        height: "250px",
                        maxHeight: "max-content",
                        maxWidth: "max-content",
                        boxSizing: "content-box"}}
                    overlay={
                            <img className="UploadComp-image-hint" src={a.getImageContentStr(600)}  />
                    }>
                    <img className="UploadComp-image-m" src={a.getImageContentStr()} onClick={this.onClickImage} />
                </MyHint>
                {a.errorLoading &&
                    <svg width="17" height="17" className="UploadComp-image-topLeftIcon" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="17" height="17" rx="8.5" fill="#2B3138"/>
                    <path d="M13 5.28545L11.7145 4L8.5 7.21455L5.28545 4L4 5.28545L7.21455 8.5L4 11.7145L5.28545 13L8.5 9.78545L11.7145 13L13 11.7145L9.78545 8.5L13 5.28545Z" fill="#E94F4F"/>
                </svg>}
                {a.status == LoadStatus.ready &&
                <svg width="17" height="17" className="UploadComp-image-topLeftIcon" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="17" height="17" rx="8.5" fill="#2B3138"/>
                    <path d="M13.1752 6.37495L11.6877 4.88745L7.4377 9.13745L5.3127 7.01245L3.8252 8.49995L7.4377 12.1125L13.1752 6.37495Z" fill="#4DB6BC"/>
                </svg>}
                {a.status == LoadStatus.loading &&
                    <ProgressUploadComp  className="UploadComp-image-progress" progress={a.sendBytes / a.needSend
                    } />
                }
                <div className="UploadItemComp-image-label">{fname}</div>
                {/*this.showHint && <ContextMenuCommonComp className="UploadComp-image-hintDiv" autoCornerWidth={560} autoCornerHeight={360} indentHorizontal={-50}
                                                         popupCorner={PopupCorner.rightTop} direction={PopupDirection.vertical} >
                    <div className="UploadComp-image-hintDiv2" onMouseEnter={this.onMouseEnterDiv} onMouseLeave={this.onMouseLeaveDiv}>
                        <img className="UploadComp-image-hint" src={a.getImageContentStr(600)}  />
                    </div>
                </ContextMenuCommonComp>*/}
            </div>
            <div className="UploadItemComp-properiesItem-m">
                <div className="flex-columns UploadItemComp-properiesItemFile-m">
                    <div className="flex-align-items-center flex-columns">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0625 1.875H12.1875V0H9.375V1.875H5.625V0H2.8125V1.875H0.9375C0.42 1.875 0 2.295 0 2.8125V14.0625C0 14.58 0.42 15 0.9375 15H14.0625C14.58 15 15 14.58 15 14.0625V2.8125C15 2.295 14.58 1.875 14.0625 1.875ZM13.125 13.125H1.875V6.5625H13.125V13.125Z" fill="#3A7BAF"/>
                        </svg>
                        <span className="text-next">{((new Date(a.date)).toLocaleString())}</span>
                    </div>
                    <div className="flex-align-items-center flex-columns text-next-2">
                        <svg width="16" height="16" viewBox="0 0 16 16" className={classNames("dark-blue-fill",{"red-fill": !hasCoord})} xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2347 11.026C11.9267 11.4494 11.6153 11.8507 11.31 12.2227C13.2887 12.5087 14.474 13.006 14.6573 13.3327C14.388 13.814 11.95 14.666 8 14.666C3.97133 14.666 1.51533 13.7794 1.32933 13.3607C1.45667 13.0347 2.652 12.516 4.68867 12.222C4.384 11.85 4.07267 11.4487 3.76467 11.0254C1.734 11.3967 0 12.098 0 13.3334C0 15.4387 5.02867 16 8 16C10.9713 16 16 15.4387 16 13.3334C16 12.098 14.266 11.3967 12.2347 11.026Z" fill="#3A7BAF"/>
                            <path d="M7.99984 0C5.37784 0 2.6665 1.99467 2.6665 5.33333C2.6665 8.4 7.02917 12.8847 7.5265 13.3867C7.65184 13.5127 7.82184 13.5833 7.99984 13.5833C8.17784 13.5833 8.34784 13.5127 8.47317 13.3867C8.9705 12.8847 13.3332 8.4 13.3332 5.33333C13.3332 1.99467 10.6218 0 7.99984 0ZM7.99984 6.66667C7.26317 6.66667 6.6665 6.07 6.6665 5.33333C6.6665 4.59667 7.26317 4 7.99984 4C8.7365 4 9.33317 4.59667 9.33317 5.33333C9.33317 6.07 8.7365 6.66667 7.99984 6.66667Z" fill="#3A7BAF"/>
                        </svg>
                        {hasCoord &&
                            <span className={classNames("text-next",{"underline-link pointer light-blue-text": isFunction(this.props.onZoomToPhoto)})} onClick={this.clickZoomPhoto}>{Utils.coosToString(a.lon, a.lat, CoordinateStringFormat.degMinSec)}</span>
                        }
                        {!hasCoord &&
                            <span className="text-next red">{store.trans["There is no georeference"]}</span>
                        }
                    </div>
                </div>
                {a.errorLoading && <div className="UploadItemComp-properiesItemTags-m flex-columns flex-align-items-center">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 12C7.4 12 7 11.6 7 11C7 10.4 7.4 10 8 10C8.6 10 9 10.4 9 11C9 11.6 8.6 12 8 12ZM9 9H7V4H9V9Z" fill="#E94F4F"/>
                    </svg>
                    <span className="red text-next">{store.trans["Loading error"]}</span>
                </div>}
                <TagsForPhoto item={this.props.item}
                              photo_id={this.props.item.photo_id}
                              tags={this.props.item.tags_ids}
                              store={store} onDeleteTag={this.onDeleteTag} />
            </div>

        </div>;
    }
}
