import * as React from 'react';
import {Suspense} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IA2FieldItemComp} from "./A2FieldItemComp";

const A2FieldFullCartComp = React.lazy(() => import('./A2FieldFullCartComp'));
@observer
export class A2FieldFullCartProxyComp extends React.Component<IA2FieldItemComp, undefined> {
    constructor(props: IA2FieldItemComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <Suspense fallback={<div style={{fontSize: "12px"}}>{store.trans.Downloading}...</div>}>
                <A2FieldFullCartComp store={store} field={this.props.field}/>
            </Suspense>;
    }
}
