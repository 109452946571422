import {CustomStore} from "../../../app/store/CustomStore";
import {observable} from "mobx";

export enum IndexBandExportPixel{
    p7='p7', p15 = 'p15', p30='p30'
}
export enum IndexBandExportGeometry{
    grid, zone
}
export enum IndexBandExportFormat{
    geojson, shp, isoXml
}
export class IndexBandExportStore extends CustomStore{
    @observable
    show: boolean = false;

    @observable
    pixel: IndexBandExportPixel = IndexBandExportPixel.p15;
    @observable
    zone: IndexBandExportGeometry = IndexBandExportGeometry.grid;
    @observable
    format: IndexBandExportFormat = IndexBandExportFormat.isoXml;
    @observable
    notExportInvisible: boolean = false;
    @observable
    splitByFields: boolean = true;
    @observable
    cutVisible: boolean = false;
    @observable
    intrWidth: number = 0;
    @observable
    filename: string = "";
    readonly filename_default = "export";


    @observable //Указать нормы внесения (кг/га)
    private _normaChecked: boolean = true;
    get normaChecked(): boolean { return this._normaChecked;}
    set normaChecked(value: boolean){
        this._normaChecked = value;
        if (value){
            this.notExportInvisible = false;
            this.cutVisible = false;
            this.splitByFields = true;
            this.format = IndexBandExportFormat.isoXml;
            this.zone = IndexBandExportGeometry.grid;
        }else{
            if (this.format == IndexBandExportFormat.isoXml) this.format = IndexBandExportFormat.shp;
        }
    }
    @observable
    normaBase: number = 100;
    @observable
    normaClasses: number[] = [];
    @observable
    fert_id: number = null;

    normaInit(classes: number){
        if (this.normaClasses.length != classes){
            this.normaClasses = [];
            for(let i = 0; i < classes; i++){
                this.normaClasses.push(0);
            }
        }
    }
}