import {CustomTool} from "./ContainerTools";
import {SuperStore} from "../../SuperStore";

export class CustomStoreTool extends CustomTool{
    constructor(storage: SuperStore, name: string) {
        super(storage.map.superTools.container, name);
        this._store = storage;
    }
    static readonly COMMON_PREFIX = "class_";
    private _store: SuperStore;

    get store(): SuperStore{
        return this._store;
    }
}
