// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPanel_CommentEditComp {
  width: 415px;
  position: relative;
  background: #2B3138;
  border: 1px solid #3E4751;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 15px 25px 15px;
  display: flex;
  flex-direction: column;
  color: #C5C5C5;
}

.AdminPanel_CommentEditComp-header {
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
}

.AdminPanel_CommentEditComp-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 8px;
  padding-top: 15px;
  padding-bottom: 8px;
}

.AdminPanel_CommentEditComp-close {
  margin: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.AdminPanel_CommentEditComp-footer {
  padding-top: 25px;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/User/AdminPanel/AdminPanel_CommentEditComp.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,2CAAA;EACA,mBAAA;EACA,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAGF;;AAAA;EACE,YAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AAGF;;AADA;EACE,iBAAA;EACA,aAAA;EACA,yBAAA;AAIF","sourcesContent":[".AdminPanel_CommentEditComp{\r\n  width: 415px;\r\n  position: relative;\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\r\n  border-radius: 10px;\r\n  padding: 15px 15px 25px 15px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  color: #C5C5C5;\r\n}\r\n.AdminPanel_CommentEditComp-header {\r\n  font-weight: 600;\r\n  font-size: 13px;\r\n  line-height: 24px;\r\n}\r\n.AdminPanel_CommentEditComp-label {\r\n  font-weight: 400;\r\n  font-size: 10px;\r\n  line-height: 8px;\r\n  padding-top: 15px;\r\n  padding-bottom: 8px;\r\n}\r\n\r\n.AdminPanel_CommentEditComp-close{\r\n  margin: 16px;\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n}\r\n.AdminPanel_CommentEditComp-footer{\r\n  padding-top: 25px;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
