// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhotoTagEditorTag-div {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #C5C5C5;
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 46px;
}

.PhotoTagEditorTag-div:hover {
  background: #22282F;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/PhotoPanel/PhotoTagEditor/PhotoTagEditorTag.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;AACF;;AACA;EACE,mBAAA;AAEF","sourcesContent":[".PhotoTagEditorTag-div{\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  color: #C5C5C5;\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 6px 16px 6px 46px;\r\n}\r\n.PhotoTagEditorTag-div:hover{\r\n  background: #22282F;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
