import * as React from "react";
import {CSSProperties, ReactNode} from "react";
import autoBindReact from "auto-bind/react";
import {isFunction} from "lodash-es";
import classNames from "classnames";

export enum TriState {
    check = "checked",
    uncheck = "unchecked",
    partial = "partial"
}
export enum CheckboxSize {
    mini = "mini",
    standart = "standart", //квадрат в квадрате
    switcher = "switcher",
    grayV = "grayV"
}
export enum CheckboxShape {
    square = "square",
    circle = "circle" // новая форма
}
export interface ICheckboxMiniCompProps {
    onClick?: (event: any, data?: any) => void;
    state: TriState | boolean;
    size: CheckboxSize;
    shape?: CheckboxShape;
    classesBox?: string;
    classesContainer?: string;
    styleBox?: CSSProperties;
    "data-attr"?: string;
    data?: any;
    replaceBox?: ReactNode;
    disabled?: boolean;
    //getImage?: (props: ICheckboxMiniCompProps) => ReactNode;
    checkedEye?: boolean;
}

export class CheckboxMiniComp extends React.PureComponent<
    ICheckboxMiniCompProps,
    undefined
> {
    constructor(props: ICheckboxMiniCompProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(event: any) {
        if (this.props.disabled) return;
        if (isFunction(this.props.onClick))
            this.props.onClick(event, this.props.data);
    }

    getTriState(): TriState {
        let state = this.props.state;
        let s: TriState = state as TriState;
        if (state === true) s = TriState.check;
        if (state === false || state == null) s = TriState.uncheck;
        return s;
    }
    getImageMini(): any {
        const s = this.getTriState();
        const isCircle = this.props.shape === CheckboxShape.circle;

        if (s === TriState.check) {
            return isCircle ? (
                <svg
                    width='10'
                    height='10'
                    viewBox='0 0 10 10'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle
                        cx='5'
                        cy='5'
                        r='4.5'
                        className='svg-stroke'
                        fill='transparent'
                        strokeWidth='1'
                    />
                    <circle
                        cx='5'
                        cy='5'
                        r='2'
                        className='svg-fill svg-stroke'
                        strokeWidth='1'
                    />
                </svg>
            ) : (
                <svg
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect
                        x='1'
                        y='1'
                        className='svg-stroke'
                        width='13'
                        height='13'
                        rx='3'
                        fill='transparent'
                        strokeWidth='2'
                    />
                    <rect
                        x='4'
                        y='4'
                        className='svg-fill svg-stroke'
                        width='7'
                        height='7'
                        rx='1'
                        strokeWidth='2'
                    />
                </svg>
            );
        }

        if (s === TriState.uncheck) {
            return isCircle ? (
                <svg
                    width='10'
                    height='10'
                    viewBox='0 0 10 10'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle
                        cx='5'
                        cy='5'
                        r='4.5'
                        className='svg-stroke'
                        fill='transparent'
                        strokeWidth='2'
                    />
                </svg>
            ) : (
                <svg
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect
                        x='1'
                        y='1'
                        className='svg-stroke'
                        width='13'
                        height='13'
                        rx='3'
                        fill='transparent'
                        strokeWidth='2'
                    />
                </svg>
            );
        }

        if (s === TriState.partial) {
            return isCircle ? (
                <svg
                    width='10'
                    height='10'
                    viewBox='0 0 10 10'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle
                        cx='5'
                        cy='5'
                        r='4.5'
                        className='svg-stroke'
                        fill='transparent'
                        strokeWidth='2'
                    />
                    <rect
                        x='4.75'
                        y='6.75'
                        className='svg-fill svg-stroke'
                        width='5.5'
                        height='1.5'
                        rx='0.75'
                        strokeWidth='1.5'
                    />
                </svg>
            ) : (
                <svg
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect
                        x='1'
                        y='1'
                        className='svg-stroke'
                        width='13'
                        height='13'
                        rx='3'
                        fill='transparent'
                        strokeWidth='2'
                    />
                    <rect
                        x='4.75'
                        y='6.75'
                        className='svg-fill svg-stroke'
                        width='5.5'
                        height='1.5'
                        rx='0.75'
                        strokeWidth='1.5'
                    />
                </svg>
            );
        }
    }
    getImageBig(): any {
        let s = this.getTriState();

        if (s == TriState.check) {
            if (this.props.checkedEye) {
                return (
                    <svg
                        width='16'
                        height='18'
                        viewBox='0 0 16 12'
                        overflow='visible'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M8 12C11.6113 12 14.4201 8.9 15.6238 7.1C16.1254 6.4 16.1254 5.5 15.6238 4.8C14.4201 3.1 11.6113 0 8 0C4.38871 0 1.57994 3.1 0.376176 4.9C-0.125392 5.6 -0.125392 6.5 0.376176 7.1C1.57994 8.9 4.38871 12 8 12ZM8 3C9.70533 3 11.0094 4.3 11.0094 6C11.0094 7.7 9.70533 9 8 9C6.29467 9 4.9906 7.7 4.9906 6C4.9906 4.3 6.29467 3 8 3Z'
                            fill='#4DB6BC'
                        />
                    </svg>
                );
            }
            return (
                <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect
                        x='1'
                        y='1'
                        width='16'
                        height='16'
                        rx='3'
                        fill='none'
                        strokeWidth='2'
                    />
                    <rect
                        x='5'
                        y='5'
                        width='8'
                        height='8'
                        rx='1'
                        strokeWidth='2'
                    />
                </svg>
            );
        }
        if (s == TriState.uncheck)
            return (
                <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect
                        x='1'
                        y='1'
                        width='16'
                        height='16'
                        rx='3'
                        fill='none'
                        strokeWidth='2'
                    />
                </svg>
            );
        if (s == TriState.partial)
            return (
                <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect
                        x='1'
                        y='1'
                        width='16'
                        height='16'
                        rx='3'
                        stroke='#4DB6BC'
                        strokeWidth='2'
                    />
                    <rect
                        x='4.5'
                        y='8.5'
                        width='9'
                        height='1'
                        rx='0.5'
                        fill='#4DB6BC'
                        stroke='#4DB6BC'
                    />
                </svg>
            );
    }
    getGrayV() {
        let s = this.getTriState();

        return (
            <svg
                width='10'
                height='8'
                viewBox='0 0 10 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{
                    visibility:
                        s == TriState.check ? "visible" : "hidden"
                }}
            >
                <path
                    d='M9.4001 1.99961L8.0001 0.599609L4.0001 4.59961L2.0001 2.59961L0.600098 3.99961L4.0001 7.39961L9.4001 1.99961Z'
                    fill='#C5C5C5'
                />
            </svg>
        );
    }

    render() {
        let s = this.getTriState();
        let st: CSSProperties = {};
        if (this.props.styleBox) {
            st = this.props.styleBox;
        }

        let img: any = this.props.replaceBox;
        if (img === undefined) {
            if (this.props.size == CheckboxSize.mini)
                img = this.getImageMini();
            if (this.props.size == CheckboxSize.standart)
                img = this.getImageBig();
            if (this.props.size == CheckboxSize.grayV)
                img = this.getGrayV();
            if (this.props.size == CheckboxSize.switcher) {
                let checked = this.getTriState();
                img = (
                    <input
                        type='checkbox'
                        className='switch_1'
                        checked={checked == TriState.check}
                        readOnly={true}
                    />
                );
            }
        }

        return (
            <div
                className={classNames(
                    "CheckboxMiniComp",
                    this.props.classesContainer,
                    {opacity20: this.props.disabled}
                )}
                data-attr={
                    this.props["data-attr"]
                        ? this.props["data-attr"]
                        : null
                }
                onClick={this.onClick}
            >
                <span
                    className={classNames(
                        "CheckboxMiniComp-box",
                        {
                            "CheckboxMiniComp-Check":
                                s == TriState.check,
                            "CheckboxMiniComp-Uncheck":
                                s == TriState.uncheck,
                            "CheckboxMiniComp-Partial":
                                s == TriState.partial
                        },
                        this.props.classesBox
                    )}
                    style={st}
                >
                    {img}
                </span>
                {this.props.children}
            </div>
        );
    }
}
