import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2FieldStore} from "../../../../../store/agro/A2FieldStore";
import {action} from "mobx";
import {GeometryUtils} from "../../../../../helper/utils/GeometryUtils";
import bbox from "@turf/bbox";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import classNames from "classnames";
import {CheckboxMiniComp, CheckboxSize, TriState} from "../../../../Common/CheckboxMiniComp";
import {A2LineButton} from "./A2LineButton";
import {PenSvg} from "../../Agro/Buttons/PenSvg";
import {SmallCenterToSvg} from "../../Agro/Buttons/CenterToSvg";
import './A2FieldList.scss';
import {A2FieldFullCartProxyComp} from "./A2FieldFullCartProxyComp";
import {A2SubModeLeftPanel} from "../../../../../store/agro/A2AgroStore";
import {IEventAgroEditField} from "../../../../../../pluginApi/PluginsEvents";
import {bounds} from "leaflet";
import {ra} from "../../../../../helper/utils/mobxUtils";

export interface IA2FieldItemComp2 extends IStoreProps{
    field: A2FieldStore;
    isSelected: boolean;
    setSelected:(value: boolean)=>any;
    onOpenField: (field_id: number)=>any;
    isHighlight?: boolean;
}
export interface IA2FieldItemComp extends IStoreProps{
    field: A2FieldStore;
}
@observer
export class A2FieldItemComp extends React.Component<IA2FieldItemComp2, undefined> {
    constructor(props: IA2FieldItemComp2) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickEdit(){
        if (!this.props.store.agro2.permission.canEditObject()) return;
        this.props.store.agro2.fieldEditorForm.initEditField(this.props.field.id);
        this.props.store.agro2.subModeLeftPanel = A2SubModeLeftPanel.editOrCreateField;
        this.props.store.agro2.fieldEditorForm.showFieldMultiEditPanel = false;
        if (this.props.onOpenField) this.props.onOpenField(this.props.field.id);
    }
    @action
    onClickSelect(){
        let agro = this.props.store.agro2;
        this.props.setSelected(!this.props.isSelected);
        if (agro.filterSelectedChecked && agro.projectStruct.activeNavigatorPage.selectedFields.length == 0)
            agro.filterSelectedChecked = false;
        if (agro.fieldEditorForm.showFieldMultiEditPanel) {
            let flds = agro.projectStruct.lastNavigatorItem.selectedFields;
            agro.fieldEditorForm.initMultiEditField(flds);
        }
    }

    onClickCenter(){
        let store = this.props.store;
        store.agro2.getField(this.props.field.id).then(f => {
            ra(() => {
                if (f != null && !GeometryUtils.isGeometryEmpty(f.geometry)){
                    let b = bbox(f) as BBox2d;
                    store.map.zoomToBBox1in3(b);
                    this.props.store.agro2.agroFieldVisible = true;
                }
            });
        }).catch(err => store.addError(err));
    }

    @action
    onMouseLeave(){
        if (this.props.store.agro2.hover_field_id === this.props.field.id){
            this.props.store.agro2.hover_field_id = null;
        }
    }
    @action
    onMouseEnter(){
        if (this.props.store.agro2.hover_field_id !== this.props.field.id)
            this.props.store.agro2.hover_field_id = this.props.field.id;
    }

    @action
    onEndFlashAnimation(){
        this.props.field.flash = false;
    }
    @action
    onClickExpend(){
        this.props.field.expanded = !this.props.field.expanded;
    }
    render() {
        let field = this.props.field;
        let store = this.props.store;
        let selected = this.props.isSelected;
        let ev: IEventAgroEditField = {
            ok: true,
            field: field,
            isSelected: selected
        };
        store.events.onAgroCanEditField.call(ev);
        let canEdit = ev.ok;

        return <div className={classNames("A2FieldList-row-hover",{
            "A2FieldList-row-Expanded": field.expanded,
            "A2FieldList-row-flash": field.flash
        })} onAnimationEnd={this.onEndFlashAnimation}
                    onMouseLeave={this.onMouseLeave} onMouseEnter={this.onMouseEnter} id={"AgroFieldItemComp-"+field.id}>
            <div className="A2FieldList-row A2FieldList-row-fields">
                {this.props.isHighlight && <div className="A2FieldList-rowHighlight" />}
                <div className="flex-columns A2FieldList-td-title">
                    <CheckboxMiniComp state={selected?TriState.check:TriState.uncheck}
                                      size={CheckboxSize.standart} classesContainer="A2FieldList-checkbox"
                                      onClick={this.onClickSelect} >
                    </CheckboxMiniComp>
                </div>
                <A2FieldFullCartProxyComp field={this.props.field} store={this.props.store} />
                <div className="A2FieldList-extButtons">
                    <A2LineButton onClick={this.onClickEdit} disabled={!canEdit} className={classNames({"hide-important": !store.agro2.permission.canEditObject()})}>
                        <PenSvg/>
                    </A2LineButton>
                    <A2LineButton onClick={this.onClickCenter}>
                        <SmallCenterToSvg/>
                    </A2LineButton>
                </div>
            </div>
        </div>;
    }
}
