import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action, autorun, observable} from "mobx";
import {ButtonLight} from "../../../../Common/ButtonLight";
import './PhotoTagEditorWindow.scss';
import {PhotoTagEditorGroupTag} from "./PhotoTagEditorGroupTag";
import {InputStringWindow} from "../../../../Common/InputStringWindow";
import {IReactionDisposer} from "mobx/lib/internal";

@observer
export class PhotoTagEditorWindow extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);

        this.props.store.photo.initTags();

        this.disp = autorun(()=>{
            this.props.store.photo.tagEditor.resync();
        });
    }

    disp:IReactionDisposer = null;

    componentWillUnmount() {
        if (this.disp != null) this.disp();
        this.disp = null;
    }

    @observable
    editName: string = "";

    @observable
    showAddGroupName: boolean = false;

    static isDisableEditText(str: string): boolean{
        return (str.length == 0);
    }

    @action
    onChangeTagSearch(event: any){
        this.props.store.photo.tagEditor.tagGroups.filter = event.target.value;
    }

    @action
    async onClickAddTagGroup(){
        this.editName = "";
        this.showAddGroupName = true;
    }
    @action
    onCloser(){
        this.props.store.photo.tagEditor.closeTagEditor();
    }
    @action
    async onAddTagGroup(str: string){
        this.showAddGroupName = false;
        await this.props.store.photo.tagEditor.sendAddTagGroup(this.editName);
    }
    @action
    onChangeEditText(str: string){
        this.editName = str;
    }
    @action
    onCloseEdit(){
        this.showAddGroupName = false;
    }

    render() {
        let store = this.props.store;
        let tagEditor = store.photo.tagEditor;
        let tagGroups = tagEditor.tagGroups.tagGroups.map(a => <PhotoTagEditorGroupTag group={a} store={store} key={a.id} />);

        return <div className="modal-back">
            <div className="modal-full modal-full-frame PhotoTagEditorWindow-window">
                <div className="flex-columns flex-align-items-start">
                    <div className="PhotoTagEditorWindow-header flex-stretch-item">{store.trans["Tags reference"]}</div>
                    <div className="PhotoTagEditorWindow-topCloser" onClick={this.onCloser}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" fill="#C5C5C5"/>
                        </svg>
                    </div>
                </div>
                <div className="flex-columns flex-align-items-center PhotoTagEditorWindow-marginLR flex-center-content PhotoTagEditorWindow-marginBottom20">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.707 7.207L7.5 0H2V2H5.914L13.414 9.5C13.4818 9.571 13.5443 9.64687 13.601 9.727L14.707 8.621C14.8945 8.43347 14.9998 8.17916 14.9998 7.914C14.9998 7.64884 14.8945 7.39453 14.707 7.207Z" fill="#C5C5C5"/>
                        <path d="M12.707 10.207L5.5 3H0V8.5L7.207 15.707C7.39453 15.8945 7.64884 15.9998 7.914 15.9998C8.17916 15.9998 8.43347 15.8945 8.621 15.707L12.707 11.621C12.8945 11.4335 12.9998 11.1792 12.9998 10.914C12.9998 10.6488 12.8945 10.3945 12.707 10.207ZM3 7C2.80222 7 2.60888 6.94135 2.44443 6.83147C2.27998 6.72159 2.15181 6.56541 2.07612 6.38268C2.00043 6.19996 1.98063 5.99889 2.01921 5.80491C2.0578 5.61093 2.15304 5.43275 2.29289 5.29289C2.43275 5.15304 2.61093 5.0578 2.80491 5.01921C2.99889 4.98063 3.19996 5.00043 3.38268 5.07612C3.56541 5.15181 3.72159 5.27998 3.83147 5.44443C3.94135 5.60888 4 5.80222 4 6C4 6.26522 3.89464 6.51957 3.70711 6.70711C3.51957 6.89464 3.26522 7 3 7Z" fill="#C5C5C5"/>
                    </svg>
                    <span className="text-next">{store.trans.Tags}</span>
                    <input type="text" className="text-box-editor flex-stretch-item" style={{marginLeft:"20px"}}
                           onChange={this.onChangeTagSearch} placeholder={store.trans["Search by tags"]}
                           value={tagEditor.tagGroups.filter}/>
                </div>
                <div className="PhotoTagEditorWindow-marginLR PhotoTagEditorWindow-marginBottom20 flex-columns">
                    <ButtonLight onClick={this.onClickAddTagGroup}
                                 className="ButtonLight-blueBackground-onhover pointer">
                        {store.trans["Create tag group"]}
                    </ButtonLight>
                </div>
                <div className="separator_line-flex PhotoTagEditorWindow-marginLR PhotoTagEditorWindow-marginBottom20" />
                <div className="flex-stretch-item overflowHidden" style={{paddingRight: "8px"}}>
                    <div className="height100 width100 overflowAutoY style-4">
                        {tagGroups}
                    </div>
                </div>
                {this.showAddGroupName && <InputStringWindow value={this.editName}
                    title={store.trans["Create tag group"]} name={store.trans.Name} okText={store.trans["Create group"]} cancelText={store.trans.Cancel}
                    onChange={this.onChangeEditText} onCancelClick={this.onCloseEdit} onOkClick={this.onAddTagGroup}
                    isDisable={PhotoTagEditorWindow.isDisableEditText(this.editName)}  />
                }
            </div>
        </div>;
    }
}
