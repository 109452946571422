import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ProductSettingStore} from "../../../../store/productSetting/ProductSettingStore";
import "./ProductCss.scss";
import {action, observable, reaction} from "mobx";
import classNames from "classnames";
import {BandStaticStyleProductStore} from "../../../../store/productSetting/BandStaticStyleProductStore";
import {InputNumber} from "../../../Common/InputNumber";
import "./ProductSettingsComp.scss";
import { SceneMosaicSwitch } from '../../../../store/SearchStore';
import { ProductItemIndexBandComp } from './ProductItemIndexBandComp';
import { IBand } from '../../../../store/config/ConfigStore';

export interface IProductItemPropStaticBandComp extends IStoreProps{
    band: BandStaticStyleProductStore,
    item: ProductSettingStore,
    index: number,
    readonly: boolean
}
@observer
export class ProductItemPropStaticBandComp extends React.Component<IProductItemPropStaticBandComp, undefined> {
    @observable quantileMin: number = 2;
    @observable quantileMax: number = 98;
    @observable isActive: boolean = this.props.store.map.searchObject.isNotEmpty;
    constructor(props: IProductItemPropStaticBandComp) {
        super(props);
        autoBindReact(this);
        reaction(
            () => this.props.store.map.searchObject.isNotEmpty, 
            isNotEmpty => {
                this.isActive = isNotEmpty; 
            }
        );
    }

    @action
    toggleSwitcher() {
        if (!this.props.store.map.searchObject.isNotEmpty) return;
        this.isActive = !this.isActive;
    }
    @action
    onChangeMin(num: number){
        this.props.band.min = num;
        this.props.item.changed = true;
    }
    @action
    onChangeMax(num: number){
        this.props.band.max = num;
        this.props.item.changed = true;
    }
    @action
    onChangeQuantileMin(num: number) {
        this.quantileMin = num;
    }
    @action
    onChangeQuantileMax(num: number) {
        this.quantileMax = num;
    }
    @action
async onClickMethod1() {
    try {
        const store = this.props.store;
        const z = store.map.mapbox.getZoom();

        const currentSceneId = store.searchPanel.currentSceneid;
        const mosaicSwitch = store.searchPanel.sceneMosaicSwitch;
        const hasCurrentScene = !!currentSceneId;

        const favoriteItems = store.searchPanel.favoriteList.getAllSceneItems();
        const selectedFavorites = favoriteItems.filter(item => item.selected);
        const hasSelectedFavorites = selectedFavorites.length > 0;

        let sceneIds: string[] = [];
        if (hasCurrentScene) {
            if (mosaicSwitch === SceneMosaicSwitch.mosaic) {
                const group = store.searchPanel.searchResult.findGroup(currentSceneId);
                if (group) {
                    sceneIds = group.getSceneIds();
                }
            } else {
                sceneIds = [currentSceneId];
            }
        } else if (hasSelectedFavorites) {
            sceneIds = selectedFavorites.map(item => item.feature.properties.scene_id);
        }

        const bands = this.props.item.staticStyle.bands.map(band => band.name);
        const quantiles = { q1: this.quantileMin / 100, q2: this.quantileMax / 100 };
        const borderFeature = this.isActive && store.map.searchObject.searchGeometry
            ? {
                type: "Feature",
                geometry: store.map.searchObject.searchGeometry,
                properties: {}
            }
            : undefined;

        const params = {
            scene_ids: sceneIds,
            bands: bands,
            quantiles: quantiles,
            z: z,
            ...(borderFeature ? { border: borderFeature } : {})
        };
        const response = await fetch("/api/scene/band/stat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ params })
        });

        if (!response.ok) {
            store.addError(`${response.statusText}`);
            return;
        }
        const data = await response.json();
        this.props.item.staticStyle.bands.forEach(band => {
            const colorData = data[band.name];
            if (colorData?.stat) {
                band.min = colorData.stat.v1;
                band.max = colorData.stat.v2;
            }
        });
        this.props.item.changed = true;
    } catch (e) {
        this.props.store.addError(e);
    }
}

    render() {
        let store = this.props.store;
const items = store.searchPanel.favoriteList.getAllSceneItems();
const hasSelectedScene = !!store.searchPanel.currentSceneid;
const item = store.searchPanel.searchResult.currentItems[0];
const hasFavorite = items.length > 0 && items.some(i => i.selected);
let band = this.props.band;
let isDisabled = !hasSelectedScene && !hasFavorite;

let color = { background: "#DA5D5D" };
if (this.props.index === 1) color = { background: "#76DA5D" };
if (this.props.index === 2) color = { background: "#4DA7EE" };

let isLast = this.props.index === 2;

const spectrumName = band.name?.toUpperCase(); 
const satelliteName = item?.satellite;
const satConfig = (store.config.satellites as Record<string, any>)?.[satelliteName];
let channelName = null;
if (satConfig && Array.isArray(satConfig.bands)) {
    const matchingBand = satConfig.bands.find((b: IBand) => b.spectrum_name?.toUpperCase() === spectrumName);
    channelName = matchingBand?.name || null;
}
if (!channelName) {
    channelName = band.name;
}
    
        return <>
        <div className={classNames("ProductItemPropStaticBandComp flex-columns", {"readonly": this.props.readonly})}>
            <span className="ProductItemPropStaticBandComp-icon" style={color} />
            <span className="flex-stretch-item text-white">{channelName}{','}{' '}{band.name}</span>
            <InputNumber className="ProductItemPropStaticBandComp-inputNum blue-border-onfocus"
                   disabled={this.props.readonly} changeOnExitOrEnter={true}
                   value={band.min} min={1} max={10000} onChangeNumber={this.onChangeMin}/>
            <span className="ProductItemPropStaticBandComp-delimeter">-</span>
            <InputNumber className="ProductItemPropStaticBandComp-inputNum blue-border-onfocus"
                   disabled={this.props.readonly} changeOnExitOrEnter={true}
                   value={band.max} min={1} max={10000} onChangeNumber={this.onChangeMax}/>
        </div>
        {isLast && (
             <div className='ProductSettingsComp-method'>
             <div className='ProductSettingsComp-method-info'>
                 <div className='ProductSettingsComp-method-info-row1'>
                     {
                         store.trans[
                             "Calculate min-max values"
                         ]
                     }
                 </div>
                 <div>
                     <svg
                         width='16'
                         height='16'
                         viewBox='0 0 16 16'
                         fill='none'
                         xmlns='http://www.w3.org/2000/svg'
                     >
                         <g clipPath='url(#clip0_261_14001)'>
                             <path
                                 d='M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z'
                                 stroke='#4DB6BC'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                             />
                             <path
                                 d='M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z'
                                 fill='#4DB6BC'
                             />
                             <path
                                 d='M8 12.5V7'
                                 stroke='#4DB6BC'
                                 strokeLinecap='round'
                                 strokeLinejoin='round'
                             />
                         </g>
                         <defs>
                             <clipPath id='clip0_261_14001'>
                                 <rect
                                     width='16'
                                     height='16'
                                     fill='white'
                                 />
                             </clipPath>
                         </defs>
                     </svg>
                 </div>
             </div>
             <div className='ProductSettingsComp-method-info-row2'> {store.trans["Trimming"]}</div>
             <div>
             <div className="ProductSettingsComp-percent">
             <InputNumber className="ProductItemPropStaticBandComp-inputNum blue-border-onfocus"
                   disabled={this.props.readonly} changeOnExitOrEnter={true}
                   value={this.quantileMin} min={0} max={100} onChangeNumber={this.onChangeQuantileMin}/>
              <span className="ProductSettingsComp-percent-symbol">%</span>
              </div>       
            <span className="ProductItemPropStaticBandComp-delimeter">-</span>
            <div className="ProductSettingsComp-percent">
            <InputNumber className="ProductItemPropStaticBandComp-inputNum blue-border-onfocus"
                   disabled={this.props.readonly} changeOnExitOrEnter={true}
                   value={this.quantileMax} min={1} max={100} onChangeNumber={this.onChangeQuantileMax}/>  
              <span className="ProductSettingsComp-percent-symbol">%</span>
              </div>         
             </div>
             <div className="popup-pref-in__rowSeasonSearch">
                                     <div className='indexByPoint_margin-top'>
                                         <input type="checkbox" className={classNames("switch_1","switch_2")} id="compositImage1" checked={this.isActive} 
                                        onChange={this.toggleSwitcher}
                                        disabled={!this.props.store.map.searchObject.isNotEmpty} 
                                            /><label
                                         htmlFor="compositImage1" className="ProductSettingsComp-method-checkbox ProductItemComp-textLabel-m">{store.trans[
                                            "Calculate only by Area of Interest"
                                        ]}</label>
                                     </div>             
                                     </div>
             <div className="ProductSettingsComp-method-buttons">
                 <button disabled={isDisabled } onClick={this.onClickMethod1}className={classNames("ProductSettingsComp-method-button",{'ProductSettingsComp-method-disabled':isDisabled })}>  {
                                store.trans[
                                    "Calculate"
                                ]
                            }</button>
             </div>
         </div>
        )}
        </>
    }
}
