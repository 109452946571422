// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroProjectCreateComp-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.AgroProjectCreateComp-lr_margin {
  margin-left: 15px;
  margin-right: 15px;
}

.AgroProjectCreateComp-inputFont {
  font-size: 12px !important;
}

div.AgroProjectCreateComp-dropDowm {
  background: #262B32;
}

.AgroProjectCreateComp-dropDowm.select-items > div {
  background: #262B32;
}

.AgroProjectCreateComp-nameLine {
  font-weight: 400;
  font-size: 10px;
  line-height: 1.4;
  color: #C5C5C5;
  margin-top: 6px;
  margin-bottom: 8px;
  display: flex;
}

.AgroProjectCreateComp-star {
  font-size: 14px;
  line-height: 22px;
}

input.AgroProjectCreateComp-nameInput {
  background: #262B32;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/ProjectEditor/AgroProjectCreateComp.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AACA;EACE,iBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,0BAAA;AAGF;;AADA;EACE,mBAAA;AAIF;;AAFA;EACE,mBAAA;AAKF;;AAHA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;AAMF;;AAJA;EACE,eAAA;EACA,iBAAA;AAOF;;AALA;EACE,mBAAA;EACA,eAAA;AAQF","sourcesContent":[".AgroProjectCreateComp-main{\r\n  flex-grow: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  overflow: hidden;\r\n  position: relative;\r\n}\r\n.AgroProjectCreateComp-lr_margin {\r\n  margin-left: 15px;\r\n  margin-right: 15px;\r\n}\r\n.AgroProjectCreateComp-inputFont{\r\n  font-size: 12px!important;\r\n}\r\ndiv.AgroProjectCreateComp-dropDowm{\r\n  background: #262B32;\r\n}\r\n.AgroProjectCreateComp-dropDowm.select-items > div{\r\n  background: #262B32;\r\n}\r\n.AgroProjectCreateComp-nameLine {\r\n  font-weight: 400;\r\n  font-size: 10px;\r\n  line-height: 1.4;\r\n  color: #C5C5C5;\r\n  margin-top: 6px;\r\n  margin-bottom: 8px;\r\n  display: flex;\r\n}\r\n.AgroProjectCreateComp-star {\r\n  font-size: 14px;\r\n  line-height: 22px;\r\n}\r\ninput.AgroProjectCreateComp-nameInput{\r\n  background: #262B32;\r\n  font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
