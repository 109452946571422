import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {action, observable} from "mobx";
import {ra} from "../../../../helper/utils/mobxUtils";

@observer
export class PersonalArea_PassPanel extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @observable
    cur_password: string = "";
    @observable
    new_password: string = "";
    @observable
    new_password_show: boolean = false;
    @observable
    new_password2: string = "";
    @observable
    error_pass_not_equals: boolean = false;
    @observable
    edit_mode: boolean = false;

    @action onChangeCurPass(event: any){
        this.cur_password = event.target.value;
    }
    @action onChangeNewPass(event: any){
        this.new_password = event.target.value;
        this.error_pass_not_equals = false;
    }
    @action onChangeNewPass2(event: any){
        this.new_password2 = event.target.value;
        this.error_pass_not_equals = false;
    }
    @action checkPass(): boolean{
        this.error_pass_not_equals = false;
        if (this.new_password != this.new_password2 || this.new_password==""){
            this.error_pass_not_equals = true;
            return false;
        }
        return true;
    }
    @action onClickStartEdit(){
        this.onClickCancel();
        this.edit_mode = true;
    }
    @action async onClickCancel(){
        this.edit_mode = false;
        this.cur_password = "";
        this.new_password = "";
        this.new_password2 = "";
        this.error_pass_not_equals = false;
    }
    @action async onClickSave(){
        let store = this.props.store;
        try {
            if (!this.checkPass()) return;
            await store.user.changePassSend(this.cur_password, this.new_password2);
            ra(()=>{
                store.addInfo(store.trans["Password has been changed"]);
                this.onClickCancel();
            });
        }catch (e) {
            store.addError(e);
        }
    }

    render() {
        let store = this.props.store;
        let EyeIcon = (props:{active: boolean, onClick: ()=>any})=> <div className="PersonalAreaComp-valueIcon">
            <div className="flex-full-center">
                {!props.active && <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="pointer" onClick={props.onClick}>
                    <path d="M8.00013 12C12.7071 12 15.7441 6.716 15.8711 6.492C16.0421 6.188 16.0431 5.816 15.8721 5.512C15.7461 5.287 12.7311 0 8.00013 0C3.24513 0 0.251127 5.289 0.126127 5.514C-0.0428725 5.817 -0.0418725 6.186 0.128127 6.489C0.254128 6.713 3.26913 12 8.00013 12ZM8.00013 2C10.8391 2 13.0361 4.835 13.8181 6C13.0341 7.166 10.8371 10 8.00013 10C5.15913 10 2.96213 7.162 2.18113 5.999C2.95813 4.835 5.14613 2 8.00013 2Z" fill="#C5C5C5"/>
                    <path d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z" fill="#C5C5C5"/>
                </svg>}

                {props.active && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="pointer" onClick={props.onClick}>
                    <path d="M14.5739 5.66901L13.1499 7.09301C13.4279 7.44001 13.6559 7.75701 13.8189 8.00001C13.0589 9.13001 10.9689 11.82 8.25787 11.985L6.44287 13.8C6.93887 13.924 7.45687 14 7.99987 14C12.7069 14 15.7439 8.71601 15.8709 8.49201C16.0419 8.18801 16.0429 7.81601 15.8719 7.51201C15.8249 7.42701 15.3719 6.63101 14.5739 5.66901Z" fill="#C5C5C5"/>
                    <path d="M0.292887 15.707C0.487887 15.902 0.743887 16 0.999887 16C1.25589 16 1.51189 15.902 1.70689 15.707L15.7069 1.70701C16.0979 1.31601 16.0979 0.684006 15.7069 0.293006C15.3159 -0.0979941 14.6839 -0.0979941 14.2929 0.293006L11.5479 3.03801C10.5149 2.43101 9.33089 2.00001 7.99989 2.00001C3.24489 2.00001 0.250887 7.28901 0.125887 7.51401C-0.0431132 7.81701 -0.0421132 8.18601 0.127887 8.48901C0.197887 8.61401 1.17189 10.291 2.82089 11.765L0.291887 14.294C-0.0981131 14.684 -0.0981131 15.316 0.292887 15.707ZM2.18089 7.99901C2.95789 6.83501 5.14589 4.00001 7.99989 4.00001C8.74189 4.00001 9.43689 4.20101 10.0779 4.50801L8.51189 6.07401C8.34789 6.02901 8.17789 6.00001 7.99989 6.00001C6.89489 6.00001 5.99989 6.89501 5.99989 8.00001C5.99989 8.17801 6.02889 8.34801 6.07389 8.51201L4.23989 10.346C3.28489 9.51 2.55889 8.56201 2.18089 7.99901Z" fill="#C5C5C5"/>
                </svg>}
            </div>
        </div>;
        return <div className={classNames("flex-rows")}>
                <div className="PersonalAreaComp-Pass">
                    {store.trans.Password}
                    <svg className="text-next" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303297 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.9945 5.14519 13.2552 3.36793 11.9436 2.05637C10.6321 0.744816 8.85482 0.00553954 7 0V0ZM7.4375 11.375H6.5625C6.44647 11.375 6.33519 11.3289 6.25314 11.2469C6.1711 11.1648 6.125 11.0535 6.125 10.9375V6.5625C6.125 6.44647 6.1711 6.33519 6.25314 6.25314C6.33519 6.17109 6.44647 6.125 6.5625 6.125H7.4375C7.55354 6.125 7.66482 6.17109 7.74686 6.25314C7.82891 6.33519 7.875 6.44647 7.875 6.5625V10.9375C7.875 11.0535 7.82891 11.1648 7.74686 11.2469C7.66482 11.3289 7.55354 11.375 7.4375 11.375ZM7 4.375C6.88327 4.38202 6.76637 4.36419 6.65703 4.3227C6.54769 4.28121 6.44839 4.217 6.3657 4.1343C6.283 4.05161 6.21879 3.95231 6.1773 3.84297C6.13581 3.73363 6.11799 3.61674 6.125 3.5C6.11799 3.38326 6.13581 3.26637 6.1773 3.15703C6.21879 3.04769 6.283 2.94839 6.3657 2.8657C6.44839 2.783 6.54769 2.71879 6.65703 2.6773C6.76637 2.63581 6.88327 2.61798 7 2.625C7.11674 2.61798 7.23364 2.63581 7.34298 2.6773C7.45232 2.71879 7.55161 2.783 7.63431 2.8657C7.717 2.94839 7.78121 3.04769 7.8227 3.15703C7.8642 3.26637 7.88202 3.38326 7.875 3.5C7.88202 3.61674 7.8642 3.73363 7.8227 3.84297C7.78121 3.95231 7.717 4.05161 7.63431 4.1343C7.55161 4.217 7.45232 4.28121 7.34298 4.3227C7.23364 4.36419 7.11674 4.38202 7 4.375Z" fill="#6A7886"/>
                    </svg>
                </div>
            <form autoComplete="off" onSubmit={this.onClickSave}
                  className={classNames("flex-rows",{"PersonalAreaComp-disabledArea": !this.edit_mode})}>
                <div className="PersonalAreaComp-keyValuePair">
                    <div className="PersonalAreaComp-key">
                        {store.trans["Current password"]}
                    </div>
                    <div className="PersonalAreaComp-value">
                        <div className="relative">
                            <input type={"password"} disabled={!this.edit_mode}
                                   style={{margin: ""}} autoComplete="off"
                                   className={classNames("text-box-editor width100",{"input-DarkBack": this.edit_mode})}
                                   value={this.cur_password}
                                   onChange={this.onChangeCurPass}
                            />
                        </div>
                    </div>
                </div>
                <div className="PersonalAreaComp-keyValuePair">
                    <div className="PersonalAreaComp-key">
                        {store.trans["New password"]}
                    </div>
                    <div className="PersonalAreaComp-value">
                        <div className="relative">
                            <input type={this.new_password_show?"text":"password"}
                                   disabled={!this.edit_mode}
                                   style={{margin: ""}} autoComplete="off"
                                   className={classNames("text-box-editor width100 input-DarkBack",{"input-DarkBack": this.edit_mode})}
                                   value={this.new_password}
                                   onChange={this.onChangeNewPass}
                            />
                                <EyeIcon active={this.new_password_show} onClick={()=>{this.new_password_show = !this.new_password_show}} />
                        </div>
                    </div>
                </div>
                <div className="PersonalAreaComp-keyValuePair">
                    <div className="PersonalAreaComp-key">
                        {store.trans["New password again"]}
                    </div>
                    <div className="PersonalAreaComp-value">
                        <div className="relative">
                            <input type="password" disabled={!this.edit_mode}
                                   style={{margin: ""}} autoComplete="off"
                                   className={classNames("text-box-editor width100 ",{
                                       "input-DarkBack": this.edit_mode,
                                       "red_border":this.error_pass_not_equals})}
                                   value={this.new_password2}
                                   onChange={this.onChangeNewPass2}
                            />
                            {this.error_pass_not_equals && <div className="PersonalAreaComp-errorPassEqual">{store.trans["Confirmation does not match password"]}</div>}
                        </div>
                    </div>
                </div>
            </form>
                {!this.edit_mode && <div className="PersonalAreaComp-linkButtonsRow link-text-underlineOnHover" onClick={this.onClickStartEdit}>
                    {store.trans["Change password"]}
                </div>}
                {this.edit_mode && <div className="PersonalAreaComp-linkButtonsRow">
                    <span className="PersonalAreaComp-linkButton link-text-underlineOnHover" onClick={this.onClickCancel}>{store.trans.Cancel}</span>
                    <span className="PersonalAreaComp-linkButton link-text-underlineOnHover" onClick={this.onClickSave}>{store.trans.Save}</span>
                </div>}
            </div>;
    }
}
