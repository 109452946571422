// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.PhotoTagEditorWindow-window {
  max-width: 452px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: #C5C5C5;
  top: 30px;
  bottom: auto;
  height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  padding-bottom: 15px;
}

.PhotoTagEditorWindow-header {
  margin: 25px 25px 20px 25px;
  color: #EEEEEE;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.PhotoTagEditorWindow-topCloser {
  margin: 16px;
  cursor: pointer;
}

.PhotoTagEditorWindow-marginLR {
  margin-left: 25px;
  margin-right: 25px;
}

.PhotoTagEditorWindow-marginBottom20 {
  margin-bottom: 20px;
}

div.PhotoTagEditorWindow-showHover {
  display: none;
}

.PhotoTagEditorWindow-showHoverInitiator:hover > .PhotoTagEditorWindow-showHover {
  display: flex;
}

.PhotoTagEditorWindow-showAnyway {
  background: #22282F !important;
}

.PhotoTagEditorWindow-showAnyway > .PhotoTagEditorWindow-showHover {
  display: flex !important;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/PhotoPanel/PhotoTagEditor/PhotoTagEditorWindow.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,SAAA;EACA,YAAA;EACA,0BAAA;EACA,8BAAA;EACA,oBAAA;AACF;;AACA;EACE,2BAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AAAA;EACE,YAAA;EACA,eAAA;AAGF;;AADA;EACE,iBAAA;EACA,kBAAA;AAIF;;AAFA;EACE,mBAAA;AAKF;;AAAA;EACE,aAAA;AAGF;;AAEA;EACE,aAAA;AACF;;AACA;EACE,8BAAA;AAEF;;AAAA;EAEE,wBAAA;AAEF","sourcesContent":["div.PhotoTagEditorWindow-window{\r\n  max-width: 452px;\r\n  margin: auto;\r\n  display: flex;\r\n  flex-direction: column;\r\n  color: #C5C5C5;\r\n  top: 30px;\r\n  bottom: auto;\r\n  height: calc(100% - 100px);\r\n  max-height: calc(100% - 100px);\r\n  padding-bottom: 15px;\r\n}\r\n.PhotoTagEditorWindow-header{\r\n  margin: 25px 25px 20px 25px;\r\n  color: #EEEEEE;\r\n  font-weight: bold;\r\n  font-size: 16px;\r\n  line-height: 16px;\r\n}\r\n.PhotoTagEditorWindow-topCloser{\r\n  margin: 16px;\r\n  cursor: pointer;\r\n}\r\n.PhotoTagEditorWindow-marginLR{\r\n  margin-left: 25px;\r\n  margin-right: 25px;\r\n}\r\n.PhotoTagEditorWindow-marginBottom20{\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.PhotoTagEditorWindow-showHoverInitiator{\r\n}\r\ndiv.PhotoTagEditorWindow-showHover{\r\n  display: none;\r\n  //opacity: 0;\r\n  //pointer-events: none;\r\n  //transition: all 0.3s linear;\r\n}\r\n.PhotoTagEditorWindow-showHoverInitiator:hover > .PhotoTagEditorWindow-showHover{\r\n  display: flex;\r\n}\r\n.PhotoTagEditorWindow-showAnyway{\r\n  background: #22282F!important;\r\n}\r\n.PhotoTagEditorWindow-showAnyway > .PhotoTagEditorWindow-showHover\r\n{\r\n  display: flex!important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
