import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {IUser, UserRole, UserStatus, UserSubscription} from "../../../../store/user/UserListStore";
import classNames from "classnames";
import {action, observable} from "mobx";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {DropDownComp, IDropDownItem} from "../../../Common/DropDownComp";
import {ra} from "../../../../helper/utils/mobxUtils";
import {ReactUtils} from "../../../../helper/utils/ReactUtils";
import {Utils} from "../../../../helper/utils/Utils";
import {ContextMenuCommonComp} from "../../../Common/ContextMenuCommonComp";
import {AdminPanel_CommentEditComp} from "./AdminPanel_CommentEditComp";

export interface IAdminPanelRowComp extends IStoreProps{
    user: IUser,
    filterText: string
}
@observer
export class AdminPanelRowComp extends React.Component<IAdminPanelRowComp, undefined> {
    constructor(props: IAdminPanelRowComp) {
        super(props);
        autoBindReact(this);
    }
    @observable
    changedRole: boolean = false;
    @observable
    changedStatus: boolean = false;

    @action
    async onChangeRole(item: IDropDownItem){
        try {
            await this.props.store.userList.updateRole(this.props.user.user_id, item.key as string);
            ra(()=>{
                this.changedRole = true;
            });
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    async onChangeStatus(item: IDropDownItem) {
        try {
            await this.props.store.userList.updateStatus(this.props.user.user_id, item.key as number);
            ra(()=>{
                this.changedStatus = true;
            });
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @observable
    openEditComment: boolean = false;
    @observable
    openInsertComment: boolean = false;
    @observable
    textComment: string = "";

    @action
    onChangeComment(newValue: string){
        this.textComment = newValue;
    }
    @action
    startEditComment(){
        this.openEditComment = true;
        this.openInsertComment = false;
        this.textComment = this.props.user.comment??"";
    }
    @action
    startInsertComment(){
        this.openEditComment = false;
        this.openInsertComment = true;
        this.textComment = this.props.user.comment??"";
    }
    @action
    onCloseCommentForm(){
        this.openEditComment = false;
        this.openInsertComment = false;
    }
    @action
    onSaveComment(){
        this.props.user.comment = this.textComment;
        this.onCloseCommentForm();
    }
    @action
    onCreateComment(){
        this.props.user.comment = this.textComment;
        this.onCloseCommentForm();
    }

    render() {
        let store = this.props.store;
        let u = this.props.user;
        let roleItems: IDropDownItem[] = [
            {key: UserRole.ADMIN, value: store.trans["Administrator"]},
            {key: UserRole.USER, value: store.trans.User},
            {key: UserRole.GUEST, value: store.trans.Guest},
            {key: UserRole.OWNER, value: store.trans.Manager}
        ];
        let statusItems: IDropDownItem[] = [
            {key:UserStatus.ACTIVE, value: store.trans.Active, class: "AdminPanelComp-greenDropDown"},
            {key:UserStatus.LOCKED, value: store.trans.Blocked, class: "AdminPanelComp-redDropDown"},
        ];
        let subsrcItems: IDropDownItem[] = [
            {key:UserSubscription.limit, value: store.trans["Limit"]},
            {key:UserSubscription.unlimit, value: store.trans["Unlimit"]},
        ];

        return <tr key={u.user_id.toString()} className="">
            <td></td>
            <td>{ReactUtils.getHighlight(TranslateUtils.getUserFullName(u.surname , u.given_name), this.props.filterText)}</td>
            <td>{ReactUtils.getHighlight(u.email, this.props.filterText)}</td>
            <td>
                <div className="AdminPanelComp-commentColumn">
                    {ReactUtils.getHighlight(u.comment, this.props.filterText)}
                    {Utils.isStringNotEmpty(u.comment) && <div className="AdminPanelComp-editComment white_svg blue_svg_onhover pointer" style={{marginLeft: "10px"}} onClick={this.startEditComment}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.075 2.625L0.225 8.475C0.075 8.625 0 8.775 0 9V11.25C0 11.7 0.3 12 0.75 12H3C3.225 12 3.375 11.925 3.525 11.775L9.375 5.925L6.075 2.625Z" stroke="none"/>
                            <path d="M11.775 2.475L9.525 0.225C9.225 -0.075 8.775 -0.075 8.475 0.225L7.125 1.575L10.425 4.875L11.775 3.525C12.075 3.225 12.075 2.775 11.775 2.475Z" stroke="none"/>
                        </svg>
                        {this.openEditComment && <ContextMenuCommonComp onClose={this.onCloseCommentForm}>
                            <AdminPanel_CommentEditComp isInsertMode={false} text={this.textComment} onClose={this.onCloseCommentForm}
                                                        onChangeComment={this.onChangeComment} onSave={this.onSaveComment} store={store} />
                        </ContextMenuCommonComp>}
                    </div>
                    }
                    {!Utils.isStringNotEmpty(u.comment) && <div className="AdminPanelComp-editComment white_svg blue_svg_onhover pointer" onClick={this.startInsertComment}>
                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12 9H9V12H7V9H4V7H7V4H9V7H12V9Z" stroke="none"/>
                        </svg>
                        {this.openInsertComment && <ContextMenuCommonComp onClose={this.onCloseCommentForm}>
                            <AdminPanel_CommentEditComp isInsertMode={true} text={this.textComment} onClose={this.onCloseCommentForm}
                                                        onChangeComment={this.onChangeComment} onCreate={this.onCreateComment} store={store} />
                        </ContextMenuCommonComp>}
                    </div>
                    }
                </div>
            </td>
            <td>{u.login_time}</td>
            <td className="AdminPanelComp-cellDropbox">
                <div className={"flex-columns-centerByVertical width100"}>
                    <DropDownComp items={roleItems} currentKey={u.role} onChange={this.onChangeRole} className="AdminPanelComp-dropSize"/>
                    <div className={classNames("AdminPanelComp-sendedOk",{"hide": !this.changedRole})}>
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M9.4001 2L8.0001 0.599998L4.0001 4.6L2.0001 2.6L0.600098 4L4.0001 7.4L9.4001 2Z" fill="#6ABC4D"/>
                        </svg>
                    </div>
                </div>
            </td>
            <td className="AdminPanelComp-cellDropbox">
                <div className={"flex-columns-centerByVertical width100"}>
                    <DropDownComp items={statusItems} currentKey={u.is_active? UserStatus.ACTIVE: UserStatus.LOCKED}
                                  className={classNames("AdminPanelComp-dropSize", {
                                      "AdminPanelComp-redDrop": !u.is_active,
                                      "AdminPanelComp-greenDrop": u.is_active})}
                                  onChange={this.onChangeStatus} />

                    <div className={classNames("AdminPanelComp-sendedOk",{"hide": !this.changedStatus})}>
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M9.4001 2L8.0001 0.599998L4.0001 4.6L2.0001 2.6L0.600098 4L4.0001 7.4L9.4001 2Z" fill="#6ABC4D"/>
                        </svg>
                    </div>
                </div>
            </td>
            <td className="AdminPanelComp-cellDropbox">
                <DropDownComp items={subsrcItems} currentKey={UserSubscription.limit} className="AdminPanelComp-dropSize"/>
            </td>
        </tr>;
    }
}
