import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {IndexBandExportStore} from "./IndexBandExportStore";
import {IDictonaryType} from "../../../app/helper/utils/Utils";
import {observable} from "mobx";
import {update} from "../../../app/store/PermalinkDecor";
import {VoronovExportStore} from "./VoronovExportStore";
import {Lang} from "../../../pluginApi/store/Lang";
import {AgroExportTranslate_en} from "../translate/AgroExportTranslate_en";
import {AgroExportTranslate_ru, AgroExportTranslate_ru2} from "../translate/AgroExportTranslate_ru";
import {AgroAhoTranslate_en} from "../../agroAho/translate/AgroAhoTranslate_en";
import {AgroAhoTranslate_ru2} from "../../agroAho/translate/AgroAhoTranslate_ru";
import {AgroExportPointsStore} from "./agroExportPointsStore";


export class AgroExportStore extends  ObservableCustomStore{

    @observable
    private _export: IDictonaryType<IndexBandExportStore> = {};//new IndexBandExportStore(this);

    getIndexBandExportStore(prodName: string): IndexBandExportStore{
        if (this._export[prodName]) return this._export[prodName];
        let t = new IndexBandExportStore(this);
        this._export[prodName] = t;
        return t;
    }
    @update @observable
    voronovExportStore = new VoronovExportStore(this);
    pointsStore = new AgroExportPointsStore(this);


    @observable
    trans: AgroExportTranslate_en = new AgroExportTranslate_en();
    trans2: AgroExportTranslate_en = this.trans;
    calculateTranslate(){
        if (this.root.lang == Lang.ru) {
            this.trans = new AgroExportTranslate_ru();
            this.trans2 = new AgroExportTranslate_ru2();
        } else {
            this.trans = new AgroExportTranslate_en();
            this.trans.init();
            this.trans2 = this.trans;
        }
    }

}