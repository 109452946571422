import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from '../../../../../helper/structs/IStoreProps';
import {action} from "mobx";
import {LeftPanelMode} from "../../../../../store/SearchStore";

export interface IA2UploadSelectFileComp extends IStoreProps{
    onChangeRefInput: (elem: HTMLInputElement)=>any;
}
@observer
export class A2UploadSelectFileComp extends React.Component<IA2UploadSelectFileComp, undefined> {
    constructor(props: IA2UploadSelectFileComp) {
        super(props);
        autoBindReact(this);
    }
    uploadElement: HTMLInputElement = null;

    @action
    uploadChanged(e:any){
        let input = this.uploadElement;
        if (input.files.length == 0) return;
        let store = this.props.store;
        let file = e.target.files[0];
        input.value = '';
        this.props.store.searchPanel.switchPanel(LeftPanelMode.uploadFields);
        //store.agro2.uploadFieldsForm.showPanel = true;
        store.agro2.uploadFieldsForm.fetchColumnsStep1(file).then(a =>{
        }).catch(err => store.addError(err));
    }

    onChangeRef(node: any){
        this.uploadElement = node;
        this.props.onChangeRefInput(node);
    }

    render() {
        //let store = this.props.store;
        return <input type="file" style={{display: "none"}} accept=".kml, .kmz, .geojson, .json, .gpkg, .zip" onChange={this.uploadChanged}

                      ref={this.onChangeRef} />;
    }
}
