import {action} from "mobx";
import {SuperStore} from "../../store/SuperStore";
import * as QueryString from "qs";
import {isString, join, toNumber} from "lodash-es";
import {Utils} from "./Utils";
import {satDic, Sentinel2Level} from "./satellliteDic";
import {SearchSceneSourceType} from "../../store/SearchStore";
import {SearchParamsS5CompositeMethod, SearchParamsS5Type} from "../../store/SearchParamsS5Store";
import {LineString} from "geojson";
import {Sentinel1Mode} from "../../store/SearchParamsS1Store";
import {ra} from "./mobxUtils";
import {GeometryUtils} from "./GeometryUtils";
import {MeasuringStatus} from "../structs/MeasuringStatus";

export interface IUrlParams {
    zoom?: string,
    lat?: string,
    proj?: string,
    lng?: string,
    sceneId?: string,
    filterBegin?: string,
    filterEnd?: string,
    filterTiles?: string,
    filterCloud?: string,
    filterLevel?: string,
    satellites?: string,
    //geomId?: string,
    product?: string,
    sourceType?: string,
    s1mode?: string,
    baseLayer?: string,
    s5type?: string,
    s5year?: string,
    s5month?: string,
    s5begin?: string,
    s5end?: string,
    s5method?: string,
    ruler?: string,
    cad?: string,
    aho?: string
}

export class UrlCoder {
    @action
    public static readUrl(store: SuperStore, url: string): void {
        let u = "";
        if (url.indexOf("?") >= 0) u = url.substr(url.indexOf("?") + 1);

        let pm: IUrlParams = QueryString.parse(u);
        UrlCoder.readUrlParams(store, pm);

    }
    public static readUrlParams(store: SuperStore, pm: IUrlParams): void{
        if (isString(pm.zoom)) {
            let zoom = toNumber(pm.zoom);
            if (zoom >= 0 && zoom < 25) store.map.zoom = zoom;
        }
        if (isString(pm.lat) && isString(pm.lng)) {
            let lat = toNumber(pm.lat);
            let lng = toNumber(pm.lng);
            if (lat >= -180 && lat <= 180 && lng >= -180 && lng <= 180) {
                store.map.center.set(lat, lng);
            }
        }
        if (Utils.isStringNotEmpty(pm.sourceType)) {
            store.searchPanel.sourceType = pm.sourceType as SearchSceneSourceType;
        }
        if(store.searchPanel.sourceType == SearchSceneSourceType.sentinel5){
            if (Utils.isStringNotEmpty(pm.s5type)) {
                store.searchPanel.searchParamsS5.type = pm.s5type as SearchParamsS5Type;
                if (store.searchPanel.searchParamsS5.type == SearchParamsS5Type.scenes){
                    if (Utils.isStringNotEmpty(pm.s5month)) store.searchPanel.searchParamsS5.sceneMonth = parseFloat(pm.s5month);
                    if (Utils.isStringNotEmpty(pm.s5year)) store.searchPanel.searchParamsS5.sceneYear = parseFloat(pm.s5year);
                }
                if (store.searchPanel.searchParamsS5.type == SearchParamsS5Type.composites){
                    if (Utils.isStringNotEmpty(pm.s5begin)) store.searchPanel.searchParamsS5.compositeDate.begin = new Date(pm.s5begin);
                    if (Utils.isStringNotEmpty(pm.s5end)) store.searchPanel.searchParamsS5.compositeDate.end = new Date(pm.s5end);
                    if (Utils.isStringNotEmpty(pm.s5method)) store.searchPanel.searchParamsS5.compositeMethod = pm.s5method as SearchParamsS5CompositeMethod;
                }
            }
        }
        if (Utils.isStringNotEmpty(pm.s1mode)) {
            if (pm.s1mode == Sentinel1Mode.IW) store.searchPanel.searchParamsS1.mode = Sentinel1Mode.IW;
            if (pm.s1mode == Sentinel1Mode.EW) store.searchPanel.searchParamsS1.mode = Sentinel1Mode.EW;
        }
        if (Utils.isStringNotEmpty(pm.product)) {
            store.map.productInfo.activeProductsSet.activeCodeCurrent.setProductCodeWithExpand(pm.product);
        }
        if (Utils.isStringNotEmpty(pm.sceneId)) {
            store.searchPanel.currentSceneid = pm.sceneId as string;
        }
        if (isString(pm.filterBegin)) {
            store.searchPanel.filterDate.begin = new Date(pm.filterBegin);
        }
        if (isString(pm.filterEnd)) {
            store.searchPanel.filterDate.end = new Date(pm.filterEnd);
        }
        if (isString(pm.filterTiles)) {
            store.searchPanel.filterTiles = pm.filterTiles === "true";
        }
        if (isString(pm.filterCloud)) {
            store.searchPanel.filterCloud = Number.parseFloat(pm.filterCloud);
        }
        if (isString(pm.filterLevel)) {
            store.searchPanel.filterSentinel2_level = pm.filterLevel as Sentinel2Level;
        }
        if (isString(pm.ruler)){
            UrlCoder.setRulerAsString(store, pm.ruler);
        }
        if (isString(pm.proj)) {
            store.agro2.projectList.projectInfoRequest(pm.proj).then(() => {
            }).catch(e => {
                ra(()=>{
                    store.addError(e);
                });
            });
        }
        else
            store.map.overlays.init();
        if (isString(pm.satellites)){
            let sats = Utils.allKeys(satDic);
            let ss = pm.satellites.split(",");
            ss.forEach(a => {
                if (sats.includes(a)){
                    (<any>store.searchPanel.filterSatellites)[a] = true;
                }
            });
        }
        if (Utils.isStringNotEmpty(pm.baseLayer)) {
            store.map.currentBaselayerKey = pm.baseLayer;
        }else{
            if (store.config.map_layers.baselayers.length > 0){
                store.map.currentBaselayerKey = store.config.map_layers.baselayers[0].key;
            }
        }
        store.events.onReadUrl.call(pm);
    }

    public static makeUrlParams(store: SuperStore): IUrlParams{
        let p: IUrlParams = {};
        p.zoom = store.map.zoom.toFixed(2); 
        p.lat = store.map.center.lat.toFixed(4);
        p.lng = store.map.center.lng.toFixed(4);
        if (Utils.isStringNotEmpty(store.searchPanel.currentSceneid)) {
            p.sceneId = store.searchPanel.currentSceneid;
        }
        if (Utils.objectTruePropertiesCount(store.searchPanel.filterSatellites) > 0){
            let sats = Utils.allKeys(satDic);
            let satsArr: string[] = [];
            sats.forEach( a =>{
                if ((<any>store.searchPanel.filterSatellites)[a] === true){
                    satsArr.push(a);
                }
            });
            if (satsArr.length > 0){
                p.satellites = join(satsArr, ",");
            }
        }
        if (store.agro.projectName != null){
            p.proj = store.agro.projectName;
        }
        if (store.searchPanel.sourceType != SearchSceneSourceType.passive) {
            p.sourceType = store.searchPanel.sourceType;
        }
        if (store.searchPanel.sourceType == SearchSceneSourceType.sentinel1){
            p.s1mode = store.searchPanel.searchParamsS1.mode;
        }
        if (store.searchPanel.sourceType == SearchSceneSourceType.sentinel5){
            p.s5type = store.searchPanel.searchParamsS5.type;
            if (store.searchPanel.searchParamsS5.type == SearchParamsS5Type.scenes){
                p.s5year= store.searchPanel.searchParamsS5.sceneYear.toString();
                p.s5month= store.searchPanel.searchParamsS5.sceneMonth.toString();
            }
            if (store.searchPanel.searchParamsS5.type == SearchParamsS5Type.composites){
                if (store.searchPanel.searchParamsS5.compositeDate.isValidBegin()) p.s5begin = Utils.getDateStr(store.searchPanel.searchParamsS5.compositeDate.begin);
                if (store.searchPanel.searchParamsS5.compositeDate.isValidEnd()) p.s5end = Utils.getDateStr(store.searchPanel.searchParamsS5.compositeDate.end);
                p.s5method = store.searchPanel.searchParamsS5.compositeMethod;
            }
        }
        if (store.map.productInfo.activeProductsSet.activeCodeCurrent.productCode != "nat-c"){
            p.product = store.map.productInfo.activeProductsSet.activeCodeCurrent.productCode;
        }
        if (store.searchPanel.filterDate.isValidBegin()) {
            p.filterBegin = Utils.getDateStr(store.searchPanel.filterDate.begin);
        }
        let ruler = UrlCoder.getRulerAsString(store);
        if (ruler != null && ruler != "") p.ruler = ruler;
        if (store.searchPanel.filterDate.isValidEnd()) {
            p.filterEnd = Utils.getDateStr(store.searchPanel.filterDate.end);
        }
        if (store.searchPanel.filterTiles) {
            p.filterTiles = store.searchPanel.filterTiles.toString();
        }
        if (store.searchPanel.filterCloud != null && store.searchPanel.filterCloud < 100) {
            p.filterCloud = Math.round(store.searchPanel.filterCloud).toString();
        }
        if (store.searchPanel.filterSentinel2_level != Sentinel2Level.L2) {
            p.filterLevel = store.searchPanel.filterSentinel2_level.toString();
        }
        if (isString(store.map.currentBaselayerKey)) {
            p.baseLayer = store.map.currentBaselayerKey;
        }
        store.events.onWriteUrl.call(p);
        return p;
    }

    public static makeUrl(store: SuperStore): string {
        let p: IUrlParams = UrlCoder.makeUrlParams(store);
        let arr: string[] = [];
        for (let k in p) {
            arr.push( encodeURIComponent(k) +"="+ encodeURIComponent((<any>p)[k]));
        }

        return "?"+join(arr, "&");
    }

    public static getUrlPathOnly(): string{
        return window.location.pathname;
    }
    public static getQueryParams():any{
        let paramsUrlSrc = window.location.search;
        let paramsUrl = paramsUrlSrc;
        if (paramsUrl.indexOf("?") >= 0) paramsUrl = paramsUrl.substr(paramsUrl.indexOf("?") + 1);
        let pm: any = {};
        if (paramsUrl) pm = QueryString.parse(paramsUrl);
        return pm;
    }

    //недописал
    public static getRulerAsString(store: SuperStore): string{
        let g = store.map.rulerInfo.rulerManager.state.getGeometry();
        if (g == null || g.type != "LineString" || g.coordinates == null || g.coordinates?.length == 0) return "";
        return g.coordinates.map(a => a[0].toFixed(5)+'~'+a[1].toFixed(5)).join(",");
    }
    public static setRulerAsString(store: SuperStore, s:string):void{
        if (s == null || s == "") return ;
        let g = <LineString>{type: "LineString", coordinates: []};


        s.split(",").forEach(a => {
            let c = a.split("~").map(a => Utils.parseNumber(a));
            g.coordinates.push(c);
        });
        let state = store.map.rulerInfo.rulerManager.state
        state.simpleGeometry = GeometryUtils.getSimpleGeometries(g);
        //store.map.setMeasuringStatus(MeasuringStatus.Ruler2Edit);
    }
}
