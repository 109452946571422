// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoWindowComp-main {
  box-sizing: border-box;
  position: absolute;
  background: #2B3138;
  border: 1px solid #22282F;
  border-radius: 8px;
  z-index: 1000;
  cursor: default;
}

.AgroAhoWindowComp-hidden {
  display: none;
}

.AgroAhoWindowComp-button {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/AgroAhoWindowComp.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,kBAAA;EAKA,mBAAA;EACA,yBAAA;EAEA,kBAAA;EACA,aAAA;EACA,eAAA;AAJF;;AAOA;EACE,aAAA;AAJF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAHF","sourcesContent":[".AgroAhoWindowComp-main {\r\n  box-sizing: border-box;\r\n  position: absolute;\r\n  //width: 287px;\r\n  //height: 286px;\r\n  //left: 35px;\r\n  //top: -3px;\r\n  background: #2B3138;\r\n  border: 1px solid #22282F;\r\n  //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\r\n  border-radius: 8px;\r\n  z-index: 1000;\r\n  cursor: default;\r\n}\r\n\r\n.AgroAhoWindowComp-hidden {\r\n  display: none;\r\n}\r\n.AgroAhoWindowComp-button {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
