import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {ISearchItemComp} from "./SearchSceneItemComp";
import classNames from "classnames";
import {CheckStates} from "../../../../helper/structs/CheckStates";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {CenterSVG} from "../../../icons/CenterSVG";
import {Utils} from "../../../../helper/utils/Utils";
import {action} from "mobx";

export interface ISearchSceneItemPropertyComp extends ISearchItemComp{
    showTooBig: boolean,
}
@observer
export class SearchSceneItemPropertyComp extends React.Component<ISearchSceneItemPropertyComp, undefined> {
    constructor(props: ISearchSceneItemPropertyComp) {
        super(props);
        autoBindReact(this);
    }


    onClickImage(){
        let si = this.props.searchGroup;
        let it = si.first().feature.properties;
        Utils.copyToClipboard(it.scene_id);
    }

    toCenter(event: any){
        let si = this.props.searchGroup;
        let bbox = si.getBbox();
        this.props.store.map.zoomFlyToBBox(bbox);
        event.stopPropagation();
    }

    @action
    onClickFavorite(event: any){
        if (this.props.showTooBig) return;
        let store = this.props.store;
        let si = this.props.searchGroup;
        let isFav = store.searchPanel.isFavoritesSceneIds(si.getSceneIds());
        if (isFav == CheckStates.checked){
            store.searchPanel.favoriteList.removeManySceneItem(si.items);
            store.searchPanel.favoriteList.totalRecords = store.searchPanel.favoriteList.records.length;
            si.first().animate_fav_icon = true;
        } else{
            si.setSelected(true);
            store.map.mapScenesStore.favoriteScenesVisible = true;
            store.searchPanel.favoriteList.appendNewSceneItems(si.items.slice(0));
            store.searchPanel.favoriteList.totalRecords = store.searchPanel.favoriteList.records.length;
            si.first().animate_fav_icon = true;
        }
        event.stopPropagation();
    }

    render() {
        let store = this.props.store;
        let sg = this.props.searchGroup;
        let it = sg.first().feature.properties;
        let is_favorite = store.searchPanel.isFavoritesSceneIds(sg.getSceneIds());

        let multiScene = (sg.items.length > 1);
        let cloud: string = null;
        if (it.cloud != null) {
            cloud = it.cloud.toString() + '%';
            if (multiScene) {
                let min = it.cloud;
                let max = it.cloud;
                sg.items.forEach(a => {
                    if (a.feature.properties.cloud > max) max = a.feature.properties.cloud;
                    if (a.feature.properties.cloud < min) min = a.feature.properties.cloud;
                });
                cloud = min.toString() + "-" + max.toString() + "%";
            }
        }

        let sunelev: string = null;
        if (it.sunelev != null) {
            sunelev = it.sunelev.toString() + '°';
            if (multiScene) {
                let min = it.sunelev;
                let max = it.sunelev;
                sg.items.forEach(a => {
                    if (a.feature.properties.sunelev > max) max = a.feature.properties.sunelev;
                    if (a.feature.properties.sunelev < min) min = a.feature.properties.sunelev;
                });
                sunelev = min.toString() + "-" + max.toString() + "°";
            }
        }

        return <div className="left-sidebar__right-bottom__row4-in-right pointer hidden_row">
            <div className="left-sidebar__right-bottom__row4-in-right-el">
                <div className="calendar_table dark-blue" onClick={this.onClickImage}/>
                {it.acqdate} {!multiScene && it.acqtime}
                <div className="pointer right_row_icon_iconbox" onClick={this.onClickFavorite}>
                    <div className={classNames("hidden_row_icon", {
                        "favorites_table": (is_favorite == CheckStates.unchecked),
                        "favorites_table_added": (is_favorite == CheckStates.checked),
                        "favorites_table_partial": (is_favorite == CheckStates.partial),
                        "animate__animated animate__bounceIn": sg.first().animate_fav_icon,
                        "hidden_row_visible": !sg.first().animate_fav_icon
                    })}
                    />
                </div>
            </div>
            <div className="left-sidebar__right-bottom__row4-in-separator"/>
            <div className="left-sidebar__right-bottom__row4-in-right-el">
                <div className="satellite_table svg_table dark-blue"/>
                {it.satellite}
            </div>
            <div className="left-sidebar__right-bottom__row4-in-right-el">
                <div className="angle_table dark-blue"/>
                {sunelev}
            </div>
            <div className="left-sidebar__right-bottom__row4-in-right-el">
                <div className="cloud-sun_table dark-blue"/>
                {cloud}
            </div>
            <div className="left-sidebar__right-bottom__row4-in-right-el">
                {!multiScene && <div className="maximize-area_table dark-blue"/>}
                {!multiScene && it.gridcode}
                {multiScene && <span>{TranslateUtils.format(store.trans["{0} scenes"], sg.items.length)}</span>}
                <div className="pointer right_row_icon_iconbox" onClick={this.toCenter} >
                    <CenterSVG className="settings-pref hidden_row_icon"/>
                </div>
            </div>
        </div>;
    }
}
