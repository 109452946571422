import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {observable} from "mobx";
import {fetchBytesPost} from "../../helper/utils/FetchUtils";
import {Utils} from "../../helper/utils/Utils";
import {AutoPolygonModel} from "../agro/fieldForm/FieldAutoStore";

export enum ModelAutoField{
    "U-Net",
    "U-Net++",
    "ResU-Net"
}
interface IResponseFieldDelineation{
    date_end: string;//"2023-03-06"
    date_start: string;//"2023-03-01"
    mgrs: string;// "37TGM"
    model: string;// "unet_valid"
    year: number;//2022;
}
export class FieldsDelineationStore extends CustomStore{
    @save @observable
    periodByYear: boolean;
    @save @observable
    year: number = 2022;

    @save @observable
    modelField: AutoPolygonModel = AutoPolygonModel.unetValid;
    //Sentinel-2 grid cell
    @save @observable
    gridCell: string = "";

    loadInfo(resp: IResponseFieldDelineation){
        this.gridCell = resp.mgrs;
        this.modelField = AutoPolygonModel.unetValid;
        this.root.exportStore.period.begin = new Date(resp.date_start);
        this.root.exportStore.period.end = new Date(resp.date_end);
    }
    async doExport(){
        let dateFrom: string;
        let dateTo: string;
        let exp = this.root.exportStore;
        let args: any = {
            "order": {"name":exp.projectName, "email": exp.email},
            "model": 'unet-valid',
            "mgrs": this.gridCell,
            "date_start": dateFrom,
            "date_end": dateTo};
        if (this.periodByYear){
            args.year = this.year;
        }else{
            args.date_start = Utils.getDateStr(this.root.exportStore.period.begin);
            args.date_end = Utils.getDateStr(this.root.exportStore.period.end);
        }

        let r = await fetch('/api/proc_proxy/orders/fields_mgrs',
            {method: 'POST', body: JSON.stringify(args)});

        if (!r.ok){
            let txt = await r.text();
            throw "Error. " + txt;
        }
    }
}