import {ObservableCustomStore} from "../CustomStore";
import {action, computed, observable} from "mobx";
import {A2ColumnItemStore, ProjectStructItemType} from "./A2ColumnItemStore";
import {save} from "../PermalinkDecor";
import {A2NavigatorItemStore, NavigatorItemStoreType} from "./A2NavigatorItemStore";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {Feature} from "geojson";

export class A2ProjectStructStore extends ObservableCustomStore{

    @save @observable
    columns_items: A2ColumnItemStore[] = [];
    @observable
    loadProjectStatus: LoadStatus = null;


    @computed
    get columns(): Map<string, A2ColumnItemStore>{
        let map = new Map<string, A2ColumnItemStore>();
        this.columns_items.forEach(a => {
            map.set(a.id, a);
        });
        return map;
    }
    getColumnByName(column_name: string): A2ColumnItemStore{
        return this.columns.get(column_name);
    }

    @save @observable
    editor_structIds: string[] = [];

    @save @observable
    structIds: string[] = [  ];

    @computed
    get structColumns(): A2ColumnItemStore[]{
        return this.structIds.map(a => this.columns.get(a));
    }

    @save @observable
    navigatorRootItem: A2NavigatorItemStore = null;

    beforeLoadPermalink(){

    }
    @computed
    get allNavigatorItems(): A2NavigatorItemStore[]{
        let r: A2NavigatorItemStore[] = [];
        let t: A2NavigatorItemStore = this.navigatorRootItem;
        while (t != null){
            r.push(t);
            t = t.nextItem;
        }
        return r;
    }

    initFirstPage(): A2NavigatorItemStore{
        if (this.navigatorRootItem != null){
            this.navigatorRootItem.dispose();
            this.navigatorRootItem = null;
        }
        this.navigatorRootItem = new A2NavigatorItemStore(this);
        if (this.structColumns.length > 0) {
            let s = this.structColumns[0];
            if (s == null) throw `Group column is not found ${this.structIds[0]}`;
            this.navigatorRootItem.id_column = s.id;
        }else{
            this.navigatorRootItem.type = NavigatorItemStoreType.fields;
        }
        this.root.pluginsApi.onSelectProject();
        return this.navigatorRootItem;
    }

    @computed
    get activeNavigatorPage(): A2NavigatorItemStore{
        let t: A2NavigatorItemStore = this.navigatorRootItem;
        while (t != null){
            if (t.activePage) return t;
            t = t.nextItem;
        }
        return null;
    }
    @computed
    get fieldsPage(): A2NavigatorItemStore{
        if (this.lastNavigatorItem == null) return null;
        if (this.lastNavigatorItem.fields == null) return null;
        return this.lastNavigatorItem;
    }

    @computed
    get lastNavigatorItem(): A2NavigatorItemStore{
        let t: A2NavigatorItemStore = this.navigatorRootItem;
        while (t != null && t.nextItem != null){
            t = t.nextItem;
        }
        return t;
    }
    setActivePage(item: A2NavigatorItemStore){
        let oldActive = this.root.agro2.projectStruct.activeNavigatorPage;
        if (oldActive != null){
            oldActive.filter_string = "";
        }
        if (item != null) item.filter_string = "";
        let t = this.root.agro2.projectStruct.navigatorRootItem;
        while (t != null){
            if (t == item) t.activePage = true; else t.activePage = false;
            t = t.nextItem;
        }
    }
    @action
    to_up_folder(){
        let navItem = this.activeNavigatorPage;
        if (navItem.parentItem == null) return;
        this.setActivePage(navItem.parentItem);
    }
    setPathByField(field: Feature){
        if (this.navigatorRootItem == null){
            this.initFirstPage();
        }
        let navItem = this.navigatorRootItem;
        let needReset = false;
        for(let i = 0; i < this.structIds.length; i++){
            if (navItem.column_value != field.properties[navItem.columnInfo.column_name]){
                navItem.column_value = field.properties[navItem.columnInfo.column_name];
                navItem.column_stringValue = field.properties[navItem.columnInfo.column_nameString];
                needReset = true;
            }

            if (navItem.nextItem == null){
                if (i == this.structIds.length - 1){
                    navItem.nextItem = new A2NavigatorItemStore(navItem);
                    navItem.nextItem.type = NavigatorItemStoreType.fields;
                }else{
                    navItem.nextItem = new A2NavigatorItemStore(navItem);
                    navItem.nextItem.id_column = this.structColumns[i + 1].id;
                }
            }else{
                if (i == this.structIds.length - 1) {
                    navItem.nextItem.type = NavigatorItemStoreType.fields;
                }
            }
            if (needReset) {
                navItem.resetValues();
                navItem.loadValues();
                if (i == this.structIds.length - 1){
                    navItem.nextItem.resetValues();
                    navItem.nextItem.loadValues();
                }
            }
            navItem = navItem.nextItem;
        }
        this.root.agro2.projectStruct.setActivePage(this.lastNavigatorItem);
    }

    @action
    resetAllValues(){
        let t: A2NavigatorItemStore = this.navigatorRootItem;
        while (t != null){
            t.resetValues();
            t = t.nextItem;
        }
        return t;
    }
}