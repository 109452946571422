import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../helper/structs/IStoreProps";
import {UrlCoder} from "../helper/utils/UrlCoder";
import autoBindReact from "auto-bind/react";
import {SuperStore} from "../store/SuperStore";

@observer
export class UrlSyncComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    static first: boolean = true;

    static updateUrl(store: SuperStore){
        let s = UrlCoder.makeUrl(store);
        if (UrlSyncComp.first) {
            history.pushState({}, "", s);
            UrlSyncComp.first = false;
        }else {
            history.replaceState({}, "", s);
        }
    }

    render() {
        let store = this.props.store;
        UrlSyncComp.updateUrl(store);
        return true;
    }
}
