import {IGraphData, IGraphDataInfo, IIndexValue, IRequestProdStat, IStatIndex} from "../IndexByPointGraphStore";
import {action, observable, runInAction} from "mobx";
import {IDictonaryType, Utils} from "../../../helper/utils/Utils";
import {sortBy} from "lodash-es";
import {IndexAreaType} from "../IndexByPointGraphPointStore";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {fetchJson} from "../../../helper/utils/FetchUtils";
import {IndexByPointerSourceCustomStore} from "./IndexByPointerSourceCustomStore";

export class IndexByPointerSourceS1IwStore extends IndexByPointerSourceCustomStore{
    loading_params: IRequestProdStat = null;
    @observable
    private ndviData: IIndexValue[] = [];

    @action
    reset() {
        super.reset();
        this.ndviData = [];
    }

    getGraphData(): IGraphDataInfo{
        let arr = this.getIndexesWithFakeDate();
        let res: IGraphData[] = [];
        let productCode = this.parent.parent.polarIW;
        arr.forEach(a => {
            res.push({
                dayOfYear: Utils.getDayOfYearRelativeByGlobalAllLeapYears(new Date(a.date), this.parent.year),
                absoluteDate: (new Date(a.date)).getTime(),
                sceneID: a.sceneID,
                value: (a.stat != null) ? Math.round(a.stat[productCode].avg * 10000) / 10000 : null
            });
        });
        return {data: res, hasMinMax: false};
    }

    private getIndexesWithFakeDate(): IIndexValue[]{
        if (this.status == null) setImmediate(()=> { this.load(); });

        let arr1 = this.ndviData;
        if (this.parent.isClimate) return [];
        if (arr1.length == 0) return [];
        if (arr1.length == 1) return arr1;
        arr1 = sortBy(arr1, a => (new Date(a.date)).getTime());
        let arr2: IIndexValue[] = [];
        let year = this.parent.year;
        arr2.push(arr1[0]);
        for(let i = 1; i < arr1.length; i++){
            let prev = arr1[i - 1];
            let prevDate = new Date(prev.date);
            let cur = arr1[i];
            let curDate = new Date(cur.date);
            let curDay = Utils.getDayOfYearRelativeByGlobalAllLeapYears(curDate, year);
            let prevDay = Utils.getDayOfYearRelativeByGlobalAllLeapYears(prevDate, year);
            let productCode = this.parent.parent.polarIW;

            if ((curDay - prevDay) > 1){
                for(let i1 = prevDay + 1; i1 < curDay; i1++){
                    let stat: IDictonaryType<IStatIndex> = {};
                    try {
                        let avg = Utils.lineInterpolation(
                            Utils.getDateByRelativeByGlobalAllLeapYears(i1, year).getTime(),
                            prevDate.getTime(),
                            curDate.getTime(),
                            prev.stat[productCode].avg,
                            cur.stat[productCode].avg
                        );
                        stat[productCode] = {avg: avg, pxl_x: 0, pxl_y: 0, tile_x: 0, tile_y: 0};
                        let d = Utils.getDateByRelativeByGlobalAllLeapYears(i1, year);
                        let v: IIndexValue = {
                            date: (d).toISOString(),
                            sceneID: null,
                            stat: stat
                        };
                        arr2.push(v);
                    }catch (e) {
                        this.root.addError(i1);
                    }
                }
            }
            arr2.push(cur);
        }
        return arr2;
    }

    private getRequestParams(): IRequestProdStat{
        let q = this.parent;
        let gp = q.gPoint;
        let reqParams: IRequestProdStat ={
            product: "VV,VH",
            scene_source: "s1",
            mode: "chart"
        };
        let filter:any = {};
        if (gp.areaType == IndexAreaType.point){
            reqParams.lat = gp.point.lat;
            reqParams.lon = gp.point.lng;
        }
        if (gp.areaType == IndexAreaType.field){
            reqParams.dskey = this.root.agro2.projectInfo.getFieldVectorLayerId();
            reqParams.column = this.root.agro2.projectInfo.fieldName_id;
            reqParams.objkey = gp.field_id;
            reqParams.precalc = 1;
        }

        let {dateBegin, dateEnd} = this.parent.getDateInterval();

        filter.acqdate = {"$>=": Utils.dateOnlyToISOString(dateBegin),"$<=": Utils.dateOnlyToISOString(dateEnd),

        };
        filter.mode = "IW";
        reqParams.filter = filter;
        reqParams.mask = "cloudless";

        if (q.radius != null){
            reqParams.rm = q.radius;
        }
        return reqParams;
    }

    @action
    private load(){
        let reqParams: IRequestProdStat = this.getRequestParams();
        let oldParams = reqParams;
        let q = this;
        q.loading_params = reqParams;
        q.status = LoadStatus.loading;
        fetchJson(this.root.indexByPointer.getProdStatUrl()+"?"+Utils.queryEncodeParams(reqParams))
            .then(json =>{
                runInAction(()=>{
                    let j: IIndexValue[] = json;
                    j.filter(a => a.error != null).forEach(a => {
                        if (a.error.show != false)
                            this.root.addError(a.error.message);
                    });

                    if (q.loading_params === oldParams && q.status == LoadStatus.loading){
                        q.ndviData = j.filter(a => a.error == null);
                        q.status = LoadStatus.ready;
                    }
                });
            })
            .catch(err =>{
                runInAction(()=> {
                    this.root.addError(err);
                    if (q.loading_params === oldParams && q.status == LoadStatus.loading) {
                        q.ndviData = [];
                        q.status = LoadStatus.ready;
                    }
                });
            });
    }

}