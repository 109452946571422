import {CustomStoreTool} from "../general/CustomStoreTool";

export class ShowRoomLayersTool extends CustomStoreTool{
    //disp: IReactionDisposer = null;

    onInstall() {
        this.bindToMap(this.store.map.superMap);
    }

    onUninstall() {
        this.unbind(this.store.map.superMap);
    }


    bindToMap(rootStore: MapRootStore){
        let mapbox: mapboxgl.Map = this.map;
        let layerIds: string[] = [];
        let ths = this;
        rootStore.sources.forEach(s => {
            if (mapbox.getSource(s.sourceId) == null)
                mapbox.addSource(s.sourceId, s.getSourceJson());
        });

        doFolder(rootStore.rootFolder);
        return layerIds;
        function doFolder(folder: MapFolderItemStore){
            folder.items.forEach(f => {
                if (f.itemType == MapItemType.folder){
                    doFolder(f as MapFolderItemStore);
                }else
                if (f.itemType == MapItemType.layer){
                    doLayer(f as MapLayerItemStore);
                }
            });
        }
        function doLayer(layer: MapLayerItemStore){
            layer.subLayers.forEach(sl => {
                if (mapbox.getLayer(sl.layerId) == null) {
                    ths.addLayer(sl.getLayerJson());
                    layerIds.push(sl.layerId);
                }
            });
        }
    }

    unbind(rootStore: MapRootStore){
        let mapbox: mapboxgl.Map = this.map;
        let ths = this;
        doFolder(rootStore.rootFolder);
        function doFolder(folder: MapFolderItemStore){
            folder.items.forEach(f => {
                if (f.itemType == MapItemType.folder){
                    doFolder(f as MapFolderItemStore);
                }else
                if (f.itemType == MapItemType.layer){
                    doLayer(f as MapLayerItemStore);
                }
            });
        }
        function doLayer(layer: MapLayerItemStore){
            layer.subLayers.forEach(sl => {
                if (mapbox.getLayer(sl.layerId) != null)
                    ths.removeLayer(sl.layerId);
            });
        }
        rootStore.sources.forEach(s => {
            if (mapbox.getSource(s.sourceId) != null)
                mapbox.removeSource(s.sourceId);
        });
    }
    setVisible(layerId: string, value: boolean){
        this.map.setLayoutProperty(layerId, 'visibility', value? 'visible' : 'none');
    }
}
import * as mapboxgl from "maplibre-gl";
import {MapFolderItemStore} from "../../map/mapLayers/MapFolderItemStore";
import {MapLayerItemStore} from "../../map/mapLayers/MapLayerItemStore";
import {MapRootStore} from "../../map/mapLayers/MapRootStore";
import {MapItemType} from "../../config/MapConfigInterfaces";
