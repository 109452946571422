import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {MeasuringStatus} from "../../../../helper/structs/MeasuringStatus";
import {MyHint} from "../../../Common/MyHint";
import { FiltersSvg } from '../../../icons/FiltersSvg';


@observer
export class SearchSceneTitleComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickButtonShowSearchProperty(){
        this.props.store.searchPanel.switchParamsPanel();
    }

    render() {
        let store = this.props.store;
        let active = store.searchPanel.showParamsPanel;
        let blue_dot: boolean = [MeasuringStatus.Point,
            MeasuringStatus.Rectangle,
            MeasuringStatus.Polygon,
            MeasuringStatus.Polyline,
            MeasuringStatus.Circle].findIndex(a => a == store.map.measuringStatus) >= 0;

        return <div className="left-sidebar__right-bottom__row1 relative">
                <div className="left-sidebar__right-bottom__row1-left">{store.trans["Image search"]}</div>
            <MyHint text={store.trans["Search options"]}>
                <div className={
                    classNames("icon_container icon_container_fill icon_container-preferences qtransit",
                        {"icon_container-preferences-active": active})}
                     onClick={this.onClickButtonShowSearchProperty}>
                        <FiltersSvg className={classNames({"white-fill white-stroke": !active, "blue-stroke blue-fill": active})} />
                    {/*<svg width="36" height="23" viewBox="0 0 36 23" xmlns="http://www.w3.org/2000/svg" className={classNames({"white-fill white-stroke": !active, "blue-stroke blue-fill": active})}>
                        <path d="M10.4546 8.18164H17" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 8.18164H3.18182" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.2727 14H17" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 14H9" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.4546 19.8184H17" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 19.8184H3.18182" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.36358 10.3636C6.56857 10.3636 7.5454 9.3868 7.5454 8.18182C7.5454 6.97683 6.56857 6 5.36358 6C4.1586 6 3.18176 6.97683 3.18176 8.18182C3.18176 9.3868 4.1586 10.3636 5.36358 10.3636Z" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.1818 16.182C12.3868 16.182 13.3636 15.2052 13.3636 14.0002C13.3636 12.7952 12.3868 11.8184 11.1818 11.8184C9.97683 11.8184 9 12.7952 9 14.0002C9 15.2052 9.97683 16.182 11.1818 16.182Z" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.36358 21.9999C6.56857 21.9999 7.5454 21.023 7.5454 19.818C7.5454 18.6131 6.56857 17.6362 5.36358 17.6362C4.1586 17.6362 3.18176 18.6131 3.18176 19.818C3.18176 21.023 4.1586 21.9999 5.36358 21.9999Z" fill="none" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <g clipPath="url(#clip0)">
                            <path d="M30 13.9995C30 13.8365 29.9205 13.6835 29.7865 13.59L24.7865 10.09C24.6345 9.98303 24.4345 9.97103 24.269 10.056C24.1035 10.1425 24 10.313 24 10.4995V17.5C24 17.6865 24.1035 17.8575 24.269 17.9435C24.4345 18.0285 24.634 18.0165 24.7865 17.9095L29.7865 14.4095C29.9205 14.3165 30 14.1635 30 14.0005C30 14 30 14 30 13.9995C30 14 30 14 30 13.9995Z" stroke="none"/>
                        </g>
                        {blue_dot && <circle cx="32.5" cy="3.5" r="3.5" fill="#4DB6BC" stroke="none"/>}
                        <defs>
                            <clipPath id="clip0">
                                <rect width="8" height="8" fill="white" stroke="none" transform="translate(23 10)"/>
                            </clipPath>
                        </defs>
                    </svg>*/}
                </div>
            </MyHint>
        </div>;
    }
}
