import React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './FavoritePanel.scss';
import { SceneUiType } from "../../../../store/SearchStore";
import { MyHint } from "../../../Common/MyHint";
import classNames from "classnames";
import { Translate_en } from "../../../../helper/lang/Translate_en";

interface IFavSceneTitleCompProps {
    trans: Translate_en;
    //active: boolean;
    enabled: boolean;
    exportEnabled: boolean;
    //currentProduct: string;
    onClickExport: () => void;
    //onClickNdvi: () => void;
}

const FavSceneTitleComp = (props: IFavSceneTitleCompProps) => {
    return (<div className="FavSceneTitleComp">
        <span className="FavSceneTitleComp-title">{props.trans['Selected images']}</span>
        <div className={classNames("FavSceneTitleComp-buttons", {"disable": !props.enabled})}>
            <MyHint text={props.trans.ExportTo} key="exportButton2">
                <div className={classNames("SearchSceneButtonsComp-export", {"active": props.enabled && props.exportEnabled,
                    "pointer":props.exportEnabled, "opacity30": props.enabled && !props.exportEnabled} )} 
                    onClick={props.onClickExport}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 16H1C0.4 16 0 15.6 0 15V3C0 2.4 0.4 2 1 2H4V4H2V14H14V9H16V15C16 15.6 15.6 16 15 16Z" fill="#C5C5C5"/>
                        <path d="M10 3C6.8 3 4 5.5 4 10C5.1 8.3 6.4 7 10 7V10L16 5L10 0V3Z" fill="#C5C5C5"/>
                    </svg>
                </div>
            </MyHint>
            {/* <MyHint text={props.trans.Products}>
                <button className={classNames("FavSceneTitleComp left-sidebar__right-bottom__row3_right_button",
                    {"text-blue": props.active})} onClick={props.onClickNdvi}>
                    <div className={classNames("ndvi qtransit", {"gray": !props.active, "blue": props.active})}/>
                    {props.currentProduct}
                    <div className={classNames("arrow_pref qtransit", {"gray": !props.active, "blue": props.active, "any-skale-x": props.active})}/>
                </button>
            </MyHint> */}
        </div>
    </div>);
}

export default FavSceneTitleComp;