// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContextItems-outer {
  background: #2B3138;
  border-radius: 8px;
  border: 1px solid #3E4751;
  padding: 5px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.ContextItems-item {
  padding: 6px 10px;
}

.ContextItems-item-disabled {
  cursor: default;
  color: #6B707B;
}

.ContextItems-item-active {
  cursor: pointer;
  color: #C5C5C5;
}

.ContextItems-item-active:hover {
  background: #383f48;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/ContextItems.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EAEA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAEA;EACE,eAAA;EACA,cAAA;AACF;;AACA;EACE,eAAA;EACA,cAAA;AAEF;;AAAA;EACE,mBAAA;AAGF","sourcesContent":[".ContextItems-outer{\r\n  background: #2B3138;\r\n  border-radius: 8px;\r\n  border: 1px solid #3E4751;\r\n  padding: 5px 0;\r\n\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  font-size: 12px;\r\n  line-height: 14px;\r\n\r\n}\r\n.ContextItems-item{\r\n  padding: 6px 10px;\r\n}\r\n.ContextItems-item-disabled{\r\n  cursor: default;\r\n  color: #6B707B;\r\n}\r\n.ContextItems-item-active{\r\n  cursor: pointer;\r\n  color: #C5C5C5;\r\n}\r\n.ContextItems-item-active:hover{\r\n  background: #383f48;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
