// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndexByPointPopupSettingsRadarComp-main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #C5C5C5;
}

.IndexByPointPopupSettingsRadarComp-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.IndexByPointPopupSettingsRadarComp-radiusLabel {
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 8px;
}

.IndexByPointPopupSettingsRadarComp-radiusInput {
  width: 140px;
}

.IndexByPointPopupSettingsRadarComp-polar {
  display: flex;
  margin-left: 30px;
  margin-bottom: 11px;
}

.IndexByPointPopupSettingsRadarComp-polarLabel {
  font-weight: 400;
  font-size: 13px;
  color: #646B77;
}

.IndexByPointPopupSettingsRadarComp-polarItems {
  margin-left: 9px;
  font-weight: 400;
  font-size: 13px;
  display: flex;
}

.IndexByPointPopupSettingsRadarComp-polarItemLabel {
  font-weight: 400;
  font-size: 13px;
  padding: 0 15px 0 8px;
}

.IndexByPointPopupSettingsRadarComp-divider {
  padding-top: 15px;
  border-bottom: 1px solid #242930;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/IndexByPoint/Popups/IndexByPointPopupSettingsRadarComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;AACF;;AACA;EACE,aAAA;EACA,iBAAA;EACA,qBAAA;AAEF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;AAAF;;AAEA;EACE,YAAA;AACF;;AACA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAGF;;AADA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;AAIF;;AAFA;EACE,gBAAA;EACA,eAAA;EACA,qBAAA;AAKF;;AAHA;EACE,iBAAA;EACA,gCAAA;AAMF","sourcesContent":[".IndexByPointPopupSettingsRadarComp-main{\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex-wrap: nowrap;\r\n  color: #C5C5C5;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-row{\r\n  display: flex;\r\n  flex-wrap: nowrap;\r\n  align-items: flex-end;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-radius{\r\n\r\n}\r\n.IndexByPointPopupSettingsRadarComp-radiusLabel{\r\n  font-weight: 400;\r\n  font-size: 10px;\r\n  margin-bottom: 8px;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-radiusInput{\r\n  width: 140px;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-polar{\r\n  display: flex;\r\n  margin-left: 30px;\r\n  margin-bottom: 11px;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-polarLabel{\r\n  font-weight: 400;\r\n  font-size: 13px;\r\n  color: #646B77;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-polarItems{\r\n  margin-left: 9px;\r\n  font-weight: 400;\r\n  font-size: 13px;\r\n  display: flex;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-polarItemLabel{\r\n  font-weight: 400;\r\n  font-size: 13px;\r\n  padding: 0 15px 0 8px;\r\n}\r\n.IndexByPointPopupSettingsRadarComp-divider{\r\n  padding-top: 15px;\r\n  border-bottom: 1px solid #242930;\r\n  //margin-bottom: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
