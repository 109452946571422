import * as React from 'react';
import {RefObject} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {Utils} from "../../../helper/utils/Utils";
import {action, observable, runInAction} from "mobx";
import "./PermalinkPanelComp.scss";
import classNames from "classnames";

@observer
export class PermalinkPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.doGetPermalink();
        this.ref = React.createRef();
    }

    async doGetPermalink(){
        try {
            let id = await this.props.store.putPermalinkAndGetId();
            this.text = window.location.origin+window.location.pathname+"?link="+id;
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    onClickCopyToClipboard(){
        Utils.copyToClipboard(this.text);
        this.ref.current.select();
        this.copied = true;
        this.copied_start_animation = true;
        this.copied_finish_animation = false;
        setTimeout(()=>{ runInAction(()=>{
            this.copied = false;
            this.copied_start_animation = false;
            this.copied_finish_animation = true;
            })}, 5000);
    }

    @action
    onAnimationEnd(){
        this.copied_finish_animation = false;
        this.copied_start_animation = false;
    }

    @observable
    text: string = null;
    @observable
    copied: boolean = false;
    @observable
    copied_start_animation: boolean = false;
    @observable
    copied_finish_animation: boolean = false;

    ref: RefObject<HTMLInputElement> = null;

    render() {
        let store = this.props.store;
        //animate__bounceIn
        //
        return <div className="permalinkPanel-outer">
            {(this.copied || this.copied_finish_animation || this.copied_start_animation)
            && <div className="permalinkPanel-copied-box flex-align-items-center flex-columns">
                <div className={classNames("permalinkPanel-copied-label", {
                    "animate__animated animate__bounce": this.copied_start_animation,
                    "animate__hinge": this.copied_finish_animation
                })} onAnimationEnd={this.onAnimationEnd}
                >{store.trans["Copied!"]}</div>
            </div>}
            <div className="permalinkPanel">
                <input type="text" className="permalinkPanel-input" ref={this.ref}
                       value={(this.text == null) ? "..." : this.text}
                       readOnly={true}/>
                <div className="permalinkPanel-copy full-center-content" onClick={this.onClickCopyToClipboard}>
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.5625 8.25H0.6875C0.307313 8.25 0 7.94269 0 7.5625V0.6875C0 0.308 0.307313 0 0.6875 0H7.5625C7.94269 0 8.25 0.308 8.25 0.6875V7.5625C8.25 7.94269 7.94269 8.25 7.5625 8.25Z"
                            fill="#C5C5C5"/>
                        <path d="M10.3125 11H2.75V9.625H9.625V2.75H11V10.3125C11 10.6927 10.6927 11 10.3125 11Z"
                              fill="#C5C5C5"/>
                    </svg>
                </div>
            </div>
        </div>;
    }
}
