import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {ColorHelper, IRGBA} from "../../../helper/utils/ColorHelper";
import {isString} from "lodash-es";
import {MyHint} from "../../Common/MyHint";
import {Utils} from "../../../helper/utils/Utils";
import { StringLiteral } from 'typescript';
import classNames from "classnames";
import {LegendCheckBoxComp} from "./LegendCheckBoxComp";

export interface ILegendLinearComp extends IStoreProps{
    min: number,
    max: number;

    transparentMore?: boolean;
    transparentLess?: boolean;
    onChangeTransparent?: (options: {isMore: boolean, isLess: boolean, value: boolean})=>any;
    visibleCheckbox: boolean;
    title?: string;
    description?: string;
    colors: IRGBA[];
    values: number[];
    hintClassName?: string;
    top_name?: string;
    hideMoreLess?: boolean;
}


@observer
export class LegendLinearComp extends React.Component<ILegendLinearComp, undefined> {
    constructor(props: ILegendLinearComp) {
        super(props);
    }

    static toExpNum(num: number): string {
        let n = parseFloat(num.toExponential(5));
        if ((n > -0.0001 && n < 0) || (n < 0.0001 && n > 0)) return n.toExponential();
        if (n > 1000 || n < -1000) return n.toExponential();
        return n.toString();
    }

    static toNumbersToText(nums: number[]): string[] {
        let minE = 0;
        let maxE = 0;
        nums.forEach((a, index) => {
            let e = Utils.getExpBase(a);
            if (e < minE || index == 0) minE = e;
            if (e > maxE || index == 0) maxE = e;
        });
        let baseExp = minE;
        if (minE < 0) baseExp = maxE;
        if (minE >= -3 && minE <= 3) baseExp = 0;
        if (baseExp < 0) {
            while (baseExp % 3 != 0) {
                baseExp--;
            }
        }
        if (baseExp > 0) {
            while (baseExp % 3 != 0) {
                baseExp--;
            }
        }

        let txtRes: string[] = [];
        for (let i = 0; i < nums.length; i++) {
            let a = nums[i];
            let v = Utils.toExponential(a, baseExp, 5);
            if (i > 0 && i < (nums.length - 1)) v = LegendLinearComp.skipDigits(txtRes[i - 1], v);
            txtRes.push(v);
        }
        return txtRes;
    }

    static skipDigits(prev: string, s: string): string {
        let prev1 = "";
        let prev2 = "";
        let s1 = "";
        let s2 = "";
        if (prev.indexOf("e") > 0 && s.indexOf("e") > 0) {
            if (prev.substr(prev.indexOf("e")) != s.substr(s.indexOf("e"))) return s;
            prev1 = prev.substring(0, prev.indexOf("e"));
            prev2 = prev.substr(prev.indexOf("e"));
            s1 = s.substring(0, s.indexOf("e"));
            s2 = s.substr(s.indexOf("e"));
        } else if (prev.indexOf("e") < 0 && s.indexOf("e") < 0) {
            s1 = s;
            prev1 = prev;
        } else return s;

        if (s1.indexOf(".") > 0 && prev1.indexOf(".") > 0) {
            let newLen = 0;
            if (s1.substring(0, s1.indexOf(".")) != prev1.substring(0, prev1.indexOf("."))) {
                newLen = s1.indexOf(".") + 1;
            } else {
                for (let i = prev.indexOf("."); i < prev1.length; i++) {
                    if (s1.length > i) {
                        if (s1[i] != prev1[i]) {
                            newLen = i;
                            break;
                        }
                    } else {
                        newLen = prev1.length;
                        break;
                    }
                }
            }
            if (newLen > 0) {
                s1 = s1.substr(0, newLen + 2);
                if (s1.indexOf(".") > 0) { s1 = parseFloat(s1).toString() }
                return s1 + s2;
            } else return s1;
        } else return s;
    }

    render() {
        let store = this.props.store;
        let colors = this.props.colors;
        let min = this.props.min;
        let max = this.props.max;
        let colItems: any[] = [];
        if (colors.length == 0) {
            colItems.push(<stop offset="0" stopColor="#000" key="1" />);
            colItems.push(<stop offset="1" stopColor="#000" key="2" />);
        } else if (colors.length == 1) {
            colItems.push(<stop offset="0" stopColor={ColorHelper.ColorRGBAtoCSS(colors[0])} key="1" />);
            colItems.push(<stop offset="1" stopColor={ColorHelper.ColorRGBAtoCSS(colors[0])} key="2" />);
        }
        let colSize = 1 / (colors.length - 1);
        let start = 0;
        colors.forEach((c, index) => {
            colItems.push(<stop offset={start + index * colSize} stopColor={ColorHelper.ColorRGBAtoCSS(c)} key={index} />);
        });
        let txt: string[] = [];
        let nums: number[] = this.props.values || [];

        if (!this.props.values) {
            const numSteps = this.props.hideMoreLess ? 6 : 4;
            for (let i = 0; i <= numSteps; i++) {
                let v = min + ((max - min) / numSteps) * i;
                if (this.props.hideMoreLess) {
                    v = Math.round(v);
                }
                nums.push(v);
            }
        }
        txt = LegendLinearComp.toNumbersToText(nums.reverse());
        let c1 =   colors[0];
        let c2 = colors[colors.length - 1];

        return (
            <div>
                {this.props.top_name && <div className="legend-title">{this.props.top_name}</div>}
                <div className="legend-panel_linearBox">
                    {this.props.visibleCheckbox && !this.props.hideMoreLess && (
                        <div className="legend-panel_linearCheckboxPanel">
                            <LegendCheckBoxComp
                                className="legend-panel_linearCheckboxMore"
                                checked={!this.props.transparentMore}
                                onClick={() => {
                                    if (this.props.onChangeTransparent) this.props.onChangeTransparent({ isMore: true, isLess: false, value: !this.props.transparentMore });
                                }}
                                store={store}
                            />
                            <LegendCheckBoxComp
                                className="legend-panel_linearCheckboxLess"
                                checked={!this.props.transparentLess}
                                onClick={() => {
                                    if (this.props.onChangeTransparent) this.props.onChangeTransparent({ isMore: false, isLess: true, value: !this.props.transparentLess });
                                }}
                                store={store}
                            />
                        </div>
                    )}
                    <div>
                        <svg width="100" height="196" viewBox="0 0 100 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <style>
                                {"text { font-style:normal; font-weight:400; line-height:1.25; font-size:12px; fill:#C5C5C5; }"}
                            </style>
                            <line x1="8" y1="191.5" x2="26" y2="191.5" stroke="#6D7785" />
                            <line x1="9" y1="5.5" x2="26" y2="5.5" stroke="#6D7785" />
                            <path d="M16.5 13.5V183.5C16.5 187.918 12.9183 191.5 8.5 191.5C4.08172 191.5 0.5 187.918 0.5 183.5V13.5C0.5 9.08173 4.08172 5.5 8.5 5.5C12.9183 5.5 16.5 9.08172 16.5 13.5Z" fill="#2B3138" stroke="#6D7785" />
                            <line x1="17" y1="36.5" x2="28" y2="36.5" stroke="#6D7785" />
                            <line x1="16" y1="66.5" x2="27" y2="66.5" stroke="#6D7785" />
                            <line x1="16" y1="96.5" x2="27" y2="96.5" stroke="#6D7785" />
                            <line x1="16" y1="126.5" x2="27" y2="126.5" stroke="#6D7785" />
                            <line x1="16" y1="160.5" x2="27" y2="160.5" stroke="#6D7785" />
                            <path d="M1 160H16V37H1V160Z" fill="url(#paint0_linear_1_2)" />
                            <path d="M16 161H1V183.5C1 187.642 4.35786 191 8.5 191V191C12.6421 191 16 187.642 16 183.5V161Z" fill={ColorHelper.ColorRGBAtoCSS(c1)} />
                            <path d="M1 36H16V13.5C16 9.35786 12.6421 6 8.5 6V6C4.35786 6 1 9.35786 1 13.5V36Z" fill={ColorHelper.ColorRGBAtoCSS(c2)} />

                            {this.props.transparentLess && (
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.83984 186.952C1.30323 185.919 1 184.745 1 183.5V161H14.0415L1.83984 186.952ZM16 163.453V183.5C16 187.642 12.6421 191 8.5 191C6.72944 191 5.10218 190.386 3.81921 189.36L16 163.453Z" fill="#22282F" />
                            )}
                            {this.props.transparentMore && (
                                <path fillRule="evenodd" clipRule="evenodd" d="M1 33.7382V13.5C1 9.35786 4.35786 6 8.5 6C10.3006 6 11.9531 6.63455 13.2459 7.69224L1 33.7382ZM15.2069 10.1397C15.7144 11.1505 16 12.2919 16 13.5V36H3.04834L15.2069 10.1397Z" fill="#22282F" />
                            )}
                            {this.props.hideMoreLess ? (
                                <>
                                     <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="30" y="195.155">{txt[6]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="30" y="10.1553">{txt[0]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="30" y="39.6553">{txt[1]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="69.6553">{txt[2]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="99.6553">{txt[3]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="129.655">{txt[4]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="163.655">{txt[5]}</tspan></text>
                                </>
                            ) : (
                                <>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="30" y="195.155">{store.trans.less}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="30" y="10.1553">{store.trans.more}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="30" y="39.6553">{txt[0]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="69.6553">{txt[1]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="99.6553">{txt[2]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="129.655">{txt[3]}</tspan></text>
                                    <text fill="#C5C5C5" xmlSpace="preserve"><tspan x="29" y="163.655">{txt[4]}</tspan></text>
                                </>
                            )}
                            <defs>
                                <linearGradient id="paint0_linear_1_2" x1="8.5" y1="160" x2="8.5" y2="37" gradientUnits="userSpaceOnUse">
                                    {colItems}
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                {isString(this.props.title) && this.props.title.length > 0 && (
                    <>
                        <div className="separator_line" />
                        <MyHint text={this.props.description}>
                            <div className={this.props.hintClassName || "legend-panel_linearTitle"}>{this.props.title}</div>
                        </MyHint>
                    </>
                )}
            </div>
        );
    }
}