import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {ReactDoubleDatePicker2Comp} from "../../../../Common/ReactDoubleDatePicker2Comp";
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {SearchParamsS5CompositeMethod} from "../../../../../store/SearchParamsS5Store";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {action} from "mobx";
import {ISwitchItem, SwitchItems} from "../../../../Common/SwitchItems";

@observer
export class Sentinel5CompositesComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    static readonly MaxPeriodCompositeDays = 30;

    @action
    onChangeMethod(item: IDropDownItem){
        this.props.store.searchPanel.searchParamsS5.compositeMethod = item.data;
    }
    @action
    onChangeInterval(item: ISwitchItem){
        this.props.store.searchPanel.searchParamsS5.compositeInterval = item.data;
        this.setDateInterval();
    }
    @action
    onChangeDateInterval(){
        this.setDateInterval();
    }
    setDateInterval(){
        let s5 = this.props.store.searchPanel.searchParamsS5;
        if (s5.compositeDate.isValidBegin()){
            let d = new Date(s5.compositeDate.begin);
            d.setDate(d.getDate() + s5.compositeInterval-1);
            s5.compositeDate.end = d;
        }else s5.compositeDate.end = null;
    }

    render() {
        let store = this.props.store;
        let s5store = store.searchPanel.searchParamsS5;
        function getDropItem(c: SearchParamsS5CompositeMethod): IDropDownItem{
            return {key: c, value: TranslateUtils.getCompositeMethodLongName(c, store), data: c};
        }
        let methodItems: IDropDownItem[] = [
            getDropItem(SearchParamsS5CompositeMethod.newest),
            getDropItem(SearchParamsS5CompositeMethod.oldest),
            getDropItem(SearchParamsS5CompositeMethod.max),
            getDropItem(SearchParamsS5CompositeMethod.min),
            getDropItem(SearchParamsS5CompositeMethod.mean),
            getDropItem(SearchParamsS5CompositeMethod.median),
            getDropItem(SearchParamsS5CompositeMethod.rms)
        ];
        let arr = store.searchPanel.allSentinel5Data;
        let dates: Date[] = [];
        arr.forEach(a => {
            dates.push(new Date(a.date));
        });

        return <div>
            <div className="flex-row-centerByVertical Sentinel5Panel-marginTop15">
                <div className="flex-columns-centerByVertical flex-stretch-item text-10" style={{marginLeft: 0}}>
                {store.trans["Interval (days)"]}
                </div>
                <div className="flex-columns-centerByVertical Sentinel5Panel-Interval">
                <DropDownComp items={[{key:"10", value: "10", data: 10}, {key:"20", value: "20", data: 20}, {key:"30", value: "30", data: 30}]} onChange={this.onChangeInterval}
                                  currentKey={s5store.compositeInterval.toString()} className="Sentinel5Panel-IntervalSwitcher" />
                </div>
            </div>
            <div className="flex-row-centerByVertical Sentinel5Panel-marginTop15">
                <div className="flex-columns-centerByVertical flex-stretch-item text-10">
                    {store.trans.Date}
                </div>
                <div className="flex-columns-centerByVertical Sentinel5Panel-datePeriod">
                    <ReactDoubleDatePicker2Comp store={store} interval={s5store.compositeDate}
                                                anyDaysChooseItem1={true}
                                                includeDays={dates}
                                                disabledItem2={true} onChange={this.onChangeDateInterval}
                                                className="Sentinel5Panel-dateIntervalComponent"
                                                 />
                </div>
            </div>
            <div className="flex-row-centerByVertical Sentinel5Panel-marginTop15 width100">
                <div className="flex-columns-centerByVertical">
                    <div className="text-10">{store.trans.Method}</div>
                </div>
                <div className="flex-columns-centerByVertical Sentinel5Panel-datePeriod text-10 overflowHidden flex-stretch-item" >
                    <DropDownComp disableSearch items={methodItems} onChange={this.onChangeMethod}
                                  currentKey={s5store.compositeMethod} className="width100" />
                </div>
            </div>
        </div>;
    }
}
