import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoStmt4Comp.scss'
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";
import {AgroAhoStepsComp} from "../uploadTable/AgroAhoStepsComp";
import {Utils} from "../../../../app/helper/utils/Utils";
import {action} from "mobx";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";

@observer
export class AgroAhoStmt4Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    canNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        return st.labEmail && st.yourEmail;
    }
    onLabEmailChange(e: any){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.labEmail = e?.currentTarget?.value;
    }
    onYourEmailChange(e: any){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.yourEmail = e?.currentTarget?.value;
    }
    onCopyEmailChange(e: any){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.copyEmail = e?.currentTarget?.value;
    }
    onDownloadInstructionsClick(){
        // let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        // Utils.downloadFile(url);
    }
    onClickBack(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doBackClick();
    }
    onClickNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (!this.canNext()) return;
        st.doNextClick();
    }
    onClickCancel(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doCancel();
    }
    onDownloadClick(){
        if (!this.canNext()) return;
        let st = this.props.store.agroAhoStore.stmtStore;
        st.onDownloadClick();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore
        let st = store_.stmtStore;


        return <div className="AgroAhoStmt4Comp-main">
            <div className="AgroAhoStmt4Comp-cover">
                <div className="AgroAhoStmt4Comp-topPanel">
                    <div className="AgroAhoStmt4Comp-titleCover">
                        <div className="AgroAhoStmt4Comp-title"><b>{store_.trans['Research plan']}.</b> {
                            store_.trans['Step 4. Sending and exporting the file']}</div>
                        <AgroAhoStepsComp step={st.stage} steps={4} style2={true}/>
                    </div>
                    <div className="AgroAhoStmt4Comp-stat">
                        <div className="AgroAhoStmt4Comp-statLeftCol">
                            <div>{store_.trans.Fields}: {st.stat.fields}</div>
                            <div>{store_.trans2.Cells}: {st.stat.cells}</div>
                            {/*<div>{store_.trans.Area}: {store_.toFloat(st.stat.area)} {store_.trans.ha}</div>*/}
                        </div>
                        <div className="AgroAhoStmt4Comp-statCenterCol">
                            <div><b>{store_.trans['Main research']}:</b></div>
                            <div>{store_.trans.indicators}: {st.stat.main_indcs}</div>
                            <div>{store_.trans2.cells}: {st.stat.cells}</div>
                        </div>
                        <div className="AgroAhoStmt4Comp-statRightCol">
                            <div><b>{store_.trans['Extended research']}:</b></div>
                            <div className="AgroAhoDot">{store_.trans.indicators}: {st.stat.ext_indcs}</div>
                            <div>{store_.trans2.cells}: {st.stat.ext_cells}</div>
                        </div>
                    </div>
                    <div className="AgroAhoStmt4Comp-line"/>

                    <ul className="AgroAhoStmt4Comp-doc">
                        <li>{store_.trans['Date of field work']}: {store_.format_date3(st.fieldWorkDate)}</li>
                        <li>{st.contract}</li>
                        <li>{st.client}</li>
                    </ul>

                    <div className="AgroAhoStmt4Comp-fields">
                        <div className="AgroAhoStmt4Comp-fieldCaption">{store_.trans['Laboratory email']}</div>
                        <input type="text" className="AgroAhoStmt4Comp-fieldName text-box-editor"
                               value={st.labEmail} onChange={this.onLabEmailChange}/>
                        <div className="AgroAhoStmt4Comp-fieldCaption">{store_.trans['Your Email']}</div>
                        <input type="text" className="AgroAhoStmt4Comp-fieldName text-box-editor"
                               value={st.yourEmail} onChange={this.onYourEmailChange}/>
                        <div className="AgroAhoStmt4Comp-fieldCaptionNoReq">{store_.trans['Add to copy']}</div>
                        <input type="text" className="AgroAhoStmt4Comp-fieldName text-box-editor"
                               value={st.copyEmail} onChange={this.onCopyEmailChange}/>

                        <div className={classNames("AgroAhoStmt4Comp-downloadStmsBut", {
                            "AgroAhoStmt4Comp-disabled": !this.canNext()})} onClick={this.onDownloadClick}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 9C12.7348 9 12.4804 9.10536 12.2929 9.29289C12.1054 9.48043 12 9.73478 12 10V12H2V10C2 9.73478 1.89464 9.48043 1.70711 9.29289C1.51957 9.10536 1.26522 9 1 9C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12V10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9Z"/>
                                <path d="M6.29341 9.707C6.48094 9.89447 6.73524 9.99979 7.00041 9.99979C7.26557 9.99979 7.51988 9.89447 7.70741 9.707L11.7074 5.707C11.8029 5.61475 11.8791 5.50441 11.9315 5.3824C11.9839 5.2604 12.0115 5.12918 12.0127 4.9964C12.0138 4.86362 11.9885 4.73194 11.9382 4.60905C11.8879 4.48615 11.8137 4.3745 11.7198 4.2806C11.6259 4.18671 11.5143 4.11246 11.3914 4.06218C11.2685 4.0119 11.1368 3.9866 11.004 3.98775C10.8712 3.9889 10.74 4.01649 10.618 4.0689C10.496 4.12131 10.3857 4.19749 10.2934 4.293L8.00041 6.586V1C8.00041 0.734784 7.89505 0.48043 7.70751 0.292893C7.51998 0.105357 7.26562 0 7.00041 0C6.73519 0 6.48084 0.105357 6.2933 0.292893C6.10576 0.48043 6.00041 0.734784 6.00041 1V6.586L3.70741 4.293C3.5188 4.11084 3.2662 4.01005 3.00401 4.01233C2.74181 4.0146 2.491 4.11977 2.30559 4.30518C2.12018 4.49059 2.01501 4.7414 2.01273 5.0036C2.01045 5.2658 2.11125 5.5184 2.29341 5.707L6.29341 9.707Z"/>
                            </svg>
                            <span>{store_.trans['Download the plan-statement']}</span>
                        </div>

                    </div>

                </div>
                <div className="AgroAhoStmt4Comp-bottomPanel">
                    <div className="AgroAhoStmt4Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoStmt4Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoStmt4Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <button className="left-sidebar-cancel button-jump AgroAhoStmt4Comp-backButton"
                            onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </button>
                    <button className={classNames("left-sidebar-active AgroAhoStmt4Comp-nextButton",
                         {"AgroAhoStmt4Comp-disabled": !this.canNext(), "button-jump": !!this.canNext()})}
                         onClick={this.onClickNext}>{store_.trans['Send by mail']}</button>
                </div>
            </div>
        </div>
    }
}
