import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoCheckboxSvgProps {
    className?: string
    checked: boolean;
}

export class AgroAhoCheckboxSvg extends React.PureComponent<IAgroAhoCheckboxSvgProps, undefined> {
    constructor(props: IAgroAhoCheckboxSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let res = <svg className={classNames(this.props.className)}
            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="5" width="8" height="8" rx="4" fill="#4DB6BC" stroke="#4DB6BC" strokeWidth="2"/>
            <rect x="1" y="1" width="16" height="16" rx="8" stroke="#4DB6BC" strokeWidth="2"/>
        </svg>;
        if (!this.props.checked)
            res = <svg className={classNames(this.props.className)}
            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="16" height="16" rx="8" stroke="#454C52" strokeWidth="2"/>
        </svg>;
        return res;
    }
}