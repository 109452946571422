import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {HelpComp} from "../../Common/Forms/HelpComp";
import {LogoSvgComp} from "../../icons/LogoSvgComp";

@observer
export class LeftAuthPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className="authorize-d1 style-4">
            <div className="authorize-logo">
                <LogoSvgComp store={store} />
            </div>
            <div className="authorize-welcome">{store.trans.Welcome}</div>
            <div className="authorize-welcome-desc" dangerouslySetInnerHTML={{__html:  store.config?.theme?.welcome_text??""}}>
            </div>
            {store.config?.theme?.help_link &&
                <div className="authorize-welcome-bottom">
                    <a href={store.config.theme.help_link} className="authorize-welcome-help"  target="_blank">
                        <HelpComp/>
                        <span className="text-next light-blue-text">{store.trans.Help}</span>
                    </a>
                    <span className="gray-text">© Ctrl2GO</span>
                </div>
            }
        </div>;
    }
}
