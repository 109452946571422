import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoDelSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoDelSvg extends React.PureComponent<IAgroAhoDelSvgProps, undefined> {
    constructor(props: IAgroAhoDelSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
            width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 3.5V1.75H9.625V0.875C9.625 0.642936 9.53281 0.420376 9.36872 0.256282C9.20462 0.0921872 8.98206 0 8.75 0L5.25 0C5.01794 0 4.79538 0.0921872 4.63128 0.256282C4.46719 0.420376 4.375 0.642936 4.375 0.875V1.75H0V3.5H14Z"/>
            <path d="M1.75 4.375V12.25C1.75 12.7141 1.93437 13.1592 2.26256 13.4874C2.59075 13.8156 3.03587 14 3.5 14H10.5C10.9641 14 11.4092 13.8156 11.7374 13.4874C12.0656 13.1592 12.25 12.7141 12.25 12.25V4.375H1.75ZM9.98725 10.9375L8.75 12.1747L7 10.4247L5.25 12.1747L4.01275 10.9375L5.76275 9.1875L4.01275 7.4375L5.25 6.20025L7 7.95025L8.75 6.20025L9.98725 7.4375L8.23725 9.1875L9.98725 10.9375Z"/>
        </svg>;
    }
}
