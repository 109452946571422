// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-context-title-scenario {
  color: #c5c5c5;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 8px;
}

.custom-context-menu {
  background-color: rgb(43, 49, 56);
  border: 1px solid #3e4751;
  width: 358px;
  height: auto;
  padding: 15px;
  border-radius: 8px;
}

.custom-context-menu-compare {
  width: 168px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/IndexByPoint/IndexByPointColorPickerCom.scss"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;;AAEA;EACI,iCAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AACA;EACC,YAAA;EACA,YAAA;AAED","sourcesContent":["\n.custom-context-title-scenario {\n    color: #c5c5c5;\n    font-size: 10px;\n    font-weight: 400;\n    margin-bottom: 8px;\n}\n.custom-context-menu {\n    background-color: rgba(43, 49, 56, 1);\n    border: 1px solid #3e4751;\n    width: 358px;\n    height: auto;\n    padding: 15px;\n    border-radius: 8px;\n}\n.custom-context-menu-compare{\n\twidth: 168px;\n\theight: auto;\n\t\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
