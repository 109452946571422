import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoPointSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoPointSvg extends React.PureComponent<IAgroAhoPointSvgProps, undefined> {
    constructor(props: IAgroAhoPointSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false}
            width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#D9D9D9"/>
        </svg>;
    }
}
