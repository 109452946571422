import {ObservableCustomStore} from "../CustomStore";
import {autorun, observable} from "mobx";
import {save} from "../PermalinkDecor";
import {DataShowRoomMode, ShowRoom2Store} from "./ShowRoom2Store";
import {IReactionDisposer} from "mobx/lib/internal";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";
import {LeftPanelMode} from "../SearchStore";

export enum ShowroomLayerType{
    districts,
    ccePoints,
    classPoints,
    yieldAssessmentPoints,
    yieldMapPerGP,
}


export class ShowRoom2LayerStore extends ObservableCustomStore<ShowRoom2Store>{
    static create(parent: ShowRoom2Store, type: ShowroomLayerType): ShowRoom2LayerStore{
        let t = new ShowRoom2LayerStore(parent);
        t.showroomLayerType = type;
        return t;
    }

    title: string;

    showroomLayerType: ShowroomLayerType = null;

    @save @observable
    _checkedProject: boolean = false;
    @save @observable
    _checkedMultiYear: boolean = false;

    @observable
    countPoints: number;
    @observable
    countPoints_status: LoadStatus = null;

    subscription():IReactionDisposer[]{
        return [
            autorun(()=>{
                this.loadStatistics();
        })];
    }

    get checked(): boolean{
        if (this.parent.dataShowRoomMode == DataShowRoomMode.Projects) return this._checkedProject;
        return this._checkedMultiYear;
    }
    set checked(value: boolean){
        if (this.parent.dataShowRoomMode == DataShowRoomMode.Projects){
            this._checkedProject = value;
        }else {
            this._checkedMultiYear = value;
        }
    }

    loadStatistics(){
        if (!this.parent.active || this.root.searchPanel.leftPanelMode != LeftPanelMode.showRoom2) return;
        if (
            this.showroomLayerType == ShowroomLayerType.classPoints ||
            this.showroomLayerType == ShowroomLayerType.ccePoints ||
            this.showroomLayerType == ShowroomLayerType.districts ||
            this.showroomLayerType == ShowroomLayerType.yieldMapPerGP ||
            this.showroomLayerType == ShowroomLayerType.yieldAssessmentPoints
        ){
            let url: string;
            if (this.showroomLayerType == ShowroomLayerType.classPoints)
                url = `/reports/api/class/count?filter=${encodeURIComponent(JSON.stringify(this.parent.getFilters()))}`;
            if (this.showroomLayerType == ShowroomLayerType.ccePoints)
                url = `/reports/api/cce/count?filter=${encodeURIComponent(JSON.stringify(this.parent.getFilters()))}`;
            if (this.showroomLayerType == ShowroomLayerType.yieldAssessmentPoints)
                url = `/reports/api/yield/count?filter=${encodeURIComponent(JSON.stringify(this.parent.getFilters()))}`;
            if (this.showroomLayerType == ShowroomLayerType.yieldMapPerGP)
                url = `/reports/api/yield_gp/count?filter=${encodeURIComponent(JSON.stringify(this.parent.getFilters()))}`;
            if (this.showroomLayerType == ShowroomLayerType.districts)
                url = `/reports/api/districts/find?count=1&filter=${encodeURIComponent(JSON.stringify(this.parent.getFilters()))}`;
            ra(()=>{
                this.countPoints_status = LoadStatus.loading;
                this.countPoints = null;
                fetchJsonGet(url).then(json =>{
                    ra(()=>{
                        this.countPoints = json.count;
                        this.countPoints_status = LoadStatus.ready;
                    });
                }).catch(err => this.root.addError(err));
            });
        }else{
            ra(()=>{
                this.countPoints = null;
                this.countPoints_status = LoadStatus.ready;
            });
        }
    }

}