import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {LogoSvgComp} from "../../icons/LogoSvgComp";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";

@observer
export class LeftSidebarRightTop extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickOrderList(){
        this.props.store.orderList.doLoadList();
        this.props.store.searchPanel.closeAllLeftPanels();
        this.props.store.searchPanel.showOrderList = true;
    }

    @action
    onClickSearchAddress(){
        let sa = this.props.store.searchPanel.searchAddress;
        if (!sa.isShowPanel()){
            sa.showPanel();
        }else sa.closePanel();
    }

    render() {
        let store = this.props.store;
        return <div className="left-sidebar__right-top">
            <div className="flex-stretch-item">
                <div className="top_logo">
                    <LogoSvgComp store={store} />
                </div>
            </div>
	    <React.Fragment>
	    <div className="top-button full-center-content top-button-br" onClick={this.onClickOrderList}>
                <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 4.625H0V1.25C0 0.629 0.504 0.125 1.125 0.125H16.875C17.496 0.125 18 0.629 18 1.25V4.625Z"/>
                    <path d="M5.625 15.875H1.125C0.504 15.875 0 15.371 0 14.75V6.875H5.625V15.875Z" />
                    <path d="M16.875 15.875H7.875V6.875H18V14.75C18 15.371 17.496 15.875 16.875 15.875Z" />
                </svg>
            </div>
            <div className={classNames("top-button full-center-content top-button-br top-button-big qtransit",
                {"any-skale-x": store.searchPanel.searchAddress.isShowPanel()})} onClick={this.onClickSearchAddress}>
                <svg className={classNames({"blue-fill":store.searchPanel.searchAddress.isShowPanel()})} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.875 15.75C3.5325 15.75 0 12.2175 0 7.875C0 3.5325 3.5325 0 7.875 0C12.2175 0 15.75 3.5325 15.75 7.875C15.75 12.2175 12.2175 15.75 7.875 15.75ZM7.875 2.25C4.77338 2.25 2.25 4.77338 2.25 7.875C2.25 10.9766 4.77338 13.5 7.875 13.5C10.9766 13.5 13.5 10.9766 13.5 7.875C13.5 4.77338 10.9766 2.25 7.875 2.25Z" />
                    <path d="M17.6706 16.0798L14.9784 13.3877C14.5161 13.9828 13.9828 14.5161 13.3877 14.9784L16.0798 17.6706C16.2992 17.8899 16.5872 18.0002 16.8752 18.0002C17.1632 18.0002 17.4512 17.8899 17.6706 17.6706C18.1104 17.2307 18.1104 16.5197 17.6706 16.0798Z"/>
                </svg>
                <div className={classNames("arrow_pref qtransit gray", {"blue":store.searchPanel.searchAddress.isShowPanel()})} ></div>
            </div>
	    </React.Fragment>
        </div>;
    }
}
