import * as React from 'react';
import {RefObject} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoWindowComp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {IAgroAhoSuperStore} from "../agroAhoPlugin";

export interface IAgroAhoWindowCompProps {
    store: IAgroAhoSuperStore;
    onClick?: ()=>void,
    onBlur?: ()=>void,
    className?: string,
    visible: Boolean;
    button: any;
}


@observer
export class AgroAhoWindowComp extends React.PureComponent<IAgroAhoWindowCompProps, undefined> {
    constructor(props: IAgroAhoWindowCompProps) {
        super(props);
        autoBindReact(this);
        this.winRootElement = React.createRef();
    }

    winRootElement: RefObject<HTMLDivElement>;

    componentDidMount() {
        let root = this.winRootElement.current;
        root.addEventListener('blur', this.onBlur.bind(this));
    }

    @action
    onBlur(e: any){
        if (!this.props.onBlur) return;
        this.props.onBlur();
        e.stopPropagation();
        e.preventDefault();
    }

    @action
    onClickButton(e: any){
        if (!this.props.onClick) return;
        this.props.onClick();
        e.stopPropagation();
        e.preventDefault();
    }

    onClick(e: any){
        e.stopPropagation();
        e.preventDefault();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            this.winRootElement.current.focus();
        }
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;

        return <div className={classNames("AgroAhoWindowComp-root", this.props.className)}
                    tabIndex={1000} ref={this.winRootElement} onClick={this.onClick}>
            <div className="AgroAhoWindowComp-button" onClick={this.onClickButton}>{this.props.button}</div>
            <div className={classNames("AgroAhoWindowComp-main",{
                "AgroAhoWindowComp-hidden": !this.props.visible})}>{this.props.children}</div>
        </div>



    }
}
