// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwitchItems-div {
  display: flex;
  color: #C5C5C5;
}

.SwitchItems-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.SwitchItems-item-active {
  cursor: default;
  background: #4DB6BC;
  color: #2B3138;
  border-radius: 9px;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/SwitchItems.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;AACF;;AACA;EACE,eAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EAGA,YAAA;AADF;;AAGA;EACE,eAAA;EAEA,mBAAA;EACA,cAAA;EACA,kBAAA;AADF","sourcesContent":[".SwitchItems-div{\n  display: flex;\n  color: #C5C5C5;\n}\n.SwitchItems-item{\n  cursor: pointer;\n // height: 28px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n//  border: 1px solid #3E4751;\n // border-left-style: hidden;\n  flex-grow: 1;\n}\n.SwitchItems-item-active{\n  cursor: default;\n // border-left-style: solid;\n  background: #4DB6BC;\n  color: #2B3138;\n  border-radius: 9px;\n}\n// .SwitchItems-item:first-child{\n//   border-left-style: solid;\n//   border-radius: 10px 0 0 10px;\n// }\n// .SwitchItems-item:last-child{\n//   border-radius: 0 10px 10px 0;\n// }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
