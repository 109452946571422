import {AddrContour, AddrPoint, ContourPoints, ISimpleGeometry, SimpleGeometryType} from "../utils/GeometryUtils";
import {Position} from "geojson";

export enum GActionType{
    group = 'group',
    insertPoint = 'insertPoint',
    movePoint = 'movePoint',
    deletePoint = 'deletePoint',
    deleteHole = 'deleteHole',
    insertHole = 'insertHole',
    deleteGeom = 'deleteGeom',
    insertGeom = 'insertGeom',
}

export enum GActionTransactionType{
    insertPoint = 'insertPoint',
    movePoint = 'movePoint',
    deletePoint = 'deletePoint',
    autoGeometry = 'autoGeometry',
    deleteContour = 'deleteContour',
    deleteGeometry = "deleteGeometry",
    cutGeometry = 'cutGeometry',
    unionGeometry = 'unionGeometry',
}

export interface IGActionTransaction extends IGActionGroup{
    transactionType: GActionTransactionType;//служит как ключ к описанию действия
}

export interface IGAction {
    actionType: GActionType;
}

export interface IGActionGroup extends IGAction{
    actionType: GActionType.group;
    actions: IGAction[];
}

export interface IGActionPoint extends IGAction{
    actionType: GActionType.movePoint | GActionType.deletePoint | GActionType.insertPoint;
    addrPoint: AddrPoint;
    newValue?: Position;
    oldValue?: Position;
}
export interface IGActionHole extends IGAction{
    actionType: GActionType.deleteHole | GActionType.insertHole;
    addrContour: AddrContour;
    newValue?: ContourPoints;
    oldValue?: ContourPoints;
}
export interface IGActionGeometry extends IGAction{
    actionType: GActionType.deleteGeom | GActionType.insertGeom;
    index: number;
    newValue?: ISimpleGeometry;
    oldValue?: ISimpleGeometry;
}
