// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectGeometryType-dropDownPanenl {
  width: 40px;
  border-radius: 10px;
  border: 1px solid #3E4751;
  padding: 7px;
  background: #2B3138;
}

.SelectGeometryType-dropDownButton {
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  fill: #C5C5C5;
  stroke: #C5C5C5;
  cursor: pointer;
}

.SelectGeometryType-dropDownButton:last-child {
  margin-bottom: 0;
}

.SelectGeometryType-dropDownButton:hover {
  background: #454C52;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/ParamSearchPanel/SelectGeometryType.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;AAFF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA;EACA,eAAA;AADF;;AAGA;EACE,gBAAA;AAAF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".SelectGeometryType{\n\n}\n.SelectGeometryType-dropDownPanenl{\n  width: 40px;\n  border-radius: 10px;\n  border: 1px solid #3E4751;\n  padding: 7px;\n  background: #2B3138;\n}\n.SelectGeometryType-dropDownButton{\n  border-radius: 3px;\n  width: 24px;\n  height: 24px;\n  margin-bottom: 5px;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  fill: #C5C5C5;\n  stroke: #C5C5C5;\n  cursor: pointer;\n}\n.SelectGeometryType-dropDownButton:last-child{\n  margin-bottom: 0;\n}\n.SelectGeometryType-dropDownButton:hover{\n  background: #454C52;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
