import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoStmt1Comp.scss'
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";
import {AgroAhoStepsComp} from "../uploadTable/AgroAhoStepsComp";
import {Utils} from "../../../../app/helper/utils/Utils";
import {action} from "mobx";
import {AgroAhoDateComp} from "../uploadTable/AgroAhoDateComp";

@observer
export class AgroAhoStmt1Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }


    canNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        return st.contract && st.client && st.fieldWorkDate;
    }

    onContrantChange(e: any){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.contract = e?.currentTarget?.value;
    }

    onClientChange(e: any){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.client = e?.currentTarget?.value;
    }

    onFieldWorkDateChange(date: Date){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.fieldWorkDate = date;
    }

    onDownloadInstructionsClick(){
        // let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        // Utils.downloadFile(url);
    }

    onClickNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (!this.canNext()) return;
        st.doNextClick();
    }

    @action
    onClickCancel(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doCancel();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore
        let st = store_.stmtStore;


        return <div className="AgroAhoStmt1Comp-main">
            <div className="AgroAhoStmt1Comp-cover">
                <div className="AgroAhoStmt1Comp-topPanel">
                    <div className="AgroAhoStmt1Comp-titleCover">
                        <div className="AgroAhoStmt1Comp-title"><b>{store_.trans['Research plan']}.</b> {
                            store_.trans['Step 1. Contract and customer']}</div>
                        <AgroAhoStepsComp step={st.stage} steps={4} style2={true}/>
                    </div>
                    <div className="AgroAhoStmt1Comp-msg">
                        <span>{store_.trans['Check and, if necessary, correct the contract number. Specify the final customer for the research (peasant farm, enterprise, holding, etc.)']}</span>
                    </div>
                    <div className="AgroAhoStmt1Comp-line"/>

                    <div className="AgroAhoStmt1Comp-fields">
                        <div className="AgroAhoStmt1Comp-fieldCaption">{store_.trans['Contract']}</div>
                        <input type="text" className="AgroAhoStmt1Comp-fieldName text-box-editor"
                               value={st.contract} onChange={this.onContrantChange}/>
                        <div className="AgroAhoStmt1Comp-fieldCaption">{store_.trans['End customer']}</div>
                        <input type="text" className="AgroAhoStmt1Comp-fieldName text-box-editor"
                               value={st.client} onChange={this.onClientChange}/>
                        <div className="AgroAhoStmt1Comp-fieldCaption">{store_.trans['Date of field work']}</div>
                        <div className="AgroAhoStmt1Comp-fieldWorkDateCover">
                            <AgroAhoDateComp className="AgroAhoStmt1Comp-fieldWorkDate" onChange={this.onFieldWorkDateChange}
                                 selected={st.fieldWorkDate} calendarIcon={true} placeholderText='DD-MM-YYYY'
                                 dateFormat='dd-MM-yyyy'/>
                        </div>
                        <div className="AgroAhoStmt1Comp-fieldWorkDateMsg">{store_.trans['Please indicate the actual or planned date of field work. The tour date will be linked to this date after receiving data from the laboratory']}</div>
                    </div>

                </div>
                <div className="AgroAhoStmt1Comp-bottomPanel">
                    <div className="AgroAhoStmt1Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoStmt1Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoStmt1Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <button className={classNames("left-sidebar-active AgroAhoStmt1Comp-nextButton",
                         {"AgroAhoStmt1Comp-disabledButton": !this.canNext(), "button-jump": !!this.canNext()})}
                         onClick={this.onClickNext}>{store_.trans.Next}</button>
                </div>
            </div>
        </div>
    }
}
