import {ContainerTools, ContainerToolsState, CustomTool, IObjectByClick} from "../general/ContainerTools";
import * as mapboxgl from "maplibre-gl";
import {MapMouseEvent} from "maplibre-gl";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class CustomEditGeometryTool extends CustomTool{
    constructor(container: ContainerTools, state: ContainerToolsState, name: string) {
        super(container, name);
        this._state = state;
    }

    _state: ContainerToolsState;

    set state(value: ContainerToolsState){
        this._state = value;
    }
    get state(): ContainerToolsState{
        return this._state;
    }
    updateMovedPoint(){
        if (this.state?.events?.updateMovedPoint)
            this.state.events.updateMovedPoint();
    }
    updateGeometry(){
        if (this.state.events?.updateGeometry){
            this.state.events.updateGeometry();
        }
    }

    getObjectByClick(point: mapboxgl.Point):IObjectByClick{
        if (this.state.events?.getObjectByClick)
            return this.state.events?.getObjectByClick(point);
        else return null;
    }
    callChange(){
        if (this.state.events?.onChangeGeometry)
            return this.state.events.onChangeGeometry();
    }
    callRightClickCreate(e : MapMouseEvent & ToolEvent): boolean{
        if (this.state.events?.onClickRightButton)
            return this.state.events.onClickRightButton(e);
        return true;
    }
    callClickFirstPointPolygon(e : MapMouseEvent & ToolEvent): boolean{
        if (this.state.events?.onClickFirstPointPolygon)
            return this.state.events.onClickFirstPointPolygon(e);
        return false;
    }
    callDeleteRing(e : MapMouseEvent & ToolEvent){
        if (this.state.events?.onDeleteContour)
            return this.state.events.onDeleteContour(e);
    }
}