import {A2ConfirmActionStore} from "./A2ConfirmActionStore";
import {ra} from "../../../helper/utils/mobxUtils";
import {A2ConfirmExportMode} from "./A2ConfirmExportStore";

export class A2ConfirmDeleteStore extends A2ConfirmActionStore{

    async doDelete() {
        this.doHide();
        if (this.mode_export == A2ConfirmExportMode.folder) await this.deleteFolder();
        if (this.mode_export == A2ConfirmExportMode.selectedFields) await this.deleteFields();
    }

    async deleteFields(){
        let agro = this.root.agro2;
        if (agro.projectStruct.fieldsPage.selectedFields.length == 0) return;
        let field_ids = agro.projectStruct.fieldsPage.selectedFields.map(a => a.id);
        try {
            await agro.removeFields(field_ids);

            agro.root.addInfo(agro.root.trans["Removed successfully"]);
        }catch (err){
            agro.root.addError(err);
        }
    }

    async deleteFolder(){
        let store = this.root;
        try {
            await store.agro2.removeFieldsFilter(this.filterSelected);
            ra(()=>{
                store.addInfo(store.trans["Removed successfully"]);
            });
        }catch (err) {
            store.addError(err);
        }
    }

}