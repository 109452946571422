// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressUploadComp-box {
  padding: 1px;
  border-radius: 3px;
  background: #22282F;
}

.ProgressUploadComp-progress {
  border-radius: 3px;
  background: #4DB6BC;
  height: 100%;
}

div.ProgressUploadComp-red {
  background: #E94F4F;
}

div.ProgressUploadComp-green {
  background: #6ABC4D;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/PhotoPanel/UploadPanel/ProgressUploadComp.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,mBAAA;AAGF","sourcesContent":[".ProgressUploadComp-box{\r\n  padding: 1px;\r\n  border-radius: 3px;\r\n  background: #22282F;\r\n\r\n}\r\n.ProgressUploadComp-progress{\r\n  border-radius: 3px;\r\n  background: #4DB6BC;\r\n  height: 100%;\r\n}\r\ndiv.ProgressUploadComp-red{\r\n  background: #E94F4F;\r\n}\r\ndiv.ProgressUploadComp-green{\r\n  background: #6ABC4D;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
