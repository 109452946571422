import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoXlsxSvgProps {
    className?: string
}

export class AgroAhoXlsxSvg extends React.PureComponent<IAgroAhoXlsxSvgProps, undefined> {
    constructor(props: IAgroAhoXlsxSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)}
            width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8307 14.6253H2.16406L0.539062 12.4587L2.70573 10.292H23.2891L25.4557 12.4587L23.8307 14.6253Z" fill="#B3B3B3"/>
            <path d="M22.2031 25.4587H3.78646C3.18792 25.4587 2.70312 24.9739 2.70312 24.3753V1.62533C2.70312 1.02678 3.18792 0.541992 3.78646 0.541992H16.7865L23.2865 7.04199V24.3753C23.2865 24.9739 22.8017 25.4587 22.2031 25.4587Z" fill="#6B707B"/>
            <path d="M16.7891 0.541992V5.95866C16.7891 6.5572 17.2739 7.04199 17.8724 7.04199H23.2891L16.7891 0.541992Z" fill="#B3B3B3"/>
            <path d="M24.3724 22.209H1.6224C1.02385 22.209 0.539062 21.7242 0.539062 21.1257V12.459H25.4557V21.1257C25.4557 21.7242 24.9709 22.209 24.3724 22.209Z" fill="#444C56"/>
            <path d="M9.5219 19.5008H8.1921L7.36281 18.171L6.54273 19.5008H5.24219L6.65323 17.3244L5.32669 15.2471H6.60123L7.36931 16.5622L8.10815 15.2471H9.42006L8.07294 17.4143L9.5219 19.5008Z" fill="white"/>
            <path d="M10.0078 19.5008V15.2471H11.1572V18.5729H12.7952V19.5008H10.0078Z" fill="white"/>
            <path d="M16.2526 18.208C16.2526 18.4718 16.1854 18.7058 16.0516 18.9106C15.9179 19.1153 15.7245 19.2746 15.4726 19.3878C15.2207 19.5015 14.9244 19.5579 14.5854 19.5579C14.3021 19.5579 14.0648 19.5378 13.8725 19.4983C13.6802 19.4588 13.4809 19.3894 13.2734 19.2903V18.266C13.4928 18.3787 13.7203 18.4664 13.957 18.5293C14.1937 18.5921 14.4109 18.6235 14.6086 18.6235C14.7793 18.6235 14.9044 18.5943 14.984 18.5352C15.0636 18.4762 15.1032 18.3998 15.1032 18.3066C15.1032 18.2487 15.0869 18.1978 15.0555 18.1539C15.023 18.1105 14.9721 18.0661 14.9011 18.0217C14.8307 17.9773 14.6417 17.8858 14.3356 17.7482C14.0583 17.6225 13.8503 17.5001 13.7116 17.3815C13.573 17.2628 13.4701 17.1274 13.4034 16.9741C13.3363 16.8208 13.3027 16.6399 13.3027 16.4303C13.3027 16.0381 13.4451 15.7326 13.7306 15.5138C14.0161 15.295 14.4077 15.1855 14.906 15.1855C15.3464 15.1855 15.7954 15.2874 16.2531 15.4905L15.9011 16.3778C15.5035 16.1958 15.1601 16.1048 14.8714 16.1048C14.7219 16.1048 14.6135 16.1308 14.5453 16.1833C14.477 16.2358 14.4434 16.3008 14.4434 16.3778C14.4434 16.4612 14.4868 16.5359 14.5729 16.602C14.659 16.6681 14.893 16.7883 15.2754 16.9628C15.6421 17.128 15.8962 17.3045 16.0392 17.4936C16.1811 17.6832 16.2526 17.921 16.2526 18.208Z" fill="white"/>
            <path d="M20.7406 19.5008H19.4109L18.5816 18.171L17.7615 19.5008H16.4609L17.872 17.3244L16.5454 15.2471H17.82L18.5881 16.5622L19.3269 15.2471H20.6388L19.2917 17.4143L20.7406 19.5008Z" fill="white"/>
        </svg>;
    }
}