import * as React from 'react';
import autoBindReact from "auto-bind/react";
import * as ReactDOM from "react-dom";

export interface IRootPortalProps {
}

export class RootPortal extends React.PureComponent<IRootPortalProps, undefined> {
    constructor(props: IRootPortalProps) {
        super(props);
        autoBindReact(this);
        this.el = document.createElement('div');
    }
    el: any;
    getRoot(): HTMLElement{
        return  document.body;
    }
    componentDidMount() {
        this.getRoot().appendChild(this.el);
    }

    componentWillUnmount() {
        this.getRoot().removeChild(this.el);
    }
    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}
