import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {isString} from "lodash-es";
import VizSensor from 'react-visibility-sensor';
import {LoadStatus} from "../../../../helper/structs/LoadStatus";

import {TelescopeSVG} from "../../../icons/TelescopeSVG";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {action} from "mobx";
import {FavItemComp} from "./FavItemComp";
import autoBindReact from "auto-bind/react";
import {Utils} from "../../../../helper/utils/Utils";
import {ScrollSaveComp} from "../../../Common/ScrollSaveComp";
import { FavoritesListSceneStore } from '../../../../store/FavoritesListSceneStore';

export interface IFavListComp extends IStoreProps{
    list: FavoritesListSceneStore,
    reorder: boolean
}

@observer
export class FavListComp extends React.PureComponent<IFavListComp, undefined> {
    constructor(props: IFavListComp) {
        super(props);
        autoBindReact(this);
    }

    componentDidUpdate(prevProps: Readonly<IStoreProps>, prevState: Readonly<undefined>, snapshot?: any) {
        let store = this.props.store;
        let curSceneId = store.searchPanel.currentSceneid;
        if (isString(curSceneId) && curSceneId != ""){
            let elem = document.getElementById(curSceneId);
            if (elem != null)
                Utils.scrollIntoView(elem);

        }
    }
    componentDidMount() {
        let store = this.props.store;
        let curSceneId = store.searchPanel.currentSceneid;
        if (isString(curSceneId) && curSceneId != ""){
            let elem = document.getElementById(curSceneId);
            if (elem != null)
                Utils.scrollIntoView(elem);
        }
    }

    static reorder(list: any[], startIndex:number, endIndex:number){
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    @action
    onDragEnd(result: DropResult, provided: ResponderProvided){
        if (!result.destination) {
            return;
        }
        let sr = this.props.list;
        sr.groups = FavListComp.reorder(
            sr.groups,
            result.source.index,
            result.destination.index
        );
    }

    getItemStyle(isDragging: boolean, draggableStyle: any){
        let c = { "borderBottom":"1px solid #3E4751"};
        if (isDragging) return{
            borderTop: "2px solid #3A7BAF",
            borderRadius: "6px",
            background:"#383f48",
            ...draggableStyle,
            ...c
        }; else return { ...draggableStyle, ...c};
    }
    @action
    onScroll(pos: number){
        this.props.store.searchPanel.favorite_scrollPos = pos;
    }
    render() {
        let store = this.props.store;
        let sr = this.props.list;
        let arr:any[] = null;
        let val: any = null;
        //let showSearch = true;
        let loading = sr.searchState == LoadStatus.loading;

        if (this.props.reorder) {
            sr.groups.length;//создаём исскуственно зависимость от количества элементов в массиве, а то не работало удаление
            if (sr.records.length == 0){
                val = <div className="zoom-for-scene" key="no"><p>{store.trans["No selected images"]}</p></div>;
            }
            else val = (
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{width: "100%"}}
                                //style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                                {sr.groups.map((item, index) => (
                                    <Draggable key={item.groupId(true)} draggableId={item.groupId(true)} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <VizSensor key={item.groupId(true)}
                                                            partialVisibility={true}>{
                                                    (args: { isVisible: boolean }) => {
                                                        if (!args.isVisible && !item.wasVisible) return <div
                                                            id={item.groupId(true)}
                                                            key={item.groupId(true)}
                                                            className="left-sidebar__favitem__row4-in"/>;
                                                        return <FavItemComp searchGroup={item} store={store}
                                                                            key={item.groupId(true)}/>;
                                                    }
                                                }</VizSensor>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>);
        } else {
            arr = [];
            sr.groups.forEach(rec => {
                arr.push(<VizSensor key={rec.groupId(true)} partialVisibility={true}>{
                    (args: { isVisible: boolean }) => {

                        if (!args.isVisible && !rec.wasVisible) return <div id={rec.groupId()}
                                                                            key={rec.groupId()}
                                                                            className="left-sidebar__favitem__row4-in"/>;
                        return <FavItemComp searchGroup={rec} store={store}
                                            key={rec.groupId(true)}/>;
                    }
                }</VizSensor>);
            });
            if (arr.length == 0){
                arr.push(<div className="zoom-for-scene" key="no"><p>{store.trans["Nothing found, change search parameters"]}</p></div>);
            }
        }

        //<LoadingSpinCircle classes="loading-list" />
        return <ScrollSaveComp className="left-sidebar__favitem__row4 style-4 flex-fit-item" onScroll={this.onScroll} scrollTop={store.searchPanel.favorite_scrollPos}>
            {loading && <div className="loading-search-background center-content">
                <div className="loading-search">
                    <div className="spinner"/>
                </div>
            </div>}
            {arr}{val}
        </ScrollSaveComp>;
    }

}
