import * as React from 'react';
import {RefObject} from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {llParser} from "../../../helper/utils/llParser";
import autoBindReact from "auto-bind/react";
import {getSuggestions, searchId, searchText} from '../../../helper/utils/Geocoder';
import {SearchSuggestion} from "../../../store/SearchAddressStore";

@observer
export class SearchAddressComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.myRef = React.createRef();
    }
    myRef: RefObject<any>;

    autosuggestionTimer: any = null;

    @action
    onChangeText(event: any){
        let saStore = this.props.store.searchPanel.searchAddress;
        saStore.searchAddressText = event.target.value;
        if (this.autosuggestionTimer != null) clearTimeout(this.autosuggestionTimer);
        if (event.target.value.trim().length >= 3)
            this.autosuggestionTimer = setTimeout(this.getSuggestions, 300);
        else
            saStore.suggestions = [];
    }

    @action
    parseText(){
        let store = this.props.store.searchPanel;
        let txt = store.searchAddress.searchAddressText;
        try {
            let ll = llParser(txt);
            store.searchAddress.setSearchAddressMarker(
                txt, ll.lat, ll.lon
            );
            store.searchAddress.suggestions = [];
            return;
        }catch {

        }
        this.searchText(txt);
    }

    onSearchDone(res: any) {
        if (! res)
            return;
        let saStore = this.props.store.searchPanel.searchAddress;
        if (saStore.searchAddressText != res.display_name)
            saStore.searchAddressText = res.display_name;
        saStore.setSearchAddressPolygon(
            res.display_name, parseFloat(res.lat), parseFloat(res.lon), res.geojson);
    }

    onSearchFail(err: any) {
        this.props.store.addError(err);
    }

    @action
    searchText(txt: string, isId = false) {
        this.props.store.searchPanel.searchAddress.suggestions = [];
        let search = isId? searchId: searchText;
        search(txt, this.onSearchDone, this.onSearchFail);
    }

    @action
    getSuggestions() {
        let saStore = this.props.store.searchPanel.searchAddress;
        getSuggestions(saStore.searchAddressText,
            function(json: any) {
                saStore.suggestions = json.features.map( (_: any) => {
                    let p:{name: string, street: string,
                        city: string, district: string, state: string, country: string,
                        housenumber: string,osm_id: string,osm_type: string} = _.properties;
                    let name = p.name?? [p.street, p.housenumber].filter(Boolean).join(", ");
                    let objName = [name, p.district, p.city, p.state, p.country].filter(Boolean).join(", ");
                    return new SearchSuggestion(objName, p.osm_id, p.osm_type);
                });
            }, this.onSearchFail);
    }

    @action
    onPressKey(event: any){
        let store = this.props.store;
        if ((event.keyCode == 13 || event.keyCode == 27) && this.autosuggestionTimer != null)
            clearTimeout(this.autosuggestionTimer);
        if (event.keyCode == 13){
            this.parseText();
        }
        if (event.keyCode === 27){
            store.searchPanel.searchAddress.searchAddressText = "";
            store.searchPanel.searchAddress.closePanel();
        }
    }

    @action onSuggestionPressed(e : any){
        let id = e.currentTarget.getAttribute("data-id");
        this.searchText(id, true);
    }

    markText(text: string, words: string[]) {
        let wordMask : Uint8Array = new Uint8Array(text.length);
        words.forEach(word => {
            if (word == "") return;
            let wordStart: number = -1;
            while (true) {
                wordStart = text.indexOf(word, wordStart);
                if (wordStart < 0) break;
                for (let i = 0; i < word.length; i++)
                    wordMask[i + wordStart] = 1;
                wordStart += word.length;
            }
        });
        let startPosition: number = -1;
        let isMarking: boolean = false;
        let marks : {start: number, end: number}[] = [];
        for (let i = 0; i < wordMask.length; i++) {
            if (wordMask[i] == 1 && ! isMarking) {
                startPosition = i;
                isMarking = true;
            }
            if ((wordMask[i] == 0 || i == wordMask.length - 1) && isMarking) {
                marks.push({start: startPosition, end: i});
                isMarking = false;
            }
        }
        return marks;
    }
    @action
    onClearText(){
        let saStore = this.props.store.searchPanel.searchAddress;
        saStore.searchAddressText = "";
        saStore.suggestions = [];
        this.props.store.root.map.superTools.searchAddressMarker.geoObject = null;
    }

    render() {
        let store = this.props.store;
        let saStore = this.props.store.searchPanel.searchAddress;
        let words = saStore.searchAddressText.trim().toUpperCase().split(/\s+/)

        let hints: any[] = [];
        let uniKeyCounter = 0;
        saStore.suggestions.forEach((s, i) => {
            let wordList = this.markText(s.text.toUpperCase(), words);
            let spans: any[] = [];
            let wordStart = 0;
            for (let j = 0; j < wordList.length; j++) {
                spans.push(<span key={uniKeyCounter}>{s.text.substring(wordStart, wordList[j].start)}</span>);uniKeyCounter++;
                spans.push(<span style={{color: "#4DB6BC", fontWeight: 600}} key={uniKeyCounter}>
                    {s.text.substring(wordList[j].start, wordList[j].end)}
                </span>);uniKeyCounter++;
                wordStart = wordList[j].end;
            }
            if (wordList.length > 0)
                spans.push(<span key={uniKeyCounter}>{s.text.substring(wordStart)}</span>);uniKeyCounter++;
            let label = <label key={i} data-id={s.id} onClick={this.onSuggestionPressed} className="searchAddressSuggestion">
                {wordList.length == 0? s.text : spans}
            </label>;
            hints.push(label);
        });

        return <div className="searchAddress-box" ref={this.myRef}>
            <div className="searchAddress">
            <input type="text" className="searchAddress-editor width100"
                   placeholder={store.trans["Search: coordinates, OSM objects"]}
                   onKeyDown={this.onPressKey}
                   value={saStore.searchAddressText}
                   onChange={this.onChangeText} />
                {saStore.searchAddressText != "" && <div className="searchAddress-down blue_fill_onhover" onClick={this.onClearText}>
                <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.78571 0.214286C9.5 -0.0714286 9.07143 -0.0714286 8.78572 0.214286L5 4L1.21429 0.214286C0.928571 -0.0714286 0.5 -0.0714286 0.214286 0.214286C-0.0714286 0.5 -0.0714286 0.928571 0.214286 1.21429L4 5L0.214286 8.78572C-0.0714286 9.07143 -0.0714286 9.5 0.214286 9.78571C0.357143 9.92857 0.5 10 0.714286 10C0.928571 10 1.07143 9.92857 1.21429 9.78571L5 6L8.78572 9.78571C8.92857 9.92857 9.14286 10 9.28571 10C9.42857 10 9.64286 9.92857 9.78571 9.78571C10.0714 9.5 10.0714 9.07143 9.78571 8.78572L6 5L9.78571 1.21429C10.0714 0.928571 10.0714 0.5 9.78571 0.214286Z" />
                </svg>
                </div>}
        </div>
        {hints.length > 0 &&
        <div className="searchAddressSuggestionPanel style-4">
            {hints}
        </div>}
        </div>;
    }
}
