// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Agro2ToolsGeometryExt-main {
  background: #2B3138;
  position: absolute;
  left: 0;
  top: 20%;
  border-radius: 0 6px 6px 0;
  display: flex;
  flex-direction: column;
  padding: 10px 6px;
  border-left: 1px solid #3E4751;
  z-index: 5;
}

.Agro2ToolsGeometryExt-button {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
}

.Agro2ToolsGeometryExt-button.disabled {
  opacity: 0.3;
}

.Agro2ToolsGeometryExt-button.active {
  background: #355960;
}

.Agro2ToolsGeometryExt-button:not(.active):not(.disabled):hover {
  background: #3e4751;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/A2FieldEdit/Tools/Agro2ToolsGeometryExt.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,8BAAA;EACA,UAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,mBAAA;AAIF;;AAFA;EACE,mBAAA;AAKF","sourcesContent":[".Agro2ToolsGeometryExt-main{\r\n  background: #2B3138;\r\n  position: absolute;\r\n  left: 0;\r\n  top: 20%;\r\n  border-radius: 0 6px 6px 0;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px 6px;\r\n  border-left: 1px solid #3E4751;\r\n  z-index: 5;\r\n}\r\n.Agro2ToolsGeometryExt-button{\r\n  width: 28px;\r\n  height: 28px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  cursor: pointer;\r\n  border-radius: 3px;\r\n}\r\n.Agro2ToolsGeometryExt-button.disabled{\r\n  opacity: 0.3;\r\n}\r\n.Agro2ToolsGeometryExt-button.active{\r\n  background: #355960;\r\n}\r\n.Agro2ToolsGeometryExt-button:not(.active):not(.disabled):hover {\r\n  background: #3e4751;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
