// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A2FieldFilterSelectedCheckbox-div {
  width: 16px;
  height: 16px;
  align-self: center;
  cursor: pointer;
  position: relative;
}

.A2FieldFilterSelectedCheckbox-div.noselection {
  cursor: default;
  opacity: 0.2;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/A2FieldList/A2FieldFilterSelectedCheckbox.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,YAAA;AACJ","sourcesContent":[".A2FieldFilterSelectedCheckbox-div {\n    width: 16px;\n    height: 16px;\n    align-self: center;\n    cursor: pointer;\n    position: relative;\n}\n\n.A2FieldFilterSelectedCheckbox-div.noselection {\n    cursor: default;\n    opacity: 0.2;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
