import * as React from 'react';
import {CustomSvg} from "../CustomSvg";
import { IIconProp } from '../IIconProp';

export class RemoveOptionSvg extends CustomSvg {
    render() {
        return <svg width="16" height="16" viewBox="0 0 16 16" className={this.getClasses()} xmlns="http://www.w3.org/2000/svg">
        <path d="M14 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V14C0.5 14.8284 1.17157 15.5 2 15.5H14C14.8284 15.5 15.5 14.8284 15.5 14V2C15.5 1.17157 14.8284 0.5 14 0.5Z" stroke="#4DB6BC" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 8H11.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>;
        return <svg width="16" height="16" viewBox="0 0 16 16" className={this.getClasses()} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5261_2061)">
        <path d="M14 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V14C0.5 14.8284 1.17157 15.5 2 15.5H14C14.8284 15.5 15.5 14.8284 15.5 14V2C15.5 1.17157 14.8284 0.5 14 0.5Z" stroke="#4DB6BC" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 4.5V11.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 8H11.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_5261_2061">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
        </svg>      
    }
}
