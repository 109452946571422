// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowRoom2Projects-keyValue {
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
}

.ShowRoom2Projects-key {
  flex-grow: 1;
}

.ShowRoom2Projects-value {
  width: 283px;
  display: flex;
}

.ShowRoom2Projects-value-dropdown {
  flex-grow: 1;
}

.ShowRoom2Projects-loading {
  margin-left: 10px !important;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/dataShowRoom2/ShowRoom2Projects.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,YAAA;EACA,aAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,4BAAA;AAKF","sourcesContent":[".ShowRoom2Projects-keyValue{\r\n  display: flex;\r\n  margin-bottom: 10px;\r\n  margin-left: 20px;\r\n  margin-right: 20px;\r\n  align-items: center;\r\n}\r\n.ShowRoom2Projects-key{\r\n  flex-grow: 1;\r\n}\r\n.ShowRoom2Projects-value{\r\n  width: 283px;\r\n  display: flex;\r\n}\r\n.ShowRoom2Projects-value-dropdown{\r\n  flex-grow: 1;\r\n}\r\n.ShowRoom2Projects-loading{\r\n  margin-left: 10px!important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
