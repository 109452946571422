import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {ZaborSvg} from "../../Agro/Buttons/ZaborSvg";
import {IProjectsProjResponse} from "../../../../../store/user/UserStore";
import {action, runInAction} from "mobx";
import {A2LineButton} from "../A2FieldList/A2LineButton";
import {PenSvg} from "../../Agro/Buttons/PenSvg";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {ra} from "../../../../../helper/utils/mobxUtils";
import {A2NavigatorSection} from "../../../../../store/agro/A2AgroStore";
import {TextUtils} from "../../../../../helper/utils/TextUtils";
import {ReactUtils} from "../../../../../helper/utils/ReactUtils";
import { OverlayTool } from '../../../../../store/tools/class/OverlayTool';

export interface IAgroProjectItemComp extends IStoreProps{
    projectInfo: IProjectsProjResponse;
}
@observer
export class AgroProjectItemComp extends React.Component<IAgroProjectItemComp, undefined> {
    constructor(props: IAgroProjectItemComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onEditProject(event: any){
        event.stopPropagation();
        let store = this.props.store;
        try {
            store.agro2.projectEditor.toEditProject(this.props.projectInfo);
        }catch (e) {
            store.addError(e);
        }
    }

    @action
    async onClick(event: any){
        event.stopPropagation();
        let store = this.props.store;
        let hasChange = !store.layerListStore.isButtonActive
        if (store.layerListStore.isEditingMode && hasChange ) {
            store.layerListStore.isProjectChanging = true;
            const userConfirmed = await store.layerListStore.showCancelConfirmation();
            if (!userConfirmed) {
                return;
            }
        }
        store.layerListStore.isEditingMode = false
        store.map.superTools.overlayTool.unloadOverlays()
        try {
            await store.agro2.projectList.loadProject(this.props.projectInfo.proj_name);
        }
        catch(err) {
            ra(()=>{
                store.addError(err);
            });
        }
    }


    render() {
        let store = this.props.store;
        let date = "";
        let srcDate = this.props.projectInfo.upd_time;
        if (srcDate != null){
            date = (new Date(srcDate)).toLocaleDateString();
        }
        let filterStr = this.props.store.agro2.projectList.filter_projects;


        return <div className="AgroFarmList-row AgroFarmList-row-hover relative" onClick={this.onClick}>
            <div className="AgroFarmList-td-titleRow">
                <div className="AgroFarmList-td-titleText" onClick={this.onClick}>
                    {ReactUtils.getHighlight(this.props.projectInfo.proj_title, filterStr)}
                </div>
                <div className="AgroFarmList-td-titleDate">
                    {date}
                    <div className="AgroFarmList-td-titlePen white_svg blue_svg_onhover" onClick={this.onEditProject}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.075 2.625L0.225 8.475C0.075 8.625 0 8.775 0 9V11.25C0 11.7 0.3 12 0.75 12H3C3.225 12 3.375 11.925 3.525 11.775L9.375 5.925L6.075 2.625Z" stroke="none" />
                            <path d="M11.775 2.475L9.525 0.225C9.225 -0.075 8.775 -0.075 8.475 0.225L7.125 1.575L10.425 4.875L11.775 3.525C12.075 3.225 12.075 2.775 11.775 2.475Z" stroke="none" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="AgroProjectItem-td-desc">
                {ReactUtils.getHighlight(store.agro2.projectList.getUserName(this.props.projectInfo), filterStr)}
            </div>
            <div className="AgroProjectItem-td-desc">
                {ReactUtils.getHighlight(this.props.projectInfo.proj_owner.email, filterStr)}
            </div>
        </div>;
    }
}
