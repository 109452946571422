import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoToursPanelComp.scss'
import classNames from "classnames";
import {action, observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoSortSvg} from "../icons/AgroAhoSortSvg";
import {AgroAhoWindowComp} from "../AgroAhoWindowComp";
import {AgroAhoCardTourItemComp} from "../card/AgroAhoCardTourItemComp";
import {AgroAhoMenuSvg} from "../icons/AgroAhoMenuSvg";
import {AhoModalType} from "../../agroAhoStore";
import {TourItem2} from "../../agroAhoTourStore";

@observer
export class AgroAhoToursPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    menuVisible: boolean = false;
    @observable
    alignBottom: boolean = false;

    @action
    onClose(){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.toursShow = false;
    }

    @action
    onSelectAllClick(){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.doSelectTourAllClick();
    }
    onMenuBlur(){
        this.menuVisible = false;
    }
    onMenuClick(){
        let el = document.querySelector('.AgroAhoToursPanelComp-menuSvg');
        if (el) {  // смещаем меню вниз если недостаточно места справа
            let rect = el.getBoundingClientRect();
            let rightSpace = document.body.clientWidth - rect.x -rect.width;
            this.alignBottom = rightSpace < 250;
        }
        this.menuVisible = !this.menuVisible;
    }
    onMenuCopyClick(e: any){
        let tstore = this.props.store.agroAhoStore.tourStore;
        if (this.selCount() > 1) return;
        this.menuVisible = false;
        tstore.onTourCopyClick();
        e.stopPropagation();
        e.preventDefault();
    }
    onMenuMergeClick(e: any){
        let tstore = this.props.store.agroAhoStore.tourStore;
        if (this.selCount() < 2) return;
        this.menuVisible = false;
        tstore.onTourMergeClick();
        e.stopPropagation();
        e.preventDefault();
    }
    onMenuDelClick(e: any){
        let store_ = this.props.store.agroAhoStore;
        this.menuVisible = false;
        store_.toggleModal(true, AhoModalType.DeleteTours);
        e.stopPropagation();
        e.preventDefault();
    }

    selCount(){
        let tstore = this.props.store.agroAhoStore.tourStore;
        let selected = 0;
        if (tstore.toursItems) {
            let selectedItems = tstore.toursItems.filter((t: any)=>t.checked);
            selected = selectedItems.length;
        }
        return selected;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;
        let selected = this.selCount();

        return <div className="AgroAhoToursPanelComp-main">
            <div className="AgroAhoToursPanelComp-closer" onClick={this.onClose}>
                <div className="arrow-left"/>
            </div>
            <div className="AgroAhoToursPanelComp-header">
                <div>{store_.trans.Tours}</div>
                <div className="AgroAhoToursPanelComp-stat">
                    <span>{tstore.selTours(true).length}&nbsp;/&nbsp;{tstore.toursItems?.length || 0}</span>
                </div>
            </div>
            <div className="AgroAhoToursPanelComp-listHeader">
                <div className="AgroAhoToursPanelComp-checkboxCover" onClick={this.onSelectAllClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoToursPanelComp-selectCheckbox"
                        checked={tstore.selectTourAll}/>
                    <div className="AgroAhoToursPanelComp-listCaption">{store_.trans["Select all"]}</div>
                </div>
                <div className="AgroAhoToursPanelComp-splitter"> </div>
                {/*<AgroAhoSortSvg className="AgroAhoToursPanelComp-sortSvg" desc={tstore.sortToursDesc}*/}
                {/*    onClick={tstore.doSortToursClick}/>*/}

                <AgroAhoWindowComp store={store} className={classNames("AgroAhoToursPanelComp-menuCover",{
                    "AgroAhoToursPanelComp-menuAlignBottom": this.alignBottom,
                    "AgroAhoToursPanelComp-hidden": !selected})} visible={this.menuVisible}
                    /*onClick={this.onMenuShow}*/ onBlur={this.onMenuBlur} button={
                    <AgroAhoMenuSvg className="AgroAhoToursPanelComp-menuSvg" onClick={this.onMenuClick} />}>
                    <div className={classNames("AgroAhoToursPanelComp-menuItem", {
                        "AgroAhoToursPanelComp-menuItemDisabled": selected > 1})} onClick={this.onMenuCopyClick}>{
                        store_.trans['Copy selected tour']}</div>
                    <div className={classNames("AgroAhoToursPanelComp-menuItem", {
                        "AgroAhoToursPanelComp-menuItemDisabled": selected < 2})} onClick={this.onMenuMergeClick}>{
                        store_.trans['Merge selected tours']}</div>
                    <div className="AgroAhoToursPanelComp-menuItem AgroAhoToursPanelComp-menuItemRed"
                         onClick={this.onMenuDelClick}>{store_.trans['Delete selected tours from ASA map']}</div>
                </AgroAhoWindowComp>

            </div>
            <div className="AgroAhoToursPanelComp-ToursCover style-4">
                {tstore.toursItems &&
                tstore.toursItems.map((f: TourItem2)=><AgroAhoCardTourItemComp key={f.tour_id}
                    store={store} tour={f}/>)}
            </div>
        </div>
    }
}
