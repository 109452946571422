import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import './SwitchItems.scss';
import {isFunction} from "lodash-es";
import {IDropDownItem} from "./DropDownComp";

export interface ISwitchItem{
    key: string | number;
    value: string;
    //childNode?: ReactNode;
    data?: any;
    class?: string;
}


export interface ISwitchItemsProps {
    items: ISwitchItem[];
    currentKey: string | number;
    className?: string;
    onChange?: (item: ISwitchItem)=>any;
}

export class SwitchItems extends React.PureComponent<ISwitchItemsProps, undefined> {
    constructor(props: ISwitchItemsProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(e: any){
        let key = e.currentTarget.getAttribute("data-key");
        let index = this.props.items.findIndex(a => a.key == key);
        let v:IDropDownItem = null;
        if (index >= 0) v = this.props.items[index];
        if (isFunction(this.props.onChange)){
            this.props.onChange(v);
        }
    }

    render() {
        let items: any[] =[];
        this.props.items.forEach(a => {
            items.push(<div key={a.key} data-key={a.key} className={classNames("SwitchItems-item", a.class,
                {
                    "SwitchItems-item-active": a.key == this.props.currentKey
                })} onClick={this.onClick}><span>{a.value}</span></div>);
        });
        return <div className={classNames("SwitchItems-div",this.props.className)}>{items}</div>
    }
}
