// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowRoom2-main {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: #C5C5C5;
  height: 100%;
  border-left: 1px solid #22282F;
  border-top: 1px solid #22282F;
  display: flex;
  flex-direction: column;
}

.ShowRoom2-titleDiv {
  display: flex;
  margin: 10px 15px;
  align-items: center;
  justify-content: space-between;
}

.ShowRoom2-switcher {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.ShowRoom2-separateline {
  margin: 20px 15px;
  height: 1px;
  min-height: 1px;
  background: #22282F;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/dataShowRoom2/ShowRoom2.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,8BAAA;EACA,6BAAA;EACA,aAAA;EACA,sBAAA;AACF;;AACA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;AAEF;;AAAA;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;AAGF;;AADA;EACE,iBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;AAIF","sourcesContent":[".ShowRoom2-main{\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  font-size: 11px;\r\n  line-height: 12px;\r\n  color: #C5C5C5;\r\n  height: 100%;\r\n  border-left: 1px solid #22282F;\r\n  border-top: 1px solid #22282F;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n.ShowRoom2-titleDiv{\r\n  display: flex;\r\n  margin: 10px 15px;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n.ShowRoom2-switcher{\r\n  margin-top: 10px;\r\n  margin-bottom: 20px;\r\n  margin-left: 15px;\r\n}\r\n.ShowRoom2-separateline{\r\n  margin: 20px 15px;\r\n  height: 1px;\r\n  min-height: 1px;\r\n  background: #22282F;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
