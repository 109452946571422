import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './PhotoEditor.scss';
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {UploadItemComp} from "../UploadPanel/UploadItemComp";
import {PhotoEditorTools} from "./PhotoEditorTools";
import VizSensor from "react-visibility-sensor";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import './../PhotoList/PhotoPanelPhotoList.scss';
import {rai} from "../../../../../helper/utils/mobxUtils";
import {PhotoFileItemStore} from "../../../../../store/photo/PhotoFileItemStore";
import {isNumber} from "lodash-es";
import {PhotoPanelTopFiltersComp} from "../PhotoFiltersElements/PhotoPanelTopFiltersComp";

//юзается

@observer
export class PhotoEditor extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.props.store.photo.initTags();
    }

    @action
    close(){
        this.props.store.photo.editor.editorWindowShow = false;
    }

    @action
    onDeleteTag(photo_id: number, tag_id: number){
        this.props.store.photo.editor.sendDeleteTags([photo_id], [tag_id]);
        this.props.store.map.superTools.photoLayersTool.refreshTiles();
    }
    @action
    onZoomToPhoto(item: PhotoFileItemStore){
        if (isNumber(item.lon)) {
            this.props.store.photo.editor.editorWindowShow = false;
            this.props.store.map.mapbox.flyTo({zoom: 17, center: {lat: item.lat, lng: item.lon}});
        }
    }

    render() {
        let store = this.props.store;
        let editor = store.photo.editor;
        let photoItems: any[] = [];
        editor.photos.forEach((a, idx) => {
            let k = a.photo_id;
            photoItems.push(<VizSensor key={k} partialVisibility={true}>{
                (args: { isVisible: boolean }) => {
                    if (!a.was_visible && (args.isVisible == null || !args.isVisible)) return <div key={k} className="PhotoFilterLeftTagGroup-divEmpty"/>;
                    a.was_visible = true;
                    if (idx == (editor.photos.length - 4) &&
                        (store.photo.featuresLoadStatus != LoadStatus.loading) &&
                        !store.photo.featuresLoadToEnd){
                            //нельзя action в render
                            rai(()=>{
                                if ((store.photo.featuresLoadStatus != LoadStatus.loading) &&
                                    !store.photo.featuresLoadToEnd) store.photo.doLoadPhotoListNextPage();
                            });
                    }
                    return <UploadItemComp item={a} store={store} key={a.photo_id} onZoomToPhoto={this.onZoomToPhoto}
                                           onDeleteTag={this.onDeleteTag} />;
                }
            }</VizSensor>);
            //photoItems.push(<UploadItemComp item={a} store={store} key={a.photo_id} onDeleteTag={this.onDeleteTag} />);
        });


        return <div className="overMapFullWindow PhotoEditor-window">
            <div className="PhotoEditor-header">
                <div className="PhotoEditor-headerTitle">{store.trans.List}</div>
                <div className="flex-stretch-item"> </div>
                <div className="PhotoEditor-headerClose white-fill blue_fill_onhover" onClick={this.close}>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" />
                    </svg>
                </div>
            </div>
            <div className="separator_line" />
            <PhotoEditorTools store={store} />
            <PhotoPanelTopFiltersComp className="PhotoEditor-marginLR PhotoPanel-filters" store={store} />
            <div className="flex-stretch-item PhotoEditor-marginLR relative overflowHidden">
                <div className="height100 width100 overflowAutoY style-4 relative">
                    {photoItems}
                </div>
                {store.photo.featuresLoadStatus == LoadStatus.loading && <div className="PhotoPanelPhotoListComp-loadingBackground center-content">
                    <div className="loading-search">
                        <div className="spinner"/>
                    </div>
                </div>
                }
            </div>
            <div className="separator_line" />
            <div className="PhotoEditor-footer">
                {store.trans.Photo}: {this.props.store.photo.featuresTotalCount}
            </div>
        </div>;
    }
}
