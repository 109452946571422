import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoSelCellsPanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";

@observer
export class AgroAhoSelCellsPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onCancelClick(){
        this.props.store.agroAhoStore.stmtStore.doMapSelCancelClick();
    }
    onApplyClick(){
        if (!this.canApply()) return;
        this.props.store.agroAhoStore.stmtStore.doMapSelApplyClick();
    }
    canApply(){
        let ts = this.props.store.agroAhoStore.mapStore.stmtSelCellsStore;
        return !!ts.exts.filter(e=>e.ext).length;
    }

    // onZoomClick(){
    //     this.props.store.agroAhoStore.mapStore.mapEditRouteStore.doZoomClick();
    // }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ts = this.props.store.agroAhoStore.mapStore.stmtSelCellsStore;
        let selCnt = ts.exts.filter(e=>e.ext).length;

        return <div className="AgroAhoSelCellsPanelComp-main">
            <div className="AgroAhoSelCellsPanelComp-window">
                <div>{store_.trans["Select cells by clicking for advanced research"]}</div>
                <div className="AgroAhoSelCellsPanelComp-sel">{store_.trans2['Selected']}: {selCnt}</div>

                {/*<div className="AgroAhoSelCellsPanelComp-zoomCover" onClick={this.onZoomClick}>*/}
                {/*    <AgroAhoZoomSvg/>*/}
                {/*</div>*/}

                {/*<div className="AgroAhoSelCellsPanelComp-splitter"> </div>*/}
                <div className="AgroAhoSelCellsPanelComp-cancelButton" onClick={this.onCancelClick}>
                    {store_.trans.Cancel}</div>
                <div className={classNames("AgroAhoSelCellsPanelComp-applyButton", {
                    "AgroAhoSelCellsPanelComp-disabled": !this.canApply()})} onClick={this.onApplyClick}>
                    {store_.trans.Apply}</div>
            </div>
        </div>
    }
}
