import * as React from 'react';
import {CustomStoreTool} from "../general/CustomStoreTool";

export class TestTool extends CustomStoreTool{
    async onInstall() {

    }

    onMoveStart() {
        //console.log("onMoveStart");
    }

    onMoveEnd() {
        //console.log("onMoveEnd");
    }
}
