import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './VoronovExportWidgetComp.scss';
import {action, observable} from "mobx";
import classNames from "classnames";
import {A2ExportFormat} from "../../../../app/store/agro/A2AgroStore";
import {A2ExportFormatComp} from "../../../../app/components/panels/Left/Agro2/A2Common/A2ExportFormatComp";
import {ConstrMethod, SampleSelectionUrl, SelectionRoute} from "../../store/VoronovExportStore";
import {Utils} from "../../../../app/helper/utils/Utils";
import {ra} from "../../../../app/helper/utils/mobxUtils";
import {LoadingAniSvg} from "../../../../app/components/icons/LoadingAniSvg";
import {LoadStatus} from "../../../../app/helper/structs/LoadStatus";
import {IAgroExportStoreProps} from "../../agroExportPlugin";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AgroExportInputNumber} from "../AgroExportInputNumber";
import {MyHint} from "../../../../app/components/Common/MyHint";

@observer
export class VoronovExportWidgetComp extends React.Component<IAgroExportStoreProps, undefined> {
    constructor(props: IAgroExportStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onChangeNumFrom(str: string){
        this.props.store.agroExport.voronovExportStore.numberFrom = str;
    }
    @action
    onChangeCellArea(str: string){
        this.props.store.agroExport.voronovExportStore.cellArea = str;
    }
    @action
    async onApply(){
        if (!this.props.store.agroExport.voronovExportStore.needApply) return;
        this.loading = true;
        try {
            await this.props.store.agroExport.voronovExportStore.apply();

        }catch (err) {
            this.props.store.addError(err);
        }
        ra(()=>{
            this.loading = false;
        });
    }
    @observable
    loading: boolean = false;
    @observable
    exporting: boolean = false;

    @action
    onClose(){
        this.props.store.agroExport.voronovExportStore.hideWidget();
    }
    @observable
    downloadFormatShow: boolean = false;

    @action
    async onDownload(){
        let vstore = this.props.store.agroExport.voronovExportStore;
        if (!this.isEnabledData() || vstore.needApply) return;
        // >>> debug console msg >>>
        let idPermalink = await this.props.store.putPermalinkAndGetId();
        let url = vstore.getUrl(SampleSelectionUrl.download);
        url += `&permalink=${idPermalink}`;
        console.log('Download url:', url);
        // <<< ---------------- <<<
        this.downloadFormatShow = true;
    }
    @action
    onDownloadCancel(){
        this.downloadFormatShow = false;
    }
    @action
    async onDownloadSubmit(format: A2ExportFormat){
        this.downloadFormatShow = false;
        let store = this.props.store;
        let vstore = store.agroExport.voronovExportStore;
        this.exporting = true;
        try {
            let idPermalink = await this.props.store.putPermalinkAndGetId();
            let url = vstore.getUrl(SampleSelectionUrl.download, format);
            url += `&permalink=${idPermalink}`;
            Utils.downloadFile(url);
            ra(()=>{
                this.exporting = false;
            });
        }catch (err){
            this.exporting = false;
            store.addError(err);
        }
    }

    @action
    onClickEye(){
        if (!this.isEnabledData()) return;
        let store = this.props.store;
        let vstore = store.agroExport.voronovExportStore;
        vstore.showLayers = !vstore.showLayers;
    }

    isEnabledData(): boolean{
        let store = this.props.store;
        let vstore = store.agroExport.voronovExportStore;
        return !this.loading && (vstore.statusLoad == LoadStatus.ready);
    }
    @action
    async onSave(){
        let store = this.props.store;
        if (store.agroExport.voronovExportStore.needApply) return;

        await store.agroExport.voronovExportStore.saveOptions.reset();
        store.agroExport.voronovExportStore.saveOptions.showSaveForm = true;
        let navItem = store.agro2.projectStruct.activeNavigatorPage;
        let paths = navItem.getNaviPath();
        let pathsRes: string[] = [];
        pathsRes.push(store.agro.projectTitle);
        for(let i = paths.length - 1; i >= 1 ; i--){
            pathsRes.push(paths[i].column_stringValue);
        }
        store.agroExport.voronovExportStore.saveOptions.paths = pathsRes;
        store.agroExport.voronovExportStore.saveOptions.fieldsCount = navItem.selectedFields.length;
    }

    onChangeMethod(item: IDropDownItem){
        let vstore = this.props.store.agroExport.voronovExportStore;
        vstore.constrMethod = item.key as ConstrMethod;
    }

    onChangeRoute(item: IDropDownItem){
        let vstore = this.props.store.agroExport.voronovExportStore;
        vstore.selectionRoute = item.key as SelectionRoute;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroExport;
        let vstore = store_.voronovExportStore;
        let cells = '';
        let numbers = '';

        let methodItems: IDropDownItem[] = [
            {key: ConstrMethod.ByVorAvgArea, value: store_.trans['Adaptive partitioning']},
            // {key: ConstrMethod.ByVorAvgArea, value: store_.trans['Voronoi diagrams']},
            // {key: ConstrMethod.ByVorNumSamples, value: store_.trans['Voronoi diagrams / number of samples'], disabled: true},
            {key: ConstrMethod.ByRectAvgArea, value: store_.trans['Rectangular grid']},
            // {key: ConstrMethod.ByRectNumSamples, value: store_.trans['Rectangular grid / number of samples'], disabled: true}
        ];
        let routeItems: IDropDownItem[] = [
            {key: SelectionRoute.Diagonal , value: store_.trans.Diagonal},
            {key: SelectionRoute.AvgLine , value: store_.trans['Avg. line']},
            {key: SelectionRoute.ZShaped, value: store_.trans['Z-shaped']}
        ];

        let unit = store.trans.ha;
        let numTitle = store_.trans['Avg. square'];
        if (vstore.constrMethod == ConstrMethod.ByVorNumSamples ||
            vstore.constrMethod == ConstrMethod.ByRectNumSamples) {
            unit = store_.trans.pcs;
            numTitle = store_.trans['Num. of sampl.'];
        }
        let st = {cells: 0, min: 0, max: 0};
        if (vstore.geoJsonCells?.data?.features) {
            vstore.geoJsonCells.data.features.forEach(f=>{
                st.cells += 1;
                let cnum = +f.properties.cell_id;
                if (st.min > cnum || !st.min) st.min = cnum;
                if (st.max < cnum || !st.max) st.max = cnum;
            });
            cells = st.cells+'';
            numbers = `${st.min} - ${st.max}`;
        }


        return <div className="VoronovExportWidgetComp-back">
            <div className={classNames("VoronovExportWidgetComp-window ")}>
                <div className={classNames("VoronovExportWidgetComp-items")}>
                    <div className={classNames("VoronovExportWidgetComp-eye",
                            {"blue-svg": vstore.showLayers, "white-svg": !vstore.showLayers || !this.isEnabledData(),
                                "opacity30": !this.isEnabledData(), "pointer": this.isEnabledData()})}
                         onClick={this.onClickEye}>
                        <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00013 12C12.7071 12 15.7441 6.716 15.8711 6.492C16.0421 6.188 16.0431 5.816 15.8721 5.512C15.7461 5.287 12.7311 0 8.00013 0C3.24513 0 0.251127 5.289 0.126127 5.514C-0.0428725 5.817 -0.0418725 6.186 0.128127 6.489C0.254128 6.713 3.26913 12 8.00013 12ZM8.00013 2C10.8391 2 13.0361 4.835 13.8181 6C13.0341 7.166 10.8371 10 8.00013 10C5.15913 10 2.96213 7.162 2.18113 5.999C2.95813 4.835 5.14613 2 8.00013 2Z" stroke="none"/>
                            <path d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"  stroke="none"/>
                        </svg>
                    </div>

                    <div className="VoronovExportWidgetComp-cover">
                        <div className="VoronovExportWidgetComp-title">{store_.trans['Construction method']}</div>
                        <DropDownComp items={methodItems} currentKey={vstore.constrMethod} onChange={this.onChangeMethod}
                            className="VoronovExportWidgetComp-constrMethod" classNameDown="VoronovExportWidgetComp-constrMethodDown" />
                    </div>

                    <div className="VoronovExportWidgetComp-cover">
                        <div className="VoronovExportWidgetComp-title">{numTitle}</div>
                        <div className={classNames("flex-columns-centerByVertical", {"opacity30": this.loading})}>
                            {/*<div className={classNames("VoronovExportWidgetComp-label")} style={{marginLeft: "20px"}}>Площадь ячейки (га)</div>*/}
                            <AgroExportInputNumber value={vstore.cellArea} onChange={this.onChangeCellArea} /*min={0.001}*/
                                              classNameInput="text-box-editor number-without-arrow VoronovExportWidgetComp-numInput flex-strech-item"
                                              classNameDiv="VoronovExportWidgetComp-numInputDiv" unit={unit}/>
                        </div>
                    </div>

                    <div className="VoronovExportWidgetComp-cover">
                        <div className="VoronovExportWidgetComp-title">{store_.trans['Selection route']}</div>
                        <DropDownComp items={routeItems} currentKey={vstore.selectionRoute} onChange={this.onChangeRoute}
                                      className="VoronovExportWidgetComp-selectionRoute" classNameDown="VoronovExportWidgetComp-constrMethodDown" />
                    </div>

                    <div className="VoronovExportWidgetComp-cover">
                        <div className="VoronovExportWidgetComp-title">{store_.trans['Cell numbers with']}</div>
                        <div className={classNames("flex-columns-centerByVertical", {"opacity30": this.loading})}>
                            {/*<div className={classNames("VoronovExportWidgetComp-label VoronovExportWidgetComp-labelNext")}>Номера ячеек с</div>*/}
                            <AgroExportInputNumber value={vstore.numberFrom} onChange={this.onChangeNumFrom} min={1}
                                              classNameInput="text-box-editor number-without-arrow VoronovExportWidgetComp-numInput flex-strech-item"
                                              classNameDiv="VoronovExportWidgetComp-numInputDiv"/>
                        </div>
                    </div>

                    <div className="VoronovExportWidgetComp-cover VoronovExportWidgetComp-saveCover">
                        <div className="VoronovExportWidgetComp-subCover">
                            <MyHint text={store.trans.Apply} mouseEnterDelay={1} placement="topLeft">
                                <div className={classNames("VoronovExportWidgetComp-applyButton", {
                                    "VoronovExportWidgetComp-disabled": !vstore.needApply})} onClick={this.onApply}>
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8542 2.14576L14.3542 0.645762C14.1593 0.450762 13.8422 0.450762 13.6473 0.645762L5.50025 8.79276L2.35425 5.64676C2.15925 5.45176 1.84225 5.45176 1.64725 5.64676L0.14625 7.14576C-0.04875 7.34076 -0.04875 7.65776 0.14625 7.85276L5.14625 12.8528C5.24425 12.9508 5.37225 12.9988 5.50025 12.9988C5.62825 12.9988 5.75625 12.9498 5.85425 12.8528L15.8542 2.85376C16.0492 2.65876 16.0492 2.34176 15.8542 2.14676V2.14576Z" fill="#4DB6BC"/>
                                    </svg>
                                </div>
                            </MyHint>

                            <div className="VoronovExportWidgetComp-separete" />

                            {!this.loading && <div className={classNames("VoronovExportWidgetComp-saveButton blue-border-button", {"disabled": vstore.needApply})} onClick={this.onSave}>
                                {store.trans.Save}
                            </div>}
                            {this.loading && <div className="VoronovExportWidgetComp-applyLoading">
                                <LoadingAniSvg />
                            </div>}

                            <div className={classNames("VoronovExportWidgetComp-button blue-fill VoronovExportWidgetComp-buttonNext light_background_onhover",
                                { "opacity30 disabled": !this.isEnabledData() || this.exporting || vstore.needApply,
                                    "pointer": this.isEnabledData() && !this.exporting && !vstore.needApply})}
                                 onClick={this.onDownload}>
                                {this.exporting && <LoadingAniSvg />}
                                {!this.exporting && <MyHint text={store.trans.Download} placement="right">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.00107 8.93333C6.22107 8.93333 6.36774 8.86 6.51441 8.71333L10.6944 4.53333L9.66774 3.50667L6.73441 6.44V0.133331H5.26774V6.44L2.33441 3.50667L1.30774 4.53333L5.48774 8.71333C5.63441 8.86 5.78107 8.93333 6.00107 8.93333Z" fill="#4DB6BC"/>
                                        <path d="M11.1332 10.4H0.866577V11.8667H11.1332V10.4Z" fill="#4DB6BC"/>
                                    </svg></MyHint>}
                            </div>

                            {this.downloadFormatShow && <A2ExportFormatComp store={store.root} onCancel={this.onDownloadCancel}
                                                                            formats={[A2ExportFormat.geojson, A2ExportFormat.kml, A2ExportFormat.shp]}
                                                                            onSubmit={this.onDownloadSubmit} >
                                <div className="gray-text">Площадь ячейки (га): {vstore.cellArea}</div>
                                <div className="Forms-header2Text Forms-topIdent2">Формат</div>
                            </A2ExportFormatComp>}
                        </div>
                        <div className={classNames("VoronovExportWidgetComp-stat", {
                            "VoronovExportWidgetComp-hidden": !st.cells})} >
                            <span>{store_.trans.Cells}: {cells}&nbsp;&nbsp;</span>
                            <span>{store_.trans.Numbers}: {numbers}</span>
                        </div>
                    </div>
                </div>

                <div className="VoronovExportWidgetComp-closeButton" onClick={this.onClose}>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.4 0L4 2.4L1.6 0L0 1.6L2.4 4L0 6.4L1.6 8L4 5.6L6.4 8L8 6.4L5.6 4L8 1.6L6.4 0Z" fill="#C5C5C5"/>
                    </svg>
                </div>
            </div>
        </div>;
    }
}
