import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action, observable, runInAction} from "mobx";
import './PhotoViewer.scss';
import {LoadingBars} from "../../../../Common/LoadingBars";
import classNames from "classnames";
import {Utils} from "../../../../../helper/utils/Utils";

@observer
export class PhotoViewer extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.ref = React.createRef();
        this.props.store.photo.initTags();
    }

    ref: React.Ref<HTMLDivElement>;
    timer: any = null;
    timerPhotoId: any = null;

    componentDidUpdate(prevProps: Readonly<IStoreProps>, prevState: Readonly<undefined>, snapshot?: any) {
        let p = this.props.store.photo;
        if (!p.loadedPhotoViewerImage && !p.loadedPhotoViewerImageAnimation){
            if (this.timerPhotoId != p.currentPhotoId) this.resetTimer();
            this.timerPhotoId = p.currentPhotoId;
            this.timer = setTimeout(()=>{
                runInAction(()=>{
                    if (this.timerPhotoId == p.currentPhotoId && !p.loadedPhotoViewerImage){
                        p.loadedPhotoViewerImageAnimation = true;
                    }
                    this.resetTimer();
                });
            }, 200);
        }
    }

    componentDidMount() {
        document.body.addEventListener("keydown", this.keyDown);
    }
    componentWillUnmount() {
        document.body.removeEventListener("keydown", this.keyDown);
        if (this.timer == null){
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
    keyDown(e: any){
        if (e.code == "ArrowRight"){
            this.onNext();
        }
        if (e.code == "ArrowLeft"){
            this.onPrev();
        }
        if (e.code == "Escape"){
            this.onClose();
        }
    }

    @action
    onClose(){
        this.props.store.photo.currentPhotoId = null;
    }
    @action
    onNext(){
        let p = this.props.store.photo;
        let newId = this.getNextPhotoId(1);
        if (newId != null){
            p.currentPhotoId = newId;
            this.scroollTo();
            this.resetTimer();
        }
    }

    resetTimer(){
        if (this.timer != null) clearTimeout(this.timer);
        this.timer = null;
    }
    scroollTo(){
        let p = this.props.store.photo;
        let id = "photoItem"+p.currentPhotoId.toString();
        let elem = document.getElementById(id);
        if (elem != null)
            Utils.scrollIntoView(elem);
    }

    @action
    onPrev(){
        let p = this.props.store.photo;
        let newId = this.getNextPhotoId(-1);
        if (newId != null){
            p.currentPhotoId = newId;
            this.scroollTo();
            this.resetTimer();
        }
    }
    getNextPhotoId(delta: number): number{
        let p = this.props.store.photo;
        let idx = p.features.findIndex((a)=> a.properties.photo_id == p.currentPhotoId);
        if (idx >= 0){
            idx = idx + delta;
            if (idx >= 0 && p.features.length > 0 && idx < p.features.length) return p.features[idx].properties.photo_id;
            return null;
        }else return null;
    }

    @action
    onLoadImage(){
        this.props.store.photo.loadedPhotoViewerImage = true;
    }

    @observable
    rotatedCount: number = 0;
    @action
    onRotate(){
        this.rotatedCount++;
        if (this.rotatedCount > 3) this.rotatedCount = 0;
    }

    render() {
        let store = this.props.store;

        let imgUrl = `/api/projects/${store.agro.projectName}/photo/image?photo_id=${encodeURIComponent(store.photo.currentPhotoId)}&width=${2000}&version=${store.photo.featureCurrentPhoto.properties.version}`;
        let preloads: any[] = [];
        for(let i = -2; i < 3; i++){
            if (i != 0){
                let newId = this.getNextPhotoId(i);
                let nextUrl: string = null;
                if (newId != null) {
                    let f = store.photo.getFeatureByPhotoId(newId);
                    if (f != null) {
                        nextUrl = `/api/projects/${store.agro.projectName}/photo/image?photo_id=${encodeURIComponent(newId)}&width=${2000}&version=${f.properties.version}`;
                        preloads.push(<link rel="preload" as="image" href={nextUrl} key={newId.toString()}/>);
                    }
                }
            }
        }


        return <div className="PhotoViewer-main" ref={this.ref}>
            <div className="PhotoViewer-mainDivTop">
                <div className="width100 height100 relative ">
                    {preloads}
                    <img className={classNames("PhotoViewer-mainDivTopImage",
                        {
                            "rotate90": this.rotatedCount == 1,
                            "rotate180": this.rotatedCount == 2,
                            "rotate270": this.rotatedCount == 3,
                        })}
                         src={imgUrl} onLoad={this.onLoadImage}
                         alt={store.photo.featureCurrentPhoto.properties.file_name} />
                    {(store.photo.loadedPhotoViewerImageAnimation && !store.photo.loadedPhotoViewerImage) && <LoadingBars classes="PhotoViewer-loadingImg" />}
                    <div className="PhotoViewer-roundButton PhotoViewer-roundButtonAbs full-center-content" style={{top:"11px", right:"11px"}} onClick={this.onClose}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 2L2 10" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            <path d="M10 10L2 2" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                        </svg>
                    </div>
                    <div className="PhotoViewer-roundButton PhotoViewer-roundButtonAbs full-center-content" style={{bottom:"11px", right:"59px"}}>
                        <svg width="6" height="15" viewBox="0 0 6 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="opacity20">
                            <path d="M4 6C4 5.4 3.6 5 3 5H0V7H2V13H0V15H6V13H4V6Z" fill="#C5C5C5"/>
                            <path d="M2.5 3C3.32843 3 4 2.32843 4 1.5C4 0.671573 3.32843 0 2.5 0C1.67157 0 1 0.671573 1 1.5C1 2.32843 1.67157 3 2.5 3Z" fill="#C5C5C5"/>
                        </svg>
                    </div>
                    <div className="PhotoViewer-roundButton PhotoViewer-roundButtonAbs full-center-content"
                         onClick={this.onRotate}
                         style={{bottom:"11px", right:"107px"}}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33268 14H9.99935L7.33268 10L2.66602 14H9.33268Z" fill="#C5C5C5"/>
                            <path d="M11.3327 6.66675H1.33268C1.15587 6.66675 0.986302 6.73699 0.861278 6.86201C0.736253 6.98703 0.666016 7.1566 0.666016 7.33341V15.3334C0.666016 15.5102 0.736253 15.6798 0.861278 15.8048C0.986302 15.9298 1.15587 16.0001 1.33268 16.0001H11.3327C11.5095 16.0001 11.6791 15.9298 11.8041 15.8048C11.9291 15.6798 11.9993 15.5102 11.9993 15.3334V7.33341C11.9993 7.1566 11.9291 6.98703 11.8041 6.86201C11.6791 6.73699 11.5095 6.66675 11.3327 6.66675ZM10.666 14.6667H1.99935V8.00008H10.666V14.6667Z" fill="#C5C5C5"/>
                            <path d="M3.9987 10.6666C4.36689 10.6666 4.66536 10.3681 4.66536 9.99992C4.66536 9.63173 4.36689 9.33325 3.9987 9.33325C3.63051 9.33325 3.33203 9.63173 3.33203 9.99992C3.33203 10.3681 3.63051 10.6666 3.9987 10.6666Z" fill="#C5C5C5"/>
                            <path d="M11.9993 2H9.99935V0L6.66602 2.66667L9.99935 5.33333V3.33333H11.9993C12.7066 3.33333 13.3849 3.61428 13.885 4.11438C14.3851 4.61448 14.666 5.29276 14.666 6V8H15.9993V6C15.9983 4.93946 15.5765 3.92266 14.8266 3.17274C14.0767 2.42283 13.0599 2.00106 11.9993 2Z" fill="#C5C5C5"/>
                        </svg>
                    </div>
                    <div className={classNames("PhotoViewer-roundButton PhotoViewer-roundButtonAbs full-center-content", {"opacity20": this.getNextPhotoId(-1)==null})} onClick={this.onPrev}
                         style={{left:"11px", top:"50%", transform: "translate(0,-50%)"}}>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames({"opacity20": this.getNextPhotoId(-1)==null})}>
                            <path d="M6 10L2 6L6 2" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                        </svg>
                    </div>
                    <div className={classNames("PhotoViewer-roundButton PhotoViewer-roundButtonAbs full-center-content")} onClick={this.onNext}
                         style={{right:"11px", top:"50%", transform: "translate(0,-50%)"}}>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames({"opacity20": this.getNextPhotoId(1)==null})}>
                            <path d="M2 2L6 6L2 10" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                        </svg>
                    </div>
                    <a href={`/api/projects/${store.agro.projectName}/photo/download?photo_id=${encodeURIComponent(store.photo.currentPhotoId)}`}>
                    <div className="PhotoViewer-roundButton PhotoViewer-roundButtonAbs full-center-content"
                         style={{right:"11px", bottom:"11px"}}>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99961 12C7.29961 12 7.49961 11.9 7.69961 11.7L13.3996 6L11.9996 4.6L7.99961 8.6V0H5.99961V8.6L1.99961 4.6L0.599609 6L6.29961 11.7C6.49961 11.9 6.69961 12 6.99961 12Z" fill="#C5C5C5"/>
                            <path d="M14 14H0V16H14V14Z" fill="#C5C5C5"/>
                        </svg>
                    </div></a>
                </div>

            </div>
        </div>;
    }
}