import {IReactionDisposer} from "mobx/lib/internal";
import autoBind from "auto-bind";
import {autorun, computed, observable} from "mobx";
import {SuperStore} from "./SuperStore";

export interface ICustomStore{
    get parent(): ICustomStore;
    get root(): SuperStore;
}

export class CustomStore<P extends CustomStore = CustomStore<any>> implements ICustomStore{
    constructor(parent: P) {
        autoBind(this);
        this._parent = parent;
    }
    class(): string {return "CustomStore";}

    internalClassname(): string { return this.constructor.name;}

    @observable
    protected _parent: P = null;
    get parent(): P { return this._parent;}


    @computed
    get root(): SuperStore{
        let p = this;
        while(p.parent != null){
            p = p.parent as any;
        }
        if (p instanceof SuperStore) {
            return p;
        }else return null;
    }
    beforeLoadPermalink(){}

    dispose(){}
}

export abstract class ObservableCustomStore<P extends CustomStore = CustomStore<any>> extends CustomStore<P>{
    constructor(parent: P) {
        super(parent);
        let oldRoot: SuperStore = null;
        this.reSubscribe = autorun(()=>{
            if (this.root == oldRoot) return;
            if (this.root != null) {
                if (oldRoot) oldRoot.removeStoreSubscribtion(this);
                this.doUnsubscribe();
                oldRoot = this.root;
                this.root.addStoreSubscribtion(this);
            }else{
                if (oldRoot) oldRoot.removeStoreSubscribtion(this);
                oldRoot = null;
            }
        });
    }

    private doUnsubscribe(){
        if (this.hasSubscribe){
            this.internalSubcriptions.forEach(a => a());
            this.internalSubcriptions = [];
            this.hasSubscribe = false;
        }
    }
    public doSubscribe(){
        if (this.hasSubscribe)
            this.doUnsubscribe();
        this.internalSubcriptions = this.subscription();
        this.hasSubscribe = true;
    }

    private reSubscribe: IReactionDisposer = null;
    private internalSubcriptions: IReactionDisposer[] = [];
    private hasSubscribe: boolean = false;
    private _disposed: boolean = false;
    get disposed(): boolean{
        return this._disposed;
    }

    public subscription(): IReactionDisposer[] {return [];}

    dispose(){
        this.doUnsubscribe();
        if (this.reSubscribe != null) {
            this.reSubscribe();
            this.reSubscribe = null;
        }
        this._disposed = true;
    }
}

