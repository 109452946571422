import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../../helper/structs/IStoreProps";
import './Agro2ToolsGeometryExt.scss';
import {MeasuringStatus} from "../../../../../../helper/structs/MeasuringStatus";
import {action} from "mobx";
import classNames from "classnames";
import {GeometryUtils, SimpleGeometryType} from "../../../../../../helper/utils/GeometryUtils";
import {CreateGeometryType} from "../../../../../../store/tools/general/ContainerTools";
import {MyHint} from "../../../../../Common/MyHint";

@observer
export class Agro2ToolsGeometryExt extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClickCreateHole(){
        if (!this.isEnabledNewContour()) return;
        if (this.props.store.map.measuringStatus == MeasuringStatus.agroCreatePolygonHole){
            this.props.store.map.resetMeasuringStatus();
        }else this.props.store.map.setMeasuringStatus(MeasuringStatus.agroCreatePolygonHole);
    }
    @action
    onClickDeleteContour(){
        if (!this.isEnabledDeleteContour()) return;
        if (this.props.store.map.measuringStatus == MeasuringStatus.agroDeleteContour){
            this.props.store.map.resetMeasuringStatus();
        }else this.props.store.map.setMeasuringStatus(MeasuringStatus.agroDeleteContour);
    }

    isEnabledNewContour(): boolean{
        let store = this.props.store;
        let types = GeometryUtils.getSimpleTypes(store.agro2.editManager.state.simpleGeometry);
        return (types.find(a => a == SimpleGeometryType.Polygon) != null) || this.props.store.map.measuringStatus == MeasuringStatus.agroCreatePolygonHole;
    }
    isEnabledDeleteContour(): boolean{
        let store = this.props.store;
        let types = GeometryUtils.getSimpleTypes(store.agro2.editManager.state.simpleGeometry);
        return (types.find(a => a == SimpleGeometryType.Polygon || a == SimpleGeometryType.Line) != null) || this.props.store.map.measuringStatus == MeasuringStatus.agroDeleteContour;
    }
    @action
    onClickDeleteAllGeometries(){
        this.props.store.agro2.fieldToolController.removeGeometry();
        this.props.store.map.setMeasuringStatus(MeasuringStatus.agroEdit);
    }
    @action
    onClickCutGeometry(){
        if (this.props.store.map.measuringStatus != MeasuringStatus.agroCutGeometry) {
            this.props.store.map.setMeasuringStatus(MeasuringStatus.agroCutGeometry);
            this.props.store.agro2.editManager.cutManager.state.createGeometryType = CreateGeometryType.Line;
        }else{
            this.props.store.map.resetMeasuringStatus();
        }
    }
    @action
    onClickUnionGeometry(){
        if (this.props.store.map.measuringStatus != MeasuringStatus.agroUnionGeometry) {
            this.props.store.map.setMeasuringStatus(MeasuringStatus.agroUnionGeometry);
            this.props.store.agro2.editManager.cutManager.state.createGeometryType = CreateGeometryType.Polygon;
        }else{
            this.props.store.map.resetMeasuringStatus();
        }
    }

    render() {
        let store = this.props.store;
        return <div className="Agro2ToolsGeometryExt-main">
            <MyHint placement="right" text={store.trans["Merge/Add patch"]} >
            <div className={classNames("Agro2ToolsGeometryExt-button", {
                "active": store.map.measuringStatus == MeasuringStatus.agroUnionGeometry
            })} onClick={this.onClickUnionGeometry}
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5 11V15C5 15.5523 5.44771 16 6 16H15C15.5523 16 16 15.5523 16 15V6C16 5.44772 15.5523 5 15 5H11V10C11 10.5523 10.5523 11 10 11H5Z" fill="#C5C5C5"/>
                    <rect x="0.5" y="0.5" width="10" height="10" rx="0.5" stroke="#C5C5C5"/>
                </svg>
            </div></MyHint>
            <MyHint placement="right" text={store.trans["Split parts"]} >
            <div className={classNames("Agro2ToolsGeometryExt-button", {
                "active": store.map.measuringStatus == MeasuringStatus.agroCutGeometry
            })} onClick={this.onClickCutGeometry}
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.85246 5L0 16L10.0984 12.3333L11 9L6.85246 5Z" fill="#C5C5C5"/>
                    <path d="M12.5 7L8 3L11.2 0L16 3.73333L12.5 7Z" fill="#C5C5C5"/>
                </svg>
            </div>
            </MyHint>
            <MyHint placement="right" text={store.trans["Add hole"]} >
            <div className={classNames("Agro2ToolsGeometryExt-button", {
                "disabled": !this.isEnabledNewContour(),
                "active": store.map.measuringStatus == MeasuringStatus.agroCreatePolygonHole})} onClick={this.onClickCreateHole}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="6.5" y="7.5" width="15" height="15" rx="0.5" stroke="#C5C5C5"/>
                    <circle cx="14" cy="15" r="4.25" fill="#4DB6BC" fillOpacity="0.3" stroke="#4DB6BC" strokeWidth="1.5" strokeDasharray="2 2"/>
                </svg>
            </div>
            </MyHint>
            <MyHint placement="right" text={store.trans["Delete part"]} >

            <div className={classNames("Agro2ToolsGeometryExt-button",
                {
                    "disabled": !this.isEnabledDeleteContour(),
                    "active": store.map.measuringStatus == MeasuringStatus.agroDeleteContour})}
                 onClick={this.onClickDeleteContour}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 3.5V0.5H15.5V10.5H12.5" stroke="#C5C5C5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.77783 6.22223L1.77783 14.2222" stroke="#E94F4F" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.77783 6.22223L9.77783 14.2222" stroke="#E94F4F" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            </MyHint>
            <MyHint placement="right" text={store.trans["Delete all"]} >

            <div className={classNames("Agro2ToolsGeometryExt-button",
                {"disabled": false})}
                 onClick={this.onClickDeleteAllGeometries}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 5.5V14C2.5 14.828 3.172 15.5 4 15.5H12C12.828 15.5 13.5 14.828 13.5 14V5.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M0.5 3.5H15.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.5 3.5V0.5H10.5V3.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 7.5V12.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5 7.5V12.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.5 7.5V12.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            </MyHint>

        </div>;
    }
}
