// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroExportPointsItemComp-title {
  font-weight: 600;
  font-size: 12px;
  color: #C5C5C5;
  padding: 3px 15px;
}

.AgroExportPointsItemComp-fields {
  font-weight: 400;
  font-size: 12px;
  color: #6A7886;
  padding: 3px 15px 8px 15px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroExport/components/AgroExportPointsItemComp.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,0BAAA;AAEF","sourcesContent":[".AgroExportPointsItemComp-title {\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  color: #C5C5C5;\r\n  padding: 3px 15px;\r\n}\r\n.AgroExportPointsItemComp-fields {\r\n  font-weight: 400;\r\n  font-size: 12px;\r\n  color: #6A7886;\r\n  padding: 3px 15px 8px 15px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
