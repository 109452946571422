import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDelIndicatorPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {TranslateUtils} from "../../../app/helper/lang/TranslateUtils";
import {AgroAhoSwitcherSvg} from "./icons/AgroAhoSwitcherSvg";
import classNames from "classnames";
import {AgroAhoRectCheckboxSvg} from "./AgroAhoRectCheckboxSvg";
import {TourForDelIndc} from "../agroAhoTourStore";
import {AhoUtils} from "../agroAhoUtils";

@observer
export class AgroAhoDelIndicatorPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        if (!this.canDel()) return;
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;

        let ind = cstore.removableIndicator;
        // let indc_tours = tstore.doGetIndicatorTours(ind.indc_code);
        // let tour_ids = tstore.selTours(true).filter(t=>indc_tours.indexOf(t.tour_id)>=0).map(t=>t.tour_id);
        // store.deleteCards();
        let tour_ids = tstore.toursForDelIndc.filter(t=>t.del).map(t=>t.tour.tour_id);
        cstore.deleteIndicator(cstore.card.map_id, ind, tour_ids, cstore.delIndcSelFields);
        store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    selFieldCheckboxClick(){
        if (this.isCheckboxDisabled()) return;
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.delIndcSelFields = !cstore.delIndcSelFields;
    }

    isCheckboxDisabled(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        return !cstore.getFieldSelected().length;
    }

    onTourClick(t: TourForDelIndc){
        t.del = !t.del;
    }

    canDel(){
        let tstore = this.props.store.agroAhoStore.tourStore;
        return !tstore.toursForDelIndc.every(t=>!t.del);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let tstore = store_.tourStore;
        let cstore = store_.cardStore;
        let ind = cstore.removableIndicator;
        let toursItems: any = tstore.toursForDelIndc.map(t=><div key={t.tour.tour_id}
            className="AgroAhoDelIndicatorPanelComp-tourItem" onClick={this.onTourClick.bind(this, t)}>
            <AgroAhoRectCheckboxSvg className="AgroAhoDelIndicatorPanelComp-tourCheckbox" checked={t.del}/>
            <div className="AgroAhoDelIndicatorPanelComp-tourCaption">{t.tour.tour_name}</div>
            <div className="AgroAhoDelIndicatorPanelComp-tourDate">{store_.format_date(t.tour.tour_date)}</div>
        </div>);

        return <div className="AgroAhoDelIndicatorPanelComp-main">
            <div className="AgroAhoDelIndicatorPanelComp-caption">{store_.trans['Removing an indicator']}</div>
            <div className="AgroAhoDelIndicatorPanelComp-msg">{
                store_.trans['Attention! You confirm the deletion of the indicator']} {ind.indc_name}?</div>
            <div className={classNames("AgroAhoDelIndicatorPanelComp-selFieldCheckboxCover",{
                "AgroAhoDelIndicatorPanelComp-disabled": this.isCheckboxDisabled()})}
                 onClick={this.selFieldCheckboxClick}>
                <AgroAhoSwitcherSvg className="AgroAhoDelIndicatorPanelComp-selFieldCheckbox"
                                    checked={cstore.delIndcSelFields}/>
                <span className="AgroAhoDelIndicatorPanelComp-selFieldCheckboxCaption">
                            {store_.trans['Delete for selected fields only']}</span>
            </div>
            <div className="AgroAhoDelIndicatorPanelComp-line"> </div>
            <div className="AgroAhoDelIndicatorPanelComp-toursCaption">
                {store_.trans['Select tours to remove the indicator']}</div>
            <div className="AgroAhoDelIndicatorPanelComp-toursList style-4">{toursItems}</div>
            <div className="AgroAhoDelIndicatorPanelComp-line"> </div>
            <div className="AgroAhoDelIndicatorPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoDelIndicatorPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className={classNames("large-button left-sidebar-red", {
                    "AgroAhoDelIndicatorPanelComp-disabled": !this.canDel(),
                    "button-jump": this.canDel()})}
                    onClick={this.onClickDelete}>{store.trans.Delete}</button>
            </div>
        </div>;
    }
}
