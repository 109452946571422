import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";

export interface IAgroAhoCellListSvgProps {
    className?: string;
}

export class AgroAhoCellListSvg extends React.PureComponent<IAgroAhoCellListSvgProps, undefined> {
    constructor(props: IAgroAhoCellListSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.787 4.09057C24.712 4.03803 24.6241 4.00707 24.5328 4.00107C24.4414 3.99508 24.3502 4.01427 24.269 4.05657C24.1878 4.09886 24.1198 4.16263 24.0724 4.24093C24.0249 4.31922 23.9999 4.40903 24 4.50057V11.5006C24.0002 11.592 24.0254 11.6817 24.073 11.7598C24.1207 11.8379 24.1888 11.9015 24.27 11.9436C24.3409 11.981 24.4198 12.0006 24.5 12.0006C24.6026 12.0007 24.7028 11.9693 24.787 11.9106L29.787 8.41057C29.853 8.36449 29.9069 8.30316 29.9442 8.23177C29.9814 8.16039 30.0008 8.08108 30.0008 8.00057C30.0008 7.92007 29.9814 7.84075 29.9442 7.76937C29.9069 7.69799 29.853 7.63665 29.787 7.59057L24.787 4.09057Z"/>
            <g clipPath="url(#clip0_1574_116)">
                <path d="M15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0ZM14 14H2V2H14V14Z"/>
                <path d="M12 4H4V12H12V4ZM10 10H6V6H10V10Z"/>
            </g>
        </svg>;
    }
}