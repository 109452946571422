import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './Agro2ToolAuto.scss';
import {IStoreProps} from "../../../../../../helper/structs/IStoreProps";
import {InputNumber} from "../../../../../Common/InputNumber";
import {action} from "mobx";
import {Utils} from "../../../../../../helper/utils/Utils";
import classNames from "classnames";
import {MeasuringStatus} from "../../../../../../helper/structs/MeasuringStatus";
import { DropDownComp, IDropDownItem } from '../../../../../Common/DropDownComp';
import InformationIconComp from '../../../../../Common/InformationIconComp';

@observer
export class Agro2ToolAuto extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickMinimize(){
        let store = this.props.store;
        let auto = store.agro2.fieldToolController.autoPolygon;
        auto.minimize = !auto.minimize;
    }

    @action
    onClickApply(){
        if (! this.props.store.agro2.fieldToolController.autoPolygon.canApply) return;
        this.props.store.agro2.fieldEditorForm.createGeometryButtonDown = false;
        this.props.store.agro2.fieldToolController.autoPolygon.applyAll();
        this.props.store.map.resetMeasuringStatus();
    }

    @action
    onClickRemember(){
        this.props.store.agro2.fieldToolController.autoPolygon.remember();
    }

    @action
    onClickDelete() {
        this.props.store.agro2.fieldToolController.autoPolygon.editingPolygon = null;
    }

    @action
    onSimplifyChanged(item: IDropDownItem) {
        let auto = this.props.store.agro2.fieldToolController.autoPolygon;
        if (auto.simplify == item.data) return;
        auto.simplify = item.data;
    }
    
    static leftClickSvg() {
        return <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 7H1.5C1.5 2.5 4.5 1.5 6 1.5V7Z" fill="#4DB6BC"/>
            <path d="M6 1V7.4" stroke="#6A7886" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 7.40002H11" stroke="#6A7886" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 6.86667C11 3.6266 8.76142 1 6 1C3.23858 1 1 3.6266 1 6.86667V11.1333C1 14.3734 3.23858 17 6 17C8.76142 17 11 14.3734 11 11.1333V6.86667Z" stroke="#6A7886" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>;
    }

    static newPointSvg() {
        return <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="13" rx="1.5" fill="#FF5E54" fillOpacity="0.15" stroke="#FF5E54"/>
            <circle cx="6" cy="5" r="2" fill="#66ED5B"/>
        </svg>;
    }

    static rightClickSvg() {
        return <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1V7.4" stroke="#6A7886" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 7.40002H11" stroke="#6A7886" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.5 7H11C11 2.5 8 1.5 6.5 1.5V7Z" fill="#4DB6BC"/>
            <path d="M11 6.86667C11 3.6266 8.76142 1 6 1C3.23858 1 1 3.6266 1 6.86667V11.1333C1 14.3734 3.23858 17 6 17C8.76142 17 11 14.3734 11 11.1333V6.86667Z" stroke="#6A7886" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>;
    }

    static innerPointSvg() {
        return <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="13" rx="1.5" fill="#FF5E54" fillOpacity="0.15" stroke="#FF5E54"></rect>
            <circle cx="6" cy="5" r="2" fill="#66ED5B"></circle>
            <circle cx="14" cy="9" r="2" fill="#66ED5B"></circle>
        </svg>;
    }

    static outerPointSvg() {
        return <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H14.6136C15.8337 0.5 16.5434 1.87906 15.8342 2.87186L8.69136 12.8719C8.4098 13.2661 7.95519 13.5 7.47076 13.5H2C1.17157 13.5 0.5 12.8284 0.5 12V2Z" fill="#FF5E54" fillOpacity="0.15" stroke="#FF5E54"/>
        <circle cx="6" cy="5" r="2" fill="#66ED5B"/>
        <circle cx="18" cy="10" r="2" fill="#FF0000"/>
    </svg>;
    }

    render() {
        let store = this.props.store;
        let auto = store.agro2.fieldToolController.autoPolygon;
        let simplifyItems: IDropDownItem[] = [0,1,2,5,10].map(i => Object({key: i, value: i.toString(), data: i}));

        return <div className="Agro2ToolAuto-panel">
            <div className="Agro2ToolAuto-header">
                <span>{store.trans["AI digitization"]}</span>
                <InformationIconComp onClick={() => auto.showHelp = true}/>
            </div>
            <div className="Agro2ToolAuto-keyValue">
                <div>
                    <span className='Agro2ToolAuto-spanSimplify'>{store.trans["Polygon simplification, m"]}</span>
                    <DropDownComp currentKey={auto.simplify} items={simplifyItems} onChange={this.onSimplifyChanged}></DropDownComp>
                </div>
            </div>
            <div className='Agro2ToolAuto-divButtons'>
                <div className={classNames("Agro2ToolAuto-divGarbage",{"disabled": !auto.canRemember})}
                onClick={this.onClickDelete}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="#2B3138" stroke="#E94F4F"></rect>
                        <g clipPath="url(#clip0_6566_4938)">
                        <path d="M8.5 11.5V20C8.5 20.828 9.172 21.5 10 21.5H18C18.828 21.5 19.5 20.828 19.5 20V11.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M6.5 9.5H21.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M11.5 9.5V6.5H16.5V9.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M14 13.5V18.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M16.5 13.5V18.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M11.5 13.5V18.5" stroke="#E94F4F" strokeLinecap="round" strokeLinejoin="round"></path>
                        </g>
                        <defs>
                        <clipPath id="clip0_6566_4938">
                        <rect width="16" height="16" fill="white" transform="translate(6 6)"></rect>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className={classNames("blue-border-button Agro2ToolAuto-Remember",{"disabled": !auto.canRemember})}
                    onClick={this.onClickRemember}>
                    {store.trans.Remember}
                </div>
            </div>
            <div className="Agro2ToolAuto-minimize" onClick={this.onClickMinimize}>
                <span className='Agro2ToolAuto-spanInstruction'>{store.trans.Instruction}</span>
                {!auto.minimize && <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.0005 4.1008e-05C4.1635 4.1008e-05 4.3165 0.0662904 4.41 0.177956L7.90997 4.34459C8.01697 4.47167 8.02897 4.63792 7.94397 4.77584C7.85747 4.91375 7.68697 5 7.50047 5H0.499529C0.31303 5 0.142532 4.91375 0.0560323 4.77584C-0.028967 4.63792 -0.0169671 4.47167 0.090032 4.34459L3.59 0.177956C3.6835 0.0662904 3.8365 4.1008e-05 3.9995 4.1008e-05C4 4.1008e-05 4 4.1008e-05 4.0005 4.1008e-05C4 4.1008e-05 4 4.1008e-05 4.0005 4.1008e-05Z" fill="#22282F"/>
                </svg>}
                {auto.minimize && <svg width="8" height="5" viewBox="0 0 8 5" className="rotate180" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.0005 4.1008e-05C4.1635 4.1008e-05 4.3165 0.0662904 4.41 0.177956L7.90997 4.34459C8.01697 4.47167 8.02897 4.63792 7.94397 4.77584C7.85747 4.91375 7.68697 5 7.50047 5H0.499529C0.31303 5 0.142532 4.91375 0.0560323 4.77584C-0.028967 4.63792 -0.0169671 4.47167 0.090032 4.34459L3.59 0.177956C3.6835 0.0662904 3.8365 4.1008e-05 3.9995 4.1008e-05C4 4.1008e-05 4 4.1008e-05 4.0005 4.1008e-05C4 4.1008e-05 4 4.1008e-05 4.0005 4.1008e-05Z" fill="#22282F"/>
                </svg>}
            </div>
            <div className={classNames("Agro2ToolAuto-footer qtransit",{"height0-important": auto.minimize, "open": !auto.minimize})}>
                <div className="Agro2ToolAuto-footerInner">
                    <div className="Agro2ToolAuto-footerRow nowrap">
                        {Agro2ToolAuto.leftClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">—</span>
                        {Agro2ToolAuto.newPointSvg()}
                    </div>
                    <div className="Agro2ToolAuto-footerRow nowrap">
                        <span className="Agro2ToolAuto-keySpan">Shift</span>
                        <span className="Agro2ToolAuto-spanNext">+</span>
                        {Agro2ToolAuto.leftClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">—</span>
                        {Agro2ToolAuto.innerPointSvg()}
                    </div>
                    <div className="Agro2ToolAuto-footerRow nowrap">
                        <span className="Agro2ToolAuto-keySpan">Shift</span>
                        <span className="Agro2ToolAuto-spanNext">+</span>
                        {Agro2ToolAuto.rightClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">—</span>
                        {Agro2ToolAuto.outerPointSvg()}
                    </div>
                    <div className='Agro2ToolAuto-divSeparator'/>
                    <div className="Agro2ToolAuto-footerRow nowrap">
                        {Agro2ToolAuto.rightClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">—</span>
                        <span>{store.trans['Delete blue contour']}</span>
                    </div>
                </div>
            </div>
            {/* <div className='Agro2ToolAuto-divApply'>
                <div onClick={this.onClickApply} className={classNames('Agro2ToolAuto-Apply', 
                    {'nonempty': auto.canApply, 'disabled opacity30': !auto.canApply, 'pointer': auto.canApply})}>
                    {store.trans.Ready}
                </div>
            </div> */}
        </div>;
    }
}
