import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";

export enum ProjectStructItemType{
    Season= 'Season',
    Variety = 'Variety',
    Crop = 'Crop',
    'Sowing date' = 'Sowing date',
    'Harvest date' = 'Harvest date',
    Farm = 'Farm',
    Specialty = 'Specialty',
    region = 'region',
}

export enum A2ColumnType{
    number = 'number',
    string = 'string',
    date = 'date',
    reference = 'reference',
}

export class A2ColumnItemStore extends CustomStore{
    class(): string { return "A2ColumnItemStore"; }

    get id(): string{
        return this.column_name;
    }
    @save
    column_name: string;
    @save
    column_type: A2ColumnType;
    @save
    column_nameString: string;//для reference колонок значение из справочника
    @save
    required: boolean = false;
    @save
    reference_name: string;
    @save
    reference_primary_column: string;
    @save
    reference_value_column: string;
    @save
    reference_group_name: string;
    @save
    readOnly: boolean = false;
    @save
    show: boolean = true;
    @save
    order: number;//порядок отображения
    @save//Псевдоним названия, мультиязычное
    title_store: { [index: string]: string } = {};
    get title(): string{
        let t = this.title_store[this.root.getLang()];
        if (t == null) t = "";
        return t;
    }

    getValueColumnName(): string{
        if (this.column_type == A2ColumnType.reference) return this.reference_value_column;
        return this.column_name;
    }
}
