// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndexByPointPopupGroupsComp-main {
  display: flex;
  color: #C5C5C5;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 10px;
  background: #2B3138;
  flex-grow: 1;
}

.IndexByPointPopupGroupsComp-text {
  flex-grow: 1;
  flex-shrink: 0;
  line-height: 26px;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 15px;
}

.IndexByPointPopupGroupsComp-arrow {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IndexByPointPopupGroupsComp-dropDown {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 315px;
  color: #C5C5C5;
  padding-bottom: 15px;
  margin-top: 6px;
}

.IndexByPointPopupGroupsComp-subGroupTitle {
  padding: 5px 15px;
  margin-top: 15px;
  line-height: 20px;
  font-weight: 600;
  font-size: 13px;
  background: #262B32;
}

.IndexByPointPopupGroupsComp-footer {
  padding: 20px 15px;
}

.IndexByPointPopupGroupsComp-footerButton {
  min-width: 120px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/IndexByPoint/Popups/IndexByPointPopupGroupsComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AACA;EACE,YAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAGF;;AADA;EACE,oDAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;AAIF;;AAFA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AAKF;;AAHA;EACE,kBAAA;AAMF;;AAJA;EACE,gBAAA;AAOF","sourcesContent":[".IndexByPointPopupGroupsComp-main{\n  display: flex;\n  color: #C5C5C5;\n  border: 1px solid #3E4751;\n  box-sizing: border-box;\n  border-radius: 10px;\n  background: #2B3138;\n  flex-grow: 1;\n}\n.IndexByPointPopupGroupsComp-text{\n  flex-grow: 1;\n  flex-shrink: 0;\n  line-height: 26px;\n  font-weight: 600;\n  font-size: 14px;\n  padding: 7px 15px;\n}\n.IndexByPointPopupGroupsComp-arrow{\n  margin-right: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.IndexByPointPopupGroupsComp-dropDown{\n  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));\n  background: #2B3138;\n  border: 1px solid #3E4751;\n  box-sizing: border-box;\n  border-radius: 10px;\n  min-width: 315px;\n  color: #C5C5C5;\n  padding-bottom: 15px;\n  margin-top: 6px;\n}\n.IndexByPointPopupGroupsComp-subGroupTitle{\n  padding: 5px 15px;\n  margin-top: 15px;\n  line-height: 20px;\n  font-weight: 600;\n  font-size: 13px;\n  background: #262B32;\n}\n.IndexByPointPopupGroupsComp-footer{\n  padding: 20px 15px;\n}\n.IndexByPointPopupGroupsComp-footerButton{\n  min-width: 120px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
