import {CustomTool} from "../general/ContainerTools";
import {SuperTools} from "../general/SuperTools";
import {Feature, FeatureCollection} from "geojson";
import {GeoJSONSource, MapMouseEvent, Marker, Point} from "maplibre-gl";
import {action, observable} from "mobx";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class SearchAddressMarkerTool extends CustomTool {
    static readonly GEOOBJECT_SOURCE_ID : string = 'class_GEOOBJECT_SOURCE_ID';
    static readonly GEOOBJECT_LINE_LAYER_ID : string = 'class_GEOOBJECT_LINE_LAYER_ID';
    static readonly GEOOBJECT_FILL_LAYER_ID : string = 'class_GEOOBJECT_FILL_LAYER_ID';
    static readonly GEOOBJECT_POINT_LAYER_ID : string = 'class_GEOOBJECT_POINT_LAYER_ID';
    static readonly EMPTY_SOURCE : FeatureCollection = {'type': 'FeatureCollection', 'features': []};

    tools : SuperTools;

    @observable
    protected _geoObject: Feature;

    @observable
    protected _isMenuShown: boolean = false;

    private _marker: Marker = null;

    public get geoObject() {return this._geoObject;}

    public set geoObject(feature: Feature){
        let src = (this.map.getSource(SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID) as GeoJSONSource);
        this._geoObject = feature;
        if (src)
            src.setData(feature? {'type': 'FeatureCollection', 'features': [feature]}: SearchAddressMarkerTool.EMPTY_SOURCE);
        this.deleteMarker();
        if (feature)
            this.createMarker();
    }

    deleteMarker() {
        if (this._marker) {
            this._marker.remove();
            this._marker = null;
        }
    }

    createMarker() {
        let el = document.createElement('div');
        el.className = 'geo-object-context-menu-button';
        el.innerHTML = `<svg width="22" height="27" viewBox="0 0 22 27" style="margin-left:-3.5px;margin-bottom:8px;margin-top:-18px" fill="none" xmlns="http://www.w3.org/2000/svg">
            <style type="text/css">
                .geo-object-context-menu-button-fill {
                    fill: #212121;
                    stroke: white;
                }

                .geo-object-context-menu-button:hover svg path {
                fill: #4DB6BC ;
            }
            </style>
            <path class="geo-object-context-menu-button-fill" d="M0.5 10.5652C0.5 4.32771 5.83397 0.5 11 0.5C16.166 0.5 21.5 4.32771 21.5 10.5652C21.5 12.0024 20.931 13.6783 20.023 15.4225C19.1197 17.1578 17.9056 18.9143 16.6707 20.5005C14.2014 23.6725 11.6844 26.119 11.5092 26.2872C11.3468 26.4432 11.223 26.5 11 26.5C10.777 26.5 10.6532 26.4432 10.4908 26.2872C10.3156 26.119 7.79862 23.6725 5.32927 20.5005C4.09444 18.9143 2.88034 17.1578 1.97701 15.4225C1.06902 13.6783 0.5 12.0024 0.5 10.5652Z"/>
            <circle cx="11" cy="11" r="6" fill="white"/>
        </svg>`;
        el.addEventListener('click', (e) => {
            e.stopPropagation();
            this._isMenuShown = true;
        });
        this._marker = new Marker(el).setLngLat([
            this.geoObject.properties.lon, this.geoObject.properties.lat
        ]).addTo(this.map);
    }

    public get isMenuShown() {return this._isMenuShown;}

    public set isMenuShown(val: boolean) {this._isMenuShown = val;}

    @action
    onMoveEnd(e: ToolEvent): void {
        this._isMenuShown = false;
    }
    @action
    onMouseClick(e: MapMouseEvent & ToolEvent): void {
        this._isMenuShown = false;
    }

    onInstall() {
        if (this.map.getSource(SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID) == null) {
            this.map.addSource(SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID, {
                'type': 'geojson',
                'data': SearchAddressMarkerTool.EMPTY_SOURCE
            });
        }
        if (this.map.getLayer(SearchAddressMarkerTool.GEOOBJECT_FILL_LAYER_ID) == null) {
            this.addLayer({
                'id': SearchAddressMarkerTool.GEOOBJECT_FILL_LAYER_ID,
                'type': 'fill',
                'source': SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID,
                'layout': {},
                'paint': {
                    'fill-color': '#FF0000',
                    'fill-opacity': 0.1
                },
                'filter': ["==", "$type", "Polygon"]
            });
        }
        if (this.map.getLayer(SearchAddressMarkerTool.GEOOBJECT_LINE_LAYER_ID) == null) {
            this.addLayer({
                'id': SearchAddressMarkerTool.GEOOBJECT_LINE_LAYER_ID,
                'type': 'line',
                'source': SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID,
                'paint': {
                    'line-color': '#FF0000',
                    'line-opacity': 1.0,
                    'line-width': 2
                },
                "filter": ["!=", "$type", "Point"]
            });
        }
        if (this.map.getLayer(SearchAddressMarkerTool.GEOOBJECT_POINT_LAYER_ID) == null) {
            this.addLayer({
                'id': SearchAddressMarkerTool.GEOOBJECT_POINT_LAYER_ID,
                'type': 'circle',
                'source': SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID,
                'paint': {
                    'circle-radius': 5,
                    'circle-color': 'transparent',
                    'circle-stroke-width': 0,
                    
                },
                "filter": ["==", "$type", "Point"]
            });
        }
    }

    onUninstall() {
        if (this.map.getLayer(SearchAddressMarkerTool.GEOOBJECT_FILL_LAYER_ID) != null)
            this.removeLayer(SearchAddressMarkerTool.GEOOBJECT_FILL_LAYER_ID);
        if (this.map.getLayer(SearchAddressMarkerTool.GEOOBJECT_LINE_LAYER_ID) != null)
            this.removeLayer(SearchAddressMarkerTool.GEOOBJECT_LINE_LAYER_ID);
        if (this.map.getLayer(SearchAddressMarkerTool.GEOOBJECT_POINT_LAYER_ID) != null)
            this.removeLayer(SearchAddressMarkerTool.GEOOBJECT_POINT_LAYER_ID);
        if (this.map.getSource(SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID) != null)
            this.map.removeSource(SearchAddressMarkerTool.GEOOBJECT_SOURCE_ID);
    }
}