// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckBoxInFrame {
  border-radius: 10px;
  border: 1px solid #C5C5C5;
  padding: 8px;
}

.CheckBoxInFrame.active {
  border: 1px solid #4DB6BC;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/User/ProjectSettings/CheckBoxInFrame.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,YAAA;AACF;;AACA;EACE,yBAAA;AAEF","sourcesContent":[".CheckBoxInFrame{\n  border-radius: 10px;\n  border: 1px solid #C5C5C5;\n  padding: 8px;\n}\n.CheckBoxInFrame.active{\n  border: 1px solid #4DB6BC;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
