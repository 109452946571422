import {CustomStore} from "./CustomStore";
import {Feature, Geometry} from "@turf/helpers";
import {ISearchPropertiesRest} from "./SearchStore";
import {observable} from "mobx";
import {save} from "./PermalinkDecor";

export class SearchItemStore extends CustomStore{
    class(): string {return "SearchItemStore";}

    @save @observable
    feature: Feature<Geometry, ISearchPropertiesRest>;
    @save @observable
    selected: boolean = false;
    @observable
    animate_fav_icon: boolean = false;

    public sceneId(): string{
        return this.feature.properties.scene_id;
    }
}
