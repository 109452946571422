import {ShowRoom2LayerPointCustomTool} from "./ShowRoom2LayerPointCustomTool";
import {SymbolLayout, SymbolPaint} from "maplibre-gl";

import {action} from "mobx";
import {ShowroomLayerType} from "../../dataShowroom2/ShowRoom2LayerStore";

export class ShowRoom2LayerPointClassTool extends ShowRoom2LayerPointCustomTool{

    @action
    loadPopupById(id: any):void{
        this.store.dataShowRoomStore.mapPopup.showRoom2LayerName = ShowroomLayerType.classPoints;
        this.store.dataShowRoomStore.mapPopup.loadCCE(id as any);
    }

    getSvgStr(): string{
        let svg = '<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<rect x="1" y="1" width="15" height="15" rx="7.5" fill="{{color}}" stroke="white" stroke-width="2"/>\n' +
            '<rect x="6" y="6" width="5" height="5" rx="2.5" fill="white"/>\n' +
            '</svg>';
        return svg;
    }
    async addLayers() {
        let svg = this.getSvgStr();
        let map = this.store.map.mapbox;
        let tileUrl = `${window.location.origin}/reports/cce/public.get_class/{z}/{x}/{y}.pbf?filter=${JSON.stringify(this.store.dataShowRoomStore.getFilters())}`;
        let circlePaint = await this.generateColorStyle();
        let icon = await this.generateSprites(svg);

        if (!map.getSource(this.getSourceId())){
            map.addSource(this.getSourceId(),{
                type: 'vector',
                tiles: [tileUrl],
                scheme: "xyz",
            });
        }
        if (!map.getLayer(this.layerIdPoints())){

            this.addLayer({
                id: this.layerIdPoints(),
                source: this.getSourceId(),
                "source-layer": 'points',
                type: 'symbol',
                //minzoom: 11,
                layout: <SymbolLayout>{
                    'icon-image': icon,
                    "icon-allow-overlap": false,
                },
                paint:<SymbolPaint>{
                }
            });
        }

    }

}