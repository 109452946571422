import {Translate_ru} from "../Translate_ru";
import {SuperStore} from "../../../store/SuperStore";

export class TranslateOver_ParcelAssess_ru //extends Translate_ru
{
    static updateTranslate(store: SuperStore) {
        let t = store.trans;

        t.object_create = 'Создать поле'
        t.object_creation = 'Создание поля'
        t.object_editing = 'Редактировать'
        t.objects_editing = 'Редактирование полей'
        t.object_border = 'Контур поля'
        t["Maximum number of objects in active area: {0}"] = "Маскимальное число полей в активной области: {0}"
        t["Select the attributes of your table from the uploaded file that you want to import and match them to the characteristics of your objects"] = "Выберите атрибуты вашей таблицы из загружаемого файла, которые вы хотите импортировать, и соотнесите их с характеристиками ваших полей";
        t["Objects not selected"] = "Поля не выбраны";
        t["Selected objects to active area"] = "Выделенные поля в активную область"
        t.ObjectsFields = "Поля"
        t["Get object profile"] = "Получит профиль поля"
        t["Loading objects from a file"] = "Загрузка полей из файла"
        t["Object border"] = "Контур поля"
        t["Split by objects"]="Разбить по полям"
    }
}