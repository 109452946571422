import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoMapStore, IAhoLayers} from "./agroAhoMapStore";
import {AhoCardShowType, Card} from "../agroAhoCardStore";
import {AhoUtils} from "../agroAhoUtils";
import {IReactionDisposer, reaction} from "mobx";

export interface OpacityLayers {
    [key: string]: number; // [key: IAhoLayers]: number;
}

export interface OpacFieldItem {
    field_id: number;
    opacity?: OpacityLayers;
}

export class AgroAhoOpacity extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;
    opacFieldItems: OpacFieldItem[] = [];
    // cacheSource: any = {};
    cacheState: any = {};

    // список всех слоев в порядке следования

    // 1.  Подписи рек. норм          - rec norm    - symbol - cell geojson
    // 2.  Подписи ячеек              - cell        - symbol - cell vector
    // 3.  Подписи полей              - fields      - symbol - fields vector
    // 4.  Подписи показателей        - indicator   - symbol - cell geojson
    // 5.  Подписи маршрута объезда   - track route - symbol - cell geojson
    // 6.  Границы показателей        - indicator   - line   - cell geojson
    // 7.  Границы рек. норм          - rn          - line   - cell geojson
    // 8.  Заливка показателей        - indicator   - fill   - cell geojson
    // 9.  Крайние точки треков полей - track field - circle - track geojson
    // 10. Линии треков ячеек         - track cell  - line   - cell vector
    // 11. Линии треков полей         - track field - line   - track vector
    // 12. Точки                      - points      - circle - point vector
    // 13. Границы ячеек              - cells       - line   - cell vector
    // 14. Заливка ячеек              - cells       - fill   - cell vector
    // 15. Границы полей              - fields      - line   - field vector
    // 16. Заливка рек. норм          - rec norm    - fill   - cell geojson
    // 17. Заливка маршрута объезда   - track route - fill   - cell geojson

    subscription(): IReactionDisposer[]{
        return [
            reaction(()=>this.mapStore.parentStore.cardStore.card,
                ()=>this.doChangeCard())
        ]
    }

    doChangeCard(){
        // console.log('reset idsCache')
        this.idsCache = {};
    }

    get ids_func(): any{
        let cs = this.mapStore.parentStore.cardStore;
        return {
            field: (fid: number)=>Promise.resolve([fid]),
            cell: (fid: number)=>{
                let res: number[] = [];
                if (cs.cellsItems) res = cs.cellsItems.filter(c=>c.field_id == fid).map(c=>c.cell_id);
                return res;
            }
        }
    }




    // cells: boolean;
    // cellsNumber: boolean;
    // tracks: boolean;
    // trackRoute: boolean;
    // fields: boolean;
    // points: boolean;
    // fieldsNumber: boolean;
    // indicators: boolean;
    // indicatorsNumber: boolean;
    // recNorm: boolean;
    // recNormNumber: boolean;
    idsCache: any = {};



    get cfg(){
        let ms = this.mapStore;
        let cs = ms.parentStore.cardStore;
        let ts = ms.trackFieldPointStore;
        let rs = ms.mapRNStore;
        let idf = this.ids_func;
        let ds = {'field': cs.card.field_info.ds_code, 'cell': cs.card.cell_info.ds_code,
                  'track': cs.card.track_info.ds_code, 'point': cs.card.point_info.ds_code};
        let nc = ms.newCfg;
        return  { // id_type: field, cell, feature
            rn_lbl: {ids_func: rs.getRNIds, ds: ds.cell, src_id_func: rs.getRecNormSourceId, req: nc.recNormNumber}, // 1. Подписи рек. норм
            cell_lbl: {ids_func: idf.cell, ds: ds.cell, src_id_func: ms.getCardSourceId, postfix: '_lbl', req: nc.cellsNumber}, // 2. Подписи ячеек
            field_lbl: {ids_func: idf.field, ds: ds.field, src_id_func: ms.getCardSourceId, postfix: '_lbl', req: nc.fieldsNumber}, // 3. Подписи полей
            ind_lbl: {}, // 4. Подписи показателей
            track_route_lbl: {}, // 5. Подписи маршрута объезда
            ind_line: {}, // 6. Границы показателей
            rn_line: {}, // 7. Границы рек. норм
            ind: {ids_func: ms.getIndcIds, ds: ds.cell, src_id_func: ms.getIndcSouceId, req: nc.indicators}, // 8. Заливка показателей
            track_field_point: {ids_func: ts.getTrackFPIds, ds: ds.track, src_id_func: ts.getTFPSourceId, req: nc.tracks}, // 9. Крайние точки треков полей
            track_cell: {ids_func: cs.getTrackIds, ds: ds.track, src_id_func:  ms.getCardSourceId, req: nc.tracks}, // 10. Линии треков ячеек
            track_field: {ids_func: cs.getTrackFIds, ds: ds.track, src_postfix: '_fld', src_id_func:  ms.getCardSourceId, req: nc.tracks}, // 11. Линии треков полей
            point: {}, // 12. Точки
            cell: {ids_func: idf.cell, ds: ds.cell, src_id_func: ms.getCardSourceId, req: nc.cells}, // 13. Границы ячеек
            cell_fill: {}, // 14. Заливка ячеек
            field: {ids_func: idf.field, ds: ds.field, src_id_func: ms.getCardSourceId, req: nc.fields}, // 15. Границы полей
            rn: {ids_func: rs.getRNIds, ds: ds.cell, src_id_func: rs.getRNSouceId, req: nc.recNorm}, // 16. Заливка рек. норм
            track_route: {} // 17. Заливка маршрута объезда
        } as any;
    };

    // resetCacheSource(){
    //     this.cacheSource = {};
    // }

    // async getCacheSource(source_id: string){
    //     let src = this.cacheSource[source_id];
    //     if (src) return src;
    //     let map = this.root.map.mapbox;
    //     await AhoUtils.wait(async ()=>await map.getSource(source_id),
    //         // `wait ${key}`,10, 10);
    //         `wait ${source_id}`,10, 10);
    //     src = await map.getSource(source_id);
    //     this.cacheSource[source_id] = src;
    //     return src;
    // }

    async setState(cfg: any, id: number, state: any, key?: string){
        if (!cfg.req) return;
        let map = this.root.map.mapbox;
        let source_id = await cfg.src_id_func(cfg.ds + (cfg.src_postfix || ''));
        // let src:any = await this.getCacheSource(source_id);
        await AhoUtils.wait(async ()=>await map.getSource(source_id),null, 50, 30);
        // await AhoUtils.wait(async ()=>await map.getSource(source_id),`wait ${key}`,10, 30);
        let src = await map.getSource(source_id);
        // if (key == 'track_field_point') console.log('src', !!src);
        if (!src) return;

        let feat: any = { source: source_id, id: id};
        if (src.type !== 'geojson') feat.sourceLayer = cfg.ds;
        this.root.map.mapbox.setFeatureState(feat, state);
    }

    // async setState_(cfg: any, id: number, state: any, key?: string){
    //     // if (!cfg.req) return;
    //     let map = this.root.map.mapbox;
    //     let source_id = await cfg.src_id_func(cfg.ds);
    //     let src = map.getSource(source_id);
    //     if (!src) {
    //         // console.log('no src', key, 'req:', cfg.req);
    //         // if (source_id == 'agro_base_kazm11_asa_map_97_track_tfp_src_aho') console.log('no src', source_id);
    //         return;
    //     }
    //     let feat: any = { source: source_id, id: id};
    //     if (src.type !== 'geojson') feat.sourceLayer = cfg.ds;
    //     // if (feat.source == 'agro_base_kazm11_asa_map_97_track_tfp_src_aho') console.log('feat', feat, AhoUtils.cp(state));
    //     this.root.map.mapbox.setFeatureState(feat, state);
    // }

    async updOpacity() {
        // Все свойства хранятся в переменных opacFieldItems и opacCellsItems и назначаются слоям через feature-state
        // Fields
        if (this.opacFieldItems) {
            for (let i = 0; i < this.opacFieldItems.length; i++) {
                let f = this.opacFieldItems[i];
                if (f.opacity) for (let key of Object.keys(f.opacity)) {
                    let cfg = this.cfg[key];
                    if (cfg && Object.keys(cfg).length) {
                        let opacity: number = f.opacity[key];
                        let ckey = `${key}_${f.field_id}`;
                        let fids = this.idsCache[ckey];
                        if (!fids) {
                            fids = await cfg.ids_func(f.field_id);
                            this.idsCache[ckey] = fids;
                        }
                        // if (key == 'track_field_point') console.log('f', f.field_id, 'ids', AhoUtils.cp(fids));
                        // let l: any = {layers: ['class_agroAho_track_field_point_aho']};
                        // if (key == 'track_field_point') console.log('f',
                        //     this.root.map.mapbox.queryRenderedFeatures(l));
                        fids.forEach((fid: number)=> {
                            let state: any = {};
                            state['opacity' + (cfg.postfix || '')] = opacity;
                            this.setState(cfg, +fid, state, key);
                            
                        });
                    }
                }
            }
        }
    }

    async updOpacityLayers() {
        let cs = this.mapStore.parentStore.cardStore;
        if (cs.ignoreChangeConfig) return;
        if (!this.mapStore.agroAhoTool.isInit) return;
        if (!this.mapStore.parentStore.cardStore.card) return;

        this.updOpacityInfo();
        this.updOpacity();
    }

    setFieldsOpacity(layers: string[], field_ids: number[], opacity: number, other_opacity?: number) {
        let ls = Object.keys(this.cfg);
        if (!layers) layers = ls;
        let fs = this.opacFieldItems.map(f=>f.field_id);
        if (!field_ids) field_ids = fs;
        if (!this.opacFieldItems?.length) return;
        this.opacFieldItems.forEach(f=>{
            if (!f.opacity) f.opacity = {};
            layers.forEach(l=>{
                if (field_ids.indexOf(f.field_id) >= 0) {
                    f.opacity[l] = opacity;
                } else if (other_opacity || other_opacity == 0) {
                    f.opacity[l] = other_opacity;
                }
            });
        });
        // console.log('updFieldsOpacity', AhoUtils.cp(field_ids), layers, AhoUtils.cp(cs.fieldsItems));
    }

    updOpacityInfo() {
        let store_ = this.mapStore.parentStore;
        let cs = store_.cardStore;
        let ms = this.mapStore;
        let ts = ms.trackFieldPointStore;
        let es = store_.editorStore;
        let ers = ms.mapEditRouteStore;
        let tracks = ['track_field', 'track_cell', 'track_field_point'];
        this.opacFieldItems = cs.fieldsItems.map(f=>{ return {field_id: f.field_id}});

        // Обработка прозрачности:
        // 1. Выделены поля
        let sf = cs.getFieldSelected();
        if (sf.length == cs.fieldsItems.length) sf = [];
        // console.log('sel fields', AhoUtils.cp(sf));
        if (sf.length) this.setFieldsOpacity(null, sf, 1, 0.2)
        else this.setFieldsOpacity(null, null, 1);
        // Для не выделенных полей название поля без прозрачности
        if (sf.length) this.setFieldsOpacity(['field_lbl'], sf,
            (ms.layersShow && ms.layerCellsShow) || (cs.soilAnalysisShow &&
                cs.ahoCardShowType != AhoCardShowType.WithoutLabels) ? 0 : 1, 1)
        if (!sf.length && !(
            (cs.soilAnalysisShow && cs.fieldsNumberShow) || (ms.layersShow && !ms.layerCellsShow)
        )) this.setFieldsOpacity(['field_lbl'], null, 0);

        // 2. Редактируется трек или порядок объезда
        let fid = es.getEditTrackFieldId();
        let rid = ers.getRouteFieldId();
        let tid = fid || rid;
        // Для всех треков : 0.2
        if (tid !== null ) this.setFieldsOpacity(tracks, [tid], 0.2);
        // Границы ред. поля 1, остальные 0.2
        if (tid !== null) this.setFieldsOpacity(['field'], [tid], 1, 0.2);
        // Подсиси ячеек ред. поля 0, остальные 0.2
        if (fid !== null) this.setFieldsOpacity(['cell_lbl'], [fid], 0, 0.2);
        // Подсиси полей ред. поля 0
        if (rid !== null) this.setFieldsOpacity(['field_lbl'], [rid], 0);

    }
}