import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IProductItemExportComp} from "./ProductItemExportComp";
import './ProductItemExportNormaComp.scss';
import {CheckboxMiniComp, CheckboxSize} from "../../../app/components/Common/CheckboxMiniComp";
import {action, observable} from "mobx";
import {Utils} from "../../../app/helper/utils/Utils";
import {ProductItemExportNormaClassComp} from "./ProductItemExportNormaClassComp";
import classNames from "classnames";
import {InputNumberArrow} from "../../../app/components/Common/InputNumberArrow";
import {DropDownComp, IDropDownItem} from "../../../app/components/Common/DropDownComp";
import {fetchJsonGet} from "../../../app/helper/utils/FetchUtils";
import {ra} from "../../../app/helper/utils/mobxUtils";


interface IFertilizer{
    fert_id: number;
    fert_name: string;
}
@observer
export class ProductItemExportNormaComp extends React.Component<IProductItemExportComp, undefined> {
    constructor(props: IProductItemExportComp) {
        super(props);
        autoBindReact(this);
        let exp = this.props.exp;
        this.props.exp.normaInit(this.props.index.getDiscreteClassValuesValidsInterval().length);
        this.normaBase = this.props.exp.normaBase.toString();
        setImmediate(async() =>{
            try {
                await this.loadFertilizer();
            }catch (e) {
                this.props.store.addError(e);
            }
        });
    }
    @observable
    normaBase: string;
    @observable
    fertilizers: IFertilizer[]

    @action
    async loadFertilizer(){
        let url = "/api/reference/fert/list";
        let r: {values: IFertilizer[]} = await fetchJsonGet(url);
        ra(()=> {
            this.fertilizers = r.values;
        });
    }

    @action
    onClickNormaCheck(){
        this.props.exp.normaChecked = !this.props.exp.normaChecked;
    }
    @action
    onChangeBaseNorma(str: string){
        this.normaBase = str;
        let v = Utils.parseNumber(str);
        if (v != null){
            this.props.exp.normaBase = v;
        }
    }

    @action
    onChangeClass(index: number, value: number){
        this.props.exp.normaClasses[index] = value;
    }

    @action
    onChangeFert(item: IDropDownItem, newIndex: number){
        if (item != null) {
            this.props.exp.fert_id = item.data;
        }else this.props.exp.fert_id = null;
    }
    render() {
        let store = this.props.store;
        let index = this.props.index;
        let exp = this.props.exp;

        let intervals = index.getDiscreteClassValuesValidsInterval();

        let notActiveNotVisible = index.hasTransparentClasses() && exp.notExportInvisible && exp.normaChecked;

        let classItems: any[] = [];
        intervals.forEach((a, idx) => {
            let area = 0;
            if (store.map.productInfo.legendAreaStore.areas.length > idx){
                area = store.map.productInfo.legendAreaStore.areas[idx];
            }

            classItems.push(<ProductItemExportNormaClassComp store={store} value={exp.normaClasses[idx]}
                                                             disabled={!exp.normaChecked} area={area} disableAll={notActiveNotVisible && a.isTransparent}
                                                             base={exp.normaBase}
                                                             key={idx} index={idx} onChange={this.onChangeClass} color={a.color} /> );
        });

        let fert_items: IDropDownItem[] = [];
        if (this.fertilizers != null) fert_items = this.fertilizers.map(a => {
            let r: IDropDownItem = {key: a.fert_id, value: a.fert_name, data: a.fert_id};
            return r;
        });

//http://localhost:8080/?link=171755
        return <div className="ProductItemExportNormaComp-main">
            <div className="ProductItemExportNormaComp-row-Check">
                <CheckboxMiniComp state={exp.normaChecked} size={CheckboxSize.switcher} onClick={this.onClickNormaCheck} disabled={exp.cutVisible} >
                    <div className="ProductItemExportNormaComp-textBold pointer text-next">Задать нормы внесения (кг/га)</div>
                </CheckboxMiniComp>
            </div>
            <div className={classNames({"opacity30": !exp.normaChecked})}>
                <div className="ProductItemExportNormaComp-row ProductItemExportNormaComp-row-baseNorma">
                    <div className="ProductItemExportNormaComp-col1">
                        <div className="ProductItemExportNormaComp-textBold">Базовая норма <span
                            className="ProductItemExportNormaComp-star">*</span></div>
                    </div>
                    <div className="ProductItemExportNormaComp-col2">
                        <InputNumberArrow value={exp.normaBase} disabled={!exp.normaChecked}
                                          onChange={this.onChangeBaseNorma}
                                          classNameDiv="ProductItemExportNormaComp-baseInput"
                                          classNameInput="text-box-editor number-without-arrow width100"/>
                    </div>
                    <div className="ProductItemExportNormaComp-col3 ProductItemExportNormaComp-textGray">
                        кг/га
                    </div>
                </div>
                <div>
                    <div className="ProductItemExportNormaComp-row-fertilizer">{store.trans.Fertilizer}</div>
                    <div style={{"width": "320px"}}>
                        <DropDownComp items={fert_items} currentKey={exp.fert_id}
                                      onChange={this.onChangeFert}/>
                    </div>
                </div>


                <div className="ProductItemExportNormaComp-row-procentTitle">Процент отклонения от базовой нормы:</div>
                {classItems}
            </div>
        </div>;
    }
}
