import {IPaintPoint} from "./IPaintPoint";
import {IPaintPolyline} from "./IPaintPolyline";
import {IPaintPolygon} from "./IPaintPolygon";
import {IPaintLabel} from "./IPaintLabel";

export enum IPaintType{
    point = "point",
    polyline = 'polyline',
    polygon = 'polygon',
    label = 'label'
}
export interface IPaint {
    point?: IPaintPoint;
    polyline?: IPaintPolyline;
    polygon?: IPaintPolygon;
    label?: IPaintLabel;
}