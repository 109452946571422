import * as React from "react";
import {IStoreProps} from "../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";

interface IScrollSaveComp{
    scrollTop: number;
    onScroll: (pos: number, max: number)=>any;
    className?: string;
}
@observer
export class ScrollSaveComp extends React.Component<IScrollSaveComp, undefined> {
    constructor(props: IScrollSaveComp) {
        super(props);
        autoBindReact(this);
        this.ref = React.createRef();
    }
    ref: any;
    onScroll(e: any){
        let max = this.ref.current.scrollHeight - this.ref.current.offsetHeight;
        if (this.props.onScroll)
            this.props.onScroll(this.ref.current.scrollTop, max);
    }
    componentDidUpdate() {
        if (this.ref.current)
            this.ref.current.scrollTop = this.props.scrollTop;
    }
    componentDidMount() {
        if (this.ref.current)
            this.ref.current.scrollTop = this.props.scrollTop;
    }
    render() {
        return <div ref={this.ref} onScroll={this.onScroll}
            className={this.props.className}>{this.props.children}</div>;
    }
}