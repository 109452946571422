// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClassLegendComp-tableCol1 {
  width: 55px;
  position: relative;
}

.ClassLegendComp-tableCol3 {
  position: relative;
}

.ClassLegendComp-table {
  top: -15px;
  position: relative;
  border-collapse: collapse;
  margin: 4px;
  font-size: 12px;
}

.ClassLegendComp-tableCol2Text {
  margin-left: 4px;
  padding-right: 4px;
  color: #C5C5C5;
}

.ClassLegendComp-ballon-absLines {
  position: absolute;
  top: 15px;
  left: -30px;
}

.ClassLegendComp-ballon-middle {
  border-left: 1px solid #6D7785;
  border-right: 1px solid #6D7785;
  background: #2A94E1;
  height: 31px;
  width: 18px;
  box-sizing: border-box;
  left: -20px;
}

.ClassLegendComp-ballon-top {
  border: 1px solid #6D7785;
  border-bottom-width: 0;
  border-radius: 9px 9px 0 0;
  background: #E1AE2A;
  height: 31px;
  width: 18px;
  box-sizing: border-box;
  left: -20px;
}

.ClassLegendComp-ballon-bottom {
  border: 1px solid #6D7785;
  border-top-width: 0;
  border-radius: 0 0 9px 9px;
  background: #D75455;
  height: 31px;
  width: 18px;
  box-sizing: border-box;
  left: -20px;
}

.ClassLegendComp-tableCol3Text {
  position: relative;
  top: 15px;
  color: #C5C5C5;
  height: 100%;
  padding-left: 6px;
}`, "",{"version":3,"sources":["webpack://./plugins/meteo/Meteo/ClassLegendComp.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;AACJ;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,UAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;AAGF;;AADA;EACE,gBAAA;EACA,kBAAA;EACA,cAAA;AAIF;;AAFA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAKF;;AAHA;EACE,8BAAA;EACA,+BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,WAAA;AAMF;;AAJA;EACE,yBAAA;EACA,sBAAA;EACA,0BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,WAAA;AAOF;;AALA;EACE,yBAAA;EACA,mBAAA;EACA,0BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,WAAA;AAQF;;AANA;EACE,kBAAA;EACA,SAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;AASF","sourcesContent":[".ClassLegendComp-tableCol1{\n    width: 55px;\n    position: relative;\n}\n.ClassLegendComp-tableCol3{\n  position: relative;\n}\n.ClassLegendComp-table {\n  top: -15px;\n  position: relative;\n  border-collapse: collapse;\n  margin: 4px;\n  font-size: 12px;\n}\n.ClassLegendComp-tableCol2Text {\n  margin-left: 4px;\n  padding-right: 4px;\n  color: #C5C5C5;\n}\n.ClassLegendComp-ballon-absLines {\n  position: absolute;\n  top: 15px;\n  left: -30px;  \n}\n.ClassLegendComp-ballon-middle {\n  border-left: 1px solid #6D7785;\n  border-right: 1px solid #6D7785;\n  background: #2A94E1;\n  height: 31px;\n  width: 18px;\n  box-sizing: border-box;\n  left: -20px;  \n}\n.ClassLegendComp-ballon-top {\n  border: 1px solid #6D7785;\n  border-bottom-width: 0;\n  border-radius: 9px 9px 0 0;\n  background: #E1AE2A;\n  height: 31px;\n  width: 18px;\n  box-sizing: border-box;\n  left: -20px;\n}\n.ClassLegendComp-ballon-bottom {\n  border: 1px solid #6D7785;\n  border-top-width: 0;\n  border-radius: 0 0 9px 9px;\n  background: #D75455;\n  height: 31px;\n  width: 18px;\n  box-sizing: border-box;\n  left: -20px;\n}\n.ClassLegendComp-tableCol3Text {\n  position: relative;\n  top: 15px;\n  color: #C5C5C5;\n  height: 100%;\n  padding-left: 6px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
