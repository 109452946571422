import {
    ContainerToolsState,
    CreateGeometryType,
    IGeometryEditorEvents,
    IObjectByClick
} from "../tools/general/ContainerTools";
import {CustomStore, ObservableCustomStore} from "../CustomStore";
import * as mapboxgl from "maplibre-gl";
import {MapMouseEvent} from "maplibre-gl";
import {Geometry} from "geojson";
import {GeometryUtils, SimpleGeometryType} from "../../helper/utils/GeometryUtils";
import {ViewGeometryLayersTool} from "../tools/EditGeometry/ViewGeometryLayersTool";
import {action, autorun, observable} from "mobx";
import {MeasuringStatus} from "../../helper/structs/MeasuringStatus";
import {FieldEditModeType} from "../agro/fieldForm/A2FieldFormCustomStore";
import {ToolEvent} from "../../../pluginApi/tools/ToolEvent";
import {AgroCutManager} from "./AgroCutManager";
import {IReactionDisposer} from "mobx/lib/internal";

export class AgroEditManager extends ObservableCustomStore implements IGeometryEditorEvents{
    constructor(parent: CustomStore) {
        super(parent);
        this.state.events = this;
        this.cutManager.externalState = this.state;
    }
    public state: ContainerToolsState = new ContainerToolsState();
    public cutManager: AgroCutManager = new AgroCutManager(this);

    public subscription(): IReactionDisposer[] {
        return [
            autorun(()=>{
                if (this.root.map.mapReady){
                    let ms = this.root.map.measuringStatus;
                    if (ms == MeasuringStatus.agroCreateLine || ms == MeasuringStatus.agroCreatePoint ||
                        ms == MeasuringStatus.agroCreatePolygon || ms == MeasuringStatus.agroAutoPolygon ||
                        ms == MeasuringStatus.agroCreatePolygonHole || ms == MeasuringStatus.agroDeleteContour){
                        //this.viewGeometryLayersTool.style.setRedStyle();
                        this.viewGeometryLayersTool.reinstallLayers();
                    }
                    if (this.isEdit() || ms == MeasuringStatus.agroCutGeometry || ms == MeasuringStatus.agroUnionGeometry){
                        //this.viewGeometryLayersTool.style.setBlueStyle();
                        this.viewGeometryLayersTool.reinstallLayers();
                    }
                }
            })
        ];
    }

    @observable
    _changes: number = 0;
    get changes(): number{
        return this._changes;
    }

    get viewGeometryLayersTool(): ViewGeometryLayersTool{
        return this.root.map.superTools.viewEditorGeometry;
    }
    getGeometry(): Geometry{
        (this.root.agro2.editManager.changes == 0);//просто привязывамся к переменной
        return GeometryUtils.createGeometryBySimpleGeometry(this.state.simpleGeometry);
    }
    setGeometry(value: Geometry){
        this.state.resetMovedPoints();
        this.state.simpleGeometry = GeometryUtils.getSimpleGeometries(value);
        this.updateGeometry();
        this.resetChanges();
    }
    resetChanges(){
        if (this._changes == 0) this._changes = -1;
        else this._changes = 0;
    }
    isEdit(): boolean {
        let editMode = this.root.agro2.fieldEditorForm.editMode;
        return (this.root.map.measuringStatus ==  MeasuringStatus.None) && (editMode == FieldEditModeType.edit || editMode == FieldEditModeType.insert);//this.root.map.measuringStatus == MeasuringStatus.agroEdit;
    }
    isDeleteContour(): boolean {
        return this.root.map.measuringStatus == MeasuringStatus.agroDeleteContour;
    }
    isCreateGeometry(): boolean {
        let ms = this.root.map.measuringStatus;
        return ms == MeasuringStatus.agroCreatePolygon
            || ms == MeasuringStatus.agroCreatePoint
            || ms == MeasuringStatus.agroCreateLine
            || ms == MeasuringStatus.agroCreatePolygonHole;
    }

    public startCreateGeometry(geometryType: CreateGeometryType){
        this.state.createGeometryType = geometryType;
        this.resetAll();
    }
    private resetAll(){
        this.state.resetMovedPoints();
        this.state.midPoint = null;
        this.state.movingPoint = false;
        this.state.curAddrContour = null;
        this.state.highlightContourAddr = null;
    }
    public deactivate(){
        this.resetAll();
    }
    public toEdit(){
        this.resetAll();
    }
    public toCreateGeoemtry(geomType: CreateGeometryType){
        this.resetAll();
        this.state.createGeometryType = geomType;
    }
    public toDeleteContour(){
        this.resetAll();
    }


    @action
    public Finished(){
        this.deleteSmallContours();
        if (this.root.map.measuringStatus == MeasuringStatus.agroCreatePolygon){
            this.toCreateGeoemtry(CreateGeometryType.Polygon);
        }else if (this.root.map.measuringStatus == MeasuringStatus.agroCreatePolygonHole){
            this.toCreateGeoemtry(CreateGeometryType.Hole);
        }else {
            this.root.map.resetMeasuringStatus();
        }
        this.root.map.superTools.viewEditorGeometry.updateGeometry();
    }

    deleteSmallContours(){
        if (this.state.simpleGeometry == null) return;
        this.state.simpleGeometry = this.state.simpleGeometry.filter(a => {
            if (a.simple == SimpleGeometryType.Polygon){
                if (a.contour.length < 3){ return false;}
                if (a.holes != null) a.holes = a.holes.filter(a => {
                    if (a.length < 3) return false;
                    return true;
                })
            }
            if (a.simple == SimpleGeometryType.Line){
                if (a.contour != null && a.contour.length >= 2) return true;
                return false;
            }
            return true;
        })

    }

    getObjectByClick(point: mapboxgl.Point):IObjectByClick{
        return this.root.map.superTools.viewEditorGeometry.getObjectByClick(point);
    }
    public updateMovedPoint() {
        this.root.map.superTools.viewEditorGeometry.updateMovedPoint();
    }

    public updateGeometry(){
        this.root.map.superTools.viewEditorGeometry.updateGeometry();
    }

    onClickFirstPointPolygon(e : MapMouseEvent & ToolEvent): boolean{
        return this.onClickRightButton(e);
    }
    onClickRightButton(e : MapMouseEvent & ToolEvent): boolean{
        this.state.resetMovedPoints();
        this.Finished();
        e.noPropagation();
        return false;
    }
    onChangeGeometry() {
        this._changes++;
    }
    onDeleteContour(e : MapMouseEvent & ToolEvent): void {
        this.state.highlightContourAddr = null;
        this.updateGeometry();
    }

}