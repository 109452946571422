import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import classNames from "classnames";
import {action} from "mobx";
import {
    A2FieldFormCustomStore,
    A2FieldFormNotValidType
} from "../../../../../store/agro/fieldForm/A2FieldFormCustomStore";

export interface IA2FieldEditorInlineErrorCompProps extends IStoreProps {
    field: A2FieldFormCustomStore;
    validType?: A2FieldFormNotValidType;
}

@observer
export class A2FieldEditorInlineErrorComp extends React.PureComponent<IA2FieldEditorInlineErrorCompProps, undefined> {
    constructor(props: IA2FieldEditorInlineErrorCompProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onEndAnimation(){
        this.props.field.labelsErrorAnimation = false;
    }

    render() {
        if (!this.props.validType) return null;
        if (this.props.validType == A2FieldFormNotValidType.isRequired) return <div
            onAnimationEnd={this.onEndAnimation}
            className={classNames("Agro2CreateField-errorInline",{"Agro2CreateField-redLabelsDrag": this.props.field.labelsErrorAnimation})}>
            {this.props.store.trans["Required data"]}
        </div>;
        if (this.props.validType == A2FieldFormNotValidType.isNotValid) return <div
            onAnimationEnd={this.onEndAnimation}
            className={classNames("Agro2CreateField-errorInline",{"Agro2CreateField-redLabelsDrag": this.props.field.labelsErrorAnimation})}>
            {this.props.store.trans["Not valid value"]}
        </div>;
    }
}
