import { SearchObjectStore } from './SearchObjectStore';
import {CustomStore, ObservableCustomStore} from "./CustomStore";
import {action, autorun, computed, observable, reaction} from "mobx";
import {LoadStatus} from "../helper/structs/LoadStatus";
import {Utils} from "../helper/utils/Utils";
import {Feature, FeatureCollection, Geometry, Polygon} from "@turf/helpers";
import {cloneDeep, isEqual} from "lodash-es";
import {SearchItemStore} from "./SearchItemStore";
import {IntervalStore} from "./IntervalStore";
import {ISatellitesDicBool, satDic, Satellite, SatEnum2, Sentinel2Level} from "../helper/utils/satellliteDic";
import {SearchListSceneStore} from "./SearchListSceneStore";
import {CheckStates} from "../helper/structs/CheckStates";
import {SearchAddressStore} from "./SearchAddressStore";
import {fetchJson, fetchJsonPost} from "../helper/utils/FetchUtils";
import {save, update} from "./PermalinkDecor";
import {SearchParamsS5Store, SearchParamsS5Type} from "./SearchParamsS5Store";
import {ra, rai} from "../helper/utils/mobxUtils";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import {MBUtils} from "../helper/utils/MBUtils";
import {LngLat, PointLike} from "maplibre-gl";
import {SearchParamsS1Store, Sentinel1Mode} from "./SearchParamsS1Store";
import {IReactionDisposer} from "mobx/lib/internal";

export type LeftPanelModeType = string;
export enum LeftPanelMode {
    rshb = 'rshb',
    //agrometrikaAho = 'agrometrikaAho',
    search ='search',
    favorite = 'favorite',
    agro = 'agro',
    photo = 'photo',
    showRoom = 'showRoom',
    soilGrid = 'soilGrid',
    orders = 'orders',
    showRoom2 = 'showRoom2',
    //createAgroField2 = 'createAgroField2',
    uploadFields = 'uploadFields',
    createProject = 'createProject',
    editProject = 'editProject',
    test = 'test'
}
export enum SceneMosaicSwitch{
    scene = 'scene',
    mosaic = 'mosaic'
}

export enum SearchSceneSourceType{
    passive= 'passive',
    sentinel5='sentinel5',
    sentinel1='sentinel1'
}


export class SearchStore extends ObservableCustomStore{
    constructor(parent: CustomStore) {
        super(parent);
        this._favoriteList.autoSort = false;
        this._searchResult.autoSort = true;
    }
    class(): string {return "SearchStore";}
    static readonly maxSearchItems: number = 500;
    static readonly MIN_SEARCH_YEAR = 1985;

    subscription(): IReactionDisposer[] {
        return [
            autorun(()=>{
                this.subscriptionSyncDateFilter();
            }),
            reaction(()=>{
                return {a: this.root.searchPanel.currentSceneVisible, b: this.root.map.mapScenesStore.favoriteScenesVisible};
            }, ()=>{
                this.detectTopScene();
            })
        ];
    }

    @save
    @observable
    private _leftPanelMode: LeftPanelMode | string = LeftPanelMode.search;
    get leftPanelMode(): LeftPanelMode | string{
        return this._leftPanelMode;
    }

    initialMeasuringStatus: MeasuringStatus | string = MeasuringStatus.None;
    private needResetStatus() {
        return this.initialMeasuringStatus != this.root.map.measuringStatus &&
            [MeasuringStatus.CreatePointIndex, MeasuringStatus.Ruler2, MeasuringStatus.Ruler2Edit, MeasuringStatus.ndviValueByMouse, 
                MeasuringStatus.surveyPoint as MeasuringStatus | string].indexOf(this.root.map.measuringStatus) < 0;
    }

    @save @observable
    searchAddress: SearchAddressStore = new SearchAddressStore(this);
    @save @observable
    showParamsPanel: boolean = false;

    @save @observable
    //showFavoriteGroupScenes: boolean = false;
    favoriteGroupScenes: string = null;
    @save @observable
    showSearchProductPanel: boolean = false;
    @save @observable
    showNdviPanelSceneUiType: SceneUiType = SceneUiType.current;
    @save @observable
    sceneMosaicSwitch: SceneMosaicSwitch = SceneMosaicSwitch.scene;

    @save @observable
    sourceType: SearchSceneSourceType = SearchSceneSourceType.passive;

    //@update
    searchParamsS5: SearchParamsS5Store = new SearchParamsS5Store(this);
    @update
    searchParamsS1: SearchParamsS1Store = new SearchParamsS1Store(this);

    @observable
    showOrderList: boolean = false;

    @observable
    showDeleteAllScene: boolean = false;

    @update
    filterDate: IntervalStore = new IntervalStore(this);
    formFilterDate: IntervalStore = new IntervalStore(this);

    @save @observable
    scrollPos: number = 0;
    @save @observable
    favorite_scrollPos: number = 0;

    private subscriptionSyncDateFilter(){
        this.formFilterDate.begin = this.filterDate.begin;
        this.formFilterDate.end = this.filterDate.end;
    }

    @save @observable
    filterSeasonDate: boolean = false;
    @save @observable
    filterCloud: number = null;
    @save @observable
    filterSunelev: number = null;
    @save @observable
    filterSource: string = null;
    @save @observable
    filterTiles: boolean = false;
    @save @observable
    filterSentinel2_level: Sentinel2Level = Sentinel2Level.L2;
    @save @observable
    filterSatellites: ISatellitesDicBool = {S2: true, L7E: false, L45: false, L8: true};

    @update @observable
    private _searchResult: SearchListSceneStore = new SearchListSceneStore(this);
    get searchResult(): SearchListSceneStore{ return this._searchResult; }

    @update @observable
    private _favoriteList: FavoritesListSceneStore = new FavoritesListSceneStore(this);
    get favoriteList(): FavoritesListSceneStore{ return this._favoriteList; }

    @computed
    get has_animated_fav_icon(): boolean{
        return this._favoriteList.records.find(a => a.animate_fav_icon) != null;
    }

    @computed
    get currentGroup() : SearchItemGroup {
        if (this.top_sceneType == SceneUiType.current) {
            return this.searchResult.findGroup(this.top_sceneId);
        }
        if (this.top_sceneType == SceneUiType.favorite) {
            return this.favoriteList.findGroup(this.top_sceneId);
        }
        return null;
    }

    //текущая веделенная сцена
    @save @observable
    currentSceneid: string;
    @save @observable
    cutFieldsByBorder: boolean = false;//обрезать растр по границе полей
    @save @observable
    currentSceneVisible: boolean = true;//глобальное отключение видимости текущей сцены
    @observable
    hoverSceneid: string;//мышка над сценой

    //базовые параметры поиска текущего состояния
    @save
    searchParams: ISearchUrlParams = null;

    timer_refresh: any = null;

    @observable
    top_sceneId: any = null;
    @observable
    top_sceneType: SceneUiType = null;
    @observable
    top_scene_disabled: boolean = false;
    @observable
    isDateChangedByUser: boolean = false; // ручное изменение даты

    @action
    detectTopScene(){
        let ll = this.root.map.center.getMbPoint();
        let sceneCur = this.getTopCurrentScene(ll);
        if (this.root.searchPanel.currentSceneVisible && sceneCur != null){
            this.top_sceneId = sceneCur;
            this.top_sceneType = SceneUiType.current;
            this.top_scene_disabled = false;
            return;
        }
        let sceneFav = this.getTopFavScene(ll);
        if (sceneFav != null && this.root.map.mapScenesStore.favoriteScenesVisible){
            this.top_sceneId = sceneFav;
            this.top_sceneType = SceneUiType.favorite;
            this.top_scene_disabled = false;
            return;
        }
        if (sceneCur != null){
            this.top_sceneId = sceneCur;
            this.top_sceneType = SceneUiType.current;
            this.top_scene_disabled = true;
            return;
        }
        if (sceneFav != null){
            this.top_sceneId = sceneFav;
            this.top_sceneType = SceneUiType.favorite;
            this.top_scene_disabled = true;
            return;
        }
        this.top_sceneId = null;
        this.top_sceneType = null;
        this.top_scene_disabled = true;
    }

    @action
    public closeAllLeftPanels(){
        //this.showNdviPanel = false;
        this.showParamsPanel = false;
        this.searchAddress.closePanel();
        this.showOrderList = false;
        this.root.exportStore.showExportOptions = false;
        this.showDeleteAllScene = false;
        this.root.agro.projectForm.showForm = false;
        this.root.photo.editor.editorWindowShow = false;
        this.root.photo.uploadImageStore.showPanel = false;

        //this.root.agro2.fieldEditorForm.showFieldMultiEditPanel = false;
        this.root.photo.currentPhotoId = null;//так убиираем панель просмотра фото
        this.root.events.onCloseAllLeftPanels.call();

    }

    public get favoriteProductGroupId() {
        return this.root.map.productInfo.activeProductsSet.favoriteProductGroupId;
    }

    public set favoriteProductGroupId(value: string) {
        this.root.map.productInfo.activeProductsSet.favoriteProductGroupId = value;
    }

    //кто-то загораживаеи
    public hasBlockLeftPanels(): boolean{
        return (this.favoriteProductGroupId != null || this.showSearchProductPanel 
            || this.showParamsPanel || this.showOrderList || this.root.exportStore.showExportOptions
            || this.showDeleteAllScene
            || this.root.photo.editor.editorWindowShow || this.root.photo.photoViewerShow) ;
    }

    showSearchList(): boolean{
        let store = this.root;
        if (this.root.searchPanel.sourceType == SearchSceneSourceType.sentinel5) return true;
        let p1 = (this.leftPanelMode != LeftPanelMode.search || this.root.map.extandMap);
        let p2 = store.indexByPointer.showPanel == WindowTriState.close;
        if (p1 && p2) return false;

        if (this.root.map.searchObject.isEmpty){
            if (this.root.map.zoom < MapComp.MIN_SEARCH_ZOOM) return false;
        }
        return true;
    }

    @action
    public switchSceneMosaic(value: SceneMosaicSwitch){
        if (this.sceneMosaicSwitch == value) return;
        this.sceneMosaicSwitch = value;
        if (value == SceneMosaicSwitch.mosaic){
            this.root.searchPanel.searchResult.convertToMosaic();
        }
        if (value == SceneMosaicSwitch.scene){
            this.root.searchPanel.searchResult.convetToScenes()
        }
    }

    exportWindowMapButtonEnabled(): boolean{
        let store = this.root;
        return (store.searchPanel.leftPanelMode == LeftPanelMode.search && Utils.isStringNotEmpty(store.searchPanel.currentSceneid)) &&
            (store.qgisExport.isActiveButtonWindow);
        //return (store.exportStore.getSceneItems().length > 0 );
    }

    @action
    public switchPanel(leftPanel: string){
        let map = this.root.map;
        if (map.extandMap) map.setExtandMap(false);
        if (this._leftPanelMode == leftPanel) return;
        this.root.events.onBeforeLeftPanelChanged.call();
        if (this.needResetStatus()) {
            map.resetMeasuringStatus();
        }
        this.initialMeasuringStatus = map.measuringStatus;
        if (leftPanel == LeftPanelMode.search)
            this.showNdviPanelSceneUiType = SceneUiType.current;
        if (leftPanel == LeftPanelMode.favorite) {
            this.showNdviPanelSceneUiType = SceneUiType.favorite;
            this.root.searchPanel.favoriteList.convertToMosaic();
        } 
        this.closeAllLeftPanels();
        this._leftPanelMode = leftPanel;
        if (leftPanel != LeftPanelMode.agro){
            //this.root.agro2.subModeLeftPanel = A2SubModeLeftPanel.tree;
        }
        this.root.events.onAfterLeftPanelChanged.call();
    }


    @action
    public switchParamsPanel(){
        let oldVal = this.showParamsPanel;
        if (this.showSearchProductPanel) this.showSearchProductPanel = false;
        if (this.favoriteProductGroupId) this.favoriteProductGroupId = null;
        this.closeAllLeftPanels();
        this.showParamsPanel = !oldVal;
    }

    @action
    public switchNdviPanel(sceneUiType: SceneUiType, favGroupId: string = null){
        // let oldVal = sceneUiType == SceneUiType.current && this.showSearchProductPanel 
        //     || sceneUiType == SceneUiType.favorite && this.showFavoriteProductGroupPanel;
        if (this.showParamsPanel) this.showParamsPanel = false;
        this.closeAllLeftPanels();
        if (sceneUiType == SceneUiType.current)
            this.showSearchProductPanel = !this.showSearchProductPanel;
        else if (sceneUiType == SceneUiType.favorite) 
            this.favoriteProductGroupId = (this.favoriteProductGroupId == favGroupId)? null : favGroupId;
        this.showNdviPanelSceneUiType = sceneUiType;
    }

    isFavoritesSceneIds(sceneIds: string[]): CheckStates{
        let groupSums = this.favoriteList.groups.map(x => 0);
        sceneIds.forEach(a => {
            this.favoriteList.groups.forEach((g, i) => g.items.forEach(c => {
                if (c.sceneId() == a) groupSums[i]++;
            }));
        });
        let sumFav = groupSums.length > 0? Math.max(...groupSums) : 0;
        if (sumFav == 0) return CheckStates.unchecked;
        if (sumFav >= sceneIds.length) return CheckStates.checked; //1 сцена в нескольких группах
        return CheckStates.partial;
    }

    refreshByTimer2(): void{
        let time: number = 2000;
        if (this.root.searchPanel.leftPanelMode != LeftPanelMode.search) time = time * 2.5;
        if (!this.root.config.searchImage) return;
        if (this.timer_refresh != null){
            clearTimeout(this.timer_refresh);
            this.timer_refresh = null;
        }
        let store = this.root;
        if (!store.map.mapReady) return;
        if (!store.searchPanel.showSearchList()) return;

        if (this.sourceType == SearchSceneSourceType.sentinel5){
            this.searchParams = null;
            this.doSearchS5();
            return;
        }
        let sp = this.createBaseSearchParams();
        if (sp == null || isEqual(this.searchParams, sp)) return ;

        this.timer_refresh = setTimeout(async ()=>{
            this.timer_refresh = null;
            if (sp == null) {
                //пустой вариант
                this.searchParams = null;
                this.searchResult.totalRecords = 0;
                this.searchResult.groups = [];
                this.searchResult.searchTotalState = LoadStatus.ready;
                this.searchResult.searchState = LoadStatus.ready;
                return;
            }
            await this.doSearch(sp);
        }, time);
    }
    async refreshImmediately(){
        if (this.sourceType == SearchSceneSourceType.sentinel5){
            this.searchParams = null;
            this.doSearchS5();
            return;
        }
        let sp = this.createBaseSearchParams();
        if (sp == null || isEqual(this.searchParams, sp)) return ;

        await this.doSearch(sp);
    }
    clearResults(){
        this.searchResult.totalRecords = 0;
        this.searchResult.groups = [];
        this.searchResult.searchTotalState = LoadStatus.ready;
        this.searchResult.searchState = LoadStatus.ready;
    }

    doSearchS5(){
        if (this.sourceType != SearchSceneSourceType.sentinel5) return ;
        if (this.allSentinel5DataStatus == null) {
            this.fetchAllSentinel5Data();
            this.searchResult.searchTotalState = LoadStatus.loading;
            this.searchResult.searchState = LoadStatus.loading;
            return;
        }
        if (this.allSentinel5DataStatus == LoadStatus.loading) {
            this.searchResult.searchTotalState = LoadStatus.loading;
            this.searchResult.searchState = LoadStatus.loading;
            return;
        }

        this.searchResult.searchTotalState = LoadStatus.ready;
        this.searchResult.searchState = LoadStatus.ready;
        if (this.searchParamsS5.type == SearchParamsS5Type.composites) {
            if (!this.searchParamsS5.compositeDate.isValid()) {
                this.clearResults();
                return;
            }
            let sceneid = "s5_composite";
            let k = new SearchItemStore(this);
            k.feature = this.getSentinel5Feature(sceneid, this.root.map.productInfo.productsS5.activeCodeCurrent.productCode, SatEnum2.S5L2A,
                this.searchParamsS5.compositeMethod, this.searchParamsS5.compositeDate.begin, this.searchParamsS5.compositeDate.end, null);

            let t = new SearchListSceneStore(this);
            t.syncAddSceneItem([k]);
            rai(()=>{
                this.searchResult.synchronizeLists(t);
                this.searchResult.setCountResult(1, 1);
            });
        }
        if (this.searchParamsS5.type == SearchParamsS5Type.scenes) {
            let cnt = 0;
            let d1 = new Date(this.searchParamsS5.sceneYear, this.searchParamsS5.sceneMonth, 1);
            let d2 = new Date(this.searchParamsS5.sceneYear, this.searchParamsS5.sceneMonth+1, 1);
            let prodCode = this.root.map.productInfo.productsS5.activeCodeCurrent.productCode;
            let arr: SearchItemStore[] = [];
            this.allSentinel5Data.forEach(a => {
                let d = new Date(a.date);
                if (d >= d1 &&
                    d <= d2 &&
                    a.products.indexOf(prodCode) >= 0){
                    let k = new SearchItemStore(this);
                    let sceneId = "S5_"+a.date;
                    k.feature = this.getSentinel5Feature(sceneId, prodCode, SatEnum2.S5L2A,
                        null, null, null, d);
                    arr.push(k);
                    cnt++;
                }
            });
            let t = new SearchListSceneStore(this);
            t.syncAddSceneItem(arr);
            rai(()=> {
                this.searchResult.synchronizeLists(t);
                this.searchResult.setCountResult(cnt, cnt);
            });
        }
    }

    static is_S5_SeceneId(scene_id: string): boolean{
        return (scene_id.toLowerCase().startsWith("s5_"));
    }


    getSentinel5Feature(sceneid: string, prodid: string, scene_type: SatEnum2, method: string,
                        begin_date: Date, end_date: Date, acqdate: Date): Feature<Geometry, ISearchPropertiesRest>{
        return {type: "Feature",
            geometry: {
                "type": "Polygon",
                "coordinates": [[[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]]
            },
            properties: {
                    acqdate: acqdate?Utils.getDateStr(acqdate):null,
                    "scene_id": sceneid,
                    "product_id": prodid,
                    "scene_type": scene_type,
                    "satellite": Satellite["Sentinel-5"],
                    "sat_id": "S5",
                    "level": "L2A",
                    "tiles_exists": true,
                    "gridcode": "ALL_WORLD",
                    s5_date_begin: end_date != null?begin_date.getTime(): null,
                    composite_method: method,
                    s5_date_end: end_date != null?end_date.getTime(): null,
                    "tl_x": -180,
                    "tl_y": 90,
                    "tr_x": 180,
                    "tr_y": 90,
                    "br_x": 180,
                    "br_y": -90,
                    "bl_x": -180,
                    "bl_y": -90
            }};
    }

    @observable
    private _allSentinel5Data: ISearchProductsS5DayInfo[] = [];
    @observable
    allSentinel5DataStatus: LoadStatus = null;

    get allSentinel5Data(): ISearchProductsS5DayInfo[]{
        if (this.allSentinel5DataStatus == null) this.fetchAllSentinel5Data();
        return this._allSentinel5Data;
    }


    fetchAllSentinel5Data(){
        let url = "/api/s5/product";
        let p: any = {};
        p.beg = Utils.getDateStr(new Date(1950,1,1));
        p.end = Utils.getDateStr(new Date());
        let ths = this;
        this.allSentinel5DataStatus = LoadStatus.loading;

        fetchJsonPost(url, p)
            .then( value => {
                ra(()=>{
                    let v: ISearchProductsS5Info = value;
                    this.allSentinel5DataStatus = LoadStatus.ready;
                    this._allSentinel5Data = v.date_products;
                });
            }).catch(err =>{
                ra(()=>{
                    this.allSentinel5DataStatus = LoadStatus.ready;
                    ths.root.addError(err);
                });
        });


    }


    createBaseSearchParams(): ISearchUrlParams{
        let store = this.root;

        let r: ISearchUrlParams = {};
        let filter: ISearchUrlFilter = {};
        r.filter = filter;


        let sats: SatEnum2[] = [];
        if (this.sourceType == SearchSceneSourceType.sentinel1){
            if (this.searchParamsS1.filterDate.isValidBegin())
                filter.beg = Utils.getDateStr(this.searchParamsS1.filterDate.begin);

            if (this.searchParamsS1.filterDate.isValidEnd())
                filter.end = Utils.getDateStr(this.searchParamsS1.filterDate.end);
            r.scene_source = "s1";
            filter.mode = this.searchParamsS1.mode;
            let t = this.searchParamsS1.getRealativeOrbitNumbers();
            if (t != null) filter.rel_orbit = t;
            let ms = store.map;
            let searchGeom: string = JSON.stringify(ms.searchObject.searchGeometry);
            filter.area = searchGeom;
        }else
        if (this.sourceType == SearchSceneSourceType.sentinel5){
            sats.push(SatEnum2.S5L2A);

            filter.beg = Utils.getDateStr(new Date(this.searchParamsS5.sceneYear, this.searchParamsS5.sceneMonth, 1));
            filter.end = Utils.getDateStr(new Date(this.searchParamsS5.sceneYear, this.searchParamsS5.sceneMonth+1, 1));
        }else {
            if (this.filterDate.isValidBegin())
                filter.beg = Utils.getDateStr(this.filterDate.begin);

            if (this.filterDate.isValidEnd())
                filter.end = Utils.getDateStr(this.filterDate.end);
            filter.seasonDate = this.filterSeasonDate && SearchStore.canUseFilterSeasonDate(this.filterDate.begin, this.filterDate.end);
            if (this.filterTiles != null){
                if (this.filterTiles) r.tiles = 1;
            }
            if (this.filterCloud != null){
                filter.cloud = Math.round(this.filterCloud);
            }
            if (this.filterSatellites.S2) {
                if (this.filterSentinel2_level == Sentinel2Level.L2) sats.push(SatEnum2.S2L2A);
                if (this.filterSentinel2_level == Sentinel2Level.L1) sats.push(SatEnum2.S2L1C);
            }
            if (this.filterSatellites.L8) sats.push(SatEnum2.LS8, SatEnum2.LS9);
            if (this.filterSatellites.L45) sats.push(SatEnum2.LS45);
            if (this.filterSatellites.L7E) sats.push(SatEnum2.LS7);

            let ms = store.map;
            let searchGeom: string = JSON.stringify(ms.searchObject.searchGeometry);
            filter.area = searchGeom;
        }
        if (sats.length != Utils.objectPropertiesCount(satDic)){
            filter.scene_type = sats.join(",");
        }


        return r;
    }

    public static canUseFilterSeasonDate(beg: Date, end: Date): boolean{
        if (beg == null || end == null) return false;
        if (end < beg) return false;
        return SearchStore.getSeasonPeriods(beg, end).length > 1;
    }

    private static getSeasonPeriods(beg: Date, end: Date): [Date, Date][]{
        let r: [Date, Date][] = [];
        let diff = (end.getTime() - beg.getTime()) / (1000*60*60*24);
        if (diff <= 365) return [[new Date(beg), new Date(end)]];
        let curYear1 = beg.getFullYear();
        let curYear2 = beg.getFullYear();

        if (beg.getTime() > (new Date(beg.getFullYear(), end.getMonth(), end.getDate())).getTime()){//перехлёст через год
            curYear2++;
        }
        while (true){
            let d1 = new Date(curYear1, beg.getMonth(), beg.getDate());
            let d2 = new Date(curYear2, end.getMonth(), end.getDate());
            if (d1.getTime() > end.getTime()) break;
            if (d2.getTime() > end.getTime()) d2 = new Date(end);
            r.push([d1, d2]);
            curYear1++;
            curYear2++;
        }
        return r;
    }

    private static searchParamsToFilter(sp: ISearchUrlParams): any{
        let arrAnd: any[] = [];
        let spFilter = sp.filter;
        if (spFilter.scene_type) arrAnd.push({"scene_type": {$in: spFilter.scene_type.split(",")}});

        if (spFilter.mode != null){
            arrAnd.push({"mode": spFilter.mode});
        }
        if (spFilter.rel_orbit != null && spFilter.rel_orbit.length > 0){
            arrAnd.push({"rel_orbit": {"$in": spFilter.rel_orbit}});
        }
        if (spFilter.seasonDate){
            let dd = SearchStore.getSeasonPeriods(new Date(spFilter.beg), new Date(spFilter.end));
            let add: any[] = [];
            dd.forEach(a => {
                add.push({"acqdate":{"$>=": Utils.getDateStr(a[0]), "$<=": Utils.getDateStr(a[1])}});
            })
            arrAnd.push({"$or":add});
        }else{
            if (spFilter.beg || spFilter.end) {
                let t: {acqdate:any} = {"acqdate":{}};
                if (spFilter.beg) t.acqdate["$>="] = spFilter.beg;
                if (spFilter.end) t.acqdate["$<="] = spFilter.end;
                arrAnd.push(t);
            }
        }
        if (spFilter.cloud != null){
            arrAnd.push({cloud : {"$<=": spFilter.cloud}});
        }
        if (spFilter.area){
            arrAnd.push({border : {"$intersects": {"$geometry": JSON.parse(spFilter.area)}}});
        }
        return {"$and":arrAnd};
    }
    private static searchParamsToOther(sp: ISearchUrlParams): any{
        let r = cloneDeep(sp);
        delete r.filter;
        return r;
    }
@action
private async doSearch(sp: ISearchUrlParams): Promise<void> {
    let store = this.root;
    this.searchParams = sp;

    // Переводим в режим загрузки
    let spCount = cloneDeep(this.searchParams);
    spCount.mode = "count";
    this.searchResult.searchTotalState = LoadStatus.loading;
    let p: any = SearchStore.searchParamsToOther(spCount);
    p.filter = SearchStore.searchParamsToFilter(spCount);
    let url = "/api/satmeta";
    try {
        let value = await fetchJson(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: Utils.queryEncodeParams(p)
        });
        if (isEqual(sp, this.searchParams)) {
            let v = value as ISearchCount;
            this.searchResult.setCountResult(v.count, v.date_count);
        }
        this.toPage();
    } catch (reason) {
        if (isEqual(sp, this.searchParams)) {
            store.addError(Utils.getErrorString(reason) + " " + url);
        }
    }
}

@action
async toPage() {
    let store = this.root;
    let sp = cloneDeep(this.searchParams);
    let spPage = cloneDeep(this.searchParams);
    spPage.mode = "page";
    spPage.limit = store.map.searchObject.isEmpty ? SearchStore.maxSearchItems : 1000;
    spPage.offset = 0;
    this.searchResult.searchState = LoadStatus.loading;
    
    let p: any = SearchStore.searchParamsToOther(spPage);
    p.filter = SearchStore.searchParamsToFilter(spPage);

    let url = "/api/satmeta";
    try {
        let value = await fetchJson(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: Utils.queryEncodeParams(p)
        });


        if (isEqual(sp, this.searchParams)) {

            let v = value as FeatureCollection<Geometry, ISearchPropertiesRest>;
             this.setPageResult(v);
            await Utils.pauseAsync(200); // для эмуляции задержки
            SearchSceneListComp.scrollToCurrent(this.root);
            this.isDateChangedByUser = false;
        }
    } catch (reason) {
        if (isEqual(sp, this.searchParams)) {
            store.addError(Utils.getErrorString(reason) + " " + url);
        }
    }
}

@action setPageResult(value:  FeatureCollection<Geometry, ISearchPropertiesRest>){
    let root = this.root;
    let arrSI = value.features.map( a =>
        {
            let k = new SearchItemStore(this);
            k.feature = a;
            return k;
        });
  // let newList = new ListSceneStore(this);
  //  newList.syncAddSceneItem(arrSI);
    this.searchResult.syncAddSceneItem(arrSI);
    this.searchResult.searchState = LoadStatus.ready;
}



    getTopCurrentScene(p: LngLat): string{
        let sceneId: string = null;
        let curScenes: SearchItemStore[] = [];
        let grp = this.searchResult.groups.find(g => g.isCurrent());
        if (grp == null) return null;
        curScenes = grp.items;

        curScenes.forEach(fs =>{
            if (sceneId == null && booleanPointInPolygon(MBUtils.llToPosition(p), fs.feature  as Feature<Polygon>)){
                sceneId = fs.sceneId();
            }
        });
        return sceneId;
    }
    getTopFavScene(p: LngLat): string{
        let sceneId: string = null;
        let favScenes = this.favoriteList.getAllSceneItems();
        let t = favScenes.filter(a => a.selected);
        t.forEach(fs => {
            if (sceneId == null && booleanPointInPolygon(MBUtils.llToPosition(p), fs.feature  as Feature<Polygon>)){
                sceneId = fs.sceneId();
            }
        });
        return sceneId;
    }
    getTopVisibleScene(p: LngLat): {sceneId: string, sceneUiType: SceneUiType}{
        let allScenes = this.searchResult.getAllSceneItems();

        let sceneId: string = null;
        let sceneUiType: SceneUiType = null;

        if (this.root.searchPanel.currentSceneVisible) {
            sceneId = this.getTopCurrentScene(p);
            sceneUiType = SceneUiType.current;
        }
        if (sceneId == null && this.root.map.mapScenesStore.favoriteScenesVisible){
            sceneId = this.getTopFavScene(p);
            sceneUiType = SceneUiType.favorite;
        }

        return {sceneId, sceneUiType};
    }
}

export interface ISearchProductsS5DayInfo{
    date: string, products: string[]
}
export interface ISearchProductsS5Info{
    date_products: ISearchProductsS5DayInfo[];
}
export enum SceneUiType{
    current='current',
    favorite='favorite'
}

export interface ISearchPropertiesRest{
    acqdate?: string,//"2020-05-14"
    acqtime?: string,//"05:26:51"
    bl_x?: number,
    bl_y?: number,
    br_x?: number,
    br_y?: number,
    cloud?: string,//1
    gridcode: string,//"43QCV"
    level?: string,//"L2A"
    product_id?: string,//"a69769dc-1bdf-4680-b3db-1970ede73062"
    //для S5 composite
    s5_date_begin?: number,
    s5_date_end?: number,
    composite_method?: string,

    //для s1
    mode?: Sentinel1Mode, //"IW",
    polar?: string, //"DV",
    rel_orbit?: number,//145
    ql_exists?: boolean,//true

    tiles_exists?: boolean,
    quicklook?: string,//"https://storage.yandexcloud.net/satellite//s2/ql/2020/2020-05-14/S2A_L1C_2020-05-14_083730_43QCV-ql.jpg"
    //sat: string,//"S2A"
    sat_id?: string,//"S2A"
    satellite?: Satellite,
    scene_id?: string,//"S2A_L2A_2020-05-14_094058_43QCV"
    scene_type?: SatEnum2,//"S5L2A"
    source?: string,
    sunelev?: number,//67
    tl_x?: number,//73.1101854642
    tl_y?: number,//18.0798160938
    tr_x?: number,//74.1475579665
    tr_y?: number,//18.0871874792
    version?: number,    

}

// export function dayGroupId(props: ISearchPropertiesRest, copyId: string) {
//     return props.scene_type + "_" + props.acqdate + (copyId? `#${copyId}` : '');
// }

export interface ISearchUrlFilter{//данные для фильтра
    mode?: string,
    rel_orbit?: number[],
    beg?: string,//min acquisition date
    end?: string,//max acquisition date
    area?: string,//WKT
    cloud?: number,//image <= cloudiness percent
    scene_type?: string,
    seasonDate?: boolean,
}
export interface ISearchUrlParams{//данные для параметров
    mode?: "page" | "count" | "s5composite";
    product?: string,
    tiles?: number,
    scene_source?: "s1",
    level?: string,//Available values : L1, L2
    offset?: number,//return <= maxqnt images
    limit?: number,//return <= maxqnt images
    filter?: ISearchUrlFilter;
}


export interface ISearchCount {
    count: number;
    date_count: number;
}


import {MapComp} from "../components/panels/Map/MapComp";
import {WindowTriState} from "../helper/structs/WindowTriState";
import * as mapboxgl from "maplibre-gl";
import {SearchSceneListComp} from "../components/panels/Left/SearchPanel/SearchSceneListComp";import { SearchItemGroup } from "./SearchItemGroup";
import { MeasuringStatus } from "../helper/structs/MeasuringStatus";
import { ActiveProductCodeStore } from './productSetting/ActiveProductCodeStore';
import { FavoritesListSceneStore } from './FavoritesListSceneStore';

