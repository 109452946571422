import * as React from 'react';
import {IIconProp} from "../IIconProp";

export class StarFullMinusSvg extends React.PureComponent<IIconProp, undefined> {

    render() {
        return <svg className={this.props.className??""} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M 16 12 L 10 12 L 10 14 L 16 14 L 16 12 Z" fill="#F05E5E"/>
            <path d="M 8 13.513 L 8.001 8.018 L 14.821 8 L 15.699 7.145 C 16.293 6.565 15.962 5.558 15.144 5.439 L 10.828 4.812 L 8.898 0.9 C 8.729 0.559 8.365 0.388 8.001 0.388 C 7.637 0.388 7.273 0.559 7.104 0.9 L 5.174 4.811 L 0.857 5.438 C 0.039 5.557 -0.291 6.565 0.302 7.145 L 3.426 10.189 L 2.689 14.488 C 2.579 15.132 3.093 15.659 3.676 15.659 C 3.829 15.659 3.987 15.623 4.14 15.543 L 8 13.513 Z" fill="#F05E5E"/>
        </svg>;
        /*
        return <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" className={c}>
            <g fill="#F05E5E">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8271 4.81118l4.317.628.002.001a.99973.99973 0 01.8064.68047.9993.9993 0 01.0199.54827.99992.99992 0 01-.2723.47626L14.8222 8H8v5.5132l-3.86292 2.031a1.00063 1.00063 0 01-.53739.1123 1.00023 1.00023 0 01-.51568-.1884.99956.99956 0 01-.33855-.4321 1.00019 1.00019 0 01-.05938-.5458l.737-4.3-3.123-3.04502a1.00001 1.00001 0 01.556-1.706l4.317-.627 1.927-3.912a1.04104 1.04104 0 01.9-.51786 1.04098 1.04098 0 01.9.51786l1.92702 3.911z"/>
                <path d="M10 11h6v2h-6z"/>
            </g>
        </svg>;*/
    }
}
