import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './A2UploadFormComp.scss';
import {A2UploadFormItemColumn} from "./A2UploadFormItemColumn";
import {LoadingAniSvg} from "../../../../icons/LoadingAniSvg";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import classNames from "classnames";
import {LeftPanelMode} from "../../../../../store/SearchStore";
import {action} from "mobx";
import {A2UploadFormItemStatic} from "./A2UploadFormItemStatic";
import {A2FieldEditorALLInputComp} from "../A2FieldEdit/A2FieldEditorALLInputComp";
import {MyHint} from "../../../../Common/MyHint";
import {BackCircle} from "../A2FieldEdit/BackCircle";
import {ra} from "../../../../../helper/utils/mobxUtils";

@observer
export class A2UploadFormComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    submitEnable(): boolean{
        let frm = this.props.store.agro2.uploadFieldsForm;
        return frm.loadColumnStatus == LoadStatus.ready && frm.file_id != null;
    }
    @action
    async onClickSubmit(){
        if (!this.submitEnable()) return;
        try {
            this.props.store.agro2.uploadFieldsForm.check_properties = true;
            this.props.store.agro2.uploadFieldsForm.fieldsForm.checkFieldsValid();
            await this.props.store.agro2.uploadFieldsForm.loadFileStep2();
            ra(async ()=>{
                this.props.store.addInfo(this.props.store.trans["Saved successfully"]);
                await this.props.store.agro2.incVersionFieldTiles();
                this.props.store.searchPanel.switchPanel(LeftPanelMode.agro);
                this.props.store.map.zoomFlyToBBox(this.props.store.agro2.uploadFieldsForm.bbox);
            });
        }catch (err){
            this.props.store.addError(err);
        }
    }
    @action
    onCancel(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.agro);
    }

    render() {
        let store = this.props.store;
        let frm = store.agro2.uploadFieldsForm;
        let columnItems: any[] = [];
        store.agro2.uploadFieldsForm.columns.forEach((a, index)=>{
            columnItems.push(<A2UploadFormItemColumn item={a} store={store} key={index} />);
        });
        let staticItems: any[] = [];
        let constCols =  store.agro2.projectInfo?.projectInfoJson?.type_info?.type_schema?.obj?.file_import?.const_value_columns;
        if (constCols){
            constCols.forEach(col => {
                let c = store.agro2.projectStruct.getColumnByName(col.name);
                if (c != null) {
                    let vt = frm.fieldsForm.notValidProperties[c.column_name];
                    staticItems.push(<A2UploadFormItemStatic store={store} columnTitle={c.title}
                                                             isRequire={c.required}
                                                             key={col.name}>
                        <A2FieldEditorALLInputComp hasDiffValue={frm.fieldsForm.hasDiffValue.has(c.column_name)}
                                                   isMultiEdit={false}
                                                   columnInfo={c} field={frm.fieldsForm} store={store} validType={vt}/>
                    </A2UploadFormItemStatic>);
                }
            });
        }

        return <div className="A2UploadFormComp-main Agro2Common-topLeftBorder">
            <div className="A2UploadFormComp-header Agro2Common-lr_margin">
                <BackCircle className="A2UploadFormComp-header-back" onClick={this.onCancel} />
                {store.trans["Loading objects from a file"]}
                <MyHint placement="right"
                    text={store.trans['Select the attributes of your table from the uploaded file that you want to import and match them to the characteristics of your objects']}>
                    <svg width="18" height="18" viewBox="0 0 18 18" className="A2UploadFormComp-header-i" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 15.75C5.2875 15.75 2.25 12.7125 2.25 9C2.25 5.2875 5.2875 2.25 9 2.25C12.7125 2.25 15.75 5.2875 15.75 9C15.75 12.7125 12.7125 15.75 9 15.75Z" fill="#4DB6BC"/>
                        <path d="M10.125 7.875H7.875V13.5H10.125V7.875Z" fill="#4DB6BC"/>
                        <path d="M9 6.75C9.62132 6.75 10.125 6.24632 10.125 5.625C10.125 5.00368 9.62132 4.5 9 4.5C8.37868 4.5 7.875 5.00368 7.875 5.625C7.875 6.24632 8.37868 6.75 9 6.75Z" fill="#4DB6BC"/>
                    </svg>
                </MyHint>
            </div>
            <div className="A2UploadFormComp-header-file">
                <div>{frm.filename}</div>
                <div className="A2UploadFormComp-header-fileObj">Объектов: {frm.featuresCount}</div>
            </div>
            {frm.loadColumnStatus == LoadStatus.loading && <LoadingAniSvg />}
            <div className="Agro2Common-Form-body Agro2Common-lr_margin style-4 overflowAutoY">
                <table className="width100">
                    <colgroup>
                        <col/>
                        <col style={{width:"233px"}}/>
                    </colgroup>
                    <tbody>
                    {staticItems}
                    </tbody>
                </table>

                <table className="width100">
                    <colgroup>
                        <col/>
                        <col style={{width:"233px"}}/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <td className="A2UploadFormComp-tableHeader">Атрибуты файла</td>
                        <td className="A2UploadFormComp-tableHeader">Характеристики</td>
                    </tr>
                    {columnItems}
                    </tbody>
                </table>
            </div>
            <div className="Agro2Common-Form-footer Agro2Common-lr_margin">
                <button className="left-sidebar-cancel button-jump Agro2Common-Form-footerButton" onClick={this.onCancel}>{store.trans.Cancel}</button>
                <button onClick={this.onClickSubmit} className={classNames("left-sidebar-active button-jump Agro2Common-Form-footerButton",
                    {"opacity50": !this.submitEnable()})}>{store.trans.Upload}</button>
            </div>
        </div>;
    }
}
