import React, { Fragment } from "react";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import { EyeSvg } from "../../../icons/MiniIcon/EyeSvg";
import { ColorPicker } from "./ColorPicker";
import { ContextMenuCommonComp, PopupCorner, PopupDirection } from "../../../Common/ContextMenuCommonComp";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { Visibility } from "maplibre-gl";
import { CancelEyeSvg } from "../../../icons/MiniIcon/CancelEyeSvg";
import tinycolor from "tinycolor2";
import { RadiusSvg } from "../../../icons/MiniIcon/RadiusSvg";
import classNames from "classnames";

export interface ICircleStyleEditorCompProps extends IStoreProps {
    showRadius: boolean;
}

@observer
export class CircleStyleEditorComp extends React.Component<ICircleStyleEditorCompProps> {
    @observable
    state = {
        color: this.props.store.layerListStore.stylePaintProperties.fillColor,
        opacity: Math.round(100 * this.props.store.layerListStore.stylePaintProperties.fillOpacity) + '%',
        radius: this.props.store.layerListStore.stylePaintProperties.circleRadius
    }

    constructor(props: ICircleStyleEditorCompProps) {
        super(props);
        autoBindReact(this);        
    }

    @action
    onFillColorChange(color: string) {
        let lls = this.props.store.layerListStore;
        lls.stylePaintProperties = {...lls.stylePaintProperties, fillColor: color};
        this.setState({color: color});
    }

    @action
    onFillOpacityChange(opacity: number) {
        let lls = this.props.store.layerListStore;
        lls.stylePaintProperties = {...lls.stylePaintProperties, fillOpacity: opacity};
        this.setState({opacity: Math.round(100 * opacity) + '%'});
    }

    @action
    onFillVisibilityChange() {
        let lls = this.props.store.layerListStore;
        let vis : Visibility = lls.stylePaintProperties.fillVisibility == 'visible'? 'none' : 'visible';
        lls.stylePaintProperties = {...lls.stylePaintProperties, fillVisibility: vis}
    }

    @action
    onOpenColorPicker () {
        this.props.store.layerListStore.fillPickerOpened = true;
    }

    @action
    onCloseColorPicker(e: any) {
        this.props.store.layerListStore.fillPickerOpened = false;
        e.stopPropagation();
    }

    @action
    onHexChanging(e: any) {
        this.setState({color: e.currentTarget.value});
    }

    @action
    onHexChange(e: any) {
        let hex = e.currentTarget.value;
        let color = tinycolor(hex);
        if (color.isValid())
            this.onFillColorChange(color.toHexString());
        else
            this.setState({color: this.props.store.layerListStore.stylePaintProperties.fillColor});
    }

    @action
    onHexKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        let color = tinycolor(this.state.color);
        if (color.isValid())
            this.onFillColorChange(color.toHexString());
        else
            this.setState({color: this.props.store.layerListStore.stylePaintProperties.fillColor});
    }

    @action
    onOpacityChanging(e: any) {
        this.setState({opacity: e.currentTarget.value});
    }

    @action
    onOpacityChange(e: any) {
        let op = e.currentTarget.value;
        op = op.replaceAll('%', '');
        if (! isNaN(op) && op > 0 && op <= 100)
            this.onFillOpacityChange(op / 100);
        else
            this.setState({opacity: Math.round(100 * this.props.store.layerListStore.stylePaintProperties.fillOpacity) + '%'});
    }

    @action
    onOpacityKeyPressed(e: any) {
        if(e.keyCode != 13) return;        
        let op = (this.state.opacity as any).replaceAll('%', '');
        if (! isNaN(op) && op > 0 && op <= 100)
            this.onFillOpacityChange(op / 100);
        else
            this.setState({opacity: Math.round(100 * this.props.store.layerListStore.stylePaintProperties.fillOpacity) + '%'});
    }

    @action
    onRadiusChanging(e: any) {
        this.setState({radius: e.currentTarget.value});
    }

    @action
    onRadiusChange(e: any) {
        let r = e.currentTarget.value;
        let lls = this.props.store.layerListStore;
        if (! isNaN(r) && Number(r) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, circleRadius: Number(r)}
        else
            this.setState({radius: lls.stylePaintProperties.circleRadius});
    }

    @action
    onRadiusKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        let lls = this.props.store.layerListStore;
        let r = this.state.radius;
        if (! isNaN(r) && Number(r) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, circleRadius: Number(r)}
        else
            this.setState({radius: lls.stylePaintProperties.circleRadius});
    }

    render() {
        let lls = this.props.store.layerListStore;
        return <Fragment>
            <div className="LayerStyleComp-color-container">
                <div className="LayerStyleComp-color-button-container pointer" onClick={this.onOpenColorPicker}>
                    <div className="LayerStyleComp-color-button" style={{backgroundColor: lls.stylePaintProperties.fillColor}}></div>
                    {this.props.store.layerListStore.fillPickerOpened &&
                        <ContextMenuCommonComp onClose={this.onCloseColorPicker}
                            direction={PopupDirection.horizontal}
                            popupCorner={PopupCorner.rightBottom}>
                        <ColorPicker color={this.props.store.layerListStore.stylePaintProperties.fillColor}
                            setColor={(color) => this.onFillColorChange(color)}
                            opacity={this.props.store.layerListStore.stylePaintProperties.fillOpacity}
                            setOpacity={(op) => this.onFillOpacityChange(op)}
                            onChange={this.onFillColorChange} onClose={this.onCloseColorPicker}/>
                        </ContextMenuCommonComp>
                    }
                </div>
                <div className="LayerStyleComp-color-editors">
                <input type='text' className='LayerStyleComp-color-code LayerStyleComp-color-caption'
                            value={this.state.color.toUpperCase()} 
                            onChange={this.onHexChanging} onBlur={this.onHexChange}
                            onKeyDown={this.onHexKeyPressed}/>
                        <input type='text' className='LayerStyleComp-color-opacity LayerStyleComp-color-caption'
                            value={this.state.opacity} 
                            maxLength={4} pattern="[0-9]{1,3}"
                            onChange={this.onOpacityChanging} onBlur={this.onOpacityChange}
                            onKeyDown={this.onOpacityKeyPressed}/>
                </div>
                <div className="LayerStyleComp-color-eye-container" onClick={this.onFillVisibilityChange}>
                    {lls.stylePaintProperties.fillVisibility == 'visible' && <EyeSvg/> }
                    {lls.stylePaintProperties.fillVisibility == 'none' && <CancelEyeSvg/> }
                </div>
            </div>
            {this.props.showRadius && <div className="LayerStyleComp-radius-container">
                <div className="LayerStyleComp-color-button-container">
                    <RadiusSvg/>
                </div>
                <div className="LayerStyleComp-color-editors">
                    <input type='text' className='border-width-editor border-width-editor-single'
                                maxLength={4} pattern="[0-9]{1,3}"
                                value={this.state.radius}
                                onChange={this.onRadiusChanging} onBlur={this.onRadiusChange}
                                onKeyDown={this.onRadiusKeyPressed}
                                />
                </div>
            </div>}
        </Fragment>;
    }
}