// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroProjectNewUserComp-form {
  max-width: 345px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: #C5C5C5;
  top: 30px;
  bottom: auto;
}

.AgroProjectNewUserComp-title {
  margin: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
}

.AgroProjectNewUserComp-subtitle {
  margin: 5px 20px 8px 20px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.4;
}

.AgroProjectNewUserComp-input {
  margin: 0 20px 40px 20px;
  font-size: 12px;
}

.AgroProjectNewUserComp-footer {
  margin: 20px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/ProjectEditor/AgroProjectNewUserComp.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,SAAA;EACA,YAAA;AACJ;;AACA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AAEF;;AAAA;EACE,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AAGF;;AADA;EACE,wBAAA;EACA,eAAA;AAIF;;AAFA;EACE,YAAA;EACA,aAAA;AAKF","sourcesContent":[".AgroProjectNewUserComp-form{\r\n    max-width: 345px;\r\n    margin: auto;\r\n    display: flex;\r\n    flex-direction: column;\r\n    color: #C5C5C5;\r\n    top: 30px;\r\n    bottom: auto;\r\n}\r\n.AgroProjectNewUserComp-title{\r\n  margin: 20px;\r\n  font-weight: 700;\r\n  font-size: 16px;\r\n  line-height: 1.4;\r\n}\r\n.AgroProjectNewUserComp-subtitle{\r\n  margin: 5px 20px 8px 20px;\r\n  font-weight: 400;\r\n  font-size: 10px;\r\n  line-height: 1.4;\r\n}\r\n.AgroProjectNewUserComp-input{\r\n  margin: 0 20px 40px 20px;\r\n  font-size: 12px;\r\n}\r\n.AgroProjectNewUserComp-footer{\r\n  margin: 20px;\r\n  display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
