import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDelIndicatorsPanelComp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";

@observer
export class AgroAhoDelIndicatorsPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        if (!cstore.cardIndicators.length) return;

        cstore.deleteIndicators(cstore.card.map_id);
        // store.deleteCards();
        // store.deleteIndicator(store.card.map_id, store.removableIndicator);
        store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        // let selected = store_.cardsStore.getSelectedCards().length;

        return <div className="AgroAhoDelIndicatorsPanelComp-main">
            <div className="AgroAhoDelIndicatorsPanelComp-caption">{store.trans.Delete}</div>
            <div className="AgroAhoDelIndicatorsPanelComp-msg">{store.trans['Attention! The selected objects will be permanently deleted with all information attached']}</div>
            <div className="AgroAhoDelIndicatorsPanelComp-stat">
                <span>{store.trans['Indicators:']}&nbsp;</span>
                <span>{cstore.cardIndicators.length}</span>
            </div>
            <div className="AgroAhoDelIndicatorsPanelComp-line"> </div>
            <div className="AgroAhoDelIndicatorsPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoDelIndicatorsPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className={classNames( "large-button left-sidebar-red",
                    {"AgroAhoDelIndicatorsPanelComp-disabled": !cstore.cardIndicators.length},
                    {"button-jump": cstore.cardIndicators.length})}
                    onClick={this.onClickDelete}>{store.trans.Delete}</button>
            </div>
        </div>;
    }
}
