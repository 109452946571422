import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {FarmItemStore} from "./FarmItemStore";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {ra, rai} from "../../helper/utils/mobxUtils";
import {FeatureCollection, Geometry} from "geojson";
import {IResponseFarmProperties} from "./AgroStore";

export class AgroAllFarms extends CustomStore{
    @observable
    private _allFarms: FarmItemStore[] = [];
    @observable
    private _cacheAllFarms: Map<number, FarmItemStore> = new Map<number, FarmItemStore>();

    @observable
    public allFarmsLoadStatus: LoadStatus = null;

    reset(){
        this.allFarmsLoadStatus = null;
        this._allFarms = [];
        this._cacheAllFarms.clear();
    }

    getFarmItemByFarmId(farmId: number): FarmItemStore{
        if (this.allFarmsLoadStatus == null) {
            rai(()=>this.loadAllFarms());
            return null;
        }
        if (this._cacheAllFarms.has(farmId)) return this._cacheAllFarms.get(farmId);
        let r = this._allFarms.find(a => a.farm_id == farmId);
        rai(()=>{
            this._cacheAllFarms.set(farmId, r);
        });
        return r;
    }

    loadAllFarms(){
        this.allFarmsLoadStatus = LoadStatus.loading;
        fetchJsonGet(`/api/agro/projects/${this.root.agro.projectName}/farm/select`
        ).then(json =>{
            ra(()=>{
                let f: FeatureCollection<Geometry, IResponseFarmProperties> = json as FeatureCollection<Geometry, IResponseFarmProperties>;
                this._allFarms = [];
                this._cacheAllFarms.clear();
                this.allFarmsLoadStatus = LoadStatus.ready;
                f.features.forEach(a => {
                    let t = new FarmItemStore(this);
                    t.farm_id = a.properties.farm_id;
                    t.farm_name = a.properties.farm_name;
                    if (t.farm_name == null) t.farm_name = "";
                    t.region_id = a.properties.region_id;
                    t.entity = a.properties.entity;
                    t.field_area_sys = a.properties.field_area_sys;
                    t.photo_cnt = a.properties.photo_cnt;

                    t.field_cnt = a.properties.field_cnt;
                    t.contract_year = a.properties.contract_year;
                    this._allFarms.push(t);
                });
            });
        });

    }

}