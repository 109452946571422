// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioButton-main {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.RadioButton-main-disabled {
  opacity: 0.3;
}

.RadioButton-div {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  border: 2px solid #454C52;
  position: relative;
}

.RadioButton-div.RadioButton-checked {
  border: 2px solid #4DB6BC;
}

.RadioButton-div.RadioButton-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 2px;
  content: "";
  border-radius: 8px;
  background-color: #4DB6BC;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/RadioButton.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;AAGF;;AADA;EACE,yBAAA;AAIF;;AAFA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;AAKF","sourcesContent":[".RadioButton-main{\r\n  display: flex;\r\n  align-items: center;\r\n  cursor: pointer;\r\n}\r\n.RadioButton-main-disabled{\r\n  opacity: 0.3;\r\n}\r\n.RadioButton-div{\r\n  width: 18px;\r\n  height: 18px;\r\n  border-radius: 18px;\r\n  border: 2px solid #454C52;\r\n  position: relative;\r\n}\r\n.RadioButton-div.RadioButton-checked{\r\n  border: 2px solid #4DB6BC;\r\n}\r\n.RadioButton-div.RadioButton-checked:after{\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  margin: 2px;\r\n  content: \"\";\r\n  border-radius: 8px;\r\n  background-color: #4DB6BC;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
