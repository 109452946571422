import { SuperStore } from "../../store/SuperStore";

export function ErrorCodeToString(store: SuperStore, code: number): string{
    switch (code){
        case 1:
            return store.trans["User doesn't exist"];
        case 2:
            return store.trans["User isn't valid"];
        case 3:
            return store.trans["Project doesn't exist or was deleted"];
        case 4:
            return store.trans["You don't have access to perform this operation"];
    }
}