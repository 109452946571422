import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import "./AgroFarms.scss";
import {AgroProjectListComp} from "./AgroProjectListComp";
import {A2FolderListComp} from "../A2Folder/A2FolderListComp";
import {A2NavigatorComp} from "../A2Navigator/A2NavigatorComp";
import {A2NavigatorSection} from "../../../../../store/agro/A2AgroStore";
import {A2FieldListComp} from "../A2FieldList/A2FieldListComp";
import {NavigatorItemStoreType} from "../../../../../store/agro/A2NavigatorItemStore";
import {ElementHolder} from "../../LeftSidebarRight";

export interface IAgroPanelComp extends IStoreProps{
    uploadInput: ElementHolder;
}
@observer
export class AgroPanelComp extends React.Component<IAgroPanelComp, undefined> {
    constructor(props: IAgroPanelComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;


        return <div className="flex-strech-and-cut flex-rows height100">
            <div className="Agro-P_border">
                <A2NavigatorComp store={store} uploadInput={this.props.uploadInput} />
            </div>
            <div className="Agro-II_border flex-strech-and-cut flex-rows">
                {store.agro2.navigatorSection == A2NavigatorSection.projects && <AgroProjectListComp store={store} />}
                {store.agro2.navigatorSection == A2NavigatorSection.folder &&
                    store.agro2.projectStruct.activeNavigatorPage != null &&
                    store.agro2.projectStruct.activeNavigatorPage.type == NavigatorItemStoreType.folder &&
                    <React.Fragment>
                        <A2FolderListComp store={store} />
                    </React.Fragment>
                    }
                {store.agro2.navigatorSection == A2NavigatorSection.folder &&
                    store.agro2.projectStruct.activeNavigatorPage != null &&
                    store.agro2.projectStruct.activeNavigatorPage.type == NavigatorItemStoreType.fields &&
                        <A2FieldListComp store={store} />
                }
            </div>

        </div>;
    }
}
