import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {action, observable} from "mobx";
import classNames from "classnames";
import {observer} from "mobx-react";
import {AdminPanelRowComp} from "./AdminPanelRowComp";
import './AdminPanelComp.scss';
import {AdminPanel_DownFiltersComp} from "./AdminPanel_DownFiltersComp";
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../../Common/ContextMenuCommonComp";
import {AdminPanel_FilterBadgetComp} from "./AdminPanel_FilterBadgetComp";
import {UserListColumns, UserListFilterItems} from "../../../../store/user/UserListStore";
import {SortDirection} from "../../../../helper/utils/Utils";

@observer
export class AdminPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    filtersDropDown: boolean = false;

    @action
    onClose(){
        this.props.store.user.showAdminPanel = false;
    }

    @action
    onClickReload(){
        this.props.store.userList.doLoadList();
    }

    @action
    onChangeFilter(e: any){
        let store = this.props.store;
        let txt = e.currentTarget.value;
        store.userList.filterUserStr = txt;
    }
    @action
    onClearFilter(){
        let store = this.props.store;
        store.userList.filterUserStr = "";
    }
    @action
    onCloseFilterDropDown(){
        this.filtersDropDown = false;
    }
    @action
    onClickFilterDropDown(){
        this.filtersDropDown = true;
    }

    @action
    onDeleteFilterItem(filterItem: UserListFilterItems){
        let store = this.props.store;
        let ul = store.userList;
        ul.filterItems.delete(filterItem);
    }

    getSortIcon(col: UserListColumns): any{
        let title = "";
        let store = this.props.store;
        let ul = store.userList;
        switch (col){
            case UserListColumns.title: title = store.trans["User name"]; break;
            case UserListColumns.email: title = store.trans["Email"]; break;
            case UserListColumns.comment: title = store.trans["Comment"]; break;
            case UserListColumns.enter: title = store.trans["Last enter"]; break;
            case UserListColumns.role: title = store.trans["Role"]; break;
            case UserListColumns.status: title = store.trans["Status"]; break;
            case UserListColumns.subscr: title = store.trans["Subscription"]; break;
        }
        return <div className="flex-columns-centerByVertical link-blueOnHover" onClick={()=>{this.onClickColumn(col)}}>
            {title}
            <div className="flex-full-center text-next">
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={classNames({"hide": ul.sortedColumn != col, "rotate180": ul.sortedDirection == SortDirection.asc})}>
                <path d="M0 7.62939e-05L3.75 4.50008L7.5 7.62939e-05H0Z" fill="#4DB6BC"/>
                </svg>
            </div>
        </div>;
    }
    @action
    onClickColumn(col: UserListColumns){
        let store = this.props.store;
        let ul = store.userList;
        if (ul.sortedColumn == col){
            if (ul.sortedDirection == SortDirection.asc) ul.sortedDirection = SortDirection.desc; else ul.sortedDirection = SortDirection.asc;
        }else{
            ul.sortedColumn = col;
            ul.sortedDirection = SortDirection.asc;
        }
    }

    render() {
        let store = this.props.store;
        let ul = store.userList;
        let showTable = (ul.loaded && ul.users);
        let items: any[] = [];
        if (showTable) {
            ul.filterdUsers.forEach(u => {
                items.push(<AdminPanelRowComp user={u} store={store} key={u.user_id} filterText={ul.filterUserStr} />);
            });
        }
        let filterBadgets: any[] = [];
        Array.from(ul.filterItems.keys()).sort((a,b)=> a - b).forEach(a =>{
            filterBadgets.push(<AdminPanel_FilterBadgetComp store={store} text={store.userList.getTitleFilter(a)} id={a} key={a} onClose={this.onDeleteFilterItem} />);
        });

        return <div className="modal-back">
            <div className="modal-full modal-full-frame modal-with-header admin-window">
            <div className="AdminPanelComp-header">
                <div className="AdminPanelComp-headerTitle">{store.trans["Admin Panel"]}</div>
                <div className="relative">
                    <input type="text" style={{margin: "", height: "30px"}}
                           className={classNames("text-box-editor AdminPanelComp-headerFilterInput")}
                           value={ul.filterUserStr}
                           onChange={this.onChangeFilter}
                           placeholder={store.trans["Search by name, email, comments"]} />
                    <div className={classNames("AdminPanelComp-headerFilterInputClose white_svg blue_svg_onhover", {"opacity30": ul.filterUserStr == ""})} onClick={this.onClearFilter}>
                        <svg width="12" height="12" viewBox="0 0 12 12"  xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z" stroke="none"/>
                        </svg>
                    </div>
                </div>

                <div className="text-next-2 AdminPanelComp-headerFilterButton white_svg blue_svg_onhover blue-border-onhover" onClick={this.onClickFilterDropDown}>
                    <svg width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 0.5V3.5L6.5 7.5V15.5L9.5 13.5V7.5L15.5 3.5V0.5H0.5Z" fill="none"
                              strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {ul.filterItems.size > 0 && <div className="AdminPanelComp-headerFilterButtonCounter">
                        {ul.filterItems.size}
                    </div>}
                    {this.filtersDropDown && <ContextMenuCommonComp  className="" onClose={this.onCloseFilterDropDown} indentHorizontal={5}
                                                                     indentVertical={5} popupCorner={PopupCorner.rightTop} direction={PopupDirection.vertical}>
                        <AdminPanel_DownFiltersComp store={store} />
                    </ContextMenuCommonComp>}
                </div>

                {ul.loading && <div className="header-text text-white2 text-next-2">{store.trans["Loading..."]}</div>}
                {filterBadgets}
                <div className="AdminPanelComp-totalUsers">{store.trans["Users"]}: {ul.filterdUsers.length}</div>
                <div className="modal-button-close" onClick={this.onClose}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z"
                fill="#C5C5C5"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body flex-rows flex-stretch-item">
                {showTable &&
                <React.Fragment>
                    <div className="journal-body-div journal-body-div1 table-scroll style-4 text-white journal-text bold admin-div">
                        <table className="table1-head AdminPanelComp-tableHeader">
                            <colgroup>
                                <col className="admin-table-col-first" />
                                <col className="admin-table-col-title"/>
                                <col className="admin-table-col-email"/>
                                <col className="admin-table-col-comment"/>
                                <col className="admin-table-col-enter"/>
                                <col className="admin-table-col-role"/>
                                <col className="admin-table-col-status"/>
                                <col className="admin-table-col-subscr"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td></td>
                                <td>{this.getSortIcon(UserListColumns.title)}</td>
                                <td>{this.getSortIcon(UserListColumns.email)}</td>
                                <td>{this.getSortIcon(UserListColumns.comment)}</td>
                                <td>{this.getSortIcon(UserListColumns.enter)}</td>
                                <td>{this.getSortIcon(UserListColumns.role)}</td>
                                <td>{this.getSortIcon(UserListColumns.status)}</td>
                                <td>{this.getSortIcon(UserListColumns.subscr)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="journal-body-div journal-body-div2 flex-fit-item table-scroll style-4 text-white journal-text admin-div">
                        <table className="table1-body AdminPanelComp-table">
                            <colgroup>
                                <col className="admin-table-col-first" />
                                <col className="admin-table-col-title"/>
                                <col className="admin-table-col-email"/>
                                <col className="admin-table-col-comment"/>
                                <col className="admin-table-col-enter"/>
                                <col className="admin-table-col-role"/>
                                <col className="admin-table-col-status"/>
                                <col className="admin-table-col-subscr"/>
                            </colgroup>
                            <tbody>
                            {items}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>}
                </div>
            </div>
        </div>;
    }
}