import {ContainerTools, CustomTool} from "../general/ContainerTools";
import mapboxgl, {MapMouseEvent} from "maplibre-gl";
import {action, when} from "mobx";
import {LeftPanelMode} from "../../SearchStore";
import {ra} from "../../../helper/utils/mobxUtils";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {FieldToolController} from "../../agro/fieldForm/FieldToolController";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {Utils} from "../../../helper/utils/Utils";
import {A2SubModeLeftPanel} from "../../agro/A2AgroStore";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class FieldSelectTool extends CustomTool{
    constructor(container: ContainerTools, fieldToolController: FieldToolController, name: string) {
        super(container, name);
        this.fieldToolController = fieldToolController;
    }
    fieldToolController: FieldToolController;

    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent): void {
        //return super.onMouseMove(e);
        if (this.fieldToolController.root.map.measuringStatus != MeasuringStatus.None) return;
        if (e.cursor == null || e.cursor == "default") {
            let field_id = this.fieldToolController.fieldClickTool.clickTo_field_id(e.point);
            if (field_id != null) {
                e.cursor = "pointer";
            }
        }
    }

    onDblclick(e: mapboxgl.MapMouseEvent & ToolEvent) {
        let store = this.fieldToolController.root;
        if (!store.agro2.permission.canEditObject()) return;
        if (store.agro2.subModeLeftPanel == A2SubModeLeftPanel.editOrCreateField) return;

        let field_id = this.fieldToolController.fieldClickTool.clickTo_field_id(e.point);
        if (field_id != null) {// && this.tools.store.agro.region_id != field_id
            ra(() => {
                store.agro2.fieldEditorForm.initEditField(field_id);
                store.agro2.subModeLeftPanel = A2SubModeLeftPanel.editOrCreateField;
                store.agro2.fieldEditorForm.showFieldMultiEditPanel = false;
            });
            e.noPropagation();
        }
    }

    @action
    onMouseClick(e: MapMouseEvent & ToolEvent): void {
        //if (this.state.mousePointMoveFarAfterDown) return ;
        if (!!(e.originalEvent.button & 2)) return;
        if (this.fieldToolController.root.map.measuringStatus != MeasuringStatus.None) return;
        if (e.dragging) return;
        let field_id = this.fieldToolController.fieldClickTool.clickTo_field_id(e.point);
        if (field_id != null) {// && this.tools.store.agro.region_id != field_id
            let isCtrlPressed = e.originalEvent.ctrlKey || e.originalEvent.shiftKey;

            let st = this.fieldToolController.root;
            if (field_id == this.fieldToolController.editable_field_id) return;
            e.isNoPropagation = true;
            (async () => {

                ra(async () => {
                    try {
                        await st.agro2.gotoFieldId(field_id, isCtrlPressed);
                    }catch (e) {
                        ra(()=>{
                            st.addError(e);
                        });
                    }
                });
            })();
            //e.noPropagation();
            return;
        }
    }
}