import {CustomStore} from "../CustomStore";
import {action, observable} from "mobx";
import {ProductSettingStore} from "./ProductSettingStore";
import {ProductSetStore} from "./ProductSetStore";
import {SearchSceneSourceType} from "../SearchStore";
import {save, update} from "../PermalinkDecor";
import {LegendAreaStore} from "./LegendAreaStore";
import {IProductConfig} from "../config/ConfigStore";
import {Sentinel1Mode} from "../SearchParamsS1Store";
import { cloneDeep } from "lodash-es";

export class ProductsStore extends CustomStore{
    class(): string {
        return "ProductsStore";
    }

    @update productsPassive: ProductSetStore = new ProductSetStore(this);
    @update productsS5: ProductSetStore = new ProductSetStore(this);
    @update productsS1_IW: ProductSetStore = new ProductSetStore(this);
    @update productsS1_EW: ProductSetStore = new ProductSetStore(this);
    legendAreaStore: LegendAreaStore = new LegendAreaStore(this);
    @save @observable
    mask_quality: number = 80;
    @save @observable
    opacity: number = 75;

    get sourceType(): SearchSceneSourceType{
        return this.root.searchPanel.sourceType;
    }

    get activeProductsSet(): ProductSetStore{
        if (this.sourceType == SearchSceneSourceType.sentinel5) return this.productsS5;
        if (this.sourceType == SearchSceneSourceType.sentinel1 &&
            this.root.searchPanel.searchParamsS1.mode == Sentinel1Mode.IW) return this.productsS1_IW;
        if (this.sourceType == SearchSceneSourceType.sentinel1 &&
            this.root.searchPanel.searchParamsS1.mode == Sentinel1Mode.EW) return this.productsS1_EW;
        return this.productsPassive;
    }

    @action
    public loadFromConfig(){
        let store = this.root;
        this.loadProducts(this.productsPassive, store.config.products);
        if (store.config.s5_order_active === true) this.loadProducts(this.productsS5, store.config.productsS5);
        if (store.config.s1_order_active === true) {
            this.loadProducts(this.productsS1_IW, store.config.productsS1_IW);
            this.loadProducts(this.productsS1_EW, store.config.productsS1_EW);
        }
    }
    private loadProducts(prod: ProductSetStore, confProd: IProductConfig[]){
        //prod.products = [];
        if (confProd){
            confProd.forEach(a => {
                prod.addProduct(a);
                // let ps = new ProductSettingStore(this);
                // ps.prod_name = a.tile_prod_name;
                // ps.unitName = a.unitName;
                // ps.unitDescription = a.unitDescription;
                // ps.productConfig = a;
                // ps.loadDefault();
                // prod.products.push(ps);
                // prod.activeCodeCurrent.products.push(ps);
                // prod.activeCodeFavorite.products.push(ps);
            });
            prod.activeCodeCurrent.productCode = prod.activeCodeCurrent.products[0].prod_name;
            prod.activeCodeFavorite.productCode = prod.activeCodeFavorite.products[0].prod_name;

            // if (prod.products.length > 0){
            //     prod.activeCodeCurrent.productCode = prod.products[0].prod_name;
            //     prod.activeCodeFavorite.productCode = prod.products[0].prod_name;
            // }//else {
            //    // throw "Products not found";
            // //}

        }
    }
}