import * as React from 'react';
import classNames from "classnames";
import {isFunction} from "lodash-es";
import './AgroAhoStepsComp.scss'


export interface IAgroAhoStepsCompProps {
    step: number;
}

export class AgroAhoStepsComp extends React.PureComponent<IAgroAhoStepsCompProps, undefined> {
    render() {
        let arr = [];
        for (let i = 1;i<6;i++) {
            arr.push(
                <div key={'c'+i} className={classNames("AgroAhoStepsComp-circle",{
                    "AgroAhoStepsComp-active": this.props.step == i,
                    "AgroAhoStepsComp-disabled": this.props.step > i})}>{i}</div>
            );
            if (i < 5) arr.push(<div key={'l'+i} className="AgroAhoStepsComp-line"/>);
        }

        return <div className="AgroAhoStepsComp-main">
            {arr}
        </div>
    }
}
