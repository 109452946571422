import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './A2UploadFormItemStatic.scss';

export interface IA2UploadFormItemStatic extends IStoreProps{
    columnTitle: string;
    isRequire: boolean;
}
@observer
export class A2UploadFormItemStatic extends React.Component<IA2UploadFormItemStatic, undefined> {
    constructor(props: IA2UploadFormItemStatic) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;

        return <tr>
            <td className="A2UploadFormItemStatic-key">{this.props.columnTitle}{this.props.isRequire &&
                <span className="Agro2Common-createFerm-star blue-text">*</span>
            }</td>
            <td className="A2UploadFormItemStatic-value">
                {this.props.children}
            </td>
        </tr>;
    }
}
