import React from "react";
import { IStoreProps } from "../../../../../../helper/structs/IStoreProps";
import { Agro2ToolAuto } from "./Agro2ToolAuto";



const Agro2ToolHelp = (props: IStoreProps) => {
    let auto = props.store.agro2.fieldToolController.autoPolygon;

    const onClose = () => {
        auto.showHelp = false
    }

    return (<div className="modal-back" onClick={onClose}>
        <div className="Agro2ToolAuto-Help-panel">
            <div className="Agro2ToolAuto-Help-header">
                <div className="Agro2ToolAuto-Help-title">Работа с оцифровкой AI</div>
                <div className="Agro2ToolAuto-Help-close" onClick={onClose}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_6549_3790)">
                    <path d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z" fill="#C5C5C5"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_6549_3790">
                    <rect width="12" height="12" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                </div>
            </div>
            <div className="Agro2ToolAuto-Help-body Agro2ToolAuto-Help-text">
                <span>Интерактивное выделение объектов работает с помощью искусственного интеллекта и предназначено для ускорения оцифровки по космическим снимкам. Работает как со спутниковыми подложками, так и по снимкам Sentinel/Landsat.</span>
                <span className="Agro2ToolAuto-Help-instruction">Инструкция</span>
                <div>
                    <div className="Agro2ToolAuto-footerRow margin-bottom15 nowrap">
                        {Agro2ToolAuto.leftClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">— Выделить объект по точке</span>
                        {Agro2ToolAuto.newPointSvg()}
                    </div>
                    <div className="Agro2ToolAuto-footerRow margin-bottom15 nowrap">
                        <span className="Agro2ToolAuto-keySpan">Shift</span>
                        <span className="Agro2ToolAuto-spanNext">+</span>
                        {Agro2ToolAuto.leftClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">— Выделить по нескольким точкам</span>
                        {Agro2ToolAuto.innerPointSvg()}
                    </div>
                    <div className="Agro2ToolAuto-footerRow margin-bottom15 nowrap">
                        <span className="Agro2ToolAuto-keySpan">Shift</span>
                        <span className="Agro2ToolAuto-spanNext">+</span>
                        {Agro2ToolAuto.rightClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">— Добавить точку ВНЕ объекта</span>
                        {Agro2ToolAuto.outerPointSvg()}
                    </div>
                    <div className="Agro2ToolAuto-footerRow margin-bottom15 nowrap">
                        {Agro2ToolAuto.rightClickSvg()}
                        <span className="Agro2ToolAuto-spanNext">—</span>
                        <span>{props.store.trans['Delete blue contour']}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>);

}

export default Agro2ToolHelp;