import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './AgroProjectCreateComp.scss';
import '../A2Common/Agro2Common.scss';
import {action, observable} from "mobx";
import {BackCircle} from "../A2FieldEdit/BackCircle";
import {LeftPanelMode} from "../../../../../store/SearchStore";
import {A2NavigatorSection, A2SubModeLeftPanel} from "../../../../../store/agro/A2AgroStore";
import classNames from "classnames";
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {ProjType} from "../../../../../store/user/UserStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {ra} from "../../../../../helper/utils/mobxUtils";
import {ConfirmModalComp} from "../../../../Common/Forms/ConfirmModalComp";
import {LastStackComp} from "../../../../Common/LastStackComp";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";

export interface IAgroProjectCreateComp extends IStoreProps{
    isCreate: boolean
}

@observer
export class AgroProjectCreateComp extends React.Component<IAgroProjectCreateComp, undefined> {
    constructor(props: IAgroProjectCreateComp) {
        super(props);
        autoBindReact(this);
        setImmediate(()=>{
            ra(()=>{
                if (this.props.store.agro2.projectInfo.projectTypeFilters.length == 1){
                    this.props.store.agro2.projectEditor.projectType = this.props.store.agro2.projectInfo.projectTypeFilters[0];
                }
            });
        });
    }
    @observable
    showConfirmDeleteProject: boolean = false;
    @action
    onClickCloseConfirmDelete(){
        this.showConfirmDeleteProject = false;
    }
    @action
    async onClickDeleteConfirm(){
        this.showConfirmDeleteProject = false;
        await this.sendDelete();
    }
    @action
    onDelete(){
        this.showConfirmDeleteProject = true;
    }
    @action
    async sendDelete(){
        try {
            this.onCancel();
            await this.props.store.agro2.projectEditor.sendDeleteProject(this.props.store.agro2.projectEditor.edit_prj_name);
            ra(()=>{
                this.props.store.addInfo( TranslateUtils.format(this.props.store.trans["Project {0} has been deleted"], this.props.store.agro2.projectEditor.title));
            });
            await this.props.store.agro2.projectList.projectListRequest();
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    onCancel(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.agro);
        this.props.store.agro2.subModeLeftPanel = A2SubModeLeftPanel.tree;
    }

    @action
    onChangeName(e: any){
        let txt = e.currentTarget.value;
        this.props.store.agro2.projectEditor.title = txt;
    }
    @action
    onChangeProjType(item: IDropDownItem, newIndex: number): void{
        this.props.store.agro2.projectEditor.projectType = item.key as any;
    }
    @action
    async onSave(){
        try {
            await this.props.store.agro2.projectEditor.sendUpdateProjectTitle(
                this.props.store.agro2.projectEditor.edit_prj_name,
                this.props.store.agro2.projectEditor.title);
            this.onCancel();
        }catch (e) {
            this.props.store.addError(e);
        }
    }
    @action
    async onCreate(){
        try {
            this.onCancel();
            let p = await this.props.store.agro2.projectEditor.sendCreateProject();
            await this.props.store.agro2.projectList.projectListRequest();
            await this.props.store.agro2.projectList.loadProject(p.proj_name);
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    async onClickSecurityForm(){
        this.props.store.agro2.projectEditor.showSecurityForm = true;
        await this.props.store.agro2.projectEditor.loadUserSecurityCatch();
    }

    render() {
        let store = this.props.store;
        let projEditor = store.agro2.projectEditor;
        let curProjItem: string = null;
        let projTypes = Object.values(ProjType).filter(pr => pr != ProjType.pnt_yld_assess);
        if (store.config.new_project_types != null){
            projTypes = store.config.new_project_types;
        }
        let projItems: IDropDownItem[] = projTypes.map(pt => {
            let t: IDropDownItem = {key: pt,value: TranslateUtils.getProjectTypeTitle(pt, store), data: pt};
            if (projEditor.projectType == pt) curProjItem = pt;
            return t;
        });



        return <div className="AgroProjectCreateComp-main">
            <div className="Agro2Common-Form-div">
                <div className="Agro2Common-Form-body style-4 overflowAutoY">
                    <div className="Agro2Common-Form-header Agro2Common-lr_margin">
                        <BackCircle className="Agro2Common-backCircleRightMarg" onClick={this.onCancel}/>
                        <span className="flex-stretch-item">{store.trans["Create project"]}</span>
                    </div>
                    <div className="AgroProjectCreateComp-lr_margin">
                        <div className="AgroProjectCreateComp-nameLine">
                            <div className="flex-stretch-item">
                                <span>{store.trans["Project name"]}</span>
                                <span className="AgroProjectCreateComp-star blue-text">*</span>
                            </div>
                        </div>
                        <input type="text" style={{margin: ""}}
                               className={classNames("text-box-editor AgroProjectCreateComp-nameInput width100")}
                               value={projEditor.title}
                               onChange={this.onChangeName}
                                />
                    </div>
                    {(store.agro2.projectInfo.projectTypeFilters.length != 1) && this.props.isCreate && <React.Fragment>
                        <div className="AgroProjectCreateComp-lr_margin">
                            <div className="AgroProjectCreateComp-nameLine">
                                <div className="flex-stretch-item">
                                    <span>{store.trans["Project type"]}</span>
                                    <span className="AgroProjectCreateComp-star blue-text">*</span>
                                </div>
                            </div>
                        </div>
                        <div className="AgroProjectCreateComp-lr_margin">
                            <DropDownComp items={projItems} currentKey={curProjItem}
                                          className="width100 DropDowm-DarkBack AgroProjectCreateComp-inputFont" classNameDown="DropDowm-DarkBack AgroProjectCreateComp-inputFont"
                                          onChange={this.onChangeProjType} />
                        </div>
                    </React.Fragment>}
                    {this.showConfirmDeleteProject &&
                    <LastStackComp store={store}>
                        <ConfirmModalComp title={store.trans.Delete}
                                      bottomContent={<React.Fragment>
                                          <button onClick={this.onClickCloseConfirmDelete}
                                                  className="large-button left-sidebar-cancel button-jump text-prev">{store.trans.Cancel}</button>
                                          <button onClick={this.onClickDeleteConfirm}
                                                  className="large-button left-sidebar-red button-jump text-prev">{store.trans.Delete}</button>
                                      </React.Fragment>}
                                                                   bodyText={store.trans["Are you sure you want to delete the project and all attached data?"]} />
                    </LastStackComp>}
                    {!this.props.isCreate && <div className="AgroProjectCreateComp-lr_margin" style={{marginTop: "25px"}} >
                        <div className="flex-columns-centerByVertical flex-justify-end">
                            <div className="pointer"   onClick={this.onClickSecurityForm}>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 6.5C7.15685 6.5 8.5 5.15685 8.5 3.5C8.5 1.84315 7.15685 0.5 5.5 0.5C3.84315 0.5 2.5 1.84315 2.5 3.5C2.5 5.15685 3.84315 6.5 5.5 6.5Z" stroke="#50A9F0" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.5 5.5C13.6046 5.5 14.5 4.60457 14.5 3.5C14.5 2.39543 13.6046 1.5 12.5 1.5C11.3954 1.5 10.5 2.39543 10.5 3.5C10.5 4.60457 11.3954 5.5 12.5 5.5Z" stroke="#50A9F0" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.5 8.5C4.17392 8.5 2.90215 9.02678 1.96447 9.96447C1.02678 10.9021 0.5 12.1739 0.5 13.5H10.5C10.5 12.1739 9.97322 10.9021 9.03553 9.96447C8.09785 9.02678 6.82608 8.5 5.5 8.5V8.5Z" stroke="#50A9F0" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.8409 8C11.3324 7.67303 11.9097 7.49903 12.4999 7.5C13.2956 7.5 14.0587 7.81607 14.6213 8.37868C15.1839 8.94129 15.4999 9.70435 15.4999 10.5V11.5H12.4999" stroke="#50A9F0" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className="light-blue-text text-next">
                                    {store.trans["Change permissions"]}
                                </span>
                            </div>
                        </div>
                    </div>}
                </div>

                {/* футер */}
                <div className="Agro2Common-Form-footer Agro2Common-lr_margin">
                    {!this.props.isCreate && <button className="left-sidebar-red button-jump Agro2Common-Form-footerButton" onClick={this.onDelete}>{store.trans.Delete}</button>}
                    {!this.props.isCreate && <button className="left-sidebar-active button-jump Agro2Common-Form-footerButton" style={{marginRight: "0"}}
                                                     onClick={this.onSave}>{store.trans.Save}</button>}
                    {this.props.isCreate && <button className="left-sidebar-cancel button-jump Agro2Common-Form-footerButton" onClick={this.onCancel}>{store.trans.Cancel}</button>}
                    {this.props.isCreate && <button className="left-sidebar-active button-jump Agro2Common-Form-footerButton" style={{marginRight: "0"}}
                                                     onClick={this.onCreate}>{store.trans.Create}</button>}
                </div>
            </div>

        </div>;
    }
}
