import {ObservableCustomStore} from "../CustomStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {action, autorun, observable} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {fetchJsonPost} from "../../helper/utils/FetchUtils";
import {IndexBandProductStore, Interpolation} from "./IndexBandProductStore";
import {ProductItemIndexBandComp} from "../../components/panels/Left/NdviPanel/ProductItemIndexBandComp";
import {ra} from "../../helper/utils/mobxUtils";
import {save} from "../PermalinkDecor";

export enum LegendAreaMode{
    area= 'area',
    procent='procent'
}
export class LegendAreaStore extends ObservableCustomStore{
    @observable
    statusLoad: LoadStatus = null;
    @observable
    areas: number[] = [];
    @save @observable
    mode: LegendAreaMode = LegendAreaMode.procent;
    maxLenErrorShown: boolean = false;

    subscription():IReactionDisposer[] {
        return [
            autorun(()=>{
                let store = this.root;
                let ok = false;
                if (store.searchPanel.searchResult.currentItems.length > 0){
                    let prod = store.map.productInfo.activeProductsSet.activeCodeCurrent.currentProductStore;
                    if (prod.bandType == "index" ){
                        if (this.maxLenErrorShown && store.map.searchObject.isEmpty)
                            this.maxLenErrorShown = false;
                        let index: IndexBandProductStore = prod.indexBand;
                        if (store.map.searchObject.isNotEmpty && index.interpolation == Interpolation.discrete){
                            ok = true;
                            this.doLoad();
                        }
                    }
                }
                if (!ok){
                    ra(()=>{
                        this.areas = [];
                        this.statusLoad = LoadStatus.ready;
                    });
                }
            })
        ];
    }

    async doLoad(){
        let store = this.root;
        let prod = store.map.productInfo.activeProductsSet.activeCodeCurrent.currentProductStore;

        let index: IndexBandProductStore = prod.indexBand;
        let q: number[] = [];
        //q.push(Utils.parseNumber(index.min));
        index.classValues2.forEach(a => {q.push(a.value)});
        //q.push(Utils.parseNumber(index.max));
        let scenes = ProductItemIndexBandComp.getScenes(store);
        let zone = store.map.searchObject.searchGeometry;

        let p = {vals: q.map(a => a.toString()).join(","), mode: "area", product: prod.prod_name, scene_id: scenes.join(","), zone: JSON.stringify(zone)};

        if (scenes.length == 0){
            ra(()=>{
                this.areas = [];
                this.statusLoad = LoadStatus.ready;
            });
            return;
        }
        ra(()=>{
            this.areas = [];
            this.statusLoad = LoadStatus.loading;
        });

        try {
            let res = await fetchJsonPost('/api/raster/stat/area', p) as {area: number[]};
            ra(()=>{
                this.statusLoad = LoadStatus.ready;
                this.areas = res.area;
            });
        }catch (e) {
            if (e.startsWith('Server error: max_len_km =')) {
                if (this.maxLenErrorShown) return;
                this.maxLenErrorShown = true;
            }
            store.addError(e);
        }
    }

}