import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import './ProjSettingPageGeneral.scss';
import classNames from "classnames";
import {action, observable} from "mobx";
import {observer} from "mobx-react";
import {ra} from "../../../../helper/utils/mobxUtils";

@observer
export class ProjSettingPageGeneral extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    componentDidMount() {
        ra(()=>{
            this.title = this.props.store.agro2.projectEditor.title;
        });
    }

    @action
    onChangeName(e: any){
        let txt = e.currentTarget.value;
        this.title = txt;
    }

    @observable
    isDisabled: boolean = true;
    @observable
    title: string = "";
    @action onClickActive(){
        this.isDisabled = false;
    }
    @action async onClickSave(){
        this.isDisabled = true;
        try {
            await this.props.store.agro2.projectEditor.sendUpdateProjectTitle(
                this.props.store.agro2.projectEditor.edit_prj_name,
                this.title);
            ra(()=>{
                this.props.store.agro2.projectEditor.title = this.title;
            });
        }catch (e) {
            this.props.store.addError(e);
        }
    }
    @action onClickCancel(){
        this.isDisabled = true;
        this.title = this.props.store.agro2.projectEditor.title;
    }

    render() {
        let store = this.props.store;
        let projEditor = store.agro2.projectEditor;
        return <div className="ProjSettingPageGeneral-main">
            <div className={classNames("ProjSettingPageGeneral-lr_margin", {"opacity50": this.isDisabled} )}>
                <div className="ProjSettingPageGeneral-nameLine">
                    <div className="flex-stretch-item">
                        <span>{store.trans["Project name"]}</span>
                    </div>
                </div>
                <input type="text" style={{margin: ""}}
                       className={classNames("text-box-editor ProjSettingPageGeneral-nameInput width100")}
                       value={this.title} readOnly={this.isDisabled}
                       onChange={this.onChangeName}
                />
            </div>
            <div className="ProjSettingPageGeneral-lr_margin flex-columns-centerByVertical" style={{"justifyContent": "flex-end"}}>
                {this.isDisabled && <div className="link-text-underlineOnHover ProjSettingPageGeneral-button" onClick={this.onClickActive}>{store.trans["Change name"]}</div>}
                {!this.isDisabled && <div className="link-text-underlineOnHover ProjSettingPageGeneral-button" onClick={this.onClickCancel}>{store.trans.Cancel}</div>}
                {!this.isDisabled && <div className="link-text-underlineOnHover ProjSettingPageGeneral-button text-next-2" onClick={this.onClickSave}>{store.trans.Save}</div>}
            </div>
        </div>;
    }
}