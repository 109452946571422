import * as React from 'react';
import {CustomSvg} from "../CustomSvg";
import { IIconProp } from '../IIconProp';

export class CancelEyeSvg extends CustomSvg {
    render() {
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()} style={this.getStyles()}>
            <path d="M14.5744 5.66895L13.1504 7.09295C13.4284 7.43995 13.6564 7.75695 13.8194 7.99995C13.0594 9.12995 10.9694 11.8199 8.25836 11.9849L6.44336 13.7999C6.93936 13.9239 7.45736 13.9999 8.00036 13.9999C12.7074 13.9999 15.7444 8.71594 15.8714 8.49194C16.0424 8.18794 16.0434 7.81595 15.8724 7.51195C15.8254 7.42695 15.3724 6.63095 14.5744 5.66895Z" fill="#4DB6BC"/>
            <path d="M0.293375 15.7068C0.488375 15.9018 0.744375 15.9998 1.00038 15.9998C1.25638 15.9998 1.51238 15.9018 1.70738 15.7068L15.7074 1.70676C16.0984 1.31576 16.0984 0.683762 15.7074 0.292762C15.3164 -0.0982383 14.6844 -0.0982383 14.2934 0.292762L11.5484 3.03776C10.5154 2.43076 9.33138 1.99976 8.00037 1.99976C3.24537 1.99976 0.251375 7.28876 0.126375 7.51376C-0.0426249 7.81676 -0.0416249 8.18576 0.128375 8.48876C0.198375 8.61376 1.17238 10.2908 2.82138 11.7648L0.292375 14.2938C-0.0976249 14.6838 -0.0976249 15.3158 0.293375 15.7068ZM2.18138 7.99876C2.95837 6.83476 5.14637 3.99976 8.00037 3.99976C8.74237 3.99976 9.43738 4.20076 10.0784 4.50776L8.51237 6.07376C8.34838 6.02876 8.17838 5.99976 8.00037 5.99976C6.89537 5.99976 6.00038 6.89476 6.00038 7.99976C6.00038 8.17776 6.02938 8.34776 6.07438 8.51176L4.24038 10.3458C3.28538 9.50976 2.55938 8.56176 2.18138 7.99876Z" fill="#4DB6BC"/>
        </svg>      
    }
}


