import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoFilterSvgProps {
    className?: string
}

export class AgroAhoRefreshSvg extends React.PureComponent<IAgroAhoFilterSvgProps, undefined> {
    constructor(props: IAgroAhoFilterSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3v2a5 5 0 0 0-3.54 8.54l-1.41 1.41A7 7 0 0 1 10 3zm4.95 2.05A7 7 0 0 1 10 17v-2a5 5 0 0 0 3.54-8.54l1.41-1.41zM10 20l-4-4 4-4v8zm0-12V0l4 4-4 4z"/>
        </svg>
    }
}