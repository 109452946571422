import {CustomEditGeometryTool} from "./CustomEditGeometryTool";
import {ContainerTools, ContainerToolsState, CustomTool} from "../general/ContainerTools";
import {ViewGeometryStyle} from "./ViewGeometryLayersTool";
import maplibregl from "maplibre-gl";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import {CustomStoreTool} from "../general/CustomStoreTool";

export class RulerMapMoveTool extends CustomStoreTool {

    onZoom() {
        this.store.map.rulerInfo.rulerManager.onZoomOrMove();
    }
    onMove(e: maplibregl.MapMouseEvent & ToolEvent) {
        this.store.map.rulerInfo.rulerManager.onZoomOrMove();
    }
}