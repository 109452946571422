import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {InfoSVG} from "../../../icons/InfoSVG";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {ProductSettingStore} from "../../../../store/productSetting/ProductSettingStore";
import {ProductItemPropComp} from "./ProductItemPropComp";
import {ArrowSvg} from "../../../icons/ArrowSvg";
import {ProductItemIndexBandComp} from "./ProductItemIndexBandComp";
import {ActiveProductCodeStore} from "../../../../store/productSetting/ActiveProductCodeStore";
import { LeftPanelMode, SceneUiType } from '../../../../store/SearchStore';

export interface IProductItemComp extends IStoreProps{
    item: ProductSettingStore,
    productCode: ActiveProductCodeStore
}
@observer
export class ProductItemComp extends React.Component<IProductItemComp, undefined> {
    constructor(props: IProductItemComp) {
        super(props);
        autoBindReact(this);
    }

    @action onClick(e: any){
        let store = this.props.store;
        let item = this.props.item;
        if (this.props.productCode.productCode == item.prod_name){
            if (this.getExpandEnabled()) {
                this.props.item.expand = !this.props.item.expand;
            }
        }else {
            this.props.productCode.setProductCodeWithExpand(this.props.item.prod_name);
        }
        e.stopPropagation();
    }

    @action onClickSetting(e: any){
        if (!this.getExpandEnabled()) return;
        this.props.item.expand = !this.props.item.expand;
        e.stopPropagation();
    }

    getExpandEnabled(): boolean{
        return this.props.item.bandType == "RGB" || this.props.item.bandType == "index";
    }

    render() {
        let store = this.props.store;
        let item = this.props.item;
        let cfg = item.productConfig;
        let cur = this.props.productCode.productCode == item.prod_name;

        let editor: ProductSettingStore = this.props.item.editor;

        return <div className={classNames("popup-ndvi-in__view-row", {"expanded": item.expand})}>
            <div className={classNames("popup-ndvi-in__view-row-top", {"pointer": cur})} onClick={this.onClick}>
                <div className={classNames("popup-ndvi-in__view-row-img-div",{"blue-active": cur})}>
                    <img src={cfg.icon} className="popup-ndvi-in__view-row-img"/>
                </div>
                <div className="popup-ndvi-in__view-row-title flex-fit-item popup-ndvi-in__view-row_border">
                    <div className="popup-ndvi-in__view-row-title-row1">{cfg.full_name}{cur && <InfoSVG className="text-next" />}</div>
                    <div className="popup-ndvi-in__view-row-title-row2">{cfg.formulae}</div>
                </div>
                <div className={classNames("popup-ndvi-in__view-row-i",
                        "full-center-content",
                        "popup-ndvi-in__view-row_end",
                        {"white-fill": this.getExpandEnabled(),
                        "notvisible-important": !this.getExpandEnabled()},
                        {"pointer blue_fill_onhover": this.getExpandEnabled()})}

                     onClick={this.onClickSetting} >
                    <ArrowSvg className={classNames("size12 arrow")} />
                </div>
            </div>
            {item.expand && (item.productConfig.type == "RGB") &&
                <ProductItemPropComp store={store} item={editor} productCode={this.props.productCode} />
            }
            {item.expand && (item.productConfig.type == "index") &&
                <ProductItemIndexBandComp store={store} item={editor} productCode={this.props.productCode}/>
            }
        </div>;
    }
}

