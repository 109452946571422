import {observable} from "mobx";
import {Utils} from "../helper/utils/Utils";
import {IGibsModisOverlayParams, ParameterizedOverlayType} from "./config/ConfigStore";
import {CustomStore} from "./CustomStore";
import { save } from "./PermalinkDecor";

export class GibsOverlayStore extends CustomStore {
    class(): string {return "GibsOverlayStore";}

    @save @observable
    active: boolean = false;

    @save @observable
    gibsLayerId: string = null;

    @save @observable
    date: Date = new Date();

    @save @observable
    currentDate: string = Utils.getDateStr(this.date);

    @observable
    isEditing: boolean = false;

    _params : IGibsModisOverlayParams = null;
    get params() : IGibsModisOverlayParams {
        if (this._params) return this._params;
        let p: IGibsModisOverlayParams = null;
        this.root.config.parameterizedOverlays.forEach(ov => {
            if (ov.id == "gibs_modis") p = ov.params as IGibsModisOverlayParams;
        });
        this._params = p || {layers: [] };
        return this._params;
    }
}