import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import './ShowRoom2Filters.scss';
import {IShowRoom2FilterItem, ShowRoom2FiltersList} from "./ShowRoom2FiltersList";
import {DataShowRoomMode} from "../../../../../store/dataShowroom2/ShowRoom2Store";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../../icons/LoadingAniSvg";
import {ShowRoom2FiltersDistricts} from "./ShowRoom2FiltersDistricts";

@observer
export class ShowRoom2Filters extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClose(){
        let store = this.props.store;
        store.dataShowRoomStore.filterPanelShow = false;
    }

    @action
    onCheckSeasons(item: IShowRoom2FilterItem){
        this.props.store.dataShowRoomStore.filtersMultiproject.seasonsFilters.add(item.id);
    }
    @action
    onUnCheckSeasons(item: IShowRoom2FilterItem){
        this.props.store.dataShowRoomStore.filtersMultiproject.seasonsFilters.delete(item.id);
    }

    @action
    onCheckCrop(item: IShowRoom2FilterItem){
        let ids = (item.id as number[]);
        ids.forEach(a => {
            this.props.store.dataShowRoomStore.filtersMultiproject.cropFilters.add(a);
        });
    }
    @action
    onUnCheckCrop(item: IShowRoom2FilterItem){
        let ids = (item.id as number[]);
        ids.forEach(a => {
            this.props.store.dataShowRoomStore.filtersMultiproject.cropFilters.delete(a);
        });
    }
    @action
    onUnCheckDistrict(item: IShowRoom2FilterItem){
        this.props.store.dataShowRoomStore.filtersProject.filter_districts_ids.delete(item.id);
    }
    @action
    onCheckDistrict(item: IShowRoom2FilterItem){
        this.props.store.dataShowRoomStore.filtersProject.filter_districts_ids.add(item.id);
    }

    render() {
        let store = this.props.store;

        let seasonsList: IShowRoom2FilterItem[] = [];
        store.dataShowRoomStore.seasons.forEach(a =>{
            seasonsList.push({id: a.id, name: a.name, checked: store.dataShowRoomStore.filtersMultiproject.seasonsFilters.has(a.id)});
        });
        let cropList: IShowRoom2FilterItem[] = [];
        store.dataShowRoomStore.cropDataShort.forEach(a =>{
            cropList.push({id: [a.id], name: a.name, checked: store.dataShowRoomStore.filtersMultiproject.cropFilters.has(a.id)});
        });
        let otherIds: number[] = [];
        let otherChecked: boolean = false;
        store.dataShowRoomStore.cropDataAll.forEach(a =>{
            let id = a.id;
            if (!store.dataShowRoomStore.cropDataShort.has(id)){
                otherIds.push(id);
                if (store.dataShowRoomStore.filtersMultiproject.cropFilters.has(id)) otherChecked = true;
            }
        });
        cropList.push({id: otherIds, name: "Other", checked: otherChecked});

        let districtsList: IShowRoom2FilterItem[] = [];
        store.dataShowRoomStore.filtersProject.districts.forEach(a =>{
            let c = store.dataShowRoomStore.filtersProject.filter_districts_ids.has(a.id);
            districtsList.push({id: a.id, name: a.distrname, checked: c});
        });

        //let otherIds =
        //cropList.push({id: a.id, name: a.name, checked: store.dataShowRoomStore.cropFilters.has(a.id)});

        return <div className="popup-ndvi active-flex">
            <div className="popup-ndvi-in ShowRoom2Filters-form">
                <div className="popup-ndvi-in-closer" onClick={this.onClose}>
                    <div className="close-button-icon"></div>
                </div>
                <React.Fragment>
                    <div className="ShowRoom2Filters-title">
                        Filters
                    </div>
                    {store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Projects &&
                        <div className="style-4 flex-stretch-item popup-ndvi-in__view">
                            {/*
                                <ShowRoom2FiltersList list={districtsList} generalName="Districts" onCheck={this.onCheckDistrict} onUncheck={this.onUnCheckDistrict} store={store} />
                                */
                            }
                            {store.dataShowRoomStore.filtersProject.districts_status == LoadStatus.ready && <ShowRoom2FiltersDistricts store={store} />}
                            {store.dataShowRoomStore.filtersProject.districts_status == LoadStatus.loading && <LoadingAniSvg />}
                        </div>
                    }
                    {store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Multiyear && <div className="style-4 flex-stretch-item popup-ndvi-in__view ShowRoom2Filters-scroll">
                        <ShowRoom2FiltersList list={seasonsList} generalName="Seasons" onCheck={this.onCheckSeasons} onUncheck={this.onUnCheckSeasons} store={store} />
                        <div className="separator_line-flex ShowRoom2Filters-separate"/>
                        <ShowRoom2FiltersList list={cropList} generalName="Crops" onCheck={this.onCheckCrop} onUncheck={this.onUnCheckCrop} store={store} />
                    </div>}
                </React.Fragment>
            </div>
        </div>;
    }
}
