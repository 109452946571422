// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowRoom2TopFilters-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 5px 3px 10px;
  background: #262B32;
  border: 1px solid #3E4751;
  border-radius: 15px;
  align-items: flex-start;
  margin: 0 16px;
  height: 46px;
}

div.ShowRoom2TopFilters-item {
  background: #454C52;
}

div.ShowRoom2TopFilters-itemDown {
  background: #4DB6BC;
  fill: #262B32;
  color: #262B32;
}

.ShowRoom2TopFilterPopup {
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 5px 9px;
  font-size: 12px;
  color: #C5C5C5;
  line-height: 18px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/dataShowRoom2/ShowRoom2TopFilters.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,0BAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,YAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,aAAA;EACA,cAAA;AAGF;;AAAA;EACE,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,yCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AAGF","sourcesContent":[".ShowRoom2TopFilters-div{\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex-wrap: wrap;\r\n  padding: 10px 5px 3px 10px;\r\n  background: #262B32;\r\n  border: 1px solid #3E4751;\r\n  border-radius: 15px;\r\n  align-items: flex-start;\r\n  margin: 0 16px;\r\n  height: 46px;\r\n}\r\ndiv.ShowRoom2TopFilters-item{\r\n  background: #454C52;\r\n}\r\ndiv.ShowRoom2TopFilters-itemDown{\r\n  background: #4DB6BC;\r\n  fill: #262B32;\r\n  color: #262B32;\r\n}\r\n\r\n.ShowRoom2TopFilterPopup{\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\r\n  border-radius: 6px;\r\n  padding: 5px 9px;\r\n  font-size: 12px;\r\n  color: #C5C5C5;\r\n  line-height: 18px;\r\n\r\n  //text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
