import {CustomStore} from "../CustomStore";
import {action, observable} from "mobx";

export enum ProjectSettingPage{
    general= "general",
    access = "access",
    struct = "struct",
    styles = "styles"
}
export class ProjectSettingStore extends CustomStore{
    @observable
    _showProjectSettings: boolean = false;
    get showProjectSettings(): boolean{
        return this._showProjectSettings;
    }
    @observable
    _hiddenProjectSettings: boolean = false;
    get hiddenProjectSettings(): boolean{
        return this._hiddenProjectSettings;
    }
    @action
    closeWindow(){
        this._showProjectSettings = false;
        this._hiddenProjectSettings = false;
    }
    @action
    openWindow(){
        this._showProjectSettings = true;
        this._hiddenProjectSettings = false;
    }
    @action
    hideWindow(){
        this._showProjectSettings = false;
        this._hiddenProjectSettings = true;
    }

    @observable
    page: ProjectSettingPage = ProjectSettingPage.general;

}