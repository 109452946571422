import * as React from 'react';
import { Fragment, useState } from 'react';
import { ContextMenuCommonComp, PopupCorner, PopupDirection } from './ContextMenuCommonComp';
import { isFunction } from 'lodash-es';

interface IInformationIconCompProps {
    hint?: string;
    hintClass?: string;
    onClick?: () => void;
}

interface IInformationIcon {
    isHovered: boolean;
}

const InformationIcon = (props: IInformationIcon) => {
    return props.isHovered? 
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_749_22)">
    <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM10.125 12.9375C10.125 13.248 9.873 13.5 9.5625 13.5H8.4375C8.127 13.5 7.875 13.248 7.875 12.9375V8.4375C7.875 8.127 8.127 7.875 8.4375 7.875H9.5625C9.873 7.875 10.125 8.127 10.125 8.4375V12.9375ZM9 6.75C8.379 6.75 7.875 6.246 7.875 5.625C7.875 5.004 8.379 4.5 9 4.5C9.621 4.5 10.125 5.004 10.125 5.625C10.125 6.246 9.621 6.75 9 6.75Z" fill="#4DB6BC"/>
    </g>
    <defs>
    <clipPath id="clip0_749_22">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg> : 
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_749_2)">
    <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 15.75C5.2875 15.75 2.25 12.7125 2.25 9C2.25 5.2875 5.2875 2.25 9 2.25C12.7125 2.25 15.75 5.2875 15.75 9C15.75 12.7125 12.7125 15.75 9 15.75Z" fill="#4DB6BC"/>
    <path d="M10.125 7.875H7.875V13.5H10.125V7.875Z" fill="#4DB6BC"/>
    <path d="M9 6.75C9.62132 6.75 10.125 6.24632 10.125 5.625C10.125 5.00368 9.62132 4.5 9 4.5C8.37868 4.5 7.875 5.00368 7.875 5.625C7.875 6.24632 8.37868 6.75 9 6.75Z" fill="#4DB6BC"/>
    </g>
    <defs>
    <clipPath id="clip0_749_2">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>;
}

const InformationIconComp = (props: IInformationIconCompProps) => {
    const {hint, hintClass} = props;
// const InvormationIcon = () => {
    let hovered = false;
    const [isHovered, setHovered] = useState(hovered);

    const onEnter = () => {
        setHovered(true);
    }

    const onLeave = () => {
        setHovered(false);
    }

    const onClick = () => {
        if (isFunction(props.onClick)) props.onClick();
    }

    return <Fragment>
        <div className="pointer" onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={onClick}>
        {<InformationIcon isHovered={isHovered}/>}
        </div>
        {hint && isHovered && <div className={hintClass}>{hint}</div>}
    </Fragment>;
}

export default InformationIconComp;
