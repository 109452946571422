import * as React from 'react';

export class AgroAhoDownloadFileSvg extends React.Component {

    render() {
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 10.25C14.4516 10.25 14.1655 10.3685 13.9545 10.5795C13.7435 10.7905 13.625 11.0766 13.625 11.375V13.625H2.375V11.375C2.375 11.0766 2.25647 10.7905 2.0455 10.5795C1.83452 10.3685 1.54837 10.25 1.25 10.25C0.951631 10.25 0.665483 10.3685 0.454505 10.5795C0.243526 10.7905 0.125 11.0766 0.125 11.375V13.625C0.125 14.2217 0.362053 14.794 0.78401 15.216C1.20597 15.6379 1.77826 15.875 2.375 15.875H13.625C14.2217 15.875 14.794 15.6379 15.216 15.216C15.6379 14.794 15.875 14.2217 15.875 13.625V11.375C15.875 11.0766 15.7565 10.7905 15.5455 10.5795C15.3345 10.3685 15.0484 10.25 14.75 10.25Z" fill="#50A9F0"/>
            <path d="M7.20463 11.0454C7.41559 11.2563 7.70169 11.3748 8 11.3748C8.29831 11.3748 8.58441 11.2563 8.79537 11.0454L13.2954 6.54537C13.4028 6.4416 13.4885 6.31746 13.5475 6.1802C13.6065 6.04295 13.6375 5.89533 13.6388 5.74595C13.6401 5.59657 13.6116 5.44844 13.555 5.31018C13.4985 5.17192 13.4149 5.04631 13.3093 4.94068C13.2037 4.83505 13.0781 4.75152 12.9398 4.69495C12.8016 4.63838 12.6534 4.60992 12.504 4.61122C12.3547 4.61252 12.2071 4.64355 12.0698 4.70251C11.9325 4.76147 11.8084 4.84718 11.7046 4.95463L9.125 7.53425V1.25C9.125 0.951631 9.00647 0.665483 8.7955 0.454505C8.58452 0.243526 8.29837 0.125 8 0.125C7.70163 0.125 7.41548 0.243526 7.20451 0.454505C6.99353 0.665483 6.875 0.951631 6.875 1.25V7.53425L4.29537 4.95463C4.0832 4.7497 3.79902 4.6363 3.50405 4.63887C3.20908 4.64143 2.92691 4.75975 2.71833 4.96833C2.50975 5.17691 2.39143 5.45908 2.38887 5.75405C2.3863 6.04902 2.4997 6.3332 2.70463 6.54537L7.20463 11.0454Z" fill="#50A9F0"/>
        </svg>;
    }
}
