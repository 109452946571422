import {IndexByPointerSourceSimpleTempCustomStore} from "./IndexByPointerSourceSimpleTempCustomStore";
import {
    IGraphDayValue,
    IndexByPointGraphStore
} from "../IndexByPointGraphStore";
import {Utils} from "../../../helper/utils/Utils";

export class IndexByPointerSourceSimpleTempAvgClimateStore  extends IndexByPointerSourceSimpleTempCustomStore{
    constructor(parent: IndexByPointGraphStore) {
        super(parent);
        this.dataValue.getUrl = this.getUrlValue;
        this.dataMin.getUrl = this.getUrlMin;
        this.dataMax.getUrl = this.getUrlMax;
        this.dataValue.getValue = this.getValueSimple;
        this.dataMin.getValue = this.getValueMin;
        this.dataMax.getValue = this.getValueMax;
    }

    protected getUrlValue(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        const {dayOfYearBegin, dayOfYearEnd} = Utils.adjustDayOfYear(
            dateBegin,
            dateEnd
        );
        let url = `/api/meteo/avg/ltm/tmean?lon=${gp.point.lng}&lat=${gp.point.lat}&from_day=${dayOfYearBegin}&to_day=${dayOfYearEnd}`;
        return url;
    }
    protected getUrlMin(): string{
        let gp = this.parent.gPoint;
        let url = `/api/meteo/avg/ltm/tmin?lon=${gp.point.lng}&lat=${gp.point.lat}&product=avg`;
        return url;
    }
    protected getUrlMax(): string{
        let gp = this.parent.gPoint;
        let url = `/api/meteo/avg/ltm/tmax?lon=${gp.point.lng}&lat=${gp.point.lat}`;
        return url;
    }
    protected getValueSimple(a: any): IGraphDayValue{
        return {dayOfYear: a.day - 1, value: a.tmean};
    }
    protected getValueMin(a: any): IGraphDayValue{
        return {dayOfYear: a.day - 1, value: a.tmin};
    }
    protected getValueMax(a: any): IGraphDayValue{
        return {dayOfYear: a.day - 1, value: a.tmax};
    }
}