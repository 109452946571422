import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {action} from "mobx";
import {WindowTriState} from "../../../helper/structs/WindowTriState";
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../Common/ContextMenuCommonComp";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {PermalinkSvg} from "../../icons/RightPanel/PermalinkSvg";
import {PermalinkPanelComp} from "./PermalinkPanelComp";
import {UserMenuComp} from "../User/UserMenuComp";
import {MyHint} from "../../Common/MyHint";
import {CreateGeometryType} from "../../../store/tools/general/ContainerTools";

@observer
export class RightSidebarTop extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickUser(){
        //this.props.store.user.requestLogout();
        this.props.store.user.showMenu = true;
    }

    @action onOpenLayers() {
        this.props.store.map.layersMenuOpened = !this.props.store.map.layersMenuOpened;
        this.props.store.indexByPointer.showPanel = WindowTriState.close;
    }
/*
    @action onOpenMTOverlays() {
        this.props.store.map.mtOverlaysMenuOpened = !this.props.store.map.mtOverlaysMenuOpened;
    }
*/
    @action onOpenPermalink() {
        this.props.store.map.permalinkPanelOpen = !this.props.store.map.permalinkPanelOpen;
    }

    @action
    onGraphClick(){
        this.props.store.map.layersMenuOpened = false;
        if (this.props.store.indexByPointer.showPanel == WindowTriState.show)
            this.props.store.indexByPointer.showPanel = WindowTriState.minimize;
        else this.props.store.indexByPointer.showPanel = WindowTriState.show;
    }
    @action
    onClickRuler() {
        this.props.store.map.rulerInfo.rulerManager.clickStartRuler();
    }
    rulerActive(): boolean{
        return this.props.store.map.rulerInfo.rulerManager.buttonRulerActive();
    }
    @action
    onClickNdviValueByMouse(){
        //this.props.store.map.ndviValueByMouseStore.active = !this.props.store.map.ndviValueByMouseStore.active;
        if (this.props.store.map.measuringStatus != MeasuringStatus.ndviValueByMouse) {
            this.props.store.map.setMeasuringStatus(MeasuringStatus.ndviValueByMouse);
        }else{
            this.props.store.map.resetMeasuringStatus();
        }
    }

    onExportEnable(): boolean{
        let store = this.props.store;
        return store.searchPanel.exportWindowMapButtonEnabled();
    }
    @action
    onExport(){
        let store = this.props.store;
        //if (!store.searchPanel.exportWindowMapButtonEnabled()) return;

        //if (!this.props.store.qgisExport.isActiveButtonWindow) return;
        this.props.store.qgisExport.showExportWindow = true;
    }

    render() {
        let store = this.props.store;

        return <div className="right-sidebar__top">
            <div className="icon_container icon_container_fill" onClick={this.onClickUser}>
                <div className="user_right_sidebar gray blue"/>
                {store.user.showMenu &&
                <ContextMenuCommonComp indentVertical={4} indentHorizontal={4} onClose={() => {
                    store.user.showMenu = false
                }} autoCornerHeight={100} autoCornerWidth={100} direction={PopupDirection.vertical} popupCorner={PopupCorner.leftTop}
                >
                    <UserMenuComp store={store} />
                </ContextMenuCommonComp>
                }
            </div>
            <div className="spacer-10"/>
            <div className="spacer-10-line"/>
            <MyHint text={store.trans.Layers} placement="left">{/*слои*/}
                <div className={classNames("icon_container icon_container_fill relative",{"active": store.map.layersMenuOpened})} onClick={this.onOpenLayers}>
                    <div className={classNames("stack_right_sidebar",
                        {blue: store.map.layersMenuOpened, gray: !store.map.layersMenuOpened})}/>
                </div>
            </MyHint>

            <div className="spacer-5"/>
            <div className="spacer-10-line"/>
            <div className="spacer-5"/>
            <MyHint text={store.trans.Measure} placement="left">{/*линейка*/}
                <div className={classNames("icon_container icon_container_fill",{"active": this.rulerActive()})}
                     onClick={this.onClickRuler}>
                    <div className={classNames("ruler gray",
                        {"blue": this.rulerActive()})}/>
                </div>
            </MyHint>

            <div className="spacer-5"/>
            <MyHint text={store.trans["Exporting a map window"]} placement="left">{/*экспорт*/}
                <div className={classNames("icon_container icon_container_fillstroke relative",
                    {"active": this.props.store.qgisExport.showExportWindow})} onClick={this.onExport}>
                    <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_185_3055)">
                            <path d="M13.125 17.076V15.8689V8.62769C13.125 8.59453 13.1118 8.56274 13.0884 8.5393C13.065 8.51586 13.0332 8.50269 13 8.50269C12.9669 8.50269 12.9351 8.51586 12.9116 8.5393C12.8882 8.56274 12.875 8.59453 12.875 8.62769V15.8689V17.076L12.0215 16.2225L9.96758 14.1686C9.94423 14.147 9.91348 14.1351 9.8816 14.1354C9.84883 14.1357 9.81748 14.1488 9.7943 14.172C9.77113 14.1952 9.75798 14.2265 9.7577 14.2593C9.75742 14.2912 9.76933 14.3219 9.79094 14.3453L12.9116 17.466C12.9117 17.466 12.9117 17.466 12.9117 17.466C12.9351 17.4894 12.9669 17.5026 13 17.5026C13.0331 17.5026 13.0649 17.4894 13.0883 17.466C13.0883 17.466 13.0884 17.466 13.0884 17.466L16.2133 14.3411C16.2367 14.3176 16.2499 14.2858 16.2499 14.2527C16.2499 14.2196 16.2368 14.1878 16.2134 14.1644C16.2133 14.1644 16.2133 14.1643 16.2133 14.1643M13.125 17.076L15.6831 13.8108C15.8003 13.6936 15.9593 13.6278 16.125 13.6278C16.2907 13.6278 16.4497 13.6936 16.5669 13.8108L16.2133 14.1643M13.125 17.076L13.9786 16.2225L16.0366 14.1644M13.125 17.076L16.0366 14.1644M16.2133 14.1643C16.1898 14.1409 16.1581 14.1278 16.125 14.1278C16.0919 14.1278 16.0601 14.141 16.0366 14.1644M16.2133 14.1643L16.0366 14.1644" />
                        </g>
                        <path d="M17.5061 0.196235C17.3538 0.0932442 17.1787 0.0291382 16.9959 0.00952619C16.8131 -0.0100859 16.6283 0.0153936 16.4576 0.0837352L11.3017 2.14024L7.25175 0.115235C7.10949 0.0453586 6.95407 0.00633047 6.79567 0.000708101C6.63728 -0.00491427 6.47948 0.0229961 6.33263 0.0826102L0.707625 2.33261C0.49869 2.41608 0.319583 2.56029 0.193441 2.74659C0.0672993 2.9329 -8.25203e-05 3.15274 7.58455e-08 3.37774V16.8777C-6.74237e-05 17.0616 0.04492 17.2426 0.131026 17.4051C0.217132 17.5675 0.341733 17.7064 0.493926 17.8095C0.646119 17.9126 0.821266 17.9769 1.00404 17.9966C1.18682 18.0164 1.37165 17.9911 1.54238 17.9229L6.69825 15.8652H6.79684V13.5044C6.95545 13.511 7 13.5027 6.79684 13.5044C6.63824 13.4978 6.48003 13.5248 6.33263 13.5837L2.25 15.2161V4.13936L6.69825 2.36524L10.7483 4.39024C10.8901 4.46159 11.0456 4.50191 11.2043 4.5085C11.3629 4.5151 11.5212 4.48782 11.6685 4.42849L15.75 2.78936V8.00269H18V1.12774C18 0.943938 17.955 0.762933 17.8689 0.600548C17.7828 0.438163 17.6583 0.299345 17.5061 0.196235Z" stroke="none" />
                        <defs>
                            <clipPath id="clip0_185_3055">
                                <rect width="10" height="10" stroke="none" transform="translate(8 8.00269)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </MyHint>

            <div className="spacer-5"/>
            <MyHint text={store.trans.Charts} placement="left">{/*точки*/}
                <div className={classNames("icon_container icon_container_fill relative",{"active": store.indexByPointer.showPanel == WindowTriState.show})} onClick={this.onGraphClick}>
                    <svg width="21" height="19" viewBox="0 0 21 19" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 19H1C0.734784 19 0.48043 18.8946 0.292893 18.7071C0.105357 18.5196 0 18.2652 0 18V5H2V17H16V19Z" />
                        <path d="M16 16H3V14C5.912 14 6.889 12.241 8.126 10.015C9.432 7.664 10.912 5 15 5H16V16Z" />
                        {store.indexByPointer.graphs.length > 0 && <circle cx="15.5" cy="5.5" r="4.5" fill="#4DB6BC" stroke="#2B3138" strokeWidth="2"/>}
                    </svg>
                </div>
            </MyHint>

            {store.config.pixelValue && <React.Fragment>
            <div className="spacer-5"/>
            <MyHint text={store.trans["Pixel value"]} placement="left">{/*пипетка*/}
            <div className={classNames("icon_container icon_container_fill relative",{"active": store.map.measuringStatus == MeasuringStatus.ndviValueByMouse})}
                 onClick={this.onClickNdviValueByMouse}>
                <svg width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 5.5L3 10C3 10 2.4 12 2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 13.6 6 13 6 13L10.5 8.5L7.5 5.5Z" />
                    <path d="M13.9 5.1L15.4 3.6C16.2 2.8 16.2 1.4 15.4 0.6C14.6 -0.2 13.2 -0.2 12.4 0.6L10.9 2.1L8.9 0.1L7 2L14 9L15.9 7.1L13.9 5.1Z" />
                </svg>
            </div>
            </MyHint>
            </React.Fragment>}
            <div className="spacer-5"/>
            <MyHint text={store.trans.Permalink} placement="left">{/*пермалинк*/}
            <div className="icon_container" onClick={this.onOpenPermalink}>
                <PermalinkSvg className={classNames({"blue-fill": store.map.permalinkPanelOpen, "white-fill": !store.map.permalinkPanelOpen})} />
                {store.map.permalinkPanelOpen &&
                    <ContextMenuCommonComp indentVertical={4} indentHorizontal={4} onClose={() => {
                        store.map.permalinkPanelOpen = false
                    }}  autoCornerHeight={100} autoCornerWidth={100} direction={PopupDirection.vertical} popupCorner={PopupCorner.leftTop}>
                        <PermalinkPanelComp store={store} />
                    </ContextMenuCommonComp>
                }
            </div>
            </MyHint>

        </div>;
    }
}
