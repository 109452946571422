import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCardTourItemComp.scss'
import {action} from "mobx";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";
import classNames from "classnames";
import {AgroAhoEditSvg} from "../icons/AgroAhoEditSvg";
import {AgroAhoDelSvg} from "../icons/AgroAhoDelSvg";
import {AgroAhoDel2Svg} from "../icons/AgroAhoDel2Svg";
import {TourItem2} from "../../agroAhoTourStore";
import {AhoUtils} from "../../agroAhoUtils";


export interface IAgroAhoCardTourItemCompProps {
    store: IAgroAhoSuperStore;
    tour: TourItem2;
}

@observer
export class AgroAhoCardTourItemComp extends React.PureComponent<IAgroAhoCardTourItemCompProps, undefined> {
    constructor(props: IAgroAhoCardTourItemCompProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        this.props.store.agroAhoStore.tourStore.doTourClick(this.props.tour);
    }

    onEdit(e: any){
        this.props.store.agroAhoStore.tourStore.doEditTourClick(this.props.tour);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onDel(e: any){
        this.props.store.agroAhoStore.tourStore.onDelTourClick(this.props.tour);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let tour = this.props.tour;
        // console.log('tour:', AhoUtils.cp(tour));
        let indc_num: any = '';
        if (Object.keys(tour?.observ?.columns || {}).length)
            indc_num = Object.keys(tour?.observ?.columns || {}).filter(key=>key != 'cell_id').length || '';
        let vals_num = '';
        let vcnt = 0;
        if (tour?.observ_vals) {
            tour.observ_vals.forEach(val=>{
                Object.keys(val).filter(key=>key != 'cell_id').forEach(key=>{
                    if (AhoUtils.isNum(val[key])) vcnt += 1;
                });
            })
        }
        if (vcnt) vals_num = store_.numWithSpaces(vcnt);

        return <div className="AgroAhoCardTourItemComp-main" onClick={this.onClick}>
            <AgroAhoRectCheckboxSvg className="AgroAhoCardTourItemComp-checkbox" checked={tour.checked}/>
            <div className="AgroAhoCardTourItemComp-nameCover">
                <div className="AgroAhoCardTourItemComp-name">{tour.tour_name}</div>
                <div className="AgroAhoCardTourItemComp-statCover">
                    <span className="AgroAhoCardTourItemComp-indc">{store_.trans.Indic}: {indc_num}</span>
                    <span className="AgroAhoCardTourItemComp-indcVals">{store_.trans.Vals}: {vals_num}</span>
                </div>
            </div>
            <div className="AgroAhoCardTourItemComp-rightCover">
                <span className="AgroAhoCardTourItemComp-date">{store_.format_date(tour.tour_date)}</span>
                <div className="AgroAhoCardTourItemComp-del" onClick={this.onDel}>
                    <AgroAhoDelSvg/>
                </div>
                <div className="AgroAhoCardTourItemComp-edit" onClick={this.onEdit}>
                    <AgroAhoEditSvg/>
                </div>
            </div>
        </div>
    }
}
