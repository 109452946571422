import {ShowRoom2LayerPointCustomTool} from "./ShowRoom2LayerPointCustomTool";
import {FillPaint, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {action} from "mobx";
import {ShowroomLayerType} from "../../dataShowroom2/ShowRoom2LayerStore";

export class ShowRoom2LayerPointYieldAssTool extends ShowRoom2LayerPointCustomTool{
    @action
    loadPopupById(id: any):void{
        this.store.dataShowRoomStore.mapPopup.showRoom2LayerName = ShowroomLayerType.yieldAssessmentPoints;
        this.store.dataShowRoomStore.mapPopup.loadInfoYieldAss(id as any);
    }

    idlayerFill(): string{
        return this.getLayerId()+"_fill";
    }

    getSvgStr(): string{
        let svg = '<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.5 1.28868C7.8094 1.11004 8.1906 1.11004 8.5 1.28868L14.4282 4.71132C14.7376 4.88996 14.9282 5.22008 14.9282 5.57735V12.4226C14.9282 12.7799 14.7376 13.11 14.4282 13.2887L8.5 16.7113C8.1906 16.89 7.8094 16.89 7.5 16.7113L1.5718 13.2887C1.2624 13.11 1.0718 12.7799 1.0718 12.4226V5.57735C1.0718 5.22008 1.2624 4.88996 1.5718 4.71132L7.5 1.28868Z" fill="{{color}}" stroke="white" stroke-width="2"/> </svg>';
        return svg;
    }
    async addLayers() {
        let svg = this.getSvgStr();
        let map = this.store.map.mapbox;
        let tileUrl = `${window.location.origin}/reports/yield/public.get_yield/{z}/{x}/{y}.pbf?filter=${JSON.stringify(this.store.dataShowRoomStore.getFilters())}`;
        let icon = await this.generateSprites(svg);

        if (!map.getSource(this.getSourceId())){
            map.addSource(this.getSourceId(),{
                type: 'vector',
                tiles: [tileUrl],
                scheme: "xyz",
            });
        }
        //MBUtils.getNewColor
        if (!map.getLayer(this.layerIdPoints())){
            this.addLayer({
                id: this.layerIdPoints(),
                source: this.getSourceId(),
                "source-layer": 'points',
                type: 'symbol',
                //minzoom: 7,
                layout: <SymbolLayout>{
                    'icon-image': icon,
                    "icon-allow-overlap": true,
                },
                paint:<SymbolPaint>{
                }
            });
        }

        if (!map.getLayer(this.idlayerFill())){
            this.addLayer({
                id: this.idlayerFill(),
                source: this.getSourceId(),
                "source-layer": 'hexagons',
                type: 'fill',
                //maxzoom: 6,
                paint: <FillPaint>{
                    'fill-color': 'rgba(255,255,255,0.5)'
                }
            });
        }
    }

    removeLayers() {
        let map = this.store.map.mapbox;
        if (map.getLayer(this.idlayerFill())){
            this.removeLayer(this.idlayerFill());
        }
        super.removeLayers();
    }

}