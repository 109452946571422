import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoArrowBackSvgProps {
    className?: string;
    onClick?:(e: any)=>void;
}

export class AgroAhoArrowBackSvg extends React.PureComponent<IAgroAhoArrowBackSvgProps, undefined> {
    constructor(props: IAgroAhoArrowBackSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick ? this.props.onClick : ()=>false} 
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9998 6.99943H3.41379L6.70679 3.70643C6.88894 3.51783 6.98974 3.26523 6.98746 3.00303C6.98518 2.74083 6.88001 2.49002 6.6946 2.30461C6.5092 2.1192 6.25838 2.01403 5.99619 2.01176C5.73399 2.00948 5.48139 2.11027 5.29279 2.29243L0.292786 7.29243C0.105315 7.47996 0 7.73427 0 7.99943C0 8.2646 0.105315 8.5189 0.292786 8.70643L5.29279 13.7064C5.38503 13.8019 5.49538 13.8781 5.61738 13.9305C5.73939 13.9829 5.87061 14.0105 6.00339 14.0117C6.13616 14.0128 6.26784 13.9875 6.39074 13.9373C6.51364 13.887 6.62529 13.8127 6.71918 13.7188C6.81307 13.6249 6.88733 13.5133 6.93761 13.3904C6.98789 13.2675 7.01319 13.1358 7.01204 13.003C7.01088 12.8703 6.9833 12.739 6.93089 12.617C6.87848 12.495 6.8023 12.3847 6.70679 12.2924L3.41379 8.99943H14.9998C15.265 8.99943 15.5194 8.89407 15.7069 8.70654C15.8944 8.519 15.9998 8.26465 15.9998 7.99943C15.9998 7.73421 15.8944 7.47986 15.7069 7.29232C15.5194 7.10479 15.265 6.99943 14.9998 6.99943Z" fill="#4DB6BC"/>
        </svg>;
    }
}
