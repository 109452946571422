import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export interface IPermalinkSvgProps {
}

export class PermalinkSvg extends CustomSvg {

    render() {
        return <svg width="18" height="18" viewBox="0 0 18 18" className={this.getClasses()} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.12476 18C0.902291 17.9999 0.684831 17.9339 0.499869 17.8103C0.314908 17.6867 0.170751 17.511 0.0856226 17.3055C0.00049377 17.0999 -0.0217846 16.8738 0.0216039 16.6556C0.0649924 16.4374 0.1721 16.2369 0.329385 16.0796L5.95439 10.4546C6.16656 10.2497 6.45074 10.1363 6.74571 10.1388C7.04068 10.1414 7.32285 10.2597 7.53143 10.4683C7.74002 10.6769 7.85833 10.959 7.86089 11.254C7.86346 11.549 7.75006 11.8332 7.54514 12.0453L1.92014 17.6703C1.70921 17.8813 1.4231 17.9999 1.12476 18Z" />
            <path d="M1.08658 12.141L2.79883 10.4276L2.73695 10.2836C2.7302 10.2656 2.08895 8.50161 3.04633 7.54423L7.58008 3.01048C8.52733 2.06323 10.2801 2.68761 10.2801 2.68648L10.4635 2.76523L12.1757 1.05186L11.1261 0.601857C10.999 0.552357 7.98733 -0.574893 5.9882 1.42086L1.45445 5.95461C-0.540174 7.94698 0.588201 10.9642 0.653451 11.1307L1.08658 12.141Z" />
            <path d="M5.85889 16.9132L7.57226 15.201L7.71626 15.2629C7.73426 15.2696 9.49826 15.9109 10.4556 14.9535L14.9894 10.4197C15.9366 9.4725 15.3123 7.71975 15.3134 7.71975L15.2346 7.53637L16.948 5.823L17.398 6.87262C17.4475 6.99975 18.5748 10.0159 16.579 12.0105L12.0453 16.5442C10.0529 18.5389 7.03564 17.4105 6.87026 17.3452L5.85889 16.9132Z"/>
            <path d="M11.2498 7.87497C11.0273 7.87492 10.8098 7.80892 10.6249 7.6853C10.4399 7.56168 10.2958 7.38599 10.2106 7.18046C10.1255 6.97492 10.1032 6.74876 10.1466 6.53056C10.19 6.31236 10.2971 6.11193 10.4544 5.9546L16.0794 0.329595C16.2916 0.124667 16.5757 0.0112733 16.8707 0.0138365C17.1657 0.0163997 17.4478 0.134715 17.6564 0.343299C17.865 0.551883 17.9833 0.834047 17.9859 1.12902C17.9885 1.42399 17.8751 1.70817 17.6701 1.92034L12.0451 7.54534C11.8342 7.75634 11.5481 7.87491 11.2498 7.87497Z" />
        </svg>;
    }
}
