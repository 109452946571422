import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class TrashSvg extends CustomSvg{
    render() {// fill="#E94F4F"
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()} style={this.getStyles()}>
            <g clipPath="url(#clip0_154_47)">
                <path d="M2.5 5.5V14C2.5 14.828 3.172 15.5 4 15.5H12C12.828 15.5 13.5 14.828 13.5 14V5.5"
                    strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M0.5 3.5H15.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.5 3.5V0.5H10.5V3.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                <path d="M8 7.5V12.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.5 7.5V12.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.5 7.5V12.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_154_47">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>;
    }
}
