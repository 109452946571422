import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {ILayerLegendCategory} from "../../../../helper/intStyle/IntStyleLegend";
import {IPaintType} from "../../../../helper/intStyle/styleInterface/IPaint";
import './LegendLayer.scss';
import {action} from "mobx";
import {LegendCheckBoxComp} from "../LegendCheckBoxComp";
import {IntStyleConverter} from "../../../../helper/intStyle/IntStyleConverter";

interface ILegendLayerItem extends IStoreProps{
    item: ILayerLegendCategory;
}
@observer
export class LegendLayerItem extends React.Component<ILegendLayerItem, undefined> {
    constructor(props: ILegendLayerItem) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickVisible(){
        let store = this.props.store;
        let item = this.props.item;
        let oldValue = store.map.legend.isVisibleCategories(item.categoryKey);
        store.map.legend.setVisibleCategories(item.categoryKey, !oldValue);
        store.agro2.styles.refreshStyles();
    }
    render() {
        let store = this.props.store;
        let item = this.props.item;
        let paintType = IPaintType.polygon;
        let visible = store.map.legend.isVisibleCategories(item.categoryKey);
        if (item.paintTypes.has(IPaintType.polygon)) paintType = IPaintType.polygon; else
        if (item.paintTypes.has(IPaintType.polyline)) paintType = IPaintType.polyline; else
        if (item.paintTypes.has(IPaintType.point)) paintType = IPaintType.point;

        let polygonBackgroundColor = item?.paint?.polygon?.fill?.color;
        if ((item?.paint?.polygon?.fill?.visible !== undefined && !item?.paint?.polygon?.fill?.visible) || item?.paint?.polygon?.fill?.opacity == 0) polygonBackgroundColor = "transparent";
        let polygonContourColor = item?.paint?.polygon?.contour?.color;
        if ((item?.paint?.polygon?.contour?.visible !== undefined && !item?.paint?.polygon?.contour?.visible) || item?.paint?.polygon?.contour?.opacity == 0) polygonContourColor = "transparent";

        return (
            <div className="legendLayer-row">
                <div className="legendLayer-checkVisible">
                    <LegendCheckBoxComp store={store} checked={visible} onClick={this.onClickVisible} />
                </div>
                {paintType == IPaintType.polygon &&
                    <div className="legendLayer-icon legendLayer-iconPolygon" style={{
                        background: polygonBackgroundColor,

                        borderColor: polygonContourColor}}/>
                }
                {paintType == IPaintType.polyline &&
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" fill="none" stroke={item.paint.polyline.color}/>
                        <rect x="2" y="11.2931" width="13.1424" height="1" rx="0.5" transform="rotate(-45 2 11.2931)" fill={item.paint.polyline.color}/>
                    </svg>
                }
                {paintType == IPaintType.point &&
                    <div className="legendLayer-icon flex-full-center">
                        <div className="legendLayer-iconPoint" style={{
                            background: item.paint.point.color,
                            borderWidth: (item.paint.point["stroke-width"] > 0) ? 1 : 0,
                            borderColor: item.paint.point["stroke-color"]}}/>
                    </div>
                }
                <div className="legendLayer-rowName">{item.name}</div>
            </div>
        );
    }
}