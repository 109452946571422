import * as React from 'react';
import {CustomSvg} from "../CustomSvg";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import classNames from "classnames";


export class AgroButtonSvg extends React.Component<IStoreProps, undefined> {

    render() {
        let store = this.props.store;
        let iconUrl = store.config.theme?.agro_button_icon;
        let html: any = null;
        if (iconUrl){
            html = {__html: iconUrl};
            return <div className={classNames("flex-full-center", this.props.className)} dangerouslySetInnerHTML={html} />
        }

        return <div className={classNames("flex-full-center", this.props.className)}>
            {!iconUrl &&

                <svg width="18" height="18" viewBox="0 0 18 18" stroke="none"  xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_4590_285)">
                        <path d="M12.0001 0C10.7468 0 9.4778 0.40875 8.43005 1.1625C11.1901 2.097 13.5001 4.59825 13.5001 8.25C13.5001 10.2638 12.2716 12.543 10.9006 14.466C11.1721 14.7578 11.3791 14.9707 11.4676 15.06C11.6086 15.2017 11.7998 15.2812 12.0001 15.2812C12.2003 15.2812 12.3916 15.2017 12.5326 15.06C13.0921 14.4952 18.0001 9.45 18.0001 6C18.0001 2.244 14.9498 0 12.0001 0Z" />
                        <path d="M6 2.25C3.05025 2.25 0 4.494 0 8.25C0 11.7 4.908 16.7452 5.4675 17.31C5.6085 17.4517 5.79975 17.5312 6 17.5312C6.20025 17.5312 6.3915 17.4517 6.5325 17.31C7.092 16.7452 12 11.7 12 8.25C12 4.494 8.94975 2.25 6 2.25ZM6 9.75C5.17125 9.75 4.5 9.07875 4.5 8.25C4.5 7.42125 5.17125 6.75 6 6.75C6.82875 6.75 7.5 7.42125 7.5 8.25C7.5 9.07875 6.82875 9.75 6 9.75Z"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_4590_285">
                            <rect width="18" height="18"/>
                        </clipPath>
                    </defs>
                </svg>

            }
        </div>;
    }
}
