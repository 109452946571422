import React, {useState} from "react";
import {observer} from "mobx-react";
import {
    ContextMenuCommonComp,
    PopupDirection,
    PopupCorner
} from "../../../Common/ContextMenuCommonComp";
import {
    CheckboxMiniComp,
    CheckboxShape,
    CheckboxSize,
    TriState
} from "../../../Common/CheckboxMiniComp";
import {ColorPicker} from "../LayersList/ColorPicker";
import {ColorHelper} from "../../../../helper/utils/ColorHelper";
import {SuperStore} from "../../../../store/SuperStore";
import {IndexByPointYearStore} from "../../../../store/indeByPoint/IndexByPointYearStore";
import {IndexByPointGraphPointStore} from "../../../../store/indeByPoint/IndexByPointGraphPointStore";
import {Utils} from "../../../../helper/utils/Utils";

interface IIndexByPointTableColorCompProps {
    store: SuperStore;
    point: IndexByPointGraphPointStore;
}

const IndexByPointTableColorComp: React.FC<IIndexByPointTableColorCompProps> =
    observer(({store, point}) => {
        const [isPickerOpen, setIsPickerOpen] = useState(false);
        const [selectedYearPoint, setSelectedYearPoint] =
            useState<any>(null);

        const colorMode = store.indexByPointer.colorMode;

        const togglePicker = (yearPoint: IndexByPointYearStore) => {
            setSelectedYearPoint(yearPoint);
            setIsPickerOpen(!isPickerOpen);
        };

        const getTitlePeriod = (
            yearPoint: IndexByPointYearStore
        ): string => {
            if (!yearPoint.yearPeriod.isClimate) {
                return store.indexByPointer.isCrossYear
                    ? `${yearPoint.yearPeriod.year}-${
                          yearPoint.yearPeriod.year + 1
                      }`
                    : yearPoint.yearPeriod.year.toString();
            }
            return store.trans.Climate;
        };

        const assignColorToYearPoint = (
            yearPoint: IndexByPointYearStore,
            color: string
        ) => {
            const parsedColor = ColorHelper.parseColor(color);
            const numericColor =
                parsedColor.r * 65536 +
                parsedColor.g * 256 +
                parsedColor.b;

            if (colorMode === "everyYear") {
                const yearOrUniqueId = yearPoint.yearPeriod.isClimate
                    ? `climate`
                    : `${yearPoint.yearPeriod.year}`;
                store.indexByPointer.yearPoints
                    .filter(yp => {
                        const ypYearOrUniqueId = yp.yearPeriod
                            .isClimate
                            ? `climate`
                            : `${yp.yearPeriod.year}`;
                        return ypYearOrUniqueId === yearOrUniqueId;
                    })
                    .forEach(yp => {
                        applyColorToYearPoint(yp, numericColor);
                    });
            } else if (colorMode === "everyPoint") {
                point.yearPoints.forEach(yp => {
                    applyColorToYearPoint(yp, numericColor);
                });
            } else {
                applyColorToYearPoint(yearPoint, numericColor);
            }
        };

        const applyColorToYearPoint = (
            yearPoint: IndexByPointYearStore,
            numericColor: number
        ) => {
            yearPoint.setCustomColor(numericColor);
            if (yearPoint.graphs && yearPoint.graphs.length > 0) {
                yearPoint.graphs.forEach(graph => {
                    graph.color = numericColor;
                });
            }
        };
        const sortedYearPoints = point.yearPoints.sort((a, b) => {
            const climateComparison = Utils.compareSortBoolean(
                a.yearPeriod.isClimate,
                b.yearPeriod.isClimate
            );
            if (climateComparison !== 0) return climateComparison;
            return b.yearPeriod.year - a.yearPeriod.year;
        });

        const graphArr = sortedYearPoints.map(y => {
            return (
                <div
                    style={{
                        backgroundColor: ColorHelper.numToCssHexRgb(
                            y.color
                        ),
                        border: "1px solid #99A4B7",
                        width: "17px",
                        height: "17px",
                        marginRight: y.yearPeriod.isClimate
                            ? "45px"
                            : "32px",
                        borderRadius: "4px"
                    }}
                    onClick={() => togglePicker(y)}
                    key={
                        `${y.idPoint}` +
                        `${y.yearPeriod.year}` +
                        `${y.yearPeriod.isClimate}`.toString()
                    }
                    className='pointer IndexByPointTableRowComp-commonCheckbox-withLabel'
                >
                    <span
                        style={{
                            color: "#C5C5C5",
                            fontSize: "12px",
                            marginLeft: "25px"
                        }}
                        className='IndexByPointTableRowComp-commonCheckbox-label pointer'
                    >
                        {getTitlePeriod(y)}
                    </span>
                </div>
            );
        });

        return (
            <div className='flex-columns IndexByPointTableRowComp'>
                <div className='IndexByPointTableRowComp-sub1 flex-columns'>
                    <CheckboxMiniComp
                        shape={CheckboxShape.circle}
                        state={true}
                        size={CheckboxSize.mini}
                        styleBox={{cursor: "default"}}
                        classesContainer=' IndexByPointTableRowComp-commonCheckbox-withLabel'
                        classesBox='IndexByPointTableRowComp-commonCheckbox IndexByPointTableRowComp-commonCheckbox-white'
                    >
                        <span
                            style={{
                                color: "#C5C5C5",
                                fontSize: "12px"
                            }}
                            className='IndexByPointTableRowComp-commonCheckbox-label'
                        >
                            {point.num || point.description}
                        </span>
                    </CheckboxMiniComp>
                    <div className='flex-stretch-item IndexByPointTableRowComp-description'>
                        {point.description}
                    </div>
                </div>
                <div
                    className='IndexByPointTableRowComp-sub2 flex-columns flex-wrap'
                    style={{rowGap: "8px", columnGap: "12px"}}
                >
                    {graphArr}
                </div>

                {isPickerOpen && selectedYearPoint && (
                    <ContextMenuCommonComp
                        onClose={() => setIsPickerOpen(false)}
                        direction={PopupDirection.horizontal}
                        popupCorner={PopupCorner.leftBottom}
                    >
                        <ColorPicker
                            store={store}
                            color={ColorHelper.numToCssHexRgb(
                                selectedYearPoint.color
                            )}
                            setColor={newColor =>
                                assignColorToYearPoint(
                                    selectedYearPoint,
                                    newColor
                                )
                            }
                            opacity={1}
                            setOpacity={() => {}}
                            variant='preset'
                            onChange={newColor =>
                                assignColorToYearPoint(
                                    selectedYearPoint,
                                    newColor
                                )
                            }
                            onClose={() => setIsPickerOpen(false)}
                        />
                    </ContextMenuCommonComp>
                )}
            </div>
        );
    });

export default IndexByPointTableColorComp;
