import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {
    ContainerToolsState,
    CreateGeometryType,
    IGeometryEditorEvents,
    IObjectByClick
} from "../tools/general/ContainerTools";
import {ViewGeometryLayersTool} from "../tools/EditGeometry/ViewGeometryLayersTool";
import {MapMouseEvent} from "maplibre-gl";
import {ToolEvent} from "../../../pluginApi/tools/ToolEvent";
import {MeasuringStatus} from "../../helper/structs/MeasuringStatus";
import {GActionTransactionType} from "../../helper/geometryAction/IGeometryAction";
import {GeometryUtils, ISimpleGeometry, SimpleGeometryType} from "../../helper/utils/GeometryUtils";
import difference from "@turf/difference";
import {Feature, Geometry} from "geojson";
import union from "@turf/union";
import {IReactionDisposer} from "mobx/lib/internal";
import {autorun} from "mobx";
import {GeometryActionTransaction} from "../../helper/geometryAction/ActionManager";
import lineSplit from "@turf/line-split";
import polygonSplitter from "polygon-splitter";

export class AgroCutManager extends ObservableCustomStore implements IGeometryEditorEvents{
    constructor(parent: CustomStore) {
        super(parent);
        this.state.events = this;
    }
    public externalState: ContainerToolsState;

    public state: ContainerToolsState = new ContainerToolsState();

    public viewEditorGeometry: ViewGeometryLayersTool;
    subscription(): IReactionDisposer[] {
        return [autorun(()=>{
            if (this.root.map.measuringStatus != MeasuringStatus.agroCutGeometry && this.root.map.measuringStatus != MeasuringStatus.agroUnionGeometry){
                if (this.state.simpleGeometry.length > 0) {
                    this.state.simpleGeometry = [];
                    this.state.events.updateGeometry();
                    this.state.resetMovedPoints();
                    this.state.events.updateMovedPoint();
                }
            }
        })];
    }

    getObjectByClick(point: maplibregl.Point): IObjectByClick {
        return this.viewEditorGeometry.getObjectByClick(point);
    }
    public updateMovedPoint() {
        this.viewEditorGeometry.updateMovedPoint();
    }

    public updateGeometry(){
        this.viewEditorGeometry.updateGeometry();
    }

    onClickFirstPointPolygon(e : MapMouseEvent & ToolEvent): boolean{
        return this.onClickRightButton(e);
    }
    onClickRightButton(e : MapMouseEvent & ToolEvent): boolean{
        this.state.resetMovedPoints();
        this.Finished();
        e.noPropagation();
        return false;
    }
    onChangeGeometry() {
        //this._changes++;
    }


    public Finished(){


        this.root.map.superTools.viewEditorGeometry.updateGeometry();
        if (this.root.map.measuringStatus == MeasuringStatus.agroCutGeometry) {
            if (this.state.simpleGeometry == null || this.state.simpleGeometry.length == 0) return;
            this.doOpearation(()=>{
                    return this.externalState.actionManager.startGeometryFieldTransaction(GActionTransactionType.cutGeometry);
                },
                (g1, drawedGeometry) => {
                    let ff: Feature[] = [];
                    if (drawedGeometry == null){
                        return [ff[0].geometry];
                    }
                    if (g1.type == "MultiPolygon"){
                        g1.coordinates.forEach(c => {
                            let feature = polygonSplitter(
                                {geometry: {type: "Polygon", coordinates:c}, type: "Feature", properties: {}},
                                {geometry: drawedGeometry as any, type: "Feature", properties: {}}
                            );
                            ff.push(feature);
                        });
                    }
                    if (g1.type == "Polygon") {
                        let feature = polygonSplitter(
                            {geometry: g1 as any, type: "Feature", properties: {}},
                            {geometry: drawedGeometry as any, type: "Feature", properties: {}}
                        );
                        ff.push(feature);
                    }

                    if (ff.length == 1){
                        return [ff[0].geometry];
                    }
                    if (ff.length > 1){
                        let cc: any = [];
                        ff.forEach(a => {
                            if (a.geometry.type == "Polygon"){
                                cc.push(a.geometry.coordinates);
                            }
                            if (a.geometry.type == "MultiPolygon"){
                                a.geometry.coordinates.forEach(b =>{
                                    cc.push(b);
                                });
                            }
                        });
                        let p:any = {type: "MultiPolygon", coordinates: cc};
                        return [p];
                    }
                    return [g1];
                //return [feature.geometry];
            });
            this.state.createGeometryType = CreateGeometryType.Line;
        }
        if (this.root.map.measuringStatus == MeasuringStatus.agroUnionGeometry) {
            this.doOpearation(()=>{
                return this.externalState.actionManager.startGeometryFieldTransaction(GActionTransactionType.unionGeometry);
            },
                (g1, drawedGeometry) => {
                let feature = union(g1 as any, drawedGeometry as any);
                return [feature.geometry];
            });
            this.state.createGeometryType = CreateGeometryType.Polygon;
        }
    }
    isCreateGeometry(): boolean{
        return (this.root.map.measuringStatus == MeasuringStatus.agroCutGeometry ||
            this.root.map.measuringStatus == MeasuringStatus.agroUnionGeometry);
    }
    isEdit(): boolean{
        return false;
    }
    isDeleteContour(): boolean{ return false;}

    doOpearation(startTransaction: ()=>GeometryActionTransaction,
        operation: (g1: Geometry, drawedGeometry: Geometry)=> Geometry[]){
        let tr = startTransaction();
        let sg1: ISimpleGeometry[] = [];
        if (this.externalState.simpleGeometry != null) {
            for(let i = this.externalState.simpleGeometry.length - 1; i >=0; i--){
                if (this.externalState.simpleGeometry[i].simple == SimpleGeometryType.Polygon){
                    sg1.push(this.externalState.simpleGeometry[i]);
                    tr.deleteGeometry(i);
                }
            }
        }
        if (sg1 != null && sg1.length > 0 && this.state.simpleGeometry != null && this.state.simpleGeometry.length > 0) {
            let g1 = GeometryUtils.createGeometryBySimpleGeometry(sg1);
            let g2 = GeometryUtils.createGeometryBySimpleGeometry(this.state.simpleGeometry);
            let g3arr = operation(g1, g2);
            g3arr.forEach(g => {
                let sg3 = GeometryUtils.getSimpleGeometries(g);
                sg3.forEach(a => {
                    tr.insertGeometry(this.externalState.simpleGeometry.length, a);
                });
            });
        }
        this.state.simpleGeometry = [];
        this.state.events.updateGeometry();
        this.externalState.events.updateGeometry();
    }

}