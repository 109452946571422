// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.LogView-window {
  margin: 14px auto;
  max-width: 1800px;
}

.LogView-header {
  display: flex;
  height: 80px;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.LogView-table-col-first {
  width: 20px;
}

.LogView-table-col-date {
  width: 10%;
}

.LogView-table-col-user {
  width: 20%;
}

.LogView-table-col-email {
  width: 20%;
}

.LogView-table-col-action {
  width: 15%;
}

.LogView-table-exportButton {
  border-radius: 10px;
  width: 40px;
  height: 40px;
  background: #2B3138;
  cursor: pointer;
  border: solid 1px #3E4751;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogView-value-row {
  display: flex;
  height: 63px;
  align-items: center;
  border-top: 1px solid #3E4751;
}

.LogView-value-row:hover {
  background: #262B32;
}

.LogView-value-col-first {
  width: 20px;
}

.LogView-value-col-date {
  width: 10%;
}

.LogView-value-col-user {
  width: 20%;
}

.LogView-value-col-email {
  width: 20%;
}

.LogView-value-col-action {
  width: 15%;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/User/LogView/LogView.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;AACF;;AACA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAEF;;AAAA;EAAyB,WAAA;AAIzB;;AAHA;EAAwB,UAAA;AAOxB;;AANA;EAAwB,UAAA;AAUxB;;AATA;EAAyB,UAAA;AAazB;;AAZA;EAA0B,UAAA;AAgB1B;;AAbA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAgBF;;AAbA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,6BAAA;AAgBF;;AAdA;EACE,mBAAA;AAiBF;;AAfA;EAAyB,WAAA;AAmBzB;;AAlBA;EAAwB,UAAA;AAsBxB;;AArBA;EAAwB,UAAA;AAyBxB;;AAxBA;EAAyB,UAAA;AA4BzB;;AA3BA;EAA0B,UAAA;AA+B1B","sourcesContent":["div.LogView-window{\n  margin: 14px auto;\n  max-width: 1800px;\n}\n.LogView-header{\n  display: flex;\n  height: 80px;\n  align-items: center;\n  position: relative;\n  overflow: hidden;\n}\n.LogView-table-col-first{width: 20px;}\n.LogView-table-col-date{width: 10%;}\n.LogView-table-col-user{width: 20%;}\n.LogView-table-col-email{width: 20%;}\n.LogView-table-col-action{width: 15%;}\n.LogView-table-col-end{}\n\n.LogView-table-exportButton{\n  border-radius: 10px;\n  width: 40px;\n  height: 40px;\n  background: #2B3138;\n  cursor: pointer;\n  border: solid 1px #3E4751;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.LogView-value-row{\n  display: flex;\n  height: 63px;\n  align-items: center;\n  border-top: 1px solid #3E4751;\n}\n.LogView-value-row:hover{\n  background: #262B32;\n}\n.LogView-value-col-first{width: 20px;}\n.LogView-value-col-date{width: 10%;}\n.LogView-value-col-user{width: 20%;}\n.LogView-value-col-email{width: 20%;}\n.LogView-value-col-action{width: 15%;}\n.LogView-value-col-end{}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
