import * as React from 'react';
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {isFunction, isString} from "lodash-es";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {IDropDownItem} from "./DropDownComp";
import {ContextMenuCommonComp} from "./ContextMenuCommonComp";
import {TextUtils} from "../../helper/utils/TextUtils";
import './DropTextComp.scss';
import {Utils} from "../../helper/utils/Utils";

export enum DropTextFindType{
    start, contain
}
export interface IDropTextCompProps {
    items: IDropDownItem[],
    currentItemKey?: string,
    text?: string;
    findType?: DropTextFindType,//default = start
    dropItemsAllVisible?: boolean,//все элементы в выпадающем списке всегда видимы, фильтр не используется. default=false
    placeHolder?: string;
    disabled?: boolean;
    className?: string;
    classNameInput?: string;
    classNameButton?: string;
    classNameDropDown?: string;
    highlightItems?: boolean;//Подсвечивать одинаковый текст в выпадающем списке. default= true
    caseSensitive?: boolean; //default = true

    onChange?:(text: string, item: IDropDownItem)=>void;
    onAnimateEndInput?: ()=>any;
    hasDownButton?: boolean;//default=true
    onFinish?: ()=>any;//press enter or lost focus or clickDownItem
}

@observer
export class DropTextComp extends React.Component<IDropTextCompProps, undefined> {
    constructor(props: IDropTextCompProps) {
        super(props);
        autoBindReact(this);
        this.refList = React.createRef();
        this.refCurLine = React.createRef();
    }

    @observable
    down: boolean = false;
    @observable
    indexLine: number = null;

    visibleItems: IDropDownItem[] = [];
    refList: any = null;
    refCurLine: any = null;

    onClosePopup(){
        this.down = false;
    }

    getFindType(): DropTextFindType{
        if (!this.props.findType){
            return DropTextFindType.start;
        }else return this.props.findType;
    }
    getCaseSensitive():boolean{ return this.props.caseSensitive??true; }

    get text(): string{
        return this.props.text??"";
    }

    getCurrentItem(): IDropDownItem{
        if (this.getCaseSensitive()) return this.props.items.find(a => this.text == a.value);

        else return this.props.items.find(a => TextUtils.stringSortCompare(this.text, a.value, true) == 0);
    }

    onChangeText(e: any){
        if (!!this.props.disabled) return;
        let txt = e.currentTarget.value;
        let f = this.props.items.find(a => txt == a.value);
        this.down = true;
        if (isFunction(this.props.onChange)) this.props.onChange(txt, f);
    }

    onClick(e: any){
        if (!!this.props.disabled) return;
        this.down = !this.down;
        e.stopPropagation();
        e.preventDefault();
    }
    onClickButton(e: any){
        if (!!this.props.disabled) return;

        this.down = !this.down;
        e.stopPropagation();
        e.preventDefault();
    }

    onClickItem(e: any){
        let key = e.currentTarget.getAttribute("data-key");
        let it = this.props.items.find(a => a.key == key );
        this.down = false;
        if (isFunction(this.props.onChange)) this.props.onChange(it.value, it);
        if (this.props.onFinish) this.props.onFinish();
    }

    onKeyUp(e: any) {
        if  (e.keyCode == 13 && this.props.onFinish){
            this.props.onFinish();
        }
        if  (e.keyCode == 13 || e.keyCode == 27 || e.keyCode == 40 || e.keyCode == 38) {//esc
            e.stopPropagation();
            e.preventDefault();
        }
    }

    @action
    onBlur(){
        if  (this.props.onFinish){
            this.props.onFinish();
        }
    }
    @action
    onKeyDown(e: any){

        if (e.keyCode == 13){
            if (!!this.props.disabled) return;
            if (this.indexLine != null && this.visibleItems.length > 0){
                let q = this.visibleItems[this.indexLine];
                if (isFunction(this.props.onChange)) this.props.onChange(q.value, q);
            }
            this.down = false;
            this.indexLine = null;
            e.stopPropagation();
            e.preventDefault();
        }else
        if (e.keyCode == 9){//tab - оставляем стандартную обработку
            this.down = false;
            this.indexLine = null;
        }else
        if (e.keyCode == 27){//esc
            this.down = false;
            this.indexLine = null;
            e.stopPropagation();
            e.preventDefault();
        }else
        if (e.keyCode == 40) {//вниз
            if (!!this.props.disabled) return;
            if (this.visibleItems.length > 0) {
                if (this.indexLine == null) this.indexLine = 0;
                else {
                    this.indexLine++;
                    if (this.indexLine >= this.visibleItems.length) {
                        this.indexLine = 0;
                    }
                }
            }
            this.down = true;
            e.stopPropagation();
            e.preventDefault();
        }else
        if (e.keyCode == 38) {//вверх
            if (!!this.props.disabled) return;
            if (this.visibleItems.length > 0) {
                if (this.indexLine == null) this.indexLine = 0;
                else {
                    this.indexLine--;
                    if (this.indexLine < 0) {
                        this.indexLine = this.visibleItems.length - 1;
                    }
                }
            }
            this.down = true;
            e.stopPropagation();
            e.preventDefault();
        }else{
            this.indexLine = null;
        }
    }

    scrollToTimer(){
        if (this.refCurLine.current != null){
            if (!checkInView(this.refCurLine.current.parentNode, this.refCurLine.current, false))
                Utils.scrollIntoView(this.refCurLine.current);
        }
        function checkInView(container:any, element:any, partial:any):boolean {

            //Get container properties
            let cTop = container.scrollTop;
            let cBottom = cTop + container.clientHeight;

            //Get element properties
            let eTop = element.offsetTop;
            let eBottom = eTop + element.clientHeight;

            //Check if in view
            let isTotal = (eTop >= cTop && eBottom <= cBottom);
            let isPartial = partial && (
                (eTop < cTop && eBottom > cTop) ||
                (eBottom > cBottom && eTop < cBottom)
            );

            //Return outcome
            return  (isTotal  || isPartial);
        }
    }
    onAnimateEnd(){
        if (isFunction(this.props.onAnimateEndInput)) this.props.onAnimateEndInput();
    }

    render() {
        let subItems: any[] =[];
        //if (this.props)
        let txt = this.text;
        if (txt == null) txt = "";
        if (!isString(txt)) txt = (txt as any).toString();

        let curItem = this.getCurrentItem();
        let hasCur: boolean = false;
        this.visibleItems = [];
        if (this.down){
            if (this.getFindType() == DropTextFindType.start){
                if (this.props.dropItemsAllVisible??false){
                    this.visibleItems = this.props.items;
                }else
                    this.visibleItems = this.props.items.filter(a => a.value.startsWith(txt));
                this.visibleItems.forEach((a, index) => {
                    let r: any = null;
                    if (index == this.indexLine || (this.indexLine == null && curItem == a)){
                        r = this.refCurLine;
                        hasCur = true;
                    }

                    subItems.push(<div key={a.key} ref={r} className={classNames("DropText-line", a.class,
                        {
                            "DropText-line-highlight": index == this.indexLine,
                            "DropText-line-selected": curItem == a
                        })} onClick={this.onClickItem} data-key={a.key}>
                        <span className={classNames({"DropText-text-highlight": this.props.highlightItems??true,
                            "DropText-text": !(this.props.highlightItems??true)})}>{a.value.substr(0, txt.length)}</span>
                        <span className="DropText-text">{a.value.substr(txt.length)}</span>
                    </div>);
                });
            }else
            if (this.getFindType() == DropTextFindType.contain){
                let usubstr: string = txt.toUpperCase();
                if (this.props.dropItemsAllVisible??false){
                    this.visibleItems = this.props.items;
                }else {
                    this.visibleItems = this.props.items.filter(a => {
                        if (this.getCaseSensitive())
                            return a.value.includes(txt);
                        else return a.value.toUpperCase().includes(usubstr);
                    });
                }

                this.visibleItems.forEach((a, index) => {
                    let spanWords: any[] = [];
                    if (this.props.highlightItems??true) {
                        let words = TextUtils.findSubstrings(a.value, txt, this.getCaseSensitive());
                        words.forEach((w, index) => {
                            let eq = TextUtils.isEqual(w, txt, this.getCaseSensitive());
                            spanWords.push(<span key={index.toString()} className={classNames({
                                "DropText-text-highlight": eq,
                                "DropText-text": !eq
                            })}>{w}</span>);
                        });
                    }else{
                        spanWords.push(<span key={0} className={"DropText-text"}>{a.value}</span>);
                    }
                    let r = null;
                    if (index == this.indexLine || (this.indexLine == null && curItem == a)){
                        r = this.refCurLine;
                        hasCur = true;
                    }
                    subItems.push(<div
                        key={a.key} ref={r}
                        className={classNames("DropText-line", a.class,
                            {"DropText-line-highlight": index == this.indexLine,
                            "DropText-line-selected": curItem == a})}
                        data-key={a.key} onClick={this.onClickItem}>
                        {spanWords}
                    </div>);
                });
            }
        }
        if (hasCur){
            setTimeout(()=> {this.scrollToTimer();}, 10);
        }

        let stPopup: any = {};
        if (this.refList.current != null){
            stPopup["width"] = this.refList.current.offsetWidth + "px";
        }

        return <div className={classNames("DropText", this.props.className)} ref={this.refList} >
            <input type="text" readOnly={!!this.props.disabled}
                   className={classNames("DropText-input", this.props.classNameInput)}
                   onClick={this.onClick} onAnimationEnd={this.onAnimateEnd}
                   onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp} value={this.props.text??""}
                   onChange={this.onChangeText} onBlur={this.onBlur}
                   placeholder={this.props.placeHolder??""} />
            {(this.props.hasDownButton??true) && <div className={classNames("DropText-button", this.props.classNameButton)} onClick={this.onClickButton}>
                <svg width='12' height='8' viewBox='0 0 12 8' xmlns='http://www.w3.org/2000/svg'><path d='M5.99961 7.4001L0.599609 2.0001L1.99961 0.600098L5.99961 4.6001L9.99961 0.600098L11.3996 2.0001L5.99961 7.4001Z' /></svg>
            </div>}
            {this.down && subItems.length > 0 && <ContextMenuCommonComp
                className={classNames("DropText-items style-4", this.props.classNameDropDown)}
                parentClickNotIgnore={true}  indentVertical={5}
                style={stPopup} onClose={this.onClosePopup}>
                {subItems}
            </ContextMenuCommonComp>}
        </div>;
    }
}
