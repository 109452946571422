import * as React from 'react';
import autoBindReact from "auto-bind/react";
import './RadioButton.scss';
import classNames from "classnames";
import {isFunction} from "lodash-es";

export interface IRadioButtonProps {
    checked: boolean;
    onClick?: (event: any, data: any)=>any;
    data?: any;
    className?: string;
    disabled?: boolean;
}

export class RadioButton extends React.PureComponent<IRadioButtonProps, undefined> {
    constructor(props: IRadioButtonProps) {
        super(props);
        autoBindReact(this);
    }

    click(e: any){
        if (this.props.disabled) return;
        if (isFunction(this.props.onClick)){
            this.props.onClick(e, this.props.data);
        }
    }

    render() {
        return <div className={classNames("RadioButton-main", this.props.className, {"RadioButton-main-disabled": this.props.disabled})}  onClick={this.click}><div className={classNames("RadioButton-div", {"RadioButton-checked": this.props.checked})} ></div>{this.props.children}</div>;
    }
}
