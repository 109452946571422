import * as React from 'react';
import {CSSProperties} from 'react';
import autoBindReact from "auto-bind/react";
import './InputStringWindow.scss';
import {isFunction} from "lodash-es";
import classNames from "classnames";

export interface IInputStringWindowProps {
    title: string;
    name: string;
    value: string;
    okText: string;
    cancelText: string;
    zIndex?: number;
    isDisable?: boolean;
    onOkClick?: (str: string)=>void;
    onCancelClick?: ()=>void;
    onChange?: (str: string)=>void;
}

export class InputStringWindow extends React.PureComponent<IInputStringWindowProps, undefined> {
    constructor(props: IInputStringWindowProps) {
        super(props);
        autoBindReact(this);
    }

    onClickOk(){
        if (this.props.isDisable) return;
        if (isFunction(this.props.onOkClick)) this.props.onOkClick();
    }
    onClickCancel(){
        if (isFunction(this.props.onCancelClick)) this.props.onCancelClick();
    }
    onChange(e: any){
        if (isFunction(this.props.onChange)) this.props.onChange(e.target.value);
    }

    render() {
        let st: CSSProperties = {};
        if (this.props.zIndex > 0){
            st.zIndex = this.props.zIndex;
        }

        return <div className="modal-back fixed">
                <div className="modal-full-frame InputStringWindow-window fixed_center_30top" style={st}>
                    <div className="InputStringWindow-title">
                        {this.props.title}
                    </div>
                    <div className="InputStringWindow-name">
                        <div className="InputStringWindow-nameLabel">{this.props.name}</div>
                        <input className="text-box-editor flex-stretch-item" onChange={this.onChange} value={this.props.value}/>
                    </div>
                    {this.props.children}
                    <div className="separator_line-flex" />
                    <div className="InputStringWindow-footer">
                        <button onClick={this.onClickOk}
                                className={classNames("large-button left-sidebar-active button-jump InputStringWindow-button", {"opacity30": this.props.isDisable})}>
                            {this.props.okText}
                        </button>
                        <button
                            className="large-button left-sidebar-cancel button-jump text-next InputStringWindow-button"
                            onClick={this.onClickCancel}>
                            {this.props.cancelText}
                        </button>
                    </div>
                </div>
            </div>;
    }
}
