import * as React from 'react';
import './AgroProjectNewUserComp.scss';
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import {ProjectRole} from "../../../../../store/agro/A2PermissionStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {Utils} from "../../../../../helper/utils/Utils";

export interface IAgroProjectNewUserCompProps extends IStoreProps{
    user: string;
    role: ProjectRole;
    onChangeTitle: (newValue: string)=>void;
    onChangeType: (newValue: ProjectRole)=>void;
    onCancel: ()=>any;
    onOk: ()=>any;
}

export class AgroProjectNewUserComp extends React.PureComponent<IAgroProjectNewUserCompProps, undefined> {
    constructor(props: IAgroProjectNewUserCompProps) {
        super(props);
        autoBindReact(this);
    }

    isValidEmail(): boolean{
        return Utils.validateEmail(this.props.user);
    }
    render() {
        let store = this.props.store;
        let validEmail = Utils.validateEmail(this.props.user);
        let items: IDropDownItem[] = [
            {key: ProjectRole.view, value: TranslateUtils.getProjectRoleTitle(ProjectRole.view, store)},
            {key: ProjectRole.edit, value: TranslateUtils.getProjectRoleTitle(ProjectRole.edit, store)},
            {key: ProjectRole.admin, value: TranslateUtils.getProjectRoleTitle(ProjectRole.admin, store)}
        ];


        return <div className="modal-back modal-full">
            <div className="fixed modal-full-frame AgroProjectNewUserComp-form text-wrap" style={{zIndex: 3000, top: "20%"}}>
                <div className="AgroProjectNewUserComp-title">{store.trans["Adding a new user"]}</div>
                <div className="AgroProjectNewUserComp-subtitle">{store.trans["User Email"]}</div>
                <div className="AgroProjectNewUserComp-input">
                    <input type="text"
                           style={{margin: ""}}
                           className={classNames("text-box-editor width100 input-DarkBack AgroProjectCreateComp-inputFont")}
                           value={this.props.user}
                           onChange={(e: any)=>{
                               this.props.onChangeTitle( e.currentTarget.value);
                           }}
                    />
                </div>
                <div className="AgroProjectNewUserComp-subtitle">{store.trans["Access rights"]}</div>
                <div className="AgroProjectNewUserComp-input">
                    <DropDownComp items={items} currentKey={this.props.role} className="DropDowm-DarkBack width100 AgroProjectCreateComp-inputFont"
                                     classNameDown="DropDowm-DarkBack AgroProjectCreateComp-inputFont"
                                     onChange={(item: IDropDownItem, newIndex: number)=>{
                                         this.props.onChangeType(item.key as any);
                                     }} />
                </div>

                <div className="AgroProjectNewUserComp-footer">
                    <button className="left-sidebar-cancel button-jump Agro2Common-Form-footerButton" onClick={this.props.onCancel}>{store.trans.Cancel}</button>
                    <button className={classNames("left-sidebar-active button-jump Agro2Common-Form-footerButton"
                                )}
                            style={{marginRight: "0"}} disabled={!this.isValidEmail()}
                            onClick={()=>{
                                if (!this.isValidEmail()) return;
                                this.props.onOk();
                            }}>{store.trans.Add}</button>
                </div>
            </div>
        </div>;
    }
}
