import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {isFunction} from "lodash-es";



export interface IAgroAhoSortSvgProps {
    className?: string;
    desc?: boolean;
    onClick?: (e: any)=>void;
    disabled?: boolean;
}

export class AgroAhoSortSvg extends React.PureComponent<IAgroAhoSortSvgProps, undefined> {
    constructor(props: IAgroAhoSortSvgProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(e: any){
        if (this.props.disabled === true) return;
        if (isFunction(this.props.onClick)) this.props.onClick(e);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 6.5L2.75 0.5H3.25L5.5 6.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.25 4.5H4.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.5 9.5H5.5L0.5 15.5H5.5" strokeLinecap="round" strokeLinejoin="round"/>
            {!this.props.desc && <React.Fragment>
                <path d="M11.5 0.5V15.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.5 11.5L11.5 15.5L15.5 11.5" strokeLinecap="round" strokeLinejoin="round"/>
            </React.Fragment>}
            {this.props.desc && <React.Fragment>
                <path d="M11.5 15.5V0.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15.5 4.5L11.5 0.5L7.5 4.5" strokeLinecap="round" strokeLinejoin="round"/>
            </React.Fragment>}
        </svg>;
    }
}