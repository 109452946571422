import {ObservableCustomStore} from "../CustomStore";
import {A2ColumnItemStore, A2ColumnType} from "./A2ColumnItemStore";
import {save} from "../PermalinkDecor";
import {action, autorun, computed, observable, ObservableSet, reaction} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {A2ProjectStructStore} from "./A2ProjectStructStore";
import {Utils} from "../../helper/utils/Utils";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {A2FieldStore} from "./A2FieldStore";
import {FeatureCollection, Geometry} from "geojson";
import {IResponseFieldProperties} from "./AgroStore";
import {isEqual} from "lodash-es";
import {Exception} from "sass";


export interface IA2NavigationItemValue{
    key: any;
    name: string;
    field_cnt: number;
    area_cnt: number;
}

export enum NavigatorItemStoreType{
    folder = 'folder',
    fields = 'fields'
}

export class A2NavigatorItemStore extends ObservableCustomStore<A2NavigatorItemStore | A2ProjectStructStore>{
    class(): string {return "A2NavigatorItemStore";}

    @save @observable
    type: NavigatorItemStoreType = NavigatorItemStoreType.folder;

    @save @observable
    id_column: string;

    @save @observable
    private _activePage: boolean = false;
    get activePage(): boolean{
        return this._activePage;
    }
    set activePage(value: boolean){
        this._activePage = value;
    }

    @computed
    get columnInfo(): A2ColumnItemStore{
        return this.root.agro2.projectStruct.columns.get(this.id_column);
    }
    beforeLoadPermalink(){

    }
    @save @observable
    column_value: any = null;
    @save @observable
    column_stringValue: string = null;//отображаемое название
    visible_columnValue(): string{
        if (this.column_stringValue) return this.column_stringValue;
        return this.column_value;
    }
    @save @observable
    sort_order: boolean = true;
    //
    @save @observable
    scrollTop: number = 0;
    @observable
    last_opened_field_id: number;

    @save @observable
    nextItem: A2NavigatorItemStore = null;

    @save @observable
    filter_string: string = "";

    toString(): string{
        return this.column_stringValue + " value="+this.column_value+" "+this.type+ " id_column="+this.id_column;
    }

    containActivePage(): boolean{
        let t:A2NavigatorItemStore = this;
        while (t != null){
            if (t.activePage) return true;
            t = t.nextItem;
        }
        return false;
    }

    get parentItem(): A2NavigatorItemStore{
        if (this.parent == null || !(this.parent instanceof A2NavigatorItemStore)) return null;
        return this.parent as A2NavigatorItemStore;
    }

    @save @observable
    child_values: IA2NavigationItemValue[] = [];

    //@save
    @observable
    _child_status: LoadStatus = null;
    get child_status(): LoadStatus{
        return this._child_status;
    }
    set child_status(value: LoadStatus){
        this._child_status = value;
    }
    //@save
    @observable
    fields: A2FieldStore[] = [];

    @save @observable
    private selectedFieldIds: ObservableSet<number> = new ObservableSet<number>();

    subscription(): IReactionDisposer[] {
        return [reaction(()=>{
            return {a: this.root, b: this.activePage, c: this.child_status};
        }, ()=>{
            if (this.disposed) return;
            if (this.root == null)return;
                if (this.child_status == null){
                    this.loadValues();
                }
        }, {fireImmediately: true})];
    }

    @computed
    get filtered_fields():A2FieldStore[]{
        let s = this.filter_string.toUpperCase();
        if (s == "") return [...this.fields];
        return this.fields.filter(a => {
            if (a.name == null) return false;
            return a.name.toUpperCase().includes(s);
        });
    }
    @computed
    get filtered_child_values(): IA2NavigationItemValue[]{
        let s = this.filter_string.toUpperCase();
        if (s == "") return [...this.child_values];
        return this.child_values.filter(a => {
            if (a.name == null) return false;
            return a.name.toUpperCase().includes(s);
        });
    }

    isSelected(field_id: number): boolean{
        return this.selectedFieldIds.has(field_id);
    }

    clearSelection(){
        this.selectedFieldIds.clear();
    }
    setSelected(field_id: number, value: boolean): void{
        if(value){
            if (!this.selectedFieldIds.has(field_id)){
                this.selectedFieldIds.add(field_id);
            }
        }else{
            if (this.selectedFieldIds.has(field_id)){
                this.selectedFieldIds.delete(field_id);
            }
        }
    }
    @computed
    get selectedFields():A2FieldStore[]{
        return this.fields.filter(a => this.selectedFieldIds.has(a.id));
    }


    @action
    resetValues(){
        this.child_status = null;
        this.child_values = [];
    }

    getSummary(): {area: number; fields: number}{
        let item = this;
        let fields = 0;
        let area = 0;
        if (item != null) {
            if (item.type == NavigatorItemStoreType.folder) {
                item.filtered_child_values.forEach(a => {
                    fields += a.field_cnt;
                    area += a.area_cnt;
                });
            }
            if (item.type == NavigatorItemStoreType.fields) {
                item.filtered_fields.forEach(a => {
                    fields += 1;
                    area += a.area;
                });
            }
        }
        return {area, fields};
    }

    getNaviPath(): A2NavigatorItemStore[]{
        let r: A2NavigatorItemStore[] = [];
        let t: A2NavigatorItemStore = this;
        while (t != null){
            r.push(t);
            t = t.parent as A2NavigatorItemStore;
            if (!(t instanceof A2NavigatorItemStore)){
                t = null;
            }
        }
        //r = r.reverse();
        return r;
    }

    getFilters(options: {notFirst: boolean, firstValue?: any}): {value: any, column: string}[]{
        let filter: {value: any, column: string}[] = [];
        let path = this.getNaviPath();
        let start = 0;
        if (options.notFirst) start = 1;
        for(let i = start; i < path.length; i++){
            let p = path[i];
            if (p.type == NavigatorItemStoreType.fields) continue;
            if (options.firstValue != null && i == 0){
                filter.push({column: p.columnInfo.column_name, value: options.firstValue});
            }else {
                filter.push({column: p.columnInfo.column_name, value: p.column_value});
            }
        }
        return filter;

    }
    getAllFilters(options: {notFirst: boolean, firstValue?: any}): any{
        let r = this.getFilters(options);
        let val:any = {};
        if (r.length == 0) return {};
        if (r.length == 1) val[r[0].column] = r[0].value;
        if (r.length > 1) {
            val["$and"] = r.map(a => {
                let obj: any = {};
                obj[a.column] = a.value;
                return obj;
            });
        }
        return  val;
    }

    @action
    loadValues(){
        if (this.type == NavigatorItemStoreType.fields) {
            this.loadFields();
        }
        if (this.type == NavigatorItemStoreType.folder){
            this.doLoadFolder();
        }
    }

    getLoadFolderParams(): {columns:any, filter: any}{
        let cols = "";
        if (this.columnInfo.column_type == A2ColumnType.reference){
            cols = this.columnInfo.column_name + ','+this.columnInfo.column_nameString;
        }else{
            cols = this.columnInfo.column_name;
        }
        let filter: any = this.getAllFilters({notFirst: true});

        return  {columns:cols, filter: filter};
    }
    @action
    private doLoadFolder(){

        let args = this.getLoadFolderParams();
        let url = `/api/projects/${this.root.agro.projectName}/object/groups?${Utils.queryEncodeParams(args)}`;
        this.child_status = LoadStatus.loading;
        fetchJsonGet(url).then(a => {
            ra(()=>{
                if (!isEqual(args, this.getLoadFolderParams())) return;
                let arr: any[] = a as any[];
                this.child_values = [];
                if (this.columnInfo.column_type == A2ColumnType.reference){
                    arr.forEach(a => {
                        this.child_values.push({key: a[this.columnInfo.column_name], name: a[this.columnInfo.column_nameString], field_cnt: a.obj_cnt, area_cnt: a.area_sys});
                    });
                }else{
                    arr.forEach(a => {
                        this.child_values.push({key: a[this.columnInfo.column_name], name: a[this.columnInfo.column_name], field_cnt: a.obj_cnt, area_cnt: a.area_sys});
                    });
                }
                this.child_status = LoadStatus.ready;
                if (this.column_value != null){
                    let has = this.child_values.find(a => a.key == this.column_value);
                    if (!has){
                        if (this.nextItem != null) {
                            if (this.nextItem.containActivePage()) {
                                this.root.agro2.projectStruct.setActivePage(this);
                            }
                            this.nextItem.dispose();
                            this.nextItem = null;
                        }
                    }else{
                        if (this.nextItem != null) this.nextItem.loadValues();
                    }
                }
            });
        }).catch(err => {
            ra(()=>{
                this.child_values = [];
                this.child_status = LoadStatus.ready;
                this.root.addError(err);
            });
        });
    }
    @action
    private loadFields(){
        this.child_status = LoadStatus.loading;
        this.fields = [];
        let filter: any = this.getAllFilters({notFirst: true});

        fetchJsonGet(`/api/projects/${this.root.agro.projectName}/object/select`, {filter: filter, geom: 1}).then(json => {
            ra(()=>{
                if (!isEqual(this.getAllFilters({notFirst: true}), filter)) return;
                this.fields = [];
                let fc = json as FeatureCollection<Geometry, IResponseFieldProperties>;
                fc.features.forEach(gf => {
                    let t = new A2FieldStore(this);
                    t.id = gf.properties[this.root.agro2.projectInfo.fieldName_id];
                    t.properties = gf.properties;
                    t.geometry = gf.geometry;
                    this.fields.push(t);
                });
                //удаляем несуществующие selected_id
                let ids: Set<number> = new Set<number>();
                this.fields.forEach(a => ids.add(a.id));
                let ids_removes  = new Set<number>();
                this.selectedFieldIds.forEach(id =>{
                    if (!ids.has(id)){
                        ids_removes.add(id);
                    }
                });
                ids_removes.forEach(id => {
                    this.selectedFieldIds.delete(id);
                });

                this.child_status = LoadStatus.ready;
            });
        }).catch(err => {
            ra(()=>{
                this.child_status = LoadStatus.ready;
                this.fields = [];
                this.root.addError(err);
            });
        });
    }

}