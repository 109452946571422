import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../../helper/structs/IStoreProps";
import './ZalFieldListPanelComp.scss';
import {ProjType} from "../../../../../../store/user/UserStore";
import {ConstantsStore} from "../../../../../../store/config/ConstantsStore";
import {ZalConstants} from "../../../../../../const/ZalConstants";
import {action} from "mobx";
import {A2FieldStore} from "../../../../../../store/agro/A2FieldStore";
import {ra} from "../../../../../../helper/utils/mobxUtils";

@observer
export class ZalFieldListPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    async onClickGetWork(){
        await this.changeStatus(ZalConstants["Создание отчета"]);
    }

    @action
    async onClickReturnWait(){
        await this.changeStatus(ZalConstants["Ожидание обработки"]);
    }

    @action
    async changeStatus(new_status: number){
        try{
            let r = this.props.store.agro2.zalogi.zakluchenie.getFirstRec();
            let req_id = r.properties[ConstantsStore.Zal_req_id];
            await this.props.store.agro2.zalogi.changeStatus(new_status, req_id);
        }catch (e) {
            this.props.store.addError(e);
        }
    }


    @action
    async onClickZakl(){
        let store = this.props.store;
        if (store.agro2.zalogi.zakluchenie.showZakluchenie) return;
        let cad_num = store.agro2.zalogi.zakluchenie.getCadNum();
        store.agro2.zalogi.zakluchenie.readOnly = false;
        await store.agro2.zalogi.zakluchenie.readValues(cad_num, store.agro2.zalogi.zakluchenie.getReqId());
        ra(()=>{
            store.agro2.zalogi.zakluchenie.showZakluchenie = true;
        });
    }
    @action
    async onClickZaklReadOnly(){
        let store = this.props.store;
        let cad_num = store.agro2.zalogi.zakluchenie.getCadNum();
        store.agro2.zalogi.zakluchenie.readOnly = true;
        await store.agro2.zalogi.zakluchenie.readValues(cad_num, store.agro2.zalogi.zakluchenie.getReqId());
        ra(()=>{
            store.agro2.zalogi.zakluchenie.showZakluchenie = true;
        });
    }

    @action
    async onClickEditReport(){
        await this.changeStatus(ZalConstants["Редактирование отчета"]);
    }

    render() {
        let store = this.props.store;
        if (store.agro2.projectInfo.projectType != ProjType.parcel_assess) return null;
        let navigatorItem = store.agro2.projectStruct.activeNavigatorPage;
        if (navigatorItem.fields.length == 0) return null;
        let firstItem = navigatorItem.fields[0];
        let buttons: any[] = [];
        let noReadonly = this.props.store.agro2.permission.canCreateObject();
        if (noReadonly && firstItem.properties[ConstantsStore.Zal_req_status_id] == ZalConstants["Ожидание обработки"]){
            buttons.push(<button className="left-sidebar-active button-jump ZalFieldListPanelComp-button" key="wait" onClick={this.onClickGetWork}>
                Взять в работу
            </button>);
        }
        if (noReadonly && firstItem.properties[ConstantsStore.Zal_req_status_id] == ZalConstants["Создание отчета"]){
            buttons.push(<button className="left-sidebar-cancel button-jump ZalFieldListPanelComp-button" key="return" onClick={this.onClickReturnWait}>
                Вернуть в ожидание
            </button>);
            buttons.push(<button className="left-sidebar-active button-jump ZalFieldListPanelComp-button" key="zakl" onClick={this.onClickZakl}>
                Заключение
            </button>);
        }
        if (noReadonly && firstItem.properties[ConstantsStore.Zal_req_status_id] == ZalConstants["Отчет готов"]){
            buttons.push(<button className="left-sidebar-cancel button-jump ZalFieldListPanelComp-button" key="edit_report" onClick={this.onClickEditReport}>
                Редактировать отчет
            </button>);
            buttons.push(<button className="left-sidebar-active button-jump ZalFieldListPanelComp-button" key="zakl" onClick={this.onClickZakl}>
                Заключение
            </button>);
        }
        if (noReadonly && firstItem.properties[ConstantsStore.Zal_req_status_id] == ZalConstants["Редактирование отчета"]){
            buttons.push(<button className="left-sidebar-active button-jump ZalFieldListPanelComp-button" key="edit_report" onClick={this.onClickZaklReadOnly}>
                Заключение
            </button>);
            buttons.push(<button className="left-sidebar-active button-jump ZalFieldListPanelComp-button" key="zakl" onClick={this.onClickReturnWait}>
                Вернуть в ожидание
            </button>);
        }
        if (buttons.length == 0) return null;

        return <div className="ZalFieldListPanelComp-panel">
            {buttons}
        </div>;
    }
}
