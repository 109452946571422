import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class MeteoButtonSvg extends CustomSvg {

    render() {
        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style={this.getStyles()} className={this.getClasses()}>
            <g>
                <path d="M6.75 1.125H2.25V3.375H6.75V1.125Z" fillRule="evenodd" clipRule="evenodd"/>
                <path d="M6.75 5.625H2.25V7.875H6.75V5.625Z" fillRule="evenodd" clipRule="evenodd"/>
                <path d="M13.5 9.62438V2.25C13.5 1.65326 13.2629 1.08097 12.841 0.65901C12.419 0.237053 11.8467 0 11.25 0C10.6533 0 10.081 0.237053 9.65901 0.65901C9.23706 1.08097 9 1.65326 9 2.25V9.62438C8.14211 10.1197 7.47163 10.8842 7.09254 11.7994C6.71346 12.7146 6.64695 13.7293 6.90334 14.6862C7.15972 15.643 7.72468 16.4885 8.51058 17.0916C9.29647 17.6946 10.2594 18.0215 11.25 18.0215C12.2406 18.0215 13.2035 17.6946 13.9894 17.0916C14.7753 16.4885 15.3403 15.643 15.5967 14.6862C15.8531 13.7293 15.7865 12.7146 15.4075 11.7994C15.0284 10.8842 14.3579 10.1197 13.5 9.62438Z" fillRule="evenodd" clipRule="evenodd"/>
            </g>
        </svg>;
    }
}
