import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";
import {MapLayerItemStore} from "../../../../store/map/mapLayers/MapLayerItemStore";
import './ShowRoomLayerComp.scss';
import {action} from "mobx";

export interface IShowRoomLayerComp extends IStoreProps{
    layer: MapLayerItemStore
}
@observer
export class ShowRoomLayerComp extends React.Component<IShowRoomLayerComp, undefined> {
    constructor(props: IShowRoomLayerComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        this.props.layer.visible = !this.props.layer.visible;
        this.props.layer.setVisibleOnMap(this.props.layer.visible);
    }

    render() {
        let store = this.props.store;
        let layer = this.props.layer;
        return <div className="flex-columns-centerByVertical ShowRoomLayerComp-div">
            <CheckboxMiniComp state={layer.visible} size={CheckboxSize.standart} onClick={this.onClick} />
            <div className="text-next pointer" onClick={this.onClick}>{layer.title}</div>
        </div>;
    }
}
