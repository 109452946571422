import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action, observable, reaction} from "mobx";
import {Utils} from "../../../helper/utils/Utils";
import {WindowTriState} from "../../../helper/structs/WindowTriState";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import center from "@turf/center";
import {fetchJsonGet} from "../../../helper/utils/FetchUtils";
import {A2SubModeLeftPanel} from "../../../store/agro/A2AgroStore";
import {CoordinateStringFormat} from "../../../helper/utils/CoordinateStringFormat";
import { DownloadSvg } from "../../icons/DownloadSvg";
import "./ContextMenuComp.scss"

@observer
export class ContextMenuComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);


        let disp = reaction(()=>{ return {a: this.props.store.map.menuPointer}},()=>{
            Utils.pauseAsync(10).then(()=>{
                this.getFieldInfo();
            })
        }, {fireImmediately: true});
    }
    disp: any;
    componentWillUnmount() {
        if (this.disp != null){
            this.disp();
            this.disp = null;
        }
    }


    getFieldInfo(){
        let mp = this.props.store.map.menuPointer;
        if (mp == null){
            this.field_id = null;
            return;
        }
        let field_id = this.props.store.map.superTools.fieldClickTool.clickTo_field_id(mp.point);
        if (field_id != null){
            this.field_id = field_id;
        }else {
            this.field_id = null;
        }
    }

    @observable
    field_id: number = null;


    @action onCopyCoordinates() {
        let ms = this.props.store.map;
        let strCoos = Utils.coosToString(ms.menuPointer.lngLat.lng, ms.menuPointer.lngLat.lat, ms.coosFormat);
        Utils.copyToClipboard(strCoos);
        ms.menuPointer = null;
    }

    @action
    onGetIndex(e: any){
        try{
            let ms = this.props.store.map;
            if (ms.menuPointer == null) return;
            this.props.store.indexByPointer.showPanel = WindowTriState.show;
            this.props.store.indexByPointer.addPoint(ms.menuPointer.lngLat.lat, ms.menuPointer.lngLat.lng);
            ms.menuPointer = null;
            this.props.store.map.layersMenuOpened = false;
            this.props.store.map.surveyPoints.showPanel = false;
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    onDownloadGeometry() {
        let feature = this.props.store.map.selectedFeature;

        fetchJsonGet(`/api/dataset/${feature.sourceLayer}/select`,
            {"geom": 1, "filter": feature.properties})
            .then(data => {
                Utils.downloadJson(feature.sourceLayer + '.geojson', data );
            })
            .catch(err => {
                this.props.store.addError(err);
            });
    }

    @action onGetIndexField(e: any){
        let field_id = this.field_id;
        if (field_id == null) return;
        let ms = this.props.store.map;
        ms.menuPointer = null;//вот здесь это this.field_id становится = null
        this.props.store.map.layersMenuOpened = false;

        try{
            this.props.store.agro2.getField(field_id).then(json =>{
                this.props.store.indexByPointer.showPanel = WindowTriState.show;
                let c = center(json.geometry as any);
                let lat = c.geometry.coordinates[1];
                let lng = c.geometry.coordinates[0];
                let name = json.properties[this.props.store.agro2.projectInfo.fieldName_columnName];
                this.props.store.indexByPointer.addField(field_id, name, lat, lng);
            });
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action
    onClickEditObject(){
        let store = this.props.store;
        let field_id = store.map.menu_field_id;
        if (field_id == null) return;
        store.agro2.fieldEditorForm.initEditField(field_id);
        store.agro2.subModeLeftPanel = A2SubModeLeftPanel.editOrCreateField;
        store.agro2.fieldEditorForm.showFieldMultiEditPanel = false;
        store.map.menuPointer = null;
    }

    render() {
        let store = this.props.store;
        let mp = store.map.menuPointer;

        return <div className="popup-right-click"
                    style={{
                        visibility: (mp? "visible": "hidden"),
                        left: mp? mp.point.x: null,
                        top: mp? mp.point.y: null
                    }}>
            <div className="popup-right-click__row" onClick={this.onCopyCoordinates}>
                <div className="icon_context_menu">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 7.875C7.9665 7.875 8.75 7.0915 8.75 6.125C8.75 5.1585 7.9665 4.375 7 4.375C6.0335 4.375 5.25 5.1585 5.25 6.125C5.25 7.0915 6.0335 7.875 7 7.875Z" fill="#C5C5C5"/>
                        <path d="M6.3875 13.7375C6.3 13.65 2.7125 10.5 2.7125 10.4125C1.4875 9.3625 0.875 7.7875 0.875 6.125C0.875 2.7125 3.5875 0 7 0C10.4125 0 13.125 2.7125 13.125 6.125C13.125 7.7875 12.5125 9.3625 11.2875 10.5C11.2 10.5875 7.7 13.7375 7.6125 13.825C7.2625 14.0875 6.7375 14.0875 6.3875 13.7375ZM4.025 9.3625L7 11.9875L9.975 9.3625C10.85 8.4875 11.375 7.4375 11.375 6.2125C11.375 3.7625 9.45 1.8375 7 1.8375C4.55 1.8375 2.625 3.675 2.625 6.125C2.625 7.35 3.15 8.4875 4.025 9.3625C4.025 9.275 4.025 9.275 4.025 9.3625Z" fill="#C5C5C5"/>
                    </svg>
                </div>
                {store.trans["Copy coordinates"]}
            </div>
            <div className="popup-right-click__row" onClick={this.onGetIndex}>
                <div className="icon_context_menu">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7" cy="7" r="6" stroke="#C5C5C5" strokeWidth="2"/>
                        <circle cx="7" cy="7" r="2" fill="#C5C5C5"/>
                    </svg>
                </div>
                {store.trans["Get point profile"]}
            </div>
            {this.props.store.map.selectedFeature && <div className="popup-right-click__row" onClick={this.onDownloadGeometry}>
                <div className="icon_context_menu">
                    <DownloadSvg className="ContextMenuPopupItem-icon"/>
                </div>
                {store.trans["Download"]}
            </div>}
            <div className={classNames("popup-right-click__row",{"opacity20": this.field_id == null})} onClick={this.onGetIndexField}>
                <div className="icon_context_menu ">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.586 0L0 6.586V9.414L9.414 0H6.586Z" fill="#C5C5C5"/>
                        <path d="M0 0V3.414L3.414 0H0Z" fill="#C5C5C5"/>
                        <path d="M10.5859 14H13.9999V10.586L10.5859 14Z" fill="#C5C5C5"/>
                        <path d="M14 0H12.586L0 12.586V14H1.414L14 1.414V0Z" fill="#C5C5C5"/>
                        <path d="M14.0002 4.586L4.58618 14H7.41418L14.0002 7.414V4.586Z" fill="#C5C5C5"/>
                    </svg>
                </div>
                {store.trans["Get object profile"]}
            </div>
            {store.map.menu_field_id != null && <div className="popup-right-click__row" onClick={this.onClickEditObject}>
                <div className="icon_context_menu ">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.0875 3.0625L0.2625 9.8875C0.0875 10.0625 0 10.2375 0 10.5V13.125C0 13.65 0.35 14 0.875 14H3.5C3.7625 14 3.9375 13.9125 4.1125 13.7375L10.9375 6.9125L7.0875 3.0625Z" fill="#C5C5C5"/>
                        <path d="M13.7375 2.8875L11.1125 0.2625C10.7625 -0.0875 10.2375 -0.0875 9.8875 0.2625L8.3125 1.8375L12.1625 5.6875L13.7375 4.1125C14.0875 3.7625 14.0875 3.2375 13.7375 2.8875Z" fill="#C5C5C5"/>
                    </svg>
                </div>
                {store.trans.object_editing} ({store.trans["double click"]})
            </div>}
        </div>;
    }
}