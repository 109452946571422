import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoStmt2Comp.scss'
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";
import {AgroAhoStepsComp} from "../uploadTable/AgroAhoStepsComp";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoSortColSvg} from "../icons/AgroAhoSortColSvg";
import {AgroAhoSwitcherSvg} from "../icons/AgroAhoSwitcherSvg";
import {AhoStmtSort} from "../../stmt/agroAhoStmtStore";
import {AgroAhoStmtIndcItem} from "./AgroAhoStmtIndcItem";
import {AgroAhoCrossSvg} from "../icons/AgroAhoCrossSvg";

@observer
export class AgroAhoStmt2Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    canNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        return st.contract && st.client;
    }
    onFilterChange(e: any){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doFilterChange(e?.currentTarget?.value);
    }
    onSortClick(sort: AhoStmtSort){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (sort == AhoStmtSort.Indc && st.sortMode == AhoStmtSort.Indc)
            sort = AhoStmtSort.IndcDesc;
        if (sort == AhoStmtSort.IndcDesc && st.sortMode == AhoStmtSort.IndcDesc)
            sort = AhoStmtSort.Indc;
        if (st.sortMode == sort) return;
        if (sort == AhoStmtSort.ExtResearch && !st.isExtResearch) return;
        st.doSortClick(sort);
    }
    onDownloadInstructionsClick(){
        // let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        // Utils.downloadFile(url);
    }
    onClickNext(){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (!this.canNext()) return;
        st.doNextClick();
    }
    onClickCancel(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doCancel();
    }
    onClickBack(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doBackClick();
    }
    onExtCheckboxClick(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doExtCheckboxClick();
    }
    onMainResearchReset(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.doMainResearchReset();
    }
    onExtResearchReset(){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (!st.isExtResearch) return;
        st.doExtResearchReset();
    }


    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore
        let st = store_.stmtStore;
        let arr = st.indcs.map(i=><AgroAhoStmtIndcItem key={i.indc_code} store={store} item={i}/>);
        let mainCnt = st.indcs.filter(f=>f.main).length;
        let extCnt = st.indcs.filter(f=>f.ext).length;

        return <div className="AgroAhoStmt2Comp-main">
            <div className="AgroAhoStmt2Comp-cover">
                <div className="AgroAhoStmt2Comp-topPanel">
                    <div className="AgroAhoStmt2Comp-titleCover">
                        <div className="AgroAhoStmt2Comp-title"><b>{store_.trans['Research plan']}.</b> {
                            store_.trans['Step 2. Selecting laboratory tests']}</div>
                        <AgroAhoStepsComp step={st.stage} steps={4} style2={true}/>
                    </div>
                    <div className="AgroAhoStmt2Comp-msg">
                        <span>{store_.trans['It is possible to divide the indicators into groups: the main and extended studies. The main study will be performed for all samples (=cells), the extended one - only for individual selected cells.']}</span>
                    </div>
                </div>
                <div className="AgroAhoStmt2Comp-centerPanel">
                    <div className="AgroAhoStmt2Comp-tableHover">
                        <div className="AgroAhoStmt2Comp-colIndc">
                            <div className={classNames("AgroAhoStmt2Comp-indcCover",{
                                "AgroAhoStmt2Comp-active": st.sortMode == AhoStmtSort.Indc})}
                                 onClick={this.onSortClick.bind(this, AhoStmtSort.Indc)}>
                                <div className="AgroAhoStmt2Comp-colCaption">{store_.trans.Indicator}</div>
                                <AgroAhoSortColSvg className={classNames("AgroAhoStmt2Comp-colSort", {
                                    "AgroAhoStmt2Comp-colSortDesc": st.lastIndcSort == AhoStmtSort.IndcDesc})}/>
                            </div>
                            <input type="text" className="AgroAhoStmt2Comp-filterInput text-box-editor"
                                   value={st.filter} onChange={this.onFilterChange} placeholder={store_.trans['Search by indicators']}/>
                        </div>
                        <div className="AgroAhoStmt2Comp-colUnit">
                            <div className="AgroAhoStmt2Comp-colCaption">{store_.trans['Unit of measurement']}</div>
                        </div>
                        <div className="AgroAhoStmt2Comp-colGost">
                            <div className="AgroAhoStmt2Comp-colCaption">{store_.trans['GOST / Methodology']}</div>
                        </div>
                        <div className={classNames("AgroAhoStmt2Comp-colResearch",{
                            "AgroAhoStmt2Comp-active": st.sortMode == AhoStmtSort.Research})}
                             onClick={this.onSortClick.bind(this, AhoStmtSort.Research)}>
                            <div className="AgroAhoStmt2Comp-colSortCover">
                                <div className="AgroAhoStmt2Comp-colCaption">{store_.trans['Main research']}</div>
                                <AgroAhoSortColSvg className="AgroAhoStmt2Comp-colSort"/>
                            </div>
                            <div className="AgroAhoStmt2Comp-counter">
                                <span>{mainCnt}</span>
                                <div className="AgroAhoStmt2Comp-crossCover" onClick={this.onMainResearchReset}>
                                    <AgroAhoCrossSvg className="AgroAhoStmt2Comp-cross"/>
                                </div>
                            </div>
                        </div>
                        <div className="AgroAhoStmt2Comp-colSplitter"/>
                        <div className="AgroAhoStmt2Comp-colExtResearch">
                            <div className="AgroAhoStmt2Comp-colExtCheckboxCover" onClick={this.onExtCheckboxClick}>
                                <AgroAhoSwitcherSvg className="AgroAhoStmt2Comp-colExtResearchCheckbox"
                                    checked={st.isExtResearch}/>
                            </div>
                            <div className={classNames("AgroAhoStmt2Comp-colExtSortCover", {
                                "AgroAhoStmt2Comp-active": st.sortMode == AhoStmtSort.ExtResearch,
                                "AgroAhoStmt2Comp-disabled": !st.isExtResearch})}
                                 onClick={this.onSortClick.bind(this, AhoStmtSort.ExtResearch)}>
                                <div className="AgroAhoStmt2Comp-colSvgSortCover">
                                    <div className="AgroAhoStmt2Comp-colCaption">{store_.trans['Extended research']}</div>
                                    <AgroAhoSortColSvg className="AgroAhoStmt2Comp-colSort"/>
                                </div>
                                <div className="AgroAhoStmt2Comp-counter">
                                    <span>{extCnt}</span>
                                    <div className="AgroAhoStmt2Comp-crossExtCover" onClick={this.onExtResearchReset}>
                                        <AgroAhoCrossSvg className="AgroAhoStmt2Comp-cross"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="AgroAhoStmt2Comp-table style-4">
                        {arr}
                    </div>
                </div>
                <div className="AgroAhoStmt2Comp-bottomPanel">
                    <div className="AgroAhoStmt2Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoStmt2Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoStmt2Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <button className="left-sidebar-cancel button-jump AgroAhoStmt2Comp-backButton"
                            onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </button>
                    <button className={classNames("left-sidebar-active AgroAhoStmt2Comp-nextButton",
                         {"AgroAhoStmt2Comp-disabled": !this.canNext(), "button-jump": !!this.canNext()})}
                         onClick={this.onClickNext}>{store_.trans.Next}</button>
                </div>
            </div>
        </div>
    }
}
