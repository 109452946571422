import {CustomEditGeometryTool} from "./CustomEditGeometryTool";

export class EditUndoRedoTool extends CustomEditGeometryTool {
    listen: boolean = false;
    onInstall() {
        let elem = document.getElementById("mapRef");
        document.addEventListener("keydown", this.onSystemKeyDown);
        if (elem != null){
            elem.addEventListener("keyup", this.onKeyUp);
            this.listen = true;
        }
    }
    onUninstall() {
        let elem = document.getElementById("mapRef");
        document.removeEventListener("keydown", this.onSystemKeyDown);
        if (elem != null && this.listen){
            elem.removeEventListener("keyup", this.onKeyUp);
            this.listen = false;
        }

    }
    onSystemKeyDown(event: any){
        if (event.code == "KeyZ" && event.target.tagName.toUpperCase() == 'CANVAS'){
            event.preventDefault();
        }
    }

    onKeyUp(event: KeyboardEvent){
        if (event.code == "KeyZ"){
            event.preventDefault();
            if (this.state.actionManager.canUndo()){
                this.state.actionManager.undo();
            }
        }
        if (event.code == "KeyY"){
            event.preventDefault();
            if (this.state.actionManager.canRedo()){
                this.state.actionManager.redo();
            }
        }
    }

}