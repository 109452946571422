import {CustomStore} from "../../CustomStore";
import {observable} from "mobx";
import {A2UploadBindCustom} from "./A2UploadBindCustom";

export class A2UploadBindColumnStore extends CustomStore implements A2UploadBindCustom{
    @observable
    name: string;//колонка в файле
    @observable
    fileValues: string[];//примеры значений
    @observable
    bindColumnName: string = null;//колнка приёмника
}