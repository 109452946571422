import {CustomStore} from "../../../app/store/CustomStore";
import {observable} from "mobx";
import {VoronovExportStore} from "./VoronovExportStore";
import {fetchJsonGet, fetchJsonPost} from "../../../app/helper/utils/FetchUtils";
import {IAgroExportSuperStore} from "../agroExportPlugin";
import {TextUtils} from "../../../app/helper/utils/TextUtils";
import {TranslateUtils} from "../../../app/helper/lang/TranslateUtils";


export interface AsaMap {
    map_id: number;
    map_name: string;
}

export enum ExportType {
    NotSet = 'NotSet',
    New = 'New',
    Exist = 'Exist'
}

export enum MsgType {
    NoAdd = 'NoAdd',
    NoSave = 'NoSave'
}


export class VoronovSaveStore extends CustomStore<VoronovExportStore>{
    @observable
    showSaveForm: boolean = false;
    @observable
    saveTitle: string = '';
    @observable
    paths: string[] = [];
    @observable
    fieldsCount: number = 0;
    @observable
    exportType: ExportType = ExportType.New;
    @observable
    asaMapId: number = null;
    @observable
    asaMaps: AsaMap[];
    @observable
    showMsgForm: boolean = false;
    @observable
    msgType: MsgType = MsgType.NoAdd;
    msgData: string = '';

    async reset(){
        let store_ = this.root.getRootAs<IAgroExportSuperStore>().agroExport;
        this.saveTitle = '';
        this.exportType = ExportType.New;
        await this.loadAsaMaps();
        this.asaMapId = null;
    }

    async loadAsaMaps(){
        let proj_name = this.root.agro.projectName;
        let url = `/api/projects/${proj_name}/asa2/map/select`;
        let asaMaps = await fetchJsonGet(url).catch(()=>{});
        if (!asaMaps) return;
        this.asaMaps = asaMaps.sort((a: any,b: any)=> TextUtils.stringSortCompare(a.map_name, b.map_name));
    }

    async sendSave(){
        let store_ = this.root.getRootAs<IAgroExportSuperStore>().agroExport;
        if (this.exportType == ExportType.New) {
            let url = `/api/projects/${this.root.agro.projectName}/asa_map/create`;
            let field_ids = this.root.agro2.projectStruct.activeNavigatorPage.selectedFields.map(a => a.id);
            let params = {
                mode:"probe_plan",
                map_name: this.saveTitle,
                field_ds_code: this.parent.field_ds_code,
                //field_filters:{"field_id":{"$in": field_ids}},//<-
                cell_ds_code:this.parent.geoJsonCells.ds_code,
                track_ds_code:this.parent.geoJsonCellTracks.ds_code
            };
            fetchJsonPost(url, params).then(r=>{
                if (!!r?.map_id) {
                    this.root.addInfo(`${store_.trans['Data saved in ASA map']}: «${this.saveTitle}»`)
                } else this.root.addError(r?.message || store_.trans['Save error']);
            }).catch(err => {
                let key_double_msg = 'duplicate key value violates unique constraint "uk_map_name"';
                if (err && err.indexOf(key_double_msg) >= 0) {
                    this.root.addError(TranslateUtils.format(
                        store_.trans['ASA map with name "{0}" already exists'],
                        this.saveTitle));
                } else this.root.addError(err);
            });
        }
        if (this.exportType == ExportType.Exist) {
            // /api/projects/proj_name/asa2/map/map_id/add
            if (!this.asaMapId) return;
            let url = `/api/projects/${this.root.agro.projectName}/asa2/map/${this.asaMapId}/add`;
            let field_ids = this.root.agro2.projectStruct.activeNavigatorPage.selectedFields.map(a => a.id);
            let params = {
                // mode:"probe_plan",
                // map_name: this.saveTitle,
                field_ds_code: this.parent.field_ds_code,
                //field_filters:{"field_id":{"$in": field_ids}},//<-
                cell_ds_code:this.parent.geoJsonCells.ds_code,
                track_ds_code:this.parent.geoJsonCellTracks.ds_code
            };
            fetchJsonPost(url, params).then(r=>{
                if (r?.status == 'error'  && r?.error_code == 'dupl_fields' && r?.dupl_fields?.length) {
                    this.msgData = r.dupl_fields.map((v: any)=>v.field_name).join(', ');
                    this.msgType = MsgType.NoAdd;
                    this.showMsgForm = true;
                } else if (r?.status == 'error' && r?.error_code == 'dupl_cells' && r?.dupl_cells?.length) {
                    this.msgData = r.dupl_cells.map((c: any)=>c.cell_id).join(', ');
                    this.msgType = MsgType.NoSave;
                    this.showMsgForm = true;
                } else if (r?.status == 'ok') {
                    let map_name = this.saveTitle;
                    if (this.exportType == ExportType.Exist) {
                        let maps = this.asaMaps.filter(m=>m.map_id == this.asaMapId);
                        if (maps?.length) map_name = maps[0].map_name;
                    }
                    this.root.addInfo(`${store_.trans['Data saved in ASA map']}: «${map_name}»`)
                } else this.root.addError(r?.message || store_.trans['Save error']);
            }).catch(err => this.root.addError(err));
        }
    }
}