import {ShowRoom2Clickable, ShowRoom2LayerCustomTool} from "./ShowRoom2LayerCustomTool";
import * as mapboxgl from "maplibre-gl";
import {MapboxGeoJSONFeature, PointLike} from "maplibre-gl";
import {ColorHelper} from "../../../helper/utils/ColorHelper";
import {MBUtils} from "../../../helper/utils/MBUtils";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";


export abstract class ShowRoom2LayerPointCustomTool extends ShowRoom2LayerCustomTool implements ShowRoom2Clickable{
    layerIdPoints(): string{
        return this.getLayerId()+"_points";
    }
    layerHeat(): string{
        return this.getLayerId()+"_heat";
    }
    hoveredStateId: any = null;
    generateColorStyle(svg: string = null): any{
        let crops = this.store.dataShowRoomStore.cropDataShort;
        let icon: any = [];
        let def: any = null;
        icon.push("case");
        for (let c of crops.values()) {
            icon.push(["==",["get", "crop_id"], c.id]);
            let colorStr = ColorHelper.numToCssHexRgb(c.color);
            icon.push(colorStr);
        }

        let colorStr = ColorHelper.numToCssHexRgb(this.store.dataShowRoomStore.otherCropColor);
        icon.push(colorStr);
        return icon;
    }
    async generateSprites(svg: string): Promise<any>{
        let map = this.store.map.mapbox;
        let crops = this.store.dataShowRoomStore.cropDataShort;
        let icon: any = [];
        let def: any = null;
        icon.push("case");
        for (let c of crops.values()) {
            let imgName = this.getLayerId()+c.id.toString();
            if (def == null) def = imgName;
            if (!map.hasImage(imgName)){
                let colorStr = ColorHelper.numToCssHexRgb(c.color);
                let svg2 = svg.replace("{{color}}", colorStr);
                await MBUtils.loadSvgToMapbox(svg2, map, 17, 17, imgName);
            }
            icon.push(["==",["get", "crop_id"], c.id]);
            icon.push(imgName);
        }

        let imgName = this.getLayerId()+"_default";
        if (!map.hasImage(imgName)){
            let colorStr = ColorHelper.numToCssHexRgb(this.store.dataShowRoomStore.otherCropColor);
            let svg2 = svg.replace("{{color}}", colorStr);
            await MBUtils.loadSvgToMapbox(svg2, map, 17, 17, imgName);
        }
        icon.push(imgName);
        return icon;
    }

    onMouseMove(e: mapboxgl.MapMouseEvent & ToolEvent) {
        //super.onMouseMove(e);
        if (!this.layerVisible()) return;
        let map = this.store.map.mapbox;
        if (!map.getSource(this.getSourceId())) return;
        let point = e.point;
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(point.x - 3, point.y - 3),
            new mapboxgl.Point(point.x + 3, point.y + 3)
        ];
        let res: MapboxGeoJSONFeature[] = this.map.queryRenderedFeatures(bbox,
            {layers: [this.layerIdPoints()]});
        if (res.length > 0) {
            //res[0].id
            let id = res[0].id;
            this.hoveredStateId = id;
            e.cursor = 'pointer';
            //map.setFilter(this.layerIdPointsHover(), ['==', ['id'], this.hoveredStateId]);
        } else {
            if (this.hoveredStateId !== null) {
                //map.setFilter(this.layerIdPointsHover(), ['boolean', false]);
            }
        }
    }

    onMouseLeave(e: ToolEvent) {
        if (!this.layerVisible()) return;
        let map = this.store.map.mapbox;
        if (!map.getSource(this.getSourceId())) return;
        if (this.hoveredStateId !== null) {
            //map.setFilter(this.layerIdPointsHover(), ['boolean', false]);
        }
        this.hoveredStateId = null;
    }

    abstract loadPopupById(id: any, feature: MapboxGeoJSONFeature):void;

    removeLayers() {
        let map = this.store.map.mapbox;
        if (map.getLayer(this.layerIdPoints())){
            this.removeLayer(this.layerIdPoints());
        }
        if (map.getLayer(this.layerHeat())){
            this.removeLayer(this.layerHeat());
        }
        super.removeLayers();
    }

}