import './LegendBaseMap.scss';
import {observer} from "mobx-react";
import * as React from "react";
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {LegendCommonComp} from "../LegendCommonComp";
import {LegendLinearComp} from "../LegendLinearComp";
import {Utils} from "../../../../helper/utils/Utils";
import {IBaseLayer} from "../../../../store/config/ConfigStore";
import {ColorHelper, IRGBA} from "../../../../helper/utils/ColorHelper";
import {LegendDiscreteComp} from "../LegendDiscreteComp";
import {IDiscreteInterval} from "../../../../store/productSetting/IndexBandProductStore";
import {MinSvg} from "../../../icons/Legend/MinSvg";
import {MyHint} from "../../../Common/MyHint";
import { defaultContainerStyler } from '../../../Common/Legend/Legend';

@observer
export class LegendBaseMap extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    getLinear(bl: IBaseLayer): any{
        let store = this.props.store;
        if (bl.raster_style.values == null || bl.raster_style.values.length == 0) return null;
        let dia: IDiscreteInterval[] = [];
        for(let i = 0; i < bl.raster_style.values.length - 1; i++){
            let t1 = bl.raster_style.values[i];
            let t2 = bl.raster_style.values[i+1];
            dia.push({isTransparent: false, min: t1.value, max: t2.value, color: ColorHelper.parseColor(t1.color)});
        }
        let t1 = bl.raster_style.values[bl.raster_style.values.length - 1];
        dia.push({isTransparent: false, min: t1.value, max: t1.value + 1, color: ColorHelper.parseColor(t1.color)});

        dia = dia.reverse();
        let footer = <React.Fragment>
            <div className="separator_line"/>
            <div className={"legend-panel_linearTitle"}> {store.trans[bl.raster_style.unit_name as keyof typeof store.trans]}</div>
        </React.Fragment>;

        return <LegendDiscreteComp store={store} title={store.trans["Slopes °"]} areaMode={null} intervals={dia} 
            numAreas={[]} showCheckBoxes={false} /*footerComp={footer}*/ />;
    }

    render() {
        let store = this.props.store;
        if (!(store.config?.map_layers?.baselayers) || store.map.currentBaselayerKey == null) return null;
        let bl = store.config.map_layers.baselayers.find((lr) => lr.key == store.map.currentBaselayerKey);
        if (bl == null || bl.raster_style == null) return null;

        let st = defaultContainerStyler(store);

        return <LegendCommonComp store={store} className="LegendBaseMap-common qtransit" style={st}>
            {this.getLinear(bl)}
        </LegendCommonComp>;
    }
}