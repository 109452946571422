import * as React from 'react';

export interface IHelpCompProps {
}

export class HelpComp extends React.PureComponent<IHelpCompProps, undefined> {
    render() {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 16C20 18.2091 18.2091 20 16 20L4 20C1.79086 20 1.75079e-06 18.2091 1.55766e-06 16L5.08584e-07 4C3.15455e-07 1.79086 1.79086 -3.15455e-07 4 -5.08584e-07L16 -1.55766e-06C18.2091 -1.75079e-06 20 1.79086 20 4L20 16Z" fill="#50A9F0"/>
            <path d="M10.625 12.1875H9.37504V11.5625C9.38232 11.1042 9.51181 10.6562 9.75012 10.2648C9.98844 9.8733 10.3269 9.55254 10.7307 9.33562C11.2925 8.93375 11.5625 8.71438 11.5625 8.125C11.5625 7.0375 10.5838 6.875 10 6.875C9.57392 6.91811 9.16068 7.04572 8.78444 7.25037C8.4082 7.45501 8.07653 7.73259 7.80879 8.06687L6.93066 7.1875C7.30912 6.732 7.77652 6.35849 8.30427 6.08983C8.83203 5.82117 9.40905 5.663 10 5.625C11.7082 5.625 12.8125 6.60625 12.8125 8.125C12.8053 8.58325 12.6758 9.03125 12.4375 9.42273C12.1991 9.8142 11.8606 10.135 11.4569 10.3519C10.895 10.7537 10.625 10.9731 10.625 11.5625V12.1875Z" fill="#2B3138"/>
            <path d="M10 15C10.5178 15 10.9375 14.5803 10.9375 14.0625C10.9375 13.5447 10.5178 13.125 10 13.125C9.48223 13.125 9.0625 13.5447 9.0625 14.0625C9.0625 14.5803 9.48223 15 10 15Z" fill="#2B3138"/>
        </svg>;

    }
}
