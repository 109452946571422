import {CustomStore} from "../../CustomStore";
import {AnySourceData} from "maplibre-gl";

export class MapSourceStore extends CustomStore{
    sourceId: string;
    sourceNativeJson: any;

    getSourceJson(): AnySourceData{
        return this.sourceNativeJson;
    }
}
