import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import { ContextMenuCommonComp } from '../../../Common/ContextMenuCommonComp';

@observer
export class FavListDeleteAllComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store = this.props.store;
        store.searchPanel.favoriteList.groups.length = 0;
        store.searchPanel.showDeleteAllScene = false;
    }

    @action
    onClickCancel(){
        let store = this.props.store;
        store.searchPanel.showDeleteAllScene = false;
    }

    render() {
        let store = this.props.store;
        return <ContextMenuCommonComp
                        indentVertical={5}
                        autoCornerWidth={125}
                        autoCornerHeight={155}
                        onClose={this.onClickCancel}
                        className="">
        <div className="left-sidebar__favitem__delete_back">
            <div>{store.trans["Delete all images from Favorites?"]}</div>
            <div className="left-sidebar__favitem__delete_group_button">
                <span className="left-sidebar__favitem__delete_button left-sidebar__favitem__delete_button-red pointer underline-link" onClick={this.onClickDelete}>{store.trans.Delete}</span>
                <span className="left-sidebar__favitem__delete_button pointer underline-link" onClick={this.onClickCancel}>{store.trans.Cancel}</span>
            </div>
        </div>;
        </ContextMenuCommonComp>
    }
}
