import {IPaint, IPaintType} from "./styleInterface/IPaint";
import {IStyle} from "./styleInterface/IStyle";
import {IStyleItem} from "./styleInterface/IStyleItem";
import {IStyleItemSimple} from "./styleInterface/IStyleItemSimple";
import {IStyleItemCategorized} from "./styleInterface/IStyleItemCategorized";
import {getDefaultIPaint} from "./defaultIntStyle";
import {Utils} from "../utils/Utils";
import {isBoolean} from "lodash-es";

export interface ILegendInfo{
    title: string;
    categories: ILayerLegendCategory[];
}
export interface ILayerLegendCategory {
    name: string;
    paint: IPaint;
    paintTypes: Set<IPaintType>;
    categoryKey: any;
}
export class IntStyleLegend {
    public static getLegendInfo(style: IStyle, currentZoom: number): ILegendInfo{
        if (style?.legend?.visible != true) return null;
        let si: IStyleItem = IntStyleLegend.getStyleItem(style, currentZoom);
        if (si == null) return null;
        let cats: ILayerLegendCategory[] = [];
        if (si.type == "simple" || si.type == null){
            let simple: IStyleItemSimple = si as IStyleItemSimple;
            let pt = new Set<IPaintType>();
            let usePaint: IPaint = {};
            if (simple.paint){
                usePaint = Utils.deepCloneOverride(usePaint, si.paint);
                IntStyleLegend.getPaintTypes(si.paint, pt);
            }
            cats.push({paint: usePaint, name: "", paintTypes: pt, categoryKey: null});
        }
        if (si.type == "categorized"){
            let c: IStyleItemCategorized = si as IStyleItemCategorized;

            c.categories.forEach(sc => {
                let pt = new Set<IPaintType>();
                let usePaint = {};
                if (si.paint){
                    usePaint = Utils.deepCloneOverride(usePaint, si.paint);
                }
                if (sc.paint){
                    usePaint = Utils.deepCloneOverride(usePaint, sc.paint);
                    IntStyleLegend.getPaintTypes(sc.paint, pt);
                }
                if (isBoolean(sc.visible) && !sc.visible) return;
                let name: any = null;
                if (sc.title != null) name = sc.title;
                if (name == null && sc.value !== undefined) name = sc.value;
                if (name == null) name = "null";
                let r: ILayerLegendCategory = {
                    paint: usePaint,
                    name: name,
                    paintTypes: pt,
                    categoryKey: sc.value
                };
                cats.push(r);
            });
        }
        let li: ILegendInfo = {title: style?.legend?.title, categories: cats};
        return li;
    }
    private static getPaintTypes(p: IPaint, storage: Set<IPaintType>){
        if (p.polygon != null) storage.add(IPaintType.polygon);
        if (p.label != null) storage.add(IPaintType.label);
        if (p.point != null) storage.add(IPaintType.point);
        if (p.polyline != null) storage.add(IPaintType.polyline);
    }
    private static getStyleItem(style: IStyle, currentZoom: number): IStyleItem{
        let sia: IStyleItem[] = [];
        style.styles.forEach(a => {
            let ok = true;
            if (a.minZoom != null && a.minZoom > currentZoom) ok = false;
            if (a.maxZoom != null && a.maxZoom <= currentZoom) ok = false;
            if (ok) sia.push(a);
        });
        let si: IStyleItem = null;
        if (sia.length > 1){
            let cat = sia.find(a => a.type == "categorized");
            if (cat != null){
                si = cat;
            }else si = sia[0];
        }
        if (sia.length == 1) si = sia[0];
        return  si;
    }

}