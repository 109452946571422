import * as React from 'react';
import {RefObject} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDialogComp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {AgroAhoProgressComp} from "./AgroAhoProgressComp";
import {AhoModalType} from "../agroAhoStore";
import {AgroAhoLoadPanelComp} from "./AgroAhoLoadPanelComp";
// import {AgroAhoDelPanelComp} from "./AgroAhoDelPanelComp";
import {AgroAhoEditCardPanelComp} from "./AgroAhoEditCardPanelComp";
import {AgroAhoExportPanelComp} from "./AgroAhoExportPanelComp";
import {AgroAhoExportLayersPanelComp} from "./AgroAhoExportLayersPanelComp";
import {AgroAhoDelPanelComp} from "./AgroAhoDelPanelComp";
import {AgroAhoDelIndicatorPanelComp} from "./AgroAhoDelIndicatorPanelComp";
import {AgroAhoDelIndicatorsPanelComp} from "./AgroAhoDelIndicatorsPanelComp";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {AgroAhoUploadPanelComp} from "./card/AgroAhoUploadPanelComp";
import {AgroAhoSelectColumnPanelComp} from "./card/AgroAhoSelectColumnPanelComp";
import {AgroAhoVisPanelComp} from "./cards/AgroAhoVisPanelComp";
import {AgroAhoUploadIndsSelPanelComp} from "./AgroAhoUploadIndsSelPanelComp";
import {AhoUploadTableStage} from "../agroAhoUploadTableStore";
import {AgroAhoTableUpload1Comp} from "./uploadTable/AgroAhoTableUpload1Comp";
import {AgroAhoTableUpload2Comp} from "./uploadTable/AgroAhoTableUpload2Comp";
import {AgroAhoTableUpload3Comp} from "./uploadTable/AgroAhoTableUpload3Comp";
import {AgroAhoTableUpload4Comp} from "./uploadTable/AgroAhoTableUpload4Comp";
import {AgroAhoTableUpload5Comp} from "./uploadTable/AgroAhoTableUpload5Comp";
import {AgroAhoTableUpload6Comp} from "./uploadTable/AgroAhoTableUpload6Comp";
import {AgroAhoDelTourPanelComp} from "./uploadTable/AgroAhoDelTourPanelComp";
import {AgroAhoDelRNPanelComp} from "./card/AgroAhoDelRNPanelComp";
import {AgroAhoDelRNItemPanelComp} from "./card/AgroAhoDelRNItemPanelComp";
import {AgroAhoExportRNPanelComp} from "./AgroAhoExportRNPanelComp";
import {AgroAhoMergeIsoXmlComp} from "./cards/AgroAhoMergeIsoXmlComp";
import {AgroAhoDelFieldsPanelComp} from "./AgroAhoDelFieldsPanelComp";
import {AgroAhoCopyFieldsComp} from "./card/AgroAhoCopyFieldsComp";
import {AgroAhoDelToursPanelComp} from "./tours/AgroAhoDelToursPanelComp";
import {AgroAhoCopyTourComp} from "./tours/AgroAhoCopyTourComp";
import {AgroAhoMergeToursComp} from "./tours/AgroAhoMergeToursComp";
import {AgroAhoEditTourComp} from "./tours/AgroAhoEditTourComp";
import {AgroAhoEditCellsPanelComp} from "./card/AgroAhoEditCellsPanelComp";

@observer
export class AgroAhoDialogComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
        this.modalRootElement = React.createRef();
    }

    modalRootElement: RefObject<HTMLDivElement>;

    componentDidMount() {
        let store = this.props.store.agroAhoStore;
        let root = this.modalRootElement.current;
        let el = root.querySelector('.AgroAhoDialogComp-main');
        store.setModal(root, el);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let stage = store_.uploadTableStore.ahoUploadTableStage;

        return <div className="AgroAhoDialogComp-modalRoot" ref={this.modalRootElement}>
            <div className={classNames("AgroAhoDialogComp-main",{
                "AgroAhoDialogComp-hidden": !store_.ahoDialogShow})}>
                {store_.ahoModalType == AhoModalType.FileLoad && <AgroAhoLoadPanelComp store={store}/>}
                {store_.ahoModalType == AhoModalType.ExportIndicators && <AgroAhoExportPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.ExportRN && <AgroAhoExportRNPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.EditCard && <AgroAhoEditCardPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.ExportLayers && <AgroAhoExportLayersPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.DeleteCards && <AgroAhoDelPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.DeleteIndicator && <AgroAhoDelIndicatorPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.DeleteIndicators && <AgroAhoDelIndicatorsPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.DeleteTour && <AgroAhoDelTourPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.UploadCells && <AgroAhoUploadPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.SelectFile && <AgroAhoSelectColumnPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.VisualizationSetup && <AgroAhoVisPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.MergeIsoXml && <AgroAhoMergeIsoXmlComp store={store}/> }
                {store_.ahoModalType == AhoModalType.UploadIndsSelect && <AgroAhoUploadIndsSelPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.TableUpload && stage == AhoUploadTableStage.Step1 &&
                    <AgroAhoTableUpload1Comp store={store}/> }
                {store_.ahoModalType == AhoModalType.TableUpload && stage == AhoUploadTableStage.Step2 &&
                    <AgroAhoTableUpload2Comp store={store}/> }
                {store_.ahoModalType == AhoModalType.TableUpload && stage == AhoUploadTableStage.Step3 &&
                    <AgroAhoTableUpload3Comp store={store}/> }
                {store_.ahoModalType == AhoModalType.TableUpload && stage == AhoUploadTableStage.Step4 &&
                    <AgroAhoTableUpload4Comp store={store}/> }
                {store_.ahoModalType == AhoModalType.TableUpload && stage == AhoUploadTableStage.Step5 &&
                    <AgroAhoTableUpload5Comp store={store}/> }
                {store_.ahoModalType == AhoModalType.TableUpload && stage == AhoUploadTableStage.Step6 &&
                    <AgroAhoTableUpload6Comp store={store}/> }
                {store_.ahoModalType == AhoModalType.DeleteRecNorm && <AgroAhoDelRNPanelComp store={store}/>}
                {store_.ahoModalType == AhoModalType.DeleteRecNormItem && <AgroAhoDelRNItemPanelComp store={store}/>}
                {store_.ahoModalType == AhoModalType.DeleteFields && <AgroAhoDelFieldsPanelComp store={store}/>}
                {store_.ahoModalType == AhoModalType.CopyFields && <AgroAhoCopyFieldsComp store={store}/>}
                {store_.ahoModalType == AhoModalType.DeleteTours && <AgroAhoDelToursPanelComp store={store}/> }
                {store_.ahoModalType == AhoModalType.CopyTour && <AgroAhoCopyTourComp store={store}/> }
                {store_.ahoModalType == AhoModalType.MergeTours && <AgroAhoMergeToursComp store={store}/> }
                {store_.ahoModalType == AhoModalType.EditTour && <AgroAhoEditTourComp store={store}/> }
                {store_.ahoModalType == AhoModalType.EditCells && <AgroAhoEditCellsPanelComp store={store}/> }
            </div>
        </div>
    }
}
