// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FavSceneTitleComp {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
  padding: 19px 14px 0px;
  display: flex;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/FavoritePanel/FavoritePanel.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,WAAA;AACJ","sourcesContent":[".FavSceneTitleComp {\n    font-family: Open Sans;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 21.79px;\n    text-align: left;\n    padding: 19px 14px 0px;\n    display: flex;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
