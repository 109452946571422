import React, { useState } from "react";
import { A2ConfirmCreateStore, CreateFieldMode } from "../../../../../store/agro/Confirms/A2ConfirmCreateStore";
import { RadioButton } from "../../../../Common/RadioButton";
import "./A2ConfirmCreateFormComp.scss";

interface IA2ConfirmCreateFormCompProps {
    confirmStore : A2ConfirmCreateStore;
}

const A2ConfirmCreateFormComp = (props: IA2ConfirmCreateFormCompProps) => {
    let cs = props.confirmStore;
    let tr = cs.root.trans;

    const getFieldNames = (mode: CreateFieldMode) => {
        if (! cs.baseName) return "";
        return mode == CreateFieldMode.multiPart? cs.baseName :
        Array(cs.fieldsCount).fill(cs.baseName).map((a, i) => a + (i == 0? '': '_' + i)).join(", ");
    }

    const [createMode, setCreateMode] = useState(cs.createMode);
    const [fieldNames, setFieldNames] = useState(getFieldNames(cs.createMode));
    
    const onClickCancel = () => {
        cs.doCancel();
    }
    const onClickCreate = () => {
        cs.doHide();
        cs.onCreate(createMode);        
    }

    return <div className="Form-backgray">
        <div className="Forms-self-center Forms-base"  style={{width: "385px"}}>
            <div className="Forms-headerText Forms-marginTop Forms-marginLR Forms-headerBottomMargin">{tr.object_create}</div>
            <div className="Forms-marginLR">
                <div className="A2ConfirmCreateFormComp-select-type">
                    <div className="A2ConfirmCreateFormComp-select-type-line">
                        <RadioButton checked={createMode == CreateFieldMode.multiPart} 
                                    onClick={() => { 
                                        cs.createMode = CreateFieldMode.multiPart; 
                                        setCreateMode(CreateFieldMode.multiPart);
                                        setFieldNames(getFieldNames(CreateFieldMode.multiPart));
                                    }} />
                        <div className="A2ConfirmCreateFormComp-select-type-line-text">{tr["One field of several contours"]}</div>
                    </div>
                    <div className="A2ConfirmCreateFormComp-select-type-line">
                        <RadioButton checked={createMode == CreateFieldMode.multiField} 
                                    onClick={() => { 
                                        cs.createMode = CreateFieldMode.multiField;
                                        setCreateMode(CreateFieldMode.multiField);
                                        setFieldNames(getFieldNames(CreateFieldMode.multiField));
                                    }} />
                        <div className="A2ConfirmCreateFormComp-select-type-line-text">{tr["Several fields"]}</div>
                    </div>
                </div>
                <div className="A2ConfirmCreateFormComp-stats">
                    <div className="gray-text">{`${tr["Fields:"]} ${cs.fieldsCount}`}</div>
                    <div className="gray-text">{`${tr["Names:"]} ${fieldNames}`}</div>
                </div>
            </div>
            <div className="Forms-baseline Forms-topIdent2" />
            <div className="A2ConfirmCreateFormComp-buttons">
                <button onClick={onClickCancel} style={{minWidth: "100px"}}
                        className="large-button left-sidebar-cancel button-jump">{tr.Cancel}</button>
                <button onClick={onClickCreate} style={{minWidth: "100px"}}
                        className="large-button left-sidebar-active button-jump">{tr.Create}</button>
            </div>
        </div>
    </div>;
}

export default A2ConfirmCreateFormComp;

