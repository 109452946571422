import { observable } from "mobx";
import { save } from "../../PermalinkDecor";
import { A2ConfirmActionStore } from "./A2ConfirmActionStore";
import { CustomConfirmStore } from "../../CustomConfirmStore";

export enum CreateFieldMode {
    multiPart, multiField
}

export class A2ConfirmCreateStore extends CustomConfirmStore{
    @observable
    createMode: CreateFieldMode = CreateFieldMode.multiField;

    @observable
    fieldsCount: number = 0;

    @observable
    baseName: string = "";

    onCreate: (mode: CreateFieldMode) => void;
}

