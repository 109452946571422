import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './ShowRoom2RightYieldPerGp.scss';
import {RadioButton} from "../../../Common/RadioButton";
import {action, observable} from "mobx";
import {SoilGridsSourcePalleteComp} from "../SoilGrids/SoilGridsSourcePalleteComp";
import classNames from "classnames";

@observer
export class ShowRoom2RightYieldPerGp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    radio_Select: number = 0;

    @action
    onClickRadio(event: any, data: any){
       //this.radio_Select = data;
    }

    @observable
    mini: boolean = true;

    @action
    onClickMinimize(){
        this.mini = !this.mini;
    }

    render() {
        let store = this.props.store;
        //console.log('cropid='+store.dataShowRoomStore.filtersProject.cropId);
        //paddy cropid=7
        //wheat cropid=11
        let colors: string[] = [];
        let labels: string[] = [];
        if (store.dataShowRoomStore.filtersProject.cropId == 11) {
            colors = ['rgb(7,159,0)', 'rgb(255,213,0)', 'rgb(217,84,87)'];
            labels = ['3300', '2250', '1200'];
        }else{
            colors = ['rgb(7,159,0)', 'rgb(255,213,0)', 'rgb(217,84,87)'];
            labels = ['4100', '3000', '1900'];
        }

        return <div className="ShowRoom2RightYieldPerGp-main">
            <div className={classNames("ShowRoom2RightYieldPerGp-left qtransit", {"ShowRoom2RightYieldPerGp-leftMini": this.mini})}>
                <div className="ShowRoom2RightYieldPerGp-leftInner">
                    <div className="ShowRoom2RightYieldPerGp-title">Yield map per GP</div>
                    <RadioButton checked={this.radio_Select == 0} onClick={this.onClickRadio} data={0} className="ShowRoom2RightYieldPerGp-radio" >
                        <div className="ShowRoom2RightYieldPerGp-radioLabel">Average yield</div>
                    </RadioButton>
                    <RadioButton checked={this.radio_Select == 1} onClick={this.onClickRadio} data={1}
                                 className="ShowRoom2RightYieldPerGp-radio opacity30" >
                        <div className="ShowRoom2RightYieldPerGp-radioLabel">Crop area</div>
                    </RadioButton>
                    <RadioButton checked={this.radio_Select == 2} onClick={this.onClickRadio} data={2}
                                 className="ShowRoom2RightYieldPerGp-radio opacity30" >
                        <div className="ShowRoom2RightYieldPerGp-radioLabel">Gross yield</div>
                    </RadioButton>
                    <RadioButton checked={this.radio_Select == 3} onClick={this.onClickRadio} data={3}
                                 className="ShowRoom2RightYieldPerGp-radio opacity30" >
                        <div className="ShowRoom2RightYieldPerGp-radioLabel">Point yield</div>
                    </RadioButton>
                </div>
            </div>
            <div className={classNames("ShowRoom2RightYieldPerGp-right qtransit", {"ShowRoom2RightYieldPerGp-rightMini": this.mini})}>
                <div className={classNames("ShowRoom2RightYieldPerGp-minimizer qtransit")} onClick={this.onClickMinimize}>
                    <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames({"ShowRoom2RightYieldPerGp-minimizerMini": this.mini})}>
                        <path d="M0.722654 7.00012L3.88575 3.77083C4.03841 3.61519 4.03841 3.38506 3.88575 3.22942L0.722655 0.000121784L0.000245983 0.541944L2.89844 3.50033L0.000721152 6.4583L0.722654 7.00012Z" fill="#C5C5C5"/>
                    </svg>
                </div>
                <div className="ShowRoom2RightYieldPerGp-rightTitle">kg/ha</div>
                <div className="ShowRoom2RightYieldPerGp-rightPallete">
                    <SoilGridsSourcePalleteComp colors={colors} labels={labels} />
                </div>
            </div>
        </div>;
    }
}
