import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditCardPanelComp.scss'
import {action} from "mobx";
import {A2NavigatorItemStore} from "../../../app/store/agro/A2NavigatorItemStore";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";

@observer
export class AgroAhoEditCardPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickEdit(){
        let store_ = this.props.store.agroAhoStore;
        store_.cardStore.doEditCard();
        store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    @action
    inputChanged(e:any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.newName = e.target.value;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let t: A2NavigatorItemStore = store.agro2.projectStruct.navigatorRootItem;
        let arr: any[] = [<span key="0">{store.agro.projectTitle}</span>];

        while (t != null){
            if (t.nextItem == null) break;
            arr.push(<svg key={arr.length} width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 6L4 3L0 0V6Z" fill="#6B707B"/>
            </svg>);
            arr.push(<span key={arr.length}>{t.column_stringValue}</span>);
            t = t.nextItem;
        }

        return <div className="AgroAhoEditCardPanelComp-main">
            <div className="AgroAhoEditCardPanelComp-caption">{store.trans['Editing a agricultural soil analysis map (ASA Map)']}</div>
            <div className="AgroAhoEditCardPanelComp-path">{arr}</div>
            <div className="AgroAhoEditCardPanelComp-line"> </div>
            <div className="AgroAhoEditCardPanelComp-title AgroAhoEditCardPanelComp-titleAsterisk">{store.trans.Title}</div>
            <input type="text" className="AgroAhoEditCardPanelComp-input text-box-editor" value={store_.cardStore.newName}
                   placeholder={store.trans['Trial evaluation of the work of cartograms']} onChange={this.inputChanged}/>
            <div className="AgroAhoEditCardPanelComp-line"> </div>
            <div className="AgroAhoEditCardPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump popup-exp-2button-button text-next AgroAhoEditCardPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className="large-button left-sidebar-active button-jump popup-exp-2button-button"
                    onClick={this.onClickEdit}>{store.trans.Save}</button>
            </div>
        </div>
    }
}
