import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";

@observer
export class OrderStatusWarning extends React.PureComponent<IStoreProps, undefined> {
    render() {
        let store = this.props.store;
        return <div className="journal-status warning">
            <svg width="20" height="20" viewBox="0 0 20 20"xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 15C9.25 15 8.75 14.5 8.75 13.75C8.75 13 9.25 12.5 10 12.5C10.75 12.5 11.25 13 11.25 13.75C11.25 14.5 10.75 15 10 15ZM11.25 11.25H8.75V5H11.25V11.25Z"/>
            </svg>
            <div className="journal-status-name">{store.trans.Ready}</div>
        </div>
    }
}
