import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditIndicatorPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {TranslateUtils} from "../../../app/helper/lang/TranslateUtils";
import {AgroAhoSwitcherSvg} from "./icons/AgroAhoSwitcherSvg";
import classNames from "classnames";
import {AgroAhoRectCheckboxSvg} from "./AgroAhoRectCheckboxSvg";
import {TourForDelIndc, TourForEditIndc} from "../agroAhoTourStore";
import {AhoUtils} from "../agroAhoUtils";
import {AgroAhoDropDownComp} from "./uploadTable/AgroAhoDropDownComp";
import {TextUtils} from "../../../app/helper/utils/TextUtils";
import {IDropDownItem} from "../../../app/components/Common/DropDownComp";

@observer
export class AgroAhoEditIndicatorPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickEdit(){
        if (!this.canEdit()) return;
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;
        let ind = cstore.editableIndicator;
        let newInd = cstore.editableNewIndicator;
        let tour_ids = tstore.toursForEditIndc.filter(t=>t.edit).map(t=>t.tour.tour_id);
        cstore.editIndicator(cstore.card.map_id, ind, newInd, tour_ids/*, cstore.editIndcSelFields*/);
        store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    selFieldCheckboxClick(){
        if (this.isCheckboxDisabled()) return;
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.editIndcSelFields = !cstore.editIndcSelFields;
    }

    isCheckboxDisabled(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        return !cstore.getFieldSelected().length;
    }

    onTourClick(t: TourForEditIndc){
        t.edit = !t.edit;
    }

    canEdit(){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        let cstore = store_.cardStore;
        return cstore.editableNewIndicator && !tstore.toursForEditIndc.every(t=>!t.edit);
    }

    onIndcChange(item: IDropDownItem, newIndex: number){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.editableNewIndicator = item.data;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let tstore = store_.tourStore;
        let cstore = store_.cardStore;
        let pstore = store_.projStore;
        let ind = cstore.editableIndicator;
        let toursItems: any = tstore.toursForEditIndc.map(t=><div key={t.tour.tour_id}
            className="AgroAhoEditIndicatorPanelComp-tourItem" onClick={this.onTourClick.bind(this, t)}>
            <AgroAhoRectCheckboxSvg className="AgroAhoEditIndicatorPanelComp-tourCheckbox" checked={t.edit}/>
            <div className="AgroAhoEditIndicatorPanelComp-tourCaption">{t.tour.tour_name}</div>
            <div className="AgroAhoEditIndicatorPanelComp-tourDate">{store_.format_date(t.tour.tour_date)}</div>
        </div>);

        let indcs_ = pstore.indicators.slice().filter(i=>i.indc_id != ind.indc_id).sort((a,b) =>
            TextUtils.stringSortCompare(a.indc_name, b.indc_name));

        let class_ = 'AgroAhoEditIndicatorPanelComp-typeItem';
        let indcs: IDropDownItem[] = indcs_.map(i=>{
            return {key: i.lower_code, class: class_, value: i.indc_name, data: i,
                childNode: <span className="AgroAhoEditIndicatorPanelComp-typeCover">
                    <span className="AgroAhoEditIndicatorPanelComp-itemIndcText">{i.indc_name}</span>
                    <span className="AgroAhoEditIndicatorPanelComp-itemIndcUnit">{i.unit}</span>
                    <span className="AgroAhoEditIndicatorPanelComp-itemIndcStandart">{i.standard}</span>
                </span>}
        });
        // indcs.unshift({key: '', data: 0, value: '', class: class_});

        return <div className="AgroAhoEditIndicatorPanelComp-main">
            <div className="AgroAhoEditIndicatorPanelComp-caption">{store_.trans['Editing an indicator']}</div>
            <div className="AgroAhoEditIndicatorPanelComp-msgCover">
                <div className="AgroAhoEditIndicatorPanelComp-indcName">{ind.indc_name}</div>
                <div className="AgroAhoEditIndicatorPanelComp-indcUnit">{ind.unit}</div>
            </div>
            <div className="AgroAhoEditIndicatorPanelComp-indcStandart">{ind.standard}</div>


            <div className="AgroAhoEditIndicatorPanelComp-line"> </div>
            <div className="AgroAhoEditIndicatorPanelComp-toursCaption">
                {store_.trans['Select tours to edit the indicator']}</div>
            <div className="AgroAhoEditIndicatorPanelComp-toursList style-4">{toursItems}</div>
            <div className="AgroAhoEditIndicatorPanelComp-line"> </div>
            <div className="AgroAhoEditIndicatorPanelComp-toursCaption">
                {store_.trans['Replace with indicator']}</div>

            <div className="AgroAhoEditIndicatorPanelComp-selIndcTitle">
                {store_.trans.Indicator}</div>
            <AgroAhoDropDownComp items={indcs} onChange={this.onIndcChange}
                                 currentKey={cstore.editableNewIndicator?.lower_code}
                                 className="AgroAhoEditIndicatorPanelComp-selectIndc"
                                 emptyClass="AgroAhoEditIndicatorPanelComp-colDataTypeEmpty"
                                 emptyText={'--- ' + store_.trans['Empty field'] + ' ---'}/>

            <div className="AgroAhoEditIndicatorPanelComp-splitter"/>
            <div className="AgroAhoEditIndicatorPanelComp-lineButton"/>
            <div className="AgroAhoEditIndicatorPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoEditIndicatorPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className={classNames("large-button left-sidebar-active", {
                    "AgroAhoEditIndicatorPanelComp-disabled": !this.canEdit(),
                    "button-jump": this.canEdit()})}
                    onClick={this.onClickEdit}>{store.trans.Save}</button>
            </div>
        </div>;
    }
}
