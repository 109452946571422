import * as mapboxgl from "maplibre-gl";
import {LngLat, LngLatLike, Point} from "maplibre-gl";
import {Utils} from "./Utils";
import {BBox, Position} from "geojson";
import {isArray, isNumber} from "lodash-es";

export interface IDmsResult{
    whole: number, minutes: number, seconds: number, dir: 'N' | 'W' | 'S' | 'E'
}
export class MBUtils {

    static llEquals(p1: LngLat, p2: LngLat): boolean{
        return p1.lat == p2.lat && p1.lng == p2.lng;
    }

    static setLayerVisibility(mapbox: mapboxgl.Map, layer : string, visible : boolean) {
        if (mapbox.getLayer(layer))
            mapbox.setLayoutProperty(layer, 'visibility', visible? 'visible' : 'none');
    }

    static latLngtoStr(ll: number, s: "lat" | "lon"): string{
        let t = MBUtils.coordToDMS(ll, s);
        let coordStr = `${t.whole}°${t.minutes}'${t.seconds}" ${t.dir}`;
        return coordStr;
    }
    static coordToDMS(input: number, dim: "lat" | "lon"):IDmsResult {
        var dirs = { lat: ['N', 'S'], lon: ['E', 'W'] }[dim] || '';
        var dir = dirs[input >= 0 ? 0 : 1];
        var abs = Math.abs(input);
        var whole = Math.floor(abs);
        var fraction = abs - whole;
        var fractionMinutes = fraction * 60;
        var minutes = Math.floor(fractionMinutes);
        var seconds = Math.floor((fractionMinutes - minutes) * 60);

        return {
            whole: whole,
            minutes: minutes,
            seconds: seconds,
            dir: <any>dir
        };
    }

    private static beautifulColors: number[] = [
        0x00ffff, 0x00ff00, 0xff0000,
        0xffff00,
        0xff00ff,0x0000ff,0x696400,
        0x6558a0,0xdb7380,0xd86a43,0x7c2d1c,
        0x99c45a,0x83531e,0x3eb5f1,0x1d6887];
    //Красивые цвета для графиков. existsColors - цвета которые нельзя использовать.
    static getNewColor(existsColors: number[]): number{
        let dic = Utils.toObject(existsColors.map(a => a.toString()), true);
        //получаем разрешенные цвета
        let c = MBUtils.beautifulColors.filter(a => !dic[a.toString()]);
        if (c.length == 0) {
            while(true) {
                let c2 = Math.round(Math.random() * 0xffffff);
                if (!dic[c2.toString()]) return c2;
            }
        }
        return c[0];
    }
    static getNewColorIndex(index: number): number{
        return MBUtils.beautifulColors[index];
    }

    static createBbox(p: Point, size: number): [Point, Point]{
        return [new Point(p.x - size, p.y - size), new Point(p.x + size, p.y+size)];
    }
    static pointToPosition(p: mapboxgl.Point): Position{
        return [p.x, p.y];
    }
    static positionToPoint(p: Position): mapboxgl.Point{
        return new Point(p[0], p[1]);
    }

    static positionToLL(p: Position): LngLat{
        return new LngLat(p[0], p[1]);
    }
    static llToPosition(p: LngLatLike): Position{
        let x;
        let y;
        if (isArray(p)){
            x = p[0];
            y = p[1];
        }
        if (isNumber((<any>p)['lat'])) y = (<any>p)['lat'];
        if (isNumber((<any>p)['lon'])) x = (<any>p)['lon'];
        if (isNumber((<any>p)['lng'])) x = (<any>p)['lng'];
        return [x, y];
    }

    static isEqualPoint(p1: Position, p2: Position): boolean{
        return MBUtils.isEqualCoord(p1[0], p2[0]) && MBUtils.isEqualCoord(p1[1], p2[1]);
    }

    static isEqualCoord(c1: number, c2: number):boolean{
        return Math.abs(c1 - c2)< 0.0000001;
    }

    static centerOfBox(bbox: BBox): Position{
        return [(bbox[0] + bbox[2])/2, (bbox[1] + bbox[3])/2];
    }

    //загрузка SVG как image в mapbox
    static loadSvgToMapbox(svgStr: string, mapbox: mapboxgl.Map, width: number, height: number, imgName: string): Promise<boolean>{
        return new Promise<boolean>((resolve, reject)=> {
            if (mapbox.hasImage(imgName)) resolve(false);
            let img = new Image(15, 15);
            img.onerror = (event) => {
                reject(event);
            };
            img.onload = () => {
                mapbox.addImage(imgName, img);
                resolve(true);
            };
            img.src = "data:image/svg+xml;base64," + btoa(svgStr);
        });
    }
}
