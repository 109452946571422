import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {IndexByPointTableRowComp} from "./IndexByPointTableRowComp";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import autoBindReact from "auto-bind/react";

@observer
export class IndexByPointTableComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;

        let arr: any[] = [];
        store.indexByPointer.points.forEach((a, idx) => {
            arr.push(<IndexByPointTableRowComp store={store} point={a} key={a.id.toString()} />)
        });
        //options= {{classNames:{scrollContent:"width100 height100 IndexByPointTableComp"}}}
            return <SimpleBar className="width100 height100 IndexByPointTableComp" forceVisible="y" autoHide={false} >
                {arr}
            </SimpleBar>;
    }
}
