import * as React from 'react';
import {observer} from "mobx-react";
import './AgroAhoTopSpinnerComp.scss'
import {IAgroAhoStoreProps} from "../agroAhoPlugin";


@observer
export class AgroAhoTopSpinnerComp extends React.Component<IAgroAhoStoreProps, undefined> {

    render() {
        return <div className="AgroAhoTopSpinnerComp-main">
            <div className="AgroAhoTopSpinnerComp-cover">
                <div className="loading-search">
                    <div className="spinner"/>
                </div>
            </div>
        </div>
    }
}
