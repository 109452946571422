import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../app/helper/structs/IStoreProps";
//import {MeteoPresetColorComp} from './MeteoPresetColorComp'
import {DropDownComp, IDropDownItem} from "../../../app/components/Common/DropDownComp";
import {action, observable} from "mobx";
import {ReactDoubleDatePicker2Comp} from "../../../app/components/Common/ReactDoubleDatePicker2Comp";
import {MeteoStore, PeriodMode, ViewMode} from "../MeteoStore";
import classNames from "classnames";
import {HandleInteractionKind, MultiSlider} from "@blueprintjs/core";
import {ColorHelper} from "../../../app/helper/utils/ColorHelper";
import { CheckboxMiniComp, CheckboxSize, TriState } from '../../../app/components/Common/CheckboxMiniComp';
import './MeteoPanelComp.scss'
import {IMeteoStoreProps} from "../meteoPlugin";
import { CalendarComp } from '../../../app/components/Common/CalendarComp';
import { Utils } from '../../../app/helper/utils/Utils';
import { RadioButton } from '../../../app/components/Common/RadioButton';
import DropDownRangeSelector, { IRangeValues } from '../../../app/components/Common/DropDownRangeSelector/DropDownRangeSelector';
import { DateUtils } from '../../../app/helper/utils/DateUtils';
import { SwitchText } from '../../../app/components/Common/SwitchText';
import { Fragment } from 'react';
import MeteoReportComp from './MeteoReportComp';
import { isDate } from 'lodash-es';

@observer
export class MeteoPanelComp extends React.Component<IMeteoStoreProps, undefined> {
    constructor(props: IMeteoStoreProps) {
        super(props);
        autoBindReact(this);
        let meteo = this.props.store.meteoStore;
        if (meteo.percents == null) {
            meteo.loadSettings();
        }
    }

    @action
    onParamChanged(item: IDropDownItem) {
        let meteo = this.props.store.meteoStore;
        if (meteo.currentMeteoParam == item.data) return;
        meteo.lastParamSettings[meteo.currentMeteoParam.code] = {
            filterChecked : meteo.filterChecked,
            filter : meteo.currentFilter,
            filterValue : meteo.currentFilterValue
        }
        meteo.currentMeteoParam = item.data;
        let s = meteo.lastParamSettings[item.data.code];
        if (s) {
            meteo.currentFilter = s.filter;
            meteo.currentFilterValue = s.filterValue;
            meteo.currentFilterTextValue = s.filterValue?.toString();
            meteo.filterChecked = s.filterChecked;
        }
        meteo.somethingChanged = true;
    }

    @action
    onFuncChanged(item: IDropDownItem) {
        let meteo = this.props.store.meteoStore;
        if (meteo.currentFunction == item.data) return;
        meteo.currentFunction = item.data;
        if (meteo.currentFunction.code == 'norm') meteo.periodMode = PeriodMode.Custom;
        meteo.somethingChanged = true;
    }

    @action
    onFilterChanged(item: IDropDownItem) {
        let meteo = this.props.store.meteoStore;
        if (meteo.currentFilter == item.data) return;
        meteo.currentFilter = item.data;        
        meteo.somethingChanged = true;
    }

    @action
    onDatesChanged() {
        let meteo = this.props.store.meteoStore;
        meteo.validInterval = meteo.dateInterval.isValid();
        meteo.intervalEdited = true;
        meteo.somethingChanged = true;
    }

    @action
    onDraughtChanged(item: IDropDownItem) {
        let meteo = this.props.store.meteoStore;
        if (meteo.currentDraughtType == item.data) return;
        meteo.currentDraughtType = item.data;
        //meteo.somethingChanged = true;
    }

    checkInterval() {
        let store = this.props.store;
        let meteo = store.meteoStore;
        let dint = meteo.dateInterval;
        if (! dint.isValid()) {
            meteo.intervalWarning = store.trans["Incorrect dates"];
            return false;
        }
        else if (Math.floor((dint.end.getTime() - dint.begin.getTime()) / (1000 * 60 * 60 * 24)) > 366) {
            meteo.intervalWarning = store.trans["Period must be no more than 366 days"];
            return false;
        }
        else if (dint.begin.getFullYear() < 2010) {
            meteo.intervalWarning = store.trans["Data available since 2010-01-01"];
            return false;
        }
        meteo.intervalWarning = "";
        return true;
    }

    @action
    onApply() {
        let meteo = this.props.store.meteoStore;
        if (meteo.periodMode == PeriodMode.Custom && meteo.currentFunction.code != 'norm') {
            meteo.intervalEdited = false;
            meteo.validInterval = this.checkInterval();
            if (! meteo.validInterval) return;
        }
        //meteo.currentPreset = meteo.currentMeteoParam.code == MeteoStore.PARAMS[0].code? MeteoStore.BGR: MeteoStore.RGB;
        meteo.onlineChange = false;
        meteo.currentParams = meteo.getTempPrecipParams();
        meteo.deviation = null;
        meteo.somethingChanged = false;
    }

    @action
    onFilterValueChanged(e: React.ChangeEvent<HTMLInputElement>){
        let meteo = this.props.store.meteoStore;
        if (meteo.currentFilterTextValue == e.currentTarget.value) return;
        meteo.currentFilterTextValue = e.currentTarget.value;
        meteo.somethingChanged = true;
    }

    @action
    onFilterValueAfterChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let meteo = this.props.store.meteoStore;
        let val = parseInt(meteo.currentFilterTextValue);
        if (meteo.currentFilterValue == val) return;
        if (! isNaN(val) && val >= 0) meteo.currentFilterValue = val;
        meteo.currentFilterTextValue = meteo.currentFilterValue.toString();
        meteo.somethingChanged = true;
    }

    @action
    onQuantileChanged(item: IDropDownItem){
        let meteo = this.props.store.meteoStore;
        if (meteo.currentQuantile == item.data) return;
        meteo.onlineChange = true;
        meteo.currentQuantile = item.data;        
        meteo.deviation = {...meteo.deviation, autoChange: false};
        //meteo.somethingChanged = true;
    }

   @action
    onModeChanged(check1: boolean) {
        this.props.store.meteoStore.onlineChange = true;
        this.props.store.meteoStore.viewMode = check1? ViewMode.Report : ViewMode.Map;
    }

    @action
    onChangeDeviation(values: number[]): void{
        let meteo = this.props.store.meteoStore;
        meteo.deviation = { ...meteo.deviation, ranges: values, autoChange: true};
    }

    @action
    onReleaseDeviation(values: number[]): void{
        let meteo = this.props.store.meteoStore;
        meteo.onlineChange = true;
        meteo.deviation = { ...meteo.deviation, ranges: values, autoChange: false};
    }

    @observable
    animate: boolean = false;

    @action
    onEndAnimate(){
        this.animate = false;
    }

    getValid() : {dateFromValid: boolean, dateToValid: boolean}{
        let meteo = this.props.store.meteoStore;
        return {
            dateFromValid: meteo.dateInterval.isValidBegin(),
            dateToValid: meteo.dateInterval.isValidEnd()
        }
    }

    @observable
    applyEnabled: boolean = false;

    checkValid() {
        let {dateFromValid, dateToValid} = this.getValid();
        return dateFromValid && dateToValid;
    }

    @action
    onCheckFilter() {
        let meteo = this.props.store.meteoStore;
        meteo.filterChecked = !meteo.filterChecked;
        meteo.somethingChanged = true;
    }

    @action
    onOpenDroughtDate() {
        this.props.store.meteoStore.droughtCalendarOpened = true;
    }

    @action
    onCloseDroughtDate() {
        this.props.store.meteoStore.droughtCalendarOpened = false;
    }

    @action
    onChangeDroughtDate(date: Date) {
        let meteo = this.props.store.meteoStore;
        // meteo.droughtCalendarOpened = false;
        meteo.droughtDate = date;
    }    

    @action
    onOpenAnalysDate() {
        this.props.store.meteoStore.analysCalendarOpened = true;
    }

    @action
    onCloseAnalysDate() {
        this.props.store.meteoStore.analysCalendarOpened = false;
    }

    @action
    onChangeAnalysDate(date: Date) {
        let meteo = this.props.store.meteoStore;
        if (meteo.analysDate == date) return;
        meteo.analysDate = date;
        meteo.somethingChanged = true;
    }    

    @action onChangePeriodMode(mode: PeriodMode) {
        let meteo = this.props.store.meteoStore;
        if (meteo.periodMode == mode) return;
        meteo.periodMode = mode;
        meteo.somethingChanged = true;
    }

    @action onMonthesChange(range : IRangeValues) {
        let meteo = this.props.store.meteoStore;
        meteo.currentRange = range
        meteo.somethingChanged = true;
    }

    getDraughtExcIntervals(minYear: number, maxYear: number) {
        let arr = new Array<{ start: Date; end: Date }>();
        for (let y = minYear; y <= maxYear; y++) {
            arr.push({start: new Date(y, 0, 1), end: new Date(y, 4, 15)}); //jan 01 - may 15
            arr.push({start: new Date(y, 9, 17), end: new Date(y, 11, 31)}); //oct 17 - dec 31
        }
        return arr;
    }

    render() {
        let store = this.props.store;
        let meteo = store.meteoStore;
        let meteoBody: any = null;
        if (meteo.viewMode == ViewMode.Map) {
            let maxDate = new Date();
            maxDate.setDate(maxDate.getDate() - MeteoStore.METEO_DAYS_GAP);
    
            let minDate = new Date(2010, 0, 1);
    
            let paramItems: IDropDownItem[] = [];
            MeteoStore.PARAMS.forEach(p => paramItems.push({key: p.code, value: (store.trans as any)[p.name], data: p}));
    
            let funcItems: IDropDownItem[] = [];
            MeteoStore.FUNCTIONS.forEach(f => funcItems.push({key: f.code, value: (store.trans as any)[f.name], data: f}));
    
            let isNorm = meteo.currentFunction.code == 'norm';
    
            let filterItems: IDropDownItem[] = [];
            MeteoStore.FILTERS.forEach(f => filterItems.push({key: f.code, value: (store.trans as any)[f.name], data: f}));
    
            let quantileItems: IDropDownItem[] = [];
            MeteoStore.QUANTILES.forEach(f => quantileItems.push({key: f.val, value: f.name, data: f}));
    
            let draughtItems: IDropDownItem[] = [];
            MeteoStore.DRAUGHT_TYPES.forEach(f => draughtItems.push({key: f.code, value: (meteo.trans as any)[f.name], data: f}));
    
            let monthes: IDropDownItem[] = [];
            Array.from({ length: 12 }, (value, index) => index).forEach(m => {
                monthes.push({key: m, value: DateUtils.getMonthName(m, store.trans), data: m});
            });
    
            let isTempOrPrecip = meteo.isTempOrPrecip();
            let isGtk = meteo.isGtk();
    
            let rangeSliders: any[] = [];
            let colors = meteo.colors;
            for(let index = 0; meteo.deviation && index < meteo.deviation.ranges.length; index++){
                let beforeStyle: React.CSSProperties = {};
                let afterStyle: React.CSSProperties = {};
                if (index == 0){
                    beforeStyle = {background: ColorHelper.ColorRGBAtoCSS(ColorHelper.parseColor(colors[0]))};
                }
                afterStyle = {background: ColorHelper.ColorRGBAtoCSS(ColorHelper.parseColor(colors[index + 1]))};
    
                rangeSliders.push(<MultiSlider.Handle type={"start"} interactionKind={HandleInteractionKind.PUSH}
                                           trackStyleBefore={beforeStyle}
                                           trackStyleAfter={afterStyle} value={meteo.deviation.ranges[index]} key={index}  />);
            }
    
            function getLabel(value: number, opts?: { isHandleTooltip: boolean }): string | JSX.Element {
                if (opts && opts.isHandleTooltip) return value.toFixed(2);
                return "";
            }
    
            function getPeriodTitle() {
                if (isNorm) return meteo.trans['In months'];
                return meteo.periodMode == PeriodMode.Custom? meteo.trans.Period + " " + store.trans["(no more than 366 days)"] : meteo.trans['Date of analysis'];
            }

            let analysFrom10 = isDate(meteo.analysDate)? Utils.formatDate(Utils.getDateOffset(meteo.analysDate, -10)): null;
            let analysFrom30 = isDate(meteo.analysDate)? Utils.formatDate(Utils.getDateOffset(meteo.analysDate, -30)): null;
            let analysTo = isDate(meteo.analysDate)? Utils.formatDate(Utils.getDateOffset(meteo.analysDate, -1)): null;
        
            meteoBody = <Fragment>
                <div className="MeteoPanelComp-body style-4">
                <div className="MeteoPanelComp-first-param">
                    <div className="MeteoPanelComp-paramname">{store.trans['Parameter']}</div>
                    <DropDownComp currentKey={meteo.currentMeteoParam.code} items={paramItems}
                                onChange={this.onParamChanged} className={'MeteoPanelComp-drop-down'}>
                    </DropDownComp>
                </div>
                {(isTempOrPrecip || isGtk) && <React.Fragment>
                    <div>
                        <div className="MeteoPanelComp-paramname">{store.trans['Aggregation for the period']}</div>
                        <DropDownComp currentKey={meteo.currentFunction ? meteo.currentFunction.code : null} items={funcItems}
                                    placeHolder={store.trans['Indicator value']} onChange={this.onFuncChanged} className='MeteoPanelComp-drop-down'></DropDownComp>
                    </div>
                    {isTempOrPrecip && <React.Fragment>
                        <div className="MeteoPanelComp-value-checkbox" onClick={this.onCheckFilter}>
                            <CheckboxMiniComp state={meteo.filterChecked? TriState.check:TriState.uncheck}
                                            size={CheckboxSize.standart} classesContainer="pointer">
                            </CheckboxMiniComp>
                            <span className={classNames("MeteoPanelComp-param-overlay-title", {
                                "LayersListComp-active-color": meteo.filterChecked,
                                "LayersListComp-inactive-color": !meteo.filterChecked
                            })}>{store.trans['Values filter']}</span>
                        </div>
                        <div className='MeteoPanelComp-filter-div'>
                            <div>
                                <div className={classNames("MeteoPanelComp-paramname", {"opacity50": !meteo.filterChecked})}>{store.trans.Operator}</div>
                                <DropDownComp currentKey={meteo.currentFilter.code} items={filterItems} disabled={!meteo.filterChecked}
                                            onChange={this.onFilterChanged}
                                            className={classNames('MeteoPanelComp-drop-down', {'MeteoPanelComp-drop-down-disabled': !meteo.filterChecked})}>
                                </DropDownComp>
                            </div>
                            <div>
                                <div className={classNames("MeteoPanelComp-paramname", {"opacity50": !meteo.filterChecked})}>{store.trans['Threshold value']}</div>
                                <input type="text"  maxLength={4} pattern="[0-9]{1,3}" placeholder={store.trans["Values filter"]}
                                    disabled={!meteo.filterChecked} value={meteo.currentFilterTextValue} 
                                    className={classNames("text-box-editor width100 number-without-arrow", {"gray-text": !meteo.filterChecked})}
                                    onChange={this.onFilterValueChanged} onBlur={this.onFilterValueAfterChanged}/>
                            </div>
                        </div>
                    </React.Fragment>}
                    <div className={classNames('MeteoPanelComp-period-div', {'MeteoPanelComp-period-gtk': isGtk})}>
                        <div className='MeteoPanelComp-period-title'>
                            <div className="MeteoPanelComp-calendar margin-right-15 gray"></div>
                            <div className="MeteoPanelComp-period-title-caption">{store.trans.Period}</div>
                        </div>
                        <div className='MeteoPanelComp-period-line'></div>
                    </div>
                    <div>
                        <div className='MeteoPanelComp-period-selector'>
                            <RadioButton checked={meteo.periodMode == PeriodMode.Days10} disabled={isNorm} 
                                onClick={() => this.onChangePeriodMode(PeriodMode.Days10)} />
                            <div className='MeteoPanelComp-period-description'>
                                <div className={classNames('', {'opacity50': isNorm})}>{meteo.trans['10 days before the analysis date']}</div>
                                <div className={classNames('MeteoPanelComp-period-description-color', {'opacity50': isNorm})}>
                                {meteo.periodMode == PeriodMode.Custom || !isDate(meteo.analysDate)? "": `${analysFrom10} - ${analysTo}`}                                    
                                </div>
                            </div>
                        </div>                
                        <div className='MeteoPanelComp-period-selector'>
                            <RadioButton checked={meteo.periodMode == PeriodMode.Days30} disabled={isNorm} 
                                onClick={() => this.onChangePeriodMode(PeriodMode.Days30)} />
                            <div className='MeteoPanelComp-period-description'>
                                <div className={classNames('', {'opacity50': isNorm})}>{meteo.trans['30 days before the analysis date']}</div>
                                <div className={classNames('MeteoPanelComp-period-description-color', {'opacity50': isNorm})}>
                                {meteo.periodMode == PeriodMode.Custom || !isDate(meteo.analysDate)? "": `${analysFrom30} - ${analysTo}`}
                                </div>
                            </div>
                        </div>                
                        <div className='MeteoPanelComp-period-selector'>
                            <RadioButton checked={meteo.periodMode == PeriodMode.Custom} onClick={() => this.onChangePeriodMode(PeriodMode.Custom)} />
                            <div className='MeteoPanelComp-period-description'>
                                <div>{meteo.trans['Arbitrary period']}</div>
                                <div className='MeteoPanelComp-period-description-color'>
                                    {meteo.periodMode != PeriodMode.Custom || !meteo.dateInterval.isValid() || isNorm? "" :
                                    (Utils.getDatesDiff(meteo.dateInterval.end, meteo.dateInterval.begin, "day") + 1) + " дн."}
                                </div>
                            </div>
                        </div>    
                    </div>

                    <div className="MeteoPanelComp-period">
                        <div className='MeteoPanelComp-period-title'>{getPeriodTitle()}{!isNorm && <span className="ActiveSensorDay-star">*</span>}</div>                        
                        { isNorm && 
                            <DropDownRangeSelector currentValue={meteo.currentRange} values1={monthes} onChange={this.onMonthesChange}/>
                        }
                        { !isNorm && meteo.periodMode == PeriodMode.Custom && 
                        <React.Fragment>
                            <div className={classNames("MeteoPanelComp-interval", {"red_border": !meteo.validInterval && !meteo.intervalEdited})}>
                                <ReactDoubleDatePicker2Comp store={store} interval={meteo.dateInterval}
                                                            onChange={this.onDatesChanged} maxDate={maxDate} minDate={minDate}
                                                            onBeginFocus={() => meteo.intervalEdited = true}
                                                            onEndFocus={() => meteo.intervalEdited = true}/>
                            </div>
                            {/* <div className="MeteoPanelComp-dates-note">{store.trans['The period length is no more than 366 days']}</div> */}
                        </React.Fragment>
                        }
                        { !isNorm && meteo.periodMode != PeriodMode.Custom && 
                        <div className={classNames("MeteoPanelComp-Analys-Date", {"red_border": false/*!isDate(meteo.analysDate)*/})}>
                                <CalendarComp open={meteo.analysCalendarOpened} value={meteo.analysDate} classCalendar="date-input"
                                onOpen={this.onOpenAnalysDate} onClose={this.onCloseAnalysDate} onChange={this.onChangeAnalysDate}
                                maxDate={maxDate} minDate={minDate} locale={store.getLocale()} placeholderText={this.props.store.trans['YYYY-MM-DD']}/>
                        </div>}
                    </div>
                    {/* {isTempOrPrecip && <React.Fragment>
                    <div className="MeteoPanelComp-separator"></div>
                    {meteo.deviation != null && <div className="MeteoPanelComp-color-settings">
                        <div className="MeteoPanelComp-color-settings-title">{store.trans['Color settings']}</div>
                        <div>
                            <div className="MeteoPanelComp-paramname">{store.trans['Quantiles, min-max']}</div>
                            <DropDownComp currentKey={meteo.currentQuantile.val} items={quantileItems}
                                        onChange={this.onQuantileChanged} className='MeteoPanelComp-drop-down'
                                        disabled={meteo.currentFunction.code == 'div'}>                                    
                            </DropDownComp>
                        </div>
                        <div className="MeteoPanelComp-legend">
                            <div className="MeteoPanelComp-paramname">{store.trans["Scale"]}</div>
                            <MultiSlider min={meteo.deviation.min} max={meteo.deviation.max} stepSize={(meteo.deviation.max - meteo.deviation.min) / 340}
                                        className="MeteoPanelComp-slider" labelRenderer={getLabel} onChange={this.onChangeDeviation}
                                        onRelease={this.onReleaseDeviation}>
                                {rangeSliders}
                            </MultiSlider>
                            <div className="MeteoPanelComp-palette-labels">
                                <div className="MeteoPanelComp-palette-label-min">{meteo.deviation.min}{meteo.stats.unit}</div>
                                <div className="MeteoPanelComp-palette-label-max">{meteo.deviation.max}{meteo.stats.unit}</div>
                            </div>
                        </div>
                    </div>}
                    </React.Fragment>} */}
                </React.Fragment>}
                {meteo.isDrought() && <React.Fragment>
                <div>
                    <div className="MeteoPanelComp-paramname">{store.trans['Aggregation for the period']}</div>
                    <DropDownComp currentKey={meteo.currentDraughtType ? meteo.currentDraughtType.code : null} items={draughtItems}
                                placeHolder={store.trans['Indicator value']} onChange={this.onDraughtChanged} className='MeteoPanelComp-drop-down'></DropDownComp>
                </div>
                <div className="MeteoPanelComp-dates-note">{meteo.trans['The assessment...']}</div>
                <div className="MeteoPanelComp-separator"></div>
                <div className="MeteoPanelComp-period">
                    <div className='MeteoPanelComp-period-title'>{meteo.trans['Date of analysis']}</div>
                    <div className={classNames("MeteoPanelComp-Drought-Date", {"red_border": !isDate(meteo.droughtDate)})}>
                            <CalendarComp open={meteo.droughtCalendarOpened} value={meteo.droughtDate} classCalendar="date-input"
                            onOpen={this.onOpenDroughtDate} onClose={this.onCloseDroughtDate} onChange={this.onChangeDroughtDate}
                            excludeDateIntervals={this.getDraughtExcIntervals(minDate.getFullYear(), maxDate.getFullYear())}
                            maxDate={maxDate} minDate={minDate} locale={store.getLocale()} placeholderText={this.props.store.trans['YYYY-MM-DD']}/>
                    </div>
                </div>
                {/* <div className="MeteoPanelComp-dates">
                    <div className="popup-pref-in__row2_left">
                        <div className="calendar_table margin-right-15 gray"/>
                        {store.trans.Date}
                    </div>
                    <div className={classNames("MeteoPanelComp-Drought-Date", {"red_border": false})}>
                        <CalendarComp open={meteo.droughtCalendarOpened} value={meteo.droughtDate} classCalendar="date-input"
                        onOpen={this.onOpenDroughtDate} onClose={this.onCloseDroughtDate} onChange={this.onChangeDroughtDate}/>
                    </div>
                    <div></div>
                    <div className="MeteoPanelComp-dates-note">Доступен период с 1 мая по 30 сентября</div>
                </div> */}
                </React.Fragment>}
                </div>
                {(isTempOrPrecip || isGtk) &&<div className="MeteoPanelComp-applyDiv">
                    <span className='MeteoPanelComp-applyHint'>{meteo.trans['Cartogram creation time is up to 10 seconds']}</span>
                    <button onClick={this.onApply}
                            disabled={!meteo.somethingChanged || 
                                (!meteo.validInterval && meteo.periodMode == PeriodMode.Custom && !isNorm) ||
                                (!isDate(meteo.analysDate) && meteo.periodMode != PeriodMode.Custom && !isNorm)}
                            className="large-button left-sidebar-active button-jump MeteoPanelComp-applyButton">
                        {store.trans.Apply}
                    </button>
                </div>}
            </Fragment>;
        }
        else { //report
            let d = meteo.districtData?.district;
            meteoBody = <MeteoReportComp isActive={meteo.districtSelectionMode} 
            farmName={meteo.farmName}
            cropId={meteo.cropId}
            addForecast={meteo.addForecast}
            store={store}            
            canCreateReport={meteo.canCreateReport}
            isReportLoading={meteo.isReportLoading}
            periodMode={meteo.reportPeriodMode}
            analysDate={meteo.reportAnalysDate}
            onReportLoadingChanged={(val) => {meteo.isReportLoading = val}}
            onCentered={() => { this.props.store.map.zoomToBBox([d.lon_min, d.lat_min, d.lon_max, d.lat_max]); }}/>;
        }

        return <div className="MeteoPanelComp-main">
            <div className="MeteoPanelComp-title">
                <span>{meteo.trans["Meteo"]}</span>
                <span className='MeteoPanelComp-title-help' onClick={()=> window.open("https://help.riskagro.ru/page33166728.html", "_blank")}>
                    {store.trans['Help']}</span>
            </div>
            <div className="MeteoPanelComp-switcher">
                    <SwitchText text1={store.trans.Reports}
                                text2={store.trans.Cartograms}                                
                                check1={meteo.viewMode == ViewMode.Report}
                                onClick={this.onModeChanged}/>
            </div>            
            {meteoBody}
        </div>
    }
}
