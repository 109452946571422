// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalendarComp-cont {
  background: #2B3138;
  border: 1px solid #3E4751;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #C5C5C5;
  display: flex;
}

input.CalendarComp-edit {
  padding: 13px 15px;
  line-height: 22px;
  flex-grow: 1;
  border: 0px;
  outline: none;
  border-radius: 10px;
  color: #C5C5C5;
  background-color: #2B3138;
  width: 100%;
}

.CalendarComp-button {
  border-left: 1px solid #3E4751;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalendarComp-rightButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  border-left: 1px solid #3e4751;
}

.CalendarComp-today-selected {
  color: #2B3138;
  background: #4DB6BC;
}

.CalendarComp-today-nonselected {
  color: #4DB6BC;
}

.react-datepicker__day.today-selected {
  color: #2B3138;
  background: #4DB6BC;
}

.react-datepicker__day.nottoday-selected {
  color: #4DB6BC;
  background: none;
  border: solid 1px #4DB6BC;
}

.react-datepicker__day.today-notselected {
  color: #4DB6BC;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/CalendarComp.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EAEA,cAAA;EACA,aAAA;AAAF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,WAAA;AACF;;AACA;EACE,8BAAA;EACA,eAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;AAGF;;AADA;EACE,cAAA;EACA,mBAAA;AAIF;;AADA;EACE,cAAA;AAIF;;AADA;EACE,cAAA;EACA,mBAAA;AAIF;;AADA;EACE,cAAA;EACA,gBAAA;EACA,yBAAA;AAIF;;AADA;EACE,cAAA;AAIF","sourcesContent":[".CalendarComp-cont{\n  background: #2B3138;\n  border: 1px solid #3E4751;\n  border-radius: 10px;\n  box-sizing: border-box;\n  font-size: 13px;\n  //height: 50px;\n  color: #C5C5C5;\n  display: flex;\n}\ninput.CalendarComp-edit{\n  padding: 13px 15px;\n  line-height: 22px;\n  flex-grow: 1;\n  border: 0px;\n  outline: none;\n  border-radius: 10px;\n  color: #C5C5C5;\n  background-color:#2B3138;\n  width: 100%;\n}\n.CalendarComp-button{\n  border-left: 1px solid #3E4751;\n  padding: 0 20px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.CalendarComp-rightButton{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 90px;\n  border-left: 1px solid #3e4751;\n}\n.CalendarComp-today-selected {\n  color: #2B3138;\n  background: #4DB6BC;\n}\n\n.CalendarComp-today-nonselected {\n  color: #4DB6BC;\n}\n\n.react-datepicker__day.today-selected {\n  color: #2B3138;\n  background: #4DB6BC;\n}\n\n.react-datepicker__day.nottoday-selected {\n  color: #4DB6BC;\n  background: none;\n  border: solid 1px #4DB6BC;\n}\n\n.react-datepicker__day.today-notselected {\n  color: #4DB6BC;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
