// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.TagsAdd-window {
  max-width: 452px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: #C5C5C5;
  top: 30px;
  bottom: auto;
  max-height: calc(100% - 100px);
}

.TagsAdd-searchStr {
  margin-bottom: 15px;
}

.TagsAdd-marginLR {
  margin-left: 25px;
  margin-right: 25px;
}

.TagsAdd-header {
  margin: 25px 25px 15px 25px;
  color: #EEEEEE;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.TagsAdd-key {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.TagsAdd-button {
  min-width: 170px;
}

.TagsAdd-footer {
  border-top: 1px solid #383F48;
  padding: 20px 0;
}

.TagsAdd-selectedDiv {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/PhotoPanel/TagsAdd/TagsAdd.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,SAAA;EACA,YAAA;EACA,8BAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,iBAAA;EACA,kBAAA;AAGF;;AADA;EACE,2BAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAIF;;AAFA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAKF;;AAHA;EACE,gBAAA;AAMF;;AAJA;EACE,6BAAA;EACA,eAAA;AAOF;;AALA;EAEE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAOF","sourcesContent":["div.TagsAdd-window{\r\n  max-width: 452px;\r\n  margin: auto;\r\n  display: flex;\r\n  flex-direction: column;\r\n  color: #C5C5C5;\r\n  top: 30px;\r\n  bottom: auto;\r\n  max-height: calc(100% - 100px);\r\n}\r\n.TagsAdd-searchStr{\r\n  margin-bottom: 15px;\r\n}\r\n.TagsAdd-marginLR{\r\n  margin-left: 25px;\r\n  margin-right: 25px;\r\n}\r\n.TagsAdd-header{\r\n  margin: 25px 25px 15px 25px;\r\n  color: #EEEEEE;\r\n  font-weight: bold;\r\n  font-size: 16px;\r\n  line-height: 22px;\r\n}\r\n.TagsAdd-key{\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n}\r\n.TagsAdd-button{\r\n  min-width: 170px;\r\n}\r\n.TagsAdd-footer{\r\n  border-top: 1px solid #383F48;\r\n  padding: 20px 0;\r\n}\r\n.TagsAdd-selectedDiv{\r\n  //margin-top: 20px;\r\n  margin-bottom: 20px;\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n}\r\n.TagsAdd-tagFilterStrDiv{\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
