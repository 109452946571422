import * as React from "react";
import {CenterPlace, ContextMenuCommonComp} from "../../../Common/ContextMenuCommonComp";
import classNames from "classnames";
import {action, observable} from "mobx";
import './SelectGeometryType.scss';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {MyHint} from "../../../Common/MyHint";

export interface ISelectGeometryType extends IStoreProps{
    showUpload?: boolean;
    showPolygon?: boolean;
    showLine?: boolean;
    showRect?: boolean;
    onUpload?: ()=>void;
    onPolygon?: ()=>void;
    onLine?: ()=>void;
    onRect?: ()=>void;
    notAccent?:boolean
}
@observer
export class SelectGeometryType extends React.Component<ISelectGeometryType, undefined> {
    constructor(props: ISelectGeometryType) {
        super(props);
        autoBindReact(this);
    }
    @observable
    down: boolean = false;
    @action
    onClick(){
        this.down = true;
    }
    @action
    onClose(){
        this.down = false;
    }
    @action
    clickUpload(){
        this.down = false;
        if (this.props.onUpload) this.props.onUpload();
    }
    render() {
        let store = this.props.store;
        let activeArea = store.map.searchObject.isNotEmpty
        return <MyHint text={store.trans["Create an area"]}>
            <div className={classNames(this.props.className,{ "accent-button" : !activeArea && !this.props.notAccent})} onClick={this.onClick}>
            <div className={`flex-full-center ${activeArea || this.props.notAccent? 'white-svg' : 'black-svg'}`}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_178_791)">
                        <path
                            d="M7 10.5C7.82843 10.5 8.5 9.82843 8.5 9C8.5 8.17157 7.82843 7.5 7 7.5C6.17157 7.5 5.5 8.17157 5.5 9C5.5 9.82843 6.17157 10.5 7 10.5Z"
                            strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M0.5 15.5001L5.94 10.0601"  strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M7.5 0.5L15.5 8.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.5 6.5L11.5 13.5L0.5 15.5L2.5 4.5L9.5 2.5"  strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_178_791">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            {this.down && <ContextMenuCommonComp onClose={this.onClose} parentClickNotIgnore={true} isCenter={true} centerPlace={CenterPlace.top}>
                <div className="SelectGeometryType-dropDownPanenl">
                    {(this.props.showUpload??true) && <MyHint text={this.props.store.trans["File in .geojson format, EPSG:4326"]}>
                        <div className="SelectGeometryType-dropDownButton blue-svg-onhover" onClick={this.clickUpload}>{/*download*/}
                        <div className="flex-full-center">
                            <svg width="16" height="17" viewBox="0 0 16 17"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.06689 16L8.06689 3.95435" fill="none" strokeMiterlimit="10"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.0669 9.42963L8.0669 3.95435L2.06689 9.42963" fill="none"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <rect width="16" height="2" rx="1" stroke="#none"/>
                            </svg>
                        </div>
                    </div></MyHint>}
                    {(this.props.showPolygon??true) && <div className="SelectGeometryType-dropDownButton blue-svg-onhover" onClick={this.props.onPolygon}>{/*polygon*/}
                        <div className="flex-full-center">

                            <svg width="16" height="16" viewBox="0 0 16 16" stroke="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.77783" width="4.44444" height="4.44444" />
                                <rect width="4.44444" height="4.44444" transform="matrix(-1 0 0 1 14.2222 0)"
                                      />
                                <rect y="11.5557" width="4.44444" height="4.44444" />
                                <rect x="11.5557" y="11.5557" width="4.44444" height="4.44444"/>
                                <rect x="1.77783" y="10.7012" width="5.64444" height="0.888889"
                                      transform="rotate(-71.9858 1.77783 10.7012)" />
                                <rect width="5.64444" height="0.888889"
                                      transform="matrix(-0.309253 -0.95098 -0.95098 0.309253 14.2222 10.7012)"
                                      />
                                <rect x="5.3335" y="13.3335" width="5.33333" height="0.888889"/>
                                <rect x="7.11133" y="1.77783" width="1.77778" height="0.888889"/>
                            </svg>
                        </div>
                    </div>}
                    {(this.props.showLine??true) &&
                        <div className="SelectGeometryType-dropDownButton blue-svg-onhover" onClick={this.props.onLine}>{/*line*/}
                            <div className="flex-full-center">
                                <svg width="16" height="16" viewBox="0 0 16 16" stroke="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="4.44444" height="4.44444" rx="2.22222"/>
                                    <path d="M11.5557 11.5557H16.0001V16.0001H11.5557V11.5557Z"/>
                                    <rect x="10.5405" y="11.1687" width="8.88889" height="0.888889"
                                          transform="rotate(-135 10.5405 11.1687)"/>
                                </svg>
                            </div>
                        </div>}
                    {(this.props.showRect??true) && <div className="SelectGeometryType-dropDownButton blue-svg-onhover" onClick={this.props.onRect}>{/*rect*/}
                        <div className="flex-full-center">
                            <svg width="16" height="16" viewBox="0 0 16 16"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="5.3335" y="1.77783" width="5.33333" height="0.888889" stroke="none"/>
                                <rect width="4.44444" height="4.44444" stroke="none"/>
                                <rect y="11.5557" width="4.44444" height="4.44444" stroke="none"/>
                                <rect x="11.5557" width="4.44444" height="4.44444" stroke="none"/>
                                <rect x="11.5557" y="11.5557" width="4.44444" height="4.44444" stroke="none"/>
                                <rect x="5.3335" y="13.3335" width="5.33333" height="0.888889" stroke="none"/>
                                <rect x="13.3335" y="10.6665" width="5.33333" height="0.888889"
                                      transform="rotate(-90 13.3335 10.6665)" stroke="none"/>
                                <rect x="1.77783" y="10.6665" width="5.33333" height="0.888889"
                                      transform="rotate(-90 1.77783 10.6665)" stroke="none"/>
                            </svg>
                        </div>
                    </div>}
                </div>
            </ContextMenuCommonComp>}
            </div>
        </MyHint>;
    }
}