import {ObservableCustomStore} from "../../app/store/CustomStore";
import {observable} from "mobx";
import {AgroAhoStore} from "./agroAhoStore";
import {LoadedIndicator} from "./agroAhoCardStore";


export enum AhoLoadStatus {
    Ready= 'Ready',
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error'
}

export enum AhoFileLoadStage {
    Ready = 'Ready',
    Uploading = 'Uploading',
    Success = 'Success',
    Error = 'Error'
}


export class AgroAhoUploadIndcStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    @observable
    ahoProgress: number = 0;
    @observable
    ahoLoadedIndicators: LoadedIndicator[];
    @observable
    ahoLoadStatus: AhoLoadStatus = AhoLoadStatus.Ready;
    @observable
    ahoResult: string = '';
    @observable
    ahoFileLoadStage: AhoFileLoadStage = AhoFileLoadStage.Ready;
    @observable
    ahoLog: string = '';


    async uploadFile(file: any){
        let req = new XMLHttpRequest();
        let fd = new FormData();

        this.ahoProgress = 0;
        this.ahoLoadedIndicators = [];
        this.ahoLoadStatus = AhoLoadStatus.Ready;

        fd.append("asa_file", file);
        req.open("post", `/api/projects/${this.parentStore.projStore.projName}/asa_map/${this.parentStore.cardStore.card.map_id}/upload?action=validate`);
        req.upload.onprogress = (event) => {
            this.ahoProgress = Math.round((event.loaded / event.total) * 100);
        }
        req.onerror = () => {
            this.ahoResult = this.root.trans['Loading error'];
            this.ahoFileLoadStage = AhoFileLoadStage.Error;
        }
        req.onload = () => {
            if (req.status !== 200) {
                if (req.status == 400) {
                    let err = this.root.trans['Loading error'];
                    if (req.response) {
                        let res = JSON.parse(req.response);
                        if (res && res.status === 'error')
                            err = res.msg || res.message || err;
                    }
                    this.root.addError(err);
                    this.parentStore.toggleModal(false);
                    this.clearFileLoadData();
                    this.ahoProgress = 0;
                    this.ahoLoadedIndicators = [];
                    this.ahoLoadStatus = AhoLoadStatus.Ready;
                    return;
                }
                this.ahoResult = this.root.trans['Loading error'];
                this.ahoFileLoadStage = AhoFileLoadStage.Error;
                this.ahoLog = this.root.trans['Loading error'];
                if (req.response) {
                    let res = JSON.parse(req.response);
                    if (res && res.status === 'error')
                        this.ahoLog = res.msg || res.message || '';
                }
                this.ahoLoadStatus = AhoLoadStatus.Error;
                this.ahoFileLoadStage = AhoFileLoadStage.Error;
            } else {
                let res = JSON.parse(req.response);
                let err_cnt = res?.errors?.common?.length || 0;
                err_cnt += res?.errors?.row?.length || 0;
                if (res && (res.status === 'error' || err_cnt)) {
                    if (err_cnt) {
                        this.ahoLog = JSON.stringify(res.errors, undefined, 4);
                    } else this.ahoLog = res.msg || res.message || '';
                    this.ahoLoadStatus = AhoLoadStatus.Error;
                    this.ahoFileLoadStage = AhoFileLoadStage.Error;
                    return;
                }
                let arr: LoadedIndicator[] = [];
                let is_warn: boolean = false;
                if (res.indicators && Object.keys(res.indicators).length) {
                    for (let key in res.indicators){
                        let indc = res.indicators[key];
                        arr.push({name: this.parentStore.cardStore.getIndicatorName(key), count: indc.probe_cnt, indc_code: key,
                            warning: false});
                    }
                }
                if (res.columns?.length) {
                    for (let key in res.columns){
                        let col = res.columns[key];
                        if (col.col_class == 'extra_prop') {
                            is_warn = true;
                            arr.push({name: '', count: '', indc_code: col.col_name, warning: true})
                        }
                    }
                }
                if (arr.length) {
                    this.ahoLoadedIndicators = arr;
                    this.ahoLoadStatus = is_warn ? AhoLoadStatus.Warning : AhoLoadStatus.Success;
                }
                this.parentStore.cardStore.ds_code = res.ds_code;
                this.ahoFileLoadStage = AhoFileLoadStage.Success;
            }

        };
        req.send(fd);
    }

    clearFileLoadData(){
        this.ahoProgress = 0;
        this.ahoResult = '';
        this.ahoLog = '';
        // this.ahoPLink = '';
        this.ahoFileLoadStage = AhoFileLoadStage.Ready;
    }

    async saveClick(){
        let res = await this.parentStore.cardStore.doSaveIndicators(this.parentStore.cardStore.ds_code);
        // if (res.result === 'ok') await this.parentStore.cardStore.updIndicators();
        if (res && res.indicators) await this.parentStore.cardStore.updIndicators();
        this.parentStore.toggleModal(false);
        this.clearFileLoadData();

        this.ahoProgress = 0;
        this.ahoLoadedIndicators = [];
        this.ahoLoadStatus = AhoLoadStatus.Ready;
    }

    loadCancelClick(){
        this.parentStore.toggleModal(false);
        this.clearFileLoadData();
        this.ahoProgress = 0;
        this.ahoLoadedIndicators = [];
        this.ahoLoadStatus = AhoLoadStatus.Ready;
    }


}