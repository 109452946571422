import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2NavigatorItemStore} from "../../../../../store/agro/A2NavigatorItemStore";
import './A2NavigatorComp.scss';
import classNames from "classnames";
import {action} from "mobx";
import {A2NavigatorSection} from "../../../../../store/agro/A2AgroStore";
import {MyHint} from "../../../../Common/MyHint";

export interface IA2NavigatorItemComp extends IStoreProps{
    section: A2NavigatorSection;
    bold?: boolean;
    navItemStore?: A2NavigatorItemStore;
    first: boolean;
    text: string;

    hint?: string;
    active: boolean;
    disabled: boolean;
    activeLink: boolean;
}
@observer
export class A2NavigatorItemComp extends React.Component<IA2NavigatorItemComp, undefined> {
    constructor(props: IA2NavigatorItemComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        if (this.props.disabled) return;
        if (!this.props.activeLink) return;
        let agro = this.props.store.agro2;
        agro.filterSelectedChecked = false;
        agro.fieldEditorForm.showFieldMultiEditPanel = false;
        if (this.props.section == A2NavigatorSection.projects){
            let newItem = agro.projectStruct.navigatorRootItem;
            if (newItem != null) {
                agro.projectStruct.setActivePage(newItem);
                newItem.resetValues();
                agro.navigatorSection = A2NavigatorSection.folder;
            }
            return;
        }
        if (this.props.section == A2NavigatorSection.folder) {
            let newItem: A2NavigatorItemStore = this.props.navItemStore.nextItem;
            if (newItem != null) {
                agro.projectStruct.setActivePage(newItem);
                newItem.resetValues();
                agro.navigatorSection = A2NavigatorSection.folder;
            }
        }
    }

    render() {
        let store = this.props.store;
        let text = this.props.text;
        if (text == null) text = "["+store.trans["Not set"]+"]";
        let active = this.props.active;
        let disabled = this.props.disabled;



        return <div className="A2NavigatorItemComp-block">
            {!this.props.first && <div className="A2NavigatorItemComp-arrow">
                <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6L4 3L0 0V6Z" fill="#6B707B"/>
                </svg>
            </div>}
            <MyHint text={this.props.hint} placement="top"><div
                className={classNames("A2NavigatorItemComp-blockText",{
                    "pointer": this.props.activeLink,
                    "bold": this.props.bold,
                    "A2NavigatorItemComp-blockTextActive": active, "A2NavigatorItemComp-blockTextDisabled": disabled})}
            onClick={this.onClick}
            >{text}</div></MyHint>
        </div>;
    }
}
