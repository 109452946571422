import {CustomStore} from "../CustomStore";
import {observable} from "mobx";


export class FieldItemStore extends CustomStore{
    class(): string {return "FieldItemStore";}

    //@save
    @observable
    field_id: number;
    //@save
    @observable
    selected: boolean;
    //@save
    @observable
    name: string;
    //@save
    @observable
    area: number;


    //интерфейс
    @observable
    expand: boolean = false;
    @observable//всыпыхивание
    flash: boolean = false;//AgroFieldItem-row-flash
}