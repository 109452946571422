import {IndexByPointerSourceCustomSimpleStore} from "./IndexByPointerSourceCustomSimpleStore";
import {Utils} from "../../../helper/utils/Utils";
import {IGraphDayValue} from "../IndexByPointGraphStore";
import {IndexByPointerSourceModisCustom} from "./IndexByPointerSourceModisCustom";

export class IndexByPointerSourceSimpleModisNdvi extends IndexByPointerSourceModisCustom{


    protected getRequest(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return (this.root.indexByPointer.getProdStatUrl()+`?lon=${gp.point.lng}&lat=${gp.point.lat}&product=1&scene_source=mod13q1&filter={"acqdate":{"$>=":"${Utils.getDateStr(dateBegin)}","$<=":"${Utils.getDateStr(dateEnd)}"}}&apikey=5b2176fa8786f7452f84b390c909c343`);
    }
    protected getValue(json: any): IGraphDayValue{
        let j: {date: string, stat:{"1":{avg: number}} } = json;
        return this.getGraphDataValue(j.date, j.stat["1"].avg);
    }
    protected getGraphDataInternal(): IGraphDayValue[]{
        return this.getData;
    }


    private get getData(): IGraphDayValue[]{
        let src: IGraphDayValue[];
        if (this.status == null) setImmediate(()=> { this.load() });
        src = this.data;
        return src;
    }

}