import {MapCustomItemStore} from "./MapCustomItemStore";
import {observable} from "mobx";

export class MapFolderItemStore extends MapCustomItemStore{
    @observable
    items: MapCustomItemStore[] = [];
    @observable
    radioButton: boolean = false;
    get itemType():MapItemType{
        return MapItemType.folder;
    }

    setVisibleOnMap(visible: boolean){
        this.items.forEach(a => a.setVisibleOnMap(visible && this.visible));
    }

    findByItemId(itemId: string): MapCustomItemStore{
        if (this.itemId == itemId) return this;
        for(let i = 0; i < this.items.length; i++){
            let t = this.items[i];
            if (t.itemType == MapItemType.folder) {
                let r = t.asFolder.findByItemId(itemId);
                if (r != null) return r;
            }else {
                if (t.itemId == itemId) return t;
            }
        }
        return null;
    }
}
import {MapItemType} from "../../config/MapConfigInterfaces";
