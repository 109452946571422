import {Translate} from "../../../app/helper/lang/Translate";

export class MeteoTranslate_en extends Translate{
    "Meteo" = "Meteo"
    "Weather map" = "Weather map"
    "Draught on date" = "Draught on date"
    "Draught days per season" = "Draught days per season"
    "In months" = "In months"
    "Period" = "Period"
    "Date of analysis" = "Date of analysis"
    "10 days before the analysis date" = "10 days before the analysis date"
    "30 days before the analysis date" = "30 days before the analysis date"
    "The assessment..." = "The assessment of the presence of drought on a selected date is performed based on calculations of various indices based on aggregate data for the 30-day period preceding the selected date"
    "Arbitrary period" = "Arbitrary period"
    "Create report" = "Create report"
}