import {fetchJson} from "./FetchUtils";
import {Utils} from "./Utils";
import {LngLat} from "maplibre-gl";

export function getSuggestions(input: string, lang: string, doneCallback: any, failCallback: any, lngLat: LngLat = null) {
    let reqParams: any = {
        q: input,
        native_args: true,
        limit: 10,
        provider: 'photon'
    };    
    if (lang)
        reqParams.lang = lang;
    if (lngLat) {
        reqParams.lat = lngLat.lat;
        reqParams.lon = lngLat.lng;
    }
    fetchJson("/api/geocode/search?"+Utils.queryEncodeParams(reqParams))
        .then(json =>{
            doneCallback(json);
        })
        .catch(err =>{
            failCallback(err);
        });
}

export function searchText(input: string, lang: string, doneCallback: any, failCallback: any) {
    let reqParams: any = {
        q : input,
        native_args: true,
        limit: 1,
        polygon_geojson: 1
    };
    if (lang)
        reqParams['accept-language'] = lang;
    fetchJson("/api/geocode/search?"+Utils.queryEncodeParams(reqParams))
        .then(json =>{
            doneCallback(json.length > 0? json[0]: null);
        })
        .catch(err =>{
            failCallback(err);
        });
}

export function searchId(id: string, lang: string, doneCallback: any, failCallback: any) {
    let reqParams: any = {
        osm_ids : id,
        limit: 1,
        format: 'json',
        polygon_geojson: 1
    };
    if (lang)
        reqParams['accept-language'] = lang;
    fetchJson("/api/geocode/lookup?"+Utils.queryEncodeParams(reqParams))
        .then(json =>{
            doneCallback(json.length > 0? json[0]: null);
        })
        .catch(err =>{
            failCallback(err);
        });
}
