import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {save} from "../PermalinkDecor";
import { IProjectStat, ProjType} from "../user/UserStore";
import {AgroProjectFormStore} from "./AgroProjectFormStore";
import {AgroAllFarms} from "./AgroAllFarms";

export enum AgroSection{
    projects = 0,
    years = 1,
    regions = 2,
    farmList = 3,
    fieldList = 4
}
export interface IYearRegionGroup{
    area_sys: number,
    obj_cnt: number;
    region_id: number,
    region_name: string;
    season_id: number;
    season_name: string;

//    photo_cnt: number,
}
export interface ICrop {
    crop_group: string,
    crop_id: number,
    crop_name: string,
    crop_name_eng: string,
    crop_subgroup: string,
    crop_type: string,
}

export interface IResponseFieldProperties extends Record<string, any>{
    area_sys: number;
    area_user: number;
    contract_year: number;
    crop_rot: IResponseCropRotProperties[];
    entity: string;
    ext_id: string;
    farm_id: number;
    farm_name: string;
    field_id: number;
    field_name: string;
    field_time: string;
    region_id: number;
    region_name: string;
}
export interface IResponseCropRotProperties{
    cr_id: number;
    crop_id: number;
    crop_name: string;
    crop_user: string;
    field_id: number;
    harvest_date: string;
    harvest_year: number;
    sowing_date: string;
    variety: string;
}

export interface IResponseFarmProperties{
    entity: string;
    farm_id: number;
    farm_name: string;
    field_area_sys: number;
    field_cnt: number;
    region_id: number;
    region_name: string;
    photo_cnt: number;


    contract_year?: number;//может не быть
}
export enum FilterProjectsType{
    byName,
    byDate
}

export class AgroStore extends CustomStore{
    class(): string {return "AgroStore";}


    @save @observable
    private _projectName: string = null;//'dolgov_copy';
    get projectName(): string{
        return this._projectName;
    }
    set projectName(value: string){
        if (this._projectName != value) {
            this._projectName = value;
            this.root.photo.clearTags();
            this.allFarms.reset();
        }
    }

    @save @observable
    projectTitle: string = '';
    //@save
    @observable
    projectStat: IProjectStat = null;

    //@save
    @observable
    datasetPhoto: string = null;

    allFarms: AgroAllFarms = new AgroAllFarms(this);

    projectForm: AgroProjectFormStore = new AgroProjectFormStore(this);


    //@save
    @observable
    order_desc: boolean = false;//направление сортировки

    get projectType(): ProjType{
        return this.root.agro2.projectInfo.projectType;
    }
}



