import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './TagsAdd.scss';
import '../PhotoFilter/PhotoFilterLeftTag.scss';
import {PhotoTagGroupFilterStore} from "../../../../../store/photo/PhotoTagGroupFilterStore";
import {PhotoFilterLeftTagGroup} from "../PhotoFilter/PhotoFilterLeftTagGroup";
import {isFunction} from "lodash-es";
import {PhotoSelectTagMode} from "../../../../../store/photo/PhotoSelectTagStore";

export interface ITagsAdd extends IStoreProps{
    photoTagGroupFilterStore: PhotoTagGroupFilterStore;
    mode: PhotoSelectTagMode;
    onClickOk?: ()=>void;
    onClickCancel?: ()=>void;
}

@observer
export class TagsAdd extends React.Component<ITagsAdd, undefined> {
    constructor(props: ITagsAdd) {
        super(props);
        autoBindReact(this);
    }

    onChangeTagSearch(event: any){
        this.props.photoTagGroupFilterStore.filter = event.target.value;
    }

    onClickOk(){
        if (isFunction(this.props.onClickOk)) this.props.onClickOk();
    }
    onClickCancel(){
        if (isFunction(this.props.onClickCancel)) this.props.onClickCancel();
    }

    render() {
        let store = this.props.store;
        let photoTagGroupFilterStore = this.props.photoTagGroupFilterStore;
        let selectedCount = store.photo.editor.photos.filter(f => f.selected).length;

        let tagGroups: any[] = [];
        photoTagGroupFilterStore.tagGroups.forEach(a => {
            if (a.tagsWithFilters.length > 0 || a.groupNameFilter.length > 0){
                tagGroups.push(<PhotoFilterLeftTagGroup group={a} tags={a.tagsWithFilters} store={store} key={a.id} />)
            }
        });
        let selectedTags = 0;
        photoTagGroupFilterStore.tagGroups.forEach(a =>{a.tags.forEach(b => {if (b.checked) selectedTags++;})});

        return <div className="modal-back">
            <div className="modal-full modal-full-frame TagsAdd-window">
                <div className="TagsAdd-header">
                    {this.props.mode == PhotoSelectTagMode.add && store.trans["Adding tags"]}
                    {this.props.mode == PhotoSelectTagMode.remove && store.trans["Removing tags"]}
                </div>
                <div className="flex-columns flex-align-items-center TagsAdd-marginLR TagsAdd-selectedDiv">
                    <span className="TagsAdd-key">{store.trans["Selected photos"]}: </span><span className="PhotoFilterLeftTagGroup-titleBadget">{selectedCount}</span>
                </div>
                <div className="flex-columns flex-align-items-center TagsAdd-marginLR flex-center-content TagsAdd-searchStr">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.707 7.207L7.5 0H2V2H5.914L13.414 9.5C13.4818 9.571 13.5443 9.64687 13.601 9.727L14.707 8.621C14.8945 8.43347 14.9998 8.17916 14.9998 7.914C14.9998 7.64884 14.8945 7.39453 14.707 7.207Z" fill="#C5C5C5"/>
                        <path d="M12.707 10.207L5.5 3H0V8.5L7.207 15.707C7.39453 15.8945 7.64884 15.9998 7.914 15.9998C8.17916 15.9998 8.43347 15.8945 8.621 15.707L12.707 11.621C12.8945 11.4335 12.9998 11.1792 12.9998 10.914C12.9998 10.6488 12.8945 10.3945 12.707 10.207ZM3 7C2.80222 7 2.60888 6.94135 2.44443 6.83147C2.27998 6.72159 2.15181 6.56541 2.07612 6.38268C2.00043 6.19996 1.98063 5.99889 2.01921 5.80491C2.0578 5.61093 2.15304 5.43275 2.29289 5.29289C2.43275 5.15304 2.61093 5.0578 2.80491 5.01921C2.99889 4.98063 3.19996 5.00043 3.38268 5.07612C3.56541 5.15181 3.72159 5.27998 3.83147 5.44443C3.94135 5.60888 4 5.80222 4 6C4 6.26522 3.89464 6.51957 3.70711 6.70711C3.51957 6.89464 3.26522 7 3 7Z" fill="#C5C5C5"/>
                    </svg>
                    <span className="TagsAdd-key text-next">{store.trans.Tags}</span>
                    <span className="PhotoFilterLeft-tagSearchBadgetSingle PhotoFilterLeft-tagSearchBadget">{selectedTags}</span>
                    <input type="text" className="text-box-editor flex-stretch-item" style={{marginLeft:"25px"}}
                           onChange={this.onChangeTagSearch} placeholder={store.trans["Search by tags"]}
                           value={photoTagGroupFilterStore.filter}/>
                </div>
                <div className="flex-stretch-item TagsAdd-marginLR style-4 overflowAutoY">
                    {tagGroups}
                </div>
                <div className="flex-columns flex-center-byPerp TagsAdd-footer">
                    {this.props.mode == PhotoSelectTagMode.add && <button onClick={this.onClickOk}
                            className="large-button left-sidebar-active button-jump popup-exp-2button-button TagsAdd-button">
                        {store.trans.Add}
                    </button>}
                    {this.props.mode == PhotoSelectTagMode.remove && <button onClick={this.onClickOk}
                                                                          className="large-button left-sidebar-red button-jump popup-exp-2button-button TagsAdd-button">
                        {store.trans.Delete}
                    </button>}
                    <button
                        className="large-button left-sidebar-cancel button-jump popup-exp-2button-button text-next TagsAdd-button"
                        onClick={this.onClickCancel}>
                        {store.trans.Cancel}
                    </button>
                </div>
            </div>
        </div>;
    }
}
