import * as React from 'react';
import {IStoreProps} from "../../../../helper/structs/IStoreProps";

export interface IOrderStatusWork extends IStoreProps{
    text?: string;
}
export class OrderStatusWork extends React.PureComponent<IOrderStatusWork, undefined> {

    render() {
        let store = this.props.store;
        return <div className="journal-status work">
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5229 20 10C20 4.47716 15.5228 0 10 0C4.47717 0 0 4.47716 0 10C0 15.5229 4.47717 20 10 20ZM12.8876 12.0124L14.1125 13.2374L16.7375 10.6124C17.0875 10.2624 17.0875 9.73743 16.7375 9.38742L14.1125 6.76242L12.8876 7.98743L14.9 9.99992L12.8876 12.0124ZM5.88751 13.2374L3.26251 10.6124C2.91248 10.2624 2.91248 9.73743 3.26251 9.38742L5.88751 6.76242L7.11249 7.98743L5.09998 9.99992L7.11249 12.0124L5.88751 13.2374ZM7.98761 16.0375C8.07507 16.125 8.1626 16.125 8.25006 16.125C8.6001 16.125 8.95007 15.8625 9.12506 15.5125L12.6251 5.01248C12.8001 4.48749 12.5376 4.04999 12.1001 3.87498C11.5751 3.69998 11.1376 3.96248 10.9626 4.39998L7.46259 14.9C7.2876 15.425 7.55011 15.8625 7.98761 16.0375Z"/>
            </svg>
            {this.props.text == null && <div className="journal-status-name">{store.trans.Wait}</div>}
            {this.props.text != null && <div className="journal-status-name">{this.props.text}</div>}
        </div>;
    }
}
