import {CustomStore} from "../CustomStore";
import {observable} from "mobx";

export class PhotoTagStore extends CustomStore{
    @observable
    tag_name: string;
    @observable
    tag_id: number;

    @observable
    checked: boolean = false;
    @observable
    disabled: boolean = false;
    @observable
    highlight: boolean = false;
}