import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {Property} from "csstype";
import {action} from "mobx";
import {Utils} from "../../../helper/utils/Utils";

@observer
export class GeoObjectMenuComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onRemoveObject() {
        this.props.store.map.superTools.searchAddressMarker.geoObject = null;
    }

    @action
    onDownloadObject() {
        let feature = this.props.store.map.superTools.searchAddressMarker.geoObject;
        Utils.downloadJson(feature.properties.name.split(',')[0] + '.geojson',
            {'type': 'FeatureCollection', 'features': [feature]});
    }

    @action
    onMakeSearchObject() {
        let map = this.props.store.map;
        let feature = map.superTools.searchAddressMarker.geoObject;
        map.searchObject.content = {'type': 'FeatureCollection', 'features': [feature]};
        map.searchObject.name = feature.properties.name;
        map.searchObject.visible = true;
        map.superTools.searchAddressMarker.geoObject = null;
        map.superTools.searchAddressMarker.isMenuShown = false;
    }

    @action
    onMakeSurveyPoint() {
        let ms = this.props.store.map;
        let geoObject = ms.superTools.searchAddressMarker.geoObject;
        let point = ms.mapbox.project([geoObject.properties.lon, geoObject.properties.lat]);
        ms.superTools.surveyPointsTool.add(
            ms.surveyPoints.createNewPoint([geoObject.properties.lon, geoObject.properties.lat], [point.x, point.y])
        );
        ms.superTools.searchAddressMarker.geoObject = null;
        ms.superTools.searchAddressMarker.isMenuShown = false;
        ms.surveyPoints.showPanel = true;
    }

    render() {
        let tool = this.props.store.map.superTools.searchAddressMarker;
        let vis : Property.Visibility = tool.geoObject? "visible": "hidden";
        let point = ! tool.geoObject? null :
            this.props.store.map.mapbox.project([tool.geoObject.properties.lon, tool.geoObject.properties.lat]);
        return <div className="geo-object-context-menu"
                    style={{
                        visibility: vis,
                        left: point? point.x + 10: null,
                        top: point? point.y - 8: null
                    }}>
            <div className="geo-object-context-menu-item" onClick={this.onDownloadObject}>
                {this.props.store.trans["Download"]}
            </div>
            <div className="geo-object-context-menu-item" onClick={this.onRemoveObject}>
                {this.props.store.trans["Remove"]}
            </div>
            <div className="geo-object-context-menu-item" onClick={this.onMakeSearchObject}>
                {this.props.store.trans["Set Area of Interest"]}
            </div>
            <div className="geo-object-context-menu-item" onClick={this.onMakeSurveyPoint}>
                {this.props.store.trans["Make it an inspection point"]}
            </div>
        </div>;
    }
}