import React, { Fragment, useEffect, useRef, useState } from "react";
import PointMarkerSvg from "../../../app/components/icons/PointMarkerSvg";
import classNames from "classnames";
import { CenterToSvg } from "../../../app/components/panels/Left/Agro/Buttons/CenterToSvg";
import { DropDownComp, IDropDownItem } from "../../../app/components/Common/DropDownComp";
import { ICrop, MeteoStore, PeriodMode } from "../MeteoStore";
import { fetchJsonGet } from "../../../app/helper/utils/FetchUtils";
import { Translate } from "../../../app/helper/lang/Translate";
import { Translate_en } from "../../../app/helper/lang/Translate_en";
import { ReactDoubleDatePicker2Comp } from "../../../app/components/Common/ReactDoubleDatePicker2Comp";
import { IIStoreProps } from "../../../app/helper/structs/IStoreProps";
import { IMeteoStoreProps } from "../meteoPlugin";
import { CheckboxMiniComp, CheckboxSize, TriState } from "../../../app/components/Common/CheckboxMiniComp";
import { Utils } from "../../../app/helper/utils/Utils";
import { IntervalStore } from "../../../app/store/IntervalStore";
import { ra } from "../../../app/helper/utils/mobxUtils";
import { RadioButton } from "../../../app/components/Common/RadioButton";
import { CalendarComp } from "../../../app/components/Common/CalendarComp";
import { isDate, isNumber } from "lodash-es";

export interface IMeteoReportCompProps extends IMeteoStoreProps {
    isActive: boolean;
    onActiveChanged: (active: boolean)=> void;
    //regionName: string;
    onCentered: ()=> void;
    farmName: string;
    onFarmNameChanged: (name: string)=>void;
    cropId: number | string;
    addForecast: boolean;
    canCreateReport: boolean;
    isReportLoading: boolean;
    //reportDateInterval: IntervalStore;
    onCropChanged: (cropId: number | string)=>void;
    //onDatesChanged: ()=>void;
    onAddForecastChanged: (addForecast: boolean)=>void;
    //onChangePeriodMode: (mode: PeriodMode)=>void;
    onReportLoadingChanged: (isReportLoading: boolean)=>void;
}

interface ICreateReport {
    farm_name: string;
    crop_names: string[];
    location: {lon: number, lat: number, adm4_osm_id: number, adm6_osm_id: number};
    retro: {beg_dt: string, end_dt: string};
    forecast: {beg_dt: string, end_dt: string};
}

const MeteoReportComp = (props: IMeteoReportCompProps) => {
    const [crops, setCrops] = useState([]);
    const [analysDate, setAnalysDate] = useState(props.store.meteoStore.reportAnalysDate);
    const [periodMode, setPeriodMode] = useState(props.store.meteoStore.reportPeriodMode);
    const refPeriod = useRef(props.store.meteoStore.reportDateInterval);
    const [begin, setBegin] = useState(props.store.meteoStore.reportDateInterval.begin);
    const [end, setEnd] = useState(props.store.meteoStore.reportDateInterval.end);
    useEffect(() => {        
        if (begin?.getTime() != props.store.meteoStore.reportDateInterval.begin?.getTime()) {
            setBegin(props.store.meteoStore.reportDateInterval.begin);
        }
    }, [props.store.meteoStore.reportDateInterval.begin])
    useEffect(() => {        
        if (end?.getTime() != props.store.meteoStore.reportDateInterval.end?.getTime()) {
            setEnd(props.store.meteoStore.reportDateInterval.end);
        }
    }, [props.store.meteoStore.reportDateInterval.begin])
    const [calendarOpened, setCalendarOpened] = useState(false);
    useEffect(() => {
        fetchJsonGet("/api/reference/crop/list").then(r => {
            let cs: IDropDownItem[] = [{key: '', value: `[${props.store.trans["Not set"]}]`, data: null}];
            (r.values as ICrop[]).forEach(c => cs.push({key: c.crop_id, value: c.crop_name, data: c}));
            setCrops(cs);
        });
    }, []);

    const downloadReport = (params: ICreateReport) => {
        let url = `/api/meteo/report/create?async=0&type=rshb&params=${JSON.stringify(params)}`;
        try {
            props.onReportLoadingChanged(true);
            console.log(`download file ${params.farm_name}.pdf`);
            Utils.downloadFile(url, `${params.farm_name}.pdf`);
        }
        catch (err) {
            props.store.addError(err);
        }
        finally {
            props.onReportLoadingChanged(false);
        }
    }

    let meteo = props.store.meteoStore;
    let d = meteo.districtData?.district;
    let regionName = d? d.name + ", " + d.region?.name : "";
    let fcastBegin = Utils.formatDate(new Date());
    let fcastEnd = Utils.formatDate(Utils.getDateOffset(new Date(), +9));
    let forecastText = props.addForecast? `${fcastBegin} - ${fcastEnd}` : "";
    let coos = meteo.districtData?.initialPoint.coordinates;
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() - MeteoStore.METEO_DAYS_GAP);
    let minDate = new Date(2010, 0, 1);
    let [dateFrom, dateTo]: [string, string] = [null, null];
    let analysFrom = isDate(analysDate)? Utils.formatDate(Utils.getDateOffset(analysDate, periodMode == PeriodMode.Days10? -10: -30)): null;
    let analysTo = isDate(analysDate)? Utils.formatDate(Utils.getDateOffset(analysDate, -1)): null;
    if (periodMode == PeriodMode.Custom && isDate(begin) && isDate(end)) {
        dateFrom = Utils.formatDate(begin);
        dateTo = Utils.formatDate(end);
    } else if (periodMode != PeriodMode.Custom && isDate(analysDate)) {
        [dateFrom, dateTo] = [analysFrom, analysTo];
    }

    return <Fragment>
        {/* <div className={classNames('', {'disable': props.isReportLoading})}> */}
    <div className="MeteoPanelComp-Reports-add-button-div">
        <div className={classNames("MeteoPanelComp-Reports-add-button", 
            {"active": props.isActive, "notactive": !props.isActive})}
            onClick={() => props.onActiveChanged(!props.isActive)}>
            <PointMarkerSvg isActive={false}/>        
        </div>
        <div className="MeteoPanelComp-Reports-add-button-help">Выберете район, кликнув на карту</div>
    </div>
    {props.store.meteoStore.districtData && <Fragment>
    <div className="MeteoPanelComp-Reports-region-div">
        <div>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 0C3.1 0 0 3.1 0 7C0 8.9 0.7 10.7 2.1 12C2.2 12.1 6.2 15.7 6.3 15.8C6.7 16.1 7.3 16.1 7.6 15.8C7.7 15.7 11.8 12.1 11.8 12C13.2 10.7 13.9 8.9 13.9 7C14 3.1 10.9 0 7 0ZM7 9C5.9 9 5 8.1 5 7C5 5.9 5.9 5 7 5C8.1 5 9 5.9 9 7C9 8.1 8.1 9 7 9Z" fill="#C5C5C5"></path>
            </svg>
        </div>
        <div className="MeteoPanelComp-Reports-region-name">{regionName}</div>
        <div className="blue_svg_onhover white_svg pointer" onClick= {props.onCentered}>
            <CenterToSvg />
        </div>
    </div>
    <div className="flex-column">
        <div className="flex-input-title">
            <div className="flex-stretch-item">Название хозяйства / страхователь<span className="ActiveSensorDay-star">*</span></div>
        </div>
        <div className="flex-input-value">
            <input type="text" className="text-box-editor width100" value={props.store.meteoStore.farmName}
                onChange={(e) => props.onFarmNameChanged(e.currentTarget.value)}/>
        </div>
    </div>
    <div className="flex-column">
        <div className="flex-input-title">
            <div className="flex-stretch-item">Культура</div>
        </div>
        <DropDownComp items={crops} currentKey={props.cropId}
            onChange={(item: IDropDownItem) => props.onCropChanged(item.key)}
            className="Export2Panel-switchModeDropdown width100" />

    </div>
    <div className="MeteoPanelComp-Reports-line"/>
    <div className="flex-column">
        <div className='MeteoPanelComp-period-title'>{props.store.trans.Period}<span className="ActiveSensorDay-star">*</span></div>
        <div>
            <div className='MeteoPanelComp-period-selector'>
                <RadioButton checked={periodMode == PeriodMode.Days10}
                    onClick={() => {setPeriodMode(PeriodMode.Days10); meteo.reportPeriodMode = PeriodMode.Days10;}} />
                <div className='MeteoPanelComp-period-description'>
                    <div>{meteo.trans['10 days before the analysis date']}</div>
                    <div className={classNames('MeteoPanelComp-period-description-color')}>
                        {!isDate(analysDate)? "": `${analysFrom} - ${analysTo}`}
                    </div>
                </div>
            </div>                
            <div className='MeteoPanelComp-period-selector'>
                <RadioButton checked={periodMode == PeriodMode.Days30}
                    onClick={() => {setPeriodMode(PeriodMode.Days30); meteo.reportPeriodMode = PeriodMode.Days30;}} />
                <div className='MeteoPanelComp-period-description'>
                    <div>{meteo.trans['30 days before the analysis date']}</div>
                    <div className={classNames('MeteoPanelComp-period-description-color')}>
                        {!isDate(analysDate)? "": `${analysFrom} - ${analysTo}`}
                    </div>
                </div>
            </div>                
            <div className='MeteoPanelComp-period-selector'>
                <RadioButton checked={periodMode == PeriodMode.Custom} 
                    onClick={() => {setPeriodMode(PeriodMode.Custom); meteo.reportPeriodMode = PeriodMode.Custom;}} />
                <div className='MeteoPanelComp-period-description'>
                    <div>{meteo.trans['Arbitrary period']}</div>
                    <div className='MeteoPanelComp-period-description-color'>
                        {isDate(begin) && isDate(end)? (Utils.getDatesDiff(end, begin, "day") + 1) + " дн." : ""}
                    </div>
                </div>
            </div>    
        </div>

        <div className="MeteoPanelComp-period">
            <div className='MeteoPanelComp-period-title'>{periodMode == PeriodMode.Custom? meteo.trans.Period : meteo.trans['Date of analysis']}</div>
            { periodMode == PeriodMode.Custom && 
            <React.Fragment>
                <div className="MeteoPanelComp-interval">
                    <ReactDoubleDatePicker2Comp store={props.store} interval={refPeriod.current} maxIntervalDays={90}
                                                onChange={() => { 
                                                    setBegin(meteo.reportDateInterval.begin);
                                                    setEnd(meteo.reportDateInterval.end);
                                                    meteo.canCreateReport = Utils.isStringNotEmpty(meteo.farmName) && meteo.reportDateInterval.isValid();
                                                }}
                                                maxDate={maxDate} minDate={minDate}/>
                </div>
            </React.Fragment>
            }
            { periodMode != PeriodMode.Custom && 
            <div className="MeteoPanelComp-Analys-Date">
                    <CalendarComp open={calendarOpened} value={analysDate} classCalendar="date-input"
                    onOpen={() => setCalendarOpened(true)} onClose={() => setCalendarOpened(false)} onChange={(val) => setAnalysDate(val)}
                    maxDate={maxDate} minDate={minDate} locale={props.store.getLocale()}/>
            </div>}
        </div>
        <div className="MeteoPanelComp-dates-note">{props.store.trans["The period length is no more than 90 days"]}</div>
    </div>
    <div className="flex-column">
        <div className="MeteoPanelComp-value-checkbox" onClick={() => props.onAddForecastChanged(!props.addForecast)}>
            <CheckboxMiniComp state={props.addForecast? TriState.check:TriState.uncheck}
                            size={CheckboxSize.standart} classesContainer="pointer">
            </CheckboxMiniComp>
            <span className={classNames("MeteoPanelComp-param-overlay-title", {
                "LayersListComp-active-color": props.addForecast,
                "LayersListComp-inactive-color": !props.addForecast
            })}>Добавить прогнозные данные на 10 дней</span>
        </div>
        <div className="MeteoPanelComp-dates-note margin-left-30">{forecastText}</div>
    </div>
    <div className='MeteoPanelComp-edit-buttons'>
        <button
            className='MeteoPanelComp-cancel-button button-jump'
            onClick={() => {meteo.districtData = null}}
        >
            {props.store.trans.Cancel}
        </button>
        <button
            className={classNames('MeteoPanelComp-save-button button-jump', {
                'opacity30' : (!props.canCreateReport || !isDate(analysDate)) && !props.isReportLoading
            })}
            onClick={() => downloadReport({
                farm_name: props.farmName,
                crop_names: isNumber(props.cropId)? [crops.find(c => c.key == props.cropId)?.value] : null,//isNumber(props.cropId)? props.cropId as number : null,
                location: {lat: coos[1], lon: coos[0], adm6_osm_id: d.osm_id, adm4_osm_id: d.region.osm_id},
                retro: {beg_dt:  dateFrom, end_dt: dateTo},
                forecast: meteo.addForecast? {beg_dt: fcastBegin, end_dt: fcastEnd} : null
            })}
            disabled={!props.canCreateReport || !isDate(analysDate)}>
            {meteo.trans["Create report"]}
        </button>
    </div>

    </Fragment>}
    </Fragment>
    // </div>;
}

export default MeteoReportComp;