import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {action, observable} from "mobx";
import {ra} from "../../../../helper/utils/mobxUtils";

@observer
export class PersonalArea_NamePanel extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.initValues();
    }
    @observable
    surname: string = "214e124e";
    @observable
    firstname: string = "rrrrwqe";

    @action onChangeSurname(event: any){
        this.surname = event.target.value;
    }
    @action onChangeFirstname(event: any){
        this.firstname = event.target.value;
    }
    @observable
    edit_mode: boolean = false;

    @action
    onCliskEdit(){
        this.edit_mode = true;
    }

    @action
    onClickCancel(){
        this.edit_mode = false;
        this.initValues();
    }

    @action
    async onClickSave(){
        let store = this.props.store;
        try {
            await store.user.changeNameSend(this.surname, this.firstname);
            ra(()=>{
                this.edit_mode = false;
                store.user.surname = this.surname;
                store.user.given_name = this.firstname;
            });
        }catch (e) {
            store.addError(e);
        }
    }

    @action
    initValues(){
        this.surname = this.props.store.user.surname;
        this.firstname = this.props.store.user.given_name;
    }

    render() {
        let store = this.props.store;
        return <React.Fragment>
            <div className={classNames("PersonalAreaComp-keyValuePair", {"PersonalAreaComp-disabledArea": !this.edit_mode})}>
                <div className="PersonalAreaComp-key">
                    {store.trans.Surname}
                </div>
                <div className="PersonalAreaComp-value">
                    <input type="text" disabled={!this.edit_mode}
                           style={{margin: ""}}
                           className={classNames("text-box-editor width100 input-DarkBack", {"": this.edit_mode})}
                           value={this.surname}
                           onChange={this.onChangeSurname}
                    />
                </div>
                <div className="PersonalAreaComp-keyValuePair">
                    <div className="PersonalAreaComp-key">
                        {store.trans.Firstname}
                    </div>
                    <div className="PersonalAreaComp-value">
                        <input type="text" disabled={!this.edit_mode}
                               style={{margin: ""}}
                               className={classNames("text-box-editor width100 input-DarkBack", {"": this.edit_mode})}
                               value={this.firstname}
                               onChange={this.onChangeFirstname}
                        />
                    </div>
                </div>
            </div>
            {!this.edit_mode && <div className="PersonalAreaComp-linkButtonsRow link-text-underlineOnHover" onClick={this.onCliskEdit}>
                {store.trans["Change personal data"]}
            </div>}
            {this.edit_mode && <div className="PersonalAreaComp-linkButtonsRow">
                <span className="PersonalAreaComp-linkButton link-text-underlineOnHover" onClick={this.onClickCancel}>{store.trans.Cancel}</span>
                <span className="PersonalAreaComp-linkButton link-text-underlineOnHover" onClick={this.onClickSave}>{store.trans.Save}</span>
            </div>}
        </React.Fragment>;
    }
}
