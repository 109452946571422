// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permalinkPanel-outer {
  position: relative;
  z-index: 100000;
}

.permalinkPanel {
  position: relative;
  width: 350px;
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px 10px 10px 10px;
}

.permalinkPanel-input {
  outline: none;
  color: #C5C5C5;
  width: 100%;
  height: 27px;
  position: relative;
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 31px 5px 5px;
}

.permalinkPanel-copy {
  position: absolute;
  top: 24px;
  right: 18px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.permalinkPanel-copied-box {
  position: absolute;
  top: -13px;
  width: 100%;
  z-index: 1;
}

.permalinkPanel-copied-label {
  color: #C5C5C5;
  background: #2B3138;
  border: 1px solid #3E4751;
  box-sizing: border-box;
  border-radius: 6px;
  margin: auto;
  font-size: 11px;
  line-height: 15px;
  padding: 5px 10px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/PermalinkPanelComp.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;AAEF;;AAAA;EACE,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;AAGF;;AADA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AAIF;;AAFA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;AAKF;;AAHA;EACE,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AAMF","sourcesContent":[".permalinkPanel-outer{\r\n  position: relative;\r\n  z-index: 100000;\r\n}\r\n.permalinkPanel{\r\n  position: relative;\r\n  width: 350px;\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  border-radius: 6px;\r\n  padding: 20px 10px 10px 10px;\r\n}\r\n.permalinkPanel-input{\r\n  outline: none;\r\n  color: #C5C5C5;\r\n  width: 100%;\r\n  height: 27px;\r\n  position: relative;\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  border-radius: 6px;\r\n  padding: 5px 31px 5px 5px;\r\n}\r\n.permalinkPanel-copy{\r\n  position: absolute;\r\n  top: 24px;\r\n  right: 18px;\r\n  cursor: pointer;\r\n  width: 18px;\r\n  height: 18px;\r\n}\r\n.permalinkPanel-copied-box{\r\n  position: absolute;\r\n  top: -13px;\r\n  width: 100%;\r\n  z-index: 1;\r\n}\r\n.permalinkPanel-copied-label{\r\n  color: #C5C5C5;\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  box-sizing: border-box;\r\n  border-radius: 6px;\r\n  margin: auto;\r\n  font-size: 11px;\r\n  line-height: 15px;\r\n  padding: 5px 10px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
