import {CustomStore} from "../CustomStore";
import {save} from "../PermalinkDecor";
import {action, computed, get, observable, set, toJS} from "mobx";
import {IDictonaryType} from "../../helper/utils/Utils";
import {IOrderedProducts} from "../config/ConfigStore";
import {ExportStore} from "./ExportStore";

export class FavoriteExportStore extends CustomStore<ExportStore>{
    constructor(parent: ExportStore) {
        super(parent);
        this.bandValues = observable<IDictonaryType<boolean>>({});
        this.indexValues = observable<IDictonaryType<boolean>>({});
    }

    @save @observable
    apply_cutline: boolean = true;//!
    @save @observable
    albedo: boolean = false;//!
    @save @observable
    mergeScenes: boolean = false;//Merge scenes from single path
    @save @observable
    combine_products: boolean = false;//!
    @save @observable
    bandValues: IDictonaryType<boolean> = null;
    @save @observable
    indexValues: IDictonaryType<boolean> = null;

    @action
    setBandValue(name: string, value: boolean){
        set<IDictonaryType<boolean>>(this.bandValues, name, value);
    }
    @action
    cleatBandValue(){
        this.bandValues = observable<IDictonaryType<boolean>>({});
    }
    getBandValue(name: string): boolean{
        let v = toJS(get<IDictonaryType<boolean>>(this.bandValues, name));
        if (v == null) return  false;
        return v;
    }

    @action
    setIndexValue(name: string, value: boolean){
        set<IDictonaryType<boolean>>(this.indexValues, name, value);
    }
    @action
    clearIndexValue(){
        this.indexValues = observable<IDictonaryType<boolean>>({});
    }
    getIndexValue(name: string): boolean{
        return toJS(get<IDictonaryType<boolean>>(this.indexValues, name));
    }

    get productConfig(): IOrderedProducts{
        let scenes = this.parent.getSceneItems();
        if (scenes.length == 0){
            return null;
        }
        let sat = scenes[0].feature.properties.satellite;
        return this.root.config.ordered_products[sat];
    }

    @computed
    get getBandsNames(): string[]{
        let q = this.productConfig;
        if (q == null) return [];
        let arr: string[] = [];
        q.bands.forEach(a => {
            arr.push(a.name);
        });
        return arr;
    }

    getSelectedBands(): string[]{
        let bandNames = this.getBandsNames;
        let arr = bandNames.filter(a => this.getBandValue(a));
        return arr;
    }

    getSelectedIndexes(): string[]{
        let names = this.getIndexesNames;
        let s: string[] = [];
        names.forEach(a =>{
            if (this.getIndexValue(a)) s.push(a);
        });
        return s;
    }

    @computed
    get getIndexesNames(): string[]{
        let q = this.productConfig;
        if (q == null) return [];
        let arr: string[] = [];
        q.indexes.forEach(a =>{
            arr.push(a);
        });
        return arr;
    }

    canExport(): boolean{ return true;}

    async sendOrder(){}
}