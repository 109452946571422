// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoMapDebugItemComp-main {
  padding: 3px;
  cursor: pointer;
  display: flex;
}

.AgroAhoMapDebugItemComp-main:hover {
  background: #383F48;
}

.AgroAhoMapDebugItemComp-id {
  flex-grow: 1;
}

.AgroAhoMapDebugItemComp-delCover {
  cursor: pointer;
}

.AgroAhoMapDebugItemComp-delCover svg {
  fill: #C5C5C5;
}

.AgroAhoMapDebugItemComp-delCover:hover svg {
  fill: #E94F4F;
}

.AgroAhoMapDebugItemComp-delCover svg {
  fill: #C5C5C5;
}

.AgroAhoMapDebugItemComp-hideCover {
  margin-right: 8px;
}

.AgroAhoMapDebugItemComp-hideCover:hover {
  opacity: 0.7;
}

.AgroAhoMapDebugItemComp-hideCover svg {
  stroke: #4DB6BC;
  fill: #4DB6BC;
}

.AgroAhoMapDebugItemComp-invisible svg {
  stroke: #C5C5C5;
  fill: #C5C5C5;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/map/debug/AgroAhoMapDebugItemComp.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,aAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,eAAA;AAIF;;AAFA;EACE,aAAA;AAKF;;AAHA;EACE,aAAA;AAMF;;AAJA;EACE,aAAA;AAOF;;AALA;EACE,iBAAA;AAQF;;AANA;EACE,YAAA;AASF;;AAPA;EACE,eAAA;EACA,aAAA;AAUF;;AARA;EACE,eAAA;EACA,aAAA;AAWF","sourcesContent":[".AgroAhoMapDebugItemComp-main {\r\n  padding: 3px;\r\n  cursor: pointer;\r\n  display: flex;\r\n}\r\n.AgroAhoMapDebugItemComp-main:hover {\r\n  background: #383F48;\r\n}\r\n.AgroAhoMapDebugItemComp-id {\r\n  flex-grow: 1;\r\n}\r\n.AgroAhoMapDebugItemComp-delCover {\r\n  cursor: pointer;\r\n}\r\n.AgroAhoMapDebugItemComp-delCover svg {\r\n  fill: #C5C5C5;\r\n}\r\n.AgroAhoMapDebugItemComp-delCover:hover svg {\r\n  fill: #E94F4F;\r\n}\r\n.AgroAhoMapDebugItemComp-delCover svg {\r\n  fill: #C5C5C5;\r\n}\r\n.AgroAhoMapDebugItemComp-hideCover {\r\n  margin-right: 8px;\r\n}\r\n.AgroAhoMapDebugItemComp-hideCover:hover {\r\n  opacity: .7;\r\n}\r\n.AgroAhoMapDebugItemComp-hideCover svg {\r\n  stroke: #4DB6BC;\r\n  fill: #4DB6BC;\r\n}\r\n.AgroAhoMapDebugItemComp-invisible svg {\r\n  stroke: #C5C5C5;\r\n  fill: #C5C5C5;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
