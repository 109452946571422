import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCardItemComp.scss'
import {action} from "mobx";
import {AhoModalType} from "../../agroAhoStore";
import {AgroAhoRectCheckboxSvg} from "./../AgroAhoRectCheckboxSvg";
import classNames from "classnames";
import {Card} from "../../agroAhoCardStore";
import {AhoPerm} from "../../agroAhoProjStore";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {Utils} from "../../../../app/helper/utils/Utils";

export interface IAgroAhoCardItemCompProps {
    store: IAgroAhoSuperStore;
    card: Card;
}

@observer
export class AgroAhoCardItemComp extends React.PureComponent<IAgroAhoCardItemCompProps, undefined> {
    constructor(props: IAgroAhoCardItemCompProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        // this.props.store.agroAhoStore.probeClick(this.props.probe);
        this.props.store.agroAhoStore.cardStore.cardClick(this.props.card);
    }

    @action
    onEditClick(e: any){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let disabled = store_.projStore.perm === AhoPerm.View;
        if (!disabled) {
            cstore.card = this.props.card;
            cstore.newName = cstore.card.map_name;
            store_.toggleModal(true, AhoModalType.EditCard);
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    @action
    onZoomClick(e: any){
        this.props.store.agroAhoStore.mapStore.cardZoomClick(this.props.card);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    @action
    onSelectClick(e: any){
        let card = this.props.card;
        // let noData = !Object.keys(card.field_info).length || !Object.keys(card.cell_info).length ||
        //     !Object.keys(card.track_info).length;
        // if (!noData) this.props.card.selected = !this.props.card.selected;
        this.props.card.selected = !this.props.card.selected;
        e.preventDefault();
        e.stopPropagation();
        return false;
    }



    render() {

        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let card = this.props.card;
        // let noData = !Object.keys(card.field_info).length || !Object.keys(card.cell_info).length ||
        //     !Object.keys(card.track_info).length;
        let idc_len = card.indc_cnt; // Object.keys(card.cell_info.indicators || {}).length;
        let idc_padding = idc_len < 10 ? 6 : 4;
        if (idc_len > 99) idc_padding = 1;
        let area: any = card.field_info.sum_area_ha && parseFloat(card.field_info.sum_area_ha.toFixed(1));
        area = store_.numWithSpaces(area);
        let cells = store_.numWithSpaces(this.props.card.cell_info.cell_cnt);


        // return <div className={classNames("AgroAhoCardItemComp-main",
        //     {"AgroAhoCardItemComp-noData": noData})} onClick={this.onClick}>
        return <div className="AgroAhoCardItemComp-main" onClick={this.onClick}>
            <div className="AgroAhoCardItemComp-wrapper">
                <div className="AgroAhoCardItemComp-leftCover">
                    <AgroAhoRectCheckboxSvg checked={this.props.card.selected} onClick={this.onSelectClick}/>
                </div>
                <div className="AgroAhoCardItemComp-centerCover">
                    <div className="AgroAhoCardItemComp-name">{this.props.card.map_name}</div>
                    <div className="AgroAhoCardItemComp-fields">
                        <span className="AgroAhoCardItemComp-fieldsMargin">{store.trans.Fields}:</span>
                        <span>{this.props.card.field_info.field_cnt || ''}</span>
                        {area && <React.Fragment>
                            <span className="AgroAhoCardItemComp-dataSplitter">/</span>
                            <span>{area}</span>
                            <span>&nbsp;{store.trans.ha}</span>
                        </React.Fragment>}
                    </div>
                    <div className="AgroAhoCardItemComp-cells">
                        <span className="AgroAhoCardItemComp-fieldsMargin">{store.trans['Cells:']}</span>
                        <span>{cells}</span>
                    </div>
                </div>
                <div className="AgroAhoCardItemComp-rightCover">
                    <div className="AgroAhoCardItemComp-updTm">
                        <span>{store_.format_date(this.props.card.upd_tm)}</span>
                    </div>
                    {!!idc_len && <div className="AgroAhoCardItemComp-xls">
                        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 5H23V18.0968C23 18.3363 22.8946 18.5661 22.7071 18.7355C22.5196 18.9048 22.2652 19 22 19H6C5.73478 19 5.48043 18.9048 5.29289 18.7355C5.10536 18.5661 5 18.3363 5 18.0968V5Z" fill="#185C37"/>
                            <path d="M6 0H14V5H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0Z" fill="#21A366"/>
                            <path d="M14 0H22C22.2652 0 22.5196 0.105357 22.7071 0.292893C22.8946 0.48043 23 0.734784 23 1V5H14V0Z" fill="#33C481"/>
                            <path d="M14 5H5V10H14V5Z" fill="#107C41"/>
                            <path d="M23 5H14V10H23V5Z" fill="#21A366"/>
                            <path d="M23 10H14V15H23V10Z" fill="#107C41"/>
                            <path opacity="0.4" d="M18.2857 5H8V16H18.2857C18.7404 16 19.1764 15.8946 19.4979 15.7071C19.8194 15.5196 20 15.2652 20 15V6C20 5.73478 19.8194 5.48043 19.4979 5.29289C19.1764 5.10536 18.7404 5 18.2857 5Z" fill="black"/>
                            <path d="M16.3636 2H1.63636C0.732625 2 0 2.48842 0 3.09091V12.9091C0 13.5116 0.732625 14 1.63636 14H16.3636C17.2674 14 18 13.5116 18 12.9091V3.09091C18 2.48842 17.2674 2 16.3636 2Z" fill="#148349"/>
                            <text x={idc_padding} y="12" fill="white" fontSize="9px" fontWeight="700">{idc_len}</text>
                        </svg>

                    </div>}
                    <div className="AgroAhoCardItemComp-rightHoverButtons">
                        <div className="AgroAhoCardItemComp-editCover" onClick={this.onEditClick}>
                            <svg className="AgroAhoCardItemComp-edit" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.075 2.625L0.225 8.475C0.075 8.625 0 8.775 0 9V11.25C0 11.7 0.3 12 0.75 12H3C3.225 12 3.375 11.925 3.525 11.775L9.375 5.925L6.075 2.625Z" />
                                <path d="M11.775 2.475L9.525 0.225C9.225 -0.075 8.775 -0.075 8.475 0.225L7.125 1.575L10.425 4.875L11.775 3.525C12.075 3.225 12.075 2.775 11.775 2.475Z" />
                            </svg>
                        </div>
                        <div className="AgroAhoCardItemComp-zoomCover" onClick={this.onZoomClick}>
                            <svg className="AgroAhoCardItemComp-zoom" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 8.25C7.24264 8.25 8.25 7.24264 8.25 6C8.25 4.75736 7.24264 3.75 6 3.75C4.75736 3.75 3.75 4.75736 3.75 6C3.75 7.24264 4.75736 8.25 6 8.25Z" />
                                <path d="M1.5 4.5H0V0.75C0 0.3 0.3 0 0.75 0H4.5V1.5H1.5V4.5Z"/>
                                <path d="M12 4.5H10.5V1.5H7.5V0H11.25C11.7 0 12 0.3 12 0.75V4.5Z"/>
                                <path d="M11.25 12H7.5V10.5H10.5V7.5H12V11.25C12 11.7 11.7 12 11.25 12Z"/>
                                <path d="M4.5 12H0.75C0.3 12 0 11.7 0 11.25V7.5H1.5V10.5H4.5V12Z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="AgroAhoCardItemComp-line"> </div>
        </div>
    }
}
