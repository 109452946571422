import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './PhotoTagEditorTag.scss';
import {PhotoTagStore} from "../../../../../store/photo/PhotoTagStore";
import {action, observable} from "mobx";
import {InputStringWindow} from "../../../../Common/InputStringWindow";
import {PhotoTagEditorWindow} from "./PhotoTagEditorWindow";
import {ContextMenuCommonComp} from "../../../../Common/ContextMenuCommonComp";
import {ConfirmFormComp} from "../../../../Common/ConfirmFormComp";
import classNames from "classnames";

export interface IPhotoTagEditorTag extends IStoreProps{
    tag: PhotoTagStore;
}

@observer
export class PhotoTagEditorTag extends React.Component<IPhotoTagEditorTag, undefined> {
    constructor(props: IPhotoTagEditorTag) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        this.openConfirmDelete = true;
    }
    @observable
    openConfirmDelete: boolean = false;
    @action
    confiremedDelete(){
        this.openConfirmDelete = false;
        this.props.store.photo.tagEditor.sendDeleteTag(this.props.tag.tag_id);
    }
    @action
    confimedCancel(){
        this.openConfirmDelete = false;
    }

    @observable
    showRenameTagName: boolean = false;
    @observable
    editName: string = "";

    @action
    onChangeEditText(str: string){
        this.editName = str;
    }
    @action
    onCloseEdit(){
        this.showRenameTagName = false;
    }
    @action
    async doRenameTagName(){
        await this.props.store.photo.tagEditor.sendRenameTag(this.props.tag.tag_id, this.editName);
        this.showRenameTagName = false;
    }
    @action
    onClickRenameTag(){
        this.showRenameTagName = true;
        this.editName = this.props.tag.tag_name;
    }

    render() {
        let store = this.props.store;
        let t = this.props.tag;
        return <div className={classNames("PhotoTagEditorTag-div PhotoTagEditorWindow-showHoverInitiator",{"PhotoTagEditorWindow-showAnyway":this.openConfirmDelete})}>
            <span>{t.tag_name}</span>
            <div className="flex-stretch-item"/>
            <div className={classNames("flex-columns flex-align-items-center PhotoTagEditorWindow-showHover")}>
                <div className="icon_container-mini16 pointer" onClick={this.onClickRenameTag}>
                    <svg width="14" height="14" fill="#C5C5C5" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.0875 3.0625L0.2625 9.8875C0.0875 10.0625 0 10.2375 0 10.5V13.125C0 13.65 0.35 14 0.875 14H3.5C3.7625 14 3.9375 13.9125 4.1125 13.7375L10.9375 6.9125L7.0875 3.0625Z"/>
                        <path d="M13.7375 2.8875L11.1125 0.2625C10.7625 -0.0875 10.2375 -0.0875 9.8875 0.2625L8.3125 1.8375L12.1625 5.6875L13.7375 4.1125C14.0875 3.7625 14.0875 3.2375 13.7375 2.8875Z"/>
                    </svg>
                </div>

                <div className="icon_container_red icon_container-mini16 text-next pointer" onClick={this.onClickDelete}>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 4.375V2.625H10.5V0.875C10.5 0.392 10.1089 0 9.625 0H4.375C3.89112 0 3.5 0.392 3.5 0.875V2.625H0V4.375H14ZM5.25 1.75H8.75V2.625H5.25V1.75Z"/>
                        <path d="M11.375 6.125V12.25H2.625V6.125H0.875V13.125C0.875 13.608 1.26613 14 1.75 14H12.25C12.7339 14 13.125 13.608 13.125 13.125V6.125H11.375Z"/>
                    </svg>
                    {this.openConfirmDelete && <ContextMenuCommonComp
                        autoCornerWidth={125} indentVertical={10}
                        autoCornerHeight={155} onClose={this.confimedCancel}
                        className="">

                        <ConfirmFormComp text={store.trans["Are you sure you want to remove the tag?"]}>
                            <div className="flex-columns">
                                <div className="red underline-link AgroConfirmDeleteFarmComp-button" onClick={this.confiremedDelete}>{store.trans.Delete}</div>
                                <div className="white text-next-2 underline-link AgroConfirmDeleteFarmComp-button" onClick={this.confimedCancel}>{store.trans.Cancel}</div>
                            </div>
                        </ConfirmFormComp>
                    </ContextMenuCommonComp>}
                </div>
            </div>
            {this.showRenameTagName && <InputStringWindow value={this.editName}
                title={store.trans["Changing the tag name"]} name={store.trans.Name} okText={store.trans.Change} cancelText={store.trans.Cancel}
                onChange={this.onChangeEditText} onCancelClick={this.onCloseEdit} onOkClick={this.doRenameTagName}
                isDisable={PhotoTagEditorWindow.isDisableEditText(this.editName)}  />
            }
        </div>;
    }
}
