import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {IndexBandExportStore} from "./IndexBandExportStore";
import {IDictonaryType} from "../../../app/helper/utils/Utils";
import {observable} from "mobx";
import {update} from "../../../app/store/PermalinkDecor";
import {VoronovExportStore} from "./VoronovExportStore";
import {Lang} from "../../../pluginApi/store/Lang";
import {AgroExportTranslate_en} from "../translate/AgroExportTranslate_en";
import {AgroExportTranslate_ru} from "../translate/AgroExportTranslate_ru";


export class AgroExportStore extends  ObservableCustomStore{

    @observable
    private _export: IDictonaryType<IndexBandExportStore> = {};//new IndexBandExportStore(this);

    getIndexBandExportStore(prodName: string): IndexBandExportStore{
        if (this._export[prodName]) return this._export[prodName];
        let t = new IndexBandExportStore(this);
        this._export[prodName] = t;
        return t;
    }
    @update @observable
    voronovExportStore = new VoronovExportStore(this);

    @observable
    trans: AgroExportTranslate_en = new AgroExportTranslate_en();
    calculateTranslate(){
        if (this.root.lang == Lang.ru) {
            this.trans = new AgroExportTranslate_ru();
        } else {
            this.trans = new AgroExportTranslate_en();
            this.trans.init();
        }
    }

}