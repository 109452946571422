import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {action} from "mobx";
import {observer} from "mobx-react";
import React from "react";
import {Utils} from "../../../helper/utils/Utils";
import {IGibsModisOverlayParams} from "../../../store/config/ConfigStore";
import { CheckboxMiniComp, CheckboxSize, TriState } from "../../Common/CheckboxMiniComp";
import { GearSvg } from "../../icons/MiniIcon/GearSvg";
import { IParameterizedOverlayProps } from "../Right/LayersList/LayersListStore";
import { EllapseGroupSvg } from "../../icons/MiniIcon/EllapseGroupSvg";
import { ExpandGroupSvg } from "../../icons/MiniIcon/ExpandGroupSvg";

@observer
export class GibsOverlayComp extends React.Component<IParameterizedOverlayProps, undefined> {
    constructor(props: IParameterizedOverlayProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onOverlayToggled(e: any) {
        let gibs = this.props.store.map.gibs;
        gibs.active = !gibs.active;
        if (! gibs.gibsLayerId && gibs.params.layers.length > 0)
            gibs.gibsLayerId = gibs.params.layers[0].id;
    }

    @action
    onDateChanged(e: any) {        
        this.props.store.map.gibs.currentDate = e.currentTarget.value;
    }

    @action
    onDateChangeFinished() {
        this.props.store.map.gibs.isEditing = false;
        let ts = Date.parse(this.props.store.map.gibs.currentDate);
        if (isNaN(ts))
            this.props.store.map.gibs.currentDate = Utils.formatDate(this.props.store.map.gibs.date);
        else {
            let newDate = new Date(ts);
            if (this.props.store.map.gibs.date != newDate)
                this.props.store.map.gibs.date = newDate;
        }
    }

    @action
    onDateRight() {
        let dt = this.props.store.map.gibs.date;
        dt.setDate(dt.getDate() + 1);
        this.props.store.map.gibs.date = new Date(dt.getTime());
        this.props.store.map.gibs.currentDate = Utils.formatDate(this.props.store.map.gibs.date);
    }

    @action
    onDateLeft() {
        let dt = this.props.store.map.gibs.date;
        dt.setDate(dt.getDate() - 1);
        this.props.store.map.gibs.date = new Date(dt.getTime());
        this.props.store.map.gibs.currentDate = Utils.formatDate(this.props.store.map.gibs.date);
    }

    @action
    onStartEdit() {
        this.props.store.map.gibs.isEditing = true;
    }

    @action
    onGibsChanged(e: any) {
        this.props.store.map.gibs.gibsLayerId = e.currentTarget.getAttribute('data-id');
    }

    render() {
        let ov = this.props.overlay;
        let params = ov.params as IGibsModisOverlayParams;
        let store = this.props.store;
        let active = this.props.store.map.gibs.active;
        let cur: string = this.props.store.map.gibs.gibsLayerId;

        let modisLayers : any = [];
        params.layers.forEach(lr => {
            modisLayers.push(<div className="ParameterizedOverlays-check-box" key={lr.id}  data-id={lr.id} onClick={this.onGibsChanged}>
            <input type="radio" name={lr.id} id={lr.id} className="css-checkbox" data-id={lr.id}
                   checked={lr.id == cur} readOnly={true}/><label
             style={{color: (lr.id == cur)? "#4DB6BC" : "#C5C5C5",
                 left: 1, position: "relative", fontWeight: (lr.id == cur)? 600: 400}}
             className={classNames("css-label")}
             htmlFor={lr.id}>{lr.title}</label>
        </div>);
        });
        
        return <div className="ParameterizedOverlays-div"
                    style={{height: "auto"}} key={ov.id}>
                    <div className="ParameterizedOverlays-title" onClick={this.onOverlayToggled}>
                    <>
                                <div className="LayersListComp-overlays-group-item-expand-div">
                                {active? <EllapseGroupSvg/>: <ExpandGroupSvg/>}
                                </div>
                            
                            </>
                        <CheckboxMiniComp checkedEye state={active? TriState.check:TriState.uncheck}
                                        size={CheckboxSize.standart} classesContainer="pointer"
                                        styleBox={{marginLeft:"-15px"}}>
                        </CheckboxMiniComp>
                        <span className={classNames("LayersListComp-param-overlay-title", {
                            "LayersListComp-active-color": active,
                            "LayersListComp-inactive-color": !active
                        })}>{ov.title}</span>
                        <div className="ParameterizedOverlays-gear"><GearSvg/></div>
                    </div>
            {active && <div className="ParameterizedOverlays-grid">
                <div>
                    <div className="GibsOverlayComp-vertical-line"></div>
                    <div className="GibsOverlayComp-horizontal-line GibsOverlayComp-date-line"></div>
                    <div className="GibsOverlayComp-horizontal-line GibsOverlayComp-layer-line"></div>
                </div>
                <div>
                    <div className="ParameterizedOverlays-paramname">{store.trans['Date']}</div>
                    <div className="GibsOverlayComp-date-arrows-div">
                        <svg className="GibsOverlayComp-date-arrow-left" onClick={this.onDateLeft}
                            width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.01426 4.65667C0.961404 4.69695 0.918563 4.74889 0.889072 4.80844C0.859581 4.86799 0.844239 4.93355 0.844238 5C0.844238 5.06645 0.859581 5.13201 0.889072 5.19156C0.918563 5.25112 0.961404 5.30305 1.01426 5.34333L7.68093 9.92667C7.74348 9.96962 7.81659 9.99467 7.89234 9.99912C7.96809 10.0036 8.04362 9.98724 8.11078 9.9519C8.17794 9.91657 8.23417 9.86356 8.2734 9.79861C8.31264 9.73365 8.33339 9.65922 8.33343 9.58333L8.33343 0.416667C8.33339 0.340782 8.31264 0.266347 8.2734 0.201393C8.23417 0.13644 8.17794 0.0834354 8.11078 0.0480998C8.04363 0.0127643 7.9681 -0.00356166 7.89234 0.000885318C7.81659 0.0053323 7.74349 0.0303834 7.68093 0.073334L1.01426 4.65667Z" fill="#505860"/>
                        </svg>
                        <svg className="GibsOverlayComp-date-arrow-right" onClick={this.onDateRight}
                            width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.98574 5.34333C8.0386 5.30305 8.08144 5.25111 8.11093 5.19156C8.14042 5.13201 8.15576 5.06645 8.15576 5C8.15576 4.93355 8.14042 4.86799 8.11093 4.80844C8.08144 4.74888 8.0386 4.69695 7.98574 4.65667L1.31907 0.0733322C1.25652 0.0303814 1.18341 0.00533083 1.10766 0.000883754C1.03191 -0.00356332 0.956375 0.012762 0.889219 0.048098C0.822064 0.0834339 0.765834 0.136439 0.726596 0.201392C0.687359 0.266345 0.666605 0.340781 0.666574 0.416665L0.666574 9.58333C0.666605 9.65922 0.687359 9.73365 0.726596 9.79861C0.765833 9.86356 0.822063 9.91656 0.889219 9.9519C0.956375 9.98724 1.0319 10.0036 1.10766 9.99911C1.18341 9.99467 1.25651 9.96962 1.31907 9.92667L7.98574 5.34333Z" fill="#505860"/>
                        </svg>
                        <input type="text" maxLength={10} placeholder="YYYY-MM-DD"
                            // pattern="[\-]?[0-9]{,3}"
                            // onKeyPress={this.onKeyOnlyNums} 
                            onChange={this.onDateChanged}
                            onBlur={this.onDateChangeFinished}
                            onFocus={this.onStartEdit}
                            className={classNames("GibsOverlayComp-text-box number-without-arrow",
                                {"GibsOverlayComp-text-box-active": store.map.gibs.isEditing,
                                "GibsOverlayComp-text-box-passive": !store.map.gibs.isEditing})}
                            value={store.map.gibs.currentDate}/>                        
                    </div>
                    <div className="GibsOverlayComp-layers-grid">{modisLayers}</div>                    
                </div>
            </div>}
        </div>
    }
}