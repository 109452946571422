// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoStmtExtItem-main {
  display: flex;
  padding: 8px 0 8px 24px;
  font-weight: 400;
  font-size: 12px;
  color: #C5C5C5;
  user-select: none;
}

.AgroAhoStmtExtItem-main:hover {
  background: #2E343D;
}

.AgroAhoStmtExtItem-field {
  width: 260px;
}

.AgroAhoStmtExtItem-cell {
  width: 377px;
}

.AgroAhoStmtExtItem-mainCheckbox {
  margin-right: 313px;
}

.AgroAhoStmtExtItem-extCheckbox {
  cursor: pointer;
}

.AgroAhoStmtExtItem-disabled {
  cursor: default;
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/stmt/AgroAhoStmtExtItem.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,mBAAA;AAKF;;AAHA;EACE,eAAA;AAMF;;AAJA;EACE,eAAA;EACA,YAAA;AAOF","sourcesContent":[".AgroAhoStmtExtItem-main {\r\n  display: flex;\r\n  padding: 8px 0 8px 24px;\r\n  font-weight: 400;\r\n  font-size: 12px;\r\n  color: #C5C5C5;\r\n  user-select: none;\r\n}\r\n.AgroAhoStmtExtItem-main:hover {\r\n  background: #2E343D;\r\n}\r\n.AgroAhoStmtExtItem-field {\r\n  width: 260px;\r\n}\r\n.AgroAhoStmtExtItem-cell {\r\n  width: 377px;\r\n}\r\n.AgroAhoStmtExtItem-mainCheckbox {\r\n  margin-right: 313px;\r\n}\r\n.AgroAhoStmtExtItem-extCheckbox {\r\n  cursor: pointer;\r\n}\r\n.AgroAhoStmtExtItem-disabled {\r\n  cursor: default;\r\n  opacity: .3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
