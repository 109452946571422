

export abstract class Translate{
    init(){
        for (let key in this){
            if (this[key] == null || (this as any)[key] == ""){
                (this as any)[key] = key;
            }
        }
    }
}



