import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './PhotoEditor.scss';
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action, observable} from "mobx";
import {ProjType} from "../../../../../store/user/UserStore";
import {CheckboxMiniComp, CheckboxSize, TriState} from "../../../../Common/CheckboxMiniComp";
import {ButtonLight} from "../../../../Common/ButtonLight";
import {TagsAdd} from "../TagsAdd/TagsAdd";
import {Utils} from "../../../../../helper/utils/Utils";
import {ra} from "../../../../../helper/utils/mobxUtils";
import {PhotoSelectTagMode} from "../../../../../store/photo/PhotoSelectTagStore";
import {ContextMenuCommonComp} from "../../../../Common/ContextMenuCommonComp";
import {ConfirmFormComp} from "../../../../Common/ConfirmFormComp";
import './../../Agro/window/AgroCreateFarm.scss';
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";

@observer
export class PhotoEditorTools extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClickSelectAll(){
        this.props.store.photo.editor.selectAll = !this.props.store.photo.editor.selectAll;
    }
    @action
    clickAddTag(){
        this.props.store.photo.editor.selectTags.showAddTag();
    }
    @action
    clickRemoveTag(){
        this.props.store.photo.editor.selectTags.showRemoveTags();
    }

    @action
    onCancelSelectTags(){
        this.props.store.photo.editor.selectTags.closeSelectTagPanel();
    }
    @action
    async onOkAddTag(){
        this.props.store.photo.editor.selectTags.closeSelectTagPanel();
        let tg = this.props.store.photo.editor.selectTags;
        let editor = this.props.store.photo.editor;
        let send_photo_id: number[] = [];
        let send_tags: number[] = [];

        editor.photos.forEach(a => {
            if (a.selected) {
                send_photo_id.push(a.photo_id);
                tg.tagGroupFilter.tagGroups.forEach(g => {
                    g.tags.forEach(t => {
                        if (t.checked && !t.disabled){
                            if (a.tags_ids.find(q => q == t.tag_id) == null){
                                a.tags_ids.push(t.tag_id);
                            }
                        }
                    });
                });
            }
        });
        tg.tagGroupFilter.tagGroups.forEach(g => {
            g.tags.forEach(t => {
                if (t.checked) {
                    send_tags.push(t.tag_id);
                }
            });
        });
        if (send_tags.length > 0 && send_photo_id.length > 0) {
            if (editor.selectAll)
                this.props.store.photo.editor.sendAddTagByFilter(send_tags);
            else
                this.props.store.photo.editor.sendAddTag(send_photo_id, send_tags);
            this.props.store.map.superTools.photoLayersTool.refreshTiles();
        }

    }

    @action
    async onOkRemoveTag(){
        this.props.store.photo.editor.selectTags.closeSelectTagPanel();
        let tg = this.props.store.photo.editor.selectTags;
        let editor = this.props.store.photo.editor;
        let send_photo_id: Set<number> = new Set<number>();
        let send_tags: Set<number> = new Set<number>();

        tg.tagGroupFilter.tagGroups.forEach(g => {
            g.tags.forEach(t => {
                if (t.checked) {
                    send_tags.add(t.tag_id);
                }
            });
        });

        editor.photos.forEach(a => {
            if (a.selected) {
                send_photo_id.add(a.photo_id);
            }
        });
        this.props.store.photo.features.forEach(f =>{
            if (send_photo_id.has(f.properties.photo_id)){
                f.properties.tags = f.properties.tags.filter(q => !send_tags.has(q));
            }
        });

        if (send_tags.size > 0 && send_photo_id.size > 0) {
             this.props.store.photo.editor.sendRemoveTag(Array.from(send_photo_id), Array.from(send_tags));
            this.props.store.map.superTools.photoLayersTool.refreshTiles();
        }
    }

    @observable
    openConfirmDelete: boolean = false;
    @action
    onCancelConfirmDelete(){
        this.openConfirmDelete = false;
    }
    @action
    onOkConfirmDelete(){
        this.openConfirmDelete = false;
        this.doDeletePhotos();
    }

    @action
    clickDeletePhotos(){
        this.openConfirmDelete = true;
    }

    @action
    async doDeletePhotos(){
        let store = this.props.store;
        let editor = store.photo.editor;
        let ids = editor.photos.filter(a => a.selected).map(a => a.photo_id);
        store.photo.features = store.photo.features.filter(a => !ids.includes(a.properties.photo_id));

        if (editor.selectAll) await editor.sendDeletePhotosByFilter();
        else await editor.sendDeletePhotos(ids);
        await store.photo.loadPhotoList();
        this.props.store.map.superTools.photoLayersTool.refreshTiles();
    }

    @action
    async onClickDownload(){
        try {
            this.props.store.photo.editor.busySending = true;
            let ids = this.props.store.photo.editor.photos.filter(a => a.selected).map(a => a.photo_id);
            if (ids.length > 0){
                let url = `/api/projects/${this.props.store.agro.projectName}/photo/zip?photo_id=${ids.join(',')}&zip_name=photos`;
                Utils.downloadFile(url);
            }
        }finally {
            await Utils.pauseAsync(1000);
            ra(()=>{
                this.props.store.photo.editor.busySending = false;
            });

        }
    }

    render() {
        let store = this.props.store;
        let agro = store.agro;
        let editor = store.photo.editor;
        let selectedCount = 0;
        editor.photos.forEach(a => {
            if (a.selected) selectedCount++;
        });

        return <div className="flex-columns PhotoEditor-marginLR PhotoEditorTools-main">
            <div className="PhotoEditorTools-selectAll PhotoEditorTools-item">
                <CheckboxMiniComp state={editor.selectAll?TriState.check:TriState.uncheck}
                                  size={CheckboxSize.standart} onClick={this.onClickSelectAll} />
                <span className="text-next button- pointer" onClick={this.onClickSelectAll}>{store.trans["Select all"]}</span>
            </div>
            <ButtonLight className="ButtonLight-blueBackground PhotoEditorTools-item pointer"
                         disabled={editor.busySending || selectedCount == 0}
                         onClick={this.clickAddTag}>
                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5625 0.25H3.4375V3.4375H0.25V5.5625H3.4375V8.75H5.5625V5.5625H8.75V3.4375H5.5625V0.25Z" fill="#2B3138"/>
                </svg>
                <span>{store.trans.Tag}</span>
            </ButtonLight>
            <ButtonLight disabled={editor.busySending || selectedCount == 0} onClick={this.onClickDownload}
                className="button-next ButtonLight-blueBackground-onhover PhotoEditorTools-item pointer">
                {store.trans.Download}
            </ButtonLight>
            <ButtonLight disabled={editor.busySending || selectedCount == 0} onClick={this.clickRemoveTag}
                className="ButtonLight-redBorder button-next ButtonLight-redBackground-onhover PhotoEditorTools-item pointer">
                {store.trans["Delete tags"]}
            </ButtonLight>
            <ButtonLight disabled={editor.busySending || selectedCount == 0} onClick={this.clickDeletePhotos}
                className="ButtonLight-redBackground button-next PhotoEditorTools-item pointer">
                {store.trans["Delete photo"]}
                {this.openConfirmDelete && <ContextMenuCommonComp
                    autoCornerWidth={125} indentVertical={10}
                    autoCornerHeight={155}
                    className="">

                    <ConfirmFormComp text={TranslateUtils.format(store.trans["Delete photos ({0})?"], selectedCount)}>
                        <div className="flex-columns">
                            <div className="red underline-link AgroConfirmDeleteFarmComp-button" onClick={this.onOkConfirmDelete}>{store.trans.Delete}</div>
                            <div className="white text-next-2 underline-link AgroConfirmDeleteFarmComp-button" onClick={this.onCancelConfirmDelete}>{store.trans.Cancel}</div>
                        </div>
                    </ConfirmFormComp>
                </ContextMenuCommonComp>}
            </ButtonLight>
            {store.photo.editor.selectTags.selectTagPanelShow && store.photo.editor.selectTags.mode == PhotoSelectTagMode.add &&
            <TagsAdd photoTagGroupFilterStore={store.photo.editor.selectTags.tagGroupFilter} store={store}
                     onClickOk={this.onOkAddTag} onClickCancel={this.onCancelSelectTags} mode={PhotoSelectTagMode.add}
            />}
            {store.photo.editor.selectTags.selectTagPanelShow && store.photo.editor.selectTags.mode == PhotoSelectTagMode.remove &&
            <TagsAdd photoTagGroupFilterStore={store.photo.editor.selectTags.tagGroupFilter} store={store}
                     onClickOk={this.onOkRemoveTag} onClickCancel={this.onCancelSelectTags} mode={PhotoSelectTagMode.remove}
            />}
        </div>;
    }
}
