import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoTableUpload3Comp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {Utils} from "../../../../app/helper/utils/Utils";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoStepsComp} from "./AgroAhoStepsComp";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AhoNameColValuesError} from "../../agroAhoUploadTableStore";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoArrowRightSvg} from "../icons/AgroAhoArrowRightSvg";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";
import {AhoUtils} from "../../agroAhoUtils";

@observer
export class AgroAhoTableUpload3Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickNext(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        // console.log('click id', ustore.idColValuesError);
        if (ustore.nameColValuesError !== AhoNameColValuesError.NoError) return;
        ustore.step3NextClick().catch(()=>{});
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadCancelClick();
    }

    @action
    onClickBack(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadBackClick();
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        Utils.downloadFile(url);
    }

    @action
    onColumnChange(col: any){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (ustore.curIdsColumn.num === col.data.num) return;
        ustore.nameColValuesError = AhoNameColValuesError.NoError;
        ustore.nameColErrorList = [];
        ustore.curIdsColumn = col.data;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let tstore = store_.tourStore;

        let items: IDropDownItem[] = [/*{key: 0, data: {tour_id: 0}, value: store_.trans['Create a new tour2']}*/];
        ustore.loadedFileData.columns.forEach(c=>{
            items.push({key: c.num, data: c, value: c.title});
        });
        let maxRows = 10;
        let list: any[] = [];

        // if (ustore.nameColValuesError !== AhoNameColValuesError.NoError) {
        //     list = ustore.idColErrorList.map((v, i)=>{
        //         let type = '';
        //         if (v.type == 'duplicate') type = store_.trans.duplicate;
        //         if (v.type == 'fk') type = store_.trans['incorrect cell number'];
        //         return <div key={i}>
        //             <span>{v.cell_id}&nbsp;(</span>
        //             <span className="AgroAhoTableUpload3Comp-idError">{type}</span>
        //             {v.type == 'duplicate' && <span>,&nbsp;{store_.trans.row}&nbsp;{v.row}</span>}
        //             <span>)</span>
        //         </div>;
        //     });
        // }

        let dubles = AhoUtils.cp(ustore.curIdsColumn?.dubles || {});
        if (ustore.nameColValuesError == AhoNameColValuesError.Duplicates && dubles) {
            let arr = Object.keys(dubles);
            list = arr.slice(0, maxRows).map((key,i)=>{
                let rows = dubles[key]?.rows || [];
                return <div key={i}>{key} ({store_.trans.lines}: {rows.join(', ')})</div>;
            });
            if (arr.length > maxRows) list.push(<div key={-1}>...</div>);
        }
        let emptyRows = ustore.curIdsColumn?.empty_rows || [];
        if (ustore.nameColValuesError == AhoNameColValuesError.EmptyValue && emptyRows.length) {
            list = emptyRows.slice(0, maxRows).map(v=><div key={v}>{v}</div>);
            if (emptyRows.length > maxRows) list.push(<div key={-1}>...</div>);
        }
        let sample = ustore.curIdsColumn?.sample || [];
        if (ustore.nameColValuesError == AhoNameColValuesError.NoError && sample?.length) {
            list = sample.slice(0, maxRows).map(v=><div key={v.row_num}>{v.value}</div>);
            if (sample.length > maxRows) list.push(<div key={-1}>...</div>);
        }
        let errList = ustore.nameColErrorList;
        if (ustore.nameColValuesError == AhoNameColValuesError.NoExistsName && errList.length) {
            list = errList.slice(0, maxRows).map(v=><div key={v.cell_name}>{v.cell_name} ({store_.trans.line} {v.row})</div>);
            if (errList.length > maxRows) list.push(<div key={-1}>...</div>);
        }

        let errMsg = '';
        let listTitle = store_.trans['Examples of values in the selected column'];
        if (ustore.nameColValuesError == AhoNameColValuesError.Duplicates) {
            errMsg = store_.trans['The selected column contains duplicate values!'];
            listTitle = store_.trans['Examples of repeating values'];
        }
        if (ustore.nameColValuesError == AhoNameColValuesError.EmptyValue) {
            errMsg = store_.trans['The selected column contains empty values!'];
            listTitle = store_.trans['Line numbers with empty values'];
        }
        if (ustore.nameColValuesError == AhoNameColValuesError.NoExistsName) {
            errMsg = store_.trans['The selected column contains references to non-existent cells!'];
            listTitle = store_.trans['There are no cells with such names in the ASA map'];
        }

        return <div className={classNames("AgroAhoTableUpload3Comp-main",{
            "AgroAhoTableUpload3Comp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoTableUpload3Comp-cover">
                <div className="AgroAhoTableUpload3Comp-topPanel">
                    <div className="AgroAhoTableUpload3Comp-titleCover">
                        <div className="AgroAhoTableUpload3Comp-title">{store_.trans['Step 3. Selecting the column with sample names']}</div>
                        <AgroAhoStepsComp step={ustore.ahoUploadTableStage}/>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-subTitleCover">
                        {/*<div className="AgroAhoTableUpload3Comp-titleStatus">{store_.trans['The file was successfully processed and matches the sample']}</div>*/}
                        <div className="AgroAhoTableUpload3Comp-titleFileName">{store_.trans.File}: {tstore.curTour?.tour_file_name}</div>
                        <div className="AgroAhoTableUpload3Comp-titleTourName">{store_.trans.Tour}: {tstore.curTour?.tour_name}</div>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-line"/>
                    {/*<div className="AgroAhoTableUpload3Comp-msg">{store_.trans['Below you need to select a column from the loaded table that corresponds to the cell numbers']}</div>*/}
                    <div className="AgroAhoTableUpload3Comp-msg">{store_.trans['Below you need to select a column from the loaded table that corresponds to the sample names (=cell names in the ASA map). The values ​​in this column can be numeric and/or text. These values ​​are used to compare the data loaded from the table with the cells (=sections) into which the field is divided.']}</div>

                    <div className={classNames("AgroAhoTableUpload3Comp-table", {
                        "AgroAhoTableUpload3Comp-idValuesError": ustore.nameColValuesError != AhoNameColValuesError.NoError
                    })}>
                        <div className="AgroAhoTableUpload3Comp-leftCol">
                            <div className="AgroAhoTableUpload3Comp-selTourCaption">{store_.trans['Column title']}</div>
                            <div className="AgroAhoTableUpload3Comp-colCover">
                                <DropDownComp items={items} onChange={this.onColumnChange} currentKey={ustore.curIdsColumn.num}/>
                            </div>
                            <div className="AgroAhoTableUpload3Comp-errMsg">{errMsg}</div>
                        </div>
                        <div className="AgroAhoTableUpload3Comp-centerCol">
                            <AgroAhoArrowRightSvg/>
                        </div>
                        <div className="AgroAhoTableUpload3Comp-rightCol">
                            <div className="AgroAhoTableUpload3Comp-rightColTitle">{listTitle}:</div>
                            <div className="AgroAhoTableUpload3Comp-rightColList style-4">{list}</div>
                        </div>
                    </div>
                </div>
                <div className={classNames("AgroAhoTableUpload3Comp-bottomPanel", {
                    "AgroAhoTableUpload3Comp-idValuesError": ustore.nameColValuesError !== AhoNameColValuesError.NoError
                })}>
                    {/*<div className="AgroAhoTableUpload3Comp-linkSample" onClick={this.onDownloadSampleClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Sample}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload3Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Instruction}</span>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload3Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoTableUpload3Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <div className="AgroAhoTableUpload3Comp-backButton" onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-nextButton" onClick={this.onClickNext}>
                        <span>{store_.trans.Next}</span>
                        <AgroAhoBackSvg isNext={true}/>
                    </div>
                </div>
            </div>
        </div>
    }
}
