// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoMergeIsoXmlFarmComp-titleCover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #6A7886;
  padding: 6px 16px 4px 16px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/cards/AgroAhoMergeIsoXmlFarmComp.scss"],"names":[],"mappings":"AASA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,0BAAA;AARF","sourcesContent":[".AgroAhoMergeIsoXmlFarmComp-main {\r\n\r\n}\r\n.AgroAhoMergeIsoXmlFarmComp-name {\r\n\r\n}\r\n.AgroAhoMergeIsoXmlFarmComp-fields {\r\n\r\n}\r\n.AgroAhoMergeIsoXmlFarmComp-titleCover {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  font-family: Open Sans, sans-serif;\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  color: #6A7886;\r\n  padding: 6px 16px 4px 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
