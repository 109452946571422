import {
    FeatureCollection,
    Geometry,
    GeometryCollection,
    LineString,
    MultiLineString,
    MultiPoint,
    MultiPolygon,
    Polygon,
    Position
} from "geojson";
import {LngLat} from "maplibre-gl";
import {cloneDeep} from "lodash-es";
import midpoint from "@turf/midpoint";
import {Utils} from "./Utils";
import {GeoAddrUtils} from "./GeoAddrUtils";
import {MBUtils} from "./MBUtils";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";


/**
 * адрес контура [номер котура, номер дырки (0 для внешнего, для дырок holes[value - 1])]
 */
export type AddrContour = [number, number];
/**
 * Адрес точки
 * номер простой геометрии, номер контура, номер точки
 */
export type AddrPoint = [number, number, number];

function sqr(p: number): number{
    return p * p;
}
export enum GeometryType {
    "Point"="Point",
    "MultiPoint"="MultiPoint",
    "LineString" = "LineString",
    "MultiLineString" ="MultiLineString",
    "Polygon" = "Polygon",
    "MultiPolygon" = "MultiPolygon",
    "GeometryCollection" = "GeometryCollection"
}
export enum SimpleGeometryType {
    "Point"="Point",
    "Line"="Line",
    "Polygon" = "Polygon"
}

export type ContourPoints = Position[];

export interface IWidthHeight{
    width: number;
    height: number;
}

/**
 * Простое представление отдельных геометрий. Для мультиточек может представлять как отдельные точки так и массив точек.
 * Для полигонов не содержит последнюю замыкающую точку
 */
export interface ISimpleGeometry {
    contour: ContourPoints,
    simple: SimpleGeometryType,
    holes: ContourPoints[]
}

export class GeometryUtils{
    static createContourAddr(numContour: number): AddrContour{
        /**
         * адрес контура [номер котура, номер дырки(-1 для внешнего)]
         */
        return

    }
    static createEmptyCollection(): FeatureCollection{
        return <FeatureCollection>{'type': 'FeatureCollection', 'features': []};
    }
    static getBbox(min_lon: number, max_lon: number, min_lat: number, max_lat: number): BBox2d{
        return [ min_lon,min_lat,  max_lon,max_lat];
    }
    static unionBbox(bbox1: BBox2d, bbox2: BBox2d): BBox2d{
        let b: BBox2d = [
            Math.min(bbox1[0], bbox2[0]),
            Math.min(bbox1[1], bbox2[1]),
            Math.max(bbox1[2], bbox2[2]),
            Math.max(bbox1[3], bbox2[3]),
        ];
        return b;
    }
    static isEqualAddrPoint(addr1: AddrPoint, addr2: AddrPoint): boolean{
        if (addr1 == addr2 == null) return true;
        if (addr1 == null || addr2 == null) return false;
        return (addr1[0] == addr2[0]) && (addr1[1] == addr2[1]) && (addr1[2] == addr2[2]);
    }
    static isEqualAddrContour(addr1: AddrContour, addr2: AddrContour): boolean{
        if (addr1 == addr2 == null) return true;
        if (addr1 == null || addr2 == null) return false;
        return (addr1[0] == addr2[0]) && (addr1[1] == addr2[1]);
    }
    //По идее надо анализировать типы
    static isGeometryEmpty(g: Geometry): boolean{
        if (g == null || g.type == null) return true;
        return false;
    }

    // можно ли опустить перпендикуляр на отрезок
    static perpOtrezok(a: Position, b: Position, p: Position): boolean {
        let b1 = (sqr(GeometryUtils.distance(a, p)) <= (sqr(GeometryUtils.distance(a, b)) + sqr(GeometryUtils.distance(b, p))));
        let b2 = (sqr(GeometryUtils.distance(b, p)) <= (sqr(GeometryUtils.distance(a, b)) + sqr(GeometryUtils.distance(a, p))));
        return (b1 && b2);
    }

    // Вычисляет перпиндикуляр из точки P к линии AB.
    // null - если перпендикуляр опустить нельзя
    static perpToLine(a: Position, b: Position, p: Position): Position {
        let rez: Position = [0, 0];
        let aa, bb, cc;
        if (((a[0] - b[0]) == 0) && ((a[1] - b[1]) == 0)) {
            return null;
        } else if ((a[0] - b[0]) == 0) {
            if (((p[1] >= a[1]) && (p[1] <= b[1])) || ((p[1] >= b[1]) && (p[1] <= a[1]))) {
                rez[0] = a[0];
                rez[1] = p[1];
                return rez;
            }
            return null;
        } else if ((a[1] - b[1]) == 0) {
            if (((p[0] >= a[0]) && (p[0] <= b[0])) || ((p[0] >= b[0]) && (p[0] <= a[0]))) {
                rez[0] = p[0];
                rez[1] = a[1];
                return rez;
            }
            return null;
        }
//y=ax+b
        aa = (a[1] - b[1]) / (a[0] - b[0]);
        bb = a[1] - (aa * a[0]);
//перпендик
//y=-(1/a)x+c
        cc = p[1] + (p[0] / aa);

        rez[0] = ((bb - cc) / (-(1 / aa) - aa));
        rez[1] = ((aa * rez[0]) + bb);
        return rez;
    }


    static distance(p1: Position, p2: Position): number{
        return Math.sqrt((p2[0] - p1[0]) * (p2[0] - p1[0]) + (p2[1] - p1[1]) * (p2[1] - p1[1]));
    }

    static midPoint(p1: Position, p2: Position): Position{
        return [(p1[0] + p2[0]) / 2.0, (p1[1] + p2[1])/ 2.0];
    }

    static lineOverEarth(g: Geometry): Geometry{
        let r: LineString = {type: "LineString", coordinates: []};
        if (g.type == "LineString") {
            let line: LineString = g as LineString;
            for (let i = 0; i < line.coordinates.length; i++) {
                let p1 = line.coordinates[i];
                if (i > 0) {
                    let p0 = line.coordinates[i - 1];
                    let mid = GeometryUtils.midPoint(p0, p1);
                    r.coordinates.push(mid);
                }
                r.coordinates.push(cloneDeep(p1));
            }
        }
        return r;
    }

    static lineOverEarth2(g: Geometry): Geometry{
        function divLine(arr: Position[]){
            if (arr.length < 2) return;
            let index = 0;
            while( index < arr.length - 1){
                while (GeometryUtils.distance(arr[index], arr[index + 1]) > 1){
                    let ok = div(arr, index);
                    if (!ok) break;
                }
                index++;
            }
        }
        function div(arr: Position[], idx1: number): boolean{
            let d = GeometryUtils.distance(arr[idx1], arr[idx1 + 1]);
            if (d > 359) return false;
            let gp = midpoint(arr[idx1], arr[idx1 + 1]);
            Utils.arrayInsert(arr, idx1 + 1, gp.geometry.coordinates);
            return true;
        }
        let r: LineString = cloneDeep(g) as LineString;
        if (r.type == "LineString") {
            let line: LineString = r as LineString;
            divLine(line.coordinates);
        }
        return r;
    }

    static makeGeometryAddr(g: Geometry, index: number[]){
        if (g.type == "LineString"){
            while (g.coordinates.length <= (index[0])){
                g.coordinates.push([0,0]);
            }
        }
        if (g.type == "Polygon" || g.type == "MultiLineString"){
            while (g.coordinates.length <= (index[0])){
                g.coordinates.push([]);
            }
            while (g.coordinates[index[0]].length <= (index[1])){
                g.coordinates[index[0]].push([0,0]);
            }
        }
        if (g.type == "MultiPolygon"){
            while (g.coordinates.length <= (index[0])){
                g.coordinates.push([]);
            }
            while (g.coordinates[index[0]].length <= (index[1])){
                g.coordinates[index[0]].push([]);
            }
            while (g.coordinates[index[0]][index[1]].length <= (index[2])){
                g.coordinates[index[0]][index[1]].push([0,0]);
            }
        }
    }
    static setGeometryPoint(g: Geometry, index: number[], value: Position){
        GeometryUtils.makeGeometryAddr(g, index);
        if (g.type == "LineString"){
            g.coordinates[index[0]] = value;
        }
        if (g.type == "Polygon" || g.type == "MultiLineString"){
            g.coordinates[index[0]][index[1]] = value;
        }
        if (g.type == "MultiPolygon"){
            g.coordinates[index[0]][index[1]][index[2]] = value;
        }
    }
    static getGeometryPoint(g: Geometry, index: number[]): Position{
        GeometryUtils.makeGeometryAddr(g, index);
        if (g.type == "LineString"){
            return cloneDeep(g.coordinates[index[0]]);
        }
        if (g.type == "Polygon" || g.type == "MultiLineString"){
            return cloneDeep(g.coordinates[index[0]][index[1]]);
        }
        if (g.type == "MultiPolygon"){
            return cloneDeep(g.coordinates[index[0]][index[1]][index[2]]);
        }
    }
    static insertGeometryPoint2(sGeoms: ISimpleGeometry[], addr: AddrPoint, coord: Position){
        let c = GeometryUtils.getContourPoints(sGeoms, GeometryUtils.getAddrContourByAddrPoint(addr));
        if (c != null){
            Utils.arrayInsert(c, addr[2], coord);
        }
    }

    static deleteContourByAddr(contours: ISimpleGeometry[], addr: AddrContour){
        if (addr.length > 0) {
            let a1 = addr[0];
            if (a1 >= 0 && a1 < contours.length) {
                let a2 = addr[1];
                if (a2 > 0) {//это дырка
                    a2--;
                    if (a2 < contours[a1].holes.length) {
                        Utils.arrayRemoveByIndex(contours[a1].holes, a2);
                    }
                }else{
                    Utils.arrayRemoveByIndex(contours, a1);
                }
            }
        }
    }

    static removeGeometryPoint2(sGeoms: ISimpleGeometry[], addr: AddrPoint, removeEmptyGeom: boolean = true){
        let c = GeometryUtils.getContourPoints(sGeoms, GeometryUtils.getAddrContourByAddrPoint(addr));

        if (c != null && addr[2] >= 0 && addr[2] < c.length){
            Utils.arrayRemoveByIndex(c, addr[2]);
        }
        if (removeEmptyGeom && c != null && c.length == 0){
            GeometryUtils.deleteContourByAddr(sGeoms, GeometryUtils.getAddrContourByAddrPoint(addr));
        }
    }

    public static getContourPoints(sGeoms: ISimpleGeometry[], addr: AddrContour): ContourPoints{
        if (addr[0] >= 0 && addr[0] < sGeoms.length){
            let gi = sGeoms[addr[0]];
            let c: ContourPoints = null;
            if (addr[1] ==0){
                c = gi.contour;
            }else{
                let num_hole = addr[1] - 1;
                if (num_hole >= 0 && num_hole < gi.holes.length){
                    c = gi.holes[num_hole];
                }
            }
            return c;
        }
        return null;
    }

    static incExistPointAddr(sGeoms: ISimpleGeometry[], addr: AddrPoint): AddrPoint{
        let c = GeometryUtils.getContourPoints(sGeoms, GeometryUtils.getAddrContourByAddrPoint(addr));
        if (c == null) return null;
        let sg = sGeoms[addr[0]];
        if (sg.simple == SimpleGeometryType.Point) return null;
        let next = addr[2] + 1;
        if (sg.simple == SimpleGeometryType.Line){
            if (next >= c.length) return null;
        }
        if (sg.simple == SimpleGeometryType.Polygon){
            if (next >= c.length) {
                next = 0;
            }
            if (c.length == 0) return null;
        }
        return [addr[0], addr[1], next];
    }
    static decExistPointAddr(sGeoms: ISimpleGeometry[], addr: AddrPoint): AddrPoint{
        let c = GeometryUtils.getContourPoints(sGeoms, GeometryUtils.getAddrContourByAddrPoint(addr));
        if (c == null) return null;
        let sg = sGeoms[addr[0]];
        if (sg.simple == SimpleGeometryType.Point) return null;
        let next = addr[2] - 1;
        if (sg.simple == SimpleGeometryType.Line){
            if (next < 0) return null;
        }
        if (sg.simple == SimpleGeometryType.Polygon){
            if (next < 0) {
                next = c.length - 1;
            }
            if (c.length == 0) return null;
        }
        return [addr[0], addr[1], next];
    }

    static getSimpleGeometryByAddr(sGeoms: ISimpleGeometry[], addr: AddrContour): ISimpleGeometry{
        if (addr[0] >= 0 && addr[0] < sGeoms.length) {
            return sGeoms[addr[0]];
        }
        return null;
    }
    static getGeometryPoint2(sGeoms: ISimpleGeometry[], addr: AddrPoint): Position{
        let c = GeometryUtils.getContourPoints(sGeoms, GeometryUtils.getAddrContourByAddrPoint(addr));
        if (c != null && addr[2] >= 0 && addr[2] < c.length){
            return c[addr[2]];
        }
        return null;
    }

    static setGeometryPoint2(sGeoms: ISimpleGeometry[], addr: AddrPoint, value: Position){
        let c = GeometryUtils.getContourPoints(sGeoms, GeometryUtils.getAddrContourByAddrPoint(addr));
        if (c != null && addr[2] >= 0 && addr[2] < c.length){
            c[addr[2]] = value;
        }
    }

    static removeGeometryPoint(g: Geometry, index: number[]){
        GeometryUtils.makeGeometryAddr(g, index);
        if (g.type == "LineString"){
            Utils.arrayRemoveByIndex(g.coordinates, index[0]);
        }
        if (g.type == "MultiLineString"){
            Utils.arrayRemoveByIndex(g.coordinates[index[0]], index[1]);
        }
        if (g.type == "Polygon"){
            if (GeoAddrUtils.isLastPoint(g, index)){
                Utils.arrayRemoveByIndex(g.coordinates[index[0]], index[1]);
                Utils.arrayRemoveByIndex(g.coordinates[index[0]], 0);
                if (g.coordinates[index[0]].length > 0){
                    g.coordinates[index[0]].push(cloneDeep(g.coordinates[index[0]][0]));
                }
            }else
            if (GeoAddrUtils.isFirstPoint(g, index)){
                Utils.arrayRemoveByIndex(g.coordinates[index[0]], index[1]);
                Utils.arrayRemoveByIndex(g.coordinates[index[0]], g.coordinates[index[0]].length - 1);
                if (g.coordinates[index[0]].length > 0){
                    g.coordinates[index[0]].push(cloneDeep(g.coordinates[index[0]][0]));
                }
            }else{
                Utils.arrayRemoveByIndex(g.coordinates[index[0]], index[1]);
            }
        }
        if (g.type == "MultiPolygon"){
            if (GeoAddrUtils.isLastPoint(g, index)){
                Utils.arrayRemoveByIndex(g.coordinates[index[0]][index[1]], index[2]);
                Utils.arrayRemoveByIndex(g.coordinates[index[0]][index[1]], 0);
                if (g.coordinates[index[0]][index[1]].length > 0){
                    g.coordinates[index[0]][index[1]].push(cloneDeep(g.coordinates[index[0]][index[1]][0]));
                }
            }else
            if (GeoAddrUtils.isFirstPoint(g, index)){
                Utils.arrayRemoveByIndex(g.coordinates[index[0]][index[1]], index[2]);
                Utils.arrayRemoveByIndex(g.coordinates[index[0]], g.coordinates[index[0]].length - 1);
                if (g.coordinates[index[0]][index[1]].length > 0){
                    g.coordinates[index[0]][index[1]].push(cloneDeep(g.coordinates[index[0]][index[1]][0]));
                }
            }else{
                Utils.arrayRemoveByIndex(g.coordinates[index[0]][index[1]], index[2]);
            }
        }
    }


    static closeRing(ring: ContourPoints): ContourPoints{
        if (!MBUtils.isEqualPoint(ring[0], ring[ring.length - 1])){
            return [...ring, ring[0]];
        }
        return [...ring];
    }

    static getLastPointAddr(gia: ISimpleGeometry[], addrContour: AddrContour): AddrPoint{
        let arr = GeometryUtils.getContourPoints(gia, addrContour);
        if (arr == null || arr.length == 0){
            return null;
        }
        let addrPoint = GeometryUtils.getAddrPointByAddrContour(addrContour);
        addrPoint[2] = arr.length - 1;
        return addrPoint;
    }

    static getFirstPointAddr(gia: ISimpleGeometry[], addrContour: AddrContour): AddrPoint{
        let arr = GeometryUtils.getContourPoints(gia, addrContour);
        if (arr == null && arr.length == 0){
            return null;
        }
        let addrPoint = GeometryUtils.getAddrPointByAddrContour(addrContour);
        addrPoint[2] = 0;
        return addrPoint;
    }

    static getSimpleTypes(sGeoms: ISimpleGeometry[]): SimpleGeometryType[]{
        let typesSet: Set<SimpleGeometryType> = new Set<SimpleGeometryType>(sGeoms.map(a => a.simple));
        return  Array.from(typesSet);
    }

    static createGeometryBySimpleGeometry(sGeoms: ISimpleGeometry[]): Geometry{
        function cloneWithDub(arr: ContourPoints): ContourPoints{
            let r = [...arr];
            if (r.length > 0){
                r.push(r[0]);
            }
            return r;
        }
        if(sGeoms.length == 0){
            return null;
        }else
        if(sGeoms.length == 1){
            let c = sGeoms[0];
            if (c.simple == SimpleGeometryType.Point){
                if (c.contour.length > 0){
                    return {type: "MultiPoint", coordinates: cloneDeep(c.contour)};
                }else{
                    return {type: "Point", coordinates: cloneDeep(c.contour[0])};
                }
            }
            if (c.simple == SimpleGeometryType.Line){
                return {type: "LineString", coordinates: cloneDeep(c.contour)};
            }
            if (c.simple == SimpleGeometryType.Polygon){
                let g: Polygon = {type: "Polygon", coordinates: [cloneWithDub(c.contour)]};
                if (c.holes != null && c.holes.length > 0){
                    c.holes.forEach(a => {
                        g.coordinates.push(cloneWithDub(a));
                    });
                }
                return g;
            }
        }else{
            let typesSet: Set<SimpleGeometryType> = new Set<SimpleGeometryType>(sGeoms.map(a => a.simple));
            let types = Array.from(typesSet);
            if (types.length == 0) return null;
            if (types.length == 1){
                let t = types[0];
                if (t == SimpleGeometryType.Point){
                    let g: MultiPoint = {type: "MultiPoint", coordinates: []};
                    sGeoms.forEach(c =>{
                        g.coordinates = g.coordinates.concat(cloneDeep(c.contour));
                    });
                    return g;
                }
                if (t == SimpleGeometryType.Line){
                    let g: MultiLineString = {type: "MultiLineString", coordinates: []};
                    sGeoms.forEach(c =>{
                        g.coordinates.push(cloneDeep(c.contour));
                    });
                    return g;
                }
                if (t == SimpleGeometryType.Polygon){
                    let g: MultiPolygon = {type: "MultiPolygon", coordinates: []};
                    sGeoms.forEach(c =>{
                        let c2: ContourPoints[] = [];
                        c2.push(cloneWithDub(cloneDeep(c.contour)));
                        if (c.holes != null && c.holes.length > 0){
                            c.holes.forEach(a => {
                                c2.push(cloneWithDub(a));
                            });
                        }
                        g.coordinates.push(c2);
                    });
                    return g;
                }
            }else{
                let g: GeometryCollection = {type: "GeometryCollection", geometries: []};
                types.forEach(type =>{
                    let arr = sGeoms.filter(a => a.simple == type);
                    g.geometries.push(GeometryUtils.createGeometryBySimpleGeometry(arr));
                });
                return g;
            }
        }

        return null;
    }

    static getAddrPointByAddrContour(addrContour: AddrContour): AddrPoint{
        return [addrContour[0], addrContour[1], 0];
    }
    static getAddrContourByAddrPoint(addrPoint: AddrPoint): AddrContour{
        return [addrPoint[0], addrPoint[1]];
    }
    /**
     * Возвращает адрес контура в который попали мышкой, возможно дырка. Если не нашёл, то null.
     * @param p
     * @param g
     */
    static getAddrContourByPoint(p: LngLat, sGeoms: ISimpleGeometry[]): AddrContour{
        let pos = MBUtils.llToPosition(p);

        for(let index1 = 0; index1 < sGeoms.length; index1++){
            let c = sGeoms[index1];
            if (c.simple == SimpleGeometryType.Polygon){
                let cg = GeometryUtils.createGeometryBySimpleGeometry([{simple: SimpleGeometryType.Polygon, holes: [], contour: c.contour}]);
                if (booleanPointInPolygon(pos, cg as any)){
                    if (c.holes != null && c.holes.length > 0){
                        for(let index2 = 0; index2 < c.holes.length; index2++){
                            let c2 = c.holes[index2];
                            if (c2.length > 0) {
                                let cg2 = GeometryUtils.createGeometryBySimpleGeometry([{
                                    simple: SimpleGeometryType.Polygon,
                                    contour: c2,
                                    holes: []
                                }]);
                                if (booleanPointInPolygon(pos, cg2 as any)) {
                                    return [index1, index2 + 1];
                                }
                            }
                        }
                    }
                    return [index1, 0];
                }
            }
        }
        return null;
    }

    static getNearLineAddrContourByPoint(p: LngLat, sGeoms: ISimpleGeometry[]): {addr: AddrContour, distance: number}{
        let pos = MBUtils.llToPosition(p);
        let minD: number = null;
        let addr: AddrContour = null;

        for(let index1 = 0; index1 < sGeoms.length; index1++){
            let c = sGeoms[index1];
            if (c.simple == SimpleGeometryType.Line){
                for(let i = 1; i < c.contour.length; i++){
                    let cP = GeometryUtils.perpToLine(c.contour[i - 1], c.contour[i], pos);
                    let d: number = null;
                    if (cP != null){
                        d = GeometryUtils.distance(cP, pos);
                    }
                    if (minD == null || d < minD) {
                        minD = d;
                        addr = [index1, 0];
                    }
                }
            }
        }
        return {addr: addr, distance: minD};
    }
    static getSimpleGeometries(g: Geometry): ISimpleGeometry[]{
        let res: ISimpleGeometry[] = [];
        if (g == null) return [];
        function addPolygon(cc: Position[][]){
            let outer: ISimpleGeometry = null;
            cc.forEach((c, index) =>{
                if (index == 0){
                    outer = {holes: [], contour: c.slice(0, -1), simple: SimpleGeometryType.Polygon};
                    res.push(outer);
                }else{
                    outer.holes.push(c.slice(0, -1));
                }
            });
        }
        function addSimple(cc: Position[][], typeContour: SimpleGeometryType){
            cc.forEach((c, index) =>{
                let cont: ISimpleGeometry = {holes: [], simple: typeContour, contour: [...c]};
                res.push(cont);
            });
        }
        if (g.type == "LineString"){
            res.push({holes: [], simple: SimpleGeometryType.Line, contour: [...g.coordinates]});
        }
        if (g.type == "MultiLineString"){
            addSimple(g.coordinates, SimpleGeometryType.Line);
        }
        if (g.type == "Point"){
            res.push({holes: [], simple: SimpleGeometryType.Point, contour: [[...g.coordinates]]});
        }
        if (g.type == "MultiPoint"){
            res.push({holes: [], simple: SimpleGeometryType.Point, contour: [...g.coordinates]});
        }
        if (g.type == "Polygon"){
            addPolygon(g.coordinates);
        }
        if (g.type == "MultiPolygon"){
            g.coordinates.forEach(a =>{
                addPolygon(a);
            });
        }
        if (g.type == "GeometryCollection"){
            g.geometries.forEach(gg =>{
                let a = GeometryUtils.getSimpleGeometries(gg);
                res = res.concat(a);
            });
        }
        return res;
    }

}