import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './PhotoPanelTopFiltersComp.scss';
import {isFunction} from "lodash-es";
import classNames from "classnames";

export enum PhotoFilterType{
    tag = 'tag',
    data = 'data',
    farm = 'farm',
    region = 'region',
    year = 'year'
}
export interface IPhotoFilterInfo{
    filterType: PhotoFilterType,
    id?: number,
}
export interface IPhotoPanelTopFilterItemComp extends IStoreProps{
    text: string,
    hasDelete?: boolean,
    data?: IPhotoFilterInfo,
    highlight?: boolean,
    onClickDelete?: (data: IPhotoFilterInfo)=> void,
    onClick?: (data: any)=>void,
}

@observer
export class PhotoPanelTopFilterItemComp extends React.Component<IPhotoPanelTopFilterItemComp, undefined> {
    constructor(props: IPhotoPanelTopFilterItemComp) {
        super(props);
        autoBindReact(this);
        this.ref = React.createRef();
    }
    ref: any;

    onClickDelete(){
        if (isFunction(this.props.onClickDelete)) this.props.onClickDelete(this.props.data);
    }
    onClick(e: any){
        if (this.ref.current.contains(e.currentTarget)) return;
        if (isFunction(this.props.onClick)) this.props.onClick();
    }

    render() {
        let store = this.props.store;
        return <div className={classNames("PhotoPanelTopFilterItemComp", this.props.className, {"highlight": this.props.highlight})}>
            <div className="flex-distribute flex-columns-centerByVertical" onClick={this.onClick}>
                {this.props.children}
                <span className="">{this.props.text}</span>
            </div>
            <div className="PhotoPanelTopFilterItemComp-delete" onClick={this.onClick}>
                <svg width="13" height="13" className="PhotoPanelTopFilterItemComp-deleteSvg" onClick={this.onClickDelete} ref={this.ref}
                     viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 0C2.925 0 0 2.925 0 6.5C0 10.075 2.925 13 6.5 13C10.075 13 13 10.075 13 6.5C13 2.925 10.075 0 6.5 0ZM9.34375 8.20625L8.20625 9.34375L6.5 7.6375L4.79375 9.34375L3.65625 8.20625L5.3625 6.5L3.65625 4.79375L4.79375 3.65625L6.5 5.3625L8.20625 3.65625L9.34375 4.79375L7.6375 6.5L9.34375 8.20625Z"/>
                </svg>
            </div>
        </div>;
    }
}
