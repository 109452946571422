import * as React from 'react';
import {CSSProperties} from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action} from "mobx";
import "./RulerInfo.scss";
import {CloseSvg} from "../../icons/MiniIcon/CloseSvg";
import autoBindReact from "auto-bind/react";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {MBUtils} from "../../../helper/utils/MBUtils";

@observer
export class RulerInfoComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onDeleteAll(){
        this.props.store.map.rulerInfo.rulerManager.clickDeleteAll();
    }

    render() {
        let store = this.props.store;
        let ms = store.map;
        let ri = ms.rulerInfo;
        let p = ms.rulerInfo.scrPoint;
        if (p == null) return null;
        let deltaX = 0;
        let deltaY = -38;
        let w = document.getElementById("map").clientWidth;
        let h = document.getElementById("map").clientHeight;

        let st: CSSProperties = {left: (p[0] + deltaX)+"px", top: (p[1] + deltaY)+"px"};
        let close: boolean = store.map.measuringStatus != MeasuringStatus.Ruler2;
        if (!ri.isPolygon && ri.perimeter == null) return null;
        if (ri.isPolygon && ri.area == null) return null;

        return <div style={st} className="rulerInfo-outside">
            <div className="rulerInfo">
                <div className="flex-align-items-center">
                    {!ri.isPolygon && <span>{ri.perimeter.toFixed(1)}{ri.perimeterPostfix}</span>}
                    {ri.isPolygon && <span>{ri.area.toFixed(1)}{ri.areaPostfix}</span>}
                    {close && <span className="text-next rulerInfo-close" onClick={this.onDeleteAll}><CloseSvg /></span>}
                </div>
            </div>
        </div>;
    }
}
