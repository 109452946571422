import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './AdminPanel_FilterBadgetComp.scss';
import {UserListFilterItems} from "../../../../store/user/UserListStore";
import {action} from "mobx";

interface IAdminPanel_FilterBadgetComp extends IStoreProps{
    //filterItem: UserListFilterItems;
    id?: any,
    text?: any,
    beforeText?: any,
    afterText?: any,
    onClose?: (filterItem: UserListFilterItems)=>any;
}

@observer
export class AdminPanel_FilterBadgetComp extends React.Component<IAdminPanel_FilterBadgetComp, undefined> {
    constructor(props: IAdminPanel_FilterBadgetComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className="AdminPanel_FilterBadgetComp">
            {this.props.beforeText}
            <span className="def-cursor">{this.props.text}</span>
            {this.props.afterText}
            <div className="AdminPanel_FilterBadgetComp_close"
                 onClick={()=>{
                    if (this.props.onClose) this.props.onClose(this.props.id);
                 }}>
                <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.43125 0.56875C6.25625 0.39375 5.99375 0.39375 5.81875 0.56875L3.5 2.8875L1.18125 0.56875C1.00625 0.39375 0.74375 0.39375 0.56875 0.56875C0.39375 0.74375 0.39375 1.00625 0.56875 1.18125L2.8875 3.5L0.56875 5.81875C0.39375 5.99375 0.39375 6.25625 0.56875 6.43125C0.65625 6.51875 0.74375 6.5625 0.875 6.5625C1.00625 6.5625 1.09375 6.51875 1.18125 6.43125L3.5 4.1125L5.81875 6.43125C5.90625 6.51875 6.0375 6.5625 6.125 6.5625C6.2125 6.5625 6.34375 6.51875 6.43125 6.43125C6.60625 6.25625 6.60625 5.99375 6.43125 5.81875L4.1125 3.5L6.43125 1.18125C6.60625 1.00625 6.60625 0.74375 6.43125 0.56875Z" fill="#C5C5C5"/>
                </svg>
            </div>
        </div>;
    }
}
