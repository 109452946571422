import {isArray} from "lodash-es";
import mapboxgl, {ImageSourceRaw, ImageSource, VectorSource, RasterSource, Layer, AnySourceData, GeoJSONSourceRaw} from "maplibre-gl";
import {autorun, IReactionDisposer} from "mobx";
import {CustomStoreTool} from "../general/CustomStoreTool";
import {MBUtils} from "../../../helper/utils/MBUtils";
import {IOverlayConfig} from "../../config/ConfigStore";
import {ConstantsStore} from "../../config/ConstantsStore";
import { OverlayObjectStore } from "../../OverlayObjectStore";
import { EStyleType, LayersListStore } from "../../../components/panels/Right/LayersList/LayersListStore";
import { Utils } from "../../../helper/utils/Utils";

interface IDynamicRasterSource {
    urlTemplate: string;
    lastUrl: string;
    overlayId: string;
}

export class OverlayTool extends CustomStoreTool {
    
    static readonly OVERLAY_SOURCE_PREFIX : string = ConstantsStore.COMMON_PREFIX+'overlay_source_';
    static readonly OVERLAY_LAYER_PREFIX : string = ConstantsStore.COMMON_PREFIX+'overlay_layer_';
    private dynamicRasterSources : Map<string, IDynamicRasterSource> = new Map<string, IDynamicRasterSource>();

    onInstall() {
    }

    onUninstall() {
    }

    getImageParams(urlTemplate: string, mapbox: mapboxgl.Map) {
        let c = mapbox.getCanvas();
        let bbox = mapbox.getBounds();
        let z = mapbox.getZoom() + 0.5;
        let w = bbox.getWest();
        let e = bbox.getEast();
        let n = bbox.getNorth();
        let s = bbox.getSouth();
        return {
            'url': urlTemplate.replace('{w}', w.toString())
                .replace('{e}', e.toString()).replace('{n}', n.toString())
                .replace('{s}', s.toString()).replace('{z}', z.toString())
                .replace('{W}', c.width.toString()).replace('{H}', c.height.toString()),
            'coordinates': [
                [w, n],
                [e, n],
                [e, s],
                [w, s]
              ]
        }
    }

    moveTimerHandle: any = null;

    onMoveEndDeferred() {
        let map = this.store.map.mapbox;
        this.dynamicRasterSources.forEach((drs, id) => {            
            if (! this.store.map.overlays.getVisible(drs.overlayId))
                return;
            let ip = this.getImageParams(drs.urlTemplate, map);
            (map.getSource(id) as ImageSource).updateImage(ip);
            drs.lastUrl = ip.url;
        });        
    }

    onMoveEnd() {
        if (this.moveTimerHandle)
            clearTimeout(this.moveTimerHandle);
        this.moveTimerHandle = setTimeout(() => this.onMoveEndDeferred(), 300);
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.setupLayers();
            }),
            autorun(() => {
                this.changeOverlayes();
            }),
            ...super.onSubscription()
        ];
    }


    changeOverlayes(){
        let map = this.store.map.mapbox;
        let overlays = this.store.map.overlays;
        overlays.overlayesConfig.forEach((ov, i) => {
            let isVisible = overlays.getVisible(ov.id);
            let id = OverlayTool.OVERLAY_SOURCE_PREFIX + i;
            let drs = this.dynamicRasterSources.get(id);
            if (isVisible && drs) {
                let ip = this.getImageParams(drs.urlTemplate, map);
                if (ip.url != drs.lastUrl)
                    (map.getSource(id) as ImageSource).setCoordinates([[0,0.1],[0.1,0.1],[0.1,0],[0,0]]).updateImage(ip);
            }
            ov.layers.forEach((b, idx) => {
                let layer = OverlayObjectStore.getOverlayLayerName(ov.id, idx);
                let vis = isVisible && (b.metadata?.["class:overlay:border:visibility"] ?? true);
                MBUtils.setLayerVisibility(this.map, layer, vis);
                let trans = b.metadata?.["class:overlay:translate:text-field"];
                if (! trans) return;
                this.map.setLayoutProperty(layer, 'text-field', trans[this.store.lang] || trans["default"])
            });
        });
    }
    setupLayers() {
        this.unloadOverlays();
        this.loadOverlays();
    }

    addSource(i: number) {
        let ov = this.store.map.overlays.overlayesConfig[i];
        let map = this.store.map.mapbox;
        let id = OverlayTool.OVERLAY_SOURCE_PREFIX + i;
        if (map.getSource(id) == null) {           
            let src: AnySourceData = ov.source;
            if (ov.source.type == "image") {
                let imgSrc = ov.source as ImageSourceRaw;
                if (imgSrc.url.match("\{[e,n,s,w,z,W,H]\}")) {
                    let ip = this.getImageParams(imgSrc.url, map);
                    this.dynamicRasterSources.set(id, 
                        {urlTemplate: imgSrc.url, overlayId: ov.id, lastUrl: ip.url});
                    src = {...src, ...ip};
                }
            }
            this.store.map.overlays.prepareTemporalSource(ov);
            map.addSource(id, Utils.prepareAbsoluteUrls(src));
        }
    }    

     unloadOverlays() {
        let map = this.store.map.mapbox;
        let ovs = this.store.map.overlays.overlayesConfig;
     //   console.log("====",ovs)
        for (let i = 0; i < ovs.length; i++) {
            for (let j = 0; j < ovs[i].layers.length; j++) {
                let layerId = OverlayObjectStore.getOverlayLayerName(ovs[i].id, j);
                if (map.getLayer(layerId))
                    this.removeLayer(layerId);
            }
            if (map.getSource(OverlayTool.OVERLAY_SOURCE_PREFIX + i))
                map.removeSource(OverlayTool.OVERLAY_SOURCE_PREFIX + i);
        }
        this.dynamicRasterSources.clear();
    }

     loadOverlays(){
        let map = this.store.map.mapbox;
        let ovs = this.store.map.overlays.overlayesConfig;

        let firstLayer: string = null;
        for (let i = ovs.length - 1; i >= 0; i--) {
            let ovsi = ovs[i];
            this.addSource(i);
            if (isArray(ovsi.images)){
                let imgs = ovsi.images.filter(a => !map.hasImage(a.resourceName));
                let cntRes = imgs.length;
                let idx = i;
                imgs.forEach(q => {
                    map.loadImage(q.url, (error: any, image: any) =>{
                        map.addImage(q.resourceName, image);
                        cntRes--;
                        if (cntRes == 0) {
                            this.addOverlayLayers(ovsi, idx);
                        }
                    });
                });
            }
            else {
                this.addOverlayLayers(ovsi, i);
            }
        }
    }

    addOverlayLayers(ovsi: IOverlayConfig, i: number){
        let style = LayersListStore.getOverlaySimpleStyle(ovsi);
        if (style?.type == EStyleType.FILL && style.lineLayerIndex < 0) {
            // add phantom border layer
            let pl : Layer = {
                id: "#temp",
                type: "line",
                layout: {"visibility": "none"},
                paint: {},
                metadata: {"class:overlay:border:visibility": false}
            };
            if (ovsi.source.type != "geojson") pl["source-layer"] = ovsi.id;
            ovsi.layers.unshift(pl);
        }
        let map = this.store.map.mapbox;
        let ov = this.store.map.overlays;
        for (let j = ovsi.layers.length - 1; j >= 0; j--) {
            let vis = ovsi.layers[j].metadata?.["class:overlay:border:visibility"]?? ov.getVisible(ovsi.id);
            let lr : Layer = {
                ...ovsi.layers[j],
                'id': OverlayObjectStore.getOverlayLayerName(ovsi.id, j),
                'source': OverlayTool.OVERLAY_SOURCE_PREFIX + i,
                'layout': {'visibility': vis? 'visible' : 'none', ...ovsi.layers[j].layout},
            };
            //if (ovsi.group?.temporalParams) {}
            if (map.getLayer(lr.id) == null) {
                this.addLayer(lr as any);
            }
        }
    }
}