import {MapComp} from "../../../components/panels/Map/MapComp";
import {FeatureCollection} from "geojson";
import {LeftPanelMode, SearchStore} from "../../SearchStore";
import {GeoJSONSource} from "maplibre-gl";
import {IReactionDisposer} from "mobx/lib/internal";
import {autorun} from "mobx";
import {CustomTool} from "../general/ContainerTools";
import {ConstantsStore} from "../../config/ConstantsStore";

export class SceneHoverTool extends CustomTool{
    static readonly HOVERED_SOURCE_ID : string = ConstantsStore.COMMON_PREFIX+'hovered_src';
    static readonly HOVERED_LAYER_ID : string = ConstantsStore.COMMON_PREFIX+'hovered_layer';

    searchStore: SearchStore;


    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.syncHoveredScene();
            })
        ];
    }

    onInstall() {
        this.installSources();
        //super.onInstall();
    }

    onUninstall() {
        this.uninstallSources();
        //super.onUninstall();
    }

    syncHoveredScene(){
        let fc: FeatureCollection = {'type': 'FeatureCollection', 'features': []};
        let searchStore = this.searchStore;
        if (searchStore.leftPanelMode == LeftPanelMode.search || searchStore.leftPanelMode == LeftPanelMode.agro){
            if (searchStore.hoverSceneid != null){
                let grp = searchStore.searchResult.groups.find(a => a.groupId() == searchStore.hoverSceneid);
                if (grp != null){
                    fc.features = <any>grp.items.map(a => a.feature);
                }
            }
        }
        if (searchStore.leftPanelMode == LeftPanelMode.favorite){
            if (searchStore.hoverSceneid != null){
                let ids = searchStore.hoverSceneid.split('>');
                let groupId = ids[0];
                let sceneIndex = ids.length > 1? Number(ids[1]) : null;
                let grp = searchStore.favoriteList.getGroup(groupId);
                if (grp){
                    fc.features = sceneIndex == null? <any>grp.items.map(a => a.feature)
                        : [grp.items[sceneIndex].feature];
                }
            }
        }

        //if (!this.mapReady) return;
        let src = (this.map.getSource(SceneHoverTool.HOVERED_SOURCE_ID) as GeoJSONSource);
        src.setData(fc);
    }

    uninstallSources(){
        if (this.map.getLayer(SceneHoverTool.HOVERED_LAYER_ID) == null) {
            this.removeLayer(SceneHoverTool.HOVERED_LAYER_ID);
        }
        if (this.map.getSource(SceneHoverTool.HOVERED_SOURCE_ID) == null) {
            this.map.removeSource(SceneHoverTool.HOVERED_SOURCE_ID);
        }
    }
    installSources(){
        if (this.map.getSource(SceneHoverTool.HOVERED_SOURCE_ID) == null) {
            this.map.addSource(SceneHoverTool.HOVERED_SOURCE_ID, {
                'type': 'geojson',
                'data': MapComp.EMPTY_SOURCE
            });
        }
        if (this.map.getLayer(SceneHoverTool.HOVERED_LAYER_ID) == null) {
            this.addLayer({
                'id': SceneHoverTool.HOVERED_LAYER_ID,
                'type': 'line',
                'source': SceneHoverTool.HOVERED_SOURCE_ID,
                'layout': {},
                'paint': {
                    'line-color': '#4DB6BC',
                    'line-opacity': 1,
                    'line-width': 3
                }
            });
        }
    }
}