import * as React from 'react';
import {observer} from "mobx-react";
import autoBindReact from "auto-bind/react";
import {isFunction} from "lodash-es";
import classNames from "classnames";


export interface IAgroAhoRectCheckboxSvgProps {
    onClick?: (e: any)=>void,
    className?: string,
    disabled?: boolean,
    checked?: boolean
}

@observer
export class AgroAhoRectCheckboxSvg extends React.PureComponent<IAgroAhoRectCheckboxSvgProps, undefined> {
    constructor(props: IAgroAhoRectCheckboxSvgProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(e: any){
        if (this.props.disabled === true) return;
        if (isFunction(this.props.onClick)) this.props.onClick(e);
    }

    render() {
        return <React.Fragment>
            {this.props.checked &&
            <svg onClick={this.onClick} className={classNames(this.props.className)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="16" rx="3" stroke="#4DB6BC" strokeWidth="2"/>
                <rect x="4" y="4" width="10" height="10" rx="2" fill="#4DB6BC"/>
            </svg>                }
            {!this.props.checked &&
            <svg onClick={this.onClick} className={classNames(this.props.className)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="16" rx="3" stroke="#454C52" strokeWidth="2"/>
            </svg>}
        </React.Fragment>;
    }
}
