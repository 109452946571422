import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {DateUtils} from "../../../../helper/utils/DateUtils";

@observer
export class IndexByPointMonthTitleComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        if (store.indexByPointer.monthBegin == 0 && store.indexByPointer.monthEnd == 11) return null;
        let m1 = DateUtils.getMonthName(store.indexByPointer.monthBegin, store.trans);
        let m2 = DateUtils.getMonthName(store.indexByPointer.monthEnd, store.trans);
        return <div style={{fontStyle: "13px", lineHeight: 1.4, padding:"3px 14px 0"}}>
            {m1} - {m2}
        </div>;
    }
}
