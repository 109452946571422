import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";


@observer
export class SearchSceneImgTooBigComp extends  React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className="flex-rows flex-rows-centerByBoth">
            <div className="flex-rows flex-rows-centerByBoth" style={{width: "130px",height: "130px"}}>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: "0.5"}}>
                    <path d="M8 4.66663H28" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M4.66797 28V8" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M31.334 8V28" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M28 31.3333H8" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M8.00065 1.33331H1.33398V7.99998H8.00065V1.33331Z" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                    <path d="M34.6667 1.33331H28V7.99998H34.6667V1.33331Z" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                    <path d="M8.00065 28H1.33398V34.6667H8.00065V28Z" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                    <path d="M34.6667 28H28V34.6667H34.6667V28Z" stroke="#ECD719" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                </svg>
                <div style={{fontSize: "11px", margin: "8px",
                    lineHeight: "12px",
                    textAlign: "center",
                    color: "#ECD719",
                    opacity: "0.5"}}>{store.trans["Too large area for preview"]}</div>
            </div>
        </div>;
    }
}
