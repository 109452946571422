import {CustomStore} from "../../CustomStore";
import {AnyLayer, Layer} from "maplibre-gl";
import {MapLayerItemStore} from "./MapLayerItemStore";
import {MapRootStore} from "./MapRootStore";
import {computed} from "mobx";

export enum NativeLayerType{
    simple= "simple",
    text = "text"
}
export class MapLayerSubLayerStore extends CustomStore{
    layerId: string;
    nativeType: NativeLayerType = NativeLayerType.simple;
    layerJson: any;


    get parentLayer(): MapLayerItemStore{
        if (this.parent == null) return null;
        if (this.parent instanceof MapLayerItemStore) return this.parent as MapLayerItemStore;
        return null;
    }
    @computed
    get parentMap(): MapRootStore{
        if (this.parentLayer == null) return null;
        return this.parentLayer.parentMap;
    }

    getLayerJson(): AnyLayer{
        let r: Layer = Object.assign({}, this.layerJson, <AnyLayer>{id: this.layerId});
        if (!r.layout){
            r.layout = {};
        }
        let visible = this.parentLayer.isVisible();
        r.source = this.parentLayer.sourceId;
        if (this.parentLayer.sourceLayer != null) r["source-layer"] = this.parentLayer.sourceLayer;
        r.layout.visibility = visible? 'visible' : 'none';
        return r as AnyLayer;
    }

    setVisibleOnMap(value: boolean){
        this.root.map.superTools.showRoomLayersTool.setVisible(this.layerId, value);
    }
}
