import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class RadiusSvg extends CustomSvg {
    render() {
        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="8.5" stroke="#C5C5C5"/>
        <circle cx="9" cy="9" r="2" fill="#C5C5C5"/>
        <rect x="9.11719" y="8.40625" width="8.07545" height="1.06595" transform="rotate(-40.7416 9.11719 8.40625)" fill="#C5C5C5"/>
        </svg>;      
    }
}
