import React from "react";
import './A2FieldFilterSelectedCheckbox.scss'
import { IStoreProps } from "../../../../../helper/structs/IStoreProps";
import classNames from "classnames";

interface IA2FieldFilterSelectedCheckboxProps extends IStoreProps {
    checked: boolean;
    enabled: boolean;
    hasSelected: boolean;
}

const A2FieldFilterSelectedCheckbox = (props: IA2FieldFilterSelectedCheckboxProps) => {
    let fillColor = props.checked? "#4DB6BC": "#C5C5C5";

    const onClick = () => {
        if (! props.enabled || ! props.hasSelected) return;
        props.store.agro2.filterSelectedChecked = !props.store.agro2.filterSelectedChecked;
    }

    return <div onClick={onClick}
            className={classNames("A2FieldFilterSelectedCheckbox-div", 
            {"noselection": !props.hasSelected || !props.enabled})}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0ZM13.707 5.207L6.707 12.207C6.51947 12.3945 6.26516 12.4998 6 12.4998C5.73484 12.4998 5.48053 12.3945 5.293 12.207L2.293 9.207C2.19749 9.11475 2.12131 9.00441 2.0689 8.8824C2.01649 8.7604 1.9889 8.62918 1.98775 8.4964C1.9866 8.36362 2.0119 8.23194 2.06218 8.10905C2.11246 7.98615 2.18671 7.8745 2.28061 7.7806C2.3745 7.68671 2.48615 7.61246 2.60905 7.56218C2.73194 7.5119 2.86362 7.4866 2.9964 7.48775C3.12918 7.4889 3.2604 7.51649 3.3824 7.5689C3.50441 7.62131 3.61475 7.69749 3.707 7.793L6 10.086L12.293 3.793C12.3852 3.69749 12.4956 3.62131 12.6176 3.5689C12.7396 3.51649 12.8708 3.4889 13.0036 3.48775C13.1364 3.4866 13.2681 3.5119 13.391 3.56218C13.5139 3.61246 13.6255 3.68671 13.7194 3.78061C13.8133 3.8745 13.8875 3.98615 13.9378 4.10905C13.9881 4.23194 14.0134 4.36362 14.0123 4.4964C14.0111 4.62918 13.9835 4.7604 13.9311 4.8824C13.8787 5.00441 13.8025 5.11475 13.707 5.207Z" fill={fillColor}></path>
        </svg>
        {props.checked && <svg className='A2FieldSelectedButton-button-redcircle' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="10" height="10" rx="5" fill="#E94F4F" stroke="#2B3138" strokeWidth="2"></rect>
        </svg>}
    </div>;
}

export default A2FieldFilterSelectedCheckbox;
