import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './AdminPanel_CommentEditComp.scss';
import {observable} from "mobx";
import classNames from "classnames";

interface IAdminPanel_CommentEditComp extends IStoreProps{
    isInsertMode: boolean;
    text: string;
    onChangeComment: (newValue: string)=>any;
    onSave?: ()=>any;
    onCreate?: ()=>any;
    onClose?: ()=>any;
}

@observer
export class AdminPanel_CommentEditComp extends React.Component<IAdminPanel_CommentEditComp, undefined> {
    constructor(props: IAdminPanel_CommentEditComp) {
        super(props);
        autoBindReact(this);
    }
    onClickClose(){
        if (this.props.onClose) this.props.onClose();
    }

    render() {
        let store = this.props.store;
        return <div className="AdminPanel_CommentEditComp">
            <div className="AdminPanel_CommentEditComp-header">
                {this.props.isInsertMode && store.trans["Аdding a comment"]}
                {!this.props.isInsertMode && store.trans["Editing a comment"]}
            </div>
            <div className="AdminPanel_CommentEditComp-label">
                {store.trans.Comment}
            </div>
            <div>
                <input type="text" style={{margin: ""}}
                       className={classNames("text-box-editor width100")}
                       value={this.props.text}
                       onChange={(e: any)=>{this.props.onChangeComment(e.currentTarget.value)}}
                />
            </div>
            <div className="AdminPanel_CommentEditComp-footer">
                {!this.props.isInsertMode && <button className="large-button left-sidebar-active button-jump " onClick={this.props.onSave}>{store.trans.Save}</button>}
                {this.props.isInsertMode && <button className="large-button left-sidebar-active button-jump " onClick={this.props.onCreate}>{store.trans.Add}</button>}
            </div>
            <div className="AdminPanel_CommentEditComp-close" onClick={this.onClickClose}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z" fill="#C5C5C5"/>
                </svg>
            </div>
        </div>;
    }
}
