import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AhoStmtIndcItem, AhoStmtSort} from "../../stmt/agroAhoStmtStore";
import {observer} from "mobx-react";
import autoBindReact from "auto-bind/react";
import React from "react";
import './AgroAhoStmtIndcItem.scss'
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import classNames from "classnames";


export interface IAgroAhoStmtIndcItemProps {
    store: IAgroAhoSuperStore;
    item: AhoStmtIndcItem
}

@observer
export class AgroAhoStmtIndcItem extends React.PureComponent<IAgroAhoStmtIndcItemProps, undefined> {
    constructor(props: IAgroAhoStmtIndcItemProps) {
        super(props);
        autoBindReact(this);
    }

    onMainCheckboxClick(){
        let st = this.props.store.agroAhoStore.stmtStore;
        st.sortMode = AhoStmtSort.NoSort;
        this.props.item.main = !this.props.item.main;
        if (this.props.item.main && this.props.item.ext)
            this.props.item.ext = false;
    }
    onExtCheckboxClick(){
        let st = this.props.store.agroAhoStore.stmtStore;
        if (!st.isExtResearch || this.props.item.main) return;
        st.sortMode = AhoStmtSort.NoSort;
        this.props.item.ext = !this.props.item.ext;
    }

    render() {
        let store_ = this.props.store.agroAhoStore;
        let st = store_.stmtStore;
        let isExt = st.isExtResearch;
        let filter = st.filter && this.props.item.indc_name.toLowerCase().indexOf(st.filter.toLowerCase()) < 0;

        return <div className={classNames("AgroAhoStmtIndcItem-main", {
            "AgroAhoStmtIndcItem-hidden": filter})}>
            <div className="AgroAhoStmtIndcItem-name">{this.props.item.indc_name}</div>
            <div className="AgroAhoStmtIndcItem-unit">{this.props.item.unit}</div>
            <div className="AgroAhoStmtIndcItem-standart">{this.props.item.standard}</div>
            <AgroAhoRectCheckboxSvg className="AgroAhoStmtIndcItem-mainCheckbox"
                checked={this.props.item.main} onClick={this.onMainCheckboxClick}/>
            <AgroAhoRectCheckboxSvg className={classNames("AgroAhoStmtIndcItem-extCheckbox", {
                "AgroAhoStmtIndcItem-disabled": !isExt || this.props.item.main})} checked={this.props.item.ext}
                onClick={this.onExtCheckboxClick}/>
        </div>
    }
}
