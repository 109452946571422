import {CustomStore, ObservableCustomStore} from "../../app/store/CustomStore";
import {autorun, observable, reaction, spy} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelMode} from "../../app/store/SearchStore";
import {AgroAhoProjStore} from "./agroAhoProjStore";
import {AgroAhoCardsStore} from "./agroAhoCardsStore";
import {AgroAhoCardStore, AhoCardShowType, Card} from "./agroAhoCardStore";
import {AgroAhoMapStore} from "./map/agroAhoMapStore";
import {AgroAhoFileStore} from "./agroAhoFileStore";
import {AgroAhoUploadIndcStore} from "./agroAhoUploadIndcStore";
import {Lang} from "../../pluginApi/store/Lang";
import {AgroAhoTranslate_en} from "./translate/AgroAhoTranslate_en";
import {AgroAhoTranslate_ru, AgroAhoTranslate_ru2} from "./translate/AgroAhoTranslate_ru";
import {IAgroAhoSuperStore, LeftPanelModeAgroAho} from "./agroAhoPlugin";
import {AgroAhoUploadCellsStore} from "./agroAhoUploadCellsStore";
import {AgroAhoUrlStore, AhoPermalink} from "./agroAhoUrlStore";
import {save} from "../../app/store/PermalinkDecor";
import {AgroAhoPolylabel} from "./map/agroAhoPolylabel";
import {AgroAhoEditorStore} from "./agroAhoEditorStore";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {AhoUtils} from "./agroAhoUtils";
import {AgroAhoUploadTableStore} from "./agroAhoUploadTableStore";
import {AgroAhoTourStore} from "./agroAhoTourStore";

export enum AhoStage {
    Loading = 'Loading',
    Cards = 'Cards',
    Card = 'Card'
}

export enum AhoModalType {
    FileLoad = 'FileLoad',
    ExportIndicators = 'ExportIndicators',
    ExportRN = 'ExportRN',
    EditCard = 'EditCard',
    ExportLayers = 'ExportLayers',
    DeleteCards = 'DeleteCards',
    DeleteIndicator = 'DeleteIndicator',
    DeleteIndicators = 'DeleteIndicators',
    UploadCells = 'UploadCells',
    SelectFile = 'SelectFile',
    VisualizationSetup = 'VisualizationSetup',
    UploadIndsSelect = 'UploadIndsSelect',
    TableUpload = 'TableUpload',
    DeleteTour = 'DeleteTour',
    DeleteRecNorm = 'DeleteRecNorm',
    DeleteRecNormItem = 'DeleteRecNormItem',
    MergeIsoXml = 'MergeIsoXml',
    DeleteFields = 'DeleteFields',
    CopyFields = 'CopyFields',
    CopyTour = 'CopyTour',
    MergeTours = 'MergeTours',
    DeleteTours = 'DeleteTours',
    EditTour = 'EditTour',
    EditCells = 'EditCells'
}

export enum IAhoUrlType {
    Layres = 'L',
    Indicators = 'I'
}

export enum AhoCheckbox3StateType {
    Checked = 'Checked',
    NoChecked = 'NoChecked',
    Indeterminate = 'Indeterminate'
}

export interface IAhoUrlCode {
    projName: string;
    map_id: number;
    type?: IAhoUrlType;
    tracks?: boolean;
    cells?: boolean;
    field?: boolean;
    indc_id?: number;
    labelCell?: boolean;
}

interface DictCacheJsons {
    [url: string]: any;
}

// export interface ProbeInfo {
//     indicator_cnt: number
//     point_cnt: number;
//     type: string;
// }
//
// export interface Probe {
//     asa_id: number;
//     name: string;
//     ins_tm: string;
//     info: ProbeInfo;
//     file_name: string;
//     file_size: number;
//     indicators?: string[];
// }


// interface ProbeCnt {
//     probe_cnt: number
// }
//
// interface DictIndicators {
//     [indc_code: string]: ProbeCnt;
// }
//
// interface CellInfo {
//     cell_cnt: number;
//     ds_code: string;
//     indicators: DictIndicators
// }
//
// interface FieldInfo {
//     ds_code: string;
//     field_cnt: number;
//     sum_area_ha: number;
// }
//
// interface TrackInfo {
//     ds_code: string;
//     sum_len_m: number;
// }
//
// export interface Card {
//     map_id: number;
//     map_name: string;
//     cell_info: CellInfo;
//     field_info: FieldInfo;
//     track_info: TrackInfo;
//     selected?: boolean;
// }


// export class AgrometrikaAhoStore extends ObservableCustomStore { // загрузка старых пермалинков для agrometrikaStore
//     constructor(parent: IAgroAhoSuperStore) {
//         super(parent as any);
//         this.agroAhoStore = parent.agroAhoStore;
//     }
//     agroAhoStore: AgroAhoStore;
//     @save @observable
//     ahoPermalink: AhoPermalink;
//     subscription(): IReactionDisposer[]{
//         return [
//             autorun( ()=>{
//                 if (this.ahoPermalink)
//                     this.agroAhoStore.ahoPermalink = this.ahoPermalink;
//             })
//         ];
//     }
// }
export const AhoLocalTs = AhoUtils.getTimestamp();

export class AgroAhoStore extends ObservableCustomStore {
    constructor(parent: CustomStore) {
        super(parent);
        this.parentStore = parent as any;
    }

    class(): string {return "AgroAhoStore";}

    @observable
    trans: AgroAhoTranslate_en = new AgroAhoTranslate_en();
    trans2: AgroAhoTranslate_en = this.trans;
    calculateTranslate(){
        if (this.root.lang == Lang.ru) {
            this.trans = new AgroAhoTranslate_ru();
            this.trans2 = new AgroAhoTranslate_ru2();
        } else {
            this.trans = new AgroAhoTranslate_en();
            this.trans.init();
            this.trans2 = this.trans;
        }
    }

    parentStore: IAgroAhoSuperStore;
    @observable
    ahoStage: AhoStage = AhoStage.Loading;
    // projName: string;
    @observable
    ahoDialogShow: boolean = false;
    @observable
    ahoTopSpinnerShow: boolean = false;
    modalRootElement: HTMLDivElement;
    dialogElement: HTMLDivElement;
    ahoModalType: AhoModalType;



    // @observable
    // cards: Card[] = [];
    // card: Card;



    // @observable
    // probes: Probe[] = [];
    // probe: Probe;
    // @observable
    // probeEdit: Probe;


    // probeIndicators: Indicator[] = null;




    // @observable
    // editName: string;
    // @observable
    // editPLink: string;
    // loadProbeId: number;

    // @observable
    // perm: string;
    @observable
    isActive: boolean = false;
    @observable
    isSaved: boolean = false;
    @save
    ahoPermalink: AhoPermalink = {ts: AhoLocalTs} as AhoPermalink;

    loadPLink: boolean = false;
    appInit: boolean = false;
    appInitInProgress: boolean = false;
    cacheJsons: DictCacheJsons = {};


    // popupData: any = {};


    subscription(): IReactionDisposer[]{
        let root: any = this.root;
        return [
            autorun( ()=>{if (!this.appInit && !this.appInitInProgress) this.init().then()}),
            reaction(()=>[this.root.user?.isAuth], ()=>{if (!this.appInit) this.init().then()}),
            reaction(()=>[this.root.agro.projectName, this.projStore.isChanged()], this.doProjChanged),
            reaction(()=>root?.agroExport?.voronovExportStore?.numberOfSaves, this.animateIcon),
            reaction(()=>this.editorStore.mode, this.mapStore.opacityStore.updOpacityLayers),
        ];
    }

    projStore: AgroAhoProjStore = new AgroAhoProjStore(this);
    cardsStore: AgroAhoCardsStore = new AgroAhoCardsStore(this);
    cardStore: AgroAhoCardStore = new AgroAhoCardStore(this);
    mapStore: AgroAhoMapStore = new AgroAhoMapStore(this);
    fileStore: AgroAhoFileStore = new AgroAhoFileStore(this);
    uploadIndcStore: AgroAhoUploadIndcStore = new AgroAhoUploadIndcStore(this);
    uploadCellsStore: AgroAhoUploadCellsStore = new AgroAhoUploadCellsStore(this);
    urlStore: AgroAhoUrlStore = new AgroAhoUrlStore(this);
    polylabel: AgroAhoPolylabel = new AgroAhoPolylabel(this);
    editorStore: AgroAhoEditorStore = new AgroAhoEditorStore(this);
    uploadTableStore: AgroAhoUploadTableStore = new AgroAhoUploadTableStore(this);
    tourStore: AgroAhoTourStore = new AgroAhoTourStore(this);


    async doProjChanged(){
        // if (this.root.searchPanel.leftPanelMode == 'agrometrikaAho')
        //     this.root.searchPanel.switchPanel(LeftPanelModeAgroAho);
        this.cardStore.lastCardName = null;
        this.projStore.projName = this.projStore.urlProjName;
        this.projStore.updVisFormat();
        await this.projStore.doLoadIndicators();
        await this.cardsStore.doLoadCards();
        // console.log('doProjChanged', JSON.parse(JSON.stringify(this.cardsStore.cards)));
        if (this.projStore.isViewPermAndNoCards()) return;
        if (!this.loadPLink) this.ahoStage = AhoStage.Cards;
        // console.log('upd proj', this.ahoStage);
        this.isActive = true;
    }

    get isPlink(){
        return this.loadPLink || window.location.search.indexOf('?link=') == 0;
    }

    isValidPlink(){
        if (this.ahoPermalink && !this.ahoPermalink?.ts) this.ahoPermalink.ts = AhoLocalTs + '_old_plink';
        return this.ahoPermalink?.ts && this.ahoPermalink?.ts !== AhoLocalTs;
    }

    async init(){
        // console.log('init1', AhoUtils.cp(this.ahoPermalink));
        this.appInitInProgress = true;

        // spy((ev) => {console.log('>>>', ev)});
        if (!this.loadPLink) this.loadPLink = this.isPlink;
        if (!this.root.user?.isAuth || !this.root.config.agroAhoShow) {this.appInitInProgress = false; return}
        if (this.loadPLink) {
            // console.log('init2', AhoUtils.cp(this.ahoPermalink));
            if (!this.projStore.urlProjName)
                await AhoUtils.wait(()=>this.projStore.urlProjName/*, 'projName'*/);
            if (!this.projStore.urlProjName || !await this.projStore.isPerm()) {this.appInitInProgress = false; return}
            if (!this.isValidPlink())
                await AhoUtils.wait(()=>this.isValidPlink(), /*'plink'*/null, 5); // ждем загрузки пермалинка
            // console.log('init3', AhoUtils.cp(this.ahoPermalink));
            if (this.ahoPermalink?.cardHidden !== false && this.ahoPermalink?.cardsHidden !== false
                && this.ahoPermalink?.ahoStage !== AhoStage.Card) {
                this.appInit = true;
                this.appInitInProgress = false;
                if (this.root.searchPanel.leftPanelMode == 'agrometrikaAho')
                    this.root.searchPanel.switchPanel(LeftPanelMode.agro);
                return false;
            }
            if (this.ahoPermalink?.cardHidden == false || this.ahoPermalink?.cardsHidden == false) {
                this.cardStore.hideNoAhoElements(true);
                // console.log('init4');
                if (!this.ahoPermalink?.projName) {
                    console.error('init: permalink loading error', this.ahoPermalink);
                    this.root.addError(this.trans['Permalink loading error']);
                    this.appInit = true;
                    this.appInitInProgress = false;
                    this.root.searchPanel.switchPanel(LeftPanelMode.agro);
                    return false;
                }
            }
            // console.log('init5');
            // console.log('plink123', this.ahoPermalink, this.ahoPermalink.cardHidden, this.ahoPermalink.cardsHidden);
            if (this.ahoPermalink && (this.ahoPermalink.cardHidden === false ||
                this.ahoPermalink?.ahoStage == AhoStage.Card)) {
                this.appInit = true;
                if (!this.cardsStore.cards.length)
                    await AhoUtils.wait(()=>!!this.cardsStore.cards.length/*, 'cards'*/);
                let card = this.cardStore.getCard(this.ahoPermalink.map_id);
                // console.log('map_id', this.ahoPermalink.map_id, AhoUtils.cp(this.cardsStore.cards));
                if (AhoUtils.check(card, 'Card')) {
                    await this.cardStore.updPointInfo(card);
                    await this.urlStore.applyPermalink();
                    await AhoUtils.wait(()=>!!this.cardStore.fieldsItems.length/*, 'fields'*/);
                    // await this.wait(()=>!!this.cardStore.cardIndicators?.length, 'ci', 1);
                    // await this.cardStore.updRecNorm();
                    this.ahoStage = AhoStage.Card;
                    if (this.ahoPermalink.cardHidden === false)
                        this.root.searchPanel.switchPanel(LeftPanelModeAgroAho);
                    this.loadPLink = false;
                } else {
                    console.error('init: Wrong card format, card:', card);
                    this.root.addError('init: Wrong card format');
                }
            }
            // console.log('init6');
            if (this.ahoPermalink && this.ahoPermalink.cardsHidden === false) {
                this.appInit = true;
                if (!this.cardsStore.cards.length)
                    await AhoUtils.wait(()=>!!this.cardsStore.cards.length/*, 'cards'*/);
                if (AhoUtils.check(this.cardsStore.cards, 'Card[]')) {

                    if (this.isActive) {
                        await this.cardsStore.doLoadCards();
                        this.ahoStage = AhoStage.Cards;
                        this.root.searchPanel.switchPanel(LeftPanelModeAgroAho);
                    }

                } else {
                    console.error('init: Wrong cards format, cards:', this.cardsStore.cards);
                    this.root.addError('init: Wrong cards format');
                }
            }
            // this.loadPLink = false;
            // console.log('init7');
        }
        (window as any)._map = this.root.map; // debug add map to console
        this.appInitInProgress = false;
    }

    animateIcon(){
        this.isSaved = true;
        // if (this.root.voronovExportStore.isSaved && this.isActive)
        setTimeout(()=>this.isSaved = false, 1500);
    }

    isNum(num: any) {
        return (typeof num === 'number' || typeof num === 'string') && (!!+num || +num === 0);
    }

    toFloat(num: any){
        if (!this.isNum(num)) return 0;
        return this.numWithSpaces(num.toFixed(1).replace('.0',''));
    }

    numWithSpaces(num: any){
        if (!this.isNum(num)) return '';
        let res = num.toString().split(".");
        res[0] = res[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return res.join(".");
    }

    format_date(d_: any){   /* дата в формате дд.мм.гггг */
        let d = new Date(d_);
        if (!d) return '';
        return ('0' + d.getDate()).slice(-2) + '.' + ('0'+(d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    }

    format_date2(d_: any){   /* дата в формате гггг-мм-дд чч:мм:сс */
        let d = new Date(d_);
        if (!d) return '';
        return d.getFullYear() + '-' + ('0'+(d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) +
            ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    }

    format_date3(d_: any){   /* дата в формате гггг-мм-дд */
        let d = new Date(d_);
        if (!d) return '';
        return d.getFullYear() + '-' + ('0'+(d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
    }










    // async doLoadRights(){
    //     await fetchJsonGet('/api/projects').then(async json=>{
    //         let proj_info = json.projects.filter((proj: any)=>proj.proj_name==this.projName);
    //         if (proj_info && proj_info.length == 1) {
    //             await fetchJsonGet(`/api/projects/${this.projName}/info`).then(info=>{
    //                 this.perm = info?.project?.perm;
    //             });
    //         }
    //     });
    //     return this.perm;
    // }







    // async doLoadProbeIndicators(asa_id: number){
    //     let url = `/api/projects/${this.projName}/asa/${asa_id}/info`;
    //     let json = await fetchJsonGet(url);
    //     return json.indicators;
    // }


    // async doLoadProbes(){
    //     let url = `/api/projects/${this.projName}/asa/select`;
    //     await fetchJsonGet(url).then(json=>{
    //         this.probes = json.values;
    //         this.formatProbesDate();
    //     });
    // }







    // formatProbesDate(){
    //     for (let i=0; i<this.probes.length; i++) {
    //         if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(this.probes[i].ins_tm))
    //             this.probes[i].ins_tm = this.probes[i].ins_tm.replace(/-/g, '.').substring(0, 16);
    //     }
    // }

    // async doSaveProbe(asa_id: number){
    //     let url = `/api/projects/${this.projName}/asa/register?action=save&asa_id=${asa_id}`;
    //     await fetchJsonGet(url).then(json=>{
    //         console.log('save:', json);
    //     });
    //     this.doLoadProbes();
    // }





    setModal(root: HTMLDivElement, el: any){
        this.modalRootElement = root;
        this.dialogElement = el;
    }

    toggleModal(show: boolean, type?: AhoModalType){
        if (show) {
            this.ahoModalType = type;
            if (this.dialogElement.parentElement !== document.body)
                document.body.appendChild(this.dialogElement);
        } else {
            if (this.dialogElement.parentElement !== this.modalRootElement)
                this.modalRootElement.appendChild(this.dialogElement);
        }
        this.ahoDialogShow = !!show;
    }



    // async initUrlCode_(){
    //     // <projName>_<map_id>[_L_[c][t][f]] || _I_[indc_id]_[c]]
    //     if (!this.urlAhoCode) return;
    //     let cs = this.cardStore;
    //     let code = this.urlAhoCode.split('_');
    //     this.projStore.projName = code.shift();
    //     if (this.root.agro.projectName == null)
    //         this.root.agro2.projectList.projectInfoRequest(this.projStore.projName);
    //     cs.card = cs.getCard(+code.shift());
    //     cs.lastCardName = cs.card.map_name;
    //     // console.log('indcs1', this.indicators);
    //     cs.cardIndicators = await cs.getIndicators();
    //     // console.log('cindcs', this.cardIndicators);
    //     if (code.length) {
    //         let type = code.shift();
    //         let data = code.shift();
    //         if (data) {
    //             if (type == 'L') {
    //                 cs.indicatorsShow = false;
    //                 this.mapStore.layersShow = true;
    //                 this.mapStore.layerTracksShow = data.indexOf('t') >= 0;
    //                 this.mapStore.layerCellsShow = data.indexOf('c') >= 0;
    //                 this.mapStore.layerFieldOutlinesShow = data.indexOf('f') >= 0;
    //                 cs.selectedIndicator = cs.cardIndicators[0];
    //             } else if (cs.cardIndicators.length) {
    //                 let indc_ = cs.cardIndicators.filter(item=>item.indc_id === +data);
    //                 cs.selectedIndicator = indc_.length ? indc_[0] : cs.cardIndicators[0];
    //                 let label = code.shift();
    //                 if (label == 'c') cs.ahoCardShowType = AhoCardShowType.Cells;
    //             }
    //         }
    //     }
    // }

    // probeEditClick(probe: Probe){
    //     this.probeEdit = probe;
    //     this.editName = probe.name;
    //     this.ahoStage = AhoStage.EditProbe
    // }
    //
    // setProbeDefName(){
    //     this.editName = this.probeEdit.file_name.split('.').slice(0, -1).join('.');
    // }


    // editBackClick(){
    //     this.ahoStage = AhoStage.Probes;
    // }

    getJsonFromCache(url: string){
        return this.cacheJsons[url];
    }

    async cachedFetchJsonGet(url: string, reload?: boolean){
        if (!this.cacheJsons[url] || reload) {
            this.cacheJsons[url] = await fetchJsonGet(url);
        }
        return this.cacheJsons[url];
    }


}