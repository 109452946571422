import * as React from 'react';
import {CSSProperties} from 'react';
import autoBindReact from "auto-bind/react";
import {cloneDeep, isBoolean, isFunction, isNumber, isUndefined} from "lodash-es";
import classNames from "classnames";
import {Utils} from "../../helper/utils/Utils";
import * as ReactDOM from "react-dom";

//угол с которого будет распахиваться
export enum PopupCorner{
    leftBottom = 'leftBottom',
    rightTop = 'rightTop',
    rightBottom = 'rightBottom',
    leftTop = 'leftTop'
}
export enum PopupDirection{
    horizontal = 'horizontal',
    vertical = 'vertical'
}
export enum CenterPlace{
    top,
    bottom,
    right,
    left
}
export interface IContextMenuCommonCompProps {
    style?: any,
    className?: string,
    parentClickNotIgnore?: boolean,
    onClose?: (event: any)=>void,
    direction?: PopupDirection,
    setMinWidth?: boolean,//выставлять min-width по размеру предка. По умолчанию false
    popupCorner?: PopupCorner,//предпочитаемый угол из которого будет выпирать
    centerPlace?: CenterPlace,//default=top
    isCenter?: boolean,//default=false
    //предполагаемые максимальные размеры окна, если по рассчётам будет выходить за
    //эти пределы то будет переориентирован
    autoCornerWidth?: number,
    autoCornerHeight?: number,
    indentVertical?: number//отступ от родителя
    indentHorizontal?: number//отступ от родителя
}

export class ContextMenuCommonComp extends React.Component<IContextMenuCommonCompProps, undefined> {
    constructor(props: IContextMenuCommonCompProps) {
        super(props);
        autoBindReact(this);
        this.ref = React.createRef();
        this.ref2 = React.createRef();
        this.initContextSize();
    }

    contextWidth: number;
    contextHeight: number;
    ref: any;
    ref2: any;

    componentDidMount() {
        this.doCreate();
        //document.body.addEventListener('mousedown', this.myHandlerClick,true);
        //document.body.addEventListener('scroll', this.myHandlerClick,true);
        window.addEventListener('resize', this.callClose);
        //document.addEventListener("keydown", this.keyDown);
    }
    componentWillUnmount() {
        //document.body.removeEventListener('mousedown', this.myHandlerClick,true);
        //document.body.removeEventListener('scroll', this.myHandlerClick,true);
        window.removeEventListener('resize', this.callClose);
        //document.removeEventListener("keydown", this.keyDown);
        this.doDestroy();
        this.ref = null;
    }

    callClose(){
        if (isFunction(this.props.onClose)) this.props.onClose(event);
    }

    div: HTMLDivElement = null;
    inited: boolean = false;
    doCreate(){
        this.doDestroy();
        this.div = document.createElement('div');
        this.div.className = "ttest2";
        //this.div.style.position = "fixed";
        this.div.style.visibility = "none";
        this.div.style.height = "0";
        if (!this.inited){
            document.body.appendChild(this.div);
            this.doRender(true);
        }
    }
    onRender(){
        if (this.inited && (this.contextHeight != this.ref2.current.clientHeight || this.contextWidth != this.ref2.current.clientWidth)){
            this.contextHeight = this.ref2.current.clientHeight;
            this.contextWidth = this.ref2.current.clientWidth;
            this.doRender();
            return;
        }
        this.contextHeight = this.ref2.current.clientHeight;
        this.contextWidth = this.ref2.current.clientWidth;
        this.inited = true;
        this.div.style.display = "block";
        this.div.style.visibility = "visible";
        this.doRender();
    }
    initContextSize(){
        if (this.props.autoCornerWidth > 0) this.contextWidth = this.props.autoCornerWidth;
        if (this.props.autoCornerHeight > 0) this.contextHeight = this.props.autoCornerHeight;
    }
    componentDidUpdate(prevProps: Readonly<IContextMenuCommonCompProps>, prevState: Readonly<undefined>, snapshot?: any) {
        this.initContextSize();
        this.doRender(true);
    }
    shouldComponentUpdate(nextProps: Readonly<IContextMenuCommonCompProps>, nextState: Readonly<undefined>, nextContext: any): boolean {
        return true;
    }

    doRender(invisible: boolean = false){
        let st: CSSProperties = {};
        if (this.props.style){
            st = cloneDeep( this.props.style);
        }
        if (invisible){
            //st.display = "none";
            //st.position = "fixed";
        }
        let bbox = Utils.getElemAbsoluteCoords(this.ref.current.parentElement);
        let indentW = 0;
        let indentH = 0;
        if (isNumber(this.props.indentHorizontal)) {
            indentW = this.props.indentHorizontal;
        }
        if (isNumber(this.props.indentVertical)) {
            indentH = this.props.indentVertical;
        }

        let x1 = bbox.left;
        let x2 = bbox.left + bbox.width;
        let y1 = bbox.top;
        let y2 = bbox.top + bbox.height;
        x1 = x1 - indentW;
        x2 = x2 + indentW;
        y1 = y1 - indentH;
        y2 = y2 + indentH;
        bbox.left = x1;
        bbox.width = x2 - x1;
        bbox.top = y1;
        bbox.height = y2 - y1;


        st.position = "absolute";
        st.zIndex = 10000;
        let isCenter = false;
        if (!isUndefined(this.props.isCenter)) isCenter = this.props.isCenter;

        let dir: PopupDirection = PopupDirection.horizontal;
        if (!isUndefined(this.props.direction)) dir = this.props.direction;
        let c: PopupCorner = PopupCorner.leftBottom;
        if (!isUndefined(this.props.popupCorner)) c = this.props.popupCorner;
        let left = true;
        let top = true;
        if (c == PopupCorner.rightTop) {
            left = false;
            top = true;
        }
        if (c == PopupCorner.rightBottom) {
            left = false;
            top = false;
        }
        if (c == PopupCorner.leftBottom) {
            left = true;
            top = false;
        }
        if (c == PopupCorner.leftTop) {
            left = true;
            top = true;
        }
        if (isCenter){
            let centerPlace = CenterPlace.top;
            if (!isUndefined(this.props.centerPlace)) centerPlace = this.props.centerPlace;
            if (centerPlace == CenterPlace.top) {
                st.top = (bbox.top - this.contextHeight) + "px";
                st.left = (bbox.left - Math.round((this.contextWidth - bbox.width) / 2.0) ) + "px";
            }
            if (centerPlace == CenterPlace.bottom) {
                st.top = (bbox.top + bbox.height) + "px";
                st.left = (bbox.left - Math.round((this.contextWidth - bbox.width) / 2.0) ) + "px";
            }
        }else {
            if (dir == PopupDirection.vertical) {
                if (this.contextWidth > 0) {
                    if (left == true && (bbox.left - this.contextWidth) < 0) {
                        left = false;
                    }
                    if (left == false && (bbox.left + bbox.width + this.contextWidth) > document.body.clientWidth) {
                        left = true;
                    }
                }
                if (this.contextHeight > 0) {
                    if (top == false && (bbox.top + bbox.height - this.contextHeight) < 0) {
                        top = true;
                    }
                    if (top == true && (bbox.top + this.contextHeight) > document.body.clientHeight) {
                        top = false;
                    }
                }

                if (left) {
                    st.right = (document.body.clientWidth - bbox.left) + "px";
                } else {
                    st.left = (bbox.left + bbox.width) + "px";
                }
                if (top) {
                    st.top = (bbox.top) + "px";
                } else {
                    st.bottom = (document.body.clientHeight - (bbox.top + bbox.height)) + "px";
                }
            }
            if (dir == PopupDirection.horizontal) {
                if (this.contextWidth > 0) {
                    if (left == true && document.body.clientWidth < (bbox.left + this.contextWidth)) {
                        left = false;
                    }
                    if (left == false && (bbox.left + this.contextWidth) < 0) {
                        left = true;
                    }
                }
                if (this.contextHeight > 0) {
                    if (top == false && (bbox.top + bbox.height + this.contextHeight) > document.body.clientHeight) {
                        top = true;
                    }
                    if (top == true && (bbox.top + this.contextHeight) < 0) {
                        top = false;
                    }
                }

                if (left) {
                    st.left = (bbox.left) + "px";
                } else {
                    st.right = (document.body.clientWidth - (bbox.left + bbox.width)) + "px";
                }
                if (top) {
                    st.top = (bbox.top - this.contextHeight) + "px";
                } else {
                    st.top = (bbox.top + bbox.height) + "px";
                }
            }
        }
        if (this.props.setMinWidth) {
            st.minWidth = bbox.width + "px";
        }
        st.zIndex = 1000;
        ReactDOM.render(<React.Fragment>
            <div  style={{zIndex: 1000, background: "0 0", position: "fixed", left: "0", right: "0", top: "0", bottom: "0"}} onClick={this.onOutClick} />
                <div className={classNames(this.props.className)} ref={this.ref2}
                             style={st} >
                    {this.props.children}
                </div>

        </React.Fragment>, this.div, invisible?this.onRender: null);
    }

    doDestroy(){
        if (this.div != null){
            ReactDOM.unmountComponentAtNode(this.div);
            document.body.removeChild(this.div);
            this.div = null;
        }
    }

    onOutClick(){
        this.callClose();
    }

    render() {
        return <div ref={this.ref} className="ttest" />;
    }
}
