import {CustomStore} from "./CustomStore";
import {action, computed, observable} from "mobx";
import {LoadStatus} from "../helper/structs/LoadStatus";
import {SearchItemStore} from "./SearchItemStore";
import {SearchItemGroup} from "./SearchItemGroup";
import {Utils} from "../helper/utils/Utils";
import {save} from "./PermalinkDecor";
import {SyncUtils} from "../helper/utils/SyncUtils";

export class ListSceneStore extends CustomStore{
    class(): string {return "ListSceneStore";}
    //*************** состояние выборки
    @observable
    searchTotalState: LoadStatus = LoadStatus.ready;
    @observable
    searchState: LoadStatus = LoadStatus.ready;
    @save @observable
    totalRecords: number = 0;//сколько результатов в теории
    @save @observable
    totalRecordsDates: number = 0;//сколько результатов в теории по датам

    @save @observable
    groups: SearchItemGroup[] = [];
    @save @observable
    grouped: boolean = false;
    @save @observable
    autoSort: boolean = false;

    @computed
    get currentItems():SearchItemStore[]{
        let g = this.groups.find(a => a.isCurrent());
        if (g == null) return [];
        return g.items;
    }

    @computed
    get records(): SearchItemStore[]{
        let r: SearchItemStore[] = [];
        for(let i = 0; i < this.groups.length; i++){
            r = r.concat(this.groups[i].items);
        }
        return r;
    }

    synchronizeLists(fromList: ListSceneStore){
        let ths = this;
        SyncUtils.syncDataAndState(
            this.groups,
            fromList.groups, (oldState => oldState.groupId()),
            (oldState => oldState.groupId()),
            (newDataItem) =>{
                    let t = new SearchItemGroup(ths);
                    newDataItem.items.forEach(a => {
                        let t2 = new SearchItemStore(t);
                        t2.feature = a.feature;
                        t.items.push(t2);
                    });
                    return t;
                },  (oldState, newDataItem) => {

                SyncUtils.syncDataAndState(oldState.items, newDataItem.items,
                        oldState1 => oldState1.sceneId(),
                        newDataItem1 => newDataItem1.sceneId(),
                    newDataItem1 => {
                        let t = new SearchItemStore(oldState);
                        t.feature = newDataItem1.feature;
                        return t;
                    }, (oldState1, newDataItem1) => {
                        oldState1.feature = newDataItem1.feature;
                    });
            }
        );
    }

    @action
    syncAddSceneItem(arr: SearchItemStore[]) {
        const idDic = new Set(arr.map(a => a.sceneId()));
    
        const groupMap = new Map<string, SearchItemGroup>();
        this.groups.forEach(group => {
            group.items.forEach(item => {
                groupMap.set(item.sceneId(), group);
            });
        });

        for (const item of arr) {
            const sceneId = item.sceneId();
            if (!groupMap.has(sceneId)) {
                this.addSceneItem(item);
                groupMap.set(sceneId, this.groups[0]);
            }
        }
        const forDel = this.records.filter(a => !idDic.has(a.sceneId()));
        forDel.forEach(a => this.removeSceneItem(a));
        this.sortGroups();
    } 
    @action sortGroups(){
        if (!this.autoSort) return;
        this.groups = this.groups.slice().sort((a,b) => b.first().feature.properties.acqdate.localeCompare(a.first().feature.properties.acqdate))
    }
    @action convetToScenes(){
        let root = this.root;
        this.grouped = false;
        for(let i = 0; i < this.groups.length; i++){
            let g = this.groups[i];
            for(let i1 = g.items.length - 1; i1 >= 1; i1--){
                let n = new SearchItemGroup(root);
                n.items.push(g.items[i1]);
                Utils.arrayInsert(this.groups, i, n);
            }
            g.items.length = 1;
        }
    }
    @action convertToMosaic(){
        this.grouped = true;
        for(let i = this.groups.length - 1; i >= 0; i--){
            let g = this.groups[i];
            for(let i1 = i - 1; i1 >= 0; i1--){
                if (this.isGroupedScenes(g.first(), this.groups[i1].first())){
                    let sis = g.items.slice(0);
                    this.removeManySceneItem(sis);
                    this.addManySceneItem(sis);
                    break;
                }
            }
        }

    }
    @action addManySceneItem(si: SearchItemStore[]){
        for(let i = 0; i < si.length; i++) this.addSceneItem(si[i]);
        this.sortGroups();
    }
    @action addOneSceneItem(si: SearchItemStore){
        this.addSceneItem(si);
        this.sortGroups();
    }
    @action
private addSceneItem(si: SearchItemStore) {
    const root = this.root;
    let targetGroup: SearchItemGroup;

    if (this.grouped) {
        targetGroup = this.groups.find(a => this.isGroupedScenes(a.first(), si)) || new SearchItemGroup(root);

        if (!this.groups.includes(targetGroup)) {
            this.groups.unshift(targetGroup);
        }

        targetGroup.items.unshift(si);
    } else {
        targetGroup = new SearchItemGroup(root);
        targetGroup.items.unshift(si);
        this.groups.unshift(targetGroup);
    }
}
    isGroupedScenes(si1: SearchItemStore, si2: SearchItemStore): boolean{
        return (si1.feature.properties.acqdate == si2.feature.properties.acqdate) && (si1.feature.properties.satellite == si2.feature.properties.satellite);
    }
    @action removeAll(){
        this.groups = [];
    }
    @action removeManySceneItem(si: SearchItemStore[]){
        for(let i = 0; i < si.length; i++) this.removeSceneItem(si[i]);
    }
    findGroup(sceneId: string): SearchItemGroup{
        for(let i = 0; i < this.groups.length; i++){
            let d2 = this.groups[i].items.find(a => a.sceneId() === sceneId);
            if (d2 != null) return this.groups[i];
        }
        return null;
    }
    findItem(sceneId: string): SearchItemStore{
        let grp = this.findGroup(sceneId);
        if (grp == null) return null;
        let item = grp.items.find(a => a.sceneId() == sceneId);
        return item;
    }
    @action
    removeSceneItem(si: SearchItemStore) {
        for (let i = 0; i < this.groups.length; i++) {
            const group = this.groups[i];
            const itemIndex = group.items.findIndex(a => a.sceneId() === si.sceneId());
    
            if (itemIndex >= 0) {
                group.items.splice(itemIndex, 1);
                if (group.items.length === 0) {
                    this.groups.splice(i, 1);
                }
                return;
            }
        }
    }

    getAllSceneItems(): SearchItemStore[]{
        let r: SearchItemStore[] = [];
        this.groups.forEach(a => {a.items.forEach(b => r.push(b))});
        return r;
    }

    @action setCountResult(count: number, count_dates: number){
        this.totalRecords = count;
        this.totalRecordsDates = count_dates;
        this.searchTotalState = LoadStatus.ready;
    }

    isSelectedAll(): boolean{
        if (this.records.length == 0) return false;
        for(let i = 0; i < this.records.length; i++){
            if (!this.records[i].selected) return false;
        }
        return true;
    }

    @action inversSelectAll(){
        let all = this.isSelectedAll();
        for (let i = 0; i < this.records.length; i++) {
            this.records[i].selected = !all;
            //this.root().map.setLayerVisibility(this.records[i].sceneId(), !all);
        }
    }
}
