import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './A2ProjectStructForm.scss';
import {ReactSortable} from "react-sortablejs";
import {action, observable} from "mobx";
import classNames from "classnames";
import {ProjectStructItemType} from "../../../../../store/agro/A2ColumnItemStore";
import {A2NavigatorSection} from "../../../../../store/agro/A2AgroStore";
import {fetchJsonPost} from "../../../../../helper/utils/FetchUtils";
import {ra} from "../../../../../helper/utils/mobxUtils";
import {CheckboxMiniComp, CheckboxSize} from "../../../../Common/CheckboxMiniComp";
import {CheckBoxInFrame} from "../../../User/ProjectSettings/CheckBoxInFrame";

interface IProjectStructItem{
    id: any;
    name: string;
    filtered: boolean;
}
@observer
export class A2ProjectStructForm extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.doLoadCurrent();
    }

    doLoadDefault(){
        let store = this.props.store;
        let pi = store.agro2.projectInfo.projectInfoJson;
        let ps = store.agro2.projectStruct;
        ps.editor_structIds = [...pi.proj_settings.groups];

        this.selectedItems = [];
        ps.editor_structIds.forEach(a =>{
            let col = store.agro2.projectInfo.projectInfoJson.type_info.type_schema.obj.view.columns.find(c => c.name == a);

            let p:IProjectStructItem = {
                id: col.name,
                name: store.agro2.projectInfo.translate.getColumnTitle(col.name),
                filtered: col.grouping?.ord != null
            };
            this.selectedItems.push(p);
        });
        this.avaibleItems = [];
        store.agro2.projectInfo.projectInfoJson.type_info.type_schema.obj.view.columns.filter(a => a.grouping).forEach(a => {
            if (ps.editor_structIds.find(b => a.name == b) == null) {
                this.avaibleItems.push({id: a.name, name: store.agro2.projectInfo.translate.getColumnTitle(a.name), filtered: false});
            }
        });
    }
    @action
    doLoadCurrent(){
        let store = this.props.store;
        let ps = store.agro2.projectStruct;
        ps.editor_structIds = [...ps.structIds];

        this.selectedItems = [];
        ps.editor_structIds.forEach(a =>{
            let col = store.agro2.projectInfo.projectInfoJson.type_info.type_schema.obj.view.columns.find(c => c.name == a);

            let p:IProjectStructItem = {
                id: col.name,
                name: store.agro2.projectInfo.translate.getColumnTitle(col.name),
                filtered: col.grouping?.ord != null
            };
            this.selectedItems.push(p);
        });
        this.avaibleItems = [];
        store.agro2.projectInfo.projectInfoJson.type_info.type_schema.obj.view.columns.filter(a => a.grouping).forEach(a => {
            if (ps.editor_structIds.find(b => a.name == b) == null) {
                this.avaibleItems.push({id: a.name, name: store.agro2.projectInfo.translate.getColumnTitle(a.name), filtered: false});
            }
        });
    }

    @action
    setStateAvaible(newState: any[]){
        this.avaibleItems = newState;
    }
    @action
    setStateSelected(newState: any[]){
        let store = this.props.store;
        let ps = store.agro2.projectStruct;
        ps.editor_structIds = newState.map(a => a.id);
        this.selectedItems = newState;
    }
    @observable
    selectedItems: IProjectStructItem[] = [];
    @observable
    avaibleItems: IProjectStructItem[] = [];
    @observable
    saveOnlyCurrentSession: boolean = false;

    @action
    onClose(){
        this.props.store.agro2.projectSettings.closeWindow();
    }

    @action
    onClickToDefault(){
        let store = this.props.store;
        this.doLoadDefault();
    }
    @action
    onClickApply(){
        if (!this.saveOnlyCurrentSession){
            let structStr =this.props.store.agro2.projectStruct.editor_structIds.join(",")
            let url = `/api/projects/${this.props.store.agro.projectName}/modify`;
            fetchJsonPost(url,{groups: structStr}).then(()=>{
                ra(()=>{
                    this.doApply([...this.props.store.agro2.projectStruct.editor_structIds]);
                    this.props.store.agro2.projectSettings.closeWindow();
                });
            }).catch(err => this.props.store.addError(err));
        }else {
            this.doApply([...this.props.store.agro2.projectStruct.editor_structIds]);
            this.props.store.agro2.projectSettings.closeWindow();
        }
    }

    private doApply(newStruct: string[]){
        this.props.store.agro2.projectStruct.structIds = [...newStruct];
        this.props.store.agro2.projectStruct.initFirstPage();
        this.props.store.agro2.projectStruct.setActivePage(this.props.store.agro2.projectStruct.navigatorRootItem);
        this.props.store.agro2.projectStruct.navigatorRootItem.resetValues();
        this.props.store.agro2.navigatorSection = A2NavigatorSection.folder;
    }

    onMove(evt: any, originalEvent: any){
        let id = evt.related.getAttribute("data-id");
        let it = this.selectedItems.find(a => a.id == id);
        if (it != null && (it.filtered === true) &&
            !evt.willInsertAfter) return false;           
        return true;
    }
    onMove2(evt: any, originalEvent: any){
        let id = evt.related.getAttribute("data-id");
        let it = this.selectedItems.find(a => a.id == id);
        if (it != null && (it.filtered === true) &&
            !evt.willInsertAfter) return false;
        return true;
    }

    isLockedItem(item: IProjectStructItem): boolean{
        return item.filtered === true;
    }
    @action
    onClickSaveOnlyCurrentSession(){
        this.saveOnlyCurrentSession = !this.saveOnlyCurrentSession;
    }

    render() {
        let store = this.props.store;

        let LockIcon = ()=>{ return <div className="A2ProjectStructForm-lockIcon">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1096_120)">
                    <path d="M9 4.5H7.5V3C7.5 2.60218 7.34196 2.22064 7.06066 1.93934C6.77936 1.65804 6.39782 1.5 6 1.5C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V4.5H3V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0C6.79565 0 7.55871 0.316071 8.12132 0.87868C8.68393 1.44129 9 2.20435 9 3V4.5Z" fill="#2B3138"/>
                    <path d="M10.5 5.25H1.5C1.30109 5.25 1.11032 5.32902 0.96967 5.46967C0.829018 5.61032 0.75 5.80109 0.75 6V11.25C0.75 11.4489 0.829018 11.6397 0.96967 11.7803C1.11032 11.921 1.30109 12 1.5 12H10.5C10.6989 12 10.8897 11.921 11.0303 11.7803C11.171 11.6397 11.25 11.4489 11.25 11.25V6C11.25 5.80109 11.171 5.61032 11.0303 5.46967C10.8897 5.32902 10.6989 5.25 10.5 5.25ZM6 9.75C5.70333 9.75 5.41332 9.66203 5.16665 9.4972C4.91997 9.33238 4.72771 9.09811 4.61418 8.82402C4.50065 8.54994 4.47094 8.24834 4.52882 7.95736C4.5867 7.66639 4.72956 7.39912 4.93934 7.18934C5.14912 6.97956 5.41639 6.8367 5.70736 6.77882C5.99834 6.72094 6.29994 6.75065 6.57403 6.86418C6.84811 6.97771 7.08238 7.16997 7.2472 7.41664C7.41203 7.66332 7.5 7.95333 7.5 8.25C7.5 8.64782 7.34196 9.02936 7.06066 9.31066C6.77936 9.59196 6.39782 9.75 6 9.75Z" fill="#2B3138"/>
                </g>
                <defs>
                    <clipPath id="clip0_1096_120">
                        <rect width="12" height="12" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>};

        return <div className="A2ProjectStructForm-form noselect">
                <div className="A2ProjectStructForm-title">
                    <div className="flex-stretch-item"></div>
                </div>
                <div className="flex-stretch-item A2ProjectStructForm-selectedPanelOuter">
                    <div className="A2ProjectStructForm-selectedPanel A2ProjectStructForm-selectedItemsBox1">
                        <div className="A2ProjectStructForm-selectedPanelTitle">
                            <span className="A2ProjectStructForm-selectedPanelTitleText">{store.trans["Group order"]}</span>
                        </div>
                        <ReactSortable list={this.selectedItems} setList={this.setStateSelected}
                                       group="shared"
                                       filter='.filtered'
                                       animation={150}
                                       onMove={this.onMove}
                                       dragClass="A2ProjectStructForm-selectedItemsDivDragClass"
                                       className="A2ProjectStructForm-selectedItemsBox"
                                       ghostClass="A2ProjectStructForm-selectedItemsDivGhostClass">
                            {this.selectedItems.map((item, index) => {
                                if (index != this.selectedItems.length - 1){
                                    return <div key={item.id} className="A2ProjectStructForm-selectedItemsDivBox">
                                        <div key={item.id} className="A2ProjectStructForm-selectedItemsDiv A2ProjectStructForm-selectedItemsItem">
                                            <div className={classNames("A2ProjectStructForm-selectedItemsDivInner", {"pointer":!this.isLockedItem(item)})}>
                                                {this.isLockedItem(item) && <LockIcon />}
                                                {item.name}
                                            </div>
                                        </div>
                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="A2ProjectStructForm-selectedItemsDivArrow">
                                            <path d="M7 4.6875H3C1.3 4.6875 0 5.90625 0 7.5C0 9.09375 1.3 10.3125 3 10.3125H7V14.0625L16 7.5L7 0.9375V4.6875Z" fill="#6B707B"/>
                                        </svg>
                                    </div>;
                                }else
                                return <div key={item.id} className="A2ProjectStructForm-selectedItemsDivBox">
                                    <div key={item.id} className="A2ProjectStructForm-selectedItemsDiv A2ProjectStructForm-selectedItemsItem">
                                        <div className={classNames("A2ProjectStructForm-selectedItemsDivInner",{"pointer":!this.isLockedItem(item)})}>
                                            {this.isLockedItem(item) && <LockIcon />}
                                            {item.name}
                                        </div>
                                    </div>
                                    {this.avaibleItems.length > 0 && <React.Fragment>
                                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                                         className="A2ProjectStructForm-selectedItemsDivArrow A2ProjectStructForm-GhostHidden">
                                        <path d="M7 4.6875H3C1.3 4.6875 0 5.90625 0 7.5C0 9.09375 1.3 10.3125 3 10.3125H7V14.0625L16 7.5L7 0.9375V4.6875Z" fill="#6B707B"/>
                                    </svg>
                                    <div className="A2ProjectStructForm-featureItemDiv" />
                                    </React.Fragment>}
                                </div>;
                            })}

                        </ReactSortable>
                    </div>
                    <div className="flex-full-center">
                        <div className="A2ProjectStructForm-strelki">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.11111 11.5V1" stroke="#59626D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1 3.8L4.11111 1L7.22222 3.8" stroke="#59626D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.8889 3.7998V14.9998" stroke="#59626D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.77778 12.2002L11.8889 15.0002L15 12.2002" stroke="#59626D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div className="A2ProjectStructForm-DragPanel">
                        <div className="A2ProjectStructForm-selectedPanel A2ProjectStructForm-selectedItemsBox2">
                            <div className="A2ProjectStructForm-selectedPanelTitle">
                                <span className="A2ProjectStructForm-selectedPanelTitleText">{store.trans.Categories}</span>
                            </div>
                            <ReactSortable list={this.avaibleItems} setList={this.setStateAvaible}
                                           group="shared"
                                           filter='.filtered'
                                           animation={150}
                                           onMove={this.onMove2}

                                           dragClass="A2ProjectStructForm-selectedItemsDivDragClass"
                                           className="A2ProjectStructForm-selectedItemsBox"
                                           ghostClass="A2ProjectStructForm-selectedItemsDivGhostClass">
                                {this.avaibleItems.map((item) => (
                                    <div key={item.id} className="A2ProjectStructForm-selectedItemsDiv A2ProjectStructForm-selectedItemsItem">
                                        <div className="A2ProjectStructForm-selectedItemsDivInnerBottom pointer">{item.name}</div></div>
                                ))}
                            </ReactSortable>
                        </div>

                    </div>
                </div>
                <div className="A2ProjectStructForm-footerDiv">
                    <div>
                        <CheckBoxInFrame className="" state={this.saveOnlyCurrentSession} onChange={this.onClickSaveOnlyCurrentSession}>
                            <span className="text-next">{store.trans["Save for current session only"]}</span>
                        </CheckBoxInFrame>
                    </div>
                    <div className="flex-stretch-item" />
                    <button className={classNames(
                        "large-button left-sidebar-active A2ProjectStructForm-footerButton button-jump",
                        {"opacity50": false})}
                            onClick={this.onClickApply}>
                        <span>{store.trans.Save}</span>
                    </button>
                </div>
            </div>;
    }
}
