import {CustomStore} from "../CustomStore";
import {action, computed, observable, ObservableSet} from "mobx";
import {fetchJson, fetchJsonPost} from "../../helper/utils/FetchUtils";
import {SortDirection, Utils} from "../../helper/utils/Utils";
import {TranslateUtils} from "../../helper/lang/TranslateUtils";
import {ra} from "../../helper/utils/mobxUtils";

export enum UserRole {
    ADMIN = 'admin',
    USER = 'user',
    GUEST = 'guest',
    OWNER = 'proj_admin',
}

export enum UserStatus {
    LOCKED,
    ACTIVE
}
export enum UserSubscription{
    limit= "limit",
    unlimit="unlimit"
}

export interface IUser{
    user_id: number;
    login: string;
    email: string;
    is_active: boolean;
    email_confirmed: boolean;
    role: string;
    given_name: string;
    surname: string;
    login_time: string;
    comment: string;
}

export enum UserListFilterItems{
    filter_role_admin,
    filter_role_owner,
    filter_role_user,
    filter_role_guest,

    filter_status_active,
    filter_status_blocked,

    filter_subscr_limit,
    filter_subscr_unlimit,
}
export enum UserListFilterItemsRegion{
    role, status, subscr
}
function getUserListItemsByRegion(region: UserListFilterItemsRegion): UserListFilterItems[]{
    if (region == UserListFilterItemsRegion.role) return [UserListFilterItems.filter_role_user, UserListFilterItems.filter_role_guest, UserListFilterItems.filter_role_owner, UserListFilterItems.filter_role_admin];
    if (region == UserListFilterItemsRegion.status) return [UserListFilterItems.filter_status_blocked, UserListFilterItems.filter_status_active];
    if (region == UserListFilterItemsRegion.subscr) return [UserListFilterItems.filter_subscr_unlimit, UserListFilterItems.filter_subscr_limit];
    return [];
}
export enum UserListColumns{
title,
email,
comment,
enter,
role,
status,
subscr,
}

export class UserListStore extends CustomStore{
    class(): string {return "UserListStore";}

    @observable loading: boolean = false;
    @observable loaded: boolean = false;
    @observable users: IUser[] = [];
    @observable filterUserStr: string = "";
    @observable filterItems: ObservableSet<UserListFilterItems> = new ObservableSet<UserListFilterItems>();
    @observable sortedColumn: UserListColumns = UserListColumns.title;
    @observable sortedDirection: SortDirection = SortDirection.asc;

    getTitleFilter(item: UserListFilterItems): string{
        let store = this.root;
        switch (item){
            case UserListFilterItems.filter_role_admin: return store.trans.Administrator;
            case UserListFilterItems.filter_role_owner: return store.trans.Owner;
            case UserListFilterItems.filter_role_user: return store.trans.User;
            case UserListFilterItems.filter_role_guest: return store.trans.Guest;
            case UserListFilterItems.filter_status_active: return store.trans.Active;
            case UserListFilterItems.filter_status_blocked: return store.trans.Blocked;
            case UserListFilterItems.filter_subscr_limit: return store.trans.Limit;
            case UserListFilterItems.filter_subscr_unlimit: return store.trans.Unlimit;
            default: return "";
        }
    }
    isFiltered(user: IUser, filter: UserListFilterItems): boolean{
        switch (filter){
            case UserListFilterItems.filter_role_admin: return user.role == UserRole.ADMIN;
            case UserListFilterItems.filter_role_owner: return user.role == UserRole.ADMIN;
            case UserListFilterItems.filter_role_user: return user.role == UserRole.USER;
            case UserListFilterItems.filter_role_guest: return user.role == UserRole.GUEST;
            case UserListFilterItems.filter_status_active: return user.is_active;
            case UserListFilterItems.filter_status_blocked: return !user.is_active;
            case UserListFilterItems.filter_subscr_limit: return true;
            case UserListFilterItems.filter_subscr_unlimit: return false;
            default: return true;
        }
    }
    @computed
    get filterdUsers(): IUser[]{
        let list = this.getSortedUsers();
        return list.filter(user => {
            let ok = true;
            if (this.filterUserStr != "") {
                ok = (
                    TranslateUtils.getUserFullName(user.surname, user.given_name).toLocaleLowerCase().includes(this.filterUserStr.toLocaleLowerCase()) ||
                    user.email.toLocaleLowerCase().includes(this.filterUserStr.toLocaleLowerCase()) ||
                    (user.comment??"").toLocaleLowerCase().includes(this.filterUserStr.toLocaleLowerCase())
                );
            }
            let checkRegionFilters = (reg: UserListFilterItemsRegion)=>{
                let items = getUserListItemsByRegion(reg);
                let hasAny = false;
                items.forEach(i => {
                    if (this.filterItems.has(i)) hasAny = true;
                });
                if (!hasAny) return;
                let hit = false;
                items.forEach(f =>{
                    if (this.filterItems.has(f) && this.isFiltered(user, f)){
                        hit = true;
                    }
                });
                if (!hit) ok = false;
            }
            if (this.filterItems.size > 0){
                checkRegionFilters(UserListFilterItemsRegion.role);
                checkRegionFilters(UserListFilterItemsRegion.status);
                checkRegionFilters(UserListFilterItemsRegion.subscr);
            }
            return ok;
        });
    }
    private getSortedUsers(): IUser[]{
        let list = this.users.slice(0);
        if (this.sortedColumn == UserListColumns.title) {
            list = list.sort((u1, u2) => {
                let un1 = TranslateUtils.getUserFullName(u1.surname, u1.given_name);
                let un2 = TranslateUtils.getUserFullName(u2.surname, u2.given_name);
                if (this.sortedDirection == SortDirection.asc) return Utils.compareSortString(un1, un2);
                else return Utils.compareSortString(un2, un1);
            });
        }
        if (this.sortedColumn == UserListColumns.email) {
            list = list.sort((u1, u2) => {
                if (this.sortedDirection == SortDirection.asc) return Utils.compareSortString(u1.email, u2.email);
                else return Utils.compareSortString(u2.email, u1.email);
            });
        }
        if (this.sortedColumn == UserListColumns.enter) {
            list = list.sort((u1, u2) => {
                function parseDate(s: string): Date{
                    if (s == null) return null;
                    return new Date(s.replace(" ", "T")+"Z");
                }

                if (this.sortedDirection == SortDirection.asc) return Utils.compareSortDates(parseDate(u1.login_time), parseDate(u2.login_time));
                else return Utils.compareSortDates(parseDate(u2.login_time), parseDate(u1.login_time));
            });
        }
        if (this.sortedColumn == UserListColumns.status) {
            list = list.sort((u1, u2) => {
                if (this.sortedDirection == SortDirection.asc) return Utils.compareSortBoolean(u1.is_active, u2.is_active);
                else return Utils.compareSortBoolean(u2.is_active, u1.is_active);
            });
        }
        if (this.sortedColumn == UserListColumns.role) {
            list = list.sort((u1, u2) => {
                if (this.sortedDirection == SortDirection.asc) return Utils.compareSortString(u1.role, u2.role);
                else return Utils.compareSortString(u2.role, u1.role);
            });
        }
        return list;
    }
    @action
    doLoadList(){
        let url = "/api/auth/user/list";
        this.loading = true;
        fetchJson(url)
            .then( value => {
                this.setUsers(value);
            })
            .catch(reason => {
                this.setError(reason);
            });
    }

    @action
    private setUsers(value: IUser[]){
        this.users = value as IUser[];
        this.loading = false;
        this.loaded = true;
    }
    @action
    private setError(reason: string){
        this.loading = false;
        this.loaded = true;
        this.root.addError(Utils.getErrorString(reason));
    }

    updateRole(userId: number, role: string): Promise<any>{
        let url = '/api/auth/user/set_role';
        let arg = {user_id: userId, role: role};
        return fetchJsonPost(url, arg).then(value => {
            ra(()=>{
                let user = this.users.find(u => u.user_id == userId);
                if (user != null){
                    user.role = role;
                }
            });
        });
    }

    @action
    updateStatus(userId: number, status: UserStatus): Promise<any> {
        let url = '/api/auth/user/set_active';
        let arg = {user_id: userId, active: status == UserStatus.ACTIVE? 1 : 0};
        return fetchJsonPost(url, arg).then(value => {
            //console.log(`Update user ${userId} status to ${status}. Status: ${value}`);
            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].user_id == userId) {
                    this.users[i].is_active = (status == UserStatus.ACTIVE);
                    break;
                }
            }
            //this.users = this.users;
        });
    }
}