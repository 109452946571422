import {MapMouseEvent} from "maplibre-gl";
import {AddrContour, AddrPoint, ContourPoints, GeometryUtils, SimpleGeometryType} from "../../../helper/utils/GeometryUtils";
import {Position} from "geojson";
import {MBUtils} from "../../../helper/utils/MBUtils";
import {cloneDeep} from "lodash-es";
import {CustomEditGeometryTool} from "./CustomEditGeometryTool";
import {DelayRunOnce} from "../../../helper/utils/DelayRunOnce";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";

export class MidPointTool extends CustomEditGeometryTool {

    static DistanseToLine = 10;//минимальная дистанция от курсора до отрезка
    static MinDistanceToVertex = 12;//минимальная дистанция от вычисленной точки на линии до ближайшей вершины отрезка

    delay: DelayRunOnce;

    onInstall() {
        this.delay = new DelayRunOnce();
    }

    onUninstall() {
        this.delay.clearTimer();
    }

    private isActive(): boolean{
        return this.state?.events?.isEdit && this.state.events.isEdit() && !this.state?.movingPoint;
    }

    onMouseMove(e: MapMouseEvent & ToolEvent): void {
        if (!this.isActive()) return;

        let pos = MBUtils.pointToPosition(e.point);
        this.delay.runOnce(() => {
            this.calculateMidPoints(pos);
        }, 10);
    }

    private calculateMidPoints(mousePos: Position){
        let minDist: number = null;
        let afterAddr: AddrPoint = null;
        let midP: Position = null;

        let checkLine = (llp1: Position, llp2: Position, addr: AddrPoint)=>{
            let p1 = MBUtils.pointToPosition(this.map.project(MBUtils.positionToLL(llp1)));
            let p2 = MBUtils.pointToPosition(this.map.project(MBUtils.positionToLL(llp2)));

            if ((GeometryUtils.perpOtrezok(p1, p2, mousePos))){
                let mid = GeometryUtils.perpToLine(p1, p2, mousePos);
                if (mid != null){
                    let d = GeometryUtils.distance(mousePos, mid);
                    if ((minDist == null || d < minDist) &&
                        GeometryUtils.distance(p1, mid) > MidPointTool.MinDistanceToVertex &&
                        GeometryUtils.distance(p2, mid) > MidPointTool.MinDistanceToVertex) {
                        minDist = d;
                        afterAddr = cloneDeep(addr);
                        midP = mid;
                    }
                }
            }
        }
        let checkContour = (c: ContourPoints, addrContour: AddrContour) => {
            for(let i = 0; i < c.length - 1; i++){
                checkLine(c[i], c[i + 1], [addrContour[0], addrContour[1], i]);
            }
        }

        if (this.state.simpleGeometry != null) {
            let gia = this.state.simpleGeometry;
            gia.forEach((sg, index_g) => {
                if (sg.simple == SimpleGeometryType.Line) {
                    checkContour(sg.contour, [index_g, 0]);
                }
                if (sg.simple == SimpleGeometryType.Polygon) {
                    checkContour(sg.contour, [index_g, 0]);
                    checkLine(sg.contour[0], sg.contour[sg.contour.length - 1], [index_g, 0, sg.contour.length - 1]);
                    if (sg.holes != null) sg.holes.forEach((c, index_c) => {
                        checkContour(c, [index_g, index_c + 1]);
                        checkLine(c[0], c[c.length - 1], [index_g, index_c + 1, c.length - 1]);
                    });
                }
            });
        }
        if (minDist != null && minDist < MidPointTool.DistanseToLine){
            midP = MBUtils.llToPosition(this.map.unproject(MBUtils.positionToPoint(midP)));
            if (this.state.midPoint == null || !MBUtils.isEqualPoint(this.state.midPoint.point, midP)){
                this.state.midPoint = {point: midP, afterPointAddr: afterAddr};
                this.updateGeometry();
            }
        }else {
            if (this.state.midPoint != null){
                this.state.midPoint = null;
                this.updateGeometry();
            }
        }
    }
}