import {CustomStore} from "./CustomStore";
import {fetchBytesPost} from "../helper/utils/FetchUtils";

export class Api extends CustomStore{

    async downloadExcel(csv: string, delimterCol: string): Promise<Blob>{
        let p = {format: "ods", filename: "file", delim: delimterCol, content: csv};
        let arr = await fetchBytesPost(`/api/graph`, p);
        let blb = new Blob([arr], {type: 'application/octet-stream'});
        return blb;
    }

}