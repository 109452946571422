// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropDownRangeSelector-main {
  display: flex;
  column-gap: 10px;
}

.DropDownRangeSelector-divider {
  border-top: 1px solid #3E4751;
  width: 8px;
  align-self: center;
}

.DropDownRangeSelector-dropdown {
  width: 105px;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/DropDownRangeSelector/DropDownRangeSelector.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".DropDownRangeSelector-main {\n    display: flex;\n    column-gap: 10px;        \n}\n\n.DropDownRangeSelector-divider {\n    border-top: 1px solid #3E4751;\n    width: 8px;\n    align-self: center;\n}\n\n.DropDownRangeSelector-dropdown {\n    width: 105px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
