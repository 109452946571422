import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";

@observer
export class PersonalArea_StatPanel extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className="PersonalAreaComp-rightColBody style-4 overflowAutoY">
            <div className="PersonalAreaComp-lineKeyValue">
                <div className="PersonalAreaComp-lineKey">{store.trans["Period start date:"]}</div>
                <div className="PersonalAreaComp-lineValue">05.07.2022</div>
            </div>
            <div className="PersonalAreaComp-lineKeyValue">
                <div className="PersonalAreaComp-lineKey">{store.trans["Period end date:"]}</div>
                <div className="PersonalAreaComp-lineValue">04.07.2023</div>
            </div>
            <div className="PersonalAreaComp-lineHeader">{store.trans["Remaining for the period:"]}</div>
            <div className="PersonalAreaComp-lineKeyValue">
                <div className="PersonalAreaComp-lineKey">{store.trans["pictures"]}</div>
                <div className="PersonalAreaComp-lineValue PersonalAreaComp-lineValueYellow">286 из 300</div>
            </div>
            <div className="PersonalAreaComp-lineKeyValue">
                <div className="PersonalAreaComp-lineKey">{store.trans["fields"]}</div>
                <div className="PersonalAreaComp-lineValue PersonalAreaComp-lineValueYellow">217 из 300</div>
            </div>
            <div className="PersonalAreaComp-lineKeyValue">
                <div className="PersonalAreaComp-lineKey">{store.trans["charts"]}</div>
                <div className="PersonalAreaComp-lineValue PersonalAreaComp-lineValueRed">43 из 300</div>
            </div>
            <div className="PersonalAreaComp-lineKeyValue">
                <div className="PersonalAreaComp-lineKey">{store.trans["photos"]}</div>
                <div className="PersonalAreaComp-lineValue PersonalAreaComp-lineValueYellow">300 из 300</div>
            </div>
        </div>;
    }
}
