import {autorun} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {Utils} from "../../../helper/utils/Utils";
import {LeftPanelMode} from "../../SearchStore";
import {CustomStoreTool} from "../general/CustomStoreTool";

export class SoilGridsTool extends CustomStoreTool{
    static SolidGridLayerId = "class_soilGridsLayer";
    static SolidGridSourceId = "class_soilGridsSource";

    dispReplaceTile: IReactionDisposer = null;

    onInstall() {
        if (!this.store.soilGrids.active) return;
        this.installLayers();
        this.dispReplaceTile = autorun(() => {
            this.replaceTiles();
        });
    }

    onUninstall() {
        this.removeLayers();
        if (this.dispReplaceTile != null) {
            this.dispReplaceTile();
            this.dispReplaceTile = null;
        }
    }

    installLayers(){
        this.addLayers();
    }

    removeLayers(){
        if (this.map.getLayer(SoilGridsTool.SolidGridLayerId) != null) {
            this.map.removeLayer(SoilGridsTool.SolidGridLayerId);
        }
        if (this.map.getSource(SoilGridsTool.SolidGridSourceId) != null) {
            this.map.removeSource(SoilGridsTool.SolidGridSourceId);
        }
    }
    addLayers(){
        if (this.map.getSource(SoilGridsTool.SolidGridSourceId) == null) {
            this.map.addSource(SoilGridsTool.SolidGridSourceId, {
                type: 'raster',
                tiles: this.getTilesUrl(),
                scheme: "xyz",
            });
        }
        if (this.map.getLayer(SoilGridsTool.SolidGridLayerId) == null)
            this.addLayer({
                id: SoilGridsTool.SolidGridLayerId,
                source: SoilGridsTool.SolidGridSourceId,
                type: 'raster',
                layout:{
                    visibility: (this.getTilesUrl().length > 0)?"visible":"none"
                }
            });
    }

    replaceTiles(){
        this.removeLayers();
        this.addLayers();
    }

    getTilesUrl(): string[]{
        if (this.store.searchPanel.leftPanelMode != LeftPanelMode.soilGrid ||
            !this.store.soilGrids.active ||
            this.store.soilGrids.selectedSource == null) return [];
        let t = Utils.getAbsoluteUrlPath(this.store.soilGrids.selectedSource.layers[this.store.soilGrids.selectedSource.currentLayerIndex].tiles);
        if (Utils.isStringNotEmpty(t)) return [t];
        return [];
    }
}