import * as React from 'react';
import './SwitchText.scss';
import classNames from "classnames";
import autoBindReact from "auto-bind/react";
import {isFunction} from "lodash-es";

export interface ISwitchTextProps {
    text1: string;
    text2: string;
    check1: boolean;
    className?: string;
    backClassName?: string;
    backRightClassName? : string;
    backLeftClassName? : string;
    onClick?: (check1: boolean)=>any;
    disabledUnchecked?: boolean;
}

export class SwitchText extends React.PureComponent<ISwitchTextProps, undefined> {
    constructor(props: ISwitchTextProps) {
        super(props);
        autoBindReact(this);
    }
    onClick1(){
        if (isFunction(this.props.onClick)) this.props.onClick(true);
    }
    onClick2(){
        if (isFunction(this.props.onClick)) this.props.onClick(false);
    }

    render() {
        let check1 = this.props.check1;
        let backClass: string = (this.props.backClassName || "tswitcher-back") + " ";
        let rightBackClass: string = this.props.backRightClassName || "tswitcher-back-right";
        let leftBackClass: string = this.props.backLeftClassName || "tswitcher-back-left";
        backClass += check1? leftBackClass : rightBackClass;
        return <div className={classNames("tswitcher", this.props.className)}>
            <div className={classNames("tswitcher-item tswitcher-item-left",{
                "tswitcher-disabledItem": !check1 && this.props.disabledUnchecked,
                "tswitcher-checked": check1})}
                 onClick={this.onClick1}>{this.props.text1}</div>
            <div className={classNames("tswitcher-item tswitcher-item-right",{
                "tswitcher-disabledItem": check1 && this.props.disabledUnchecked,
                "tswitcher-checked": !check1})}
                 onClick={this.onClick2}>{this.props.text2}</div>
            <div className={backClass}/>
        </div>;
    }
}
