import {IndexByPointerSourceCustomSimpleStore} from "./IndexByPointerSourceCustomSimpleStore";
import {Utils} from "../../../helper/utils/Utils";
import {IGraphDayValue} from "../IndexByPointGraphStore";

export class IndexByPointerSourceSimpleHumidityEra5Store extends IndexByPointerSourceCustomSimpleStore{

    protected getRequest(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return (`/api/meteo/era5/time?from_date=${Utils.getDateStr(dateBegin)}&to_date=${Utils.getDateStr(dateEnd)}&lon=${gp.point.lng}&lat=${gp.point.lat}&data_type=rh`)
    }
    protected getValue(json: any): IGraphDayValue{
        let j: {date: string, rh: number} = json;
        return this.getGraphDataValue(j.date, j.rh);
    }
    protected getGraphDataInternal(): IGraphDayValue[]{
        return this.getData;
    }


    private get getData(): IGraphDayValue[]{
        let src: IGraphDayValue[];
        if (this.status == null) setImmediate(()=> { this.load() });
        src = this.data;
        return src;
    }

}