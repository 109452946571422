import {isString} from "lodash-es";
import {Utils} from "./Utils";

export class TextUtils{
    ///каждая строка из первого массива равна одной из строк второго массива
    static eachOneOfAny(eachStr: string[], oneOfAny: string[], caseSensitivity: boolean = false): boolean{
        let ok1 = true;
        eachStr.forEach(a =>{
            let ok2 = false;
            oneOfAny.forEach(b => {
                if (TextUtils.isEqual(a, b, caseSensitivity)) ok2 = true;
            });
            if (!ok2) ok1 = false;
        });
        return ok1;
    }
    static stringSortCompare(s1: string, s2: string, caseSensitivity: boolean = false): number{
        if (isString(s1) == isString(s2) == false) return 0;
        if (isString(s1) == false) return -1;
        if (isString(s2) == false) return 1;
        if (caseSensitivity){
            return s1.localeCompare(s2, "co", {sensitivity: "accent"});
        }
        return s1.localeCompare(s2);
    }
    static isEqual(s1: string, s2: string,  caseSensitive: boolean): boolean{
        if (caseSensitive) return s1 === s2;
        if (s1 == null && s2 == null) return true;
        if (s1 == null) return false;
        if (s2 == null) return false;
        return s1.toUpperCase() == s2.toUpperCase();
    }

    //умная сортировка текста. Сравнивает '1' и '10' правильно, или 'k-1' и 'k-20' тоже
    static smartTextSort(str1: string, str2: string, caseSensitivity: boolean = false): number{
        if (str1 == null) return -1;
        if (str2 == null) return 1;
        if (str1 == str2) return 0;
        str1 = str1.toString();
        str2 = str2.toString();
        function splitStringToNumberArray(text: string): {text: string, isNum: boolean}[] {
            const result: {text: string, isNum: boolean}[] = [];
            let currentNumber = '';
            let currentText = '';
            function saveResult(){
                if (currentNumber !== '') {
                    result.push({isNum: true, text: currentNumber});
                    currentNumber = '';
                }
                if (currentText !== ''){
                    result.push({isNum: false, text: currentText});
                    currentText = '';
                }
            }
            for (let i = 0; i < text.length; i++) {
                const char = text[i];
                if (!isNaN(parseInt(char))) {
                    if (currentText != '') saveResult();
                    currentNumber += char;
                } else {
                    if (currentNumber != '') saveResult();
                    currentText += char;
                }
            }
            saveResult();
            return result;
        }
        let arr1 = splitStringToNumberArray(str1);
        let arr2 = splitStringToNumberArray(str2);
        let minCnt = Math.min(arr1.length, arr2.length);
        for(let i = 0; i < minCnt; i++){
            if ((arr1[i].isNum === arr2[i].isNum) && arr2[i].isNum){
                let r = Utils.parseInt(arr1[i].text) - Utils.parseInt(arr2[i].text);
                if (r != 0) return r;
            }else
            if ((arr1[i].isNum === arr2[i].isNum) && !arr2[i].isNum){
                let r = TextUtils.stringSortCompare(arr1[i].text, arr2[i].text, caseSensitivity);
                if (r != 0) return r;
            }else{
                let r = TextUtils.stringSortCompare(arr1[i].text, arr2[i].text, caseSensitivity);
                return r;
            }
        }
        if (arr1.length > arr2.length) return 1;
        return -1;
    }

    static findSubstrings(str: string, substr: string, caseSensitive: boolean): string[]{
        if (substr.length == 0) return [str];
        let ustr = str;
        let usubstr = substr;
        if (!caseSensitive){
            ustr = ustr.toUpperCase();
            usubstr = usubstr.toUpperCase();
        }
        let i = 0;
        let r: string[] = [];
        while(true){
            let ni = ustr.indexOf(usubstr, i);
            if (ni >= 0){
                if (ni > i){
                    r.push(str.substr(i, ni - i));
                }
                r.push(str.substr(ni, substr.length));
                i = ni+usubstr.length;
            }else break;
        }
        if (i < str.length){
            r.push(str.substr(i));
        }
        return r;
    }

    static roundBytes(s: number): string{
        let str = "";
        if (s > 1024 * 1024 * 1024 * 1024 * 1.2) str = (Math.round(s / (1024 * 1024 * 1024 * 1024) * 100) / 100).toString() + " Tb";else
        if (s > 1024 * 1024 * 1024 * 1.2) str = (Math.round(s / (1024 * 1024 * 1024) * 100) / 100).toString() + " Gb"; else
        if (s > 1024 * 1024 * 1.2) str = (Math.round(s / (1024 * 1024) * 100) / 100).toString() + " Mb"; else
        if (s > 1024 * 1.2) str = (Math.round(s / (1024) * 100) / 100).toString() + " Kb"; else
            str = Math.round(s).toString() + " b";
        return str;
    }

    // Разбор "Hello [world], [how] are [you] doing?" => ['Hello ', '[world]', ', ', '[how]', ' are ', '[you]', ' doing?']
    static extractSubstringsSquareBrackets(str: string): (string | undefined)[] {
        const regex = /\[(.*?)\]/g;
        const substrings = [];
        let match;
        let lastIndex = 0;

        while ((match = regex.exec(str))) {
            const startIndex = match.index;
            const endIndex = regex.lastIndex - 1;

            if (startIndex > lastIndex) {
                substrings.push(str.substring(lastIndex, startIndex));
            }

            substrings.push(match[0]);
            lastIndex = endIndex + 1;
        }

        if (lastIndex < str.length) {
            substrings.push(str.substring(lastIndex));
        }

        return substrings;
    }
}