import {ContainerTools, CustomTool} from "../general/ContainerTools";
import * as mapboxgl from "maplibre-gl";
import {MapboxGeoJSONFeature, PointLike} from "maplibre-gl";
import {isObjectLike, isUndefined} from "lodash-es";
import {FieldToolController} from "../../agro/fieldForm/FieldToolController";

export class FieldClickTool extends CustomTool{
    constructor(container: ContainerTools, fieldToolController: FieldToolController, name: string) {
        super(container, name);
        this.fieldToolController = fieldToolController;
    }
    fieldToolController: FieldToolController;

    clickTo_field_id(point: mapboxgl.Point): number{
        let bbox: [PointLike, PointLike] = [
            new mapboxgl.Point(point.x - 5, point.y - 5),
            new mapboxgl.Point(point.x + 5, point.y + 5)
        ];

        if (!this.fieldToolController.fieldLayerTool.internalInstalled) return null;

        let curLayers: string[] = [
            this.fieldToolController.agroEditorManager.viewGeometryLayersTool.GEOMETRY_LINES_LAYER_ID,
            this.fieldToolController.agroEditorManager.viewGeometryLayersTool.GEOMETRY_FILL_LAYER_ID,
            this.fieldToolController.agroEditorManager.viewGeometryLayersTool.GEOMETRY_VERTEX_LAYER_ID];
        let queryLayers = [
            ...this.fieldToolController.fieldLayerTool.getLayerStyles().fields.map(st => st.id),
            ...(this.fieldToolController.fieldLayerTool.getLayerStyles().selected || []).map(st => st.id),
            ...curLayers
        ];
        let res: MapboxGeoJSONFeature[] = this.map.queryRenderedFeatures(bbox,
            {layers: queryLayers});
        if (res.length == 0) return null;
        //ищем редактируемый объект
        let isClickToCur = false;
        let fieldId = null;
        let store = this.fieldToolController.root;
        res.forEach(a => {
            if (a.layer != null && curLayers.includes(a.layer.id)){
                isClickToCur = true;
            }
        });
        if (isClickToCur) return this.fieldToolController.editable_field_id;
        res = res.filter(a =>{
            return (isObjectLike(a.properties) && !isUndefined(a.properties[store.agro2.projectInfo.fieldName_id]));
        });
        if (res.length == 0) return null;
        fieldId = res[0].properties[store.agro2.projectInfo.fieldName_id];
        return fieldId;
    }


}

