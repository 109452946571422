// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmModalComp-body {
  margin-top: "15px";
  margin-bottom: "25px";
}`, "",{"version":3,"sources":["webpack://./app/components/Common/Forms/ConfirmModalComp.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF","sourcesContent":[".ConfirmModalComp-body {\r\n  margin-top: \"15px\";\r\n  margin-bottom: \"25px\";\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
