import * as React from 'react';
import {CustomSvg} from "./CustomSvg";

export class DownloadSvg extends CustomSvg{

    render() {
        return <svg width="14" height="14" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()}>
            <path d="M7.0001 12C7.3001 12 7.5001 11.9 7.7001 11.7L13.4001 6L12.0001 4.6L8.0001 8.6V0H6.0001V8.6L2.0001 4.6L0.600098 6L6.3001 11.7C6.5001 11.9 6.7001 12 7.0001 12Z"/>
            <path d="M14 14H0V16H14V14Z"/>
        </svg>;
    }
}
