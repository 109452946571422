import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './ProjSettingPageGeneral.scss';
import autoBindReact from "auto-bind/react";
import AceEditor from "react-ace";
import {action, observable} from "mobx";
import {ra} from "../../../../helper/utils/mobxUtils";

import {CheckBoxInFrame} from "./CheckBoxInFrame";
import JSON5 from "json5";


import "ace-builds/src-noconflict/mode-json5";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/snippets/json5";
import "ace-builds/src-noconflict/ext-language_tools";




import {KeyIcon} from "./KeyIcon";
//import 'brace/theme/tomorrow';
//import 'brace/mode/json';
//import 'brace/snippets/json';
//import 'brace/ext/language_tools';



@observer
export class ProjSettingPageStyle extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.jsonStyleText = this.props.store.agro2.styles.styleJson5Text;
        this.refEditor = React.createRef();
    }
    refEditor: React.RefObject<AceEditor>;
    @observable
    jsonStyleText: string = "";

    @action
    onChange(value: string, event?: any){
        this.jsonStyleText = value;
    }

    @action
    async onClickApply(){
        try {
            JSON5.parse(this.jsonStyleText);
        }catch (e){
            let lineNumber = e.lineNumber;
            let columnNumber = e.columnNumber;
            let editor = this.refEditor.current.editor;

            editor.resize(true);


            //editor.scrollToLine(lineNumber, true, true, null);

            editor.gotoLine(lineNumber, columnNumber, true);
            editor.focus();
            this.props.store.addError(e);
            return;
            /*
            this.annotations.push({
                text: e.message,
                row: e.lineNumber,
                column: e.columnNumber,
                type: "error"
            });
            this.markers.push(({startRow: e.lineNumber, startCol: 0, type: "fullLine"} as any));
             */
        }
        try {
            JSON5.parse(this.jsonStyleText);
            this.props.store.agro2.styles.styleTextApply(this.jsonStyleText);
            if (!this.props.store.agro2.styles.saveStyleInSession){
                await this.props.store.agro2.styles.saveStyleToServer();
            }
            ra(()=>{
                this.props.store.agro2.projectSettings.hideWindow();
            });
        }catch (e) {
            this.props.store.addError(e);
        }
    }


    @action
    onClickSwitcher(){
        this.props.store.agro2.styles.saveStyleInSession = !this.props.store.agro2.styles.saveStyleInSession;
    }

    render() {
        let store = this.props.store;
        return <div className="ProjSettingPageStyles-main">
            <div className="ProjSettingPageStyles-title">
                <span style={{marginRight: "15px"}}>{store.trans["To quickly open this window, click"]}</span>
                <KeyIcon text="Shift" store={store} />
                <div style={{margin:"0 12px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <rect x="4" width="2" height="10" rx="1" fill="#6A7886"/>
                        <rect y="6" width="2" height="10" rx="1" transform="rotate(-90 0 6)" fill="#6A7886"/>
                    </svg>
                </div>
                {/* <span style={{margin: "0 15px"}}>{store.trans["or"]}</span> */}
                <KeyIcon text=" Alt/Option" store={store} />
                <div style={{margin:"0 12px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <rect x="4" width="2" height="10" rx="1" fill="#6A7886"/>
                        <rect y="6" width="2" height="10" rx="1" transform="rotate(-90 0 6)" fill="#6A7886"/>
                    </svg>
                </div>
                <KeyIcon className="KeyIcon_short" text="V" store={store} />
                 {/* <span style={{margin: "0 15px"}}>на Mac OS</span> */}
            </div>
            <div className="flex-stretch-item relative">
                    <AceEditor
                        enableBasicAutocompletion={true}
                        enableLiveAutocompletion={true}
                        enableSnippets={true}
                        highlightActiveLine={true}

                        //showGutter={true}
                        name="ProjSettingPageGeneral-jsonEditor"
                        width="100%"
                        height="100%"
                        mode="json5"
                        //showPrintMargin={false}

                        theme="monokai"
                        editorProps={{ $blockScrolling: true }}

                        setOptions={{
                            useWorker: true
                        }}
                        ref={this.refEditor}

                        //setOptions={{ useWorker: true  }}
                        onChange={this.onChange}

                        value={this.jsonStyleText} />
                {/*<div className="ProjSettingPageStyles-divEye blue-border-onhover gray-background-onhover pointer" onClick={this.onClickHide}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_4346_4015)">
                            <path d="M8.00013 14C12.7071 14 15.7441 8.716 15.8711 8.492C16.0421 8.188 16.0431 7.816 15.8721 7.512C15.7461 7.287 12.7311 2 8.00013 2C3.24513 2 0.251127 7.289 0.126127 7.514C-0.0428725 7.817 -0.0418725 8.186 0.128127 8.489C0.254128 8.713 3.26913 14 8.00013 14ZM8.00013 4C10.8391 4 13.0361 6.835 13.8181 8C13.0341 9.166 10.8371 12 8.00013 12C5.15913 12 2.96213 9.162 2.18113 7.999C2.95813 6.835 5.14613 4 8.00013 4Z" fill="#C5C5C5"/>
                            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="#C5C5C5"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4346_4015">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>*/}
            </div>
            <div className="ProjSettingPageStyles-footer">
                <CheckBoxInFrame className="ProjSettingPageStyles-footerText" state={this.props.store.agro2.styles.saveStyleInSession} onChange={this.onClickSwitcher}>
                    <span className="text-next">{store.trans["Save for current session only"]}</span>
                </CheckBoxInFrame>
                <div className="flex-stretch-item" />
                <button className="large-button left-sidebar-cancel ProjSettingPageStyles-footerButton button-jump" onClick={this.onClickApply}>
                    <span>{store.trans.Default}</span>
                </button>
                <button className="large-button left-sidebar-active ProjSettingPageStyles-footerButton button-jump text-next" onClick={this.onClickApply}>
                    <span>{store.trans.Save}</span>
                </button>
            </div>
        </div>;
    }
}