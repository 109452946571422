import {CustomStore} from "../CustomStore";
import {observable} from "mobx";

export class FarmItemStore extends CustomStore {
    class(): string {return "FarmItemStore";}

    //@save
    @observable
    farm_id: number;
    //@save
    @observable
    farm_name: string;
    //@save
    @observable
    region_id: number;
    //@save
    @observable
    entity: string;
    //@save
    @observable
    contract_year: number;
    //@save
    @observable
    field_cnt: number;
    //@save
    @observable
    selected: boolean = false;
    //@save
    @observable
    field_area_sys: number;
    //@save
    @observable
    photo_cnt: number;
}