// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A2LineButton {
  width: 30px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/A2FieldList/A2LineButton.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".A2LineButton{\r\n  width: 30px;\r\n  height: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
