import * as React from 'react';

export interface IA2FieldEditorPenSvgProps {
}

export class A2FieldEditorPenSvg extends React.PureComponent<IA2FieldEditorPenSvgProps, undefined> {


    render() {
        return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" className="A2FieldEditorPenSvg-svg" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.0625 2.1875L0.1875 7.0625C0.0625 7.1875 0 7.3125 0 7.5V9.375C0 9.75 0.25 10 0.625 10H2.5C2.6875 10 2.8125 9.9375 2.9375 9.8125L7.8125 4.9375L5.0625 2.1875Z" fill="#6B707B"/>
            <path d="M9.8125 2.0625L7.9375 0.1875C7.6875 -0.0625 7.3125 -0.0625 7.0625 0.1875L5.9375 1.3125L8.6875 4.0625L9.8125 2.9375C10.0625 2.6875 10.0625 2.3125 9.8125 2.0625Z" fill="#6B707B"/>
        </svg>;
    }
}
