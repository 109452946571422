import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {ShowRoom2Store} from "./ShowRoom2Store";

export class ShowRoom2FilterMultiprojectStore extends CustomStore<ShowRoom2Store>{
    get showRoom(): ShowRoom2Store{
        return this.root.dataShowRoomStore;
    }

    @observable
    seasonsFilters: Set<number> = new Set<number>();
    @observable
    cropFilters: Set<number> = new Set<number>();

    get otherCropChecked(): boolean{
        let checked = false;
        this.cropFilters.forEach(a => {
            if (!this.showRoom.cropDataShort.has(a)){
                checked = true;
            }
        });
        return checked;
    }
    set otherCropChecked(value: boolean){
        if (value){
            this.showRoom.cropDataAll.forEach(a => {
                if (!this.showRoom.cropDataShort.has(a.id)){
                    this.cropFilters.add(a.id);
                }
            });
        }else{
            this.showRoom.cropDataAll.forEach(a => {
                if (!this.showRoom.cropDataShort.has(a.id)){
                    this.cropFilters.delete(a.id);
                }
            });
        }
    }

}