import {SearchItemStore} from "./SearchItemStore";
import {ISearchPropertiesRest, SceneUiType, SearchSceneSourceType} from "./SearchStore";
import {CustomStore} from "./CustomStore";
import {MapComp} from "../components/panels/Map/MapComp";
import {Utils} from "../helper/utils/Utils";
import {AnySourceData, RasterSource} from "maplibre-gl";
import {Satellite, SatEnum2} from "../helper/utils/satellliteDic";
import {ProductSettingStore} from "./productSetting/ProductSettingStore";
import {Sentinel1Mode} from "./SearchParamsS1Store";
import {ProductStyleType} from "./config/ConfigStore";
import {LoadStatus} from "../helper/structs/LoadStatus";
import {observable} from "mobx";
import {isArray} from "lodash-es";
import urlJoin from "url-join";
import { save } from "./PermalinkDecor";

interface IMapSceneLayerOrder{
    sceneItem: SearchItemStore;
    uiType: SceneUiType;
}
interface IMapSceneLayerLink{
    sceneId: string;
    layerName: string;
    sourceName: string;
    uiType: SceneUiType;
    uniqHash: string;
    sourceJson: AnySourceData;
}
export class MapScenesStore extends CustomStore {
    class(): string {
        return "MapStore";
    }
    //глобальное отключение видимости "суперслоя" избранных
    @observable @save favoriteScenesVisible: boolean = true;



    getQuicklookSourceJson(meta: ISearchPropertiesRest): AnySourceData {
        let imgUrl = `/api/quicklookimage?sceneid=${encodeURIComponent(meta.scene_id)}&ver=${encodeURIComponent(meta.version)}&source=${encodeURIComponent(meta.source)}`;
        let store = this.root;
        if (store.searchPanel.sourceType == SearchSceneSourceType.sentinel5) imgUrl += "&product=" + encodeURIComponent(store.map.productInfo.productsS5.activeCodeCurrent.productCode);
        let coos = [
            [meta.tl_x, meta.tl_y],
            [meta.tr_x, meta.tr_y],
            [meta.br_x, meta.br_y],
            [meta.bl_x, meta.bl_y]
        ];
        return {
            type: 'image', url: imgUrl,
            coordinates: coos
        };
    }

    getBaseTilesSource(meta: ISearchPropertiesRest): string{
        let tilesUrl = `/api/tileraster?z={z}&x={x}&y={y}&sceneid=${meta.scene_id}&source=${meta.source}`;
        if (meta.scene_type == SatEnum2.S5L2A) {
            if (meta.s5_date_end != null) {
                tilesUrl = `/api/s5/tile?z={z}&x={x}&y={y}&method=${meta.composite_method}&beg=${Utils.getDateStr(new Date(meta.s5_date_begin))}` +
                    `&end=${Utils.getDateStr(new Date(meta.s5_date_end))}`;
            }else{
                tilesUrl = `/api/s5/tile?z={z}&x={x}&y={y}&date=${meta.acqdate}`;
            }
        }
        return tilesUrl;
    }

    getTileUrl(meta: ISearchPropertiesRest, sceneType: SceneUiType): string{
        //по умолчанию ничего не добавляем к запроосу
        let st = '';
        let prod_code: string;
        let productInfo = this.root.map.productInfo;
        //если Local и режим в "Натуральных цветах"
        let prod: ProductSettingStore = null;
        if (meta.satellite == Satellite["Sentinel-1"]) {
            if (meta.mode == Sentinel1Mode.IW){
                prod = productInfo.productsS1_IW.getActiveCodeByUiType(sceneType).currentProductStore;
                prod_code = productInfo.productsS1_IW.getActiveCodeByUiType(sceneType).productCode;
            }else{
                prod = productInfo.productsS1_EW.getActiveCodeByUiType(sceneType).currentProductStore;
                prod_code = productInfo.productsS1_EW.getActiveCodeByUiType(sceneType).productCode;
            }
        }else
        if (meta.scene_type == SatEnum2.S5L2A) {
            prod = productInfo.productsS5.getActiveCodeByUiType(sceneType).currentProductStore;
            prod_code = productInfo.productsS5.getActiveCodeByUiType(sceneType).productCode;
        } else {
            if (!meta.tiles_exists){
                prod_code = productInfo.productsPassive.getActiveCodeByUiType(sceneType).products[0].prod_name;
                prod = productInfo.productsPassive.getActiveCodeByUiType(sceneType).currentProductStore;//.productSetStore.getProductByProdName(prod_code);
            }else {
                prod = productInfo.productsPassive.getActiveCodeByUiType(sceneType).currentProductStore;
                prod_code = productInfo.productsPassive.getActiveCodeByUiType(sceneType).productCode;
            }
        }
        let stObj = prod.getStyles();
        if (stObj != null) st = "&style="+encodeURIComponent(JSON.stringify(stObj));

        let store = this.root;
        let cutline: any = {};
        let filter: any = {};
        cutline.filter = filter;
        if (this.root.searchPanel.cutFieldsByBorder) {
            let so = this.root.map.searchObject;
            if (so.isNotEmpty){
                if (so.geometry_save_status == LoadStatus.ready){
                    st = st + '&cutline='+encodeURIComponent(JSON.stringify({"geom_id": so.geom_id_saved}));
                }
                if (so.geometry_save_status == null){
                    so.saveGeometryToServer();
                }
            }
        }



        let tilesUrl: string;
        tilesUrl = this.getBaseTilesSource(meta)+`&product=${prod_code}${st}`;

        if (meta.scene_type == SatEnum2.S5L2A){
            tilesUrl += `&min_quality=${store.map.productInfo.mask_quality}`;
        }
        return tilesUrl;
    }
    getTilesSource(meta: ISearchPropertiesRest, sceneType: SceneUiType): RasterSource{
        let urls: string[];
        if (isArray(this.root.config.tile_domains) && this.root.config.tile_domains.length > 0){
            urls = this.root.config.tile_domains.map(a => urlJoin(a, this.getTileUrl(meta,sceneType)));
        }else{
            urls = [this.getTileUrl(meta,sceneType)];
        }
        let bBox = [Math.min(meta.bl_x, meta.tl_x), Math.min(meta.bl_y, meta.br_y),
            Math.max(meta.tr_x, meta.br_x), Math.max(meta.tr_y, meta.tl_y)];

        return  {
            type: 'raster', tiles: urls, bounds: bBox,
            tileSize: MapComp.DEFAULT_TILE_SIZE, maxzoom : MapComp.MAX_ZOOM
        };
    }

}