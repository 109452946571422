import * as React from 'react';
import {observer} from "mobx-react";
import autoBindReact from "auto-bind/react";
import {isFunction} from "lodash-es";
import classNames from "classnames";
import {AhoCheckbox3StateType} from "../agroAhoStore";


export interface IAgroAhoRectCheckbox3StateSvgProps {
    onClick?: (e: any)=>void,
    className?: string,
    disabled?: boolean,
    state?: AhoCheckbox3StateType;
}

@observer
export class AgroAhoRectCheckbox3StateSvg extends React.PureComponent<IAgroAhoRectCheckbox3StateSvgProps, undefined> {
    constructor(props: IAgroAhoRectCheckbox3StateSvgProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(e: any){
        if (this.props.disabled === true) return;
        if (isFunction(this.props.onClick)) this.props.onClick(e);
    }

    render() {
        return <React.Fragment>
            {this.props.state == AhoCheckbox3StateType.Checked &&
            <svg onClick={this.onClick} className={classNames(this.props.className)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="16" rx="3" stroke="#4DB6BC" strokeWidth="2"/>
                <rect x="4" y="4" width="10" height="10" rx="2" fill="#4DB6BC"/>
            </svg>                }
            {this.props.state == AhoCheckbox3StateType.NoChecked &&
            <svg onClick={this.onClick} className={classNames(this.props.className)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="16" rx="3" stroke="#454C52" strokeWidth="2"/>
            </svg>}
            {this.props.state == AhoCheckbox3StateType.Indeterminate &&
            <svg onClick={this.onClick} className={classNames(this.props.className)}
                width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="0.996094" width="16" height="16" rx="3" stroke="#4DB6BC" strokeWidth="2"/>
                <rect x="4" y="8" width="10" height="2" rx="1" fill="#4DB6BC"/>
            </svg>}
        </React.Fragment>;
    }
}
