import {cloneDeep} from "lodash-es";
import {Geometry} from "geojson";
import {AddrPoint} from "./GeometryUtils";

export class GeoAddrUtils{
    static incPointAddr2(addr: AddrPoint): AddrPoint{
        let r: AddrPoint = [...addr];
        r[2] = r[2] + 1;
        return r;
    }
    static decPointAddr(addr: number[]): number[]{
        let r = cloneDeep(addr);
        r[r.length - 1] = r[r.length - 1] - 1;
        return r;
    }
    static getAddrFirstPoint(g: Geometry, addr: number[]):number[]{
        let r = cloneDeep(addr);
        r[r.length - 1] = 0;
        return r;
    }
    static getAddrLastPoint(g: Geometry, addr: number[]):number[]{
        let r = cloneDeep(addr);
        if (g.type == "Polygon"){
            r[1] = g.coordinates[r[0]].length - 1;
        }
        if (g.type == "MultiPolygon"){
            r[2] = g.coordinates[r[0]][r[1]].length - 1;
        }
        if (g.type == "LineString"){
            if (r == null || r.length != 0) r = [0];
            r[0] = g.coordinates.length - 1;
        }
        return r;
    }
    static isEqualAddr(addr1: number[], addr2: number[]): boolean{
        if (addr1.length != addr2.length) return false;
        for(let i = 0; i < addr1.length; i++){
            if (addr1[i] != addr2[i]) return false;
        }
        return true;
    }
    static isFirstPoint(g: Geometry, addr: number[]): boolean{
        return (addr[addr.length - 1] == 0);
    }
    static isLastPoint(g: Geometry, addr: number[]): boolean{
        if (g.type == "MultiPolygon"){
            if (addr.length != 3) return false;
            if (addr[0] >= g.coordinates.length) return false;
            if (addr[1] >= g.coordinates[addr[0]].length) return false;
            return g.coordinates[addr[0]][addr[1]].length == addr[2] + 1;
        }
        if (g.type == "Polygon"){
            if (addr.length != 2) return false;
            if (addr[0] >= g.coordinates.length) return false;
            return g.coordinates[addr[0]].length == addr[1] + 1;
        }
        if (g.type == "LineString"){
            return g.coordinates.length == addr[0] - 1;
        }
        return false;
    }
}