import {CustomStore} from "../CustomStore";
import {observable} from "mobx";
import {fetchJson} from "../../helper/utils/FetchUtils";
import {Utils} from "../../helper/utils/Utils";
import {IProjectsProjResponse} from "../user/UserStore";

export class AgroProjectFormStore extends CustomStore{
    class(): string {return "AgroProjectFormStore";}

    @observable
    showForm: boolean = false;
    @observable
    editMode: boolean = false;
    @observable
    company: string = "";
    @observable
    title: string = "";
    @observable
    projectName: string = "";
    //@observable


    reset(){
        this.title = '';
        this.company = '';
    }

    toEdit(project: IProjectsProjResponse){
        this.title = project.proj_title;
        this.company = project.proj_entity;
        this.projectName = project.proj_name;
    }

    async sendUpdate(): Promise<void>{
        let ids = await fetchJson(`/api/agro2/projects/${this.projectName}/modify`, {
            method: "POST",
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: Utils.queryEncodeParams({proj_title:this.title, proj_entity:this.company, proj_type: "farming"})
        });
    }
    async sendCreate(): Promise<IProjectsProjResponse>{
        let r = await fetchJson('/api/agro2/create', {
                method: "POST",
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: Utils.queryEncodeParams({proj_title:this.title, proj_entity:this.company, proj_type: "farming"})
            });
        return r.project;
    }
    /*
создание агро-проекта
http://dev-class.ctrl2go.com/agro/create?proj_title=test01&proj_entity=OAO_Test&proj_type=farming
возвращается proj_name

изменение агро-проекта
http://dev-class.ctrl2go.com/agro/projects/<proj_name>/modify?proj_title=test011&proj_entity=OAO_Test011

удаление агро-проекта
http://dev-class.ctrl2go.com/agro/projects/<proj_name>/delete        * */
}