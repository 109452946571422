import React from 'react';
import autoBindReact from 'auto-bind/react';
import { action, observable } from 'mobx';
import tinycolor from 'tinycolor2';
import { HexColorPicker } from "react-colorful";
import { CloseSvg } from '../../../icons/MiniIcon/CloseSvg';

export interface IColorPickerProps {
    color: string;
    opacity: number;
    onChange? : (color: string) => void;
    onClose? : (e: any) => void;
    setColor : (color: string) => void;
    setOpacity : (opacity: number) => void;
}

export class ColorPicker extends React.Component<IColorPickerProps> {
    @observable
    state = {
        color: this.props.color,
        opacity: Math.round(100 * this.props.opacity) + '%'
    }
    
    constructor (props: IColorPickerProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onColorChange(color: any) {
        this.state.color = color;
        this.props.onChange(color);
    }

    @action
    onHexChanging(e: any) {
        this.setState({color: e.currentTarget.value});
    }

    @action
    onHexChange(e: any) {
        let hex = e.currentTarget.value;
        let color = tinycolor(hex);
        if (color.isValid())
            this.props.setColor(color.toHexString());
        else
            this.setState({color: this.props.color});
    }

    @action
    onHexKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        let color = tinycolor(this.state.color);
        if (color.isValid())
            this.props.setColor(color.toHexString());
        else
            this.setState({color: this.props.color});
    }

    @action
    onOpacityChanging(e: any) {
        this.setState({opacity: e.currentTarget.value});
    }

    setOpacity(opacity: number) {
        this.setState({opacity: Math.round(100 * opacity) + '%'});
        this.props.setOpacity(opacity);
    }

    @action
    onOpacityChange(e: any) {
        let op = e.currentTarget.value;
        op = op.replaceAll('%', '');
        if (! isNaN(op) && op > 0 && op <= 100) 
            this.setOpacity(op / 100);
        else
            this.setState({opacity: Math.round(100 * this.props.opacity) + '%'});
    }

    @action
    onOpacityKeyPressed(e: any) {
        if(e.keyCode != 13) return;        
        let op = (this.state.opacity as any).replaceAll('%', '');
        if (! isNaN(op) && op > 0 && op <= 100)
            this.setOpacity(op / 100);
        else
            this.setState({opacity: Math.round(100 * this.props.opacity) + '%'});
    }

    render() {
        return <div className='LayerStyleComp-color-color-picker'>
            <div className='LayerStyleComp-color-color-picker-header'>
                <span>Color picker</span>
                <div onClick={this.props.onClose} className="pointer">
                    <CloseSvg/>
                </div>
            </div>
            <div className='LayerStyleComp-color-color-picker-palette'>
                <HexColorPicker color={this.props.color} 
                    onChange={this.onColorChange} />
            </div>
            <div className='LayerStyleComp-color-color-picker-editor'>
                <span className='LayerStyleComp-color-color-picker-editor-span'>Hex</span>
                <div className='LayerStyleComp-color-color-picker-editor-inputs'>
                    <input type='text' className='LayerStyleComp-color-color-picker-editor-hex'
                        value={this.state.color.toUpperCase()} 
                        onChange={this.onHexChanging} onBlur={this.onHexChange}
                        onKeyDown={this.onHexKeyPressed}/>
                    <input type='text' className='LayerStyleComp-color-color-picker-editor-opacity'
                        value={this.state.opacity} 
                        maxLength={4} pattern="[0-9]{1,3}"
                        onChange={this.onOpacityChanging} onBlur={this.onOpacityChange}
                        onKeyDown={this.onOpacityKeyPressed}/>
                </div>
            </div>
        </div>;
    }
}

