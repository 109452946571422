import React, { useRef, useState } from "react";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import { MeasuringStatus } from "../../../../helper/structs/MeasuringStatus";
import classNames from "classnames";
import './SurveyPointsComp.scss';
import { CenterToSvg, SmallCenterToSvg } from "../../Left/Agro/Buttons/CenterToSvg";
import bbox from "@turf/bbox";
import { RemoveAllSvg } from "../../../icons/MiniIcon/RemoveAllSvg";
import { ContextMenuCommonComp } from "../../../Common/ContextMenuCommonComp";
import { ConfirmFormComp } from "../../../Common/ConfirmFormComp";
import CompactListSvg from "../../../icons/MiniIcon/CompactListSvg";
import { TrashSvg } from "../../Left/Agro/Buttons/TrashSvg";
import { PenSvg } from "../../Left/Agro/Buttons/PenSvg";
import { Feature, Point } from "geojson";
import { TranslateUtils } from "../../../../helper/lang/TranslateUtils";
import InformationIconComp from "../../../Common/InformationIconComp";
import EditPenSvg from "../../../icons/EditPenSvg";

export interface IFavSceneTitleCompProps extends IStoreProps {
    isActive: boolean;
    isOpen: boolean;
    points: GeoJSON.FeatureCollection;
}

const SurveyPointsComp = (props: IFavSceneTitleCompProps) => {
    let tr = props.store.trans;
    let [confirmDeletePressed, setConfirmDeletePressed] = useState(false);

    const onCenterToAll = () => {
        if (props.points.features.length == 0) return;
        props.store.map.zoomToBBox(bbox(props.points) as [number, number, number, number]);
    }

    const onCenterToPoint = (f: GeoJSON.Feature)  => {
        if (! f) return;
        let [lon, lat] = (f.geometry as Point).coordinates;
        props.store.map.toPosition(lat, lon);
    }

    const onClosePopup = () => {
        setConfirmDeletePressed(false);
    }

    const onDeleteAll = () => {
        props.store.map.superTools.surveyPointsTool.remove();
        setConfirmDeletePressed(false);
    }

    const onDeletePoint = (f: Feature) => {
        props.store.map.superTools.surveyPointsTool.remove(f);
    }

    const onCompact = () => {
        props.store.map.superTools.surveyPointsTool.compactNumbers();
    }

    const onExport = () => {
        props.store.map.surveyPoints.showExport = true;
    }

    const onEditPoint = (f : Feature) => {
        props.store.map.surveyPoints.editingPoint = f;
    }

    const onClickEditMode = () => {
        let map = props.store.map;
        if (map.measuringStatus != MeasuringStatus.surveyPoint) {
            map.setMeasuringStatus(MeasuringStatus.surveyPoint);
        }else{
            map.resetMeasuringStatus();
        }
    }

    let items: any[] = [];
    let fts = props.points.features;
    fts.forEach(f => {
        items.push(<div className="SurveyPointsComp-point-div" key={f.properties.num}>
            <div className="SurveyPointsComp-point-text">
                <span className="SurveyPointsComp-point-num">{f.properties.num}</span>
                <span className="SurveyPointsComp-point-comment">{f.properties.comment}</span>
            </div>
            <div className={classNames("SurveyPointsComp-point-buttons")}>
                <div className="SurveyPointsComp-button white-fill red_fill_onhover" onClick={() => onDeletePoint(f)}>
                    <TrashSvg />
                </div>
                <div className="SurveyPointsComp-button white_svg blue_svg_onhover" onClick={() => onEditPoint(f)}>
                    <PenSvg/>
                </div>
                <div className="SurveyPointsComp-button white_svg blue_svg_onhover" onClick={() => onCenterToPoint(f)}>
                    <SmallCenterToSvg/>
                </div>
            </div>
        </div>);
    });

    return <div className={classNames("SurveyPointsComp-main qtransit", {
        "width0": !props.isOpen})}>
        <div className="SurveyPointsComp-header">
            <span className="SurveyPointsComp-header-span">{tr["Inspection points"]}</span>
            <div className={classNames("SurveyPointsComp-header-buttons", {"opacity30": fts.length == 0})}>
                <div className={classNames("white_svg", {"blue_svg_onhover pointer": fts.length > 0})} onClick= {onCompact}>
                    <CompactListSvg />
                </div>
                <div className={classNames("white_svg", {"blue_svg_onhover pointer": fts.length > 0})} onClick= {onCenterToAll}>
                    <CenterToSvg />
                </div>
                <div className={classNames("red-svg", {"pointer": fts.length > 0})} onClick= {() => {if (fts.length > 0) setConfirmDeletePressed(true)}}>
                    <RemoveAllSvg/>
                    {confirmDeletePressed && <ContextMenuCommonComp
                                    indentVertical={5}
                                    autoCornerWidth={125}
                                    autoCornerHeight={155}
                                    onClose={onClosePopup}
                                    className="SurveyPointsComp-delete-popup">
                    
                                    <ConfirmFormComp className=""
                                                     text={tr["Are you sure to delete all points?"]}>
                                        <div className="flex-columns">
                                            <div className="red underline-link AgroConfirmDeleteFarmComp-button" 
                                                onClick={onDeleteAll}>{tr.Delete}</div>
                                            <div className="white text-next-2 underline-link AgroConfirmDeleteFarmComp-button" 
                                                onClick={onClosePopup}>{tr.Cancel}</div>
                                        </div>
                                    </ConfirmFormComp>
                                </ContextMenuCommonComp>}
                </div>
            </div>
        </div>
        <div className="SurveyPointsComp-header2">
            <div className="SurveyPointsComp-info-div">
                <InformationIconComp hintClass="SurveyPointsComp-info" hint={tr["Survey points information"]}/>
                {fts.length > 0 && <span className="SurveyPointsComp-footer-count">{TranslateUtils.format(tr["Points: {0}"], fts.length)}</span>}
            </div>
            <div className={classNames("SurveyPointsComp-add-button", 
            {"active": props.isActive, "notactive": !props.isActive})} onClick={onClickEditMode}>
            <EditPenSvg isActive={props.isActive}/>        
            </div>
        </div>
        <div className="SurveyPointsComp-body style-4">
            {items}
        </div>
        <div className="SurveyPointsComp-footer">
            <div className="SurveyPointsComp-footer-panel">
                <button onClick={onExport} disabled={fts.length == 0}
                        className={classNames("large-button left-sidebar-active button-jump popup-exp-2button-button text-next-2")}>
                    {tr.ExportTo}
                </button>
            </div>
        </div>
    </div>
}

export default SurveyPointsComp;