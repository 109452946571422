import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import "./LegendDataShowRoom2Comp.scss";
import {IShowRoom2Crop} from "../../../store/dataShowroom2/ShowRoom2Store";
import {ColorHelper} from "../../../helper/utils/ColorHelper";
import {LegendCommonComp} from "./LegendCommonComp";

@observer
export class LegendDataShowRoom2Comp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let cropLegend = false;
        if (store.dataShowRoomStore.active &&
            (store.dataShowRoomStore.classPoints.checked || store.dataShowRoomStore.yieldAssessmentPoints.checked ||
                store.dataShowRoomStore.ccePoints.checked) ){
            cropLegend = true;
        }
        if (!cropLegend) return null;

        let items: IShowRoom2Crop[] = [];

        let allShow = false;
        if (store.dataShowRoomStore.filtersMultiproject.cropFilters.size == 0) allShow = true;
        let idCrops: number[] = [...store.dataShowRoomStore.filtersMultiproject.cropFilters.values()];
        store.dataShowRoomStore.cropDataShort.forEach(a =>{
            if (store.dataShowRoomStore.filtersMultiproject.cropFilters.has(a.id) || allShow){
                items.push(a);
            }
        });
        if (store.dataShowRoomStore.filtersMultiproject.otherCropChecked || allShow){
            items.push({id: -1, color: store.dataShowRoomStore.otherCropColor, name: "Other"});
        }
        let arr: any[] = [];
        items.forEach(a =>{
            arr.push(<tr key={a.id}>
                <td>
                    <div className="LegendDataShowRoom2Comp-box" style={{background: ColorHelper.numToCssHexRgb(a.color)}}/>
                </td>
                <td><div className="LegendDataShowRoom2Comp-label">{a.name}</div></td>
            </tr>);
        });

        return <LegendCommonComp store={store} className="legend-panel qtransit">
            <div className="LegendDataShowRoom2Comp-div">
                <table className="LegendDataShowRoom2Comp-table">
                    <colgroup>
                        <col className=""/>
                        <col className=""/>
                    </colgroup>
                    <tbody>
                        {arr}
                    </tbody>
                </table>
            </div>
            </LegendCommonComp>;
    }
}
