import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import './ConfirmModalComp.scss';
import {A2ConfirmExportMode} from "../../../store/agro/Confirms/A2ConfirmExportStore";
import {ReactNode} from "react";
import classNames from "classnames";

export interface IConfirmModalComp{
    title?: string;
    bodyText?: string;
    bottomContent?: ReactNode;
    classNameForm?: string;
}
@observer
export class ConfirmModalComp extends React.Component<IConfirmModalComp, undefined> {
    constructor(props: IConfirmModalComp) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <div className="Form-backgray">
            <div className={classNames("Forms-self-center Forms-base",this.props.classNameForm)}>
                {this.props.title && <div className="Forms-headerText Forms-marginTop Forms-marginLR Forms-headerBottomMargin">{this.props.title}</div>}
                <div className="Forms-marginLR">
                    {this.props.bodyText && <div className="ConfirmModalComp-body">
                        {this.props.bodyText}
                    </div>}
                    {this.props.children}
                </div>
                <div className="Forms-baseline Forms-topIdent2" />
                <div className="Forms-footerButtons">
                    {this.props.bottomContent}
                </div>
            </div>
        </div>;

    }
}
