import {ColorDescriptor, get} from "color-string";

export interface IRGBA { r: number, g: number, b: number, a: number}//a: 0..1

export class ColorHelper {

    //npm install color-string

    static colorComponentNormal(c: number){
        return Math.min(Math.max(0, Math.round(c)), 255);
    }
    static rgbToHex(r:number, g:number, b:number) {
        function componentToHex(c:number) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    static parseColor(s: string): IRGBA{
        let vv: ColorDescriptor = null;
        if (s.startsWith("0x")){
            vv = get("#"+s.substr(2));
        }else vv = get(s);
        return {r: vv.value[0], g: vv.value[1], b: vv.value[2], a: vv.value[3]};
    }
/*
    static colorToNum(s: string): number {
        let c = ColorHelper.parseColor(s);
        let r = (c.r << 16)  + (c.g << 8) + c.b + Math.round(c.a * 255) << 24;
        return r;
    }

    static numToColorRGBA(num: number): IRGBA {
        let b = num & 0xFF,
            g = (num & 0xFF00) >>> 8,
            r = (num & 0xFF0000) >>> 16,
            a = ( (num & 0xFF000000) >>> 24 ) / 255 ;
        return {b: b, r: r, g: g, a: a};
    }*/
    static ColorRGBAtoCSS(color: IRGBA): string  {
        return `rgba(${color.r}, ${color.g},${color.b}, ${color.a})`;
    }
    static numToCssHexRgb(colorNum: number): string{
        return "#" + colorNum.toString(16).padStart(6, '0');
    }
    /*
    static numColorToCssRgba(num: number): string{
        //num >>>= 0;
        let b = num & 0xFF,
            g = (num & 0xFF00) >>> 8,
            r = (num & 0xFF0000) >>> 16,
            a = ( (num & 0xFF000000) >>> 24 ) / 255 ;
        return "rgba(" + [r, g, b, a].join(",") + ")";
    }*/

}