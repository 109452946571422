import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {CheckboxMiniOkComp} from "../../../Common/CheckboxMiniOkComp";
import {CheckboxSize} from "../../../Common/CheckboxMiniComp";
import {action, observable} from "mobx";
import {ContextMenuCommonComp} from "../../../Common/ContextMenuCommonComp";
import SimpleBar from "simplebar-react";
import {groupHasClimate, IndexByPointerStore, IndexDataGroup} from "../../../../store/indeByPoint/IndexByPointerStore";
import {IndexByPointYearStore, IYearPeriod} from "../../../../store/indeByPoint/IndexByPointYearStore";

const Climate: string = "climate";
@observer
export class IndexByPointYearsDropDownComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickPopupYear(event: any, data?: any){
        let yp: IYearPeriod = data;
        if (this.props.store.indexByPointer.yearPeriodsData.find(a=> IndexByPointYearStore.comparePeriod(a, yp)) == null){
            this.props.store.indexByPointer.yearPeriodsData.push(yp);
            this.props.store.indexByPointer.yearPeriodsData = this.props.store.indexByPointer.yearPeriodsData.slice().sort((a,b)=> IndexByPointYearStore.sortPeriod(a,b));
            this.props.store.indexByPointer.points.forEach(p =>{
                let t = new IndexByPointYearStore(this.props.store.indexByPointer);
                t.idPoint = p.id;
                t.yearPeriod = yp;
                t.visible = (this.props.store.indexByPointer.getSingleYearPoint() == null || !this.props.store.indexByPointer.compareSources);
                this.props.store.indexByPointer.yearPoints.push(t);
            });
        } else {
            this.props.store.indexByPointer.yearPeriodsData = this.props.store.indexByPointer.yearPeriodsData.filter(a => !IndexByPointYearStore.comparePeriod(yp, a));
            this.props.store.indexByPointer.yearPoints = this.props.store.indexByPointer.yearPoints.filter(a => !a.comparePeriod(yp));
        }
    }

    @action
    onClosePopup(){
        this.openYear = false;
    }
    @action
    onClickOpen(){
        this.openYear = !this.openYear;
    }
    @observable
    openYear: boolean = false;

    render() {
        let store = this.props.store;
        let ip = store.indexByPointer;
        let yearsAddRemoveArr: any[] = [];
        let curYear = (new Date()).getFullYear();
        let hasClimate = groupHasClimate(store.indexByPointer.dataGroup);

        if (hasClimate) {
            yearsAddRemoveArr.push(
                <CheckboxMiniOkComp key="climate"
                                    state={(ip.yearPeriodsData.find(a=> a.isClimate) != null)}
                                   onClick={this.onClickPopupYear}
                                   size={CheckboxSize.mini}
                                   data={({year: IndexByPointerStore.defaultYear(), isClimate: true} as IYearPeriod)}
                                   classesContainer="IndexByPoint-popupYear-cont"
                                   classesBox="IndexByPoint-popupYear-box">
                    <span className="IndexByPoint-popupYear-label">{store.trans["Climate"]}</span>
                </CheckboxMiniOkComp>
            );
        }

        let minYear = 2010;
        let isCross = (ip.isCrossYear);
        if (ip.dataGroup == IndexDataGroup.spectral) minYear = 2017;
        for(let i = curYear; i >= minYear; i--){

            yearsAddRemoveArr.push(<CheckboxMiniOkComp key={i.toString()}
                                                       state={(ip.yearPeriodsData.find(a=> a.year == i && !a.isClimate) != null)}
                                                       onClick={this.onClickPopupYear}
                                                       size={CheckboxSize.mini}
                                                       data={({year: i, isClimate: false} as IYearPeriod)}
                                                       classesContainer="IndexByPoint-popupYear-cont"
                                                       classesBox="IndexByPoint-popupYear-box">
                {!isCross && <span className="IndexByPoint-popupYear-label">{i.toString()}</span>}
                {isCross && <span className="IndexByPoint-popupYear-label">{i.toString()} - {(i+1).toString()}</span>}
            </CheckboxMiniOkComp>);
        }




        return <div onClick={this.onClickOpen}
                     className={"indexByPoint_headerline-button indexByPoint_headerline-marginLeftButton "
                         +"white-stroke white blue_stroke_onhover blue_text_onhover white-fill blue_fill_onhover"
                     +" indexByPoint_headerline-year flex-columns flex-center-content"}
                     style={{borderColor:this.openYear?' #4DB6BC':'#3E4751'}}>
            <span className="indexByPoint_headerline-year ">{store.trans["Select year"]}</span>
            <span className="indexByPoint_headerline-downItem">
                                    <svg width="8" height="4" viewBox="0 0 8 4" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.875 0.125L4 3.875L7.125 0.125H0.875Z" />
                                    </svg>
                                </span>
            {this.openYear &&
            <ContextMenuCommonComp
                indentVertical={5}
                autoCornerWidth={125}
                autoCornerHeight={155}
                onClose={this.onClosePopup}
                className="contextMenuCommon IndexByPointTableRowComp-year-popup scrollbar-standart style-4">
                <SimpleBar className="width100 height100" forceVisible="y" autoHide={false} >
                    {yearsAddRemoveArr}
                </SimpleBar>
            </ContextMenuCommonComp>}
        </div>;
    }
}
