import {Translate_en} from "../lang/Translate_en";

export class DateUtils{
    static getMonthName(monthNum: number, translate: Translate_en): string{
        switch (monthNum) {
            case 0: return translate.January;
            case 1: return translate.February;
            case 2: return translate.March;
            case 3: return translate.April;
            case 4: return translate.May;
            case 5: return translate.June;
            case 6: return translate.July;
            case 7: return translate.August;
            case 8: return translate.September;
            case 9: return translate.October;
            case 10: return translate.November;
            case 11: return translate.December;
        }
    }
}