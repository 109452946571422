import * as React from 'react';
import {CSSProperties, ReactNode} from 'react';
import autoBindReact from "auto-bind/react";
import {isFunction} from "lodash-es";
import classNames from "classnames";

export enum TriState{
    check = 'checked',
    uncheck ='unchecked',
    partial = 'partial'
}
export enum CheckboxSize {
    mini = 'mini',
    standart = 'standart',//квадрат в квадрате
    switcher = 'switcher',
    grayV = 'grayV',
}

export interface ICheckboxMiniCompProps {
    onClick?: (event: any, data?: any)=>void,
    state: TriState | boolean,
    size: CheckboxSize,
    classesBox?: string,
    classesContainer?: string,
    styleBox?: CSSProperties,
    "data-attr"?: string,
    data?: any,
    replaceBox?: ReactNode,
    disabled?: boolean,
    //getImage?: (props: ICheckboxMiniCompProps) => ReactNode;
    checkedEye?: boolean;
}

export class CheckboxMiniComp extends React.PureComponent<ICheckboxMiniCompProps, undefined> {
    constructor(props: ICheckboxMiniCompProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(event: any){
        if (this.props.disabled) return;
        if (isFunction(this.props.onClick)) this.props.onClick(event, this.props.data);
    }

    getTriState(): TriState{
        let state = this.props.state;
        let s: TriState = state as TriState;
        if (state === true) s = TriState.check;
        if (state === false || state == null) s = TriState.uncheck;
        return s;
    }
    getImageMini(): any{
        let s = this.getTriState();

        if (s == TriState.check) {
            if (this.props.checkedEye) {
                return (
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.00021 14C14.2956 14 17.7123 7.83533 17.8552 7.574C18.0475 7.21933 18.0487 6.78533 17.8563 6.43067C17.7145 6.16817 14.3226 0 9.00021 0C3.6508 0 0.282521 6.1705 0.141894 6.433C-0.0482319 6.7865 -0.0471069 7.217 0.144145 7.5705C0.285896 7.83183 3.6778 14 9.00021 14ZM9.00021 2.33333C12.1941 2.33333 14.6658 5.64083 15.5455 7C14.6635 8.36033 12.1919 11.6667 9.00021 11.6667C5.80406 11.6667 3.33242 8.35567 2.45379 6.99883C3.32792 5.64083 5.78944 2.33333 9.00021 2.33333Z" fill="#4DB6BC "strokeWidth="0.1"/>
                    <path d="M9.00026 9.33366C10.2429 9.33366 11.2503 8.28899 11.2503 7.00033C11.2503 5.71166 10.2429 4.66699 9.00026 4.66699C7.75761 4.66699 6.75024 5.71166 6.75024 7.00033C6.75024 8.28899 7.75761 9.33366 9.00026 9.33366Z" fill="#4DB6BC "strokeWidth="0.1"/>
                    </svg>
                );
            }
            return (
                <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" className="svg-stroke" width="13" height="13" rx="3" fill="transparent" strokeWidth="2"/>
                    <rect x="4" y="4" className="svg-fill svg-stroke" width="7" height="7" rx="1" strokeWidth="2"/>
                </svg>
            );
        }
        if (s == TriState.uncheck) return (
        <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" className="svg-stroke" width="13" height="13" rx="3" fill="transparent" strokeWidth="2"/>
        </svg>);
        if (s == TriState.partial) return (
        <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" className="svg-stroke" width="13" height="13" rx="3" fill="transparent" strokeWidth="2"/>
            <rect x="4.75" y="6.75" className="svg-fill svg-stroke" width="5.5" height="1.5" rx="0.75" strokeWidth="1.5"/>
        </svg>);
    }
    getImageBig(): any{
        let s = this.getTriState();

        if (s == TriState.check) {
            if (this.props.checkedEye) {
                return (
                    <svg width="18" height="20" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.00021 14C14.2956 14 17.7123 7.83533 17.8552 7.574C18.0475 7.21933 18.0487 6.78533 17.8563 6.43067C17.7145 6.16817 14.3226 0 9.00021 0C3.6508 0 0.282521 6.1705 0.141894 6.433C-0.0482319 6.7865 -0.0471069 7.217 0.144145 7.5705C0.285896 7.83183 3.6778 14 9.00021 14ZM9.00021 2.33333C12.1941 2.33333 14.6658 5.64083 15.5455 7C14.6635 8.36033 12.1919 11.6667 9.00021 11.6667C5.80406 11.6667 3.33242 8.35567 2.45379 6.99883C3.32792 5.64083 5.78944 2.33333 9.00021 2.33333Z" fill="#4DB6BC" strokeWidth="0.1"/>
                        <path d="M9.0002 9.33366C10.2429 9.33366 11.2502 8.28899 11.2502 7.00033C11.2502 5.71166 10.2429 4.66699 9.0002 4.66699C7.75755 4.66699 6.75018 5.71166 6.75018 7.00033C6.75018 8.28899 7.75755 9.33366 9.0002 9.33366Z" fill="#4DB6BC"strokeWidth="0.1"/>
                    </svg>
                );
            }
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="16" height="16" rx="3" fill="none" strokeWidth="2"/>
                    <rect x="5" y="5" width="8" height="8" rx="1" strokeWidth="2"/>
                </svg>
            );
        }
        if (s == TriState.uncheck) return (
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="16" rx="3"  fill="none" strokeWidth="2"/>
            </svg>);
        if (s == TriState.partial) return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="16" height="16" rx="3" stroke="#4DB6BC" strokeWidth="2"/>
                <rect x="4.5" y="8.5" width="9" height="1" rx="0.5" fill="#4DB6BC" stroke="#4DB6BC"/>
            </svg>);
    }
    getGrayV(){
        let s = this.getTriState();
        
        return <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{visibility: (s == TriState.check)?"visible":"hidden"}}>
            <path d="M9.4001 1.99961L8.0001 0.599609L4.0001 4.59961L2.0001 2.59961L0.600098 3.99961L4.0001 7.39961L9.4001 1.99961Z" fill="#C5C5C5"/>
        </svg>;
    }

    render() {
        let s = this.getTriState();
        let st: CSSProperties = {};
        if (this.props.styleBox){
            st = this.props.styleBox;
        }

        let img: any = this.props.replaceBox;
        if (img === undefined){
            if (this.props.size == CheckboxSize.mini) img = this.getImageMini();
            if (this.props.size == CheckboxSize.standart) img = this.getImageBig();
            if (this.props.size == CheckboxSize.grayV) img = this.getGrayV();
            if (this.props.size == CheckboxSize.switcher){
                let checked = this.getTriState();
                img = <input type="checkbox" className="switch_1" checked={checked ==TriState.check} readOnly={true}/>;
            }
        }

        return <div className={classNames("CheckboxMiniComp",this.props.classesContainer, {"opacity20": this.props.disabled})}
                    data-attr={this.props["data-attr"]?this.props["data-attr"]:null}
                    onClick={this.onClick}
                    >
            <span className={classNames("CheckboxMiniComp-box",{
                "CheckboxMiniComp-Check": s == TriState.check,
                "CheckboxMiniComp-Uncheck": s == TriState.uncheck,
                "CheckboxMiniComp-Partial": s == TriState.partial
            },this.props.classesBox)}
                  style={st}>
                {img}
            </span>
            {this.props.children}
        </div>;
    }
}
