import React, {RefObject} from 'react';
import {IChartEvent, IMyChartProps, IMyTestCompProps} from "./IMyTestCompProps";
import ResizeObserver from "resize-observer-polyfill";
import {dispose, ECharts, init} from "echarts";
import autoBindReact from "auto-bind/react";

export default class MyChart extends React.PureComponent<IMyChartProps, undefined> {
    constructor(props: any) {
        super(props);
        autoBindReact(this);
        this.divRef = React.createRef();
    }
    divRef: RefObject<HTMLDivElement>;
    chart: ECharts = null;
    ro: any = null;

    componentWillUnmount() {
        if (this.ro != null){
            this.ro.disconnect();
            this.ro = null;
        }
        if (this.chart != null) {
            dispose(this.chart);
            this.chart = null;
        }
    }
    getSplitNumber() {
        const options = this.chart?.getOption();
        const yAxis = options?.yAxis as echarts.EChartOption.YAxis[];
        if (Array.isArray(yAxis) ) {
            return yAxis[0].axisTick.length;
        }
        return null;
    }

    componentDidUpdate(prevProps: Readonly<IMyTestCompProps>, prevState: Readonly<undefined>, snapshot?: any) {
        let opt = this.props.options;
        let old = this.chart.getOption();
        if (old.dataZoom){
            opt.dataZoom = old.dataZoom;
        }
       
        this.chart.setOption(opt, true);
    }

    componentDidMount() {
        let c = init(this.divRef.current);

        this.ro = new ResizeObserver(()=>{
            c.resize();
        });
        this.ro.observe(this.divRef.current);
        this.chart = c;
        let opt = this.props.options;
        c.on("click", (a: any)=>{
            eventCall(a, this.props.onClick);
        });
        c.on("dblclick", (a: any)=>{
            eventCall(a, this.props.onDblClick);
        });
        c.on("mousedown", (a: any)=>{
            eventCall(a, this.props.onMouseDown);
        });
        c.on("mousemove", (a: any)=>{
            eventCall(a, this.props.onMouseMove);
        });
        c.on("mouseup", (a: any)=>{
            eventCall(a, this.props.onMouseUp);
        });
        c.on("mouseover", (a: any)=>{
            eventCall(a, this.props.onMouseOver);
        });
        c.on("mouseout", (a: any)=>{
            eventCall(a, this.props.onMouseOut);
        });
        c.on("globalout", (a: any)=>{
            eventCall(a, this.props.onGlobalOut);
        });
        c.on("contextmenu", (a: any)=>{
            eventCall(a, this.props.onContextMenu);
        });

        function eventCall(a: any, func: (arg: IChartEvent)=>void){
            if (func){
                let t: IChartEvent = {
                    borderColor: a.borderColor,
                    color: a.color,
                    componentIndex: a.componentIndex,
                    componentSubType: a.componentSubType,
                    componentType: a.componentType,
                    data: a.data,
                    dataType: a.dataType,
                    dimensionNames: a.dimensionNames,
                    event: a.event,
                    seriesId: a.seriesId,
                    seriesIndex: a.seriesIndex,
                    seriesName: a.seriesName,
                    seriesType: a.seriesType,
                    type: a.type,
                    value: a.value
                }
                func(t);
            }
        }
        c.setOption(opt, true);
    }

    render() {
        let c = this.props.class??'';
        return <div className={c} ref={this.divRef} tabIndex={0} id={this.props.id}>
        </div>;
    }
}
