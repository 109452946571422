import {CustomStore} from "../CustomStore";
import {observable, ObservableSet} from "mobx";
import {ExportStore} from "./ExportStore";
import {Utils} from "../../helper/utils/Utils";
import {fetchJsonPostWithJsonParams} from "../../helper/utils/FetchUtils";
import {IModisOrderParams} from "../OrderListStore";
import {isArray} from "lodash-es";

export enum ExportModisProducts{
    MOD09GQ= "MOD09GQ",
    MOD13Q1="MOD13Q1",
    MYD13Q1="MYD13Q1"
}

export class Export2ModisStore extends CustomStore<ExportStore>{


    @observable
    products: ObservableSet<ExportModisProducts> = new ObservableSet<ExportModisProducts>();

    hasProduct(prod: ExportModisProducts){
        return this.products.has(prod);
    }

    getParamsExport(): any{
        let ms = this.root.map;
        let g = ms.searchObject.searchGeometry;
        let products: string[] = Array.from(this.products);

        let obj = {
            "date_end": Utils.getDateStr(this.root.searchPanel.filterDate.end),
            "date_start": Utils.getDateStr(this.root.searchPanel.filterDate.begin),
            "order": {"name": this.parent.projectName, "email": this.parent.email},
            "products": products,
            "region": JSON.stringify(g)
        }
        return obj;
    }

    async sendExport(){
        let obj = this.getParamsExport();
        let url = `/api/proc_proxy/orders/modis_data`;
        await fetchJsonPostWithJsonParams(url, obj);
    }

    loadOrder(info: IModisOrderParams){
        this.products.clear();
        if (isArray(info.products)){
            let keys = Object.values(ExportModisProducts); //Object.keys(ExportModisProducts);
            keys.forEach(p => {
                if (info.products.find(a => a == p)){
                    this.products.add(p);
                }
            });
        }
    }
}