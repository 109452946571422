import * as React from 'react';
import {ReactElement, ReactNode} from "react";
import {SuperStore} from "../app/store/SuperStore";
import autoBind from "auto-bind";
import {ISuperStore} from "./store/SuperStore";
import {IUrlParams} from "../app/helper/utils/UrlCoder";
import {IQueryArguments} from "../app/helper/utils/IQueryArguments";
import {Utils} from "../app/helper/utils/Utils";
import {ButtonGeometryCreateType} from "../app/store/agro/fieldForm/A2FieldFormStore";
import {IA2FieldEditorProps} from "../app/components/panels/Left/Agro2/A2FieldEdit/A2FieldEditorCustomInputComp";
import {IDropDownCompProps} from "../app/components/Common/DropDownComp";
import {IDropTextCompProps} from "../app/components/Common/DropTextComp";

export interface IEventAgroGroupItemEditField {
    value: IA2NavigationItemValue;
    ok: boolean;
}
export interface IEventAgroEditField{
    field: A2FieldStore;
    isSelected: boolean;

    ok: boolean;
}

export interface IOverrideBehavior {
    overrideBehavior: boolean;
}
export type addChildrenElementsWithArgFunc<T> = (store: ISuperStore, children: ReactElement[], arg: T) => void;
export type addChildrenElementsFunc = (store: ISuperStore, children: ReactElement[]) => void;
export type simpleEventFunc = (store: ISuperStore) => void;
export type OverrideBehaviorFunc = (store: ISuperStore, options: IOverrideBehavior) => void;
export type argEventFunc<T> = (store: ISuperStore, arg: T) => void;

interface IEventProperty<T>{
    func: T, prior: number
}

export interface IOnAgroFormCreatePropertyDropDownCompRender{
    dropTextCompProps: IDropTextCompProps;
    dropDownCompProps: IDropDownCompProps;
    self: IA2FieldEditorProps;
}
//заготовка для событий
class CustomClassEvent<T>{
    constructor(store: SuperStore) {
        autoBind(this);
        this._store = store;
    }
    protected _store: SuperStore;
    protected funcs: IEventProperty<T>[] = [];

    addListen(func: T, prior: number = 0){
        if (!this.has(func, prior)) this.funcs.push({func: func, prior: prior});
    }
    has(func: T, prior: number = 0): boolean{
        return this.funcs.find(a => a.func === func && a.prior === prior) != null;
    }
    //Отписываться не обязательно. С новым стором всё отпишется автоматом
    removeListener(func: T, prior: number = 0){
        let idx = this.funcs.findIndex(a => a.func === func && a.prior === prior);
        if (idx >= 0) Utils.arrayRemoveByIndex(this.funcs, idx);
    }
    protected getSortedFuncs(): T[]{
        return this.funcs.sort((a,b)=> b.prior - a.prior).map(a => a.func);
    }
    clearAll(){
        this.funcs = [];
    }
}

class AddChildrenElements extends CustomClassEvent<addChildrenElementsFunc>{
    call(children: ReactElement[]): ReactElement[]{
        let r: ReactElement[] = [...children];
        this.getSortedFuncs().forEach(p => {
            p(this._store, r);
        });
        return r;
    }
}

class AddChildrenElementsWithArg<T> extends CustomClassEvent<addChildrenElementsWithArgFunc<T>>{
    call(children: ReactElement[], arg: T): ReactElement[]{
        let r: ReactElement[] = [...children];
        this.getSortedFuncs().forEach(p => {
            p(this._store, r, arg);
        });
        return r;
    }
}

class SimpleEvent extends CustomClassEvent<simpleEventFunc>{
    call(): void{
        this.getSortedFuncs().forEach(p => {
            p(this._store);
        });
    }
}

//Позволяет отменять стандратное поведение чего-то.
class OverrideBehaviorEvent extends  CustomClassEvent<OverrideBehaviorFunc>{
    call(): boolean{
        let options: IOverrideBehavior = {overrideBehavior: false};
        this.getSortedFuncs().forEach(p => {
            p(this._store, options);
        });
        return options.overrideBehavior;
    }

}

class ArgEvent<T> extends CustomClassEvent<argEventFunc<T>>{
    call(arg: T): void{
        this.getSortedFuncs().forEach(p => {
            p(this._store, arg);
        });
    }
}

export class PluginsEvents{
    constructor(store: SuperStore) {
        autoBind(this);
        this._store = store;
        this.onLeftPanelChildren = new AddChildrenElements(store);
        this.onMapChildren = new AddChildrenElements(store);
        this.onInitTools = new SimpleEvent(store);
        this.onCloseAllLeftPanels = new SimpleEvent(this.store);
        this.onLeftSidebarLeftChildren = new AddChildrenElements(this.store);
        this.onAgroExportChildren = new AddChildrenElements(this.store);
        this.onAgroExportDownload = new ArgEvent<IQueryArguments>(this.store);
        this.onChangeLang = new SimpleEvent(this.store);
        this.onReadUrl = new ArgEvent<IUrlParams>(this.store);
        this.onWriteUrl = new ArgEvent<IUrlParams>(this.store);
        this.onFieldList = new AddChildrenElements(this.store);
        this.onAgroNewGeometryTypes = new ArgEvent<Map<ButtonGeometryCreateType, boolean>>(this.store);
        this.onNavigatorPopup = new AddChildrenElements(this.store);
        this.onAgroFormProperties = new AddChildrenElements(this.store);
        this.onAgroFormCreatePropertyComponent = new ArgEvent<IA2FieldEditorProps>(this.store);
        this.onAgroFormCreatePropertyDropDownCompRender = new ArgEvent<IOnAgroFormCreatePropertyDropDownCompRender>(this.store);
        this.onAgroGetFieldTitle = new ArgEvent<IA2FieldTitle>(this.store);
        this.onAgroCanCreateField = new ArgEvent<{ok: boolean}>(this.store);
        this.onAgroCanDeleteField = new ArgEvent<{ok: boolean}>(this.store);
        this.onAgroCanEditField = new ArgEvent<IEventAgroEditField>(this.store);
        this.onAgroCanEditGroupField = new ArgEvent<{ok: boolean}>(this.store);
        this.onAgroCanEditGroupItemField = new ArgEvent<IEventAgroGroupItemEditField>(this.store);
        this.onChangeProject = new SimpleEvent(this.store);
        this.onFolderList = new AddChildrenElements(this.store);
        this.onFolderListItems = new ArgEvent<IA2NavigationItemValue[]>(this.store);
        this.onNdviProductItemChildren = new AddChildrenElementsWithArg<IProductItemComp>(store);
        this.onAgroFieldSelectedDropDownChildren = new AddChildrenElements(store);
        this.onAgroUseFilterMapShowOnlyCurrentNavigatorPath = new ArgEvent<{ok: boolean}>(store);
        this.onInitStore = new SimpleEvent(this.store);
        this.onLayerFieldsFilter = new ArgEvent<{ navItem: A2NavigatorItemStore }>(store);
        this.onChangeMeasuringStatus = new ArgEvent<MeasuringStatus | string>(store);
    }

    private _store: SuperStore;
    get store(): SuperStore{
        return this._store;
    }

    clearAll(){
        this.onLeftPanelChildren.clearAll();
        this.onCloseAllLeftPanels.clearAll();
        this.onInitTools.clearAll();
        this.onMapChildren.clearAll();
        this.onLeftSidebarLeftChildren.clearAll();
    }
    onLeftPanelChildren: AddChildrenElements;
    onMapChildren: AddChildrenElements;
    onLeftSidebarLeftChildren: AddChildrenElements;

    onNdviProductItemChildren: AddChildrenElementsWithArg<IProductItemComp>;
    onAgroFieldSelectedDropDownChildren: AddChildrenElements;

    onInitStore: SimpleEvent;
    onInitTools: SimpleEvent;
    onCloseAllLeftPanels: SimpleEvent;
    onNavigatorPopup: AddChildrenElements;

    onLayerFieldsFilter: ArgEvent<{ navItem: A2NavigatorItemStore }>
    onAgroUseFilterMapShowOnlyCurrentNavigatorPath: ArgEvent<{ok: boolean}>;
    onAgroExportChildren: AddChildrenElements;
    onAgroExportDownload: ArgEvent<IQueryArguments>;
    onAgroNewGeometryTypes: ArgEvent<Map<ButtonGeometryCreateType, boolean>>;
    onAgroFormProperties: AddChildrenElements;
    onAgroGetFieldTitle: ArgEvent<IA2FieldTitle>;
    onAgroFormCreatePropertyComponent: ArgEvent<IA2FieldEditorProps>;
    onAgroCanCreateField: ArgEvent<{ok: boolean}>;
    onAgroCanDeleteField: ArgEvent<{ok: boolean}>;
    onAgroCanEditField: ArgEvent<IEventAgroEditField>;
    onAgroCanEditGroupField: ArgEvent<{ok: boolean}>;
    onAgroCanEditGroupItemField: ArgEvent<IEventAgroGroupItemEditField>;
    onChangeProject: SimpleEvent;

    onAgroFormCreatePropertyDropDownCompRender: ArgEvent<IOnAgroFormCreatePropertyDropDownCompRender>;
    onChangeLang: SimpleEvent;
    onReadUrl: ArgEvent<IUrlParams>;
    onWriteUrl: ArgEvent<IUrlParams>;
    onFieldList: AddChildrenElements;
    onFolderList: AddChildrenElements;
    onFolderListItems: ArgEvent<IA2NavigationItemValue[]>;

    onChangeMeasuringStatus: ArgEvent<MeasuringStatus | string>;
}

import {A2FieldStore, IA2FieldTitle} from "../app/store/agro/A2FieldStore";
import {A2NavigatorItemStore, IA2NavigationItemValue} from "../app/store/agro/A2NavigatorItemStore";
import {IProductItemComp} from "../app/components/panels/Left/NdviPanel/ProductItemComp";import { MeasuringStatus } from '../app/helper/structs/MeasuringStatus';

