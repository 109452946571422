// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContextMenuPopupItem-icon {
  fill: #C5C5C5;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Map/ContextMenuComp.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".ContextMenuPopupItem-icon {\n    fill: #C5C5C5;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
