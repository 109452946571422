import {ObservableCustomStore} from "../../../../app/store/CustomStore";
import {AgroAhoStore} from "../../agroAhoStore";
import {Cfg} from "./agroAhoLayersConfig";


export class AgroAhoLayer extends ObservableCustomStore {
    constructor(parent: AgroAhoStore, layerId: string) {
        super(parent);
        this.parentStore = parent;
        this.layerId = layerId;
        this.cfg = (Cfg as any)[layerId];
    }

    parentStore: AgroAhoStore;
    layerId: string;
    cfg: any;

    testFunc(){
        // console.log('layer', JSON.stringify(this.cfg));
    }

}
