import {CustomStore} from "./CustomStore";
import {observable} from "mobx";

export class DebugStore extends CustomStore{
    @observable
    showPanel: boolean = false;
    @observable
    circleRadius: number = 15;
    @observable
    circleBlure: number = 0.8;
    @observable
    circleOpacity: number = 0.3;
}