import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";

@observer
export class MeasuringHintComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let ms = store.map;
        let hint: string = null;

        let status = ms.measuringStatus;

        switch (status) {
            case MeasuringStatus.Ruler:
                break;
            case MeasuringStatus.Point:
                hint = store.trans['Click to draw a point'];
                break;
            case MeasuringStatus.Polygon:
                hint = store.trans['Click to draw vetrices and double click to finish'];
                break;
            case MeasuringStatus.Polyline:
                hint = store.trans['Click to draw vetrices and double click to finish'];
                break;
            case MeasuringStatus.Rectangle:
                hint = store.trans['Click to draw a vertex, drag and click to finish'];
                break;
        }


        if (hint == null) return null;

        return <div className="hint-panel" style={{left: ms.hintMousePointer.x + 16, top: ms.hintMousePointer.y}}>
            <span>{ms.hintText}</span>
        </div>;
    }
}