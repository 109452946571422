import {Translate} from "../../../app/helper/lang/Translate";

export class AgroAhoTranslate_en extends Translate{
    "Upload cells"=""
    "Uploading cells"=""
    "You can upload cells in GeoJSON, KML, or SHP format in a zip archive. New cells must completely cover the area of ​​the fields within which these cells are located. But it is not necessary for new cells to cover all ASA map fields (cells of fields that are not in the uploaded file will remain unchanged)."=""
    "Attention"=""
    "During the process of loading new cells, existing cells, tracks and ALL attached information will be deleted: indicators, recommended standards (if any)."=""
    "Select a file"=""
    "Selecting a column with cell names"=""
    "Below you need to select the column from your table that corresponds to the cell names. Cell names can be numeric and/or text."=""
    "Column"=""
    "Cancel"=""
    "Download"=""
    "Loading error"=""
    "Save"=""
    "Generate"=""
    "Example of values in the selected column:"=""
    "Cells processed successfully"=""
    "There are remarks"=""
    "File loaded with errors. Fix the following errors"=""
    "Cell number"=""
    "Remarks"=""
    "Save error"=""
    "Cell outlines loaded successfully"=""
    "There are comments and errors"=""
    "The file has errors"=""
    "Remarks:"=""
    "Errors:"=""
    "Cell / field number"=""
    "Remark / error"=""
    "Map export settings"=""
    "Cell numbers"=""
    "Cell labels" = ""
    "Meaning of indicators" = ""
    "Meaning of indicators/rec. norm" = ""
    "Scale and palette type" = ""
    "Agro-Soft" = ""
    "Agroplem" = ""
    "FosAgro"=""
    "gradations"=""
    "Visualization setup"=""
    "Default scale and palette"=""
    "Upload indicators" = ""
    "Agri. soil analysis maps (ASA maps)" = ""
    "Attention! The selected indicator will be permanently deleted with all attached information" = ""
    "sortByName"=""
    "sortByDate"=""
    "ASAs"=""
    "Without labels"=""
    "To change a track, double click on the cell."=""
    "To change the selection route, double-click on the cell"=""
    "To change the detour order, double-click on the field"=""
    "Go out"=""
    "Cell"=""
    "Field"=""
    "Selected"=""
    "Clear"=""
    "Export only visible fields"=""
    "Export only selected fields"=""
    "Export data from selected fields only"=""
    "Show cells of selected fields only"=""
    "There are no norms set for the selected fields"=""
    "Total fields"=""
    "Search"=""
    "Select all"=""
    "Total cells"=""
    "Search by cell number"=""
    "Search by cell name"=""
    "Tours"=""
    "Selection points"=""
    "Survey data is loaded by field, field survey data is organized into rounds. There can be several rounds in the system; for each cell, the data can be located in different rounds. For each tour, you must indicate the name and date of field work."=""
    "On to a new tour"=""
    "To an existing tour"=""
    "Where do you want to upload the indicators"=""
    "Load cells from kml/geojson/shp to zip"=""
    "Selected fields"=""
    "AXO data"=""
    "Introduction cards"=""
    "Indicators"=""
    "Recommended norms"=""
    "Download table"=""
    "Next"=""
    "Step 1. Select a tour"=""
    "Choose from existing tours or create a new one"=""
    "Create a new tour"=""
    "Tour selection"=""
    "Loading into an existing tour"=""
    "Tour name"=""
    "Tour date"=""
    "Step 2. Loading a file with agrochemical indicators"=""
    "Step 3. Selecting the column with sample names"=""
    "The file was successfully processed and matches the sample"=""
    "Tour"=""
    "Below you need to select a column from the loaded table that corresponds to the cell numbers"=""
    "Examples of values in the selected column"=""
    "Examples of repeating values"=""
    "The selected column contains duplicate values!"=""
    "The selected column contains empty values!"=""
    "The selected column contains references to non-existent cells!"=""
    "Line numbers with empty values"=""
    "There are no cells with such names in the ASA map"=""
    "Column title"=""
    "Step 4: Configure Compliance"=""
    "The selected column contains incorrect values!"=""
    "Match the cells in your table to what constitutes their essence. If necessary, you can choose which cells to load and which not"=""
    "loading?"=""
    "Values"=""
    "Data type"=""
    "Matching"=""
    "Indicator"=""
    "Recom. norm"=""
    "kg/ha"=""
    "Not set"=""
    "Empty field"=""
    "yields"=""
    "c/ha"=""
    "for"=""
    "Step 5: View value intersections and errors"=""
    "The tour already contains the following data, which is also contained in the downloadable table:"=""
    "Cell values from intersecting data:"=""
    "Overwrite"=""
    "Do not overwrite"=""
    "The file was processed with errors. Correct the following errors in the Excel file:"=""
    "Below you need to select a column from the loaded table that corresponds to the sample names (=cell names in the ASA map). The values ​​in this column can be numeric and/or text. These values ​​are used to compare the data loaded from the table with the cells (=sections) into which the field is divided."=""
    "File"=""
    "Match the columns in the table with the data types and parameters you need. You can choose which columns to load and which not to load. To not load a column, select Data Type = Not Specified."=""
    "Column with sample names"=""
    "There are non-numeric values"=""
    "Error creating tour"=""
    "Attention! All indicator values will be removed from the tours: {0}"=""
    "Permalink loading error"=""
    "Attention! All tour {0} data will be deleted"=""
    "Successfully deleted"=""
    "duplicate"=""
    "repeat key"=""
    "row"=""
    "incorrect cell number"=""
    "Error deleting tour"=""
    "The file has been processed successfully!"="Файл успешно обработан!"
    "The data downloaded from the table file (AHO indicators, recommended application rates) must be assigned to a particular Tour. Grouping data by Tour allows, for example, to save a history of values for one cell over different seasons."=""
    "Attention! All data for culture {0} will be deleted"=""
    "Active substance (as)"=""
    "Fertilizers in phys. wt"=""
    "Attention! All data fertilizer will be removed from the tours: {0}"=""
    "Fields"=""
    "Export of AXO indicators" = ""
    "File name" = ""
    "Tabular file, all indicators (.ods)" = ""
    "Cartograms, all indicators (.pdf)" = ""
    "Cells, all indicators (.shp)" = ""
    "Export of recommended norms" = ""
    "Tabular file, all norms (.ods)" = ""
    "grid file for technology (ISO-XML)" = ""
    "grid file (.shp)" = ""
    "Cell borders (.shp)" = ""
    "Grid grain size (m)" = ""
    "Show field names" = ""
    "Preparations are underway for export" = ""
    "Incorrect values" = ""
    "Merge ISO-XML files" = ""
    "Merge multiple ISO-XML" = ""
    "Merge" = ""
    "Merged file name" = ""
    "Merge error" = ""
    "Farm not specified"=""
    "Error, more than 2 files selected"=""
    "Copy selected fields"=""
    "Remove selected fields from ASA map"=""
    "Attention! For selected fields in the ASA map, all related data will be deleted: cells, indicators, recommended norms!"=""
    "Copy to new ASA map"=""
    "Copy to existing ASA map"=""
    "Copying selected fields"=""
    "cells"=""
    "New ASA map"=""
    "Search by ASA map"=""
    "Copy"=""
    "Add value to cell numbers"=""
    "Numbers of copied cells"=""
    "The value specified in the input field above will be added to the cell numbers of all copied fields."=""
    "Some numbers coincide with those already available in"=""
    "Cell range"=""
    "Successfully copied"=""
    "Error creating a new ASA map"=""
    "The ASA map {0} already has the following fields:"=""
    "The ASA map {0} already has the following cell numbers:"=""
    "Deletion error"=""
    "Copy selected tour"=""
    "Merge selected tours"=""
    "Delete selected tours from ASA map"=""
    "Attention! All data from tours {0} will be deleted"=""
    "Copying a selected tour"=""
    "Editing a tour"=""
    "Where to copy"=""
    "Merging selected tours"=""
    "Copy error"=""
    "The ASA map {0} does not have the following fields:"=""
    "The ASA map {0} does not have the following cell numbers:"=""
    "A new tour will be created from the union of these tours:"=""
    "Tour priority (when overlapping values)"=""
    "Routes"=""
    "Total volume of fertilizers"=""
    "Min. norm"=""
    "Avg. norm"=""
    "Max. norm"=""
    "kg"=""
    "Return to recommended value"=""
    "pcs"=""
    "Sort by"=""
    "Title"=""
    "Crop"=""
    "Search by field name"=""
    "Search by field name and crop"=""
    "Off"=""
    "Crops per season"=""
    "Examples of meanings"=""
    "Edit selection route"=""
    "Set the detour order"=""
    "Set the detour order of field"=""
    "Export"=""
    "ASA map name"=""
    "Indic"=""
    "Vals"=""
    "Removing an indicator"=""
    "Attention! You confirm the deletion of the indicator"=""
    "Attention! You confirm the deletion"=""
    "of the indicator"=""
    "Delete for selected fields only"=""
    "Delete data from selected fields only"=""
    "Select tours to remove the indicator"=""
    "Cell names"=""
    "Export layers"=""
    "lines"=""
    "line"=""
    "Editing cell names"=""
    "Download the template table for editing cell names from the link below («Download template»). This table has two columns: " = ""
    "old names" = ""
    "column for new names. If you do not want to change the cell name, leave the corresponding position in the column "=""
    "empty" = ""
    "DO NOT change "=""
    "the column names! After that, upload the completed table to the Agrometrika service by clicking the «Upload table with new names» button. The uploaded table must be "=""
    "strictly in the OpenDocument (.ods) format."=""
    "Download table with new names"=""
    "There are duplicate values in the column cell_name_new"=""
    "There are duplicate values in the column cell_name"=""
    "Error while processing. Check file integrity."=""
    "Column «cell_name» not found - old cell names"=""
    "Column «cell_name_new» not found - new cell names"=""
    "File successfully processed, number of replacements"=""
    "Old values"=""
    "New values"=""
    "New values taken from file"=""
    "Please correct the errors and re-upload the file"=""
    "Download template"=""
    "Edit cell names"=""
    "Loading new cell borders"=""
}
