import * as React from 'react';
import {CSSProperties} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './PresetColorComp.scss';
import {ContextMenuCommonComp} from "../../../Common/ContextMenuCommonComp";
import {action, observable} from "mobx";
import {Interpolation, PresetColorValue} from "../../../../store/productSetting/IndexBandProductStore";
import {ColorHelper} from "../../../../helper/utils/ColorHelper";

export interface IPresetColorComp extends IStoreProps{
    currentPreset: string;
    onChange: (newPresetName: string) => void;
}
@observer
export class PresetColorComp extends React.Component<IPresetColorComp, undefined> {
    constructor(props: IPresetColorComp) {
        super(props);
        autoBindReact(this);
    }

    @observable
    down: boolean = false;

    onClosePopup(){
        this.down = false;
    }

    onClick(){
        this.down = true;
    }

    static getGradientStyle(colors: PresetColorValue[], interpolation: Interpolation): string{
        let backGradient: string = "linear-gradient(90deg";
        for (let i = 0; i < colors.length; i++) {
            let kv = colors[i];

            function getK(index: number): number {
                return (index / (colors.length - 1));
            }

            backGradient += `, ${ColorHelper.ColorRGBAtoCSS(kv.color)} ${getK(i) * 100}% `;
            if (i < colors.length - 1 && interpolation == Interpolation.discrete) backGradient += `, ${ColorHelper.ColorRGBAtoCSS(kv.color)} ${getK(i + 1) * 100}% `;
        }
        backGradient += "), url('./img/chess.png')";
        return backGradient;
    }
    @action
    onClickItem(e: any){
        let attr = e.currentTarget.getAttribute("data-id");
        if (attr != null) {
            this.props.onChange(attr);
            this.down = false;
        }
    }

    render() {
        let store = this.props.store;
        let curId = this.props.currentPreset;

        let cur = store.map.presetColors.find(a => a.id == curId);
        if (cur == null && store.map.presetColors.length > 0){
            cur = store.map.presetColors[0];
        }
        let leftGradient:CSSProperties ={};
        if (cur != null){
            let backStyle = PresetColorComp.getGradientStyle(cur.colors, Interpolation.linear);
            leftGradient.background = backStyle;
        }


        let divArr: any[]=[];
        divArr.push(<div className="PresetColorComp-downItem-m PresetColorComp-downItem-header" key="Gradients">{store.trans.Palettes}</div>);
        store.map.presetColors.forEach(a => {
            let bs: CSSProperties = {background: PresetColorComp.getGradientStyle(a.colors, Interpolation.linear)};
            divArr.push(<div style={bs} onClick={this.onClickItem} data-id={a.id}
                             className="PresetColorComp-downItem-m PresetColorComp-downItem-Gradient"
                             key={a.id}><span className="PresetColorComp-downItem-GradientText">{a.name}</span></div>);
        });

        return <div className="mini PresetColorComp-div-m flex-stretch-item">
            <div className="PresetColorComp-header-m select-selected" onClick={this.onClick}>
                <div className="PresetColorComp-leftGradientHeader-m" style={leftGradient} />
                <span className="PresetColorComp-Header-text flex-stretch-item">{(cur==null)?"":cur.name}</span>
            </div>
            {this.down &&
                <ContextMenuCommonComp  parentClickNotIgnore={true} className="PresetColorComp-down-m" setMinWidth={true}
                                            onClose={this.onClosePopup}>
                {divArr}
                </ContextMenuCommonComp>
            }
        </div>;
    }
}
