import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './PhotoPanelPhotoList.scss';
import {IPhotoProperties} from "../../../../../store/photo/PhotoStore";
import {action, observable} from "mobx";
import {Geometry, Point} from "geojson";
import {MBUtils} from "../../../../../helper/utils/MBUtils";
import classNames from "classnames";
import {ra} from "../../../../../helper/utils/mobxUtils";

export interface IPhotoPanelPhotoItemComp extends IStoreProps{
    item: IPhotoProperties,
    geometry: Geometry
}

@observer
export class PhotoPanelPhotoItemComp extends React.Component<IPhotoPanelPhotoItemComp, undefined> {
    constructor(props: IPhotoPanelPhotoItemComp) {
        super(props);
        autoBindReact(this);
        this.ref = React.createRef();
    }

    ref: any;

    @observable
    showLine: boolean = false;

    @observable x1: number;
    @observable y1: number;
    @observable x2: number;
    @observable y2: number;

    @observable scrWidth: number;
    @observable scrHeight: number;
    @observable imgRect: DOMRect;

    componentDidMount() {
        document.getElementById("photolist").addEventListener("scroll", this.onScroll);
    }
    componentWillUnmount() {
        document.getElementById("photolist").removeEventListener("scroll", this.onScroll);
    }

    @action
    onScroll(){
        if (this.showLine) this.recalcLine();
    }

    @action
    onClick(){
        this.showLine = false;
        this.props.store.photo.currentPhotoId = this.props.item.photo_id;
    }

    recalcLine(){
        let store = this.props.store;
        if (this.ref.current == null) {
            this.x1 = null;
            return;
        }
        let rect = (this.ref.current as HTMLDivElement).getBoundingClientRect();
        this.imgRect = rect;
        this.x1 = rect.x + rect.width / 2;
        this.y1 = rect.y + rect.height / 2;
        let p = (this.props.geometry as Point);
        let mapboxLL = MBUtils.positionToLL(p.coordinates);
        let mapElem = document.getElementById("map");
        let mapScrRect = mapElem.getBoundingClientRect();

        let screenPoint = store.map.mapbox.project(mapboxLL);
        this.x2 = screenPoint.x + mapScrRect.x;
        this.y2 = screenPoint.y + mapScrRect.y;
        this.scrWidth = window.innerWidth;
        this.scrHeight = window.innerHeight;
    }

    timer: any = null;
    @action
    onEnter(){
        this.timer = setTimeout(()=>{
            ra(()=>{
                if (this.props.store.photo.currentPhotoId != null || this.props.store.searchPanel.hasBlockLeftPanels()) return;
                this.showLine = true;
                this.animate = true;
                this.recalcLine();
                this.timer = null;
            });
        }, 200);
    }

    @action
    onLeave(){
        this.showLine = false;
        if (this.timer != null) clearTimeout(this.timer);
        this.timer = null;
    }
    onEndAnimate(){
        this.recalcLine();
        this.animate = false;
    }
    animate: boolean = true;

    render() {
        let store = this.props.store;
        let item = this.props.item;
        let urlImg = `/api/projects/${store.agro.projectName}/photo/image?photo_id=${item.photo_id}&width=110&version=${item.version}`;
        let d = new Date(item.photo_time);
        let highlightBorder = this.showLine || store.photo.currentPhotoId == item.photo_id;


        let dateStr = d.toLocaleString(navigator.language, {"year":"numeric", "month":"2-digit",
            "day":"2-digit", "hour":"2-digit", "minute":"2-digit"});
        return <div className="PhotoPanelPhotoItemComp-main" id={"photoItem"+item.photo_id.toString()} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} >
                <div className={classNames("PhotoPanelPhotoItemComp-imgDiv",
                        {"PhotoPanelPhotoItemComp-imgDivHover": highlightBorder})} ref={this.ref}>
                    <img className="PhotoPanelPhotoItemComp-img" src={urlImg} onClick={this.onClick} />
                    {this.showLine && this.x1 != null &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="PhotoPanelPhotoItemComp-svgLine"
                         viewBox={`0 0 ${this.scrWidth} ${this.scrHeight}`} onAnimationEnd={this.onEndAnimate}
                         width={this.scrWidth} height={this.scrHeight}>
                        <defs>
                            <clipPath id="cut">
                                <path d={this.getRoundedRect(this.imgRect.x+1, this.imgRect.y+1, this.imgRect.width-2, this.imgRect.height-2, this.scrWidth, this.scrHeight)}  />
                            </clipPath>
                        </defs>
                        {!this.animate &&<line x1={this.x1} y1={this.y1} x2={this.x2} y2={this.y2} stroke="#4DB6BC" strokeWidth="1" clipPath="url(#cut)"/>}
                        {this.animate &&
                        <line x1={this.x1} y1={this.y1} x2={this.x1} y2={this.y1} stroke="#4DB6BC" strokeWidth="1" clipPath="url(#cut)">
                            <animate attributeName="x2" values={`${this.x1};${this.x2}`} dur="200ms" repeatCount="1" fill="freeze"/>
                            <animate attributeName="y2" values={`${this.y1};${this.y2}`} dur="200ms" repeatCount="1" fill="freeze"/>
                        </line>}
                        <circle cx={this.x2} cy={this.y2} r="0" fill="#3E4751" stroke="#4DB6BC" strokeWidth="2">
                            {this.animate && <animate attributeName="r" values="0;4" begin="200ms" dur="100ms"  repeatCount="1" fill="freeze"/>}
                        </circle>
                    </svg>}
                </div>
                <div className="PhotoPanelPhotoItemComp-imageLabel">{dateStr}</div>
            </div>;
    }

    getRoundedRect(x: number, y: number, w: number, h: number, scrWidth:number, scrHeight: number): string{
        /*let x = Math.min(x1, x2);
        let y = Math.min(y1, y2);
        let w = Math.abs(x2 - x1);
        let h = Math.abs(y2 - y1);*/
        let a = 6;
        let r = "";
        r += `M${x + a},${y} `;
        r += `L ${x + w - a} ${y}`;
        r += `a${a},${a} 0 0 1 ${a},${a} `;
        r += `L ${x + w} ${y + h - a} `;
        r += `a${a},${a} 0 0 1 ${-a},${a} `;
        r += `L ${x + a} ${y + h} `;
        r += `a${a},${a} 0 0 1 ${-a},${-a} `;
        r += `L ${x} ${y + a} `;
        r += `a${a},${a} 0 0 1 ${a},${-a} `;
        r += `L ${x+a} ${0} `;
        r += `L ${0} ${0} `;
        r += `L ${0} ${scrHeight} `;
        r += `L ${scrWidth} ${scrHeight} `;
        r += `L ${scrWidth} ${0} `;
        r += `L ${x+a} ${0} `;
        r += `L ${x+a} ${y} `;
        r += 'z';
        //r += `h${x1 - x2 - a*2} `;
        /*
        r += `a${a},${a} 0 0 1 ${-a},${-a} `;
        r += `V${y1 - a} `;
        r += `a${a},${a} 0 0 1 ${a},${-a} `;*/
        //r += 'z';
        return r;
    }
}
