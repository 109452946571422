// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputStringWindow-window {
  min-width: 452px;
  margin: auto;
  color: #EEEEEE;
  z-index: 30000;
}

.InputStringWindow-title {
  margin: 25px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.InputStringWindow-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin: 0 25px 20px 0;
}

.InputStringWindow-nameLabel {
  margin-left: 25px;
  margin-right: 20px;
}

.InputStringWindow-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.InputStringWindow-button {
  min-width: 170px;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/InputStringWindow.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;AACF;;AACA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;AAGF;;AADA;EACE,iBAAA;EACA,kBAAA;AAIF;;AAFA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAKF;;AAHA;EACE,gBAAA;AAMF","sourcesContent":[".InputStringWindow-window{\r\n  min-width: 452px;\r\n  margin: auto;\r\n  color: #EEEEEE;\r\n  z-index: 30000;\r\n}\r\n.InputStringWindow-title{\r\n  margin: 25px;\r\n  font-weight: bold;\r\n  font-size: 16px;\r\n  line-height: 16px;\r\n}\r\n.InputStringWindow-name{\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  display: flex;\r\n  align-items: center;\r\n  margin: 0 25px 20px 0;\r\n}\r\n.InputStringWindow-nameLabel{\r\n  margin-left: 25px;\r\n  margin-right: 20px;\r\n}\r\n.InputStringWindow-footer{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin: 20px;\r\n}\r\n.InputStringWindow-button{\r\n  min-width: 170px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
