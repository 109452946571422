import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {LeftSidebarLeftComp} from "./LeftSidebarLeftComp";
import {LeftSidebarRight} from "./LeftSidebarRight";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";

@observer
export class LeftSidebarComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onAnimationEnd(){
        this.props.store.map.mapbox.resize();
    }
    render() {
        let store = this.props.store;// className={classNames({"left-sidebar__right_extand-map": store.extandMap})}
        return <div className={classNames("left-sidebar transit-map", {"left-sidebar_extand-map": store.map.extandMap})} onTransitionEnd={this.onAnimationEnd} onAnimationEnd={this.onAnimationEnd}>
            <LeftSidebarLeftComp store={store}/>
            <LeftSidebarRight store={store} className={classNames({"left-sidebar__right-extand-map": store.map.extandMap})}/>
        </div>;
    }
}
