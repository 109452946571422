import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCardFieldItemComp.scss'
import {action} from "mobx";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {FieldItem} from "../../agroAhoCardStore";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";
import classNames from "classnames";


export interface IAgroAhoCardFieldItemCompProps {
    store: IAgroAhoSuperStore;
    field: FieldItem;
}

@observer
export class AgroAhoCardFieldItemComp extends React.PureComponent<IAgroAhoCardFieldItemCompProps, undefined> {
    constructor(props: IAgroAhoCardFieldItemCompProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        this.props.store.agroAhoStore.cardStore.doFieldClick(this.props.field);
    }

    @action
    onZoomClick(e: any){
        this.props.store.agroAhoStore.cardStore.doFieldZoomClick(this.props.field);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    render() {

        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let field = this.props.field;
        let area: any = field.area_ha && parseFloat(field.area_ha.toFixed(1));
        area = store_.numWithSpaces(area);

        return <div className={classNames("AgroAhoCardFieldItemComp-main", {
            "AgroAhoCardFieldItemComp-hidden": field.filtered})}  onClick={this.onClick}>
            <AgroAhoRectCheckboxSvg className="AgroAhoCardFieldItemComp-checkbox" checked={field.checked}/>
            <span className="AgroAhoCardFieldItemComp-name">{field.field_name}</span>
            <span className="AgroAhoCardFieldItemComp-crop">{field.crop_name}</span>
            <div className="AgroAhoCardFieldItemComp-rightCover">
                {area && <div className="AgroAhoCardFieldItemComp-ha">
                    <span>{area}</span>
                    <span>&nbsp;{store.trans.ha}</span>
                </div>}
                <div className="AgroAhoCardFieldItemComp-zoomCover" onClick={this.onZoomClick}>
                    <AgroAhoZoomSvg/>
                </div>
            </div>
        </div>
    }
}
