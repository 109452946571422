import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {isFunction} from "lodash-es";



export interface IAgroAhoSortColSvgProps {
    className?: string;
}

export class AgroAhoSortColSvg extends React.PureComponent<IAgroAhoSortColSvgProps, undefined> {
    constructor(props: IAgroAhoSortColSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L3.5 4L7 0H0Z"/>
        </svg>
    }
}