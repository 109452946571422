import React from 'react'
import { SuperStore } from '../../../../store/SuperStore';
import { observer } from 'mobx-react';

interface IProps {
	store: SuperStore; 
  }
const DragCancelModalComp: React.FC<IProps> = observer(({store} )=> {

  const onCancelClick = () => {
    store.layerListStore.callHandleConfirmCancelFunction();
     store.layerListStore.isProjectChanging = false
  };
  const onReturnClick = () => {
     store.layerListStore.showCancelPopup = false
     store.layerListStore.isProjectChanging = false
  }
  let isProjectChanging = store.layerListStore.isProjectChanging
  return (
	<div className='modal-back'>
                    <div className='modal-full modal-full-frame DraggbleOverlaysCompPopup-window'>
                    <div className="DraggbleOverlaysCompPopup-window-title"> {isProjectChanging? 'Отмена изменений при смене  проекта' :'Отмена изменений в панели слоев'}</div>
                    <div className="DraggbleOverlaysCompPopup-window-sub-title"> Вы уверены, что хотите <strong>отменить</strong> изменения в панели слоев? Внесенные правки не будут сохранены!</div>
                    <div className="DraggbleOverlaysComp-separator"></div>
                    <div className="DraggbleOverlaysComp-edit-buttons">
                    <button className="DraggbleOverlaysComp-cancel-button button-jump" 
                    onClick={onReturnClick}>{store.trans.Return}</button>
                    <button className="DraggbleOverlaysComp-save-button button-jump" 
                    onClick={onCancelClick}>{isProjectChanging?store.trans["Go to another project"]:store.trans["Exit without save"]}</button>
                    </div>
                    </div>
                </div>
  )
})

export default DragCancelModalComp