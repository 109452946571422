import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {GeoJSON, MapContainer, Polygon, TileLayer, useMap} from 'react-leaflet'
import {LatLngBounds, Map as LeafletMap} from "leaflet";
import 'leaflet/dist/leaflet.css';
import {SuperStore} from "../../../../store/SuperStore";
import bbox from "@turf/bbox";
import md5 from "md5";
import {SearchItemGroup} from "../../../../store/SearchItemGroup";
import classNames from "classnames";
import {SceneUiType} from "../../../../store/SearchStore";
import {MapComp} from "../../Map/MapComp";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {Satellite, SatEnum2} from "../../../../helper/utils/satellliteDic";
import {  observable, reaction } from 'mobx';
import { cloneDeep, debounce, throttle } from 'lodash-es';


const SearchSceneMiniMap2AutoCenterComp = observer((props: {store: SuperStore, searchGroup: SearchItemGroup}): any => {
    let store = props.store;
    const map = useMap();

    let bboxAll: BBox2d = null;
    if (store.map.searchObject.isEmpty) {
        bboxAll = props.searchGroup.getBbox();
        let box2 = new LatLngBounds({lat: bboxAll[1], lng: bboxAll[0]}, {lat: bboxAll[3], lng: bboxAll[2]});
        map.flyToBounds(box2, {animate: false});
    }else{
        var geom = getGeoJson(store);
        let bb = bbox(geom);

        if (map != null){
            let box2 = new LatLngBounds({lat: bb[1], lng: bb[0]}, {lat: bb[3], lng: bb[2]});
            map.flyToBounds(box2, {animate: false});
        }
    }


    return null;
});

function getGeoJson(store: SuperStore): any{
    if (store.map.searchObject.isNotEmpty){
        return store.map.searchObject.content;
    }else{
        return  store.map.bbox.geoJson;
    }
}

export interface ISearchSceneMiniMap2Comp extends IStoreProps{
    searchGroup: SearchItemGroup
}
@observer
export class SearchSceneMiniMap2Comp extends React.Component<ISearchSceneMiniMap2Comp, undefined> {
    @observable
    key: string;
    coordinate: typeof this.props.store.map.bbox.geoJson;
    debounceUpdateCoordinates = debounce(this.updateCoordinates, 700)
    constructor(props: ISearchSceneMiniMap2Comp) {
        super(props);
        autoBindReact(this);
        this.key = this.generateKey();
        this.coordinate = this.getCoordinate()
        reaction(
            () => this.props.store.map.bbox.geoJson,
            () => {
                this.debounceUpdateCoordinates();
            }
        );
    }
    leaflet: LeafletMap;
    generateKey() {
        return md5(JSON.stringify(this.props.store.map.bbox.geoJson))
    }
    getCoordinate(){
        return cloneDeep(this.props.store.map.bbox.geoJson)
    }
    updateCoordinates() {
        this.coordinate = this.getCoordinate(); 
        this.key = this.generateKey(); 
    }
    whenCreate(map: LeafletMap){
        this.leaflet = map;
    }

    componentWillUnmount() {
        this.leaflet = null;
      }
   
    render() {
        let store = this.props.store;
        let json: any = null;
        let key: string;

        let items: any[] = [];
        let sg = this.props.searchGroup;
        let minZoom = 0;

        if (store.map.searchObject.isNotEmpty) {
            json = getGeoJson(store);
            key = md5(JSON.stringify(json));
        }
        sg.items.forEach(si => {
            let meta = si.feature.properties;
            let url = this.props.store.map.mapScenesStore.getBaseTilesSource(meta);
            if (!meta.tiles_exists) return;
            if (meta.scene_type == SatEnum2.S5L2A) {
                url = this.props.store.map.mapScenesStore.getTileUrl(meta, SceneUiType.current);
                minZoom = 1;
            }else
            url += "&product=nat-c";
            if (meta.satellite == Satellite["Sentinel-1"]){
                url = this.props.store.map.mapScenesStore.getTileUrl(meta, SceneUiType.current);
                minZoom = 1;
            }

            items.push(<TileLayer key={si.sceneId()+url}
                url={url} minZoom={minZoom}
                maxZoom={MapComp.MAX_ZOOM} tileSize={MapComp.DEFAULT_TILE_SIZE}
                />
            );
        });
        
        
        return <div className={classNames(this.props.className)}  >
            <MapContainer  style={{width: "100%", height: "100%", background: "#262B32"}} whenCreated={this.whenCreate}
                          zoomControl={false} scrollWheelZoom={false} attributionControl={false}
                          dragging={false}
                          boxZoom={false}
                          touchZoom={false}
                          doubleClickZoom={false} >
                <GeoJSON data={this.coordinate} key={this.key}  pathOptions={{
                        stroke: true,color: 'black',weight: 4,opacity: 1,     fill:false }}/>
                {store.map.searchObject.isNotEmpty && <GeoJSON style={{ color: '#D9A541', fillColor: 'rgba(217, 165, 65, 0.9)', fill: true, weight:2 }} data={json} key={key} />}
                {items}
                 { <GeoJSON data={this.coordinate} key={`${this.key}-white`} style={{ color: 'white', fill: false, weight:2,className:'my"_border'}} />}          
                <SearchSceneMiniMap2AutoCenterComp store={store} searchGroup={this.props.searchGroup} />
            </MapContainer>
        </div>;
    }
}
