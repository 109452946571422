import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoTableUpload6Comp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {AgroAhoDownloadFileSvg} from "./../AgroAhoDownloadSvg";
import {Utils} from "../../../../app/helper/utils/Utils";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoStepsComp} from "./AgroAhoStepsComp";
import {AhoUploadTableColType, AhoUploadTableStage} from "../../agroAhoUploadTableStore";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoPointSvg} from "../icons/AgroAhoPointSvg";
import {AgroAhoCheckboxSvg} from "../icons/AgroAhoCheckboxSvg";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";

@observer
export class AgroAhoTableUpload6Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadCancelClick();
    }

    @action
    onClickBack(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadBackClick();
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        Utils.downloadFile(url);
    }

    @action
    onSaveClick(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (!this.canSave()) return;
        ustore.step6SaveClick();
    }

    canSave(){
        return true;
    }


    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let tstore = store_.tourStore;
        let log_ = JSON.stringify(ustore.getResData(), null, 2);
        let log = <pre>{log_}</pre>


        return <div className={classNames("AgroAhoTableUpload6Comp-main",{
            "AgroAhoTableUpload6Comp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoTableUpload6Comp-cover">
                <div className="AgroAhoTableUpload6Comp-topPanel">
                    <div className="AgroAhoTableUpload6Comp-titleCover">
                        <div className="AgroAhoTableUpload6Comp-title">{store_.trans['Step 5: View value intersections and errors']}</div>
                        <AgroAhoStepsComp step={AhoUploadTableStage.Step5}/>
                    </div>
                    <div className="AgroAhoTableUpload6Comp-titleCover">
                        {!ustore.fileError && <div className="AgroAhoTableUpload6Comp-titleStatus">
                            {store_.trans['The file has been processed successfully!']}</div>}
                        {ustore.fileError && <div className="AgroAhoTableUpload6Comp-titleErrorStatus">
                            {store_.trans['The file was processed with errors. Correct the following errors in the Excel file:']}</div>}
                        <div className="AgroAhoTableUpload6Comp-splitter"/>
                        <div className="AgroAhoTableUpload6Comp-titleFileName">{store_.trans.File}: {tstore.curTour?.tour_file_name}</div>
                        <div className="AgroAhoTableUpload6Comp-titleTourName">{store_.trans.Tour}: {tstore.curTour?.tour_name}</div>
                    </div>
                    <div className="AgroAhoTableUpload6Comp-line"/>
                </div>

                <div className="AgroAhoTableUpload6Comp-msg">
                    {/*<span>{store_.trans['Data must be uploaded in OpenDocument (.ods) format. This format is supported in MS Excel, Google Sheets, OpenOffice and other applications. The table must have a column with sample numbers, and the sample numbers must correspond to the numbers of elementary sections/cells in the ASA Map.']}</span>*/}
                    {/*<span>{store.trans['Agrochemical data can be downloaded in OpenDocument (.ods) format (supported by MS Excel, Google Sheets, OpenOffice, etc.). The column names must match the']}</span>*/}
                    {/*<span className="AgroAhoTableUpload6Comp-link" onClick={this.onDownloadSampleClick}>{store.trans['pattern']}</span>*/}
                    {/*<span>{store.trans['. How to correct the original spreadsheet file from the lab is described in']}</span>*/}
                    {/*<span className="AgroAhoTableUpload6Comp-link" onClick={this.onDownloadInstructionsClick}>{store.trans['instructions']}.</span>*/}
                </div>


                <div className="AgroAhoTableUpload6Comp-log style-4">
                    {log}
                </div>
                <div className="AgroAhoTableUpload6Comp-bottomPanel">
                    {/*<div className="AgroAhoTableUpload6Comp-linkSample" onClick={this.onDownloadSampleClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Sample}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload6Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Instruction}</span>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload6Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoTableUpload6Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoTableUpload6Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <div className="AgroAhoTableUpload6Comp-backButton" onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </div>
                    <div className={classNames("AgroAhoTableUpload6Comp-nextButton", {
                        "AgroAhoTableUpload6Comp-disabled": !this.canSave()
                        })} onClick={this.onSaveClick}>
                        <span>{store_.trans.Save}</span>
                    </div>
                </div>
            </div>
        </div>
    }
}
