import {Utils} from "../../../helper/utils/Utils";
import {IGraphDayValue} from "../IndexByPointGraphStore";
import {PrecipitationCategory} from "../IndexByPointerStore";
import {IndexByPointerSourceCustomSimpleStore} from "./IndexByPointerSourceCustomSimpleStore";

export class IndexByPointerSourceSimplePrecipEra5Store extends IndexByPointerSourceCustomSimpleStore{

    protected getRequest(): string{
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        return `/api/meteo/era5/time?from_date=${Utils.getDateStr(
            dateBegin
        )}&to_date=${Utils.getDateStr(dateEnd)}&lon=${
            gp.point.lng
        }&lat=${gp.point.lat}&data_type=prec`;
    }
    protected getValue(json: any): IGraphDayValue{
        let j: {date: string, prec: number} = json;
        return this.getGraphDataValue(j.date, j.prec);
    }
    protected getGraphDataInternal(): IGraphDayValue[]{
        return this.getData;
    }

    private get getData(): IGraphDayValue[]{
        let src: IGraphDayValue[];
        if (this.status == null) setImmediate(()=> { this.load() });
        src = this.data;
        if (this.parent.parent.precipitationCategory == PrecipitationCategory.daily) return src;
        if (this.parent.parent.precipitationCategory == PrecipitationCategory.accumulated) return this.accumGraphDayValue(src, 0);
        return [];
    }

}