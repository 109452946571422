import {IndexByPointerSourceCustomStore} from "./IndexByPointerSourceCustomStore";
import {IGraphData, IGraphDataInfo, IGraphDayValue} from "../IndexByPointGraphStore";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {fetchJson} from "../../../helper/utils/FetchUtils";
import {action, runInAction} from "mobx";

export abstract class IndexByPointerSourceCustomSimpleStore extends IndexByPointerSourceCustomStore {
    protected abstract getRequest(): string;
    protected abstract getValue(json: any): IGraphDayValue;


    getGraphData(): IGraphDataInfo{
        let res: IGraphData[] = [];
        let arr: IGraphDayValue[] = this.getGraphDataInternal();
        arr.forEach(a => {
            res.push({dayOfYear: a.dayOfYear, value: Math.round(a.value), sceneID: null});
        });
        return {data: res, hasMinMax: false};
    }


    protected abstract getGraphDataInternal(): IGraphDayValue[];

    protected readSourceArray(arr: any[]): IGraphDayValue[]{
        let res:IGraphDayValue[] = [];
        arr.forEach(a => {
            res.push(this.getValue(a));
        });
        return res;
    }
    @action
    protected load() {
        let url = this.getRequest();
        this.status = LoadStatus.loading;
        fetchJson(url)
            .then(json => {
                runInAction(() =>{
                    let arr: IGraphDayValue[] = [];
                    let r: any[] = json;
                    arr = this.readSourceArray(r);
                    this.data = arr;
                    this._status = LoadStatus.ready;
                });
            }).catch(err =>{
            runInAction(() => {
                this.root.addError(err);
                this.data = [];
                this.status = LoadStatus.ready;
            });
        });
    }
}
