import {CustomStore} from "../CustomStore";
import {A2ProjectInfo} from "./A2ProjectInfo";
import {isString} from "lodash-es";
import {Lang} from "../../../pluginApi/store/Lang";

export class A2TranslateStore extends CustomStore<A2ProjectInfo>{
    getColumnTitle(columnName: string): string{
        let cols = this.parent.projectInfoJson?.type_info?.type_schema?.obj?.view?.columns;
        if (cols == null) throw "Column name is not found";
        let column = cols.find(a => a.name == columnName);
        let name = column.title[this.root.getLang()];
        if (name == null) name = column.title.eng;
        if (name == null){
            let keys = Object.keys(column.title);
            if (keys.length > 0){
                name = column.title[keys[0] as Lang];
            }
        }
        return name;
    }
}