// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchSceneItemS5Comp-main {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 6px;
  transition: all 0.13s linear;
  color: #C5C5C5;
  cursor: pointer;
}

.SearchSceneItemS5Comp-divTitle {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}

.SearchSceneItemS5Comp-divImg {
  width: 100%;
  height: 140px;
  background: #262B32;
  border: 2px solid #3E4751;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.SearchSceneItemS5Comp-miniMap {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.SearchSceneItemS5Comp-quicklook {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  object-fit: cover;
  object-position: 0 43%;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/SearchPanel/SearchSceneItemS5Comp.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,4BAAA;EACA,cAAA;EACA,eAAA;AACF;;AACA;EACE,aAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAGF;;AADA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAIF;;AAFA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAKF","sourcesContent":[".SearchSceneItemS5Comp-main{\r\n  width: 100%;\r\n  margin-bottom: 15px;\r\n  border-radius: 6px;\r\n  transition: all 0.13s linear;\r\n  color: #C5C5C5;\r\n  cursor: pointer;\r\n}\r\n.SearchSceneItemS5Comp-divTitle {\r\n  display: flex;\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  margin-bottom: 5px;\r\n}\r\n.SearchSceneItemS5Comp-divImg {\r\n  width: 100%;\r\n  height: 140px;\r\n  background: #262B32;\r\n  border: 2px solid #3E4751;\r\n  box-sizing: border-box;\r\n  border-radius: 6px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 5px;\r\n}\r\n.SearchSceneItemS5Comp-miniMap {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: relative;\r\n  cursor: pointer;\r\n}\r\n.SearchSceneItemS5Comp-quicklook {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: relative;\r\n  cursor: pointer;\r\n  object-fit: cover;\r\n  object-position: 0 43%;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
