import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {PhotoFileItemStore} from "../../../../../store/photo/PhotoFileItemStore";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {isFunction} from "lodash-es";
import {
    IPhotoFilterInfo,
    PhotoFilterType,
    PhotoPanelTopFilterItemComp
} from "../PhotoFiltersElements/PhotoPanelTopFilterItemComp";
import classNames from "classnames";
import {Utils} from "../../../../../helper/utils/Utils";

export interface ITagsForPhoto extends IStoreProps {
    item?: PhotoFileItemStore;
    photo_id: number;
    tags: number[];
    onDeleteTag?: (photo_id: number, tag_id: number)=>void;
    className?: string;
}

@observer
export class TagsForPhoto extends React.Component<ITagsForPhoto, undefined> {
    constructor(props: ITagsForPhoto) {
        super(props);
        autoBindReact(this);
    }

    @action
    onDeleteTag(data: IPhotoFilterInfo){
        let id: number = data.id;
        let photo_id = this.props.photo_id;
        let f = this.props.store.photo.features.find(a => a.properties.photo_id == photo_id);
        if (f != null) f.properties.tags = f.properties.tags.filter(a => a != id);
        let it = this.props.item;
        if (it != null) {
            it.tags_ids = it.tags_ids.filter(a => a != id);
        }
        if (isFunction(this.props.onDeleteTag)) this.props.onDeleteTag(photo_id, id);
    }

    render() {
        let store = this.props.store;

        let tags: any[] = [];
        let checkedTagIds = Utils.toObject(this.props.store.photo.photoFilter.tagGroupFilter.checkedTagsId.map(a => a.tag_id.toString()), true);

        let dic = store.photo.getTagByIdDictonary;
        this.props.tags.forEach(a => {
            let tag = dic.get(a);
            if (tag){
                let hl: boolean = false;
                if (checkedTagIds[a.toString()]) hl = true;//такой тег есть в фильтрах
                tags.push(<PhotoPanelTopFilterItemComp text={tag.tag_name} hasDelete={true} onClickDelete={this.onDeleteTag}
                                                       highlight={hl}
                                                       data={{filterType: PhotoFilterType.tag, id: a}}
                                                       store={store} key={tag.tag_id.toString()} />);
            }
        });

        return <div className={classNames("UploadItemComp-properiesItemTags-m", this.props.className)}>
            {tags}
        </div>;
    }
}
