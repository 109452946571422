import {GeometryUtils, ISimpleGeometry} from "../utils/GeometryUtils";
import {
    GActionType,
    IGAction,
    IGActionGeometry,
    IGActionGroup,
    IGActionHole,
    IGActionPoint,
    IGActionTransaction
} from "./IGeometryAction";
import {cloneDeep} from "lodash-es";
import autoBind from "auto-bind";
import {Utils} from "../utils/Utils";


export class GeometryActionApply {
    constructor() {
        autoBind(this);
    }

    simpleGeometry: ISimpleGeometry[];

    static apply(simpleGeometry: ISimpleGeometry[], action: IGActionTransaction){
        let t = new GeometryActionApply();
        t.simpleGeometry = simpleGeometry;
        t.doAction(action);
    }
    static reversActionType(type: GActionType): GActionType{
        switch (type) {
            case GActionType.deleteHole: return GActionType.insertHole;
            case GActionType.insertHole: return GActionType.deleteHole;
            case GActionType.insertGeom: return GActionType.deleteGeom;
            case GActionType.deleteGeom: return GActionType.insertGeom;
            case GActionType.insertPoint: return GActionType.deletePoint;
            case GActionType.deletePoint: return GActionType.insertPoint;
            case GActionType.movePoint: return GActionType.movePoint;
            case GActionType.group: return GActionType.group;
            default:
                return type;
        }
    }
    static reversTransaction(trans: IGActionTransaction): IGActionTransaction{
        let r = GeometryActionApply.reversAction(trans) as IGActionTransaction;
        r.transactionType = trans.transactionType;
        return r;
    }
    static reversAction(action: IGAction): IGAction{
        if (action.actionType == GActionType.insertPoint || action.actionType == GActionType.deletePoint || action.actionType == GActionType.movePoint){
            let t: IGAction  = {
                actionType: GeometryActionApply.reversActionType(action.actionType),
            };
            let t1 = t as IGActionPoint;
            t1.addrPoint = cloneDeep((action as IGActionPoint).addrPoint);
            t1.newValue = cloneDeep((action as IGActionPoint).oldValue);
            t1.oldValue = cloneDeep((action as IGActionPoint).newValue);
            return t;
        }
        if (action.actionType == GActionType.insertHole || action.actionType == GActionType.deleteHole){
            let t: IGAction  = {
                actionType: GeometryActionApply.reversActionType(action.actionType),
            };
            let t1 = t as IGActionHole;
            t1.addrContour = cloneDeep((action as IGActionHole).addrContour);
            t1.newValue = cloneDeep((action as IGActionHole).oldValue);
            t1.oldValue = cloneDeep((action as IGActionHole).newValue);
            return t;
        }
        if (action.actionType == GActionType.insertGeom || action.actionType == GActionType.deleteGeom){
            let t: IGAction  = {
                actionType: GeometryActionApply.reversActionType(action.actionType),
            };
            let t1 = t as IGActionGeometry;
            t1.index = (action as IGActionGeometry).index;
            t1.newValue = cloneDeep((action as IGActionGeometry).oldValue);
            t1.oldValue = cloneDeep((action as IGActionGeometry).newValue);
            return t;
        }
        if (action.actionType == GActionType.group){
            let t: IGActionGroup = {
                actionType: GActionType.group,
                actions: (action as IGActionGroup).actions.reverse().map(a => GeometryActionApply.reversAction(a))
            };
            return t;
        }
        throw "unknow type geometry action";
    }

    doAction(action: IGAction) {
        switch (action.actionType) {
            case GActionType.insertPoint:
                this.insertPoint(action as IGActionPoint);
                break;
            case GActionType.deletePoint:
                this.deletePoint(action as IGActionPoint);
                break;
            case GActionType.movePoint:
                this.movePoint(action as IGActionPoint);
                break;
            case GActionType.deleteGeom:
                this.deleteGeometry(action as IGActionGeometry);
                break;
            case GActionType.insertGeom:
                this.insertGeometry(action as IGActionGeometry);
                break;
            case GActionType.insertHole:
                this.insertHole(action as IGActionHole);
                break;
            case GActionType.deleteHole:
                this.deleteHole(action as IGActionHole);
                break;
            case GActionType.group:
                let t = action as IGActionGroup;
                if (t.actions) t.actions.forEach((a)=>{
                    this.doAction(a);
                });
                break;
        }
    }

    private insertGeometry(action: IGActionGeometry){
        Utils.arrayInsert(this.simpleGeometry, action.index, action.newValue);
    }
    private deleteGeometry(action: IGActionGeometry){
        Utils.arrayRemoveByIndex(this.simpleGeometry, action.index);
    }

    private insertHole(action: IGActionHole){
        if (action.addrContour[1] > 0){
            let t = this.simpleGeometry[action.addrContour[0]];
            Utils.arrayInsert(t.holes, action.addrContour[1] - 1, action.newValue);
        }
    }
    private deleteHole(action: IGActionHole){
        GeometryUtils.deleteContourByAddr(this.simpleGeometry, action.addrContour);
    }
    private insertPoint(action: IGActionPoint){
        GeometryUtils.insertGeometryPoint2(this.simpleGeometry,action.addrPoint, action.newValue);
    }
    private deletePoint(action: IGActionPoint){
        GeometryUtils.removeGeometryPoint2(this.simpleGeometry, action.addrPoint, false);
    }
    private movePoint(action: IGActionPoint){
        GeometryUtils.setGeometryPoint2(this.simpleGeometry, action.addrPoint, action.newValue);
    }
}