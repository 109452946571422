import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {SearchItemGroup} from "../../../../store/SearchItemGroup";
import classNames from "classnames";
import {action, observable} from "mobx";
import './SearchSceneItemS5Comp.scss';
import {Utils} from "../../../../helper/utils/Utils";
import {SearchSceneMiniMap2Comp} from "./SearchSceneMiniMap2Comp";

export interface ISearchSceneItemS5Comp extends IStoreProps{
    searchGroup: SearchItemGroup
}

@observer
export class SearchSceneItemS5Comp extends React.Component<ISearchSceneItemS5Comp, undefined> {
    constructor(props: ISearchSceneItemS5Comp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        let store = this.props.store;
        if (store.searchPanel.currentSceneid == this.props.searchGroup.first().feature.properties.scene_id){
            store.searchPanel.currentSceneid = null;
        }else {
            store.searchPanel.currentSceneid = this.props.searchGroup.first().feature.properties.scene_id;
        }
        store.searchPanel.currentSceneVisible = true;//насильно включаем видимость.
    }

    @observable
    showError: boolean = false;
    @observable
    loaded: boolean = false;

    @action
    onError(){
        this.showError = true;
        this.forceUpdate();
    }
    @action
    onImgLoad(){
        this.loaded = true;
    }

    render() {
        let store = this.props.store;
        let sg = this.props.searchGroup;
        let it = sg.first().feature.properties;
        let isCurrent = sg.isCurrent();
        let dateText = it.acqdate;
        if (it.s5_date_begin != null && it.s5_date_end != null){
            dateText = Utils.formatDate(new Date(it.s5_date_begin))+" - "+Utils.formatDate(new Date(it.s5_date_end));
        }


        return <div className="SearchSceneItemS5Comp-main" onClick={this.onClick}>
            <div className="SearchSceneItemS5Comp-divTitle flex-columns-centerByVertical">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.0625 1.875H12.1875V0H9.375V1.875H5.625V0H2.8125V1.875H0.9375C0.42 1.875 0 2.295 0 2.8125V14.0625C0 14.58 0.42 15 0.9375 15H14.0625C14.58 15 15 14.58 15 14.0625V2.8125C15 2.295 14.58 1.875 14.0625 1.875ZM13.125 13.125H1.875V6.5625H13.125V13.125Z" fill="#3A7BAF"/>
                </svg>
                <span className="text-next">{dateText}</span>
            </div>
            <div className={classNames("SearchSceneItemS5Comp-divImg", {"blue-active": isCurrent})}>
                {/*<SearchSceneMiniMapComp searchGroup={this.props.searchGroup}
                                    className="SearchSceneItemS5Comp-miniMap"
                                    store={store}
                                    zoom={store.map.zoom - 4} center={store.map.center.getMbPoint()} />*/}
                <SearchSceneMiniMap2Comp searchGroup={this.props.searchGroup} store={store} className="SearchSceneItemS5Comp-miniMap" />
            </div>
        </div>;
    }
}
