import autoBindReact from "auto-bind/react";
import {action} from "mobx";
import {observer} from "mobx-react";
import React from "react";
import './EgrnPanelComp.scss'
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {fetchJsonGet, fetchJsonPut} from "../../../../helper/utils/FetchUtils";
import {RefreshSvg} from "../../../icons/RefreshSvg";
import {OrderStatusReady} from "../OrderList/OrderStatusReady";
import {OrderStatusError} from "../OrderList/OrderStatusError";
import {OrderStatusWork} from "../OrderList/OrderStatusWork";
import {EgrnInfoWindow} from "./EgrnInfoWindow";

@observer
export class EgrnPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onCadnumChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let egrn = this.props.store.egrnStore;
        if (egrn.cadastralNumber != e.currentTarget.value) {
            egrn.cadastralNumber = e.currentTarget.value;
            egrn.invalidCadNumber = false;
        }
    }

    @action
    onApply() {
        let egrn = this.props.store.egrnStore;
        egrn.invalidCadNumber = ! /^\d{1,2}:\d{1,2}:\d{1,7}:\d{1,6}$/.test(egrn.cadastralNumber);
        if (egrn.invalidCadNumber) return;
        let dt : any = new Date()
        fetchJsonPut(`/api/projects/proj109/reference/request/add`, 
            {"values": [{"cadastr_num":egrn.cadastralNumber, "req_ins_tm":new Date(dt - dt.getTimezoneOffset() * 60 * 1000).toISOString()}]})
        .then((r)=>{
            egrn.cadastralNumber = "";
            this.props.store.egrnStore.loadOrders();
        }).catch((err)=>{this.props.store.root.addError(err)});

    }

    @action
    onClickReload() {
        this.props.store.egrnStore.loadOrders();
    }

    @action
    onClickParcel(e : any) {
        let id = e.currentTarget.getAttribute('data-key');
        fetchJsonGet(`/api/egrn/order/${id}`).then(r => {
            this.props.store.egrnStore.activeParcel = r;
        }).catch(err => {this.props.store.root.addError(err)});        
    }

    render() {
        let store = this.props.store; 
        let egrn = store.egrnStore;

        let items: any[] = [];
        egrn.orders.forEach( order => {
            let dt = "";
            if (order.dt_done && order.dt_create) {
                let d = (((new Date(order.dt_done) as any) - (new Date(order.dt_create) as any)) as number) / 1000;
                let [days, hours, mins, secs] = [Math.floor(d / 60 / 60 / 24), Math.floor(d / 60 / 60 % 24), Math.floor(d / 60 % 60), Math.floor(d % 60)];
                if (Math.abs(days) > 1) dt = `${days} d ${hours} h`;
                else if (Math.abs(hours) > 1) dt = `${hours} h ${mins} min`;
                else if (Math.abs(mins) > 1) dt = `${mins} min ${secs} sec`;
                else dt = `${secs} sec`;
            }
            items.push(<tr className="EgrnPanelComp-parcel" key={order.id} data-key={order.id} onClick={this.onClickParcel}>
                    <td className="EgrnPanelComp-cadnum">{order.user_cadnum}</td>
                    <td>{dt}</td>
                    <td>
                        {order.state == "SUCCESS" && <OrderStatusReady store={store} />}
                        {order.state == "FATAL" && <OrderStatusError store={store}/>}
                        {! (order.state in {"FATAL": 1, "SUCCESS": 1}) && <OrderStatusWork store={store}/>}
                    </td>
                </tr>)
        });

        return <div className="EgrnPanelComp-main">
            <div className='EgrnPanelComp-param-order'>
                <div className="EgrnPanelComp-paramname">Cadastral num:</div>
                <input type="text" maxLength={20} placeholder="12:34:1234567:1234"
                    className={classNames("text-box-editor width100 number-without-arrow", {"red_border": egrn.invalidCadNumber})} 
                    value={egrn.cadastralNumber} onChange={this.onCadnumChanged}/>
                <button onClick={this.onApply} disabled={egrn.invalidCadNumber || egrn.cadastralNumber == ""}
                        className="large-button left-sidebar-active button-jump EgrnPanelComp-applyButton">
                    {store.trans.Order}
                </button>
            </div>
            <div>
                <div className="flex-columns flex-align-items-center">
                    {egrn.loading && <div className="journal-body-title header-text text-white2">{store.trans["Loading..."]}</div>}
                        {!egrn.loading && <div className="journal-body-title header-text text-white2">{store.trans["Order history"]}</div>}
                        <span className="icon_container icon_container_fill relative" onClick={this.onClickReload}>
                            <div className={classNames("full-center-content", {"rotating": egrn.loading})}>
                                <RefreshSvg className="" />
                            </div>
                        </span>
                </div>
                <div><table className="EgrnPanelComp-table">
                    <thead><tr><th>Cadastral number</th><th>Execution time</th><th>Status</th></tr></thead>
                    <tbody>{items}</tbody></table></div>
            </div>
            {egrn.activeParcel && <EgrnInfoWindow store={store}></EgrnInfoWindow>}
        </div>;
    }
}