import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {SwitchText} from "../../../../Common/SwitchText";
import classNames from "classnames";
import {PhotoPanelMode} from "../../../../../store/photo/PhotoStore";
import {action} from "mobx";
import {PhotoTagEditorWindow} from "../PhotoTagEditor/PhotoTagEditorWindow";

@observer
export class PhotoPanelSwitcher extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    clickTextSwitch(){
        if (!this.enabled()) return;
        if (this.props.store.photo.panelMode == PhotoPanelMode.photo){
            this.props.store.photo.panelMode = PhotoPanelMode.filters;
        }else{
            this.props.store.photo.panelMode = PhotoPanelMode.photo;
            this.props.store.photo.editor.editorWindowShow = false;
        }
    }

    @action
    onClickPhotoEditor(){
        if (!this.props.store.photo.editor.editorWindowShow){
            this.props.store.photo.panelMode = PhotoPanelMode.filters;
            this.props.store.searchPanel.closeAllLeftPanels();
        }
        this.props.store.photo.editor.editorWindowShow = !this.props.store.photo.editor.editorWindowShow;
    }

    enabled(): boolean{
        return true;//пока так
    }

    @action
    onClickTagEditor(){
        this.props.store.photo.tagEditor.showTagEditor();
    }

    render() {
        let store = this.props.store;
        return <div style={{paddingTop: "21px"}} className="PhotoPanel-margin PhotoPanel-switcherDiv">
            <SwitchText text1={store.trans.Gallery}
                        text2={store.trans.Filters}
                        disabledUnchecked={!this.enabled()}
                        className={classNames({"def-cursor": !this.enabled()})}
                        check1={store.photo.panelMode == PhotoPanelMode.photo}
                        onClick={this.clickTextSwitch}
            />
            <div className="flex-stretch-item" />
            <div className="icon_container icon_container_fill qtransit text-next" onClick={this.onClickTagEditor}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.875 0H1.125C0.45 0 0 0.45 0 1.125V16.875C0 17.55 0.45 18 1.125 18H16.875C17.55 18 18 17.55 18 16.875V1.125C18 0.45 17.55 0 16.875 0ZM15.75 2.25V12.375H12.375C11.7 12.375 11.25 12.825 11.25 13.5V14.625H6.75V13.5C6.75 12.825 6.3 12.375 5.625 12.375H2.25V2.25H15.75Z" fill="#C5C5C5"/>
                    <path d="M13.5 4.5H4.5V6.75H13.5V4.5Z" fill="#C5C5C5"/>
                    <path d="M13.5 7.875H4.5V10.125H13.5V7.875Z" fill="#C5C5C5"/>
                </svg>
            </div>
            <div className={
                classNames("icon_container icon_container_fill icon_container-preferences qtransit text-next",
                    {"icon_container-preferences-active blue-fill-important": store.photo.editor.editorWindowShow})}
                 onClick={this.onClickPhotoEditor}
                 >
                <svg className="" width="31" height="18" viewBox="0 0 31 18" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M5.625 10.125L1.125 10.125C0.450001 10.125 1.10153e-06 10.575 9.83506e-07 11.25L1.96701e-07 15.75C7.86805e-08 16.425 0.45 16.875 1.125 16.875L5.625 16.875C6.3 16.875 6.75 16.425 6.75 15.75L6.75 11.25C6.75 10.575 6.3 10.125 5.625 10.125Z" />
                        <path d="M18 17L9 17L9 15L18 15L18 17Z" />
                        <path d="M18 12L9 12L9 10L18 10L18 12Z" />
                        <path d="M18 7L0 7L3.49691e-07 5L18 5L18 7Z" />
                        <path d="M18 2L0 2L3.49691e-07 0L18 3.14722e-06L18 2Z" />
                    </g>
                    <g>
                        <path d="M30 9.00047C30 9.16347 29.9205 9.31647 29.7865 9.40997L24.7865 12.91C24.6345 13.017 24.4345 13.029 24.269 12.944C24.1035 12.8575 24 12.687 24 12.5005L24 5.49997C24 5.31347 24.1035 5.14247 24.269 5.05647C24.4345 4.97147 24.634 4.98347 24.7865 5.09047L29.7865 8.59047C29.9205 8.68347 30 8.83647 30 8.99947C30 8.99997 30 8.99997 30 9.00047C30 8.99997 30 8.99997 30 9.00047Z" />
                    </g>
                </svg>
            </div>
            {this.props.store.photo.tagEditor.photoTagEditorWindowShow && <PhotoTagEditorWindow store={store} />}
        </div>;
    }
}
