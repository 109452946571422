import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import "./Export2Panel.scss";
import {ActiveSensorDay} from "./ActiveSensorDay";
import {CropmaskComp} from "./CropmaskComp";
import {Export2TypeOrders} from "../../../../store/export/ExportStore";
import {action} from "mobx";
import {Export2ModisComp} from "./Export2ModisComp";
import {DropDownComp, IDropDownItem} from "../../../Common/DropDownComp";
import {Export2LandsatComp} from "./Export2LandsatComp";
import {FieldsDelineation} from "./FieldsDelineation";


@observer
export class Export2PanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    clickSwitchOrderType(item: IDropDownItem){
        this.props.store.exportStore.export2TypeOrders = item.key as any;
    }

    render() {
        let store = this.props.store;
        let exportStore = this.props.store.exportStore;
        let swItems: IDropDownItem[] =
            [
                {key:Export2TypeOrders.Sentinel1, value: store.trans["Sentinel-1"]},
                {key:Export2TypeOrders.Landsat, value: "Landsat"},
                {key:Export2TypeOrders.Sentinel2, value: "Sentinel-2"},
                {key: Export2TypeOrders.FieldsDelineation, value: "Fields delineation"}
            ];


        return <div className="Export2Panel-main">
            <div className="Export2Panel-title">{store.trans.Orders}</div>
            <div className="style-4 table-scroll flex-stretch-item">
                <div className="Export2Panel-switchMode ">
                    {store.trans["Order type"]}
                    <DropDownComp items={swItems} currentKey={this.props.store.exportStore.export2TypeOrders}
                                  onChange={this.clickSwitchOrderType}
                                  className="Export2Panel-switchModeDropdown width100" />
                </div>
                {exportStore.export2TypeOrders == Export2TypeOrders.Sentinel1 && <ActiveSensorDay store={store} />}
                {exportStore.export2TypeOrders == Export2TypeOrders.Landsat && <Export2LandsatComp store={store} satExport={exportStore.landsatExport} />}
                {exportStore.export2TypeOrders == Export2TypeOrders.Sentinel2 && <Export2LandsatComp store={store} satExport={exportStore.sentinel2Export} />}
                {exportStore.export2TypeOrders == Export2TypeOrders.FieldsDelineation && <FieldsDelineation store={store} /> }
            </div>
        </div>;
    }
}
