import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditCellsPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";
import {AgroAhoOdsSvg} from "../icons/AgroAhoOdsSvg";
import {AgroAhoXlsxSvg} from "../icons/AgroAhoXlsxSvg";
import {EditCellsStatus} from "../../card/agroAhoEditCells";
import classNames from "classnames";
import {AhoUtils} from "../../agroAhoUtils";


@observer
export class AgroAhoEditCellsPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    onClickLoadTable(){
        let estore = this.props.store.agroAhoStore.cardStore.editCellsStore;
        estore.loadTable();
    }

    onClickSave(){
        let estore = this.props.store.agroAhoStore.cardStore.editCellsStore;
        if (estore.status != EditCellsStatus.Success) return;
        estore.onSave();
    }

    onLoadTemplate(){
        let estore = this.props.store.agroAhoStore.cardStore.editCellsStore;
        estore.onLoadTemplate();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let estore = store_.cardStore.editCellsStore;

        let listTitle = <span> </span>;
        if (estore.status == EditCellsStatus.NoNamesErr)
            listTitle = <span>{store_.trans['There are no cells with such names in the ASA map']}:</span>;
        if (estore.status == EditCellsStatus.DuplicateErr)
            listTitle = <span>{store_.trans['There are duplicate values in the column cell_name']}:</span>;
        if (estore.status == EditCellsStatus.DuplicateNewErr)
            listTitle = <span>{store_.trans['There are duplicate values in the column cell_name_new']}:</span>;
        if (estore.status == EditCellsStatus.FileErr)
            listTitle = <span>{store_.trans['Error while processing. Check file integrity.']}</span>;
        if (estore.status == EditCellsStatus.NoOldNameColumnErr)
            listTitle = <span>{store_.trans['Column «cell_name» not found - old cell names']}</span>;
        if (estore.status == EditCellsStatus.NoNewNameColumnErr)
            listTitle = <span>{store_.trans['Column «cell_name_new» not found - new cell names']}</span>;
        if (estore.status == EditCellsStatus.Success)
            listTitle = <span className="AgroAhoEditCellsPanelComp-success">{
                `${store_.trans['File successfully processed, number of replacements']}: ${estore.list.length}`}</span>;

        let btnMsg = <span> </span>;
        if (estore.status == EditCellsStatus.Success)
            btnMsg = <span>{`${store_.trans['New values taken from file']}: ${estore.file_name}`}</span>;
        if (estore.status != EditCellsStatus.Unset && estore.status != EditCellsStatus.Success)
            btnMsg = <span className="AgroAhoEditCellsPanelComp-err">{store_.trans['Please correct the errors and re-upload the file']}</span>;
        // console.log('LOG:', AhoUtils.cp(estore.list), AhoUtils.cp(estore.status));
        let list: any = [];
        if (estore.status != EditCellsStatus.Unset && estore.status != EditCellsStatus.Success)
            list = estore.list.map((v,i)=><div key={i}>{v}</div>);
        if (estore.status == EditCellsStatus.Success)
            list = estore.list.map((v,i)=>
                <div className="AgroAhoEditCellsPanelComp-successItem" key={i}>
                    <span className="AgroAhoEditCellsPanelComp-oldVal">{v.old}</span>
                    <span>{v.new}</span>
                </div>);

        return <div className="AgroAhoEditCellsPanelComp-main">
            <div className="AgroAhoEditCellsPanelComp-caption">{store_.trans['Editing cell names']}</div>
            <div className="AgroAhoEditCellsPanelComp-msg">
                <span>{store_.trans['Download the template table for editing cell names from the link below («Download template»). This table has two columns: ']}</span>
                <b>cell_name — </b>
                <span>{store_.trans['old names']}, </span>
                <b>cell_name_new — </b>
                <span>{store_.trans['column for new names. If you do not want to change the cell name, leave the corresponding position in the column ']}</span>
                <b>cell_name_new </b>
                <span>{store_.trans.empty}.</span><br/>
                <b>{store_.trans['DO NOT change ']}</b>
                <span>{store_.trans['the column names! After that, upload the completed table to the Agrometrika service by clicking the «Upload table with new names» button. The uploaded table must be ']}</span>
                <b>{store_.trans['strictly in the OpenDocument (.ods) format.']}</b>
            </div>
            <div className="AgroAhoEditCellsPanelComp-contentCover">
                <div className="AgroAhoEditCellsPanelComp-contentTitle" onClick={this.onLoadTemplate}>
                    <AgroAhoOdsSvg className="AgroAhoEditCellsPanelComp-contentSvg"/>
                    <div className="AgroAhoEditCellsPanelComp-contentSvgCover">
                        <div className="AgroAhoEditCellsPanelComp-contentCaption">{store_.trans['Download template']}</div>
                        <div className="AgroAhoEditCellsPanelComp-contentSubCaption">{store_.cardStore.card.map_name}</div>
                    </div>
                </div>
                <div className="AgroAhoEditCellsPanelComp-colCover">
                    <div className="AgroAhoEditCellsPanelComp-leftCol">
                        <div className="AgroAhoEditCellsPanelComp-downloadButton" onClick={this.onClickLoadTable}>{
                            store_.trans['Download table with new names']}</div>
                        {estore.status != EditCellsStatus.Unset && <div className="AgroAhoEditCellsPanelComp-xlsxCover">
                            <AgroAhoXlsxSvg className="AgroAhoEditCellsPanelComp-xlsxSvg"/>
                            <div className="AgroAhoEditCellsPanelComp-xlsxCaption">{estore.file_name}</div>
                        </div>}
                    </div>
                    <div className="AgroAhoEditCellsPanelComp-rightCol">
                        {estore.status != EditCellsStatus.Unset && <div className="AgroAhoEditCellsPanelComp-listCover">
                            <div className="AgroAhoEditCellsPanelComp-listMsg">{listTitle}</div>
                            {estore.status == EditCellsStatus.Success && <div className="AgroAhoEditCellsPanelComp-listTitle">
                                <div className="AgroAhoEditCellsPanelComp-oldVal">{store_.trans['Old values']}</div>
                                <div>{store_.trans['New values']}</div>
                            </div>}
                            <div className="AgroAhoEditCellsPanelComp-list style-4">{list}</div>
                        </div>}
                    </div>
                </div>

            </div>
            <div className="AgroAhoEditCellsPanelComp-line"> </div>
            <div className="AgroAhoEditCellsPanelComp-buttonCover">
                <div className="AgroAhoEditCellsPanelComp-buttonMsg">{btnMsg}</div>
                <div className="AgroAhoEditCellsPanelComp-splitter"> </div>
                <button className="large-button left-sidebar-cancel button-jump AgroAhoEditCellsPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className={classNames("large-button left-sidebar-active", {
                    "AgroAhoEditCellsPanelComp-disabled": estore.status != EditCellsStatus.Success,
                    "button-jump": estore.status == EditCellsStatus.Success
                })} onClick={this.onClickSave}>{store.trans.Save}</button>
            </div>
        </div>;
    }
}
