import {ObservableCustomStore} from "../../../../app/store/CustomStore";
import {AgroAhoStore} from "../../agroAhoStore";
import {Cfg} from "./agroAhoLayersConfig";
import {AgroAhoLayer} from "./agroAhoLayer";


export class AgroAhoLayers extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
        this.layersIds = Object.keys(Cfg);
    }

    parentStore: AgroAhoStore;
    layers: AgroAhoLayer[] = [];
    layersIds: string[];

    testFunc(){
        this.layersIds.forEach(layerId=>{
            let layer = new AgroAhoLayer(this.parentStore, layerId);
            this.layers.push(layer);
            layer.testFunc();
        });
    }

}
