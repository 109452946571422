import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IProductItemComp} from "./ProductItemComp";
import classNames from "classnames";
import {action} from "mobx";
import {ProductStyleType} from "../../../../store/config/ConfigStore";
import {ProductItemPropStaticBandComp} from "./ProductItemPropStaticBandComp";
import { LeftPanelMode } from '../../../../store/SearchStore';

@observer
export class ProductItemPropComp extends React.Component<IProductItemComp, undefined> {
    constructor(props: IProductItemComp) {
        super(props);
        autoBindReact(this);
    }

    getStyleTypes(): ProductStyleType[]{
        let item = this.props.item;
        let cfg = item.productConfig;
        if (cfg == null || cfg.styles == null) return [];
        return cfg.styles.map(a => a.type);
    }

    @action
    onClickType(e: any){
        let att = e.currentTarget.getAttribute("data-typename");
        let item = this.props.item;
        item.styleType = att;
        this.props.item.changed = true;
    }
    @action
    onDefault(){
        this.props.item.loadDefault();
        this.props.item.changed = true;
    }
    @action
    onSwitchLUT(e: any){
        let item = this.props.item;
        let v = e.currentTarget.checked;
        if (v){
            item.styleType = ProductStyleType.LUT;
        }else{
            item.styleType = ProductStyleType.static;
        }
        this.props.item.changed = true;
    }

    render() {
        let store = this.props.store;
        let item = this.props.item;
        let curType = item.styleType
        let types = this.getStyleTypes();
        let isDisable = store.searchPanel.leftPanelMode != LeftPanelMode.favorite && !!!store.searchPanel.currentSceneid;
        let arr: any[] = [];
        if (curType == ProductStyleType.static || curType == ProductStyleType.LUT){
            let stat = item.staticStyle;
            if (stat != null){
                stat.bands.forEach((a, index) => {
                    arr.push(<ProductItemPropStaticBandComp key={a.name} readonly={(curType != ProductStyleType.static)}
                                                            item={this.props.item}
                                                            store={store} band={a} index={index} />);
                });
            }
        }

        if (types.length > 0){
            arr.push(
                <div key="footer" className="ProductItemPropComp-footer">
                    <div onClick={this.onDefault} key="cancel" className={
                        classNames("ProductItemPropComp-button",
                            "active_on_hover white-fill pointer",
                            "full-center-content")}>
                        {store.trans.Default}
                    </div>
                </div>
            );
        }

        return <div className={classNames("ProductItemPropComp", {
                                   "ProductItemPropComp_disable": isDisable
                                  })}
        >
              <div className='ProductSettingsComp-info'>
                    <div>
                        <div className='ProductSettingsComp-info-row1'>
                            {
                                store.trans[
                                    "Range of values for the selected image:"
                                ]
                            }
                        </div>
                        <div className='ProductSettingsComp-info-row2'>
                            1 - 10 000
                        </div>
                    </div>
                    <div>
                        <svg
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389953 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9929 6.61524 17.0424 4.33019 15.3561 2.64391C13.6698 0.957621 11.3848 0.00712227 9 0ZM9 15.75C7.66498 15.75 6.35994 15.3541 5.24991 14.6124C4.13987 13.8707 3.27471 12.8165 2.76382 11.5831C2.25293 10.3497 2.11925 8.99251 2.3797 7.68314C2.64015 6.37377 3.28303 5.17103 4.22703 4.22703C5.17104 3.28302 6.37377 2.64015 7.68314 2.3797C8.99252 2.11925 10.3497 2.25292 11.5831 2.76381C12.8165 3.2747 13.8707 4.13987 14.6124 5.2499C15.3541 6.35993 15.75 7.66498 15.75 9C15.7447 10.7886 15.0318 12.5024 13.7671 13.7671C12.5024 15.0318 10.7886 15.7447 9 15.75Z'
                                fill='#4DB6BC'
                            />
                            <path
                                d='M9.5625 7.875H8.4375C8.12684 7.875 7.875 8.12684 7.875 8.4375V12.9375C7.875 13.2482 8.12684 13.5 8.4375 13.5H9.5625C9.87316 13.5 10.125 13.2482 10.125 12.9375V8.4375C10.125 8.12684 9.87316 7.875 9.5625 7.875Z'
                                fill='#4DB6BC'
                            />
                            <path
                                d='M9 6.75C9.62132 6.75 10.125 6.24632 10.125 5.625C10.125 5.00368 9.62132 4.5 9 4.5C8.37868 4.5 7.875 5.00368 7.875 5.625C7.875 6.24632 8.37868 6.75 9 6.75Z'
                                fill='#4DB6BC'
                            />
                        </svg>
                    </div>
                </div>
            {arr}
        </div>;
    }
}
