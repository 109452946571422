import {CustomStore} from "./CustomStore";
import {observable, runInAction} from "mobx";
import {fetchJson} from "../helper/utils/FetchUtils";

export class ConfirmationFormStore extends CustomStore {
    //@observable
    //show: boolean = false;

    @observable
    confirmed: boolean = null;

    doConfirmEmail(code: string) {
        fetchJson(`/api/auth/confirm_email?code=${code}`)
            .then(a => {
                runInAction(()=>{
                    if (a == true) this.confirmed = true;
                    if (a == false) this.confirmed = false;
                });
            })
            .catch(err => this.root.addError(err));
    }

}