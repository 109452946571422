import {ISuperStore} from "./store/SuperStore";
import {PluginsController} from "./PluginsController";
import {ReactNode} from "react";

export interface IClassPluginOptions<T extends ISuperStore = ISuperStore> {
    pluginName: string;
    initStore?: (store: T) => any,
    disposeStore?: (store: T) => any,
}

export function classRegPlugin<T extends ISuperStore>(options: IClassPluginOptions<T>): void{
    PluginsController.addPlugin(options);
}