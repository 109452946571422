import {CustomStore} from "../CustomStore";
import {action, computed, observable, runInAction} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {ProjType} from "../user/UserStore";
import {PhotoFileItemStore} from "./PhotoFileItemStore";


export class UploadImageStore extends CustomStore{

    @observable
    showPanel: boolean = false;

    @observable
    showChooseFiles: boolean = false;

    uploadElement: HTMLInputElement = null;

    @observable
    noLoadDups: boolean = true;

    @observable
    files: PhotoFileItemStore[] = [];

    @observable
    generalStatusUpload: LoadStatus = null;

    @observable
    hasError: boolean = false;


    currentSendBytes: number;
    lastSend: number;

    @computed
    get allUploaded(): boolean{
        return this.files.find(a => a.selected && a.status != LoadStatus.ready) == null;
    }

    @computed
    get totalSize(): number{
        let l = 0;
        this.files.forEach(a =>{
            if (!a.selected) return;
            l = l + a.size;
        });
        return l;
    }
    @computed
    get uploadSize(): number{
        let l = 0;
        this.files.forEach(a => {
            if (!a.selected) return;
            if (a.status == LoadStatus.ready) l = l + a.size;
            else if (a.status == LoadStatus.loading) l = l + a.size * (a.sendBytes / a.needSend);
        });
        return l;
    }

    @computed
    get selectAll(): boolean{
        let allSelected = true;
        if (this.files.length == 0) return false;
        this.files.forEach(a => {if (!a.selected) allSelected = false;});
        return allSelected;
    }
    set selectAll(value: boolean){
        runInAction(()=>{
            this.files.forEach(a => {a.selected = value});
        });
    }


    @action
    public startUpload(){
        this.generalStatusUpload = LoadStatus.loading;
        this.hasError = false;
        this.files.forEach(a => {
            if (a.status != LoadStatus.ready)
                a.resetBeforeUpload();
        });
        this.sendNextPart();
    }
    private sendNextPart(): boolean{
        let ths = this;
        for(let i3 = 0; i3 < ths.files.length; i3++) {
            if (ths.files[i3].status == null && ths.files[i3].selected) {
                this.uploadFile(ths.files[i3]);
                return true;
            }
        }
        return false;
    }

    private finishUpload(){
        this.generalStatusUpload = LoadStatus.ready;
    }

    @action
    private errorUpload(){
        this.hasError = true;
        this.generalStatusUpload = null;
        this.doCancel();
    }

    @action
    public cancelUpload(){
        this.doCancel();
        this.generalStatusUpload = null;
    }

    private doCancel(){
        this.files.forEach(a => {
            if (a.xhr != null) a.xhr.abort();
        });
    }

    @action
    private uploadFile(item: PhotoFileItemStore){
        let req = new XMLHttpRequest();

        let fd = new FormData();
        //fd.append("sandbox", sandboxId);
        //fd.append("startByte", startByte);
        fd.append("photo", item.content);
        if (this.noLoadDups) fd.append("skip_dupl", "1");
        else  fd.append("skip_dupl", "0");

        req.open("post", `/api/projects/${this.root.agro.projectName}/photo/add`);
        item.xhr = req;
        item.status = LoadStatus.loading;
        item.sendBytes = 0;
        item.needSend = item.size;//пока примерный размер отправки

        let ths = this;
        req.upload.onerror = (err) => {
            runInAction(()=> {
                item.status = null;
                item.xhr = null;
                ths.root.addError("Error on upload");
                this.errorUpload();
            });
        }
        ths.lastSend = 0;

        req.upload.onprogress = (event) => {
            runInAction(()=> {
                item.sendBytes = event.loaded;
                item.needSend = event.total;

                ths.currentSendBytes += (event.loaded - ths.lastSend); //подсчёт скорости
                ths.lastSend = event.loaded;
            });
        }

        req.onload = function() {
            runInAction(()=> {
                item.xhr = null;``
                if (req.status !== 200) { // анализируем HTTP-статус ответа, если статус не 200, то произошла ошибка
                    item.status = null;
                    ths.root.addError("Error upload");
                    ths.errorUpload();
                } else {
                    // если всё прошло гладко, выводим результат
                    item.status = LoadStatus.ready;
                    if (ths.allUploaded) {
                        ths.finishUpload();
                        return;
                    }else {
                        ths.sendNextPart();
                    }
                }
            });
        };
        req.send(fd);

    }
}