import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './ShowRoomPanelComp.scss';
import {MapFolderItemStore} from "../../../../store/map/mapLayers/MapFolderItemStore";
import {ShowRoomLayerComp} from "./ShowRoomLayerComp";
import {SwitchText} from "../../../Common/SwitchText";
import {action} from "mobx";

@observer
export class ShowRoomPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onSwitchFolder(check1: boolean){
        let store = this.props.store;
        let folderCheck1 = store.map.superMap.rootFolder.findByItemId("check1").asFolder;
        let folderCheck2 = store.map.superMap.rootFolder.findByItemId("check2").asFolder;
        if (check1){
            folderCheck1.visible = true;
            folderCheck2.visible = false;
        }else{
            folderCheck1.visible = false;
            folderCheck2.visible = true;

        }
        folderCheck1.setVisibleOnMap(folderCheck1.visible);
        folderCheck2.setVisibleOnMap(folderCheck2.visible);
    }

    render() {
        let store = this.props.store;
        let folderCheck1 = store.map.superMap.rootFolder.findByItemId("check1").asFolder;
        let folderCheck2 = store.map.superMap.rootFolder.findByItemId("check2").asFolder;
        let curCheckFolder: MapFolderItemStore = null;

        if (folderCheck1.visible) curCheckFolder = folderCheck1; else curCheckFolder = folderCheck2;
        let folderMain = store.map.superMap.rootFolder.findByItemId("main").asFolder;
        let mainChilds: any[] = [];
        folderMain.items.forEach(a => {
            mainChilds.push(<ShowRoomLayerComp layer={a.asLayer} key={a.itemId} store={store} />);
        });

        let folder2Childs: any[] = [];
        curCheckFolder.items.forEach(a => {
            folder2Childs.push(<ShowRoomLayerComp layer={a.asLayer} key={a.itemId} store={store} />);
        });
        return <div className="ShowRoomPanelComp-main">
            <div className="ShowRoomPanelComp-title">Data showroom</div>
            <div className="ShowRoomPanelComp-topFolder flex-rows">
                <div className="ShowRoomPanelComp-topFolderCheckBoxes flex-columns" >
                    <div className="flex-rows flex-stretch-item">
                        <SwitchText text1={folderCheck1.title} text2={folderCheck2.title} className="ShowRoomPanelComp-topFolderSwitcher"
                                    check1={folderCheck1.visible} onClick={this.onSwitchFolder} />
                        <div className="table-scroll style-4 flex-stretch-item">
                            {folder2Childs}
                        </div>
                    </div>
                    <div className="">
                        {folderCheck1.visible && <img src="/img/showroom/legend1.png"/>}
                        {folderCheck2.visible && <img src="/img/showroom/legend2.png"/>}
                    </div>
                </div>
                <div className="ShowRoomPanelComp-FolderFooter">
                    <div className="ShowRoomPanelComp-FolderFooterItem"><div className="ShowRoomPanelComp-FolderFooterItemBox" style={{background: "#F0AA53"}} />Wheat</div>
                    <div className="ShowRoomPanelComp-FolderFooterItem"><div className="ShowRoomPanelComp-FolderFooterItemBox" style={{background:"#E3DCCB"}} />Paddy</div>
                    <div className="ShowRoomPanelComp-FolderFooterItem"><div className="ShowRoomPanelComp-FolderFooterItemBox" style={{background:"#F0CD00"}} />Maize</div>
                    <div className="ShowRoomPanelComp-FolderFooterItem"><div className="ShowRoomPanelComp-FolderFooterItemBox" style={{background:"#8ADD21"}} />Sugarcane</div>
                    <div className="ShowRoomPanelComp-FolderFooterItem"><div className="ShowRoomPanelComp-FolderFooterItemBox" style={{background:"#B32121"}} />Pulses</div>
                </div>

            </div>
            <div className="ShowRoomPanelComp-bottomFolder flex-stretch-item table-scroll style-4">
                <div className="ShowRoomPanelComp-bottomFolderTitle">{folderMain.title}</div>
                {mainChilds}
            </div>
        </div>;
    }
}
