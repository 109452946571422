// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoProgressComp-main {
  border-top: 1px solid #22282F;
  height: 1px;
  margin: 16px;
}

.AgroAhoProgressComp-line {
  position: relative;
  height: 5px;
  top: -2.5px;
  background: #4DB6BC;
  border-radius: 2.5px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/AgroAhoProgressComp.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,WAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,oBAAA;AAEF","sourcesContent":[".AgroAhoProgressComp-main {\r\n  border-top: 1px solid #22282F;\r\n  height: 1px;\r\n  margin: 16px;\r\n}\r\n.AgroAhoProgressComp-line {\r\n  position: relative;\r\n  height: 5px;\r\n  top: -2.5px;\r\n  background: #4DB6BC;\r\n  border-radius: 2.5px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
