import React, { Fragment } from "react";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import { EyeSvg } from "../../../icons/MiniIcon/EyeSvg";
import { ColorPicker } from "./ColorPicker";
import { BorderSwitcher } from "./BorderSwitcher";
import { ContextMenuCommonComp, PopupCorner, PopupDirection } from "../../../Common/ContextMenuCommonComp";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { BorderStyleSvg } from "../../../icons/MiniIcon/BorderStyleSvg";
import { CancelEyeSvg } from "../../../icons/MiniIcon/CancelEyeSvg";
import { Visibility } from "maplibre-gl";
import { BorderStyle } from "./LayersListStore";
import tinycolor from "tinycolor2";
import classNames from "classnames";

export interface ILineStyleEditorCompProps extends IStoreProps {
    showLineStyle: boolean;
}

@observer
export class LineStyleEditorComp extends React.Component<ILineStyleEditorCompProps> {
    constructor(props: ILineStyleEditorCompProps) {
        super(props);
        autoBindReact(this);        
    }

    @observable
    state = {
        color: this.props.store.layerListStore.stylePaintProperties.borderColor,
        opacity: Math.round(100 * this.props.store.layerListStore.stylePaintProperties.borderOpacity) + '%',
        width: this.props.store.layerListStore.stylePaintProperties.borderWidth,
        dashedFreq: this.props.store.layerListStore.stylePaintProperties.dashedFreq,
        dashedSpace: this.props.store.layerListStore.stylePaintProperties.dashedSpace
    }

    @action
    onBorderColorChange(color: string) {
        let lls = this.props.store.layerListStore;
        lls.stylePaintProperties = {...lls.stylePaintProperties, borderColor: color}
        this.setState({color: color});
    }

    @action
    onBorderOpacityChange(opacity: number) {
        let lls = this.props.store.layerListStore;
        lls.stylePaintProperties = {...lls.stylePaintProperties, borderOpacity: opacity};
        this.setState({opacity: Math.round(100 * opacity) + '%'});
    }

    @action
    onOpenColorPicker () {
        this.props.store.layerListStore.borderPickerOpened = true;
    }

    @action
    onCloseColorPicker(e: any) {
        this.props.store.layerListStore.borderPickerOpened = false;
        e.stopPropagation();
    }

    @action
    onWidthChanging(e: any) {
        let w = e.currentTarget.value;
        //w = w.replaceAll('px', '');
        this.setState({width: w});
    }

    @action
    onWidthChange(e: any) {
        let w = e.currentTarget.value;
        let lls = this.props.store.layerListStore;
        //w = w.replaceAll('px', '');
        if (! isNaN(w) && Number(w) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, borderWidth: Number(w)}
        else
            this.setState({width: lls.stylePaintProperties.borderWidth});
    }

    @action
    onWidthKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        let lls = this.props.store.layerListStore;
        let w = this.state.width;
        if (! isNaN(w) && Number(w) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, borderWidth: Number(w)}
        else
            this.setState({width: lls.stylePaintProperties.borderWidth});
    }

    @action
    onFreqChanging(e: any) {
        let f = e.currentTarget.value;
        this.setState({dashedFreq: f});
    }

    @action
    onFreqChange(e: any) {
        let f = e.currentTarget.value;
        let lls = this.props.store.layerListStore;
        if (! isNaN(f) && Number(f) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, dashedFreq : Number(f)}
        else
            this.setState({dashedFreq: lls.stylePaintProperties.dashedFreq});
    }

    @action
    onFreqKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        let lls = this.props.store.layerListStore;
        let f = this.state.dashedFreq;
        if (! isNaN(f) && Number(f) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, dashedFreq: Number(f)}
        else
            this.setState({dashedFreq: lls.stylePaintProperties.dashedFreq});
    }

    @action
    onSpaceChanging(e: any) {
        let s = e.currentTarget.value;
        this.setState({dashedSpace: s});
    }

    @action
    onSpaceChange(e: any) {
        let s = e.currentTarget.value;
        let lls = this.props.store.layerListStore;
        if (! isNaN(s) && Number(s) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, dashedSpace : Number(s)}
        else
            this.setState({dashedSpace: lls.stylePaintProperties.dashedSpace});
    }

    @action
    onSpaceKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        let lls = this.props.store.layerListStore;
        let s = this.state.dashedSpace;
        if (! isNaN(s) && Number(s) >= 0)
            lls.stylePaintProperties = {...lls.stylePaintProperties, dashedSpace: Number(s)}
        else
            this.setState({dashedSpace: lls.stylePaintProperties.dashedSpace});
    }

    @action
    onBorderStyleChanges(style: BorderStyle) {
        let lls = this.props.store.layerListStore;
        lls.stylePaintProperties = {...lls.stylePaintProperties, borderStyle: style}
    } 

    @action
    onBorderVisibilityChange() {
        let lls = this.props.store.layerListStore;
        let vis : Visibility = lls.stylePaintProperties.borderVisibility == 'visible'? 'none' : 'visible';
        lls.stylePaintProperties = {...lls.stylePaintProperties, borderVisibility: vis}
    }

    @action
    onHexChanging(e: any) {
        this.setState({color: e.currentTarget.value});
    }

    @action
    onHexChange(e: any) {
        let hex = e.currentTarget.value;
        let color = tinycolor(hex);
        if (color.isValid())
            this.onBorderColorChange(color.toHexString());
        else
            this.setState({color: this.props.store.layerListStore.stylePaintProperties.borderColor});
    }

    @action
    onHexKeyPressed(e: any) {
        if(e.keyCode != 13) return;
        let color = tinycolor(this.state.color);
        if (color.isValid())
            this.onBorderColorChange(color.toHexString());
        else
            this.setState({color: this.props.store.layerListStore.stylePaintProperties.borderColor});
    }

    @action
    onOpacityChanging(e: any) {
        this.setState({opacity: e.currentTarget.value});
    }

    @action
    onOpacityChange(e: any) {
        let op = e.currentTarget.value;
        op = op.replaceAll('%', '');
        if (! isNaN(op) && op > 0 && op <= 100)
            this.onBorderOpacityChange(op / 100);
        else
            this.setState({opacity: Math.round(100 * this.props.store.layerListStore.stylePaintProperties.borderOpacity) + '%'});
    }

    @action
    onOpacityKeyPressed(e: any) {
        if(e.keyCode != 13) return;        
        let op = (this.state.opacity as any).replaceAll('%', '');
        if (! isNaN(op) && op > 0 && op <= 100)
            this.onBorderOpacityChange(op / 100);
        else
            this.setState({opacity: Math.round(100 * this.props.store.layerListStore.stylePaintProperties.borderOpacity) + '%'});
    }

    render() {
        let lls = this.props.store.layerListStore;
        return <Fragment>
            <div className="LayerStyleComp-color-container">
                <div className="LayerStyleComp-color-button-container pointer" onClick={this.onOpenColorPicker}>
                    <div className="LayerStyleComp-color-button" style={{backgroundColor: lls.stylePaintProperties.borderColor}}></div>
                    {this.props.store.layerListStore.borderPickerOpened &&
                        <ContextMenuCommonComp onClose={this.onCloseColorPicker}
                            direction={PopupDirection.horizontal}
                            popupCorner={PopupCorner.rightBottom}>
                        <ColorPicker color={this.props.store.layerListStore.stylePaintProperties.borderColor}
                            setColor={(color) => this.onBorderColorChange(color)}
                            opacity={this.props.store.layerListStore.stylePaintProperties.borderOpacity}
                            setOpacity={(op) => this.onBorderOpacityChange(op)}
                            onChange={this.onBorderColorChange} onClose={this.onCloseColorPicker}/>
                        </ContextMenuCommonComp>
                    }
                </div>
                <div className="LayerStyleComp-color-editors">
                    <input type='text' className='LayerStyleComp-color-code LayerStyleComp-color-caption'
                        value={this.state.color.toUpperCase()} 
                        onChange={this.onHexChanging} onBlur={this.onHexChange}
                        onKeyDown={this.onHexKeyPressed}/>
                    <input type='text' className='LayerStyleComp-color-opacity LayerStyleComp-color-caption'
                        value={this.state.opacity} 
                        maxLength={4} pattern="[0-9]{1,3}"
                        onChange={this.onOpacityChanging} onBlur={this.onOpacityChange}
                        onKeyDown={this.onOpacityKeyPressed}/>
                    {/* <span className="LayerStyleComp-color-code LayerStyleComp-color-caption">{lls.stylePaintProperties.borderColor.toUpperCase()}</span>
                    <span className="LayerStyleComp-color-opacity LayerStyleComp-color-caption">{Math.round(lls.stylePaintProperties.borderOpacity * 100) + "%"}</span> */}
                </div>
                <div className="LayerStyleComp-color-eye-container" onClick={this.onBorderVisibilityChange}>
                    {lls.stylePaintProperties.borderVisibility == 'visible' && <EyeSvg/> }
                    {lls.stylePaintProperties.borderVisibility == 'none' && <CancelEyeSvg/> }
                </div>
            </div>
            <div className="LayerStyleComp-color-container">
                <div>
                    <div style={{display: "flex", marginBottom: "20px"}}>
                        <div className="LayerStyleComp-color-button-container">
                            <BorderStyleSvg/>
                        </div>
                        <div className='LayerStyleComp-color-editors'>
                            <input type='text' className={classNames('border-width-editor', 
                                {'border-width-editor-single': !this.props.showLineStyle})}
                                maxLength={4} pattern="[0-9]{1,3}"
                                value={isNaN(this.state.width)? this.state.width : this.state.width}
                                onChange={this.onWidthChanging} onBlur={this.onWidthChange}
                                onKeyDown={this.onWidthKeyPressed}/>
                            {this.props.showLineStyle &&
                            <BorderSwitcher border={lls.stylePaintProperties.borderStyle}
                                onChange={this.onBorderStyleChanges}/>}
                        </div>
                    </div>
                    {lls.stylePaintProperties.borderStyle == BorderStyle.DashDash && <Fragment>
                    <div className="LayerStyleComp-dashed-div">
                        <span>Частота</span>
                        <input type="number" value={this.state.dashedFreq} pattern="[0-9]{1,3}" maxLength={2}
                            className="LayerStyleComp-dashed-input" onChange={this.onFreqChanging}
                            onBlur={this.onFreqChange} onKeyPress={this.onFreqKeyPressed}/>
                    </div>
                    <div className="LayerStyleComp-dashed-div">
                        <span>Пробел</span>
                        <input type="number" value={this.state.dashedSpace} pattern="[0-9]{1,3}" maxLength={2}
                            className="LayerStyleComp-dashed-input" onChange={this.onSpaceChanging}
                            onBlur={this.onSpaceChange} onKeyPress={this.onSpaceKeyPressed}/>
                    </div>
                    </Fragment>}
                </div>
                {lls.stylePaintProperties.borderStyle == BorderStyle.Solid && 
                <div className="border-switcher-empty-div"/>}
                {lls.stylePaintProperties.borderStyle == BorderStyle.DashDash && <div style={{marginTop: "11px", marginRight: "12px"}}>
                    <svg width="19" height="91" viewBox="0 0 19 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 63H14C16.7614 63 19 60.7614 19 58V5C19 2.23858 16.7614 0 14 0H6V1H14C16.2091 1 18 2.79086 18 5V58C18 60.2091 16.2091 62 14 62H6V63Z" fill="#3E4751"/>
                        <rect x="5" y="33" width="1" height="58" fill="#3E4751"/>
                        <rect width="6" height="1" fill="#3E4751"/>
                    </svg>
                </div>}
            </div>
        </Fragment>;
    }
}