import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './VoronovMsgComp.scss';
import {action} from 'mobx';
import {IAgroExportStoreProps} from "../../agroExportPlugin";
import {ExportType, MsgType} from "../../store/VoronovSaveStore";

@observer
export class VoronovMsgComp extends React.Component<IAgroExportStoreProps, undefined> {
    constructor(props: IAgroExportStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClose(){
        this.props.store.agroExport.voronovExportStore.saveOptions.showMsgForm = false;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroExport;
        let so = store_.voronovExportStore.saveOptions;
        let pathItems: any[] = [];
        so.paths.forEach((v, index) => {
            if (index > 0){
                pathItems.push(<span className="VoronovMsgComp-pathArrow" key={v+"_arrow"}><svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6.00098L4 3.00098L0 0.000976562V6.00098Z" fill="#6B707B"/></svg></span>);
            }
            pathItems.push(<span className="" key={v}>{v}</span>);
        });
        let title = store_.trans['Can not add'];
        let listTitle = store_.trans['The following fields are already in the ASA map'];
        let bottomMsg = store_.trans['It is necessary to remove intersecting fields from the ASA map or from the current breakdown'];
        if (so.msgType == MsgType.NoSave) {
            title = store_.trans['Unable to save'];
            listTitle = store_.trans['The following cell numbers are already in the ASA map'];
            bottomMsg = store_.trans['It is necessary to change the numbers in the breakdown, to do this, use the "Cell numbers" setting'];
        }
        let map_name = so.saveTitle;
        if (so.exportType == ExportType.Exist) {
            let maps = so.asaMaps.filter(m=>m.map_id == so.asaMapId);
            if (maps?.length) map_name = maps[0].map_name;
        }

        return <div className="modal-back flex-full-center">
            <div className="modal-min modal-full-frame VoronovMsgComp-form">
                <div className="VoronovMsgComp-title">{title}</div>
                <div className="VoronovMsgComp-subTitle">
                    <div className="VoronovMsgComp-path">
                        {pathItems}
                    </div>
                    <div className="VoronovMsgComp-statistic">
                        {store_.trans['Number of fields']}: {so.fieldsCount}
                    </div>
                </div>
                <div className="VoronovMsgComp-line" />
                <div className="VoronovMsgComp-listTitle">{listTitle} "{map_name}":</div>
                <div className="VoronovMsgComp-listCover style-4">{so.msgData}</div>
                <div className="VoronovMsgComp-bottomMsg">{bottomMsg}</div>
                <div className="VoronovMsgComp-closeButton" onClick={this.onClose}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.025 0.975C10.725 0.675 10.275 0.675 9.975 0.975L6 4.95L2.025 0.975C1.725 0.675 1.275 0.675 0.975 0.975C0.675 1.275 0.675 1.725 0.975 2.025L4.95 6L0.975 9.975C0.675 10.275 0.675 10.725 0.975 11.025C1.125 11.175 1.275 11.25 1.5 11.25C1.725 11.25 1.875 11.175 2.025 11.025L6 7.05L9.975 11.025C10.125 11.175 10.35 11.25 10.5 11.25C10.65 11.25 10.875 11.175 11.025 11.025C11.325 10.725 11.325 10.275 11.025 9.975L7.05 6L11.025 2.025C11.325 1.725 11.325 1.275 11.025 0.975Z" fill="#C5C5C5"/>
                    </svg>
                </div>
            </div>
        </div>;
    }
}
