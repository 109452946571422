import * as React from "react";
import {RefObject} from "react";
import {
    IIStoreProps,
    IStoreProps
} from "../../helper/structs/IStoreProps";
import {IntervalStore} from "../../store/IntervalStore";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {isDate, isFunction} from "lodash-es";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {ra} from "../../helper/utils/mobxUtils";
import {Utils} from "../../helper/utils/Utils";

export interface IReactDoubleDatePicker2CompProps
    extends IIStoreProps {
    interval: IntervalStore; //используется как для чтения так и для записи
    onChange?: () => void; //событие иземения интервала
    onChangeBegin?: (newDate: Date) => any;
    onChangeEnd?: (newDate: Date) => any;
    onFocusExit?: () => any;
    onKeyEnter?: () => any;
    maxIntervalDays?: number; //максимальная разница между днями в интервале
    futureDates?: boolean; //можно ли выбирать будущие даты или только до сегодняшнего дня.
    disabledItem1?: boolean;
    disabledItem2?: boolean;
    anyDaysChooseItem1?: boolean;
    anyDaysChooseItem2?: boolean;
    className?: string;
    includeDays?: Date[]; //список дней которые можно выбрать (только эти)
    minDate?: Date; //абсолютный минимум в днях
    maxDate?: Date;
    yearDropdownItemNumber?: number; //число годов в выпадающем списке, по дефолту 5
}
export const CustomInputComponent = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    return (
        <div className='custom-input-wrapper' onClick={props.onClick}>
            <input {...props} ref={ref} className={props.className} />
            <div className='custom-calendar-icon' onClick={props.onClick} />
        </div>
    );
});
@observer
export class ReactDoubleDatePicker2Comp extends React.PureComponent<
    IReactDoubleDatePicker2CompProps,
    undefined
> {
    constructor(props: IReactDoubleDatePicker2CompProps) {
        super(props);
        autoBindReact(this);
        this.refEndPicker = React.createRef();
    }
    static readonly FORMAT: string = "yyyy-MM-dd";

    inFocusBegin: boolean = false;
    inFocusEnd: boolean = false;

    onSelectBegin(date: Date) {
        this.setValueBegin(date);
    }
    setValueBegin(beginDate: Date) {
        let int = this.props.interval;
        if (isDate(beginDate)) {
            int.begin = beginDate;
        } else {
            int.begin = null;
        }
    }
    @action
    handleChangeBegin(from: Date) {
        if (this.props.onChangeBegin) {
            this.props.onChangeBegin(from);
        }
        this.setValueBegin(from);
        if (isFunction(this.props.onChange)) this.props.onChange();
        return true;
    }

    onSelectEnd(date: Date) {
        this.setValueEnd(date);
    }
    setValueEnd(endDate: Date) {
        let int = this.props.interval;
        if (isDate(endDate)) {
            int.end = endDate;
        } else {
            int.end = null;
        }
    }
    @action
    handleChangeEnd(to: Date) {
        if (this.props.onChangeEnd) {
            this.props.onChangeEnd(to);
        }
        this.setValueEnd(to);
        if (isFunction(this.props.onChange)) this.props.onChange();
        return true;
    }
    refEndPicker: RefObject<any>;

    @action onBeginClose() {
        this.beginOpen = false;
        if (this.props.onFocusExit) this.props.onFocusExit();
        return true;
    }
    @action onBeginOpen() {
        this.endOpen = false;
        this.beginOpen = true;
        return true;
    }
    @observable beginOpen: boolean;

    @action onEndClose() {
        this.endOpen = false;
        if (this.props.onFocusExit) this.props.onFocusExit();
        return true;
    }
    @action onEndOpen() {
        this.beginOpen = false;
        this.endOpen = true;
        return true;
    }
    @observable endOpen: boolean;

    onFocusBegin() {
        this.onBeginOpen();
        this.inFocusBegin = true;
    }
    onExitBegin() {
        this.inFocusBegin = false;
        if (this.props.onFocusExit) this.props.onFocusExit();
    }

    onFocusEnd() {
        this.inFocusEnd = true;
        this.onEndOpen();
    }
    onExitEnd() {
        this.inFocusEnd = false;
        if (this.props.onFocusExit) this.props.onFocusExit();
    }

    onKeyDown(event: any) {
        if (event.which == 9) {
            ra(() => {
                this.endOpen = false;
            });
        }
        if (
            event.which == 13 ||
            event.keyCode == 13 ||
            event.which == 9 ||
            event.keyCode == 9
        ) {
            if (this.props.onKeyEnter) this.props.onKeyEnter();
        }
    }

    getBeginDate() {
        return this.props.interval.begin;
    }
    getEndDate() {
        return this.props.interval.end;
    }
    render() {
        let int = this.props.interval;
        let begin = this.getBeginDate();
        let end = this.getEndDate();
        let minMinDate: Date = this.props.minDate;
        let minMaxDate: Date = end;

        let maxMinDate: Date = begin;
        let maxMaxDate: Date = this.props.maxDate;

        if (minMaxDate == null) minMaxDate = this.props.maxDate;
        if (maxMinDate == null) maxMinDate = this.props.minDate;

        if (int.begin != null && this.props.maxIntervalDays) {
            maxMinDate = new Date(begin);
            maxMaxDate = new Date(begin);
            maxMaxDate.setDate(
                maxMaxDate.getDate() + this.props.maxIntervalDays
            );
            if (
                this.props.maxDate &&
                this.props.maxDate.getTime() < maxMaxDate.getTime()
            ) {
                maxMaxDate = this.props.maxDate;
            }
        }
        if (int.end != null && this.props.maxIntervalDays) {
            minMaxDate = new Date(end);
            minMinDate = new Date(end);
            minMinDate.setDate(
                minMinDate.getDate() - this.props.maxIntervalDays
            );
            if (
                this.props.minDate &&
                this.props.minDate.getTime() > minMinDate.getTime()
            ) {
                minMinDate = this.props.minDate;
            }
        }
        let futureDates = this.props.futureDates;
        if (!futureDates) {
            if (maxMaxDate == null) maxMaxDate = new Date();
            if (minMaxDate == null) minMaxDate = new Date();
        }
        if (this.props.anyDaysChooseItem1) {
            minMinDate = null;
            minMaxDate = null;
        }
        if (this.props.anyDaysChooseItem2) {
            maxMinDate = null;
            maxMaxDate = null;
        }

        return (
            <div
                className={classNames(
                    "interval-date",
                    this.props.className
                )}
            >
                <ReactDatePicker
                    locale={this.props.store.getLocale()}
                    tabIndex={1}
                    open={this.beginOpen}
                    onCalendarClose={this.onBeginClose}
                    onCalendarOpen={this.onBeginOpen}
                    onFocus={this.onFocusBegin}
                    onBlur={this.onExitBegin}
                    disabled={this.props.disabledItem1}
                    placeholderText={this.props.store.trans['YYYY-MM-DD']}
                    className='date-input date-input-item1'
                    onKeyDown={this.onKeyDown}
                    wrapperClassName={
                        this.props.disabledItem1 ? "disable" : ""
                    }
                    //includeDates={this.props.includeDays}
                    selected={begin}
                    onChange={date =>
                        this.handleChangeBegin(date as Date)
                    }
                    onSelect={this.onSelectBegin}
                    selectsStart
                    startDate={begin}
                    endDate={end}
                    minDate={minMinDate}
                    maxDate={minMaxDate}
                    monthsShown={1}
                    dateFormat={ReactDoubleDatePicker2Comp.FORMAT}
                    showYearDropdown
                    yearDropdownItemNumber={this.props.yearDropdownItemNumber || 5}
                    scrollableYearDropdown
                    popperPlacement='bottom-end'
                    showPopperArrow={false}
                    dayClassName={date => {
                        let [nowday, valday, day] = [
                            Utils.getDayOfYear(new Date()),
                            begin ? Utils.getDayOfYear(begin) : -1,
                            Utils.getDayOfYear(date)
                        ];
                        let todaySelected = valday == nowday;
                        if (todaySelected && day == valday)
                            return "today-selected";
                        if (!todaySelected && day == valday)
                            return "nottoday-selected";
                        if (!todaySelected && day == nowday)
                            return "today-notselected";
                        return undefined;
                    }}
                    customInput={
                        <CustomInputComponent
                            onClick={this.onFocusBegin}
                            className='date-input date-input-item1'
                        />
                    }
                />

                <span className='date-separator' />
                <ReactDatePicker
                    locale={this.props.store.getLocale()}
                    tabIndex={2}
                    placeholderText={this.props.store.trans['YYYY-MM-DD']}
                    className='date-input date-input-item2'
                    open={this.endOpen}
                    onCalendarClose={this.onEndClose}
                    onCalendarOpen={this.onEndOpen}
                    onFocus={this.onFocusEnd}
                    onBlur={this.onExitEnd}
                    onKeyDown={this.onKeyDown}
                    wrapperClassName={
                    this.props.disabledItem2 ? "disable" : ""
                    }
                    //includeDates={this.props.includeDays}
                    disabled={this.props.disabledItem2}
                    selected={end}
                    onChange={date =>
                    this.handleChangeEnd(date as Date)
                    }
                    onSelect={this.onSelectEnd}
                    selectsEnd
                    startDate={begin}
                    endDate={end}
                    minDate={maxMinDate}
                    maxDate={maxMaxDate}
                    monthsShown={1}
                    dateFormat={ReactDoubleDatePicker2Comp.FORMAT}
                    showYearDropdown
                    yearDropdownItemNumber={this.props.yearDropdownItemNumber || 5}
                    scrollableYearDropdown
                    popperPlacement='bottom-end'
                    showPopperArrow={false}
                    dayClassName={date => {
                        let [nowday, valday, day] = [
                            Utils.getDayOfYear(new Date()),
                            end ? Utils.getDayOfYear(end) : -1,
                            Utils.getDayOfYear(date)
                        ];
                        let todaySelected = valday == nowday;
                        if (todaySelected && day == valday)
                            return "today-selected";
                        if (!todaySelected && day == valday)
                            return "nottoday-selected";
                        if (!todaySelected && day == nowday)
                            return "today-notselected";
                        return undefined;
                    }}
                    customInput={
                        <CustomInputComponent
                            onClick={this.onFocusBegin}
                            className='date-input date-input-item2'
                        />
                    }
                />
            </div>
        );
    }
}
