import {AgroExportTranslate_en} from "./AgroExportTranslate_en";

export class AgroExportTranslate_ru extends AgroExportTranslate_en{
    "Save new ASA maps"="Сохранить новую КОП"
    "Save to existing ASA maps"="Сохранить в существующую КОП"
    "ASA map name"="Название КОП"
    "Search by ASA map"="Поиск по КОПам"
    "Can not add"="Невозможно добавить"
    "The following fields are already in the ASA map"="Следующие поля уже есть в КОПе"
    "It is necessary to remove intersecting fields from the ASA map or from the current breakdown"="Необходимо удалить пересекающиеся поля из КОПа или из текущей разбивки"
    "Unable to save"="Невозможно сохранить"
    "The following cell numbers are already in the ASA map"="Следующие номера ячеек уже есть в КОПе"
    'It is necessary to change the numbers in the breakdown, to do this, use the "Cell numbers" setting'='Необходимо изменить номера в разбивке, для этого используйте настройку "Номера ячеек"'
    "Number of fields"="Кол-во полей"
    "Data saved in ASA map"="Данные записаны в КОП"
    "Save error"="Ошибка сохранения"
    'ASA map with name "{0}" already exists'='КОП с именем "{0}" уже существует'
    "By average cell area"="По средней площади ячейки"
    "By total number of cells"="По общему количеству ячеек"
    "By the number of cells for each field"="По количеству ячеек для каждого поля"
    "Construction method"="Метод построения"
    "Avg. square"="Средн. площадь"
    "Cell numbers with"="Номера ячеек с"
    "Numbers"="Номера"
    "Cells"="Ячеек"
    "pcs"="шт"
    "Voronoi diagrams / avg. area"="Диаграм. Вороного / средн. площадь"
    "Voronoi diagrams / number of samples"="Диаграммы Вороного / кол-во проб"
    "Rectangular grid / number of samples"="Прямоугольная сетка / кол-во проб"
    "Rectangular grid / avg. area"="Прямоугольн. сетка / средн. площадь"
    "Voronoi diagrams"="Диаграммы Вороного"
    "Adaptive partitioning"="Адаптивное разбиение"
    "Rectangular grid"="Прямоугольная сетка"
    "Z-shaped"="Z-образный"
    "Linear"="Линейный"
    "Selection route"="Маршрут отбора"
    "Num. of sampl."="Кол-во проб"
    "Diagonal"="Диагональ"
    "Avg. line"="Средн. линия"
}
