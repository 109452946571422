import * as React from 'react';
import {CustomSvg} from "../../../../icons/CustomSvg";

export class TrashSvg extends CustomSvg {

    render() {
        return <svg width="12" height="12" viewBox="0 0 12 12" className={this.getClasses()} style={this.getStyles()} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7_1194)">
        <path d="M12 2.25C12 2.05109 11.921 1.86032 11.7803 1.71967C11.6397 1.57902 11.4489 1.5 11.25 1.5H8.25V0.75C8.25 0.551088 8.17098 0.360322 8.03033 0.21967C7.88968 0.0790176 7.69891 0 7.5 0L4.5 0C4.30109 0 4.11032 0.0790176 3.96967 0.21967C3.82902 0.360322 3.75 0.551088 3.75 0.75V1.5H0.75C0.551088 1.5 0.360322 1.57902 0.21967 1.71967C0.0790176 1.86032 0 2.05109 0 2.25C0 2.44891 0.0790176 2.63968 0.21967 2.78033C0.360322 2.92098 0.551088 3 0.75 3H11.25C11.4489 3 11.6397 2.92098 11.7803 2.78033C11.921 2.63968 12 2.44891 12 2.25Z"/>
        <path d="M1.5 3.75V10.5C1.5 10.8978 1.65804 11.2794 1.93934 11.5607C2.22064 11.842 2.60218 12 3 12H9C9.39782 12 9.77936 11.842 10.0607 11.5607C10.342 11.2794 10.5 10.8978 10.5 10.5V3.75H1.5ZM8.295 9.6405L7.7655 10.17C7.73067 10.2049 7.68928 10.2326 7.64372 10.2515C7.59817 10.2704 7.54933 10.2802 7.5 10.2802C7.45067 10.2802 7.40183 10.2704 7.35628 10.2515C7.31072 10.2326 7.26933 10.2049 7.2345 10.17L6 8.9355L4.7655 10.17C4.73067 10.2049 4.68928 10.2326 4.64373 10.2515C4.59817 10.2704 4.54933 10.2802 4.5 10.2802C4.45067 10.2802 4.40183 10.2704 4.35627 10.2515C4.31072 10.2326 4.26933 10.2049 4.2345 10.17L3.705 9.6405C3.67008 9.60567 3.64237 9.56428 3.62347 9.51872C3.60456 9.47317 3.59483 9.42433 3.59483 9.375C3.59483 9.32567 3.60456 9.27683 3.62347 9.23128C3.64237 9.18572 3.67008 9.14433 3.705 9.1095L4.9395 7.875L3.705 6.6405C3.67008 6.60567 3.64237 6.56428 3.62347 6.51872C3.60456 6.47317 3.59483 6.42433 3.59483 6.375C3.59483 6.32567 3.60456 6.27683 3.62347 6.23128C3.64237 6.18572 3.67008 6.14433 3.705 6.1095L4.2345 5.58C4.26933 5.54508 4.31072 5.51737 4.35627 5.49847C4.40183 5.47956 4.45067 5.46983 4.5 5.46983C4.54933 5.46983 4.59817 5.47956 4.64373 5.49847C4.68928 5.51737 4.73067 5.54508 4.7655 5.58L6 6.8145L7.2345 5.58C7.26933 5.54508 7.31072 5.51737 7.35628 5.49847C7.40183 5.47956 7.45067 5.46983 7.5 5.46983C7.54933 5.46983 7.59817 5.47956 7.64372 5.49847C7.68928 5.51737 7.73067 5.54508 7.7655 5.58L8.295 6.1095C8.32992 6.14433 8.35763 6.18572 8.37653 6.23128C8.39544 6.27683 8.40517 6.32567 8.40517 6.375C8.40517 6.42433 8.39544 6.47317 8.37653 6.51872C8.35763 6.56428 8.32992 6.60567 8.295 6.6405L7.0605 7.875L8.295 9.1095C8.32992 9.14433 8.35763 9.18572 8.37653 9.23128C8.39544 9.27683 8.40517 9.32567 8.40517 9.375C8.40517 9.42433 8.39544 9.47317 8.37653 9.51872C8.35763 9.56428 8.32992 9.60567 8.295 9.6405Z"/>
        </g>
        <defs>
        <clipPath id="clip0_7_1194">
        <rect width="12" height="12" fill="white"/>
        </clipPath>
        </defs>
        </svg>;
    }
}
