import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCardsComp.scss'
// import {AgroAhoProbeComp} from "./AgroAhoProbeComp";
import {action, observable} from "mobx";
import classNames from "classnames";
import {
    ContextMenuCommonComp,
    PopupCorner,
    PopupDirection
} from "../../../../app/components/Common/ContextMenuCommonComp";
import {AgroAhoCardItemComp} from "../card/AgroAhoCardItemComp";
import {TextUtils} from "../../../../app/helper/utils/TextUtils";
import {AhoPerm} from "../../agroAhoProjStore";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoModalType} from "../../agroAhoStore";
import {AgroAhoSortSvg} from "../icons/AgroAhoSortSvg";
import {AgroAhoWindowComp} from "../AgroAhoWindowComp";
import {AgroAhoAlignSvg} from "../icons/AgroAhoAlignSvg";
import {SortType} from "../../agroAhoCardsStore";

// import {AgroAhoSortSvg} from "../icons/AgroAhoSortButton";


@observer
export class AgroAhoCardsComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    popupShow: boolean = false;
    sortAsc: boolean = false;
    uploadIsoXmlElement: HTMLInputElement = null;

    @action
    onClickSortButton(){
        let store_ = this.props.store.agroAhoStore.cardsStore;

        // store.cards.sort((a,b) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))
        if (this.sortAsc) {
            store_.cards = store_.cards.slice().sort((a,b)=> TextUtils.stringSortCompare(a.map_name, b.map_name));
        } else {
            store_.cards = store_.cards.slice().sort((a,b)=> TextUtils.stringSortCompare(b.map_name, a.map_name));
        }
        this.sortAsc = !this.sortAsc;

        // console.log('sort', JSON.stringify(store.cards));
        // let disabled = store.perm === AhoPerm.View;
        // if (!disabled) store.toggleModal(true, AhoModalType.FileLoad);
    }

    @action
    onClickMenuButton(){
        this.popupShow = !this.popupShow;
    }

    @action
    onClosePopup(){
        this.popupShow = false;
    }

    @action
    onClickSaveCard(){
        // let pstore = this.props.store.agroAhoStore.projStore;
        // let disabled = pstore.perm === AhoPerm.View;
        // if (!disabled) {
        //     // this.popupShow = false;
        //     // store.card = null;
        //     // store.toggleModal(true, AhoModalType.SaveCard);
        // }
    }

    @action
    onClickVisSetup(){
        let store_ = this.props.store.agroAhoStore;
        this.popupShow = false;
        store_.toggleModal(true, AhoModalType.VisualizationSetup);
    }

    onClickMergeIsoXml(){
        this.popupShow = false;
        this.uploadIsoXmlElement.value = '';
        this.uploadIsoXmlElement.click();
    }

    onClickDeleteCards(){
        let cs_store = this.props.store.agroAhoStore.cardsStore;
        let selCards = cs_store.getSelectedCards();
        if (selCards.length < 1) return;
        this.popupShow = false;
        // store_.deleteCards();
        cs_store.onDeleteCards();
    }

    onAnimateClick(){
        // let store_ = this.props.store.agroAhoStore;
        // store_.root.voronovExportStore.isSaved = true;
    }

    onSortWinClick(){
        let cs_store = this.props.store.agroAhoStore.cardsStore;
        cs_store.sortWinShow = !cs_store.sortWinShow;
    }

    onSortWinBlur(){
        let cs_store = this.props.store.agroAhoStore.cardsStore;
        cs_store.sortWinShow = false;
    }

    @action
    onSortByNameClick(){
        let cs_store = this.props.store.agroAhoStore.cardsStore;
        if (cs_store.sortType == SortType.byNameAZ) cs_store.sortType = SortType.byNameZA;
        else cs_store.sortType = SortType.byNameAZ;
        cs_store.sort();
    }

    filterChanged(e:any){
        let cs_store = this.props.store.agroAhoStore.cardsStore;
        cs_store.doCardsFilterChanged(e.target.value);
    }

    @action
    onSortByDateClick(){
        let cs_store = this.props.store.agroAhoStore.cardsStore;
        if (cs_store.sortType == SortType.byDateZA) cs_store.sortType = SortType.byDateAZ;
        else cs_store.sortType = SortType.byDateZA;
        cs_store.sort();
    }

    @action
    uploadChanged(){
        let input = this.uploadIsoXmlElement;
        if (input.files.length == 0) return;
        let ustore = this.props.store.agroAhoStore.cardsStore.uploadIsoXMlStore;
        ustore.uploadFiles(input.files);
    }

    onChangeRef(node: any){
        this.uploadIsoXmlElement = node;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let cs_store = store_.cardsStore;
        let selCards = cs_store.getSelectedCards();
        let disabled = store_.projStore.perm === AhoPerm.View;
        let stat = {area: 0, cells: 0, cnt: cs_store.cards.length};
        let fstat = {area: 0, cells: 0, cnt: 0};
        let filtered = 0;
        cs_store.cards.forEach(card=>{
            let area = card.field_info.sum_area_ha && parseFloat(card.field_info.sum_area_ha.toFixed(1));
            stat.area += area || 0;
            stat.cells += card.cell_info.cell_cnt || 0;
            if (!card.filtered) {
                fstat.cnt += 1;
                fstat.area += area || 0;
                fstat.cells += card.cell_info.cell_cnt || 0;
            } else filtered += 1;
        });
        let noCards = filtered > 0 && fstat.cnt == 0;
        let cnt = (noCards || fstat.cnt) && stat.cnt !== fstat.cnt ? <span>{fstat.cnt}&nbsp;/&nbsp;{stat.cnt}</span> : stat.cnt;
        let area = (noCards || fstat.area) && stat.area !== fstat.area ?
            <span>{store_.toFloat(fstat.area)}&nbsp;/&nbsp;{store_.toFloat(stat.area)}</span> :
            <span>{store_.toFloat(stat.area)}</span>;
        // stat.area = store_.toFloat(stat.area);
        let cells = (noCards || fstat.cells) && stat.cells !== fstat.cells ?
            <span>{store_.numWithSpaces(fstat.cells)}&nbsp;/&nbsp;{store_.numWithSpaces(stat.cells)}</span> :
            <span>{store_.numWithSpaces(stat.cells)}</span>;
        // stat.cells = store_.numWithSpaces(stat.cells);

        let path = <React.Fragment> </React.Fragment>;
        if (store_.cardStore.lastCardName) path = <React.Fragment>
            <svg className="AgroAhoCardsComp-triangle" width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 6L4 3L0 0V6Z" fill="#6B707B"/>
            </svg>
            <span className="AgroAhoCardsComp-lastCardName">{store_.cardStore.lastCardName}</span>
        </React.Fragment>;

        // this.fieldsItems.forEach(f=>f.filtered = f.field_name.toLowerCase().indexOf(filter.toLowerCase()) == -1);

        let cards = cs_store.cards.filter(c => !c.filtered);

        return <React.Fragment>
            <div className="AgroAhoCardsComp-proj">{store.agro.projectTitle}{path}</div>
            <div className="AgroAhoCardsComp-statHeader">
                <span>{store_.trans["ASAs"]}:&nbsp;{cnt}</span>
                <span>{store.trans.Area}:&nbsp;{area}&nbsp;{store.trans.ha}</span>
                <span>{store.trans["Cells:"]}&nbsp;{cells}</span>
            </div>
            <div className="AgroAhoCardsComp-title">
                <div className="AgroAhoCardsComp-path" onClick={this.onAnimateClick} >{store_.trans['Agri. soil analysis maps (ASA maps)']}</div>
                <div className="AgroAhoCardsComp-splitter"> </div>


                <AgroAhoWindowComp className="AgroAhoCardsComp-sortButton" store={store}
                    visible={cs_store.sortWinShow} button={
                        <AgroAhoSortSvg className={classNames("AgroAhoCardsComp-sortSvg",{
                            "AgroAhoCardsComp-sortButtonHovered": cs_store.sortWinShow})}/>}
                    onClick={this.onSortWinClick} onBlur={this.onSortWinBlur}>
                    <div className="AgroAhoCardsComp-sortMenuItem" onClick={this.onSortByNameClick}>
                        <AgroAhoAlignSvg className={classNames({
                            "AgroAhoCardsComp-alignAZ": cs_store.sortType == SortType.byNameAZ,
                            "AgroAhoCardsComp-alignZA": cs_store.sortType == SortType.byNameZA,
                            "AgroAhoCardsComp-alignHidden": cs_store.sortType != SortType.byNameAZ
                                && cs_store.sortType != SortType.byNameZA
                        })}/>
                        <span>{store_.trans.sortByName}</span>
                    </div>
                    <div className="AgroAhoCardsComp-sortMenuItem" onClick={this.onSortByDateClick}>
                        <AgroAhoAlignSvg className={classNames({
                            "AgroAhoCardsComp-alignAZ": cs_store.sortType == SortType.byDateAZ,
                            "AgroAhoCardsComp-alignZA": cs_store.sortType == SortType.byDateZA,
                            "AgroAhoCardsComp-alignHidden": cs_store.sortType != SortType.byDateAZ
                                && cs_store.sortType != SortType.byDateZA
                        })}/>
                        <span>{store_.trans.sortByDate}</span>
                    </div>
                </AgroAhoWindowComp>






                <div className="AgroAhoCardsComp-menuButton" onClick={this.onClickMenuButton}>
                    <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 12H15C15.552 12 16 11.552 16 11C16 10.448 15.552 10 15 10H8C7.448 10 7 10.448 7 11C7 11.552 7.448 12 8 12Z" />
                        <path d="M1 2H15C15.552 2 16 1.552 16 1C16 0.448 15.552 0 15 0H1C0.448 0 0 0.448 0 1C0 1.552 0.448 2 1 2Z" />
                        <path d="M1 7H15C15.552 7 16 6.552 16 6C16 5.448 15.552 5 15 5H1C0.448 5 0 5.448 0 6C0 6.552 0.448 7 1 7Z"  />
                    </svg>
                </div>
                {this.popupShow && <ContextMenuCommonComp onClose={this.onClosePopup}
                      className="AgroAhoCardsComp-menuPopup"
                      autoCornerHeight={200} autoCornerWidth={200}
                      direction={PopupDirection.vertical}
                      popupCorner={PopupCorner.rightTop}>
                    {/*<div className="AgroAhoCardsComp-menuItem AgroAhoCardsComp-disabled" onClick={this.onClickSaveCard}>*/}
                    {/*    {store.trans['Create a agricultural soil analysis map (ASA Map)']}</div>*/}
                    {/*/!*<div className="AgroAhoCardsComp-menuLine"> </div>*!/*/}

                    {/*<div className={classNames("AgroAhoCardsComp-menuItem",*/}
                    {/*    {"AgroAhoCardsComp-disabled": selCards.length < 2})} >{store.trans['Merge Selected']}</div>*/}
                    {/*/!*<div className="AgroAhoCardsComp-menuLine"> </div>*!/*/}

                    <div className="AgroAhoCardsComp-menuItem" onClick={this.onClickVisSetup}>
                        {store_.trans['Visualization setup']}</div>
                    <div className="AgroAhoCardsComp-menuLine"> </div>

                    <div className="AgroAhoCardsComp-menuItem" onClick={this.onClickMergeIsoXml}>
                        {store_.trans['Merge ISO-XML files']}</div>
                    <div className="AgroAhoCardsComp-menuLine"> </div>

                    <div className={classNames("AgroAhoCardsComp-menuItem AgroAhoCardsComp-menuRedItem",
                        {"AgroAhoCardsComp-disabled": selCards.length < 1})}
                        onClick={this.onClickDeleteCards}>{store.trans['Delete selected']}</div>
                </ContextMenuCommonComp>}
            </div>
            <input type="text" className="AgroAhoCardsComp-filter text-box-editor"
                   value={cs_store.searchCardsFilter} placeholder={store_.trans.Search} onChange={this.filterChanged}/>
            <div className="AgroAhoCardsComp-list">
                {cards.map(item =>
                    <AgroAhoCardItemComp key={item.map_id} store={store} card={item}/>)}
            </div>
            <input type="file" style={{display: "none"}} multiple accept=".zip,.iso,.xml"
                   onChange={this.uploadChanged} ref={this.onChangeRef} />
        </React.Fragment>
    }
}
