import * as React from 'react';
import {CustomSvg} from "../CustomSvg";
import { IIconProp } from '../IIconProp';

export class EyeSvg extends CustomSvg {
    render() {
        return <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()} style={this.getStyles()}>
            <path d="M8.00013 12C12.7071 12 15.7441 6.716 15.8711 6.492C16.0421 6.188 16.0431 5.816 15.8721 5.512C15.7461 5.287 12.7311 0 8.00013 0C3.24513 0 0.251127 5.289 0.126127 5.514C-0.0428725 5.817 -0.0418725 6.186 0.128127 6.489C0.254128 6.713 3.26913 12 8.00013 12ZM8.00013 2C10.8391 2 13.0361 4.835 13.8181 6C13.0341 7.166 10.8371 10 8.00013 10C5.15913 10 2.96213 7.162 2.18113 5.999C2.95813 4.835 5.14613 2 8.00013 2Z" fill="#C5C5C5"/>
            <path d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z" fill="#C5C5C5"/>
        </svg>
    }
}
