import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {CSSProperties} from "react";
import {WindowTriState} from "../../../helper/structs/WindowTriState";
import classNames from "classnames";

interface ILegendCommonCompProps extends IStoreProps {
    style?: React.CSSProperties;
}
@observer
export class LegendCommonComp extends React.Component<ILegendCommonCompProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    render(){
        return <div className={classNames( this.props.className)} style={this.props.style}>{this.props.children}</div>;
    }
}