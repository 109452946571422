import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditTourComp.scss';
import {action, observable} from 'mobx';
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {CopyType} from "../../agroAhoCardStore";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";
import {AhoUtils} from "../../agroAhoUtils";
import {AgroAhoDateComp} from "../uploadTable/AgroAhoDateComp";

@observer
export class AgroAhoEditTourComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickCancel(){
        this.props.store.agroAhoStore.toggleModal(false);
    }

    onClickOk(){
        if (!this.canSave()) return;
        let store_ = this.props.store.agroAhoStore;
        store_.tourStore.doTourEditClick();
    }

    canSave(){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        return tstore.curTour?.map_id && !!tstore.newTourName && (tstore.newTourName !== tstore.curTour?.tour_name ||
            store_.format_date3(tstore.newTourDate) !== tstore.curTour?.tour_date);
    }

    onChangeNewName(e: any){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.newTourName = e.currentTarget.value;
    }

    onDateChange(date: Date){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        tstore.newTourDate = date;
        //tstore.curTour.tour_date = store_.format_date2(tstore.newTourDate);
        // console.log('date', ustore.curTour.tour_date);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        // let cstore = store_.cardStore;
        // let cs_store = store_.cardsStore;
        let tstore = store_.tourStore;

        // let cards_ = cs_store.cards.filter(c => !c.filtered && c.map_id != cstore.card.map_id);
        // let cards:IDropDownItem[] = cards_.map(c=>{return {key: c.map_id, value: c.map_name}});
        // let tourName = tstore.selTours?.[0]?.tour_name || '';
        // let dstCard = cards.filter(c=>c.key == tstore.copyTourMapId);
        // let dstMapName = dstCard?.length ? dstCard[0].value : '';


        return <div className="AgroAhoEditTourComp-main">
            <div className="AgroAhoEditTourComp-title">{store_.trans['Editing a tour']}</div>
            <div className="AgroAhoEditTourComp-line" />
            <div className="AgroAhoEditTourComp-nameCover">
                <div className="AgroAhoEditTourComp-caption">
                    {store_.trans['Tour name']}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoEditTourComp-nameInputCover">
                    <input type="text" className="text-box-editor width100" onChange={this.onChangeNewName}
                           value={tstore.newTourName} />
                </div>
            </div>
            <div className="AgroAhoEditTourComp-tourDateCover">
                <div className="AgroAhoEditTourComp-caption">
                    {store_.trans['Tour date']}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoEditTourComp-dateInputCover">
                    <AgroAhoDateComp className="AgroAhoEditTourComp-newDate" onChange={this.onDateChange}
                        selected={tstore.newTourDate} calendarIcon={true} placeholderText='DD-MM-YYYY'
                        dateFormat='dd-MM-yyyy'/>
                </div>
            </div>

            <div className="AgroAhoEditTourComp-line" />
            <div className="AgroAhoEditTourComp-bottom">
                <button className="left-sidebar-cancel large-button button-jump AgroAhoEditTourComp-button text-next-2"
                    onClick={this.onClickCancel}>
                    {store.trans.Cancel}
                </button>
                <button className={classNames(
                    "left-sidebar-active large-button AgroAhoEditTourComp-button text-next-2", {
                        "button-jump": this.canSave(),
                        "AgroAhoEditTourComp-disabled": !this.canSave()
                    })} onClick={this.onClickOk} >
                    {store_.trans.Save}
                </button>
            </div>

        </div>;
    }
}
