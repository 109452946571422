import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDelTourPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";


@observer
export class AgroAhoDelTourPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        tstore.delTour(tstore.tourForDel);
        store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let tstore = store_.tourStore;
        return <div className="AgroAhoDelTourPanelComp-main">
            <div className="AgroAhoDelTourPanelComp-caption">{store.trans.Delete}</div>
            <div className="AgroAhoDelTourPanelComp-msg">{
                TranslateUtils.createSpansWithReplaceMarkers(
                    store_.trans['Attention! All tour {0} data will be deleted'],
                    tstore.tourForDel.tour_name
                )}</div>
            {/*<div className="AgroAhoDelTourPanelComp-stat">*/}
            {/*    <span>{ustore.tourForDel.tour_name}</span>*/}
            {/*</div>*/}
            <div className="AgroAhoDelTourPanelComp-line"> </div>
            <div className="AgroAhoDelTourPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoDelTourPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className="large-button left-sidebar-red button-jump"
                    onClick={this.onClickDelete}>{store.trans.Delete}</button>
            </div>
        </div>;
    }
}
