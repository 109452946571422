import * as React from 'react';
import {CustomSvg} from "../../../../icons/CustomSvg";

export class CenterToSvg extends CustomSvg {

    render() {
        return <svg width="12" height="12" viewBox="0 0 12 12" className={this.getClasses()} style={this.getStyles()}  xmlns="http://www.w3.org/2000/svg">
            <path d="M6 8.25C7.24264 8.25 8.25 7.24264 8.25 6C8.25 4.75736 7.24264 3.75 6 3.75C4.75736 3.75 3.75 4.75736 3.75 6C3.75 7.24264 4.75736 8.25 6 8.25Z"  stroke="none" />
            <path d="M1.5 4.5H0V0.75C0 0.3 0.3 0 0.75 0H4.5V1.5H1.5V4.5Z" stroke="none" />
            <path d="M12 4.5H10.5V1.5H7.5V0H11.25C11.7 0 12 0.3 12 0.75V4.5Z" stroke="none" />
            <path d="M11.25 12H7.5V10.5H10.5V7.5H12V11.25C12 11.7 11.7 12 11.25 12Z" stroke="none" />
            <path d="M4.5 12H0.75C0.3 12 0 11.7 0 11.25V7.5H1.5V10.5H4.5V12Z" stroke="none" />
        </svg>;
    }
}