import * as React from 'react';
import {IIconProp} from "../IIconProp";

export class StarEmptyMinusSvg extends React.PureComponent<IIconProp, undefined> {


    render() {
        let c = this.props.className??"";
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={c}>
            <path d="M 16 12 L 10 12 L 10 14 L 16 14 L 16 12 Z" fill="#F05E5E"/>
            <path d="M8.0001 11.253L7.0701 11.742L5.0021 12.829L5.3971 10.527L5.5751 9.491L4.8221 8.757L3.1491 7.127L5.4611 6.791L6.5021 6.64L6.9671 5.697L8.0011 3.603L9.0351 5.697L9.5001 6.64L10.5411 6.791L12.8531 7.127L11.9561 8H14.8211L15.6991 7.145C16.2931 6.565 15.9621 5.558 15.1441 5.439L10.8281 4.812L8.8981 0.9C8.7291 0.559 8.3651 0.388 8.0011 0.388C7.6371 0.388 7.2731 0.559 7.1041 0.9L5.1741 4.811L0.857098 5.438C0.0390981 5.557 -0.290902 6.565 0.302098 7.145L3.4261 10.189L2.6891 14.488C2.5791 15.132 3.0931 15.659 3.6761 15.659C3.8291 15.659 3.9871 15.623 4.1401 15.543L8.0001 13.513V11.253Z" fill="#F05E5E"/>
        </svg>;/*
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={c}>
            <path d="M8.0001 11.253L7.0701 11.742L5.0021 12.829L5.3971 10.527L5.5751 9.491L4.8221 8.757L3.1491 7.127L5.4611 6.791L6.5021 6.64L6.9671 5.697L8.0011 3.603L9.0351 5.697L9.5001 6.64L10.5411 6.791L12.8531 7.127L11.1801 8.757L10.9301 9H13.7951L15.6991 7.145C16.2931 6.565 15.9621 5.558 15.1441 5.439L10.8281 4.812L8.8981 0.9C8.7291 0.559 8.3651 0.388 8.0011 0.388C7.6371 0.388 7.2731 0.559 7.1041 0.9L5.1741 4.811L0.857098 5.438C0.0390981 5.557 -0.290902 6.565 0.302098 7.145L3.4261 10.189L2.6891 14.488C2.5791 15.132 3.0931 15.659 3.6761 15.659C3.8291 15.659 3.9871 15.623 4.1401 15.543L8.0001 13.513V11.253Z" fill="#F05E5E"/>
            <path d="M16 12H10V14H16V12Z" fill="#F05E5E"/>
        </svg>;*/
    }
}
