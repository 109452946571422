import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoRecNormItemComp.scss'
import {action, observable} from "mobx";
import classNames from "classnames";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AhoCardShowType} from "../../agroAhoCardStore";
import {AgroAhoRoundCheckboxSvg} from "../AgroAhoRoundCheckboxSvg";
import {AhoUtils} from "../../agroAhoUtils";
import {AgroAhoDel3Svg} from "../icons/AgroAhoDel3Svg";
import {RecNormItem} from "../../card/agroAhoCardRN";



export interface IAgroAhoRecNormItemCompProps {
    store: IAgroAhoSuperStore;
    rn: RecNormItem;
}

@observer
export class AgroAhoRecNormItemComp extends React.PureComponent<IAgroAhoRecNormItemCompProps, undefined> {
    constructor(props: IAgroAhoRecNormItemCompProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.cardRNStore.doRNFertClick(this.props.rn);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    onDelClick(e: any){
        let store_ = this.props.store.agroAhoStore;
        store_.mapStore.mapRNStore.rnForRemoval = this.props.rn;
        store_.cardStore.cardRNStore.onDeleteRecNormItem();
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    render() {
        let store_ = this.props.store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let cstore = store_.cardStore;
        let mstore = store_.mapStore;
        let rn = this.props.rn;
        let cur = mstore.mapRNStore.curRecNormItem;
        let checked = cstore.cardRNStore.getRNGroupKey(rn) ==  cstore.cardRNStore.getRNGroupKey(cur) &&
            rn.fert_id == cur.fert_id;
        return <div className="AgroAhoRecNormItemComp-main" onClick={this.onClick}>
            <AgroAhoRoundCheckboxSvg className={classNames("AgroAhoRecNormItemComp-checkbox", {
                "AgroAhoRecNormItemComp-checked": checked})}  checked={checked}/>
            <div className="AgroAhoRecNormItemComp-name">{ustore.getFertName(rn.fert_id)}</div>
            <div className="AgroAhoRecNormItemComp-splitter"/>
            <div className="AgroAhoRecNormItemComp-unit">{store_.trans['kg/ha']}</div>
            <AgroAhoDel3Svg className="AgroAhoRecNormItemComp-del" onClick={this.onDelClick}/>
        </div>
    }
}
