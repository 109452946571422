import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {SceneMosaicSwitch} from "../../../../store/SearchStore";

@observer
export class SearchSceneFooterComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    test(){
        //console.log(this.props.store.map.mapbox.getStyle());
    }

    render() {
        let store = this.props.store;
        let total = "...";
        let cnt = "...";
        let st = {};
        if ( store.searchPanel.searchResult.searchTotalState == LoadStatus.ready){
            let cntNum = store.searchPanel.searchResult.groups.length;
            let totalNum = 0;
            if (store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.scene) totalNum = store.searchPanel.searchResult.totalRecords;
            else
                totalNum = store.searchPanel.searchResult.totalRecordsDates;
            if (totalNum > cntNum) st = {opacity: "1"};
            total = (totalNum??0).toString();
            cnt = (cntNum??0).toString();
            //if (store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.mosaic){
        }
        //total > cnt
//            <div className="left-sidebar__right-bottom-footer-left">
        return <div className="left-sidebar__right-bottom-footer">
                 {total != null && <span className="founded">{store.trans["Total found"]}: {total}</span>}
                 <span className="visibility" style={st}>{store.trans.Listed}: {cnt}</span>
        </div>;
    }
}
