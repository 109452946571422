import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {LegacyOrderStatus, OrderResult, OrderStatus} from "../../../../store/OrderListStore";
import {OrderStatusError} from "./OrderStatusError";
import {OrderStatusReady} from "./OrderStatusReady";
import {OrderStatusWork} from "./OrderStatusWork";
import {isString} from "lodash-es";
import {OrderActionDownloadComp} from "./OrderActionDownloadComp";
import {action} from "mobx";
import {OrderLink} from "./OrderLink";
import {RefreshSvg} from "../../../icons/RefreshSvg";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {OrderStatusWarning} from "./OrderStatusWarning";
import {OrderActionCancelComp} from "./OrderActionCancelComp";
import {OrderStatusCanceled} from "./OrderStatusCanceled";

@observer
export class OrderListPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClose(){
        this.props.store.searchPanel.showOrderList = false;
    }

    getSubStr(s: string, sep: string = " ", timeLen = 8): {a: string, b: string}{
        let t11 = "";
        let t12 = "";
        if (isString(s)){
            let t1 = s.split(sep).filter(a => a != "");
            if (t1.length >=2){
                t11 = t1[0];
                t12 = t1[1].substring(0, timeLen);
            }
        }
        return {a: t11, b: t12};
    }
    @action
    onClickReload(){
        this.props.store.orderList.doLoadList();
    }

    render() {
        let store = this.props.store;
        let ol = store.orderList;
        let showTable = (ol.loaded && ol.orders);
        let items: any[] = [];
        if (showTable){
            ol.orders.forEach( a => {
                let isLegacy = (a as any).order_type != undefined;
                let t1 = this.getSubStr(a.ctime, isLegacy? " ": "T");
                let t2 = this.getSubStr(a.etime, isLegacy? " ": "T");
                // let downloadUrl = isLegacy? "/api/order_proc/result" : "/api/proc_proxy/order";
                // let canDownload = (isLegacy && a.status == LegacyOrderStatus.SUCCESS && a.can_download) || (!isLegacy && a.status == OrderStatus.DONE);
                // let canCancel = (!isLegacy && a.status != OrderStatus.DONE);
                let downloadUrl = `/api/proc_proxy/order/${a.order_id}`;
                let canDownload = a.status == OrderStatus.DONE;
                let canCancel = (!isLegacy && a.status != OrderStatus.DONE && a.status != OrderStatus.CANCELED && a.status != OrderStatus.CANCEL);

                items.push(<tr key={a.order_id.toString()}>
                    <td>{a.order_id}</td>
                    <td><OrderLink order_id={a.order_id} order_name={a.name} is_legacy={isLegacy} store={store} /></td>
                    <td>{a.email}</td>
                    <td className="journal-table-td-start"><span className="bold">{t1.a}</span> {t1.b} / <span className="bold">{t2.a}</span> {t2.b}</td>
                    <td>
                        {/* {isLegacy && a.status == LegacyOrderStatus.ERROR && <OrderStatusError store={store} />}
                        {isLegacy && a.status == LegacyOrderStatus.SUCCESS && <OrderStatusReady store={store} />}
                        {isLegacy && a.status == LegacyOrderStatus.WAIT && <OrderStatusWork store={store} />}
                        {isLegacy && a.status == LegacyOrderStatus.DELETED && <OrderStatusDeleted store={store} />} */}

                        {!isLegacy && a.status == OrderStatus.DONE && a.result == OrderResult.SUCCESS && <OrderStatusReady store={store} />}
                        {!isLegacy && a.status == OrderStatus.DONE && a.result == OrderResult.ERRORS && <OrderStatusWarning store={store} />}
                        {!isLegacy && a.status == OrderStatus.DONE && a.result == OrderResult.FATAL && <OrderStatusError store={store} />}
                        {!isLegacy && (a.status == OrderStatus.WAIT || a.status == OrderStatus.PAUSE || a.status == OrderStatus.RUN || a.status == OrderStatus.STANDBY)
                            && <OrderStatusWork store={store} />}
                        {!isLegacy && [OrderStatus.CANCELED, OrderStatus.CANCEL].indexOf(a.status) >= 0 && <OrderStatusCanceled store={store} />}
                    </td>
                    <td>
                        {canDownload && <a className="journal-action journal-status" href={ downloadUrl + "?download=1"}>
                            <OrderActionDownloadComp store={store} order_id={a.order_id} /></a> }
                        {canCancel && <OrderActionCancelComp store={store} order_id={a.order_id} />}
                    </td>
                </tr>);
            });
        }


        return <div className="modal-back">
            <div className="modal-full modal-full-frame modal-with-header journal-window">

                <div className="modal-header">
                    <div className="top_logo journal-logo"/>
                    <div className="modal-button-close" onClick={this.onClose}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z"
                                fill="#C5C5C5"/>
                        </svg>
                    </div>
                </div>
                <div className="modal-body flex-rows flex-stretch-item">
                    <div className ="flex-columns flex-align-items-center">
                        {ol.loading && <div className="journal-body-title header-text text-white2">{store.trans["Loading..."]}</div>}
                        {!ol.loading && <div className="journal-body-title header-text text-white2">{store.trans["Order history"]}</div>}
                        <span className="icon_container icon_container_fill relative" onClick={this.onClickReload}>
                            <div className={classNames("full-center-content", {"rotating": ol.loading})}>
                                <RefreshSvg className="" />
                            </div>
                        </span>
                    </div>
                    {showTable &&
                        <div className="journal-body-div journal-body-div1 table-scroll style-4 text-white journal-text bold">
                            <table className="table1-head journal-table-all journal-table1">
                                <colgroup>
                                    <col className="journal-table-col-id"/>
                                    <col className="journal-table-col-title"/>
                                    <col className="journal-table-col-email"/>
                                    <col className="journal-table-col-start"/>
                                    <col className="journal-table-col-status"/>
                                    <col className="journal-table-col-action"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <td>{store.trans["Order ID"]}</td>
                                    <td>{store.trans.Title}</td>
                                    <td>{store.trans.Email}</td>
                                    <td className="journal-table-td-start">{store.trans["Start / Finish"]}</td>
                                    <td>{store.trans.Status}</td>
                                    <td>{store.trans.Action}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    {showTable &&
                    <div className="journal-body-div journal-body-div2 flex-fit-item table-scroll style-4 text-white journal-text">
                        <table className="table1-body journal-table-all journal-table2">
                            <colgroup>
                                <col className="journal-table-col-id"/>
                                <col className="journal-table-col-title"/>
                                <col className="journal-table-col-email"/>
                                <col className="journal-table-col-start"/>
                                <col className="journal-table-col-status"/>
                                <col className="journal-table-col-action"/>
                            </colgroup>
                            <tbody>
                            {items}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>
            </div>
        </div>;
    }
}
