import * as React from 'react';
import {RefObject} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {Utils} from "../../../../helper/utils/Utils";
import classNames from "classnames";
import {MeasuringStatus} from "../../../../helper/structs/MeasuringStatus";
import {SelectGeometryType} from "./SelectGeometryType";
import './ParamSearchPanel.scss';
import {MyHint} from "../../../Common/MyHint";

export interface ISpatialButtonsComp extends IStoreProps{
    className?: string;
    classNameButton?: string;
    onlyPolygonButtons?: boolean;
    activeArea?: boolean;
    notAccent?:boolean
}

@observer
export class SpatialButtonsComp extends React.Component<ISpatialButtonsComp, undefined> {
    constructor(props: ISpatialButtonsComp) {
        super(props);
        autoBindReact(this);
        this.inputOpenFileRef = React.createRef();
    }

    private readonly inputOpenFileRef : RefObject<HTMLInputElement>;

    componentDidMount() {
        if (this.inputOpenFileRef.current != null)
            this.inputOpenFileRef.current.onchange = this.props.store.map.uploadChanged;
    }

    @action showOpenFileDlg() {
        this.inputOpenFileRef.current.click();
    }

    @action
    async onClearGeometry(){
        let ms = this.props.store.map;
        try {
            await ms.searchObject.clearAndSetDefault();
        }catch (e) {
            this.props.store.addError(e);
        }
    }

    @action onDownload(){
        let ms = this.props.store.map;
        let fileName = (ms.searchObject.name? ms.searchObject.name : 'border') + '.geojson';
        Utils.downloadJson(fileName, ms.searchObject.content);
    }
    @action onZoom(){
        this.props.store.map.zoomToSearchObject();
    }

    render() {
        let store = this.props.store;
        let ms = store.map;
        let areaText = ms.searchObject.getAreaStr; //(area / 1000000).toString() + " km²";

        return <div className={classNames("flex-columns", this.props.className)}>
            <input type="file" ref={this.inputOpenFileRef}
                   onChange={ms.uploadChanged} accept=".json,.geojson"
                   style={{opacity: 0.0, cursor: "pointer", display: "none"}}/>

                <SelectGeometryType className={classNames("ParamSearchPanel-spatialButton", this.props.classNameButton)} store={store}
                                    showLine={(this.props.onlyPolygonButtons ?? false)== false}
                                    showUpload={(this.props.onlyPolygonButtons ?? false) == false}
                                    onLine={ms.createLine}
                                    onPolygon={ms.createPolygon}
                                    onRect={ms.createRectangle}
                                    onUpload={this.showOpenFileDlg}
                                    notAccent={this.props.notAccent}
                />
  {ms.searchObject.isNotEmpty &&
                <React.Fragment>
                      {/* <div
                        className={classNames("icon_container icon_container_fill ParamSearchPanel-spatialButton", this.props.classNameButton)}
                        onClick={this.onZoom}>
                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_10_3727)">
                                <path
                                    d="M8 11.5C9.933 11.5 11.5 9.933 11.5 8C11.5 6.067 9.933 4.5 8 4.5C6.067 4.5 4.5 6.067 4.5 8C4.5 9.933 6.067 11.5 8 11.5Z"
                                    fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M0.5 5.5V2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5H5"
                                    fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M10.5 0.5H14C14.3978 0.5 14.7794 0.658035 15.0607 0.93934C15.342 1.22064 15.5 1.60218 15.5 2V5.5"
                                    fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M15.5 10.5V14C15.5 14.3978 15.342 14.7794 15.0607 15.0607C14.7794 15.342 14.3978 15.5 14 15.5H10.5"
                                    fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M5.5 15.5H2C1.60218 15.5 1.22064 15.342 0.93934 15.0607C0.658035 14.7794 0.5 14.3978 0.5 14V10.5"
                                    fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_10_3727">
                                    <rect width="16" height="16" fill="white" stroke="none"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div> */}
                    <MyHint text={this.props.store.trans.Download}>
                        <div
                            className={classNames("icon_container icon_container_fill ParamSearchPanel-spatialButton", this.props.classNameButton)}
                            onClick={this.onDownload}>
                            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.87502 11.025V3.5H6.12502V11.025L2.62502 7.525L1.40002 8.75L6.38752 13.7375C6.56252 13.9125 6.73752 14 7.00002 14C7.26252 14 7.43752 13.9125 7.61252 13.7375L12.6 8.75L11.375 7.525L7.87502 11.025Z"/>
                                <path d="M13.125 0H0.875V1.75H13.125V0Z"/>
                            </svg>
                        </div>
                    </MyHint>
                    <MyHint text={this.props.store.trans.Delete}>
                        <div
                            className={classNames("icon_container red-svg ParamSearchPanel-spatialButton", this.props.classNameButton)}
                            onClick={this.onClearGeometry}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_154_47)">
                                <path
                                        d="M2.5 5.5V14C2.5 14.828 3.172 15.5 4 15.5H12C12.828 15.5 13.5 14.828 13.5 14V5.5"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M0.5 3.5H15.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.5 3.5V0.5H10.5V3.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M8 7.5V12.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.5 7.5V12.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.5 7.5V12.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_154_47">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </MyHint>
                </React.Fragment>
            }
            { this.props.activeArea && <span className="ParamSearchPanel-textArea">{areaText}</span>}
        </div>;
        
    }
    
}
