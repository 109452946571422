import * as React from 'react';
import autoBindReact from "auto-bind/react";
import './ProgressUploadComp.scss';
import classNames from "classnames";

export interface IProgressUploadCompProps {
    className: string;
    color?: 'red' | 'green';
    progress: number; //0..1
}

export class ProgressUploadComp extends React.PureComponent<IProgressUploadCompProps, undefined> {
    constructor(props: IProgressUploadCompProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        //"UploadComp-image-progress"
        let proc = Math.round(this.props.progress * 100);

        return <div className={classNames(this.props.className, "ProgressUploadComp-box")}>
            <div className={classNames("ProgressUploadComp-progress",
                {"ProgressUploadComp-red":this.props.color == 'red',
                    "ProgressUploadComp-green": this.props.color == 'green'})} style={{width: proc.toString()+"%"}} />
        </div>;
    }
}
