// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoRecNormComp-main {
  display: flex;
  flex-direction: column;
}

.AgroAhoRecNormComp-header {
  display: flex;
  align-items: center;
  background: #30363E;
  padding: 7px 16px;
  color: #C5C5C5;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 10px;
}

.AgroAhoRecNormComp-items {
  padding-bottom: 5px;
}

.AgroAhoRecNormComp-name {
  color: #C5C5C5;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AgroAhoRecNormComp-triangle {
  margin-right: 10px;
}

.AgroAhoRecNormComp-splitter {
  flex-grow: 1;
}

.AgroAhoRecNormComp-del {
  display: none;
}

.AgroAhoRecNormComp-header:hover .AgroAhoRecNormComp-del {
  display: block;
}

.AgroAhoRecNormComp-hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/card/AgroAhoRecNormComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,cAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGF;;AADA;EACE,kBAAA;AAIF;;AAFA;EACE,YAAA;AAKF;;AAHA;EACE,aAAA;AAMF;;AAHA;EACE,cAAA;AAMF;;AAJA;EACE,aAAA;AAOF","sourcesContent":[".AgroAhoRecNormComp-main {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.AgroAhoRecNormComp-header {\r\n  display: flex;\r\n  align-items: center;\r\n  background: #30363E;\r\n  padding: 7px 16px;\r\n  color: #C5C5C5;\r\n  font-family: \"Open Sans\", sans-serif;\r\n  font-size: 12px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: normal;\r\n  cursor: pointer;\r\n  margin-bottom: 10px;\r\n}\r\n.AgroAhoRecNormComp-items {\r\n  padding-bottom: 5px;\r\n}\r\n.AgroAhoRecNormComp-name {\r\n  color: #C5C5C5;\r\n  font-family: \"Open Sans\", sans-serif;\r\n  font-size: 12px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: normal;\r\n}\r\n.AgroAhoRecNormComp-triangle {\r\n  margin-right: 10px;\r\n}\r\n.AgroAhoRecNormComp-splitter {\r\n  flex-grow: 1;\r\n}\r\n.AgroAhoRecNormComp-del {\r\n  display: none;\r\n  //fill: #E94F4F;\r\n}\r\n.AgroAhoRecNormComp-header:hover .AgroAhoRecNormComp-del {\r\n  display: block;\r\n}\r\n.AgroAhoRecNormComp-hidden {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
