import React from "react";

export interface IItemMenuSvgProps {
    className?: string;
}

const ItemMenuSvg = (props: IItemMenuSvgProps) => {
    return <svg width="16" height="16" viewBox="0 0 16 16" className={props.className} xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="3"/>
        <path d="M6.83329 8.00016C6.83329 8.6445 7.35563 9.16683 7.99996 9.16683C8.64429 9.16683 9.16663 8.6445 9.16663 8.00016C9.16663 7.35583 8.64429 6.8335 7.99996 6.8335C7.35563 6.8335 6.83329 7.35583 6.83329 8.00016Z" fill="#2B3138"/>
        <path d="M6.83329 4.50016C6.83329 5.1445 7.35563 5.66683 7.99996 5.66683C8.64429 5.66683 9.16663 5.1445 9.16663 4.50016C9.16663 3.85583 8.64429 3.3335 7.99996 3.3335C7.35563 3.3335 6.83329 3.85583 6.83329 4.50016Z" fill="#2B3138"/>
        <path d="M6.83329 11.5002C6.83329 12.1445 7.35563 12.6668 7.99996 12.6668C8.64429 12.6668 9.16663 12.1445 9.16663 11.5002C9.16663 10.8558 8.64429 10.3335 7.99996 10.3335C7.35563 10.3335 6.83329 10.8558 6.83329 11.5002Z" fill="#2B3138"/>
    </svg>;
}

export default ItemMenuSvg;