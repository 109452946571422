import {CustomStore} from "../../CustomStore";

export abstract class MapCustomItemStore extends CustomStore{
    itemId: string = "";
    @observable
    title: string = "";
    @observable
    protected _visible: boolean = true;

    get visible(): boolean{
        return this._visible;
    }
    set visible(value : boolean){
        this._visible = value;
    }

    get asFolder(): MapFolderItemStore{
        return this as any;
    }

    get asLayer(): MapLayerItemStore{
        return this as any;
    }

    isVisible(): boolean{
        if (!this.visible) return false;
        if (this.parentFolder != null) return this.parentFolder.isVisible();
        return true;
    }

    abstract setVisibleOnMap(visible: boolean): any;


    abstract get itemType():MapItemType;

    get parentFolder(): MapFolderItemStore{
        if (this.parent == null) return null
        if (this.parent instanceof MapFolderItemStore) return this.parent as MapFolderItemStore;
        return null;
    }

    getIndexInParent(): number{
        return this.parentFolder.items.indexOf(this);
    }

    get parentMap(): MapRootStore{
        let p: MapCustomItemStore = this;
        while (p.parentFolder != null){
            p = p.parentFolder;
        }
        if (p.parent instanceof MapRootStore){
            return p.parent as MapRootStore;
        }
        return null;
    }

}
import {observable} from "mobx";
import {MapRootStore} from "./MapRootStore";
import {MapFolderItemStore} from "./MapFolderItemStore";
import {MapLayerItemStore} from "./MapLayerItemStore";
import {MapItemType} from "../../config/MapConfigInterfaces";
