import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import '../A2Common/Agro2Common.scss';
import './Agro2CreateField.scss';
import classNames from "classnames";
import {action, observable} from "mobx";
import area from "@turf/area";
import {LeftPanelMode} from "../../../../../store/SearchStore";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../../icons/LoadingAniSvg";
import {GeometryUtils, SimpleGeometryType} from "../../../../../helper/utils/GeometryUtils";
import bbox from "@turf/bbox";
import {BBox2d, Polygon} from "@turf/helpers/dist/js/lib/geojson";
import {ra} from "../../../../../helper/utils/mobxUtils";
import {A2FieldEditorKeyComp} from "./A2FieldEditorKeyComp";
import {A2FieldEditorALLInputComp} from "./A2FieldEditorALLInputComp";
import {BackCircle} from "./BackCircle";
import {NotSelectedFieldsComp} from "../A2Common/NotSelectedFieldsComp";
import {A2SubModeLeftPanel} from "../../../../../store/agro/A2AgroStore";
import {Agro2CreateField_CreateGeomButton} from "./Agro2CreateField_CreateGeomButton";
import length from "@turf/length";
import {FieldEditModeType} from "../../../../../store/agro/fieldForm/A2FieldFormCustomStore";
import {IA2FieldEditorProps} from "./A2FieldEditorCustomInputComp";
import { MeasuringStatus } from '../../../../../helper/structs/MeasuringStatus';
import { SuperStore } from '../../../../../store/SuperStore';
import { A2FieldFormStore } from '../../../../../store/agro/fieldForm/A2FieldFormStore';
import { CreateFieldMode } from '../../../../../store/agro/Confirms/A2ConfirmCreateStore';
import { ProjType } from '../../../../../store/user/UserStore';


@observer
export class Agro2CreateField extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    componentDidMount() {
        //document.addEventListener("keyup", this.onKeyDown);
    }
    componentWillUnmount() {
        //document.removeEventListener("keyup", this.onKeyDown);
    }

    @action
    geometryButtonEndAnimation(){
        this.props.store.agro2.fieldEditorForm.labelsErrorAnimation = false;
    }

    @observable
    sending: boolean = false;

    createWithConfirmation(parts: Polygon[]) {
        let agro = this.props.store.agro2;
        let cc = agro.confirmCreate;
        cc.baseName = agro.fieldEditorForm.name;
        cc.fieldsCount = parts.length;
        cc.onCreate = this.doCreate;
        cc.doShow();
    }

    @action
    async doCreate(mode: CreateFieldMode = CreateFieldMode.multiPart) {
        let store = this.props.store;
        let agro = store.agro2;
        let form = agro.fieldEditorForm;
        this.sending = true;
        try {
            await form.saveNewValues();

            await form.doSave(mode);
            ra(()=>{
                agro.fieldToolController.removeGeometry();
                agro.fieldToolController.autoPolygon.editingPolygon = null;
                store.map.resetMeasuringStatus();
                if (form.editMode == FieldEditModeType.multiEdit || form.editMode == FieldEditModeType.groupEdit) {
                    store.searchPanel.closeAllLeftPanels();
                    form.showFieldMultiEditPanel = false;
                } else {
                    store.searchPanel.switchPanel(LeftPanelMode.agro);                    
                }
                agro.subModeLeftPanel = A2SubModeLeftPanel.tree;
                agro.incVersionFieldTiles();
            });
        }catch (err){
            store.addError(err);
        }finally {
            ra(()=>{
                this.sending = false;
            });
        }
    }

    @action
    async onClickSubmit(){
        let store = this.props.store;
        let agro = store.agro2;
        let form = agro.fieldEditorForm;
        if (store.map.measuringStatus == MeasuringStatus.agroAutoPolygon && agro.fieldToolController.autoPolygon.canApply) {
            form.createGeometryButtonDown = false;
            agro.fieldToolController.autoPolygon.applyAll();
            store.map.resetMeasuringStatus();
        }
        form.submitClicked = true;
        if (!this.submitEnable()) return;
        if ([FieldEditModeType.insert, FieldEditModeType.edit, FieldEditModeType.multiEdit].indexOf(form.editMode) >= 0) {
            if (!form.checkFieldsValid()) {
                form.labelsErrorAnimation = true;
                return;
            }
        }        
        let parts = agro.editManager.multiPolygonParts;
        if (form.editMode == FieldEditModeType.insert && agro.projectInfo.projectType == ProjType.agro_insur && parts.length > 1)
            this.createWithConfirmation(parts);
        else
            await this.doCreate();
    }
    submitEnable(): boolean{
        let form = this.props.store.agro2.fieldEditorForm;// || form.editMode == FieldEditModeType.edit
        if (this.sending) return false;
        if (form.editMode == FieldEditModeType.groupEdit|| form.editMode == FieldEditModeType.multiEdit) return form.changedColumns.size > 0;
        return true;
    }


    @action
    onCancel(){
        this.props.store.map.resetMeasuringStatus();
        this.props.store.agro2.fieldToolController.autoPolygon.editingPolygon = null;
        if (this.props.store.agro2.fieldEditorForm.editMode == FieldEditModeType.multiEdit
            || this.props.store.agro2.fieldEditorForm.editMode == FieldEditModeType.groupEdit
        ){
            this.props.store.agro2.fieldEditorForm.showFieldMultiEditPanel = false;
        }else {
            this.props.store.agro2.subModeLeftPanel = A2SubModeLeftPanel.tree;
        }
    }

    async getBbox(): Promise<BBox2d>{
        let form = this.props.store.agro2.fieldEditorForm;

        if (form.editMode == FieldEditModeType.groupEdit){
            let bbox = await this.props.store.agro2.getFieldsBboxByFilter(this.props.store.agro2.fieldEditorForm.multiGroup_filters);
            return bbox;
        }else
        if (form.editMode == FieldEditModeType.multiEdit){
            let bbox = await this.props.store.agro2.getFieldsBbox(form.multi_fieldId);
            return bbox;
        }else {
            let geom = this.props.store.agro2.editManager.getGeometry();
            if (geom != null && !GeometryUtils.isGeometryEmpty(geom)) {
                let b = bbox(geom) as BBox2d;

                return b;
            }
        }
        return null;
    }
    async clickCenter(){
        try {
            let bbox = await this.getBbox();
            if (bbox != null) this.props.store.map.zoomToBBox1in3(bbox);
        }catch (err){
            this.props.store.addError(err);
        }
    }

    getAreasStr(): string{
        let store = this.props.store;
        let form = store.agro2.fieldEditorForm;
        let areaStr = "";
        if (form.status == LoadStatus.ready) {
            if (form.editMode == FieldEditModeType.groupEdit){
                areaStr = (form.multiGroup_areaFields??0).toFixed(2) + store.trans.ha;
            }else
            if (form.editMode == FieldEditModeType.multiEdit) {
                let sumArea = 0;
                form.multi_features_properties.forEach(f => {
                    let a = f[this.props.store.agro2.projectInfo.fieldName_area_sys];
                    sumArea += a;
                });
                areaStr = sumArea.toFixed(2) + store.trans.ha;
            } else {
                let g = store.agro2.editManager.getGeometry();
                if (g != null) {
                    let sg = GeometryUtils.getSimpleGeometries(g);
                    let types = GeometryUtils.getSimpleTypes(sg);
                    if (types.length == 0) return areaStr;
                    if (types.find(a => a == SimpleGeometryType.Polygon)) {
                        let a = area(g as any) / 10000;
                        areaStr = a.toFixed(2) + store.trans.ha;
                        return areaStr;
                    }
                    if (types.find(a => a == SimpleGeometryType.Line)) {
                        let sum = 0;
                        sg.filter(a => a.simple == SimpleGeometryType.Line).forEach(a => {
                            sum += length(GeometryUtils.createGeometryBySimpleGeometry([a]) as any, {units: "kilometers"});
                        });
                        areaStr = sum.toFixed(2) + store.trans.km;
                        return areaStr;
                    }

                }
            }
        }
        return areaStr;
    }
    onKeyDown(event: any): any{
        let store = this.props.store;
        if (event.target.tagName.toUpperCase() == 'INPUT') return;
        if (event.code == "KeyZ" || (event.ctrlKey && event.keyCode == 90)){
            if (store.agro2.editManager.state.actionManager.canUndo()){
                store.agro2.editManager.state.actionManager.undo();
            }
        }
        if (event.code == "KeyY" || (event.ctrlKey && event.keyCode == 89)){
            if (store.agro2.editManager.state.actionManager.canRedo()){
                store.agro2.editManager.state.actionManager.redo();
            }
        }
    }
    @action
    onChangeAnyProp(data: any){
        let store = this.props.store;
        if (store.agro2.fieldEditorForm.notValidProperties[data])
            store.agro2.fieldEditorForm.notValidProperties[data] = null;
    }

    render() {
        let store = this.props.store;
        let form = store.agro2.fieldEditorForm;
        let geometryValid = true;
        let nameValid = true;

        let fieldsStr = "";
        if (store.agro2.fieldEditorForm.status == LoadStatus.loading) return <LoadingAniSvg />;

        let areaStr = this.getAreasStr();
        if (form.status == LoadStatus.ready) {
            if (form.editMode == FieldEditModeType.groupEdit) {
                fieldsStr = `${store.trans.ObjectsFields}: ${form.multiGroup_countFields}, `;
            }else
            if (form.editMode == FieldEditModeType.multiEdit) {
                fieldsStr = `${store.trans.ObjectsFields}: ${form.multi_fieldId.length}, `;
            }
        }

        let isMultiEdit = form.editMode == FieldEditModeType.multiEdit || form.editMode == FieldEditModeType.groupEdit;
      
        let propsArr: any[] = [];
        if (form.status == LoadStatus.ready) store.agro2.projectStruct.columns_items.forEach(a =>{
            //if (a.column_name == store.agro2.projectInfo.fieldName_columnName) return;

            if (!a.show) return;
            let vt = store.agro2.fieldEditorForm.notValidProperties[a.column_name];

            let propsComp: IA2FieldEditorProps = {
                className: "Agro2CreateField-createFerm-lr_margin",
                isReadonly: a.readOnly,
                hasDiffValue:form.hasDiffValue.has(a.column_name),
                isMultiEdit: isMultiEdit,
                columnInfo:a,
                field: store.agro2.fieldEditorForm,
                store: store,
                validType:vt,
                key: a.column_name,
                data: a.column_name,
                onChange: this.onChangeAnyProp
            };
            store.events.onAgroFormCreatePropertyComponent.call(propsComp);
            propsArr.push(<A2FieldEditorKeyComp {...propsComp} key={propsComp.key} >
                <A2FieldEditorALLInputComp hasDiffValue={propsComp.hasDiffValue} isMultiEdit={propsComp.isMultiEdit} isReadonly={propsComp.isReadonly}
                                           columnInfo={propsComp.columnInfo} field={propsComp.field} store={store} validType={propsComp.validType}
                                           data={propsComp.data} onChange={propsComp.onChange}
                />
            </A2FieldEditorKeyComp>);
        });
        propsArr = store.events.onAgroFormProperties.call(propsArr);
        let labelsErrorAnimation = this.props.store.agro2.fieldEditorForm.labelsErrorAnimation;
        if (form.editMode == FieldEditModeType.multiEdit && form.multi_fieldId.length == 0){
            return <div className={classNames("Agro2Common-leftDiv", this.props.className)}>
                <div className="Agro2Common-Form-div">
                    <div className="Agro2Common-Form-body style-4 overflowAutoY">
                        {/* заголовок */}
                        <div className="Agro2Common-Form-header Agro2Common-lr_margin">
                            <div className="Agro2Common-Form-notArray">
                            <NotSelectedFieldsComp onClose={this.onCancel} store={store}>
                                <div className="blueSilver-text pre text-center">{store.trans["Objects not selected"]}</div>
                            </NotSelectedFieldsComp>
                            </div>
                        </div></div></div></div>;

        }

        let withGeometryButton = (form.editMode == FieldEditModeType.insert || form.editMode == FieldEditModeType.edit);
        return <div className={classNames("Agro2Common-leftDiv", this.props.className)} tabIndex={0} onKeyUp={this.onKeyDown} >

            <div className="Agro2Common-Form-div">
                <div className="Agro2Common-Form-body style-4 overflowAutoY">
                    {/* заголовок */}
                    <div className="Agro2Common-Form-header Agro2Common-lr_margin">
                        {(form.editMode == FieldEditModeType.edit || form.editMode == FieldEditModeType.insert ) &&
                            <BackCircle className="Agro2Common-backCircleRightMarg" onClick={this.onCancel}/>}
                        {(form.editMode == FieldEditModeType.multiEdit || form.editMode == FieldEditModeType.groupEdit) &&
                            <span className="flex-stretch-item">{store.trans.objects_editing}</span>}
                        {form.editMode == FieldEditModeType.edit && <span className="flex-stretch-item">{store.trans.object_editing}</span>}
                        {form.editMode == FieldEditModeType.insert && <span className="flex-stretch-item">{store.trans.object_create}</span>}
                        {this.getBbox() != null && <div className="flex-full-center Agro2CreateField-centerButton" onClick={this.clickCenter}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 8.25C7.24264 8.25 8.25 7.24264 8.25 6C8.25 4.75736 7.24264 3.75 6 3.75C4.75736 3.75 3.75 4.75736 3.75 6C3.75 7.24264 4.75736 8.25 6 8.25Z" fill="#C5C5C5"/>
                                <path d="M1.5 4.5H0V0.75C0 0.3 0.3 0 0.75 0H4.5V1.5H1.5V4.5Z" fill="#C5C5C5"/>
                                <path d="M12 4.5H10.5V1.5H7.5V0H11.25C11.7 0 12 0.3 12 0.75V4.5Z" fill="#C5C5C5"/>
                                <path d="M11.25 12H7.5V10.5H10.5V7.5H12V11.25C12 11.7 11.7 12 11.25 12Z" fill="#C5C5C5"/>
                                <path d="M4.5 12H0.75C0.3 12 0 11.7 0 11.25V7.5H1.5V10.5H4.5V12Z" fill="#C5C5C5"/>
                            </svg>
                        </div>}
                        {areaStr != "" && <div className="Agro2Common-Form-headerArea">{fieldsStr} {areaStr}</div>}
                    </div>

                    {/* Название и контур */}
                    {withGeometryButton &&
                        <div className="Agro2CreateField-text_with_button">
                        <div className="Agro2CreateField-text_with_button-div1">
                            {propsArr.length > 0 && propsArr[0]}
                            {/*
                            <A2FieldEditorKeyComp columnInfo={store.agro2.projectStruct.getColumnByName(store.agro2.projectInfo.fieldName_columnName)}
                                                  field={form} store={store}
                                                  validType={form.notValidProperties[store.agro2.projectInfo.fieldName_columnName]}>
                                <A2FieldEditorStringComp
                                    columnInfo={store.agro2.projectStruct.getColumnByName(store.agro2.projectInfo.fieldName_columnName)}
                                    field={form} store={store}
                                    validType={form.notValidProperties[store.agro2.projectInfo.fieldName_columnName]}/>
                            </A2FieldEditorKeyComp>*/}
                        </div>
                        <div className="Agro2CreateField-text_with_button-div2">
                            <div className="Agro2CreateField-createFerm-nameLine">
                                {/*
                                <div className="flex-stretch-item">
                                    <span
                                        className={classNames({
                                            "Agro2CreateField-redLabelsDrag": !form.geometryValid && labelsErrorAnimation,
                                            "white-text":form.geometryValid && !labelsErrorAnimation,
                                            "red-text": !form.geometryValid})}>{store.trans.object_border}</span>
                                    <span className={classNames("Agro2Common-createFerm-star",
                                        {"Agro2CreateField-redLabelsDrag": !form.geometryValid && labelsErrorAnimation,
                                            "blue-text":form.geometryValid && !labelsErrorAnimation,
                                            "red-text": !form.geometryValid})}>*</span>
                                </div>
                                {!form.geometryValid && <div className="flex-full-center"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5Z" stroke="#E94F4F" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5 12C8.05228 12 8.5 11.5523 8.5 11C8.5 10.4477 8.05228 10 7.5 10C6.94772 10 6.5 10.4477 6.5 11C6.5 11.5523 6.94772 12 7.5 12Z" fill="#E94F4F"/>
                                    <path d="M7.5 3.5V8" stroke="#E94F4F" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg></div>}*/}
                            </div>
                            <div className="Agro2CreateField-geomDiv">
                                <Agro2CreateField_CreateGeomButton required={true} validGeometry={store.agro2.fieldEditorForm.geometryValid} store={store} />
                            </div>
                        </div>
                    </div>
                    }
                    {withGeometryButton && propsArr.slice(1)}
                    {!withGeometryButton && propsArr}
                </div>
                {/* футер */}
                <div className="Agro2Common-Form-footer Agro2Common-lr_margin">
                    <button className="left-sidebar-cancel button-jump Agro2Common-Form-footerButton" onClick={this.onCancel}>{store.trans.Cancel}</button>
                    {store.agro2.fieldEditorForm.editMode == FieldEditModeType.insert &&
                        <button onClick={this.onClickSubmit} className={classNames("left-sidebar-active button-jump Agro2Common-Form-footerButton",
                            {"opacity50": !this.submitEnable()})}
                    >{store.trans.Create}</button>}
                    {store.agro2.fieldEditorForm.editMode == FieldEditModeType.edit &&
                    <button onClick={this.onClickSubmit} className={classNames("left-sidebar-active button-jump Agro2Common-Form-footerButton",
                        {"opacity50": !this.submitEnable()})}>{store.trans.Save}</button>}
                    {store.agro2.fieldEditorForm.editMode == FieldEditModeType.multiEdit &&
                        <button onClick={this.onClickSubmit} className={classNames("left-sidebar-active button-jump Agro2Common-Form-footerButton",
                            {"opacity50": !this.submitEnable()})}>{store.trans.Save}</button>}
                    {store.agro2.fieldEditorForm.editMode == FieldEditModeType.groupEdit &&
                        <button onClick={this.onClickSubmit} className={classNames("left-sidebar-active button-jump Agro2Common-Form-footerButton",
                            {"opacity50": !this.submitEnable()})}>{store.trans.Save}</button>}
                </div>
            </div>
        </div>;
    }
}
