import {Translate_en} from "./Translate_en";
import {observable} from "mobx";

export class Translate_hin extends Translate_en{
    "Sentinel-1" = ""
    "About CLASS"="क्लास के बारे में"
"Active"="सक्रिय"
"Administrator"="प्रशासक"
"Already have an account?"="्या पहले से ही एक खाता है?"
"Are you sure to delete all points?"="क्या आप सभी बिंदुओं को हटाना सुनिश्चित कर रहे हैं?"
"Are you sure you want to delete this farm?"="क्या आप सुनिश्चित हैं कि आप इस फार्म को हटाना चाहते हैं?"
"Attention!"="ध्यान!"
"Blocked"="अवरुद्ध"
"By clicking on the \"Sign up\" button, I accept the {0} and {1} and consent to the processing of personal data, as well as to receive advertising and other types of letters associated with my account"="साइन अप विवरण पर क्लिक करके"
"Change field"="फ़ील्ड बदलें"
"CLASS system"="क्लास सिस्टम"
"Climate"="मौसम"
"Congratulations!"="बधाइयाँ!"
"Copied!"="कॉपी हो गया!"
"Copy coordinates"="कॉपी निर्देशांक"
"Get geometries"="Get geometries"
"Create farm"="खेत बनाएं"
"Create object"="फ़ील्ड बनाएं"
"Create project"="परियोजना बनाएं"
"Crop"="फसल"
"Crop rotation"="फसल चक्र"
"Delete crop rotation"="फसल रोटेशन हटाएं"
"Delete for season(s)"="मौसम के लिए हटाएं"
"Delete selection"="चयन हटाएं"
"Description"="वर्णन"
"Draw"="खीचिये"
"Edit crop rotation"="फसल चक्र संशोधित करे"
"Edit farm"="खेत संशोधित करे"
"Edit project"="परियोजना को संशोधित करे"
"Email is also your login"="ईमेल पर आपका लॉगिन है"
"Mail confirmed"="ईमेल पुष्टि विवरण"
"Enter the email address to which we will send you an email with a link to create a new password"="ईमेल विवरण दर्ज करें"
"Farm name"="खेत का नाम"
"Farms"="खेतों"
"Object border"="फ़ील्ड बॉर्डर"
"Field name"="फ़ील्ड नाम"
"ObjectsFields"="फ़ील्ड"
"Meteo"="Meteo"
"Fields total"="कुल फील्ड्स"
"File"="फ़ाइल"
"File loaded. {0} objects have been created."="फ़ाइल वस्तु विवरण भरी हुई है"
"First name"="प्रथम नाम"
"For removing\ncrop rotation is necessary\nselect fields in the left\npanels"="फसल चक्र विवरण हटाने के लिए"
"Forgot password"="पासवर्ड भूल गया"
"Get object profile"="फील्ड प्रोफाइल प्राप्त करें"
"Get point profile"="पॉइंट प्रोफाइल प्राप्त करें"
"Guest"="अतिथि"
ha="हा"
"Harvest date"="कटाई की तारीख"
"Index"="सूची"
"Last enter"="अंतिम दर्ज करें"
"Legal entity"="कानूनी इकाई"
"Legend"="संकेत"
"Load fields"="लोड फ़ील्ड"
"Loading objects from a file"="फ़ाइल से फ़ील्ड लोड करना"
"Log in"="लॉग इन करो"
"Login or Email"="लॉगिन या ईमेल"
"Mode"="मोड"
"Name of the company"="कंपनी का नाम"
"Not yet with us?"="अभी हमारे साथ नहीं?"
"Password"="पासवर्ड"
"Password mismatch"="पासवर्ड बेमेल"
"Please specify first name"="कृपया पहला नाम निर्दिष्ट करें"
"Please specify password"="कृपया पासवर्ड निर्दिष्ट करें"
"Please specify second name"="कृपया दूसरा नाम निर्दिष्ट करें"
"Please specify valid email"="कृपया वैध ईमेल निर्दिष्ट करें"
"Polygon is not included in the spatial filter of images search"="बहुभुज छवियों की खोज के स्थानिक फ़िल्टर में शामिल नहीं है"
"Privacy Policy"="गोपनीयता नीति"
"Project"="परियोजना"
"Project name"="परियोजना का नाम"
"Projects"="परियोजनाओं"
"Radius (m)"="त्रिज्या/रेडियस"
"Registration was successful, an email was sent to your mail with a confirmation link"="पंजीकरण सफलता विवरण"
"Required data"="आवश्यक डेटा"
"Role"="भूमिका"
Save="सेव"
"Search options"="खोज विकल्प"
"Search: coordinates, OSM objects"="खोज: निर्देशांक, OSM वस्तुओं"
"Second name"="उपनाम"
"Security code is incorrect or has expired"="सुरक्षा कोड गलत है या समाप्त हो गया है"
"Select year"="वर्ष का चयन करें"
"Specify mapping from columns of the input file into the properties of your fields"="फ़ाइल अपलोड करने के लिए तालिका की विशेषता चुनें"
"Selected fields"="चयनित फ़ील्ड"
"Send"="भेजना"
"Sending confirmation..."="पुष्टि भेज रहा है..."
"Sign in"="साइन इन करें"
"Sign in via"="के माध्यम से साइन इन करें"
"Sign up"="नाम लिखो"
"Sowing date"="बुवाई की तारीख"
"Status"="ओहदा"
"Terms of Use"="उपयोग की शर्तें"
"Confirm password"="पासवर्ड फिर से"
"The point is not included in the spatial filter of image search"="बिंदु छवि खोज के स्थानिक फ़िल्टर में शामिल नहीं है"
"To edit the\ncrop rotation, you must\nselect the fields in the left\npanel"="एनफसल चक्र विवरण को संशोधित करें"
"Use the cursor {0} to place a point on the map where you would like to calculate the data type"="नक्शे पर एक बिंदु रखने के लिए कर्सर {0} का उपयोग करें जहां आप डेटा प्रकार की गणना करना चाहते हैं"
"User"="उपभोक्ता"
"User name"="उपयोगकर्ता नाम"
"Users"="उपयोगकर्ताओं"
"Variety/species"="विविधता/प्रजातियां"
"Welcome"="स्वागत है"
"Year"="साल"
"There is no georeference"="कोई भू-सम्मान नहीं है"
"Upload"="अपलोड"
"Don't download possible duplicates"="संभावित डुप्लिकेट डाउनलोड न करें"
"Loading stopped"="लोडिंग बंद कर दिया"
"Loading is complete"="लोडिंग पूरा हो गया है"
"Loading"="लोड"
"Upload photos"="फ़ोटो अपलोड करें"
"Not found"="नहीं मिला"
"Add"="जोड़ना"
"Selected photos"="चयनित तस्वीरें"
"Removing tags"="टैग निकालना"
"Adding tags"="टैग जोड़ना"
"Create group"="समूह बनाएं"
"Create tag group"="टैग समूह बनाएं"
"Tags reference"="टैग संदर्भ"
"Changing the tag name"="टैग का नाम बदलना"
"Name"="नाम"
"Change"="परिवर्तन"
"Are you sure you want to remove the tag?"="क्या आप सुनिश्चित हैं कि आप टैग निकालना चाहते हैं?"
"Photos"="तस्वीरें"
"Filters"="फ़िल्टर"
"Gallery"="गैलरी"
"All photos uploaded"="अपलोड की गई सभी तस्वीरें"
"Loading error"="त्रुटि लोड हो रही है"
"You have no uploaded\nphotos, you can\nload them with\nbuttons"="आपके पास कोई अपलोड की गई फाइल नहीं है आप उन्हें बटनों द्वारा अपलोड कर सकते हैं"
"Select files"="फ़ाइलों का चयन करें"
"Uploading photos"="फ़ोटो अपलोड करना"
"Show all"="सभी को दिखाएं"
"Minimize"="छुपाना"
"Search by tags"="टैग द्वारा खोजें"
"Tags"="टैग"
"Use map extent"="मानचित्र सीमा का उपयोग करें"
"Delete photos ({0})?"="तस्वीरें हटाएं ({0})"
"Delete photo"="फोटो हटाएं"
"Delete tags"="टैग हटाएं"
"Photo"="फोटो"
"List"="सूची"
"Farm"="खेत"
"Region"="क्षेत्र"
"Season"="ऋतु"
"Change farm"="खेत बदलें"
"m"="m"
"km"="km"
"m²"="m²"
"km²"="km²"
"Area"="क्षेत्र"
"Palettes"="पट्टियाँ"
"Palette"="रंगपट्टिका"
"Min"="न्यूनतम"
"Max"="अधिकतम"
"Classes"="वर्गों"
"Interpolation"="प्रक्षेप"
"Pixels transparent <min"="पिक्सल पारदर्शी <मिन"
"Pixels transparent >max"="पिक्सल पारदर्शी >मैक्स"
"Default"="डिफ़ॉल्ट"
"Apply"="आवेदन करें"
"Mean +/- STD x"="मीन +/-एसटीडी एक्स"
"Mask"="नकाब"
"Loading..."="लोड हो रहा है"
"Order history"="आदेश इतिहास"
"Order ID"="ऑर्डर आईडी"
"Start / Finish"="शुरू/खत्म"
"Action"="क्रिया"
"Error"="भूल / एरर"
"Ready"="तैयार"
"Wait"="ज़रा रुको"
"Polarization type"="ध्रुवीकरण प्रकार"
"Multitime filtering" = "";
"Comma separated relative orbit numbers" = ""
"Specify search area to activate Run button"="रन बटन को सक्रिय करने के लिए खोज क्षेत्र निर्दिष्ट करें"
"Active area"="खोज क्षेत्र"
"{0} scenes"="{0} एनएससीन्स"
"Quicklook is not found"="क्विकलुक नहीं मिला है"
"Export options"="निर्यात विकल्प"
"Crop to active area"="फसल क्षेत्र खोज करने के लिए"
"Products"="उत्पादों"
"Delete all elements from favorites?"="पसंदीदा से सभी तत्वों को हटाएं?"
"Changing the name of a tag group"="टैग समूह का नाम बदलना"
"Create tag"="टैग बनाएं"
"Delete"="मिटाना"
"Tag"="टैग"
"Spectral bands"="स्पेक्ट्रल बैंड"
"Spectral indexes"="स्पेक्ट्रल इंडेक्स"
"Copied"="कापी"
"Are you sure you want to delete the group?"="क्या आप सुनिश्चित हैं कि आप समूह को हटाना चाहते हैं?"
"Personal Area"="व्यक्तिगत क्षेत्र"
"Log out"="लॉग आउट करें"
"Admin panel"="व्यवस्थापक पैनल"
"Create"="बनाना"
"Update"="अपडेट करें"
"Title"="शीर्षक"
"All"="सब"
"Precipitation, IBM, mm"="वर्षाIbmMM"
"Precipitation mm"="वर्षाएरा5एमएम"
"Close"="बंद करना"
"Cancel upload"="अपलोड रद्द करें"
"NDVI (250 m)"="एनडीएवीआई (250 मीटर)"
"Daily"="दैनिक"
"Daily mean"="दैनिक मीन"
"Precipitation, NOAA"="वर्षा, एनओएए"
"Value type"="मूल्य प्रकार"
"Chart type"="चार्ट प्रकार"
"Accumulated daily mean"="संचित"
"NDVI"="एनडीवीआई"
"Adjust pixel values"="पिक्सेल मानों को समायोजित करें"
"Run"="चलाना"
"Cancel"="रद्द करना"
"Only visible"="केवल दिखाई दे रहा है"
"Task name"="कार्य का नाम"
"Orbit number filter" = ""
"Properties" = ""
"All visible"="सभी दिखाई दे रहे हैं"
"Passive sensors (night)"="निष्क्रिय सेंसर (रात)"
"No images"="कोई चित्र नहीं"
"Back"="वापस"
"Listed"="सूचीबद्ध"
"Total found"="कुल खोज"
"Nothing found, change search parameters"="कुछ नहीं मिला, खोज मापदंडों को बदलें"
"Zoom in to search images"="छवियों को खोजने के लिए ज़ूम इन करें"
"Off nadir angle"="नादिर कोण से दूर"
"Metadata"="मेटाडेटा"
"Images"="छवियां"
"Sensors"="सेंसर"
"Select all"="सभी का चयन करें"
"hello"="नमस्कार"
"Scenes"="दृश्यों"
"Dates"="दिनांकों"
"Date"="दिनांक"
"Image search"="दृश्य खोज"
"Max. cloud coverage"="अधिकतम क्लाउड कवरेज"
"Data type"="डेटा प्रकार"
"Baselayers"="बेसलेयर्स"
"Overlays"="ओवरले"
"Multi temporal overlays"=""
"Reset bearing to north"="उत्तर दिशा में रीसेट करें"
"Click to draw a point"="एक बिंदु बनाने के लिए क्लिक करें"
"Click to draw vetrices and double click to finish"="बिन्दुओ को बनाने के लिए क्लिक करें और  खत्म करने के लिए डबल क्लिक करें"
"Click to draw a vertex, drag and click to finish"="एक वर्टेक्स बनाने के लिए क्लिक करें, खींचें और खत्म करने के लिए क्लिक करें"
"Download"="डाउनलोड"
"Remove"="हटाना"
"Set active area"="खोज क्षेत्र निर्धारित करें"

}