// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroExportInputNumber-main {
  display: flex;
  height: 30px;
  position: relative;
}

.AgroExportInputNumber-unit {
  position: absolute;
  z-index: 20;
  right: 20px;
  top: 7px;
}

.AgroExportInputNumber-arrowTop,
.AgroExportInputNumber-arrowBottom {
  position: absolute;
  z-index: 20;
  right: 7px;
  cursor: pointer;
}

.AgroExportInputNumber-arrowTop {
  top: 15%;
}

.AgroExportInputNumber-arrowBottom {
  bottom: 15%;
}

.AgroExportInputNumber-input {
  padding: 0 33px 0 15px;
}

.AgroExportInputNumber-noUnit {
  padding: 0 15px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroExport/components/AgroExportInputNumber.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,kBAAA;AACF;;AACA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,QAAA;AAEF;;AAAA;;EAEE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;AAGF;;AADA;EACE,QAAA;AAIF;;AAFA;EACE,WAAA;AAKF;;AAHA;EACE,sBAAA;AAMF;;AAJA;EACE,eAAA;AAOF","sourcesContent":[".AgroExportInputNumber-main {\r\n  display: flex;\r\n  height: 30px;\r\n  position: relative;\r\n}\r\n.AgroExportInputNumber-unit {\r\n  position: absolute;\r\n  z-index: 20;\r\n  right: 20px;\r\n  top: 7px;\r\n}\r\n.AgroExportInputNumber-arrowTop,\r\n.AgroExportInputNumber-arrowBottom {\r\n  position: absolute;\r\n  z-index: 20;\r\n  right: 7px;\r\n  cursor: pointer;\r\n}\r\n.AgroExportInputNumber-arrowTop {\r\n  top: 15%;\r\n}\r\n.AgroExportInputNumber-arrowBottom {\r\n  bottom: 15%;\r\n}\r\n.AgroExportInputNumber-input {\r\n  padding: 0 33px 0 15px;\r\n}\r\n.AgroExportInputNumber-noUnit {\r\n  padding: 0 15px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
