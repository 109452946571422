import {A2ReferenceStore} from "./agro/A2ReferenceStore";
import {SuperStore} from "./SuperStore";
import {MapRulerInfoStore} from "./map/MapRulerInfoStore";
import {ExportStore} from "./export/ExportStore";
import {IndexByPointGraphStore} from "./indeByPoint/IndexByPointGraphStore";
import {IndexByPointerStore} from "./indeByPoint/IndexByPointerStore";
import {IntervalStore} from "./IntervalStore";
import {SearchListSceneStore} from "./SearchListSceneStore";
import {SearchItemGroup} from "./SearchItemGroup";
import {SearchItemStore} from "./SearchItemStore";
import {SearchAddressStore} from "./SearchAddressStore";
import {BoundsStore, MapStore, PointStore} from "./MapStore";
import {SearchObjectStore} from "./SearchObjectStore";
import {ClassFactory} from "./ClassFactory";
import {SearchStore} from "./SearchStore";
import {AgroStore} from "./agro/AgroStore";
import {FieldItemStore} from "./agro/FieldItemStore";
import {FarmItemStore} from "./agro/FarmItemStore";
import {BandStaticStyleProductStore} from "./productSetting/BandStaticStyleProductStore";
import {StaticStyleProductStore} from "./productSetting/StaticStyleProductStore";
import {AdaptiveStyleProductStore} from "./productSetting/AdaptiveStyleProductStore";
import {ProductSettingStore} from "./productSetting/ProductSettingStore";
import {ProductsStore} from "./productSetting/ProductsStore";
import {AgroProjectFormStore} from "./agro/AgroProjectFormStore";

import {
    ClassValue,
    IndexBandProductStore,
    PresetColors,
    PresetColorValue
} from "./productSetting/IndexBandProductStore";
import {OverlayObjectStore} from "./OverlayObjectStore";
import {ActiveSensorDayStore} from "./export/ActiveSensorDayStore";

import {A2NavigatorItemStore} from "./agro/A2NavigatorItemStore";
import {A2FieldStore} from "./agro/A2FieldStore";
import {A2ColumnItemStore} from "./agro/A2ColumnItemStore";

import {PhotoFilterStore} from "./photo/PhotoFilterStore";
import {IndexByPointYearStore} from "./indeByPoint/IndexByPointYearStore";
import {IndexByPointGraphPointStore} from "./indeByPoint/IndexByPointGraphPointStore";
import {ShowRoom2Store} from "./dataShowroom2/ShowRoom2Store";
import { FavoritesListSceneStore } from "./FavoritesListSceneStore";


export function initClassFactory() {

    ClassFactory.registerClass((parent) => {
        return new A2ReferenceStore(parent);
    }, "A2ReferenceStore");
    ClassFactory.registerClass((parent) => {
        return new A2ColumnItemStore(parent);
    }, "A2ColumnItemStore");
    ClassFactory.registerClass((parent) => {
        return new A2FieldStore(parent)
    }, "A2FieldStore");
    ClassFactory.registerClass((parent) => {
        return new A2NavigatorItemStore(parent)
    }, "A2NavigatorItemStore");
    ClassFactory.registerClass((parent) => {
        return new PhotoFilterStore(parent)
    }, "PhotoFilterStore");
    ClassFactory.registerClass((parent) => {
        return new ClassValue(parent)
    }, "ClassValue");


    ClassFactory.registerClass((parent) => {
        return new BandStaticStyleProductStore(parent)
    }, "BandStaticStyleProductStore");
    ClassFactory.registerClass((parent) => {
        return new StaticStyleProductStore(parent)
    }, "StaticStyleProductStore");
    ClassFactory.registerClass((parent) => {
        return new AdaptiveStyleProductStore(parent)
    }, "AdaptiveStyleProductStore");
    ClassFactory.registerClass((parent) => {
        return new ProductSettingStore(parent)
    }, "ProductSettingStore");
    ClassFactory.registerClass((parent) => {
        return new ProductsStore(parent)
    }, "ProductsStore");


    ClassFactory.registerClass((parent) => {
        return new AgroStore(parent)
    }, "AgroStore");
    ClassFactory.registerClass((parent) => {
        return new FarmItemStore(parent)
    }, "FarmItemStore");
    ClassFactory.registerClass((parent) => {
        return new FieldItemStore(parent)
    }, "FieldItemStore");


    ClassFactory.registerClass((parent) => {
        return new AgroProjectFormStore(parent)
    }, "AgroProjectFormStore");
    ClassFactory.registerClass((parent) => {
        return new ActiveSensorDayStore(parent)
    }, "ActiveSensorDayStore");
    ClassFactory.registerClass((parent) => {
        return new ShowRoom2Store(parent)
    }, "ShowRoom2Store");

    ClassFactory.registerClass((parent) => {
        return new BoundsStore(parent)
    }, "BoundsStore");
    ClassFactory.registerClass((parent) => {
        return new SearchObjectStore(parent)
    }, "SearchObjectStore");
    ClassFactory.registerClass((parent) => {
        return new MapStore(parent)
    }, "MapStore");
    ClassFactory.registerClass((parent) => {
        return new PointStore(parent)
    }, "PointStore");
    ClassFactory.registerClass((parent) => {
        return new IndexByPointGraphPointStore(parent)
    }, "IndexByPointGraphPointStore");

    ClassFactory.registerClass((parent) => {
        return new SuperStore(parent)
    }, "SuperStore");
    ClassFactory.registerClass((parent) => {
        return new SearchAddressStore(parent)
    }, "SearchAddressStore");
    ClassFactory.registerClass((parent) => {
        return new SearchStore(parent)
    }, "SearchStore");
    ClassFactory.registerClass((parent) => {
        return new MapRulerInfoStore(parent)
    }, "MapRulerInfoStore");
    ClassFactory.registerClass((parent) => {
        return new ExportStore(parent)
    }, "ExportStore");
    ClassFactory.registerClass((parent) => {
        return new IndexByPointGraphStore(parent as IndexByPointerStore)
    }, "IndexByPointGraphStore");
    ClassFactory.registerClass((parent) => {
        return new IndexByPointYearStore(parent)
    }, "IndexByPointYearStore");

    ClassFactory.registerClass((parent) => {
        return new IndexByPointerStore(parent)
    }, "IndexByPointerStore");
    ClassFactory.registerClass((parent) => {
        return new IntervalStore(parent)
    }, "IntervalStore");
    ClassFactory.registerClass((parent) => {
        return new SearchListSceneStore(parent)
    }, "SearchListSceneStore");
    ClassFactory.registerClass((parent) => {
        return new FavoritesListSceneStore(parent)
    }, "FavoritesListSceneStore");
    ClassFactory.registerClass((parent) => {
        return new SearchItemGroup(parent)
    }, "SearchItemGroup");
    ClassFactory.registerClass((parent) => {
        return new SearchItemStore(parent)
    }, "SearchItemStore");

    ClassFactory.registerClass((parent) => {return new OverlayObjectStore(parent)}, "OverlayObjectStore");
    ClassFactory.registerClass((parent) => {return new PresetColorValue(parent)}, "PresetColorValue");
    ClassFactory.registerClass((parent) => {return new PresetColors(parent)}, "PresetColors");
    ClassFactory.registerClass((parent) => {return new IndexBandProductStore(parent)}, "IndexBandProductStore");
}

