import mapboxgl, {MapMouseEvent} from "maplibre-gl";

import {MBUtils} from "../../../helper/utils/MBUtils";
import {AddrPoint, GeometryUtils} from "../../../helper/utils/GeometryUtils";
import {GeoAddrUtils} from "../../../helper/utils/GeoAddrUtils";
import {DrawPointType} from "../general/ContainerTools";
import {CustomEditGeometryTool} from "./CustomEditGeometryTool";
import {Position} from "geojson";
import {ToolEvent} from "../../../../pluginApi/tools/ToolEvent";
import {GActionTransactionType} from "../../../helper/geometryAction/IGeometryAction";
import {Utils} from "../../../helper/utils/Utils";
import maplibregl from "maplibre-gl";

export class EditDrawTool extends CustomEditGeometryTool {
    down: boolean = false;
    addrPoint: AddrPoint = null;
    pointType: DrawPointType = null;
    minPoints: number = 0; // limits the minimum number of points, in the agrometric plugin = 2

    private isActive(): boolean{
        return this.state?.events?.isEdit && this.state.events.isEdit();
            //|| this.state?.events?.isCreateGeometry && this.state.events.isCreateGeometry();
    }

    onDblclick(e: MapMouseEvent & ToolEvent): void {
        if (this.isActive() && (!this.state.events?.canDeletePointOnEdit || this.state.events.canDeletePointOnEdit())) {
            let oclick = this.getObjectByClick(e.point);
            if (oclick.pointIndex != null && oclick.pointType == DrawPointType.vertex) {
                let gia = this.state.simpleGeometry;

                let addrPoint = oclick.pointIndex;
                let c = GeometryUtils.getContourPoints(gia, GeometryUtils.getAddrContourByAddrPoint(addrPoint));
                if (c.length < this.minPoints + 1) return;
                let t = this.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.deletePoint)
                t.deletePoint(addrPoint);
                if (c != null && c.length == 0){
                    if (addrPoint[1] == 0){
                        t.deleteGeometry(addrPoint[0]);
                    }else{
                        t.deleteHole(GeometryUtils.getAddrContourByAddrPoint(addrPoint));
                    }
                }

                this.state.midPoint = null;
                this.state.resetMovedPoints();
                this.callChange();
                this.updateGeometry();
                e.noPropagation();
                return;
            }
        }
    }

    onMouseDown(e: MapMouseEvent & ToolEvent): boolean {
        if (!!(e.originalEvent.button & 2)) return;
        if (this.isActive()) {
            let oclick = this.getObjectByClick(e.point);
            if (oclick.pointType == DrawPointType.vertex || oclick.pointType == DrawPointType.midpoint) {
                this.addrPoint = oclick.pointIndex;
                this.pointType = oclick.pointType;
                this.realStartMove(e);
                e.noPropagation();
                return;
            }
        }
    }

    onMouseUp(e: MapMouseEvent & ToolEvent): void {
        if (this.isActive()) {
            if (this.addrPoint == null) return;
            if (this.state.movingPoint) {
                let pos = MBUtils.llToPosition(e.lngLat);
                this.setNewValue(pos);
                this.state.movedPrevPointCoord = null;
                this.state.movedNextPointCoord = null;
                this.state.movedPointCoord = null;
                this.updateMovedPoint();
                e.noPropagation();
            }
            this.state.movingPoint = false;
            this.addrPoint = null;
            this.pointType = null;
        }
    }

    realStartMove(e: mapboxgl.MapMouseEvent & ToolEvent){
        this.state.movingPoint = true;

        if (this.addrPoint != null){
            if (this.pointType == DrawPointType.midpoint) {
                let gia = this.state.simpleGeometry;
                let pos1 = GeometryUtils.getGeometryPoint2(gia, this.addrPoint);
                let pos2 = GeometryUtils.getGeometryPoint2(gia, GeometryUtils.incExistPointAddr(gia, this.addrPoint));
                this.state.movedPrevPointCoord = pos1;
                this.state.movedNextPointCoord = pos2;
                this.state.movedPointCoord = MBUtils.llToPosition(e.lngLat);
                this.updateMovedPoint();
            }
            if (this.pointType == DrawPointType.vertex) {
                let gia = this.state.simpleGeometry;

                let pos = GeometryUtils.getGeometryPoint2(gia, this.addrPoint);
                let nextAddr = GeometryUtils.incExistPointAddr(gia, this.addrPoint);
                let pos1: Position = null;
                if (nextAddr != null) pos1 = GeometryUtils.getGeometryPoint2(gia, nextAddr);
                let prevAddr = GeometryUtils.decExistPointAddr(gia, this.addrPoint);
                let pos2: Position = null;
                if (prevAddr != null) pos2 = GeometryUtils.getGeometryPoint2(gia, prevAddr);
                this.state.movedPrevPointCoord = pos1;
                this.state.movedNextPointCoord = pos2;
                this.state.movedPointCoord = pos;
                this.updateMovedPoint();
            }
        }
    }

    onMouseDragStart(e: mapboxgl.MapMouseEvent & ToolEvent) {
        //if (!this.isActive()) return;
        //if (this.state.movingPoint){
        //  e.noPropagation();
        //}
    }

    onMouseDragEnd(e: mapboxgl.MapMouseEvent & ToolEvent) {
        //if (!this.isActive()) return;
    }

    onMouseMove(e: MapMouseEvent & ToolEvent): void {
        if (this.isActive()) {
            let oclick = this.getObjectByClick(e.point);
            if (oclick != null && oclick.pointIndex != null) {
                e.cursor = "pointer";
            }

            if (!this.state.movingPoint) return;
            this.state.movedPointCoord = MBUtils.llToPosition(e.lngLat);
            this.updateMovedPoint();
            e.cursor = "pointer";
            e.noPropagation();
        }
    }

    private setNewValue(pos: Position){
        if (this.addrPoint != null &&
            this.pointType == DrawPointType.midpoint
        ){
            let gia = this.state.simpleGeometry;
            let nextAddr = GeoAddrUtils.incPointAddr2(this.addrPoint);
            this.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.insertPoint).insertPoint(nextAddr, pos);
            this.callChange();
            this.state.midPoint = null;
            this.updateGeometry();
        }
        if (this.addrPoint != null && this.pointType == DrawPointType.vertex){
            let gia = this.state.simpleGeometry;
            this.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.movePoint).movePoint(this.addrPoint, pos);
            //GeometryUtils.setGeometryPoint2(gia, this.addrPoint, pos);
            this.callChange();
            this.updateGeometry();
        }
    }

}
