import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoUploadIndsSelPanelComp.scss'
import {AgroAhoProgressComp} from "./AgroAhoProgressComp";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {AhoPerm} from "../agroAhoProjStore";
import {AhoModalType} from "../agroAhoStore";

@observer
export class AgroAhoUploadIndsSelPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickInNewTour(){
        let store = this.props.store.agroAhoStore;
        let disabled = store.projStore.perm === AhoPerm.View;
        if (!disabled) {
            store.toggleModal(false);
            store.toggleModal(true, AhoModalType.FileLoad);
        }
    }

    onClickInExTour(){

    }

    render() {
        let store = this.props.store;
        let store_ = this.props.store.agroAhoStore;

        return <div className="AgroAhoUploadIndsSelPanelComp-main">
            <div className="AgroAhoUploadIndsSelPanelComp-cover">
                <div className="AgroAhoUploadIndsSelPanelComp-topPanel">
                    <div className="AgroAhoUploadIndsSelPanelComp-title">{store.trans['Loading indicators']}</div>
                    <div className="AgroAhoUploadIndsSelPanelComp-msg">
                        <span>{store_.trans["Survey data is loaded by field, field survey data is organized into rounds. There can be several rounds in the system; for each cell, the data can be located in different rounds. For each tour, you must indicate the name and date of field work."]}</span>
                    </div>
                    <AgroAhoProgressComp store={store}/>
                </div>
                <div className="AgroAhoUploadIndsSelPanelComp-bottomPanel">
                    <div className="AgroAhoUploadIndsSelPanelComp-bottomWrapper">
                        <div className="AgroAhoUploadIndsSelPanelComp-bottomMsg">
                            {store_.trans['Where do you want to upload the indicators']}?
                        </div>
                        <div className="AgroAhoUploadIndsSelPanelComp-buttonsCover">
                            <button className="left-sidebar-active button-jump AgroAhoUploadIndsSelPanelComp-InNewButton button-jump"
                                    onClick={this.onClickInNewTour}>{store_.trans["On to a new tour"]}</button>
                            <button className="left-sidebar-active button-jump AgroAhoUploadIndsSelPanelComp-InExButton button-jump"
                                    onClick={this.onClickInExTour}>{store_.trans["To an existing tour"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
