import * as React from 'react';
import {CustomSvg} from "./CustomSvg";

export class FiltersSvg extends CustomSvg {

    render() {
        return <svg width="31" height="18" viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()}>
       <path d="M10.4546 3.18176H17" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
       <path d="M1 3.18176H3.18182" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
       <path d="M16.2729 9H17.0002" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
       <path d="M1 9H9" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
       <path d="M10.4546 14.8182H17" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
       <path d="M1 14.8182H3.18182" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
       <path d="M5.36346 5.36364C6.56844 5.36364 7.54528 4.3868 7.54528 3.18182C7.54528 1.97683 6.56844 1 5.36346 1C4.15847 1 3.18164 1.97683 3.18164 3.18182C3.18164 4.3868 4.15847 5.36364 5.36346 5.36364Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
       <path d="M11.1818 11.1819C12.3868 11.1819 13.3636 10.205 13.3636 9.00006C13.3636 7.79507 12.3868 6.81824 11.1818 6.81824C9.97683 6.81824 9 7.79507 9 9.00006C9 10.205 9.97683 11.1819 11.1818 11.1819Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
       <path d="M5.36346 17C6.56844 17 7.54528 16.0232 7.54528 14.8182C7.54528 13.6132 6.56844 12.6364 5.36346 12.6364C4.15847 12.6364 3.18164 13.6132 3.18164 14.8182C3.18164 16.0232 4.15847 17 5.36346 17Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
       <g>
           <path d="M30 8.99953C30 8.83653 29.9205 8.68353 29.7865 8.59003L24.7865 5.09003C24.6345 4.98303 24.4345 4.97103 24.269 5.05603C24.1035 5.14253 24 5.31303 24 5.49953V12.5C24 12.6865 24.1035 12.8575 24.269 12.9435C24.4345 13.0285 24.634 13.0165 24.7865 12.9095L29.7865 9.40953C29.9205 9.31653 30 9.16353 30 9.00053C30 9.00003 30 9.00003 30 8.99953C30 9.00003 30 9.00003 30 8.99953Z"/>
       </g>

   </svg>
;
    }
}
