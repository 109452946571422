import {ObservableCustomStore} from "../../app/store/CustomStore";
import {action, computed, observable, reaction} from "mobx";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {AgroAhoStore, AhoStage} from "./agroAhoStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelModeAgroAho} from "./agroAhoPlugin";
import {AhoIndicatorFormat} from "./agroAhoCardStore";
import {AhoUtils} from "./agroAhoUtils";


export enum AhoPerm {
    View = 'view',
    Edit = 'edit',
    Admin = 'admin'
}

interface IPerms {
    [projName: string]: string;
}

export interface Indicator {
    indc_id: number;
    indc_name: string;
    info: any;
    description: string;
    indc_code: string;
    standard: string;
    symbol: string;
    unit: string;
    lower_code?: string;
    visibility: any;
    lab_code?: any;
}

export class AgroAhoProjStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    _projName: string;
    _urlName: string;
    isUrlParsed: boolean = false;
    perms: IPerms = {};
    indicators: Indicator[] = null;
    @observable
    visFormat: AhoIndicatorFormat = AhoIndicatorFormat.Disabled;
    @observable
    asaLabAgreement: string = '';


    @action
    async updVisFormat(){
        let url = `/api/projects/${this.parentStore.projStore.projName}/info`;
        // fetchJsonGet(url).then(json=>{
        //     this.visFormat = json?.project?.proj_settings?.asa_scale_name || AhoIndicatorFormat.AgroSoft;
        // });
        let res: any = await fetchJsonGet(url);
        this.visFormat = res?.project?.proj_settings?.asa_scale_name || AhoIndicatorFormat.AgroSoft;
        this.asaLabAgreement = res?.project?.proj_settings?.asa_lab_agreement;
        // console.log('visFormat:', this.visFormat);
    }

    setVisFormat(){
        let url = `/api/projects/${this.parentStore.projStore.projName}/settings/modify?settings=` +
            `{"asa_scale_name":"${this.visFormat}"}`;
        fetchJsonGet(url).then();
    }

    @computed
    get urlProjName(): string {
        if (!this.isUrlParsed) {
            let urlData = this.parentStore.urlStore.parseUrlCode();
            this._urlName = urlData?.projName;
            this.isUrlParsed = true;
        }
        return this.root.agro.projectName || this._urlName;
    }

    get perm(): string {
        return this.perms[this.projName];
    }

    get projName(): string {
        return this._projName;
    }

    set projName(name) {
        // console.log('set proj name');
        this._projName = name;
    }


    isChanged(){
        // console.log('calc isChanged', this._projName !== this.agroProjName);
        return this._projName !== this.urlProjName;
    }

    async isPerm(){
        let pname = this.urlProjName;
        if (!pname) return false;
        if (this.perms[pname] === undefined) this.perms[pname] = await this.doLoadRights(pname);
        return !!this.perms[pname];
    }

    isViewPermAndNoCards(){
        return this.perm == AhoPerm.View && this.parentStore.cardsStore.cards.length === 0;
    }

    async doLoadRights(pname: string){
        if (!pname) return null;
        let perm: string = null;
        await this.parentStore.cachedFetchJsonGet('/api/projects', true).then(async json=>{
            let proj_info = json.projects.filter((proj: any)=>proj.proj_name==pname);
            if (proj_info && proj_info.length == 1) {
                await this.parentStore.cachedFetchJsonGet(`/api/projects/${pname}/info`, true).then(info=>{
                    perm = info?.project?.perm;
                });
            }
        });
        return perm;
    }

    async doLoadIndicators(){
        let url = `/api/projects/${this.projName}/reference/soil_indicator/list`;
        await fetchJsonGet(url).then(json=>{
            if (!Array.isArray(json)) return;
            this.indicators = json.map((a: any)=>a.values);
            for (let i=0; i<this.indicators.length; i++)
                this.indicators[i].lower_code = this.indicators[i].indc_code.toLowerCase();
        });
    }

}