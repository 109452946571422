import {CustomStore} from "../CustomStore";
import {PhotoTagStore} from "./PhotoTagStore";
import {computed, observable} from "mobx";
import {PhotoTagGroupFilterStore} from "./PhotoTagGroupFilterStore";


export class PhotoTagGroupStore extends CustomStore{
    constructor(parent: PhotoTagGroupFilterStore) {
        super(parent);
    }

    //сколько тегов видно без разворота
    static MAX_TAGS_VIEW = 10;
    @observable
    name: string;
    @observable
    id: number;

    @observable
    tags: PhotoTagStore[] = [];

    @observable
    groupNameFilter: string = '';
    @observable
    expanded: boolean = false;

    get parentGroupFilter(): PhotoTagGroupFilterStore {
        return this.parent as PhotoTagGroupFilterStore;
    }

    @computed
    get tagsWithFilters(): PhotoTagStore[]{
        let r: PhotoTagStore[] = [];
        let filter1 = this.parentGroupFilter.filter;
        r = this.tags.filter(a => (a.tag_name.toLowerCase().includes(filter1.toLowerCase()) && a.tag_name.toLowerCase().includes(this.groupNameFilter.toLowerCase())) || a.checked );
        return r;
    }
}