import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IShowRoom2FilterItem} from "./ShowRoom2FiltersList";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {CheckboxMiniComp, CheckboxSize} from "../../../../Common/CheckboxMiniComp";
import {action} from "mobx";

export interface IShowRoom2FiltersListItem extends IStoreProps{
    item: IShowRoom2FilterItem,
    onCheck: (item: IShowRoom2FilterItem)=>void
    onUncheck: (item: IShowRoom2FilterItem)=>void
}

@observer
export class ShowRoom2FiltersListItem extends React.Component<IShowRoom2FiltersListItem, undefined> {
    constructor(props: IShowRoom2FiltersListItem) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickCheck(){
        let item = this.props.item;
        if (item.checked) this.props.onUncheck(item);
        if (!item.checked) this.props.onCheck(item);
    }

    render() {
        let store = this.props.store;
        let item = this.props.item;
        return <div className="ShowRoom2Filters-row">
            <CheckboxMiniComp state={item.checked} size={CheckboxSize.standart} onClick={this.onClickCheck} />
            <div className="ShowRoom2Filters-itemName"  onClick={this.onClickCheck}>{item.name}</div>
        </div>;
    }
}
