import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {RangeStepComp} from "../../../Common/RangeStepComp";
import {action} from "mobx";
import {Sentinel2Level} from "../../../../helper/utils/satellliteDic";
import {ReactDoubleDatePicker2Comp} from "../../../Common/ReactDoubleDatePicker2Comp";
//import autoBindReact = require("auto-bind/react");
import autoBindReact from "auto-bind/react";
import {ParamSearchSpatialFilterComp} from "./ParamSearchSpatialFilterComp";
import './ParamSearchPanel.scss';
import {DropDownComp, IDropDownItem} from "../../../Common/DropDownComp";
import {SearchSceneSourceType, SearchStore} from "../../../../store/SearchStore";
import {Sentinel5PanelComp} from "./Sentinel5/Sentinel5PanelComp";
import {ParamSearchSentinel1Comp} from "./Sentinel1/ParamSearchSentinel1Comp";

export const MaxSearchSceneInterval = 24*60*60*1000 * 366;

@observer
export class ParamSearchPanel extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    changeCloud(k:any, value: number){
        let store = this.props.store;
        store.searchPanel.filterCloud = value;
    }
    @action
    changeSunlev(k:any, value: number){
        let store = this.props.store;
        store.searchPanel.filterSunelev = value;
    }
    @action
    close(){
        this.props.store.searchPanel.showParamsPanel = false;
    }

    @action
    clickSentinel2L1(){
        this.props.store.searchPanel.filterSentinel2_level = Sentinel2Level.L1;
    }
    @action
    clickSentinel2L2(){
        this.props.store.searchPanel.filterSentinel2_level = Sentinel2Level.L2;
    }

    @action
    clickSatS2(){
        this.props.store.searchPanel.filterSatellites.S2 = !this.props.store.searchPanel.filterSatellites.S2;
    }
    @action
    clickSatL7E(){
        this.props.store.searchPanel.filterSatellites.L7E = !this.props.store.searchPanel.filterSatellites.L7E;
    }
    @action
    clickSatL8(){
        this.props.store.searchPanel.filterSatellites.L8 = !this.props.store.searchPanel.filterSatellites.L8;
    }
    @action
    clickSatL45(){
        this.props.store.searchPanel.filterSatellites.L45 = !this.props.store.searchPanel.filterSatellites.L45;
    }
    @action
    clickSatAll(){
        let v = true;
        if (this.getSatAll()){
            v = false;
        }
        this.props.store.searchPanel.filterSatellites.S2 = v;
        this.props.store.searchPanel.filterSatellites.L8 = v;
        this.props.store.searchPanel.filterSatellites.L45 = v;
        this.props.store.searchPanel.filterSatellites.L7E = v;
    }

    @action
    tilesClickOn(){
        this.props.store.searchPanel.filterTiles = true;
    }
    @action
    tilesClickOff(){
        this.props.store.searchPanel.filterTiles = false;
    }

    getSatAll(): boolean{
        let t = this.props.store.searchPanel.filterSatellites;

        return ((t.S2 as boolean)  && (t.L8 as boolean) && (t.L45 as boolean)&& (t.L7E as boolean)
        );
    }

    @action
    onClickSelectSourceType(item: IDropDownItem, newIndex: number){
        this.props.store.searchPanel.sourceType = item.data;
    }
    @action
    onChangeSeasonFilter(){
        let sp = this.props.store.searchPanel;
        if (SearchStore.canUseFilterSeasonDate(sp.filterDate.begin, sp.filterDate.end)) {
            this.props.store.searchPanel.filterSeasonDate = !this.props.store.searchPanel.filterSeasonDate;
        }
    }

    @action
    onDateFocusExit(){
        let store = this.props.store;
        let sp = store.searchPanel;
        sp.filterDate.begin = sp.formFilterDate.begin;
        sp.filterDate.end = sp.formFilterDate.end;
    }

    render() {
        let store = this.props.store;
        let sp = store.searchPanel;

        let selectSourceType: IDropDownItem[] = [
            {value: store.trans["Sentinel 2 + Landsat"], key: SearchSceneSourceType.passive, data: SearchSceneSourceType.passive},

        ];
        if (store.config.s1_order_active) selectSourceType.push({value: "Sentinel-1", key: SearchSceneSourceType.sentinel1, data: SearchSceneSourceType.sentinel1});
        if (store.config.s5_order_active) selectSourceType.push({value: store.trans["Sentinel-5"], key: SearchSceneSourceType.sentinel5, data: SearchSceneSourceType.sentinel5});

        let shownYears = new Date().getFullYear() - SearchStore.MIN_SEARCH_YEAR;
        
        return <div className="popup-pref active-flex">
            <div className="popup-pref-in">
                <div className="popup-pref-in-closer" onClick={this.close}>
                    <div className="arrow-left"/>
                </div>
                <div className="popup-pref-in__row1">
                    <div className="popup-pref-in__row1_title">{store.trans["Search options"]}</div>
                    <div className="popup-pref-in__row1_left">{store.trans["Data type"]}</div>
                    <DropDownComp items={selectSourceType} onChange={this.onClickSelectSourceType}
                                  currentKey={store.searchPanel.sourceType} disabled={selectSourceType.length <= 1 } className="width100" />
                </div>
                {sp.sourceType == SearchSceneSourceType.sentinel5 && <Sentinel5PanelComp store={store} /> }
                {sp.sourceType == SearchSceneSourceType.sentinel1 && <ParamSearchSentinel1Comp store={store} /> }
                {sp.sourceType == SearchSceneSourceType.passive && <React.Fragment>
                    <div className="popup-pref-in__separator"/>
                    <div className="popup-pref-in__row2">
                        <div className="popup-pref-in__row2_left">
                        {store.trans.Period}
                        </div>
                        <div className="popup-pref-in__row2_right">
                            <ReactDoubleDatePicker2Comp store={store} interval={store.searchPanel.formFilterDate}
                                                        onFocusExit={this.onDateFocusExit} onKeyEnter={this.onDateFocusExit}
                                                        yearDropdownItemNumber={shownYears} minDate={new Date(`${SearchStore.MIN_SEARCH_YEAR}-01-01`)} />
                        </div>
                    </div>
                    <div className="popup-pref-in__rowSeasonSearch">
                        <div>
                            <input type="checkbox" className="switch_1" id="compositImage1" checked={store.searchPanel.filterSeasonDate}
                               disabled={!SearchStore.canUseFilterSeasonDate(sp.filterDate.begin, sp.filterDate.end)}
                               onChange={this.onChangeSeasonFilter}/><label
                            htmlFor="compositImage1" className="css-label-2 ProductItemComp-textLabel-m">Season search</label>
                        </div>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 15.75C5.2875 15.75 2.25 12.7125 2.25 9C2.25 5.2875 5.2875 2.25 9 2.25C12.7125 2.25 15.75 5.2875 15.75 9C15.75 12.7125 12.7125 15.75 9 15.75Z" fill="#4DB6BC"/>
                            <path d="M10.125 7.875H7.875V13.5H10.125V7.875Z" fill="#4DB6BC"/>
                            <path d="M9 6.75C9.62132 6.75 10.125 6.24632 10.125 5.625C10.125 5.00368 9.62132 4.5 9 4.5C8.37868 4.5 7.875 5.00368 7.875 5.625C7.875 6.24632 8.37868 6.75 9 6.75Z" fill="#4DB6BC"/>
                        </svg>

                        </div>

                    <div className="popup-pref-in__row3">
                        <div className="popup-pref-in__row3_left">
                            {store.trans["Max. cloud coverage"]}
                        </div>
                        <RangeStepComp value={store.searchPanel.filterCloud == null? 100 : store.searchPanel.filterCloud} onChange={this.changeCloud} />
                    </div>
                    <div className="popup-pref-in__row4">
                        <div className="popup-pref-in__row4_left">
                            <span >{store.trans["Off nadir angle"]}</span>
                        </div>
                        <RangeStepComp value={90//store.searchPanel.filterSunelev
                            } itemStr="&deg;" onChange={this.changeSunlev} min={0} max={90} disable={true} />
                    </div>
                    <div className="popup-pref-in__separator"/>
                    <div className="popup-pref-in__row6">
                        <div className="popup-pref-in__row6_header">
                            {store.trans["Sensor"]}
                        </div>
                        <div className="popup-pref-in__row6_content style-4">
                            <input type="checkbox" name="checkboxG4" id="checkboxG4" className="css-checkbox-1" checked={this.getSatAll()}
                                   onChange={this.clickSatAll}/><label
                                htmlFor="checkboxG4" className="css-label-1" style={{fontWeight: 600}}>{store.trans["Select all"]}</label>
                            <input type="checkbox" name="checkboxG5" id="checkboxG5" className="css-checkbox-1"
                                    checked={sp.filterSatellites.S2 === true} onChange={this.clickSatS2}/><label
                                htmlFor="checkboxG5" className="css-label-1">Sentinel-2 L2A</label>
                            {(store.config?.ordered_products?.["Landsat-8"] != null || store.config?.ordered_products?.["Landsat-9"] != null) && <React.Fragment>
                                <input type="checkbox" name="checkboxG6" id="checkboxG6" className="css-checkbox-1"
                                    checked={sp.filterSatellites.L8 === true} onChange={this.clickSatL8}/><label
                                htmlFor="checkboxG6" className="css-label-1">Landsat 8-9 (OLI/TIRS)</label></React.Fragment>}
                            {store.config?.ordered_products?.["Landsat-7"] != null && <React.Fragment><input type="checkbox" name="checkboxG7" id="checkboxG7" className="css-checkbox-1"
                                   checked={sp.filterSatellites.L7E === true} onChange={this.clickSatL7E}/><label
                                htmlFor="checkboxG7" className="css-label-1">Landsat-7 ETM+</label></React.Fragment>}
                            {store.config?.ordered_products?.["Landsat-5"] != null && <React.Fragment><input type="checkbox" name="checkboxG8" id="checkboxG8" className="css-checkbox-1"
                                   checked={sp.filterSatellites.L45 === true} onChange={this.clickSatL45}/><label
                                htmlFor="checkboxG8" className="css-label-1">Landsat-5 TM</label></React.Fragment>}
                        </div>
                    </div>
                </React.Fragment>}
            </div>
                {sp.sourceType != SearchSceneSourceType.sentinel5 &&
                    <React.Fragment>
                        <div className="popup-pref-in__separator-only"/>
                        <ParamSearchSpatialFilterComp store={store} />
                    </React.Fragment>
                }
        </div>;
    }
}
