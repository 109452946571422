import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action, runInAction} from "mobx";

import {gps, parse} from "exifr";
import {PhotoFileItemStore} from "../../../../../store/photo/PhotoFileItemStore";
import {ProjType} from "../../../../../store/user/UserStore";

export interface IPhotoPanelChooseFilesComp extends IStoreProps{
    onOpenFiles?: ()=>void
}
@observer
export class PhotoPanelChooseFilesComp extends React.Component<IPhotoPanelChooseFilesComp, undefined> {
    constructor(props: IPhotoPanelChooseFilesComp) {
        super(props);
        autoBindReact(this);
        this.refUpload = React.createRef();
    }

    refUpload: any;

    componentDidMount() {
        this.props.store.photo.uploadImageStore.uploadElement = this.refUpload.current;//.click()
    }

    componentWillUnmount() {
        this.props.store.photo.uploadImageStore.uploadElement = null;
        this.refUpload = null;
    }

    @action
    async onChangeFiles(){
        let el = this.refUpload.current;
        let list = this.props.store.photo.uploadImageStore;

        list.hasError = false;
        list.generalStatusUpload = null;
        let arr: PhotoFileItemStore[] = [];
        for(let i = 0; i < el.files.length; i++){
            let ff: File =  el.files[i];
            let it = new PhotoFileItemStore(list);
            it.name = ff.name;
            it.date = ff.lastModified;
            it.size = ff.size;
            it.content = ff;
            it.selected = true;

            //it.region_id =
            //it.contentStr = await thumbnailUrl(it.content);

            let obj = await parse(it.content);
            let ll = await gps(it.content);
            if (ll != null && !Number.isNaN(ll.latitude) && !Number.isNaN(ll.longitude)) {
                it.lat = ll.latitude;
                it.lon = ll.longitude;
            }
            if (obj != null){
                if (obj.DateTimeOriginal != null && obj.DateTimeOriginal instanceof Date){
                    it.date = (obj.DateTimeOriginal as Date).getTime();
                }else
                if (obj.CreateDate != null && obj.CreateDate instanceof Date){
                    it.date = (obj.CreateDate as Date).getTime();
                }
            }
            arr.push(it);
        }

        runInAction(()=>{
            list.files = arr;
            this.props.store.searchPanel.closeAllLeftPanels();
            this.props.store.photo.uploadImageStore.showChooseFiles = false;
            this.props.store.photo.uploadImageStore.showPanel = true;
            if (this.props.onOpenFiles) this.props.onOpenFiles();
        });
    }

    render() {
        return <input type="file" id="upload" multiple ref={this.refUpload}
                                  accept=".tiff,*.tif,.jpg,.jpeg,.png" onChange={this.onChangeFiles}
                                  style={{height: 0, width: 0, zIndex: -1}} />;
    }
}
