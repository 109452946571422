// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoStmtIndcItem-main {
  display: flex;
  padding: 8px 0 8px 24px;
  font-weight: 400;
  font-size: 12px;
  color: #C5C5C5;
  user-select: none;
}

.AgroAhoStmtIndcItem-main:hover {
  background: #2E343D;
}

.AgroAhoStmtIndcItem-name {
  width: 472px;
}

.AgroAhoStmtIndcItem-unit {
  width: 170px;
}

.AgroAhoStmtIndcItem-standart {
  width: 280px;
}

.AgroAhoStmtIndcItem-mainCheckbox {
  margin-right: 230px;
  cursor: pointer;
}

.AgroAhoStmtIndcItem-extCheckbox {
  cursor: pointer;
}

.AgroAhoStmtIndcItem-disabled {
  cursor: default;
  opacity: 0.3;
}

.AgroAhoStmtIndcItem-hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/stmt/AgroAhoStmtIndcItem.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,YAAA;AAKF;;AAHA;EACE,mBAAA;EACA,eAAA;AAMF;;AAJA;EACE,eAAA;AAOF;;AALA;EACE,eAAA;EACA,YAAA;AAQF;;AANA;EACE,aAAA;AASF","sourcesContent":[".AgroAhoStmtIndcItem-main {\r\n  display: flex;\r\n  padding: 8px 0 8px 24px;\r\n  font-weight: 400;\r\n  font-size: 12px;\r\n  color: #C5C5C5;\r\n  user-select: none;\r\n}\r\n.AgroAhoStmtIndcItem-main:hover {\r\n  background: #2E343D;\r\n}\r\n.AgroAhoStmtIndcItem-name {\r\n  width: 472px;\r\n}\r\n.AgroAhoStmtIndcItem-unit {\r\n  width: 170px;\r\n}\r\n.AgroAhoStmtIndcItem-standart {\r\n  width: 280px;\r\n}\r\n.AgroAhoStmtIndcItem-mainCheckbox {\r\n  margin-right: 230px;\r\n  cursor: pointer;\r\n}\r\n.AgroAhoStmtIndcItem-extCheckbox {\r\n  cursor: pointer;\r\n}\r\n.AgroAhoStmtIndcItem-disabled {\r\n  cursor: default;\r\n  opacity: .3;\r\n}\r\n.AgroAhoStmtIndcItem-hidden {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
