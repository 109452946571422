import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";



export interface IAgroAhoEye2SvgProps {
    className?: string
}

export class AgroAhoEye2Svg extends React.PureComponent<IAgroAhoEye2SvgProps, undefined> {
    constructor(props: IAgroAhoEye2SvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)}
            width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.0001 11.25C10.1501 11.25 12.6001 8.5375 13.6501 6.9625C14.0876 6.35 14.0876 5.5625 13.6501 4.95C12.6001 3.4625 10.1501 0.75 7.0001 0.75C3.8501 0.75 1.4001 3.4625 0.350098 5.0375C-0.0874024 5.65 -0.0874024 6.4375 0.350098 6.9625C1.4001 8.5375 3.8501 11.25 7.0001 11.25ZM7.0001 3.375C8.4876 3.375 9.6251 4.5125 9.6251 6C9.6251 7.4875 8.4876 8.625 7.0001 8.625C5.5126 8.625 4.3751 7.4875 4.3751 6C4.3751 4.5125 5.5126 3.375 7.0001 3.375Z" fill="#6D7785"/>
        </svg>;
    }
}