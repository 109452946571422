import * as React from 'react';
import {ContextMenuCommonComp} from "./ContextMenuCommonComp";
import autoBindReact from "auto-bind/react";
import './DropDownNumberSlider.scss';
import classNames from "classnames";
import {action, observable} from "mobx";
import {observer} from "mobx-react";
import {isFunction} from "lodash-es";
import {Utils} from "../../helper/utils/Utils";
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';

export interface IDropDownNumberSliderProps {
    value: number;
    disabled?: boolean;
    onChange:(value: number)=>any;
    className?: string;
    classNameButton?: string;
    classNameInput?: string;
    placeHolder?: string;
    min?: number;
    max?: number;
}

@observer
export class DropDownNumberSlider extends React.PureComponent<IDropDownNumberSliderProps, undefined> {
    constructor(props: IDropDownNumberSliderProps) {
        super(props);
        autoBindReact(this);
        this.text = this.props.value.toString();
    }
    @observable
    text: string = "";
    @observable
    down: boolean = false;

    disabled(): boolean{
        return !!this.props.disabled;
    }

    @action
    onClick(){
        if (this.disabled()) return ;
        this.down = !this.down;
    }
    @action
    onChangeText(e: any){
        if (!!this.props.disabled) return;
        let txt = e.currentTarget.value;
        let v = Utils.parseNumber(txt);
        if (v != null) {
            if (isFunction(this.props.onChange)) this.props.onChange(v);
        }
    }
    OnChangeClider(value: number){
        if (!!this.props.disabled) return;
        if (isFunction(this.props.onChange)) this.props.onChange(value);
    }
    onKeyDown(e: any) {}
    onKeyUp(e: any) {
        if  (e.keyCode == 13 || e.keyCode == 27 || e.keyCode == 40 || e.keyCode == 38) {//esc
            e.stopPropagation();
            e.preventDefault();
        }
    }
    @action
    onClickButton(){
        this.down = !this.down;
    }
    @action
    onClose(){
        this.down = false;
    }
    @action
    componentDidUpdate(prevProps: Readonly<IDropDownNumberSliderProps>, prevState: Readonly<undefined>, snapshot?: any) {
        if (this.props.value != prevProps.value){
            this.text = this.props.value.toString();
        }
    }

    render() {
        return <div className={classNames("DropDownNumberSlider-div", this.props.className)}>
            <input type="text" readOnly={!!this.props.disabled}
                   className={classNames("DropDownNumberSlider-input", this.props.classNameInput)}
                   onClick={this.onClick}
                   onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp} value={this.text??""}
                   onChange={this.onChangeText}
                   placeholder={this.props.placeHolder??""} />
            <div className={classNames("DropDownNumberSlider-button", this.props.classNameButton)} onClick={this.onClickButton}>
                <svg width='12' height='8' viewBox='0 0 12 8' xmlns='http://www.w3.org/2000/svg'><path d='M5.99961 7.4001L0.599609 2.0001L1.99961 0.600098L5.99961 4.6001L9.99961 0.600098L11.3996 2.0001L5.99961 7.4001Z' /></svg>
            </div>
            {this.down && <ContextMenuCommonComp onClose={this.onClose} className="DropDownNumberSlider-downbox">
                <Slider defaultValue={this.props.value} className="DropDownNumberSlider-slider"
                    onChange={this.OnChangeClider} min={this.props.min} max={this.props.max} value={this.props.value}  />
            </ContextMenuCommonComp>}
        </div>;
    }
}
