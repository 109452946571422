import React, { Fragment } from "react";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import { action } from "mobx";
import { LayersListComp } from "./LayersListComp";
import { EyeSvg } from "../../../icons/MiniIcon/EyeSvg";
import { ContextMenuCommonComp, PopupCorner, PopupDirection } from "../../../Common/ContextMenuCommonComp";
import { ColorPicker } from "./ColorPicker";
import { observer } from "mobx-react";
import { CircleStyleEditorComp } from "./CircleStyleEditorComp";
import { EStyleType, LayersListStore, OverlaySimpleStyle } from "./LayersListStore";
import { LineStyleEditorComp } from "./LineStyleEditorComp";
import { AddOptionSvg } from "../../../icons/MiniIcon/AddOptionSvg";
import { llParser } from "../../../../helper/utils/llParser";
import { RemoveOptionSvg } from "../../../icons/MiniIcon/RemoveOptionSvg";
import { Visibility } from "maplibre-gl";

@observer
export class LayerStyleComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);        
    }

    @action
    onToggleFillBorder(st: EStyleType) {
        let lls = this.props.store.layerListStore;
        let vis : Visibility = lls.stylePaintProperties.borderVisibility == 'visible'? 'none' : 'visible';
        if (st == EStyleType.CIRCLE && vis == 'visible')
            lls.stylePaintProperties.borderOpacity = 1;
        lls.stylePaintProperties = {...lls.stylePaintProperties, borderVisibility: vis}
        this.props.store.layerListStore.showBorderOption = vis == 'visible';
    }

    render() {
        let lls = this.props.store.layerListStore; 
        let tp = LayersListStore.getOverlaySimpleStyle(lls.activeOverlay);
        return <div className="LayerStyleComp-container">
            {tp.type == EStyleType.CIRCLE && <Fragment>
                <div className="LayerStyleComp-fill-header">Заливка</div>
                <CircleStyleEditorComp store={this.props.store} showRadius={true}/>
                <div className="LayerStyleComp-fill-option-border"/>
                <div className="LayerStyleComp-fill-option-header">
                    <span>Обводка</span>
                    <div className="LayerStyleComp-fill-option-svg" onClick={() => this.onToggleFillBorder(tp.type)}>
                        {!lls.showBorderOption &&
                        <AddOptionSvg className="LayerStyleComp-fill-option-add"/>}
                        {lls.showBorderOption &&
                        <RemoveOptionSvg className="LayerStyleComp-fill-option-add"/>}
                    </div>
                </div>
                {lls.showBorderOption && <LineStyleEditorComp store={this.props.store} showLineStyle={false}/>}
            </Fragment>}
            {tp.type == EStyleType.LINE && <Fragment>
                <div className="LayerStyleComp-fill-header">Обводка</div>
                <LineStyleEditorComp store={this.props.store} showLineStyle={true}/>
            </Fragment>}
            {tp.type == EStyleType.FILL && <Fragment>
                <div className="LayerStyleComp-fill-header">Заливка</div>
                <CircleStyleEditorComp store={this.props.store} showRadius={false}/>
                <div className="LayerStyleComp-fill-option-border"/>
                <div className="LayerStyleComp-fill-option-header">
                    <span>Обводка</span>
                    <div className="LayerStyleComp-fill-option-svg" onClick={() => this.onToggleFillBorder(tp.type)}>
                        {!lls.showBorderOption &&
                        <AddOptionSvg className="LayerStyleComp-fill-option-add"/>}
                        {lls.showBorderOption &&
                        <RemoveOptionSvg className="LayerStyleComp-fill-option-add"/>}
                    </div>
                </div>
                {lls.showBorderOption && <LineStyleEditorComp store={this.props.store} showLineStyle={true}/>}
            </Fragment>}
        </div>;
    }
}