import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {
    ContainerToolsState,
    CreateGeometryType,
    IGeometryEditorEvents,
    IObjectByClick
} from "../tools/general/ContainerTools";
import {ViewGeometryLayersTool} from "../tools/EditGeometry/ViewGeometryLayersTool";
import {MeasuringStatus} from "../../helper/structs/MeasuringStatus";
import {IReactionDisposer} from "mobx/lib/internal";
import {action, autorun} from "mobx";
import {MapMouseEvent} from "maplibre-gl";
import {ToolEvent} from "../../../pluginApi/tools/ToolEvent";
import {GActionTransactionType} from "../../helper/geometryAction/IGeometryAction";
import polygonSplitter from "polygon-splitter";
import union from "@turf/union";
import {GeometryActionTransaction} from "../../helper/geometryAction/ActionManager";
import {FeatureCollection, Geometry} from "geojson";
import {GeometryUtils, ISimpleGeometry, SimpleGeometryType} from "../../helper/utils/GeometryUtils";
import { LeftPanelMode } from "../SearchStore";

export class SearchGeomManager extends ObservableCustomStore implements IGeometryEditorEvents{
    constructor(parent: CustomStore) {
        super(parent);
        this.state.events = this;
    }
    //public externalState: ContainerToolsState;

    public state: ContainerToolsState = new ContainerToolsState();

    public viewEditorGeometry: ViewGeometryLayersTool;
    subscription(): IReactionDisposer[] {
        return [autorun(()=>{
            if (this.root.map.measuringStatus != MeasuringStatus.Polygon &&
                this.root.map.measuringStatus != MeasuringStatus.Polyline &&
                this.root.map.measuringStatus != MeasuringStatus.Rectangle){
                if (this.state.simpleGeometry.length > 0) {
                    this.state.simpleGeometry = [];
                    this.state.events.updateGeometry();
                    this.state.resetMovedPoints();
                    this.state.events.updateMovedPoint();
                }
            }
        })];
    }

    getObjectByClick(point: maplibregl.Point): IObjectByClick {
        return this.viewEditorGeometry.getObjectByClick(point);
    }
    public updateMovedPoint() {
        this.viewEditorGeometry.updateMovedPoint();
    }

    public updateGeometry(){
        this.viewEditorGeometry.updateGeometry();
    }

    onClickFirstPointPolygon(e : MapMouseEvent & ToolEvent): boolean{
        return this.onClickRightButton(e);
    }
    onClickRightButton(e : MapMouseEvent & ToolEvent): boolean{
        this.state.resetMovedPoints();
        this.Finished();
        e.noPropagation();
        return false;
    }
    onChangeGeometry() {
        //this._changes++;
    }


    @action
    public Finished(){
        let g = GeometryUtils.createGeometryBySimpleGeometry(this.state.simpleGeometry);
        let fc: FeatureCollection = {type: "FeatureCollection", features: [{type: "Feature", properties: {}, geometry: g}]};
        this.root.map.searchObject.content = fc;
        this.root.map.searchObject.name = "";
        this.state.simpleGeometry = [];
        this.root.map.resetMeasuringStatus();
        this.root.map.searchObject.visible = true;
        this.root.searchPanel.showParamsPanel = this.root.searchPanel.leftPanelMode == LeftPanelMode.search;
        this.viewEditorGeometry.updateGeometry();
        this.viewEditorGeometry.updateMovedPoint();
    }
    isCreateGeometry(): boolean{
        return (this.root.map.measuringStatus == MeasuringStatus.Polygon ||
            this.root.map.measuringStatus == MeasuringStatus.Rectangle ||
            this.root.map.measuringStatus == MeasuringStatus.Polyline);
    }
    isEdit(): boolean{
        return false;
    }
    isDeleteContour(): boolean{ return false;}


}