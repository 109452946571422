// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sentinel5Panel-panel-div {
  width: 100%;
}

.Sentinel5Panel-marginTop15 {
  margin-top: 15px;
}

.Sentinel5Panel-monthValue {
  display: flex;
  width: 171px;
}

div.Sentinel5Panel-datePeriod {
  width: 100%;
}

div.Sentinel5Panel-Interval {
  width: 90px;
}

.Sentinel5Panel-IntervalSwitcher {
  width: 100%;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/ParamSearchPanel/Sentinel5/Sentinel5PanelComp.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,aAAA;EACA,YAAA;AAGF;;AADA;EACE,WAAA;AAIF;;AAFA;EACE,WAAA;AAKF;;AAHA;EACE,WAAA;EACA,eAAA;AAMF","sourcesContent":[".Sentinel5Panel-panel-div{\n  width: 100%;\n}\n.Sentinel5Panel-marginTop15{\n  margin-top: 15px;\n}\n.Sentinel5Panel-monthValue{\n  display: flex;\n  width: 171px;\n}\ndiv.Sentinel5Panel-datePeriod {\n  width: 100%;\n}\ndiv.Sentinel5Panel-Interval {\n  width: 90px;\n}\n.Sentinel5Panel-IntervalSwitcher{\n  width: 100%;\n  font-size: 13px;\n}\n.Sentinel5Panel-dateIntervalComponent{\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
