import React, { useState } from "react";
import { A2ExportFormat } from "../../../../store/agro/A2AgroStore";
import { A2ExportFormatComp } from "../../Left/Agro2/A2Common/A2ExportFormatComp";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";
import { TranslateUtils } from "../../../../helper/lang/TranslateUtils";
import { fetchBlobPost, fetchBlobPostWithJsonParams, fetchBytesPost, fetchJsonPost, fetchJsonPostWithJsonParams } from "../../../../helper/utils/FetchUtils";
import { Utils } from "../../../../helper/utils/Utils";
import { Point } from "geojson";

interface ISurveyPointsExportCompProps extends IStoreProps {

}

const SurveyPointsExportComp = (props: ISurveyPointsExportCompProps) => {
    let st = props.store;
    const [filename, setFilename] = useState(st.trans["Inspection points"]);
    const [format, setFormat] = useState(A2ExportFormat.kml);
    const [addFields, setAddFields] = useState(false);

    const onExport = async () => {
        try {
            let url = `/api/survey_points/export`;
            let pts: any[] = [];
            st.map.surveyPoints.points.features.forEach(f => {
                let g: Point = {...f.geometry} as Point;
                g.coordinates = g.coordinates.map(x => Utils.toFixedNum(x, 6));
                pts.push({"properties": {"point_id": f.properties.num, "description": f.properties.comment || ""}, "geometry": g});                
            });
            let res = await fetchBlobPost(url, {params:{
                "file_name": filename,
                "format": format,
                "survey_points": pts,
                //"fields": {"proj_name": "proj250", "season_id": 17}
            }});
            Utils.downloadBlob(res.blob, filename + '.' + ((format == A2ExportFormat.shp)? 'zip': format));
            props.store.map.surveyPoints.showExport = false;
        }catch (e) {
            st.addError(e);
        }
    }

    const onCancel = () => {
        props.store.map.surveyPoints.showExport = false;
    }

    const onSelectFormat = (fmt: A2ExportFormat) => {
        setFormat(fmt);
    }

    // let ext = <div className="SurveyPointsExportComp-settings">
    //     <div className="popup-exp-visible popup-exp-crop" style={{marginTop: "5px"}}>
    //         <input type="checkbox" className="switch_1" id="cut"
    //                 checked={true} disabled={!true}
    //                 onChange={this.onClick_apply_cutline}/><label
    //         htmlFor="cut"
    //         className="css-label-2">{st.trans["Add field contours for the season:"]}</label>
    //     </div>

    // </div>;

    return <A2ExportFormatComp store={props.store}
                                formats={[A2ExportFormat.kml, A2ExportFormat.geojson, A2ExportFormat.csv, A2ExportFormat.shp]}
                                defaultFormat={A2ExportFormat.kml} title={st.trans["Export inspection points"]}
                                extChilds={[]} footerClass="SurveyPointsExportComp" buttonClass="SurveyPointsExportComp"
                                onSubmit={onExport} onCancel={onCancel} onSelectFormat={onSelectFormat}>
        <div className="gray-text">{TranslateUtils.format(st.trans["Points: {0}"], st.map.surveyPoints.points.features.length)}</div>
        <div className="Forms_filename">
            <div className="Forms_filenameText">{st.trans["File name"]}</div>
            <input type="text" className="text-box-editor width100" onChange={e => setFilename(e.target.value)}
                    value={filename}/>
        </div>
        <div className="Forms-header2Text Forms-topIdent2">{st.trans.Format}</div>
    </A2ExportFormatComp>
}

export default SurveyPointsExportComp;