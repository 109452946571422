// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ParamSearchSentinel1Comp-modeGrayText {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  margin-left: 10px;
  color: #6A7886;
}

.ParamSearchSentinel1Comp-radioButton {
  margin: 15px 0;
}

.ParamSearchSentinel1Comp-radioButtonText {
  margin-left: 12px;
}

.ParamSearchSentinel1Comp-relativeOrbitGrey {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: #6A7886;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.ParamSearchSentinel1Comp-relativeOrbitGreyText {
  width: 240px;
}

.ParamSearchSentinel1Comp-bottomLabel {
  color: #6A7886;
  max-width: 200px;
  margin: auto;
  line-height: 1.4;
}

.ParamSearchSentinel1Comp-modeSeparate {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/ParamSearchPanel/Sentinel1/ParamSearchSentinel1Comp.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,iBAAA;AAGJ;;AADA;EACI,aAAA;EACA,2BAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAIJ;;AAFA;EACI,YAAA;AAKJ;;AAHA;EACI,cAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AAMJ;;AAJA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAOJ","sourcesContent":[".ParamSearchSentinel1Comp-modeGrayText{\n    font-weight: 400;\n    font-size: 11px;\n    line-height: 15px;\n    margin-left: 10px;\n    color: #6A7886;\n}\n.ParamSearchSentinel1Comp-radioButton{\n    margin: 15px 0;\n}\n.ParamSearchSentinel1Comp-radioButtonText{\n    margin-left: 12px;\n}\n.ParamSearchSentinel1Comp-relativeOrbitGrey{\n    display: flex;\n    justify-content: flex-start;\n    width: 100%;\n    color: #6A7886;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n}\n.ParamSearchSentinel1Comp-relativeOrbitGreyText{\n    width: 240px;\n}\n.ParamSearchSentinel1Comp-bottomLabel{\n    color: #6A7886;\n    max-width: 200px;\n    margin: auto;\n    line-height: 1.4;\n}\n.ParamSearchSentinel1Comp-modeSeparate{\n    display: flex;\n    align-items: center;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 22px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
