// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPanel_FilterBadgetComp {
  background: #3E4751;
  border: 1px solid #3E4751;
  border-radius: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  padding: 2px 10px;
  position: relative;
  margin-left: 10px;
}

.AdminPanel_FilterBadgetComp_close {
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  position: absolute;
  right: -4px;
  top: -7px;
  background: #3E4751;
  border: 2px solid #2B3138;
  border-radius: 10px;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  justify-content: center;
  align-items: center;
  color: #2B3138;
  display: none;
  cursor: pointer;
}

.AdminPanel_FilterBadgetComp:hover > .AdminPanel_FilterBadgetComp_close, .AdminPanel_FilterBadgetComp_close:hover {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/User/AdminPanel/AdminPanel_FilterBadgetComp.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AACA;EACE,sBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;AAEF;;AAAA;EACE,aAAA;AAGF","sourcesContent":[".AdminPanel_FilterBadgetComp{\r\n  background: #3E4751;\r\n  border: 1px solid #3E4751;\r\n  border-radius: 10px;\r\n  font-weight: 400;\r\n  font-size: 10px;\r\n  line-height: 22px;\r\n  padding: 2px 10px;\r\n  position: relative;\r\n  margin-left: 10px;\r\n}\r\n.AdminPanel_FilterBadgetComp_close{\r\n  box-sizing: border-box;\r\n  width: 17px;\r\n  height: 17px;\r\n  position: absolute;\r\n  right: -4px;\r\n  top: -7px;\r\n  background: #3E4751;\r\n  border: 2px solid #2B3138;\r\n  border-radius: 10px;\r\n  font-weight: 700;\r\n  font-size: 10px;\r\n  line-height: 10px;\r\n  justify-content: center;\r\n  align-items: center;\r\n  color: #2B3138;\r\n  display: none;\r\n  cursor: pointer;\r\n}\r\n.AdminPanel_FilterBadgetComp:hover > .AdminPanel_FilterBadgetComp_close, .AdminPanel_FilterBadgetComp_close:hover{\r\n  display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
