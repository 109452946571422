import * as React from 'react';
import {IIconProp} from "../IIconProp";

export class MinSvg extends React.PureComponent<IIconProp, undefined> {

    render() {
        return <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99998 8V7H6.29298L3.64648 4.3535L4.35348 3.6465L6.99998 6.293V4H7.99998L7.99998 7.5C7.99998 7.776 7.77598 8 7.49998 8H3.99998Z" fill="#C5C5C5"/>
            <path d="M8 0L0.5 0C0.224 0 0 0.224 0 0.5L0 8H1L1 1L8 1L8 0Z" fill="#C5C5C5"/>
        </svg>;
    }
}
