import React, {
    useState,
    useRef,
    useEffect,
    useImperativeHandle,
    Fragment,
    ReactNode,
    forwardRef
} from "react";
import Tree from "rc-tree";
import {DataNode, EventDataNode, Key} from "rc-tree/lib/interface";
import "./DraggableOverlaysComp.scss";
//import "rc-tree/assets/index.css"
import {
    IOverlayConfig,
    IOverlayGroup
} from "../../../../store/config/ConfigStore";
import classNames from "classnames";
import {
    CheckboxMiniComp,
    TriState,
    CheckboxSize
} from "../../../Common/CheckboxMiniComp";
import {
    ContextMenuCommonComp,
    PopupDirection,
    PopupCorner
} from "../../../Common/ContextMenuCommonComp";
import {LayerStyleComp} from "./LayerStyleComp";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {NodeDragEventParams} from "rc-tree/lib/contextTypes";
import {AllowDropOptions} from "rc-tree/lib/Tree";
import {observer} from "mobx-react";
import {TemporalGroupComp} from "./TemporalGroupComp";
import cloneDeep from "lodash-es/cloneDeep";
import deepEqual from "lodash-es/isEqual";
import {OverlayObjectStore} from "../../../../store/OverlayObjectStore";
import {OverlayTool} from "../../../../store/tools/class/OverlayTool";

interface ILayersListComp extends IStoreProps {
    showPanel: boolean;
    onStyleWindowClose: () => void;
    onTemporalGroupClick: (e: any) => void;
    onTemporalGroupWindowClose: () => void;
    onStyleClick: (e: any) => void;
    createStyleSvg: (lr: IOverlayConfig) => ReactNode;
    onOverlayChange: (e: any) => void;
    parOverlays: any[];
}
export interface DraggableOverlaysCompRef {
    addNewParentNode: () => void;

    isEqual: boolean;
}
const DraggableOverlaysComp = observer(
    forwardRef<DraggableOverlaysCompRef, ILayersListComp>(
        (props, ref) => {
            const inputRef = useRef<HTMLInputElement>(null);
            const [isEditing, setIsEditing] = useState(false);
            const [isEqual, setIsEqual] = useState(true);
            const [editingNodeKey, setEditingNodeKey] = useState<
                string | null
            >(null);
            const [editingNodeTitle, setEditingNodeTitle] =
                useState("");
            const [editingNode, setEditingNode] =
                useState<IOverlayGroup | null>(null);
            const [treeData, setTreeData] = useState<DataNode[]>([]);
            const [expandedKeys, setExpandedKeys] = useState<Key[]>(
                []
            );
            const [isAddingParent, setIsAddingParent] =
                useState(false);
            const [newParentTitle, setNewParentTitle] = useState("");
            const [uniqueKeyCounter, setUniqueKeyCounter] =
                useState(0);
            const [
                initialTreeDataCounter,
                setInitialTreeDataCounter
            ] = useState(0);

            const isMounted = useRef(true);
            const frozenExpandedKeysRef = useRef<Key[] | null>(null);
            let initialTreeDataRef = useRef(
                cloneDeep(props.store.config.map_layers.overlays)
            );

            useEffect(() => {
                isMounted.current = true;
                return () => {
                    isMounted.current = false;
                };
            }, []);
            const handleConfirmReset = () => {
                props.store.map.superTools.overlayTool.unloadOverlays();
                props.store.config.map_layers.overlays =
                    initialTreeDataRef.current;
                props.store.map.superTools.overlayTool.setupLayers();
            };
            const handleConfirmSave = () => {
                const proj_layers =
                    props.store.config.map_layers.overlays;
                if (!props.store.layerListStore.isVisibleByDefault) {
                    function updateVisibility(
                        projLayers: (
                            | IOverlayConfig
                            | IOverlayGroup
                        )[],
                        initialTreeData: (
                            | IOverlayConfig
                            | IOverlayGroup
                        )[]
                    ) {
                        const initialMap = initialTreeData.reduce(
                            (acc: any, layer) => {
                                acc[layer.id] = layer;
                                return acc;
                            },
                            {}
                        );
                        function updateLayerVisibility(
                            layers: (IOverlayConfig | IOverlayGroup)[]
                        ) {
                            layers.forEach((layer: any) => {
                                if (layer.id in initialMap) {
                                    layer.isVisible =
                                        initialMap[
                                            layer.id
                                        ].isVisible;
                                }
                                if (
                                    layer.layers &&
                                    Array.isArray(layer.layers)
                                ) {
                                    updateLayerVisibility(
                                        layer.layers
                                    );
                                }
                                if (
                                    layer.overlays &&
                                    Array.isArray(layer.overlays)
                                ) {
                                    updateLayerVisibility(
                                        layer.overlays
                                    );
                                }
                            });
                        }
                        updateLayerVisibility(projLayers);
                    }
                    updateVisibility(
                        proj_layers,
                        initialTreeDataRef.current
                    );
                }
                function replaceURLs(obj: any) {
                    for (let key in obj) {
                        if (typeof obj[key] === "object") {
                            replaceURLs(obj[key]);
                        } else if (typeof obj[key] === "string") {
                            obj[key] = obj[key].replace(
                                "http://localhost:8080/",
                                "https://dev-class.ctrl2go.com/"
                            );
                        }
                    }
                }
                replaceURLs(proj_layers);
                const fd = new FormData();
                const proj_name =
                    props.store.root.agro2.projectInfo.projectInfoJson
                        .proj_name;
                fd.append("proj_layers", JSON.stringify(proj_layers));
                fetch(`/api/projects/${proj_name}/proj_layers/save`, {
                    method: "POST",

                    body: fd
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(
                                `HTTP error! status: ${response.status}`
                            );
                        }
                        return response.json();
                    })
                    .then(data => {
                        props.store.addInfo(
                            "Панель слоев успешно изменена"
                        );
                    })
                    .catch(error => {
                        console.error("Error during fetch:", error);
                    });

                if (props.store.layerListStore.isStylesToChange) {
                    props.store.config.map_layers.overlays =
                        proj_layers;
                } else {
                    const newLayers = reorderOverlays(
                        treeData,
                        initialTreeDataRef.current
                    );

                    props.store.config.map_layers.overlays =
                        newLayers;
                }
                initialTreeDataRef.current = cloneDeep(proj_layers);
                setInitialTreeDataCounter(+1);
                props.store.config.map_layers.overlays = proj_layers;
                props.store.layerListStore.isVisibleByDefault = false;
                props.store.map.superTools.overlayTool.setupLayers();
            };

            const haveLayersChanged = (
                currentOverlays: (IOverlayConfig | IOverlayGroup)[],
                originalOverlays: (IOverlayConfig | IOverlayGroup)[]
            ) => {
                const findOverlayById = (
                    id: string,
                    overlays: (IOverlayConfig | IOverlayGroup)[]
                ) => {
                    for (const overlay of overlays) {
                        if (overlay.id === id) {
                            return overlay;
                        }
                        if ("overlays" in overlay) {
                            const found: any = findOverlayById(
                                id,
                                overlay.overlays
                            );
                            if (found) {
                                return found;
                            }
                        }
                    }
                    return undefined;
                };
                for (const currentOverlay of currentOverlays) {
                    const originalOverlay = findOverlayById(
                        currentOverlay.id,
                        originalOverlays
                    );
                    if (
                        originalOverlay &&
                        "layers" in currentOverlay &&
                        "layers" in originalOverlay
                    ) {
                        if (
                            !deepEqual(
                                currentOverlay.layers,
                                originalOverlay.layers
                            )
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            };

            useEffect(() => {
                let isActive = deepEqual(
                    initialTreeDataRef.current,
                    props.store.config.map_layers.overlays
                );
                props.store.layerListStore.isButtonActive = isActive;
            }, [
                treeData,
                initialTreeDataCounter,
                props.store.config.map_layers.overlays,
                props.store.layerListStore.isButtonActive
            ]);

            useEffect(() => {
                const treeData = createTreeData();
                const initialExpandedKeys = ["sentinel_layers"];
                setTreeData(treeData);
                setExpandedKeys(initialExpandedKeys);
            }, []);
            useEffect(() => {
                props.store.layerListStore.setHandleConfirmSaveFunction(
                    handleConfirmSave
                );
                props.store.layerListStore.setHandleConfirmCancelFunction(
                    handleConfirmReset
                );
                props.store.layerListStore.setHandleDeleteNodeFunction(
                    nodeDelete
                );
                return () => {
                    props.store.layerListStore.setHandleConfirmSaveFunction(
                        null
                    );
                    props.store.layerListStore.setHandleConfirmCancelFunction(
                        null
                    );
                    props.store.layerListStore.setHandleDeleteNodeFunction(
                        null
                    );
                };
            }, [treeData]);
            useEffect(() => {
                if (!editingNodeKey && !isEditing) {
                    return;
                }
                if (!editingNode) {
                    setEditingNodeTitle("");
                }
                let _treeData = treeData.map(node => {
                    let _overlay =
                        props.store.config.map_layers.overlays.filter(
                            (
                                overlay:
                                    | IOverlayGroup
                                    | IOverlayConfig
                            ) => {
                                return overlay.id === node.key;
                            }
                        )[0];
                    if ("overlays" in _overlay && _overlay.overlays) {
                        if (node.key === editingNodeKey) {
                            return {
                                key: String(editingNode.id),
                                title: (
                                    <div className='DraggableOverlaysComp-parent-node'>
                                        <input
                                            className='DraggableOverlaysComp-new-parent-input'
                                            style={{
                                                margin: 0,
                                                width: "100%",
                                                fontSize: "12px"
                                            }}
                                            ref={inputRef}
                                            type='text'
                                            defaultValue={
                                                editingNode.title
                                            }
                                            onChange={
                                                handleRenameInputChange
                                            }
                                            onBlur={
                                                handleRenameInputBlur
                                            }
                                            onKeyDown={
                                                handleRenameInputKeyDown
                                            }
                                            autoFocus
                                        />
                                    </div>
                                ),
                                isLeaf: false,
                                children:
                                    createTreeDataFromGroup(
                                        editingNode
                                    ),
                                className: "parent-node"
                            };
                        } else {
                            return {
                                key: String(node.key),
                                title: (
                                    <div className='DraggableOverlaysComp-parent-node'>
                                        <>
                                            <span>{node.title}</span>
                                            <div
                                                className='edit-icon'
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleEditClick(
                                                        _overlay as IOverlayGroup
                                                    );
                                                }}
                                            />

                                            <div
                                                className='delete-icon'
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleNodeDelete(
                                                        String(
                                                            node.key
                                                        ),
                                                        false
                                                    );
                                                }}
                                            />
                                        </>
                                    </div>
                                ),
                                isLeaf: false,
                                children: createTreeDataFromGroup(
                                    _overlay as IOverlayGroup
                                ),
                                className: "parent-node"
                            };
                        }
                    } else {
                        const isActive =
                            props.store.map.overlays.getVisible(
                                _overlay.id
                            );
                        const lr = _overlay as IOverlayConfig;
                        return {
                            key: _overlay.id,
                            title: (
                                <div
                                    className='LayersListComp-overlays-overlay'
                                    key={lr.id}
                                    data-overlay={lr.id}
                                    onClick={props.onOverlayChange}
                                >
                                    {createLayerItem(
                                        lr,
                                        isActive,
                                        undefined,
                                        true
                                    )}
                                </div>
                            ),
                            isLeaf: true,
                            className: "child-node"
                        };
                    }
                });

                setTreeData(_treeData);
                if (!editingNode) {
                    setIsEditing(false);
                }
            }, [editingNode]);

            useEffect(() => {
                if (!editingNode) {
                    return;
                }
                editingNode.title = editingNodeTitle;
                setEditingNode(editingNode);
            }, [editingNodeTitle]);

            useEffect(() => {
                if (!editingNodeKey) {
                    setEditingNode(null);
                }
            }, [editingNodeKey]);

            useImperativeHandle(
                ref,
                () => ({
                    addNewParentNode() {
                        setIsAddingParent(true);
                    },
                    isEqual
                }),
                [isEqual]
            );

            const createTreeData = () => {
                return props.store.config.map_layers.overlays.map(
                    (overlay: IOverlayGroup | IOverlayConfig) => {
                        if (
                            "overlays" in overlay &&
                            overlay.overlays
                        ) {
                            let activeCount: number = 0;
                            overlay.overlays.map(o => {
                                let isActive =
                                    props.store.map.overlays.getVisible(
                                        o.id
                                    );
                                activeCount += Number(isActive);
                            });

                            return {
                                key: overlay.id,
                                title: (
                                    <div
                                        className={classNames(
                                            "DraggableOverlaysComp-parent-node",
                                            {
                                                "DraggableOverlaysComp-parent-node-temporal":
                                                    overlay.temporalParams
                                            }
                                        )}
                                    >
                                        <div
                                            className={classNames(
                                                overlay.temporalParams
                                                    ? "DraggableOverlaysComp-overlay-title-temporal"
                                                    : "DraggableOverlaysComp-overlay-title"
                                            )}
                                        >
                                            {overlay.title}
                                        </div>
                                        <div
                                            className='edit-icon'
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleEditClick(
                                                    overlay as IOverlayGroup
                                                );
                                            }}
                                        />
                                        <div
                                            className='delete-icon'
                                            onClick={e => {
                                                e.stopPropagation();
                                                {overlay.overlays.length?
                                                    handleNodeDelete(
                                                        overlay.id,
                                                    false
                                                    ) : nodeDelete(overlay.id)}
                                            }}
                                        />
                                        <div
                                            className={classNames(
                                                "drag-icon"
                                            )}
                                            style={{
                                                left: "-44px"
                                            }}
                                        />
                                        {"temporalParams" in
                                            overlay &&
                                            overlay.temporalParams && (
                                                <div>
                                                    <span
                                                        className={classNames(
                                                            "LayersListComp-overlays-group-item-header-temporal-date",
                                                            {
                                                                "LayersListComp-overlays-group-item-header-temporal-date-active":
                                                                    activeCount >
                                                                    0
                                                            }
                                                        )}
                                                        onClick={
                                                            handleonTemporalGroupClick
                                                        }
                                                        data-group={
                                                            overlay.id
                                                        }
                                                    >
                                                        {
                                                            props.store.map.overlays.temporalGroup(
                                                                overlay.id
                                                            ).start
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            props.store.map.overlays.temporalGroup(
                                                                overlay.id
                                                            ).end
                                                        }
                                                    </span>
                                                    {props.store
                                                        .layerListStore
                                                        .activeTemporalGroup
                                                        ?.group.id ===
                                                        overlay.id && (
                                                        <ContextMenuCommonComp
                                                            onClose={
                                                                handleonTemporalGroupWindowClose
                                                            }
                                                            direction={
                                                                PopupDirection.horizontal
                                                            }
                                                            popupCorner={
                                                                PopupCorner.rightBottom
                                                            }
                                                        >
                                                            <TemporalGroupComp
                                                                store={
                                                                    props.store
                                                                }
                                                                group={
                                                                    props
                                                                        .store
                                                                        .layerListStore
                                                                        .activeTemporalGroup
                                                                }
                                                            />
                                                        </ContextMenuCommonComp>
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                ),
                                isLeaf: false,
                                children: createTreeDataFromGroup(
                                    overlay as IOverlayGroup
                                ),
                                className: `${
                                    overlay.temporalParams
                                        ? "parent-node-temporal"
                                        : "parent-node "
                                }`
                            };
                        } else {
                            const isActive =
                                props.store.map.overlays.getVisible(
                                    overlay.id
                                );
                            const lr = overlay as IOverlayConfig;
                            return {
                                key: overlay.id,
                                title: (
                                    <div
                                        className='LayersListComp-overlays-overlay'
                                        key={lr.id}
                                        data-overlay={lr.id}
                                        onClick={handleOverlayChange}
                                    >
                                        {createLayerItem(
                                            lr,
                                            isActive,
                                            undefined,
                                            true
                                        )}
                                    </div>
                                ),
                                isLeaf: true,
                                className: "child-node"
                            };
                        }
                    }
                );
            };
            const handleOverlayChange = (e: any) => {
                props.onOverlayChange(e);
                setTreeData(createTreeData());
            };
            const handleStyleClick = (e: any) => {
                props.onStyleClick(e);
                setTreeData(createTreeData());
            };
            const handleonTemporalGroupClick = (e: any) => {
                props.onTemporalGroupClick(e);
                setTreeData(createTreeData());
            };
            const handleonTemporalGroupWindowClose = (e: any) => {
                props.onTemporalGroupWindowClose();
                setTreeData(createTreeData());
            };
            const createTreeDataFromGroup = (
                group: IOverlayGroup
            ) => {
                if (
                    !group.overlays ||
                    !Array.isArray(group.overlays)
                ) {
                    return [];
                }
                return group.overlays.map((subOverlay, i) => {
                    const isActive =
                        props.store.map.overlays.getVisible(
                            subOverlay.id
                        );
                    return {
                        key: subOverlay.id,
                        title: (
                            <div
                                className='DraggableOverlaysComp-overlays-group-overlay-item'
                                key={subOverlay.id}
                                data-overlay={subOverlay.id}
                                onClick={handleOverlayChange}
                            >
                                <div
                                    className={classNames({
                                        "DraggableOverlaysComp-overlays-group-overlay-item-vertical-line":
                                            i !==
                                            group.overlays.length - 1,
                                        "DraggableOverlaysComp-overlays-group-overlay-item-vertical-line-last":
                                            i ===
                                            group.overlays.length - 1
                                    })}
                                />
                                <div className='DraggableOverlaysComp-overlays-group-overlay-item-horizontal-line' />
                                {createLayerItem(
                                    subOverlay,
                                    isActive,
                                    group,
                                    true
                                )}
                            </div>
                        ),
                        isLeaf: true,
                        className: "child-node"
                    };
                });
            };

            const createLayerItem = (
                lr: IOverlayConfig,
                isActive: boolean,
                gr: IOverlayGroup = null,
                isChildren: boolean = false
            ) => {
                return (
                    <Fragment>
                        <CheckboxMiniComp
                            checkedEye
                            styleBox={{marginRight: "10px"}}
                            state={
                                isActive
                                    ? TriState.check
                                    : TriState.uncheck
                            }
                            size={CheckboxSize.standart}
                            classesContainer='pointer LayersListComp-overlays-group-overlay-checkbox'
                        ></CheckboxMiniComp>
                        <div
                            className={classNames(
                                "LayersListComp-overlays-overlay-title",
                                "LayersListComp-inactive-color"
                            )}
                        >
                            <span className='LayersListComp-overlays-overlay-title-span'>
                                {lr.title}
                            </span>
                        </div>
                        <div
                            className={classNames(
                                "LayersListComp-overlays-overlay-icon",
                                {
                                    "LayersListComp-overlays-overlay-icon-children":
                                        isChildren
                                }
                            )}
                            data-overlay={lr.id}
                            data-group={gr?.id}
                            onClick={handleStyleClick}
                        >
                            {props.createStyleSvg(lr)}
                        </div>
                        <div
                            className={classNames("drag-icon", {
                                "drag-icon-children": isChildren
                            })}
                        />
                        <div
                            className={classNames("delete-icon", {
                                "delete-icon-children": isChildren
                            })}
                            onClick={e => {
                                e.stopPropagation();
                                handleNodeDelete(lr.id, true);
                            }}
                        />
                        {props.store.layerListStore.showStyleWindow &&
                            props.store.layerListStore.activeOverlay
                                ?.id == lr.id && (
                                <ContextMenuCommonComp
                                    onClose={handleOnStyleClose}
                                    direction={
                                        PopupDirection.horizontal
                                    }
                                    popupCorner={
                                        PopupCorner.rightBottom
                                    }
                                >
                                    <LayerStyleComp
                                        store={props.store}
                                    />
                                </ContextMenuCommonComp>
                            )}
                    </Fragment>
                );
            };
            const handleOnStyleClose = () => {
                let lls = props.store.layerListStore;
                lls.activeOverlay = null;
                lls.showStyleWindow = false;
                setTreeData(createTreeData());
                props.store.layerListStore.isStylesToChange =
                    haveLayersChanged(
                        props.store.config.map_layers.overlays,
                        initialTreeDataRef.current
                    );
            };

            const handleEditClick = (overlay: IOverlayGroup) => {
                setEditingNode(overlay);
                setEditingNodeKey(overlay.id);
                setEditingNodeTitle(overlay.title);
                setIsEditing(true);
            };

            const handleRenameInputChange = (
                e: React.ChangeEvent<HTMLInputElement>
            ) => {
                setEditingNodeTitle(e.target.value);
            };

            const handleRenameInputBlur = () => {
                setTreeData(
                    treeData.map((node: DataNode) => {
                        if (node.key === editingNode.id) {
                            return {
                                key: node.key,
                                title: (
                                    <div className='DraggableOverlaysComp-parent-node'>
                                        <>
                                            <span>
                                                {editingNode.title}
                                            </span>
                                            <div
                                                className='edit-icon'
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleEditClick(
                                                        editingNode
                                                    );
                                                }}
                                            />

                                            <div
                                                className='delete-icon'
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    {node.children.length?
                                                    handleNodeDelete(
                                                        String(
                                                            node.key
                                                        ),
                                                        false
                                                    ) : nodeDelete(String(
                                                        node.key
                                                    ))}
                                                }}
                                            />
                                        </>
                                    </div>
                                )
                            } as DataNode;
                        } else {
                            return node;
                        }
                    })
                );

                setEditingNodeKey(null);
            };

            const handleRenameInputKeyDown = (
                e: React.KeyboardEvent<HTMLInputElement>
            ) => {
                if (e.key === "Enter") {
                    handleRenameInputBlur();
                }
                if (e.key === "Escape") {
                    handleRenameInputBlur();
                }
            };

            const findNodeByKey = (
                key: string,
                data: DataNode[]
            ): DataNode | undefined => {
                for (const node of data) {
                    if (node.key === key) return node;
                    if (node.children) {
                        const found = findNodeByKey(
                            key,
                            node.children
                        );
                        if (found) return found;
                    }
                }
            };

            const reorderOverlays = (
                treeData: DataNode[],
                originalOverlays: (IOverlayConfig | IOverlayGroup)[]
            ): (IOverlayConfig | IOverlayGroup)[] => {
                const findOverlayById = (
                    id: string,
                    overlays: (IOverlayConfig | IOverlayGroup)[]
                ): IOverlayConfig | IOverlayGroup | undefined => {
                    for (const overlay of overlays) {
                        if (overlay.id === id) {
                            return overlay;
                        }
                        if ("overlays" in overlay) {
                            const found = findOverlayById(
                                id,
                                overlay.overlays
                            );
                            if (found) {
                                return found;
                            }
                        }
                    }
                    return undefined;
                };

                const reorderNodes = (
                    nodes: DataNode[],
                    overlays: (IOverlayConfig | IOverlayGroup)[]
                ): (IOverlayConfig | IOverlayGroup)[] => {
                    return nodes.map(node => {
                        const nodeKey = String(node.key);

                        const originalOverlay = findOverlayById(
                            nodeKey,
                            overlays
                        );

                        if (!originalOverlay) {
                            return {
                                id: nodeKey,
                                title: node.title,
                                isExpanded: false,
                                overlays: node.children
                                    ? reorderNodes(
                                          node.children,
                                          overlays
                                      )
                                    : []
                            } as IOverlayGroup;
                        }

                        if (
                            node.children &&
                            node.children.length === 0
                        ) {
                            return {
                                id: originalOverlay.id,
                                title: originalOverlay.title,
                                isExpanded: false,
                                overlays: []
                            } as IOverlayGroup;
                        }

                        if (
                            node.children &&
                            node.children.length > 0
                        ) {
                            if ("overlays" in originalOverlay) {
                                return {
                                    ...originalOverlay,
                                    overlays: reorderNodes(
                                        node.children,
                                        overlays
                                    )
                                } as IOverlayGroup;
                            }
                        }
                        return originalOverlay;
                    });
                };
                return reorderNodes(treeData, originalOverlays);
            };
            const updateTreeData = (newTreeData: DataNode[]) => {
                frozenExpandedKeysRef.current = null;
                props.store.map.superTools.overlayTool.unloadOverlays();

                const newLayers = reorderOverlays(
                    newTreeData,
                    props.store.config.map_layers.overlays
                );
                props.store.config.map_layers.overlays = newLayers;
                setTreeData(createTreeData());
            };
            const allowDrop = ({
                dragNode,
                dropNode,
                dropPosition
            }: AllowDropOptions<DataNode>) => {
                const isDropNodeParent =
                    dropNode.children !== undefined;
                const isDragNodeParent =
                    dragNode.children !== undefined;

                if (
                    !isDropNodeParent &&
                    !isDragNodeParent &&
                    dropPosition === 0
                ) {
                    return false;
                }

                if (!isDropNodeParent && dropPosition === 0) {
                    return true;
                }

                if (isDragNodeParent && dropPosition !== 0) {
                    return true;
                }

                if (isDragNodeParent && isDropNodeParent) {
                    return true;
                }

                return true;
            };

            const onDrop = (
                info: NodeDragEventParams<DataNode> & {
                    dragNode: EventDataNode<DataNode>;
                    dragNodesKeys: Key[];
                    dropPosition: number;
                    dropToGap: boolean;
                }
            ) => {
                const dropKey = info.node.key;
                const dragKey = info.dragNode.key;

                const dropNode = findNodeByKey(
                    String(dropKey),
                    treeData
                );
                const dragNode = findNodeByKey(
                    String(dragKey),
                    treeData
                );

                if (!dropNode || !dragNode) {
                    return;
                }

                const isDropNodeParent = Boolean(dropNode.children);
                const isDragNodeParent = Boolean(dragNode.children);

                let newTreeData = [...treeData];

                const dragObj = removeNodeFromTree(
                    dragKey,
                    newTreeData
                );
                if (!dragObj) {
                    return;
                }

                if (!isDropNodeParent && !info.dropToGap) {
                    let parentNode: DataNode | null = findParentNode(
                        newTreeData,
                        String(dropKey)
                    );

                    if (parentNode) {
                        loop(
                            newTreeData,
                            String(parentNode.key),
                            (
                                item: DataNode,
                                index: number,
                                arr: DataNode[]
                            ) => {
                                arr.splice(index + 1, 0, dragObj);
                            }
                        );
                        updateTreeData(newTreeData);
                        return;
                    }
                    if (!parentNode) {
                        moveGroupAboveOtherGroups(
                            dragObj,
                            newTreeData
                        );
                        updateTreeData(newTreeData);
                        return;
                    }
                }
                if (info.dropToGap) {
                    let ar: DataNode[] = [];
                    let i = 0;

                    loop(
                        newTreeData,
                        dropKey,
                        (
                            item: DataNode,
                            index: number,
                            arr: DataNode[]
                        ) => {
                            ar = arr;
                            i = index;
                        }
                    );

                    insertNodeAtPosition(
                        dragObj,
                        info.dropPosition === -1 ? i : i + 1,
                        ar
                    );
                } else {
                    if (isDragNodeParent && isDropNodeParent) {
                        loop(
                            newTreeData,
                            dropKey,
                            (
                                item: DataNode,
                                index: number,
                                arr: DataNode[]
                            ) => {
                                arr.splice(index + 1, 0, dragObj);
                            }
                        );
                    } else {
                        loop(
                            newTreeData,
                            dropKey,
                            (item: DataNode) => {
                                if (!item.children) {
                                    item.children = [];
                                }
                                item.children.unshift(dragObj);
                            }
                        );
                    }
                }
                props.store.map.superTools.overlayTool.unloadOverlays();
                const newLayers = reorderOverlays(
                    newTreeData,
                    props.store.config.map_layers.overlays
                );
                const updatedExpandedKeys = [...expandedKeys];
                newTreeData.forEach(node => {
                    if (node.children && node.children.length > 0) {
                        const overlayGroup = newLayers.find(
                            o => o.id === node.key && "overlays" in o
                        ) as IOverlayGroup;

                        if (overlayGroup) {
                            const originalGroup =
                                props.store.config.map_layers.overlays.find(
                                    o =>
                                        o.id === node.key &&
                                        "overlays" in o
                                ) as IOverlayGroup;
                            if (
                                overlayGroup.overlays.length >
                                    originalGroup.overlays.length &&
                                !updatedExpandedKeys.includes(
                                    overlayGroup.id
                                )
                            ) {
                                updatedExpandedKeys.push(
                                    overlayGroup.id
                                );
                            }
                        }
                    }
                });
                setExpandedKeys(updatedExpandedKeys);

                updateTreeData(newTreeData);
                props.store.map.superTools.overlayTool.setupLayers();
            };
            const findParentNode = (
                tree: DataNode[],
                targetKey: string
            ): DataNode | null => {
                let parentNode: DataNode | null = null;

                const findParent = (nodes: DataNode[]): boolean => {
                    for (const node of nodes) {
                        if (
                            node.children &&
                            node.children.some(
                                child => child.key === targetKey
                            )
                        ) {
                            parentNode = node;
                            return true;
                        }

                        if (
                            node.children &&
                            findParent(node.children)
                        ) {
                            return true;
                        }
                    }
                    return false;
                };

                findParent(tree);
                return parentNode;
            };

            const removeNodeFromTree = (
                key: React.Key,
                treeData: DataNode[]
            ): DataNode | undefined => {
                let dragObj: DataNode | undefined;

                loop(
                    treeData,
                    key,
                    (
                        item: DataNode,
                        index: number,
                        arr: DataNode[]
                    ) => {
                        arr.splice(index, 1);
                        dragObj = item;
                    }
                );

                return dragObj;
            };

            const loop = (
                data: DataNode[],
                key: React.Key,
                callback: (
                    item: DataNode,
                    index: number,
                    arr: DataNode[]
                ) => void
            ) => {
                data.forEach((item, index, arr) => {
                    if (item.key === key) {
                        return callback(item, index, arr);
                    }
                    if (item.children) {
                        return loop(item.children, key, callback);
                    }
                });
            };

            const insertNodeAtPosition = (
                node: DataNode,
                position: number,
                targetArray: DataNode[]
            ) => {
                targetArray.splice(position, 0, node);
            };

            const moveGroupAboveOtherGroups = (
                node: DataNode,
                treeData: DataNode[]
            ) => {
                let groupInsertIndex = treeData.length;

                treeData.forEach((item, index) => {
                    if (
                        item.children &&
                        groupInsertIndex === treeData.length
                    ) {
                        groupInsertIndex = index;
                    }
                });

                insertNodeAtPosition(
                    node,
                    groupInsertIndex,
                    treeData
                );
            };

            const confirmAddNewParentNode = () => {
                const updatedKeyCounter = uniqueKeyCounter + 1;
                const newParentKey = `new-parent-${updatedKeyCounter}`;

                const newParentNode = {
                    key: newParentKey,
                    title:
                        newParentTitle ||
                        `New Parent ${updatedKeyCounter}`,
                    children: [] as DataNode[],
                    isLeaf: false,
                    className: "parent-node"
                };
                setTreeData([...treeData, newParentNode]);
                setUniqueKeyCounter(updatedKeyCounter);
                setNewParentTitle("");
                const newLayers = reorderOverlays(
                    [...treeData, newParentNode],
                    props.store.config.map_layers.overlays
                );
                props.store.config.map_layers.overlays = newLayers;
                setTreeData(createTreeData());
                setIsAddingParent(false);
            };
            const handleNodeDelete = (
                key: string,
                isLeaf: boolean
            ) => {
                props.store.layerListStore.deleteNodeIsLeaf = isLeaf;
                props.store.layerListStore.deleteNodeKey = key;
                props.store.layerListStore.showDeletePopup = true;
            };
            const nodeDelete = (key: string) => {
                const deleteNode = (
                    data: DataNode[],
                    overlays: (IOverlayConfig | IOverlayGroup)[]
                ): {
                    newData: DataNode[];
                    newOverlays: (IOverlayConfig | IOverlayGroup)[];
                } => {
                    const updatedData = data
                        .filter(node => {
                            if (node.key === key) {
                                const overlayToDelete = overlays.find(
                                    overlay => overlay.id === node.key
                                );

                                if (overlayToDelete) {
                                    const removeOverlayFromMap = (
                                        overlay:
                                            | IOverlayConfig
                                            | IOverlayGroup
                                    ) => {
                                        let map =
                                            props.store.map.mapbox;
                                        let ovs =
                                            props.store.map.overlays
                                                .overlayesConfig;

                                        if (
                                            props.store.map.overlays.getVisible(
                                                overlay.id
                                            )
                                        ) {
                                            props.store.map.overlays.setVisible(
                                                overlay.id,
                                                false
                                            );
                                        }
                                        for (
                                            let i = 0;
                                            i < ovs.length;
                                            i++
                                        ) {
                                            for (
                                                let j = 0;
                                                j <
                                                ovs[i].layers.length;
                                                j++
                                            ) {
                                                let layerId =
                                                    OverlayObjectStore.getOverlayLayerName(
                                                        ovs[i].id,
                                                        j
                                                    );

                                                if (
                                                    map.getLayer(
                                                        layerId
                                                    )
                                                ) {
                                                    map.removeLayer(
                                                        layerId
                                                    );
                                                }
                                            }

                                            let sourceId =
                                                OverlayTool.OVERLAY_SOURCE_PREFIX +
                                                i;
                                            if (
                                                map.getSource(
                                                    sourceId
                                                )
                                            ) {
                                                map.removeSource(
                                                    sourceId
                                                );
                                            }
                                        }
                                        if (
                                            "overlays" in overlay &&
                                            overlay.overlays.length >
                                                0
                                        ) {
                                            overlay.overlays.forEach(
                                                childOverlay => {
                                                    removeOverlayFromMap(
                                                        childOverlay
                                                    );
                                                }
                                            );
                                        }
                                    };
                                    removeOverlayFromMap(
                                        overlayToDelete
                                    );
                                }
                            }

                            return node.key !== key;
                        })
                        .map(node => {
                            if (node.children) {
                                const {
                                    newData: updatedChildren,
                                    newOverlays: updatedOverlays
                                } = deleteNode(
                                    node.children,
                                    overlays
                                );
                                return {
                                    ...node,
                                    children: updatedChildren
                                };
                            }
                            return node;
                        });
                        const updatedOverlays = overlays.filter(overlay => {
                            if ('overlays' in overlay) {
                                if (overlay.id === key) {
                                    return false;
                                }
                                overlay.overlays = overlay.overlays.filter(childOverlay => childOverlay.id !== key);
                                return true;
                            }
                            return overlay.id !== key;
                        });
                    return {
                        newData: updatedData,
                        newOverlays: updatedOverlays
                    };
                };
               
                setTreeData(prevTreeData => {
                    props.store.map.superTools.overlayTool.unloadOverlays();
                    const {
                        newData: updatedTreeData,
                        newOverlays: updatedOverlays
                    } = deleteNode(
                        prevTreeData,
                        props.store.config.map_layers.overlays
                    );
                    props.store.config.map_layers.overlays =
                        updatedOverlays; 
                    return updatedTreeData;
                });
            };

            const onDragStart = (
                info: NodeDragEventParams<DataNode>
            ) => {
                if (frozenExpandedKeysRef.current === null) {
                    frozenExpandedKeysRef.current = expandedKeys;
                }
            };
            const onDragEnd = () => {
                frozenExpandedKeysRef.current = null;
                props.store.map.superTools.overlayTool.unloadOverlays();
                const newLayers = reorderOverlays(
                    treeData,
                    props.store.config.map_layers.overlays
                );
                props.store.config.map_layers.overlays = newLayers;
                props.store.map.superTools.overlayTool.setupLayers();

                setTreeData(createTreeData());
                if (frozenExpandedKeysRef.current) {
                    setExpandedKeys(frozenExpandedKeysRef.current);
                }
            };

            const onExpand = (newExpandedKeys: Key[]) => {
                if (frozenExpandedKeysRef.current === null) {
                    setExpandedKeys(newExpandedKeys);
                }
            };
            const dropIndicatorRender = () => {
                return <div className='dropIndicatorRender'></div>;
            };
            return (
                <>
                    <Tree
                        showIcon={false}
                        className='draggable-tree'
                        draggable
                        onDrop={onDrop}
                        treeData={treeData}
                        onDragStart={onDragStart}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        allowDrop={allowDrop}
                        onDragEnd={onDragEnd}
                        dropIndicatorRender={dropIndicatorRender}
                    />
                    {isAddingParent && (
                        <input
                            type='text'
                            className='DraggableOverlaysComp-new-parent-input'
                            value={newParentTitle}
                            onChange={e =>
                                setNewParentTitle(e.target.value)
                            }
                            onKeyDown={e => {
                                if (
                                    e.key === "Enter" &&
                                    newParentTitle.trim() !== ""
                                ) {
                                    confirmAddNewParentNode();
                                }
                                if (
                                    e.key === "Escape" &&
                                    newParentTitle.trim() !== ""
                                ) {
                                    confirmAddNewParentNode();
                                }
                            }}
                            onBlur={confirmAddNewParentNode}
                            autoFocus
                        />
                    )}
                    {treeData.length > 1 && props.parOverlays}
                </>
            );
        }
    )
);

export default DraggableOverlaysComp;
