import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IProductItemComp} from "./ProductItemComp";
import classNames from "classnames";
import {action} from "mobx";
import {ProductStyleType} from "../../../../store/config/ConfigStore";
import {ProductItemPropStaticBandComp} from "./ProductItemPropStaticBandComp";

@observer
export class ProductItemPropComp extends React.Component<IProductItemComp, undefined> {
    constructor(props: IProductItemComp) {
        super(props);
        autoBindReact(this);
    }

    getStyleTypes(): ProductStyleType[]{
        let item = this.props.item;
        let cfg = item.productConfig;
        if (cfg == null || cfg.styles == null) return [];
        return cfg.styles.map(a => a.type);
    }

    @action
    onClickType(e: any){
        let att = e.currentTarget.getAttribute("data-typename");
        let item = this.props.item;
        item.styleType = att;
        this.props.item.changed = true;
    }
    @action
    onDefault(){
        this.props.item.loadDefault();
        this.props.item.changed = true;
    }
    @action
    onSwitchLUT(e: any){
        let item = this.props.item;
        let v = e.currentTarget.checked;
        if (v){
            item.styleType = ProductStyleType.LUT;
        }else{
            item.styleType = ProductStyleType.static;
        }
        this.props.item.changed = true;
    }

    render() {
        let store = this.props.store;
        let item = this.props.item;
        let curType = item.styleType
        let types = this.getStyleTypes();

        let arr: any[] = [];
        if (curType == ProductStyleType.static || curType == ProductStyleType.LUT){
            let stat = item.staticStyle;
            if (stat != null){
                stat.bands.forEach((a, index) => {
                    arr.push(<ProductItemPropStaticBandComp key={a.name} readonly={(curType != ProductStyleType.static)}
                                                            item={this.props.item}
                                                            store={store} band={a} index={index} />);
                });
            }
        }

        if (types.length > 0){
            arr.push(
                <div key="footer" className="ProductItemPropComp-footer">
                    <div onClick={this.onDefault} key="cancel" className={
                        classNames("ProductItemPropComp-button",
                            "active_on_hover white-fill pointer",
                            "full-center-content")}>
                        {store.trans.Default}
                    </div>
                </div>
            );
        }

        return <div className="ProductItemPropComp">
            {arr}
        </div>;
    }
}
