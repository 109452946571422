import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action, observable, runInAction} from "mobx";
import {DropDownComp, IDropDownItem} from "../../Common/DropDownComp";
import {PopupCorner, PopupDirection} from "../../Common/ContextMenuCommonComp";
import "./DebugPanelComp.scss";
import {PluginsController} from "../../../../pluginApi/PluginsController";


@observer
export class DebugPanelComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        let ss = PluginsController.getPlugins().map(a => a.pluginName).join(",");
        this.paint = ss;
    }

    doc_keyUp(e: any) {
        if (e.ctrlKey && e.code === 'Digit0') {
            runInAction(()=>{
                this.props.store.debugStore.showPanel = !this.props.store.debugStore.showPanel;
            });
        }
    }

    componentDidMount() {
        document.body.addEventListener("keyup", this.doc_keyUp);
    }
    componentWillUnmount() {
        document.body.removeEventListener("keyup", this.doc_keyUp);
    }

    @action
    clickGetStyle(){
        let st = this.props.store.map.mapbox.getStyle();
        console.log(st);
    }
    @action
    onClose(){
        this.props.store.debugStore.showPanel = false;
    }
    @action
    clickNewGeometry(){
        this.props.store.debugStore.showPanel = false;
    }
    @action
    clickNewPoint(){
        this.props.store.gotoUrl('/sendedEmail');
    }
    @action
    clickNewLine(){
        this.props.store.debugStore.showPanel = false;
    }
    @action
    clickNewPolygon(){
    }
    @action
    clickNewHole(){
    }



    @action
    clickLoadPhotoList(){
    }




    @observable
    content: string = "flex-start";
    @observable
    paint: string = "";

    @observable
    txt: string = "";
    @action
    onChange(value: string, event?: any){
        this.txt = value;
    }
    clickGetTools(){
        this.props.store.map.superTools.container.allTools.forEach(a =>
            {
                console.log(a.name);
            }
        );
    }

    render() {
        let store = this.props.store;

        if (!this.props.store.debugStore.showPanel) return null;

        let arrDir: IDropDownItem[] = [
            {key: PopupDirection.horizontal, value: PopupDirection.horizontal},
            {key: PopupDirection.vertical, value: PopupDirection.vertical},
            {key: 11, value: PopupDirection.horizontal},
            {key: 12, value: PopupDirection.vertical},
            {key: 13, value: PopupDirection.horizontal},
            {key: 14, value: PopupDirection.vertical},
        ];
        let arrCorner: IDropDownItem[] = [
            {key: PopupCorner.leftBottom, value: PopupCorner.leftBottom},
            {key: PopupCorner.rightBottom, value: PopupCorner.rightBottom},
            {key: PopupCorner.rightTop, value: PopupCorner.rightTop},
            {key: PopupCorner.leftTop, value: PopupCorner.leftTop},
        ];
        let arrContent: IDropDownItem[] = [
            {key: "flex-start", value: "flex-start"},
            {key: "center", value: "center"},
            {key: "flex-end", value: "flex-end"},
        ];


        return <div id="DebugPanelComp" style={{position: "fixed", "top":0, "right":0, "width": "400px", "height":"400px",
                "background":"black", "color":"white", zIndex:5000}}>
            <div>
                <button onClick={this.clickGetStyle}>GetStyles</button>
                <button onClick={this.clickGetTools}>GetTools</button>
                <button onClick={this.clickNewGeometry}>close</button>
                <button onClick={this.clickNewPoint}>2</button>
                <button onClick={this.clickNewLine}>3</button>
                <button onClick={this.clickNewPolygon}>polygon</button>
                <button onClick={this.clickNewHole}>hole</button>
                <button onClick={this.clickLoadPhotoList}>delete contour</button>
            </div>
            <div>

            </div>
        </div>;
    }
}
