import {Translate} from "../Translate";
import {Translate_en} from "../Translate_en";
import {SuperStore} from "../../../store/SuperStore";

export class TranslateOver_ParcelAssess_en// extends Translate_en
{
    static updateTranslate(store: SuperStore) {
        let t = store.trans;
        t.object_create = 'Create field'
        t.object_creation = 'Create field'
        t.object_editing = 'Edit field'
        t.objects_editing = 'Edit fields'
        t.object_border = 'Field border'
        t["Maximum number of objects in active area: {0}"] = "Maximum number of fields in active area: {0}"
        t["Select the attributes of your table from the uploaded file that you want to import and match them to the characteristics of your objects"] = "Select the attributes of your table from the uploaded file that you want to import and match them to the characteristics of your fields";
        t["Objects not selected"] = "Fields not selected";
        t["Selected objects to active area"] = "Selected fields to active area"
        t.ObjectsFields = "Fields"
        t["Get object profile"] = "Get field profile"
        t["Loading objects from a file"] = "Loading fields from a file"
        t["Object border"] = "Field border"
        t["Split by objects"]="Split by fields"
    }
}