import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {ShowRoom2LayerStore, ShowroomLayerType} from "./ShowRoom2LayerStore";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {action, autorun, observable, reaction} from "mobx";
import {fetchJsonGet} from "../../helper/utils/FetchUtils";
import {ra} from "../../helper/utils/mobxUtils";
import {IReactionDisposer} from "mobx/lib/internal";
import {MBUtils} from "../../helper/utils/MBUtils";
import {ShowRoom2MapPopupStore} from "./ShowRoom2MapPopupStore";
import {save, update} from "../PermalinkDecor";
import {ShowRoom2FilterProjectStore} from "./ShowRoom2FilterProjectStore";
import {ShowRoom2FilterMultiprojectStore} from "./ShowRoom2FilterMultiprojectStore";
import {IShowRoom2LayerFilters} from "../tools/ShowRoom2/ShowRoom2LayerCustomTool";

export interface IShowRoom2District {
//"id": 1, "distrcode": "712", "statecode": "36", "distrname": "Mancherial", "statename": "Telengana"
    "id": number,
    "distrcode": string,
    "statecode": string,
    "distrname": string,
    "statename": string
}
export interface IShowRoom2Crop{
    id: number;
    name: string;
    color: number;
}
export interface IShowRoom2Seasons{
    id: number;
    name: string;
}
export enum DataShowRoomMode {
    Multiyear='Multiyear',
    Projects='Projects'
}

export class ShowRoom2Store extends ObservableCustomStore{
    constructor(parent: CustomStore) {
        super(parent);
        this.initLayers();
    }

    class(): string {
        return "ShowRoom2Store";
    }

    subscription(): IReactionDisposer[]{
        return [
            autorun(()=>{
                if (this.active && this.crops_status == null && this.root.user.isAuth)
                    this.loadDictonaries().then(()=>{});
            }),
            reaction(()=>{
                return {a: this.filtersProject.seasonId, b: this.active};
            },()=>{
                if (this.active){
                    this.filtersProject.loadCropIdsBySeason();
                }
            }),
        ];
    }

    @observable
    active: boolean = false;

    mapPopup: ShowRoom2MapPopupStore = new ShowRoom2MapPopupStore(this);
    
    layers : ShowRoom2LayerStore[] = [];
    @observable
    filterPanelShow: boolean = false;
    @save @observable
    dataShowRoomMode: DataShowRoomMode = DataShowRoomMode.Multiyear;

    @update
    districts: ShowRoom2LayerStore = ShowRoom2LayerStore.create(this, ShowroomLayerType.districts);
    @update
    ccePoints: ShowRoom2LayerStore = ShowRoom2LayerStore.create(this, ShowroomLayerType.ccePoints);
    @update
    classPoints: ShowRoom2LayerStore = ShowRoom2LayerStore.create(this, ShowroomLayerType.classPoints);
    @update
    yieldAssessmentPoints: ShowRoom2LayerStore = ShowRoom2LayerStore.create(this, ShowroomLayerType.yieldAssessmentPoints);
    @update
    arableAreaMask: ShowRoom2LayerStore = new ShowRoom2LayerStore(this);
    @update
    cropAreaMask: ShowRoom2LayerStore = new ShowRoom2LayerStore(this);
    @update
    yieldMapPerGP: ShowRoom2LayerStore  = ShowRoom2LayerStore.create(this, ShowroomLayerType.yieldMapPerGP);

    @save @observable
    seasons: IShowRoom2Seasons[] = [];
    @update
    filtersProject: ShowRoom2FilterProjectStore = new ShowRoom2FilterProjectStore(this);
    @update
    filtersMultiproject: ShowRoom2FilterMultiprojectStore = new ShowRoom2FilterMultiprojectStore(this);

    @observable
    crops_status: LoadStatus = null;
    @observable
    districtsData: IShowRoom2District[] = [];
    @observable
    cropDataShort: Map<number, IShowRoom2Crop> = new Map<number, IShowRoom2Crop>();
    @observable
    cropDataAll: Map<number, IShowRoom2Crop> = new Map<number, IShowRoom2Crop>();


    @observable
    otherCropColor: number = 0x4D85F2;

    initLayers(){
        let layerArr:ShowRoom2LayerStore[] = [];
        this.districts.title = "Districts";
        this.ccePoints.title = "CCE points";
        this.classPoints.title = "Classification points";
        this.yieldAssessmentPoints.title = "Yield assessment points";
        this.arableAreaMask.title = 'Arable area mask';
        this.cropAreaMask.title = 'Crop area mask';
        this.yieldMapPerGP.title = 'Yield map per GP';

        layerArr.push(this.districts);
        layerArr.push(this.ccePoints);
        layerArr.push(this.classPoints);
        layerArr.push(this.yieldAssessmentPoints);
        this.layers = layerArr;
    }

    getFilters(){
        let store = this.root;
        let r: IShowRoom2LayerFilters = {};
        if (store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Multiyear) {
            if (store.dataShowRoomStore.filtersMultiproject.cropFilters.size > 0) r.crops = [...store.dataShowRoomStore.filtersMultiproject.cropFilters.values()];
            if (store.dataShowRoomStore.filtersMultiproject.seasonsFilters.size > 0) r.seasons = [...store.dataShowRoomStore.filtersMultiproject.seasonsFilters.values()];
        }
        if (store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Projects) {
            if (store.dataShowRoomStore.filtersProject.cropId != null) r.crops = [store.dataShowRoomStore.filtersProject.cropId];
            if (store.dataShowRoomStore.filtersProject.seasonId != null) r.seasons = [store.dataShowRoomStore.filtersProject.seasonId];
            if (store.dataShowRoomStore.filtersProject.filter_districts_ids.size > 0) r.districts = [...store.dataShowRoomStore.filtersProject.filter_districts_ids.values()];
        }
        return r;
    }

    @action
    async loadDictonaries(){
        try {
            this.crops_status = LoadStatus.loading;
            let res = await Promise.all(
                [
                    fetchJsonGet('/reports/api/crops'),
                    fetchJsonGet('/reports/api/seasons'),
                ]
            );
            ra(() => {
                this.cropDataShort.clear();
                let colors: number[] = [];
                let newColor = MBUtils.getNewColor(colors);
                this.cropDataShort.set(11, {id: 11, name: "Wheat", color: newColor});colors.push(newColor);
                newColor = MBUtils.getNewColor(colors);
                this.cropDataShort.set(7, {id: 7, name: "Paddy", color: newColor});colors.push(newColor);
                newColor = MBUtils.getNewColor(colors);
                this.cropDataShort.set(9, {id: 9, name: "Sugarcane", color: newColor});colors.push(newColor);
                newColor = MBUtils.getNewColor(colors);
                this.cropDataShort.set(0, {id: 0, name: "Barley", color: newColor});colors.push(newColor);
                this.otherCropColor = 0x4D85F2;
                this.cropDataAll.clear();
                this.filtersMultiproject.cropFilters.clear();
                (res[0] as IShowRoom2Crop[]).forEach(a => {
                    this.cropDataAll.set(a.id, a);
                });

                this.seasons = res[1] as IShowRoom2Seasons[];
                this.filtersMultiproject.seasonsFilters.clear();
                this.crops_status = LoadStatus.ready;
            });
        }catch (e)
        {
            this.root.addError(e);
        }

    }
}