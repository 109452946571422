// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegendBaseMap-common {
  border: 1px solid #3E4751;
  right: 5px;
  border-radius: 6px;
  top: 5px;
  background-color: #2B3138;
  z-index: 4;
  position: absolute;
  color: #C5C5C5;
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/LegendBaseMap/LegendBaseMap.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,QAAA;EACA,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AACF","sourcesContent":[".LegendBaseMap-common {\n  border: 1px solid #3E4751;\n  right: 5px;\n  border-radius: 6px;\n  top: 5px;\n  background-color: #2B3138;\n  z-index: 4;\n  position: absolute;\n  color: #C5C5C5;\n  padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
