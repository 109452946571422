import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDelFieldsPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {TranslateUtils} from "../../../app/helper/lang/TranslateUtils";

@observer
export class AgroAhoDelFieldsPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store = this.props.store.agroAhoStore;
        let store_ = store.cardStore;
        store_.doFieldsDel();
        store.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    render() {
        let store = this.props.store;
        return <div className="AgroAhoDelFieldsPanelComp-main">
            <div className="AgroAhoDelFieldsPanelComp-caption">{store.trans.Delete}</div>
            <div className="AgroAhoDelFieldsPanelComp-msg">{
                store.agroAhoStore.trans['Attention! For selected fields in the ASA map, all related data will be deleted: cells, indicators, recommended norms!']}</div>
            <div className="AgroAhoDelFieldsPanelComp-line"> </div>
            <div className="AgroAhoDelFieldsPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoDelFieldsPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className="large-button left-sidebar-red button-jump"
                    onClick={this.onClickDelete}>{store.trans.Delete}</button>
            </div>
        </div>;
    }
}
