import * as React from 'react';
import autoBindReact from "auto-bind/react";
import classNames from "classnames";


export interface IAgroAhoMenuSvgProps {
    className?: string;
    onClick:(e: any)=>void;
}

export class AgroAhoMenuSvg extends React.PureComponent<IAgroAhoMenuSvgProps, undefined> {
    constructor(props: IAgroAhoMenuSvgProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <svg className={classNames(this.props.className)} onClick={this.props.onClick}
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="5" fill="#4DB6BC"/>
            <path d="M10.25 12C10.25 12.9665 11.0335 13.75 12 13.75C12.9665 13.75 13.75 12.9665 13.75 12C13.75 11.0335 12.9665 10.25 12 10.25C11.0335 10.25 10.25 11.0335 10.25 12Z" fill="#2B3138"/>
            <path d="M10.25 6.75C10.25 7.7165 11.0335 8.5 12 8.5C12.9665 8.5 13.75 7.7165 13.75 6.75C13.75 5.7835 12.9665 5 12 5C11.0335 5 10.25 5.7835 10.25 6.75Z" fill="#2B3138"/>
            <path d="M10.25 17.25C10.25 18.2165 11.0335 19 12 19C12.9665 19 13.75 18.2165 13.75 17.25C13.75 16.2835 12.9665 15.5 12 15.5C11.0335 15.5 10.25 16.2835 10.25 17.25Z" fill="#2B3138"/>
        </svg>;
    }
}
