// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmPopupComp-button {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 15px;
}

.ConfirmPopupComp-text {
  padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/ConfirmPopupComp.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AACA;EACE,cAAA;AAEF","sourcesContent":[".ConfirmPopupComp-button{\r\n  margin-top: 10px;\r\n  margin-bottom: 5px;\r\n  margin-right: 15px;\r\n}\r\n.ConfirmPopupComp-text{\r\n  padding: 5px 0;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
