import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from '../../../app/helper/structs/IStoreProps';
import classNames from "classnames";
import {ColorHelper, IRGBA} from "../../../app/helper/utils/ColorHelper";
import {DropDownComp, IDropDownItem} from "../../../app/components/Common/DropDownComp";
import {DropTextComp} from "../../../app/components/Common/DropTextComp";
import {action, observable} from "mobx";
import {Utils} from "../../../app/helper/utils/Utils";
import {IAgroExportStoreProps} from "../agroExportPlugin";

export interface IProductItemExportNormaClassComp extends IAgroExportStoreProps{
    index: number;
    value: number;
    onChange: (index: number, value: number)=>any;
    base: number;
    color: IRGBA;
    disabled: boolean;
    disableAll: boolean;
    area: number;
}
@observer
export class ProductItemExportNormaClassComp extends React.Component<IProductItemExportNormaClassComp, undefined> {
    constructor(props: IProductItemExportNormaClassComp) {
        super(props);
        autoBindReact(this);
        this.text = this.props.value.toString() + "%";
    }
    @observable
    text: string;

    getCurValue(str: string): number{
        let s = str.replace(' ', "").replace("%", "");
        return Utils.parseInt(s);
    }
    @action
    onChange2(text: string, item: IDropDownItem){
        this.text = text;
        let v = this.getCurValue(text);
        if (v != null) {
            if (this.props.onChange) this.props.onChange(this.props.index, v);
        }
    }
    onChange(item: IDropDownItem, newIndex: number){
        if (this.props.disableAll) return;
        this.props.onChange(this.props.index, item.data);
    }
    @action
    onFinish(){
        let v = this.getCurValue(this.text);
        if (v == null){
            this.text = this.props.value + "%";
        }else if (v < -100) this.text = "-100%";else
            if (v > 100) this.text = "+100%";
        if (!this.text.includes("%")) this.text = this.text + "%";
    }

    render() {
        let store = this.props.store;
        let colorString = ColorHelper.ColorRGBAtoCSS(this.props.color);
        let text = `Класс #${this.props.index + 1}`;
        let items: IDropDownItem[] = [];
        let val = this.props.value;

        let curKey = this.props.value;
        function add(i: number){
            let txt = `${i}%`;
            if (i > 1)txt = `+${i}%`;
            if (i == val) curKey = i;
            items.push({data: i, key: i, value: txt});
        }

        add(50);
        add(30);
        add(20);
        add(10);
        add(0);
        add(-10);
        add(-20);
        add(-30);
        add(-50);
        add(-100);

        let v = this.props.base + this.props.value / 100 * this.props.base;


        return <div className={classNames("ProductItemExportNormaComp-row ProductItemExportNormaComp-row-classes", {"opacity30": this.props.disableAll},this.props.className)}>
            <div className="ProductItemExportNormaComp-col1 ">
                <div className="flex-rows-centerByBoth">
                    <div className="flex-columns-centerByVertical">
                        <div className="ProductItemExportNormaComp-colorBox" style={{background: colorString}} />
                        <div className="ProductItemExportNormaComp-textNormal">{text}</div>
                    </div>
                    <div className="ProductItemExportNormaComp-textGray">{this.props.area.toFixed(1)} {store.trans.ha}</div>
                </div>
            </div>
            <div className="ProductItemExportNormaComp-col2">
                <DropTextComp items={items} text={this.text} onFinish={this.onFinish} dropItemsAllVisible={true} highlightItems={false}
                              onChange={this.onChange2} disabled={this.props.disabled || this.props.disableAll} />
            </div>
            <div className="ProductItemExportNormaComp-col3 ProductItemExportNormaComp-textGray">
                <div className="ProductItemExportNormaComp-subCol3">
                    {`${v.toFixed(1)} кг/га`}
                </div>
            </div>
        </div>;
    }
}
