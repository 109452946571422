import {IStyle} from "./styleInterface/IStyle";
import {IStyleBase, IStyleItem} from "./styleInterface/IStyleItem";
import {IStyleItemCategorized, IStyleCategorized_category} from "./styleInterface/IStyleItemCategorized";
import {IPaintPoint} from "./styleInterface/IPaintPoint";
import {CircleLayer, FillLayer, Layer, LineLayer} from "maplibre-gl";
import {forEach, isArray, isBoolean, isNumber, isPlainObject} from "lodash-es";
import {IPaint} from "./styleInterface/IPaint";
import {getDefaultIPaint, StyleDefaultMaxZoom, StyleDefaultMinZoom} from "./defaultIntStyle";
import {IDictonaryType, Utils} from "../utils/Utils";
import {IPaintPolyline} from "./styleInterface/IPaintPolyline";
import {IPaintFill} from "./styleInterface/IPaintPolygon";
import {IPaintGeometry} from "./styleInterface/IPaintGeometry";
import {newSymbol} from "mobx-react/dist/utils/utils";
import {IPaintLabel} from "./styleInterface/IPaintLabel";
import {TextUtils} from "../utils/TextUtils";

export interface ILayerColumnName {
    name: string;
    aliases: { [index: string]: string };
}
export interface IStyleGeneratorOptions{
    layerNamePrefix: string;
    sourceLayer?: string;
    source?: string;
    columns?: ILayerColumnName[];
    categoriesIsVisible?: (categoryValue: any) => boolean;
}

class PropertiesCategory{
    categories: Map<any, any> = new Map<any, any>();//ключ категории, значение стиля
    defaultValue: any;
}
class LayerStruct{
    minZoom?: number;
    maxZoom?: number;
    minZoomExpr: PropertiesCategory = new PropertiesCategory();
    maxZoomExpr: PropertiesCategory = new PropertiesCategory();
    visibleExpr: PropertiesCategory = new PropertiesCategory();
    paintProperties: IDictonaryType<PropertiesCategory> = {};
    layoutProperties: IDictonaryType<PropertiesCategory> = {};
}
export class IntStyleConverter{
    public static toMapbox(intStyle: IStyle, options: IStyleGeneratorOptions): any[]{
        let arr: any[] = [];
        if (intStyle.styles){
            intStyle.styles.forEach(a => {
                arr = arr.concat(IntStyleConverter.styleItemToMapbox(a, options));
            });
        }
        return arr;
    }
    private static styleItemToMapbox(item: IStyleItem, options: IStyleGeneratorOptions): any[]{
        let res: any[] = [];
        if (item.type == "categorized" || item.type == "simple"){
            let catStyle = item as IStyleItemCategorized;

            let startPaint: IPaint = {};
            let defPaint = getDefaultIPaint();
            let layerPoint: LayerStruct = new LayerStruct();
            IntStyleConverter.browserCategory(catStyle, (paint: IPaint, categoryKey?: any) => {
                IntStyleConverter.pointProperties(categoryKey, paint.point, defPaint.point, layerPoint);
            });
            IntStyleConverter.browserBase(catStyle, layerPoint, startPaint.point, catStyle.paint?.point,
                (c) => c?.paint?.point);


            let layerLine: LayerStruct = new LayerStruct();
            IntStyleConverter.browserCategory(catStyle, (paint: IPaint, categoryKey?: any) => {
                IntStyleConverter.lineProperties(categoryKey, paint.polyline, defPaint.polyline, layerLine);
            });
            IntStyleConverter.browserBase(catStyle, layerLine, startPaint.polyline, catStyle.paint?.polyline, (c) => c.paint?.polyline);


            let layerPolygonLine: LayerStruct = new LayerStruct();
            let layerPolygonFill: LayerStruct = new LayerStruct();
            IntStyleConverter.browserCategory(catStyle, (paint: IPaint, categoryKey?: any) => {
                IntStyleConverter.lineProperties(categoryKey, paint.polygon.contour, defPaint.polygon.contour, layerPolygonLine);
                IntStyleConverter.fillProperties(categoryKey, paint.polygon.fill, defPaint.polygon.fill, layerPolygonFill);
            });
            IntStyleConverter.browserBase(catStyle, layerPolygonLine, startPaint?.polygon?.contour, catStyle.paint?.polygon?.contour,(c) => c.paint?.polygon?.contour);
            IntStyleConverter.browserBase(catStyle, layerPolygonFill, startPaint?.polygon?.fill, catStyle.paint?.polygon?.fill, (c) => c.paint?.polygon?.fill);

            let layerLabel: LayerStruct = new LayerStruct();
            IntStyleConverter.browserCategory(catStyle, (paint: IPaint, categoryKey?: any) =>{
                IntStyleConverter.labelProperties(categoryKey, paint.label, defPaint.label, layerLabel);
                IntStyleConverter.labelPropertiesLayout(categoryKey, paint.label, defPaint.label, layerLabel, options);
            });
            IntStyleConverter.browserBase(catStyle, layerLabel, startPaint.label, catStyle.paint?.label, (c) => c.paint?.label);

            if (options.categoriesIsVisible) {
                IntStyleConverter.browserCategory(catStyle, (paint: IPaint, categoryKey?: any) => {
                    let customVisible = options.categoriesIsVisible(categoryKey);
                    if (!customVisible) {
                        if (categoryKey === undefined){
                            layerPoint.visibleExpr.defaultValue = customVisible;
                            layerLine.visibleExpr.defaultValue = customVisible;
                            layerPolygonLine.visibleExpr.defaultValue = customVisible;
                            layerPolygonFill.visibleExpr.defaultValue = customVisible;
                            layerLabel.visibleExpr.defaultValue = customVisible;
                        }else {
                            layerPoint.visibleExpr.categories.set(categoryKey, customVisible);
                            layerLine.visibleExpr.categories.set(categoryKey, customVisible);
                            layerPolygonLine.visibleExpr.categories.set(categoryKey, customVisible);
                            layerPolygonFill.visibleExpr.categories.set(categoryKey, customVisible);
                            layerLabel.visibleExpr.categories.set(categoryKey, customVisible);
                        }
                    }

                });
            }
            IntStyleConverter.fillStructDefaultsValue(layerPoint);
            IntStyleConverter.fillStructDefaultsValue(layerLine);
            IntStyleConverter.fillStructDefaultsValue(layerPolygonLine);
            IntStyleConverter.fillStructDefaultsValue(layerPolygonFill);
            IntStyleConverter.fillStructDefaultsValue(layerLabel);

            res.push(IntStyleConverter.createLayer(layerPoint, catStyle.classify_by_field, options, "_point", "circle", ["Point", "MultiPoint"]));
            res.push(IntStyleConverter.createLayer(layerLine, catStyle.classify_by_field, options, "_polyline", "line", ["Polyline", "MultiPolyline"]));
            res.push(IntStyleConverter.createLayer(layerPolygonLine, catStyle.classify_by_field, options, "_polygonLine", "line", ["Polygon", "MultiPolygon"]));
            res.push(IntStyleConverter.createLayer(layerPolygonFill, catStyle.classify_by_field, options, "_polygonLill", "fill", ["Polygon", "MultiPolygon"]));
            res.push(IntStyleConverter.createLayer(layerLabel, catStyle.classify_by_field, options, "_label", "symbol"));

        }
        return res;
    }
    private static createLayer(ls: LayerStruct, source_column: string, options: IStyleGeneratorOptions, idPostfix: string, layerType: string, geometryTypes: string[] = null): Layer{
        let realSourceName: string = null;
        realSourceName = source_column;
        if (realSourceName != null) realSourceName = IntStyleConverter.getRealColumnName(realSourceName, options);
        let l: Layer = {
            id: options.layerNamePrefix??"" + idPostfix,
            type: layerType,
            paint: {},
            layout: {}
        };
        if (options.source) l.source = options.source;
        if (options.sourceLayer) l["source-layer"] = options.sourceLayer;
        l.filter = ["all" ];
        if (geometryTypes != null && geometryTypes.length > 0) l.filter.push(["in", ["geometry-type"], ["literal", geometryTypes]]);
        let t: any;
        t = IntStyleConverter.createExpressionCategory(ls.minZoomExpr, realSourceName);
        if (t != null) l.filter.push(['>=', ['zoom'], t]);
        t = IntStyleConverter.createExpressionCategory(ls.maxZoomExpr, realSourceName);
        if (t != null) l.filter.push(['<', ['zoom'], t]);
        t = IntStyleConverter.createExpressionCategory(ls.visibleExpr, realSourceName);
        if (t != null) l.filter.push(t);
        if (ls.minZoom != null){
            l.minzoom = ls.minZoom;
        }
        if (ls.maxZoom != null){
            l.maxzoom = ls.maxZoom;
        }
        let paintKeys = Utils.allKeys(ls.paintProperties);
        l.paint = {};
        paintKeys.forEach(k => {
            t = IntStyleConverter.createExpressionCategory(ls.paintProperties[k], realSourceName);
            if (t != null) (l.paint as any)[k] = t;
        });
        let layoutKeys = Utils.allKeys(ls.layoutProperties);
        l.layout = {};
        layoutKeys.forEach(k => {
            t = IntStyleConverter.createExpressionCategory(ls.layoutProperties[k], realSourceName);
            if (t != null) (l.layout as any)[k] = t;
        });
        let notVisible = (ls.visibleExpr.defaultValue == false && ls.visibleExpr.categories.size == 0) ||
            (ls.visibleExpr.defaultValue == null && ls.visibleExpr.categories.size == 0 && layoutKeys.length == 0 && paintKeys.length == 0);
        if (notVisible){
            l.layout.visibility = "none";
        }
        return l;
    }
    private static fillStructDefaultsValue(struct: LayerStruct){
        if (struct.visibleExpr.defaultValue == null) struct.visibleExpr.defaultValue = false;
        if (struct.visibleExpr.categories != null) struct.visibleExpr.categories.forEach((value, key) => {
            if (value == null) struct.visibleExpr.categories.set(key, false)
        });
        if (struct.maxZoomExpr.categories != null) struct.maxZoomExpr.categories.forEach((value, key) => {
            if (value == null) struct.maxZoomExpr.categories.set(key, StyleDefaultMaxZoom);
        });
        if (struct.maxZoomExpr.defaultValue == null) struct.maxZoomExpr.defaultValue = StyleDefaultMaxZoom;
        if (struct.minZoomExpr.categories != null) struct.minZoomExpr.categories.forEach((value, key) => {
            if (value == null) struct.minZoomExpr.categories.set(key, StyleDefaultMinZoom);
        });
        if (struct.minZoomExpr.defaultValue == null) struct.minZoomExpr.defaultValue = StyleDefaultMinZoom;
    }

    private static createExpressionCategory(e: PropertiesCategory, sourceColumn: string){
        if (e.categories.size == 0 || sourceColumn == null) return e.defaultValue;

        let firstValue: any;//смотрим уникальные значения
        let i = 0;
        let eq = true;
        e.categories.forEach((value, key)=>{
            if (i == 0) firstValue = value;
            else{
                if (firstValue !== value) eq = false;
            }
            i++;
        });
        if (eq && firstValue === e.defaultValue){//если всё одинаковое, то ставим просто дефолтовое значение
            return e.defaultValue;
        }

        let r: any[] = ['match', ['get', sourceColumn]];
        //для key=null

        let hasNull = false;
        let cnt = 0;
        e.categories.forEach((value, key)=>{
           if (key === null) {hasNull = true; return;}
           r.push(key);
           r.push(value);
           cnt++;
        });
        if (cnt > 0) {
            r.push(e.defaultValue??null);
        }else {//нет вариантов, оставляем просто дефолтовое значение без 'match'
            r = e.defaultValue??null;
        }

        if (hasNull){
            let nullValue: any;
            nullValue = e.categories.get(null);
            r = ["case", ['==', ['get', sourceColumn], null], nullValue, r];
        }
        return r;
    }

    private static browserCategory(style: IStyleItemCategorized, func: (paint: IPaint, categoryKey?: any)=>void){
        let usePaint = {};
        if (style.paint){
            usePaint = Utils.deepCloneOverride(usePaint, style.paint);
        }
        if (style.categories) {
            style.categories.forEach(cq => {
                if (cq.value !== undefined) {
                    let st: IPaint = Utils.deepCloneOverride(usePaint, cq.paint)
                    func(st, cq.value);
                }
            });
        }
        //дефолтовое значение
        let def:IStyleCategorized_category = null;
        if (style.categories) def = style.categories.find(cq => cq.value === undefined);
        if (def){
            let st: IPaint = Utils.deepCloneOverride(usePaint, def.paint)
            func(st);
        }else func(usePaint);
    }
    private static browserBase(style: IStyleItemCategorized, ls: LayerStruct, defStyle: IPaintGeometry, baseStyle: IPaintGeometry, func: (c: IStyleCategorized_category)=> IPaintGeometry){
        let defVisible = defStyle?.visible;
        if (isBoolean(style.visible)) defVisible = style.visible;
        if (isBoolean(baseStyle?.visible)) defVisible = baseStyle.visible;
        if (ls.visibleExpr.defaultValue == null && defVisible != null) ls.visibleExpr.defaultValue = defVisible;
        if (style.categories) style.categories.forEach(cq => {
            let v = defVisible;
            if (cq.value !== undefined) {
                if (isBoolean(cq.visible)) v = cq.visible;
                let pg: IPaintGeometry = func(cq);
                if (isBoolean(pg?.visible)) v = pg?.visible;
                if (v != null && ls.visibleExpr.categories.get(cq.value) == null)
                    ls.visibleExpr.categories.set(cq.value, v);
            }else {
                if (isBoolean(cq.visible)) v = cq.visible;
                if (v != null && ls.visibleExpr.defaultValue == null)
                    ls.visibleExpr.defaultValue = v;
            }
        });


        let defZoom: number = defStyle?.minZoom;
        if (style.minZoom){
            defZoom = style.minZoom;
            ls.minZoom = defZoom;
        }
        if (isBoolean(baseStyle?.minZoom)) defZoom = baseStyle.minZoom;
        if (ls.minZoomExpr.defaultValue == null && defVisible != null) ls.minZoomExpr.defaultValue = defZoom;
        if (style.categories) style.categories.forEach(cq => {
            let v = defZoom;
            if (cq.value != undefined) {
                if (cq.minZoom != null) v = cq.minZoom;
                let pg: IPaintGeometry = func(cq);
                if (isNumber(pg?.minZoom)) v = pg?.minZoom;

                if (cq.minZoom === null) v = defStyle.minZoom;
                if (v != null) ls.minZoomExpr.categories.set(cq.value, v);
            }else{
                if (cq.minZoom != null) v = cq.minZoom;
                if (v != null) ls.minZoomExpr.defaultValue = v;
            }
        });

        defZoom = defStyle?.maxZoom;
        if (style.maxZoom) {
            defZoom = style.maxZoom;
            ls.maxZoom = defZoom;
        }
        if (isBoolean(baseStyle?.maxZoom)) defZoom = baseStyle.maxZoom;
        if (ls.maxZoomExpr.defaultValue == null && defVisible != null) ls.maxZoomExpr.defaultValue = defZoom;
        if (style.categories) style.categories.forEach(cq => {
            let v = defZoom;
            if (cq.value != undefined) {
                if (cq.maxZoom) v = cq.maxZoom;
                let pg: IPaintGeometry = func(cq);
                if (isNumber(pg?.maxZoom)) v = pg?.maxZoom;
                if (cq.maxZoom === null) v = defStyle.maxZoom;
                if (v != null) ls.maxZoomExpr.categories.set(cq.value, v);
            }else{
                if (cq.maxZoom) v = cq.maxZoom;
                if (v != null) ls.maxZoomExpr.defaultValue = v;
            }
        });

    }


    //Добавляем свойство
    private static addProp(propStore: IDictonaryType<PropertiesCategory>, propName: string, key: any, value: any, defValue: any, style: IPaintPoint, struct: LayerStruct){
        if (propStore[propName] == null){
            propStore[propName] = new PropertiesCategory();
        }
        let t = propStore[propName];
        let setVisible = true;
        if (value == null){
            value = defValue;
            setVisible = false;
        }
        if (key === undefined) {
            t.defaultValue = value;
        }else t.categories.set(key, value);
        if (t.defaultValue == null && defValue != null) t.defaultValue = defValue;

        if (setVisible && style.visible == null) {
            if (key === undefined) {
                struct.visibleExpr.defaultValue = true;
            }else struct.visibleExpr.categories.set(key, true);
        }
    }
    private static pointProperties(key: any, style: IPaintPoint, defStyle: IPaintPoint, struct: LayerStruct){
        let propStore = struct.paintProperties;
        IntStyleConverter.addProp(propStore, "circle-color", key, style?.color, defStyle.color, style, struct);
        IntStyleConverter.addProp(propStore, "circle-radius", key, (style?.size == null)?style?.size: style.size / 2.0, defStyle.size??0 / 2.0,  style, struct);
        IntStyleConverter.addProp(propStore, "circle-opacity", key, style?.opacity, defStyle.opacity, style, struct);
        IntStyleConverter.addProp(propStore, "circle-stroke-color", key, (style == null)?undefined:style["stroke-color"], defStyle["stroke-color"], style, struct);
        IntStyleConverter.addProp(propStore, "circle-stroke-width", key, (style == null)?undefined:style["stroke-width"], defStyle["stroke-width"], style, struct);
    }
    private static labelProperties(key: any, style: IPaintLabel, defStyle: IPaintLabel, struct: LayerStruct){
        let propStore = struct.paintProperties;
        IntStyleConverter.addProp(propStore, "text-color", key, style?.color, defStyle.color, style, struct);
        IntStyleConverter.addProp(propStore, "text-opacity", key, style?.opacity, defStyle.opacity, style, struct);
        IntStyleConverter.addProp(propStore, "text-halo-color", key, (style == null)?undefined:style["halo-color"], defStyle["halo-color"], style, struct);
        IntStyleConverter.addProp(propStore, "text-halo-width", key, (style == null)?undefined:style["halo-width"], defStyle["halo-width"], style, struct);
    }
    private static labelPropertiesLayout(key: any, style: IPaintLabel, defStyle: IPaintLabel, struct: LayerStruct, options: IStyleGeneratorOptions){
        let propStore = struct.layoutProperties;
        if (style?.size != null) IntStyleConverter.addProp(propStore, "text-size", key, style.size, defStyle.size,  style, struct);
        if (style?.fontName != null) {
            let v = style.fontName;
            //IntStyleConverter.addProp(propStore, "text-font", key, [["to-string",["zoom"]]], true);
            if (isArray(v)) IntStyleConverter.addProp(propStore, "text-font", key, ["literal",style.fontName], ["literal", defStyle.fontName], style, struct);
            else IntStyleConverter.addProp(propStore, "text-font", key, ["literal", [style.fontName]], ["literal", [defStyle.fontName]], style, struct);

        }
        if (style?.text != null) {
            let text = style.text;
            let value: any;
            if (text == "") value = "";else {
                let subs = TextUtils.extractSubstringsSquareBrackets(text);
                let arr: any[] = ["concat"];
                subs.forEach(s =>{
                    if (s.startsWith("[") && s.endsWith("]")){
                        let column_name = s.substring(1, s.length - 1);
                        column_name = IntStyleConverter.getRealColumnName(column_name, options);
                        arr.push(['to-string',['get', column_name]]);
                    }else{
                        arr.push(s);
                    }
                })
                value = arr;
            }
            if (value != "" && value != null) {
                IntStyleConverter.addProp(propStore, "text-field", key, value, "", style, struct);
            }else {
                if (key == undefined)
                    IntStyleConverter.addProp(propStore, "text-field", key, "", "", style, struct);}
        }
    }
    static getRealColumnName(name: string, options: IStyleGeneratorOptions): string{
        if (options?.columns){
            let v1 = options?.columns.find(a => a.name == name);
            if (v1 != null) return name;
            let v2 = options?.columns.find(a => {
                if (a.aliases == null) return false;
                for (let key in a.aliases) {
                    if (a.aliases[key] == name) return true;
                }
            });
            if (v2 != null) return v2.name;
        }
        return name;
    }
//    private static setDefaultV

    private static lineProperties(key: any, style: IPaintPolyline, defStyle: IPaintPolyline, struct: LayerStruct){
        let propStore = struct.paintProperties;
        IntStyleConverter.addProp(propStore, "line-color", key, style?.color, defStyle.color, style, struct);
        IntStyleConverter.addProp(propStore, "line-width", key, style?.width, defStyle.width, style, struct);
        IntStyleConverter.addProp(propStore, "line-opacity", key, style?.opacity, defStyle.opacity, style, struct);
    }
    private static fillProperties(key: any, style: IPaintFill, defStyle: IPaintFill, struct: LayerStruct){
        if (style == null) return;
        let propStore = struct.paintProperties;
        IntStyleConverter.addProp(propStore, "fill-color", key, style?.color, defStyle.color, style, struct);
        IntStyleConverter.addProp(propStore, "fill-opacity", key, style?.opacity, defStyle.opacity, style, struct);
    }

}