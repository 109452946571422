import {CustomStore} from "../CustomStore";
import {v4} from "uuid";
import {save} from "../PermalinkDecor";
import {computed, observable} from "mobx";
import {PointStore} from "../MapStore";
import {Geometry} from "geojson";
import {IndexByPointYearStore} from "./IndexByPointYearStore";
import {IndexByPointGraphStore} from "./IndexByPointGraphStore";
import {IndexByPointerStore, IndexDataType} from "./IndexByPointerStore";
import { IndexByPointerSourceSimplePrecipMeteoStationStore } from "./sources/IndexByPointerSourceSimplePrecipMeteoStationStore";
import { Utils } from "../../helper/utils/Utils";
import { IndexByPointerSourceSimpleTempMeteoStationStore } from "./sources/IndexByPointerSourceSimpleTempMeteoStationStore";
import { MeteoStationInfo } from "./sources/MeteoStationInfo";
import { IndexByPointerSourceSimpleSoilMoistureMeteoStationStore } from "./sources/IndexByPointerSourceSimpleSoilMoistureMeteoStationStore";

export enum IndexAreaType{ point, field}

export class IndexByPointGraphPointStore extends CustomStore<IndexByPointerStore>{
    constructor(parent: IndexByPointerStore) {
        super(parent);
        this.id = v4();
    }
    class(): string {return "IndexByPointGraphPointStore";}
    @save @observable
    id: string;//не меняется
    @save @observable
    point: PointStore = new PointStore(this);
    //кэш геометрии поля
    fieldGeometry: Geometry = null;
    @save @observable
    num: number;
    @save @observable
    description: string = "";

    @save @observable
    areaType: IndexAreaType = IndexAreaType.point;
    @save @observable
    field_id: number = null;
    @save @observable
    field_name: string = "";

    @computed
    get pointName(): string {
        let gs = this.graphs;
        let si: MeteoStationInfo = null;
        for (let i = 0; i < gs.length; i++)
        {
            let dt = gs[i].dataType;
            if ([IndexDataType.precipitationMeteoStation, IndexDataType.temperatureMeteoStation, IndexDataType.soilMoistureMeteoStation].indexOf(dt) >= 0) {
                si = (gs[i].source as IndexByPointerSourceSimplePrecipMeteoStationStore | 
                    IndexByPointerSourceSimpleTempMeteoStationStore | IndexByPointerSourceSimpleSoilMoistureMeteoStationStore).meteoStation;
                break;
            }
        }
        if (!si)
            return this.num.toString();
        let tr = this.root.trans;
        let [dist, unit] = si.dist_m > 1000? [Utils.roundDigitsWithSpace(si.dist_m / 1000, 1), tr['km']]: 
            [Utils.roundDigitsWithSpace(si.dist_m, 0), tr['m']];
        return `${this.num} - ${si.name} (${dist} ${unit})`;
    }

    @computed
    get graphs(): IndexByPointGraphStore[]{
        return this.root.indexByPointer.graphs.filter(p => this.id == p.idPoint);
    }
    get yearPoints(): IndexByPointYearStore[]{
        return this.root.indexByPointer.yearPoints.filter(a => a.idPoint == this.id);
    }

}
