import {CircleLayout, CirclePaint, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {action} from "mobx";
import {ShowRoom2LayerPointCustomTool} from "./ShowRoom2LayerPointCustomTool";
import {ShowroomLayerType} from "../../dataShowroom2/ShowRoom2LayerStore";


export class ShowRoom2LayerPointCceTool extends ShowRoom2LayerPointCustomTool{

    layerIdFill(): string{
        return this.getLayerId()+"_Fill";
    }

    @action
    loadPopupById(id: any):void{
        this.store.dataShowRoomStore.mapPopup.showRoom2LayerName = ShowroomLayerType.ccePoints;
        this.store.dataShowRoomStore.mapPopup.loadCCE(id as any);
    }

    getSvgStr(): string{
        let svg = '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<rect width="10" height="10" rx="2" fill="white"/>\n' +
            '<rect x="1.66675" y="1.66666" width="6.66667" height="6.66667" rx="1" fill="{{color}}"/>\n' +
            '</svg>\n';
        return svg;
    }
    async addLayers() {
        let svg = this.getSvgStr();
        let map = this.store.map.mapbox;
        let tileUrl = `${window.location.origin}/reports/cce/public.get_cce/{z}/{x}/{y}.pbf?filter=${JSON.stringify(this.store.dataShowRoomStore.getFilters())}`;
        let icon = await this.generateSprites(svg);
        let circlePaint = this.generateColorStyle();

        if (!map.getSource(this.getSourceId())){
            map.addSource(this.getSourceId(),{
                type: 'vector',
                tiles: [tileUrl],
                scheme: "xyz",
            });
        }
        //MBUtils.getNewColor


        if (!map.getLayer(this.layerHeat())) {
            this.addLayer({
                id: this.layerHeat(),
                source: this.getSourceId(),
                "source-layer": 'points',
                type: 'circle',
                maxzoom: 12,
                layout: <CircleLayout>{
                    //'icon-image': icon,
                    //"icon-allow-overlap": true,
                },
                paint: <CirclePaint>{
                    "circle-color": circlePaint,
                    "circle-blur": this.store.debugStore.circleBlure??0,
                    "circle-opacity":this.store.debugStore.circleOpacity??0,
                    "circle-radius": this.store.debugStore.circleRadius??0,
                }
            });
        }

        if (!map.getLayer(this.layerIdPoints())){
            this.addLayer({
                id: this.layerIdPoints(),
                source: this.getSourceId(),
                "source-layer": 'points',
                type: 'symbol',
                minzoom: 11,
                layout: <SymbolLayout>{
                    'icon-image': icon,
                    "icon-allow-overlap": true,
                },
                paint:<SymbolPaint>{
                }
            });
        }
    }


}