import {isFunction} from "lodash-es";
import {ClassFactory, ClassValueUpdateType} from "./ClassFactory";

export function save(target: object, propertyKey: string): void {
    ClassFactory.registerProperty(target, propertyKey, ClassValueUpdateType.save);
}

export function сhangesStore(target: object, propertyKey: string): any {
    let descr: PropertyDescriptor = null;
    if (arguments.length > 2) descr = arguments[2];// Object.getOwnPropertyDescriptor(target, propertyKey);

    let value: any = null;


    const getter = function() {
        if (descr && isFunction(descr.get)) return descr.get();
        return value;
    };
    const setter = function(newVal: any) {
        //(target as any).setChangeProperty(propertyKey);
        if (descr && isFunction(descr.set)) return descr.set(newVal);
        value = newVal;
    };

    return {
        ...descr,
            get: getter,
            set: setter,
    };

}


export function update(target: object, propertyKey: string): void {
    ClassFactory.registerProperty(target, propertyKey, ClassValueUpdateType.update);
}

