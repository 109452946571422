import * as React from 'react';
import classNames from "classnames";
import './AgroAhoTableUploadItem.scss'
import {AhoUploadTableColType, IFert, UploadTableColumn} from "../../agroAhoUploadTableStore";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoOkSvg} from "../icons/AgroAhoOkSvg";
import {IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {action, observable} from "mobx";
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {AgroAhoDropDownComp, IAgroAhoDropDownItem} from "./AgroAhoDropDownComp";
import {AgroAhoErrorSvg} from "../icons/AgroAhoErrorSvg";
import {AhoUtils} from "../../agroAhoUtils";
import {Feature} from "geojson";
import {TextUtils} from "../../../../app/helper/utils/TextUtils";
import Tooltip from 'rc-tooltip';


export interface IAgroAhoTableUploadItemProps {
    store: IAgroAhoSuperStore;
    column: UploadTableColumn
}

@observer
export class AgroAhoTableUploadItem extends React.PureComponent<IAgroAhoTableUploadItemProps, undefined> {
    constructor(props: IAgroAhoTableUploadItemProps) {
        super(props);
        autoBindReact(this);
    }

    item_class: string = 'AgroAhoTableUploadItem-typeItem';
    @observable
    inFocus: boolean = false;

    onTooltipVisibleChange(inFocus: boolean){
        this.inFocus = inFocus;
    }

    @action
    onTypesChange(item: IDropDownItem, newIndex: number){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        let col = this.props.column;
        ustore.step4HideDuplicates(col);
        col.data_type = item.key as AhoUploadTableColType;
        col.indc_error = col.fert_error = col.crop_error = col.yield_error = false;
        ustore.updStep4NextButton();
    }

    @action
    onIndcChange(item: IDropDownItem, newIndex: number){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        let col = this.props.column;
        ustore.step4HideDuplicates(col);
        col.indc_code = item.data.lower_code;
        col.indc_error = false;
        ustore.updStep4NextButton();
    }

    @action
    onYieldChange(e: any){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        let col = this.props.column;
        ustore.step4HideDuplicates(col);
        col.yield = e.currentTarget.value;
        col.yield_error = false;
        ustore.updStep4NextButton();
    }

    @action
    onFertsChange(item: IDropDownItem, newIndex: number){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        let col = this.props.column;
        ustore.step4HideDuplicates(col);
        col.fert_id = item.data.values?.fert_id;
        col.fert_error = false;
        ustore.updStep4NextButton();
    }

    @action
    onCropsChange(item: IDropDownItem, newIndex: number){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        let col = this.props.column;
        ustore.step4HideDuplicates(col);
        col.crop_id = item.data.values?.crop_id;
        col.crop_error = false;
        ustore.updStep4NextButton();
    }

    getFertSections(){
        let store_ = this.props.store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let ferts: IAgroAhoDropDownItem[] = [];
        let simple: IAgroAhoDropDownItem[] = [];
        let complex: IAgroAhoDropDownItem[] = [];
        ustore.ferts.forEach(f=>{
            if (f.values.is_rn) {
                let item = {key: f.values.fert_id, class: this.item_class, value: f.values.fert_name , data: f};
                if (f.values.fert_info.type == 'simple') simple.push(item);
                if (f.values.fert_info.type == 'complex') complex.push(item);
            }
        });
        simple = simple.sort((a, b)=>TextUtils.stringSortCompare(a.value, b.value));
        complex = complex.sort((a, b)=>TextUtils.stringSortCompare(a.value, b.value));
        ferts = ferts.concat(
            [{key: -2, class: this.item_class, value: store_.trans['Active substance (as)'] , title: true}],
            simple,
            [{key: -3, class: this.item_class, value: store_.trans['Fertilizers in phys. wt'] , title: true}],
            complex
        );
        return ferts;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let pstore = store_.projStore;
        let ustore = store_.uploadTableStore;
        let col = this.props.column;

        let class_ = this.item_class;
        let types: IDropDownItem[] = [
            {key: 0, data: 0, value: '', class: class_},
            {key: 1, data: 1, value: store_.trans['Indicator'], class: class_}
        ];
        types.push({key: 2, data: 2, value: store_.trans['Recom. norm'],
            childNode: <span className="AgroAhoTableUploadItem-typeCover">
                    <span className="AgroAhoTableUploadItem-typeText">{store_.trans['Recom. norm']}</span>
                    <span className="AgroAhoTableUploadItem-typeUnit">{store_.trans['kg/ha']}</span>
            </span>, class: class_
        });
        let indcs: IDropDownItem[] = pstore.indicators.map(i=>{
            return {key: i.lower_code, class: class_, value: i.indc_name, data: i,
                childNode: <span className="AgroAhoTableUploadItem-typeCover">
                    <span className="AgroAhoTableUploadItem-indcText">{i.indc_name}</span>
                    <span className="AgroAhoTableUploadItem-indcUnit">{i.unit}</span>
                    <span className="AgroAhoTableUploadItem-indcStand">{i.standard}</span>
                </span>}
        });
        indcs.unshift({key: '', data: 0, value: '', class: class_});
        let ferts = this.getFertSections();
        // let ferts: IAgroAhoDropDownItem[] = ferts_.map(f=>{
        //     return {key: f.values.fert_id, class: class_, value: f.values.fert_name , data: f}
        // });
        ferts.unshift({key: -1, data: 0, value: '', class: class_});
        // console.log('ferts:', AhoUtils.cp(ferts));
        // ferts.unshift({key: -2, data: 0, value: 'Действующее вещ.-во (дв)', class: class_, title: true});
        let crops: IDropDownItem[] = ustore.crops.filter(c=>c?.values?.is_rn).map(c=>{
            return {key: c.values.crop_id, class: class_, value: c.values.crop_name , data: c}
        });
        crops.unshift({key: -1, data: 0, value: '', class: class_});
        let isError = col.indc_error || col.fert_error || col.yield_error;
        // let isOk = !!col.data_type && (!isError || (isError && !ustore.columnCheckMode));
        let isOk = ustore.step4IsOk(col) && !col.duplicates;
        let isProbeNum = col == ustore.curIdsColumn;
        let samples = col.sample?.map(s=>s.value);
        let samples_ = samples.filter(v=>!AhoUtils.isNum(v));
        if (samples_.length) samples = samples_;

        // console.log('col', col.title, ustore.step4IsOk(col), !col.duplicates);
        let html = <div className="AgroAhoTableUploadItem-descCover">
            <div className="AgroAhoTableUploadItem-descTitle">{store_.trans['Examples of meanings']}:</div>
            <div className="AgroAhoTableUploadItem-descMsg">{'"'+samples.join('", "')+'"' || ''}</div>
        </div>;


        return <div className={classNames("AgroAhoTableUploadItem-main", {
            "AgroAhoTableUploadItem-checkOk": isOk,
            "AgroAhoTableUploadItem-duplicates": col.duplicates && ustore.columnCheckMode })}>
            <div className="AgroAhoTableUploadItem-colIsLoading">
                {isOk && <AgroAhoOkSvg/>}
                {!!col.data_type && isError && ustore.columnCheckMode &&
                    <AgroAhoErrorSvg className="AgroAhoTableUploadItem-errSvg"/>}
                {col.duplicates && ustore.columnCheckMode &&
                    <AgroAhoErrorSvg className="AgroAhoTableUploadItem-duplicatesSvg"/>}
            </div>
            <div className="AgroAhoTableUploadItem-colTitle">{col.title}</div>
            <div className="AgroAhoTableUploadItem-colValues">{col.cnt}</div>
            {col.no_numbers && <React.Fragment>
                <div className="AgroAhoTableUploadItem-typeColMsg">{store_.trans['There are non-numeric values']}</div>
                <Tooltip placement="right"
                         mouseEnterDelay={0.5}
                         overlayClassName={classNames("AgroAhoTableUploadItem-tooltipOverlay",{
                             "AgroAhoTableUploadItem-inFocus": this.inFocus})}
                         onVisibleChange={this.onTooltipVisibleChange}
                         overlay={html}>
                    <svg className="AgroAhoTableUploadItem-tooltip" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 15.75C5.2875 15.75 2.25 12.7125 2.25 9C2.25 5.2875 5.2875 2.25 9 2.25C12.7125 2.25 15.75 5.2875 15.75 9C15.75 12.7125 12.7125 15.75 9 15.75Z"/>
                        <path d="M10.125 7.875H7.875V13.5H10.125V7.875Z"/>
                        <path d="M9 6.75C9.62132 6.75 10.125 6.24632 10.125 5.625C10.125 5.00368 9.62132 4.5 9 4.5C8.37868 4.5 7.875 5.00368 7.875 5.625C7.875 6.24632 8.37868 6.75 9 6.75Z"/>
                    </svg>
                </Tooltip>
            </React.Fragment>}
            {!col.no_numbers && isProbeNum &&
                <div className="AgroAhoTableUploadItem-typeColMsg">{store_.trans['Column with sample numbers']}</div>}
            {!col.no_numbers && !isProbeNum && <React.Fragment>
                <div className="AgroAhoTableUploadItem-colDataType">
                    <AgroAhoDropDownComp items={types} onChange={this.onTypesChange} currentKey={col.data_type}
                                         className="AgroAhoTableUploadItem-select"
                                         emptyClass="AgroAhoTableUploadItem-colDataTypeEmpty"
                                         emptyText={'--- ' + store_.trans['Not set'] + ' ---'}/>
                </div>
                <div className="AgroAhoTableUploadItem-colMatching">
                    {col.data_type == AhoUploadTableColType.Indc && <React.Fragment>
                        <AgroAhoDropDownComp items={indcs} onChange={this.onIndcChange} currentKey={col.indc_code}
                                             className={classNames("AgroAhoTableUploadItem-selectIndc",
                                                 {"AgroAhoTableUploadItem-error": col.indc_error && ustore.columnCheckMode})}
                                             emptyClass="AgroAhoTableUploadItem-colDataTypeEmpty"
                                             emptyText={'--- ' + store_.trans['Empty field'] + ' ---'}/>
                    </React.Fragment>}
                    {col.data_type == AhoUploadTableColType.RecNorm && <React.Fragment>
                        <AgroAhoDropDownComp items={ferts} onChange={this.onFertsChange} currentKey={col.fert_id}
                                             className={classNames("AgroAhoTableUploadItem-selectFert",
                                                 {"AgroAhoTableUploadItem-error": col.fert_error && ustore.columnCheckMode})}
                                             emptyClass="AgroAhoTableUploadItem-colDataTypeEmpty"
                                             emptyText={'--- ' + store_.trans['Empty field'] + ' ---'}
                                             noSearch={true} />
                        <div className="AgroAhoTableUploadItem-yieldsTitle">{store_.trans.for}</div>
                        <AgroAhoDropDownComp items={crops} onChange={this.onCropsChange} currentKey={col.crop_id}
                                             className={classNames("AgroAhoTableUploadItem-selectCrop",
                                                 {"AgroAhoTableUploadItem-error": col.crop_error && ustore.columnCheckMode})}
                                             emptyClass="AgroAhoTableUploadItem-colDataTypeEmpty"
                                             emptyText={'--- ' + store_.trans['Empty field'] + ' ---'}/>
                        <div className="AgroAhoTableUploadItem-yieldsTitle">{store_.trans.yields}</div>
                        <div className={classNames("AgroAhoTableUploadItem-yieldsCover",
                            {"AgroAhoTableUploadItem-error": col.yield_error && ustore.columnCheckMode})}>
                            <input type="text" className="AgroAhoTableUploadItem-yieldsInput"
                                   value={col.yield || ''} onChange={this.onYieldChange}/>
                            <div className="AgroAhoTableUploadItem-yieldsUnit">{store_.trans['c/ha']}</div>
                        </div>
                    </React.Fragment>}
                </div>
            </React.Fragment>}
        </div>
    }
}
