import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoRecNormComp.scss'
import classNames from "classnames";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoRecNormItemComp} from "./AgroAhoRecNormItemComp";
import {AgroAhoTriangleSvg} from "../icons/AgroAhoTriangleSvg";
import {RNGroup} from "../../card/agroAhoCardRN";



export interface IAgroAhoRecNormCompProps {
    store: IAgroAhoSuperStore;
    rng: RNGroup;
}

@observer
export class AgroAhoRecNormComp extends React.PureComponent<IAgroAhoRecNormCompProps, undefined> {
    constructor(props: IAgroAhoRecNormCompProps) {
        super(props);
        autoBindReact(this);
    }

    onExpandClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.cardRNStore.doRNGroupExpandClick(this.props.rng);
        // console.log('expand click', this.props.rng.group_id);
    }

    onDelClick(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.cardRNStore.onDeleteRecNorm();
        // console.log('del', this.props.rng.group_id);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let rng = this.props.rng;

        return <div className="AgroAhoRecNormComp-main" onClick={this.onExpandClick}>
            <div className="AgroAhoRecNormComp-header">
                <AgroAhoTriangleSvg className="AgroAhoRecNormComp-triangle" expand={rng.expand} />
                <div className="AgroAhoRecNormComp-name">{ustore.getCropName(rng.items[0].crop_id)}</div>
                <div className="AgroAhoRecNormComp-stat">&nbsp;—&nbsp;{rng.items[0].centner_ha} {
                    store_.trans['c/ha']}</div>
                <div className="AgroAhoRecNormComp-splitter"/>
                {/*<AgroAhoDel3Svg className="AgroAhoRecNormComp-del" onClick={this.onDelClick}/>*/}
            </div>
            <div className={classNames("AgroAhoRecNormComp-items",{
                "AgroAhoRecNormComp-hidden": !rng.expand})}>
                {rng.items.map(rn=><AgroAhoRecNormItemComp key={rn.fert_id} store={store} rn={rn}/>)}
            </div>

        </div>
    }
}
