import {observer} from "mobx-react";
import * as React from "react";
import autoBindReact from "auto-bind/react";
import './CheckBoxInFrame.scss';
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";
import classNames from "classnames";

interface ICheckBoxInFrame {
    state: boolean;
    className?: string;
    onChange?: ()=>void;
}
@observer
export class CheckBoxInFrame  extends React.Component<ICheckBoxInFrame, undefined> {
    constructor(props: ICheckBoxInFrame) {
        super(props);
        autoBindReact(this);
    }
    onClick(){
        if (this.props.onChange) this.props.onChange();
    }
    render(){
        return <div className={classNames("CheckBoxInFrame", this.props.className,  {"active": this.props.state})}>
            <CheckboxMiniComp state={this.props.state} size={CheckboxSize.switcher} onClick={this.onClick} classesContainer="pointer" >
                {this.props.children}
            </CheckboxMiniComp>
        </div>
    }
}