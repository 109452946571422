import {AnyLayer, AnySourceData} from "maplibre-gl";

export enum MapItemType{
    layer = "layer",
    folder = "folder"
}

export interface IConfigMap{
    rootFolder: IConfigMapFolderItem;
    sources: IConfigMapSource[];
}
export interface IConfigMapCustomItem{
    itemId?: string;
    visible: boolean;
    title: string;
    itemType?: MapItemType;
}
export interface IConfigMapLayerItem extends IConfigMapCustomItem{
    sourceId: string;
    sourceLayer: string;
    sublayers: IConfigMapLayerNative[];
}
export interface IConfigMapFolderItem extends IConfigMapCustomItem{
    items: (IConfigMapCustomItem | IConfigMapFolderItem | IConfigMapLayerItem)[];
}
export interface IConfigMapSource{
    sourceId?: string;
    nativeSource: AnySourceData;
}
export interface IConfigMapLayerNative{
    layerId?: string;
    nativeLayer: AnyLayer;
}
