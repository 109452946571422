import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {TrashSvg} from "../../../icons/Fav/TrashSvg";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {MyHint} from "../../../Common/MyHint";

@observer
export class FavTopMenuComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickSelectAll(){
        let store = this.props.store;
        store.searchPanel.favoriteList.inversSelectAll();
    }

    @action
    onClickDelete(){
        let store = this.props.store;
        if (store.searchPanel.favoriteList.groups.length == 0) return;
        store.searchPanel.showDeleteAllScene = true;
        //store.searchPanel.favoriteList.groups.length = 0;
    }

    render() {
        let store = this.props.store;
        return <div className="left-sidebar__favitem__topmenu">
            <div className="left-sidebar__favitem__topmenu__selectAll_column">
                <input type="checkbox" name="checkboxSeleactAll" id="checkboxSeleactAll" className="css-checkbox-1"
                       checked={store.searchPanel.favoriteList.isSelectedAll()} onChange={this.onClickSelectAll}/><label
                htmlFor="checkboxSeleactAll" className="css-label-1 left-sidebar__favitem__topmenu__SelectAll_label">{store.trans["All visible"]}</label>
            </div>
            <MyHint text={store.trans["Remove all"]}>
                <div className={classNames("icon_container icon_container_fill icon_container_red relative",
                    {"disabled": store.searchPanel.favoriteList.groups.length == 0})}

                     onClick={this.onClickDelete}>
                    <TrashSvg />
                </div>
            </MyHint>
        </div>;
    }
}
