import {ISuperStore} from "../../pluginApi/store/SuperStore";
import autoBind from "auto-bind";
import {IRshbRepSuperStore} from "../rshbReport/rshbPlugin";
import React, {ReactElement} from "react";
import {IProductItemComp} from "../../app/components/panels/Left/NdviPanel/ProductItemComp";
import {IAgroExportSuperStore} from "./agroExportPlugin";
import {AgroExportProductComp} from "./components/AgroExportProductComp";
import {Utils} from "../../app/helper/utils/Utils";
import classNames from "classnames";
import {action} from "mobx";
import {LeftPanelMode} from "../../app/store/SearchStore";
import {VoronovExportWidgetComp} from "./components/voronov/VoronovExportWidgetComp";
import {VoronovSaveComp} from "./components/voronov/VoronovSaveComp";
import {VoronovMsgComp} from "./components/voronov/VoronovMsgComp";

export class AgroExportEvents {
    constructor(store: IAgroExportSuperStore) {
        autoBind(this);
        this.store = store;
    }
    store: IAgroExportSuperStore;

    @action
    onClickExportPlots(){
        this.store.agroExport.voronovExportStore.setExportPanel();
        this.store.agro2.agroButtonPopupVisible = false;
    }


    onMapChildren(children: ReactElement[]){
        if (this.store.searchPanel.leftPanelMode == LeftPanelMode.agro && this.store.agroExport.voronovExportStore.showVoronovWidget)
            children.push(<VoronovExportWidgetComp key="VoronovExportWidgetComp" store={this.store} />);

        if (this.store.agroExport.voronovExportStore.saveOptions.showSaveForm) children.push(<VoronovSaveComp store={this.store} key="VoronovSaveComp" />);
        if (this.store.agroExport.voronovExportStore.saveOptions.showMsgForm) children.push(<VoronovMsgComp store={this.store} key="VoronovMsgComp" />);

    }
    onNdviProductItemChildren(children: ReactElement[], arg: IProductItemComp){
        children.push(<AgroExportProductComp item={arg.item} productCode={arg.productCode} store={this.store} key={"AgroExportProductComp"} />);
    }
    onAgroFieldSelectedDropDownChildren(children: ReactElement[]){
        Utils.insertReactAfterKey(
        <React.Fragment key="proby">
            <div className={classNames("flex-columns flex-align-items-center A2FieldSelectedButton-popup-props-line")}
                 onClick={this.onClickExportPlots}>
                <div className="flex-stretch-item text-next">
                    Спланировать отбор проб
                </div>
            </div>
            <div className="A2FieldSelectedButton-popup-props-line-div" />
        </React.Fragment>, children, ["Selected objects to active area_div"]
        )
    }
}