import {CustomStore} from "./CustomStore";
import {action, observable} from "mobx";

export class CustomConfirmStore extends CustomStore{
    @observable
    show: boolean = false;

    @action
    doShow(){
        this.show = !this.show;
    }
    @action
    doCancel(){
        this.show = false;
    }
    @action
    doHide(){
        this.show = false;
    }
}