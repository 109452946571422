import autoBind from "auto-bind";
import {IAgroAhoSuperStore, LeftPanelModeAgroAho} from "./agroAhoPlugin";
import * as React from "react";
import {ReactElement} from "react";
import {AgroAhoPanelComp} from "./AgroAhoPanel/AgroAhoPanelComp";
import {MyHint} from "../../app/components/Common/MyHint";
import classNames from "classnames";
import {action} from "mobx";
import {AhoStage} from "./agroAhoStore";
import {AgroAhoLegendPanelComp} from "./AgroAhoPanel/map/AgroAhoLegendPanelComp";
import {AhoEditorMode} from "./agroAhoEditorStore";
import {AgroAhoEditorWaitPanelComp} from "./AgroAhoPanel/map/AgroAhoEditorWaitPanelComp";
import {AgroAhoEditorEditPanelComp} from "./AgroAhoPanel/map/AgroAhoEditorEditPanelComp";
import {AgroAhoFieldsPanelComp} from "./AgroAhoPanel/map/AgroAhoFieldsPanelComp";
import {AgroAhoCellsPanelComp} from "./AgroAhoPanel/map/AgroAhoCellsPanelComp";
import {AgroAhoSvg} from "./AgroAhoPanel/icons/AgroAhoSvg";
import {AgroAhoToursPanelComp} from "./AgroAhoPanel/map/AgroAhoToursPanelComp";
import {AhoUtils} from "./agroAhoUtils";
import {AgroAhoLegendRNPanelComp} from "./AgroAhoPanel/map/AgroAhoLegendRNPanelComp";
import {AgroAhoMapDebugComp} from "./map/debug/AgroAhoMapDebugComp";
import {AhoEditRouteMode} from "./map/agroAhoEditRoute";
import {AgroAhoEditRouteWaitPanelComp} from "./AgroAhoPanel/map/AgroAhoEditRouteWaitPanelComp";
import {AgroAhoEditRoutePanelComp} from "./AgroAhoPanel/map/AgroAhoEditRoutePanelComp";
import {AgroAhoSelCellsPanelComp} from "./AgroAhoPanel/map/AgroAhoSelCellsPanelComp";

export class AgroAhoEvents {
    constructor(store: IAgroAhoSuperStore) {
        autoBind(this);
        this.store = store;
    }
    store: IAgroAhoSuperStore;

    onLeftPanelChildrens(children: ReactElement[]): void{
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho)
            children.push(<AgroAhoPanelComp store={this.store} key="AgroAhoPanelComp" />);
    }
    onMapChildren(children: ReactElement[]){
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.mapStore.legendShow)
            children.push(<AgroAhoLegendPanelComp store={this.store} key="AgroAhoLegendPanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.mapStore.mapRNStore.legendRNShow &&
            this.store.agroAhoStore.cardStore.cardRNStore.hasRecNorm())
            children.push(<AgroAhoLegendRNPanelComp store={this.store} key="AgroAhoLegendRNPanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.editorStore.mode == AhoEditorMode.Select)
            children.push(<AgroAhoEditorWaitPanelComp store={this.store} key="AgroAhoEditorWaitPanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.editorStore.mode == AhoEditorMode.Edit)
            children.push(<AgroAhoEditorEditPanelComp store={this.store} key="AgroAhoEditorEditPanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.mapStore.mapEditRouteStore.mode == AhoEditRouteMode.Select)
            children.push(<AgroAhoEditRouteWaitPanelComp store={this.store} key="AgroAhoEditRouteWaitPanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.mapStore.mapEditRouteStore.mode == AhoEditRouteMode.Edit)
            children.push(<AgroAhoEditRoutePanelComp store={this.store} key="AgroAhoEditRoutePanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.ahoStage == AhoStage.Card &&
            this.store.agroAhoStore.cardStore.fieldsShow &&
            this.store.agroAhoStore.mapStore.layersShow)
            children.push(<AgroAhoFieldsPanelComp store={this.store} key="AgroAhoFieldsPanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.ahoStage == AhoStage.Card &&
            this.store.agroAhoStore.cardStore.cellsShow &&
            this.store.agroAhoStore.mapStore.layersShow)
            children.push(<AgroAhoCellsPanelComp store={this.store} key="AgroAhoCellsPanelComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.ahoStage == AhoStage.Card &&
            this.store.agroAhoStore.tourStore.toursShow)
            children.push(<AgroAhoToursPanelComp store={this.store} key="AgroAhoToursPanelComp" />);
        if (this.store.agroAhoStore.mapStore.mapDebugStore.debugMode &&
            this.store.agroAhoStore.mapStore.mapDebugStore.debugShow)
            children.push(<AgroAhoMapDebugComp store={this.store} key="AgroAhoMapDebugComp" />);
        if (this.store.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            this.store.agroAhoStore.stmtStore.selCellShow)
            children.push(<AgroAhoSelCellsPanelComp store={this.store} key="AgroAhoSelCellsPanelComp" />);


    }

    @action
    async onClickAgroAho(){
        // console.log('aho click', this.store.agroAhoStore.isActive);
        let store_ = this.store.agroAhoStore;
        let cstore = store_.cardStore;
        if (store_.isActive) {
            // if (this.store.agroAhoStore.ahoStage == AhoStage.Cards)
            store_.editorStore.resetFieldEditor();
            if (store_.ahoStage == AhoStage.Card && cstore.card) {
                cstore.hideIndc = false;
                await cstore.updFieldsInfo(true);
                await cstore.updCellsInfo();
            } else await store_.cardsStore.doLoadCards(true);
            if (store_.ahoStage == AhoStage.Loading) store_.ahoStage = AhoStage.Cards;
            this.store.searchPanel.switchPanel(LeftPanelModeAgroAho);
            store_.urlStore.updPermalink();
        }
    }
    onLeftSidebarLeftChildren(children: ReactElement[]){

        if (this.store.config.agroAhoShow) {
            let leftMode = this.store.searchPanel.leftPanelMode;
            children.push(<React.Fragment key={"agrometrika"}>
                {/*<div className="spacer-5"/>*/}
                <MyHint text={this.store.trans['Agri. Soil Analysis Data']} placement="right">
                    <div className={classNames("icon_container ", {"opacity30 def-cursor": !this.store.agroAhoStore.isActive,
                        "active": leftMode == LeftPanelModeAgroAho && this.store.agroAhoStore.isActive})}
                         onClick={this.onClickAgroAho}>
                        <AgroAhoSvg className={classNames({
                            "blue-fill": leftMode == LeftPanelModeAgroAho,
                            "white-fill": leftMode != LeftPanelModeAgroAho,
                            "animate__animated animate__bounceIn AgroAhoPanelComp-animateDouble": this.store.agroAhoStore.isSaved
                        })} />
                    </div>
                </MyHint>
            </React.Fragment>);

            for (let i = 1; i<3; i++) {
                children.push(<React.Fragment key={`splitter${i}`}>
                    {/*<div className="spacer-15"/>*/}
                    <div style={{background: "#6B707B", height: "1px", width: "28px", opacity: "0.5"}}> </div>
                    {/*<div className="spacer-10"/>*/}
                </React.Fragment>)
            }

            let sort_keys = ['logo', 'standard_space', 'agro', 'agrometrika', 'photo', 'splitter1', 'searchImage',
                'favorite', 'splitter2'];
            let children_: ReactElement[] = [];
            sort_keys.forEach((k: string)=>children_.push(children.filter(c=>c.key == k)[0]));
            children.splice(0, children.length);
            children_.forEach(el=>children.push(el));
        }
    }

}