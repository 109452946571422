import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoFieldsPanelComp.scss'
import classNames from "classnames";
import {action, observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoSortSvg} from "../icons/AgroAhoSortSvg";
import {AgroAhoWindowComp} from "../AgroAhoWindowComp";
import {AgroAhoCardFieldItemComp} from "../card/AgroAhoCardFieldItemComp";
import {AgroAhoMenuSvg} from "../icons/AgroAhoMenuSvg";
import {AhoCheckbox3StateType, AhoModalType} from "../../agroAhoStore";
import {AgroAhoDropDownComp} from "../uploadTable/AgroAhoDropDownComp";
import {IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AgroAhoCheckSvg} from "../icons/AgroAhoCheckSvg";
import {FieldSortType} from "../../card/agroAhoFields";
import {AgroAhoRectCheckbox3StateSvg} from "../AgroAhoRectCheckbox3StateSvg";
import {Utils} from "../../../../app/helper/utils/Utils";

@observer
export class AgroAhoFieldsPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    menuVisible: boolean = false;
    @observable
    alignBottom: boolean = false;
    @observable
    menuSortVisible: boolean = false;

    @action
    onClose(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.fieldsShow = false;
    }

    @action
    filterChanged(e:any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.doFilterChanged(e.target.value);
    }

    @action
    onSelectAllClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.doSelectAllClick();
    }

    onMenuClick(){
        let el = document.querySelector('.AgroAhoFieldsPanelComp-menuSvg');
        if (el) {  // смещаем меню вниз если недостаточно места справа
            let rect = el.getBoundingClientRect();
            let rightSpace = document.body.clientWidth - rect.x -rect.width;
            this.alignBottom = rightSpace < 250;
        }
        this.menuVisible = !this.menuVisible;
    }
    onMenuBlur(){
        this.menuVisible = false;
    }
    onSortMenuBlur(){
        this.menuSortVisible = false;
    }
    onMenuCopyClick(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        this.menuVisible = false;
        cstore.onFieldsCopyClick();
        e.stopPropagation();
        e.preventDefault();
    }
    onMenuDelClick(e: any){
        let store_ = this.props.store.agroAhoStore;
        this.menuVisible = false;
        store_.toggleModal(true, AhoModalType.DeleteFields);
        e.stopPropagation();
        e.preventDefault();
    }
    onSeasonChange(item: IDropDownItem){
        let cstore = this.props.store.agroAhoStore.cardStore;
        let fstore = cstore.cardFieldStore;
        fstore.curSeasonKey = item.key as number;
        cstore.doFilterChanged('');
        cstore.sortFields(cstore.sortDesc);
        fstore.updCropNames(item.key as number);
    }
    onSortMenuClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        let fstore = cstore.cardFieldStore;
        if (fstore.isCropExists()) {
            this.menuSortVisible = !this.menuSortVisible
        } else cstore.doSortFieldsClick();
    }
    onMenuSortByNameClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.sortCropFields(true);
    }
    onMenuSortByCropClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.sortCropFields(false);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let fstore = cstore.cardFieldStore;
        let cnt: any = 0;
        let selected = 0;
        let total = 0;
        if (cstore.fieldsItems) {
            cnt = cstore.fieldsItems.length;
            let selectedItems = cstore.fieldsItems.filter((f: any)=>f.checked);
            selected = selectedItems.length;
            // if (selected == cstore.fieldsItems.length) selected = 0;
            selectedItems.forEach(i=>{
                total += i.area_ha;
            });
        }
        total = total && parseFloat(total.toFixed(1));
        total = store_.numWithSpaces(total);
        // let area: any = cstore.card.field_info.sum_area_ha && parseFloat(cstore.card.field_info.sum_area_ha.toFixed(1));
        // area = store_.numWithSpaces(area);
        cnt = selected ? <span>{selected}&nbsp;/&nbsp;{cnt}</span> : cnt;
        let seasons_ = fstore.getSeasons();
        // console.log('seasons', seasons_, fstore.seasonsHistory);

        let seasons: IDropDownItem[] = seasons_.map(s=>{
            return {key: s.id, class: '', value: s.name, data: s};
        });
        seasons.unshift({key: -1, data: 0, value: store_.trans.Off, class: ''});
        let caption: any = <div className="AgroAhoFieldsPanelComp-caption">{store.trans.Season}:</div>;
        let sortByName = fstore.sortType == FieldSortType.ByName || fstore.sortType == FieldSortType.ByNameDesc;
        let placeHolder = fstore.curSeasonKey == -1 ? store_.trans['Search by field name'] :
            store_.trans['Search by field name and crop'];

        let found = '';
        if (cstore.searchFilter) {
            let found_ = cstore.fieldsItems.filter(f=>!f.filtered);
            let area = found_.reduce((sum, cur)=> sum + cur.area_ha, 0);
            found = `${store.trans.Found}: ${found_.length} ${store_.trans.pcs} / ${
                Utils.roundDigitsWithSpace(area,2)} ${store.trans.ha}`;
        }


        return <div className="AgroAhoFieldsPanelComp-main">
            <div className="AgroAhoFieldsPanelComp-closer" onClick={this.onClose}>
                <div className="arrow-left"/>
            </div>
            <div className="AgroAhoFieldsPanelComp-header">
                <div>{store.trans.Fields}</div>
                {/*<div className={classNames("AgroAhoFieldsPanelComp-stat",*/}
                {/*    {"AgroAhoFieldsPanelComp-hidden": !cstore.fieldsShow || !cstore.fieldsItems})}>*/}
                {/*    <span>{store_.trans.Fields}:&nbsp;{cnt}</span>*/}
                {/*</div>*/}
                <div className="AgroAhoFieldsPanelComp-splitter"/>
                <div className="AgroAhoFieldsPanelComp-headerCaption">{store_.trans['Crops per season']}</div>
                <AgroAhoDropDownComp items={seasons} onChange={this.onSeasonChange} currentKey={fstore.curSeasonKey}
                                     className="AgroAhoFieldsPanelComp-selectSeason"
                                     emptyClass="AgroAhoFieldsPanelComp-selectSeasonEmpty"/>

            </div>
            <input type="text" className="AgroAhoFieldsPanelComp-filter text-box-editor"
                value={cstore.searchFilter} placeholder={placeHolder} onChange={this.filterChanged}/>
            <div className="AgroAhoFieldsPanelComp-listHeader">
                <div className="AgroAhoFieldsPanelComp-checkboxCover" onClick={this.onSelectAllClick}>
                    <AgroAhoRectCheckbox3StateSvg className="AgroAhoFieldsPanelComp-selectCheckbox"
                        state={cstore.selectAll}/>
                    <div className="AgroAhoFieldsPanelComp-listCaption">{found}</div>
                </div>
                <div className="AgroAhoFieldsPanelComp-splitter"/>

                <AgroAhoWindowComp store={store} className="AgroAhoFieldsPanelComp-menuSortCover"
                    visible={this.menuSortVisible} onBlur={this.onSortMenuBlur} button={
                    <AgroAhoSortSvg className="AgroAhoFieldsPanelComp-sortSvg" desc={cstore.sortDesc}
                        onClick={this.onSortMenuClick}/> }>
                    <div className="AgroAhoFieldsPanelComp-menuSortTitle">{store_.trans['Sort by']}</div>
                    <div className="AgroAhoFieldsPanelComp-menuSortItem" onClick={this.onMenuSortByNameClick}>
                        <AgroAhoCheckSvg className={classNames("AgroAhoFieldsPanelComp-menuSortCheck",{
                            "AgroAhoFieldsPanelComp-menuSortNoVisible": !sortByName})} />
                        <div className="AgroAhoFieldsPanelComp-menuSortItemValue">{store_.trans2.Title}</div>
                    </div>
                    <div className="AgroAhoFieldsPanelComp-menuSortItem" onClick={this.onMenuSortByCropClick}>
                        <AgroAhoCheckSvg className={classNames("AgroAhoFieldsPanelComp-menuSortCheck",{
                            "AgroAhoFieldsPanelComp-menuSortNoVisible": sortByName})} />
                        <div className="AgroAhoFieldsPanelComp-menuSortItemValue">{store_.trans2.Crop}</div>
                    </div>
                </AgroAhoWindowComp>

                <AgroAhoWindowComp store={store} className={classNames("AgroAhoFieldsPanelComp-menuCover",{
                    "AgroAhoFieldsPanelComp-menuAlignBottom": this.alignBottom,
                    "AgroAhoFieldsPanelComp-hidden": !selected})} visible={this.menuVisible}
                    /*onClick={this.onMenuShow}*/ onBlur={this.onMenuBlur} button={
                        <AgroAhoMenuSvg className="AgroAhoFieldsPanelComp-menuSvg" onClick={this.onMenuClick} />}>
                    <div className="AgroAhoFieldsPanelComp-menuItem" onClick={this.onMenuCopyClick}>{
                        store_.trans['Copy selected fields']}</div>
                    <div className="AgroAhoFieldsPanelComp-menuItem AgroAhoFieldsPanelComp-menuItemRed"
                         onClick={this.onMenuDelClick}>{store_.trans['Remove selected fields from ASA map']}</div>
                </AgroAhoWindowComp>
            </div>
            <div className="AgroAhoFieldsPanelComp-fieldsCover style-4">
                {cstore.fieldsItems &&
                cstore.fieldsItems.map((f: any)=><AgroAhoCardFieldItemComp key={f.field_id}
                    store={store} field={f}/>)}
            </div>
        </div>
    }
}
