import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {computed, observable, toJS} from "mobx";
import {IStyle} from "../../helper/intStyle/styleInterface/IStyle";
import {ILayerColumnName, IntStyleConverter, IStyleGeneratorOptions} from "../../helper/intStyle/IntStyleConverter";
import mapboxgl, {AnyLayer} from "maplibre-gl";
import {save} from "../PermalinkDecor";
import {fetchJsonPost} from "../../helper/utils/FetchUtils";
import JSON5 from "json5";

export class A2FieldStyleStore extends ObservableCustomStore{
    @save @observable
    saveStyleInSession: boolean = false;

    @observable
    layers: mapboxgl.Layer[] = [];
    @save @observable
    private _styleJson5Text: string = "";
    get styleJson5Text(): string{
        return this._styleJson5Text;
    }

    @computed
    get styleJson(): IStyle{
        if (this._styleJson5Text == null) return null
        try {
            let j = JSON5.parse(this._styleJson5Text);

            return j;
        }catch {
            return null;
        }
    }

    styleTextApply(json5: string){
        let j = JSON5.parse(json5);
        this._styleJson5Text = json5;
        let op: IStyleGeneratorOptions = {
            layerNamePrefix: "class_fields_",
            categoriesIsVisible: (categoryValue) =>{
                return this.root.map.legend.isVisibleCategories(categoryValue);
            }
        };
        this.root.map.legend.clearVisibleCategories();
        let columns: ILayerColumnName[] = [];
        this.root.agro2.projectStruct.columns_items.forEach(a => columns.push({name: a.column_name, aliases: a.title_store}));
        op.columns = columns;
        let arrLayers = IntStyleConverter.toMapbox(j, op);
        this.layers = arrLayers;
        this.root.agro2.fieldToolController.fieldLayerTool.reInstallLayers();
    }

    refreshStyles(){
        let j = JSON5.parse(this._styleJson5Text);
        let op: IStyleGeneratorOptions = {
            layerNamePrefix: "class_fields_",
            categoriesIsVisible: (categoryValue) =>{
                return this.root.map.legend.isVisibleCategories(categoryValue);
            }
        };
        let columns: ILayerColumnName[] = [];
        this.root.agro2.projectStruct.columns_items.forEach(a => columns.push({name: a.column_name, aliases: a.title_store}));
        op.columns = columns;

        let arrLayers = IntStyleConverter.toMapbox(j, op);
        this.layers = arrLayers;
        this.root.agro2.fieldToolController.fieldLayerTool.reInstallLayers();
    }

    async saveStyleToServer(){
        let url = `/api/projects/${this.root.agro.projectName}/settings/modify`;
        JSON5.parse(this.styleJson5Text);
        let p: any = {settings:{obj_styles: this.styleJson5Text}};
        let r = await fetchJsonPost(url, p);
    }
}