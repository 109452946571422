import {ObservableCustomStore} from "../../app/store/CustomStore";
import {observable} from "mobx";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {AgroAhoStore, AhoModalType, AhoStage} from "./agroAhoStore";
import {makeLogger} from "ts-loader/dist/logger";
import {AhoIndicatorFormat, Card} from "./agroAhoCardStore";
import {TextUtils} from "../../app/helper/utils/TextUtils";
import {AhoUtils} from "./agroAhoUtils";
import {AgroAhoProjStore} from "./agroAhoProjStore";
import {AgroAhoUploadIsoXmlStore} from "./agroAhoUploadIsoXmlStore";
import {TourItem2} from "./agroAhoTourStore";


export enum SortType {
    byNameAZ = 'byNameAZ',
    byNameZA = 'byNameZA',
    byDateAZ = 'byDateAZ',
    byDateZA = 'byDateZA'
}

export class AgroAhoCardsStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    @observable
    cards: Card[] = [];
    @observable
    popupShow: boolean = false;
    @observable
    sortWinShow: boolean = false;
    @observable
    sortType: SortType = SortType.byDateZA;
    @observable
    searchCardsFilter: string = '';

    uploadIsoXMlStore: AgroAhoUploadIsoXmlStore = new AgroAhoUploadIsoXmlStore(this);

    getCard(map_id: number){
        let res = this.cards.filter(c=>c.map_id == map_id);
        if (res?.length) return res[0];
    }

    async doLoadCards(noCache?: boolean){
        let mstore = this.parentStore.mapStore;
        let proj_name = this.parentStore.projStore.projName;
        let url = `/api/projects/${proj_name}/asa2/map/select`;
        let cards: Card[] = await this.parentStore.cachedFetchJsonGet(url, noCache).catch(()=>{});
        // console.log('loaded cards:', AhoUtils.cp(cards));
        if (!cards) return;
        if (mstore.isNewProj() && !AhoUtils.check(cards, 'CardNoValid[]', false)) return Promise.reject(new Error('doLoadCards: Wrong cards format'));
        // this.cards.forEach(c=>c.proj_name = proj_name);
        cards.forEach(c=>c.proj_name = proj_name);
        this.cards = cards;
        // if (mstore.isNewProj()) await this.doTmpLoadIndcsFromTours();
        this.sort();
    }

    doCardsFilterChanged(val: string){
        this.searchCardsFilter = val;
        this.cards.forEach(
            c => c.filtered = c.map_name.toLowerCase().indexOf(this.searchCardsFilter.toLowerCase()) == -1);
    }

    getSelectedCards(){
        return this.cards.filter(item=>item.selected);
    }

    onDeleteCards(){
        this.parentStore.toggleModal(true, AhoModalType.DeleteCards);
    }

    async deleteCards(){
        let cards = this.getSelectedCards();
        let card_ids = cards.map(c=>c.map_id);
        if (!cards?.length) return;
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/delete?map_id=${card_ids.join(',')}`;
        await fetchJsonGet(url).then();
        await AhoUtils.delay(300);
        await this.doLoadCards(true);
    }

    compareDate(a: string, b: string){
        let c: any = new Date(a);
        let d: any = new Date(b);
        let res = c - d;
        return res ? res : 0;
    }

    sort(){
        if (this.sortType == SortType.byNameAZ)
            this.cards = this.cards.slice().sort((a,b)=> TextUtils.stringSortCompare(a.map_name, b.map_name));
        if (this.sortType == SortType.byNameZA)
            this.cards = this.cards.slice().sort((a,b)=> TextUtils.stringSortCompare(b.map_name, a.map_name));
        if (this.sortType == SortType.byDateAZ)
            this.cards = this.cards.slice().sort((a,b)=> this.compareDate(a.upd_tm, b.upd_tm));
        if (this.sortType == SortType.byDateZA)
            this.cards = this.cards.slice().sort((a,b)=> this.compareDate(b.upd_tm, a.upd_tm));
    }
}