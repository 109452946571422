import * as React from 'react';
import {Suspense} from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IProductItemComp} from "./ProductItemComp";
import {
    ClassValue,
    IndexBandProductStore,
    Interpolation
} from "../../../../store/productSetting/IndexBandProductStore";
import classNames from "classnames";
import {ProductItemIndexBandColorsComp} from "./ProductItemIndexBandColorsComp";
import {action} from "mobx";
import {IDropDownItem} from "../../../Common/DropDownComp";
import {PresetColorComp} from "./PresetColorComp";
import {Utils} from "../../../../helper/utils/Utils";
import {DelayRunOnce} from "../../../../helper/utils/DelayRunOnce";
import {fetchJsonPost} from "../../../../helper/utils/FetchUtils";
import {ra} from "../../../../helper/utils/mobxUtils";
import {InputNumber} from "../../../Common/InputNumber";
import {LeftPanelMode} from "../../../../store/SearchStore";
import {InputNumberArrow} from "../../../Common/InputNumberArrow";
import {ISuperStore} from "../../../../../pluginApi/store/SuperStore";

const ProductItemIndexBandColor2Comp = React.lazy(() => import('./ProductItemIndexBandColor2Comp'));


@observer
export class ProductItemIndexBandComp extends React.Component<IProductItemComp, undefined> {
    constructor(props: IProductItemComp) {
        super(props);
        autoBindReact(this);
    }

    delay: DelayRunOnce = new DelayRunOnce();

    @action
    onDefault(){
        this.props.item.loadDefault();
        this.props.item.changed = true;
    }

    @action
    onMaxChange(num: number){
        if (num == null || num < this.props.item.indexBand.min){
            return this.props.item.indexBand.max.toString();
        }
        this.props.item.indexBand.max = num;
        this.props.item.changed = true;
        this.props.item.indexBand.recalculateClassValuesPartial();
    }
    @action
    onMinChange(num: number){
        if (num == null || num > this.props.item.indexBand.max){
            return this.props.item.indexBand.min.toString()
        }
        this.props.item.indexBand.min = num;
        this.props.item.indexBand.recalculateClassValuesPartial();
        this.props.item.changed = true;
    }
    @action
    onClassesChange(n: number){
        if (n == null) return;
        this.props.item.indexBand.classes = n;
        this.props.item.indexBand.recalculateClassValuesFull();
        this.props.item.changed = true;
    }
    @action
    onClassesKeyPress(e: any){
        if(e.charCode == 13){
            this.props.item.indexBand.recalculateClassValuesFull();
            this.props.item.changed = true;
        }
    }

    @action
    onChangeInterpolation(item: IDropDownItem, newIndex: number){
        this.props.item.indexBand.interpolation = item.key as Interpolation;
        this.props.item.changed = true;
    }
    @action
    onChangeColorPreset(newPresetName: string): void{
        let store = this.props.store;
        let p = store.map.presetColors.find(a => a.id == newPresetName);
        this.props.item.indexBand.colors = p;
        this.props.item.changed = true;
    }

    @action
    onChangeInterpolation2(e: any){
        let att = e.currentTarget.getAttribute("data-typename");
        let item = this.props.item;
        item.indexBand.interpolation = att;
        this.props.item.changed = true;
    }

    public static getScenes(store: ISuperStore): string[]{
        let fav_scenes: string[] = store.searchPanel.favoriteList.getAllSceneItems().map(a => a.sceneId());
        let active_scenes: string[] = store.searchPanel.searchResult.currentItems.map(a => a.sceneId());
        if (store.searchPanel.leftPanelMode == LeftPanelMode.search)
            return active_scenes;
        return fav_scenes;
    }


    enabledMinMax(): boolean{
        let store = this.props.store;
        return (ProductItemIndexBandComp.getScenes(store).length > 0 && store.map.searchObject.isNotEmpty);
    }
    async getMinMax(){
        if (!this.enabledMinMax()) return;
        let store = this.props.store;
        let item = this.props.item;
        try {
            let scenes = ProductItemIndexBandComp.getScenes(store);
            let zone: any =  store.map.searchObject.searchGeometry;
            let p = {mode: "quantile", q:"0,1", product: item.prod_name, scene_id: scenes.join(","), zone: JSON.stringify(zone)};
            //?mode=minmax&product=${item.prod_name}&scene_id=${scenes.join(",")}&zone=${JSON.stringify(zone)}`);
            let res = await fetchJsonPost(`/api/raster/stat/area`, p)  as {vals: number[]};
            ra(()=>{
                this.props.item.indexBand.min = res.vals[0];
                this.props.item.indexBand.max = res.vals[res.vals.length - 1];
                this.props.item.indexBand.recalculateClassValuesFull();
                this.props.item.changed = true;
            });
        }catch (e){
            store.addError(e);
        }
    }
    enabledAreaEquals(): boolean{
        let index: IndexBandProductStore = this.props.item.indexBand;
        let store = this.props.store;
        let classes = Utils.parseNumber(index.classes);
        if (index.interpolation != Interpolation.discrete ||
            ProductItemIndexBandComp.getScenes(store).length == 0 ||
            classes < 2 || store.map.searchObject.isEmpty) return false;
        return true;
    }
    async getAreaEquals(){
        let store = this.props.store;
        let item = this.props.item;
        let index: IndexBandProductStore = this.props.item.indexBand;
        if (!this.enabledAreaEquals()) return;
        let classes = Utils.parseNumber(index.classes);

        let q: number[] = [];
        let t = 1 / classes;
        for(let i = 1; i < classes; i++){
            q.push(i * t);
        }

        try {
            let scenes = ProductItemIndexBandComp.getScenes(store);

            let zone: any =  store.map.searchObject.searchGeometry;//{"dskey": store.agro.datasetVField, "filters": {"field_id": {"$in": field_ids}}};
            let p = {q: q.map(a=> a.toString()).join(","), mode: "quantile", product: item.prod_name, scene_id: scenes.join(","), zone: JSON.stringify(zone)};
            let res = await fetchJsonPost(`/api/raster/stat/area`, p) as {vals: number[]};
            ra(()=>{

                this.props.item.indexBand.min = res.vals[0];
                let vals: number[] = [];
                for(let i = 1; i < res.vals.length - 1; i++) vals.push(res.vals[i]);
                this.props.item.indexBand.classValues2 = vals.map<ClassValue>(a => {return ClassValue.create(this.props.item.indexBand, a,  true )});
                this.props.item.indexBand.max = res.vals[res.vals.length - 1];
                this.props.item.changed = true;
            });
        }catch (e){
            store.addError(e);
        }
    }


    render() {
        let store = this.props.store;
        let item = this.props.item;
        let index: IndexBandProductStore = this.props.item.indexBand;

        let child: any[] = [];
        child = this.props.store.events.onNdviProductItemChildren.call(child, this.props);

        return <div className="ProductItemIndexBandComp">
            <div className="flex-columns">
                <span className="ProductItemComp-textLabel-m">{store.trans.Palette}</span>
                <PresetColorComp store={store} currentPreset={index.colors.id} onChange={this.onChangeColorPreset} />
            </div>
            {(index.interpolation == Interpolation.linear)  &&
                <div className="ProductItemIndexBandComp-colors-div">
                    <ProductItemIndexBandColorsComp item={this.props.item} store={this.props.store} productCode={this.props.productCode} />
                </div>
            }
            {(index.interpolation == Interpolation.discrete) &&
                <div className="ProductItemIndexBandComp-colors2-div">
                    <Suspense fallback={<div style={{height: "29px"}}>{store.trans.Downloading}...</div>}>
                        <ProductItemIndexBandColor2Comp item={this.props.item} store={this.props.store} />
                    </Suspense>
                </div>
            }
            <div className="ProductItemIndexBandComp-mmDiv">
                <div className="ProductItemIndexBandComp-mmDivItem">
                    <InputNumber onChangeNumber={this.onMinChange}
                                 hasStandartStyles={false} changeOnExitOrEnter={true}
                           className="text-box-editor number-without-arrow ProductItemIndexBandComp-mmDivItemLabelInput blue-border-onfocus"
                           min={item.getIndexStyle.min} max={item.getIndexStyle.max} value={index.min}/>
                    <div className="ProductItemIndexBandComp-mmDivItemLabelDiv">
                        <div className="ProductItemIndexBandComp-mmDivItemLabelText">{store.trans.Min}: {item.getIndexStyle.min}</div>
                    </div>
                </div>
                <div className="flex-stretch-item ">
                    {store.map.searchObject.isNotEmpty && <div className="yellow text-center">{store.trans["Active area is defined"]}</div>}
                </div>
                <div className="ProductItemIndexBandComp-mmDivItem">
                    <InputNumber onChangeNumber={this.onMaxChange}
                                 hasStandartStyles={false} changeOnExitOrEnter={true}
                           className="text-box-editor number-without-arrow ProductItemIndexBandComp-mmDivItemLabelInput blue-border-onfocus flex-align-items-end"
                                 min={item.getIndexStyle.min} max={item.getIndexStyle.max} value={index.max}/>
                    <div className="ProductItemIndexBandComp-mmDivItemLabelDiv">
                        <div className="ProductItemIndexBandComp-mmDivItemLabelText">{store.trans.Max}: {item.getIndexStyle.max}</div>
                    </div>
                </div>
            </div>

            <div className="ProductItemIndexBandComp-line" />

            <div className="ProductItemComp-props-line ProductItemIndexBandComp-nextLine-div">
                <div className="ProductItemIndexBandComp-interpolationSwitch">
                    <div onClick={this.onChangeInterpolation2} data-typename={Interpolation.linear}
                         className={classNames(
                             "popup-ndvi-in__view-row-tileMode-item full-center-content item-left ProductItemIndexBandComp-interpolationSwitchItem",
                             {"active-button": index.interpolation == Interpolation.linear})}>{store.trans["Gradient"]}</div>
                    <div onClick={this.onChangeInterpolation2} data-typename={Interpolation.discrete}
                         className={classNames(
                             "popup-ndvi-in__view-row-tileMode-item full-center-content item-right ProductItemIndexBandComp-interpolationSwitchItem",
                             {"active-button": index.interpolation == Interpolation.discrete})}>{store.trans["Discrete"]}</div>
                </div>
            </div>

            <div className="ProductItemComp-props-line ProductItemIndexBandComp-nextLine-div">
                {index.interpolation == Interpolation.discrete && <div className="flex-columns flex-align-items-center">
                    <span className="ProductItemComp-textLabel-m ProductItemComp-textLabel-inputLabel">{store.trans["Classes"]}</span>
                    <InputNumberArrow value={index.classes} onClassesKeyPress={this.onClassesKeyPress}
                                 changeOnExitOrEnter={true} onChangeNumber={this.onClassesChange}
                                 classNameInput="text-box-editor number-without-arrow ProductItemIndexBandComp-edit-classes flex-strech-item blue-border-onfocus"
                                 min={2} max={7}
                    />
                </div>}
                <div className="flex-stretch-item" />
            </div>
            <div className="ProductItemIndexBandComp-divMinMax">
                <span className="ProductItemIndexBandComp-divMinMax-Label flex-stretch-item">{store.trans["Calc. by active area"]}</span>
                <div onClick={this.getMinMax} className={
                    classNames("ProductItemIndexBandComp-divMinMax-Button blue-border-button full-center-content",
                        {"disabled": !this.enabledMinMax()})}>
                    {store.trans["Min-Max"]}
                </div>

                {index.interpolation == Interpolation.discrete && <div onClick={this.getAreaEquals} className={
                    classNames("ProductItemIndexBandComp-divMinMax-Button ProductItemPropComp-button-next blue-border-button full-center-content",
                        {"disabled": !this.enabledAreaEquals()})}>
                    {store.trans["Equal area"]}
                </div>}
            </div>

            <div className="ProductItemIndexBandComp-line ProductItemIndexBandComp-nextLine-div " />
            <div key="footer" className={classNames("ProductItemIndexBandComp-footer", {"flex-justify-end": index.interpolation == Interpolation.linear})}>
                {child}
                <div onClick={this.onDefault} key="cancel" className={
                    classNames("ProductItemPropComp-button",
                        "active_on_hover white-fill pointer",
                        "full-center-content")}>
                    {store.trans.Default}
                </div>
            </div>
        </div>;

    }
}
