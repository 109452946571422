// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Export2Panel-main {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #C5C5C5;
  border-left: 1px solid #22282F;
  border-top: 1px solid #22282F;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.Export2Panel-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #EEEEEE;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 15px;
}

.Export2Panel-switchMode {
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
  font-size: 10px;
  line-height: 22px;
}

.Export2Panel-switchModeDropdown {
  width: 240px;
}

.Export2Panel-switchMode > .SwitchItems-div > .SwitchItems-item {
  height: 33px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.Export2Panel-switchMode > .SwitchItems-div {
  width: 330px;
}

.Export2Panel-switchMode > .SwitchItems-div > .SwitchItems-item:last-child {
  border-radius: 0 100px 100px 0;
}

.Export2Panel-switchMode > .SwitchItems-div > .SwitchItems-item:first-child {
  border-radius: 100px 0 0 100px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Export2Panel/Export2Panel.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,8BAAA;EACA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AACA;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAKF;;AAHA;EACE,YAAA;AAMF;;AAJA;EACE,8BAAA;AAOF;;AALA;EAEE,8BAAA;AAOF","sourcesContent":[".Export2Panel-main{\n  font-style: normal;\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 16px;\n  color: #C5C5C5;\n  border-left: 1px solid #22282F;\n  border-top: 1px solid #22282F;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow: hidden;\n}\n.Export2Panel-title{\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 18px;\n  color: #EEEEEE;\n  margin-top: 20px;\n  margin-left: 15px;\n  margin-bottom: 15px;\n}\n.Export2Panel-switchMode{\n  margin-bottom: 15px;\n  margin-left: 15px;\n  margin-right: 22px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  font-weight: 600;\n  font-size: 10px;\n  line-height: 22px;\n}\n.Export2Panel-switchModeDropdown{\n  width: 240px;\n}\n.Export2Panel-switchMode > .SwitchItems-div > .SwitchItems-item{\n  height: 33px;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 19px;\n}\n.Export2Panel-switchMode > .SwitchItems-div{\n  width: 330px;\n}\n.Export2Panel-switchMode > .SwitchItems-div > .SwitchItems-item:last-child{\n  border-radius: 0 100px 100px 0;\n}\n.Export2Panel-switchMode > .SwitchItems-div > .SwitchItems-item:first-child{\n\n  border-radius: 100px 0 0 100px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
