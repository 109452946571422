import {IndexByPointerSourceCustomSimpleStore} from "./IndexByPointerSourceCustomSimpleStore";
import {IGraphDayValue} from "../IndexByPointGraphStore";
import {Utils} from "../../../helper/utils/Utils";
import {PrecipitationCategory} from "../IndexByPointerStore";

export class IndexByPointerSourceSimplePrecipAvgClimateStore extends IndexByPointerSourceCustomSimpleStore {
    protected getRequest(): string {
        let gp = this.parent.gPoint;
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        const {dayOfYearBegin, dayOfYearEnd} = Utils.adjustDayOfYear(
            dateBegin,
            dateEnd
        );
        return `/api/meteo/avg/ltm/prec?lon=${gp.point.lng}&lat=${gp.point.lat}&from_day=${dayOfYearBegin}&to_day=${dayOfYearEnd}`;
    }

    protected readSourceArray(arr: any[]): IGraphDayValue[] {
        let res: IGraphDayValue[] = [];
        arr.forEach(a => {
            let v = this.getValue(a);
            if (v.dayOfYear >= 59) {
                v.dayOfYear++;
            }
            res.push(v);
        });
        arr.forEach(a => {
            let v = this.getValue(a);
            v.dayOfYear = v.dayOfYear + Utils.DaysByYear;
            if (v.dayOfYear >= 425) {
                v.dayOfYear++;
            }
            res.push(v);
        });
        let {dateBegin, dateEnd} = this.parent.getDateIntervalPlus();
        const {dayOfYearBegin, dayOfYearEnd} = Utils.adjustDayOfYear(
            dateBegin,
            dateEnd
        );
        if (dayOfYearBegin > dayOfYearEnd) {
            res = res.slice(dayOfYearEnd - 1);
        }
        return res;
    }

    protected getValue(j: any): IGraphDayValue {
        // let j: {day: number, ltm_prec: number} = json;

        return {dayOfYear: j.day - 1, value: j.prec};
    }
    protected getGraphDataInternal(): IGraphDayValue[] {
        return this.getData;
    }

    private get getData(): IGraphDayValue[] {
        let src: IGraphDayValue[];
        if (this.status == null)
            setImmediate(() => {
                this.load();
            });
        src = this.data;
        if (
            this.parent.parent.precipitationCategory ==
            PrecipitationCategory.daily
        )
            return src;
        if (
            this.parent.parent.precipitationCategory ==
            PrecipitationCategory.accumulated
        )
            return this.accumGraphDayValue(src, 0);
        return [];
    }
}
