import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {isFunction, isString} from "lodash-es";
import classNames from "classnames";
import './InputNumber.scss';
import {Utils} from "../../helper/utils/Utils";
import {observable} from "mobx";
import {observer} from "mobx-react";

export interface IInputNumberProps {
    value: string|number,
    min?: number,
    max?: number,
    hasStandartStyles?: boolean,//default: true
    className?: string,
    onChange?: (str: string)=>any,
    //Если возвращает строку, то она заменяет внутреннее значение
    onChangeNumber?: (num: number)=>string | any,
    onClassesKeyPress?: (e:any)=>any,
    disabled?: boolean,
    changeOnExitOrEnter?: boolean,//default: false
    maxLength?: number,
    pattern?: string,
    placeholder?: string,
}
@observer
export class InputNumber extends React.PureComponent<IInputNumberProps, undefined> {
    constructor(props: IInputNumberProps) {
        super(props);
        autoBindReact(this);
        this.value = this.props.value;
    }

    @observable
    value: string|number;
    @observable
    isChanging: boolean = false;

    componentDidUpdate(prevProps: Readonly<IInputNumberProps>, prevState: Readonly<undefined>, snapshot?: any) {
        if (prevProps.value != this.props.value){
            this.value = this.props.value;
        }
    }

    onChange(e: any){
        if (this.props.disabled) return;
        let v = e.currentTarget.value;
        this.value = v;
        if (this.props.changeOnExitOrEnter){
            this.isChanging = true;
            return;
        }
        let vNum = Utils.parseNumber(v);
        if (vNum != null && this.props.max != null && vNum > this.props.max) v = this.props.max;
        if (vNum != null && this.props.min != null && vNum < this.props.min) v = this.props.min;
        if (isFunction(this.props.onChange)) this.props.onChange(v);
    }

    callChangeOnExit(){
        let n = Utils.parseNumber(this.getValue());
        let min = Utils.parseNumber(this.props.min);
        let max = Utils.parseNumber(this.props.max);
        if (n == null){
            this.value = this.props.value;
        }else{
            if (min != null && n < min) this.value = min;
            if (max != null && n > max) this.value = max;
        }

        if (isFunction(this.props.onChange)) this.props.onChange(this.getValue());
        if (isFunction(this.props.onChangeNumber)) {
            let newValue = this.props.onChangeNumber(Utils.parseNumber(this.getValue()));
            if (isString(newValue)){
                this.value = newValue;
            }
        }

        this.isChanging = false;

    }

    onClassesKeyPress(e: any){
        if (this.props.disabled) return;
        if (isFunction(this.props.onClassesKeyPress)) this.props.onClassesKeyPress(e);
        if(e.charCode === 13 && this.props.changeOnExitOrEnter){
            this.callChangeOnExit();
        }
    }

    getValue(): any{
        let v = this.props.value;
        if (this.props.changeOnExitOrEnter){
            v = this.value;
        }
        return v;
    }

    onBlur(){
        if (this.isChanging) this.callChangeOnExit();
    }

    render() {
        let v = this.getValue();
        return <input type="number" onChange={this.onChange} onKeyPress={this.onClassesKeyPress} disabled={this.props.disabled}
                      className={classNames({
                          "InputNumber-input": this.props.hasStandartStyles??true},
                          this.props.className
                      )}
                      maxLength={this.props.maxLength}
                      pattern={this.props.pattern}
                      placeholder={this.props.placeholder}
                      onBlur={this.onBlur}
                      min={this.props.min}
                      max={this.props.max}
                      value={v}/>;
    }
}
