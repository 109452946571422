import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {LeftAuthPanelComp} from "./LeftAuthPanelComp";
import classNames from "classnames";
import {UrlCoder} from "../../../helper/utils/UrlCoder";

@observer
export class NewPassComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action async onSubmit(event: any){
        event.preventDefault();
        let store = this.props.store;
        try {
            let pass = this.props.store.user.signUpForm.password;
            if (!store.user.checkPasswordCorrect(pass)){
                store.addError(store.trans["The password must contain at least 8 characters, consist of letters of the Latin alphabet (A-z), Arabic numbers (0-9) and special characters."]);
                return false;
            }
            let code: string = "";
            let params = UrlCoder.getQueryParams();
            code = params.code;
            await store.user.newPassSend(pass, code);
            store.addInfo("Password changed successfully");
            store.gotoUrl('/signin');
        }catch (e){
            store.addError(e);
        }
        return false;
    }

    @action onChangePassword(event: any){
        this.props.store.user.signUpForm.password = event.target.value;
        this.props.store.user.signUpForm.passwordUpdated = true;
    }
    @action onChangePassword2(event: any){
        this.props.store.user.signUpForm.password2 = event.target.value;
        this.props.store.user.signUpForm.password2Updated = true;
    }
    @action
    onClickEye(){
        this.props.store.user.signUpForm.passwordShow = !this.props.store.user.signUpForm.passwordShow;
    }

    onClickSignIn(){
        //this.props.store.user.authFormShow = true;
        //this.props.store.user.signUpForm.show = false;
        this.props.store.gotoUrl('/signin');
    }

    render() {
        let store = this.props.store;
        let form = store.user.signUpForm;
        let passwordError: string = null;
        if ((form.tryingSubmit || form.passwordUpdated) && form.password.length == 0) passwordError = store.trans["Please specify password"];
        let password2Error: string = null;
        if ((form.tryingSubmit || form.passwordUpdated) && form.password != form.password2) password2Error = store.trans["Password mismatch"];

        let enableSubmit: boolean = !password2Error && !passwordError;

        return <div className="authorize-background">
            <div className="authorize-background2">
                <LeftAuthPanelComp store={store} />
                <div className="authorize-d2 style-4">
                    <div className="authorize-form-zatyk" />
                    <form className="authorize-form authorize-form-width" onSubmit={this.onSubmit} autoComplete="off">
                        <div className="UserForm-title-m flex-columns flex-align-items-center UserForm-title-marginTop">
                            <div className="flex-fit-item">{store.trans["New password"]}</div>
                        </div>

                        <div className="UserForms-labelDiv-m">
                            <div className="authorize-in-right__row_label-left">
                                {store.trans.Password}
                                <span className="UserForms-Star-m">*</span>
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type={classNames({"password": !form.passwordShow, "text": form.passwordShow})}
                                   className={classNames("UserForms-inputText-m UserForms-inputText-password", {"red_border": passwordError})}
                                   onChange={this.onChangePassword} value={form.password} />
                            <div onClick={this.onClickEye}
                                 className={classNames("UserForms-inputDiv-eye",{"gray-fill":!form.passwordShow, "blue-fill":form.passwordShow})}>
                                <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00001 12C12.707 12 15.744 6.716 15.871 6.492C16.042 6.188 16.043 5.816 15.872 5.512C15.746 5.287 12.731 0 8.00001 0C3.24501 0 0.251005 5.289 0.126005 5.514C-0.0429946 5.817 -0.0419946 6.186 0.128005 6.489C0.254005 6.713 3.26901 12 8.00001 12ZM8.00001 2C10.839 2 13.036 4.835 13.818 6C13.034 7.166 10.837 10 8.00001 10C5.15901 10 2.96201 7.162 2.18101 5.999C2.95801 4.835 5.14601 2 8.00001 2Z"/>
                                    <path d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z" />
                                </svg>
                            </div>
                            {passwordError && <div className="UserForms-errorHint-m">{passwordError}</div>}
                        </div>

                        <div className="UserForms-labelDiv-m">
                            <div className="authorize-in-right__row_label-left">
                                {store.trans["Confirm password"]}
                                <span className="UserForms-Star-m">*</span>
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type={classNames({"password": !form.passwordShow, "text": form.passwordShow})}
                                   className={classNames("UserForms-inputText-m UserForms-inputText-password", {"red_border": password2Error})}
                                   onChange={this.onChangePassword2} value={form.password2} />
                            <div onClick={this.onClickEye}
                                 className={classNames("UserForms-inputDiv-eye",{"gray-fill":!form.passwordShow, "blue-fill":form.passwordShow})}>
                                <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00001 12C12.707 12 15.744 6.716 15.871 6.492C16.042 6.188 16.043 5.816 15.872 5.512C15.746 5.287 12.731 0 8.00001 0C3.24501 0 0.251005 5.289 0.126005 5.514C-0.0429946 5.817 -0.0419946 6.186 0.128005 6.489C0.254005 6.713 3.26901 12 8.00001 12ZM8.00001 2C10.839 2 13.036 4.835 13.818 6C13.034 7.166 10.837 10 8.00001 10C5.15901 10 2.96201 7.162 2.18101 5.999C2.95801 4.835 5.14601 2 8.00001 2Z"/>
                                    <path d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z" />
                                </svg>
                            </div>
                            {password2Error && <div className="UserForms-errorHint-m">{password2Error}</div>}
                        </div>
                        <div className="UserForms-footer-m">
                            <button style={{width:"100%"}} disabled={!enableSubmit}
                                    className={classNames("authorize-submit-form", {"opacity20": !enableSubmit})}
                                    type="submit">{store.trans.Change}</button>
                        </div>

                        <div className="authorize-in-right__row_label-in authorize-footer-marginTop">
                            <div className="authorize-in-right__row_label-left not-yet-with-us">{store.trans["Already have an account?"]}</div>
                            <div className="authorize-in-right__row_label-right not-yet-with-us-line">&nbsp;</div>
                        </div>
                    </form>
                    <div className="flex-columns authorize-form-width">
                        <button className="reg-now" onClick={this.onClickSignIn} disabled={!enableSubmit}>{store.trans["Sign in"]}</button>
                        <button className="reg-now UserForms-facebook-btn text-next-2">
                            {store.trans["Sign in via"]}
                            <svg width="9" height="15" className="text-next" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.72064 15L2.7 8.4375H0V5.625H2.7V3.75C2.7 1.2195 4.20436 0 6.37144 0C7.40949 0 8.30165 0.0805031 8.56165 0.116484V2.761L7.05866 2.76172C5.88009 2.76172 5.65188 3.34509 5.65188 4.20116V5.625H9L8.1 8.4375H5.65187V15H2.72064Z" fill="#3A7BAF"/>
                            </svg>
                        </button>
                    </div>
                    <div className="authorize-form-zatyk" />
                </div>
            </div>
        </div>;
    }
}
