import * as React from 'react';
import {CustomSvg} from "../CustomSvg";

export class ClockSvg extends CustomSvg {

    render() {
        return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.getClasses()}>
        <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303297 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.9945 5.14519 13.2552 3.36793 11.9436 2.05637C10.6321 0.744816 8.85482 0.00553954 7 0ZM10.5 7.875H7C6.76794 7.875 6.54538 7.78281 6.38129 7.61872C6.21719 7.45462 6.125 7.23206 6.125 7V3.5C6.125 3.26794 6.21719 3.04538 6.38129 2.88128C6.54538 2.71719 6.76794 2.625 7 2.625C7.23207 2.625 7.45463 2.71719 7.61872 2.88128C7.78282 3.04538 7.875 3.26794 7.875 3.5V6.125H10.5C10.7321 6.125 10.9546 6.21719 11.1187 6.38128C11.2828 6.54538 11.375 6.76794 11.375 7C11.375 7.23206 11.2828 7.45462 11.1187 7.61872C10.9546 7.78281 10.7321 7.875 10.5 7.875Z" fill="#6A7886"/>
        </svg>
    }
}