import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import './KeyIcon.scss';
import classNames from "classnames";
import {observer} from "mobx-react";
export interface IKeyIcon extends IStoreProps{
    text: string;
}
@observer
export class KeyIcon extends React.Component<IKeyIcon, undefined> {
    constructor(props: IKeyIcon) {
        super(props);
        autoBindReact(this);
    }

    render() {
        return <div className={classNames("KeyIcon", this.props.className)}>{this.props.text}</div>;
    }
}