import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoPanelComp.scss'
import {AhoStage} from "../agroAhoStore";
import {AgroAhoDialogComp} from "./AgroAhoDialogComp";
import {AgroAhoSpinnerComp} from "./AgroAhoSpinnerComp";
import {AgroAhoCardsComp} from "./cards/AgroAhoCardsComp";
import {AgroAhoCardComp} from "./card/AgroAhoCardComp";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {AgroAhoTopSpinnerComp} from "./AgroAhoTopSpinnerComp";

@observer
export class AgroAhoPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;

        // console.log('project:', store_.projName, store.agro.projectName);

        return <div className="AgroAhoPanelComp-main">
            {store_.ahoStage == AhoStage.Loading &&
                <AgroAhoSpinnerComp store={store} />
            }
            {store_.ahoTopSpinnerShow &&
                <AgroAhoTopSpinnerComp store={store} />
            }
            {/*{store_.ahoStage == AhoStage.Probes && !store_.probes.length &&*/}
            {/*    <AgroAhoLoadComp store={store} />*/}
            {/*}*/}
            {/*{store_.ahoStage == AhoStage.Probes && store_.probes.length > 0 &&*/}
            {/*    <AgroAhoProbesComp store={store} />             */}
            {/*}*/}
            {store_.ahoStage == AhoStage.Cards &&
                <AgroAhoCardsComp store={store} />
            }
            {/*{store_.ahoStage == AhoStage.Indicators &&*/}
            {/*    <AgroAhoIndicatorsComp store={store} />             */}
            {/*}*/}
            {store_.ahoStage == AhoStage.Card &&
                <AgroAhoCardComp store={store} />
            }
            {/*{store_.ahoStage == AhoStage.EditProbe &&*/}
            {/*    <AgroAhoEditComp store={store} />}*/}
            <AgroAhoDialogComp store={store}/>
        </div>
    }
}
