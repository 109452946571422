// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tswitcher {
  width: 200px;
  height: 33px;
  display: flex;
  position: relative;
  border-radius: 17px;
  border: 1px solid #3E4751;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}

.tswitcher-item {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: #C5C5C5;
  transition: color 0.5s;
}

.tswitcher-checked {
  color: #2B3138;
}

.tswitcher-disabledItem {
  opacity: 0.3;
}

.tswitcher-item-left {
  left: 0;
  right: 50%;
}

.tswitcher-item-right {
  left: 50%;
  right: 0;
}

.tswitcher-back {
  position: absolute;
  background: #4DB6BC;
  top: -1px;
  bottom: -1px;
  z-index: 5;
  border-radius: 17px;
}

.tswitcher-back-left {
  left: -1px;
  right: 50%;
  transition: left 0.5s, right 0.4s 0.2s;
}

.tswitcher-back-right {
  left: 50%;
  right: -1px;
  transition: right 0.5s, left 0.4s 0.2s;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/SwitchText.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EAEA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAAF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;EACA,sBAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,OAAA;EACA,UAAA;AAIF;;AAFA;EACE,SAAA;EACA,QAAA;AAKF;;AAHA;EACE,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;AAMF;;AAJA;EACE,UAAA;EACA,UAAA;EACA,sCAAA;AAOF;;AALA;EACE,SAAA;EACA,WAAA;EACA,sCAAA;AAQF","sourcesContent":[".tswitcher {\r\n  width: 200px;\r\n  height: 33px;\r\n  display: flex;\r\n  position: relative;\r\n  border-radius: 17px;\r\n  border: 1px solid #3E4751;\r\n\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 14px;\r\n  line-height: 19px;\r\n  cursor: pointer;\r\n}\r\n.tswitcher-item{\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  z-index: 10;\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex-wrap: nowrap;\r\n  justify-content: center;\r\n  align-items: center;\r\n  align-content: stretch;\r\n  color: #C5C5C5;\r\n  transition: color .5s;\r\n}\r\n.tswitcher-checked{\r\n  color: #2B3138;\r\n}\r\n.tswitcher-disabledItem{\r\n  opacity: 0.3;\r\n}\r\n.tswitcher-item-left{\r\n  left: 0;\r\n  right: 50%;\r\n}\r\n.tswitcher-item-right{\r\n  left: 50%;\r\n  right: 0;\r\n}\r\n.tswitcher-back{\r\n  position: absolute;\r\n  background: #4DB6BC;\r\n  top: -1px;\r\n  bottom: -1px;\r\n  z-index: 5;\r\n  border-radius: 17px;\r\n}\r\n.tswitcher-back-left{\r\n  left: -1px;\r\n  right: 50%;\r\n  transition: left .5s, right .4s .2s;\r\n}\r\n.tswitcher-back-right{\r\n  left: 50%;\r\n  right: -1px;\r\n  transition: right .5s,left .4s .2s;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
