import {ObservableCustomStore} from "../../CustomStore";
import {fetchJson, fetchJsonPost} from "../../../helper/utils/FetchUtils";
import {action, autorun, observable} from "mobx";
import {A2UploadBindColumnStore} from "./A2UploadBindColumnStore";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {ra} from "../../../helper/utils/mobxUtils";
import {A2UploadFieldsFormStore} from "./A2UploadFieldsFormStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelMode} from "../../SearchStore";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {GeometryUtils} from "../../../helper/utils/GeometryUtils";


interface IDatasetSampleResponse{
    columns: IDatasetSampleInfo[];
    cnt: number;
    file_id: string;
    bbox: {min_lat: number, max_lat: number, min_lon: number, max_lon: number}
}
interface IDatasetSampleInfo{
    name: string;
    type: string;
    values: string[];
}

export class A2UploadFormStore extends ObservableCustomStore{
    @observable
    file_id:string;
    @observable
    columns: A2UploadBindColumnStore[] = [];
    @observable
    filename: string;
    @observable
    bbox: BBox2d;
    @observable
    featuresCount: number = 0;
    //@observable
    //staticValues: A2UploadBindStaticValueStore[] = [];
    @observable
    loadColumnStatus: LoadStatus = null;
    @observable
    check_properties: boolean = false;

    fieldsForm: A2UploadFieldsFormStore = new A2UploadFieldsFormStore(this);

    subscription(): IReactionDisposer[] {
        return [
            autorun(()=>{
                if (this.root.searchPanel.leftPanelMode == LeftPanelMode.uploadFields && this.check_properties){
                    this.fieldsForm.checkFieldsValid();
                }
            })
        ];
    }

    public clear(){
        this.check_properties = false;
        this.fieldsForm.clear();
    }

    async loadFileStep2(){
        await this.fieldsForm.saveNewValues();
        let prms: any = {};
        prms.file_id = this.file_id;
        prms.const_value_columns = this.fieldsForm.getPropertiesForSaving();
        prms.mapping_columns = {};

        let constCols = this.root.agro2.projectInfo?.projectInfoJson?.type_info?.type_schema?.obj?.file_import?.const_value_columns;
        if (constCols != null) {
            constCols.forEach(col =>{
                let v = this.fieldsForm.properties[col.name];
                if (v != null) prms.const_value_columns[col.name] = v;
            });
        }
        this.columns.forEach(a => {
            if (a.bindColumnName != null){
                prms.mapping_columns[a.bindColumnName] = a.name;
            }
        });
        let uploadUrl = `/api/projects/${this.root.agro.projectName}/object/file/load`;
        await fetchJsonPost(uploadUrl, prms);
    }
    @action
    fetchColumnsStep1(file : File): Promise<IDatasetSampleResponse> {
        let uploadUrl = `/api/projects/${this.root.agro.projectName}/object/file/sample`;///api/dataset/sample?store=1`;
        this.filename = file.name;
        this.featuresCount = 0;
        const fd = new FormData();
//        fd.append('file', file);
        fd.append('obj_file', file);
        this.loadColumnStatus = LoadStatus.loading;
        this.columns = [];
        this.file_id = null;
        this.fieldsForm.clear();

        return fetchJson(uploadUrl, {
            method: 'POST',
            body: fd
        }).then(value => {
            let v: IDatasetSampleResponse = value;
            ra(()=>{
                this.featuresCount = v.cnt;
                this.columns = [];
                this.file_id = v.file_id;
                this.bbox = GeometryUtils.getBbox(v.bbox.min_lon, v.bbox.max_lon, v.bbox.min_lat, v.bbox.max_lat);

                v.columns.forEach(a => {
                    let t: A2UploadBindColumnStore = new A2UploadBindColumnStore(this);
                    t.name = a.name;
                    t.fileValues = a.values;
                    this.columns.push(t);
                });
                this.loadColumnStatus = LoadStatus.ready;
            });
            return Promise.resolve(v);
        });
    }



}