export enum CoordinateStringFormat {
    degMinSec,
    degMin,
    deg,
    abs,
}

export function nextFormat(val: CoordinateStringFormat): CoordinateStringFormat {
    let numVal = val as number;
    if (numVal == CoordinateStringFormat.abs) return CoordinateStringFormat.degMinSec;
    return (numVal + 1) as CoordinateStringFormat;
}
