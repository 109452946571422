import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {isFunction} from "lodash-es";
import './A2LineButton.scss';
import classNames from "classnames";

export interface IA2LineButtonProps {
    onClick?: (e: any, c?: A2LineButton) => void;
    className?: string;
    disabled?: boolean;
}


export class A2LineButton extends React.PureComponent<IA2LineButtonProps, undefined> {
    constructor(props: IA2LineButtonProps) {
        super(props);
        autoBindReact(this);
    }

    onClick(e: any){
        if (isFunction(this.props.onClick) && !this.props.disabled){

            this.props.onClick(e, this);
        }
    }

    render() {
        return <div className={classNames("A2LineButton full-center-content white-fill blue_svg_onhover noselect", this.props.className,
            {"pointer": !this.props.disabled,
                "opacity30 disabled": this.props.disabled})}
                    onClick={this.onClick}>{this.props.children}</div>;
    }

}
