import {CustomStore} from "../CustomStore";
import {action, observable} from "mobx";

export class ForgotStore extends CustomStore{
    @observable
    email: string = "";
    @observable
    emailUpdated: boolean = false;

    @action
    reset(){
        this.email = "";
        this.emailUpdated = false;
    }

    async send(){

    }
}