import {action, observable} from "mobx";
import {fetchJson} from "../../helper/utils/FetchUtils";
import {CustomStore, ObservableCustomStore} from "../CustomStore";

export interface IEgrnOrder {
    id : number;
    user_cadnum : string;
    state : ("NEW" | "INFO" | "ORDER" | "SUCCESS" | "FATAL");
    dt_create: string;
    dt_done: string;
}

export class EgrnStore extends ObservableCustomStore {
    constructor(parent: CustomStore) {
        super(parent);
    }

    class(): string {return "EgrnStore";}

    @observable
    cadastralNumber : string = "";

    @observable
    invalidCadNumber : boolean = false;

    @observable
    loading : boolean = false;

    @observable
    orders : IEgrnOrder[] = [];

    @observable
    activeParcel : any = null;

    @action
    loadOrders(){
        let url = "/api/egrn/orders";
        this.loading = true;
        fetchJson(url)
            .then( value => {
                this.loading = false;
                this.orders = value;
            })
            .catch(err => {
                this.loading = false;
                this.root.addError(err);
            });            
    }
}