import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './PhotoPanelPhotoList.scss';
import {PhotoPanelPhotoItemComp} from "./PhotoPanelPhotoItemComp";
import {LoadStatus} from "../../../../../helper/structs/LoadStatus";
import VizSensor from "react-visibility-sensor";
import {rai} from "../../../../../helper/utils/mobxUtils";

@observer
export class PhotoPanelPhotoListComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.props.store.photo.initTags();
    }

    render() {
        let store = this.props.store;
        let arr: any[] = [];
        let fs = store.photo.features;
        fs.forEach((f, idx) => {
            let k = f.properties.photo_id.toString();
            arr.push(<VizSensor key={k} partialVisibility={true}>{
                (args: { isVisible: boolean }) => {
                    if (args.isVisible == null || !args.isVisible ) return <div key={k} className="PhotoPanelPhotoItemComp-hidden"/>;

                    if (idx == (fs.length - 4) &&
                        (store.photo.featuresLoadStatus != LoadStatus.loading) &&
                        !store.photo.featuresLoadToEnd){
                        rai(()=>{//нельзя action в render
                            if ((store.photo.featuresLoadStatus != LoadStatus.loading) &&
                                !store.photo.featuresLoadToEnd) store.photo.doLoadPhotoListNextPage();
                        });
                    }
                    return <PhotoPanelPhotoItemComp item={f.properties} geometry={f.geometry} store={store} key={k} />;
                }
            }</VizSensor>);
            //arr.push(<PhotoPanelPhotoItemComp item={f.properties} geometry={f.geometry} store={store} key={f.properties.photo_id.toString()} />);
        });

        return <div className="relative flex-stretch-item overflowHidden">
            <div className="height100 width100 overflowAutoY style-4 PhotoPanelPhotoListComp-scroll" id="photolist">
            <div className="PhotoPanelPhotoListComp-main">
                {arr}
            </div>
        </div>
            {(store.photo.featuresLoadStatus == LoadStatus.loading) &&
            <div className="PhotoPanelPhotoListComp-loadingBackground center-content">
                <div className="loading-search">
                    <div className="spinner"/>
                </div>
            </div>
            }
        </div>;
    }
}