import {CustomStore} from "../CustomStore";
import {computed, observable} from "mobx";
import {Utils} from "../../helper/utils/Utils";
import {GeometryUtils} from "../../helper/utils/GeometryUtils";
import {A2NavigatorSection} from "../agro/A2AgroStore";
import {fetchJsonGet, fetchJsonPost} from "../../helper/utils/FetchUtils";
import {SceneMosaicSwitch, SceneUiType} from "../SearchStore";

interface IExportQgisLayerFields {
    "name": string,
    "is_main": number,
    "label": string,
    "dataset": string,//"agro_base_kazminskiy_v_field",
    "filters": any,//{"season_name":"2022","crop_name":"Пшеница озимая","farm_name":"Казьминское"}}
}
interface IExportQgisLayerActiveArea {
    "name":"ruler_line" | "active_area",
    "features": any[],
}
interface IExportQgisScenes {
    scene_ids: string[],
    product: string,
    style?: any,
    cutline?: string,
    contours?: any,
}
interface IPexportQgis {
    filename:string;//kazminskiy
    async?: number;
    z?: number;
    surface: number;
    map_extent: {"x_min":number,"y_min":number,"x_max":number,"y_max":number};

    vectors: (IExportQgisLayerFields | IExportQgisLayerActiveArea)[],
    scene: IExportQgisScenes,

}
export interface IExportScenes{
    sceneIds: string[];
    UiType: SceneUiType;
}
export class QgisExportStore extends CustomStore{
    @observable
    showExportWindow: boolean = false;
    @observable
    addContourLines: boolean = false;
    @observable
    onlySelected: boolean = false;
    @observable
    contourRuler: boolean = false;
    @observable
    izoLines: boolean = false;
    @observable
    filename: string = "";

    @computed
    get isIndexProduct(): boolean{
        return  this.root.map.productInfo.activeProductsSet.activeCodeCurrent.currentProductStore.productConfig.type == "index"
    }
    @computed
    get isActiveButtonWindow(): boolean{
        let store = this.root;
        return (this.hasFields) ||
            (store.searchPanel.searchResult.currentItems.length > 0);
    }
    @computed
    get hasFields(): boolean{
        let store = this.root;
        return store.agro2.navigatorSection == A2NavigatorSection.folder && store.agro2.projectStruct.navigatorRootItem != null;
    }
    @computed
    get hasRuler(): boolean{

        return !this.root.map.rulerInfo.getGeometry() != null && !GeometryUtils.isGeometryEmpty(this.root.map.rulerInfo.getGeometry());
    }
    @computed
    get hasSelectedFields(): boolean{
        if (!this.hasFields) return false;
        return this.root.agro2.projectStruct.fieldsPage.selectedFields.length > 0;
    }
    getSceneInfo(): IExportScenes{
        let store = this.root;
        if (store.searchPanel.top_sceneId == null) return null;

        if (store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.scene){
            return {sceneIds: [store.searchPanel.top_sceneId], UiType: store.searchPanel.top_sceneType};
        }

        let grp = store.searchPanel.searchResult.findGroup(store.searchPanel.top_sceneId);

        if (grp == null) return null;
        return {sceneIds: grp.getSceneIds(), UiType: store.searchPanel.top_sceneType};
    }

    async exportToQgis(){
        let store = this.root;
        let filename = this.filename;

        let bbox = store.map.bbox;
        let sceneInfo = this.getSceneInfo();


        let scenes: string[] = [];
        let productCode: string = null;
        let sceneStyle: any = {};
        if (sceneInfo != null) {
            scenes = sceneInfo.sceneIds;
            productCode = store.map.productInfo.activeProductsSet.getActiveCodeByUiType(sceneInfo.UiType).productCode;
            sceneStyle = store.map.productInfo.activeProductsSet.getActiveCodeByUiType(sceneInfo.UiType).currentProductStore.getStyles();
        }
        //let productCode = store.map.productInfo.activeProductsSet.activeCodeCurrent.productCode;
        let cutByActiveContour = store.searchPanel.cutFieldsByBorder;

        let url = `/api/qgis_proj/export`;
        let params: IPexportQgis = {
            async: 1,
            z: store.map.zoom,
            filename: filename,
            surface: this.izoLines?1:0,
            map_extent: {x_max: bbox.ne.lng, x_min: bbox.sw.lng, y_max: bbox.ne.lat, y_min: bbox.sw.lat},
            scene: {scene_ids: scenes, product: productCode}, vectors:[]
        };
        if (sceneStyle != null) params.scene.style = sceneStyle;

        if (this.addContourLines && this.isIndexProduct) params.scene.contours = {};
        if (this.contourRuler){ //добавляем линейку
            let g = store.map.rulerInfo.rulerManager.state.getGeometry();
            if (!GeometryUtils.isGeometryEmpty(g)) {
                params.vectors.push({name: "ruler_line", features: [{properties: {}, geometry: g}]});
            }
        }
        if (cutByActiveContour && store.map.searchObject.isNotEmpty){

            params.vectors.push({name: "active_area", features: [{properties: {}, geometry: store.map.searchObject.searchGeometry}]});
            params.scene.cutline = "active_area";
        }
        let filter: any = {};
        if (store.agro2.navigatorSection == A2NavigatorSection.folder && store.agro2.projectStruct.navigatorRootItem != null) {
            if (this.onlySelected && this.hasSelectedFields) {
                let field_ids = store.agro2.projectStruct.fieldsPage.selectedFields.map(a => a.id);
                filter[this.root.agro2.projectInfo.fieldName_id] = {"$in": field_ids};
            } else {
                if (store.agro2.projectStruct.activeNavigatorPage != null)
                    filter = store.agro2.projectStruct.activeNavigatorPage.getAllFilters({notFirst: true});
            }
            let vName: string = "objects";

            if (this.root.agro2.projectInfo?.projectInfoJson?.type_info?.type_schema?.obj?.view?.export?.file_name)
                vName = this.root.agro2.projectInfo?.projectInfoJson?.type_info?.type_schema?.obj?.view?.export?.file_name;
            params.vectors.push({name: vName, "is_main":1, "label": this.root.agro2.projectInfo.fieldName_columnName,
                dataset: store.agro2.projectInfo.getFieldVectorLayerId(), filters: filter});
        }

        let res: {task_code: string}  = await fetchJsonPost(url, params);
        while (true){
            await Utils.pauseAsync(2000);
            let info: {"task_code": string, "type": string, "status": string,
                "result": {"file_code": string},
                "error": string} = await fetchJsonGet(" /api/task/get", {task_code: res.task_code});
            //статусы: wait, run, ok, error
            if (info.status == "error") throw info.error;
            if (info.status == "ok"){
                Utils.downloadFile(`/api/qgis_proj/export/async_result?task_code=${encodeURIComponent(res.task_code)}`, "export.zip");
                break;
            }
        }

    }
}