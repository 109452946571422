import {ObservableCustomStore} from "../../app/store/CustomStore";
import {observable} from "mobx";
import {AgroAhoStore, AhoModalType} from "./agroAhoStore";
import {IAhoUrlType} from "./agroAhoStore";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {TrackInfo} from "./agroAhoCardStore";
import {Utils} from "../../app/helper/utils/Utils";
import {AgroAhoCardsStore} from "./agroAhoCardsStore";
import {AhoUtils} from "./agroAhoUtils";
import {AgroAhoMergeIsoXmlFarmComp} from "./AgroAhoPanel/cards/AgroAhoMergeIsoXmlFarmComp";
import * as React from "react";


const isoXmlCfg = {
    MergeIsoXmlResult: {
        files: 'MergeIsoXmlFile[]',
        // errors: 'str[]?'
    },
    MergeIsoXmlFile: {
        fields: 'MergeIsoXmlField[]'
    },
    MergeIsoXmlField: {
        farm_id: 'num?',
        farm_name: 'str?',
        area_m2: 'num',
        field_order: 'num',
        field_id: 'num',
        field_name: 'str'
    }
}

export interface MergeIsoXmlField {
    farm_id?: number;
    farm_name?: string;
    area_m2: number;
    field_order: number;
    field_id: number;
    field_name: string;
    selected?: boolean;
}

export interface MergeIsoXmlFile {
    file_name: string;
    fields: MergeIsoXmlField[];
}

export interface MergeIsoXmlResult {
    files: MergeIsoXmlFile[];
    errors: string[];
    merge_id: string;
}

export interface MergeIsoXmlFarm {
    farm_id?: number;
    farm_name: string;
    fields: MergeIsoXmlField[];
}

export interface MergeIsoXmlFarmsDict {
    [farm_id: number]: MergeIsoXmlFarm;
}

export interface MergeIsoXmlFileFarmsDict {
    file_name: string;
    dict: MergeIsoXmlFarmsDict;
}

export class AgroAhoUploadIsoXmlStore extends ObservableCustomStore {
    constructor(parent: AgroAhoCardsStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoCardsStore;
    @observable
    mergeFileName: string = '';
    @observable
    mergeIsoXmlFileFarmsDictArr: MergeIsoXmlFileFarmsDict[] = [];
    // @observable
    // mergeFarms1: MergeIsoXmlFarms = null;
    // @observable
    // mergeFarms2: MergeIsoXmlFarms = null;
    mergeResult: MergeIsoXmlResult = null;

    uploadFiles_(files: any){
        console.log('files_:', files);
        let store_ = this.parentStore.parentStore;
        // let res = {'files': [{'file_name': 'TASKDATA.XML', 'fertilizers': {'110': {'fert_id': 110, 'fert_name': 'Аммофос NP 12:52'}}, 'fields': [{'field_id': 21819, 'field_name': 'Поле для ISO тестов', 'poly_cnt': 1, 'area_m2': 46143.0, 'farm_id': 41, 'farm_name': 'Агро', 'crop_id': 191, 'crop_name': 'Сахарная свекла', 'field_order': 1}]}, {'file_name': 'TASKDATA.XML', 'fertilizers': {'110': {'fert_id': 110, 'fert_name': 'Аммофос NP 12:52'}}, 'fields': [{'field_id': 21820, 'field_name': 'ISO_Test_2', 'poly_cnt': 1, 'area_m2': 148085.0, 'farm_id': 41, 'farm_name': 'Агро', 'crop_id': 191, 'crop_name': 'Сахарная свекла', 'field_order': 1}]}], 'fertilizers': {'110': {'fert_id': 110, 'fert_name': 'Аммофос NP 12:52'}}, 'errors': [''], 'merge_id': 'ToM15h3w'};
        let res: any = {
            "files": [
                {
                    "file_name": "TASKDATA.XML",
                    "fertilizers": {
                        "110": {
                            "fert_id": 110,
                            "fert_name": "Аммофос NP 12:52"
                        }
                    },
                    "fields": [
                        {
                            "field_id": 21819,
                            "field_name": "Поле для ISO тестов",
                            "poly_cnt": 1,
                            "area_m2": 46143.0,
                            "farm_id": 41,
                            "farm_name": "Агро",
                            "crop_id": 191,
                            "crop_name": "Сахарная свекла",
                            "field_order": 1
                        }
                    ]
                },
                {
                    "file_name": "TASKDATA.XML",
                    "fertilizers": {
                        "110": {
                            "fert_id": 110,
                            "fert_name": "Аммофос NP 12:52"
                        }
                    },
                    "fields": [
                        {
                            "field_id": 21820,
                            "field_name": "ISO_Test_2",
                            "poly_cnt": 1,
                            "area_m2": 148085.0,
                            "farm_id": 41,
                            "farm_name": "Агро",
                            "crop_id": 191,
                            "crop_name": "Сахарная свекла",
                            "field_order": 1
                        },
                        {
                            "field_id": 21821,
                            "field_name": "ISO_Test_3",
                            "poly_cnt": 1,
                            "area_m2": 149085.0,
                            "farm_id": 41,
                            "farm_name": "Агро",
                            "crop_id": 192,
                            "crop_name": "Свекла",
                            "field_order": 2
                        }
                    ]
                }
            ],
            "fertilizers": {
                "110": {
                    "fert_id": 110,
                    "fert_name": "Аммофос NP 12:52"
                }
            },
            "errors": [],
            "merge_id": "wRDC1d1d"
        };
        // let len = res?.files?.length;
        // if (len != 1 && len != 2) {
        if (!AhoUtils.check_(res, 'MergeIsoXmlResult', isoXmlCfg)) {
            console.log('merging error, files length =', files?.length);
            this.showError(store_.trans['Merge error']);
            return;
        }
        this.mergeResult = res as MergeIsoXmlResult;
        this.updMergeData();
        store_.toggleModal(false);
        store_.toggleModal(true, AhoModalType.MergeIsoXml);
    }

    getGroupFarms(file: MergeIsoXmlFile){
        let gr: MergeIsoXmlFarmsDict = {};
        file.fields.forEach(f=>{
            let id = -1;
            let name = this.parentStore.parentStore.trans['Farm not specified'];
            if (f.farm_id || f.farm_id === 0) {
                id = f.farm_id;
                name = f.farm_name || '_';
            }
            if (!AhoUtils.in(id+'', Object.keys(gr))) gr[id] = {farm_id: id, farm_name: name, fields: []};
            gr[id].fields.push({field_name: f.field_name, area_m2: f.area_m2, field_order: f.field_order,
                field_id: f.field_id});

        });
        Object.keys(gr).forEach(farm_id=>{
            gr[+farm_id].fields.sort((a: any, b: any) => a.field_order - b.field_order);
        });
        return gr;
    }

    updMergeData(){
        this.mergeFileName = '';
        this.mergeIsoXmlFileFarmsDictArr = [];
        let r = this.mergeResult;
        r.files.forEach(f=>{
            let arr: MergeIsoXmlFileFarmsDict = {file_name: f.file_name, dict: this.getGroupFarms(f)};
           this.mergeIsoXmlFileFarmsDictArr.push(arr);
        });
        // this.mergeFile1Name = r.files[0].file_name;
        // this.mergeFarms1 = this.getGroupFarms(r.files[0]);
        // if (r.files.length > 1) {
        //     this.mergeFile2Name = r.files[1].file_name;
        //     this.mergeFarms2 = this.getGroupFarms(r.files[1]);
        // } else {
        //     this.mergeFile2Name = '';
        //     this.mergeFarms2 = null;
        // }
    }

    async uploadFiles(files: any){
        let store_ = this.parentStore.parentStore;
        let req = new XMLHttpRequest();
        let fd = new FormData();

        // console.log('files:', files, files?.length);
        // if (files?.length > 2) {
        //     console.log('upload error, files length =', files?.length);
        //     this.showError(store_.trans['Error, more than 2 files selected']);
        //     return;
        // }
        for (let i=0; i<files.length; i++)
            fd.append("files",files[i]);
        req.open("post", `/api/agro/isoxml/merge/info`);
        req.upload.onprogress = (event) => {
            // this. ahoProgress = Math.round((event.loaded / event.total) * 100);
        }
        req.onerror = () => {
            this.showError();
        }
        req.onload = () => {
            if (req.status !== 200) {
                console.log('upload error', req);
                this.showError();
            } else {
                let res = JSON.parse(req.response);
                let errors: string[] = [];
                if (res?.errors) {
                    res.errors.forEach((err: any)=>{
                        if (err?.fatal == 1) errors.push(err?.msg);
                    });
                    if (errors.length) {
                        this.showError(`${store_.trans['Merge error']}: ${errors.join(', ')}`);
                        return;
                    }
                }
                if (!AhoUtils.check_(res, 'MergeIsoXmlResult', isoXmlCfg)) {
                    console.log('merging error, files length =', files?.length);
                    this.showError(store_.trans['Merge error']);
                    return;
                }
                this.mergeResult = res as MergeIsoXmlResult;
                this.updMergeData();
                store_.toggleModal(false);
                store_.toggleModal(true, AhoModalType.MergeIsoXml);
            }
        };
        req.send(fd);
    }

    showError(msg?: string){
        let store_ = this.parentStore.parentStore;
        store_.root.addError(msg || store_.trans['Loading error']);
        // this.parentStore.toggleModal(false);
    }

    async doMerge(){
        let store_ = this.parentStore.parentStore;
        // let url = `/api/projects/${r[0]}/asa_map/${r[1]}/cell/upload?mode=save&file_id=${r[2]}&cell_id=${r[3]}`;
        let files: any = [];
        this.mergeIsoXmlFileFarmsDictArr.forEach((fd, idx)=>{
            let file: any = {file_num: idx, field_ids: []};
            Object.keys(fd.dict).forEach(farm_id=>{
                let farm = fd.dict[+farm_id];
                farm.fields.forEach(field=>{
                    if (field.selected) file.field_ids.push(field.field_id);
                });
            });
            if (file.field_ids.length) files.push(file);
        });
        let r = [this.mergeResult.merge_id, this.mergeFileName, JSON.stringify(files)];
        let url = `/api/agro/isoxml/merge/action?merge_id=${r[0]}&file_name=${r[1]}&fields=${r[2]}`;
        console.log('url', url);

        Utils.downloadFile(url);
        // return await fetchJsonGet(url).catch(()=>{});
    }

    doSelAllClick(farm: MergeIsoXmlFarm){
        let selAll = farm.fields.every(f=>f.selected);
        farm.fields.forEach(f=>f.selected = !selAll);
    }
}