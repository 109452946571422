// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SoilGridsSourcePallete-divOuter {
  display: flex;
}

.SoilGridsSourcePallete-divLabels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SoilGridsSourcePallete-main {
  border: 1px solid #454C52;
  border-radius: 13px;
  background: #1F252C;
  width: 17px;
  padding: 2px;
  height: 100%;
}

.SoilGridsSourcePallete-inner {
  border-radius: 13px;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/SoilGrids/SoilGridsSourcePallete.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;AAAF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACF;;AACA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;AAEF","sourcesContent":[".SoilGridsSourcePallete-divOuter{\r\n  display: flex;\r\n}\r\n.SoilGridsSourcePallete-divPallete{\r\n}\r\n.SoilGridsSourcePallete-divLabels{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n}\r\n.SoilGridsSourcePallete-main{\r\n  border: 1px solid #454C52;\r\n  border-radius: 13px;\r\n  background: #1F252C;\r\n  width: 17px;\r\n  padding: 2px;\r\n  height: 100%;\r\n}\r\n.SoilGridsSourcePallete-inner{\r\n  border-radius: 13px;\r\n  width: 100%;\r\n  height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
