import React from 'react'
import { SuperStore } from '../../../../store/SuperStore';
import { observer } from 'mobx-react';

interface IProps {
	store: SuperStore; 
  }
const DragDeleteModalComp: React.FC<IProps> = observer(({store} )=> {
  const isLeaf = store.layerListStore.deleteNodeIsLeaf 
  const onDeleteClick = () => {
	const k = store.layerListStore.deleteNodeKey
	store.layerListStore.callHandleDeleteNodeFunction(k);
	store.layerListStore.showDeletePopup = false
  };
  const onReturnClick = () => {
	store.layerListStore.showDeletePopup = false
  }
 
  return (
	<div className='modal-back'>
                    <div className='modal-full modal-full-frame DraggbleOverlaysCompPopup-window'>
                    <div className="DraggbleOverlaysCompPopup-window-title"> {isLeaf? 'Удаление слоя': "Удаление группы слоёв"}</div>
                     {isLeaf? (<div className="DraggbleOverlaysCompPopup-window-sub-title"> Вы уверены, что хотите <strong>удалить</strong> слой из Панели слоёв? </div>):(<div className="DraggbleOverlaysCompPopup-window-sub-title"> Вы уверены, что хотите <strong>удалить</strong> из Панели слоёв группу со вложенными слоями?</div>)}
                    <div className="DraggbleOverlaysComp-separator"></div>
                    <div className="DraggbleOverlaysComp-edit-buttons">
                    <button className="DraggbleOverlaysComp-cancel-button button-jump" 
                    onClick={onReturnClick}>{store.trans.Return}</button>
                    <button className="DraggbleOverlaysComp-delete-button button-jump" 
                    onClick={onDeleteClick}>{store.trans["Delete"]}</button>
                    </div>
                    </div>
                </div>
  )
})

export default DragDeleteModalComp