import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoTableUpload4Comp.scss'
import {action, observable} from "mobx";
import classNames from "classnames";
import {AgroAhoProgressComp} from "./../AgroAhoProgressComp";
import {AgroAhoDownloadFileSvg} from "./../AgroAhoDownloadSvg";
import {Utils} from "../../../../app/helper/utils/Utils";
import {AhoFileLoadStage, AhoLoadStatus} from "../../agroAhoUploadIndcStore";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoStepsComp} from "./AgroAhoStepsComp";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AgroAhoCardTourItemComp} from "../card/AgroAhoCardTourItemComp";
import {AhoUploadTablePath, UploadTableColumn, UploadTableData} from "../../agroAhoUploadTableStore";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoArrowRightSvg} from "../icons/AgroAhoArrowRightSvg";
import {AhoUtils} from "../../agroAhoUtils";
import {AgroAhoTableUploadItem} from "./AgroAhoTableUploadItem";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";

@observer
export class AgroAhoTableUpload4Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickNext(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (ustore.columnCheckError) return;
        ustore.step4NextClick();
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadCancelClick();
    }

    @action
    onClickBack(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadBackClick();
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция. Загрузка таблицы с значениями АХО.pdf`;
        Utils.downloadFile(url);
    }

    @action
    onColumnChange(col: any){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.curIdsColumn = col.data;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let tstore = store_.tourStore;
        let items = ustore.loadedFileData.columns.map(column=> <AgroAhoTableUploadItem key={column.num} store={store} column={column}/>);

        // console.log('render stage 4');

        return <div className={classNames("AgroAhoTableUpload4Comp-main",{
            "AgroAhoTableUpload4Comp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoTableUpload4Comp-cover">
                <div className="AgroAhoTableUpload4Comp-topPanel">
                    <div className="AgroAhoTableUpload4Comp-titleCover">
                        <div className="AgroAhoTableUpload4Comp-title">{store_.trans['Step 4: Configure Compliance']}</div>
                        <AgroAhoStepsComp step={ustore.ahoUploadTableStage}/>
                    </div>
                    {/*<div className="AgroAhoTableUpload4Comp-titleCover">*/}
                    {/*    <div className="AgroAhoTableUpload4Comp-titleStatus">{store_.trans['The file was successfully processed and matches the sample']}</div>*/}
                    {/*    <div className="AgroAhoTableUpload4Comp-titleTourName">{store_.trans.Tour}: {ustore.curTour?.tour_name}</div>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload4Comp-subTitleCover">
                        <div className="AgroAhoTableUpload4Comp-titleFileName">{store_.trans.File}: {tstore.curTour?.tour_file_name}</div>
                        <div className="AgroAhoTableUpload4Comp-titleTourName">{store_.trans.Tour}: {tstore.curTour?.tour_name}</div>
                    </div>
                    <div className="AgroAhoTableUpload4Comp-line"/>
                    <div className="AgroAhoTableUpload4Comp-msg">{store_.trans['Match the columns in the table with the data types and parameters you need. You can choose which columns to load and which not to load. To not load a column, select Data Type = Not Specified.']}</div>
                </div>
                <div className="AgroAhoTableUpload4Comp-tableTitle">
                    <div className="AgroAhoTableUpload4Comp-colIsLoading">{store_.trans['loading?']}</div>
                    <div className="AgroAhoTableUpload4Comp-colTitle">{store_.trans['Column title']}</div>
                    <div className="AgroAhoTableUpload4Comp-colValues">{store_.trans['Values']}</div>
                    <div className="AgroAhoTableUpload4Comp-colDataType">{store_.trans['Data type']}</div>
                    <div className="AgroAhoTableUpload4Comp-colMatching">{store_.trans['Matching']}</div>
                </div>
                <div className="AgroAhoTableUpload4Comp-tableCover style-4">
                    {items}
                </div>
                <div className="AgroAhoTableUpload4Comp-bottomPanel">
                    {/*<div className="AgroAhoTableUpload4Comp-linkSample" onClick={this.onDownloadSampleClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Sample}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload4Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Instruction}</span>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload4Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoTableUpload4Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoTableUpload4Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>

                    <button className="left-sidebar-active button-jump AgroAhoTableUpload4Comp-backButton"
                            onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </button>
                    <button className={classNames("left-sidebar-active AgroAhoTableUpload4Comp-nextButton", {
                        "button-jump": !ustore.columnCheckError,
                        "AgroAhoTableUpload4Comp-disabled": ustore.columnCheckError
                        })} onClick={this.onClickNext}>
                        <span>{store_.trans.Next}</span>
                        <AgroAhoBackSvg isNext={true}/>
                    </button>


                    {/*<div className="AgroAhoTableUpload4Comp-backButton" onClick={this.onClickBack}>*/}
                    {/*    <AgroAhoBackSvg/>*/}
                    {/*    <span>{store.trans.Back}</span>*/}
                    {/*</div>*/}
                    {/*<div className={classNames("AgroAhoTableUpload4Comp-nextButton", {*/}
                    {/*    "AgroAhoTableUpload4Comp-disabled": ustore.columnCheckError*/}
                    {/*    })} onClick={this.onClickNext}>*/}
                    {/*    <span>{store_.trans.Next}</span>*/}
                    {/*    <AgroAhoBackSvg isNext={true}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    }
}
