import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IA2FieldEditorProps} from "./A2FieldEditorCustomInputComp";
import {A2ColumnType} from "../../../../../store/agro/A2ColumnItemStore";
import {A2FieldEditorRefComp} from "./A2FieldEditorRefComp";
import {A2FieldEditorStringComp} from "./A2FieldEditorStringComp";
import {A2FieldEditorDateComp} from "./A2FieldEditorDateComp";

@observer
export class A2FieldEditorALLInputComp extends React.Component<IA2FieldEditorProps, undefined> {
    constructor(props: IA2FieldEditorProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        if (this.props.columnInfo.column_type == A2ColumnType.reference)
            return <A2FieldEditorRefComp field={this.props.field} validType={this.props.validType} isReadonly={this.props.isReadonly}
                                         store={this.props.store} className={this.props.className}
                                         columnInfo={this.props.columnInfo} isMultiEdit={this.props.isMultiEdit}
                                         data={this.props.data} onChange={this.props.onChange}
                                         hasDiffValue={this.props.hasDiffValue} />;
        if (this.props.columnInfo.column_type == A2ColumnType.date)
            return <A2FieldEditorDateComp field={this.props.field} validType={this.props.validType} isReadonly={this.props.isReadonly}
                                          store={this.props.store} className={this.props.className}
                                          columnInfo={this.props.columnInfo} isMultiEdit={this.props.isMultiEdit}
                                          hasDiffValue={this.props.hasDiffValue} data={this.props.data} onChange={this.props.onChange} />;
        if (this.props.columnInfo.column_type == A2ColumnType.string || this.props.columnInfo.column_type == A2ColumnType.number)
            return <A2FieldEditorStringComp field={this.props.field} validType={this.props.validType} isReadonly={this.props.isReadonly}
                                            store={this.props.store} className={this.props.className}
                                            columnInfo={this.props.columnInfo} isMultiEdit={this.props.isMultiEdit}
                                            hasDiffValue={this.props.hasDiffValue} data={this.props.data} onChange={this.props.onChange}
            />;
        return null;
    }
}
