import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ISoilGridsSourceClass} from "../../../../store/config/ConfigStore";
import './SoilGridsSourceClassesComp.scss';
import {observable} from "mobx";
import classNames from "classnames";
import {ra} from "../../../../helper/utils/mobxUtils";

export interface ISoilGridsSourceClassesComp extends IStoreProps{
    classes: ISoilGridsSourceClass[]
}
@observer
export class SoilGridsSourceClassesComp extends React.Component<ISoilGridsSourceClassesComp, undefined> {
    constructor(props: ISoilGridsSourceClassesComp) {
        super(props);
        autoBindReact(this);
    }

    @observable
    highlightIndex: number = null;

    render() {
        let store = this.props.store;
        let items: any[] = [];
        let ths = this;

        let ItemColor = (props: {color: string, highlight: boolean, index: number})=>{
            return <div className="SoilGridsSourceClassesComp-item"
                        onMouseEnter={()=>{ra(()=>{ths.highlightIndex = props.index;})}}
                        onMouseLeave={()=>{
                            ra(()=>{
                                //if (ths.highlightIndex == props.index) ths.highlightIndex = null;
                            })
                        }}
            >
                <div style={{background: props.color}}
                    className={classNames("SoilGridsSourceClassesComp-itemColor",
                    {"SoilGridsSourceClassesComp-itemColorHighlight": props.highlight})} />
            </div>;
        };
        this.props.classes.forEach((a, index) =>{
            items.push(<ItemColor key={index} color={a.color} highlight={index == this.highlightIndex} index={index} />);
        });
        let text = "Hover over a class to view its label";
        if (this.highlightIndex != null){
            text = this.props.classes[this.highlightIndex].title;
        }


        return <div className="SoilGridsSourceClassesComp-main">
            <div className="SoilGridsSourceClassesComp-title">Legend (most probable)</div>
            <div className="SoilGridsSourceClassesComp-colorBox">{items}</div>
            <div className={classNames("SoilGridsSourceClassesComp-description",
                {"SoilGridsSourceClassesComp-descriptionGray": this.highlightIndex == null})}>{text}</div>
        </div>;
    }
}
