// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A2ConfirmCreateFormComp-select-type {
  margin-top: 15px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.A2ConfirmCreateFormComp-select-type-line {
  display: flex;
  column-gap: 10px;
}

.A2ConfirmCreateFormComp-select-type-line-text {
  align-content: center;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.A2ConfirmCreateFormComp-stats {
  display: flex;
  row-gap: 10px;
  border-top: 1px solid #22282F;
  flex-direction: column;
  padding-top: 20px;
}

.A2ConfirmCreateFormComp-buttons {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/A2Common/A2ConfirmCreateFormComp.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kCAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,6BAAA;EACA,sBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,yBAAA;EACA,gBAAA;AACJ","sourcesContent":[".A2ConfirmCreateFormComp-select-type {\n    margin-top: 15px;\n    margin-bottom: 25px;\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n}\n\n.A2ConfirmCreateFormComp-select-type-line {\n    display: flex;\n    column-gap: 10px;\n}\n\n.A2ConfirmCreateFormComp-select-type-line-text {\n    align-content: center;\n    font-family: Open Sans;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n}\n\n.A2ConfirmCreateFormComp-stats {\n    display: flex;\n    row-gap: 10px;\n    border-top: 1px solid #22282F;\n    flex-direction: column;\n    padding-top: 20px;\n}\n\n.A2ConfirmCreateFormComp-buttons {\n    padding: 15px;\n    display: flex;\n    justify-content: flex-end;\n    column-gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
