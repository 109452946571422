import * as React from 'react';
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {observer} from "mobx-react";
import {action} from "mobx";
import {SearchItemGroup} from "../../../../store/SearchItemGroup";
import {SceneMosaicSwitch} from "../../../../store/SearchStore";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {SearchSceneItemImgComp} from "./SearchSceneItemImgComp";
import {SearchSceneImgTooBigComp} from "./SearchSceneImgTooBigComp";
import {SearchObjectStore} from "../../../../store/SearchObjectStore";
import {SearchSceneMiniMap2Comp} from "./SearchSceneMiniMap2Comp";
import {SearchSceneItemPropertyComp} from "./SearchSceneItemPropertyComp";
import {Satellite} from "../../../../helper/utils/satellliteDic";
import {SearchSceneItemPropertyS1Comp} from "./SearchSceneItemPropertyS1Comp";


export interface ISearchItemComp extends IStoreProps{
    searchGroup: SearchItemGroup
}

@observer
export class SearchSceneItemComp extends React.PureComponent<ISearchItemComp, undefined> {
    constructor(props: ISearchItemComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        if (!this.isClickable()) return;
        let store = this.props.store;
        if (this.showTooBig()) return;
        if (store.searchPanel.currentSceneid == this.props.searchGroup.first().feature.properties.scene_id){
            store.searchPanel.currentSceneid = null;
        }else {
            store.searchPanel.currentSceneid = this.props.searchGroup.first().feature.properties.scene_id;
        }
        store.searchPanel.currentSceneVisible = true;//насильно включаем видимость.
    }
    isClickable(): boolean{
        let sg = this.props.searchGroup;
        let it = sg.first().feature.properties;
        if (it.satellite == Satellite["Sentinel-1"] && it.ql_exists == false){
            return false;
        }
        return true;
    }

    @action
    onMouseOver() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = this.props.searchGroup.groupId();
    }

    @action
    onMouseEnter() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = this.props.searchGroup.groupId();
    }

    @action
    onMouseLeave() {
        let store = this.props.store;
        store.searchPanel.hoverSceneid = null;
    }

    showTooBig(): boolean {
        let store = this.props.store;
        return store.map.searchObject.isNotEmpty &&
            store.map.searchObject.getMaxSideLengthKm >= SearchObjectStore.MaxSideForSearchKm &&
            store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.mosaic;
    }

    @action
    EndAnimateFavIcon(){
        let si = this.props.searchGroup;
        si.first().animate_fav_icon = false;
    }

    public static preview_error_url = "./img/preview_not_available.svg";

    render() {
        let store = this.props.store;
        let sg = this.props.searchGroup;
        let it = sg.first().feature.properties;
        let isCurrent = sg.isCurrent();
        sg.wasVisible = true;

        let leafleatImg = (store.map.searchObject.isNotEmpty)
            || store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.mosaic;
        let showTooBig = this.showTooBig();

        return <div className="left-sidebar__right-bottom__row4-in" onClick={this.onClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
                    id={it.scene_id}>
            <div className={classNames("left-sidebar__right-bottom__row4-in-left relative pointer", {"blue-active": isCurrent})} >
                {/*
                {it.satellite == Satellite["Sentinel-1"] && store.searchPanel.sceneMosaicSwitch == SceneMosaicSwitch.scene
                && <SearchSceneItemImgComp searchGroup={this.props.searchGroup} store={store}
                                           className={classNames("left-sidebar__right-bottom__row4-in-left-in img-radius")}/>
                }
                {it.satellite == Satellite["Sentinel-1"] && store.searchPanel.sceneMosaicSwitch != SceneMosaicSwitch.scene
                && <img src={SearchSceneItemComp.preview_error_url} className="preview_error img-radius"
                        alt="Quicklook is not found" title={store.trans["Quicklook is not found"]} />
                }*/}

                {/*it.satellite != Satellite["Sentinel-1"] &&*/
                <React.Fragment>
                    {showTooBig && <SearchSceneImgTooBigComp store={store} /> }
                    {!showTooBig &&
                    <React.Fragment>
                        {!leafleatImg && <SearchSceneItemImgComp searchGroup={this.props.searchGroup} store={store}
                                                                 className={classNames("left-sidebar__right-bottom__row4-in-left-in img-radius")}
                        />}
                        {leafleatImg && <SearchSceneMiniMap2Comp store={store} searchGroup={this.props.searchGroup}
                                                                 className="left-sidebar__right-bottom__row4-in-left-in img-radius"
                        />}

                    </React.Fragment>}
                </React.Fragment>
                }
            </div>
            {it.satellite != Satellite["Sentinel-1"] && <SearchSceneItemPropertyComp searchGroup={this.props.searchGroup} store={store} showTooBig={showTooBig} />}
            {it.satellite == Satellite["Sentinel-1"] && <SearchSceneItemPropertyS1Comp searchGroup={this.props.searchGroup} store={store} />}
        </div>;
    }
}
