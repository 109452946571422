import React, {useState} from "react";
import {
    ContextMenuCommonComp,
    PopupCorner
} from "../../../Common/ContextMenuCommonComp";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {IndexByPointTableComp} from "./IndexByPointTableComp";
import {DropDownComp} from "../../../Common/DropDownComp";
import {observer} from "mobx-react";
import {ColorHelper} from "../../../../helper/utils/ColorHelper";
import classNames from "classnames";
import "./IndexByPointColorPickerCom.scss";
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {getShortNameOfIndexDataType} from "../../../../store/indeByPoint/IndexByPointerStore";
import {LoadingAniSvg} from "../../../icons/LoadingAniSvg";
import { IndexByPointYearStore } from "../../../../store/indeByPoint/IndexByPointYearStore";
interface IIndexByPointColorPickerComProps extends IStoreProps {
    isScenario?: boolean;
}
const IndexByPointColorPickerCom: React.FC<IIndexByPointColorPickerComProps> =
    observer(({store, isScenario = false}) => {
        const [isOpen, setIsOpen] = useState(false);
        const predefinedColors = store.indexByPointer.predefinedColors;

        const toggleOpen = () => {
            setIsOpen(!isOpen);
        };

        const handleDropDownChange = (item: any) => {
            store.indexByPointer.setColorMode(item.key);
            assignColors(item.key);
        };

        const assignColors = (mode: string) => {
            let colorIndex = 0;

            if (mode === "everyYear") {
                const yearColorMap: Partial<Record<number | "climate", string>> = {};
        
        store.indexByPointer.yearPoints.forEach((yearPoint) => {
            const yearKey = yearPoint.yearPeriod.isClimate ? "climate" : yearPoint.yearPeriod.year;

          
            if (!yearColorMap[yearKey]) {
               
                if (yearKey === "climate") {
                    yearColorMap[yearKey] = predefinedColors[0];
                } else {
                    yearColorMap[yearKey] = predefinedColors[colorIndex % predefinedColors.length];
                    colorIndex++;
                }
            }

            applyColorToYearPoint(yearPoint, yearColorMap[yearKey]);
        });
            } else if (mode === "everyPoint") {
                store.indexByPointer.points.forEach((point) => {
                    const pointColor =
                        predefinedColors[
                            colorIndex % predefinedColors.length
                        ];
                    colorIndex++;
                    point.yearPoints.forEach((yearPoint) => {
                        applyColorToYearPoint(yearPoint, pointColor);
                    });
                });
            } else if (mode === "everyChar") {
                store.indexByPointer.yearPoints.forEach(
                    (yearPoint) => {
                        const uniqueColor =
                            predefinedColors[
                                colorIndex % predefinedColors.length
                            ];
                        colorIndex++;
                        applyColorToYearPoint(yearPoint, uniqueColor);
                    }
                );
            }
        };

        const applyColorToYearPoint = (
            yearPoint:IndexByPointYearStore,
            color: string
        ) => {
            const parsedColor = ColorHelper.parseColor(color);
            const numericColor =
                parsedColor.r * 65536 +
                parsedColor.g * 256 +
                parsedColor.b;
        
            yearPoint.setCustomColor(numericColor);
        
            if (yearPoint.graphs) {
                yearPoint.graphs.forEach((graph: any) => {
                    graph.color = numericColor;
                });
            }
        };

        const swItems = [
            {key: "everyPoint", value: store.trans["Every point - new color"]},
            {key: "everyYear", value: store.trans["Every year - new color"]},
            {key: "everyChar", value: store.trans["Every char - new color"]}
        ];
        const isCompare = store.indexByPointer.compareSources;
        let ibp = store.indexByPointer;

        let graphs = ibp.graphs.filter(a => a.visible);
        let arr: any[] = graphs.map(a => {
            return (
                <div
                    style={{
                        alignItems: "center",
                        gap: 0,
                        color: "#C5C5C5"
                    }}
                    className='flex-columns-centerByVertical'
                    key={a.id}
                >
                    {a.status == LoadStatus.loading && (
                        <LoadingAniSvg />
                    )}
                    {a.status == LoadStatus.ready && (
                        <div
                            style={{
                                width: "17px",
                                height: "5px",
                                boxSizing: "border-box",
                                borderRadius: "4px",
                                background:
                                    ColorHelper.numToCssHexRgb(
                                        a.colorByDataType
                                    )
                            }}
                        />
                    )}
                    <div className='text-next text-prev'>
                        {getShortNameOfIndexDataType(
                            a.dataType,
                            store
                        )}
                    </div>
                </div>
            );
        });

        return (
            <div
                className='indexByPoint_headerline-button indexByPoint_headerline-marginLeftButton indexByPoint_headerline-colorButton'
                onClick={toggleOpen}
                style={{borderColor:isOpen?' #4DB6BC':'#3E4751'}}
            >
                <svg
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle
                        cx='2.5'
                        cy='2.5'
                        r='2.5'
                        fill='#EC5151'
                    />
                    <circle
                        cx='2.5'
                        cy='9.5'
                        r='2.5'
                        fill='#51EC78'
                    />
                    <circle
                        cx='9.5'
                        cy='2.5'
                        r='2.5'
                        fill='#ECC051'
                    />
                    <circle
                        cx='9.5'
                        cy='9.5'
                        r='2.5'
                        fill='#516DEC'
                    />
                </svg>
                {isOpen && !isCompare && (
                    <ContextMenuCommonComp
                        indentVertical={5}
                        popupCorner={PopupCorner.rightBottom}
                        className={classNames(
                            "custom-context-menu",
                            {}
                        )}
                        onClose={() => toggleOpen()}
                    >
                        <div className='custom-context-title-scenario'>
                            {isScenario
                                ? store.trans["Scenario"]
                                : store.trans[
                                      "Distribution of colors"
                                  ]}
                        </div>
                        <DropDownComp
                            items={swItems}
                            currentKey={
                                store.indexByPointer.colorMode
                            }
                            onChange={handleDropDownChange}
                            className='indexByPoint-switchModeDropdown width100 margin-bottom15'
                        />
                        <IndexByPointTableComp
                            store={store}
                            isColor
                        />
                    </ContextMenuCommonComp>
                )}
                {isOpen && isCompare && (
                    <ContextMenuCommonComp
                        indentVertical={5}
                        popupCorner={PopupCorner.rightBottom}
                        className={classNames("custom-context-menu", {
                            "custom-context-menu-compare": isCompare
                        })}
                        onClose={() => toggleOpen()}
                    >
                        <div className='custom-context-title-scenario'>
                            {store.trans["Colors settings"]}
                        </div>
                        <div className='flex-columns-centerByVertical flex-stretch-item '>
                            {arr}
                        </div>
                    </ContextMenuCommonComp>
                )}
            </div>
        );
    });

export default IndexByPointColorPickerCom;
