import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {action} from "mobx";
import {FeatureCollection} from "geojson";
import {Utils} from "../../../../helper/utils/Utils";
import bboxPolygon from "@turf/bbox-polygon";
import {LngLatBounds} from "maplibre-gl";

@observer
export class SearchScenePlugComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    static readonly K_SCREEN = 0.01;

    @action
    onClickMerge(){
        let map = this.props.store.map;
        let bb = map.mapbox.getBounds();
        let b:LngLatBounds = Utils.getInnerBounds(bb, SearchScenePlugComp.K_SCREEN);
        let g = bboxPolygon([b.getNorthEast().lng, b.getNorthEast().lat, b.getSouthWest().lng, b.getSouthWest().lat]).geometry;


        let fc: FeatureCollection = {type: "FeatureCollection", features: [{type: "Feature", properties: {}, geometry: g}]};
        this.props.store.map.searchObject.content = fc;
    }
    render() {
        let store = this.props.store;
        return <div>
            <div style={{fontWeight: 400, fontSize: "13px", marginTop: "56px"}} className="flex-full-center"
            >{store.trans["Merge scenes into single dates within current map extent"]}</div>
            <div className="flex-full-center" style={{marginTop: "20px"}}>
                <div style={{padding: "14px 20px", fontWeight: 600, fontSize: "12px", lineHeight: "12px", border: "1px solid", borderRadius: "10px"}}
                     className="blue-border-button" onClick={this.onClickMerge}>
                    {store.trans["Merge by dates"]}
                </div>
            </div>
            <div style={{padding:"60px 30px 0", color: "#595D62", fontWeight: 400, fontSize: "13px", lineHeight: "22px",alignItems: "center", textAlign: "center"}}>
            {TranslateUtils.createSpansWithReplaceMarkers(
                store.trans["To combine scenes from the same date, create a hotspot at the bottom of the Image search options panel {0}"],

                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <path d="M8.68164 2.77271H13.9998" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 2.77271H2.77273" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13.4102 7.5H14.0011" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 7.5H7.5" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.68164 12.2273H13.9998" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 12.2273H2.77273" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.54616 4.54545C5.52521 4.54545 6.31889 3.75178 6.31889 2.77273C6.31889 1.79368 5.52521 1 4.54616 1C3.56711 1 2.77344 1.79368 2.77344 2.77273C2.77344 3.75178 3.56711 4.54545 4.54616 4.54545Z" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.27273 9.27275C10.2518 9.27275 11.0455 8.47907 11.0455 7.50002C11.0455 6.52097 10.2518 5.72729 9.27273 5.72729C8.29368 5.72729 7.5 6.52097 7.5 7.50002C7.5 8.47907 8.29368 9.27275 9.27273 9.27275Z" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.54616 14C5.52521 14 6.31889 13.2063 6.31889 12.2273C6.31889 11.2482 5.52521 10.4545 4.54616 10.4545C3.56711 10.4545 2.77344 11.2482 2.77344 12.2273C2.77344 13.2063 3.56711 14 4.54616 14Z" stroke="#C5C5C5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>)
            }
        </div>
        </div>;
    }
}
