import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './Agro2CreateField_CreateGeomButton.scss';
import {action, observable} from "mobx";
import {ContextMenuCommonComp} from "../../../../Common/ContextMenuCommonComp";
import {MeasuringStatus} from "../../../../../helper/structs/MeasuringStatus";
import {GeometryUtils, SimpleGeometryType} from "../../../../../helper/utils/GeometryUtils";
import classNames from "classnames";
import {ButtonGeometryCreateType} from "../../../../../store/agro/fieldForm/A2FieldFormStore";
import {ProjectGeometryType} from "../../../../../store/user/UserStore";
import {CreateGeometryType} from "../../../../../store/tools/general/ContainerTools";

export interface IAgro2CreateField_CreateGeomButton extends IStoreProps{
    required: boolean,
    validGeometry: boolean,
}

@observer
export class Agro2CreateField_CreateGeomButton extends React.Component<IAgro2CreateField_CreateGeomButton, undefined> {
    constructor(props: IAgro2CreateField_CreateGeomButton) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClosePopup(){
        this.props.store.agro2.fieldEditorForm.createGeometryButtonDown = false;
    }
    @action
    onClick(){
        let s = this.getState();
        if (Array.from(s.buttons.values()).filter(a => a).length == 0) return;
        this.props.store.agro2.fieldEditorForm.createGeometryButtonDown = true;
    }

    rectSvg(){
        return <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="4.57764e-05" width="5" height="5"/>
            <rect width="5" height="5" transform="matrix(-1 0 0 1 16 4.57764e-05)"/>
            <rect y="13" width="5" height="5"/>
            <rect x="13" y="13" width="5" height="5"/>
            <rect x="2" y="12.0388" width="6.35" height="1" transform="rotate(-71.9858 2 12.0388)"/>
            <rect width="6.35" height="1" transform="matrix(-0.309253 -0.95098 -0.95098 0.309253 16 12.0388)"/>
            <rect x="6" y="15" width="6" height="1" />
            <rect x="8" y="2.00005" width="2" height="1" />
        </svg>;
    }
    lineSvg(){
        return <svg width="18" height="18" viewBox="0 0 18 18" className="Agro2CreateField_CreateGeomButton-arrow"  xmlns="http://www.w3.org/2000/svg">
            <rect width="5" height="5" transform="matrix(-1 0 0 1 18 0)" />
            <rect y="13" width="5" height="5" />
            <rect x="5" y="11.8826" width="9.49909" height="1" transform="rotate(-46.4317 5 11.8826)" />
        </svg>
    }
    pointSvg(){
        return <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="5"/>
        </svg>;
    }

    arrowSvg(){
        return <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.0002 5.55L0.950195 1.5L2.0002 0.449997L5.0002 3.45L8.0002 0.449997L9.05019 1.5L5.0002 5.55Z" />
        </svg>;
    }
    getSingleButton(): ButtonGeometryCreateType{
        let state = this.getState();
        return state.cur;
    }
    @action
    onClickSingle(){
        let state = this.getState();
        this.props.store.agro2.fieldEditorForm.createGeometryButtonDown = false;
        if (state.isActive){
            if (state.cur == ButtonGeometryCreateType.auto)
                this.props.store.agro2.fieldToolController.autoPolygon.applyAll();
            this.props.store.map.resetMeasuringStatus();
            return;
        }

        this.setCreateType(this.getSingleButton());
    }
    @action
    setCreateType(bt: ButtonGeometryCreateType){
        let state = this.getState();
        if (bt == null) return;
        if (!state.buttons.get(bt)) return;
        let store = this.props.store;
        let form = store.agro2.fieldEditorForm;
        form.lastButton = bt;
        form.createGeometryButtonDown = false;
        let g = form.getGeometry();

        if (g != null){
            let tps = GeometryUtils.getSimpleTypes(GeometryUtils.getSimpleGeometries(g));
            if (bt == ButtonGeometryCreateType.polygon || bt == ButtonGeometryCreateType.auto){
                if (!tps.find(a => a == SimpleGeometryType.Polygon)) form.setGeoemtry(null);
            }
            if (bt == ButtonGeometryCreateType.line){
                if (!tps.find(a => a == SimpleGeometryType.Line)) form.setGeoemtry(null);
            }
            if (bt == ButtonGeometryCreateType.point){
                if (!tps.find(a => a == SimpleGeometryType.Point)) form.setGeoemtry(null);
            }
        }
        if (bt == ButtonGeometryCreateType.auto) store.map.setMeasuringStatus(MeasuringStatus.agroAutoPolygon);
        if (bt == ButtonGeometryCreateType.polygon) {
            store.agro2.editManager.state.createGeometryType = CreateGeometryType.Polygon;
            store.map.setMeasuringStatus(MeasuringStatus.agroCreatePolygon);
        }
        if (bt == ButtonGeometryCreateType.line) {
            store.agro2.editManager.state.createGeometryType = CreateGeometryType.Line;
            store.map.setMeasuringStatus(MeasuringStatus.agroCreateLine);
        }
        if (bt == ButtonGeometryCreateType.point) {
            store.agro2.editManager.state.createGeometryType = CreateGeometryType.Point;
            store.map.setMeasuringStatus(MeasuringStatus.agroCreatePoint);
        }
    }
    onClickPolygon(){
        this.setCreateType(ButtonGeometryCreateType.polygon);
    }
    onClickAutoPolygon(){
        this.setCreateType(ButtonGeometryCreateType.auto);
    }
    onClickPoint(){
        if (!this.getState().buttons.get(ButtonGeometryCreateType.point)) return;
        this.props.store.agro2.fieldEditorForm.createGeometryButtonDown = false;
        this.setCreateType(ButtonGeometryCreateType.point);
    }
    onClickLine(){
        if (!this.getState().buttons.get(ButtonGeometryCreateType.line)) return;
        this.props.store.agro2.fieldEditorForm.createGeometryButtonDown = false;
        this.setCreateType(ButtonGeometryCreateType.line);
    }

    getState(): {buttons: Map<ButtonGeometryCreateType, boolean>, cur: ButtonGeometryCreateType, isActive: boolean}{
        let store = this.props.store;

        let dic: Map<ButtonGeometryCreateType, boolean> = new Map<ButtonGeometryCreateType, boolean>();

        dic.set(ButtonGeometryCreateType.polygon, true);
        dic.set(ButtonGeometryCreateType.line, true);
        dic.set(ButtonGeometryCreateType.point, true);
        dic.set(ButtonGeometryCreateType.auto, true);
        if (store.agro2.projectInfo.projectInfoJson?.type_info?.type_schema?.obj?.table?.geometry.types){
            let q = store.agro2.projectInfo.projectInfoJson?.type_info?.type_schema?.obj?.table?.geometry.types;
            if (!q.find(a => a.name == ProjectGeometryType.point)) dic.set(ButtonGeometryCreateType.point, false);
            if (!q.find(a => a.name == ProjectGeometryType.line)) dic.set(ButtonGeometryCreateType.line, false);
            if (!q.find(a => a.name == ProjectGeometryType.polygon)) dic.set(ButtonGeometryCreateType.polygon, false);
        }
        store.pluginsApi.onGetAgroNewGeometryTypes({buttons: dic});
        let isActive: boolean = false;

        let ms = this.props.store.map.measuringStatus;
        let cur: ButtonGeometryCreateType = null;
        if (ms == MeasuringStatus.agroCreatePoint) {cur = ButtonGeometryCreateType.point; isActive = true;}
        if (ms == MeasuringStatus.agroCreateLine) {cur = ButtonGeometryCreateType.line; isActive = true;}
        if (ms == MeasuringStatus.agroCreatePolygon) {cur = ButtonGeometryCreateType.polygon; isActive = true;}
        if (ms == MeasuringStatus.agroAutoPolygon) {cur = ButtonGeometryCreateType.auto; isActive = true;}

        if (cur == null) {
            if (store.agro2.fieldEditorForm.lastButton) {
                cur = store.agro2.fieldEditorForm.lastButton;
                isActive = false;
            } else {
                if (dic.get(ButtonGeometryCreateType.polygon)) {
                    cur = ButtonGeometryCreateType.polygon;
                    isActive = false;
                }
                if (dic.get(ButtonGeometryCreateType.line)) {
                    cur = ButtonGeometryCreateType.line;
                    isActive = false;
                }
                if (dic.get(ButtonGeometryCreateType.point)) {
                    cur = ButtonGeometryCreateType.point;
                    isActive = false;
                }
            }
        }

        return { buttons: dic, cur: cur, isActive:  isActive};
    }

    render() {
        let state = this.getState();
        let countButtons = Array.from(state.buttons.values()).filter(a => a).length;
        let validGeometry = this.props.validGeometry;
        let icon: any = null;
        if (state.cur == ButtonGeometryCreateType.polygon) icon = this.rectSvg();
        if (state.cur == ButtonGeometryCreateType.auto)
            icon = <span className={classNames('Agro2CreateField_CreateGeomButton-AutoAI', {'active': state.isActive})}>AUTO</span>;
        if (state.cur == ButtonGeometryCreateType.line) icon = this.lineSvg();
        if (state.cur == ButtonGeometryCreateType.point) icon = this.pointSvg();

        return <div>
            <div className={classNames("Agro2CreateField_CreateGeomButton",{"active": state.isActive, "invalid": !validGeometry})}>
                <div className="Agro2CreateField_CreateGeomButton-part1" onClick={this.onClickSingle}>
                    {icon}
                </div>
                <div className="Agro2CreateField_CreateGeomButton-part2" onClick={this.onClick}>
                    {this.arrowSvg()}
                </div>
                {this.props.required && <div className={classNames("Agro2Common-createFerm-star blue-text Agro2CreateField_CreateGeomButtonStar",{"invalid": !validGeometry})}>
                    <span style={{position: "relative", top: "2px"}}>*</span>
                </div>}
            </div>
            {this.props.store.agro2.fieldEditorForm.createGeometryButtonDown && <ContextMenuCommonComp className="Agro2CreateField_CreateGeomButton-dropDownList" onClose={this.onClosePopup}>
                <div className={classNames("Agro2CreateField_CreateGeomButton-dropDownRow",{"disabled": !state.buttons.get(ButtonGeometryCreateType.polygon)})} onClick={this.onClickPolygon}>
                    {this.rectSvg()}
                </div>
                {state.buttons.get(ButtonGeometryCreateType.auto) && <div className={classNames("Agro2CreateField_CreateGeomButton-dropDownRow",{"disabled": !state.buttons.get(ButtonGeometryCreateType.auto)})} onClick={this.onClickAutoPolygon}>
                    Auto
                </div>}
                <div className={classNames("Agro2CreateField_CreateGeomButton-dropDownRow",{"disabled": !state.buttons.get(ButtonGeometryCreateType.line)})} onClick={this.onClickLine}>
                    {this.lineSvg()}
                </div>
                <div className={classNames("Agro2CreateField_CreateGeomButton-dropDownRow",{"disabled": !state.buttons.get(ButtonGeometryCreateType.point)})} onClick={this.onClickPoint}>
                    {this.pointSvg()}
                </div>
            </ContextMenuCommonComp>}
        </div>;
    }
}
