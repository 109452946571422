import {CustomStore, ObservableCustomStore} from "../../CustomStore";
import {IntervalStore} from "../../IntervalStore";
import {fetchJsonGet} from "../../../helper/utils/FetchUtils";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {observable} from "mobx";
import {ra} from "../../../helper/utils/mobxUtils";
import {LogOperation} from "./LogViewStore";
import {IReactionDisposer} from "mobx/lib/internal";

interface IUserForLog{
    user_id: number,
    email: string,
    "given_name": string,
    "surname": string
}
export class LogViewFilterStore extends ObservableCustomStore{
    @observable
    filter_userId: number = null;
    filter_userBadgetText: string = null;
    @observable
    filter_operation: LogOperation = null;

    interval: IntervalStore = new IntervalStore(this);

    @observable
    user_LoadStatus: LoadStatus;
    @observable
    users: IUserForLog[] = [];


    countFilters(): number{
        let cnt = 0;
        if (this.filter_operation != null) cnt++;
        if (this.filter_userId != null) cnt++;
        if (this.interval.isValid()) cnt++;
        return cnt;
    }
    isAllOperations(): boolean{
        return this.filter_operation == null;
    }

    isAllUsers(): boolean{
        return this.filter_userId == null;
    }

    async doLoadUserList(){
        let url = `/api/projects/${this.root.agro.projectName}/action/users`;
        let r: {users: IUserForLog[]} = await fetchJsonGet(url);
        this.user_LoadStatus = LoadStatus.loading;
        try{
            ra(()=>{
                this.user_LoadStatus = LoadStatus.ready;
                this.users = r.users;
            });
        }catch (e) {
            ra(()=>{
                this.user_LoadStatus = LoadStatus.ready;
                this.users = [];
                this.root.addError(e);
            });
        }
    }

}