import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {observable} from "mobx";
import {LinePaint, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {RecNormKey} from "../agroAhoCardStore";
import {AgroAhoMapStore, IAhoLayers} from "./agroAhoMapStore";
import {RecNormItem, RNGroup} from "../card/agroAhoCardRN";
import {AhoUtils} from "../agroAhoUtils";


export class AgroAhoMapRNStore extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;
    @observable
    legendRNShow: boolean = false;
    lastRNKey: RecNormKey;
    lastRNId: number;
    @observable
    curRecNormItem: RecNormItem = null;
    rnForRemoval: RecNormItem = null;
    @observable
    rn_groups: RNGroup[] = [];
    // @observable
    // rn_info: RNInfo[] = [];

    getRecNormSourceId(ds: string): string{
        return `${ds}_aho_rn_src`;
    }
    // getRecNormLabelLayerId(ds: string): string{
    //     // return `${ds}_aho_rn_lbl`;
    //     return 'class_agroAho_rn_lbl_aho';
    // }
    // getRecNormLayerId(key: RecNormKey): string{
    //     // return `${key.ds}_${key.rn_id}_aho_rn`;
    //     return 'class_agroAho_rn_aho';
    // }
    // getRecNormLineLayerId(key: RecNormKey): string{
    //     // return `${key.ds}_${key.rn_id}_aho_rn_line`;
    //     return 'class_agroAho_rn_line_lyr_aho';
    // }
    getRNCellSourceId(key: RecNormKey): string{
        return `${key.ds}_${key.rn_id}_aho_cell_rn_src`;
    }

    get rnKey(): RecNormKey {
        let cs = this.mapStore.parentStore.cardStore;
        let ds = cs.card?.cell_info?.ds_code;
        let rn_id = this.curRecNormItem?.rn_id;
        // console.log('rnKey', ds, rn_id);
        if (!ds || !rn_id) return;
        return {ds: ds, rn_id: 'rn_' + rn_id};
    }
    async updRNSource(ds: string){
        // console.log('upd rn source', ds);
        let data = await this.mapStore.tourStore.getDataJSON(ds, IAhoLayers.Cell);
        let map = this.root.map.mapbox;
        let src: any = map.getSource(this.getRecNormSourceId(ds));
        if (!data || !src) return;
        // if (JSON.stringify(data) == JSON.stringify(src?._options?.data))
        //     await src.setData({'type': 'FeatureCollection', 'features': []});
        // console.log('data', JSON.stringify(data) == JSON.stringify(src._options.data), AhoUtils.cp(data), AhoUtils.cp(src._options.data));
        src.setData(data);
    }
    async updRNTours(key: RecNormKey){
        let data = await this.mapStore.tourStore.getDataJSON(key.ds, IAhoLayers.Cell);
        let map = this.root.map.mapbox;
        let src: any = map.getSource(this.getRNCellSourceId(key));
        if (!data || !src) return;
        src.setData(data);
    }

    deleteRecNormLabelLayer(ds: string){
        // console.log('del rn label layer', ds);
        if (!ds) return;
        let map = this.root.map.mapbox;
        let layer_id =  this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.RnLbl); // this.getRecNormLabelLayerId(ds);
        // console.log('rn lbl layer id', layer_id);
        if (map.getLayer(layer_id)){
            // console.log('rn lbl layer id found', layer_id);
            map.removeLayer(layer_id);
        }
    }

    rnKeyChanged(){
        return JSON.stringify(this.lastRNKey) !== JSON.stringify(this.rnKey);
    }

    rnIDChanged(){
        // let cstore = this.mapStore.parentStore.cardStore;
        return this.lastRNId !== this.curRecNormItem.rn_id;
    }

    async addRecNormLabelLayer(ds: string){
        if (!ds) return;
        let data = await this.mapStore.tourStore.getDataJSON(ds, IAhoLayers.Cell);
        // console.log('add rn label layer', ds);
        // console.log('rn data', data);
        if (!data) return;
        let map = this.root.map.mapbox;
        this.deleteRecNormLabelLayer(ds);
        let layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.RnLbl);// this.getRecNormLabelLayerId(ds);
        // console.log('add rn layer id', layer_id);
        let source_id = this.getRecNormSourceId(ds);
        if (!map.getSource(source_id)){
            if (typeof data != 'object' || !data.type) return;
            map.addSource(source_id,{
                type: 'geojson',
                data: data
            });
        }
        // await this.updRNOpacity_();
        // -----------------------------------------------------
        let cs = this.mapStore.parentStore.cardStore;
        let code = `rn_${this.curRecNormItem.rn_id}`;
        // console.log('code label:', code);
        // console.log('rn layer add id', layer_id);
        if (!map.getLayer(layer_id)) {
            // console.log('add label indc, prev layer:', this.mapStore.getPrevLayer(layer_id));
            setTimeout( async ()=>{
            map.addLayer({
                id: layer_id,
                source: source_id,
                type: 'symbol',
                layout:<SymbolLayout>{
                    'text-field': ['get', code],
                    'text-font': [
                        'Open Sans Semibold'
                    ],
                    'text-anchor': 'center',
                    "text-allow-overlap": true,
                    "text-size": 14
                },
                paint: <SymbolPaint>{
                    "text-color": 'black',
                    "text-halo-color": "white",
                    "text-halo-width": 1,
                    "text-opacity": [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                        ['feature-state', 'opacity'],
                        ['==',['feature-state', 'opac'], 1],
                        this.mapStore.unselectedIndcOpacity,
                        ['!=', ['typeof', ['get', code]], 'number'],
                        0,
                        1
                    ]
                }
            // }, this.mapStore.getPrevLayer(layer_id)); }, 50);
            }, await this.mapStore.getPrevLayer(IAhoLayers.RnLbl)); }, 0);
        }
        // -----------------------------------------------------

    }

    deleteRecNormLayer(key: RecNormKey){
        if (!key) return;
        // console.log('del rn layer', this.getRecNormLayerId(key));
        let map = this.root.map.mapbox;
        let layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.Rn);
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
        layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.RnLine);
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
        // if (map.getLayer(this.getRecNormLayerId(key))){
        //     map.removeLayer(this.getRecNormLayerId(key));
        // }
        // if (map.getLayer(this.getRecNormLineLayerId(key))){
        //     map.removeLayer(this.getRecNormLineLayerId(key));
        // }
    }

    async updRNOpacity(field_id: number, checked: boolean, selected: boolean){
        // console.log('updRNOpacity');
        let mstore = this.mapStore;
        if (!this.rnKey?.ds) return;
        // console.log('before get geojson updIndcsOpacity', this.card.cell_info.ds_code);
        let data: any = await mstore.getCellCardGeoJSONs({ds: this.rnKey.ds, no_geom: true,
            type: IAhoLayers.Cell});
        // console.log('updIndcsOpacity data', field_id, data, data?.features);
        if (!data?.features) return;
        // let ds = mstore.getCellSourceId(mstore.indicatorKey);
        let ds_lbl = this.getRecNormSourceId(this.rnKey?.ds);
        let ds = this.getRNCellSourceId(this.rnKey);
        // console.log('ds:', ds);
        // console.log('data:', data, ds);
        data.features.forEach((f: any)=>{
            if (f.properties.field_id == field_id) {
                // console.log('f.id', f.id, f.properties.field_id, this.rnKey?.ds, checked, selected);
                mstore.setFeatureIndcOpacity(ds_lbl, f.id, checked, selected);
                mstore.setFeatureIndcOpacity(ds, f.id, checked, selected);
            }
        });
    }

    async updRNOpacity_(){
        let mstore = this.mapStore;
        let cstore = mstore.parentStore.cardStore;
        if (!cstore.fieldsItems) return;
        let checked = !cstore.fieldsItems.every(i=>!i.checked);
        for (let i=0; i<cstore.fieldsItems.length; i++){
            let f = cstore.fieldsItems[i];
            await this.updRNOpacity(f.field_id, checked && !f.checked,checked && f.checked);
        }
    }

    getRNSouceId(){
        return this.getRNCellSourceId(this.rnKey);
    }

    async getRNIds(field_id: number){
        let res: number[] = [];
        // console.log('rn ds', this.rnKey?.ds);
        if (!this.rnKey?.ds) return res;
        let data = await this.mapStore.tourStore.getDataJSON(this.rnKey.ds, IAhoLayers.Cell);
        if (data?.features) data.features.forEach((f: any)=>{
            if (f.properties.field_id == field_id) res.push(f.id);
        });
        return res;
    }

    async addRecNormLayer(key: RecNormKey){
        if (!key) return;
        let data = await this.mapStore.tourStore.getDataJSON(key.ds, IAhoLayers.Cell);
        if (!data) return;
        // console.log('data rn', key.ds);
        let map = this.root.map.mapbox;
        this.deleteRecNormLayer(this.lastRNKey);
        if (!map.getSource(this.getRNCellSourceId(key))){
            if (typeof data != 'object' || !data.type) return;
            map.addSource(this.getRNCellSourceId(key),{
                type: 'geojson',
                data: data
            });
        }
        // await this.updRNOpacity_();
        let layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.Rn);
        if (!map.getLayer(layer_id)) {
        // if (!map.getLayer(this.getRecNormLayerId(key))) {
            // console.log('add layer', this.getRecNormLayerId(key));
            map.addLayer({
                id: layer_id,
                // id: this.getRecNormLayerId(key),
                source: this.getRNCellSourceId(key),
                type: 'fill',
                paint: this.getRNCellStyle(key)
            // }, this.mapStore.getPrevLayer(this.getRecNormLayerId(key)));
            }, await this.mapStore.getPrevLayer(IAhoLayers.Rn));
        }
        layer_id = this.mapStore.agroAhoTool.getAhoLayerId(IAhoLayers.RnLine);
        if (!map.getLayer(layer_id)){
        // if (!map.getLayer(this.getRecNormLineLayerId(key))){
            map.addLayer({
                id: layer_id,
                // id: this.getRecNormLineLayerId(key),
                source: this.getRNCellSourceId(key),
                type: 'line',
                paint: <LinePaint>{
                    'line-color': '#000000',
                    'line-width': 1,
                    "line-opacity": [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                        ['feature-state', 'opacity'],
                        ['==',['feature-state', 'opac'], 1],
                        this.mapStore.unselectedIndcOpacity,
                        1
                    ]
                }
            // }, this.mapStore.getPrevLayer(this.getRecNormLineLayerId(key)));
            }, await this.mapStore.getPrevLayer(IAhoLayers.RnLine));
        }
    }

    getRNCellStyle(key: RecNormKey){
        let cardRNStore = this.mapStore.parentStore.cardStore.cardRNStore;
        let info = cardRNStore.getRNInfo();
        // console.log('info', JSON.stringify(info, null, 2));
        let code = 'rn_' + this.curRecNormItem.rn_id;
        let arr:any[] = ['case'];
        arr.push(['all',
            ['!=', ['typeof', ['get', code]], 'number'],
            ['==', ['to-boolean', ['feature-state', 'hover']], false],
        ]);
        arr.push('transparent');
        arr.push(['all',
            ['!=', ['typeof', ['get', code]], 'number'],
            ['==', ['feature-state', 'hover'], true]
        ]);
        arr.push('rgba(255,255,255,0.3)');
        arr.push(['<', ['get', code], 1]);
        arr.push('#C0C0C0');
        for (let i=0; i<info.length; i++){
            let el = info[i];
            // arr.push(['all', ['==', ['get', code], el.min], ['==',['get', code], el.max]]);
            // arr.push(el.color);
            arr.push(['all', ['>=', ['get', code], el.min], ['<=',['get', code], el.max]]);
            arr.push(el.color);
        }
        arr.push('transparent');
        let style: object = {
            'fill-color': arr,
            // 'fill-color': '#FF0000',
            'fill-opacity': [
                'case',
                ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                ['feature-state', 'opacity'],
                ['!=',['feature-state', 'opac'], 1],
                1,
                0
            ]
        };
        // console.log('arr:', JSON.stringify(style, null, 2));
        return style;
    }


}