import {RulerTool} from "../class/RulerTool";
import {MidPointTool} from "../EditGeometry/MidPointTool";
import {CreatePolygonDrawTool} from "../EditGeometry/CreatePolygonDrawTool";
import {EditDrawTool} from "../EditGeometry/EditDrawTool";
import {FieldLayersTool} from "../Fields/FieldLayersTool";
import {MoveMapTool} from "../mapCommon/MoveMapTool";
import {StopTool} from "../mapCommon/StopTool";
import {ContainerTools, CustomTool} from "./ContainerTools";
import {SuperStore} from "../../SuperStore";
import {PointIndexLayersTool} from "../class/PointIndexLayersTool";
import {SearchAddressMarkerTool} from "../class/SearchAddressMarkerTool";

import {FieldSelectTool} from "../Fields/FieldSelectTool";
import {CustomStore} from "../../CustomStore";
import {ContextMenuTool} from "../mapCommon/ContextMenuTool";
import {NdviValueTool} from "../class/NdviValueTool";
import {PhotoLayersTool} from "../class/PhotoLayerTool";
import {SearchConourTool} from "../class/SearchConourTool";
import {ShowRoomLayersTool} from "../class/ShowRoomLayersTool";
import {SoilGridsTool} from "../class/SoilGridsTool";
import {TestTool} from "../class/TestTool";
import {ShowRoom2LayerDistrictsTool} from "../ShowRoom2/ShowRoom2LayerDistrictsTool";
import {ShowRoom2PopupTool} from "../ShowRoom2/ShowRoom2PopupTool";
import {ShowRoom2LayerPointClassTool} from "../ShowRoom2/ShowRoom2LayerPointClassTool";
import {ShowRoom2LayerPointCceTool} from "../ShowRoom2/ShowRoom2LayerPointCceTool";
import {ShowRoom2LayerPointYieldAssTool} from "../ShowRoom2/ShowRoom2LayerPointYieldAssTool";
import {ShowRoom2Tool} from "../ShowRoom2/ShowRoom2Tool";
import {ShowRoom2LayerYieldMapGpTool} from "../ShowRoom2/ShowRoom2LayerYieldMapGpTool";
import {CreatePointIndexGraphTool} from "../class/CreatePointIndexGraphTool";
import {OverlayTool} from "../class/OverlayTool";
import {ViewGeometryLayersTool, ViewGeometryStyle} from "../EditGeometry/ViewGeometryLayersTool";
import {PopupTool} from "../mapCommon/PopupTool";
import {DeleteContourTool} from "../EditGeometry/DeleteContourTool";
import {FieldClickTool} from "../Fields/FieldClickTool";
import {CancelContextMenuTool} from "../mapCommon/CancelContextMenuTool";
import {GibsOverlayTool} from "../class/GibsOverlayTool";
import {DapFieldsMaskOverlayTool} from "../class/DapFieldsMaskOverlayTool";
import {SceneFavoritesTool} from "../scenes/SceneFavoritesTool";
import {SceneHoverTool} from "../scenes/SceneHoverTool";
import {AutoPolygonTool} from "../EditGeometry/AutoPolygonTool";
import {Utils} from "../../../helper/utils/Utils";
import {EditUndoRedoTool} from "../EditGeometry/EditUndoRedoTool";
import {SceneTopDetectTool} from "../scenes/SceneTopDetectTool";
import { GfsWindOverlayTool } from "../class/GfsWindOverlayTool";
import {MoveMapQuickTool} from "../mapCommon/MoveMapQuickTool";
import {RulerMapMoveTool} from "../EditGeometry/RulerMapMoveTool";
import { SurveyPointsTool } from "../surveyPoints/SurveyPointsTool";

export class SuperTools extends CustomStore{
    constructor(parent: CustomStore) {
        super(parent);
        this.store = this.root;

    }

    container: ContainerTools;

    store: SuperStore;
    showRoomLayersTool: ShowRoomLayersTool;
    photoLayersTool: PhotoLayersTool;
    // создание полигона
    createPolygonDrawTool: CreatePolygonDrawTool;
    viewEditorGeometry: ViewGeometryLayersTool;
    //инструемнт редактирования существующей геометрии
    editDrawTool: EditDrawTool;
    //просто набор слоёв для полей
    fieldLayersTool: FieldLayersTool;
    // по клику на поля выделяет их
    fieldSelectTool: FieldSelectTool;
    //по клику на другое поле или мимо поля переключает на дургое редактирование или всё завершает.
    fieldClickTool: FieldClickTool;
    //на mouseMove в состоянии выставляет определение средней точки
    midPointTool: MidPointTool;
    //перемещение карты
    moveMapTool: MoveMapQuickTool;
    //rulerTool: RulerTool;
    ndviValueTool: NdviValueTool;
    surveyPointsTool: SurveyPointsTool;
    //не распространять больше события
    stopTool: StopTool;
    contexMenu: ContextMenuTool;
    searchContour: SearchConourTool;
    showRoom2: ShowRoom2Tool;
    overlayTool: OverlayTool;
    gibsOverlayTool: GibsOverlayTool;
    dapFieldsMaskOverlayTool: DapFieldsMaskOverlayTool;
    gfsWindOverlayTool: GfsWindOverlayTool;

    soilGrids: SoilGridsTool;

    pointIndexLayersTool: PointIndexLayersTool;

    searchAddressMarker: SearchAddressMarkerTool;
    popupTool : PopupTool;
    sceneFavoritesTool: SceneFavoritesTool;

    testTool: TestTool;

    createAll(){
        //слои устанавливаются отсюда вниз, события обрабатываются наоборот
        this.container = new ContainerTools();


        this.stopTool = new StopTool(this.container, "StopTool");
        this.container.measures.push(this.stopTool);
        //Этот инструмент должен быть первым.
        this.moveMapTool = new MoveMapQuickTool(this.container, "MoveMapQuickTool");
        this.container.measures.push(this.moveMapTool);

        this.popupTool = new PopupTool(this.store, "PopupTool");
        this.container.measures.push(this.popupTool);

        this.contexMenu = new ContextMenuTool(this.container, "ContextMenuTool");//
        this.contexMenu.tools = this;
        this.container.measures.push(this.contexMenu);

        //GIBS MODIS
        this.gibsOverlayTool = new GibsOverlayTool(this.store, "GibsOverlayTool");
        this.container.measures.push(this.gibsOverlayTool);
        //GFS WIND
        this.gfsWindOverlayTool = new GfsWindOverlayTool(this.store, "GfsWindOverlayTool");
        this.container.measures.push(this.gfsWindOverlayTool);

        //снимки
        this.sceneFavoritesTool = new SceneFavoritesTool(this.container, "SceneFavoritesTool");
        this.sceneFavoritesTool.mapScenesStore = this.store.map.mapScenesStore;
        let sceneTopDetectTool = new SceneTopDetectTool(this.store, "SceneTopDetectTool");
        this.container.measures.push(sceneTopDetectTool);
        this.sceneFavoritesTool.productInfo = this.store.map.productInfo;
        this.sceneFavoritesTool.searchPanel = this.store.searchPanel;
        this.container.measures.push(this.sceneFavoritesTool);
        {
            let t = new SceneHoverTool(this.container, "SceneHoverTool");
            t.searchStore = this.store.searchPanel;
            this.container.measures.push(t);
        }
        //DAP mask
        this.dapFieldsMaskOverlayTool = new DapFieldsMaskOverlayTool(this.store, "DapFieldsMaskOverlayTool");
        this.container.measures.push(this.dapFieldsMaskOverlayTool);

        this.container.measures.push( new CreatePointIndexGraphTool(this.store, "CreatePointIndexGraphTool"));

        this.soilGrids = new SoilGridsTool(this.store, "SoilGridsTool");
        this.container.measures.push(this.soilGrids);

        this.overlayTool = new OverlayTool(this.store, "OverlayTool");
        this.container.measures.push(this.overlayTool);

        this.showRoomLayersTool = new ShowRoomLayersTool(this.store, "ShowRoomLayersTool");
        this.container.measures.push(this.showRoomLayersTool);


        this.fieldSelectTool = new FieldSelectTool(this.container, this.root.agro2.fieldToolController, "FieldSelectTool");
        this.container.measures.push(this.fieldSelectTool);

        this.fieldClickTool = new FieldClickTool(this.container, this.root.agro2.fieldToolController, "FieldClickTool");
        this.container.measures.push(this.fieldClickTool);

        this.fieldLayersTool = new FieldLayersTool(this.container, this.root.agro2.fieldToolController, "FieldLayersTool");
        this.fieldLayersTool.tools = this;
        this.container.measures.push(this.fieldLayersTool);

        this.ndviValueTool = new NdviValueTool(this.container, "NdviValueTool");
        this.ndviValueTool.store = this.root;
        this.container.measures.push(this.ndviValueTool);

        this.surveyPointsTool = new SurveyPointsTool(this.store, "SurveyPointsTool");
        this.ndviValueTool.store = this.root;
        this.container.measures.push(this.surveyPointsTool);

        this.showRoom2 = new ShowRoom2Tool(this.store, "ShowRoom2Tool");
        this.container.measures.push(this.showRoom2);

        this.showRoom2.yieldMapGp = new ShowRoom2LayerYieldMapGpTool(this.store, "ShowRoom2LayerYieldMapGpTool");
        this.showRoom2.yieldMapGp.showRoomLayerStore = this.store.dataShowRoomStore.yieldMapPerGP;
        this.container.measures.push(this.showRoom2.yieldMapGp);

        this.showRoom2.yieldAss = new ShowRoom2LayerPointYieldAssTool(this.store, "ShowRoom2LayerPointYieldAssTool");
        this.showRoom2.yieldAss.showRoomLayerStore = this.store.dataShowRoomStore.yieldAssessmentPoints;
        this.container.measures.push(this.showRoom2.yieldAss);

        this.showRoom2.districts = new ShowRoom2LayerDistrictsTool(this.store, "ShowRoom2LayerDistrictsTool");
        this.showRoom2.districts.showRoomLayerStore = this.store.dataShowRoomStore.districts;
        this.container.measures.push(this.showRoom2.districts);

        this.showRoom2.cce = new ShowRoom2LayerPointCceTool(this.store, "ShowRoom2LayerPointCceTool");
        this.showRoom2.cce.showRoomLayerStore = this.store.dataShowRoomStore.ccePoints;
        this.container.measures.push(this.showRoom2.cce);

        this.showRoom2.class = new ShowRoom2LayerPointClassTool(this.store, "ShowRoom2LayerPointClassTool");
        this.showRoom2.class.showRoomLayerStore = this.store.dataShowRoomStore.classPoints;
        this.container.measures.push(this.showRoom2.class);

        this.showRoom2.popup = new ShowRoom2PopupTool(this.store, "ShowRoom2PopupTool");
        this.container.measures.push(this.showRoom2.popup);

        this.photoLayersTool = new PhotoLayersTool(this.store, "PhotoLayersTool");
        this.container.measures.push(this.photoLayersTool);


        this.searchAddressMarker = new SearchAddressMarkerTool(this.container, "SearchAddressMarkerTool");
        this.searchAddressMarker.tools = this;
        this.container.measures.push(this.searchAddressMarker);

        this.createPolygonDrawTool = new CreatePolygonDrawTool(this.container, this.root.agro2.editManager.state, "CreatePolygonDrawTool");
        this.container.measures.push(this.createPolygonDrawTool);
        let keys = new EditUndoRedoTool(this.container, this.root.agro2.editManager.state, "EditUndoRedoTool");
        this.container.measures.push(keys);

        this.viewEditorGeometry = new ViewGeometryLayersTool(this.container, this.root.agro2.editManager.state, "ViewGeometryLayersTool");
        this.viewEditorGeometry.activeStyle = new ViewGeometryStyle();
        this.viewEditorGeometry.activeStyle.setRedStyle();
        this.viewEditorGeometry.style.setBlueStyle();
        let dt = new DeleteContourTool(this.container, this.root.agro2.editManager.state, "DeleteContourTool");
        dt.viewGeometryLayersTool = this.viewEditorGeometry;
        this.midPointTool = new MidPointTool(this.container, this.root.agro2.editManager.state, "MidPointTool");
        this.editDrawTool = new EditDrawTool(this.container, this.root.agro2.editManager.state, "EditDrawTool");

        let viewGeom2 = new ViewGeometryLayersTool(this.container, this.root.agro2.editManager.cutManager.state, "ViewGeometryLayersTool-cut");
        this.root.agro2.editManager.cutManager.viewEditorGeometry = viewGeom2;

        viewGeom2.Prefix = "class_toolsCut_";
        viewGeom2.style.setRedStyle();
        this.container.measures.push(viewGeom2);
        var cutCreatePolygonTool = new CreatePolygonDrawTool(this.container, this.root.agro2.editManager.cutManager.state, "CreatePolygonDrawTool-cut");
        this.container.measures.push(cutCreatePolygonTool);

        this.container.measures.push(dt);
        this.container.measures.push(this.midPointTool);
        this.container.measures.push(this.editDrawTool);
        this.container.measures.push(new AutoPolygonTool(this.root, "AutoPolygonTool"));


        let viewGeomSearch = new ViewGeometryLayersTool(this.container, this.root.map.searchObject.geometryEditManager.state, "ViewGeometryLayersTool-search");
        this.root.map.searchObject.geometryEditManager.viewEditorGeometry = viewGeomSearch;

        viewGeomSearch.Prefix = "class_toolsSearch_";
        viewGeomSearch.style.setYellowStyle();
        let searchCreatePolygonTool = new CreatePolygonDrawTool(this.container, this.root.map.searchObject.geometryEditManager.state, "CreatePolygonDrawTool-search");
        this.container.measures.push(viewGeomSearch);
        this.container.measures.push(searchCreatePolygonTool);






        this.pointIndexLayersTool = new PointIndexLayersTool(this.container, "PointIndexLayersTool");
        this.pointIndexLayersTool.indexByPointerStore = this.root.indexByPointer;
        this.container.measures.push(this.pointIndexLayersTool);


        this.container.measures.push(this.viewEditorGeometry);

        this.searchContour = new SearchConourTool(this.root, "SearchConourTool");
        this.container.measures.push(this.searchContour);

        //this.rulerTool = new RulerTool(this.container, "RulerTool");
        //this.container.measures.push(this.rulerTool);



        let k = new CancelContextMenuTool(this.container, "CancelContextMenuTool");
        k.mapStore = this.store.map;
        this.container.measures.push(k);

        let viewGeomRuler = new ViewGeometryLayersTool(this.container, this.root.map.rulerInfo.rulerManager.state, "ViewGeometryLayersTool-ruler");
        this.root.map.rulerInfo.rulerManager.viewEditorGeometry = viewGeomRuler;
        viewGeomRuler.Prefix = "class_toolsRuler_";
        this.root.map.rulerInfo.rulerManager.setStyles();

        //viewGeomRuler.style.setBlackStyle();

        let rulerCreatePolygonTool = new CreatePolygonDrawTool(this.container, this.root.map.rulerInfo.rulerManager.state, "CreatePolygonDrawTool-ruler");
        this.container.measures.push(viewGeomRuler);
        this.container.measures.push(rulerCreatePolygonTool);
        let midPointRuler = new MidPointTool(this.container, this.root.map.rulerInfo.rulerManager.state, "MidPointTool-ruler");

        let editRuler = new EditDrawTool(this.container, this.root.map.rulerInfo.rulerManager.state, "EditDrawTool-ruler");
        this.container.measures.push(midPointRuler);
        this.container.measures.push(editRuler);
        this.container.measures.push(new RulerMapMoveTool(this.store, "ruler-move"));


        this.testTool = new TestTool(this.store, "TestTool");
        this.container.measures.push(this.testTool);

        this.root.events.onInitTools.call();
        //события начинают обрабатываться отсюда вверх
    }

    pushToolAfter(tool: CustomTool, afterNames: string[]){
        if (this.container.measures.find(a => a.name == tool.name)) return;
        let ok = false;
        if (afterNames != null && afterNames.length > 0){
            for(let i = 0; i < afterNames.length; i++){
                let name = afterNames[i];
                let index = this.container.measures.findIndex((v)=> v.name == name);
                if (index >= 0){
                    Utils.arrayInsert(this.container.measures, index + 1, tool);
                    ok = true;
                    return;
                }
            }
        }
        if (!ok) this.container.measures.push(tool);
    }
}
