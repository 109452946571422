import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ShowRoom2Layer} from "./ShowRoom2Layer";
import './ShowRoom2Projects.scss';
import './ShowRoom2.scss';
import {DropDownComp, IDropDownItem} from "../../../Common/DropDownComp";
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../icons/LoadingAniSvg";
import {action} from "mobx";
import classNames from "classnames";

@observer
export class ShowRoom2Projects extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onChangeSeason(item: IDropDownItem, newIndex: number): void{
        this.props.store.dataShowRoomStore.filtersProject.seasonId = item.key as any;
    }
    @action
    onChangeCrop(item: IDropDownItem, newIndex: number): void{
        this.props.store.dataShowRoomStore.filtersProject.cropId = item.key as any;
    }

    render() {
        let store = this.props.store;
        let sr = store.dataShowRoomStore;
        let projectItems: IDropDownItem[] = [{key: "1", value: "MNCFC"}];
        let seasonItems: IDropDownItem[] =[];
        let seasonActive = store.dataShowRoomStore.filtersProject.seasonId;
        store.dataShowRoomStore.seasons.forEach(a =>{
            seasonItems.push({key: a.id, value:a.name});
        });

        let cropsItems: IDropDownItem[] =[];
        store.dataShowRoomStore.filtersProject.cropIdsBySeasonId.forEach(a =>{
            let b = store.dataShowRoomStore.cropDataAll.get(a);
            cropsItems.push({key: b.id, value: b.name});
        });

        return <div>
            <div className="ShowRoom2Projects-keyValue">
                <div className="ShowRoom2Projects-key opacity50">
                    Project
                </div>
                <div className="ShowRoom2Projects-value">
                    <DropDownComp items={projectItems} currentKey="1" disabled={true}  className="ShowRoom2Projects-value-dropdown" />
                </div>
            </div>
            <div className="ShowRoom2Projects-keyValue">
                <div className="ShowRoom2Projects-key">
                    Season
                </div>
                <div className="ShowRoom2Projects-value">
                    <DropDownComp items={seasonItems} currentKey={store.dataShowRoomStore.filtersProject.seasonId} onChange={this.onChangeSeason} className="ShowRoom2Projects-value-dropdown" />
                </div>
            </div>
            <div className="ShowRoom2Projects-keyValue">
                <div className={classNames("ShowRoom2Projects-key",{"opacity50": store.dataShowRoomStore.filtersProject.seasonId == null})}>
                    Crop
                </div>
                <div className="ShowRoom2Projects-value">
                    <DropDownComp items={cropsItems} disabled={store.dataShowRoomStore.filtersProject.seasonId == null}
                                  currentKey={store.dataShowRoomStore.filtersProject.cropId} onChange={this.onChangeCrop} className="ShowRoom2Projects-value-dropdown" />
                    {store.dataShowRoomStore.filtersProject.cropIdsBySeasonIdStatus == LoadStatus.loading && <LoadingAniSvg  className="ShowRoom2Projects-loading"/>}
                </div>
            </div>
            <div className="ShowRoom2-separateline" />

            <ShowRoom2Layer store={store} layer={sr.ccePoints} disabled={!store.dataShowRoomStore.filtersProject.isValid()}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" style={{marginRight: "3px"}} xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="12" height="12" rx="2" fill="white"/>
                        <rect x="2" y="2" width="8" height="8" rx="1" fill="#6B707B"/>
                    </g>
                </svg>
            </ShowRoom2Layer>
            <ShowRoom2Layer store={store} layer={sr.classPoints} disabled={!store.dataShowRoomStore.filtersProject.isValid()}>
                <svg width="12" height="12" viewBox="0 0 12 12" style={{marginRight: "3px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="12" height="12" rx="6" fill="white"/>
                        <rect x="2" y="2" width="8" height="8" rx="4" fill="#6B707B"/>
                    </g>
                </svg>
            </ShowRoom2Layer>
            <ShowRoom2Layer store={store} layer={sr.yieldAssessmentPoints} disabled={!store.dataShowRoomStore.filtersProject.isValid()}>
                <svg width="14" height="14" viewBox="0 0 14 14" style={{marginRight: "3px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M3.71131 2.30383C3.88994 1.99443 4.22007 1.80383 4.57734 1.80383L9.42263 1.80383C9.7799 1.80383 10.11 1.99443 10.2887 2.30383L12.7113 6.49999C12.8899 6.80939 12.8899 7.19058 12.7113 7.49999L10.2887 11.6961C10.11 12.0055 9.7799 12.1961 9.42263 12.1961L4.57734 12.1961C4.22007 12.1961 3.88994 12.0055 3.71131 11.6961L1.28866 7.49999C1.11003 7.19058 1.11003 6.80939 1.28866 6.49999L3.71131 2.30383Z" fill="#6B707B" stroke="white" strokeWidth="2"/>
                </svg>
            </ShowRoom2Layer>
            <ShowRoom2Layer store={store} layer={sr.arableAreaMask} disabled={true} />
            <ShowRoom2Layer store={store} layer={sr.cropAreaMask} disabled={true} />
            <ShowRoom2Layer store={store} layer={sr.yieldMapPerGP} disabled={!store.dataShowRoomStore.filtersProject.isValid()}/>
            <ShowRoom2Layer store={store} layer={sr.districts}  disabled={!store.dataShowRoomStore.filtersProject.isValid()}/>
        </div>;
    }
}
