// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoStepsComp-main {
  display: flex;
  align-items: center;
}

.AgroAhoStepsComp-circle {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #6A7886;
  color: #6A7886;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.AgroAhoStepsComp-active {
  border: 1px solid #4DB6BC;
  background: #4DB6BC;
  color: #2B3138;
}

.AgroAhoStepsComp-line {
  height: 1px;
  width: 12px;
  margin: 0 5px;
  background: #6A7886;
}

.AgroAhoStepsComp-disabled {
  border: 1px solid #6A7886;
  background: #6A7886;
  color: #2B3138;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/uploadTable/AgroAhoStepsComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAEF;;AAAA;EACE,yBAAA;EACA,mBAAA;EACA,cAAA;AAGF;;AAAA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAGF;;AADA;EACE,yBAAA;EACA,mBAAA;EACA,cAAA;AAIF","sourcesContent":[".AgroAhoStepsComp-main {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.AgroAhoStepsComp-circle {\r\n  width: 24px;\r\n  height: 24px;\r\n  border-radius: 12px;\r\n  border: 1px solid #6A7886;\r\n  color: #6A7886;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n}\r\n.AgroAhoStepsComp-active {\r\n  border: 1px solid #4DB6BC;\r\n  background: #4DB6BC;\r\n  color: #2B3138;\r\n  //color: #4DB6BC;\r\n}\r\n.AgroAhoStepsComp-line {\r\n  height: 1px;\r\n  width: 12px;\r\n  margin: 0 5px;\r\n  background: #6A7886;\r\n}\r\n.AgroAhoStepsComp-disabled {\r\n  border: 1px solid #6A7886;\r\n  background: #6A7886;\r\n  color: #2B3138;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
