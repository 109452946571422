import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import classNames from "classnames";
import {action} from "mobx";
import './UserForms.scss';
import {Utils} from "../../../helper/utils/Utils";
import {LeftAuthPanelComp} from "./LeftAuthPanelComp";
import {TranslateUtils} from "../../../helper/lang/TranslateUtils";

@observer
export class SignUpFormComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action async onSubmit(event: any){
        event.preventDefault();
        //if (this.props.store.user.formAuthStatus == LoadStatus.loading) return false;
        //await this.props.store.user.requestRegister();
        let store = this.props.store;
        try {
            let pass = store.user.signUpForm.password;
            if (!store.user.checkPasswordCorrect(pass)){
                store.addError(store.trans["The password must contain at least 8 characters, consist of letters of the Latin alphabet (A-z), Arabic numbers (0-9) and special characters."]);
                return false;
            }
            await this.props.store.user.signUpForm.sendRegistration();
            this.props.store.gotoUrl('/sendedEmail');
        }catch (e){
            this.props.store.addError(e);
        }
        return false;
    }

    @action onChangeFirstName(event: any){
        this.props.store.user.signUpForm.firstName = event.target.value;
        this.props.store.user.signUpForm.firstNameUpdated = true;
    }
    @action onChangeSecondName(event: any){
        this.props.store.user.signUpForm.secondName = event.target.value;
        this.props.store.user.signUpForm.secondNameUpdated = true;
    }
    @action onChangeEmailName(event: any){
        this.props.store.user.signUpForm.email = event.target.value;
        this.props.store.user.signUpForm.emailUpdated = true;
    }
    @action onChangePassword(event: any){
        this.props.store.user.signUpForm.password = event.target.value;
        this.props.store.user.signUpForm.passwordUpdated = true;
    }
    @action onChangePassword2(event: any){
        this.props.store.user.signUpForm.password2 = event.target.value;
        this.props.store.user.signUpForm.password2Updated = true;
    }
    emailBlur(){
        this.props.store.user.signUpForm.emailFocused = false;
    }
    emailFocus(){
        this.props.store.user.signUpForm.emailFocused = true;
    }
    @action
    onClickEye(){
        this.props.store.user.signUpForm.passwordShow = !this.props.store.user.signUpForm.passwordShow;
    }

    onClickSignIn(){
        //this.props.store.user.authFormShow = true;
        //this.props.store.user.signUpForm.show = false;
        this.props.store.gotoUrl('/signin');
    }

    render() {
        let store = this.props.store;
        let form = store.user.signUpForm;
        let firstNameError: string = null;
        if ((form.tryingSubmit || form.firstNameUpdated) && form.firstName.length == 0) firstNameError = store.trans["Please specify first name"];
        let secondNameError: string = null;
        if ((form.tryingSubmit || form.secondNameUpdated) && form.secondName.length == 0) secondNameError = store.trans["Please specify second name"];
        let emailError: string = null;
        if ((form.tryingSubmit || (form.emailUpdated && !form.emailFocused)) && !Utils.validateEmail(form.email)) emailError = store.trans["Please specify valid email"];
        let passwordError: string = null;
        if ((form.tryingSubmit || form.passwordUpdated) && form.password.length == 0) passwordError = store.trans["Please specify password"];
        let password2Error: string = null;
        if ((form.tryingSubmit || form.passwordUpdated) && form.password != form.password2) password2Error = store.trans["Password mismatch"];

        let enableSubmit: boolean = !password2Error && !firstNameError && !secondNameError && !emailError && !passwordError;

        return <div className="authorize-background">
            <div className="authorize-background2">
                <LeftAuthPanelComp store={store} />
                <div className="authorize-d2 style-4">
                    <div className="authorize-form-zatyk" />
                    <form className="authorize-form authorize-form-width" onSubmit={this.onSubmit} autoComplete="off">
                        <div className="UserForm-title-m flex-columns flex-align-items-center UserForm-title-marginTop">
                            <div className="flex-fit-item">{store.trans["Sign up"]}</div>
                            <div onClick={this.onClickSignIn} className="pointer">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="19.5" stroke="#595D62"/>
                                    <path d="M31.25 20.75H8.75" stroke="#595D62" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.25 13.25L8.75 20.75L16.25 28.25" stroke="#595D62" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>

                        <div className="UserForms-labelDiv-m UserForm-itemsGroup-marginTop">
                            <div className="authorize-in-right__row_label-left">
                                {store.trans["First name"]}
                                <span className="UserForms-Star-m">*</span>
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type="text" onBlur={this.emailBlur} onFocus={this.emailFocus}
                                   className={classNames("UserForms-inputText-m", {"red_border": firstNameError})}
                                   onChange={this.onChangeFirstName} value={form.firstName} />
                            {firstNameError && <div className="UserForms-errorHint-m">{firstNameError}</div>}
                        </div>

                        <div className="UserForms-labelDiv-m">
                            <div className="authorize-in-right__row_label-left">
                                {store.trans["Second name"]}
                                <span className="UserForms-Star-m">*</span>
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type="text"
                                   className={classNames("UserForms-inputText-m", {"red_border": secondNameError})}
                                   onChange={this.onChangeSecondName} value={form.secondName} />
                            {secondNameError && <div className="UserForms-errorHint-m">{secondNameError}</div>}
                        </div>

                        <div className="UserForms-labelDiv-m">
                            <div className="authorize-in-right__row_label-left">
                                Email
                                <span className="UserForms-Star-m">*</span>
                            </div>
                            <div className="authorize-in-right__row_label-right UserForms-text-gray">
                                {store.trans["Email is also your login"]}
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type="email"
                                   className={classNames("UserForms-inputText-m", {"red_border": emailError})}
                                   onChange={this.onChangeEmailName} value={form.email} />
                            {emailError && <div className="UserForms-errorHint-m">{emailError}</div>}
                        </div>

                        <div className="UserForms-labelDiv-m">
                            <div className="authorize-in-right__row_label-left">
                                {store.trans.Password}
                                <span className="UserForms-Star-m">*</span>
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type={classNames({"password": !form.passwordShow, "text": form.passwordShow})}
                                   className={classNames("UserForms-inputText-m UserForms-inputText-password", {"red_border": passwordError})}
                                   onChange={this.onChangePassword} value={form.password} />
                            <div onClick={this.onClickEye}
                                 className={classNames("UserForms-inputDiv-eye",{"gray-fill":!form.passwordShow, "blue-fill":form.passwordShow})}>
                                <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00001 12C12.707 12 15.744 6.716 15.871 6.492C16.042 6.188 16.043 5.816 15.872 5.512C15.746 5.287 12.731 0 8.00001 0C3.24501 0 0.251005 5.289 0.126005 5.514C-0.0429946 5.817 -0.0419946 6.186 0.128005 6.489C0.254005 6.713 3.26901 12 8.00001 12ZM8.00001 2C10.839 2 13.036 4.835 13.818 6C13.034 7.166 10.837 10 8.00001 10C5.15901 10 2.96201 7.162 2.18101 5.999C2.95801 4.835 5.14601 2 8.00001 2Z"/>
                                    <path d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z" />
                                </svg>
                            </div>
                            {passwordError && <div className="UserForms-errorHint-m">{passwordError}</div>}
                        </div>

                        <div className="UserForms-labelDiv-m">
                            <div className="authorize-in-right__row_label-left">
                                {store.trans["Confirm password"]}
                                <span className="UserForms-Star-m">*</span>
                            </div>
                        </div>
                        <div className="UserForms-inputDiv-m">
                            <input type={classNames({"password": !form.passwordShow, "text": form.passwordShow})}
                                   className={classNames("UserForms-inputText-m UserForms-inputText-password", {"red_border": password2Error})}
                                   onChange={this.onChangePassword2} value={form.password2} />
                            <div onClick={this.onClickEye}
                                 className={classNames("UserForms-inputDiv-eye",{"gray-fill":!form.passwordShow, "blue-fill":form.passwordShow})}>
                                <svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00001 12C12.707 12 15.744 6.716 15.871 6.492C16.042 6.188 16.043 5.816 15.872 5.512C15.746 5.287 12.731 0 8.00001 0C3.24501 0 0.251005 5.289 0.126005 5.514C-0.0429946 5.817 -0.0419946 6.186 0.128005 6.489C0.254005 6.713 3.26901 12 8.00001 12ZM8.00001 2C10.839 2 13.036 4.835 13.818 6C13.034 7.166 10.837 10 8.00001 10C5.15901 10 2.96201 7.162 2.18101 5.999C2.95801 4.835 5.14601 2 8.00001 2Z"/>
                                    <path d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z" />
                                </svg>
                            </div>
                            {password2Error && <div className="UserForms-errorHint-m">{password2Error}</div>}
                        </div>

                        <div className="UserForms-footer-m">
                            <button style={{width:"100%"}} disabled={!enableSubmit}
                                    className={classNames("authorize-submit-form", {"opacity20": !enableSubmit})}
                                    type="submit">{store.trans["Sign up"]}</button>
                        </div>
                        <div className="UserForms-descriptionGray-m UserForms-descriptionGray-topMargin">
                            {TranslateUtils.createSpansWithReplaceMarkers(store.trans["By clicking on the \"Sign up\" button, I accept the {0} and {1} and consent to the processing of personal data, as well as to receive advertising and other types of letters associated with my account"],
                                <a>{store.trans["Terms of Use"]}</a>,
                                <a>{store.trans["Privacy Policy"]}</a>
                            )}
                        </div>

                        <div className="authorize-in-right__row_label-in authorize-footer-marginTop">
                            <div className="authorize-in-right__row_label-left not-yet-with-us">{store.trans["Already have an account?"]}</div>
                            <div className="authorize-in-right__row_label-right not-yet-with-us-line">&nbsp;</div>
                        </div>
                    </form>
                    <div className="flex-columns authorize-form-width">
                        <button className="reg-now" onClick={this.onClickSignIn}>{store.trans["Sign in"]}</button>
                    </div>
                    <div className="authorize-form-zatyk" />
                </div>
            </div>
        </div>;
    }
}
