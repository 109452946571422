// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legendLayer-panel {
  border: 1px solid #3E4751;
  right: 5px;
  border-radius: 6px;
  bottom: 5px;
  background-color: #2B3138;
  z-index: 4;
  position: absolute;
  color: #C5C5C5;
  padding: 4px;
}

.legendLayer-minButton {
  position: absolute;
  top: 1px;
  right: 5px;
  z-index: 11;
}

.legendLayer-title {
  color: #C5C5C5;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 5px;
  padding-left: 6px;
  padding-bottom: 5px;
}

.legendLayer-row {
  color: #C5C5C5;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 6px;
}

.legendLayer-rowName {
  color: #C5C5C5;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-grow: 1;
  padding-left: 8px;
}

.legendLayer-icon {
  width: 14px;
  height: 14px;
}

.legendLayer-iconPolygon {
  border-radius: 3px;
  border: 1px solid #3E4751;
  background: #00C6C6;
}

.legendLayer-iconPoint {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
}

.legendLayer-checkVisible {
  padding-right: 8px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Right/LegendLayer/LegendLayer.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;AAEF;;AAAA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAGF;;AADA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAIF;;AAFA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AAKF;;AAHA;EACE,WAAA;EACA,YAAA;AAMF;;AAJA;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;AAOF;;AALA;EAEE,mBAAA;EACA,UAAA;EACA,WAAA;EACA,6BAAA;AAOF;;AALA;EACE,kBAAA;AAQF","sourcesContent":[".legendLayer-panel {\n  border: 1px solid #3E4751;\n  right: 5px;\n  border-radius: 6px;\n  bottom: 5px;\n  background-color: #2B3138;\n  z-index: 4;\n  position: absolute;\n  color: #C5C5C5;\n  padding: 4px;\n}\n.legendLayer-minButton{\n  position: absolute;\n  top: 1px;\n  right: 5px;\n  z-index: 11;\n}\n.legendLayer-title{\n  color: #C5C5C5;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  padding-top: 5px;\n  padding-left: 6px;\n  padding-bottom: 5px;\n}\n.legendLayer-row{\n  color: #C5C5C5;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  display: flex;\n  align-items: center;\n  padding: 4px 10px 4px 6px;\n}\n.legendLayer-rowName{\n  color: #C5C5C5;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  flex-grow: 1;\n  padding-left: 8px;\n}\n.legendLayer-icon{\n  width: 14px;\n  height: 14px;\n}\n.legendLayer-iconPolygon{\n  border-radius: 3px;\n  border: 1px solid #3E4751;\n  background: #00C6C6;\n}\n.legendLayer-iconPoint{\n  //border: 1px solid #3E4751;\n  border-radius: 10px;\n  width: 8px;\n  height: 8px;\n  border: 1px solid transparent;\n}\n.legendLayer-checkVisible{\n  padding-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
