// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KeyIcon {
  color: #6A7886;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid #6A7886;
  background: #22282F;
  width: auto;
  height: 36px;
  flex-shrink: 0;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.KeyIcon_short {
  padding: 0 12px;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/User/ProjectSettings/KeyIcon.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".KeyIcon{\n  color: #6A7886;\n  text-align: center;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n\n  border-radius: 8px;\n  border: 1px solid #6A7886;\n  background: #22282F;\n  width: auto;\n  height: 36px;\n  flex-shrink: 0;\n  padding: 0 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.KeyIcon_short{\n  padding: 0 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
