import {LngLat, MapboxGeoJSONFeature} from "maplibre-gl";
import {observable} from "mobx";
import {IOverlayConfig} from "../config/ConfigStore";
import {CustomStore, ObservableCustomStore} from "../CustomStore";
import { OverlayObjectStore } from "../OverlayObjectStore";

export interface IPopapData {
    overlay: IOverlayConfig;
    feature: MapboxGeoJSONFeature;
    x: number;
    y: number;
};

export class PopupStore extends ObservableCustomStore {
    constructor(parent: CustomStore) {
        super(parent);
    }

    @observable
    private _data: IPopapData = null;
    get data(): IPopapData {return this._data;}
    set data(value: IPopapData) {
        if (this._data == value) return;
        if (this._data?.feature != value?.feature)
            this.select(value);
        this._data = value;
    }

    private select(value: IPopapData) {
        let ms = this.root.map;
        function selectFeature(pd: IPopapData, selected: boolean) {
            if (! pd?.overlay) return;
            pd.overlay.layers.forEach((lr, idx) => {
                if (lr.type != "line") return;
                let layerId = OverlayObjectStore.getOverlayLayerName(pd.overlay.id, idx);
                let paint = lr.paint as any;
                let lineWidth: any = paint["line-width"] || 1;
                let lineOpacity: any = paint["line-opacity"] || 1;
                let lineColor: any = paint["line-color"] || "#000000";
                let lineDashArray: any = paint["line-dasharray"] || [1];
                if (selected && pd.feature?.id) {
                    lineWidth = ["case", ["==", ["id"], pd.feature.id], lineWidth + 2, lineWidth];
                    lineOpacity = ["case", ["==", ["id"], pd.feature.id], 1, lineOpacity];
                    lineColor = ["case", ["==", ["id"], pd.feature.id], "#FF0000", lineColor];
                    //lineDashArray = ["case", ["==", ["id"], pd.feature.id], ['literal', [1]], ['literal', lineDashArray]];
                }
                ms.mapbox.setPaintProperty(layerId, "line-width", lineWidth);
                ms.mapbox.setPaintProperty(layerId, "line-opacity", lineOpacity);
                ms.mapbox.setPaintProperty(layerId, "line-color", lineColor);
                //data expressions not supported
                //ms.mapbox.setPaintProperty(layerId, "line-dasharray", lineDashArray);
            });
        }
        selectFeature(this._data, false);
        selectFeature(value, true);
    }

    lngLat: LngLat = null;
}