import React, {RefObject, Suspense} from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {CloseSvg} from "../../../icons/MiniIcon/CloseSvg";
import {action} from "mobx";
import {MaximizeSvg} from "../../../icons/MiniIcon/MaximizeSvg";
import {IndexByPointTableComp} from "./IndexByPointTableComp";
import {MeasuringStatus} from "../../../../helper/structs/MeasuringStatus";
import {WindowTriState} from "../../../../helper/structs/WindowTriState";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {IndexByPointYearsDropDownComp} from "./IndexByPointYearsDropDownComp";
import {IndexByPointDeleteAllPointsComp} from "./IndexByPointDeleteAllPointsComp";
import {TranslateUtils} from "../../../../helper/lang/TranslateUtils";
import {IndexByPointPopupGroupsComp} from "./Popups/IndexByPointPopupGroupsComp";
import {IndexByPointMonthTitleComp} from "./IndexByPointMonthTitleComp";
import PointMarkerSvg from '../../../icons/PointMarkerSvg';
import DomToImage from 'dom-to-image';
import IndexByPointColorPickerCom from './IndexByPointColorPickerCom';

const IndexByPointChart2Comp = React.lazy(() => import('./IndexByPointChart2Comp'));


@observer
export class IndexByPointComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        this.legendRef = React.createRef();
    }

    legendRef: RefObject<any> = null;

    @action onClose(){
        this.props.store.indexByPointer.clearPoints();
        this.props.store.indexByPointer.showPanel = WindowTriState.close;
        if (this.props.store.map.measuringStatus == MeasuringStatus.CreatePointIndex)
            this.props.store.map.resetMeasuringStatus();
    }
    @action onMinimize(){
        this.props.store.indexByPointer.showPanel = WindowTriState.minimize;
        if (this.props.store.map.measuringStatus == MeasuringStatus.CreatePointIndex)
            this.props.store.map.resetMeasuringStatus();
    }
    @action onClickMaximize(){
        this.props.store.indexByPointer.maxPanel = !this.props.store.indexByPointer.maxPanel;
    }

    @action onClickCreateIndexPoint(){
        if (this.props.store.map.measuringStatus != MeasuringStatus.CreatePointIndex)
            this.props.store.map.setMeasuringStatus(MeasuringStatus.CreatePointIndex);
        else this.props.store.map.resetMeasuringStatus();
    }


    render() {
        let store = this.props.store;
        let st = store.indexByPointer;
        const swItems = [
            {key: "everyPoint", value: store.trans["Every point - new color"]},
            {key: "everyYear", value: store.trans["Every year - new color"]},
            {key: "everyChar", value: store.trans["Every char - new color"]}
        ];
        const currentMode = st.colorMode;
        return (
            <div className={classNames("indexByPoint-main qtransit flex-rows", {
                "width0": store.indexByPointer.showPanel == WindowTriState.minimize,
                "indexByPoint-maxSize": store.indexByPointer.maxPanel,
                "indexByPoint-opacity-root": (st.points.length == 0),
            })}>
                <div className="indexByPoint-content">
                    <div className="indexByPoint-title indexByPoint-padright">
                        <span className="shadow-text flex-stretch-item indexByPoint-title-text">{store.trans.Charts}</span>
                        <div onClick={this.onClickMaximize} className="pointer minibutton-delimiter">
                            <MaximizeSvg/>
                        </div>
                    </div>
                    <div className="indexByPoint-padright flex-columns">
                        <IndexByPointPopupGroupsComp store={store} className="" />
                        <div className={classNames(
                            "full-center-content indexByPoint-plusPoint-button",
                            {
                                "active": store.map.measuringStatus == MeasuringStatus.CreatePointIndex,
                                "notactive": store.map.measuringStatus != MeasuringStatus.CreatePointIndex
                            })}
                             onClick={this.onClickCreateIndexPoint}>
                            <PointMarkerSvg isActive={store.map.measuringStatus == MeasuringStatus.CreatePointIndex}/>
                        </div>

                    </div>

                    {(st.points.length == 0) && <div className="indexByPoint-help">
                        {TranslateUtils.createSpansWithReplaceMarkers(store.trans["Use the cursor {0} to place a point on the map where you would like to calculate the data type"],
                            <span className="text-next-half text-prev-half indexByPoint-help-pipetka">
                                 <svg style={{fill:"#4DB6BC", stroke:"#4DB6BC", opacity: 0.5}}
                                                                    width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 6.88235C13 10.1152 10.3298 12.7647 7 12.7647C3.67024 12.7647 1 10.1152 1 6.88235C1 3.64953 3.67024 1 7 1C10.3298 1 13 3.64953 13 6.88235Z" fill="none" strokeWidth="2"/>
                                    <path d="M7.71886 17.56C7.42823 18.1467 6.57177 18.1467 6.28114 17.56L4.08056 13.118C3.8243 12.6007 4.21055 12 4.79942 12L9.20058 12C9.78945 12 10.1757 12.6007 9.91944 13.118L7.71886 17.56Z" stroke="none" />
                                    <rect x="6" y="4" width="2" height="6" rx="1"  stroke="none"/>
                                    <rect x="4" y="8" width="2" height="6" rx="1" transform="rotate(-90 4 8)" stroke="none"/>
                                </svg>
                                </span>

                        )}
                        </div>
                    }
                    {(st.points.length >0) &&
                        <React.Fragment>
                        <div className="indexByPoint-chartDiv">
                            <Suspense fallback={<div style={{padding: "10px 15px"}}>{store.trans.Downloading}...</div>}>
                                <IndexByPointChart2Comp store={store}/>
                            </Suspense>
                        </div>
                        <div className="width100 indexByPoint-padright indexByPoint_headerline">
                            <div className="indexByPoint_headerline-title">{store.trans.Points}</div>
                            <div className="bottom-line-gray height1 flex-stretch-item"/>
                            <IndexByPointYearsDropDownComp store={store} />
                            <IndexByPointColorPickerCom store={store}/>
                            <IndexByPointDeleteAllPointsComp store={store} />
                        </div>
                        <IndexByPointMonthTitleComp store={store} />
                        <div style={{paddingLeft:'6px'}} className="scrollbar-standart IndexByPointTableComp-cont">
                            <IndexByPointTableComp store={store} ref={this.legendRef}/>
                        </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}
