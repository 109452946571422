import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoStore, AhoModalType} from "../agroAhoStore";
import {observable} from "mobx";
import {AhoUploadTableStage} from "../agroAhoUploadTableStore";
import {TextUtils} from "../../../app/helper/utils/TextUtils";
import {Utils} from "../../../app/helper/utils/Utils";
import {AhoUtils} from "../agroAhoUtils";
import {LeftPanelModeAgroAho} from "../agroAhoPlugin";

export enum AhoStmtStage {
    Step1 = 1,
    Step2 = 2,
    Step3 = 3,
    Step4 = 4
}

export enum AhoStmtSort {
    NoSort = 'NoSort',
    Indc = 'Indc',
    IndcDesc = 'IndcDesc',
    Research = 'Research',
    ExtResearch = 'ExtResearch'
}

export interface AhoStmtIndcItem {
    indc_id: number;
    indc_code: string;
    indc_name: string;
    unit: string;
    standard: string;
    main: boolean;
    ext: boolean;
}

export interface AhoStmtExtItem {
    field_id: number;
    field_name: string;
    cell_id: number;
    cell_name: string;
    main: boolean;
    ext: boolean;
}

export interface AhoStmtStat {
    fields: number;
    cells: number;
    area: number;
    main_indcs: number;
    ext_indcs: number;
    ext_cells: number;
}

export class AgroAhoStmtStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    @observable
    selCellShow: boolean = false;
    @observable
    stage: AhoStmtStage = AhoStmtStage.Step1;
    @observable
    contract: string = '';
    @observable
    client: string = '';
    @observable
    fieldWorkDate: Date = null;
    @observable
    filter: string = '';
    @observable
    isExtResearch: boolean = false;
    @observable
    sortMode: AhoStmtSort = AhoStmtSort.Indc;
    @observable
    lastIndcSort: AhoStmtSort = AhoStmtSort.Indc;
    @observable
    indcs: AhoStmtIndcItem[] = [];
    @observable
    exts: AhoStmtExtItem[] = [];
    stat: AhoStmtStat = null;
    @observable
    labEmail: string = '';
    @observable
    yourEmail: string = '';
    @observable
    copyEmail: string = '';

    doNextClick(){
        if (this.stage == AhoStmtStage.Step1) {
            this.updIndcs();
            this.stage = AhoStmtStage.Step2;
        } else if (this.stage == AhoStmtStage.Step2) {
            this.updExts();
            this.stage = AhoStmtStage.Step3;
        } else if (this.stage == AhoStmtStage.Step3) {
            this.updStat();
            this.stage = AhoStmtStage.Step4;
        }
    }
    updStat(){
        let stat_ = this.parentStore.cardStore.getStat();
        this.stat = {fields: stat_.fflds, cells: stat_.fcells, area: stat_.area,
            main_indcs: this.indcs.filter(f=>f.main).length,
            ext_indcs: this.isExtResearch ? this.indcs.filter(f=>f.ext).length : 0,
            ext_cells: this.isExtResearch ? this.exts.filter(f=>f.ext).length : 0
        };
        this.yourEmail = this.root.user?.curEmail || '';
    }
    updExts(){
        let exts: AhoStmtExtItem[] = [];
        let cs = this.parentStore.cardStore;
        if (cs.fieldsItems) {
            let noChecked = cs.fieldsItems.every(f=>!f.checked);
            cs.fieldsItems.forEach(f=>{
                if ((noChecked || f.checked) && cs.cellsItems) {
                    let cells = cs.cellsItems.filter(c=>c.field_id === f.field_id);
                    cells.forEach(c=>{
                        exts.push({field_id: f.field_id, field_name: f.field_name, cell_id: c.cell_id,
                            cell_name: c.cell_name, main: true, ext: false});
                    });
                }
            });
        }
        exts = exts.slice().sort((a,b) => {
           if (AhoUtils.isNum(a.cell_name) && AhoUtils.isNum(b.cell_name)) return +a.cell_name - +b.cell_name
            else return TextUtils.stringSortCompare(a.cell_name, b.cell_name)
        });
        this.exts = exts;
    }
    updIndcs(){
        let indcs_ = this.parentStore.projStore.indicators.slice().sort((a,b) =>
            TextUtils.stringSortCompare(a.indc_name, b.indc_name));
        this.indcs = indcs_.filter(i=>!!i.lab_code).map(i=>{return {indc_id: i.indc_id, indc_code: i.indc_code, indc_name: i.indc_name,
            unit: i.unit, standard: i.standard, main: false, ext: false}});
    }
    resetIndc(isExt?: boolean){
        let indcs = this.indcs.slice();
        indcs.forEach(i=>{
            if (isExt) i.ext = false
            else i.main = false;
        });
        this.indcs = indcs;
    }
    doMainResearchReset(){
        this.resetIndc();
    }
    doExtResearchReset(){
        this.resetIndc(true);
    }
    resetExts(){
        let exts = this.exts.slice();
        exts.forEach(e=>{
            e.ext = false;
        });
        this.exts = exts;
    }
    doExtFieldReset(){
        this.resetExts();
    }
    doBackClick(){
        if (this.stage == AhoStmtStage.Step2) this.stage = AhoStmtStage.Step1;
        if (this.stage == AhoStmtStage.Step3) this.stage = AhoStmtStage.Step2;
        if (this.stage == AhoStmtStage.Step4) this.stage = AhoStmtStage.Step3;
    }

    doCancel(){
        this.selCellShow = false;
        this.parentStore.toggleModal(false);
        this.resetData();
        this.parentStore.mapStore.opacityStore.updOpacityLayers();
    }

    uniqArr(arr: any){
        let arr_: any = [];
        arr.forEach((i: any)=>{
            if (arr_.indexOf(i) < 0) arr_.push(i);
        });
        return arr_;
    }

    onDownloadClick(){
        let store_ = this.parentStore;
        let pstore = store_.projStore;
        let cstore = store_.cardStore;

        let params: any = {
            main: {
                // cell_ids: this.uniqArr(this.exts.map(e=>e.cell_id)),
                field_ids: this.uniqArr(this.exts.map(e=>e.field_id)),
                indc_ids: this.indcs.filter(i=>i.main).map(i=>i.indc_id),
                work_date: store_.format_date3(this.fieldWorkDate)
            },
            lab_agreement: this.contract,
            customer: this.client
        };
        if (this.isExtResearch) {
            params.extended = {
                cell_ids: this.exts.filter(e=>e.ext).map(e=>e.cell_id),
                indc_ids: this.indcs.filter(i=>i.ext).map(i=>i.indc_id)
            }
        }
        let r = [
            pstore.projName,
            cstore.card.map_id,
            JSON.stringify(params)
        ];
        let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/lab_statement/create?params=${r[2]}`;
        console.log('url:');
        console.log(url);
        Utils.downloadFile(url);
        // this.root.addInfo(this.root.trans['Saved successfully']);
    }

    onMarkMapClick(){
        this.parentStore.cardStore.fieldsShow = false;
        this.parentStore.toggleModal(false);
        this.selCellShow = true;
        this.parentStore.mapStore.opacityStore.updOpacityLayers();
        this.parentStore.mapStore.stmtSelCellsStore.doSelCellsModeClick();
        //this.parentStore.toggleModal(true, AhoModalType.CreateStatement);
    }

    doMapSelCancelClick(){
        this.parentStore.mapStore.stmtSelCellsStore.resetData();
        this.selCellShow = false;
        this.parentStore.toggleModal(true, AhoModalType.CreateStatement);
    }
    doMapSelApplyClick(){
        this.parentStore.mapStore.stmtSelCellsStore.saveData();
        this.selCellShow = false;
        this.parentStore.toggleModal(true, AhoModalType.CreateStatement);
    }

    resetData(){
        this.contract = this.parentStore.projStore.asaLabAgreement || '';
        this.client = '';
        this.filter = '';
        this.isExtResearch = false;
        this.sortMode = AhoStmtSort.Indc;
        this.lastIndcSort = AhoStmtSort.Indc;
        this.stage = AhoStmtStage.Step1;
        this.parentStore.mapStore.stmtSelCellsStore.resetData();
    }

    isSelCellsMode(){
        return this.root.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            ((this.parentStore.ahoModalType == AhoModalType.CreateStatement && this.parentStore.ahoDialogShow)
            || this.selCellShow)
    }

    onStmtMenuClick(){
        this.resetData();
        this.parentStore.toggleModal(true, AhoModalType.CreateStatement);
    }
    doFilterChange(val: string){
        this.filter = val;
    }
    doSortClick(mode: AhoStmtSort){
        if (mode == AhoStmtSort.Indc) this.indcs = this.indcs.slice().sort((a,b) =>
            TextUtils.stringSortCompare(a.indc_name, b.indc_name));
        if (mode == AhoStmtSort.IndcDesc) this.indcs = this.indcs.slice().sort((a,b) =>
            TextUtils.stringSortCompare(b.indc_name, a.indc_name));
        if (mode == AhoStmtSort.Research) this.indcs = this.indcs.slice().sort((a,b) =>
            a.main === b.main ? 0 : a.main ? -1 : 1);
        if (mode == AhoStmtSort.ExtResearch) this.indcs = this.indcs.slice().sort((a,b) =>
            a.ext === b.ext ? 0 : a.ext ? -1 : 1);
        this.sortMode = mode;
        if (mode == AhoStmtSort.Indc || mode == AhoStmtSort.IndcDesc) this.lastIndcSort = mode;
    }
    doExtCheckboxClick(){
        this.isExtResearch = !this.isExtResearch;
        if (this.sortMode == AhoStmtSort.ExtResearch && !this.isExtResearch) this.sortMode = AhoStmtSort.NoSort;
    }

}