import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2UploadBindColumnStore} from "../../../../../store/agro/UploadFields/A2UploadBindColumnStore";
import './A2UploadFormItemColumn.scss';
import {DropDownComp, IDropDownItem} from "../../../../Common/DropDownComp";
import classNames from "classnames";
import {action} from "mobx";

export interface IA2UploadFormItemColumn extends IStoreProps{
    item: A2UploadBindColumnStore;
}
@observer
export class A2UploadFormItemColumn extends React.Component<IA2UploadFormItemColumn, undefined> {
    constructor(props: IA2UploadFormItemColumn) {
        super(props);
        autoBindReact(this);
    }

    static readonly NoImportKey = "__NoImportKey";

    @action
    onChangeBind(item: IDropDownItem, newIndex: number){
        if (item != null) {
            this.props.item.bindColumnName = item.data;
        }
    }

    render() {
        let store = this.props.store;
        let item = this.props.item;
        let exArr: any[] = item.fileValues.map((a, index) => <div
            className="A2UploadFormItemColumn-exampleStr" key={index.toString()}>{a}</div>);
        let fieldCols: IDropDownItem[] = [];
        let fieldColCurrent: string = item.bindColumnName;
        fieldCols.push({key: A2UploadFormItemColumn.NoImportKey, data: null, value: store.trans["Don't import"]});
        if (fieldColCurrent == null) fieldColCurrent = A2UploadFormItemColumn.NoImportKey;

        store.agro2.projectInfo.projectInfoJson.type_info.type_schema.obj.file_import.mapping_columns.forEach(colName => {
            let col = store.agro2.projectStruct.getColumnByName(colName);
            if (col != null)
                fieldCols.push({key: col.column_name, data: col.column_name, value: col.title});
            else{
                console.error(`mapping_columns ${colName} is not found`);
            }
        });
        return <tr>
            <td className="A2UploadFormItemColumn-key">
                <div className="A2UploadFormItemColumn-columnName">{item.name}</div>
                {exArr}
            </td>
            <td className="A2UploadFormItemColumn-value">
                <DropDownComp items={fieldCols} currentKey={fieldColCurrent}
                              onChange={this.onChangeBind}
                              className={classNames("width100",{"A2UploadFormItemColumn-noimport": fieldColCurrent == A2UploadFormItemColumn.NoImportKey})}/>
            </td>
        </tr>;
    }
}
