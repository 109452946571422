import * as React from 'react';
import {ReactNode} from 'react';
import autoBindReact from "auto-bind/react";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import './MyHint.scss';

export interface IMyHintProps {
    text?: string;
    overlay?: ReactNode;
    overlayInnerStyle?: React.CSSProperties;
    mouseEnterDelay?: number;
    placement?:'left'|'right'|'top'|'bottom'| 'topLeft'| 'topRight'| 'bottomLeft'| 'bottomRight'
}

export class MyHint extends React.PureComponent<IMyHintProps, undefined> {
    constructor(props: IMyHintProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        if (this.props.text == null && this.props.overlay == null) return <React.Fragment>{this.props.children as any}</React.Fragment>;

        let placement = this.props.placement??"top";

        return <Tooltip placement={placement}  //visible={true}
                        mouseEnterDelay={this.props.mouseEnterDelay??0.5}
                        overlayInnerStyle={this.props.overlayInnerStyle}
                        overlayClassName="myHint"
                        overlay={this.props.overlay? this.props.overlay : <div>{this.props.text}</div>}>
            {this.props.children as any}
        </Tooltip>;
    }
}
