// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.rc-tooltip-inner {
  font-size: 12px;
  background-color: #2b3138;
  color: #C5C5C5;
  padding: 4px 8px;
  min-height: auto;
  max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./app/components/Common/MyHint.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF","sourcesContent":["div.rc-tooltip-inner{\r\n  font-size: 12px;\r\n  background-color: #2b3138;\r\n  color: #C5C5C5;\r\n  padding: 4px 8px;\r\n  min-height: auto;\r\n  max-width: 200px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
