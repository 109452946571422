import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoExportRNPanelComp.scss'
import {action, observable} from "mobx";
import classNames from "classnames";
import {AgroAhoRectCheckboxSvg} from "./AgroAhoRectCheckboxSvg";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {AgroAhoRoundCheckboxSvg} from "./AgroAhoRoundCheckboxSvg";
import {AhoCardShowType, AhoIndicatorFormat, ExportRNFertType, GridGrainSize} from "../agroAhoCardStore";
import {AgroAhoSwitcherSvg} from "./icons/AgroAhoSwitcherSvg";
import {AgroAhoDel3Svg} from "./icons/AgroAhoDel3Svg";
import {DropDownComp, IDropDownItem} from "../../../app/components/Common/DropDownComp";
import {AgroAhoSpinnerComp} from "./AgroAhoSpinnerComp";
import {AgroAhoMatchSvg} from "./icons/AgroAhoMatchSvg";



@observer
export class AgroAhoExportRNPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    spinnerShow: boolean = false;

    @action
    onNormsClick(){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.normsChecked = !cs.normsChecked;
    }

    @action
    onFertClick(){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.fertChecked = !cs.fertChecked;
    }

    @action
    onClickExport(){
        let store_ = this.props.store.agroAhoStore;
        let cs = store_.cardStore;
        if ((!cs.normsChecked && !cs.fertChecked) || this.spinnerShow) return;
        this.spinnerShow = true;
        store_.fileStore.doExport(this.exportCallback);
        // store_.fileStore.doExport(store_.cardStore.card.map_id, this.cartogramChecked, this.indicatorsChecked,
        //     store_.cardStore.ahoCardShowType, store_.cardStore.indcFormat == AhoIndicatorFormat.AgroSoft,
        //     this.visFieldsChecked);
        // store_.toggleModal(false);
    }

    exportCallback(res: string){
        this.spinnerShow = false;
    }

    @action
    onClickCancel(){
        let store_ = this.props.store.agroAhoStore;
        store_.fileStore.cancelExport = true;
        store_.toggleModal(false);
    }

    @action
    onClickVisFields(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        let hasRN = cstore.cardRNStore.hasRNSelFields(true);
        if (!cstore.getFieldSelected()?.length || !hasRN) return;
        cstore.exportVisFields = !cstore.exportVisFields;
        cstore.updRecNormStat();
        // this.visFieldsChecked = !this.visFieldsChecked;
    }

    onExpRNFileNameChange(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.exportRNFileName = e?.currentTarget?.value;
    }

    onFertItemClick(f: ExportRNFertType){
        let cs = this.props.store.agroAhoStore.cardStore;
        if (!cs.fertChecked) return;
        cs.exportRNFertType = f;
    }

    onGridSizeChange(d: IDropDownItem){
        let cs = this.props.store.agroAhoStore.cardStore;
        if (!cs.fertChecked) return;
        cs.gridGrainSize = d.key as GridGrainSize;
        cs.updRecNormStat();
    }

    onFertTotalFocus(){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.fertTotalStr = cs.fertTotal ? cs.fertTotal+'' : cs.fertTotal === 0 ? '0' : '';
    }

    onFertTotalBlur(e: any){
        let cs = this.props.store.agroAhoStore.cardStore;
        let val = e?.currentTarget?.value;
        cs.setFertTotalStr(val);
    }

    onFertTotalChange(e: any){
        let cs = this.props.store.agroAhoStore.cardStore;
        let val = e?.currentTarget?.value;
        let sval = (val+'' || '').replace(/ /g, '');
        if (!cs.parentStore.isNum(sval)) return;
        cs.fertTotal = +sval;
        cs.fertTotalStr = val;
        // console.log('change', val, cs.fertTotalStr);
        cs.recalcRecNormMinMax();
    }

    onFertReturn(){
        let cs = this.props.store.agroAhoStore.cardStore;
        if (!cs.fertChecked) return;
        cs.updRecNorm();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let mstore = store_.mapStore;
        let tstore = store_.tourStore;
        let ustore = store_.uploadTableStore;
        let disabled = (!cstore.normsChecked && !cstore.fertChecked) || this.spinnerShow;
        let fert_id = mstore.mapRNStore.curRecNormItem.fert_id
        let fert_name = ustore.getFertName(fert_id);
        let type = cstore.ahoCardShowType;
        let labels = store_.trans["Without labels"];
        if (type == AhoCardShowType.Cells) labels = store_.trans["Cell numbers"];
        if (type == AhoCardShowType.Indicators) labels = store_.trans["Meaning of indicators"];
        // let scale = cstore.indcFormat == AhoIndicatorFormat.AgroSoft ? store_.trans["Agro-Soft"] :
        //     store_.trans["AGROPLEM"];
        let tours = tstore.selTours(true).map(t=>t.tour_name).join(',');
        let techs = [{c: store_.trans['grid file for technology (ISO-XML)'], t: ExportRNFertType.TechXml},
            {c: store_.trans['grid file (.shp)'], t: ExportRNFertType.TechShp}].map(i=>{
            // {c: store_.trans['Cell borders (.shp)'], t: ExportRNFertType.CellsShp}].map(i=>{
            return <div key={i.t} className="AgroAhoExportRNPanelComp-item" onClick={this.onFertItemClick.bind(this, i.t)}>
                <AgroAhoRoundCheckboxSvg className={classNames("AgroAhoExportRNPanelComp-itemCheckbox", {
                    "AgroAhoExportRNPanelComp-checked": i.t == cstore.exportRNFertType})}
                    checked={i.t == cstore.exportRNFertType}/>
                <div className="AgroAhoExportRNPanelComp">{i.c}</div>
            </div>
        });
        let g_sizes = [GridGrainSize.ggs10, GridGrainSize.ggs15, GridGrainSize.ggs20, GridGrainSize.ggs30];
        let gs_items: IDropDownItem[] = g_sizes.map(s=>{return {key: s, data: s, value: s+''}});

        let st = cstore.getStat();
        let flds = cstore.exportVisFields && st.fflds ? st.fflds : st.flds;
        let area = cstore.exportVisFields && st.farea ? store_.toFloat(st.farea) : store_.toFloat(st.area);
        let fst = {min: store_.toFloat(cstore.fertMinStat), avg: store_.toFloat(cstore.fertAvgStat),
            max: store_.toFloat(cstore.fertMaxStat)};
        let hasRN = store_.cardStore.cardRNStore.hasRNSelFields(true);
        let switcherCaption = hasRN ? store_.trans['Export data from selected fields only'] :
            `(${store_.trans['There are no norms set for the selected fields']})`;

        return <div className="AgroAhoExportRNPanelComp-main">
            <div className="AgroAhoExportRNPanelComp-title">{store_.trans['Export of recommended norms']}</div>

            <div className={classNames("AgroAhoExportRNPanelComp-spinner", {
                "AgroAhoExportRNPanelComp-hidden": !this.spinnerShow})} >
                <div>
                    <div className="AgroAhoExportRNPanelComp-ls spinner"/>
                    <div className="AgroAhoExportRNPanelComp-spinnerMsg">
                        {store_.trans['Preparations are underway for export']}...</div>
                </div>
            </div>
            <div className={classNames("AgroAhoExportRNPanelComp-content", {
                "AgroAhoExportRNPanelComp-hidden": this.spinnerShow})} >

                {!!tours && <div className="AgroAhoExportRNPanelComp-msg">
                    <span>{store_.trans.Tours}:&nbsp;</span>
                    <span>{tours}</span>
                </div>}

                <div className={classNames("AgroAhoExportRNPanelComp-checkboxCover AgroAhoExportRNPanelComp-switcherCover",
                    {"AgroAhoExportRNPanelComp-switcherDisabled": !cstore.getFieldSelected()?.length || !hasRN})}
                     onClick={this.onClickVisFields}>
                    <AgroAhoSwitcherSvg className="AgroAhoExportRNPanelComp-switcher" checked={cstore.exportVisFields}/>
                    <span className="AgroAhoExportRNPanelComp-formatCaption">{switcherCaption}</span>
                </div>

                <div className="AgroAhoExportRNPanelComp-line"/>

                <div className="AgroAhoExportRNPanelComp-fileNameTitle">
                    <div>{store_.trans['File name']}</div>
                    <div className="AgroAhoExportRNPanelComp-zip">ZIP</div>
                </div>
                <input type="text" className="AgroAhoExportRNPanelComp-fileName text-box-editor"
                       value={cstore.exportRNFileName} onChange={this.onExpRNFileNameChange}/>

                <div className="AgroAhoExportRNPanelComp-formatTitleCover">
                    <div className="AgroAhoExportRNPanelComp-sectTitle">{store.trans.Format}</div>
                </div>

                <div className="AgroAhoExportRNPanelComp-checkboxCover" onClick={this.onNormsClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoExportRNPanelComp-formatCheckbox"
                                                   checked={cstore.normsChecked}/>
                    <span className="AgroAhoExportRNPanelComp-formatCaption">
                        {store_.trans['Tabular file, all norms (.ods)']}</span>
                </div>
                <div className="AgroAhoExportRNPanelComp-checkboxCover" onClick={this.onFertClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoExportRNPanelComp-formatCheckbox"
                                                   checked={cstore.fertChecked}/>
                    <span className="AgroAhoExportRNPanelComp-formatCaption">{fert_name}</span>
                </div>
                <div className={classNames("AgroAhoExportRNPanelComp-fertCover",
                    {"AgroAhoExportRNPanelComp-disabled": !cstore.fertChecked})}>
                    {techs}
                    <div className="AgroAhoExportRNPanelComp-gridSizeCaption">{store_.trans['Grid grain size (m)']}</div>
                    <div className="AgroAhoExportRNPanelComp-gridSizeCover">
                        <DropDownComp items={gs_items} onChange={this.onGridSizeChange} currentKey={cstore.gridGrainSize}
                            disabled={!cstore.fertChecked}/>
                    </div>
                </div>

            </div>

            <div className={classNames("AgroAhoExportRNPanelComp-totalFertCover", {
                "AgroAhoExportRNPanelComp-totalFertDisabled": !cstore.fertChecked,
                "AgroAhoExportRNPanelComp-hidden": this.spinnerShow})}>

                <div className="AgroAhoExportRNPanelComp-totalMsg">
                    <span>{store_.trans.Fields}:&nbsp;</span>
                    <span>{flds}&nbsp;{store_.trans.pcs},&nbsp;</span>
                    <span>{area}&nbsp;{store.trans.ha}</span>
                </div>

                <div className="AgroAhoExportRNPanelComp-totalFertBlockCover">
                    <div className="AgroAhoExportRNPanelComp-totalFertBlock">
                        <div className="AgroAhoExportRNPanelComp-normTitle">{
                            store_.trans['Total volume of fertilizers']}</div>
                        <div className="AgroAhoExportRNPanelComp-normFertCover">
                            <input type="text" className="AgroAhoExportRNPanelComp-fertTotal" value={cstore.fertTotalStr}
                                   onChange={this.onFertTotalChange} disabled={!cstore.fertChecked}
                                   onFocus={this.onFertTotalFocus} onBlur={this.onFertTotalBlur}/>
                            <div className="AgroAhoExportRNPanelComp-normFertKg">{store_.trans.kg}</div>
                        </div>
                    </div>
                    <div className="AgroAhoExportRNPanelComp-totalFertBlock">
                        <AgroAhoMatchSvg className="AgroAhoExportRNPanelComp-match"/>
                    </div>
                    <div className="AgroAhoExportRNPanelComp-totalFertBlock">
                        <div className="AgroAhoExportRNPanelComp-normTitle">{
                            store_.trans['Min. norm']}</div>
                        <div className="AgroAhoExportRNPanelComp-normMinMaxCover">
                            <span className="AgroAhoExportRNPanelComp-normVal">{fst.min}</span>
                            <span>{store_.trans['kg/ha']}</span>
                        </div>
                    </div>
                    <div className="AgroAhoExportRNPanelComp-normSplitter"/>
                    <div className="AgroAhoExportRNPanelComp-totalFertBlock">
                        {/*<div className="AgroAhoExportRNPanelComp-normDash"/>*/}
                        <div className="AgroAhoExportRNPanelComp-normTitle">{store_.trans['Avg. norm']}</div>
                        <div className="AgroAhoExportRNPanelComp-normMinMaxCover">
                            <span className="AgroAhoExportRNPanelComp-normVal">{fst.avg}</span>
                            <span>{store_.trans['kg/ha']}</span>
                        </div>
                    </div>
                    <div className="AgroAhoExportRNPanelComp-normSplitter"/>
                    <div className="AgroAhoExportRNPanelComp-totalFertBlock">
                        <div className="AgroAhoExportRNPanelComp-normTitle">{store_.trans['Max. norm']}</div>
                        <div className="AgroAhoExportRNPanelComp-normMinMaxCover">
                            <span className="AgroAhoExportRNPanelComp-normVal">{fst.max}</span>
                            <span>{store_.trans['kg/ha']}</span>
                        </div>
                    </div>
                </div>

                <div className={classNames("AgroAhoExportRNPanelComp-normReturn",{
                    "AgroAhoExportRNPanelComp-normReturnDisabled": !cstore.fertChecked})} onClick={this.onFertReturn}>
                    {store_.trans['Return to recommended value']}</div>
            </div>

            <div className="AgroAhoExportRNPanelComp-buttons">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoExportRNPanelComp-cancelButton"
                    onClick={this.onClickCancel}>{store_.trans.Cancel}</button>
                <button className={classNames("large-button left-sidebar-active AgroAhoExportRNPanelComp-exportButton", {
                    "button-jump": !disabled}, {"AgroAhoExportRNPanelComp-disabled": disabled})}
                    onClick={this.onClickExport}>{store_.trans.Export}</button>
            </div>
        </div>
    }
}
