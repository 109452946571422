import * as React from 'react';
import classNames from "classnames";
import {isFunction} from "lodash-es";
import './AgroAhoStepsComp.scss'


export interface IAgroAhoStepsCompProps {
    step: number;
    steps?: number;
    style2?: boolean;
}

export class AgroAhoStepsComp extends React.PureComponent<IAgroAhoStepsCompProps, undefined> {
    render() {
        let arr = [];
        let steps = this.props.steps || 5;
        for (let i = 1;i<steps+1;i++) {
            arr.push(
                <div key={'c'+i} className={classNames("AgroAhoStepsComp-circle",{
                    "AgroAhoStepsComp-active": this.props.step == i,
                    "AgroAhoStepsComp-disabled": this.props.step > i})}>{i}</div>
            );
            if (i < steps) arr.push(<div key={'l'+i} className="AgroAhoStepsComp-line"/>);
        }

        return <div className={classNames("AgroAhoStepsComp-main", {
            "AgroAhoStepsComp-style2": this.props.style2})}>
            {arr}
        </div>
    }
}
