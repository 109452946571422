import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";

export interface IShowRoom2TopFilterPopup extends IStoreProps{
    onMouseEnter?: ()=>any,
    onMouseLeave?: ()=>any,
}
@observer
export class ShowRoom2TopFilterPopup extends React.Component<IShowRoom2TopFilterPopup, undefined> {
    constructor(props: IShowRoom2TopFilterPopup) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        return <div className="ShowRoom2TopFilterPopup" onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>{this.props.children}</div>;
    }
}
