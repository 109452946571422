import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {action, autorun, observable, reaction} from "mobx";
import {fetchJsonGet} from "../../../app/helper/utils/FetchUtils";
import {CirclePaint, LinePaint, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {GeometryUtils} from "../../../app/helper/utils/GeometryUtils";
import {AgroAhoStore, AhoStage} from "../agroAhoStore";
import {AhoCardShowType, AhoIndicatorFormat, Card, IndicatorKey, SoilAnalysisType} from "../agroAhoCardStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelModeAgroAho} from "../agroAhoPlugin";
import centroid from "@turf/centroid";
import {AgroAhoTool} from "./agroAhoTool";
import {AhoEditorMode} from "../agroAhoEditorStore";
import bbox from "@turf/bbox";
import {AgroAhoLayers} from "./layers/agroAhoLayers";
import {AgroAhoMapTourStore} from "./agroAhoMapTour";
import {AhoUtils} from "../agroAhoUtils";
import {AgroAhoMapRNStore} from "./agroAhoMapRN";
import {AgroAhoMapDebugStore} from "./debug/agroAhoMapDebug";
import {AgroAhoTrackFieldPointLayer} from "./layers/agroAhoTrackFieldPointLayer";
import {AgroAhoEditRoute, AhoEditRouteMode} from "./agroAhoEditRoute";
import {AgroAhoOpacity} from "./agroAhoOpacity";
import {AgroAhoMapSampleStore} from "./agroAhoMapSample";
import {AgroAhoStmtSelectCells} from "./agroAhoStmtSelectCells";


interface Extent {
    min_x: number,
    max_x: number,
    min_y: number,
    max_y: number
}

interface DictExtents {
    [key: string]: Extent;
}

export enum IAhoLayers {
    RnLbl = 'rn_lbl',
    CellLbl = 'cell_lbl',
    FieldLbl = 'field_lbl',
    IndLbl = 'ind_lbl',
    IndLine = 'ind_line',
    RnLine = 'rn_line',
    TrackCell = 'track_cell',
    TrackField = 'track_field',
    TrackFieldPoint = 'track_field_point',
    TrackRoute = 'track_route',
    SelCells = 'sel_cells',
    SamplePoint = 'sample_point',
    TrackRouteLbl = 'track_route_lbl',
    Point = 'point',
    Cell = 'cell',
    CellFill = 'cell_fill',
    Field = 'field',
    Rn = 'rn',
    Ind = 'ind'
}

// <projName>_<map_id>[_L_[c][t][f]] || _I_[indc_id]_[c]]
export interface CardLayersConfig {
    cells: boolean;
    cellsNumber: boolean;
    tracks: boolean;
    trackRoute: boolean;
    fields: boolean;
    points: boolean;
    fieldsNumber: boolean;
    indicators: boolean;
    indicatorsNumber: boolean;
    recNorm: boolean;
    recNormNumber: boolean;
    // trackOpacity?: boolean;
    tours?: string;
}
interface IParamsCellGeoJSON {
    ds: string,
    upd?: boolean,
    no_geom?: boolean,
    // filter_?: string,
    track_type?: string,
    type?: IAhoLayers
    copy?: boolean;
}

// export enum AhoDataType {
//     Indicators = 'Indicators',
//     RecNorms = 'RecNorms'
// }


export const TEST_TOUR_PROJ = 'proj276'; // ASA-2
export const NEW_FORMAT_PROJS = ['proj276', 'proj189', 'proj104']; // ASA-2, АГРОПЛЕМ_Путник, ФОСАГРО_Ставрополь


export class AgroAhoMapStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
        this.layers = new AgroAhoLayers(parent);
    }

    layers: AgroAhoLayers;

    parentStore: AgroAhoStore;
    @observable
    layersShow: boolean = false;
    @observable
    layerPointsShow: boolean = true;
    @observable
    layerFieldOutlinesShow: boolean = true;
    @observable
    layerCellsShow: boolean = true;
    @observable
    layerTracksShow: boolean = true;
    hoveredFeatureId: number = null;
    _cache_geojsons: any = {};

    layerEventsInit: any = {};
    extents: DictExtents = {};
    @observable
    legendShow: boolean = false;
    lastIndicatorKey: IndicatorKey;
    lastHideIndc: boolean = false;
    @observable
    updatingCardLayersInProgress: boolean = false;
    lastCfg: CardLayersConfig = {
        cells: false,
        cellsNumber: false,
        tracks: false,
        trackRoute: false,
        fields: false,
        points: false,
        fieldsNumber: false,
        indicators: false,
        indicatorsNumber: false,
        recNorm: false,
        recNormNumber: false
    };
    newCfg: CardLayersConfig = null;

    isDblClickInit: boolean = false;
    agroAhoTool: AgroAhoTool;
    unselectedIndcOpacity: number = 0.2;
    isInit: boolean = false;
    lastLayersIds: string[] = [];

    subscription(): IReactionDisposer[]{
        return [
            // autorun(this.initMap),
            reaction(()=>this.root.map.currentBaselayerKey,
                ()=>this.doChangeBaseLayers())
        ]
    }

    tourStore: AgroAhoMapTourStore = new AgroAhoMapTourStore(this);
    mapRNStore: AgroAhoMapRNStore = new AgroAhoMapRNStore(this);
    mapSampleStore: AgroAhoMapSampleStore = new AgroAhoMapSampleStore(this);
    mapDebugStore: AgroAhoMapDebugStore = new AgroAhoMapDebugStore(this);
    trackFieldPointStore: AgroAhoTrackFieldPointLayer = new AgroAhoTrackFieldPointLayer(this);
    mapEditRouteStore: AgroAhoEditRoute = new AgroAhoEditRoute(this);
    opacityStore: AgroAhoOpacity = new AgroAhoOpacity(this);
    stmtSelCellsStore: AgroAhoStmtSelectCells = new AgroAhoStmtSelectCells(this);

    isNewProj(){
        // return NEW_FORMAT_PROJS.indexOf(this.parentStore.projStore.projName) >= 0;
        return true;
    }

    initMap(){
        if (this.isInit || !this.root.map.mapbox) return;
        this.root.map.mapbox.on('styledata', (e: any) => {
            let ids = e?.style?._order || [];
            let lids = this.lastLayersIds;
            // console.log('ids:', AhoUtils.cp(lids), AhoUtils.cp(ids));
            ids.forEach((id: string)=>{
                if (lids.indexOf(id)<0) console.log('added layer', id);
            });
            lids.forEach((id: string)=>{
                if (ids.indexOf(id)<0) console.log('removed layer', id);
            });
            this.lastLayersIds = AhoUtils.cp(ids);
            // console.log('A styledata event occurred.', e);
        })
        this.isInit = true;
    }

    async updLayers(caller?: string){
        this.doCloseCard();
        await this.updCardLayers({caller: caller});
        await this.parentStore.cardStore.doUpdFieldsOpacity();
    }

    doChangeBaseLayers(){
        if (this.root.searchPanel.leftPanelMode === LeftPanelModeAgroAho &&
            this.parentStore.ahoStage === AhoStage.Card) {
            setTimeout(
            ()=>{
                this.updLayers('doChangeBaseLayers');
            }, 1000);
        }
    }

    doChangeCardVisible(isVisible: boolean){
        if (isVisible) {
            this.updCardLayers({caller: 'doChangeCardVisible'}).then();
            this.layers.testFunc();
        } else this.doCloseCard();
    }

    async doChangeConfig(){
        this.parentStore.cardStore.cardRNStore.updRNGroups();
        await this.updCardLayers({caller: 'doChangeConfig'}).then();
        // await this.parentStore.cardStore.doUpdFieldsOpacity();
        let cstore = this.parentStore.cardStore;
        let estore = this.parentStore.editorStore;
        let rstore = this.parentStore.mapStore.mapEditRouteStore;
        if (cstore.soilAnalysisShow) {
            estore.mode = AhoEditorMode.Disabled;
            estore._lastMode = estore.mode;
            estore.editManager.exit();
            rstore.doDisableModeClick();
        }
    }

    get indicatorKey(): IndicatorKey {
        let cs = this.parentStore.cardStore;
        let ds = cs.card?.cell_info?.ds_code;
        let indc_code = cs.selectedIndicator?.indc_code;
        if (!ds || !indc_code) return;
        return {ds: ds, indc_code: indc_code, format: cs.indcFormat};
    }

    getEmptyCfg(){
        return {
            cells: false,
            cellsNumber: false,
            tracks: false,
            fields: false,
            fieldsNumber: false,
            indicators: false,
            indicatorsNumber: false
        };
    }

    doCloseCard(){
        this.updCardLayers({cfg_: this.getEmptyCfg(), caller: 'doCloseCard'});
    }

    getCardLayersState(): CardLayersConfig {
        let ps = this.parentStore;
        let cs = ps.cardStore;
        let ts = ps.tourStore;
        if (this.root.searchPanel.leftPanelMode !== LeftPanelModeAgroAho) return this.getEmptyCfg() as CardLayersConfig;

        return {
            cells: (this.layersShow && this.layerCellsShow) ||
                (cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.RecNorms),
            // cellsNumber: ((!cs.indicatorsShow && this.layersShow && this.layerCellsShow
            //     && cs.ahoCardShowType != AhoCardShowType.WithoutLabels) ||
            //     (cs.indicatorsShow && cs.ahoCardShowType == AhoCardShowType.Cells)),
            cellsNumber: (!(this.layersShow && this.mapEditRouteStore.mode == AhoEditRouteMode.Edit) &&
                ((!cs.soilAnalysisShow && this.layersShow && this.layerCellsShow
                ) ||
                // && cs.ahoCardShowType != AhoCardShowType.WithoutLabels) ||
                (cs.soilAnalysisShow && cs.soilAnalysisType != SoilAnalysisType.Spinner
                    && cs.ahoCardShowType == AhoCardShowType.Cells))),
            tracks: this.layersShow && this.layerTracksShow,
            trackRoute: this.layersShow && (this.mapEditRouteStore.mode == AhoEditRouteMode.Select ||
                this.mapEditRouteStore.mode == AhoEditRouteMode.Edit),
            fields: (this.layersShow && this.layerFieldOutlinesShow) ||
                (cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.Indicators && !!this.indicatorKey) ||
                (cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.RecNorms),
            points: this.layersShow && this.layerPointsShow,
            // fieldsNumber: (this.layersShow && this.layerFieldOutlinesShow && !this.layerCellsShow) ||
            // fieldsNumber: (this.layersShow && this.layerFieldOutlinesShow) ||
            fieldsNumber: (this.layersShow && cs.fieldsNumberShow) ||
                (this.layersShow && this.mapEditRouteStore.mode == AhoEditRouteMode.Edit) ||
                (cs.soilAnalysisShow && cs.fieldsNumberShow),
            indicators: cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.Indicators && !!this.indicatorKey,
            indicatorsNumber: cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.Indicators && !!this.indicatorKey &&
                cs.ahoCardShowType == AhoCardShowType.Indicators,
            recNorm: cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.RecNorms,
            recNormNumber: cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.RecNorms &&
                cs.ahoCardShowType == AhoCardShowType.Indicators,
            tours: ts.selTours().map(t=>t.tour_id)+''
        }
    }

    indicatorsKeyChanged(){
        return JSON.stringify(this.lastIndicatorKey) !== JSON.stringify(this.indicatorKey);
    }
    hideIndcChanged(){
        let cs = this.parentStore.cardStore;
        return this.lastHideIndc !== cs.hideIndc;
    }

    async updAllCardLayers(timeout?: number){
        let cfg = this.getCardLayersState();
        await this.updCardLayers({cfg_: this.getEmptyCfg(), caller: 'updAllCardLayers_1'});
        setTimeout(()=>this.updCardLayers({cfg_: cfg, caller: 'updAllCardLayers_2'}), timeout || 0);
    }

    async getTrackCellGeom(cell_id: number){
        let cs = this.parentStore.cardStore;
        // console.log('get json002');
        // let data: any = await this.getCellCardGeoJSONs({ds: cs.card.track_info.ds_code, upd: true, type: IAhoLayers.TrackCell});
        let data: any = await this.getCellCardGeoJSONs({ds: cs.card.track_info.ds_code, type: IAhoLayers.TrackCell,
            upd: true});
        let feat = data.features.filter((f: any)=>f?.properties?.cell_id === cell_id);
        if (feat.length) return feat[0].geometry;
    }

    async getCellFeat(cell_id: number){
        let cs = this.parentStore.cardStore;
        let data: any = await this.getCellCardGeoJSONs({ds: cs.card.cell_info.ds_code, type: IAhoLayers.Cell});
        let feat = data.features.filter((f: any)=>f?.properties?.cell_id === cell_id);
        if (feat.length) return feat[0];
    }

    async getCellGeom(cell_id: number){
        let feat = await this.getCellFeat(cell_id);
        if (feat) return feat.geometry;
    }

    async getFieldGeom(field_id: number, upd?: boolean){
        let cs = this.parentStore.cardStore;
        // console.log('get json001'); // , true
        // let data: any = await this.getCellCardGeoJSONs({ds: cs.card.field_info.ds_code, upd: true, type: IAhoLayers.Field});
        let data: any = await this.getCellCardGeoJSONs({ds: cs.card.field_info.ds_code, type: IAhoLayers.Field, upd: upd});
        let feat = data.features.filter((f: any)=>f?.properties?.field_id === field_id);
        if (feat.length) return feat[0].geometry;
    }

    @action
    async onCellDblClick(e: any, layer_id: string){
        let feat = null;
        if (e.features && e.features.length > 0 && e.features[0].layer.id == layer_id) feat = e.features[0];
        if (!feat || feat.layer.id !== layer_id) return false;
        let cs = this.parentStore.cardStore;
        let estore = this.parentStore.editorStore;
        let editor = estore.editManager;

        if (editor.isEdit()) return false;
        // console.log('onCellDblClick', this.root.searchPanel.leftPanelMode,
        //     this.root.searchPanel.leftPanelMode == LeftPanelModeAgroAho,
        //     estore.mode, estore.mode == AhoEditorMode.Select);
        if (this.root.searchPanel.leftPanelMode == LeftPanelModeAgroAho &&
            estore.mode == AhoEditorMode.Select) {
            estore.cell_id = feat.properties.cell_id;
            let field_id = feat.properties.field_id;
            let fcur = cs.fieldsItems.filter(f=>f.field_id == field_id);
            if (cs.getFieldSelected().length && fcur.length && !fcur[0].checked)
                cs.setSelectAllFields(false);
            estore.mode = AhoEditorMode.Edit;
            await this.parentStore.mapStore.setTrackOpacity(true);
            await this.parentStore.mapStore.trackFieldPointStore.setTFPHidden(true);
            await this.parentStore.mapStore.mapSampleStore.setSLHidden(true);
            estore.fieldInfo = await this.getFieldInfo(cs.card.field_info.ds_code, field_id);
            let geom = await this.getTrackCellGeom(estore.cell_id);
            estore.cell_bbox = bbox(geom) as BBox2d;
            editor.toEdit(geom);
        }
    }

    async setTrackOpacity(opacity: boolean) {
        // this.opacityStore.updOpacityLayers();
        // let map = this.root.map.mapbox;
        // let cs = this.parentStore.cardStore;
        // let layer_id =  this.agroAhoTool.getAhoLayerId(IAhoLayers.TrackCell);  //this.getCardLayerId(cs.card.track_info.ds_code);
        // if (!map.getLayer(layer_id)) return;
        // map.setPaintProperty(layer_id, 'line-opacity', opacity ? 0.2 : null);
        // layer_id =  this.agroAhoTool.getAhoLayerId(IAhoLayers.TrackField); //this.getCardLayerId(cs.card.track_info.ds_code + '_fld');
        // map.setPaintProperty(layer_id, 'line-opacity', opacity ? 0.2 : null);
    }

    @action
    updTrackLayers(){

        let map = this.root.map.mapbox;
        let cs = this.parentStore.cardStore;

        let source_id = this.getCardSourceId(cs.card.track_info.ds_code);
        let source: any = map.getSource(source_id);
        source.setTiles(source.tiles);
        source_id = this.getCardSourceId(cs.card.track_info.ds_code + '_fld');
        source = map.getSource(source_id);
        source.setTiles(source.tiles);
    }

    async updCardLayers(opt?: any){ // cfg_?: CardLayersConfig, caller?: string
        // console.log('upd layers', opt?.caller, opt);
        // if (opt?.caller) console.log('caller:', opt.caller);
        let cs = this.parentStore.cardStore;
        let ms = this.parentStore.mapStore;
        let smstore = ms.mapSampleStore;
        if (!cs.card) return;
        if (!this.agroAhoTool.isInit) return;
        // console.log('progress', this.updatingCardLayersInProgress);
        if (this.updatingCardLayersInProgress) return;
        let noTrack = !Object.keys(cs.card.track_info).length ||
            cs.card.track_info.sum_len_m == 0;
        let noCell = !Object.keys(cs.card.cell_info).length;
        let noField = !Object.keys(cs.card.field_info).length;
        let noPoints = !cs.card.one_sample_point_info || !Object.keys(cs.card.one_sample_point_info).length;
        this.updatingCardLayersInProgress = true;
        let cfg: CardLayersConfig = opt?.cfg_ || this.getCardLayersState();
        this.newCfg = cfg;
        // console.log('NEW CFG', AhoUtils.cp(this.newCfg));

        // console.log('cell num show', cfg.cellsNumber);
        // console.log('last cfg', AhoUtils.cp(this.lastCfg), AhoUtils.cp(cfg));
        // let equalCfg = JSON.stringify(cfg) == JSON.stringify(this.lastCfg);
        // console.log('cfg == last cfg', equalCfg);
        // if (equalCfg) return;
        let with_source = false;

        // Points
        if (!this.lastCfg.points && cfg.points && !noPoints) this.addPointsLayer({
            ds: cs.card.one_sample_point_info.ds_code,
            radius: 5, color: '#15db64'
        });
        if (this.lastCfg.points && !cfg.points && !noPoints) this.deletePointsLayer(cs.card.one_sample_point_info.ds_code);
        // FieldOutlines
        if (!this.lastCfg.fields && cfg.fields && !noField) this.addCardLayer({ds: cs.card.field_info.ds_code,
            width: 4, selectedWidth: 5, color: '#DE2996', layer_id: IAhoLayers.Field});
        if (this.lastCfg.fields && !cfg.fields && !noField) this.deleteCardLayer(cs.card.field_info.ds_code,
            IAhoLayers.Field);
        // Cells
        if (!this.lastCfg.cells && cfg.cells && !noCell) this.addCardLayer({
            ds: cs.card.cell_info.ds_code, layer_id: IAhoLayers.Cell, fill_layer_id: IAhoLayers.CellFill,
            width: 2, color: cfg.indicators ? '#000000' : '#000000', onDblClick: this.onCellDblClick
        });
        if (this.lastCfg.cells && !cfg.cells && !noCell) {
            this.deleteCardLayer(cs.card.cell_info.ds_code, IAhoLayers.Cell);
            this.deleteCardLayer(cs.card.cell_info.ds_code, IAhoLayers.CellFill,
                {type: 'dblclick', f: this.onCellDblClick});
            // this.deleteCardLayer(cs.card.cell_info.ds_code + '_fill', {type: 'dblclick', f: this.onCellDblClick});
        }
        // Track route
        if (!this.lastCfg.trackRoute && cfg.trackRoute && !noCell)
            this.mapEditRouteStore.addRouteLayer(cs.card.cell_info.ds_code);
        if (this.lastCfg.trackRoute && !cfg.trackRoute)
            this.mapEditRouteStore.deleteRouteLayer();
        // Track field
        if (!this.lastCfg.tracks && cfg.tracks && !noTrack) this.addCardLayer({ds: cs.card.track_info.ds_code,
            width: 2, color: '#ed9715', filter: '&filter={"track_type":"field"}', dotted: true, ds_filter: '_fld',
            with_source: with_source, layer_id: IAhoLayers.TrackField});
        if (!this.lastCfg.tracks && cfg.tracks && !noTrack) this.trackFieldPointStore.addTFPLayer(
            cs.card.track_info.ds_code);
        if (this.lastCfg.tracks && !cfg.tracks && !noTrack) {
            this.deleteCardLayer(cs.card.track_info.ds_code, IAhoLayers.TrackField);
            this.deleteCardSource(cs.card.track_info.ds_code + '_fld');
            this.trackFieldPointStore.delTFPLayer()
        }
        // Track cell
        if (!this.lastCfg.tracks && cfg.tracks && !noTrack) {
            this.addCardLayer({ds: cs.card.track_info.ds_code,
                width: 4, color: '#15db64', filter: '&filter={"track_type":"cell"}', with_source: with_source,
                layer_id: IAhoLayers.TrackCell});
            smstore.addSampleLayer(/*{ds: cs.card.mix_sample_point_info.ds_code, layer_id: IAhoLayers.SamplePoint}*/);
        }
        if (this.lastCfg.tracks && !cfg.tracks && !noTrack) {
            this.deleteCardLayer(cs.card.track_info.ds_code, IAhoLayers.TrackCell);
            this.deleteCardSource(cs.card.track_info.ds_code);
            smstore.deleteSampleLayer(/*{ds: cs.card.mix_sample_point_info.ds_code, layer_id: IAhoLayers.SamplePoint}*/);
        }
        // Indicators
        if (!this.lastCfg.indicators && cfg.indicators) this.addIndicatorLayer(this.indicatorKey);
        if (this.lastCfg.indicators && cfg.indicators && this.indicatorsKeyChanged()) this.addIndicatorLayer(this.indicatorKey);
        if (this.lastCfg.indicators && cfg.indicators && this.hideIndcChanged()) this.addIndicatorLayer(this.indicatorKey);
        // console.log('upd indc', this.lastCfg.indicators, cfg.indicators, this.lastCfg.tours, cfg.tours,
        //     this.lastCfg.indicators && cfg.indicators && this.lastCfg.tours !== cfg.tours);
        if (this.lastCfg.indicators && cfg.indicators && this.lastCfg.tours !== cfg.tours) this.updTours(this.indicatorKey);
        // if (equalCfg) return;
        if (this.lastCfg.indicators && !cfg.indicators) this.deleteIndicatorLayer(this.lastIndicatorKey);
        // RecNorm
        // console.log('upd rn', this.lastCfg.recNorm, !cfg.recNorm, AhoUtils.cp(cs.curRecNormItem), AhoUtils.cp(this.))
        if (!this.lastCfg.recNorm && cfg.recNorm) this.mapRNStore.addRecNormLayer(this.mapRNStore.rnKey);
        if (this.lastCfg.recNorm && cfg.recNorm && this.mapRNStore.rnKeyChanged())
            this.mapRNStore.addRecNormLayer(this.mapRNStore.rnKey);
        if (this.lastCfg.recNorm && cfg.recNorm && this.lastCfg.tours !== cfg.tours)
            this.mapRNStore.updRNTours(this.mapRNStore.rnKey);
        if (this.lastCfg.recNorm && !cfg.recNorm) this.mapRNStore.deleteRecNormLayer(this.mapRNStore.lastRNKey);
        // Fields number
        if (!this.lastCfg.fieldsNumber && cfg.fieldsNumber && !noField) this.addFieldLabelLayer(cs.card.field_info.ds_code);
        if (this.lastCfg.fieldsNumber && !cfg.fieldsNumber && !noField) this.deleteFieldLabelLayer(cs.card.field_info.ds_code);
        // Cell numbers
        if (!this.lastCfg.cellsNumber && cfg.cellsNumber && !noCell) this.addCardLabelLayer(cs.card.cell_info.ds_code);
        if ((this.lastCfg.cellsNumber && cfg.cellsNumber && this.indicatorsKeyChanged() && !noCell) ||
            (this.lastCfg.cellsNumber && cfg.cellsNumber && this.lastCfg.cells && !cfg.cells && !noCell))
            this.addCardLabelLayer(cs.card.cell_info.ds_code);
        if (this.lastCfg.cellsNumber && !cfg.cellsNumber && !noCell) this.deleteCardLabelLayer(cs.card.cell_info.ds_code);
        if (!cfg.cells && !cfg.cellsNumber) this.deleteCardSource(cs.card.cell_info.ds_code);
        // Indicators number
        if (!this.lastCfg.indicatorsNumber && cfg.indicatorsNumber) this.addIndicatorLabelLayer(this.indicatorKey);
        if (this.lastCfg.indicatorsNumber && cfg.indicatorsNumber && this.indicatorsKeyChanged())
            this.addIndicatorLabelLayer(this.indicatorKey);
        if (this.lastCfg.indicatorsNumber && !cfg.indicatorsNumber) this.deleteIndicatorLabelLayer(this.lastIndicatorKey);
        // RecNorm number
        if (!this.lastCfg.recNormNumber && cfg.recNormNumber)
            this.mapRNStore.addRecNormLabelLayer(cs.card.cell_info.ds_code);
        if (this.lastCfg.recNormNumber && cfg.recNormNumber && this.mapRNStore.rnIDChanged())
            // await this.mapRNStore.updRNSource(cs.card.cell_info.ds_code);
            this.mapRNStore.addRecNormLabelLayer(cs.card.cell_info.ds_code);
        if (this.lastCfg.recNormNumber && cfg.recNormNumber && this.lastCfg.tours !== cfg.tours)
            this.mapRNStore.updRNSource(this.mapRNStore.rnKey.ds);
        if (this.lastCfg.recNormNumber && !cfg.recNormNumber)
            this.mapRNStore.deleteRecNormLabelLayer(cs.card.cell_info.ds_code);

        this.legendShow = cfg.indicators; // && cs.isLegendPalette();
        this.mapRNStore.legendRNShow = cfg.recNorm;
        this.lastCfg = AhoUtils.cp(cfg);
        this.lastIndicatorKey = this.indicatorKey;
        this.lastHideIndc = cs.hideIndc;
        this.mapRNStore.lastRNKey = this.mapRNStore.rnKey;
        this.mapRNStore.lastRNId = ms.mapRNStore.curRecNormItem?.rn_id;

        this.updatingCardLayersInProgress = false;
        this.parentStore.cardStore.doUpdFieldsOpacity();
    }

    async updTours(key: IndicatorKey){
        // if (this.parentStore.projStore.projName !== TEST_TOUR_PROJ) return;
        if (!this.isNewProj()) return;
        let data = await this.tourStore.getDataJSON(key.ds, IAhoLayers.Cell);
        let map = this.root.map.mapbox;
        let src: any = map.getSource(this.getCellSourceId(key));
        if (!data || !src) return;
        src.setData(data);
        // await this.updIndcsOpacity_();
    }
    getCenters(geojson: any, test?: boolean){
        if (!geojson) return {};
        let gj = JSON.parse(JSON.stringify(geojson));
        // console.log('f:', JSON.parse(JSON.stringify(geojson)), geojson.features)
        gj.features.forEach((f: any)=>{
            // if (!test && f.properties.cell_id == 17) {
            //     console.log('props', JSON.stringify(f));
            // }
            let tmp_geom = JSON.stringify(f.geometry);
            // console.log('polygon', JSON.parse(JSON.stringify(f.geometry)));
            let p = this.parentStore.polylabel.polylabel(f.geometry.coordinates, 100);
            let fCenter = centroid(f.geometry as any);
            // f.geometry = fCenter.geometry;
            f.geometry = {type: 'Point', coordinates: [p[0], p[1]]};
            if (test) f.geometry = fCenter.geometry;
            // console.log('geom:', test, fCenter.geometry.coordinates, p);
        });
        return gj;
    }

    async addFieldLabelLayer(ds: string){
        // console.log('addFieldLabelLayer');
        // if (!this.lastCfg.tracks && cfg.tracks && !noTrack) this.addCardLayer({ds: cs.card.track_info.ds_code,
        //     width: 2, color: '#ed9715', filter: '&filter={"track_type":"field"}', dotted: true, ds_filter: '_fld',
        //     with_source: with_source});
        if (!ds) return;
        let map = this.root.map.mapbox;
        let source_id = this.getCardSourceId(ds);
        let layer_id =  this.agroAhoTool.getAhoLayerId(IAhoLayers.FieldLbl); // this.getFieldLabelLayerId(ds);

        if (!map.getSource(source_id)){
            map.addSource(source_id,{
                type: 'vector',
                tiles: [`${window.location.origin}/api/vectorlayer/${ds}/tile?z={z}&x={x}&y={y}`],
                scheme: "xyz"
            });
        }
        if (!map.getLayer(layer_id)) {
            map.addLayer({
                id: layer_id,
                source: source_id,
                "source-layer": ds,
                type: 'symbol',

                layout: <SymbolLayout>{
                    'text-field': ['get', 'field_name'],
                    // 'text-variable-anchor': ['center'],
                    'text-size': 14,
                    'text-font': [
                        'Open Sans Semibold'
                    ],
                    'text-offset': [0, -0.4],
                    'text-allow-overlap': true,
                    // 'text-ignore-placement': true,
                    visibility: "visible",
                },
                paint: <SymbolPaint>{
                    'text-halo-color': '#C5C5C5',
                    'text-color': "#050607",
                    'text-halo-width': 1,
                    "text-opacity": [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity_lbl']], 'number'],
                        ['feature-state', 'opacity_lbl'],
                        ['==',['feature-state', 'opac'], 1],
                        0.2,
                        1
                    ]
                }
            }, await this.getPrevLayer(IAhoLayers.FieldLbl));
        }
    }

    deleteFieldLabelLayer(ds: string){
        if (!ds) return;
        let map = this.root.map.mapbox;
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.FieldLbl);  // this.getFieldLabelLayerId(ds)
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
    }

    async addCardLabelLayer(ds: string){
        if (!ds) return;
        let map = this.root.map.mapbox;
        if (this.lastIndicatorKey && map.getLayer(this.lastIndicatorKey.ds))
            this.deleteCardLabelLayer(this.lastIndicatorKey.ds);
        let filter = '';
        let source_id = this.getCardSourceId(ds);
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.CellLbl); //this.getCellLabelLayerId(ds);

        if (!map.getSource(source_id)){
            map.addSource(source_id,{
                type: 'vector',
                tiles: [`${window.location.origin}/api/vectorlayer/${ds}/tile?z={z}&x={x}&y={y}${filter}`],
                scheme: "xyz"
            });
        }
        // console.log('card layer_id', layer_id, source_id, ds);
        if (!map.getLayer(layer_id)) {
            map.addLayer({
                id: layer_id,
                source: source_id,
                "source-layer": ds,
                type: 'symbol',
                minzoom: 12,
                layout: <SymbolLayout>{
                    'text-field': ['get', 'cell_name'],
                    // 'text-variable-anchor': ['center'],
                    'text-size': 14,
                    'text-font': [
                        'Open Sans Semibold'
                    ],
                    'text-allow-overlap': false,
                    'text-offset': [0, 0.4],
                    // 'text-ignore-placement': true,
                    visibility: "visible",
                },
                paint: <SymbolPaint>{
                    'text-halo-color': '#C5C5C5',
                    'text-color': "#050607",
                    'text-halo-width': 1,
                    "text-opacity": [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity_lbl']], 'number'],
                        ['feature-state', 'opacity_lbl'],
                        ['==',['feature-state', 'opac'], 1],
                        0.2,
                        1
                    ]
                }
            }, await this.getPrevLayer(IAhoLayers.CellLbl));
        }
        // console.log('card layer_id after', layer_id);
    }

    async getPrevLayer(layer_id: IAhoLayers){
        return this.agroAhoTool.getPrevLayer(layer_id);
    }

    setFeatureOpacity(source: string, id: number, val: boolean, postfix?: string, sel?: boolean) {
        let map = this.root.map.mapbox;
        let source_ = source + (postfix ? postfix : '');
        let source_id = this.getCardSourceId(source_);
        if (!map.getSource(source_id)) return;
        // this.root.map.mapbox.setFeatureState(
        //     { source: this.getCardSourceId(source_), sourceLayer: source, id: id},
        //     { opac: val ? 1 : 0, sel: sel ? 1 : 0}
        // );
    }

    setFeatureIndcOpacity(source: string, id: number, val: boolean, sel?: boolean) {
        let map = this.root.map.mapbox;
        if (!map.getSource(source)) {
            return;
        }
        // this.root.map.mapbox.setFeatureState(
        //     { source: source, id: id},
        //     { opac: val ? 1 : 0, sel: sel ? 1 : 0}
        // );
    }

    // addCardLayer(source_id: string, layer_id: string, ds: string, width: number, color: string, filter: string,
    //              dotted: boolean, onDblClick?: any, with_source?: boolean){

    getPointsSourceId(ds: string): string{
        return  ds + '_p_src_aho';
    }
    // getPointsLayerId(ds: string): string{
    //     // return  ds + '_p_lyr_aho';
    //     return 'class_agroAho_point_lyr_aho';
    // }

    deletePointsLayer(ds: string){
        if (!ds) return;
        let map = this.root.map.mapbox;
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.Point); // this.getPointsLayerId(ds)
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
    }

    async addPointsLayer(opt: any){
        if (!opt.ds) return;
        let source_id = this.getPointsSourceId(opt.ds);
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.Point); // this.getPointsLayerId(opt.ds);
        let map = this.root.map.mapbox;
        if (!map.getSource(source_id)){
            map.addSource(source_id,{
                type: 'vector',
                tiles: [`${window.location.origin}/api/vectorlayer/${opt.ds}/tile?z={z}&x={x}&y={y}`],
                scheme: "xyz"
            });
        }
        if (!map.getLayer(layer_id)){
            map.addLayer({
                id: layer_id,
                source: source_id,
                "source-layer": opt.ds,
                type: 'circle',
                paint: <CirclePaint>{
                    'circle-radius': opt.radius,
                    'circle-color': opt.color
                    // 'circle-stroke-width': 2,
                    // 'circle-stroke-color':
                }
            }, await this.getPrevLayer(IAhoLayers.Point));
        }
    }

    async addCardLayer(opt: any){
        if (!opt.ds) return;
        if (!opt.ds_filter) opt.ds_filter = '';
        if (!opt.filter) opt.filter = '';
        if (!opt.selectedWidth) opt.selectedWidth = opt.width;
        let source_id = this.getCardSourceId(opt.ds + opt.ds_filter);
        let layer_id =  this.agroAhoTool.getAhoLayerId(opt.layer_id); // this.getCardLayerId(opt.ds + opt.ds_filter);

        let map = this.root.map.mapbox;

        if (!map.getSource(source_id)){
            await map.addSource(source_id,{
                type: 'vector',
                tiles: [`${window.location.origin}/api/vectorlayer/${opt.ds}/tile?z={z}&x={x}&y={y}${opt.filter}&t=${Date.now()}`],
                scheme: "xyz"
            });
            // if (opt.layer_id == IAhoLayers.TrackField) {
            //     let points_data = await this.getTrackPointsData(opt);
            //     console.log('data tf:', points_data);
            // }
        }
        if (!map.getLayer(layer_id)){
            let trackOpacity = this.parentStore.editorStore.mode == AhoEditorMode.Edit &&
                (opt.layer_id == IAhoLayers.TrackField || opt.layer_id == IAhoLayers.TrackCell);
            // console.log('trackOpacity', trackOpacity, opt.layer_id);
            map.addLayer({
                id: layer_id,
                source: source_id,
                type: 'line',
                "source-layer": opt.ds,
                'layout': {
                    'line-cap': 'round'
                },
                paint: <LinePaint>{
                    'line-color': opt.color,
                    // 'line-width': [
                    //     'case',
                    //     ['==',['feature-state', 'sel'], 1],
                    //     opt.selectedWidth,
                    //     opt.width
                    // ],
                    'line-width': [
                        'case',
                        ['==', ['typeof', ['feature-state', 'field_outline_width']], 'number'],
                        ['feature-state', 'field_outline_width'],
                        opt.width
                    ],
                    'line-dasharray': opt.dotted ? [3, 2] : [1, 0],
                    "line-opacity": [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                        ['feature-state', 'opacity'],
                        ['==',['feature-state', 'opac'], 1],
                        0.2,
                        trackOpacity ? 0.2 : 1
                    ]
                }
            }, await this.getPrevLayer(opt.layer_id));
            // if (this.trackOpacity && (layer_id == IAhoLayers.TrackField || layer_id == IAhoLayers.TrackCell))
            //     await this.setTrackOpacity(true);
            if (opt.onDblClick) {

                let fill_layer_id = this.agroAhoTool.getAhoLayerId(opt.fill_layer_id); // this.getCardLayerId(opt.ds + '_fill');
                map.addLayer({
                    id: fill_layer_id,
                    source: source_id,
                    "source-layer": opt.ds,
                    type: 'fill',
                    paint: {
                        "fill-opacity": 0
                    }
                }, await this.getPrevLayer(opt.fill_layer_id));

                if (!this.isDblClickInit) {
                    let estore = this.parentStore.editorStore;
                    map.on('dblclick', fill_layer_id, (e: any)=>{
                        opt.onDblClick(e, fill_layer_id);
                    });
                    map.on('mouseenter', fill_layer_id, () => {
                        if (estore.isEditorActive()) map.getCanvas().classList.add('AgroAhoCursorPointer');
                    });
                    map.on('mouseleave', fill_layer_id, () => {
                        map.getCanvas().classList.remove('AgroAhoCursorPointer');
                    });
                    this.isDblClickInit = true;
                }

            }
        }
    }

    deleteCardLayers(card: Card){

        if (!card) return;
        this.deleteCardLayer(card.field_info.ds_code, IAhoLayers.Field);            // FieldOutlines
        this.deleteCardLayer(card.cell_info.ds_code, IAhoLayers.Cell);              // Cells
        this.deleteCardLayer(card.cell_info.ds_code, IAhoLayers.CellFill,
            {type: 'dblclick', f: this.onCellDblClick});                       // Cells fill
        this.deleteCardLabelLayer(card.cell_info.ds_code);                          // Cell numbers
        this.deleteCardLayer(card.track_info.ds_code, IAhoLayers.TrackCell);        // Track cell
        this.deleteCardLayer(card.track_info.ds_code, IAhoLayers.TrackField);       // Track field
        this.deleteIndicatorLabelLayer(this.lastIndicatorKey);   // Indicator label
        this.deleteIndicatorLayer(this.lastIndicatorKey);        // Indicator
    }

    deleteCardLayer(ds: string, layer_id_: IAhoLayers, event?: any){
        if (!ds) return;
        let map = this.root.map.mapbox;
        let layer_id = this.agroAhoTool.getAhoLayerId(layer_id_); // this.getCardLayerId(ds);
        // console.log('rem events1', event?.type, ds, map.getLayer(layer_id));
        if (map.getLayer(layer_id)){
            if (event) {
                map.off(event.type, ds, event.f);
                this.isDblClickInit = false;
                // console.log('rem events', event.type, ds, event.f);
            }
            // console.log('rem layer', layer_id);
            map.removeLayer(layer_id);
        }
    }

    deleteCardSource(ds: string){
        if (!ds) return;
        let map = this.root.map.mapbox;
        let source_id = this.getCardSourceId(ds);
        if (map.getSource(source_id))
            map.removeSource(source_id);
    }

    deleteCardLabelLayer(ds: string){
        if (!ds) return;
        let map = this.root.map.mapbox;
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.CellLbl); //this.getCellLabelLayerId(ds)
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
    }

    // async setCardLabelOpacity(opacity: boolean) {
    //     let map = this.root.map.mapbox;
    //     let lyr_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.CellLbl);
    //     if (!map.getLayer(lyr_id)) return;
    //     // map.setPaintProperty(lyr_id, 'text-opacity', opacity ? 0.2 : 1);
    //     map.setLayoutProperty(lyr_id, 'visibility', opacity ? 'none' : 'visible');
    // }

    // getIndicatorLayerId(key: IndicatorKey): string{
    //     // return `${key.ds}_${key.indc_code}_aho_ind`;
    //     return 'class_agroAho_ind_lyr_aho';
    // }
    // getIndicatorLineLayerId(key: IndicatorKey): string{
    //     // return `${key.ds}_${key.indc_code}_aho_ind_line`;
    //     return 'class_agroAho_ind_line_lyr_aho';
    // }
    getCellSourceId(key: IndicatorKey): string{
        return `${key.ds}_${key.indc_code}_aho_cell_src`;
    }
    // getIndicatorLabelLayerId(key: IndicatorKey): string{
    //     // return `${key.ds}_${key.indc_code}_aho_lbl`;
    //     return 'class_agroAho_ind_lbl_lyr_aho';
    // }
    deleteIndicatorLayer(key: IndicatorKey){
        // console.log('delete indc layer', key ? this.getIndicatorLayerId(key) : null,
        //     key ? !!this.root.map.mapbox.getLayer(this.getIndicatorLayerId(key)) : null);
        if (!key) return;
        let map = this.root.map.mapbox;
        // console.log('before delete indc layer', this.getIndicatorLayerId(key), !!map.getLayer(this.getIndicatorLayerId(key)));
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.Ind);
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
        // if (map.getLayer(this.getIndicatorLayerId(key))){
        //     map.removeLayer(this.getIndicatorLayerId(key));
        //     // console.log('after delete indc layer', this.getIndicatorLayerId(key), !!map.getLayer(this.getIndicatorLayerId(key)));
        // }
        // console.log('before delete indc layer', this.getIndicatorLineLayerId(key), !!map.getLayer(this.getIndicatorLineLayerId(key)));
        layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.IndLine);
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
        // if (map.getLayer(this.getIndicatorLineLayerId(key))){
        //     map.removeLayer(this.getIndicatorLineLayerId(key));
        //     // console.log('after delete indc line layer', this.getIndicatorLineLayerId(key), !!map.getLayer(this.getIndicatorLineLayerId(key)));
        // }
        // if (map.getLayer(this.getIndicatorLabelLayerId(key))){
        //     map.removeLayer(this.getIndicatorLabelLayerId(key));
        // }
    }

    deleteIndicatorLabelLayer(key: IndicatorKey){
        if (!key) return;
        let map = this.root.map.mapbox;
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.IndLbl)
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
        // if (map.getLayer(this.getIndicatorLabelLayerId(key))){
        //     map.removeLayer(this.getIndicatorLabelLayerId(key));
        // }
    }

    getIndcSouceId(){
        return this.getCellSourceId(this.indicatorKey);
    }

    async getIndcIds(field_id: number){
        let res: number[] = [];
        if (!this.indicatorKey?.ds || !field_id) return res;
        let data = await this.tourStore.getDataJSON(this.indicatorKey.ds, IAhoLayers.Cell);
        if (data?.features) data.features.forEach((f: any)=>{
            if (f.properties.field_id == field_id) res.push(f.id);
        });
        return res;
    }

    async addIndicatorLayer(key: IndicatorKey){
        let cstore = this.parentStore.cardStore;
        // console.log('add indc layer', key ? this.getIndicatorLayerId(key) : null);
        if (!key) return;
        // console.log('before get geojson');
        // console.log('before get geojson addIndicatorLayer', key);
        // let data;
        // let TEST_TOUR_PROJ = 'proj276'; // ASA-2
        // if (this.parentStore.projStore.projName == TEST_TOUR_PROJ) data = await this.tourStore.getDataJSON(key.ds)
        //     else data = await this.getCellCardGeoJSONs(key.ds);
        // if (this.isNewProj())
        let data = await this.tourStore.getDataJSON(key.ds, IAhoLayers.Cell);
            // else data = await this.getCellCardGeoJSONs(key.ds);
        // let data = await this.tourStore.getDataJSON(key.ds)
        // console.log('after get geojson', AhoUtils.cp(data));
        if (!data) return;
        // console.log('data indc', fnum, data);
        let map = this.root.map.mapbox;
        // console.log('add indc layer');
        this.deleteIndicatorLayer(this.lastIndicatorKey);
        // this.lastIndicatorKey = key;
        if (!map.getSource(this.getCellSourceId(key))){
            // console.log('DATA source 1:', JSONdata, key.ds);
            if (typeof data != 'object' || !data.type) return;
            await map.addSource(this.getCellSourceId(key),{
                type: 'geojson',
                data: data
            });
        }
        // await this.updIndcsOpacity_();
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.Ind);
        if (!map.getLayer(layer_id) && this.parentStore.cardStore.isLegendPalette() && !cstore.hideIndc) {
        // if (!map.getLayer(this.getIndicatorLayerId(key)) && this.parentStore.cardStore.isLegendPalette()) {
            // console.log('add fill indc, prev layer:', this.getPrevLayer(this.getIndicatorLayerId(key)));
            let prev_layer = await this.getPrevLayer(IAhoLayers.Ind);
            await map.addLayer({
                id: layer_id,
                // id: this.getIndicatorLayerId(key),
                source: this.getCellSourceId(key),
                type: 'fill',
                paint: this.getIndcCellStyle(key)
            // }, this.getPrevLayer(this.getIndicatorLayerId(key)));
            }, prev_layer);
            // }, await this.getPrevLayer(IAhoLayers.Ind));
            // upd layer (no rendered features bug)
            // AhoUtils.wait(()=>{
            //     let opt: any = {layers: [layer_id]};
            //     if (map.getLayer(layer_id) && data?.features?.length && !map.queryRenderedFeatures(opt).length) {
            //         // console.log('upd feats '+layer_id);
            //         let cur = map.getLayoutProperty(layer_id, 'visibility');
            //         map.setLayoutProperty(layer_id, 'visibility', null);
            //         map.setLayoutProperty(layer_id, 'visibility', cur);
            //     } else return true;
            // }, /*'upd feat '+layer_id*/ null, 10, 100);
        }
        layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.IndLine);
        if (!map.getLayer(layer_id)){
        // if (!map.getLayer(this.getIndicatorLineLayerId(key))){
            // console.log('add line indc, prev layer:', this.getPrevLayer(this.getIndicatorLineLayerId(key)));
            map.addLayer({
                // id: this.getIndicatorLineLayerId(key),
                id: layer_id,
                source: this.getCellSourceId(key),
                type: 'line',
                // "source-layer": key.ds,
                paint: <LinePaint>{
                    'line-color': '#000000',
                    'line-width': 1,
                    "line-opacity": [
                        'case',
                        ['==',['feature-state', 'opac'], 1],
                        this.unselectedIndcOpacity,
                        1
                    ]
                }
            // }, this.getPrevLayer(this.getIndicatorLineLayerId(key)));
            }, await this.getPrevLayer(IAhoLayers.IndLine));
        }
    }

    async updIndcsOpacity_(){
        let cstore = this.parentStore.cardStore;
        // console.log('cstore.fieldsItems 1');
        if (!cstore.fieldsItems) return;
        // console.log('cstore.fieldsItems 2', cstore.fieldsItems.length,
        //     cstore.fieldsItems.reduce((sum, cur)=> sum + (cur.checked ? 1 :0), 0));
        let checked = !cstore.fieldsItems.every(i=>!i.checked);
        for (let i=0; i<cstore.fieldsItems.length; i++){
            let f = cstore.fieldsItems[i];
            await cstore.updIndcsOpacity(f.field_id, checked && !f.checked,checked && f.checked);
        }
    }

    async addIndicatorLabelLayer(key: IndicatorKey){
        if (!key) return;
        // console.log('add indc label layer');
        let map = this.root.map.mapbox;


        let data = await this.tourStore.getDataJSON(key.ds, IAhoLayers.Cell);
        if (!data) return;
        if (!map.getSource(this.getCellSourceId(key))){
            if (typeof data != 'object' || !data.type) return;
            map.addSource(this.getCellSourceId(key),{
                type: 'geojson',
                data: data
            });
        }

        // if (!map.getSource(this.getCellSourceId(key))) return;
        this.deleteIndicatorLabelLayer(this.lastIndicatorKey);
        let code = key.indc_code.toLowerCase();
        let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.IndLbl);
        if (!map.getLayer(layer_id)) {
        // if (!map.getLayer(this.getIndicatorLabelLayerId(key))) {
            // console.log('add label indc, prev layer:', this.getPrevLayer(this.getIndicatorLabelLayerId(key)));
            map.addLayer({
                id: layer_id,
                // id: this.getIndicatorLabelLayerId(key),
                source: this.getCellSourceId(key),
                type: 'symbol',
                layout:<SymbolLayout>{
                    'text-field': ['get', code],
                    'text-font': [
                        'Open Sans Semibold'
                    ],
                    'text-anchor': 'center',
                    "text-allow-overlap": true,
                    "text-size": 14
                },
                paint: <SymbolPaint>{
                    "text-color": 'black',
                    "text-halo-color": "white",
                    "text-halo-width": 1,
                    "text-opacity": [
                        'case',
                        ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                        ['feature-state', 'opacity'],
                        ['==',['feature-state', 'opac'], 1],
                        this.unselectedIndcOpacity,
                        1
                    ]
                }
            // }, this.getPrevLayer(this.getIndicatorLabelLayerId(key)));
            }, await this.getPrevLayer(IAhoLayers.IndLbl));
        }
    }

    getIndcCellStyle(key: IndicatorKey){
        let indcs = this.parentStore.projStore.indicators.filter(item=>item.lower_code === key.indc_code.toLowerCase());
        let info = indcs[0]?.visibility?.scales?.agrosoft;
        if (key.format == AhoIndicatorFormat.Agroplem)
            info = indcs[0]?.visibility?.scales?.agroplem;
        if (key.format == AhoIndicatorFormat.FosAgro)
            info = indcs[0]?.visibility?.scales?.fosagro;
        // let noAgrosoft = !indcs[0]?.visibility?.scales?.agrosoft?.length;
        // let info = indcs[0]?.info?.visibility;
        // let info = noAgrosoft ? indcs[0].visibility.scales.agroplem : indcs[0].visibility.scales.agrosoft;
        // console.log('### info', AhoUtils.cp(info), key);
        if (!info) return {};
        let code = key.indc_code.toLowerCase();
        let arr:any[] = ['case'];
        for (let i=0; i<info.length; i++){
            let el = info[i];
            if (i === 0) {
                arr.push(['all',
                    ['!=', ['typeof', ['get', code]], 'number'],
                    ['==', ['to-boolean', ['feature-state', 'hover']], false],
                ]);
                arr.push('transparent');
                arr.push(['all',
                    ['!=', ['typeof', ['get', code]], 'number'],
                    ['==', ['feature-state', 'hover'], true]
                ]);
                arr.push('rgba(255,255,255,0.3)');
                arr.push(['<=', ['get', code], el.max]);
                arr.push(el.color);
            } else if (i === info.length - 1) {
                arr.push(['>', ['get', code], el.min]);
                arr.push(el.color);
            } else {
                arr.push(['all', ['>', ['get', code], el.min], ['<=',['get', code], el.max]]);
                arr.push(el.color);
            }
        }
        arr.push('transparent');
        let style: object = {
            // 'fill-outline-color': '#000000',
            'fill-color': arr,
            'fill-opacity': [
                'case',
                ['==', ['typeof', ['feature-state', 'opacity']], 'number'],
                ['feature-state', 'opacity'],
                ['!=',['feature-state', 'opac'], 1],
                1,
                0
            ]
        }
        // console.log('style:', JSON.stringify(style, null, 2));
        return style;
    }

    // mapInit(key: any) {
    //     let map = this.root.map.mapbox;
    //     let source_id = this.getCellSourceId(key);
    //     if (!map.getSource(source_id)) return;
    //     let layer_id = this.agroAhoTool.getAhoLayerId(IAhoLayers.Ind) //this.getIndicatorLayerId(key);
    //     map.on('mousemove', layer_id, (e: any) => {
    //         if (e.features && e.features.length > 0) {
    //             if (this.hoveredFeatureId !== null) {
    //                 map.setFeatureState(
    //                     { source: source_id, id: this.hoveredFeatureId },
    //                     { hover: false }
    //                 );
    //             }
    //             map.getCanvas().style.cursor = 'pointer';
    //             this.hoveredFeatureId = +e.features[0].id;
    //             map.setFeatureState(
    //                 { source: source_id, id: this.hoveredFeatureId },
    //                 { hover: true }
    //             );
    //         }
    //         e.preventDefault();
    //         e.stopPropagation();
    //         return false;
    //     });
    //     map.on('mouseleave', layer_id, (e: any) => {
    //         if (this.hoveredFeatureId !== null) {
    //             map.setFeatureState(
    //                 { source: source_id, id: this.hoveredFeatureId },
    //                 { hover: false }
    //             );
    //             map.getCanvas().style.cursor = "";
    //         }
    //         this.hoveredFeatureId = null;
    //
    //         e.preventDefault();
    //         e.stopPropagation();
    //         return false;
    //     });
    //     map.on('click', layer_id, (e: any) => {
    //         console.log('click', layer_id, e);
    //         e.preventDefault();
    //         e.stopPropagation();
    //         return false;
    //     });
    //     this.layerEventsInit[layer_id] = true;
    // }

    getCardSourceId(ds: string): string{
        return  ds + '_src_aho';
    }
    // getCardFilterLayerId(filter: string): string{
    //     return `class_agroAho_${filter}_lyr_aho`;
    // }
    // getCardLayerId(ds: string): string{
    //     // return  ds + '_lyr_aho';
    //     return 'class_agroAho_card_lyr_aho';
    // }
    // getCellLabelLayerId(ds: string): string{
    //     // return  ds + '_lbl_aho';
    //     return 'class_agroAho_cell_lbl_aho';
    // }
    // getFieldLabelLayerId(ds: string): string{
    //     // return  ds + '_fld_lbl_aho';
    //     return 'class_agroAho_fld_lbl_aho';
    // }
    resetCellCache(){
        this.resetCache([IAhoLayers.Cell, IAhoLayers.TrackCell]);
    }
    resetCache(types: IAhoLayers[]){
        let newCache: any = {};
        Object.keys(this._cache_geojsons).forEach(key=>{
            let val = this._cache_geojsons[key];
            if (types.indexOf(val.type) < 0)
                newCache[key] = val;
        });
        this._cache_geojsons = newCache;
    }
    async reloadTracks(){
        let ds = this.parentStore.cardStore.card.track_info.ds_code;
        this.resetCache([IAhoLayers.TrackField, IAhoLayers.TrackCell, IAhoLayers.TrackFieldPoint]);
        let cfg = this.getCardLayersState();
        if (cfg.tracks) {
            cfg.tracks = false;
            await this.updCardLayers({cfg_: cfg});
            await this.deleteCardSource(ds + '_fld');
            await this.deleteCardSource(ds);
            let map = this.root.map.mapbox;
            let source_id = this.trackFieldPointStore.getTFPSourceId(ds);
            if (map.getSource(source_id)) map.removeSource(source_id);
            // console.log('TFP src', ds_point);
            // await this.deleteCardSource(ds_point);
            cfg.tracks = true;
            await this.updCardLayers({cfg_: cfg});
        }
    }
    // IParamsCellGeoJSON = {ds: string, upd?: boolean, no_geom?: boolean, filter_?: string, type: IAhoLayers}
    async getCellCardGeoJSONs(p: IParamsCellGeoJSON){
        let params: any = [];
        if (p.no_geom) params.push('geom=0');
        if (p.track_type) params.push(`filter={"track_type":"${p.track_type}"}`);
        // if (p.upd) params.push(`ts=${Date.now()}`);
        if (params.length) params = `?` + params.join('&');
        let url = `${window.location.origin}/api/vectorlayer/${p.ds}/geojson${params}`;
        if (this._cache_geojsons[url] && !p.upd) return p.copy ?
            JSON.parse(JSON.stringify(this._cache_geojsons[url].data)) : this._cache_geojsons[url].data;
        // if (this._cache_geojsons[url] && !p.upd) return this._cache_geojsons[url].data;
        // console.log('geojson001', url, p.type, !!this._cache_geojsons[url]);
        let res = await fetchJsonGet(url).catch(()=>{/*console.error('error fetch', url)*/});
        if (res) {
            this._cache_geojsons[url] = {data: this.updateFeatureId(res), type: p.type};
            return this._cache_geojsons[url].data;
        }
    }

    async getFieldsInfo(ds: string, type?: IAhoLayers){
        let geojson: any = await this.getCellCardGeoJSONs({ds: ds, upd: true, type: type || IAhoLayers.Field});
        // console.log('getFieldsInfo', AhoUtils.cp(geojson));
        let feats = geojson?.features;
        if (feats) return (feats as any).map((f: any)=>f.properties)
            else return [];
    }

    async getFieldInfo(ds: string, field_id: number){
        let fieldsInfo = await this.getFieldsInfo(ds);
        if (fieldsInfo)
            return (fieldsInfo as any).filter((f: any)=>f.field_id == field_id);
    }

    async getFieldBbox(field_id: number){
        let geom = await this.getFieldGeom(field_id, true);
        return bbox(geom) as BBox2d;
    }

    async getCellBbox(cell_id: number){
        let geom = await this.getCellGeom(cell_id);
        return bbox(geom) as BBox2d;
    }

    updateFeatureId(data: any){
        if (data && data.features?.length && typeof data.features[0].id != 'number') {
            for (let i=0; i<data.features.length; i++){
                data.features[i].id = i;
            }
        }
        return data;
    }
    clearExtents(){
        this.extents = {};
    }
    async cardZoomClick(card: Card){
        let cs_store = this.parentStore.cardsStore;
        let mstore = this.parentStore.mapStore;
        let key = `${card.proj_name || ''}_${card.map_id}`;
        if (!this.extents[key]) {
            // let url, ext;
            // if (mstore.isNewProj()) {
            //     let card = cs_store.getCard(card.map_id);
                if (!card?.extent) {
                    console.error('wrong card', key);
                    return;
                }
                let ext: any = card.extent;
            // } else {
            //     url = `/api/projects/${this.parentStore.projStore.projName || this.root.agro.projectName}/asa_map/${map_id}/extent`;
            //     ext = await fetchJsonGet(url);
            // }
            if (!ext || !ext.lon_min) {
                console.log('error extent', AhoUtils.cp(ext));
                return;
            }
            if (!ext.max_x) {
                ext.max_x = ext.lon_max;
                ext.min_x = ext.lon_min;
                ext.max_y = ext.lat_max;
                ext.min_y = ext.lat_min;
            }
            this.extents[key] = ext;
        }
        let ex: Extent = this.extents[key];
        let bbox = GeometryUtils.getBbox(ex.min_x, ex.max_x, ex.min_y, ex.max_y);
        // console.log('bbox:', key, bbox);
        this.root.map.zoomToBBox(bbox);
    }
    async centerClick(asa_id: number){
        let map = this.root.map.mapbox;
        let bbox: BBox2d;
        await fetchJsonGet(`/api/projects/${this.parentStore.projStore.projName}/asa/${asa_id}/vector_extent`).then(ext=>{
            bbox = GeometryUtils.getBbox(ext.min_lon, ext.max_lon, ext.min_lat, ext.max_lat);
        });
        map.fitBounds(bbox, {
            padding: 20,
            linear: true,
            duration: 0
        });
    }

}


// _map.mapbox.getStyle().layers
// _map.mapbox.queryRenderedFeatures({layers: ['layer_id']})
