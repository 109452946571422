import {CustomStore} from "../CustomStore";
import {IDictonaryType} from "../../helper/utils/Utils";
import {action, computed, observable} from "mobx";
import {LoadStatus} from "../../helper/structs/LoadStatus";
import {v4} from 'uuid';
import {
    getIndexDataGroups,
    getIndexDataTypes,
    IndexByPointerStore,
    IndexDataGroup,
    IndexDataType
} from "./IndexByPointerStore";
import {save} from "../PermalinkDecor";
import {IndexByPointYearStore, IYearPeriod} from "./IndexByPointYearStore";
import {MBUtils} from "../../helper/utils/MBUtils";
import {IndexByPointGraphPointStore} from "./IndexByPointGraphPointStore";
import {IndexByPointerSourceSimpleTempNOAAStore} from "./sources/IndexByPointerSourceSimpleTempNOAAStore";
import {IndexByPointerSourceSimpleTempNOAAClimateStore} from "./sources/IndexByPointerSourceSimpleTempNOAAClimateStore";
import {IndexByPointerSourceSimpleTempEra5Store} from "./sources/IndexByPointerSourceSimpleTempEra5Store";
import {IndexByPointerSourceSimpleHumidityIbmStore} from "./sources/IndexByPointerSourceSimpleHumidityIbmStore";
import {IndexByPointerSourceSimpleHumidityEra5Store} from "./sources/IndexByPointerSourceSimpleHumidityEra5Store";
import {IndexByPointerSourceSimplePrecipIbmStore} from "./sources/IndexByPointerSourceSimplePrecipIbmStore";
import {IndexByPointerSourceSimplePrecipEra5Store} from "./sources/IndexByPointerSourceSimplePrecipEra5Store";
import {IndexByPointerSourceSimplePrecipNoaaClimateStore} from "./sources/IndexByPointerSourceSimplePrecipNoaaClimateStore";
import {IndexByPointerSourceSimplePrecipNoaaStore} from "./sources/IndexByPointerSourceSimplePrecipNoaaStore";
import {IndexByPointerSourceSimpleSolarRadStore} from "./sources/IndexByPointerSourceSimpleSolarRadStore";
import {IndexByPointerSourceVegIndexStore} from "./sources/IndexByPointerSourceVegIndexStore";
import {IndexByPointerSourceVegIndex500Store} from "./sources/IndexByPointerSourceVegIndex500Store";
import {IndexByPointerSourceCustomStore} from "./sources/IndexByPointerSourceCustomStore";
import {IndexByPointerSourceEmpty} from "./sources/IndexByPointerSourceEmpty";
import {IndexByPointerSourceSimpleTempEra5ClimateStore} from "./sources/IndexByPointerSourceSimpleTempEra5ClimateStore";
import {IndexByPointerSourceSimplePrecipEra5ClimateStore} from "./sources/IndexByPointerSourceSimplePrecipEra5ClimateStore";
import {IndexByPointerSourceS1IwStore} from "./sources/IndexByPointerSourceS1IwStore";
import {IndexByPointerSourceS1EwStore} from "./sources/IndexByPointerSourceS1EwStore";
import {IndexByPointerSourceSimpleModisTerraNir} from "./sources/IndexByPointerSourceSimpleModisTerraNir";
import {IndexByPointerSourceSimpleModisTerraRed} from "./sources/IndexByPointerSourceSimpleModisTerraRed";
import {IndexByPointerSourceSimpleModisNdvi} from "./sources/IndexByPointerSourceSimpleModisNdvi";
import {IndexByPointerSourceSimpleTempAvgStore} from "./sources/IndexByPointerSourceSimpleTempAvgStore";
import {IndexByPointerSourceSimplePrecipAvg5Store} from "./sources/IndexByPointerSourceSimplePrecipAvg5Store";
import {
    IndexByPointerSourceSimplePrecipAvgClimateStore
} from "./sources/IndexByPointerSourceSimplePrecipAvgClimateStore";
import {IndexByPointerSourceSimpleTempAvgClimateStore} from "./sources/IndexByPointerSourceSimpleTempAvgClimateStore";
import { IndexByPointerSourceSimplePrecipMeteoStationStore } from "./sources/IndexByPointerSourceSimplePrecipMeteoStationStore";
import { isNullishCoalesce } from "typescript";
import { IndexByPointerSourceSimpleTempMeteoStationStore } from "./sources/IndexByPointerSourceSimpleTempMeteoStationStore";
import { IndexByPointerSourceSimpleSmapSoilMoistureStore } from "./sources/IndexByPointerSourceSimpleSmapSoilMoistureStore";
import { IndexByPointerSourceSimpleSoilMoistureMeteoStationStore } from "./sources/IndexByPointerSourceSimpleSoilMoistureMeteoStationStore";

export interface IIndexValue{
    date: string,
    sceneID: string,
    error?: {message: string, show: boolean},
    stat: IDictonaryType<IStatIndex>
}
export interface IGraphDayValue {
    dayOfYear: number,
    absoluteDate?: number,//Date as unixtimestamp
    value: number
}
export interface IGraphDataInfo{
    data: IGraphData[];
    hasMinMax: boolean;
}
export interface IGraphData{
    dayOfYear: number,
    absoluteDate?: number,//Date as unixtimestamp
    sceneID: string,
    value: number,
    min?: number,
    max?: number,
}

export interface IStatIndex{
    tile_x: number,
    tile_y: number,
    pxl_x: number,
    pxl_y: number,
    avg: number
}

export interface IRequestProdStat{
    scene_source?: string,
    product?: string,
    lon?: number,
    lat?: number,
    rm?: number,
    mask?: string,
    filter?: any,
    mode?: string,
    dskey?: string,
    column?: string,
    objkey?: any,
    precalc?: number,
}
export interface IResponseNdvi500Modis{
    cloud: number,
    date: string,
    sceneID: string,
    error: {"message": string},
    type: "MODIS",
    stat:{
        ndvi: {
            avg: number,
            chart: number
        }
    }
}


export class IndexByPointGraphStore extends CustomStore{
    constructor(parent: IndexByPointerStore, opts?: any) {
        super(parent);
        this.id = v4();
        this.idPoint = opts.idPoint;
        this.productCode = opts.productCode;
        this.color = opts.color;
        this.dataType = opts.dataType;
        this.radius = opts.radius;
        this.satellites = opts.satellites;
        this.yearPeriod = opts.yearPeriod;
    }
    class(): string {return "IndexByPointGraphStore";}
    @save
    id: string;//не меняется

    @save @observable
    idPoint: string;
    @save @observable
    dataType: IndexDataType;
    @computed
    get dataGroup(): IndexDataGroup{
        return getIndexDataTypes()[this.dataType];
    }
    @save @observable
    visible: boolean = true;
    @save @observable
    color: number = 0;
    @save @observable
    radius: number = 0;
    @save @observable
    productCode: string = "";
    @save @observable
    satellites: string[];
    @save @observable
    yearPeriod: IYearPeriod = {year: 0, isClimate: false}
    get year(): number{ return this.yearPeriod.year;}
    get isClimate(): boolean{ return this.yearPeriod.isClimate;}
    comparePeriod(yp: IYearPeriod): boolean{ return IndexByPointYearStore.comparePeriod(this.yearPeriod, yp); }

    hashForCompare(): string{
        return this.idPoint + "_" + this.yearPeriod.isClimate + "_" + this.yearPeriod.year + this.dataType + this.radius + this.productCode + JSON.stringify(this.satellites);
    }

    temperatureNoaa: IndexByPointerSourceSimpleTempNOAAStore = new IndexByPointerSourceSimpleTempNOAAStore(this);
    temperatureNoaaClimate: IndexByPointerSourceSimpleTempNOAAClimateStore = new IndexByPointerSourceSimpleTempNOAAClimateStore(this);
    temperatureEra5Climate: IndexByPointerSourceSimpleTempEra5ClimateStore = new IndexByPointerSourceSimpleTempEra5ClimateStore(this);
    temperatureAvgClimate: IndexByPointerSourceSimpleTempAvgClimateStore = new IndexByPointerSourceSimpleTempAvgClimateStore(this);
    temperatureEra5: IndexByPointerSourceSimpleTempEra5Store = new IndexByPointerSourceSimpleTempEra5Store(this);
    temperatureAvg: IndexByPointerSourceSimpleTempAvgStore = new IndexByPointerSourceSimpleTempAvgStore(this);
    temperatureMeteoStation: IndexByPointerSourceSimpleTempMeteoStationStore = new IndexByPointerSourceSimpleTempMeteoStationStore(this);;

    humidityEra5: IndexByPointerSourceSimpleHumidityEra5Store = new IndexByPointerSourceSimpleHumidityEra5Store(this);
    humidityIbm: IndexByPointerSourceSimpleHumidityIbmStore = new IndexByPointerSourceSimpleHumidityIbmStore(this);

    soilMoistureSmap: IndexByPointerSourceSimpleSmapSoilMoistureStore = new IndexByPointerSourceSimpleSmapSoilMoistureStore(this);
    soilMoistureMeteoStation: IndexByPointerSourceSimpleSoilMoistureMeteoStationStore = new IndexByPointerSourceSimpleSoilMoistureMeteoStationStore(this);

    modisTerraNir: IndexByPointerSourceSimpleModisTerraNir = new IndexByPointerSourceSimpleModisTerraNir(this);
    modisTerraRed: IndexByPointerSourceSimpleModisTerraRed = new IndexByPointerSourceSimpleModisTerraRed(this);
    modisNdvi: IndexByPointerSourceSimpleModisNdvi = new IndexByPointerSourceSimpleModisNdvi(this);

    radarS1Iw: IndexByPointerSourceS1IwStore = new IndexByPointerSourceS1IwStore(this);
    radarS1Ew: IndexByPointerSourceS1EwStore = new IndexByPointerSourceS1EwStore(this);

    precipIbm: IndexByPointerSourceSimplePrecipIbmStore = new IndexByPointerSourceSimplePrecipIbmStore(this);
    precipEra5: IndexByPointerSourceSimplePrecipEra5Store = new IndexByPointerSourceSimplePrecipEra5Store(this);
    precipAvg: IndexByPointerSourceSimplePrecipAvg5Store = new IndexByPointerSourceSimplePrecipAvg5Store(this);
    precipNoaaClimate: IndexByPointerSourceSimplePrecipNoaaClimateStore = new IndexByPointerSourceSimplePrecipNoaaClimateStore(this);
    precipEra5Climate: IndexByPointerSourceSimplePrecipEra5ClimateStore = new IndexByPointerSourceSimplePrecipEra5ClimateStore(this);
    precipAvgClimate: IndexByPointerSourceSimplePrecipAvgClimateStore = new IndexByPointerSourceSimplePrecipAvgClimateStore(this);
    precipMeteoStation: IndexByPointerSourceSimplePrecipMeteoStationStore = new IndexByPointerSourceSimplePrecipMeteoStationStore(this);;

    precipNoaa: IndexByPointerSourceSimplePrecipNoaaStore = new IndexByPointerSourceSimplePrecipNoaaStore(this);
    solarRad: IndexByPointerSourceSimpleSolarRadStore = new IndexByPointerSourceSimpleSolarRadStore(this);
    vegIndex: IndexByPointerSourceVegIndexStore = new IndexByPointerSourceVegIndexStore(this);
    vegIndex500: IndexByPointerSourceVegIndex500Store = new IndexByPointerSourceVegIndex500Store(this);
    emptySource: IndexByPointerSourceEmpty = new IndexByPointerSourceEmpty(this);

    @action
    resetStatus(){
        this.vegIndex.reset();
        this.vegIndex500.reset();
        this.temperatureNoaa.reset();
        this.temperatureNoaaClimate.reset();
        this.precipEra5Climate.reset();
        this.precipAvgClimate.reset();
        this.temperatureEra5Climate.reset();
        this.temperatureAvgClimate.reset();
        this.temperatureMeteoStation.reset();

        this.temperatureEra5.reset();
        this.temperatureAvg.reset();
        this.precipNoaaClimate.reset();
        this.precipNoaa.reset();
        this.precipEra5.reset();
        this.precipAvg.reset();
        this.precipIbm.reset();
        this.precipMeteoStation.reset();
        this.solarRad.reset();
        this.humidityEra5.reset();
        this.humidityIbm.reset();
        this.radarS1Iw.reset();
        this.radarS1Ew.reset();

        this.soilMoistureSmap.reset();
        this.soilMoistureMeteoStation.reset();

        this.modisNdvi.reset();
        this.modisTerraNir.reset();
        this.modisTerraRed.reset();
    }

    @computed
    get source(): IndexByPointerSourceCustomStore{
        if (this.dataType == IndexDataType.ndvi250) return this.vegIndex500;
        if (this.dataType == IndexDataType.vegIndex) return  this.vegIndex;

        if (this.dataGroup == IndexDataGroup.temperature && this.isClimate){
            if (this.dataType == IndexDataType.temperatureNOAA) return this.temperatureNoaaClimate;
            if (this.dataType == IndexDataType.temperatureEra5) return this.temperatureEra5Climate;
            if (this.dataType == IndexDataType.temperatureAvg) return this.temperatureAvgClimate;
            return this.emptySource;
        }else {
            if (this.dataType == IndexDataType.temperatureAvg) return this.temperatureAvg;
            if (this.dataType == IndexDataType.temperatureNOAA) return this.temperatureNoaa;
            if (this.dataType == IndexDataType.temperatureEra5) return this.temperatureEra5;
            if (this.dataType == IndexDataType.temperatureMeteoStation) return this.temperatureMeteoStation;
        }
        if (this.dataGroup == IndexDataGroup.precipitation && this.isClimate){
            if (this.dataType == IndexDataType.precipitationNOAA) return this.precipNoaaClimate;
            if (this.dataType == IndexDataType.precipitationEra5) return this.precipEra5Climate;
            if (this.dataType == IndexDataType.precipitationAvg) return this.precipAvgClimate;
            return this.emptySource;
        }else {
            if (this.dataType == IndexDataType.precipitationNOAA) return this.precipNoaa;
            if (this.dataType == IndexDataType.precipitationEra5) return this.precipEra5;
            if (this.dataType == IndexDataType.precipitationAvg) return this.precipAvg;
            if (this.dataType == IndexDataType.precipitationIbm) return this.precipIbm;
            if (this.dataType == IndexDataType.precipitationMeteoStation) return this.precipMeteoStation;
        }

        if (this.dataType == IndexDataType.modis_ndvi) return this.modisNdvi;
        if (this.dataType == IndexDataType.modis_TERRA_NIR) return this.modisTerraNir;
        if (this.dataType == IndexDataType.modis_TERRA_RED) return this.modisTerraRed;

        if (this.dataType == IndexDataType.solarRad){
            return this.solarRad;
        }
        if (this.dataType == IndexDataType.humidityIbm){
            return this.humidityIbm;
        }
        if (this.dataType == IndexDataType.humidityEra5){
            return this.humidityEra5;
        }
        if (this.dataType == IndexDataType.soilMoistureSmap) {
            return this.soilMoistureSmap;
        }
        if (this.dataType == IndexDataType.soilMoistureMeteoStation) {
            return this.soilMoistureMeteoStation;
        }

        if (this.dataType == IndexDataType.radar_s1_ew){
            return this.radarS1Ew;
        }
        if (this.dataType == IndexDataType.radar_s1_iw){
            return this.radarS1Iw;
        }
        return this.emptySource;
    }

    @computed
    get status():LoadStatus{
        return this.source.status;
    }


    get parent(): IndexByPointerStore { return this._parent as IndexByPointerStore;}

    @computed
    get colorByDataType(): number{
        let AllTypes = getIndexDataTypes();
        let group = AllTypes[this.dataType];
        let AllGroups = getIndexDataGroups();
        let types = AllGroups[group];
        let index = types.findIndex(a => a == this.dataType);
        return MBUtils.getNewColorIndex(index);
    }

    @computed
    get graphData(): IGraphDataInfo {
        return this.source.getGraphData();
    }

    @computed
    public get gPoint(): IndexByPointGraphPointStore{
        return this.parent.points.find(p => p.id == this.idPoint);
    }

    accumGraphDayValue(values: IGraphDayValue[], zero: number):IGraphDayValue[]{
        let accum = 0;
        let res: IGraphDayValue[] = []
        values.forEach(a => {
            if (a.value >= zero) accum += a.value;
            res.push({dayOfYear: a.dayOfYear, value: accum});
        });
        return res;
    }

    getDateInterval(): {dateBegin: Date, dateEnd: Date}{
        let monthBegin = this.root.indexByPointer.monthBegin;
        let monthEnd = this.root.indexByPointer.monthEnd;
        let d1 = new Date(this.year,monthBegin,1);
        let d2 = new Date(this.year,monthEnd+1,0);
        if (d1 > d2){
            d2 = new Date(this.year+1,monthEnd+1,0);
        }
        return {dateBegin: d1, dateEnd: d2};
    }
    getDateIntervalPlus(): {dateBegin: Date, dateEnd: Date}{
        let monthBegin = this.root.indexByPointer.monthBegin;
        let monthEnd = this.root.indexByPointer.monthEnd;
        let d1 = new Date(this.year,monthBegin,1);
        let d2 = new Date(this.year,monthEnd+1,1);
        if (d1 >= d2){
            d2 = new Date(this.year+1,monthEnd+1,1);
        }
        return {dateBegin: d1, dateEnd: d2};
    }
}

