import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import './ShowRoom2MapPopupComp.scss';
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {ShowroomLayerType} from "../../../../store/dataShowroom2/ShowRoom2LayerStore";
import {action} from "mobx";

@observer
export class ShowRoom2MapPopupComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onClose(){
        let store = this.props.store;
        let mp = store.dataShowRoomStore.mapPopup;
        mp.show = false;
    }

    render() {
        let store = this.props.store;
        let mp = store.dataShowRoomStore.mapPopup;
        let arr: any = null;
        function addProp(propKey: string, propName: string, isDate = false, units: string = ""): any{
            let value = mp.data[propKey];
            if (isDate){
                let d = new Date(value);
                value = d.toLocaleDateString();
            }
            return (<tr key={propName}>
                <td className="ShowRoom2MapPopupComp-table-key">{propName}</td>
                <td className="ShowRoom2MapPopupComp-table-value">{value}{units}</td>
            </tr>);
        }

        if (mp.status == LoadStatus.ready){
            let rows: any[] = [];
            let rows2: any[] = [];
            let headerText = "";
            if (mp.showRoom2LayerName == ShowroomLayerType.ccePoints){
                headerText = "CCE point";
                rows.push(addProp("season", "Season"));
                rows.push(addProp("crop", "Crop"));
                rows.push(addProp("created_at", "Date", true));
                rows.push(addProp("weight_kg", "Weight", null, " kg/ha"));

                rows2.push(addProp("state", "State"));
                rows2.push(addProp("district", "District"));
                rows2.push(addProp("gp", "GP"));
            }
            if (mp.showRoom2LayerName == ShowroomLayerType.classPoints) {
                headerText = "Classification point";
                rows.push(addProp("season", "Season"));
                rows.push(addProp("crop", "Crop"));
                rows.push(addProp("created_at", "Date", true));

                rows2.push(addProp("state", "State"));
                rows2.push(addProp("district", "District"));
                rows2.push(addProp("gp", "GP"));
            }
            if (mp.showRoom2LayerName == ShowroomLayerType.yieldAssessmentPoints){
                headerText = "Yield assessment point";
                rows.push(addProp("season", "Season"));
                rows.push(addProp("crop", "Crop"));
                rows.push(addProp("yield", "Yield", null, " kg/ha"));
                rows.push(addProp("gp_yield", "GP_yield", null, " kg/ha"));

                rows2.push(addProp("state", "State"));
                rows2.push(addProp("district", "District"));
                rows2.push(addProp("gp", "GP"));
            }
            if (mp.showRoom2LayerName == ShowroomLayerType.districts){
                headerText = "District";

                rows2.push(addProp("state", "State"));
                rows2.push(addProp("district", "District"));
            }
            if (mp.showRoom2LayerName == ShowroomLayerType.yieldMapPerGP){
                headerText = "Yield map per GP";
                rows.push(addProp("crop", "Crop"));
                rows.push(addProp("yield", "Avg. yield", null, " kg/ha"));
                rows.push(addProp("points", "Points"));

                rows2.push(addProp("state", "State"));
                rows2.push(addProp("district", "District"));
                rows2.push(addProp("gp", "GP"));
            }
            arr = <div>
                <div className="ShowRoom2MapPopupComp-table-header">
                    <div className="ShowRoom2MapPopupComp-table-headerText">{headerText}</div>
                    <div className="ShowRoom2MapPopupComp-table-headerClose">
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className="pointer" onClick={this.onClose}>
                            <path d="M10.1062 0.89375C9.83125 0.61875 9.41875 0.61875 9.14375 0.89375L5.5 4.5375L1.85625 0.89375C1.58125 0.61875 1.16875 0.61875 0.89375 0.89375C0.61875 1.16875 0.61875 1.58125 0.89375 1.85625L4.5375 5.5L0.89375 9.14375C0.61875 9.41875 0.61875 9.83125 0.89375 10.1062C1.03125 10.2437 1.16875 10.3125 1.375 10.3125C1.58125 10.3125 1.71875 10.2437 1.85625 10.1062L5.5 6.4625L9.14375 10.1062C9.28125 10.2437 9.4875 10.3125 9.625 10.3125C9.7625 10.3125 9.96875 10.2437 10.1062 10.1062C10.3812 9.83125 10.3812 9.41875 10.1062 9.14375L6.4625 5.5L10.1062 1.85625C10.3812 1.58125 10.3812 1.16875 10.1062 0.89375Z" fill="#C5C5C5"/>
                        </svg>
                    </div>
                </div>
                {rows.length > 0 && <table className="ShowRoom2MapPopupComp-table">
                    <tbody>
                        {rows}
                    </tbody>
                </table>}
                {rows.length > 0 && <div className="ShowRoom2MapPopupComp-spearateline" />}
                {rows2.length > 0 && <table className="ShowRoom2MapPopupComp-table">
                    <tbody>
                    {rows2}
                    </tbody>
                </table>}
            </div>;

        }
        //store.map.mapbox.project({lon: store.dataShowRoomStore})
        return <div className="ShowRoom2MapPopupComp-popup" style={{top: (mp.y - 11)+"px", left: (mp.x-1)+"px"}}>
            {mp.status == LoadStatus.loading && <div className="ShowRoom2MapPopupComp-popup-content ShowRoom2MapPopupComp-popup-loading">{store.trans["Loading..."]}</div>}
            {mp.status == LoadStatus.ready && <div className="ShowRoom2MapPopupComp-popup-content">{arr}</div>}
            <div className="ShowRoom2MapPopupComp-popup-pin"></div>
        </div>;
    }
}
