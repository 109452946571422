import { observable } from "mobx";
import { CustomStore } from "./CustomStore";
import { save } from "./PermalinkDecor";
import { IWindOverlayParams } from "./config/ConfigStore";
import { Utils } from "../helper/utils/Utils";

export class GfsWindOverlayStore extends CustomStore {
    class(): string {return "GfsWindOverlayStore";}

    @save @observable
    active: boolean = false;

    @save @observable
    date: Date = new Date();

    @save @observable
    currentDate: string = Utils.getDateStr(this.date);

    @observable
    isEditing: boolean = false;

    _params : IWindOverlayParams = null;
    get params() : IWindOverlayParams {
        if (this._params) return this._params;
        let p: IWindOverlayParams = null;
        this.root.config.parameterizedOverlays.forEach(ov => {
            if (ov.id == "gfs_wind") p = ov.params as IWindOverlayParams;
        });
        this._params = p || {dataTemplateUrl: ""};
        return this._params;
    }
}