import {CustomStore, ObservableCustomStore} from "../../CustomStore";
import {action, autorun, observable} from "mobx";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {LogEventInterfaces, LogEventServer} from "./LogEventInterfaces";
import {fetchJsonPost} from "../../../helper/utils/FetchUtils";
import {Utils} from "../../../helper/utils/Utils";
import {TranslateUtils} from "../../../helper/lang/TranslateUtils";
import {ra} from "../../../helper/utils/mobxUtils";
import {LogViewFilterStore} from "./LogViewFilterStore";
import {IReactionDisposer} from "mobx/lib/internal";

export enum LogOperation{
    login = 1,
    logout = 2,
    create_request=3,
    download_report = 4,
    change_geometry_oks=5,
    create_object=6,
    edit_object=7,
    delete_object=8,

}
export class LogViewStore extends ObservableCustomStore{
    @observable
    showLogView: boolean = false;

    @observable
    logLoadStatus: LoadStatus = null;

    @observable
    logEvents: LogEventInterfaces[] = [];
    @observable
    allEventsLoaded: boolean = false;

    limitEvents: number = 100;

    filters: LogViewFilterStore = new LogViewFilterStore(this);


    subscription(): IReactionDisposer[] {
        return [
            autorun(()=> {
                if (this.showLogView) this.doLoadLogEvents();
            })
        ];
    }

    @action
    async openLogView(){
        try{
            this.showLogView = true;
        }catch (e) {
            this.root.addError(e);
        }
    }
    @action
    closeLogView(){
        this.showLogView = false;
    }

    getTitleAction(action: LogOperation): string{
        let t = this.root.trans;
        switch (action){
            case LogOperation.login:
                return t["Log in"];
            case LogOperation.logout:
                return t["Log out"];
            case LogOperation.change_geometry_oks:
                return t["Changing the contours of the ACS"];
            case LogOperation.create_request:
                return t["Create the request"];
            case LogOperation.download_report:
                return t["Download the report"];
            case LogOperation.create_object:
                return t["Create object"];
            case LogOperation.edit_object:
                return t["Edit object"];
            case LogOperation.delete_object:
                return t["Delete object"];
            default:
                return "unknown";
        }
    }

    getLogFilter(): any{
        let p: any = {};//filter={"user_id":1,"oper_id":2,"end_tm":{"$>=":"2023-08-08 11:08:30","$<=":"2023-08-08 11:08:35"}}
        if (this.filters.filter_operation != null){
            p["oper_id"] = this.filters.filter_operation;
        }
        if (this.filters.filter_userId != null){
            p["user_id"] = this.filters.filter_userId;
        }
        if (this.filters.interval.isValidBegin() || this.filters.interval.isValidEnd()) {
            p["end_tm"] = {};
            if (this.filters.interval.isValidBegin()) {
                p["end_tm"]["$>="] = Utils.dateToISOString(this.filters.interval.begin);
            }
            if (this.filters.interval.isValidEnd()) {
                let date = new Date(this.filters.interval.end);
                date.setDate(date.getDate() + 1);
                p["end_tm"]["$<"] = Utils.dateToISOString(date);
            }
        }
        return p;
    }

    doLoadLogEvents(){
        let p = this.getLogFilter();

        ra(()=>{
            this.logLoadStatus = LoadStatus.loading;
            this.logEvents = [];
            this.allEventsLoaded = false;
        });
        setImmediate(async()=>{
            try {
                let r: {actions: LogEventServer[]} = await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/action`, {filter: p, limit: this.limitEvents});
                ra(() => {
                    this.logLoadStatus = LoadStatus.ready;
                    this.logEvents = r.actions.map(a => this.logEventServerToEvent(a));
                });
            }catch (e) {
                ra(()=> {
                    this.root.addError(e);
                    this.logLoadStatus = LoadStatus.ready;
                    this.logEvents = [];
                });
            }
        });
    }
    nextLogEvents(){
        //last_action_id=20 и limit=10
        let p = this.getLogFilter();
        let last_action_id = this.logEvents[this.logEvents.length - 1].action_id;
        p["action_id"] = {"$<":last_action_id};
        ra(()=>{
            this.logLoadStatus = LoadStatus.loading;
        });
        setImmediate(async()=>{
            try {
                let r: {actions: LogEventServer[]} = await fetchJsonPost(`/api/projects/${this.root.agro.projectName}/action`, {filter: p, limit: this.limitEvents});
                ra(() => {
                    this.logLoadStatus = LoadStatus.ready;
                    this.logEvents.push(...r.actions.map(a => this.logEventServerToEvent(a)));
                    if (r.actions.length == 0) this.allEventsLoaded = true;
                });
            }catch (e) {
                ra(()=> {
                    this.root.addError(e);
                    this.logLoadStatus = LoadStatus.ready;
                });
            }
        });
    }
    logEventServerToEvent(p: LogEventServer): LogEventInterfaces{
        let le: LogEventInterfaces = {
            user_id: p.user_id,
            email: p.email,
            date: Utils.toDate(p.end_tm),
            action_id: p.action_id,
            fullname: TranslateUtils.getUserFullName(p.surname , p.given_name),
            operation: p.oper_id
        };
        return le;
    }

    exportLog(){
        let p = this.getLogFilter();
        let url = `/api/projects/${this.root.agro.projectName}/action/download`;
        Utils.downloadFile(url);
    }
}