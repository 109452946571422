// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoMergeIsoXmlSelAllComp-main {
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 0 16px;
}

.AgroAhoMergeIsoXmlSelAllComp-main:hover .AgroAhoMergeIsoXmlSelAllComp-name {
  color: #4DB6BC;
}

.AgroAhoMergeIsoXmlSelAllComp-name {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #C5C5C5;
}

.AgroAhoMergeIsoXmlSelAllComp-area {
  flex-shrink: 0;
  text-align: right;
  color: #6A7886;
}

.AgroAhoMergeIsoXmlSelAllComp-disabledButton {
  opacity: 0.5;
  cursor: default;
}

.AgroAhoMergeIsoXmlSelAllComp-checkbox {
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/cards/AgroAhoMergeIsoXmlSelAllComp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;AACF;;AACA;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;AAEF;;AAAA;EACE,cAAA;EACA,iBAAA;EACA,cAAA;AAGF;;AADA;EACE,YAAA;EACA,eAAA;AAIF;;AAFA;EACE,kBAAA;AAKF","sourcesContent":[".AgroAhoMergeIsoXmlSelAllComp-main {\r\n  display: flex;\r\n  align-items: center;\r\n  height: 35px;\r\n  cursor: pointer;\r\n  font-family: Open Sans, sans-serif;\r\n  font-size: 12px;\r\n  font-weight: 400;\r\n  padding: 0 16px;\r\n  //background: #22282F;\r\n}\r\n.AgroAhoMergeIsoXmlSelAllComp-main:hover .AgroAhoMergeIsoXmlSelAllComp-name {\r\n  color: #4DB6BC;\r\n}\r\n.AgroAhoMergeIsoXmlSelAllComp-name {\r\n  flex-grow: 1;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  color: #C5C5C5;\r\n}\r\n.AgroAhoMergeIsoXmlSelAllComp-area {\r\n  flex-shrink: 0;\r\n  text-align: right;\r\n  color: #6A7886;\r\n}\r\n.AgroAhoMergeIsoXmlSelAllComp-disabledButton {\r\n  opacity: .5;\r\n  cursor: default;\r\n}\r\n.AgroAhoMergeIsoXmlSelAllComp-checkbox {\r\n  margin-right: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
