import {observable} from "mobx";
import React from "react";
import {IDapFieldsMaskOverlayParams} from "./config/ConfigStore";
import {CustomStore} from "./CustomStore";
import { save } from "./PermalinkDecor";

export interface DapFieldMaskProject {
    id_project: number,
    name_project: string
}

export interface DapFieldMaskSubproject {
    id_subproject: number;
    name_subproject: string;
    crop: string;
}

export interface DapFieldMaskModel {
    model: string;
    uuid: string;
    type_classification: string;
    bbox: number[];
}

export class DapFieldsMaskOverlayStore extends CustomStore {
    class(): string {return "DapFieldsMaskOverlayStore";}

    @save @observable
    active: boolean = false;

    @save @observable
    projects: DapFieldMaskProject[] = [];

    @save @observable
    currentProject: DapFieldMaskProject = null;

    @save @observable
    subprojects: DapFieldMaskSubproject[] = [];

    @save @observable
    currentSubproject: DapFieldMaskSubproject = null;

    @save @observable
    models: DapFieldMaskModel[] = [];

    @save @observable
    currentModel: DapFieldMaskModel = null;

    _params : IDapFieldsMaskOverlayParams = null;
    get params() : IDapFieldsMaskOverlayParams {
        if (this._params) return this._params;
        let p: IDapFieldsMaskOverlayParams = null;
        this.root.config.parameterizedOverlays.forEach(ov => {
            if (ov.id == "dap_fields_mask") p = ov.params as IDapFieldsMaskOverlayParams;
        });
        this._params = p || { tilesTemplate: null, metadataUrl: null };
        return this._params;
    }
}