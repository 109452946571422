// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgets-back-m {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}

.widgets-back-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}

.widgets-main {
  display: flex;
  flex-direction: row;
  background: #2B3138;
  border-bottom: 1px solid #3E4751;
  border-radius: 6px;
  margin: 5px auto;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
}

.widgets-text {
  color: #6A7886;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Map/widgets/widgets.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;EACA,oBAAA;AACF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;EACA,oBAAA;AAEF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gCAAA;EACA,kBAAA;EACA,gBAAA;EACA,2BAAA;EACA,mBAAA;EACA,iBAAA;AAGF;;AADA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAIF","sourcesContent":[".widgets-back-m{\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  background: transparent;\n  display: flex;\n  justify-content: center;\n  z-index: 1;\n  pointer-events: none;\n}\n.widgets-back-bottom{\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background: transparent;\n  display: flex;\n  justify-content: center;\n  z-index: 1;\n  pointer-events: none;\n}\n.widgets-main{\n  display: flex;\n  flex-direction: row;\n  background: #2B3138;\n  border-bottom: 1px solid #3E4751;\n  border-radius: 6px;\n  margin: 5px auto;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 5px 10px;\n}\n.widgets-text{\n  color: #6A7886;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
