import {ISuperStore} from "../../pluginApi/store/SuperStore";
import {EmergencyStore} from "../emergency/EmergencyStore";
import {classRegPlugin} from "../../pluginApi";
import {ra} from "../../app/helper/utils/mobxUtils";
import {ClassFactory, ClassValueUpdateType} from "../../app/store/ClassFactory";
import {EmergencyEvents} from "../emergency/EmergencyEvents";
import {EmergencyTool} from "../emergency/EmergencyTool";
import {MeteoStore} from "./MeteoStore";
import {MeteoEvents} from "./MeteoEvents";
import {MeteoTool} from "./MeteoTool";
import {MeteoClickTool} from "./MeteoClickTool";

export interface IMeteoStoreProps {
    store: IMeteoSuperStore,
    className?: string
}
export interface IMeteoSuperStore extends ISuperStore{
    meteoStore: MeteoStore;
}

classRegPlugin({pluginName: "Meteo",
        initStore: init,
    }
);

export const LeftPanelModeMeteo = 'meteo';

function init(store: IMeteoSuperStore){
    ra(()=>{
        store.meteoStore = new MeteoStore(store as any);
        ClassFactory.registerProperty(store, "meteoStore", ClassValueUpdateType.update);
        store.meteoStore.active = true; //store.config.meteoShow;
        //store.emerStore.calculateTranslate();
        store.events.onCloseAllLeftPanels.addListen(()=>{
            ra(()=>{
                //store.emerStore.showFilters = false;
            });
        });
        store.meteoStore.calculateTranslate();
        store.events.onChangeLang.addListen(()=>{
            store.meteoStore.calculateTranslate();
        });
        let ev = new MeteoEvents(store);
        store.events.onLeftPanelChildren.addListen((st, children)=>{
            ev.onLeftPanelChildrens(children);
        });

        addTools(store);//сразу добавляем
        store.events.onInitTools.addListen(()=>{//если потребуется пересоздать
            addTools(store);
        });

        store.events.onMapChildren.addListen((st, children)=>{
            ev.onMapChildren(children);
        });
        store.events.onLeftSidebarLeftChildren.addListen((st, children)=>{
            ev.onLeftSidebarLeftChildren(children);
        });
    });
}

function addTools(store: IMeteoSuperStore){
    let t = new MeteoTool(store as any, "MeteoTool");
    store.map.superTools.pushToolAfter(t, ["CreatePointIndexGraphTool", "ShowRoom2PopupTool", "NdviValueTool"]);
    let t1 = new MeteoClickTool(store as any, "MeteoClick");
    store.map.superTools.pushToolAfter(t1, ["FieldLayersTool"]);//надо ДО "NdviValueTool"
}