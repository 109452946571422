import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCardComp.scss'
import {action, observable} from "mobx";
import {AgroAhoIndicatorComp} from "./../AgroAhoIndicatorComp";
import {AhoModalType} from "../../agroAhoStore";
import {AgroAhoRectCheckboxSvg} from "./../AgroAhoRectCheckboxSvg";
import classNames from "classnames";
import {AgroAhoWindowComp} from "./../AgroAhoWindowComp";
import {AgroAhoRoundCheckboxSvg} from "./../AgroAhoRoundCheckboxSvg";
import {AhoPerm, Indicator} from "../../agroAhoProjStore";
import {AhoCardShowType, AhoIndicatorFormat, SoilAnalysisType} from "../../agroAhoCardStore";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import Tooltip from 'rc-tooltip';
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";
import {AgroAhoDownloadSvg} from "../icons/AgroAhoDownloadSvg";
import {AgroAhoEditSvg} from "../icons/AgroAhoEditSvg";
import {AgroAhoFieldSvg} from "../icons/AgroAhoFieldSvg";
import {AgroAhoCellListSvg} from "../icons/AgroAhoCellListSvg";
import {AgroAhoTourSvg} from "../icons/AgroAhoTourSvg";
import {AgroAhoArrowBackSvg} from "../icons/AgroAhoArrowBackSvg";
import {AgroAhoRecNormComp} from "./AgroAhoRecNormComp";
import {fetchJsonGet} from "../../../../app/helper/utils/FetchUtils";
import {AgroAhoSwitcherSvg} from "../icons/AgroAhoSwitcherSvg";
import {AhoEditorMode} from "../../agroAhoEditorStore";
import {LoadingAniSvg} from "../../../../app/components/icons/LoadingAniSvg";
import {AhoEditRouteMode} from "../../map/agroAhoEditRoute";
import {AgroAhoPencilSvg} from "../icons/AgroAhoPencilSvg";
// import {NEW_FORMAT_PROJS, TEST_TOUR_PROJ} from "../../map/agroAhoMapStore";

// export interface IAgroAhoCardCompProps {
//     store: IAgroAhoSuperStore;
//     name: string;
//     id: number;
// }

@observer
export class AgroAhoCardComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @observable
    trackEditMenuVisible: boolean = false;

    onTrackEditMenuBlur(){
        this.trackEditMenuVisible = false;
    }

    @action
    onClickBack(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.indicatorBackClick();
    }

    @action
    onClickZoom(e: any){
        let store_ = this.props.store.agroAhoStore;
        store_.mapStore.cardZoomClick(store_.cardStore.card);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    @action
    onFieldLayerClick(){
        let store_ = this.props.store.agroAhoStore;
        let mstore = store_.mapStore;
        let noField = !Object.keys(store_.cardStore.card.field_info).length;
        if (!mstore.layersShow || noField) return;
        mstore.layerFieldOutlinesShow = !mstore.layerFieldOutlinesShow;
        // mstore.updCardLayers();
    }

    @action
    onCellsLayerClick(){
        let store_ = this.props.store.agroAhoStore;
        let mstore = store_.mapStore;
        let noCell = !Object.keys(store_.cardStore.card.cell_info).length;
        if (!mstore.layersShow) return;
        mstore.layerCellsShow = !mstore.layerCellsShow;
        // mstore.updCardLayers();
    }

    @action
    onTracksLayerClick(){
        let store_ = this.props.store.agroAhoStore;
        let mstore = store_.mapStore;
        let noTrack = !Object.keys(store_.cardStore.card.track_info).length ||
            !store_.cardStore.card.track_info.sum_len_m;
        if (!mstore.layersShow || noTrack) return;
        let mode = store_.editorStore.mode;
        if (mode == AhoEditorMode.Select || mode == AhoEditorMode.Edit)
            store_.editorStore.doDisableEditor();
        // if (!mstore.layersShow || store_.cardStore.isCellsCanGenerate) return;
        mstore.layerTracksShow = !mstore.layerTracksShow;
        // mstore.updCardLayers();
    }
    @action
    onClickUpload(){
        let store_ = this.props.store.agroAhoStore;
        let disabled = store_.projStore.perm === AhoPerm.View;
        // if (!disabled) store_.toggleModal(true, AhoModalType.UploadIndsSelect);
        if (!disabled)
            // if (store_.projStore.projName == TEST_TOUR_PROJ)
            if (store_.mapStore.isNewProj()) store_.uploadTableStore.doUploadButtonClick()
                else store_.toggleModal(true, AhoModalType.FileLoad);
    }

    @action
    onClickLayersShow(){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;
        let mstore = store_.mapStore;
        if (mstore.layersShow) return;
        if (mstore.updatingCardLayersInProgress) return;
        tstore.toursShow = false;
        mstore.layersShow = true;
        // mstore.layersShow = !mstore.layersShow;
        // if (mstore.layersShow && cstore.indicatorsShow) cstore.indicatorsShow = false;
        if (mstore.layersShow && cstore.soilAnalysisShow)
            cstore.soilAnalysisShow = false;
        if (!mstore.layersShow) this.clearFilterWindows();
        // mstore.updCardLayers();
        // store.setUrlCode(store.card);
    }

    clearFilterWindows(){
        let store_ = this.props.store.agroAhoStore;
        let cs = store_.cardStore;
        cs.fieldsShow = false;
        cs.cellsShow = false;
        cs.doFilterChanged('');
        cs.doCellFilterChanged('');
    }

    @action
    onClickSoilAnalysisShow(){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;

        let mstore = store_.mapStore;
        // if (cstore.indicatorsShow) return;
        if (cstore.soilAnalysisShow) return;
        // cstore.indicatorsShow = !cstore.indicatorsShow;
        if (cstore.soilAnalysisType == SoilAnalysisType.Spinner) cstore.soilAnalysisType = SoilAnalysisType.Indicators;
        cstore.soilAnalysisShow = true;
        if (cstore.soilAnalysisShow && mstore.layersShow) {
            mstore.layersShow = false;
            this.clearFilterWindows();
        }
        // console.log('click sa', cstore.soilAnalysisType);
        // mstore.updCardLayers();
        // store.setUrlCode(store.card);
    }

    onClickLayersDownload(e: any){
        let store_ = this.props.store.agroAhoStore;
        let cs = store_.cardStore;
        // if (cs.getFieldSelected()?.length) cs.layersExportVisFields = true;
        cs.layersExportVisFields = !!cs.getFieldSelected()?.length;
        store_.toggleModal(true, AhoModalType.ExportLayers);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onPointsLayerClick(){
        let store_ = this.props.store.agroAhoStore;
        let mstore = store_.mapStore;
        let noPoint = !store_.cardStore.card.point_info || !Object.keys(store_.cardStore.card.point_info).length ||
            !store_.cardStore.card?.point_info?.point_cnt;
        if (!mstore.layersShow || noPoint) return;
        mstore.layerPointsShow = !mstore.layerPointsShow;
    }

    async getDefFileName(){
        let store_ = this.props.store.agroAhoStore;
        let r = [
            store_.projStore.projName,
            store_.cardStore.card.map_id
        ];
        // /api/projects/proj_name/asa2/map/map_id/tours/export/file_name
        let url = `/api/projects/${r[0]}/asa2/map/${r[1]}/tours/export/file_name`;
        let res = await fetchJsonGet(url).catch(()=>{});
        return  res?.file_name || '';
    }

    async onClickIndicatorsDownload(e: any){
        let store_ = this.props.store.agroAhoStore;
        let cs = store_.cardStore;
        if (!store_.cardStore.soilAnalysisShow) return;
        let fn = await this.getDefFileName();
        cs.exportFileName = fn;
        cs.exportRNFileName = fn;
        // store_.downloadIndicators(store_.card.map_id);
        // if (cs.getFieldSelected()?.length) cs.exportVisFields = true;
        let hasRN = store_.cardStore.cardRNStore.hasRNSelFields(true);
        cs.exportVisFields = !!cs.getFieldSelected()?.length && hasRN;
        if (cs.soilAnalysisType === SoilAnalysisType.Indicators)
            store_.toggleModal(true, AhoModalType.ExportIndicators);
        if (cs.soilAnalysisType === SoilAnalysisType.RecNorms) {
            await cs.updRecNormStat();
            if (cs.recNormStat) store_.toggleModal(true, AhoModalType.ExportRN);
        }

        // e.preventDefault();
        // e.stopPropagation();
        return false;
    }

    onClickTabInds(){
        let cstore = this.props.store.agroAhoStore.cardStore
        if (cstore.cardIndicators.length == 0) return;
        cstore.soilAnalysisType = SoilAnalysisType.Indicators;
    }

    onClickTabRecNorm(){
        let cardRNStore = this.props.store.agroAhoStore.cardStore.cardRNStore;
        if (!cardRNStore.hasRecNorm()) return;
        // if (cstore.rn_groups.length == 0) return;
        cardRNStore.doTabRecNormClick().then();
    }

    onClickTourButton(e: any){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;
        let mstore = store_.mapStore;
        if (!cstore.soilAnalysisShow || !mstore.isNewProj()) return;
        if (tstore.toursItems && tstore.toursItems.length) tstore.toursShow = !tstore.toursShow;
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onClickIndicatorsDelete(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        // store_.deleteIndicators(store_.card.map_id);
        cstore.onDeleteIndicators();
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onClickSettingShow(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.winSettingVisible = !cstore.winSettingVisible
    }

    onSettingBlur(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.winSettingVisible = false;
    }

    onShowSettingsIndicatorsClick(){
        let store_ = this.props.store.agroAhoStore;
        store_.cardStore.ahoCardShowType = AhoCardShowType.Indicators;
        // if (!store_.cardStore.indicatorsShow) return;
        // store_.mapStore.updCardLayers();
    }

    onShowSettingsCellsClick(){
        let store_ = this.props.store.agroAhoStore;
        store_.cardStore.ahoCardShowType = AhoCardShowType.Cells;
        // if (!store_.cardStore.indicatorsShow) return;
        // store_.mapStore.updCardLayers();
    }

    onShowSettingsWithoutLabelsClick(){
        let store_ = this.props.store.agroAhoStore;
        store_.cardStore.ahoCardShowType = AhoCardShowType.WithoutLabels;
    }

    onUploadCellsClick(e: any){
        this.props.store.agroAhoStore.cardStore.doUploadCells();
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onAgroSoftClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        if (cstore.formatAgroSoftDisabled) return;
        cstore.indcLegendSettingClick(AhoIndicatorFormat.AgroSoft);
    }

    onAgroplemClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        if (cstore.formatAgroplemDisabled) return;
        cstore.indcLegendSettingClick(AhoIndicatorFormat.Agroplem);
    }

    onFosAgroClick(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        if (cstore.formatFosAgroDisabled) return;
        cstore.indcLegendSettingClick(AhoIndicatorFormat.FosAgro);
    }

    onClickTrackEdit(e: any){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let mstore = store_.mapStore;
        if (mstore.updatingCardLayersInProgress) return;
        this.trackEditMenuVisible = !this.trackEditMenuVisible;
        if (this.trackEditMenuVisible) {
            cstore.cellsShow = false;
            cstore.fieldsShow = false;
        }
        // let store_ = this.props.store.agroAhoStore;
        // let mode = store_.editorStore.mode;
        // if (mode == AhoEditorMode.Select || mode == AhoEditorMode.Edit)
        //     store_.editorStore.doDisableEditor()
        // else this.props.store.agroAhoStore.editorStore.doEditModeClick();
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    disableEditors(){
        let store_ = this.props.store.agroAhoStore;
        let mode = store_.editorStore.mode;
        // if (mode == AhoEditorMode.Select || mode == AhoEditorMode.Edit)
            store_.editorStore.doDisableEditor();
        let rstore = store_.mapStore.mapEditRouteStore;
        // if (rstore.mode == AhoEditRouteMode.Select || rstore.mode == AhoEditRouteMode.Edit)
            rstore.doDisableModeClick();
    }

    onMenuEditTrackClick(){
        let estore = this.props.store.agroAhoStore.editorStore;
        let mode = estore.mode;
        this.disableEditors();
        estore.resetMeasuringStatus();
        if (mode != AhoEditorMode.Select && mode != AhoEditorMode.Edit)
            estore.doEditModeClick();
        this.trackEditMenuVisible = false;
    }
    onMenuTrackOrderClick(){
        let store_ = this.props.store.agroAhoStore;
        let rstore = store_.mapStore.mapEditRouteStore;
        let estore = store_.editorStore;
        this.disableEditors();
        estore.resetMeasuringStatus();
        if (rstore.mode != AhoEditRouteMode.Select && rstore.mode != AhoEditRouteMode.Edit)
            rstore.doSelectModeClick();
        this.trackEditMenuVisible = false;
    }

    onClickFieldList(e: any){
        this.doClickFieldList();
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    async doClickFieldList(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        if (cstore.fieldsLoading) return;
        let mstore = this.props.store.agroAhoStore.mapStore;
        let fstore = cstore.cardFieldStore;
        let noField = !Object.keys(cstore.card.field_info).length;
        if (!mstore.layersShow || noField) return;

        cstore.updSelectAll();
        if (!cstore.fieldsShow) {
            cstore.fieldsLoading = true;
            await cstore.cardFieldStore.updSeasonsHistory();
            cstore.fieldsLoading = false;
            cstore.sortDesc = false;
        }
        cstore.fieldsShow = !cstore.fieldsShow;
        if (!cstore.fieldsShow) cstore.doFilterChanged('');
        fstore.updCropNames(0);
        if (cstore.fieldsShow && cstore.cellsShow) cstore.cellsShow = false;
    }

    onClickCellList(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        let mstore = this.props.store.agroAhoStore.mapStore;
        let noCell = !Object.keys(cstore.card.cell_info).length;
        if (!mstore.layersShow || noCell) return;

        if (!cstore.cellsAreaCalculated && !cstore.cellsShow) {
            cstore.updCellsArea();
            cstore.cellsAreaCalculated = true;
        }
        cstore.cellsVisFields = !!cstore.getFieldSelected()?.length;
        cstore.cellsShow = !cstore.cellsShow;
        if (!cstore.cellsShow) cstore.doCellFilterChanged('');
        if (cstore.cellsShow && cstore.fieldsShow) cstore.fieldsShow = false;
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onFieldsClearClcik(){
        this.props.store.agroAhoStore.cardStore.doFieldsClearClick();
    }

    // hasRNGroups(){
    //     return !!this.props.store.agroAhoStore.cardStore.rn_groups.length;
    // }
    // hasIndicators(){
    //     return !!this.props.store.agroAhoStore.cardStore.cardIndicators.length;
    // }

    onFieldsNumberShowClick(){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.fieldsNumberShow = !cs.fieldsNumberShow;
    }


    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;
        let card = cstore.card;
        let mstore = store_.mapStore;
        let uploadDisabled = store_.projStore.perm === AhoPerm.View;
        let sum_len_m = cstore.card.track_info.sum_len_m;
        let noTrack = !Object.keys(cstore.card.track_info).length || !sum_len_m;
        let noCell = !Object.keys(cstore.card.cell_info).length;
        let noField = !Object.keys(cstore.card.field_info).length;
        // console.log('p info', JSON.parse(JSON.stringify(cstore.card.point_info)));
        let noPoint = !cstore.card.point_info || !Object.keys(cstore.card.point_info).length ||
            cstore.card?.point_info?.point_cnt === 0;
        // console.log('noPoint', noCell, noField, noPoint);

        let sum_len = <span/>;
        if (!noTrack) {
            if (sum_len_m < 1000) {
                sum_len = <span>{store_.numWithSpaces(sum_len_m)}&nbsp;{store.trans.m}</span>;
            } else {
                let sum_len_km = Math.round(sum_len_m / 1000);
                sum_len = <span>{store_.numWithSpaces(sum_len_km)}&nbsp;{store.trans.km}</span>;
            }
        }
        // let area: any = cstore.card.field_info.sum_area_ha && parseFloat(cstore.card.field_info.sum_area_ha.toFixed(1));
        // area = store_.numWithSpaces(area);
        // let cells = store_.numWithSpaces(cstore.card.cell_info.cell_cnt);

        let stat = {cnt: 0, cells: 0, area: 0};
        let fstat = {cnt: 0, cells: 0, area: 0};

        // console.log('cellsItems', AhoUtils.cp(cstore.cellsItems));

        if (cstore.fieldsItems) {
            cstore.fieldsItems.forEach(f=>{
                let cells_cnt = 0;
                if (cstore.cellsItems)
                    cells_cnt = cstore.cellsItems.filter(c=>c.field_id === f.field_id).length;
                stat.cnt += 1;
                stat.cells += cells_cnt;
                stat.area += f.area_ha;
                if (f.checked) {
                    fstat.cnt += 1;
                    fstat.cells += cells_cnt;
                    fstat.area += f.area_ha;
                }
            });
        }

        let cnt = fstat.cnt && stat.cnt !== fstat.cnt ? <span>{fstat.cnt}&nbsp;/&nbsp;{stat.cnt}</span> : stat.cnt;
        let area = fstat.area && stat.area !== fstat.area ?
            <span>{store_.toFloat(fstat.area)}&nbsp;/&nbsp;{store_.toFloat(stat.area)}</span> :
            <span>{store_.toFloat(stat.area)}</span>;
        let cells = fstat.cells && stat.cells !== fstat.cells ?
            <span>{store_.numWithSpaces(fstat.cells)}&nbsp;/&nbsp;{store_.numWithSpaces(stat.cells)}</span> :
            <span>{store_.numWithSpaces(stat.cells)}</span>;
        let cells_ = <span>{store_.numWithSpaces(stat.cells)}</span>;
        // total = total && parseFloat(total.toFixed(1));
        // total = store_.numWithSpaces(total);
        // console.log('fields rendered', cnt, stat.area, stat.cells);
        let points = cstore.card?.point_info?.point_cnt || '';

        let indc_ids = tstore.doGetSelTourIndicatorCodes();
        let indicators = cstore.cardIndicators.filter(c=>indc_ids.indexOf(c.lower_code)>=0);
        let hasRecNorm = cstore.cardRNStore.hasRecNorm();
        let hasIndcs = cstore.cardIndicators.length > 0;
        let isRecNorm = cstore.soilAnalysisType == SoilAnalysisType.RecNorms; // && (hasRecNorm || !hasIndcs);
        let isIndcs = cstore.soilAnalysisType == SoilAnalysisType.Indicators; // && (hasIndcs || !hasRecNorm);


        // console.log('dbg:', isRecNorm, hasRecNorm, isIndcs, hasIndcs);
        // console.log('incs:', indc_ids, indicators);

        return <React.Fragment>
            <div className="AgroAhoCardComp-header">
                <div className="AgroAhoCardComp-headerArrowCover">
                    <AgroAhoArrowBackSvg className="AgroAhoCardComp-backBtn" onClick={this.onClickBack}/>
                </div>
                <div className="AgroAhoCardComp-headerLine"/>
                <div className="AgroAhoCardComp-headerPathCover">
                    <span className="AgroAhoCardComp-proj" onClick={this.onClickBack}>{store.agro.projectTitle}</span>
                    <svg className="AgroAhoCardComp-triangle" width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 6L4 3L0 0V6Z" fill="#6B707B"/>
                    </svg>
                    <span className="AgroAhoCardComp-сardName">{cstore.card.map_name}</span>
                </div>
            </div>
            <div className="AgroAhoCardComp-statCover">
                <div>{store_.trans.Fields}:&nbsp;{cnt}&nbsp;{store.trans.pcs}</div>
                <div className="AgroAhoCardComp-statCells">{store.trans['Cells:']}&nbsp;{cells}&nbsp;{store.trans.pcs}</div>
                <div>{area}&nbsp;{store.trans.ha}</div>
            </div>
            <div className={classNames("AgroAhoCardComp-layersCover", {
                    // "AgroAhoCardComp-disabled": mstore.updatingCardLayersInProgress,
                    "AgroAhoCardComp-min": !mstore.layersShow
                })} onClick={this.onClickLayersShow}>
                <svg className={classNames("AgroAhoCardComp-layersShowButton", {"AgroAhoCardComp-layersShow": mstore.layersShow})}  width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 12C11.6113 12 14.4201 8.9 15.6238 7.1C16.1254 6.4 16.1254 5.5 15.6238 4.8C14.4201 3.1 11.6113 0 8 0C4.38871 0 1.57994 3.1 0.376176 4.9C-0.125392 5.6 -0.125392 6.5 0.376176 7.1C1.57994 8.9 4.38871 12 8 12ZM8 3C9.70533 3 11.0094 4.3 11.0094 6C11.0094 7.7 9.70533 9 8 9C6.29467 9 4.9906 7.7 4.9906 6C4.9906 4.3 6.29467 3 8 3Z"/>
                </svg>
                <span className="AgroAhoCardComp-layers">{store.trans.Overlays}</span>
                <span className="AgroAhoCardComp-selStat">
                    {!mstore.layersShow && fstat.cnt > 0 &&
                    <span>{store_.trans['Selected fields']}:&nbsp;{fstat.cnt}</span>}
                </span>
                <div className="AgroAhoCardComp-layersDownloadButton" onClick={this.onClickLayersDownload}>
                    <AgroAhoDownloadSvg/>
                </div>
                <div className="AgroAhoCardComp-zoomCover" onClick={this.onClickZoom}>
                    <AgroAhoZoomSvg/>
                </div>
            </div>
            {mstore.layersShow && <div className="AgroAhoCardComp-layersWrapper">

                {!noPoint && <div className={classNames("AgroAhoCardComp-layer AgroAhoCardComp-points",
                    {"AgroAhoCardComp-disabled": !mstore.layersShow})}
                        onClick={this.onPointsLayerClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoCardComp-layerCheckbox"
                        checked={mstore.layerPointsShow}/>
                    <span className="AgroAhoCardComp-layerCaption">{store_.trans['Selection points']}</span>
                    <span className="AgroAhoCardComp-layerStat">
                        {!!points && <React.Fragment>
                            <span>{points}</span>
                            <span>&nbsp;{store.trans.pcs}</span>
                        </React.Fragment>}
                    </span>
                </div>}

                {noPoint && <div className={classNames("AgroAhoCardComp-layer AgroAhoCardComp-tracks",
                    // {"AgroAhoCardComp-isCellsCanGenerate": store_.cardStore.isCellsCanGenerate },
                    {"AgroAhoCardComp-disabled": !mstore.layersShow || noTrack,
                     "AgroAhoCardComp-hovered": this.trackEditMenuVisible})}
                     onClick={this.onTracksLayerClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoCardComp-layerCheckbox"
                        checked={mstore.layerTracksShow}/>
                    <span className="AgroAhoCardComp-layerCaption">{store_.trans.Routes}</span>
                    <span className="AgroAhoCardComp-layerStat">
                        <span>{sum_len}</span>
                    </span>
                    <AgroAhoWindowComp store={store} className="AgroAhoCardComp-trackEditMenuCover"
                        visible={this.trackEditMenuVisible}
                        onBlur={this.onTrackEditMenuBlur} button={
                            <div className={classNames("AgroAhoCardComp-trackEditButton",{
                                "AgroAhoCardComp-trackEditButtonDisabled": mstore.updatingCardLayersInProgress
                                })}   onClick={this.onClickTrackEdit}>
                                <AgroAhoEditSvg/>
                            </div>}>
                        <div className="AgroAhoCardComp-trackEditMenuItem" onClick={this.onMenuEditTrackClick}>{
                            store_.trans['Edit selection route']}</div>
                        <div className={classNames("AgroAhoCardComp-trackEditMenuItem", {
                            "AgroAhoCardComp-trackEditMenuItemDisabled": false})} onClick={this.onMenuTrackOrderClick}>{
                            store_.trans['Set the detour order of field']}</div>
                    </AgroAhoWindowComp>






                    {/*<span className="AgroAhoCardComp-trackGenButton" onClick={this.onGenClick}>{store_.trans["Generate"]}</span>*/}
                </div>}

                <div className={classNames("AgroAhoCardComp-layer AgroAhoCardComp-cells",
                    {"AgroAhoCardComp-disabled": !mstore.layersShow || noCell,
                        "AgroAhoCardComp-hovered": cstore.cellsShow})
                } onClick={this.onCellsLayerClick}>
                    <AgroAhoRectCheckboxSvg  className="AgroAhoCardComp-layerCheckbox"
                        checked={mstore.layerCellsShow}/>
                    <span className="AgroAhoCardComp-layerCaption">{store.trans.Cells}</span>
                    <Tooltip placement="bottomLeft" mouseEnterDelay={0.5} overlay={<span>{store_.trans["Load cells from kml/geojson/shp to zip"]}</span>}
                             overlayClassName={"AgroAhoCardComp-tooltip"} align={{offset: [20, 5]}}>
                        <span className="AgroAhoCardComp-uploadCells" onClick={this.onUploadCellsClick}>
                            <svg className="AgroAhoCardComp-uploadCellsSvg" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 9C12.7348 9 12.4804 9.10536 12.2929 9.29289C12.1054 9.48043 12 9.73478 12 10V12H2V10C2 9.73478 1.89464 9.48043 1.70711 9.29289C1.51957 9.10536 1.26522 9 1 9C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12V10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9Z"/>
                                <path d="M3.70643 5.70679L5.99943 3.41379V8.99979C5.99943 9.265 6.10479 9.51936 6.29232 9.70689C6.47986 9.89443 6.73421 9.99979 6.99943 9.99979C7.26465 9.99979 7.519 9.89443 7.70654 9.70689C7.89407 9.51936 7.99943 9.265 7.99943 8.99979V3.41379L10.2924 5.70679C10.481 5.88894 10.7336 5.98974 10.9958 5.98746C11.258 5.98518 11.5088 5.88001 11.6942 5.6946C11.8797 5.5092 11.9848 5.25838 11.9871 4.99619C11.9894 4.73399 11.8886 4.48139 11.7064 4.29279L7.70643 0.292786C7.5189 0.105315 7.2646 0 6.99943 0C6.73427 0 6.47996 0.105315 6.29243 0.292786L2.29243 4.29279C2.11027 4.48139 2.00948 4.73399 2.01176 4.99619C2.01403 5.25838 2.1192 5.5092 2.30461 5.6946C2.49002 5.88001 2.74083 5.98518 3.00303 5.98746C3.26523 5.98974 3.51783 5.88894 3.70643 5.70679Z" />
                            </svg>
                        </span>
                    </Tooltip>
                    <Tooltip placement="bottomLeft" mouseEnterDelay={0.5} overlay={<span>{store_.trans['Edit cell names']}</span>}
                             overlayClassName={"AgroAhoCardComp-tooltip"} align={{offset: [20, 5]}}>
                        <div className="AgroAhoCardComp-editCells" onClick={cstore.doCellEditClick}>
                            {/*<AgroAhoPencilSvg/>*/}
                            <AgroAhoEditSvg/>
                        </div>
                    </Tooltip>
                    <div className="AgroAhoCardComp-cellListButton" onClick={this.onClickCellList}>
                        <AgroAhoCellListSvg className={classNames({"AgroAhoCardComp-cellSvgBack": cstore.cellsShow})}/>
                    </div>
                    <span className="AgroAhoCardComp-layerStat">
                        {!!cells_ && <React.Fragment>
                            <span>{cells_}</span>
                            <span>&nbsp;{store.trans.pcs}</span>
                        </React.Fragment>}
                    </span>
                </div>

                <div className={classNames("AgroAhoCardComp-layer AgroAhoCardComp-outlines",
                    {"AgroAhoCardComp-disabled": !mstore.layersShow || noField,
                        "AgroAhoCardComp-hovered": cstore.fieldsShow || cstore.fieldsLoading,
                        "AgroAhoCardComp-selected": !!fstat.cnt})} onClick={this.onFieldLayerClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoCardComp-layerCheckbox"
                                                   checked={mstore.layerFieldOutlinesShow}/>
                    <span className="AgroAhoCardComp-layerCaption">{store.trans.Fields}</span>
                    <span className="AgroAhoCardComp-fieldSelCover">
                        <span className={classNames({"AgroAhoCardComp-hidden": !fstat.cnt})}>
                            <span>{store_.trans.Selected}:&nbsp;</span>
                            <span>{fstat.cnt}</span>
                        </span>
                    </span>
                    <span className="AgroAhoCardComp-layerStat">
                        {/*<span className="AgroAhoCardComp-fieldCnt">{cstore.card.field_info.field_cnt}</span>*/}
                        <span className="AgroAhoCardComp-fieldCnt">{stat.cnt}&nbsp;{store.trans.pcs}</span>
                        {!!area && <React.Fragment>
                            <span>&nbsp;/&nbsp;{store_.toFloat(stat.area)}</span>
                            <span>&nbsp;{store.trans.ha}</span>
                        </React.Fragment>}
                    </span>
                    <div className="AgroAhoCardComp-fieldListButton" onClick={this.onClickFieldList}>
                        {/*<AgroAhoFilterSvg/>*/}
                        {!cstore.fieldsLoading && <AgroAhoFieldSvg className={classNames({
                            "AgroAhoCardComp-fieldSvgBack": cstore.fieldsShow})}/>}
                        {cstore.fieldsLoading && <LoadingAniSvg />}
                    </div>
                </div>

                {/*<div className={classNames("AgroAhoCardComp-fieldsCover style-4",*/}
                {/*    {"AgroAhoCardComp-hidden": !cstore.fieldsShow || !cstore.fieldsItems})}>*/}
                {/*    {cstore.fieldsItems &&*/}
                {/*    cstore.fieldsItems.map((f: any)=><AgroAhoCardFieldItemComp key={f.field_id}*/}
                {/*        store={store} field={f}/>)}*/}
                {/*</div>*/}
                {/*<div className={classNames("AgroAhoCardComp-fieldsFooter",*/}
                {/*    {"AgroAhoCardComp-hidden": !cstore.fieldsShow || !cstore.fieldsItems})}>*/}
                {/*    <span>*/}
                {/*        {!!selected && <React.Fragment>*/}
                {/*            <span>{store.trans.Total}:&nbsp;{selected}</span>*/}
                {/*            <span className="AgroAhoCardComp-dataSplitter">/</span>*/}
                {/*            <span>{total}</span>*/}
                {/*            <span>&nbsp;{store.trans.ha}</span>*/}
                {/*        </React.Fragment>}*/}
                {/*    </span>*/}
                {/*    <span className="AgroAhoCardComp-fieldsClear"*/}
                {/*          onClick={this.onFieldsClearClcik}>{store_.trans.Clear}</span>*/}
                {/*</div>*/}
            </div>}

            <div className={classNames("AgroAhoCardComp-indicatorsCover",
                {"AgroAhoCardComp-hidden": cstore.cardIndicators.length == 0 && !cstore.cardRNStore.hasRecNorm(),
                 "AgroAhoCardComp-min": !cstore.soilAnalysisShow})} onClick={this.onClickSoilAnalysisShow}>
                <svg className={classNames("AgroAhoCardComp-indicatorsShowButton", {"AgroAhoCardComp-indicatorsShow": cstore.soilAnalysisShow})}  width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 12C11.6113 12 14.4201 8.9 15.6238 7.1C16.1254 6.4 16.1254 5.5 15.6238 4.8C14.4201 3.1 11.6113 0 8 0C4.38871 0 1.57994 3.1 0.376176 4.9C-0.125392 5.6 -0.125392 6.5 0.376176 7.1C1.57994 8.9 4.38871 12 8 12ZM8 3C9.70533 3 11.0094 4.3 11.0094 6C11.0094 7.7 9.70533 9 8 9C6.29467 9 4.9906 7.7 4.9906 6C4.9906 4.3 6.29467 3 8 3Z"/>
                </svg>
                <span className="AgroAhoCardComp-indicatorsCaption">{store.trans['Agri. Soil Analysis Data']}</span>

                <AgroAhoWindowComp store={store} className="AgroAhoCardComp-indicatorsSettingButtonCover"
                    visible={cstore.winSettingVisible} onClick={this.onClickSettingShow} onBlur={this.onSettingBlur} button={
                    <svg className={classNames( "AgroAhoCardComp-indicatorsSettingButtonSvg", {"AgroAhoCardComp-settingHovered": cstore.winSettingVisible})}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.6375 4.55L12.6 2.7125L11.375 1.4875L9.5375 2.45C9.275 2.275 8.925 2.1875 8.575 2.1L7.875 0H6.125L5.425 2.0125C5.1625 2.1 4.8125 2.1875 4.55 2.3625L2.7125 1.4L1.4 2.7125L2.3625 4.55C2.1875 4.8125 2.1 5.1625 2.0125 5.425L0 6.125V7.875L2.0125 8.575C2.1 8.925 2.275 9.1875 2.3625 9.5375L1.4 11.375L2.625 12.6L4.4625 11.6375C4.725 11.8125 5.075 11.9 5.425 11.9875L6.125 14H7.875L8.575 11.9875C8.925 11.9 9.1875 11.725 9.5375 11.6375L11.375 12.6L12.6 11.375L11.6375 9.5375C11.8125 9.275 11.9 8.925 11.9875 8.575L14 7.875V6.125L11.9875 5.425C11.9 5.1625 11.8125 4.8125 11.6375 4.55ZM7 9.625C5.5125 9.625 4.375 8.4875 4.375 7C4.375 5.5125 5.5125 4.375 7 4.375C8.4875 4.375 9.625 5.5125 9.625 7C9.625 8.4875 8.4875 9.625 7 9.625Z"/>
                    </svg>}>
                    <div className="AgroAhoCardComp-indicatorsSettingHeader">{store.trans["Visualization settings"]}</div>
                    <div className="AgroAhoCardComp-fieldsNumberShowCover" onClick={this.onFieldsNumberShowClick}>
                        <AgroAhoSwitcherSvg className="AgroAhoCardComp-fieldsNumberCheckbox"
                            checked={cstore.fieldsNumberShow}/>
                        <span className="AgroAhoCardComp-fieldsNumberCaption">
                            {store_.trans['Show field names']}</span>
                    </div>
                    <div className="AgroAhoCardComp-indicatorsSettingTitle">{store.trans["Cell labels"]}</div>
                    <div className="AgroAhoCardComp-checkboxCover" onClick={this.onShowSettingsIndicatorsClick}>
                        <AgroAhoRoundCheckboxSvg checked={cstore.ahoCardShowType === AhoCardShowType.Indicators}/>
                        <span className="AgroAhoCardComp-formatCaption">{store_.trans["Meaning of indicators/rec. norm"]}</span>
                    </div>
                    <div className="AgroAhoCardComp-checkboxCover" onClick={this.onShowSettingsCellsClick}>
                        <AgroAhoRoundCheckboxSvg checked={cstore.ahoCardShowType === AhoCardShowType.Cells}/>
                        <span className="AgroAhoCardComp-formatCaption">{store_.trans["Cell names"]}</span>
                    </div>
                    <div className="AgroAhoCardComp-checkboxCover" onClick={this.onShowSettingsWithoutLabelsClick}>
                        <AgroAhoRoundCheckboxSvg checked={cstore.ahoCardShowType === AhoCardShowType.WithoutLabels}/>
                        <span className="AgroAhoCardComp-formatCaption">{store_.trans["Without labels"]}</span>
                    </div>
                    <div className="AgroAhoCardComp-indicatorsSettingTitle">{store.trans["Gradations and palettes"]}</div>
                    <div onClick={this.onAgroSoftClick} className={classNames("AgroAhoCardComp-checkboxCover",
                        {"AgroAhoCardComp-checkboxCoverDisabled": cstore.formatAgroSoftDisabled})}>
                        <AgroAhoRoundCheckboxSvg checked={cstore.indcFormat == AhoIndicatorFormat.AgroSoft}/>
                        <span className="AgroAhoCardComp-formatCaption">{store_.trans["Agro-Soft"]}</span>
                    </div>
                    <div onClick={this.onAgroplemClick} className={classNames("AgroAhoCardComp-checkboxCover",
                        {"AgroAhoCardComp-checkboxCoverDisabled": cstore.formatAgroplemDisabled})}>
                        <AgroAhoRoundCheckboxSvg checked={cstore.indcFormat == AhoIndicatorFormat.Agroplem}/>
                        <span className="AgroAhoCardComp-formatCaption">{store_.trans["Agroplem"]}</span>
                    </div>
                    <div onClick={this.onFosAgroClick} className={classNames("AgroAhoCardComp-checkboxCover",
                        {"AgroAhoCardComp-checkboxCoverDisabled": cstore.formatFosAgroDisabled})}>
                        <AgroAhoRoundCheckboxSvg checked={cstore.indcFormat == AhoIndicatorFormat.FosAgro}/>
                        <span className="AgroAhoCardComp-formatCaption">{store_.trans["FosAgro"]}</span>
                    </div>

                </AgroAhoWindowComp>
            </div>
            {/*<div className="AgroAhoCardComp-tabsCover">*/}
            <div className={classNames("AgroAhoCardComp-tabsCover",
                {"AgroAhoCardComp-hidden": !cstore.soilAnalysisShow || (!hasIndcs && !hasRecNorm)})}>
                <div className="AgroAhoCardComp-tabs">
                {/*<div className={classNames("AgroAhoCardComp-tabs",*/}
                {/*    // {"AgroAhoCardComp-disabled": !cstore.indicatorsShow,*/}
                {/*    //     "AgroAhoCardComp-hidden": cstore.cardIndicators.length == 0})}>*/}
                {/*    {"AgroAhoCardComp-hidden": !cstore.indicatorsShow || cstore.cardIndicators.length == 0})}>*/}
                    <div className="AgroAhoCardComp-tabAhoData AgroAhoCardComp-tabActive">{store_.trans['AXO data']}</div>
                    <div className="AgroAhoCardComp-tabInsMaps">{store_.trans['Introduction cards']}</div>
                </div>
                <div className="AgroAhoCardComp-subTabs">
                {/*// <div className={classNames("AgroAhoCardComp-subTabs",*/}
                {/*    {"AgroAhoCardComp-disabled": !cstore.indicatorsShow,*/}
                {/*        "AgroAhoCardComp-hidden": cstore.cardIndicators.length == 0})}>*/}
                    <div className={classNames("AgroAhoCardComp-tabInds",
                        {"AgroAhoCardComp-subTabsActive": isIndcs,
                         "AgroAhoCardComp-subTabsDisabled": !hasIndcs})} onClick={this.onClickTabInds}>
                        <div>{store.trans['Indicators']}</div>
                        {isIndcs && <div className="AgroAhoCardComp-subLine"/>}
                    </div>
                    <div className={classNames("AgroAhoCardComp-tabNorms",
                        {"AgroAhoCardComp-subTabsActive": isRecNorm,
                         "AgroAhoCardComp-subTabsDisabled": !hasRecNorm})} onClick={this.onClickTabRecNorm}>
                        {store_.trans['Recommended norms']}
                        {isRecNorm && <div className="AgroAhoCardComp-subLine"/>}
                    </div>
                    <div className="AgroAhoCardComp-splitter"/>
                    <div className="AgroAhoCardComp-indicatorsDownloadButtonCover" onClick={this.onClickIndicatorsDownload}>
                        <svg className="AgroAhoCardComp-indicatorsDownloadButton" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.25 12.25H1.75C1.51794 12.25 1.29538 12.3422 1.13128 12.5063C0.967187 12.6704 0.875 12.8929 0.875 13.125C0.875 13.3571 0.967187 13.5796 1.13128 13.7437C1.29538 13.9078 1.51794 14 1.75 14H12.25C12.4821 14 12.7046 13.9078 12.8687 13.7437C13.0328 13.5796 13.125 13.3571 13.125 13.125C13.125 12.8929 13.0328 12.6704 12.8687 12.5063C12.7046 12.3422 12.4821 12.25 12.25 12.25Z"/>
                            <path d="M6.38137 10.2436C6.54545 10.4077 6.76797 10.4998 6.99999 10.4998C7.23201 10.4998 7.45453 10.4077 7.61862 10.2436L11.9936 5.86862C12.0772 5.78791 12.1438 5.69136 12.1897 5.5846C12.2356 5.47785 12.2597 5.36303 12.2607 5.24685C12.2617 5.13067 12.2396 5.01545 12.1956 4.90792C12.1516 4.80038 12.0866 4.70269 12.0045 4.62053C11.9223 4.53837 11.8246 4.4734 11.7171 4.42941C11.6095 4.38541 11.4943 4.36327 11.3781 4.36428C11.262 4.36529 11.1471 4.38943 11.0404 4.43529C10.9336 4.48114 10.8371 4.5478 10.7564 4.63138L7.87499 7.51275V0.875C7.87499 0.642936 7.7828 0.420376 7.61871 0.256282C7.45461 0.0921872 7.23205 0 6.99999 0C6.76793 0 6.54537 0.0921872 6.38127 0.256282C6.21718 0.420376 6.12499 0.642936 6.12499 0.875V7.51275L3.24362 4.63138C3.07859 4.47199 2.85756 4.38379 2.62814 4.38579C2.39872 4.38778 2.17926 4.4798 2.01702 4.64203C1.85479 4.80427 1.76277 5.02373 1.76078 5.25315C1.75878 5.48257 1.84698 5.7036 2.00636 5.86862L6.38137 10.2436Z"/>
                        </svg>
                    </div>
                    {/*<div className="AgroAhoCardComp-divider"/>*/}
                    <div className="AgroAhoCardComp-tourButton" onClick={this.onClickTourButton}>
                        <div className="AgroAhoCardComp-tourCount">{tstore.selTours(true).length}</div>
                        <AgroAhoTourSvg/>
                    </div>
                </div>
            </div>

            <div className="AgroAhoCardComp-list style-4">
            {/*<div className={classNames("AgroAhoCardComp-list style-4",*/}
            {/*    {"AgroAhoCardComp-hidden": !cstore.indicatorsShow || cstore.cardIndicators.length == 0})}>*/}
            {/*<div className="AgroAhoCardComp-list style-4">*/}
            {/*    <div className="AgroAhoCardComp-listBg">*/}
                {isIndcs && <div className={classNames("AgroAhoCardComp-listBg",
                    {"AgroAhoCardComp-hidden": !cstore.soilAnalysisShow || cstore.cardIndicators.length == 0})}>
                    {/*{cstore.cardIndicators.map((item: Indicator) =>*/}
                    {indicators.map((item: Indicator) =>
                        <AgroAhoIndicatorComp key={item.indc_id} store={store} indicator={item} />)}
                </div>}
                {isRecNorm && <div className={classNames("AgroAhoCardComp-listBg",
                    {"AgroAhoCardComp-hidden": !cstore.soilAnalysisShow})}>
                    {mstore.mapRNStore.rn_groups.map(rng =>
                        <AgroAhoRecNormComp key={rng.group_id} store={store} rng={rng}/>)}
                </div>}
            </div>
            <div className={classNames("AgroAhoCardComp-uploadFileCover", {
                "AgroAhoCardComp-uploadFileCoverBg": cstore.soilAnalysisShow && cstore.cardIndicators.length > 0
            })}>
                <button className={classNames("AgroAhoCardComp-uploadFileButton",
                    {"AgroAhoCardComp-disabledButton": uploadDisabled},
                    {"left-sidebar-active button-jump ": !uploadDisabled})}
                    onClick={this.onClickUpload}>{store_.trans['Download table']}
                </button>
            </div>
        </React.Fragment>
    }
}
