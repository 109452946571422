import * as React from 'react';
import {CustomSvg} from "./CustomSvg";

export class CenterSVG extends CustomSvg {

    render() {
        return <svg width="16" height="16" viewBox="0 0 16 16" className={this.getClasses()}
             xmlns="http://www.w3.org/2000/svg">
            <path d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" />
            <path d="M2 6H0V1C0 0.4 0.4 0 1 0H6V2H2V6Z" />
            <path d="M16 6H14V2H10V0H15C15.6 0 16 0.4 16 1V6Z" />
            <path d="M15 16H10V14H14V10H16V15C16 15.6 15.6 16 15 16Z" />
            <path d="M6 16H1C0.4 16 0 15.6 0 15V10H2V14H6V16Z" />
        </svg>;
    }
}
