// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZalFieldListPanelComp-panel {
  margin: 15px 20px;
  display: flex;
  justify-content: flex-end;
}

button.ZalFieldListPanelComp-button {
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  padding: 14px 20px;
  border-radius: 10px;
  border-width: 0;
  margin-right: 15px;
}

button.ZalFieldListPanelComp-button:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./app/components/panels/Left/Agro2/Zalog/ZalFieldListPanel/ZalFieldListPanelComp.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,yBAAA;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AAEF;;AAAA;EACE,eAAA;AAGF","sourcesContent":[".ZalFieldListPanelComp-panel{\r\n  margin: 15px 20px;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\nbutton.ZalFieldListPanelComp-button{\r\n  font-weight: 600;\r\n  font-size: 13px;\r\n  line-height: 12px;\r\n  padding: 14px 20px;\r\n  border-radius: 10px;\r\n  border-width: 0;\r\n  margin-right: 15px;\r\n}\r\nbutton.ZalFieldListPanelComp-button:last-child{\r\n  margin-right: 0;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
