import { fetchJsonSyncGet } from "../../../helper/utils/FetchUtils";
import { CustomStore } from "../../CustomStore";

interface IStationData {
    station_id: number;
    name: string;
    dist_m: number;
};

export class MeteoStationInfo implements IStationData { //IndexByPointerSourceCustomSimpleStore
    constructor (lon: number, lat: number) {
        // this._lon = lon;
        // this._lat = lat;
        let s = (fetchJsonSyncGet(`/api/meteo/station/nearest?lon=${lon}&lat=${lat}`) as IStationData[])[0];
        this.dist_m = s.dist_m;
        this.name = s.name;
        this.station_id = s.station_id;
        // this.stationInfo = lst[0];
    }
    station_id: number;
    name: string;
    dist_m: number;

    // _lon: number;
    // _lat: number;
    // stationInfo: IStationData = null;

    // get stationInfo() : IStationData {
    //     if (! this._stationInfo) {
    //         let lst = fetchJsonSyncGet(`/api/meteo/station/nearest?lon=${this._lon}&lat=${this._lat}`);
    //         this._stationInfo = lst[0];
    //     }
    //     return this._stationInfo;
    // }
}